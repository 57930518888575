import {PayloadAction} from "@reduxjs/toolkit";
import {put, getContext, call, takeLeading} from "redux-saga/effects";

import {UpdateRefferalConfigProps, UpdateRefferalImageProps} from "app/infra/services/api/refferalConfig/types";
import {GoldApi} from "app/infra/services/api";
import {RefferalConfigState} from "./reducer";
import {snackBarActions} from "../snackbar";

import {refferalConfigAction, RefferalConfigTypes} from "./actions";

// >>>>>>>>> Refferal Config Actions <<<<<<<<
const {
  getRefferalConfig,
  getRefferalConfigSuccess,
  getRefferalConfigError,
  updateRefferalConfigSuccess,
  updateRefferalConfigError,
  updateRefferalImageSuccess,
  updateRefferalImageError,
} = refferalConfigAction;

// >>>>>>>> Refferal Flows <<<<<<<<<
function* fetchRefferalConfigFlow(action: PayloadAction<{}>) {
  const api: GoldApi = yield getContext("api");

  const refferalConfigState: RefferalConfigState = yield call(
    api.refferalConfig.fetchReferralConfig,
  );

  if (refferalConfigState.error) {
    yield put(getRefferalConfigError(refferalConfigState));
    yield put(
      snackBarActions.open({
        message: refferalConfigState.error || "Fetching referral config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getRefferalConfigSuccess(refferalConfigState));
  }
}

function* updateRefferalConfigFlow(
  action: PayloadAction<{
    id: number;
    updatePayload: UpdateRefferalConfigProps;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const refferalConfigState: RefferalConfigState = yield call(
    api.refferalConfig.updateRefferalConfig,
    action.payload.id,
    action.payload.updatePayload,
  );

  if (refferalConfigState.error) {
    yield put(updateRefferalConfigError(refferalConfigState));
    yield put(
      snackBarActions.open({
        message: refferalConfigState.error || "Updating referral config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(updateRefferalConfigSuccess(refferalConfigState));
    yield put(getRefferalConfig());

    yield put(
      snackBarActions.open({
        message: "Referral config updated sucessfully",
        variant: "success",
      }),
    );
  }
}

function* updateRefferalImageFlow(
  action: PayloadAction<{
    updatePayload: UpdateRefferalImageProps;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const refferalConfigState: RefferalConfigState = yield call(
    api.refferalConfig.updateRefferalImage,
    action.payload.updatePayload,
  );

  if (refferalConfigState.error) {
    yield put(updateRefferalImageError(refferalConfigState));
    yield put(
      snackBarActions.open({
        message: refferalConfigState.error || "Updating referral image failed",
        variant: "error",
      }),
    );
  } else {
    yield put(updateRefferalImageSuccess());
    yield put(getRefferalConfig());

    yield put(
      snackBarActions.open({
        message: "Referral image updated sucessfully, Please clear cache.",
        variant: "success",
      }),
    );
  }
}

export default function* refferalConfigSaga() {
  yield takeLeading(
    RefferalConfigTypes.GetRefferalConfig,
    fetchRefferalConfigFlow,
  );

  yield takeLeading(
    RefferalConfigTypes.UpdateRefferalConfig,
    updateRefferalConfigFlow,
  );

  yield takeLeading(
    RefferalConfigTypes.UpdateRefferalImage,
    updateRefferalImageFlow,
  );
}
