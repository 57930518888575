import React from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  IGDialog,
  IGFileUpload,
} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {customerProfileActions, customerProfileSelectors} from "app/store/customerProfile";
import {closeModal} from "app/store/customerProfile/customerProfile.reducer";

const BatchRefundUpdateModal = () => {
  const dispatch = useAppDispatch();
  const openModals = useAppSelector(customerProfileSelectors.getOpenModals);
  const batchRefundStatus = useAppSelector(
    customerProfileSelectors.getBatchRefundStatus,
  );
  const {
    uploadBatchClosureRefundReport: uploadBatchClosureRefundReportLoading,
  } = useAppSelector(customerProfileSelectors.getLoading);
  const [file, setFile] = React.useState<any>([]);

  const handleClosureRefundReportUpload = () => {
    const formData = new FormData();
    formData.append("refundCsv", file[0]);
    dispatch(customerProfileActions.uploadBatchClosureRefundReport(formData));
  };
  return (
    <IGDialog
          title="Upload Closure Refund Report"
          open={openModals.uploadBatchClosureRefundReport}
          onClose={() => {
            setFile([]);
            dispatch(closeModal("uploadBatchClosureRefundReport"));
          }}
          onCancel={() => {
            setFile([]);
            dispatch(closeModal("uploadBatchClosureRefundReport"));
          }}
          onConfirm={handleClosureRefundReportUpload}
          confirmProps={{
            loading: uploadBatchClosureRefundReportLoading,
            disabled: !file.length || Boolean(batchRefundStatus.length),
          }}
        >
          <Stack>
            {!batchRefundStatus.length ? (
              <Box>
                <Typography>
                  Please upload the closure refund report in CSV format.
                </Typography>
                <IGFileUpload
                  text=""
                  inputProps={{
                    accept: ".csv",
                  }}
                  value={file}
                  multiple={false}
                  onChange={(files) => {
                    setFile(files);
                  }}
                  draggable={false}
                />
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  border: "1px solid #000",
                }}
              >
                <Table sx={{
                  "& th": {
                    backgroundColor: "#000",
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    minWidth: "100px",
                  },
                  "& td": {
                    padding: "8px 16px",
                  },
                }}>
                  <TableHead>
                    <TableCell>Loan ID</TableCell>
                    <TableCell>Message</TableCell>
                  </TableHead>
                  <TableBody>
                    {batchRefundStatus.map((status) => {
                      return (
                        <TableRow>
                          <TableCell>{status.loanId}</TableCell>
                          <TableCell
                            sx={{
                              color: status.isSuccessful ? "green" : "red",
                            }}
                          >{status.message}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </IGDialog>
  );
};

export default BatchRefundUpdateModal;