import React from "react";
import {Box, Typography} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";
import GuarantorDocView from "../GuarantorDocView";

const GuaranterDocumentDetails = () => {
  const guarantorIdentifyDocs = useAppSelector(
    personalDetailsSelectors.getKycDocuments,
  );

  return (
    <Box
      bgcolor="#FFFFFF;"
      border="1px solid #0000001F"
      borderRadius="4px"
      padding="16px 16px 16px 24px"
      display="flex"
      justifyContent="space-between"
    >
      {guarantorIdentifyDocs
        .filter(
          (i) =>
            i.documentType === "GUARANTOR_AADHAAR" ||
            i.documentType === "GUARANTOR_PAN",
        )
        .map((item) => (
          <Box display="flex" gap={2}>
            <Box>
              <Typography color="text.secondary" variant="body1">
                {item.documentType === "GUARANTOR_AADHAAR"
                  ? "AADHAAR NUMBER"
                  : "PAN NUMBER"}
              </Typography>
              <Typography>{item?.documentNumber}</Typography>
            </Box>
            <Box>
              <GuarantorDocView guarantorDocument={item} />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default GuaranterDocumentDetails;
