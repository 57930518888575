import React, {useCallback, useMemo, useState} from "react";
import {Navigate} from "react-router-dom";
import {Formik} from "formik";
import {TextField, IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff, EmailOutlined} from "@mui/icons-material";
import clsx from "clsx";
import {authActions, authSelectors} from "../../store/auth";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import NewGoogleLogin from "./GoogleLogin";
import {useIGTranslation} from "app/infra/plugins/i18n";
import ServerSwitch from "app/AppLayout/NavBar/ServerSwitch";
import {IGButton} from "app/components";
import {layoutSelector} from "app/store/layout";

const inputProps = {
  style: {
    fontSize: "1.1rem",
    borderRadius: 10,
    padding: "0.2rem",
  },
};

const {hostname} = window.location;
const RETRY_SENT_OTP_TIMER = 30000;
let retrySendOtpTimeout: any;
let retrySendOtpInterval: any;

// Callbacks
const Login: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const {t, tErrors} = useIGTranslation();
  const {
    sendOtp: sendOtpLoading,
    verifyOtp: verifyOtpLoading,
    login: loginLoading,
    postGoogleLogin: postGoogleLoginLoading,
  } = useAppSelector(authSelectors.getLoading);
  const isGoogleAuth = useAppSelector(authSelectors.getIsGoogleAuth);
  const user = useAppSelector(authSelectors.getUser);
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const otpId = useAppSelector(authSelectors.getOtpId);
  const error = useAppSelector(authSelectors.getError);
  const featureFlags = useAppSelector(layoutSelector.getFeatureFlags);
  const layoutLoading = useAppSelector(layoutSelector.getLoading);
  const {showInfo} = useSnackBar();
  const [showPassword, setShowPassword] = useState(false);
  const [showOptScreen, setShowOptScreen] = useState(false);
  const [canRetry, setCanRetry] = useState(false);
  const [retrySeconds, setRetrySeconds] = useState(30);

  const is2FAEnabled = useMemo(() => {
    return featureFlags.find(
      (flag: any) =>
        flag.featureNameEnum === "ADMIN_APP_2FA" && flag.isFeatureActive,
    );
  }, [featureFlags]);

  const handleSendOtp = useCallback(
    (values) => {
      dispatch(
        authActions.sendOtp({email: values.email, password: values.password}),
      );
      if (retrySendOtpTimeout) {
        clearTimeout(retrySendOtpTimeout);
      }
      if (retrySendOtpInterval) {
        clearInterval(retrySendOtpInterval);
      }
      setCanRetry(false);
      retrySendOtpTimeout = setTimeout(() => {
        setCanRetry(true);
      }, RETRY_SENT_OTP_TIMER);
      setRetrySeconds(30);
      retrySendOtpInterval = setInterval(() => {
        setRetrySeconds((retrySeconds) => retrySeconds - 1);
      }, 1000);
    },
    [dispatch],
  );

  const handleFormSubmit = useCallback(
    (values, {setSubmitting}) => {
      if (values.email.includes("@indiagold")) {
        showInfo("Try to Login with Google!");
        setSubmitting(false);
        return;
      }

      if (is2FAEnabled) {
        if (!showOptScreen) {
          handleSendOtp(values);
          setShowOptScreen(true);
        } else {
          if (otpId) {
            dispatch(
              authActions.verifyOtp({
                otp: values.otp,
                otpId,
                email: values.email,
              }),
            );
          } else {
            handleSendOtp(values);
          }
        }
      } else {
        dispatch(
          authActions.login({
            email: values.email,
            password: values.password,
          }),
        );
      }
    },
    [showOptScreen, otpId, dispatch, showInfo, is2FAEnabled, handleSendOtp],
  );

  const validateFormData = useCallback(
    (values) => {
      const errors: { email?: string; password?: string } = {};

      if (!values.email) {
        errors.email = tErrors("REQUIRED", {field: "Email"});
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = tErrors("EMAIL");
      }
      if (!values.password) {
        errors.password = tErrors("REQUIRED", {field: "Password"});
      }
      return errors;
    },
    [tErrors],
  );

  // Lifecycle
  React.useEffect(() => {
    if (error.sendOtp) {
      setShowOptScreen(false);
    }
  }, [error]);

  // Render Helpers
  const loginButtonText = useMemo(() => {
    if (!showOptScreen && !otpId && is2FAEnabled) {
      return "Send OTP";
    }

    return "Login";
  }, [showOptScreen, otpId, is2FAEnabled]);

  if (isAuthenticated && user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="kt-login__head">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
          <div className="kt-grid__item kt-grid__item--middle">
            <h1 className="kt-login__title">
              {t("AUTH.A_CUSTOMER_CENTRIC_ASSET")}
            </h1>
          </div>
        </div>
      </div>
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__subtitle">
            <h4>{t("AUTH.SIGN_IN_TO_CONTINUE_TO_YOUR_PARTNER_ACCOUNT")}</h4>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              otp: "",
            }}
            validate={validateFormData}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <>
                <form noValidate={true} autoComplete="off" className="kt-form">
                  {!otpId ? (
                    <>
                      <div className="form-group">
                        <TextField
                          type="email"
                          margin="normal"
                          name="email"
                          placeholder="Email Address"
                          InputProps={inputProps}
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          helperText={touched.email && errors.email}
                          error={Boolean(touched.email && errors.email)}
                        />
                      </div>

                      <div className="form-group">
                        <TextField
                          type={!showPassword ? "password" : "text"}
                          margin="normal"
                          placeholder="Password"
                          InputProps={{
                            ...inputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  // edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          error={Boolean(touched.password && errors.password)}
                        />
                      </div>
                    </>
                  ) : (
                    /* <OtpForm/> */
                    <div className="form-group">
                      <TextField
                        type="otp"
                        margin="normal"
                        name="otp"
                        placeholder="Enter OTP"
                        InputProps={inputProps}
                        variant="outlined"
                        value={values.otp}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.otp && errors.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                    </div>
                  )}
                </form>
                <div className="kt-login__actions">
                  <IGButton
                    id="kt_login_signin_submit"
                    disabled={sendOtpLoading}
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                    style={{
                      paddingRight:
                        sendOtpLoading && !isGoogleAuth ? "3.5rem" : "2.5rem",
                      color: "#fff",
                    }}
                    loading={
                      sendOtpLoading ||
                      layoutLoading ||
                      loginLoading ||
                      postGoogleLoginLoading
                    }
                    onClick={() => handleSubmit()}
                  >
                    {loginButtonText}
                  </IGButton>
                  {showOptScreen && otpId && (
                    <IGButton
                      id="kt_login_signin_submit"
                      disabled={verifyOtpLoading || !canRetry}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light":
                            verifyOtpLoading && !isGoogleAuth,
                        },
                      )}`}
                      style={{
                        paddingRight:
                          verifyOtpLoading && !isGoogleAuth
                            ? "3.5rem"
                            : "2.5rem",
                        color: "#fff",
                      }}
                      onClick={() => handleSendOtp(values)}
                    >
                      {canRetry
                        ? "Resend OTP"
                        : `Resend OTP in ${retrySeconds}s`}
                    </IGButton>
                  )}
                  <NewGoogleLogin />
                  <ServerSwitch />
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
      <div className="kt-login__footer_container">
        <div className="kt-login__footer_text">
          {t("AUTH.BY_CONTINUING_WITH_ANY")}
        </div>
        <div className="kt-login__footer">
          <div>
            <EmailOutlined fontSize="small" className="mr-2" />
            {t("HELP_INDIAGOLD_CO")}
          </div>
          <div>&copy; {t("INDIAGOLD")}</div>
        </div>
      </div>
    </>
  );
};

export default Login;
