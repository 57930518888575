import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {loanPaymentActionTypes} from "./loanPayment.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {LoanIdPayload, PaymentIdPayload, ShiftPaymentPayload} from "./loanPayment.types";
import {GoldApi} from "../../infra/services/api";
import {loanPaymentActions} from "./index";
import {snackBarActions} from "../snackbar";
import {closeModal} from "../customerProfile/customerProfile.reducer";

const {
  getLoanPaymentDetailsSuccess,
  getLoanPaymentDetailsFailure,
  getLenderLoanPaymentDetailsFailure,
  getLenderLoanPaymentDetailsSuccess,
  refundPaymentFailure,
  refundPaymentSuccess,
  shiftLoanPaymentFailure,
  shiftLoanPaymentSuccess,
  getRefundStatusFailure,
  getRefundStatusSuccess,
  getShiftPaymentStatusFailure,
  getShiftPaymentStatusSuccess,
} = loanPaymentActions;

function* getLoanPaymentDetailFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.getLoanPaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getLoanPaymentDetailsFailure({response: null, error}));
  } else {
    yield put(getLoanPaymentDetailsSuccess({response, error: null}));
  }
}

function* getLenderLoanPaymentDetailFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.getLenderLoanPaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getLenderLoanPaymentDetailsFailure({response: null, error}));
  } else {
    yield put(getLenderLoanPaymentDetailsSuccess({response, error: null}));
  }
}

function* refundPaymentFlow(
  action: PayloadAction<PaymentIdPayload & ShiftPaymentPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.refundPayment,
    action.payload,
  );
  if (error) {
    yield put(refundPaymentFailure({response: null, error}));
    yield put(
      snackBarActions.open({
        message: error.message || "Refund request failed",
        variant: "error",
      }),
    );
  } else {
    yield put(refundPaymentSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Refund request sent successfully",
        variant: "success",
      }),
    );
    yield put(closeModal("refund"));
    window.location.reload();
  }
}

function* shiftPaymentFlow(action: PayloadAction<ShiftPaymentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.shiftLoanPayment,
    action.payload,
  );
  if (error) {
    yield put(shiftLoanPaymentFailure({response: null, error}));
  } else {
    yield put(shiftLoanPaymentSuccess({response, error: null}));
  }
}

function* getRefundStatusFlow(action: PayloadAction<PaymentIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.getRefundStatus,
    action.payload,
  );
  if (error) {
    yield put(getRefundStatusFailure({response: null, error}));
  } else {
    yield put(getRefundStatusSuccess({response, error: null}));
  }
}

function* getShiftPaymentStatusFlow(action: PayloadAction<PaymentIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanPayment.getShiftPaymentStatus,
    action.payload,
  );
  if (error) {
    yield put(getShiftPaymentStatusFailure({response: null, error}));
  } else {
    yield put(getShiftPaymentStatusSuccess({response, error: null}));
  }
}

export default function* loanPaymentSaga() {
  yield takeLatest(
    loanPaymentActionTypes.GetLoanPaymentDetails,
    getLoanPaymentDetailFlow,
  );

  yield takeLatest(
    loanPaymentActionTypes.GetLenderLoanPaymentDetails,
    getLenderLoanPaymentDetailFlow,
  );

  yield takeLatest(loanPaymentActionTypes.RefundPayment, refundPaymentFlow);

  yield takeLatest(loanPaymentActionTypes.ShiftLoanPayment, shiftPaymentFlow);

  yield takeLatest(loanPaymentActionTypes.GetRefundStatus, getRefundStatusFlow);

  yield takeLatest(
    loanPaymentActionTypes.GetShiftPaymentStatus,
    getShiftPaymentStatusFlow,
  );
}