import React, {FC, useEffect, useState} from "react";
import IGDialog from "./index";
import {Box, Icon, Typography} from "@mui/material";
import {ReportGmailerrorredRounded, TaskAltRounded} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGDialogActions, IGDialogSelectors} from "app/store/igDialog";
import IGLoading from "../Loading";

let interval: NodeJS.Timeout | null = null;

const IGDialogHook: FC = () => {

  const IconEnum = {
    success: TaskAltRounded,
    error: ReportGmailerrorredRounded,
  };

  const dispatch = useAppDispatch();

  const open = useAppSelector(IGDialogSelectors.getOpen);
  const title = useAppSelector(IGDialogSelectors.getTitle);
  const content = useAppSelector(IGDialogSelectors.getContent);
  const type = useAppSelector(IGDialogSelectors.getType);
  const onConfirm = useAppSelector(IGDialogSelectors.getOnConfirm);
  const params = useAppSelector(IGDialogSelectors.getOtherParams);

  const {onCancel, onClose, showActions, timer, persist} = params;

  const [seconds, setSeconds] = useState(90);

  const closeDialog = () => {
    dispatch(IGDialogActions.close());
    if (interval) {
      clearInterval(interval);
    }
  };

  useEffect(() => {
    if (timer) {
      interval = setInterval(() => {
        setSeconds((seconds) => {
          if ((seconds - 1) > 0) {
            return seconds - 1;
          }
          interval && clearInterval(interval);
          return 0;
        });
      }, 1000);
    }

    return () => {
      setSeconds(90);
      interval && clearInterval(interval);
    };
  }, [timer, content]);

  return (
    <IGDialog
      {...params}
      open={open}
      title={title}
      onClose={() => {
        if (persist) return;
        closeDialog();
        onClose && onClose();
      }}
      onConfirm={(event) => {
        closeDialog();
        onConfirm && onConfirm(event);
      }}
      onCancel={(event) => {
        closeDialog();
        onClose && onClose();
        onCancel && onCancel(event);
      }}
      hideFooter={!showActions}
    >
      {type === "loading" && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <IGLoading height="5vh"/>
          </Box>
        </Box>
      )}
      {!["confirm", "loading"].includes(type) ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={2}>
            <Icon
              component={IconEnum[type as "success" | "error"]}
              sx={{fontSize: "75px"}}
              color={type as "success" | "error"}
            />
          </Box>
          <Box>
            <Typography variant="h6">{content}</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems={type === "loading" ? "center": "flex-start"}
          justifyContent="center"
        >
          {timer && (
            <Typography>
              {new Date(seconds * 1000).toISOString().substr(14, 5)}
            </Typography>
          )}
          {content}
        </Box>
      )}
    </IGDialog>
  );
};

export default IGDialogHook;