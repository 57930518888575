import React from "react";
import {Button} from "@mui/material";
import styles from "./styles";

interface Props {
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
}
const ButtonComponent: React.FC<Props> = ({
  onClickHandler,
  children,
  style,
  className,
  disabled,
  ...props
}) => {
  const classes = styles();

  return (
    <Button
      style={{
        ...style,
      }}
      className={classes.container + (className ? ` ${className} ` : " ")}
      onClick={onClickHandler}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
