import React, {useState, useEffect, useRef} from "react";
import getGoldLoanDetail from "../../../../../../../../api/business/getGoldLoanDetail";
import {CircularProgress} from "@mui/material";
import numberWithCurrencyAndComma from "../../../../../../../../lib/numberWithCurrencyAndComma";
import styles from "../../styles";


const PersonalLoanTaken = ({selectedPayload, currentMileStone}) => {
  const classes = styles();
  const counter = useRef(0);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      gl: null,
      pl: null,
    },
    errorMessage: "",
  });

  useEffect(() => {
    (async () => {
      try {
        if (counter.current === 0) {
          counter.current = 1;
          const [response, glResponse] = await Promise.all([
            getGoldLoanDetail({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "PERSONAL_LOAN",
            }),
            getGoldLoanDetail({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "GOLD_LOAN",
            }),
          ]);

          if (response.statusCode === 200 && glResponse.statusCode === 200) {
            setStates({
              loading: false,
              error: false,
              payload: {
                pl: response.payload,
                gl: glResponse.payload,
              },
              errorMessage: "",
            });
          } else {
            setStates({
              loading: false,
              error: true,
              payload: {
                gl: null,
                pl: null,
              },
              errorMessage: response.error || glResponse.error,
            });
          }
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: {
            gl: null,
            pl: null,
          },
          errorMessage: "Something went wrong",
        });
      }
    })();
  }, [selectedPayload.id]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  // const gl = states.payload.gl;
  const {pl} = states.payload;

  return (
    <div className={classes.textContainer}>
      <div className={classes.text}>INFORMATION</div>
      <div
        className={classes.subText}
      >{`Total Loan Amount: ${numberWithCurrencyAndComma(
        pl.totalLoanAmount,
      )}`}</div>

     {/* commenting this code because of rbi compliance */}
      {/* <div
        className={classes.subText}
      >{`Total Requested Loan Amount: ${numberWithCurrencyAndComma(
        pl.loanRequestedAmount,
      )}`}</div> */}

      <div className={classes.subText}>{`Loan Tenure: ${pl.loanTenure}`}</div>

      {/* <div className={classes.subText}>{`Final LTV: ${pl.ltv}%`}</div> */}

    </div>
  );
};

export default PersonalLoanTaken;
