/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "../styles";
import updateStatus from "api/business/updateStatus";
import getNextJob from "api/business/getNextJob";

import {
  CircularProgress,
  InputAdornment,
  Typography,
  FormHelperText,
  IconButton,
  Divider,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import numberWithCurrencyAndComma from "../../../../../../../lib/numberWithCurrencyAndComma";
import getPaymentDetails from "../../../../../../../api/business/getPaymentDetail";
import getOrnamentSummary from "../../../../../../../api/business/getOrnamentSummary";
import {useAppDispatch, useSnackBar} from "app/store/hooks";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {
  IGDialog,
  IGTextField,
  IGSelect,
  IGButton,
  IGFileUpload,
  IGDocumentViewer,
} from "app/components";
import {Box, Grid} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import getSchemeTypeList from "api/activeLoan/getSchemeTypeList";
import getAllSchemeList from "api/activeLoan/getAllSchemeList";
import postGoldLoanMakerDetails from "api/activeLoan/postGoldLoanMakerDetails";
import {loanTypeEnum} from "app/enums/customerProfiles";
import {
  activeLoansActions,
  activeLoansSelectors,
} from "app/store/activeLoans";
import {
  transactionsActions,
  transactionsSelectors,
} from "app/store/transactions";
import getProcessingFeeConfig from "api/activeLoan/getProcessingFeeConfig";
import checkCustomerRoiEditable from "api/activeLoan/checkCustomerRoiEditable";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import fetchEmploymentDetails from "api/business/fetchAdminEmploymentDetails";
import createDocument from "api/business/createDocument";
import getDocument from "api/business/getDocument";
import ArrowUpFromBracket from "app/AppLayout/Icons/ArrowUpFromBracket";
import {useFormik} from "formik";
import returnToGoldValuation from "api/activeLoan/returnToGoldValuation";
import {isNil} from "lodash";
import {useIGValidations, useNumberValidations} from "app/validations";

const GoldLoanDetails = ({
  selectedPayload,
  isSameCreditManager,
  isBackMovement,
  currentMileStone,
  getMileStoneOfCr,
}) => {
  const counter = useRef(0);
  const classes = styles();

  const {showError, showSuccess} = useSnackBar();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const lmRequestedInsuranceDetails = useAppSelector(
    activeLoansSelectors.getLmInsuranceDetails,
  );
  const {t, tDialogTitle, tButton} = useIGTranslation();
  const dispatch = useAppDispatch();
  const loanMakerFieldsFlag = useAppSelector(
    activeLoansSelectors.getLoanMakerFieldsFlag,
  );

  const {glMakerScreenDisplayInputFields} = loanMakerFieldsFlag ?? {};

  const {
    sanctionAmountDisabled,
    customerDisbursedAmountDisabled,
    customerRoiDisabled,
    processingFeeAmountInputDisabled,
    bankRoiInputDisabled,
    loanSchemeTypeDropDownDisabled,
    loanSchemeDropDownDisabled,
    tenureDropDownDisabled,
    isSubventionActive,
    isIgRecoveryAmountFromCustomerForBtInputDisabled,
    isNetTopUpForCustomerInputDisabled,
  } = glMakerScreenDisplayInputFields ?? {};
  const {
    loanTenure,
    sanctionAmount,
    loanSchemeTypeEnum,
    customerDisbursedAmount,
    customerRoi,
    processingFeeAmount,
    schemeId,
    bankRoi,
  } = useAppSelector(activeLoansSelectors.getLoanMakerPrefilledDetails) ?? {};

  const isInsuranceUpsellRestricted = useAppSelector(
    activeLoansSelectors.getInsuranceFeatureFlag,
  );

  const isAgriLoanAllowedWithoutDoc = useAppSelector(
    activeLoansSelectors.getAgriFlowFlag,
  );

  const isLoanAmountSentToCustomerForBTRequired = useAppSelector(
    activeLoansSelectors.getLoanAmountSentToCustomerForBT,
  );
  const loanRenewalDetails = useAppSelector((state) =>
    activeLoansSelectors.getLoanRenewalDetailsByCrId(
      state,
      Number(selectedPayload.id),
    ),
  );

  const {getAdjustmentRenewalRoi: isRenewalDetailsLoading} = useAppSelector(
    transactionsSelectors.getLoading,
  );

  const isTransactionFlowEnable = useAppSelector(
    transactionsSelectors.showTransactionFlow,
  );
  // States

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      payment: null,
      ornamentsSummary: null,
      getNextJob: null,
    },
    errorMessage: "",
  });
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [button, setButton] = useState(true);

  const [effectiveLTV, setEffectiveLTV] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    monthlyInterestRate: "",
    subventedInterestRate: "",
  });
  const [processingFeeConfig, setProcessingFeeConfig] = useState({
    minimumProcessingFeePercentage: null,
    maximumProcessingFeePercentage: null,
    isProcessingFeeApplicable: false,
  });
  const [isGoldLoanSummaryModalOpen, setIsGoldLoanSummaryModal] = useState(
    false,
  );
  const [schemeTypeOptions, setSchemeTypeOptions] = useState([]);
  const [schemeListOptions, setSchemeListOptions] = useState([]);

  const [
    selectedSchemeRepaymentType,
    setSelectedSchemeRepaymentType,
  ] = useState("");

  const [isCustomerRoiEditable, setIsCustomerRoiEditable] = useState(false);
  const [workSector, setWorkSector] = useState("");
  const [recoveryAmountForBT, setRecoveryAmountForBT] = useState(null);
  const [isRecoveryAmountRequired, setIsRecoveryAmountRequired] = useState(
    false,
  );

  const [insuranceAmount, setInsuranceAmount] = useState(null);
  const [isInsuranceOptedOut, setIsInsuranceOptedOut] = useState(false);
  const [approvalScreenshots, setApprovalScreenshots] = useState([]);
  const [selectedCrJobId, setSelectedCrJobId] = useState(null);
  const [insuranceDocument, setInsuranceDocument] = useState([]);
  const [selectedReasonForRecovery, setSelectedReasonForRecovery] = useState(
    null,
  );
  const [landAcquiProof, setLandAcquiProof] = useState([]);
  const [lenderApprovalImage, setLenderApprovalImage] = useState([]);
  const [landAcquiImage, setLandAcquiImage] = useState([]);
  const [lenderApprovalScreenshot, setLenderApprovalScreenshot] = useState([]);
  const [isMakerDocUploadLoading, setIsMakerDocUploadLoading] = useState({});

  const {payment, ornamentsSummary} = states.payload;

  const supportedPdfTypes = ["PDF", "application/pdf"];

  const recoveryReasonOptions = [
    {
      label: "Gold not sufficient",
      value: "GOLD_NOT_SUFFICIENT",
    },
    {
      label: "Split Case",
      value: "SPLIT_CASE",
    },
    {
      label: "PF recovery",
      value: "PF_RECOVERY",
    },
  ];

  /**
   * Callbacks
   */

  const fetchOccupationalDetails = useCallback(async () => {
    const {error, response} = await fetchEmploymentDetails({
      crId: selectedPayload.id,
      crEmploymentDetailType: "LOAN_EMPLOYMENT_DETAIL",
    });

    if (!error) {
      setWorkSector(response?.userWorkSectorDisplayValue);
    } else {
      showError(error?.message || "not able to fetch occupdation details");
    }
  }, [selectedPayload.id, showError]);

  const onClickHandler = useCallback(
    async (values) => {
      const {
        disbursedAmount,
        loanSanctionedAmount,
        selectedTenurePeriod,
        selectedScheme,
        processingFeeAmount,
        selectedSchemeType,
        customerRoi,
        monthlyRoi,
        netTopUpToCustomer,
        recoveryForTakeoverAmount,
      } = values;
      try {
        if (!values.loanSanctionedAmount && !sanctionAmountDisabled) {
          showError("Amount is required");
          setConfirmBtnLoading(false);
          return;
        }
        if (!values.disbursedAmount && !customerDisbursedAmountDisabled) {
          showError("Disburse Amount is required");
          setConfirmBtnLoading(false);
          return;
        }
        setConfirmBtnLoading(true);
        const getNextResponse = await getNextJob({
          accessToken,
          crId: selectedPayload.id,
          jobType: "CM_PROCESS_LOAN",
        });

        if (getNextResponse.statusCode === 200) {
          const goldLoanMakerDetailsPayload = {
            crId: selectedPayload.id,
            crJobId: getNextResponse.payload.id,
            totalGlAmount: loanSanctionedAmount,
            loanType: loanTypeEnum.GOLD_LOAN,
            loanTenure: selectedTenurePeriod,
            disburseGlAmount: disbursedAmount,
            schemeType: selectedSchemeType,
            subventedMonthlyInterestRate: isCustomerRoiEditable
              ? customerRoi
              : monthlyRoi,
            monthlyInterestRate: monthlyRoi,
            schemeCodeId: selectedScheme,
            processingFeeIncludingGst: processingFeeAmount,
            btRecoveryAmountFromCustomer: recoveryAmountForBT,
            insuranceAmount: insuranceAmount,
            recoveryReasonEnum: selectedReasonForRecovery,
          };

          if (isLoanAmountSentToCustomerForBTRequired) {
            goldLoanMakerDetailsPayload.igRecoveryAmountFromCustomerForBt = Number(
              recoveryForTakeoverAmount,
            );
            goldLoanMakerDetailsPayload.netTopUpForCustomer = Number(
              netTopUpToCustomer,
            );
          }

          const {error: goldLoanMakerError} = await postGoldLoanMakerDetails(
            goldLoanMakerDetailsPayload,
          );

          if (!goldLoanMakerError) {
            const updateStatusResponse = await updateStatus({
              accessToken,
              crId: selectedPayload.id,
              crJobId: getNextResponse.payload.id,
              remark: "",
              status: "CM_PROCESS_LOAN_GL_APPLICATION_APPROVED",
            });
            if (updateStatusResponse.statusCode === 200) {
              showSuccess("Successfully saved!");
              setButton(false);
              getMileStoneOfCr();
              setConfirmBtnLoading(false);
            } else {
              showError(updateStatusResponse.error.toString());
              setConfirmBtnLoading(false);
            }
          } else {
            showError(goldLoanMakerError.message);
            setConfirmBtnLoading(false);
          }
        } else {
          showError(getNextResponse.error.toString());
        }
        setConfirmBtnLoading(false);
      } catch (error) {
        showError("Something went wrong");
        setConfirmBtnLoading(false);
      }
    },
    [
      sanctionAmountDisabled,
      customerDisbursedAmountDisabled,
      accessToken,
      selectedPayload.id,
      showError,
      isCustomerRoiEditable,
      recoveryAmountForBT,
      insuranceAmount,
      selectedReasonForRecovery,
      isLoanAmountSentToCustomerForBTRequired,
      showSuccess,
      getMileStoneOfCr,
    ],
  );

  const recoveryForTakeoverAmountValidation = useNumberValidations({
    positiveWithZero: true,
  });

  const netTopUpToCustomerValidation = useNumberValidations({
    positiveWithZero: true,
  });

  const validationSchema = useIGValidations({
    loanSanctionedAmount: useNumberValidations({
      positiveWithZero: true,
      integer: true,
    }),
    recoveryForTakeoverAmount: isLoanAmountSentToCustomerForBTRequired
      ? recoveryForTakeoverAmountValidation
      : undefined,
    netTopUpToCustomer: isLoanAmountSentToCustomerForBTRequired
      ? netTopUpToCustomerValidation
      : undefined,
  });

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      loanSanctionedAmount: sanctionAmount || "",
      disbursedAmount: customerDisbursedAmount,
      processingFeeAmount: processingFeeAmount || "0",
      selectedScheme: schemeId || "",
      selectedTenurePeriod: loanTenure || "",
      customerRoi: customerRoi || "",
      monthlyRoi: bankRoi || "",
      selectedSchemeType: loanSchemeTypeEnum,
      recoveryForTakeoverAmount: null,
      netTopUpToCustomer: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      await onClickHandler(values);
    },
  });

  const onClickBackHandler = useCallback(async () => {
    try {
      const response = await returnToGoldValuation({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
      });

      if (response.statusCode === 200) {
        getMileStoneOfCr();
      } else {
        showError(response.error);
      }
    } catch (error) {
      showError("Something went wrong");
    }
  }, [getMileStoneOfCr, selectedPayload.id, showError]);

  const fetchSchemeTypeList = useCallback(async () => {
    const {response, error} = await getSchemeTypeList({
      crId: selectedPayload.id,
    });
    if (!error) {
      setSchemeTypeOptions(response);
    } else {
      showError(error.message);
    }
  }, [selectedPayload.id]);

  const fetchAllSchemeList = useCallback(
    async (loanSchemeType) => {
      const {response, error} = await getAllSchemeList({
        crId: selectedPayload.id,
        loanSchemeType,
      });
      if (!error) {
        setSchemeListOptions(response);
      } else {
        showError(error.message);
      }
    },
    [selectedPayload.id],
  );

  const fetchDocuments = useCallback(async () => {
    const {payload, error} = await getDocument({
      accessToken,
      crId: selectedPayload.id,
      documentType: [
        "INSURANCE_APPROVAL_DOCUMENT",
        "LAND_ACQUAISATION_PROOF_FOR_AGRI_LOAN",
        "LENDER_APPROVAL_IMAGE_FOR_AGRI_LOAN",
      ],
    });

    if (!error && payload.length !== 0) {
      const insuranceDoc = payload.filter(
        (item) => item.documentType === "INSURANCE_APPROVAL_DOCUMENT",
      );
      const landDoc = payload.filter(
        (item) => item.documentType === "LAND_ACQUAISATION_PROOF_FOR_AGRI_LOAN",
      );
      const lenderApprovalDoc = payload.filter(
        (item) => item.documentType === "LENDER_APPROVAL_IMAGE_FOR_AGRI_LOAN",
      );

      setInsuranceDocument(insuranceDoc);
      setLandAcquiProof(landDoc);
      setLenderApprovalImage(lenderApprovalDoc);
    } else {
    }
  }, [accessToken]);

  const fetchProcessingFeeConfig = useCallback(async () => {
    const {response, error} = await getProcessingFeeConfig({
      crId: selectedPayload.id,
      lenderEnum: lenderNameEnum.SHIVALIK,
    });
    if (!error) {
      setProcessingFeeConfig((prevState) => ({
        ...prevState,
        minimumProcessingFeePercentage:
          response?.minimumProcessingFeePercentage,
        maximumProcessingFeePercentage:
          response?.maximumProcessingFeePercentage,
        isProcessingFeeApplicable: response?.isProcessingFeeApplicable,
      }));
    } else {
      showError(error.message);
    }
  }, [selectedPayload.id, showError]);

  const fetchCustomerRoiEditable = useCallback(async () => {
    const {response, error} = await checkCustomerRoiEditable({
      crId: selectedPayload.id,
    });
    if (!error) {
      setIsCustomerRoiEditable(response);
    } else {
      showError(error.message);
    }
  }, [selectedPayload.id]);
  /**
   * Lifecycle Management
   */

  useEffect(() => {
    (async () => {
      try {
        if (counter.current === 0) {
          counter.current = 1;

          if (
            currentMileStone.data.milestone === "CR_GOLD_LOAN_FRESH_GOLD_LOAN"
          ) {
            const [ornamentsResponse, nextJobResponse] = await Promise.all([
              getOrnamentSummary({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
              }),
              getNextJob({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                jobType: "CM_PROCESS_LOAN",
              }),
            ]);

            if (nextJobResponse) {
              setSelectedCrJobId(nextJobResponse?.payload?.id);
            }

            if (ornamentsResponse.statusCode === 200) {
              setStates({
                loading: false,
                error: false,
                payload: {
                  ornamentsSummary: ornamentsResponse.payload,
                  payment: null,
                  getNextJob: nextJobResponse.payload,
                },
                errorMessage: "",
              });
            } else {
              setStates({
                loading: false,
                error: true,
                payload: {
                  payment: null,
                  ornamentsSummary: null,
                },
                errorMessage: ornamentsResponse.error.toString(),
              });
            }
          } else {
            const [
              ornamentsResponse,
              personalLoanResponse,
              nextResponse,
            ] = await Promise.all([
              getOrnamentSummary({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
              }),
              getPaymentDetails({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                paymentDetailType: "TAKEOVER_PAYMENT_DETAIL",
              }),
              getNextJob({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                jobType: "CM_PROCESS_LOAN",
              }),
            ]);

            if (nextResponse) {
              setSelectedCrJobId(nextResponse?.payload?.id);
            }
            if (
              personalLoanResponse.statusCode === 200 &&
              ornamentsResponse.statusCode === 200
            ) {
              setStates({
                loading: false,
                error: false,
                payload: {
                  payment: personalLoanResponse.payload,
                  ornamentsSummary: ornamentsResponse.payload,
                  getNextJob: nextResponse.payload,
                },
                errorMessage: "",
              });
            } else {
              setStates({
                loading: false,
                error: true,
                payload: {
                  payment: null,
                  ornamentsSummary: null,
                  getNextJob: null,
                },
                errorMessage:
                  ornamentsResponse?.error?.toString() ||
                  personalLoanResponse?.error?.toString(),
              });
            }
          }
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: {
            glResponse: null,
            payment: null,
            ltv: null,
            ornamentsSummary: null,
          },
          errorMessage: "",
        });
      }
    })();
  }, [
    currentMileStone.data.milestone,
    isBackMovement,
    selectedPayload.id,
    selectedPayload.milestone,
    selectedPayload.milestones,
  ]);

  const uploadInsuranceOptOutScreenShot = async (docImages, documentType) => {
    if (
      isInsuranceOptedOut &&
      documentType === "INSURANCE_APPROVAL_DOCUMENT" &&
      docImages.length === 0
    ) {
      showError("Please upload ScreenShot!");

      return;
    }
    if (!selectedCrJobId) {
      showError("Please assign this job to yourself first!");
      return;
    }
    setIsMakerDocUploadLoading((prevState) => ({
      ...prevState,
      [documentType]: {
        ...prevState[documentType],
        isLoading: true,
      },
    }));

    const formData = new FormData();
    formData.append("fileData", docImages[0]);
    const {error} = await createDocument({
      accessToken,
      crId: selectedPayload.id,
      crJobId: selectedCrJobId,
      documentType: documentType,
      fileContentType: "image%2Fjpeg",
      fileData: formData,
      isAddressProof: false,
      isIdProof: false,
      physicalState: "original",
      documentNumber: null,
      fileType: docImages[0]?.type,
      fileName: docImages[0]?.name,
    });
    if (!error) {
      setIsMakerDocUploadLoading((prevState) => ({
        ...prevState,
        [documentType]: {
          ...prevState[documentType],
          isLoading: false,
        },
      }));
      showSuccess("Document has been successfully uploaded!");
      fetchDocuments();
    } else {
      setIsMakerDocUploadLoading((prevState) => ({
        ...prevState,
        [documentType]: {
          ...prevState[documentType],
          isLoading: false,
        },
      }));
      showError(error || "Some error occured while uploading!");
    }
  };

  useEffect(() => {
    if (ornamentsSummary !== null && !isBackMovement) {
      setEffectiveLTV(
        (Number(values.loanSanctionedAmount) * 100) /
          ornamentsSummary.newGoldValue,
      );
    }
  }, [ornamentsSummary, isBackMovement, values.loanSanctionedAmount]);

  useEffect(() => {
    fetchSchemeTypeList();
    fetchProcessingFeeConfig();
    fetchCustomerRoiEditable();
    fetchOccupationalDetails();
    fetchDocuments();
  }, []);

  useEffect(() => {
    dispatch(
      activeLoansActions.getMakerFieldsFlag({
        crId: selectedPayload.id,
      }),
    );
    dispatch(
      activeLoansActions.getInsuranceFeatureFlag({
        crId: selectedPayload.id,
      }),
    );
    dispatch(
      activeLoansActions.getPrefilledLoanMakerDetails({
        crId: selectedPayload.id,
      }),
    );
  }, []);

  useEffect(() => {
    if (values.selectedSchemeType) {
      fetchAllSchemeList(values.selectedSchemeType);
    }
  }, [fetchAllSchemeList, setFieldValue, values.selectedSchemeType]);

  useEffect(() => {
    if (isInsuranceOptedOut) {
      setInsuranceAmount(0);
    } else {
      setInsuranceAmount(null);
      setApprovalScreenshots([]);
    }
  }, [isInsuranceOptedOut]);

  useEffect(() => {
    const selectedRepaymentType = schemeListOptions?.find(
      (item) => item.id === values.selectedScheme,
    );
    if (selectedRepaymentType) {
      setSelectedSchemeRepaymentType(selectedRepaymentType.repaymentType);
    }
  }, [schemeListOptions, values.selectedScheme]);

  useEffect(() => {
    dispatch(
      transactionsActions.showTransactionFlow({
        crId: selectedPayload.id,
      }),
    );
  }, [dispatch, selectedPayload.id]);

  useEffect(() => {
    if (!isNil(isInsuranceUpsellRestricted) && !isInsuranceUpsellRestricted) {
      dispatch(
        activeLoansActions.getLMInsuranceDetails({
          crId: selectedPayload.id,
          userRole: "LOAN_MANAGER",
        }),
      );
    }
  }, []);

  const goldLoanDetailsMapping = useMemo(() => {
    if (ornamentsSummary) {
      const {grossWeight, newGoldValue} = ornamentsSummary;
      return [
        {
          title: t("ACTIVE_LOANS.GROSS_GOLD_WT"),
          value: `${grossWeight || "NA"} gm`,
        },
        {
          title: t("ACTIVE_LOANS.TOTAL_GOLD_VALUE"),
          value: numberWithCurrencyAndComma(newGoldValue),
        },

        {
          title: "Work Sector: ",
          value: workSector || "NA",
        },
      ];
    }
  }, [ornamentsSummary, t, workSector]);

  const lmRequestInsuranceDetailsMapping = useMemo(() => {
    if (lmRequestedInsuranceDetails) {
      const {gstInclusivePremium, totalInsuranceAmount} =
        lmRequestedInsuranceDetails ?? {};
      return [
        {
          title: "LM Insurance Amount:",
          value: numberWithCurrencyAndComma(totalInsuranceAmount),
        },
        {
          title: "LM Insurance Premium:",
          value: numberWithCurrencyAndComma(gstInclusivePremium),
        },
      ];
    }
  }, [lmRequestedInsuranceDetails]);

  const goldLoanSummaryFieldsMapping = useMemo(() => {
    if (
      effectiveLTV &&
      values.monthlyRoi &&
      values.selectedTenurePeriod &&
      selectedSchemeRepaymentType
    ) {
      const renderLtv = (ltvValue) => {
        if (!ltvValue) {
          return "0%";
        } else if (isBackMovement) {
          return `${ltvValue}%`;
        }
        return `${parseFloat(ltvValue).toFixed(2)}%`;
      };
      return [
        {
          title: "LTV:",
          value: renderLtv(effectiveLTV),
        },
        {
          title: "Annual ROI (After Rebate):",
          value: `${Number(values.monthlyRoi * 12).toFixed(2)} %` || "NA",
        },
        // {
        //   title: t("ACTIVE_LOANS.SUBVENTED_ROI"),
        //   value:
        //     `${Number(
        //       isCustomerRoiEditable
        //         ? values.customerRoi * 12
        //         : values.monthlyRoi * 12,
        //     ).toFixed(2)} %` || "NA",
        // },
        {
          title: t("ACTIVE_LOANS.REPAYMENT_TENURE"),
          value: `${values.selectedTenurePeriod} months` || "NA",
        },
        {
          title: t("ACTIVE_LOANS.REPAYMENT_TYPE"),
          value: selectedSchemeRepaymentType || "NA",
        },
      ];
    }
  }, [
    effectiveLTV,
    values.monthlyRoi,
    values.selectedTenurePeriod,
    values.customerRoi,
    selectedSchemeRepaymentType,
    t,
    isCustomerRoiEditable,
    isBackMovement,
  ]);

  //to get tenure period corresponding to selected scheme
  const selectedSchemeTenureOptions = useMemo(() => {
    if (values.selectedScheme) {
      const selectedSchemeTenure = schemeListOptions?.find(
        (item) => item.id === values.selectedScheme,
      );
      return selectedSchemeTenure?.eligibleTenureList;
    }
    return [];
  }, [schemeListOptions, values.selectedScheme]);

  /**
   * UI Helpers
   */

  //validating processing fee amount for max and min capping on it.

  const calculateProcessingPercentage = useMemo(() => {
    if (values.loanSanctionedAmount && values.processingFeeAmount) {
      const processingFeePercentage = Number(
        (values.processingFeeAmount / values.loanSanctionedAmount) * 100,
      ).toFixed(2);
      return processingFeePercentage;
    }
    return 0;
  }, [values.loanSanctionedAmount, values.processingFeeAmount]);

  const calculateInsurancePremium = useMemo(() => {
    if (insuranceAmount) {
      const insurancePremium = Number(
        (insuranceAmount * 0.0051 * 1.18).toFixed(2),
      );
      return insurancePremium;
    }
    return 0;
  }, [insuranceAmount]);

  const validateprocessingFeeAmount = useMemo(() => {
    if (processingFeeConfig && !processingFeeAmountInputDisabled) {
      const {
        maximumProcessingFeePercentage,
        minimumProcessingFeePercentage,
      } = processingFeeConfig;
      const errors = [];
      const minProcessingFees = Number(
        Number(minimumProcessingFeePercentage / 100) *
          Number(values.loanSanctionedAmount),
      );
      const maxProcessingFees =
        Number(maximumProcessingFeePercentage / 100) *
        Number(values.loanSanctionedAmount);

      if (Number(values.processingFeeAmount) < minProcessingFees) {
        errors.push(
          `Proccessing fee cannot be less than ${numberWithCurrencyAndComma(
            minProcessingFees,
          )}`,
        );
      }
      if (Number(values.processingFeeAmount) > Number(maxProcessingFees)) {
        errors.push(
          `Processing fee cannot be more than ${numberWithCurrencyAndComma(
            maxProcessingFees,
          )}`,
        );
      }
      return errors;
    }
    return [];
  }, [
    processingFeeAmountInputDisabled,
    processingFeeConfig,
    values.loanSanctionedAmount,
    values.processingFeeAmount,
  ]);

  const viewPdf = (link) => {
    window.open(link, "_blank");
  };

  console.log(
    errors.loanSanctionedAmount,
    touched.loanSanctionedAmount,
    "here",
  );
  const validateRoi = (key, value) => {
    if (Number(value) > 100) {
      setValidationErrors((prevState) => {
        return {
          ...prevState,
          [key]: "ROI cannot be greater than 100",
        };
      });
      return false;
    }
    if (Number(value) < 0) {
      setValidationErrors((prevState) => {
        return {
          ...prevState,
          [key]: "ROI cannot be less than 0",
        };
      });
      return false;
    }
    const decimalsAllowed = ["SHIVALIK", "PIRAMAL"].includes(
      states.payload.glResponse?.loanVendorName,
    )
      ? 2
      : 3;
    if (value.split(".")[1]?.length > decimalsAllowed) {
      setValidationErrors((prevState) => {
        return {
          ...prevState,
          [key]: `ROI cannot have more than ${decimalsAllowed} decimals for this lender`,
        };
      });
      return false;
    }
    setValidationErrors((prevState) => {
      return {
        ...prevState,
        [key]: "",
      };
    });
    return true;
  };

  // loading and error handling
  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return <div>{states.errorMessage}</div>;
  }

  return (
    <form>
      {payment !== null &&
        currentMileStone.data.milestone !== "CR_GOLD_LOAN_FRESH_GOLD_LOAN" && (
          <>
            <Typography variant="h5" fontWeight="bold">
              Takeover Payment Done
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="h6" color="gray" fontWeight="bold">
                {numberWithCurrencyAndComma(payment?.paymentAmount)}
              </Typography>
              <IconButton onClick={() => setIsRecoveryAmountRequired(true)}>
                <EditIcon />
              </IconButton>
            </Box>
            {isRecoveryAmountRequired && (
              <Box mt={2}>
                <IGTextField
                  value={recoveryAmountForBT}
                  type="number"
                  fullWidth
                  label="Please enter the Loan Recovery Amount"
                  variant="outlined"
                  required
                  onWheel={(event) => event.target.blur()}
                  onChange={(e) => setRecoveryAmountForBT(e.target.value)}
                />
                <Box mt={2}>
                  <IGSelect
                    fullWidth
                    placeholder="Please select Recovery Reason"
                    label="Please select Recovery Reason"
                    name="selectedReasonForRecovery"
                    value={selectedReasonForRecovery}
                    onChange={(ev) => {
                      setSelectedReasonForRecovery(ev.target.value);
                    }}
                    options={recoveryReasonOptions?.map((item) => ({
                      text: item?.label,
                      value: item?.value,
                    }))}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      <div className={classes.textContainer}>
        <Box mt={1} p={1}>
          <Typography variant="h5" fontWeight="bold">
            {t("ACTIVE_LOANS.GOLD_LOAN_DETAILS")}
          </Typography>
          {goldLoanDetailsMapping?.map((item) => (
            <Box display="flex" gap={1}>
              <Typography variant="h6" pt={0.5}>
                {item.title || "NA"}
              </Typography>
              <Typography variant="h6" pt={0.5} color="gray" fontWeight="bold">
                {item.value || "NA"}
              </Typography>
            </Box>
          ))}
        </Box>
      </div>

      {button &&
        states.payload.getNextJob &&
        isSameCreditManager &&
        !isBackMovement &&
        !isRenewalDetailsLoading && (
          <>
            <Box display="flex" mt={1} p={1} gap={1} alignItems="center">
              <Typography
                sx={{
                  width: "150px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                {t("ACTIVE_LOANS.SCHEME_TYPE")}
              </Typography>
              <IGSelect
                sx={{
                  width: "400px",
                }}
                fullWidth={false}
                name="selectedSchemeType"
                disabled={loanSchemeTypeDropDownDisabled}
                value={values.selectedSchemeType ?? ""}
                placeholder="Please select scheme type"
                label="Please select scheme type"
                onChange={(ev) => {
                  setFieldValue("selectedSchemeType", ev.target.value);
                }}
                options={schemeTypeOptions?.map((item) => ({
                  text: item,
                  value: item,
                }))}
              />
            </Box>
            <Box display="flex" mt={1} p={1} gap={1} alignItems="center">
              <Typography
                sx={{
                  width: "150px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                {t("ACTIVE_LOANS.SELECT_SCHEME")}
              </Typography>
              <IGSelect
                sx={{
                  width: "400px",
                }}
                fullWidth={false}
                placeholder="Please select scheme"
                label="Please select scheme"
                onBlur={handleBlur}
                disabled={loanSchemeDropDownDisabled}
                name="selectedScheme"
                value={values.selectedScheme ?? ""}
                onChange={(ev) => {
                  setFieldValue("selectedScheme", ev.target.value);
                  setFieldValue("selectedTenurePeriod", "");
                }}
                options={schemeListOptions?.map((item) => ({
                  text: item.loanSchemeName,
                  value: item.id,
                }))}
              />
            </Box>
            <Box display="flex" mt={1} p={1} gap={1} alignItems="center">
              <Typography
                sx={{
                  width: "150px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                {t("ACTIVE_LOANS.SELECT_TENURE")}
              </Typography>
              <IGSelect
                sx={{
                  width: "400px",
                }}
                fullWidth={false}
                placeholder="Please select scheme tenure"
                label="Please select scheme tenure"
                name="selectedTenurePeriod"
                onBlur={handleBlur}
                disabled={tenureDropDownDisabled}
                value={values.selectedTenurePeriod ?? ""}
                onChange={(ev) => {
                  setFieldValue("selectedTenurePeriod", ev.target.value);
                }}
                options={selectedSchemeTenureOptions?.map((item) => ({
                  text: item,
                  value: item,
                }))}
              />
            </Box>
            <Box display="flex" mt={2} p={1} gap={1} alignItems="center">
              <Typography
                sx={{
                  width: "150px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                Monthly ROI (After Rebate):
              </Typography>
              <IGTextField
                fullWidth={false}
                type="number"
                onBlur={handleBlur}
                label="Monthly ROI (After Rebate)"
                onWheel={(event) => event.target.blur()}
                name="monthlyRoi"
                disabled={bankRoiInputDisabled}
                value={values.monthlyRoi ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">/month</InputAdornment>
                  ),
                }}
                onChange={(ev) => {
                  validateRoi("monthlyInterestRate", ev.target.value);
                  setFieldValue("monthlyRoi", ev.target.value);
                }}
                error={validationErrors.monthlyInterestRate}
                touched={validationErrors.monthlyInterestRate}
                helperText={validationErrors.monthlyInterestRate}
              />
            </Box>
            {isSubventionActive && (
              <Box display="flex" mt={2} p={1} gap={1} alignItems="center">
                <Typography
                  sx={{
                    width: "150px",
                  }}
                  fontWeight="bold"
                  variant="h6"
                >
                  {t("ACTIVE_LOANS.CUSTOMER_ROI")}
                </Typography>
                <IGTextField
                  type="number"
                  disabled={!isCustomerRoiEditable || customerRoiDisabled}
                  label="Customer ROI"
                  fullWidth={false}
                  name="customerRoi"
                  onBlur={handleBlur}
                  value={
                    isCustomerRoiEditable
                      ? values.customerRoi
                      : values.monthlyRoi ?? ""
                  }
                  onWheel={(event) => event.target.blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">/month</InputAdornment>
                    ),
                  }}
                  onChange={(ev) => {
                    validateRoi("subventedInterestRate", ev.target.value);
                    setFieldValue("customerRoi", ev.target.value);
                    setFieldTouched("customerRoi", true);
                  }}
                  error={validationErrors.subventedInterestRate}
                  touched={validationErrors.subventedInterestRate}
                  helperText={validationErrors.subventedInterestRate}
                />
              </Box>
            )}

            <Box mt={2}>
              <IGTextField
                value={values.loanSanctionedAmount ?? ""}
                type="number"
                disabled={sanctionAmountDisabled}
                fullWidth
                label="Please enter the total sanctioned Gold loan amount(INR)"
                variant="outlined"
                required
                onFocus={() => setFieldTouched("loanSanctionedAmount", true)}
                onBlur={handleBlur}
                onChange={(e) => {
                  const {value} = e.target;
                  if (value < ornamentsSummary.newGoldValue) {
                    setFieldValue("loanSanctionedAmount", e.target.value);
                  }
                }}
                error={Boolean(
                  errors.loanSanctionedAmount && touched.loanSanctionedAmount,
                )}
                helperText={
                  errors.loanSanctionedAmount && touched.loanSanctionedAmount
                    ? errors.loanSanctionedAmount
                    : ""
                }
                touched={touched.loanSanctionedAmount}
              />
            </Box>

            <Box mt={2}>
              <IGTextField
                value={values.disbursedAmount ?? ""}
                type="number"
                disabled={customerDisbursedAmountDisabled}
                onWheel={(event) => event.target.blur()}
                label="Please enter the amount that customer will receive in bank account(INR)"
                variant="outlined"
                required
                fullWidth
                onFocus={() => setFieldTouched("disbursedAmount", true)}
                onBlur={handleBlur}
                error={Boolean(
                  errors.disbursedAmount && touched.disbursedAmount,
                )}
                helperText={
                  errors.disbursedAmount && touched.disbursedAmount
                    ? errors.disbursedAmount
                    : ""
                }
                touched={touched.disbursedAmount}
                onChange={(e) => {
                  const {value} = e.target;
                  if (value?.length > 9) {
                    return;
                  }
                  setFieldValue("disbursedAmount", e.target.value);
                }}
              />
            </Box>

            <Box mt={2.5}>
              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                gap={2}
              >
                <IGTextField
                  sx={{
                    width: "300px",
                  }}
                  name="processingFeeAmount"
                  value={values.processingFeeAmount ?? ""}
                  required
                  className={classes.subText}
                  type="number"
                  onBlur={handleBlur}
                  disabled={
                    !values.loanSanctionedAmount ||
                    processingFeeAmountInputDisabled
                  }
                  onWheel={(event) => event.target.blur()}
                  error={validateprocessingFeeAmount.length > 0}
                  label="Please Enter the processing fees amount"
                  onChange={(e) =>
                    setFieldValue("processingFeeAmount", e.target.value)
                  }
                />
                <Typography
                  color="#3C67B5"
                  sx={{width: "100px"}}
                  fontWeight={800}
                >{`${calculateProcessingPercentage} %`}</Typography>
              </Box>
              {!processingFeeAmountInputDisabled && (
                <Box>
                  {validateprocessingFeeAmount.map((error) => {
                    return (
                      <FormHelperText key={error} error>
                        <Typography color="error.main">{error}</Typography>
                      </FormHelperText>
                    );
                  })}
                </Box>
              )}
            </Box>

            {isLoanAmountSentToCustomerForBTRequired && (
              <>
                <Box mt={2.5}>
                  <IGTextField
                    sx={{
                      width: "300px",
                    }}
                    name="recoveryForTakeoverAmount"
                    value={values.recoveryForTakeoverAmount ?? ""}
                    disabled={isIgRecoveryAmountFromCustomerForBtInputDisabled}
                    required
                    type="number"
                    onBlur={handleBlur}
                    onFocus={() =>
                      setFieldTouched("recoveryForTakeoverAmount", true)
                    }
                    onWheel={(event) => event.target.blur()}
                    label="Please enter the recovery for takeover amount"
                    onChange={(e) =>
                      setFieldValue("recoveryForTakeoverAmount", e.target.value)
                    }
                    error={Boolean(
                      errors.recoveryForTakeoverAmount &&
                        touched.recoveryForTakeoverAmount,
                    )}
                    helperText={
                      errors.recoveryForTakeoverAmount &&
                      touched.recoveryForTakeoverAmount
                        ? errors.recoveryForTakeoverAmount
                        : ""
                    }
                    touched={touched.recoveryForTakeoverAmount}
                  />
                </Box>
                <Box mt={2.5}>
                  <IGTextField
                    sx={{
                      width: "300px",
                    }}
                    name="netTopUpToCustomer"
                    value={values.netTopUpToCustomer ?? ""}
                    disabled={isNetTopUpForCustomerInputDisabled}
                    required
                    type="number"
                    onBlur={handleBlur}
                    onFocus={() => setFieldTouched("netTopUpToCustomer", true)}
                    onWheel={(event) => event.target.blur()}
                    label="Please enter the net top Up to customer"
                    onChange={(e) =>
                      setFieldValue("netTopUpToCustomer", e.target.value)
                    }
                    error={Boolean(
                      errors.netTopUpToCustomer && touched.netTopUpToCustomer,
                    )}
                    helperText={
                      errors.netTopUpToCustomer && touched.netTopUpToCustomer
                        ? errors.netTopUpToCustomer
                        : ""
                    }
                    touched={touched.netTopUpToCustomer}
                  />
                </Box>
              </>
            )}

            <Divider
              sx={{
                padding: "1rem",
              }}
            />

            {isAgriLoanAllowedWithoutDoc && (
              <>
                {landAcquiProof?.length === 0 && (
                  <Box my={2}>
                    <IGFileUpload
                      text="Attach Land Acquistion Proof"
                      value={landAcquiImage}
                      onChange={(fileList) => {
                        setLandAcquiImage(fileList);
                      }}
                      draggable={false}
                      inputProps={{
                        accept:
                          "image/png, image/jpeg, image/jpg, application/pdf",
                      }}
                    />
                    <Box textAlign="right" mt={2}>
                      <IGButton
                        loading={
                          isMakerDocUploadLoading[
                            "LAND_ACQUAISATION_PROOF_FOR_AGRI_LOAN"
                          ]?.isLoading
                        }
                        onClick={() =>
                          uploadInsuranceOptOutScreenShot(
                            landAcquiImage,
                            "LAND_ACQUAISATION_PROOF_FOR_AGRI_LOAN",
                          )
                        }
                      >
                        Upload
                      </IGButton>
                    </Box>
                  </Box>
                )}
                <Box mt={2}>
                  {landAcquiProof.length > 0 &&
                    landAcquiProof.map((doc) => (
                      <>
                        <Typography variant="body1" my={1} fontWeight="bold">
                          {doc?.documentType.replaceAll("_", " ")}
                        </Typography>
                        {supportedPdfTypes.includes(doc.fileType) ? (
                          <IGButton
                            color="golden"
                            size="small"
                            onClick={() => viewPdf(doc.fileUrl)}
                          >
                            <b>View</b> &nbsp;
                            <ArrowUpFromBracket />
                          </IGButton>
                        ) : (
                          <IGDocumentViewer
                            key={doc.documentType}
                            document={doc}
                          />
                        )}
                      </>
                    ))}
                </Box>
                <Divider
                  sx={{
                    padding: "1rem",
                  }}
                />

                {lenderApprovalImage?.length === 0 && (
                  <Box my={2}>
                    <IGFileUpload
                      text="Attach Lender approval image"
                      value={lenderApprovalScreenshot}
                      onChange={(fileList) => {
                        setLenderApprovalScreenshot(fileList);
                      }}
                      draggable={false}
                      inputProps={{
                        accept:
                          "image/png, image/jpeg, image/jpg, application/pdf",
                      }}
                    />
                    <Box textAlign="right" mt={2}>
                      <IGButton
                        loading={
                          isMakerDocUploadLoading[
                            "LENDER_APPROVAL_IMAGE_FOR_AGRI_LOAN"
                          ]?.isLoading
                        }
                        onClick={() =>
                          uploadInsuranceOptOutScreenShot(
                            lenderApprovalScreenshot,
                            "LENDER_APPROVAL_IMAGE_FOR_AGRI_LOAN",
                          )
                        }
                      >
                        Upload
                      </IGButton>
                    </Box>
                  </Box>
                )}
                <Box mt={2}>
                  {lenderApprovalImage?.length > 0 &&
                    lenderApprovalImage.map((doc) => (
                      <>
                        <Typography variant="h6" my={1} fontWeight="bold">
                          {doc?.documentType.replaceAll("_", " ")}
                        </Typography>
                        {supportedPdfTypes.includes(doc.fileType) ? (
                          <IGButton
                            color="golden"
                            size="small"
                            onClick={() => viewPdf(doc.fileUrl)}
                          >
                            <b>View</b> &nbsp;
                            <ArrowUpFromBracket />
                          </IGButton>
                        ) : (
                          <IGDocumentViewer
                            key={doc.documentType}
                            document={doc}
                          />
                        )}
                      </>
                    ))}
                </Box>
                <Divider
                  sx={{
                    padding: "1rem",
                  }}
                />
              </>
            )}

            {!isInsuranceUpsellRestricted && (
              <Box mt={2} p={1}>
                <Typography variant="h6" fontWeight="bold">
                  Insurance Details
                </Typography>

                {lmRequestInsuranceDetailsMapping?.map((item) => (
                  <Box display="flex" gap={1}>
                    <Typography variant="h6" pt={0.5}>
                      {item.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      pt={0.5}
                      color="gray"
                      fontWeight="bold"
                    >
                      {item.value}
                    </Typography>
                  </Box>
                ))}

                <Box mt={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    textAlign="center"
                    gap={3}
                  >
                    <IGTextField
                      sx={{
                        width: "300px",
                      }}
                      name="insuranceAmount"
                      value={insuranceAmount ?? ""}
                      type="number"
                      disabled={isInsuranceOptedOut}
                      variant="outlined"
                      label="Please Enter Insurance Amount"
                      onChange={(e) => setInsuranceAmount(e.target.value)}
                    />
                    <Typography
                      color="#3C67B5"
                      fontWeight={800}
                    >{`Estimated Premium: ${numberWithCurrencyAndComma(
                      calculateInsurancePremium,
                    )} `}</Typography>
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isInsuranceOptedOut}
                          onChange={() =>
                            setIsInsuranceOptedOut(!isInsuranceOptedOut)
                          }
                        />
                      }
                      label="Opt Out for insurance"
                    />

                    {isInsuranceOptedOut && insuranceDocument.length === 0 && (
                      <Box my={2}>
                        <IGFileUpload
                          text="Attach Approval Screenshots*"
                          value={approvalScreenshots}
                          onChange={(fileList) => {
                            setApprovalScreenshots(fileList);
                          }}
                          draggable={false}
                          inputProps={{
                            accept: "image/png, image/jpeg, image/jpg",
                          }}
                        />
                        <Box textAlign="right" mt={2}>
                          <IGButton
                            loading={
                              isMakerDocUploadLoading[
                                "INSURANCE_APPROVAL_DOCUMENT"
                              ]?.isLoading
                            }
                            disabled={!isInsuranceOptedOut}
                            onClick={() =>
                              uploadInsuranceOptOutScreenShot(
                                approvalScreenshots,
                                "INSURANCE_APPROVAL_DOCUMENT",
                              )
                            }
                          >
                            Upload
                          </IGButton>
                        </Box>
                      </Box>
                    )}
                    {insuranceDocument.length > 0 &&
                      insuranceDocument.map((doc) => (
                        <IGDocumentViewer
                          key={doc.documentType}
                          document={doc}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            )}

            <Box mt={1.5}>
              <IGButton
                sx={{
                  padding: "1rem",
                }}
                size="large"
                fullWidth
                color="golden"
                onClick={() => setIsGoldLoanSummaryModal(true)}
              >
                {tButton("PROCEED")}
              </IGButton>
            </Box>

            <Box mt={1}>
              <IGButton
                sx={{
                  padding: "1rem",
                }}
                size="large"
                fullWidth
                color="inherit"
                onClick={onClickBackHandler}
              >
                {tButton("GO_BACK")}
              </IGButton>
            </Box>
          </>
        )}

      {/*  rendering Gold loan summary dialog for details */}

      <IGDialog
        open={isGoldLoanSummaryModalOpen}
        onClose={() => setIsGoldLoanSummaryModal(false)}
        title={tDialogTitle("GOLD_LOAN_SUMMARY")}
        hideFooter
      >
        <Grid container spacing={1} bgcolor="#faf6ed" p={0.5}>
          {goldLoanSummaryFieldsMapping?.map((item) => (
            <Grid item xs={6}>
              <Stack direction="column">
                <Typography fontWeight="bold">{item.title}</Typography>
                <Typography>{item.value}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Box display="flex" gap={1} pt={1}>
          <Typography fontWeight="bold">
            {t("ACTIVE_LOANS.SANCTIONED_LOAN_AMOUNT")}
          </Typography>
          <Typography>
            {numberWithCurrencyAndComma(Number(values.loanSanctionedAmount))}
          </Typography>
        </Box>
        <Box display="flex" gap={1} pt={1}>
          <Typography fontWeight="bold">
            {t("ACTIVE_LOANS.AMOUNT_DISBURSED_CUSTOMER")}
          </Typography>
          <Typography>
            {numberWithCurrencyAndComma(Number(values.disbursedAmount))}
          </Typography>
        </Box>

        <Box mt={2} display="flex" justifyContent="space-between">
          <IGButton
            color="inherit"
            onClick={() => setIsGoldLoanSummaryModal(false)}
          >
            {tButton("EDIT_DETAILS")}
          </IGButton>
          <IGButton
            onClick={handleSubmit}
            loading={confirmBtnLoading}
            color="golden"
          >
            {tButton("CONFIRM")}
          </IGButton>
        </Box>
      </IGDialog>
    </form>
  );
};

export default GoldLoanDetails;
