import * as yup from "yup";
import {ParamsInterface} from "./index";
import {getValidTime} from "_metronic/utils/moment";
import moment from "moment-timezone";
import {DateSchema, ValidationError} from "yup";
import {useIGTranslation} from "../infra/plugins/i18n";

interface DateValidationOptions {
  required?: boolean | string;
  min?: Date | number | moment.Moment | ParamsInterface<Date | number>;
  max?: Date | number | moment.Moment | ParamsInterface<Date | number>;
}

const ERRORS_MAPPING: Record<keyof DateValidationOptions, string> = {
  required: "REQUIRED",
  max: "MAX_DATE",
  min: "MIN_DATE",
};

// Yup only supports Date type
// We need to check for other types as well (epoch, Date, moment)
// We are overriding default "typeError" validation for date to achieve that (this is called by default)
yup.addMethod<DateSchema>(yup.date, "typeError", function () {
  return this.transform((value: DateSchema, input: string) => {
    const parsed = moment(input);
    return parsed.isValid()
      ? parsed.toDate()
      : new ValidationError("Date is not valid", value, input, "typeError");
  });
});

const useDateValidations = (
  options: DateValidationOptions = {},
  fieldName = "This field",
): yup.DateSchema => {

  const {tErrors} = useIGTranslation();

  let validator = yup.date();

  if (!options) {
    return validator;
  }

  if (options.min) {
    const ruleValue = options.min;
    const valToCheck = typeof ruleValue === "object" && !(ruleValue instanceof Date) && !(moment.isMoment(ruleValue))
      ? ruleValue.value
      : ruleValue;
    const message = typeof ruleValue === "object" && !(ruleValue instanceof Date) && !(moment.isMoment(ruleValue))
      ? ruleValue.message
      : tErrors(`${ERRORS_MAPPING.min}`, {
        field: fieldName,
        min: getValidTime(valToCheck).format("DD MMM, YYYY"),
      });

    validator = validator.test(
      "minDate",
      "",
      function (value, context) {
        const endDate = getValidTime(valToCheck).startOf("day");
        if (getValidTime(value).startOf("day").isSameOrBefore(endDate)) {
          return context.createError({
            message: message,
          });
        }
        return true;
      },
    );
    delete options.min;
  }

  if (options.max) {
    const ruleValue = options.max;
    const valToCheck = typeof ruleValue === "object" && !(ruleValue instanceof Date) && !(moment.isMoment(ruleValue))
      ? ruleValue.value
      : ruleValue;
    const message = typeof ruleValue === "object" && !(ruleValue instanceof Date) && !(moment.isMoment(ruleValue))
      ? ruleValue.message
      : tErrors(`${ERRORS_MAPPING.max}`, {
        field: fieldName,
        max: getValidTime(valToCheck).format("DD MMM, YYYY"),
      });

    validator = validator.test(
      "maxDate",
      "",
      function (value, context) {
        const startDate = getValidTime(valToCheck).startOf("day");
        if (getValidTime(value).startOf("day").isSameOrAfter(startDate)) {
          return context.createError({
            message: message,
          });
        }
        return true;
      },
    );
    delete options.max;
  }

  return validator;
};

export default useDateValidations;