import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navButtonStyle: {
    height: "3rem",
    width: "3rem",
    fontWeight: "500",
    margin: "1rem",
    border: "none",
    borderRadius: "4px",
    background: "white",
  },
  navTextStyle: {
    fontSize: "1rem",
    fontWeight: "400",
  },
  navStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "white",
  },
  headerStyle: {
    fontSize: "12px",
    color: "rgba(18, 18, 18, 0.5)",
    padding: "0.5rem",
    textTransform: "uppercase",
  },
  textStyle: {
    fontSize: "14px",
    color: "#121212",
    paddingLeft: "0.5rem",
    fontWeight: "500",
    whiteSpace: "break-spaces",
  },
  containerStyle: {
    width: "500px",
    padding: "1rem",
  },
  mainHeader: {
    fontSize: "1.5rem",
    lineHeight: 1.5,
    letterSpacing: "2px",
    padding: "1rem 1rem 0.5rem 0.5rem",
  },
  button: {
    height: "4rem",
    width: "100%",
    background: "#ce9f29",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
    fontFamily: "0.875rem",
    fontWeight: "500",
    textAlign: "center",
    margin: "0.5rem",
  },
  yes: {
    background: "#ce9f29",
    border: "none",
  },
  no: {
    background: "white",
    border: "solid 1px #e2e2e2",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputItem: {
    margin: "0.5rem",
    borderRadius: "4px",
    border: "solid 0.5px #e2e2e2",
    background: "#ffffff",
    fontSize: "14px",
    paddingLeft: "6px",
    color: "rgba(18, 18, 18, 0.7)",
    height: "32px",
  },
  checkBoxContainer: {
    margin: "0.5rem",
  },
  checkBox: {
    height: "18px",
    width: "18px",
  },
  root: {
    display: "flex",
    flex: 1,
    height: "100%",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "30vh",
  },
  loader: {
    width: "40px",
    height: "40px",
  },

  //remark form
  remarkFormContainer: {
    height: "252px",
    width: "444px",
  },
  remarkTextArea: {
    resize: "none",
    width: "100%",
  },
  remarkButton: {
    margin: "0px",
    marginTop: "12px",
  },
  remarkHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  remarkHeaderName: {
    height: "24px",
    fontSize: "20px",
    marginBottom: "24px",
    fontWeight: 500,
    color: "#121212",
  },
  crossButton: {
    border: "1px solid black",
    fontSize: "12px",
    borderRadius: "50%",
    height: "18px",
    width: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    fontWeight: 500,
    cursor: "pointer",
  },
  drawerHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
  },

  filterContainer: {
    display: "flex",
    alignItems: "center",
    padding: "2rem 10rem",
    justifyContent: "space-around",
    background: "rgba(122, 134, 203, 0.1) ",
    // marginRight: "8px",
  },
  jobTypeContainer: {
    width: "160px",
  },

  tableRow: {
    cursor: "pointer",
    "&:hover": {
      background: "rgba(206,159,41,0.08)",
    },
  },
  selectedRow: {
    background: "rgba(206, 159,41, 0.08)",
    border: "1px solid #ce9f29",
  },
  link: {
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    marginTop: "0.5rem",
    color: "#ce9f29",
    cursor: "pointer",
  },
}));

export default useStyles;
