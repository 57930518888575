import {goldApiService} from "app/infra/plugins/axios";
import {RefferalConfigResources, UpdateRefferalConfigProps, UpdateRefferalImageProps} from "./types";

const REFFERAL_CONFIG_ENDPOINTS: RefferalConfigResources = {
  FETCH_REFFERAL_CONFIG: {
    URL: "admin/referral/program",
    METHOD: "GET",
  },
  UPDATE_REFFERAL_CONFIG: {
    URL: "admin/referral/update-program/:id",
    METHOD: "PUT",
  },
  UPDATE_REFFERAL_IMAGE: {
    URL: "admin/referral/update-program-image",
    METHOD: "POST",
  },
};

const refferalConfigApi = {
  fetchReferralConfig: async () => {
    const {response, error} = await goldApiService({
      resource: REFFERAL_CONFIG_ENDPOINTS.FETCH_REFFERAL_CONFIG,
    });

    if (error) {
      return {
        error: error,
        referralConfig: [],
      };
    }

    return {
      error: null,
      referralConfig: response,
    };
  },
  updateRefferalConfig: async (
    id: number,
    payload: UpdateRefferalConfigProps,
  ) => {
    const {error} = await goldApiService({
      resource: REFFERAL_CONFIG_ENDPOINTS.UPDATE_REFFERAL_CONFIG,
      options: {
        pathVars: {
          id,
        },
        data: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error: error,
      };
    }

    return {
      error: null,
    };
  },
  updateRefferalImage: async (payload: UpdateRefferalImageProps) => {
    const {error} = await goldApiService({
      resource: REFFERAL_CONFIG_ENDPOINTS.UPDATE_REFFERAL_IMAGE,
      options: {
        data: payload,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (error) {
      return {
        error: error,
      };
    }

    return {
      error: null,
    };
  },
};

export default refferalConfigApi;
export {REFFERAL_CONFIG_ENDPOINTS};
