import React, {useState, useEffect, useRef, useCallback} from "react";
import styles from "../styles";
import Modal from "../../../../../../components/Modal";
import ViewOrnaments from "./ViewOrnaments";
import getOrnamentSummary from "../../../../../../../api/business/getOrnamentSummary";
import {CircularProgress} from "@mui/material";
import numberWithCurrencyAndComma from "../../../../../../../lib/numberWithCurrencyAndComma";
import getFinness from "../../../../../../../api/business/getFiness";
import ReasonToRejectModal from "../CommonComponents/Modal/RequestApproval";
import getNextJob from "../../../../../../../api/business/getNextJob";
import getDocumentAPI from "../../../../../../../api/business/getDocument";
import ImageLink from "../CommonComponents/ImageLink";
import getRejectionReason from "../../../../../../../api/activeLoan/getRejectReason";
import getCustomerLoanDemand from "api/activeLoan/getCustomerLoanDemand";
import CMOrnaments from "./OrnamentsValuation";
import {IGButton} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";

const GoldValuation = ({
  selectedPayload,
  isSameCreditManager,
  otherRequestTimer,
  isBackMovement,
  currentMileStone,
  getMileStoneOfCr,
}) => {
  const dispatch = useAppDispatch();
  const isLocker =
    currentMileStone.data.milestone === "CR_GOLD_LOCKER_PICKUP_GOLD_VALUATION";
  const classes = styles();
  const isOrnamentCheckerRequired = useAppSelector(
    activeLoansSelectors.getOrnamentCheckerFlag,
  );
  const [viewModal, setViewModal] = useState(false);
  const [reasons, setReasons] = useState("");

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      ornamentSummary: null,
      glResponse: null,
      finness: null,
      document: null,
    },
    errorMessage: "",
  });
  const [requestUpdateToLM, setRequestUpdateToLM] = useState(false);

  const counter = useRef(0);

  const getResponse = useCallback(async () => {
    try {
      if (
        currentMileStone.data.milestone ===
        "CR_GOLD_LOCKER_PICKUP_GOLD_VALUATION"
      ) {
        const [response, finnessResponse, documentResponse] = await Promise.all(
          [
            getOrnamentSummary({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
            }),
            getFinness({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
            }),
            getDocumentAPI({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              documentType: [
                "GOLD_VALUATION_SHEET",
                "CUSTOMER_PHOTO_WITH_GOLD",
                "CUSTOMER_IMAGE",
              ],
            }),
          ],
        );

        if (
          response.statusCode === 200 &&
          finnessResponse.statusCode === 200 &&
          documentResponse.statusCode === 200
        ) {
          setStates({
            loading: false,
            error: false,
            payload: {
              ornamentSummary: response.payload,
              glResponse: null,
              finness: finnessResponse.payload,
              document: documentResponse.payload,
            },
            errorMessage: "",
          });
        } else {
          setStates({
            loading: false,
            error: true,
            payload: {
              glResponse: null,
              ornamentSummary: null,
              finness: null,
              document: null,
            },
            errorMessage:
              response?.error?.toString() ||
              finnessResponse?.error?.toString() ||
              documentResponse?.error?.toString(),
          });
        }
      } else {
        const [
          response,
          glResponse,
          finnessResponse,
          documentResponse,
        ] = await Promise.all([
          getOrnamentSummary({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
          }),
          getCustomerLoanDemand({
            crId: selectedPayload.id,
          }),
          getFinness({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
          }),
          getDocumentAPI({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
            documentType: [
              "GOLD_VALUATION_SHEET",
              "CUSTOMER_PHOTO_WITH_GOLD",
              "CUSTOMER_IMAGE",
            ],
          }),
        ]);

        if (response.statusCode === 200) {
          setStates({
            loading: false,
            error: false,
            payload: {
              ornamentSummary: response.payload,
              glResponse: glResponse.response,
              finness: finnessResponse.payload,
              document: documentResponse.payload,
            },
            errorMessage: "",
          });
        } else {
          setStates({
            loading: false,
            error: true,
            payload: {
              glResponse: null,
              ornamentSummary: null,
              finness: null,
              document: null,
            },
            errorMessage:
              response?.error?.toString() ||
              glResponse?.error?.toString() ||
              finnessResponse?.error?.toString(),
          });
        }
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: {
          glResponse: null,
          ornamentSummary: null,
          finness: null,
        },
        errorMessage: "Something went wrong",
      });
    }
  }, [currentMileStone.data.milestone, selectedPayload.id]);

  const fetchRejectionReasons = useCallback(async () => {
    const {payload, error, statusCode} = await getRejectionReason({
      accessToken: localStorage.getItem("authToken"),
    });

    if (statusCode === 200) {
      setReasons(payload);
    } else {
      setReasons({
        status: true,
        message: error || "Error while Updating",
      });
    }
  }, []);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      (async () => {
        getResponse();
        fetchRejectionReasons();
      })();
    }
  }, [fetchRejectionReasons, getResponse, selectedPayload.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      getResponse();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getResponse, otherRequestTimer]);

  const onViewClickHandler = () => {
    setViewModal(!viewModal);
  };

  const [nextJobStates, setNextJobStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const counters = useRef(0);

  const getNextJobApi = useCallback(async () => {
    try {
      setNextJobStates({
        loading: true,
        error: false,
        payload: null,
      });
      const response = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode === 200) {
        setNextJobStates({
          loading: false,
          error: false,
          payload: response.payload,
          errorMessage: "",
        });
      } else {
        setNextJobStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: response.error,
        });
      }
    } catch (error) {
      setNextJobStates({
        loading: false,
        error: true,
        payload: null,
        errorMessage: "Something went wrong",
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    (async () => {
      if (counters.current === 0 && !isLocker) {
        counters.current = 1;
        getNextJobApi();
      }
    })();
  }, [
    getNextJobApi,
    isLocker,
    selectedPayload.id,
    selectedPayload.milestone,
    selectedPayload.type,
  ]);

  useEffect(() => {
    if (!isLocker) {
      const timer = setInterval(() => {
        getNextJobApi();
      }, otherRequestTimer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [getNextJobApi, isLocker, otherRequestTimer]);

  useEffect(() => {
    dispatch(
      activeLoansActions.getInsuranceFeatureFlag({
        crId: selectedPayload.id,
      }),
    );
  }, [
    dispatch,
    selectedPayload.id,
  ]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const {finness} = states.payload;
  const ornaments = states.payload.ornamentSummary;
  const {glResponse} = states.payload;

  const twentyTwoFinness = finness
    ? finness?.find((e) => e.fineness === "22K")
    : null;

  const liveRate = twentyTwoFinness ? twentyTwoFinness.liveGoldRate : 0;

  const crJobId = nextJobStates.payload?.id;

  const {document} = states.payload;

  const goldValuationSheet = document?.find(
    (e) => e.documentType === "GOLD_VALUATION_SHEET",
  );

  const customerPhotoWithGold = document?.find(
    (e) => e.documentType === "CUSTOMER_PHOTO_WITH_GOLD",
  );

  const customerPhoto = document?.find(
    (e) => e.documentType === "CUSTOMER_IMAGE",
  );

  return (
    <>
      {/* <ImageLink
        {...{
          name: "Gold Valuation Sheet",
          image: goldValuationSheet,
        }}
      /> */}
      {customerPhotoWithGold && (
        <ImageLink
          {...{
            name: "Customer Photo With Ornament",
            image: customerPhotoWithGold,
          }}
        />
      )}
      {customerPhoto && (
        <ImageLink
          {...{
            name: "Customer Image",
            image: customerPhoto,
          }}
        />
      )}

      <div className={classes.textContainer}>
        <div
          className={classes.subText}
        >{`Live 22K Price @100%: ${numberWithCurrencyAndComma(
          liveRate,
        )}/gm`}</div>

        <div
          className={classes.subText}
        >{`Live 22K Price @75%: ${numberWithCurrencyAndComma(
          (liveRate * 75) / 100,
        )}/gms`}</div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>LTV INFORMATION</div>

        <div
          className={classes.subText}
        >{`Gross Ornament Weight: ${ornaments.grossWeight} gms`}</div>

        <div
          className={classes.subText}
        >{`Maker Gold Value: ${numberWithCurrencyAndComma(
          ornaments.goldValue,
        )}`}</div>

        {isOrnamentCheckerRequired && (
          <div
            className={classes.subText}
          >{`Checker Gold Value: ${numberWithCurrencyAndComma(
            ornaments.newGoldValue,
          )}`}</div>
        )}

        <div
          className={classes.subText}
        >{`Net Gold Weight (Maker): ${ornaments.netWeight} gms`}</div>

        {isOrnamentCheckerRequired && (
          <div
            className={classes.subText}
          >{`Net Gold Weight (Checker): ${ornaments.newNetWeight} gms`}</div>
        )}

        {glResponse && (
          <div
            className={classes.subText}
          >{`Loan Requested Amount: ${numberWithCurrencyAndComma(
            glResponse?.loanAmount,
          )}`}</div>
        )}
      </div>

      {/* {selectedPayload.milestone !== "COMPLETED" &&
        selectedPayload.milestone !== "CLOSED" &&
        selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
        selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
          <div className={classes.textContainer}>
            <div className={classes.text}>SCHEMES</div>

            <div
              className={classes.subText}
            >{`LM Requested Scheme: ${glResponse?.schemeType}`}</div>
          </div>
        )}

      <div className={classes.textContainer}>
        <div
          className={classes.subText}
        >{`Tenure: ${glResponse?.tenureInMonth} Months`}</div>
      </div> */}

      <Modal
        open={requestUpdateToLM}
        setOpen={setRequestUpdateToLM}
        width="90%"
      >
        <ReasonToRejectModal
          {...{
            getMileStoneOfCr,
            getNextJobApi,
            crJobId,
            selectedPayload,
            setRejectModal: setRequestUpdateToLM,
            status: "FAILED",
            getNextJOb: getNextJobApi,
            reasons,
          }}
        />
      </Modal>

      {viewModal && (
        <CMOrnaments
          isOrnamentsDialogOpen={viewModal}
          onViewClickHandler={onViewClickHandler}
          crId={selectedPayload.id}
          crJobId={crJobId}
          isVisible={
            isSameCreditManager &&
            !isBackMovement &&
            currentMileStone.data.milestone !==
              "CR_GOLD_LOCKER_PICKUP_GOLD_VALUATION"
          }
          getMileStoneOfCr={getMileStoneOfCr}
          selectedPayload={selectedPayload}
        />
      )}

      <IGButton color="golden" fullWidth onClick={onViewClickHandler}>
        VIEW ORNAMENT DETAILS
      </IGButton>

      {crJobId && (
        <IGButton
          sx={{
            marginTop: "1rem",
          }}
          color="inherit"
          fullWidth
          onClick={() => {
            setRequestUpdateToLM(true);
          }}
        >
          SEND UPDATE TO LM
        </IGButton>
      )}
    </>
  );
};

export default GoldValuation;
