import React, {useEffect, useState, useCallback} from "react";
import Heading from "app/pages/home/GoldLoan/Lead/components/Heading";
import styles from "../../styles";
import RowContainer from "../../NewUploadKYC/styles/RowContainer";
import ColumnContainer from "../../NewUploadKYC/styles/ColumnContainer";
import SubTitle from "../../NewUploadKYC/styles/SubTitle";
import MiniTitle from "../../NewUploadKYC/styles/MiniTitle";
import Container from "../../NewUploadKYC/styles/Container";
import {
  FormControl,
  TextField,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import {
  Divider,
  Checkbox,
  IconButton,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import getLenderDetails from "api/business/getLenderDetails";

import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import IconWrapper from "../../NewUploadKYC/KYCDetails/styles/IconWrapper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import checkerValidation from "api/business/checkerValidation";
import {useSnackBar} from "app/store/hooks";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import {IGButton, IGFileUpload} from "app/components";
import getProcessingFeeConfig from "api/activeLoan/getProcessingFeeConfig";
import postLoanApprovalEmail from "api/activeLoan/sendApprovalEmail";

const PersonalLoanChecker = ({
  crId,
  personalLoanApplicationNo,
  onClickHandler,
  setIsPersonalLoanCheckerDisable,
  isPersonalLoanCheckerDisable,
  approveButtonLoading,
  setApproveButtonLoading,
  selectedPayload,
}) => {
  const [loanAmount, setLoanAmount] = useState(null);
  const [monthlyRoi, setMonthlyRoi] = useState(null);
  const [customerRoi, setCustomerRoi] = useState(null);
  const [lenderIcon, setLenderIcon] = useState(null);
  const [lenderName, setLenderName] = useState(null);
  const [disbursedAmount, setDisbursedAmount] = useState(null);
  const [amountRefundToBtLender, setAmountRefundToBtLender] = useState(null);
  const [approvalScreenshots, setApprovalScreenshots] = useState([]);

  const [loanAmountValidation, setLoanAmountValidation] = useState(null);
  const [monthlyRoiValidation, setMonthlyRoiValidation] = useState(null);
  const [customerRoiValidation, setCustomerRoiValidation] = useState(null);
  const [subventedValidation, setSubventedValidation] = useState(null);
  const [disbursedAmountValidation, setDisbursedAmountValidation] = useState(
    null,
  );
  const [
    amountRefundToBtLenderValidation,
    setAmountRefundToBtLenderValidation,
  ] = useState(null);
  const [processingFee, setProcessingFee] = useState(0);
  const [processingFeeValidation, setProcessingFeeValidation] = useState(null);
  const [isProcessingFeeApplicable, setIsProcessingFeeApplicable] = useState(
    false,
  );

  const [isApproveButtonDisable, setIsApproveButtonDisable] = useState(true);
  const [isApprovalEmailSent, setIsApprovalEmailSent] = useState(false);

  const classes = styles();

  const {showError, showSuccess} = useSnackBar();

  const accessToken = useAppSelector(authSelectors.getAuthToken);

  const allowedLoanTypes = ["TAKEOVER", "REBOOK", "RENEW", "PART_RELEASE"];
  const isAmountRefundToBTLenderAllowed = allowedLoanTypes.includes(
    selectedPayload?.loanType,
  );

  const fetchLenderDetail = useCallback(
    async (loanType) => {
      const {payload, statusCode} = await getLenderDetails({
        accessToken,
        crId,
        loanType,
      });

      if (statusCode === 200) {
        setLenderIcon(payload?.icon);
        setLenderName(payload?.lenderName);
      } else {
      }
    },
    [accessToken, setLenderIcon, setLenderName],
  );

  const fetchProcessingFeeConfig = useCallback(async () => {
    const {response, error} = await getProcessingFeeConfig({
      crId: crId,
      lenderEnum: lenderNameEnum.SUNITA_FINANCE,
    });
    if (!error) {
      setIsProcessingFeeApplicable(response?.isProcessingFeeApplicable);
    } else {
      showError(error.message);
    }
  }, [crId]);

  const onClickSendApprovalEmail = useCallback(async () => {
    const {error, response} = await postLoanApprovalEmail({
      crId: crId,
      loanType: "PERSONAL_LOAN",
    });
    if (!error) {
      showSuccess(response?.message || "Approval email has been sent!");
      setIsApprovalEmailSent(true);
    } else {
      showError(error.message);
    }
  }, [crId, showError, showSuccess]);

  const handleClipboardEvents = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchLenderDetail("PERSONAL_LOAN");
  }, [fetchLenderDetail]);

  useEffect(() => {
    fetchProcessingFeeConfig();
  }, []);

  const subventedRoi = (monthlyRoi - customerRoi).toFixed(2);

  const onCheckerSubmitHandler = useCallback(async () => {
    if (loanAmount?.length === 0) {
      showError("total loan amount is required");
      return;
    }
    if (monthlyRoi?.length === 0) {
      showError("Monthly Roi is required");
      return;
    }
    if (customerRoi?.length === 0) {
      showError("customer  Roi is required");
      return;
    }
    setApproveButtonLoading(true);

    if (
      loanAmount?.length !== 0 &&
      monthlyRoi?.length !== 0 &&
      customerRoi?.length !== 0
    ) {
      const formData = new FormData();

      formData.append("loanAmount", loanAmount);
      formData.append("monthlyRoi", monthlyRoi);
      formData.append("customerRoi", customerRoi);
      formData.append("subventedRoi", subventedRoi);
      formData.append("disbursedAmount", disbursedAmount);
      formData.append(
        "amountRefundToBtLender",
        isAmountRefundToBTLenderAllowed ? amountRefundToBtLender : 0,
      );
      formData.append("processingFeeIncludingGst", processingFee);
      formData.append("plApprovalFile", approvalScreenshots[0]);

      const {statusCode, error} = await checkerValidation({
        accessToken,
        crId,
        fileData: formData,
        loanType: "PERSONAL_LOAN",
      });
      if (statusCode === 200) {
        onClickHandler("CM_PROCESS_PERSONAL_LOAN_DISBURSAL_MAKER_DONE");
        setApproveButtonLoading(false);
        setIsPersonalLoanCheckerDisable(true);
        showSuccess("Given Values have been successfully validated!");
      } else {
        setApproveButtonLoading(false);
        setLoanAmountValidation(error?.fieldError?.loanAmount);
        setMonthlyRoiValidation(error?.fieldError?.monthlyRoi);
        setCustomerRoiValidation(error?.fieldError?.customerRoi);
        setSubventedValidation(error?.fieldError?.subventedRoi);
        setDisbursedAmountValidation(error?.fieldError?.disbursedAmount);
        setAmountRefundToBtLenderValidation(
          error?.fieldError?.amountRefundToBtLender,
        );
        setProcessingFeeValidation(
          error?.fieldError?.processingFeeIncludingGst,
        );
        showError(error?.message);
      }
    }
  }, [
    loanAmount,
    processingFee,
    isAmountRefundToBTLenderAllowed,
    monthlyRoi,
    customerRoi,
    accessToken,
    crId,
    subventedRoi,
    onClickHandler,
    showError,
    amountRefundToBtLender,
    disbursedAmount,
    setApproveButtonLoading,
    approvalScreenshots,
  ]);

  return (
    <>
      {isPersonalLoanCheckerDisable ? (
        <CircularProgress variant="indeterminate" />
      ) : (
        <>
          <div className={classes.arrowOutlined}>
            <IconButton onClick={() => setIsPersonalLoanCheckerDisable(true)}>
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <div className={classes.loanConfirmContainer}>
            <Heading header="Confirm loan value" />
            <div className={classes.validateText}>
              Please validate the correct loan details and ROI mentioned for the
              loan
            </div>
            <Container className={classes.confirmContainer}>
              <Divider light={true} />
              <div className={classes.loanConfirmRowContainer}>
                <RowContainer>
                  <ColumnContainer>
                    <SubTitle>CR ID</SubTitle>
                    <MiniTitle style={{fontWeight: "bold"}}>{crId}</MiniTitle>
                  </ColumnContainer>
                  <ColumnContainer>
                    <SubTitle>Personal Loan Application No</SubTitle>
                    <MiniTitle style={{fontWeight: "bold"}}>
                      {personalLoanApplicationNo}
                    </MiniTitle>
                  </ColumnContainer>
                </RowContainer>
              </div>
              <Divider light={true} />
            </Container>
          </div>
          <FormControl variant="outlined" fullWidth>
            <Box p={2} sx={{width: "100%"}}>
              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="lender"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Lender
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <IconWrapper
                    width={"auto"}
                    src={lenderIcon}
                    alt="bank-icon"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>{}</span>
                </Grid>
              </Grid>
              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="total-loan-value"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    PL Sanctioned
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Amount in INR"
                    value={loanAmount}
                    onChange={(event) => setLoanAmount(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {loanAmountValidation ? loanAmountValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="amount-disbursed"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Amount to be Disbursed
                  </Typography>
                  <p> (Accounts will transfer this amount to customer) </p>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Amount in INR"
                    value={disbursedAmount}
                    onChange={(event) => setDisbursedAmount(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {disbursedAmountValidation ? disbursedAmountValidation : ""}
                  </span>
                </Grid>
              </Grid>

              {isAmountRefundToBTLenderAllowed && (
                <Grid container className={classes.checkerGrid}>
                  <Grid item xs={4}>
                    <Typography
                      color="rgba(0, 0, 0, 0.6)"
                      id="amount-disbursed"
                      variant="standard"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Refund to BT Lender
                    </Typography>
                    <p> (Accounts will transfer this amount to Bt lender ) </p>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Amount in INR"
                      value={amountRefundToBtLender}
                      onChange={(event) =>
                        setAmountRefundToBtLender(event.target.value)
                      }
                      onCut={handleClipboardEvents}
                      onCopy={handleClipboardEvents}
                      onPaste={handleClipboardEvents}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span className={classes.validationError}>
                      {amountRefundToBtLenderValidation
                        ? amountRefundToBtLenderValidation
                        : ""}
                    </span>
                  </Grid>
                </Grid>
              )}

              {isProcessingFeeApplicable && (
                <Grid container className={classes.checkerGrid}>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      id="amount-disbursed"
                      variant="standard"
                      className={classes.checkerLabel}
                    >
                      Processing Fee
                    </Typography>
                    {/* <p> (Accounts will transfer this to customer) </p> */}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Amount in INR"
                      label="Processing Fee"
                      onWheel={(event) => event.target.blur()}
                      value={processingFee}
                      onChange={(event) => setProcessingFee(event.target.value)}
                      onCut={handleClipboardEvents}
                      onCopy={handleClipboardEvents}
                      onPaste={handleClipboardEvents}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span className={classes.validationError}>
                      {processingFeeValidation ? processingFeeValidation : ""}
                    </span>
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="monthly-roi"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Monthly ROI
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="in %"
                    value={monthlyRoi}
                    onChange={(event) => {
                      setMonthlyRoi(event.target.value);
                    }}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {monthlyRoiValidation ? monthlyRoiValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="customer-roi"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Customer ROI
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="in %"
                    value={customerRoi}
                    onChange={(event) => setCustomerRoi(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {customerRoiValidation ? customerRoiValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="rgba(0, 0, 0, 0.6)"
                    id="subvention-roi"
                    variant="standard"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    IG subvention
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={subventedRoi}
                    disabled
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {subventedValidation ? subventedValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Box my={2}>
                <IGFileUpload
                  text="Attach Approval Screenshots*"
                  value={approvalScreenshots}
                  onChange={(fileList) => {
                    setApprovalScreenshots(fileList);
                  }}
                  draggable={false}
                  inputProps={{
                    accept: "image/png, image/jpeg, image/jpg, application/pdf",
                  }}
                />
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() =>
                      setIsApproveButtonDisable(!isApproveButtonDisable)
                    }
                    name="checkedF"
                    sx={{
                      color: "#ce9f29",
                      "&.Mui-checked": {
                        color: "#ce9f29",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{fontWeight: "bold"}}>
                    Please verify that all the information mentioned above is
                    correct
                  </Typography>
                }
              />
            </Box>
          </FormControl>
          <Box display="flex" justifyContent="space-between" my={2}>
            <IGButton
              color="golden"
              sx={{
                marginLeft: 1,
              }}
              onClick={() => {
                onCheckerSubmitHandler();
              }}
              loading={approveButtonLoading}
              disabled={isApproveButtonDisable}
            >
              Approve & Notify LM for Loan Documents
            </IGButton>
            {lenderName === lenderNameEnum.SUNITA_FINANCE &&
              !isApprovalEmailSent && (
                <IGButton onClick={() => onClickSendApprovalEmail()}>
                  SEND APPROVAL EMAIL
                </IGButton>
              )}
          </Box>
        </>
      )}
    </>
  );
};

export default PersonalLoanChecker;
