import React, {FC, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography, IconButton} from "@mui/material";
import {Info, MoreVertRounded} from "@mui/icons-material";
import tippy from "tippy.js";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanPaymentSelectors} from "app/store/loanPayment";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {loanPaymentActions} from "app/store/loanPayment";
import {IGButton, IGDialog, IGMenu, IGTable} from "app/components";
import {IGTableColsInterface} from "app/components/IGTable";
import {LoanPaymentModel} from "app/models/loanPayment.model";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";
import VATable from "../VATable";
import {loanSelectors} from "app/store/loan";
import {openModal} from "app/store/customerProfile/customerProfile.reducer";
import {customerProfileSelectors} from "app/store/customerProfile";
import RefundModal from "./RefundModal";
import ShiftPaymentModal from "./ShiftPayment";
import ShiftPaymentStatusModal from "./ShiftPaymentStatusModal";
import RefundStatusModal from "./RefundStatusModal";
import {featureFlagSelectors} from "app/store/featureFlags";

interface PaymentDetailsProps {}

const PaymentDetails: FC<PaymentDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const {t, tModalTitle, tTableHeader, tTableActions} = useIGTranslation();
  const {loanId} = useParams();
  const totalRowId = Math.random();
  const user = useAppSelector(authSelectors.getUser);
  const loanPaymentList = useAppSelector(
    loanPaymentSelectors.getLoanPaymentList,
  );
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const {getLoanPaymentDetails} = useAppSelector(
    loanPaymentSelectors.getLoading,
  );
  const openModals = useAppSelector(customerProfileSelectors.getOpenModals);
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );

  const [openVATable, setOpenVATable] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number>(0);

  const isFinanceTeam = user?.userRoleList?.includes(RolesEnum.FINANCE_TEAM);

  const tableActions = [
    {
      actionEnum: "refund",
      displayName: "Refund",
      render: isFinanceTeam,
      onClick: () => {
        dispatch(openModal("refund"));
      },
    },
  ];

  const paymentTableCols: IGTableColsInterface<LoanPaymentModel> = [
    {
      headerName: t("DATE"),
      field: "createdAt",
      renderCell: ({value, row}) =>
        row.status === "isTotal"
          ? t("TOTAL")
          : getValidTime(value).format("DD/MM/YYYY"),
    },
    {
      headerName: t("MODE"),
      field: "paymentMode",
      renderCell: ({value}) => value?.toUpperCase(),
    },
    {
      headerName: "Original Amount",
      field: "originalPaymentAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: "Receipt Amount",
      field: "paymentAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("PAYMENT_ID"),
      field: "loanPaymentId",
      renderCell: ({value}) => value?.toUpperCase(),
    },
    {
      headerName: t("PURPOSE"),
      field: "loanPaymentType",
      renderCell: ({value}) => value?.toUpperCase().replace(/_/g, " "),
    },
    {
      headerName: t("STATUS"),
      field: "status",
      renderCell: ({value, row}) => {
        const isError = ["AUTO-REFUNDED", "FAILED"].includes(value);
        const tooltipText =
          row.errorDescription || row.orderPaymentInfoDto?.supportTeamMessage;
        const renderViewAllVA =
          value === "isTotal" &&
          user?.userRoleList?.some((role) =>
            [
              RolesEnum.ADMIN,
              RolesEnum.FOUNDER,
              RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
              RolesEnum.COLLECTION_MANAGER,
            ].includes(role),
          );

        return (
          <Box
            display="flex"
            alignItems="center"
            onMouseEnter={(e) => {
              if (tooltipText) {
                tippy(e.currentTarget, {
                  content: tooltipText,
                  allowHTML: true,
                  placement: "top",
                });
              }
            }}
          >
            <Box>
              {renderViewAllVA && (
                <IGButton onClick={() => setOpenVATable(true)}>
                  {tTableActions("VIEW_ALL_VA")}
                </IGButton>
              )}
              {value !== "isTotal" && (
                <Typography color={isError ? "error" : ""}>{value}</Typography>
              )}
            </Box>
            {isError && tooltipText && (
              <Box ml={1}>
                <Info fontSize="small" color="error" />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      headerName: t("CUSTOMER_PROFILE.TYPE_ALLOCATION", {type: "GL"}),
      field: "goldLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("CUSTOMER_PROFILE.TYPE_ALLOCATION", {type: "PL"}),
      field: "personalLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: "Received Via",
      field: "receivedVia",
      renderCell: ({value}) => value,
    },
    {
      headerName: "Refund Status",
      field: "refundType",
      renderCell: ({value, row}) => {
        return (
          <>
            {row.status === "isTotal" ? null : (
              <Typography
                onClick={() => {
                  if (value) {
                    setSelectedRowId(Number(row.id));
                    dispatch(openModal("refundStatus"));
                  }
                }}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#cc9f26",
                }}
              >
                {value}
              </Typography>
            )}
          </>
        );
      },
    },
    // {
    //   headerName: "Shift Payment Status",
    //   field: "isShiftedPayment",
    //   renderCell: ({value, row}) => {
    //     return (
    //       <>
    //         {row.status === "isTotal" ? null : (
    //           <Typography
    //             onClick={() => {
    //               if (value) {
    //                 dispatch(openModal("shiftPaymentStatus"));
    //               }
    //             }}
    //             sx={{
    //               cursor: value ? "pointer" : "initial",
    //               textDecoration: value ? "underline" : "initial",
    //               color: value ? "#cc9f26" : "#000",
    //             }}
    //           >
    //             {value ? "Shifted" : "Not Shifted"}
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      headerName: t("ACTIONS"),
      field: "actions",
      minWidth: 100,
      sortable: false,
      renderCell: ({row: {id, status, refundType}}) => (
        <Box>
          {status === "isTotal" || !isFinanceTeam ? null : (
            <IGMenu
              actionButtonProps={{
                id: `${id}_buttonvert`,
                elem: (props) => (
                  <IconButton
                    disabled={refundType || loanDetails?.status === "CLOSED"}
                    {...props}
                  >
                    <MoreVertRounded />
                  </IconButton>
                ),
                onClick: () => {
                  setSelectedRowId(Number(id));
                },
              }}
              actionMenuProps={{
                id: `${id}_menu`,
                items: tableActions
                  .filter((action) => action.render)
                  .map((item) => {
                    return {
                      key: id + item.actionEnum,
                      id: id + item.actionEnum,
                      label: item.displayName,
                      renderCondition: true,
                      menuItemProps: {value: item.actionEnum},
                      onClick: () => {
                        item.onClick();
                      },
                    };
                  }),
              }}
            />
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      loanPaymentActions.getLoanPaymentDetails({
        loanId: Number(loanId),
      }),
    );
  }, [dispatch, loanId]);

  const loanPaymentData = useMemo(() => {
    const paymentList = [...loanPaymentList];
    paymentList.push({
      status: "isTotal",
      id: totalRowId,
      paymentAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => {
          if (cv.status === "SUCCESS") {
            return acc + cv.paymentAmount;
          }
          return acc;
        }, 0),
      ),
      goldLoanAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => {
          if (cv.status === "SUCCESS") {
            return acc + cv.goldLoanAmount;
          }
          return acc;
        }, 0),
      ),
      personalLoanAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => {
          if (cv.status === "SUCCESS") {
            return acc + cv.personalLoanAmount;
          }
          return acc;
        }, 0),
      ),
    } as LoanPaymentModel);
    return paymentList;
  }, [loanPaymentList, totalRowId]);

  return (
    <div>
      <IGTable<LoanPaymentModel>
        hideFooter
        loading={getLoanPaymentDetails}
        tableCols={paymentTableCols}
        tableData={loanPaymentData}
        getRowId={(row) => row.id}
        sx={{
          ".Mui-selected": {
            fontWeight: "bold",
          },
        }}
        components={{
          Header: (params) => (
            <Box {...params} p={2} display="flex" alignItems="center" gap={2}>
              <Typography variant="h5">{tTableHeader("PAYMENTS")}</Typography>
            </Box>
          ),
        }}
        columnVisibilityModel={{
          paymentVa: !user?.userRoleList?.includes(RolesEnum.LENDER),
          personalLoanAmount: loanDetails?.isPersonalLoanTaken && !isMonitoringCheckActive,
          actions: Boolean(isFinanceTeam),
        }}
      />
      {openVATable && (
        <IGDialog
          open={openVATable}
          onClose={() => setOpenVATable(false)}
          title={tModalTitle("VIEW_ALL_VIRTUAL_ADDRESSES")}
          hideFooter
          maxWidth="sm"
        >
          <VATable />
        </IGDialog>
      )}
      {openModals.refund && (
        <RefundModal
          paymentId={selectedRowId}
          paymentAmount={
            loanPaymentList.find((p) => p.id === selectedRowId)
              ?.paymentAmount ?? 0
          }
          loanPaymentId={
            loanPaymentList.find((p) => p.id === selectedRowId)?.loanPaymentId ?? ""
          }
        />
      )}

      {openModals.shiftPayment && (
        <ShiftPaymentModal
          paymentId={selectedRowId}
          paymentAmount={
            loanPaymentList.find((p) => p.id === selectedRowId)
              ?.paymentAmount ?? 0
          }
        />
      )}
      {openModals.refundStatus && (
        <RefundStatusModal paymentId={selectedRowId} />
      )}
      {openModals.shiftPaymentStatus && (
        <ShiftPaymentStatusModal paymentId={selectedRowId} />
      )}
    </div>
  );
};

export default PaymentDetails;
