import styled from "@emotion/styled";

const Container = styled.div`
  height: 2.5rem;
  width: 100%;

  background-color: #fbf8ee;
  border: solid 1px #f3e5b6;
  border-radius: 4px;
  padding: 1.5rem;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  border-bottom: ${({active}) => active ? "none" : "solid 1px #f3e5b6"};
  border-bottom-left-radius: ${({active}) => active ? "0px" : "4px"};
  border-bottom-right-radius: ${({active}) => active ? "0px" : "4px"};
`;

export default Container;
