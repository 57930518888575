import React from "react";
import {Box, Divider, List, ListItemButton, Typography} from "@mui/material";
import {GetOrnamentsNames} from "app/models/ornaments.model";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {useAppSelector} from "app/store/hooks";
import {OrnamentsSelectors} from "app/store/ornaments";
import {setSelectedOrnamentId} from "app/store/ornaments/ornaments.reducer";
import {useAppDispatch} from "app/store/hooks";

interface OrnamentsMenuProps {
  crId: number;
  ornamentsNamesList: GetOrnamentsNames[];
}
const OrnamentsMenu = ({ornamentsNamesList}: OrnamentsMenuProps) => {
  const dispatch = useAppDispatch();
  const selectedOrnamentId = useAppSelector(
    OrnamentsSelectors.getSelectedOrnamentId,
  );

  return (
    <>
      <Box
        border="1px solid #E0E0E0"
        borderRadius="10px"
        bgcolor="#FAFAFA"
        overflow="auto"
        height="80vh"
      >
        <Typography
          textAlign="start"
          fontWeight={400}
          color="rgba(0, 0, 0, 0.87)"
          m={1}
        >
          Ornament List ({ornamentsNamesList?.length})
        </Typography>
        <Divider />
        <List
          sx={{
            // selected and (selected + hover) states
            "&& .Mui-selected, && .Mui-selected:hover": {
              bgcolor: "rgba(25, 118, 210, 0.08)",
            },
            // hover states
            "& .MuiListItemButton-root:hover": {
              bgcolor: "background: rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          {ornamentsNamesList?.map((item: GetOrnamentsNames) => (
            <ListItemButton
              key={item.id}
              alignItems="flex-start"
              sx={{gap: "1rem", justifyContent: "space-between"}}
              selected={selectedOrnamentId === item.id}
              onClick={() => {
                dispatch(setSelectedOrnamentId({ornamentId: item.id}));
              }}
            >
              <Typography>{item.ornamentType}{item.quantity > 1 && ` (${item.quantity})`}</Typography>
              <Box>
                {item.isCheckedByCm && <CheckCircleOutlineOutlinedIcon />}
              </Box>
            </ListItemButton>
          ))}
        </List>
      </Box>
    </>
  );
};

export default OrnamentsMenu;
