import {createAction} from "@reduxjs/toolkit";
import {
  CustomerProfileResponse,
  GetCustomerProfileListPayload,
  InitializeCustomerProfilePayload,
  LoanIdPayload, UserIdPayload,
} from "../../infra/services/api/customerProfile/types";

const lenderCustomerProfileActionTypes = {
  GetOverduePaymentDetails: "[Lender Customer Profile] Get Overdue Payment Detail",
  GetOverduePaymentDetailsFailure: "[Lender Customer Profile] Get Overdue Payment Detail Failure",
  GetOverduePaymentDetailsSuccess: "[Lender Customer Profile] Get Overdue Payment Detail Success",
  GetReminders: "[Lender Customer Profile] Get Reminders",
  GetRemindersSuccess: "[Lender Customer Profile] Get Reminders Success",
  GetRemindersFailure: "[Lender Customer Profile] Get Reminders Failure",
  SendReminder: "[Lender Customer Profile] Get Send Reminder",
  SendReminderSuccess: "[Lender Customer Profile] Get Send Reminder Success",
  SendReminderFailure: "[Lender Customer Profile] Get Send Reminder Failure",
  InitializeCustomerProfile: "[Lender Customer Profile] Initialize Customer Profile",
  InitializeCustomerProfileSuccess: "[Lender Customer Profile] Initialize Customer Profile Success",
  InitializeCustomerProfileFailure: "[Lender Customer Profile] Initialize Customer Profile Failure",
  GetCustomerProfileList: "[Lender Customer Profile] Get Customer Profile List",
  GetCustomerProfileListSuccess: "[Lender Customer Profile] Get Customer Profile List Success",
  GetCustomerProfileListFailure: "[Lender Customer Profile] Get Customer Profile List Failure",
  GetCustomerDetails: "[Lender Customer Profile] Get Customer Details",
  GetCustomerDetailsSuccess: "[Lender Customer Profile] Get Customer Details Success",
  GetCustomerDetailsFailure: "[Lender Customer Profile] Get Customer Details Failure",
};

const lenderCustomerProfileActions = {
  getOverduePaymentDetails: createAction<LoanIdPayload>(
    lenderCustomerProfileActionTypes.GetOverduePaymentDetails,
  ),
  getOverduePaymentDetailsSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetOverduePaymentDetailsSuccess,
  ),
  getOverduePaymentDetailsFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetOverduePaymentDetailsFailure,
  ),
  getReminders: createAction<LoanIdPayload>(
    lenderCustomerProfileActionTypes.GetReminders,
  ),
  getRemindersSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetRemindersSuccess,
  ),
  getRemindersFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetRemindersFailure,
  ),
  sendReminder: createAction<LoanIdPayload>(
    lenderCustomerProfileActionTypes.SendReminder,
  ),
  sendReminderSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.SendReminderSuccess,
  ),
  sendReminderFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.SendReminderFailure,
  ),
  initializeCustomerProfile: createAction<InitializeCustomerProfilePayload>(
    lenderCustomerProfileActionTypes.InitializeCustomerProfile,
  ),
  initializeCustomerProfileSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.InitializeCustomerProfileSuccess,
  ),
  initializeCustomerProfileFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.InitializeCustomerProfileFailure,
  ),
  getCustomerProfileList: createAction<GetCustomerProfileListPayload>(
    lenderCustomerProfileActionTypes.GetCustomerProfileList,
  ),
  getCustomerProfileListSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetCustomerProfileListSuccess,
  ),
  getCustomerProfileListFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetCustomerProfileListFailure,
  ),
  getCustomerDetails: createAction<LoanIdPayload>(
    lenderCustomerProfileActionTypes.GetCustomerDetails,
  ),
  getCustomerDetailsSuccess: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetCustomerDetailsSuccess,
  ),
  getCustomerDetailsFailure: createAction<CustomerProfileResponse>(
    lenderCustomerProfileActionTypes.GetCustomerDetailsFailure,
  ),
};

export {lenderCustomerProfileActionTypes};

export default lenderCustomerProfileActions;