import {createSlice} from "@reduxjs/toolkit";
import {RateChangeState} from "./rateChange.types";
import rateChangeActions from "./rateChange.actions";

const initialState: RateChangeState = {
  loanRateChangeHistory: null,
  loanCurrentPlan: {
    glAmount: null,
    glMonthlyRoi: null,
    plAmount: null,
    plMonthlyRoi: null,
    totalLoanAmount: null,
    weightedMonthlyRoi: null,
    planType: null,
  },
  offerVersionHistory: [],
  customerRequirementAndCompetitorDetails: {
    customerExpectedRoiDetail: {
      glCustomerAnnualRoi: null,
      glCustomerMonthlyRoi: null,
      remarks: null,
    },
    competitorDetail: {
      attachmentList: [],
      competitorName: "",
      offeredRoi: null,
    },
    rateChangeReason: "",
    isEditable: false,
    isRequirementAvailable: false,
  },
  currentOfferDetail: {
    isOfferAvailable: false,
    isDisplayAllowed: false,
    glOfferedMonthlyRoi: null,
    isCustomerSharingAllowed: false,
    cmRemarks: null,
  },
  currentTaskStatusDetail: {
    offerDetail: {
      isEditable: false,
      glCustomerExpectedMonthlyRoi: null,
      glCustomerOfferedMonthlyRoi: null,
      loanNewRoiConfig: {
        minimumMonthlyRoi: null,
        maximumMonthlyRoi: null,
        recommendedMonthlyRoi: null,
      },
      remarks: null,
    },
    customerRequirement: {
      customerExpectedRoiDetail: {
        glCustomerAnnualRoi: null,
        glCustomerMonthlyRoi: null,
        remarks: null,
      },
      competitorDetail: {
        attachmentList: [],
        competitorName: null,
        offeredRoi: null,
      },
      loanNewRoiConfig: {
        minimumMonthlyRoi: null,
        maximumMonthlyRoi: null,
        recommendedMonthlyRoi: null,
      },
      rateChangeReason: null,
      isEditable: false,
      isRequirementAvailable: false,
    },
    glNewLenderAcceptedMonthlyRoi: null,
    glNewCustomerAcceptedMonthlyRoi: null,
    infoMessage: null,
    infoMessageHexcode: null,
    taskTitleString: null,
  },
  forceRefresh: false,
  loading: {
    getLoanRateChangeHistory: false,
    getLoanCurrentPlan: false,
    getOfferVersionHistory: false,
    getCustomerRequirementAndCompetitorDetails: false,
    getCurrentOfferDetail: false,
    getCurrentTaskStatusDetail: false,
    updateCustomerRequirementsByCst: false,
    shareCmOfferWithCustomer: false,
    updateOfferByCm: false,
  },
  errors: {
    getLoanRateChangeHistory: null,
    getLoanCurrentPlan: null,
    getOfferVersionHistory: null,
    getCustomerRequirementAndCompetitorDetails: null,
    getCurrentOfferDetail: null,
    getCurrentTaskStatusDetail: null,
    updateCustomerRequirementsByCst: null,
    shareCmOfferWithCustomer: null,
    updateOfferByCm: null,
  },
};

const rateChangeSlice = createSlice({
  name: "rateChange",
  initialState,
  reducers: {
    forceRefresh: (state) => {
      state.forceRefresh = !state.forceRefresh;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rateChangeActions.getLoanRateChangeHistory, (state) => {
        state.loading.getLoanRateChangeHistory = true;
        state.errors.getLoanRateChangeHistory = null;
      })
      .addCase(
        rateChangeActions.getLoanRateChangeHistorySuccess,
        (state, action) => {
          state.loanRateChangeHistory = action.payload;
          state.loading.getLoanRateChangeHistory = false;
          state.errors.getLoanRateChangeHistory = null;
        },
      )
      .addCase(
        rateChangeActions.getLoanRateChangeHistoryFailure,
        (state, action) => {
          state.loading.getLoanRateChangeHistory = false;
          state.errors.getLoanRateChangeHistory = action.payload.error;
        },
      )
      .addCase(rateChangeActions.getLoanCurrentPlan, (state) => {
        state.loading.getLoanCurrentPlan = true;
        state.errors.getLoanCurrentPlan = null;
      })
      .addCase(
        rateChangeActions.getLoanCurrentPlanSuccess,
        (state, action) => {
          state.loanCurrentPlan = action.payload;
          state.loading.getLoanCurrentPlan = false;
          state.errors.getLoanCurrentPlan = null;
        },
      )
      .addCase(
        rateChangeActions.getLoanCurrentPlanFailure,
        (state, action) => {
          state.loading.getLoanCurrentPlan = false;
          state.errors.getLoanCurrentPlan = action.payload.error;
        },
      )
      .addCase(rateChangeActions.getOfferVersionHistory, (state) => {
        state.loading.getOfferVersionHistory = true;
        state.errors.getOfferVersionHistory = null;
      })
      .addCase(
        rateChangeActions.getOfferVersionHistorySuccess,
        (state, action) => {
          state.offerVersionHistory = action.payload;
          state.loading.getOfferVersionHistory = false;
          state.errors.getOfferVersionHistory = null;
        },
      )
      .addCase(
        rateChangeActions.getOfferVersionHistoryFailure,
        (state, action) => {
          state.loading.getOfferVersionHistory = false;
          state.errors.getOfferVersionHistory = action.payload.error;
        },
      )
      .addCase(
        rateChangeActions.getCustomerRequirementAndCompetitorDetails,
        (state) => {
          state.loading.getCustomerRequirementAndCompetitorDetails = true;
          state.errors.getCustomerRequirementAndCompetitorDetails = null;
        },
      )
      .addCase(
        rateChangeActions.getCustomerRequirementAndCompetitorDetailsSuccess,
        (state, action) => {
          state.customerRequirementAndCompetitorDetails =
            action.payload;
          state.loading.getCustomerRequirementAndCompetitorDetails = false;
          state.errors.getCustomerRequirementAndCompetitorDetails = null;
        },
      )
      .addCase(
        rateChangeActions.getCustomerRequirementAndCompetitorDetailsFailure,
        (state, action) => {
          state.loading.getCustomerRequirementAndCompetitorDetails = false;
          state.errors.getCustomerRequirementAndCompetitorDetails =
            action.payload.error;
        },
      )
      .addCase(rateChangeActions.getCurrentOfferDetail, (state) => {
        state.loading.getCurrentOfferDetail = true;
        state.errors.getCurrentOfferDetail = null;
      })
      .addCase(
        rateChangeActions.getCurrentOfferDetailSuccess,
        (state, action) => {
          state.currentOfferDetail = action.payload;
          state.loading.getCurrentOfferDetail = false;
          state.errors.getCurrentOfferDetail = null;
        },
      )
      .addCase(
        rateChangeActions.getCurrentOfferDetailFailure,
        (state, action) => {
          state.loading.getCurrentOfferDetail = false;
          state.errors.getCurrentOfferDetail = action.payload.error;
        },
      )
      .addCase(rateChangeActions.getCurrentTaskStatusDetail, (state) => {
        state.loading.getCurrentTaskStatusDetail = true;
        state.errors.getCurrentTaskStatusDetail = null;
      })
      .addCase(
        rateChangeActions.getCurrentTaskStatusDetailSuccess,
        (state, action) => {
          state.currentTaskStatusDetail = action.payload;
          state.loading.getCurrentTaskStatusDetail = false;
          state.errors.getCurrentTaskStatusDetail = null;
        },
      )
      .addCase(
        rateChangeActions.getCurrentTaskStatusDetailFailure,
        (state, action) => {
          state.loading.getCurrentTaskStatusDetail = false;
          state.errors.getCurrentTaskStatusDetail = action.payload.error;
        },
      )
      .addCase(rateChangeActions.updateCustomerRequirementsByCst, (state) => {
        state.loading.updateCustomerRequirementsByCst = true;
        state.errors.updateCustomerRequirementsByCst = null;
      })
      .addCase(
        rateChangeActions.updateCustomerRequirementsByCstSuccess,
        (state) => {
          state.loading.updateCustomerRequirementsByCst = false;
          state.errors.updateCustomerRequirementsByCst = null;
        },
      )
      .addCase(
        rateChangeActions.updateCustomerRequirementsByCstFailure,
        (state, action) => {
          state.loading.updateCustomerRequirementsByCst = false;
          state.errors.updateCustomerRequirementsByCst = action.payload.error;
        },
      )
      .addCase(rateChangeActions.shareCmOfferWithCustomer, (state) => {
        state.loading.shareCmOfferWithCustomer = true;
        state.errors.shareCmOfferWithCustomer = null;
      })
      .addCase(
        rateChangeActions.shareCmOfferWithCustomerSuccess,
        (state) => {
          state.loading.shareCmOfferWithCustomer = false;
          state.errors.shareCmOfferWithCustomer = null;
        },
      )
      .addCase(
        rateChangeActions.shareCmOfferWithCustomerFailure,
        (state, action) => {
          state.loading.shareCmOfferWithCustomer = false;
          state.errors.shareCmOfferWithCustomer = action.payload.error;
        },
      )
      .addCase(rateChangeActions.updateOfferByCm, (state) => {
        state.loading.updateOfferByCm = true;
        state.errors.updateOfferByCm = null;
      })
      .addCase(rateChangeActions.updateOfferByCmSuccess, (state) => {
        state.loading.updateOfferByCm = false;
        state.errors.updateOfferByCm = null;
      })
      .addCase(rateChangeActions.updateOfferByCmFailure, (state, action) => {
        state.loading.updateOfferByCm = false;
        state.errors.updateOfferByCm = action.payload.error;
      });
  },
});

const {forceRefresh} = rateChangeSlice.actions;
export default rateChangeSlice.reducer;
export {initialState as rateChangeState, forceRefresh};
