import React from "react";
import {Navigate, Outlet, Route} from "react-router-dom";
import ServiceDesk from "app/pages/home/GoldLoan/ServiceDesk";
import {MenuComponent} from "app/models/product";
import {IGLoading} from "app/components";
import {
  SDManageRenewRebook,
  SDCloseAndRenew,
  SDManageRateChange,
  SDProcessRequest,
  SDJumperRemoval,
  SDManagePartReleaseRequest,
} from "app/AppLayout/getAllComponents";

const ServiceDeskRoutes = (feature: MenuComponent) => {
  return (
    <Route path="service-desk" element={<ServiceDesk />}>
      <Route
        index
        element={
          feature.subMenu?.length > 0 ? (
            <Navigate to={feature.subMenu[0].path} />
          ) : (
            <IGLoading />
          )
        }
      />
      {feature.subMenu.map((route) => (
        <Route key={route.path} path={route.path.replace("/", "")} element={route.component()}/>
      ))}
      <Route path="loan-services/:loanId" element={<Outlet />}>
        <Route path="renew-rebook/:requestId" element={<Outlet />}>
          <Route index element={<SDManageRenewRebook />} />
          <Route
            path="update"
            element={<SDManageRenewRebook isUpdate={true} />}
          />
        </Route>
        <Route path="part-release/:requestId" element={<Outlet />}>
          <Route index element={<SDManagePartReleaseRequest />} />
          <Route
            path="update"
            element={<SDManagePartReleaseRequest isCmScreen />}
          />
        </Route>
        <Route
          path="close-and-renew/:requestId"
          element={<SDCloseAndRenew />}
        />
        <Route path="rate-change/:requestId" element={<Outlet />}>
          <Route index element={<SDManageRateChange />} />
          <Route
            path="approve"
            element={<SDManageRateChange />}
          />
        </Route>
      </Route>
      <Route
        path="loan-services/close-loan/:requestId"
        element={<SDProcessRequest />}
      />
      <Route path="complaints/remove-jumper" element={<SDJumperRemoval />} />
    </Route>
  );
};

export default ServiceDeskRoutes;
