import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import GuarantorDocView from "../GuarantorDocView";
import {useAppSelector} from "app/store/hooks";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";

const GuarantorSupplementDocuments = () => {
  const guarantorDocuments = useAppSelector(
    personalDetailsSelectors.getKycDocuments,
  );

  return (
    <Box
      bgcolor="#ffff"
      borderRadius="4px"
      padding="16px 16px 16px 24px"
      border="1px solid #0000001F"
    >
      <Grid container spacing={1}>
        {guarantorDocuments
          .filter(
            (i) =>
              i.documentType !== "GUARANTOR_AADHAAR" &&
              i.documentType !== "GUARANTOR_PAN",
          )
          .map((doc) => (
            <Grid item xs={6}>
              <Typography
                fontSize="14px"
                fontFamily="roboto"
                fontWeight={500}
                lineHeight="48px"
                sx={{
                  textTransform:"capitalize",
                }}
              >
                {doc?.documentType.replaceAll("_", " ").toLowerCase()}
              </Typography>
              <GuarantorDocView guarantorDocument={doc} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default GuarantorSupplementDocuments;
