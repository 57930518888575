import React, {useMemo} from "react";
import {Box, Typography} from "@mui/material";
import LoanDetailsBox from "../styled";
import {useAppSelector} from "app/store/hooks";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {IGLoading} from "app/components";
import {activeLoansSelectors} from "app/store/activeLoans";

import {LoanTopUpAmount} from "app/models/openCr";

interface TopUpAmountProps {
  topUpAmountDetails: LoanTopUpAmount;
  totalAdjustedPfAmountWithTopUp: number | undefined;
}
const TopUpAmount = ({
  topUpAmountDetails,
  totalAdjustedPfAmountWithTopUp,
}: TopUpAmountProps) => {
  const {getLoanTopUpAmount: isTopUpAmountDetailsLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  //UI HELPERS

  const loanTopUpAmountDetails = useMemo(() => {
    if (topUpAmountDetails) {
      const {fwcSrCmRenewalAmountAdjustmentResponseDto} =
        topUpAmountDetails ?? {};
      const {
        glRenewalAmountAdjustmentDto,
        plRenewalAmountAdjustmentDto,
        totalReceivableAmountWithoutAdjustment,
        totalReceivableAmountWithAdjustment,
      } = fwcSrCmRenewalAmountAdjustmentResponseDto ?? {};
      const {
        // eslint-disable-next-line max-len
        netReceivableAmountWithoutAdjustment: glNetReceivableAmountWithoutAdjustment,
      } = glRenewalAmountAdjustmentDto ?? {};
      const {
        // eslint-disable-next-line max-len
        netReceivableAmountWithoutAdjustment: plNetReceivableAmountWithoutAdjustment,
      } = plRenewalAmountAdjustmentDto ?? {};

      return [
        // {
        //   label: "GL Top-up Amount",
        //   value: numberWithCurrencyAndComma(
        //     glNetReceivableAmountWithoutAdjustment,
        //   ),
        // },
        // {
        //   label: "PL Top-up Amount",
        //   value: numberWithCurrencyAndComma(
        //     plNetReceivableAmountWithoutAdjustment,
        //   ),
        // },
        {
          label: "Total Top-up Amount",
          value: numberWithCurrencyAndComma(
            totalReceivableAmountWithoutAdjustment,
          ),
        },
        {
          label: "Pf Adjusted with Top Up",
          value: numberWithCurrencyAndComma(totalAdjustedPfAmountWithTopUp),
        },
        {
          label: "Net Receivable By Customer",
          value: numberWithCurrencyAndComma(
            totalReceivableAmountWithAdjustment,
          ),
        },
      ];
    }
  }, [topUpAmountDetails, totalAdjustedPfAmountWithTopUp]);

  return (
    <Box>
      {isTopUpAmountDetailsLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <LoanDetailsBox>
          <Typography my={1} fontWeight="bold" variant="h6">
            Net Receivable By Customer
          </Typography>

          {loanTopUpAmountDetails?.map((item) => (
            <Box
              display="flex"
              justifyContent="space-between"
              width="300px"
              gap={2}
              key={item.label}
            >
              <Typography color="text.secondary" variant="h6">
                {item.label}
              </Typography>

              <Typography variant="h6">{item.value}</Typography>
            </Box>
          ))}
        </LoanDetailsBox>
      )}
    </Box>
  );
};

export default TopUpAmount;
