import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

interface checkOldLoanClosedProps {
  crId: number;
}
export const checkOldLoanClosed = async ({crId}: checkOldLoanClosedProps) => {
  const API_ENDPOINTS: ApiResources = {
    CLOSE_CURRENT_LOAN: {
      URL: "admin/business/cr/:crId/check-old-loan-closed",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.CLOSE_CURRENT_LOAN,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default checkOldLoanClosed;
