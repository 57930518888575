import styled from "@emotion/styled";

const Button = styled.button`
  border: none;
  border-radius: 4px;
  height: 2.5rem;
  width: 10rem;
  background: #ce9f29;

  margin-bottom: 3rem;

  font-size: 1rem;
  font-weight: 500;
`;

export default Button;
