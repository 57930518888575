import {LayoutApiInterface, LayoutResources} from "./types";
import {goldApiService} from "../../../plugins/axios";

const LAYOUT_ENDPOINT: LayoutResources = {
  GET_SUB_MENU: {
    URL: "/admin/:menuName/dashboard-list",
    METHOD: "GET",
  },
  GET_LENDER_MENU: {
    URL: "/admin/business/lender/section-list",
    METHOD: "GET",
  },
  GET_LENDER_DASHBOARD_LIST: {
    URL: "/admin/business/lender/dashboard-list",
    METHOD: "GET",
  },
  GET_FEATURE_FLAGS: {
    URL: "/config/app/v2/module",
    METHOD: "GET",
  },
};

const LayoutApi: LayoutApiInterface = {
  getSubMenu: async (payload) => {
    const queryParams: Record<string, any> = {};
    if (payload.sectionEnumList) {
      queryParams["sectionEnumList"] = payload.sectionEnumList;
    }
    const {response, error} = await goldApiService({
      resource: LAYOUT_ENDPOINT.GET_SUB_MENU,
      options: {
        pathVars: {
          menuName: payload.menuName,
        },
        queryParams,
      },
    });

    if (error) {
      return {error, response: []};
    }

    const subMenu = response.submenuList.map((r: any) => ({
      ...r,
      path: r.relativePath,
      tabs: r.tabList.map((t: any) => ({...t, path: t.relativePath})),
    }));
    return {
      response: {
        menuName: payload.menuName,
        submenu: subMenu,
      },
      error: null,
    };
  },
  getLenderMenu: async () => {
    const {response, error} = await goldApiService({
      resource: LAYOUT_ENDPOINT.GET_LENDER_MENU,
    });

    if (error) {
      return {error, response: []};
    }
    const sections = response.map((r: any) => ({
      ...r,
      name: r.sectionName,
      enum: r.sectionEnum,
      subSectionList: r.subSectionList.map((s: any) => ({
        ...s,
        name: s.subSectionName,
        enum: s.subSectionEnum,
        tabList: s.subSectionTabList.map((t: any) => ({
          ...t,
          name: t.tabName,
          enum: t.tabEnum,
        })),
      })),
    }));

    return {
      response: sections,
      error: null,
    };
  },

  getFeatureFlags: async ({moduleEnumList}) => {
    const {response, error} = await goldApiService({
      resource: LAYOUT_ENDPOINT.GET_FEATURE_FLAGS,
      options: {
        queryParams: {
          moduleEnumList,
        },
        auth: false,
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {
      response,
      error: null,
    };
  },
  getLenderDashboardList: async (payload) => {
    const {response, error} = await goldApiService({
      resource: LAYOUT_ENDPOINT.GET_LENDER_DASHBOARD_LIST,
    });

    if (error) {
      return {error, response: []};
    }

    const subMenu = response.submenuList.map((r: any) => ({
      ...r,
      path: r.relativePath,
      tabs: r.tabList.map((t: any) => ({...t, path: t.relativePath})),
    }));

    return {
      response: {
        menuName: payload.menuName,
        submenu: subMenu,
      },
      error: null,
    };
  },
};

export default LayoutApi;

export {LAYOUT_ENDPOINT};
