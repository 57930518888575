import {RootState} from "../store";

const loanSummarySelectors = {
  getLoanSummaryList: (state: RootState) => state.loanSummary.loanSummaryList,
  getLoanSummary: (state: RootState, loanId: number) =>
    state.loanSummary.loanSummaryList.find((s) => s.loanId === loanId),
  getLenderLoanSummary: (state: RootState, igLoanId: number) =>
    state.loanSummary.loanSummaryList.find((s) => s.igLoanId === igLoanId),
  getLoading: (state: RootState) => state.loanSummary.loading,
};

export default loanSummarySelectors;
