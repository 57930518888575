import styled from "@emotion/styled";

const GoldenDivider = styled.div`
  border: solid 1px #ce9f29;

  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export default GoldenDivider;
