import React, {memo, useState} from "react";

import VideoModal from "./VideoModal";
import Modal from "../../../../../../../components/Modal";

import styles from "../../styles";

const Video = ({video, name}) => {
  const classes = styles();

  const [videoModal, setVideoModal] = useState(false);

  return (
    <>
      <Modal open={videoModal} setOpen={setVideoModal}>
        <VideoModal video={video} />
      </Modal>

      {video && (
        <div className={classes.textContainer}>
          <div className={classes.text}>{name}</div>
          <div onClick={() => setVideoModal(true)} className={classes.link}>
            Play Video
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Video, (prev, next) => {
  if (prev.video?.id === next.video?.id) {
    return true;
  }
  return false;
});
