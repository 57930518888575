import {createAction} from "@reduxjs/toolkit";
import {ClosureRequestInterface} from "app/models/closureRequest.model";
import {setPaginationFor} from "../factoryReducers";
import {ClosureListPayload, CrIdPayload} from "./goldRelease.types";

const goldReleaseActionTypes = {
  GetClosureList: "[GoldRelease] Get Closure List",
  GetClosureListSuccess: "[GoldRelease] Get Closure List Success",
  GetClosureListFailure: "[GoldRelease] Get Closure List Failure",
  GetHandoverImages: "[GoldRelease] Get Handover Images",
  GetHandoverImagesSuccess: "[GoldRelease] Get Handover Images Success",
  GetHandoverImagesFailure: "[GoldRelease] Get Handover Images Failure",
};

const lenderLoanActions = {
  getClosureList: createAction<ClosureListPayload>(
    goldReleaseActionTypes.GetClosureList,
  ),
  getClosureListSuccess: createAction<{ response: ClosureRequestInterface[] }>(
    goldReleaseActionTypes.GetClosureListSuccess,
  ),
  getClosureListFailure: createAction<{ error: string }>(
    goldReleaseActionTypes.GetClosureListFailure,
  ),
  getHandoverImages: createAction<CrIdPayload>(
    goldReleaseActionTypes.GetHandoverImages,
  ),
  getHandoverImagesSuccess: createAction<{
    response: {
      goldHandoverImageUrl: string;
      signedPledgeCardImages: string[];
    };
    crId: number;
  }>(goldReleaseActionTypes.GetHandoverImagesSuccess),
  getHandoverImagesFailure: createAction<{ error: string }>(
    goldReleaseActionTypes.GetHandoverImagesFailure,
  ),
  setLenderLoansPagination: setPaginationFor("goldRelease"),
};
export default lenderLoanActions;

export {goldReleaseActionTypes};
