import React from "react";
import {Toolbar} from "@mui/material";
import {Link} from "react-router-dom";
import {
  StyledAppBar,
  StyledHeaderContainer,
  StyledIconButton,
  StyledTabButton,
  StyledNotifProfileContainer,
} from "./styled";
import UserProfile from "./UserProfile";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import NotificationDrawer from "./NotificationDrawer";
import Logo from "assets/Logo";
import ServerSwitch from "./ServerSwitch";

const NavBar = ({open, activeTab, setActiveTab}) => {
  const user = useAppSelector(authSelectors.getUser);

  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar>
        <StyledIconButton>
          <Logo />
        </StyledIconButton>
        <StyledHeaderContainer>
          <div>
            {user?.products?.length > 0 &&
              user.products.map((product) => {
                return (
                  <Link key={product.path} to={`/${product.path}`}>
                    <StyledTabButton
                      key={product.path}
                      variant="outlined"
                      active={activeTab.path === product.path}
                      onClick={() => setActiveTab(product.path)}
                    >
                      {product.displayName}
                    </StyledTabButton>
                  </Link>
                );
              })}
          </div>
          <StyledNotifProfileContainer>
            <NotificationDrawer />
              <ServerSwitch />
            <UserProfile showAvatar showHi />
          </StyledNotifProfileContainer>
        </StyledHeaderContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavBar;
