import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import loanRenewRebookActions from "./loanRenewalRebook.actions";
import {loanRenewalRebookActionTypes} from "./loanRenewalRebook.actions";
import {
  LoanRenewalRebookResponse,
  GetLoanRenewalRebookMilestonesPayload,
  ServiceRequestIdPayload,
  UpdateLoanRenewalRebookMilestonePayload,
  DeleteLoanDocumentPayload,
  CreateLoanDocumentPayload,
  DocumentListByMilestonePayload,
  GetLoanRenewalOfferPayload,
  CrIdPayload,
  GetDigitalDocumentLink,
  LoanRenewalFeatureFlagPayload,
  CrJobIdPayload,
  PostLenderGatingPayload,
  GetEncryptedDocumentDetail,
} from "./loanRenewalRebook.types";
import {
  setAutoLoanRenewalStatus,
  setLoanRenewalRebookError,
  setLoanRenewalRebookLoading,
  toggleModals,
} from "./loanRenewalRebook.reducer";
import {snackBarActions} from "app/store/snackbar";
import {personalDetailsActions} from "./personalDetails";
import {activeLoansActions} from "../activeLoans";
import {transactionsActions} from "../transactions";
import {AutoLoanRenewalStatusEnum} from "app/pages/home/GoldLoan/ServiceDesk/Services/ManageRenewRebookRequest/CloseAndRenew/LoanRenewalRebook/config";

const {
  getloanRenewRebookMilestonesSuccess,
  getloanRenewRebookMilestonesFailure,
  checkLoanRenewalAllowedSuccess,
  checkLoanRenewalAllowedFailure,
  getloanRenewRebookJobDetailsSuccess,
  getloanRenewRebookJobDetailsFailure,
  createRenewRebookJobSuccess,
  createRenewRebookJobFailure,
  getloanRenewRebookJobDetails,
  getloanRenewRebookConfigSuccess,
  getloanRenewRebookConfigFailure,
  updateLoanRenewRebookMilestoneSuccess,
  updateLoanRenewRebookMilestoneFailure,
  getloanRenewRebookMilestones,
  deleteLoanDocumentSuccess,
  deleteLoanDocumentFailure,
  createLoanDocumentSuccess,
  createLoanDocumentFailure,
  getDocumentListByMilestoneSuccess,
  getDocumentListByMilestoneFailure,
  getLoanRenewalOfferSuccess,
  getLoanRenewalOfferFailure,
  postLenderGatingSuccess,
  postLenderGatingFailure,
  getLenderGatingStatusSuccess,
  getLenderGatingStatusFailure,
  getLenderGatingStatus,
  getLoanRenewalFlagStatusSuccess,
  getLoanRenewalFlagStatusFailure,
  closeRenewalLoanSuccess,
  closeRenewalLoanFailure,
  getLoanConfigDetailsSuccess,
  getLoanConfigDetailsFailure,
  getRenewRebookLoanDetailsSuccess,
  getRenewRebookLoanDetailsFailure,
  assignLoanRenewalJobSuccess,
  assignLoanRenewalJobFailure,
  unAssignLoanRenewalJobSuccess,
  unAssignLoanRenewalJobFailure,
  updateLoanRenewRebookMilestone,
  getEncryptedDocumentDetailSuccess,
  getEncryptedDocumentDetailFailure,
  getLoanCreationStatusSuccess,
  getLoanCreationStatusFailure,
  getAutoLoanRenewalStatus,
} = loanRenewRebookActions;

const  {SUCCESS, FAILED} = AutoLoanRenewalStatusEnum;

function* generalErrorFlow(action: PayloadAction<LoanRenewalRebookResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* DocumentDeleteSuccessFlow(action: PayloadAction<LoanRenewalRebookResponse>) {
  yield put(
    snackBarActions.open({
      message: "Document has been successfully deleted!",
      variant: "success",
    }),
  );
}

function* getLoanRenewRebookMilestonesFlow(
  action: PayloadAction<GetLoanRenewalRebookMilestonesPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getLoanRenewalRebookMilestones,
    action.payload,
  );
  if (error) {
    yield put(getloanRenewRebookMilestonesFailure({response: null, error}));
  } else {
    yield put(getloanRenewRebookMilestonesSuccess({response, error: null}));
  }
}

function* getLoanRenewRebookConfigFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getLoanRenewalConfig);
  if (error) {
    yield put(getloanRenewRebookConfigFailure({response: null, error}));
  } else {
    yield put(getloanRenewRebookConfigSuccess({response, error: null}));
  }
}

function* checkLoanRenewalAllowedFlow(action: PayloadAction<ServiceRequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.checkIsRenewRebookAllowed,
    action.payload,
  );
  if (error) {
    yield put(checkLoanRenewalAllowedFailure({response: null, error}));
  } else {
    yield put(checkLoanRenewalAllowedSuccess({response, error: null}));
  }
}
function* getLoanRenewRebookJobDetailsFlow(action: PayloadAction<ServiceRequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getLoanRenewalJobDetails,
    action.payload,
  );
  if (error) {
    yield put(getloanRenewRebookJobDetailsFailure({response: null, error}));
  } else {
    yield put(getloanRenewRebookJobDetailsSuccess({response, error: null}));
  }
}

function* updateLoanRenewRebookMilestoneFlow(
  action: PayloadAction<UpdateLoanRenewalRebookMilestonePayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.updateMileStoneStatus, action.payload);
  if (error) {
    yield put(updateLoanRenewRebookMilestoneFailure({response: null, error}));
  } else {
    yield put(updateLoanRenewRebookMilestoneSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Successfully Details have been Updated!",
        variant: "success",
      }),
    );
    yield put(
      getloanRenewRebookMilestones({
        crId: action.payload.crId,
      }),
    );
  }
}

function* deleteLoanDocumentFlow(action: PayloadAction<DeleteLoanDocumentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.deleteLoanDocument, action.payload);
  if (error) {
    yield put(deleteLoanDocumentFailure({response: null, error}));
  } else {
    yield put(deleteLoanDocumentSuccess({response, error: null}));
    yield put(
      loanRenewRebookActions.getDocumentListByMilestone({
        crId: action.payload.crId,
        crMilestoneEnum: action.payload.mileStoneName,
      }),
    );
  }
}

function* createLoanDocumentFlow(action: PayloadAction<CreateLoanDocumentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.createLoanDocument, action.payload);
  if (error) {
    yield put(createLoanDocumentFailure({response: null, error}));
  } else {
    yield put(createLoanDocumentSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Document has been successfully uploaded!",
        variant: "success",
      }),
    );
    yield put(
      loanRenewRebookActions.getDocumentListByMilestone({
        crId: action.payload.crId,
        crMilestoneEnum: action.payload.mileStoneName,
      }),
    );
  }
}

function* createRenewRebookJobFlow(action: PayloadAction<ServiceRequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.createLoanRenewalJob, action.payload);
  if (error) {
    yield put(createRenewRebookJobFailure({response: null, error}));
  } else {
    yield put(createRenewRebookJobSuccess({response, error: null}));
    yield put(
      activeLoansActions.getInsuranceFeatureFlag({
        crId: response?.crId,
      }),
    );
    yield put(
      getloanRenewRebookJobDetails({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
}

function* getDocumentsListByMilestoneFlow(action: PayloadAction<DocumentListByMilestonePayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getDocumentListByMilestone,
    action.payload,
  );
  if (error) {
    yield put(getDocumentListByMilestoneFailure({response: null, error}));
  } else {
    yield put(getDocumentListByMilestoneSuccess({response, error: null}));
    if (response.length > 0) {
      yield put(
        personalDetailsActions.getKycDocuments({
          crId: action.payload.crId,
          documentTypeList: response,
        }),
      );
    }
  }
}

function* getLoanRenewalOfferFlow(action: PayloadAction<GetLoanRenewalOfferPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getLoanRenewalOffer, action.payload);
  if (error) {
    yield put(getLoanRenewalOfferFailure({response: null, error}));
  } else {
    yield put(getLoanRenewalOfferSuccess({response, error: null}));
  }
}

function* closeRenewalLoanFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.closeRenewalLoan, action.payload);
  if (error) {
    yield put(closeRenewalLoanFailure({response: null, error}));
  } else {
    yield put(closeRenewalLoanSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Loan has been successfully closed!",
        variant: "success",
      }),
    );
    location.reload();
  }
}

function* getLenderGatingStatusFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getLenderGatingStatus, action.payload);
  if (error) {
    yield put(getLenderGatingStatusFailure({response: null, error}));
  } else {
    yield put(getLenderGatingStatusSuccess({response, error: null}));
  }
}

function* getLoanRenewalFeatureFlagFlow(action: PayloadAction<LoanRenewalFeatureFlagPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getFeatureFlagStatus, action.payload);
  if (error) {
    yield put(getLoanRenewalFlagStatusFailure({response: null, error}));
  } else {
    yield put(getLoanRenewalFlagStatusSuccess({response, error: null}));
  }
}

function* getRenewRebookLoanDetailFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getRenewRebookLoanDetail,
    action.payload,
  );
  if (error) {
    yield put(getRenewRebookLoanDetailsFailure({response: null, error}));
  } else {
    yield put(getRenewRebookLoanDetailsSuccess({response, error: null}));
  }
}

function* getLoanConfigDetailsFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getLoanConfigDetails, action.payload);
  if (error) {
    yield put(getLoanConfigDetailsFailure({response: null, error}));
  } else {
    yield put(getLoanConfigDetailsSuccess({response, error: null}));
  }
}

function* getEncryptedDocumentDetailFlow(action: PayloadAction<GetEncryptedDocumentDetail>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getEncryptedDocumentDetail,
    action.payload,
  );
  if (error) {
    yield put(getEncryptedDocumentDetailFailure({response: null, error}));
  } else {
    yield put(getEncryptedDocumentDetailSuccess({response, error: null}));
  }
}

function* getLoanCreationStatusFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.getLoanCreationStatus, action.payload);
  if (error) {
    yield put(getLoanCreationStatusFailure({response: null, error}));
  } else {
    yield put(getLoanCreationStatusSuccess({response, error: null}));
  }
}

function* assignLoanRenewalJobFlow(action: PayloadAction<CrJobIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.assignLoanRenewalJob, action.payload);
  if (error) {
    yield put(assignLoanRenewalJobFailure({response: null, error}));
  } else {
    yield put(assignLoanRenewalJobSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Job has been successfully assigned!",
        variant: "success",
      }),
    );
    yield put(
      getloanRenewRebookJobDetails({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
}

function* unAssignLoanRenewalJobFlow(action: PayloadAction<CrJobIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.unAssignLoanRenewalJob,
    action.payload,
  );
  if (error) {
    yield put(unAssignLoanRenewalJobFailure({response: null, error}));
  } else {
    yield put(unAssignLoanRenewalJobSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Job has been successfully unassigned!",
        variant: "success",
      }),
    );
    yield put(
      getloanRenewRebookJobDetails({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
}

function* getAutoLoanRenewalStatusFlow(action: PayloadAction<CrIdPayload>) {
  yield put(
    setLoanRenewalRebookLoading({
      key: "getAutoLoanRenewalStatus",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.getAutoLoanRenewalStatus,
    action.payload,
  );
  if (error) {
    yield put(
      setLoanRenewalRebookError({
        key: "getAutoLoanRenewalStatus",
        value: error,
      }),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    if (response?.failureReason) {
      yield put(
        snackBarActions.open({
          message: response?.failureReason,
          variant: "error",
          autoHideDuration: null,
        }),
      );
    }

    if (response?.status === SUCCESS || response?.status === FAILED) {
      yield put(
        loanRenewRebookActions.getloanRenewRebookMilestones({
          crId: action.payload.crId,
        }),
      );
      yield put(
        loanRenewRebookActions.getLenderGatingStatus({
          crId: action.payload.crId,
        }),
      );
      yield put(
        activeLoansActions.getLatestStatus({
          crId: action.payload.crId,
          crJobType: "CM_RENEW_REBOOK_LOAN",
          isRenewRebook: true,
        }),
      );
      yield put(
        transactionsActions.getTransactionsList({
          crId: action.payload.crId,
        }),
      );
    }

    yield put(
      setAutoLoanRenewalStatus({
        response,
      }),
    );
  }
  yield put(
    setLoanRenewalRebookLoading({
      key: "getAutoLoanRenewalStatus",
      value: false,
    }),
  );
}

function* createAutoLoanRenewalJobFlow(action: PayloadAction<ServiceRequestIdPayload>) {
  yield put(
    setLoanRenewalRebookLoading({
      key: "createAutoLoanRenewalJob",
      value: true,
    }),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanRenewRebook.createAutoLoanRenewalJob,
    action.payload,
  );
  if (error) {
    yield put(
      setLoanRenewalRebookError({
        key: "createAutoLoanRenewalJob",
        value: error,
      }),
    );
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      getloanRenewRebookJobDetails({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
  yield put(
    setLoanRenewalRebookLoading({
      key: "createAutoLoanRenewalJob",
      value: false,
    }),
  );
}

function* postLenderGatingFlow(action: PayloadAction<PostLenderGatingPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.loanRenewRebook.postLenderGating, action.payload);
  if (error) {
    yield put(postLenderGatingFailure({response: null, error}));
  } else {
    yield put(postLenderGatingSuccess({response, error: null}));
    yield put(
      toggleModals({
        type: "loanCheckerModal",
        value: false,
      }),
    );
    yield put(
      updateLoanRenewRebookMilestone({
        crId: action.payload.crId,
        crJobId: action.payload.crJobId,
        crJobStatus: action.payload.crJobStatus,
      }),
    );
    // location.reload();
    yield put(getLenderGatingStatus({crId: action.payload.crId}));
  }
}

export default function* loanRenewalRebookSaga() {
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewRebookMilestones,
    getLoanRenewRebookMilestonesFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewRebookMilestonesFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.CheckLoanRenewalAllowed,
    checkLoanRenewalAllowedFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.CheckLoanRenewalAllowedFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewRebookJobDetails,
    getLoanRenewRebookJobDetailsFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewRebookJobDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.CreateLoanRenewRebookJob, createRenewRebookJobFlow);
  yield takeLatest(loanRenewalRebookActionTypes.CreateLoanRenewRebookJobFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewRebookConfig,
    getLoanRenewRebookConfigFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.DeleteLoanDocument, deleteLoanDocumentFlow);
  yield takeLatest(loanRenewalRebookActionTypes.CreateLoanDocument, createLoanDocumentFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.DeleteLoanDocumentSuccess,
    DocumentDeleteSuccessFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.CreateLoanDocumentFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.DeleteLoanDocumentFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanRenewRebookConfigFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.UpdateLoanRenewRebookMilestone,
    updateLoanRenewRebookMilestoneFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.UpdateLoanRenewRebookMilestoneFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.GetDocumentListByMilestone,
    getDocumentsListByMilestoneFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.GetDocumentListByMilestoneFailure,
    generalErrorFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanRenewalOffer, getLoanRenewalOfferFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanRenewalOfferFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.PostLenderGating, postLenderGatingFlow);
  yield takeLatest(loanRenewalRebookActionTypes.PostLenderGatingFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLenderGatingStatus, getLenderGatingStatusFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLenderGatingStatusFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.GetLoanRenewalFeatureFlag,
    getLoanRenewalFeatureFlagFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanRenewalFeatureFlagFailure, generalErrorFlow);

  yield takeLatest(loanRenewalRebookActionTypes.CloseRenewalLoan, closeRenewalLoanFlow);
  yield takeLatest(loanRenewalRebookActionTypes.CloseRenewalLoanFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanConfigDetails, getLoanConfigDetailsFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanConfigDetailsFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.GetRenewRebookLoanDetails,
    getRenewRebookLoanDetailFlow,
  );
  yield takeLatest(loanRenewalRebookActionTypes.GetRenewRebookLoanDetailsFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.AssignLoanRenewalJob, assignLoanRenewalJobFlow);
  yield takeLatest(loanRenewalRebookActionTypes.AssignLoanRenewalJobFailure, generalErrorFlow);
  yield takeLatest(loanRenewalRebookActionTypes.UnAssignLoanRenewalJob, unAssignLoanRenewalJobFlow);
  yield takeLatest(loanRenewalRebookActionTypes.UnAssignLoanRenewalJobFailure, generalErrorFlow);

  yield takeLatest(
    loanRenewalRebookActionTypes.GetEncryptedDocumentDetail,
    getEncryptedDocumentDetailFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.GetEncryptedDocumentDetailFailure,
    generalErrorFlow,
  );

  yield takeLatest(loanRenewalRebookActionTypes.GetLoanCreationStatus, getLoanCreationStatusFlow);
  yield takeLatest(loanRenewalRebookActionTypes.GetLoanCreationStatusFailure, generalErrorFlow);
  yield takeLatest(
    loanRenewalRebookActionTypes.GetAutoLoanRenewalStatus,
    getAutoLoanRenewalStatusFlow,
  );
  yield takeLatest(
    loanRenewalRebookActionTypes.CreateAutoLoanRenewalJob,
    createAutoLoanRenewalJobFlow,
  );
}
