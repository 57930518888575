import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import IGDialog from "app/components/IGDialog";

interface CloseCRModalProps {
  open: boolean
  crId: number
}

const CloseCRDialog: FC<CloseCRModalProps> = ({open, crId}) => {

  const dispatch = useAppDispatch();
  const {closeCR: closeCRLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  const onClose = () => {
    dispatch(toggleModals({
      type: "closeCRDialog",
      value: false,
    }));
  };

  const closeCustomerRequest = () => {
    dispatch(activeLoansActions.closeCR(crId));
  };

  return (
    <IGDialog
      open={open}
      title="CLOSE CUSTOMER REQUEST"
      onClose={onClose}
      onConfirm={closeCustomerRequest}
      onCancel={onClose}
      confirmProps={{
        text: "Yes, I'm sure",
        loading: closeCRLoading,
      }}
      cancelProps={{
        text: "No, I will check impact first",
      }}
    >
      Are you sure you want to close customer request with id #{crId}?
    </IGDialog>
  );
};

export default CloseCRDialog;