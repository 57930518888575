import React from "react";
import styles from "../styles";

const SidebarHeader = ({textValue, style}) => {
  const classes = styles();
  return (
    <p className={classes.textStyle} style={style}>
      {textValue}
    </p>
  );
};

export default SidebarHeader;
