import styled from "@emotion/styled";
import {Box} from "@mui/material";

export const IGTableWrapper = styled(Box)`
  display: flex;
  height: 100%;
  flex-grow: 1;
  
  .ig-table--header {
    background: #e7e6e9;
  }
`;