import * as yup from "yup";

import useStringValidations from "./useStringValidations";
import useNumberValidations from "./useNumberValidations";
import useDateValidations from "./useDateValidations";

interface SchemaInterface extends Record<string,
  yup.StringSchema | yup.NumberSchema | yup.DateSchema> {
}

export interface MessageInterface {
  message?: string;
}

export interface ParamsInterface<T> extends MessageInterface {
  value: T;
}

const useIGValidations = (
  schema: SchemaInterface,
) => {
  return yup.object().shape(schema);
};

export {
  useIGValidations,
  useStringValidations,
  useNumberValidations,
  useDateValidations,
};