import {RootState} from "../../store";

const agentAvailabilitySelectors = {
  getAgents: (state: RootState) =>
    Object.values(state.serviceDesk.agentAvailability.agentsById),
  getAgentScheduleList: (state: RootState) =>
    Object.values(state.serviceDesk.agentAvailability.agentScheduleById),
  getLoading: (state: RootState) =>
    state.serviceDesk.agentAvailability.loading,
  getError: (state: RootState) =>
    state.serviceDesk.agentAvailability.error,
  getModal: (state: RootState) =>
    state.serviceDesk.agentAvailability.modal,
  getSelectedJob: (state: RootState) =>
    state.serviceDesk.agentAvailability.selectedJob,
  getSelectedTimeSlot: (state: RootState) =>
    state.serviceDesk.agentAvailability.selectedTimeSlot,
};

export default agentAvailabilitySelectors;
