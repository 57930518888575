import moment from "moment-timezone";

const time_zone = "Asia/Kolkata";

function formatDate(date, time = true, type = "") {
  const moment_date = moment(date).tz(time_zone);
  if (type === "PROMO") {
    return moment_date.isValid()
      ? moment_date.format("YYYY-MM-DDTHH:mm")
      : "N/A";
  }
  if (type === "SERVICE_DESK") {
    return moment_date.isValid()
      ? moment_date.format("DD/MM/YYYY, hh:mm A")
      : "N/A";
  }

 if (type === "CHECKER" && !time) {
   return moment_date.isValid()
     ? moment_date.format("DD/MM/YYYY")
     : "N/A";
 }
  if (type === "LOAN_SERVICING") {
    return moment_date.isValid()
      ? moment_date.toDate()
      : moment().startOf("day").toDate();
  }
  if (time) return moment_date.isValid()
      ? moment_date.format("DD-MM-YYYY HH:mm:ss")
      : "N/A";
  return moment_date.isValid() ? moment_date.format("YYYY-MM-DD") : "N/A";
}

function formatCustomDateTime(date, format) {
  return moment(date).isValid()
    ? moment(date)
        .tz(time_zone)
        .format(format)
    : "N/A";
}

// get current time in ms
function getCurrentTime() {
  return moment().tz(time_zone);
}

function getValidTime(date, format) {
  if (format) return moment(date, format).tz(time_zone);
  return moment(date).tz(time_zone);
}

export {formatDate, formatCustomDateTime, getCurrentTime, getValidTime};
