import {BankDetailsInterface} from "./../../../models/loanRenewalRebook/bankDetails.model";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import bankDetailsActions from "./bankDetails.actions";

type LoadingErrorState =
  | "getBankDetails"
  | "updateBankDetails"
  | "verifyBankDetails";

export interface BankDetailsState {
  bankDetails: BankDetailsInterface | null;
  loading: Record<LoadingErrorState, boolean>;
  openModals: {
    addNewBankAccount: boolean;
    pennyDropVerificationModal: boolean;
  };
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: BankDetailsState = {
  bankDetails: null,
  loading: {
    getBankDetails: false,
    updateBankDetails: false,
    verifyBankDetails: false,
  },
  openModals: {
    addNewBankAccount: false,
    pennyDropVerificationModal: false,
  },
  errors: {
    getBankDetails: null,
    updateBankDetails: null,
    verifyBankDetails: null,
  },
};

const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
  },
  extraReducers: (builder) =>
    builder

      .addCase(bankDetailsActions.getBankDetails, (state) => {
        state.loading.getBankDetails = true;
        state.errors.getBankDetails = initialState.errors.getBankDetails;
      })
      .addCase(bankDetailsActions.getBankDetailsSuccess, (state, action) => {
        state.bankDetails = action.payload.response;
        state.loading.getBankDetails = false;
      })
      .addCase(bankDetailsActions.getBankDetailsFailure, (state, action) => {
        state.loading.getBankDetails = false;
        state.errors.getBankDetails = action.payload.error;
      })
      .addCase(bankDetailsActions.updateBankDetails, (state) => {
        state.loading.updateBankDetails = true;
        state.errors.updateBankDetails = initialState.errors.updateBankDetails;
      })
      .addCase(bankDetailsActions.updateBankDetailsSuccess, (state, action) => {
        state.loading.updateBankDetails = false;
        state.openModals.pennyDropVerificationModal = true;
      })
      .addCase(bankDetailsActions.updateBankDetailsFailure, (state, action) => {
        state.loading.updateBankDetails = false;
        state.errors.updateBankDetails = action.payload.error;
      })
      .addCase(bankDetailsActions.verifyBankDetails, (state) => {
        state.loading.verifyBankDetails = true;
        state.errors.verifyBankDetails = initialState.errors.verifyBankDetails;
      })
      .addCase(bankDetailsActions.verifyBankDetailsSuccess, (state, action) => {
        state.bankDetails = action.payload.response;
        state.loading.verifyBankDetails = false;
      })
      .addCase(bankDetailsActions.verifyBankDetailsFailure, (state, action) => {
        state.loading.verifyBankDetails = false;
        state.errors.verifyBankDetails = action.payload.error;
      }),
});

export default bankDetailsSlice.reducer;

const {toggleModals} = bankDetailsSlice.actions;

export {toggleModals};
