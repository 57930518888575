import {
  OccupationDetailsInterface,
  WorkSectorInterface,
  WorkSectorDetailsInterface,
  BorrowerCategory,
  IndustryType,
  WeakerCategory,
  LoanPurpose,
  Occupation,
  SubSector,
} from "app/models/loanRenewalRebook/occupationDetails.model";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import occupationDetailsActions from "./occupationDetails.actions";

type LoadingErrorState =
  | "getOccupationDetails"
  | "updateOccupationDetails"
  | "getWorkSectorDetails"
  | "postLoanPurposeReason";

export interface OccupationDetailsState {
  occupationDetails: OccupationDetailsInterface | null;
  borrowerCategoryList: BorrowerCategory[];
  industryTypeList: IndustryType[];
  loanPurposeList: LoanPurpose[];
  occupationList: Occupation[];
  subSectorList: SubSector[];
  weakerCategoryList: WeakerCategory[];
  workSectorDetails: WorkSectorDetailsInterface[];
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: OccupationDetailsState = {
  occupationDetails: null,
  workSectorDetails: [],
  borrowerCategoryList: [],
  industryTypeList: [],
  loanPurposeList: [],
  occupationList: [],
  subSectorList: [],
  weakerCategoryList: [],
  loading: {
    getOccupationDetails: false,
    updateOccupationDetails: false,
    getWorkSectorDetails: false,
    postLoanPurposeReason: false,
  },

  errors: {
    getOccupationDetails: null,
    updateOccupationDetails: null,
    getWorkSectorDetails: null,
    postLoanPurposeReason: null,
  },
};

const occupationDetailsSlice = createSlice({
  name: "occupationDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder

      .addCase(occupationDetailsActions.getOccupationDetails, (state) => {
        state.loading.getOccupationDetails = true;
        state.errors.getOccupationDetails =
          initialState.errors.getOccupationDetails;
      })
      .addCase(
        occupationDetailsActions.getOccupationDetailsSuccess,
        (state, action) => {
          state.occupationDetails = action.payload.response;
          state.loading.getOccupationDetails = false;
        },
      )
      .addCase(
        occupationDetailsActions.getOccupationDetailsFailure,
        (state, action) => {
          state.loading.getOccupationDetails = false;
          state.errors.getOccupationDetails = action.payload.error;
        },
      )
      .addCase(occupationDetailsActions.updateOccupationDetails, (state) => {
        state.loading.updateOccupationDetails = true;
        state.errors.updateOccupationDetails =
          initialState.errors.updateOccupationDetails;
      })
      .addCase(
        occupationDetailsActions.updateOccupationDetailsSuccess,
        (state, action) => {
          state.loading.updateOccupationDetails = false;
        },
      )
      .addCase(
        occupationDetailsActions.updateOccupationDetailsFailure,
        (state, action) => {
          state.loading.updateOccupationDetails = false;
          state.errors.updateOccupationDetails = action.payload.error;
        },
      )
      .addCase(occupationDetailsActions.getWorkSectorDetails, (state) => {
        state.loading.getWorkSectorDetails = true;
        state.errors.getWorkSectorDetails =
          initialState.errors.getWorkSectorDetails;
      })
      .addCase(
        occupationDetailsActions.getWorkSectorDetailsSuccess,
        (state, action) => {
          const {
            borrowerCategoryList,
            industryTypeList,
            loanPurposeList,
            occupationList,
            subSectorList,
            weakerCategoryList,
          } = action.payload.response;

          state.borrowerCategoryList = borrowerCategoryList;
          state.industryTypeList = industryTypeList;
          state.loanPurposeList = loanPurposeList;
          state.occupationList = occupationList;
          state.subSectorList = subSectorList;
          state.weakerCategoryList = weakerCategoryList;
          state.loading.getWorkSectorDetails = false;
        },
      )
      .addCase(
        occupationDetailsActions.getWorkSectorDetailsFailure,
        (state, action) => {
          state.loading.getWorkSectorDetails = false;
          state.errors.getWorkSectorDetails = action.payload.error;
        },
      )
      .addCase(occupationDetailsActions.postLoanPurposeReason, (state) => {
        state.loading.postLoanPurposeReason = true;
        state.errors.postLoanPurposeReason =
          initialState.errors.postLoanPurposeReason;
      })
      .addCase(
        occupationDetailsActions.postLoanPurposeReasonSuccess,
        (state) => {
          state.loading.postLoanPurposeReason = false;
        },
      )
      .addCase(
        occupationDetailsActions.postLoanPurposeReasonFailure,
        (state, action) => {
          state.loading.postLoanPurposeReason = false;
          state.errors.postLoanPurposeReason = action.payload.error;
        },
      ),
});

export default occupationDetailsSlice.reducer;
