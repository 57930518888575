import {CircularProgress} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";

// import getCommunicationDetails from "../../../../../../../../api/business/getCommunicationDetails";
import getPersonalDetail from "../../../../../../../api/business/getPersonalDetail";
import styles from "../styles";

const CommunicationDetails = ({
  selectedPayload,
  currentMileStone,
  otherRequestTimer,
}) => {
  const counter = useRef(0);
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
    payload: {
      communicationDetails: null,
    },
  });

  const getDetails = useCallback(async () => {
    try {
      const [response] = await Promise.all([
        getPersonalDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          personalDetailType: "LOAN_PERSONAL_DETAIL",
        }),
      ]);

      if (response.statusCode === 200) {
        setStates({
          error: false,
          errorMessage: "",
          loading: false,
          payload: {
            communicationDetails: response.payload,
          },
        });
      } else {
        setStates({
          error: true,
          errorMessage: response.error.toString(),
          loading: false,
          payload: {
            communicationDetails: null,
          },
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went Wrong",
        loading: false,
        payload: {
          communicationDetails: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getDetails, otherRequestTimer]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const list = [
    {
      text: "PREFERRED MODE",
      subText: states.payload.communicationDetails.preferredCommunicationMode,
    },
  ];

  if (states.payload.communicationDetails.primaryEmail !== null) {
    list.push({
      text: "EMAIL ID",
      subText: states.payload.communicationDetails.primaryEmail,
    });
  }

  if (states.payload.communicationDetails.primaryPhoneNumber !== null) {
    list.push({
      subText: `+${states.payload.communicationDetails.primaryPhoneCode} ${states.payload.communicationDetails.primaryPhoneNumber}`,
      text: "Mobile Number",
    });
  }
  return (
    <>
      {list.map((text) => (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>{text.text}</div>

            <div className={classes.subText}>{text.subText}</div>
          </div>
        </>
      ))}
    </>
  );
};

export default CommunicationDetails;
