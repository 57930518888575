import ROUTES from "app/constants/routes";
import {allFeatures, Feature} from "./feature";
import {FeatureData} from "app/AppLayout/getAllComponents";
import {MenuIconProps} from "app/AppLayout/Icons";
import {DynamicRoutesInterface} from "../typings/api/goldApi.types";

export interface SubMenuComponent {
  name: string;
  path: string;
  icon?: React.FC<MenuIconProps>;
  enum?: string;
  component: (props?: any) => JSX.Element;
}

export interface MenuComponent {
  name: string;
  icon: React.FC<MenuIconProps>;
  path: string;
  id?: string;
  enum?: string;
  isMultiMenu: boolean;
  subMenu: SubMenuComponent[] | never[];
  component: (props?: any) => JSX.Element;
  dynamicSubMenu?: boolean;
  generateSubMenu?: (subMenu: any) => SubMenuComponent[];
}

export interface Product {
  id: number;
  name: string;
  displayName: string;
  path: string;
  defaultFeature?: Feature;
  availableFeatures: Feature[];
  getMenu?: (
    userRoleList: string[],
    dynamicRoutes?: DynamicRoutesInterface | null,
  ) => MenuComponent[];
}

export interface Products {
  [key: string]: Product;
}

function generateSubMenu(
  menu: MenuComponent,
  dynamicRoutes: DynamicRoutesInterface | null,
): SubMenuComponent[] | [] {
  if (
    dynamicRoutes &&
    menu.id &&
    menu.id in dynamicRoutes &&
    menu.generateSubMenu
  ) {
    return menu.generateSubMenu(dynamicRoutes[menu.id]);
  }
  return [];
}

function getMenu(
  this: Product,
  userRoleList: string[],
  dynamicRoutes?: DynamicRoutesInterface | null,
) {
  const availableFeatures = this.availableFeatures
    .filter((f) =>
      userRoleList.some((role) => f.whitelistedRoles.includes(role)),
    )
    .map((f) => f.path);

  const productMenuList = FeatureData.filter((f: MenuComponent) =>
    availableFeatures.includes(f.path),
  );

  productMenuList.forEach((menu) => {
    if (dynamicRoutes && "dynamicSubMenu" in menu && menu.dynamicSubMenu) {
      const subMenu = generateSubMenu(menu, dynamicRoutes);
      const staticMenu = menu.subMenu?.filter((f: SubMenuComponent) => {
        const subMenuParent = this.availableFeatures.find(
          (f) => f.path === menu.path,
        );
        const availableSubFeature = subMenuParent?.subFeatures?.find(
          (sf) => sf.path === f.path,
        );
        if (!availableSubFeature || !availableSubFeature.whitelistedRoles) {
          return false;
        }
        return userRoleList.some((role) =>
          availableSubFeature.whitelistedRoles.includes(role),
        );
      }) || [];

      menu.isMultiMenu = subMenu.length > 0 || staticMenu.length > 0;
      menu.subMenu = [
        ...staticMenu,
        ...subMenu,
      ];
      return menu;
    }

    if (!menu.isMultiMenu) {
      return menu;
    }

    menu.subMenu = menu.subMenu.filter((f: SubMenuComponent) => {
      const subMenuParent = this.availableFeatures.find(
        (f) => f.path === menu.path,
      );
      const availableSubFeature = subMenuParent?.subFeatures?.find(
        (sf) => sf.path === f.path,
      );

      if (!availableSubFeature || !availableSubFeature.whitelistedRoles) {
        return false;
      }

      return userRoleList.some((role) =>
        availableSubFeature.whitelistedRoles.includes(role),
      );
    });
  });

  return productMenuList;
}

const products: Products = {
  digitalGold: {
    id: 1,
    name: "DIGITAL_GOLD",
    displayName: "Digital Gold",
    path: ROUTES.DIGITAL_GOLD,
    availableFeatures: [
      allFeatures.emiOrder,
      allFeatures.dgOrder,
      allFeatures.giftCardConfig,
      allFeatures.userTierConfig,
      allFeatures.refferalConfig,
      allFeatures.users,
      allFeatures.payments,
      allFeatures.promotions,
      allFeatures.csv,
      allFeatures.support,
      allFeatures.internalUsers,
      // allFeatures.promos,
      allFeatures.analytics,
    ],
    defaultFeature: allFeatures.emiOrder,

    getMenu,
  },
  goldLoan: {
    id: 2,
    name: "GOLD_LOAN",
    displayName: "Gold Loan",
    path: ROUTES.GOLD_LOAN,
    availableFeatures: [
      allFeatures.scheduling,
      allFeatures.tracking,
      allFeatures.lmDistance,
      allFeatures.accounts,
      allFeatures.customerProfiles,
      allFeatures.activeLoans,
      allFeatures.leads,
      allFeatures.analytics,
      allFeatures.serviceDesk,
      allFeatures.mis,
      allFeatures.misLender,
      allFeatures.blacklistManagement,
      allFeatures.utilities,
      allFeatures.blacklistRemoval,
    ],
    defaultFeature: allFeatures.scheduling,

    getMenu,
  },

  partnerDashboard: {
    id: 3,
    name: "PARTNER_DASHBOARD",
    displayName: "Partner Dashboard",
    path: ROUTES.PARTNER_DASHBOARD,
    availableFeatures: [
      allFeatures.partnerLeadDashboard,
      allFeatures.partnerAnalytics,
    ],
    defaultFeature: allFeatures.partnerLeadDashboard,
    getMenu,
  },
  lenderPortal: {
    id: 4,
    name: "LENDER_PORTAL",
    displayName: "Lender Portal",
    path: ROUTES.LENDER_PORTAL,
    availableFeatures: [allFeatures.customerAnalytics, allFeatures.closure],
    getMenu,
  },
};

function getProductByName(name: string): Product {
  return Object.values(products).find((p) => p.name === name) as Product;
}

export {products, getProductByName, getMenu};
