import React, {FC} from "react";
import {Box} from "@mui/material";

interface IGSectionProps {
}

const IGSection: FC<IGSectionProps> = ({children}) => {

  // 64px is height of product header
  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 64px)">
      {children}
    </Box>
  );
};

export default IGSection;