import Bugsnag from "@bugsnag/js";

const BugsnagNotify = ({error, source, response}) => {
  Bugsnag.notify(error, (event) => {
    event.addMetadata("API", {
      Error: error,
      Source: source,
    });

    event.addMetadata(`${source}_RESPONSE`, {
      Response: response,
    });

    event.severity = "warning";
    event.context = error.toString();

    if (localStorage.getItem("id") !== null) {
      event.setUser(
        localStorage.getItem("id"),
        localStorage.getItem("email"),
        localStorage.getItem("name"),
      );
    }
  });
};

export default BugsnagNotify;
