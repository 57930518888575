import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  CIBIL_HTML: {
    URL: "/business/cr/:crId/credit-report-html",
    METHOD: "GET",
  },
  TAKEOVER_CIBIL_HTML: {
    URL: "/business/cr/:crId/takeover/credit-report-html",
    METHOD: "GET",
  },
};
export const getGoldLoanCreditReportHtml = async crId => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.CIBIL_HTML,
    options: {
      pathVars: {
        crId,
      },
    },
  });
  return response;
};
export const getGoldLoanTakeoverCreditReportHtml = async (crId) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.TAKEOVER_CIBIL_HTML,
    options: {
      pathVars: {
        crId,
      },
    },
  });
  return response;
};


