import React from "react";

import styles from "../styles";

const Closed = () => {
  const classes = styles();

  return (
    <div className={classes.textContainer}>
      <div className={classes.subText}>Closed</div>
    </div>
  );
};

export default Closed;
