import {goldApiService} from "app/infra/plugins/axios";
import {Method} from "axios";

interface AppEndpointsInterface {
  GET_FEATURE_FLAG_STATUS: {
    URL: string;
    METHOD: Method;
  };
  GET_FEATURE_FLAG_STATUS_LIST: {
    URL: string;
    METHOD: Method;
  };
}

export interface FeatureFlagStatusPayload {
  moduleEnum: string;
  moduleFeatureEnum: string;
}

const AppEndpoints: AppEndpointsInterface = {
  GET_FEATURE_FLAG_STATUS: {
    URL: "/config/app/is-feature-active",
    METHOD: "GET",
  },
  GET_FEATURE_FLAG_STATUS_LIST: {
    URL: "/config/app/is-feature-list-active",
    METHOD: "POST",
  },
};

const appApi = {
  getFeatureFlagStatus: async (payload: FeatureFlagStatusPayload) => {
    return await goldApiService({
      resource: AppEndpoints.GET_FEATURE_FLAG_STATUS,
      options: {
        queryParams: {...payload},
      },
    });
  },
  getFeatureFlagStatusList: async (payload: FeatureFlagStatusPayload[]) => {
    return await goldApiService({
      resource: AppEndpoints.GET_FEATURE_FLAG_STATUS_LIST,
      options: {
        data: payload,
      },
    });
  },
};

export default appApi;
export {AppEndpoints};
