import React, {useState, useMemo, useCallback, useEffect} from "react";
import {Box, FormHelperText, Typography} from "@mui/material";
import {IGButton, IGDialog, IGTextField} from "app/components";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import getProcessingFeeConfig from "api/activeLoan/getProcessingFeeConfig";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import {useParams} from "react-router-dom";

interface NewLoanAmountUpdationModalProps {
  crId: number;
  selectedLoanData: {
    loanType: string;
    loanAmount: number;
    pfAmount: number;
  };
  isLoanAmountUpdationModalOpen: boolean;
}
const NewLoanAmountUpdationModal = ({
  crId,
  selectedLoanData,
  isLoanAmountUpdationModalOpen,
}: NewLoanAmountUpdationModalProps) => {
  const dispatch = useAppDispatch();
  const {requestId} = useParams();
  const {showError} = useSnackBar();
  const {updateLoanAmount: isUpdateNewAmountLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  const [updatedLoanAmount, setUpdatedLoanAmount] = useState(
    String(selectedLoanData?.loanAmount),
  );
  const [processingFeeConfig, setProcessingFeeConfig] = useState({
    minimumProcessingFeePercentage: 0,
    maximumProcessingFeePercentage: 0,
    isProcessingFeeApplicable: false,
  });

  const [isLoanAmountChanged, setIsLoanAmountChanged] = useState(false);
  const [pfAmount, setPfAmount] = useState(String(selectedLoanData?.pfAmount));

  const fetchProcessingFeeConfig = useCallback(async () => {
    const {response, error} = await getProcessingFeeConfig({
      crId: Number(crId),
      lenderEnum: lenderNameEnum.SHIVALIK,
    });
    if (!error) {
      setProcessingFeeConfig((prevState) => ({
        ...prevState,
        minimumProcessingFeePercentage:
          response?.minimumProcessingFeePercentage,
        maximumProcessingFeePercentage:
          response?.maximumProcessingFeePercentage,
        isProcessingFeeApplicable: response?.isProcessingFeeApplicable,
      }));
    } else {
      showError(error.message);
    }
  }, [crId, showError]);

  const onClose = () => {
    dispatch(
      toggleModals({
        type: "newLoanAmountUpdationModal",
        value: false,
      }),
    );
  };

  const onConfirmClickHandler = () => {
    if (crId && updatedLoanAmount) {
      dispatch(
        activeLoansActions.updateLoanAmount({
          crId: crId,
          loanAmount: Number(updatedLoanAmount),
          loanType: selectedLoanData?.loanType,
          processingFeeIncludingGst: Number(pfAmount) || 0,
          requestId: Number(requestId),
        }),
      );
    }
  };

  const onChangeLoanAmount = async (value: string) => {
    const loanAmountInNumber = Number(value ?? "0");
    await setUpdatedLoanAmount(value);
    if (processingFeeConfig) {
      const {maximumProcessingFeePercentage} = processingFeeConfig;
      const maxPF =
        Number(maximumProcessingFeePercentage / 100) * loanAmountInNumber;
      const roundedMaxPF = Number(maxPF.toFixed(2));
      setPfAmount(String(roundedMaxPF));
      return roundedMaxPF;
    }

    return null;
  };
  const maxAllowedPF = useMemo(() => {
    if (processingFeeConfig && updatedLoanAmount) {
      const {maximumProcessingFeePercentage} = processingFeeConfig;
      const maxPF =
        Number(maximumProcessingFeePercentage / 100) *
        Number(updatedLoanAmount);
      const roundedMaxPF = Number(maxPF.toFixed(2));
      if (isLoanAmountChanged) {
        setPfAmount(String(roundedMaxPF));
      }

      return roundedMaxPF;
    }
    return null;
  }, [isLoanAmountChanged, processingFeeConfig, updatedLoanAmount]);

  const minAllowedPF = useMemo(() => {
    if (processingFeeConfig && updatedLoanAmount) {
      const {minimumProcessingFeePercentage} = processingFeeConfig;
      const maxPF =
        Number(minimumProcessingFeePercentage / 100) *
        Number(updatedLoanAmount);
      return maxPF;
    }
    return null;
  }, [processingFeeConfig, updatedLoanAmount]);

  const validateprocessingFeeAmount = useMemo(() => {
    if (processingFeeConfig) {
      const {
        minimumProcessingFeePercentage,
        maximumProcessingFeePercentage,
      } = processingFeeConfig;
      const errors = [];
      const minProcessingFees = Number(
        Number(minimumProcessingFeePercentage / 100) *
          Number(updatedLoanAmount),
      );
      const maxProcessingFees =
        Number(maximumProcessingFeePercentage / 100) *
        Number(updatedLoanAmount);

      const maxRoundedPf = Number(maxProcessingFees.toFixed(2));

      if (Number(pfAmount) < minProcessingFees) {
        errors.push(
          `Proccessing fee cannot be less than ${numberWithCurrencyAndComma(
            minProcessingFees,
          )}`,
        );
      }
      if (Number(pfAmount) > Number(maxRoundedPf)) {
        errors.push(
          `Processing fee cannot be more than ${numberWithCurrencyAndComma(
            maxProcessingFees,
          )}`,
        );
      }
      return errors;
    }
    return [];
  }, [processingFeeConfig, updatedLoanAmount, pfAmount]);

  useEffect(() => {
    if (crId) {
      fetchProcessingFeeConfig();
    }
  }, [crId]);

  return (
    <Box>
      <IGDialog
        open={isLoanAmountUpdationModalOpen}
        onClose={onClose}
        title="Are you sure, you want to change new loan detail"
        hideFooter
      >
        <IGTextField
          name="loanAmount"
          value={updatedLoanAmount}
          label="Please update new loan amount"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeLoanAmount(event?.target.value);
          }}
        />

        <IGTextField
          sx={{
            marginTop: "1rem",
          }}
          name="pfAmount"
          value={pfAmount}
          label="Please update new loan Pf amount"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPfAmount(event?.target.value);
          }}
          error={validateprocessingFeeAmount.length > 0}
        />
        <Box color="rgba(0, 0, 0, 0.6)">
          {`Allowed PF ${numberWithCurrencyAndComma(
            minAllowedPF,
          )} to ${numberWithCurrencyAndComma(maxAllowedPF)}`}
        </Box>
        <Box>
          {validateprocessingFeeAmount.map((error) => {
            return (
              <FormHelperText key={error} error>
                <Typography color="error.main">{error}</Typography>
              </FormHelperText>
            );
          })}
        </Box>

        <Box mt={2} gap={1} display="flex" justifyContent="flex-end">
          <IGButton color="inherit" onClick={onClose}>
            Cancel
          </IGButton>
          <IGButton
            loading={isUpdateNewAmountLoading}
            onClick={onConfirmClickHandler}
          >
            Confirm
          </IGButton>
        </Box>
      </IGDialog>
    </Box>
  );
};

export default NewLoanAmountUpdationModal;
