import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  getInitialState,
  paginationSliceCreator,
} from "app/store/factoryReducers";
import servicesActions from "./actions";
import {closureState} from "./closure/reducer";
import {renewRebookState} from "./renewRebook/reducer";
import {SearchTypeEnum} from "app/enums/serviceDesk";
import {getValueFromQueryParams} from "_metronic";
import {ServiceDeskFilterState, ServicesState} from "./types";
import {partReleaseState} from "./partRelease/partRelease.reducer";
import {rateChangeState} from "./rateChange";

const initialState: ServicesState = {
  closureState,
  renewRebookState,
  partReleaseState,
  rateChangeState,
  rowAction: {
    type: "",
    requestId: 0,
  },
  customerAvailability: {
    crAddressDto: null,
    lmScheduledStartTime: null,
    lmScheduledEndTime: null,
    serviceRequestId: null,
  },
  loansByMobileNumber: [],
  byPassPacketScanningList: {
    packetDetailByRM: [],
    pledgeOrnamentDetail: [],
    crDocument: [],
  },
  createRequestResponse: {
    id: 0,
    createdAt: null,
  },
  loanDetail: null,
  listReload: false,
  paymentDetail: null,
  pagination: getInitialState("services"),
  userAllLoans: [],
  lenderClosureAmount: null,
  foreclosureCharge: null,
  reload: {
    waiveOffForeclosureCharge: false,
    soaVerification: false,
  },
  cancellationReasonList: [],
  soaVerification: {
    loanCompetitorNameList: [],
    reasonForBtList: [],
    isBtCase: null,
    btCaseMessage: "",
    soaVerificationProofLinkList: [],
    remarks: "",
  },
  isRateChangeRequestCreationAllowed: false,
  eventHistory: {},
  latestEvent: null,
  eventMasterList: [],
  // Complaint Filters (Need to merge with service filters in service filters)
  searchFilters: {
    searchType: getValueFromQueryParams("searchType") || SearchTypeEnum.REQUEST_ID,
    id: getValueFromQueryParams("id") || "",
    fromTime: Number(getValueFromQueryParams("fromTime")),
    toTime: Number(getValueFromQueryParams("toTime")),
    agentId: Number(getValueFromQueryParams("agentId")),
    serviceRequestStatusList:
      getValueFromQueryParams("serviceRequestStatusList")?.split(",") ||
      undefined,
    serviceRequestCategory:
      getValueFromQueryParams("serviceRequestCategory"),
    lenderList: getValueFromQueryParams("lenderList")?.split(","),
    plLenderList:
      getValueFromQueryParams("plLenderList")?.split(","),
    cityIdList:
      getValueFromQueryParams("cityIdList")
        ?.split(",")
        .map((i) => Number(i)),
    isEscalated: Boolean(getValueFromQueryParams("isEscalated")),
    closureTypeList:
      getValueFromQueryParams("closureTypeList")?.split(","),
    documentSignPending: Boolean(getValueFromQueryParams("documentSignPending")),
    serviceRequestSource: getValueFromQueryParams("serviceRequestSource")?.split(","),
  },
  filters: {
    searchType: getValueFromQueryParams("searchType") || SearchTypeEnum.LOAN_ID,
    id: getValueFromQueryParams("id") || "",
    fromTime: Number(getValueFromQueryParams("fromTime")),
    toTime: Number(getValueFromQueryParams("toTime")),
    serviceRequestReleaseStatus:
      getValueFromQueryParams("serviceRequestReleaseStatus"),
    agentId: Number(getValueFromQueryParams("agentId")),
    serviceRequestStatusList:
      getValueFromQueryParams("serviceRequestStatusList")?.split(",") ||
      undefined,
    lenderList: getValueFromQueryParams("lenderList")?.split(","),
    plLenderList:
      getValueFromQueryParams("plLenderList")?.split(","),
    cityIdList:
      getValueFromQueryParams("cityIdList")
        ?.split(",")
        .map((i) => Number(i)),
    isEscalated: Boolean(getValueFromQueryParams("isEscalated")),
    pendingAt: getValueFromQueryParams("pendingAt")?.split(","),
    serviceType: getValueFromQueryParams("serviceType") || "CLOSURE",
    documentSignPending: Boolean(getValueFromQueryParams("documentSignPending")),
  },
  openModals: {
    update: false,
    reassignTo: false,
    closeLoan: false,
    allowPayment: false,
    modifyRequest: false,
    bypass: false,
    confirmAvailability: false,
    manageRenewRebook: false,
    checkClosureAmount: false,
    createNewRequest: false,
    updateRenewalOffer: false,
    closeAndRenew: false,
    removeJumper: false,
    waiveOffForeclosureCharge: false,
    viewVa: false,
    manageRateChange: false,
    approveRateChange: false,
    markLenderRejected: false,
    addNewUpdate: false,
    manageDocumentSigning: false,
    scheduleGoldDelivery: false,
    updateMobile: false,
    closeTicket: false,
  },
  loading: {
    getAgentList: false,
    loansByMobileNumber: false,
    createNewRequest: false,
    modifyRequest: false,
    reassignAgent: false,
    getCustomerAvailability: false,
    confirmCustomerAvailability: false,
    getByPassPacketScanningList: false,
    approveBypassScanning: false,
    getLoanDetails: false,
    getPaymentDetail: false,
    getAllLoans: false,
    getForeclosureCharge: false,
    waiveOffForeclosureCharge: false,
    getCancellationReasonList: false,
    updateSoaVerification: false,
    getSoaVerification: false,
    validateRateChangeRequest: false,
    getEventHistory: false,
    createEvent: false,
    getLatestEvent: false,
    getEventMasterList: false,
    getLenderClosureAmount: false,
  },
  error: {
    getAgentList: null,
    checkClosureAmount: null,
    createNewRequest: null,
    loansByMobileNumber: null,
    modifyRequest: null,
    getLoanDetails: null,
    reassignAgent: null,
    getCustomerAvailability: null,
    confirmCustomerAvailability: null,
    getByPassPacketScanningList: null,
    approveBypassScanning: null,
    getPaymentDetail: null,
    getAllLoans: null,
    getForeclosureCharge: null,
    waiveOffForeclosureCharge: null,
    getCancellationReasonList: null,
    updateSoaVerification: null,
    getSoaVerification: null,
    validateRateChangeRequest: null,
    getEventHistory: null,
    createEvent: null,
    getLatestEvent: null,
    getEventMasterList: null,
    getLenderClosureAmount: null,
  },
  success: {
    modifyRequest: false,
  },
};

const servicePaginationReducer = paginationSliceCreator("services");

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setRowAction: (state, action) => {
      state.rowAction = action.payload;
    },
    resetRowAction: (state) => {
      state.rowAction = initialState.rowAction;
    },
    reloadList: (state) => {
      state.listReload = !state.listReload;
    },
    setSearchFilters: (
      state,
      action: PayloadAction<Partial<ServiceDeskFilterState>>,
    ) => {
      state.searchFilters = {
        ...state.searchFilters,
        ...action.payload,
      };
    },
    setFilters: (
      state,
      action: PayloadAction<Partial<ServiceDeskFilterState>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    resetFilterState: (
      state,
      action: PayloadAction<Partial<ServiceDeskFilterState>>,
    ) => {
      state.filters = {
        serviceType: state.filters.serviceType,
        searchType: "loanId",
        id: "",
        serviceRequestStatusList: [],
        agentId: action.payload?.agentId || 0,
        serviceRequestReleaseStatus: "",
        cityIdList: action.payload?.cityIdList || [],
        lenderList: [],
        plLenderList: [],
        serviceRequestCategory: "",
        closureTypeList: action.payload?.closureTypeList || [],
        pendingAt: action.payload?.pendingAt || [],
        documentSignPending: false,
      };
    },
    resetSearchFilters: (
      state,
      action: PayloadAction<Partial<ServiceDeskFilterState>>,
    ) => {
      state.searchFilters = {
        searchType: "loanId",
        id: "",
        serviceRequestStatusList: [],
        agentId: action.payload?.agentId || 0,
        cityIdList: action.payload?.cityIdList || [],
        lenderList: [],
        plLenderList: [],
        serviceRequestCategory: "",
        isEscalated: false,
        documentSignPending: false,
      };
    },
    refreshFilters: (state) => {
      state.filters = {
        searchType:
          getValueFromQueryParams("searchType") || SearchTypeEnum.LOAN_ID,
        id: getValueFromQueryParams("id") || "",
        fromTime: Number(getValueFromQueryParams("fromTime")),
        toTime: Number(getValueFromQueryParams("toTime")),
        serviceRequestReleaseStatus:
          getValueFromQueryParams("serviceRequestReleaseStatus"),
        agentId: Number(getValueFromQueryParams("agentId")),
        serviceRequestStatusList:
          getValueFromQueryParams("serviceRequestStatusList")?.split(",") ||
          undefined,
        lenderList:
          getValueFromQueryParams("lenderList")?.split(","),
        plLenderList:
          getValueFromQueryParams("plLenderList")?.split(","),
        cityIdList:
          getValueFromQueryParams("cityIdList")
            ?.split(",")
            .map((i) => Number(i)),
        isEscalated: Boolean(
          getValueFromQueryParams("isEscalated"),
        ),
        documentSignPending: Boolean(
          getValueFromQueryParams("documentSignPending"),
        ),
        pendingAt:
          getValueFromQueryParams("pendingAt")?.split(","),
        serviceType: getValueFromQueryParams("serviceType") || "CLOSURE",
      };
    },
    refreshSearchFilters: (state) => {
      state.searchFilters = {
        searchType:
          getValueFromQueryParams("searchType") || SearchTypeEnum.LOAN_ID,
        id: getValueFromQueryParams("id") || "",
        fromTime: Number(getValueFromQueryParams("fromTime")),
        toTime: Number(getValueFromQueryParams("toTime")),
        agentId: Number(getValueFromQueryParams("agentId")),
        serviceRequestStatusList:
          getValueFromQueryParams("serviceRequestStatusList")?.split(",") ||
          undefined,
        serviceRequestCategory:
          getValueFromQueryParams("serviceRequestCategory"),
        lenderList:
          getValueFromQueryParams("lenderList")?.split(","),
        plLenderList:
          getValueFromQueryParams("plLenderList")?.split(","),
        cityIdList:
          getValueFromQueryParams("cityIdList")
            ?.split(",")
            .map((i) => Number(i)),
        isEscalated: Boolean(
          getValueFromQueryParams("isEscalated"),
        ),
        documentSignPending: Boolean(
          getValueFromQueryParams("documentSignPending"),
        ),
        closureTypeList:
          getValueFromQueryParams("closureTypeList")?.split(","),
        serviceRequestSource:
          getValueFromQueryParams("serviceRequestSource")?.split(","),
      };
    },
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    closeAllModals: (state) => {
      const modals = Object.keys(state.openModals);
      for (const key of modals) {
        // @ts-ignore
        state.openModals[key] = false;
      }
    },
    resetCreateRequestResponse: (state) => {
      state.createRequestResponse.id = 0;
      state.createRequestResponse.createdAt = null;
    },
    setCurrentServiceType: (state, action: PayloadAction<string>) => {
      state.filters.serviceType = action.payload;
      state.pagination.pageNo = 1;
      state.pagination.pageSize = 10;
      state.filters.pendingAt = undefined;
    },
    setReload: (
      state,
      action: PayloadAction<{key: string; value: boolean}>,
    ) => {
      state.reload[action.payload.key] = action.payload.value;
    },
    resetIsRateChangeFlag: (state) => {
      state.isRateChangeRequestCreationAllowed = false;
    },
    setSuccess: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.success = {
        ...state.success,
        ...action.payload,
      };
    },
    resetLenderClosureAmount: (state) => {
      state.lenderClosureAmount = null;
      state.error.getLenderClosureAmount = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(servicesActions.getLoansByMobileNumber, (state) => {
        state.loading.loansByMobileNumber = true;
      })
      .addCase(
        servicesActions.getLoansByMobileNumberSuccess,
        (state, action) => {
          state.loading.loansByMobileNumber = false;
          state.loansByMobileNumber = action.payload.response;
        },
      )
      .addCase(
        servicesActions.getLoansByMobileNumberFailure,
        (state, action) => {
          state.loading.loansByMobileNumber = false;
          state.error.loansByMobileNumber = action.payload.error;
        },
      )
      .addCase(servicesActions.createNewRequest, (state) => {
        state.loading.createNewRequest = true;
      })
      .addCase(servicesActions.createNewRequestSuccess, (state, action) => {
        state.loading.createNewRequest = false;
        state.openModals.createNewRequest = false;
        state.createRequestResponse.id = action.payload.response.id || 0;
        state.createRequestResponse.createdAt =
          action.payload.response.createdAt || null;
        state.listReload = !state.listReload;
      })
      .addCase(servicesActions.createNewRequestFailure, (state, action) => {
        state.loading.createNewRequest = false;
        state.error.createNewRequest = action.payload.error;
      })
      .addCase(servicesActions.modifyRequest, (state) => {
        state.loading.modifyRequest = true;
        state.error.modifyRequest = null;
      })
      .addCase(servicesActions.modifyRequestSuccess, (state) => {
        state.loading.modifyRequest = false;
        state.error.modifyRequest = null;
        state.openModals.modifyRequest = false;
        state.success.modifyRequest = true;
        state.listReload = !state.listReload;
      })
      .addCase(servicesActions.modifyRequestFailure, (state, action) => {
        state.loading.modifyRequest = false;
        state.error.modifyRequest = action.payload.error;
      })
      .addCase(servicesActions.getLoanDetail, (state) => {
        state.loading.getLoanDetails = true;
      })
      .addCase(servicesActions.getLoanDetailSuccess, (state, action) => {
        state.loanDetail = action.payload.response;
        state.loading.getLoanDetails = false;
      })
      .addCase(servicesActions.getLoanDetailFailure, (state, action) => {
        state.loading.getLoanDetails = false;
        state.loanDetail = null;
        state.error.getLoanDetails = action.payload.error;
      })
      .addCase(servicesActions.reassignAgent, (state) => {
        state.loading.reassignAgent = true;
      })
      .addCase(servicesActions.reassignAgentSuccess, (state) => {
        state.loading.reassignAgent = false;
        state.openModals.reassignTo = false;
        state.listReload = !state.listReload;
      })
      .addCase(servicesActions.reassignAgentFailure, (state, action) => {
        state.loading.reassignAgent = false;
        state.error.reassignAgent = action.payload.error;
      })
      .addCase(servicesActions.getCustomerAvailability, (state) => {
        state.loading.getCustomerAvailability = true;
        state.customerAvailability = initialState.customerAvailability;
      })
      .addCase(
        servicesActions.getCustomerAvailabilitySuccess,
        (state, action) => {
          state.loading.getCustomerAvailability = false;
          state.customerAvailability = action.payload.customerAvailability;
        },
      )
      .addCase(
        servicesActions.getCustomerAvailabilityFailure,
        (state, action) => {
          state.loading.getCustomerAvailability = false;
          state.openModals.confirmAvailability = false;
          state.error.getCustomerAvailability = action.payload.error;
        },
      )
      .addCase(servicesActions.confirmCustomerAvailability, (state) => {
        state.loading.confirmCustomerAvailability = true;
      })
      .addCase(servicesActions.confirmCustomerAvailabilitySuccess, (state) => {
        state.loading.confirmCustomerAvailability = false;
        state.openModals.confirmAvailability = false;
      })
      .addCase(
        servicesActions.confirmCustomerAvailabilityFailure,
        (state, action) => {
          state.loading.confirmCustomerAvailability = false;
          state.error.confirmCustomerAvailability = action.payload.error;
        },
      )
      .addCase(servicesActions.getBypassPacketScanningList, (state) => {
        state.loading.getByPassPacketScanningList = true;
        state.byPassPacketScanningList = initialState.byPassPacketScanningList;
      })
      .addCase(
        servicesActions.getBypassPacketScanningListSuccess,
        (state, action) => {
          state.loading.getByPassPacketScanningList = false;
          const {
            packetDetailByRM,
            pledgeOrnamentDetail,
            crDocumentResponseDtoList,
          } = action.payload.response;

          state.byPassPacketScanningList = {
            ...state.byPassPacketScanningList,
            packetDetailByRM,
            pledgeOrnamentDetail,
            crDocument: crDocumentResponseDtoList,
          };
        },
      )
      .addCase(
        servicesActions.getBypassPacketScanningListFailure,
        (state, action) => {
          state.loading.getByPassPacketScanningList = false;
          state.openModals.bypass = false;
          state.error.getByPassPacketScanningList = action.payload.error;
          state.byPassPacketScanningList =
            initialState.byPassPacketScanningList;
        },
      )
      .addCase(servicesActions.approveBypassScanning, (state) => {
        state.loading.approveBypassScanning = true;
      })
      .addCase(servicesActions.approveBypassScanningSuccess, (state) => {
        state.loading.approveBypassScanning = false;
      })
      .addCase(
        servicesActions.approveBypassScanningFailure,
        (state, action) => {
          state.loading.approveBypassScanning = false;
          state.error.approveBypassScanning = action.payload.error;
        },
      )
      .addCase(servicesActions.getPaymentDetails, (state) => {
        state.loading.getPaymentDetail = true;
      })
      .addCase(servicesActions.getPaymentDetailsSuccess, (state, action) => {
        state.loading.getPaymentDetail = false;
        const details = action.payload.response;
        state.paymentDetail = {
          ...details,
          serviceRequestLoanPaymentDetail:
            details.serviceRequestLoanPaymentDetailDtoList.map(
            (i: any) => ({
              ...i,
              userGoldLoanNucleusReceipt: i.userGoldLoanNucleusReceiptDtoList,
            }),
          ),
        };
      })
      .addCase(servicesActions.getPaymentDetailsFailure, (state, action) => {
        state.loading.getPaymentDetail = false;
        state.error.getPaymentDetail = action.payload.error;
      })
      .addCase(servicesActions.getForeClosureCharge, (state) => {
        state.loading.getForeclosureCharge = true;
        state.error.getForeclosureCharge = null;
      })
      .addCase(servicesActions.getForeClosureChargeSuccess, (state, action) => {
        state.loading.getForeclosureCharge = false;
        state.error.getForeclosureCharge = null;
        state.foreclosureCharge = action.payload.response?.[0];
        state.reload.waiveOffForeclosureCharge = false;
      })
      .addCase(servicesActions.getForeClosureChargeFailure, (state, action) => {
        state.loading.getForeclosureCharge = false;
        state.error.getForeclosureCharge = action.payload.error;
        state.reload.waiveOffForeclosureCharge = false;
      })
      .addCase(servicesActions.waiveOffForeclosureCharge, (state) => {
        state.loading.waiveOffForeclosureCharge = true;
        state.error.waiveOffForeclosureCharge = null;
      })
      .addCase(
        servicesActions.waiveOffForeclosureChargeSuccess,
        (state, action) => {
          state.loading.waiveOffForeclosureCharge = false;
          state.error.waiveOffForeclosureCharge = null;
          if (action.payload.response?.[0]) {
            state.foreclosureCharge = action.payload.response?.[0];
          }
          state.openModals.waiveOffForeclosureCharge = false;
          state.reload.waiveOffForeclosureCharge = true;
        },
      )
      .addCase(
        servicesActions.waiveOffForeclosureChargeFailure,
        (state, action) => {
          state.loading.waiveOffForeclosureCharge = false;
          state.error.waiveOffForeclosureCharge = action.payload.error;
          state.openModals.waiveOffForeclosureCharge = false;
          state.reload.waiveOffForeclosureCharge = false;
        },
      )
      .addCase(servicesActions.getAllLoans, (state) => {
        state.loading.getAllLoans = true;
        state.error.getAllLoans = initialState.error.getAllLoans;
      })
      .addCase(servicesActions.getAllLoansSuccess, (state, action) => {
        state.loading.getAllLoans = false;
        state.error.getAllLoans = initialState.error.getAllLoans;
        state.userAllLoans = action.payload.response.map((i: any) => ({
          id: i.id,
          loanStartDate: i.loanStartDate,
          totalAmount: i.totalAmount,
          status: i.status,
        }));
      })
      .addCase(servicesActions.getAllLoansFailure, (state, action) => {
        state.loading.getAllLoans = false;
        state.error.getAllLoans = action.payload.error;
      })
      .addCase(servicesActions.getCancellationReasonList, (state) => {
        state.loading.getCancellationReasonList = true;
        state.error.getCancellationReasonList =
          initialState.error.getCancellationReasonList;
      })
      .addCase(
        servicesActions.getCancellationReasonListSuccess,
        (state, action) => {
          state.loading.getCancellationReasonList = false;
          state.error.getCancellationReasonList =
            initialState.error.getCancellationReasonList;
          state.cancellationReasonList = action.payload.response;
        },
      )
      .addCase(
        servicesActions.getCancellationReasonListFailure,
        (state, action) => {
          state.loading.getCancellationReasonList = false;
          state.error.getCancellationReasonList = action.payload.error;
        },
      )
      .addCase(servicesActions.getSoaVerification, (state) => {
        state.loading.getSoaVerification = true;
        state.error.getSoaVerification = initialState.error.getSoaVerification;
      })
      .addCase(servicesActions.getSoaVerificationSuccess, (state, action) => {
        state.loading.getSoaVerification = false;
        state.error.getSoaVerification = initialState.error.getSoaVerification;
        state.soaVerification = action.payload.response;
      })
      .addCase(servicesActions.getSoaVerificationFailure, (state, action) => {
        state.loading.getSoaVerification = false;
        state.error.getSoaVerification = action.payload.error;
      })
      .addCase(servicesActions.updateSoaVerification, (state) => {
        state.loading.updateSoaVerification = true;
        state.error.updateSoaVerification =
          initialState.error.updateSoaVerification;
      })
      .addCase(servicesActions.updateSoaVerificationSuccess, (state) => {
        state.loading.updateSoaVerification = false;
        state.error.updateSoaVerification =
          initialState.error.updateSoaVerification;
      })
      .addCase(
        servicesActions.updateSoaVerificationFailure,
        (state, action) => {
          state.loading.updateSoaVerification = false;
          state.error.updateSoaVerification = action.payload.error;
        },
      )
      .addCase(servicesActions.validateRateChangeRequest, (state) => {
        state.loading.validateRateChangeRequest = false;
        state.error.validateRateChangeRequest = null;
        state.isRateChangeRequestCreationAllowed = false;
      })
      .addCase(
        servicesActions.validateRateChangeRequestSuccess,
        (state, action) => {
          state.loading.validateRateChangeRequest = false;
          state.error.validateRateChangeRequest =
            initialState.error.validateRateChangeRequest;
          state.isRateChangeRequestCreationAllowed =
            action.payload.response.isRateChangeRequestCreationAllowed;
        },
      )
      .addCase(
        servicesActions.validateRateChangeRequestFailure,
        (state, action) => {
          state.loading.validateRateChangeRequest = false;
          state.error.validateRateChangeRequest = action.payload.error;
        },
      )
      .addCase(servicesActions.getLenderClosureAmount, (state) => {
        state.loading.getLenderClosureAmount = true;
        state.error.getLenderClosureAmount =
          initialState.error.getLenderClosureAmount;
        state.lenderClosureAmount = initialState.lenderClosureAmount;
      })
      .addCase(
        servicesActions.getLenderClosureAmountSuccess,
        (state, action) => {
          state.lenderClosureAmount = action.payload.response;
          state.error.getLenderClosureAmount =
            initialState.error.getLenderClosureAmount;
          state.loading.getLenderClosureAmount = false;
        },
      )
      .addCase(
        servicesActions.getLenderClosureAmountFailure,
        (state, action) => {
          state.error.getLenderClosureAmount = action.payload.error;
          state.loading.getLenderClosureAmount = false;
        },
      )
      .addCase(servicesActions.getEventHistory, (state) => {
        state.loading.getEventHistory = true;
        state.error.getEventHistory = initialState.error.getEventHistory;
        state.eventHistory = initialState.eventHistory;
      })
      .addCase(servicesActions.getEventHistorySuccess, (state, action) => {
        state.eventHistory[action.payload.requestId] = action.payload.response;
        state.error.getEventHistory = initialState.error.getEventHistory;
        state.loading.getEventHistory = false;
      })
      .addCase(servicesActions.getEventHistoryFailure, (state, action) => {
        state.error.getEventHistory = action.payload.error;
        state.loading.getEventHistory = false;
      })
      .addCase(servicesActions.createEvent, (state) => {
        state.loading.createEvent = true;
        state.error.createEvent = initialState.error.createEvent;
      })
      .addCase(servicesActions.createEventSuccess, (state) => {
        state.error.createEvent = initialState.error.createEvent;
        state.loading.createEvent = false;
        state.openModals.addNewUpdate = false;
      })
      .addCase(servicesActions.createEventFailure, (state, action) => {
        state.error.createEvent = action.payload.error;
        state.loading.createEvent = false;
      })
      .addCase(servicesActions.getLatestEvent, (state) => {
        state.loading.getLatestEvent = true;
        state.error.getLatestEvent = initialState.error.getLatestEvent;
        state.latestEvent = initialState.latestEvent;
      })
      .addCase(servicesActions.getLatestEventSuccess, (state, action) => {
        state.latestEvent = action.payload.response;
        state.error.getLatestEvent = initialState.error.getLatestEvent;
        state.loading.getLatestEvent = false;
      })
      .addCase(servicesActions.getLatestEventFailure, (state, action) => {
        state.error.getLatestEvent = action.payload.error;
        state.loading.getLatestEvent = false;
      })
      .addCase(servicesActions.getEventMasterList, (state) => {
        state.loading.getEventMasterList = true;
        state.error.getEventMasterList = initialState.error.getEventMasterList;
        state.eventMasterList = initialState.eventMasterList;
      })
      .addCase(servicesActions.getEventMasterListSuccess, (state, action) => {
        state.eventMasterList = action.payload.response;
        state.error.getEventMasterList = initialState.error.getEventMasterList;
        state.loading.getEventMasterList = false;
      })
      .addCase(servicesActions.getEventMasterListFailure, (state, action) => {
        state.error.getEventMasterList = action.payload.error;
        state.loading.getEventMasterList = false;
      })
      .addMatcher(
        (action) => action.type.startsWith("servicesPagination"),
        (state, action) => {
          state.pagination = servicePaginationReducer(state.pagination, action);
        },
      );
  },
});

const {
  setRowAction,
  resetRowAction,
  reloadList,
  setSearchFilters,
  setFilters,
  resetFilterState,
  toggleModals,
  closeAllModals,
  resetCreateRequestResponse,
  resetSearchFilters,
  setCurrentServiceType,
  setReload,
  refreshFilters,
  refreshSearchFilters,
  resetIsRateChangeFlag,
  setSuccess,
  resetLenderClosureAmount,
} = servicesSlice.actions;

export default servicesSlice.reducer;

export {
  initialState,
  setRowAction,
  resetRowAction,
  reloadList,
  setSearchFilters,
  setFilters,
  resetFilterState,
  toggleModals,
  closeAllModals,
  resetCreateRequestResponse,
  servicePaginationReducer,
  resetSearchFilters,
  setCurrentServiceType,
  setReload,
  refreshFilters,
  refreshSearchFilters,
  resetIsRateChangeFlag,
  setSuccess,
  resetLenderClosureAmount,
};
