import React, {useState} from "react";
import returnToGoldValuation from "api/activeLoan/returnToGoldValuation";
import ModalComponent from "app/components/Modal";
import {Typography, Snackbar} from "@mui/material";
import styles from "../styles";
import ButtonWithLoader from "app/components/Button/ButtonWithLoader";

const ReturnGoldValuation = ({selectedPayload, getMileStoneOfCr}) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const classes = styles();

  const [modal, setModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const onClickHandler = async () => {
    setButtonDisable(true);
    const offerResponse = await returnToGoldValuation({
      accessToken: localStorage.getItem("authToken"),
      crId: selectedPayload.id,
    });

    if (offerResponse.statusCode === 200) {
      getMileStoneOfCr();
      setSnackbar({
        open: true,
        message: "Success",
      });
      onModalHandler();
    } else {
      setSnackbar({
        open: true,
        message: `Error: ${offerResponse.error || "Something went wrong"}`,
      });
    }
    setButtonDisable(false);
  };

  const onModalHandler = () => {
    setModal(state => !state);
  };

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        open={snackbar.open}
        message={snackbar.message}
      ></Snackbar>

      <ModalComponent open={modal} setOpen={setModal}>
        <Typography variant="h6">Are you Sure</Typography>

        <Typography style={{margin: "10px 0"}}>
          You will have to redo all the process starting from gold valuation.
          Are you sure you want to go back ?
        </Typography>

        <div style={{display: "flex"}}>
          <ButtonWithLoader
            className={classes.ApproveButton}
            onClick={onClickHandler}
            loading={buttonDisable}
          >
            OK
          </ButtonWithLoader>
          <button
            className={classes.reject}
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </ModalComponent>

      {/* <button
        disabled={buttonDisable}
        onClick={onModalHandler}
        className={classes.reject}
      >
        RETURN TO GOLD VALUATION
      </button> */}
    </>
  );
};

export default ReturnGoldValuation;
