import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {IGLoading, IGModal} from "app/components";
import {goldApiService} from "app/infra/plugins/axios";
import {useSnackBar} from "app/store/hooks";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useParams} from "react-router-dom";

interface OtherChargesModalProps {
  open: boolean;
  setOpen: (value: boolean) => void
}

const OtherChargesModal: React.FC<OtherChargesModalProps> = ({
  open,
  setOpen,
}) => {
  const {showError} = useSnackBar();
  const [otherCharges, setOtherCharges] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {loanId} = useParams();

  const fetchOtherCharges = async () => {
    setLoading(true);
    const {response, error} = await goldApiService({
      resource: {
        URL: `/admin/business/loan/${Number(loanId)}/other-charge-breakup`,
        METHOD: "GET",
      },
    });

    if (error) {
      showError(error.message || "Something went wrong");
      setOpen(false);
      setLoading(false);
      return;
    }
    setOtherCharges(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchOtherCharges();
  }, []);

  return (
    <IGModal
      open={open}
      onClose={() => setOpen(false)}
      title="View All Other Charges"
      width="sm"
    >
      {loading ? (
        <IGLoading height="10vh" />
      ) : (
        <Grid
          container
          sx={{
            padding: "1rem",
            border: "1px solid #E5E5E5",
            borderRadius: "5px",
            overflowY: "auto",
          }}
        >
          {otherCharges.map((item) => (
            <>
              <Grid item xs={6} key={item.id}>
                <Typography fontWeight="bold">
                  {item.chargeDisplayName}
                </Typography>
              </Grid>
              <Grid item xs={6} key={item.id}>
                <Typography alignItems="end">
                  {numberWithCurrencyAndComma(item.chargeDueAmount)}, applied on{" "}
                  {getValidTime(item.chargeDueDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </IGModal>
  );
};

export default OtherChargesModal;
