import {LoanPaymentModel} from "../../models/loanPayment.model";
import {createSlice} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import loanActions from "./loanPayment.actions";

type LoadingErrorState =
  | "getLoanPaymentDetails"
  | "getRefundStatus"
  | "refundPayment"
  | "shiftLoanPayment"
  | "getShiftPaymentStatus";

export interface LoanPaymentState {
  loanPaymentList: LoanPaymentModel[];
  refundStatus: {
    [key: string]: {
      userGoldLoanPaymentId: number;
      refundedByName: string;
      refundReason: string;
      refundAmount: number;
      refundId: string;
      refundStatus: string;
      shivalikConfirmationAttachmentUrl: string;
      internalApprovalAttachmentUrl: string;
    }[];
  };
  shiftPaymentStatus: {
    [key: string]: {
      id: number;
      sourceLoanId: number;
      targetLoanId: number;
      loanPaymentId: number;
      shiftLoanPaymentReason: string;
      isDoneAtShivalikEnd: boolean;
      actionTakenBy: string;
      remark: string;
      internalApprovalAttachmentUrl: string;
      shivalikConfirmationAttachmentLinkUrl: string;
      status: string;
    }[];
  };
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: LoanPaymentState = {
  loanPaymentList: [],
  refundStatus: {},
  shiftPaymentStatus: {},
  loading: {
    getLoanPaymentDetails: false,
    getRefundStatus: false,
    refundPayment: false,
    shiftLoanPayment: false,
    getShiftPaymentStatus: false,
  },
  errors: {
    getLoanPaymentDetails: null,
    getRefundStatus: null,
    refundPayment: null,
    shiftLoanPayment: null,
    getShiftPaymentStatus: null,
  },
};

const LoanPaymentSlice = createSlice({
  name: "loanPayment",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loanActions.getLoanPaymentDetails,
        (state) => {
          state.loading.getLoanPaymentDetails = true;
          state.errors.getLoanPaymentDetails = null;
        },
      )
      .addCase(loanActions.getLoanPaymentDetailsSuccess,
        (state, action) => {
          state.loading.getLoanPaymentDetails = false;
          state.loanPaymentList = action.payload.response;
        },
      )
      .addCase(loanActions.getLoanPaymentDetailsFailure,
        (state, action) => {
          state.loading.getLoanPaymentDetails = false;
          state.errors.getLoanPaymentDetails = action.payload.error;
        },
      )
      .addCase(loanActions.getLenderLoanPaymentDetails,
        (state) => {
          state.loading.getLoanPaymentDetails = true;
          state.errors.getLoanPaymentDetails = null;
        },
      )
      .addCase(loanActions.getLenderLoanPaymentDetailsSuccess,
        (state, action) => {
          state.loading.getLoanPaymentDetails = false;
          state.loanPaymentList = [
            ...(action.payload.response?.map((item: LoanPaymentModel) => {
              return {
                ...item,
                id: item.loanPaymentId,
              };
            }) || []),
          ];
        },
      )
      .addCase(loanActions.getLenderLoanPaymentDetailsFailure,
        (state, action) => {
          state.loading.getLoanPaymentDetails = false;
          state.errors.getLoanPaymentDetails = action.payload.error;
        },
      )
      .addCase(loanActions.getRefundStatus,
        (state, action) => {
          state.loading.getRefundStatus = true;
          state.errors.getRefundStatus = null;
          if (state.refundStatus[action.payload.paymentId]) {
            delete state.refundStatus[action.payload.paymentId];
          }
        },
      )
      .addCase(loanActions.getRefundStatusSuccess,
        (state, action) => {
          state.loading.getRefundStatus = false;
          state.errors.getRefundStatus = null;
          const paymentId = action.payload.response[0]?.userGoldLoanPaymentId;
          if (paymentId) {
            state.refundStatus[paymentId] = action.payload.response;
          }
        },
      )
      .addCase(loanActions.getRefundStatusFailure,
        (state, action) => {
          state.loading.getRefundStatus = false;
          state.errors.getRefundStatus = action.payload.error;
          if (state.refundStatus[action.payload.paymentId]) {
            delete state.refundStatus[action.payload.paymentId];
          }
        },
      )
      .addCase(loanActions.refundPayment,
        (state) => {
          state.loading.refundPayment = true;
          state.errors.refundPayment = null;
        },
      )
      .addCase(loanActions.refundPaymentSuccess,
        (state) => {
          state.loading.refundPayment = false;
          state.errors.refundPayment = null;
        },
      )
      .addCase(loanActions.refundPaymentFailure,
        (state, action) => {
          state.loading.refundPayment = false;
          state.errors.refundPayment = action.payload.error;
        },
      )
      .addCase(loanActions.shiftLoanPayment,
        (state, action) => {
          state.loading.shiftLoanPayment = true;
          state.errors.shiftLoanPayment = null;
          const paymentId = action.payload.formData.get("loanPaymentId")?.toString() || "";
          if (state.shiftPaymentStatus[paymentId]) {
            delete state.shiftPaymentStatus[paymentId];
          }
        },
      )
      .addCase(loanActions.shiftLoanPaymentSuccess,
        (state, action) => {
          state.loading.shiftLoanPayment = false;
          state.errors.shiftLoanPayment = null;
          const paymentId = action.payload.response[0]?.loanPaymentId;
          if (paymentId) {
            state.shiftPaymentStatus[paymentId] = action.payload.response;
          }
        },
      )
      .addCase(loanActions.shiftLoanPaymentFailure,
        (state, action) => {
          state.loading.shiftLoanPayment = false;
          state.errors.shiftLoanPayment = action.payload.error;
        },
      )
      .addCase(loanActions.getShiftPaymentStatus,
        (state) => {
          state.loading.getShiftPaymentStatus = true;
          state.errors.getShiftPaymentStatus = null;
        },
      )
      .addCase(loanActions.getShiftPaymentStatusSuccess,
        (state) => {
          state.loading.getShiftPaymentStatus = false;
          state.errors.getShiftPaymentStatus = null;
        },
      )
      .addCase(loanActions.getShiftPaymentStatusFailure,
        (state, action) => {
          state.loading.getShiftPaymentStatus = false;
          state.errors.getShiftPaymentStatus = action.payload.error;
        },
      )
  ,
});

export default LoanPaymentSlice.reducer;