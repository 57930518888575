import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    marginTop: "0.5rem",
    color: "#ce9f29",
    cursor: "pointer",
  },
}));

export default useStyles;
