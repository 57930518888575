const whitelistedUsers = [
  // Scheduling
  "shashwat@indiagold.co",
  "vibhay@indiagold.co",
  "paritosh@indiagold.co",
  "vibhay@indiagold.co",
  "amitgupta@indiagold.co",
  "ravish@indiagold.co",
  "shobhit@indiagold.co",
  "paritosh@mail.com",
  "rajshekar@indiagold.co",
  "suman@indiagold.co",
  "prerna@indiagold.co",
  "mani@indiagold.co",
  "harsha@indiagold.co",
  "anil@indiagold.co",
  "arihant@indiagold.co",
  // "rajat@indiagold.co", Rajat asked to remove
  "amit@indiagold.co",
  "siddarthrao@indiagold.co",
  "shobit@indiagold.co",
  "vimal@indiagold.co",
  "amitgupta@indiagold.co",
  // Service Desk
  "prashantojha@indiagold.co",
  "pooja@indiagold.co",
  "prakash@indiagold.co",
  "deepika@indiagold.co",
  "anusha@indiagold.co",
  "ankitanehal@indiagold.co",
  "sreedivya@indiagold.co",
  "pinky@indiagold.co",
  "isha@indiagold.co",
  "paritosh@indiagold.co",
  "manju@indiagold.co",
  "anjalisaji@indiagold.co",
  "manisha@indiagold.co",
  "sachinbhanuth@indiagold.co",
  "chandra@indiagold.co",
  "Krishnasahu@indiagold.co",
  "viveks@indiagold.co",
  "parul@indiagold.co",
  "rinki@indiagold.co",
  "mahalaxmi@indiagold.co",
  "shweta@indiagold.co",
  "nithyap@indiagold.co",
  "aswinadh@indiagold.co",
  "imran1@indiagold.co",
  "psaravanan@indiagold.co",
  "ramvilaskaushik@indiagold.co",
  "ameyouser@indiagold.co",
  "poonam1@indiagold.co",
  "santhoshkumar@indiagold.co",
];

export {
  whitelistedUsers,
};
