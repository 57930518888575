import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
}

const createLoanOnLms = async ({crId}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    CREATE_LOAN_ON_LMS: {
      URL: "admin/business/cr/:crId/lms-loan",
      METHOD: "POST",
    },
  };
  return await goldApiService({
    resource: API_ENDPOINTS.CREATE_LOAN_ON_LMS,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default createLoanOnLms;
