import {makeStyles, createStyles} from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: "#ce9f29",
      borderRadius: "2px",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#ce9f29",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2);",
      },
    },
  }),
);

export default useStyles;
