import React from "react";
import {Typography} from "@mui/material";
import red from "@mui/material/colors/red";
import {IGDialog} from "app/components";
import {getValidTime} from "_metronic/utils/moment";

const SessionTimeoutDialog: React.FC<SessionTimeoutDialogProps> = ({
  open,
  setOpen,
  remainingTime,
  onContinue,
}) => {
  return (
    <IGDialog
      title="Session Timeout"
      open={open}
      onClose={() => setOpen(false)}
      onConfirm={onContinue}
      confirmProps={{text: "Continue Session"}}
      cancelProps={{text: "Logout"}}
    >
      <Typography variant="body2">
        The current session is about to expire in{" "}
        <span style={{color: red[700]}}>
          {getValidTime(remainingTime).format("ss")}
        </span>{" "}
        seconds.
      </Typography>
      <Typography variant="body2">
        {"Would you like to continue the session?"}
      </Typography>
    </IGDialog>
  );
};

interface SessionTimeoutDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  remainingTime: number;
  onContinue: () => void;
}

export default SessionTimeoutDialog;
