import React, {ReactNode} from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText, IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import {SelectInputProps} from "@mui/material/Select/SelectInput";
import {CloseRounded} from "@mui/icons-material";

export type IGValueType = string | ReadonlyArray<string> | number | undefined;

export interface IGSelectOptionInterface<T> {
  text: string | ReactNode
  value: T
  disabled?: boolean
  hidden?: boolean
}

export interface IGSelectProps<T> extends SelectProps<T> {
  name: string
  value: T
  onChange: SelectInputProps<T>["onChange"],
  options: IGSelectOptionInterface<T>[]
  helperText?: ReactNode
  touched?: boolean
  multiple?: boolean
  clearable?: boolean
  loading?: boolean
}

function IGSelect<T extends IGValueType>({
  label,
  id,
  name,
  required,
  value,
  onChange,
  error,
  sx,
  clearable = false,
  options = [],
  touched = false,
  helperText = "",
  variant = "outlined",
  size = "small",
  fullWidth = true,
  loading = false,
  placeholder = "",
  ...props
}: IGSelectProps<T>) {

  const onCloseButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const event = new PointerEvent("click");
    if (onChange && e.target) {
      onChange(
        {
          ...event,
          target: {
            ...e.target,
            value: "" as T,
            name: name,
          },
          isDefaultPrevented(): boolean {
            return false;
          },
          isPropagationStopped(): boolean {
            return false;
          },
          nativeEvent: undefined,
          persist(): void {},
        },
        <></>,
      );
    }
  };

  return (
    <FormControl
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      required={required}
      error={error}
      sx={sx}
    >
      <InputLabel id={`input-label-${id || name}`}>{label}</InputLabel>
      <Select
        required={required}
        id={id || name}
        name={name}
        label={label}
        labelId={`input-label-${id || name}`}
        variant={variant}
        value={value}
        onChange={onChange}
        error={error}
        endAdornment={
          clearable &&
          value && (
            <IconButton
              size={size}
              onClick={onCloseButtonClick}
              sx={{marginRight: 1.5}}
            >
              <CloseRounded />
            </IconButton>
          )
        }
        startAdornment={loading && <CircularProgress />}
        sx={{
          "& .MuiSelect-select .notranslate::after": placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
              }
            : {},
            "& legend": {display: label ? "block" : "none"},
            "& fieldset": {top: label ? -5 : 0},
        }}
        {...props}
      >
        {options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={`input-option-${id || name}-${index}`}
              value={option.value}
              disabled={option?.disabled || false}
              hidden={option?.hidden || false}
            >
              {option.text}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={`input-option-${id || name}-empty`}
            value={undefined}
            disabled
          >
            No Options
          </MenuItem>
        )}
      </Select>
      {touched && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default IGSelect;