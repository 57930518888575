import {authActions, authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

const Unauthorised = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const {logout: logoutLoading} = useAppSelector(authSelectors.getLoading);

  useEffect(() => {
    if (!isAuthenticated && !logoutLoading) {
      navigate("/auth", {replace: true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, logoutLoading]);

  return (
    <>
      <div className="error__title">401</div>
      <div className="error__subtitle">Unauthorised</div>
      <div className="error__description">
        It looks like you don't have acess to this feature
      </div>
      <button
        className={`error__button error__button--active ${clsx({
          "kt-spinner kt-spinner--right kt-spinner--lg kt-spinner--light": logoutLoading,
        })}`}
        onClick={() => {
          dispatch(authActions.logout());
        }}
        style={{paddingRight: logoutLoading ? "2.5em" : "2em"}}
      >
        Logout
      </button>
      <button className="error__button">Do Nothing</button>
    </>
  );
};

export default Unauthorised;
