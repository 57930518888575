import React from "react";
import clsx from "clsx";
import ButtonComponent from ".";

interface Props {
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
const ButtonWithLoader: React.FC<Props> = ({
  loading,
  className,
  disabled,
  children,
  onClick = (e) => {},
  style,
  ...props
}) => {
  return (
    <ButtonComponent
      className={
        ` ${clsx({
          "kt-spinner kt-spinner--right kt-spinner--lg kt-spinner--light": loading,
        })} ${  className ? ` ${className} ` : " "}`
      }
      {...props}
      style={{
        paddingRight: loading ? "3rem" : "",
        ...style,
      }}
      disabled={loading || disabled}
      onClickHandler={onClick}
    >
      {children}
    </ButtonComponent>
  );
};
export default ButtonWithLoader;
