import React, {useCallback, useEffect, useRef, useState} from "react";

import GetpersonalDetails from "../../../../../../../api/business/getPersonalDetail";
import getEmploymentDetails from "../../../../../../../api/business/getEmploymentDetails";
import getOrnamentSummary from "../../../../../../../api/business/getOrnamentSummary";
import getFinness from "../../../../../../../api/business/getFiness";

import {CircularProgress} from "@mui/material";
import styles from "../styles";
import numberWithCurrencyAndComma from "../../../../../../../lib/numberWithCurrencyAndComma";

const LockerSummary = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      personalDetails: null,
      employmentDetails: null,
      ornamentsSummary: null,
      finness: null,
    },
    errorMessage: "",
  });
  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    try {
      const [
        personalLoanResponse,
        employmentDetailResponse,
        ornamentsSummaryResponse,
        getFinnessResponse,
      ] = await Promise.all([
        GetpersonalDetails({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          personalDetailType: "LOCKER_PERSONAL_DETAIL",
        }),
        getEmploymentDetails({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
        getOrnamentSummary({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
        getFinness({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
      ]);

      if (
        personalLoanResponse.statusCode === 200 &&
        employmentDetailResponse.statusCode === 200 &&
        ornamentsSummaryResponse.statusCode === 200 &&
        getFinnessResponse.statusCode === 200
      ) {
        setStates({
          error: false,
          errorMessage: "",
          loading: false,
          payload: {
            employmentDetails: employmentDetailResponse.payload,
            ornamentsSummary: ornamentsSummaryResponse.payload,
            personalDetails: personalLoanResponse.payload,
            finness: getFinnessResponse.payload,
          },
        });
      } else {
        setStates({
          error: true,
          errorMessage:
            personalLoanResponse?.error.toString() ||
            employmentDetailResponse?.error?.toString() ||
            ornamentsSummaryResponse?.error?.toString() ||
            getFinnessResponse?.error?.toString(),
          loading: false,
          payload: {
            personalDetails: null,
            employmentDetails: null,
            ornamentsSummary: null,
            finness: null,
          },
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: {
          employmentDetails: null,
          ornamentsSummary: null,
          personalDetails: null,
          finness: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getDetails, otherRequestTimer]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const {personalDetails} = states.payload;
  const {employmentDetails} = states.payload;
  const {ornamentsSummary} = states.payload;
  const finess = states.payload.finness;

  const twnty = finess.find((e) => e.fineness === "22K");

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>PERSONAL DETAILS</div>

        <div className={classes.subText}>{`
        Father’s Name: ${personalDetails.fatherName}`}</div>

        <div
          className={classes.subText}
        >{`Mother’s Name: ${personalDetails.motherName}`}</div>

        <div
          className={classes.subText}
        >{`Date of Birth: ${personalDetails.dob}`}</div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>EMPLOYMENT DETAILS</div>

        <div
          className={classes.subText}
        >{`Occupation: ${employmentDetails.occupationType}`}</div>

        <div className={classes.subText}>
          Industry: {employmentDetails.businessIndustry}
        </div>

        <div className={classes.subText}>
          Gross Yearly Income:{" "}
          {numberWithCurrencyAndComma(employmentDetails.annualIncome)}
        </div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>GOLD VALUATION INFORMATION</div>

        <div className={classes.subText}>
          Gross Weight of Gold : {ornamentsSummary.grossWeight}
        </div>

        <div className={classes.subText}>
          Live 22K Price : {numberWithCurrencyAndComma(twnty.liveGoldRate)}
        </div>

        <div className={classes.subText}>
          Final Value of Gold :{" "}
          {numberWithCurrencyAndComma(ornamentsSummary.newGoldValue)}
        </div>
      </div>
    </>
  );
};

export default LockerSummary;
