import styled from "@emotion/styled";

const IframeContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
`;

const IframeHeader = styled.div<{open: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({open}) => open ? "white" : "rgba(206, 159, 41, 0.5)"};
  border: 1px solid ${({open}) => open ? "grey" : "white"};
  border-bottom: none;
  border-radius: 5px;
  border-bottom-right-radius: ${({open}) => open ? "0" : "5px"};
  border-bottom-left-radius: ${({open}) => open ? "0" : "5px"};
  flex-direction: column;
  width: ${({open}) => open ? "350px" : "auto"};
`;

const ToolbarIconWrapper = styled.div`
  padding: 0.5rem;

  & svg {
    width: 2rem;
    height: 2rem;
  }
`;

export {IframeContainer, IframeHeader, ToolbarIconWrapper};
