import React, {FC, useEffect, useMemo, useState} from "react";
import {IGButton, IGLoading, IGModal} from "app/components";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  Alert,
  Box, capitalize,
  Chip, Collapse,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {customerProfileActions, customerProfileSelectors} from "app/store/customerProfile";
import {OverduePaymentList} from "./styles";
import {RemarkEnums} from "../../../../../enums/customerProfiles";

interface OverduePaymentDetailModalProps {
  open: boolean
  onClose: () => void
}

const OverduePaymentDetailModal: FC<OverduePaymentDetailModalProps> = ({
  open,
  onClose,
}) => {

  const {loanId} = useParams();
  const dispatch = useAppDispatch();
  const {t, tButton} = useIGTranslation();

  const [historyOpen, setHistoryOpen] = useState(false);

  const overduePaymentDetail = useAppSelector(
    customerProfileSelectors.getOverduePaymentDetails,
  );
  const reminderList = useAppSelector(
    customerProfileSelectors.getReminderList,
  );
  const {
    getOverduePaymentDetails: getOverduePaymentDetailsLoading,
    getReminders: getRemindersLoading,
    sendReminder: sendReminderLoading,
  } = useAppSelector(
    customerProfileSelectors.getLoading,
  );
  const {
    getOverduePaymentDetails: getOverduePaymentDetailsErrors,
  } = useAppSelector(
    customerProfileSelectors.getErrors,
  );

  const sendReminder = () => {
    dispatch(customerProfileActions.sendReminder({
      loanId: Number(loanId),
    }));
  };

  useEffect(() => {
    if (!loanId) return;
    dispatch(customerProfileActions.getOverduePaymentDetails({
      loanId: Number(loanId),
    }));
    dispatch(customerProfileActions.getReminders({
      loanId: Number(loanId),
    }));
  }, [loanId]);

  const paymentDetails = useMemo(() => {
    return [
      {
        text: t("LOAN_MANAGER"),
        value: overduePaymentDetail?.lmName,
      },
      {
        text: t("LOAN_AMOUNT"),
        value: numberWithCurrencyAndComma(overduePaymentDetail?.loanAmount),
      },
      {
        text: t("CUSTOMER_PROFILE.LAST_DUE_DATE"),
        value: overduePaymentDetail?.dueDate ? getValidTime(overduePaymentDetail?.dueDate).format("DD MMM, YYYY") : "-",
      },
      {
        text: t("CUSTOMER_PROFILE.JUMPER_APPLIED"),
        value: overduePaymentDetail?.jumperApplied ? t("YES") : t("NO"),
      },
      {
        text: t("OVERDUE_AMOUNT"),
        value: numberWithCurrencyAndComma(overduePaymentDetail?.overdueAmount),
      },
    ];
  }, [overduePaymentDetail, t]);

  return (
    <IGModal
      open={open}
      width={300}
      onClose={onClose}
      title="Overdue"
    >
      {getOverduePaymentDetailsErrors
        ? <Alert color="error" sx={{width: "100%"}}>
          {getOverduePaymentDetailsErrors.message || "Something went wrong"}
        </Alert>
        : (
          <>
            {getOverduePaymentDetailsLoading || getRemindersLoading
              ? <IGLoading height="400px"/>
              : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box textAlign="right" mb={1}>
                      <Chip
                        size="small"
                        color="error"
                        label={`${overduePaymentDetail?.dpd || ""} DPD`}
                        sx={{
                          fontWeight: "bold",
                        }}
                      />
                    </Box>
                    <OverduePaymentList>
                      {paymentDetails.map((row, index) => (
                        <Box className="list-item">
                          <Typography flex={1}>{row.text || "-"}</Typography>
                          <Typography fontWeight="bold" flex={1}>
                            {row.value || "-"}
                          </Typography>
                        </Box>
                      ))}
                    </OverduePaymentList>
                  </Grid>
                  <Grid item xs={12} textAlign="right">
                    <IGButton
                      loading={sendReminderLoading}
                      color="golden"
                      onClick={sendReminder}
                    >
                      {tButton("SEND_REMINDER")}
                    </IGButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex" alignItems="flex-end" gap={1}>
                            <Typography variant="h5" lineHeight="normal" fontWeight="bold">
                              {t("CUSTOMER_PROFILE.LM_REMARK")}
                            </Typography>
                            <Typography color={reminderList.length === 0 ? "error" : "green"}>
                              ({reminderList.length === 0 ? "Pending" : "Contacted"})
                            </Typography>
                          </Box>
                          <IGButton
                            size="small"
                            color="inherit"
                            onClick={() => setHistoryOpen(!historyOpen)}
                          >
                            {historyOpen
                              ? t("CUSTOMER_PROFILE.HIDE_HISTORY")
                              : t("CUSTOMER_PROFILE.VIEW_HISTORY")
                            }
                          </IGButton>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Collapse in={historyOpen}>
                          <Box maxHeight={165} overflow="auto">
                            <Stepper activeStep={reminderList.length} orientation="vertical">
                              {reminderList.map(
                                ({
                                   remark,
                                   createdAt,
                                   remarkEnum,
                                   promisedPayDate,
                                 }) => (
                                  <Step key={remark}>
                                    <StepLabel>
                                      <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box flex="1 1 auto">

                                          {remarkEnum !== RemarkEnums.OTHERS &&(
                                            <Box display="flex" alignItems="flex-end" gap={1}>
                                              <Typography color="#997a00" fontWeight="bold">
                                                {capitalize((remarkEnum || "-").replaceAll("_", " ").toLowerCase())}
                                              </Typography>
                                              {remarkEnum ===
                                                RemarkEnums.PROMISED_TO_PAY && (
                                                  <Typography color="#00000099" fontSize={10}>
                                                    ({promisedPayDate ? getValidTime(promisedPayDate).format("DD MMM, YYYY") : "-"})
                                                  </Typography>
                                                )}
                                            </Box>
                                          )}

                                          <Typography>
                                            {remark.length > 500
                                              ? `${remark.slice(0, 500)}...`
                                              : remark
                                            }
                                          </Typography>
                                        </Box>
                                        <Typography flexBasis="140px">
                                          {getValidTime(createdAt).format("DD MMM, YYYY, hh:mm a")}
                                        </Typography>
                                      </Box>
                                    </StepLabel>
                                  </Step>
                                ))}
                            </Stepper>
                          </Box>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </>
        )
      }
    </IGModal>
  );
};

export default OverduePaymentDetailModal;