import {createAction} from "@reduxjs/toolkit";
import {
  GetActiveLoansCRListPayload,
  ActiveLoansResponse,
  AssignCRToMePayload,
  SendBackToSchedulingPayload,
  GetLMUsersListPayload,
  AssignOrRemoveCurrentLMPayload,
  CloseCurrentLMPayload,
  BlackListLoanPayload,
  CrIdPayload,
  LoanAmountUpdateRequestPayload,
  LoanRenewalAmountDetailsPayload,
  LatestStatusPayload,
  ValidateTakeoverAmountPayload,
  LMInsuranceDetailsPayload,
  SkipOfficeReviewPayload,
  LandDocumentDetailPayload,
  MarkLandDocumentPayload,
  VerifyTakeoverAccountPayload,
  GetBtScoreCardConfig,
  RejectCrConfigPayload,
} from "../../infra/services/api/activeLoans/types";
import {setPaginationFor} from "app/store/factoryReducers";
import {Filters} from "./activeLoans.reducer";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";

export const activeLoansActionTypes = {
  GetCitiesList: "[ActiveLoans] Get Cities",
  GetCitiesListSuccess: "[ActiveLoans] Get Cities Success",
  GetCitiesListFailure: "[ActiveLoans] Get Cities Failure",
  GetCrList: "[ActiveLoans] Get Cr List",
  GetCrListSuccess: "[ActiveLoans] Get CR List Success",
  GetCrListFailure: "[ActiveLoans] Get CR List Failure",
  SetLoading: "[ActiveLoans] Set Loading",
  GetLoading: "[ActiveLoans] Get Loading",
  ApplyFilters: "[ActiveLoans] Apply Filters",
  ClearFilters: "[ActiveLoans] Clear Filters",
  ResetFilters: "[ActiveLoans] Reset Filters",
  AssignCrToMe: "[ActiveLoans] Assign Cr To me",
  AssignCrToMeSuccess: "[ActiveLoans] Assign Cr To Me success",
  AssignCrToMeFailure: "[ActiveLoans] Assign Cr To Me Failure",
  RemoveCreditManager: "[ActiveLoans] Remove Credit Manager",
  RemoveCreditManagerSuccess: "[ActiveLoans] Remove Credit Manager Success",
  RemoveCreditManagerFailure: "[ActiveLoans] Remove Credit Manager Failure",
  CloseCR: "[ActiveLoans] Close Customer Request",
  CloseCRSuccess: "[ActiveLoans] Close Customer Request Success",
  CloseCRFailure: "[ActiveLoans] Close Customer Request Failure",
  SendBackToScheduling: "[ActiveLoans] Send Back To Scheduling",
  SendBackToSchedulingSuccess: "[ActiveLoans] Send Back To Scheduling Success",
  SendBackToSchedulingFailure: "[ActiveLoans] Send Back To Scheduling Failure",
  GetLMJobDetails: "[ActiveLoans] Get LM Job Details",
  GetLMJobDetailsSuccess: "[ActiveLoans] Get LM Job Details Success",
  GetLMJobDetailsFailure: "[ActiveLoans] Get LM Job Details Failure",
  GetLMUsers: "[ActiveLoans] Get LM Users",
  GetLMUsersSuccess: "[ActiveLoans] Get LM Users Success",
  GetLMUsersFailure: "[ActiveLoans] Get LM Users Failure",
  AssignCurrentLM: "[ActiveLoans] Assign Current LM",
  AssignCurrentLMSuccess: "[ActiveLoans] Assign Current LM Success",
  AssignCurrentLMFailure: "[ActiveLoans] Assign Current LM Failure",
  RemoveCurrentLM: "[ActiveLoans] Remove Current LM",
  RemoveCurrentLMSuccess: "[ActiveLoans] Remove Current LM Success",
  RemoveCurrentLMFailure: "[ActiveLoans] Remove Current LM Failure",
  CloseLMJob: "[ActiveLoans] Close LM Job",
  CloseLMJobSuccess: "[ActiveLoans] Close LM Job Success",
  CloseLMJobFailure: "[ActiveLoans] Close LM Job Failure",
  BlackListLoan: "[ActiveLoans] Black List Loan",
  BlackListLoanSuccess: "[ActiveLoans] Black List Loan Success",
  BlackListLoanFailure: "[ActiveLoans] Black List Loan Failure",
  UpdateLoanAmount: "[ActiveLoans] Update Loan Amount Details",
  UpdateLoanAmountSuccess: "[ActiveLoans] Update Loan Amount Details Success",
  UpdateLoanAmountFailure: "[ActiveLoans] Update Loan Amount Details Failure",
  GetLoanTopUpAmount: "[ActiveLoans] Get Loan Top Up Amount",
  GetLoanTopUpAmountSuccess: "[ActiveLoans] Get Loan Top Up Amount Success",
  GetLoanTopUpAmountFailure: "[ActiveLoans] Get Loan Top Up Amount Failure",
  GetMakerFieldsFlag: "[ActiveLoans] Get Maker Fields Flag",
  GetMakerFieldsFlagSuccess: "[ActiveLoans] Get Maker Fields Flag Success",
  GetMakerFieldsFlagFailure: "[ActiveLoans] Get Maker Fields Flag Failure",
  GetLoanRenewalDetails: "[ActiveLoans] Get Loan Renewal Details",
  GetLoanRenewalDetailsSuccess: "[ActiveLoans] Get Loan Renewal Details Success",
  GetLoanRenewalDetailsFailure: "[ActiveLoans] Get Loan Renewal Details Failure",
  UpdateLoanRenewalDetails: "[ActiveLoans] Update Loan Renewal Details",
  UpdateLoanRenewalDetailsSuccess: "[ActiveLoans] Update Loan Renewal Details Success",
  UpdateLoanRenewalDetailsFailure: "[ActiveLoans] Update Loan Renewal Details Failure",
  SkipPersonalLoan: "[ActiveLoans] Skip Personal Loan",
  SkipPersonalLoanSuccess: "[ActiveLoans] Skip Personal Loan Success",
  SkipPersonalLoanFailure: "[ActiveLoans] Skip Personal Loan Failure",
  GetLatestStatus: "[ActiveLoans] Get Latest Status",
  GetLatestStatusSuccess: "[ActiveLoans] Get Latest Status Success",
  GetLatestStatusFailure: "[ActiveLoans] Get Latest Status Failure",
  GetLmInsuranceDetails: "[ActiveLoans] Get LM Insurance Details",
  GetLmInsuranceDetailsSuccess: "[ActiveLoans] Get LM Insurance Details Success",
  GetLmInsuranceDetailsFailure: "[ActiveLoans] Get LM Insurance Details Failure",
  GetInsuranceFeatureFlag: "[ActiveLoans] Get Insurance Flag",
  GetInsuranceFeatureFlagSuccess: "[ActiveLoans] Get Insurance Flag Success",
  GetInsuranceFeatureFlagFailure: "[ActiveLoans] Get Insurance Flag Failure",

  SkipOfficeReview: "[ActiveLoans] Skip Office Review",
  SkipOfficeReviewSuccess: "[ActiveLoans] Skip Office Review Success",
  SkipOfficeReviewFailure: "[ActiveLoans] Skip Office Review Failure",

  GetLandDocumentDetail: "[ActiveLoans] Get Land Document Detail",
  GetLandDocumentDetailSuccess: "[ActiveLoans] Get Land Document Detail Success",
  GetLandDocumentDetailFailure: "[ActiveLoans] Get Land Document Detail Failure",

  DeleteLandDocumentDetail: "[ActiveLoans] Delete Land Document Detail",
  DeleteLandDocumentDetailSuccess: "[ActiveLoans] Delete Land Document Detail Success",
  DeleteLandDocumentDetailFailure: "[ActiveLoans] Delete Land Document Detail Failure",

  GetMatchingLandDocumentDetail: "[ActiveLoans] Get Matching Land Document Detail",
  GetMatchingLandDocumentDetailSuccess: "[ActiveLoans] Get Matching Land Document Detail Success",
  GetMatchingLandDocumentDetailFailure: "[ActiveLoans] Get Matching Land Document Detail Failure",

  PostLandDocumentDetail: "[ActiveLoans] Post Land Document Detail",
  PostLandDocumentDetailSuccess: "[ActiveLoans] Post Land Document Detail Success",
  PostLandDocumentDetailFailure: "[ActiveLoans] Post Land Document Detail Failure",

  MarkLandDocument: "[ActiveLoans] Mark Land Document",
  MarkLandDocumentSuccess: "[ActiveLoans] Mark Land Document Success",
  MarkLandDocumentFailure: "[ActiveLoans] Mark Land Document Failure",

  GetLandStateList: "[ActiveLoans] Get Land State List",
  GetLandStateListSuccess: "[ActiveLoans] Get Land State List Success",
  GetLandStateListFailure: "[ActiveLoans] Get Land State List Failure",

  GetLandOwnerBorrowerList: "[ActiveLoans] Get Land Owner Borrower List",
  GetLandOwnerBorrowerListSuccess: "[ActiveLoans] Get Land Owner Borrower List Success",
  GetLandOwnerBorrowerListFailure: "[ActiveLoans] Get Land  Owner Borrower  List Failure",

  MarkLandDocumentAsSame: "[ActiveLoans] Mark Land Document As Same",
  MarkLandDocumentAsSameSuccess: "[ActiveLoans] Mark Land Document As Same Success",
  MarkLandDocumentAsSameFailure: "[ActiveLoans] Mark Land Document As Same Failure",

  GetAgriExposureAmount: "[ActiveLoans] Get Agri Exposure Amount",
  GetAgriExposureAmountSuccess: "[ActiveLoans] Get Agri Exposure Amount Success",
  GetAgriExposureAmountFailure: "[ActiveLoans] Get Agri Exposure Amount Failure",
  ActivateLoanMaker: "[ActiveLoans] Activate Loan Maker",
  ActivateLoanMakerSuccess: "[ActiveLoans] Activate Loan Maker Success",
  ActivateLoanMakerFailure: "[ActiveLoans] Activate Loan Maker Failure",

  GetPrefilledLoanMakerDetails: "[ActiveLoans] Get Prefilled loan maker details",
  GetPrefilledLoanMakerDetailsSuccess: "[ActiveLoans] Get Prefilled loan maker details Success",
  GetPrefilledLoanMakerDetailsFailure: "[ActiveLoans] Get Prefilled loan maker details Failure",

  GetPostLoanDocsFlag: "[ActiveLoans] Get Post Loan Docs Flag",
  GetPostLoanDocsFlagSuccess: "[ActiveLoans] Get Post Loan Docs Flag Success",
  GetPostLoanDocsFlagFailure: "[ActiveLoans] Get Post Loan Docs Flag Failure",
  GetLoanRequirementReason: "[ActiveLoans] Get  Loan Requirement Reason",
  GetLoanRequirementReasonFailure: "[ActiveLoans] Get  Loan Requirement Reason Failure",
  GetLoanRequirementReasonSuccess: "[ActiveLoans] Get  Loan Requirement Reason Success",
  GetActiveLoansTabsList: "[ActiveLoans] Get Active Loans Tabs List",
  GetActiveLoansTabsListSuccess: "[ActiveLoans] Get Active Loans Tabs List Success",
  GetActiveLoansTabsListFailure: "[ActiveLoans] Get Active Loans Tabs List Failure",
  RejectCr: "[ActiveLoans] Reject CR",
  RejectCrSuccess: "[ActiveLoans] Reject CR Success",
  RejectCrFailure: "[ActiveLoans] Reject CR Failure",

  GetRejectCrConfig: "[ActiveLoans] Get Reject CR Config",
  GetRejectCrConfigSuccess: "[ActiveLoans] Get Reject CR Config Success",
  GetRejectCrConfigFailure: "[ActiveLoans] Get Reject CR Config Failure",

  SendBackCr: "[ActiveLoans] Send Back CR",
  SendBackCrSuccess: "[ActiveLoans] Send Back CR Success",
  SendBackCrFailure: "[ActiveLoans] Send Back CR Failure",

  GetSendBackCrConfig: "[ActiveLoans] Get Send Back CR Config",
  GetSendBackCrConfigSuccess: "[ActiveLoans] Get Send Back CR Config Success",
  GetSendBackCrConfigFailure: "[ActiveLoans] Get Send Back CR Config Failure",
  GetLoanCheckerStatus: "[ActiveLoans] Get Loan Checker Status",
  GetLoanCheckerStatusSuccess: "[ActiveLoans] Get Loan Checker Status Success",
  GetLoanCheckerStatusFailure: "[ActiveLoans] Get Loan Checker Status Failure",
  SendApprovalMailToLender: "[ActiveLoans] Send Approval Mail To Lender",
  RequestLenderApproval: "[ActiveLoans] Request Lender Approval",
  GetBtScoreCardDetails: "[ActiveLoans] Get Bt Score Card Details",
  GetBtScoreCardConfig: "[ActiveLoans] Get Bt Score Card Config",
  GetBtScoreCardCustomerDetails: "[ActiveLoans] Get Bt Score Card Customer Details",
  GetCreditReportStatus: "[ActiveLoans] Get Credit Report Status",
  GetCreditDefaultHistory: "[ActiveLoans] Get Credit Default History",
  GetActionHistory: "[ActiveLoans] Get Action History",
};

export const activeLoansActions = {
  rejectCr: createAction(activeLoansActionTypes.RejectCr),
  rejectCrSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.RejectCrSuccess),
  rejectCrFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.RejectCrFailure),
  getRejectCrConfig: createAction<RejectCrConfigPayload>(activeLoansActionTypes.GetRejectCrConfig),
  getRejectCrConfigSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetRejectCrConfigSuccess,
  ),
  getRejectCrConfigFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetRejectCrConfigFailure,
  ),
  sendBackCr: createAction(activeLoansActionTypes.SendBackCr),
  sendBackCrSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.SendBackCrSuccess),
  sendBackCrFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.SendBackCrFailure),
  getSendBackCrConfig: createAction(activeLoansActionTypes.GetSendBackCrConfig),
  getSendBackCrConfigSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetSendBackCrConfigSuccess,
  ),
  getSendBackCrConfigFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetSendBackCrConfigFailure,
  ),
  getCitiesList: createAction(activeLoansActionTypes.GetCitiesList),
  getCitiesListSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetCitiesListSuccess,
  ),
  getCitiesListFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetCitiesListFailure,
  ),
  getCrList: createAction<GetActiveLoansCRListPayload>(activeLoansActionTypes.GetCrList),
  getCrListSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.GetCrListSuccess),
  getCrListFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.GetCrListFailure),
  getLoading: createAction<ActiveLoansResponse>(activeLoansActionTypes.GetLoading),
  setLoading: createAction<ActiveLoansResponse>(activeLoansActionTypes.SetLoading),
  applyFilters: createAction<Filters>(activeLoansActionTypes.ApplyFilters),
  clearFilters: createAction<ActiveLoansResponse>(activeLoansActionTypes.ClearFilters),
  resetFilters: createAction<ActiveLoansResponse>(activeLoansActionTypes.ResetFilters),
  assignCrToMe: createAction<AssignCRToMePayload>(activeLoansActionTypes.AssignCrToMe),
  assignCrToMeSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.AssignCrToMeSuccess,
  ),
  assignCrToMeFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.AssignCrToMeFailure,
  ),
  removeCreditManager: createAction<number>(activeLoansActionTypes.RemoveCreditManager),
  removeCreditManagerSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.RemoveCreditManagerSuccess,
  ),
  removeCreditManagerFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.RemoveCreditManagerFailure,
  ),
  closeCR: createAction<number>(activeLoansActionTypes.CloseCR),
  closeCRSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.CloseCRSuccess),
  closeCRFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.CloseCRFailure),
  sendBackToScheduling: createAction<SendBackToSchedulingPayload>(
    activeLoansActionTypes.SendBackToScheduling,
  ),
  sendBackToSchedulingSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SendBackToSchedulingSuccess,
  ),
  sendBackToSchedulingFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SendBackToSchedulingFailure,
  ),
  getLMJobDetails: createAction<number>(activeLoansActionTypes.GetLMJobDetails),
  getLMJobDetailsSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLMJobDetailsSuccess,
  ),
  getLMJobDetailsFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLMJobDetailsFailure,
  ),
  setActiveLoansPagination: setPaginationFor("activeLoans"),
  getLMUsers: createAction<GetLMUsersListPayload>(activeLoansActionTypes.GetLMUsers),
  getLMUsersSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.GetLMUsersSuccess),
  getLMUsersFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.GetLMUsersFailure),
  assignCurrentLM: createAction<AssignOrRemoveCurrentLMPayload>(
    activeLoansActionTypes.AssignCurrentLM,
  ),
  assignCurrentLMSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.AssignCurrentLMSuccess,
  ),
  assignCurrentLMFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.AssignCurrentLMFailure,
  ),
  removeCurrentLM: createAction<AssignOrRemoveCurrentLMPayload>(
    activeLoansActionTypes.RemoveCurrentLM,
  ),
  removeCurrentLMSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.RemoveCurrentLMSuccess,
  ),
  removeCurrentLMFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.RemoveCurrentLMFailure,
  ),

  closeLMJob: createAction<CloseCurrentLMPayload>(activeLoansActionTypes.CloseLMJob),
  closeLMJobSuccess: createAction<ActiveLoansResponse>(activeLoansActionTypes.CloseLMJobSuccess),
  closeLMJobFailure: createAction<ActiveLoansResponse>(activeLoansActionTypes.CloseLMJobFailure),
  blackListLoan: createAction<BlackListLoanPayload[]>(activeLoansActionTypes.BlackListLoan),
  blackListLoanSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.BlackListLoanSuccess,
  ),
  blackListLoanFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.BlackListLoanFailure,
  ),

  updateLoanAmount: createAction<LoanAmountUpdateRequestPayload & RequestIdPayload>(
    activeLoansActionTypes.UpdateLoanAmount,
  ),
  updateLoanAmountSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.UpdateLoanAmountSuccess,
  ),
  updateLoanAmountFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.UpdateLoanAmountFailure,
  ),
  getLoanTopUpAmount: createAction<CrIdPayload>(activeLoansActionTypes.GetLoanTopUpAmount),
  getLoanTopUpAmountSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanTopUpAmountSuccess,
  ),
  getLoanTopUpAmountFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanTopUpAmountFailure,
  ),

  getMakerFieldsFlag: createAction<CrIdPayload>(activeLoansActionTypes.GetMakerFieldsFlag),
  getMakerFieldsFlagSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetMakerFieldsFlagSuccess,
  ),
  getMakerFieldsFlagFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetMakerFieldsFlagFailure,
  ),
  getLoanRenewalDetails: createAction<CrIdPayload & RequestIdPayload>(
    activeLoansActionTypes.GetLoanRenewalDetails,
  ),
  getLoanRenewalDetailsSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanRenewalDetailsSuccess,
  ),
  getLoanRenewalDetailsFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanRenewalDetailsFailure,
  ),
  updateLoanRenewalDetails: createAction<LoanRenewalAmountDetailsPayload & RequestIdPayload>(
    activeLoansActionTypes.UpdateLoanRenewalDetails,
  ),
  updateLoanRenewalDetailsSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.UpdateLoanRenewalDetailsSuccess,
  ),
  updateLoanRenewalDetailsFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.UpdateLoanRenewalDetailsFailure,
  ),
  skipPersonalLoan: createAction<CrIdPayload>(activeLoansActionTypes.SkipPersonalLoan),
  skipPersonalLoanSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SkipPersonalLoanSuccess,
  ),
  skipPersonalLoanFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SkipPersonalLoanFailure,
  ),
  getLatestStatus: createAction<LatestStatusPayload>(activeLoansActionTypes.GetLatestStatus),
  getLatestStatusSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLatestStatusSuccess,
  ),
  getLatestStatusFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLatestStatusFailure,
  ),
  getLMInsuranceDetails: createAction<LMInsuranceDetailsPayload>(
    activeLoansActionTypes.GetLmInsuranceDetails,
  ),
  getLMInsuranceDetailsSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLmInsuranceDetailsSuccess,
  ),
  getLMInsuranceDetailsFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLmInsuranceDetailsFailure,
  ),
  getInsuranceFeatureFlag: createAction<CrIdPayload>(
    activeLoansActionTypes.GetInsuranceFeatureFlag,
  ),
  getInsuranceFeatureFlagSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetInsuranceFeatureFlagSuccess,
  ),
  getInsuranceFeatureFlagFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetInsuranceFeatureFlagFailure,
  ),

  skipOfficeReview: createAction<SkipOfficeReviewPayload>(activeLoansActionTypes.SkipOfficeReview),
  skipOfficeReviewSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SkipOfficeReviewSuccess,
  ),
  skipOfficeReviewFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.SkipOfficeReviewFailure,
  ),

  getLandDocumentDetail: createAction<CrIdPayload>(activeLoansActionTypes.GetLandDocumentDetail),
  getLandDocumentDetailSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandDocumentDetailSuccess,
  ),
  getLandDocumentDetailFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandDocumentDetailFailure,
  ),

  deleteLandDocumentDetail: createAction<CrIdPayload>(
    activeLoansActionTypes.DeleteLandDocumentDetail,
  ),
  deleteLandDocumentDetailSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.DeleteLandDocumentDetailSuccess,
  ),
  deleteLandDocumentDetailFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.DeleteLandDocumentDetailFailure,
  ),

  getMatchingLandDocumentDetail: createAction<CrIdPayload>(
    activeLoansActionTypes.GetMatchingLandDocumentDetail,
  ),
  getMatchingLandDocumentDetailSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetMatchingLandDocumentDetailSuccess,
  ),
  getMatchingLandDocumentDetailFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetMatchingLandDocumentDetailFailure,
  ),

  postLandDocumentDetail: createAction<LandDocumentDetailPayload>(
    activeLoansActionTypes.PostLandDocumentDetail,
  ),
  postLandDocumentDetailSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.PostLandDocumentDetailSuccess,
  ),
  postLandDocumentDetailFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.PostLandDocumentDetailFailure,
  ),

  markLandDocument: createAction<MarkLandDocumentPayload>(activeLoansActionTypes.MarkLandDocument),
  markLandDocumentSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.MarkLandDocumentSuccess,
  ),
  markLandDocumentFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.MarkLandDocumentFailure,
  ),

  getLandStateList: createAction(activeLoansActionTypes.GetLandStateList),

  getLandStateListSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandStateListSuccess,
  ),

  getLandStateListFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandStateListFailure,
  ),

  getLandOwnerBorrowerList: createAction(activeLoansActionTypes.GetLandOwnerBorrowerList),

  getLandOwnerBorrowerListSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandOwnerBorrowerListSuccess,
  ),

  getLandOwnerBorrowerListFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLandOwnerBorrowerListFailure,
  ),

  markLandDocumentAsSame: createAction<CrIdPayload>(activeLoansActionTypes.MarkLandDocumentAsSame),
  markLandDocumentAsSameSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.MarkLandDocumentAsSameSuccess,
  ),
  markLandDocumentAsSameFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.MarkLandDocumentAsSameFailure,
  ),

  getAgriExposureAmount: createAction<CrIdPayload>(activeLoansActionTypes.GetAgriExposureAmount),

  getAgriExposureAmountSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetAgriExposureAmountSuccess,
  ),

  getAgriExposureAmountFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetAgriExposureAmountFailure,
  ),
  activateLoanMaker: createAction<CrIdPayload>(activeLoansActionTypes.ActivateLoanMaker),
  activateLoanMakerSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.ActivateLoanMakerSuccess,
  ),
  activateLoanMakerFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.ActivateLoanMakerFailure,
  ),

  getPrefilledLoanMakerDetails: createAction<CrIdPayload>(
    activeLoansActionTypes.GetPrefilledLoanMakerDetails,
  ),
  getPrefilledLoanMakerDetailsSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetPrefilledLoanMakerDetailsSuccess,
  ),
  getPrefilledLoanMakerDetailsFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetPrefilledLoanMakerDetailsFailure,
  ),
  getPostLoanDocsFlag: createAction<CrIdPayload>(activeLoansActionTypes.GetPostLoanDocsFlag),
  getPostLoanDocsFlagSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetPostLoanDocsFlagSuccess,
  ),
  getPostLoanDocsFlagFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetPostLoanDocsFlagFailure,
  ),

  getLoanRequirementReason: createAction<CrIdPayload>(
    activeLoansActionTypes.GetLoanRequirementReason,
  ),
  getLoanRequirementReasonSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanRequirementReasonSuccess,
  ),
  getLoanRequirementReasonFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanRequirementReasonFailure,
  ),
  getActiveLoansTabsList: createAction(activeLoansActionTypes.GetActiveLoansTabsList),
  getActiveLoansTabsListSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetActiveLoansTabsListSuccess,
  ),
  getActiveLoansTabsListFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetActiveLoansTabsListFailure,
  ),
  getLoanCheckerStatus: createAction<CrIdPayload>(activeLoansActionTypes.GetLoanCheckerStatus),
  getLoanCheckerStatusSuccess: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanCheckerStatusSuccess,
  ),
  getLoanCheckerStatusFailure: createAction<ActiveLoansResponse>(
    activeLoansActionTypes.GetLoanCheckerStatusFailure,
  ),
  sendApprovalMailToLender: createAction<CrIdPayload>(
    activeLoansActionTypes.SendApprovalMailToLender,
  ),
  requestLenderApproval: createAction<CrIdPayload>(activeLoansActionTypes.RequestLenderApproval),
  getBtScoreCardDetails: createAction<CrIdPayload>(activeLoansActionTypes.GetBtScoreCardDetails),
  getBtScoreCardConfig: createAction<GetBtScoreCardConfig>(
    activeLoansActionTypes.GetBtScoreCardConfig,
  ),
  getBtScoreCardCustomerDetails: createAction<CrIdPayload>(
    activeLoansActionTypes.GetBtScoreCardCustomerDetails,
  ),
  getCreditReportStatus: createAction<CrIdPayload>(activeLoansActionTypes.GetCreditReportStatus),
  getCreditDefaultHistory: createAction<CrIdPayload>(
    activeLoansActionTypes.GetCreditDefaultHistory,
  ),
  getActionHistory: createAction<CrIdPayload>(activeLoansActionTypes.GetActionHistory),
};
