import React, {useCallback, useState} from "react";

import styles from "../../styles";
import {useSnackBar} from "app/store/hooks";
import updateStatus from "api/business/updateStatus";
import {Box} from "@mui/material";
import {IGButton, IGSelect} from "app/components";
import {useIGTranslation} from "app/infra/plugins/i18n";

const SendMessageToLM = ({
  selectedPayload,
  crJobId,
  setOpen,
  getMileStoneOfCr,
  getNextJobApi,
  reasons,
}) => {
  const snackbar = useSnackBar();
  const {tButton} = useIGTranslation();
  const classes = styles();
  const [message, setMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [rejectEnum, setRejectEnum] = useState("");

  const onClickHandler = useCallback(async () => {
    setButtonLoading(true);
    try {
      const response = await updateStatus({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        crJobId,
        remark: message,
        status: "FAILED",
        remarkEnum: rejectEnum,
      });

      if (response.statusCode === 200) {
        snackbar.showSuccess("Success");

        getMileStoneOfCr();
        getNextJobApi();

        setOpen(false);
      } else {
        snackbar.showError(response.error.toString());
      }
    } catch (error) {
      snackbar.showError("Something went Wrong");
    }
    setButtonLoading(false);
  }, [
    crJobId,
    getMileStoneOfCr,
    getNextJobApi,
    message,
    selectedPayload.id,
    setOpen,
    snackbar,
    rejectEnum,
  ]);

  return (
    <div>
      <div className={classes.title}>Update Details</div>

      {reasons && (
        <IGSelect
          label="Select any reason"
          value={rejectEnum}
          name="rejectEnum"
          onChange={(ev) => setRejectEnum(ev.target.value)}
          options={reasons.map((item) => ({
            text: item.rejectionDescription,
            value: item.rejectionReason,
          }))}
        />
      )}

      <textarea
        placeholder="Send a message to the LM of what needs to be updated in the application"
        value={message}
        className={classes.textArea}
        onChange={(event) => setMessage(event.target.value)}
      />

      <Box mt={1}>
        <IGButton
          disabled={!message || (reasons && !rejectEnum)}
          loading={buttonLoading}
          onClick={onClickHandler}
        >
          {tButton("SEND_MESSAGE_TO_LM")}
        </IGButton>
      </Box>
    </div>
  );
};

export default SendMessageToLM;
