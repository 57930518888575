import React, {useCallback, useState, useEffect} from "react";

import styles from "../styles";
import getTrackingUrl from "api/business/getTrackingId";
import getInitialData from "api/business/getInitialData";
import {useSnackBar} from "app/store/hooks";
import {PhoneRounded} from "@mui/icons-material";

const Created = ({selectedPayload, handleDialer}) => {
  const classes = styles();
  const {showError} = useSnackBar();

  const [initialData, setInitialData] = useState({
    leadSource: null,
    advocateEmail: null,
    leadReferralCode: null,
    rmName: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const {payload, statusCode, error} = await getInitialData({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
      });

      if (statusCode === 200) {
        setInitialData({
          leadSource: payload.leadSource,
          advocateEmail: payload.advocateEmail,
          leadReferralCode: payload.leadReferralCode,
          rmName: payload.rmName,
        });
      } else {
        showError(error || "Error while fetching Initial Data");
      }
    };
    fetchData();
  }, [selectedPayload.id]);

  const trackingOpenHandler = useCallback(
    async (id) => {
      try {
        const response = await getTrackingUrl({
          accessToken: localStorage.getItem("authToken"),
          agentId: id,
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          window.open(response.payload.link);
        } else {
          showError(response.error);
        }
      } catch (error) {
        showError("Something went wrong");
      }
    },
    [selectedPayload.id],
  );

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>CONTACT CUSTOMER</div>
        <div className={classes.data}>
          Name:
          <div className={classes.valueText}>
            {selectedPayload.name || "N/A"}
          </div>
        </div>
        <div className={classes.data}>
          Contact Number:
          <div className={classes.valueText}>
            {selectedPayload.mobile || "N/A"}

            {handleDialer && (
              <PhoneRounded
                onClick={(e) => handleDialer(e, selectedPayload.mobile)}
                sx={{marginLeft: "1rem"}}
                color="primary"
              />
            )}
          </div>
        </div>
        <div className={classes.data}>
          RM Name:
          <div className={classes.valueText}>
            {initialData?.rmName || "N/A"}
          </div>
        </div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>TRACK LOAN MANAGER</div>
        {selectedPayload.businessFieldAgentResponseDtoList.map((agent) => {
          return (
            <div
              onClick={() => trackingOpenHandler(agent.id)}
              className={classes.button}
              key={agent.id}
            >
              <span>{agent.name}</span>
              <span> ({agent.mobile})</span>
            </div>
          );
        })}

        <div className={classes.data}>
          LEAD SOURCE:{" "}
          <div className={classes.valueText}>
            {initialData.leadSource || "N/A"}
          </div>
        </div>
        <div className={classes.data}>
          ADVOCATE EMAIL:
          <div className={classes.valueText}>
            {initialData.advocateEmail || "N/A"}
          </div>
        </div>
        <div className={classes.data}>
          LEAD REFERRAL CODE:
          <div className={classes.valueText}>
            {initialData.leadReferralCode || "N/A"}
          </div>
        </div>
      </div>
    </>
  );
};

export default Created;
