import styled from "@emotion/styled";

const DocumentsImageWrapper = styled.div`
  margin-right: 1rem;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: column;
`;

export default DocumentsImageWrapper;
