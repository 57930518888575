import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, IconButton, Typography, FormHelperText, Grid} from "@mui/material";
import {InfoOutlined, InfoRounded, Visibility} from "@mui/icons-material";
import {
  IGButton,
  IGLoading,
  IGTable,
  IGTextField,
  IGFileUpload,
} from "app/components";
import {IGTableColsInterface} from "app/components/IGTable";
import EditIcon from "@mui/icons-material/Edit";
import {useAppSelector, useAppDispatch} from "app/store/hooks";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import NewLoanAmountUpdationModal from "./Modals/NewLoanAmountUpdationModal";
import LoanDetailsBox from "./styled";
import TopUpAmount from "./TopUpAmount";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {
  resetLoanDetailsState,
  toggleModals,
} from "app/store/activeLoans/activeLoans.reducer";
import {RootState} from "app/store/store";
import TransactionDetails from "./TransactionsDetails";
import {useFormik} from "formik";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  transactionsActions,
  transactionsSelectors,
} from "app/store/transactions";
import {useSnackBar} from "app/store/hooks";
import updateMileStones from "api/business/completeMileStone";
import {resetTransactionList} from "app/store/transactions/transactions.reducer";
import {
  useIGValidations,
  useNumberValidations,
} from "app/validations";
import ReturnToGoldValuationPopUp from "./Modals/ReturnToGoldValuationPopUp";
import {useParams} from "react-router-dom";
import InfoIcon from "app/assets/uploadKYC/InfoIcon";
import tippy from "tippy.js";
interface LoanDetailsProps {
  crId: number;
  currentMileStone?: any;
  selectedPayload?: any;
  getMileStoneOfCr: () => void;
  mileStones?: any;
  isLoanRenewalFlow: boolean;
}

interface LoanDetailsData {
  adjustedAmount?: number;
  amount?: number;
  remainingAmountToCollect?: number;
  waivedOffAmount?: number;
  type: string;
  id: number;
}

const LoanDetails = ({
  crId,
  getMileStoneOfCr,
  currentMileStone,
  selectedPayload,
  mileStones,
  isLoanRenewalFlow,
}: LoanDetailsProps) => {
  const dispatch = useAppDispatch();
  const {requestId} = useParams();
  const {showSuccess, showError} = useSnackBar();
  const [approvalScreenshots, setApprovalScreenshots] = useState<any>([]);
  const [selectedLoanData, setSelectedLoanData] = useState<null | {
    loanAmount: number;
    loanType: string;
    pfAmount: number;
  }>(null);

  const [
    isReturnToValuationModalOpen,
    setIsReturnToValuationModalOpen,
  ] = useState(false);

  const [isUpdateMileStoneLoading, setIsUpdateMileStoneLoading] = useState<
    boolean
  >(false);
  const {
    newLoanAmountUpdationModal: isLoanAmountUpdationModalOpen,
  } = useAppSelector(activeLoansSelectors.getOpenModals);

  const topUpAmountDetails = useAppSelector((state: RootState) =>
    activeLoansSelectors.getLoanTopUpAmountByCrId(state, crId),
  );

  const {
    getLoanRenewalDetails: isLoanRenewalDetailsLoading,
    updateLoanRenewalDetails: isCalculateTopUpLoading,
    getLoanTopUpAmount: isTopUpAmountDetailsLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);

  const loanRenewalDetails = useAppSelector((state: RootState) =>
    activeLoansSelectors.getLoanRenewalDetailsByCrId(state, crId),
  );

  const {
    createTransaction: isCreateTransactionLoading,
    proceedAfterAdjustment: isProceedAfterAdjustmentLoading,
  } = useAppSelector(transactionsSelectors.getLoading);
  const transactionsList = useAppSelector(
    transactionsSelectors.getTransactionsList,
  );

  const {
    shareOfferWithCustomer: isShareOfferWithCustomerLoading,
  } = useAppSelector(transactionsSelectors.getLoading);

  const shareOfferWithCustomerSuccess = useAppSelector(
    transactionsSelectors.getShareOfferWithCustomerSuccess,
  );

  const {
    glRenewalAmountAdjustmentDto,
    plRenewalAmountAdjustmentDto,
    totalAdjustedAmount,
    totalAmountToCollectFromCustomer,
    totalInterestIncludingForeclosureAndExcessAmount,
    totalWaivedOffAmount,
    newPersonalLoanAmount,
    newGoldLoanAmount,
    totalMarginMoney,
    maxWaivedAllowedForTransactionModule = 0,
    maxAllowedGlAdjustedInterestAmount,
    maxAllowedPlAdjustedInterestAmount,
    approvalScreenshotFileUrl,
    loanId,
    // eslint-disable-next-line max-len
    totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount,
    totalActualPfAmountIncludingGst,
    maxPfApplicableIncludingGst,
    isInterestAdjustmentAllowedWithTopUp,
    totalAdjustedPfAmountWithTopUp,
    totalOutstandingPublicationChargeAmount,
  } = loanRenewalDetails ?? {};
  const {
    adjustedAmount: glAdjustedAmount = 0,

    // eslint-disable-next-line max-len
    outstandingPrincipalAmountWithExcessAmount: glOutstandingPrincipalAmountWithExcessAmount,
    // eslint-disable-next-line max-len
    interestAmountTillDateIncludingForeclosureAndExcessAmount: glInterestAmountTillDateIncludingForeclosureAndExcessAmount = 0,
    remainingAmountToCollect: glRemainingAmountToCollect = 0,
    waivedOffAmount: glWaivedOffAmount = 0,
  } = glRenewalAmountAdjustmentDto ?? {};
  const {
    adjustedAmount: plAdjustedAmount = 0,
    // eslint-disable-next-line max-len
    outstandingPrincipalAmountWithExcessAmount: plOutstandingPrincipalAmountWithExcessAmount,
    remainingAmountToCollect: plRemainingAmountToCollect = 0,
    // eslint-disable-next-line max-len
    interestAmountTillDateIncludingForeclosureAndExcessAmount: plInterestAmountTillDateIncludingForeclosureAndExcessAmount = 0,
    waivedOffAmount: plWaivedOffAmount = 0,
  } = plRenewalAmountAdjustmentDto ?? {};

  const validationSchema = useIGValidations({
    glAdjustedAmount: useNumberValidations({
      custom: {
        name: "Max Gl Adjusted Amount Check",
        message: `GL adjusted interest amount should either be zero or maximum can be ${numberWithCurrencyAndComma(
          maxAllowedGlAdjustedInterestAmount,
        )}`,
        test: (value: number) => {
          if (value > 0 && value !== maxAllowedGlAdjustedInterestAmount) {
            return false;
          }

          return true;
        },
      },
    }),

    plAdjustedAmount: useNumberValidations({
      custom: {
        name: "plAdjustedAmount",
        test: (value: number, context) => {
          if (value > 0 && value !== maxAllowedPlAdjustedInterestAmount) {
            return context.createError({
              message: `PL adjusted interest amount should either be zero or maximum can be ${numberWithCurrencyAndComma(
                maxAllowedPlAdjustedInterestAmount,
              )}`,
            });
          }
          if (context.parent.glAdjustedAmount === 0 && value > 0) {
            return context.createError({
              message: "First Put value in GL then PL",
            });
          }
          return true;
        },
      },
    }),
  });

  const {
    handleBlur,
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues: {
      glRemainingAmountToCollect: glRemainingAmountToCollect,
      glAdjustedAmount: glAdjustedAmount,
      glWaivedOffAmount: glWaivedOffAmount,
      plRemainingAmountToCollect: plRemainingAmountToCollect,
      plAdjustedAmount: plAdjustedAmount,
      plWaivedOffAmount: plWaivedOffAmount,
      totalAdjustedAmount: totalAdjustedAmount,
      totalAmountToCollectFromCustomer: totalAmountToCollectFromCustomer,
      totalWaivedOffAmount: totalWaivedOffAmount,
      remarks: "",
      crId: crId,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      if (
        values.glAdjustedAmount !== 0 ||
        values.glWaivedOffAmount !== 0 ||
        values.plAdjustedAmount !== 0 ||
        values.plWaivedOffAmount !== 0
      ) {
        if (!approvalScreenshotFileUrl && approvalScreenshots.length === 0) {
          showError("Please upload approval screenshots");
          return;
        }
      }

      try {
        const formData = new FormData();

        formData.append(
          "glWaivedOffAmount",
          values.glWaivedOffAmount?.toString() ?? "",
        );
        formData.append("loanId", loanId?.toString() ?? "");
        formData.append(
          "plWaivedOffAmount",
          values.plWaivedOffAmount?.toString() ?? "",
        );
        formData.append(
          "glAdjustedInterestAmount",
          values.glAdjustedAmount?.toString() ?? "",
        );
        formData.append(
          "plAdjustedInterestAmount",
          values.plAdjustedAmount?.toString() ?? "",
        );

        formData.append("approvalScreenshot", approvalScreenshots[0]);
        dispatch(
          activeLoansActions.updateLoanRenewalDetails({
            crId: Number(crId),
            data: formData,
            requestId: Number(requestId),
          }),
        );
      } catch (error) {}
    },
  });

  const plRemainingAmountToBeCollected = useMemo(() => {
    const plAmountToCollect = parseFloat(
      Number(
        plInterestAmountTillDateIncludingForeclosureAndExcessAmount -
          values.plAdjustedAmount,
      ).toFixed(2),
    );
    return plAmountToCollect;
  }, [
    plInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    values.plAdjustedAmount,
  ]);
  const glRemainingAmountToBeCollected = useMemo(() => {
    const glAmountToCollect = parseFloat(
      Number(
        glInterestAmountTillDateIncludingForeclosureAndExcessAmount -
          values.glAdjustedAmount,
      ).toFixed(2),
    );
    return glAmountToCollect;
  }, [
    glInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    values.glAdjustedAmount,
  ]);

  const totalRemainingAmountToBeCollected = useMemo(() => {
    const totalRemainingAmount =
      glRemainingAmountToBeCollected + plRemainingAmountToBeCollected;
    return parseFloat(totalRemainingAmount.toFixed(2));
  }, [glRemainingAmountToBeCollected, plRemainingAmountToBeCollected]);

  const calculateMaxAllowedGLWaiveOff = useMemo(() => {
    const totalAllowedWaiveOffAmount = Math.min(
      Number(maxWaivedAllowedForTransactionModule),
      Number(totalRemainingAmountToBeCollected),
    );
    const allowedGLWaiveOff = Math.min(
      Number(totalAllowedWaiveOffAmount),
      Number(glRemainingAmountToBeCollected),
    );
    return allowedGLWaiveOff;
  }, [
    glRemainingAmountToBeCollected,
    maxWaivedAllowedForTransactionModule,
    totalRemainingAmountToBeCollected,
  ]);

  const calculateMaxAllowedPLWaiveOff = useMemo(() => {
    const totalAllowedWaiveOffAmount = Number(
      maxWaivedAllowedForTransactionModule - values.glWaivedOffAmount,
    );
    const allowedPLWaiveOff = Math.min(
      Number(totalAllowedWaiveOffAmount),
      Number(plRemainingAmountToBeCollected),
    );
    return allowedPLWaiveOff;
  }, [
    maxWaivedAllowedForTransactionModule,
    plRemainingAmountToBeCollected,
    values.glWaivedOffAmount,
  ]);

  // calculate total amount to be collected from the customer

  const totalAmountToBeCollectedFromCustomer = useMemo(() => {
    const remainingAmountToCollectGl = (
      parseFloat(
        // eslint-disable-next-line max-len
        glInterestAmountTillDateIncludingForeclosureAndExcessAmount?.toString() ??
          "0",
      ) -
      parseFloat(values.glAdjustedAmount?.toString() ?? "0") -
      parseFloat(values.glWaivedOffAmount?.toString() ?? "0")
    ).toFixed(2);

    const remainingAmountToCollectPl = (
      parseFloat(
        // eslint-disable-next-line max-len
        plInterestAmountTillDateIncludingForeclosureAndExcessAmount?.toString() ??
          "0",
      ) -
      parseFloat(values.plAdjustedAmount?.toString() ?? "0") -
      parseFloat(values.plWaivedOffAmount?.toString() ?? "0")
    ).toFixed(2);

    const totalAmountToCollectFromCustomer =
      parseFloat(remainingAmountToCollectGl) +
      parseFloat(remainingAmountToCollectPl);
    return totalAmountToCollectFromCustomer.toString();
  }, [
    glInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    plInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    values.glAdjustedAmount,
    values.glWaivedOffAmount,
    values.plAdjustedAmount,
    values.plWaivedOffAmount,
  ]);

  const customErrors = useMemo(() => {
    const errors: string[] = [];
    if (
      Number(
        (
          parseFloat(
            // eslint-disable-next-line max-len
            plInterestAmountTillDateIncludingForeclosureAndExcessAmount.toString() ??
              "0",
          ) -
          parseFloat(values.plAdjustedAmount.toString() ?? "0") -
          parseFloat(values.plWaivedOffAmount.toString() ?? "0")
        ).toFixed(2),
      ) < 0
    ) {
      errors.push("PL remaining amount cannot be less than zero!");
    }
    if (
      Number(
        (
          parseFloat(
            // eslint-disable-next-line max-len
            glInterestAmountTillDateIncludingForeclosureAndExcessAmount.toString() ??
              "0",
          ) -
          parseFloat(values.glAdjustedAmount.toString() ?? "0") -
          parseFloat(values.glWaivedOffAmount.toString() ?? "0")
        ).toFixed(2),
      ) < 0
    ) {
      errors.push("GL remaining amount cannot be less than zero!");
    }
    if (
      Number(
        parseFloat(
          (
            (glInterestAmountTillDateIncludingForeclosureAndExcessAmount ?? 0) -
            (values.glAdjustedAmount ?? 0) -
            (values.glWaivedOffAmount ?? 0)
          ).toFixed(2),
        ),
      ) > 0 &&
      Number(values.glWaivedOffAmount === 0) &&
      Number(values.plWaivedOffAmount) > 0
    ) {
      errors.push("First do WaiveOff in GL");
    }
    if (
      values.glWaivedOffAmount > 0 &&
      values.glWaivedOffAmount !== Number(calculateMaxAllowedGLWaiveOff)
    ) {
      errors.push(
        `GL waived off amount can either be zero or max can be ${numberWithCurrencyAndComma(
          calculateMaxAllowedGLWaiveOff,
        )}!`,
      );
    }
    if (
      values.plWaivedOffAmount > 0 &&
      values.plWaivedOffAmount !== Number(calculateMaxAllowedPLWaiveOff)
    ) {
      errors.push(
        `PL waived off amount can either be zero or max can be ${numberWithCurrencyAndComma(
          calculateMaxAllowedPLWaiveOff,
        )}!`,
      );
    }
    if (
      Number(values.plWaivedOffAmount) + Number(values.glWaivedOffAmount) >
      (maxWaivedAllowedForTransactionModule ?? 0)
    ) {
      errors.push(
        `PL + GL Waived Off Amount should be < ${numberWithCurrencyAndComma(
          maxWaivedAllowedForTransactionModule,
        )}`,
      );
    }

    if (Number(totalAmountToBeCollectedFromCustomer) < 0) {
      errors.push(
        "Total Amount to collect from customer cannot be less than zero",
      );
    }

    return errors;
  }, [
    plInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    values.plAdjustedAmount,
    values.plWaivedOffAmount,
    values.glAdjustedAmount,
    values.glWaivedOffAmount,
    glInterestAmountTillDateIncludingForeclosureAndExcessAmount,
    calculateMaxAllowedGLWaiveOff,
    calculateMaxAllowedPLWaiveOff,
    maxWaivedAllowedForTransactionModule,
    totalAmountToBeCollectedFromCustomer,
  ]);

  const tableData = [
    {
      id: 1,
      type: "GL",
      amount: glInterestAmountTillDateIncludingForeclosureAndExcessAmount ?? 0,
      remainingAmountToCollect: values.glRemainingAmountToCollect ?? 0,
      adjustedAmount: values.glAdjustedAmount ?? 0,
      waivedOffAmount: values.glWaivedOffAmount ?? 0,
    },
    {
      id: 2,
      type: "Total",
      amount: totalInterestIncludingForeclosureAndExcessAmount ?? 0,
      remainingAmountToCollect: Number(
        totalAmountToBeCollectedFromCustomer ?? 0,
      ),
      adjustedAmount: values.totalAdjustedAmount ?? 0,
      waivedOffAmount: values.totalWaivedOffAmount ?? 0,
    },
  ];
  if (plRenewalAmountAdjustmentDto) {
    tableData.splice(1, 0, {
      id: 3,
      type: "PL",
      amount: plInterestAmountTillDateIncludingForeclosureAndExcessAmount ?? 0,
      remainingAmountToCollect: values.plRemainingAmountToCollect ?? 0,
      adjustedAmount: values.plAdjustedAmount ?? 0,
      waivedOffAmount: values.plWaivedOffAmount ?? 0,
    });
  }

  const tableCols: IGTableColsInterface<LoanDetailsData> = [
    {
      field: "type",
      headerName: "Loan Type",
      sortable: false,
      headerClassName: "loans-details-header",
      renderCell: ({row}) => (
        <Typography fontWeight={600}>{row.type}</Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Total Interest Amount",
      sortable: false,
      headerClassName: "loans-details-header",
      renderCell: ({row}) => {
        return (
          <Typography color="text.secondary">
            {numberWithCurrencyAndComma(row.amount)}
          </Typography>
        );
      },
    },
    {
      field: "remainingAmountToCollect",
      headerName: "Collect Interest from customer",
      headerClassName: "loans-details-header",
      sortable: false,
      renderCell: ({row}) => (
        <>
          {row.type !== "Total" ? (
            <IGTextField
              disabled
              type="number"
              value={parseFloat(
                (
                  (row.amount ?? 0) -
                  (row.adjustedAmount ?? 0) -
                  (row.waivedOffAmount ?? 0)
                ).toFixed(2),
              )}
              name="collectFromCustomer"
              placeholder="Collect from customer"
              InputProps={{
                startAdornment: (
                  <Typography mr={1} color="text.secondary">
                    ₹
                  </Typography>
                ),
              }}
              inputProps={{
                step: 0.01,
              }}
              onChange={(e) => {
                const {value} = e.target;
                setFieldValue(
                  `${row.type.toLowerCase()}RemainingAmountToCollect`,
                  Number(Number(value).toFixed(2)),
                );
              }}
              onBlur={handleBlur}
              error={
                !!errors.glRemainingAmountToCollect ||
                !!errors.plRemainingAmountToCollect
              }
              touched={
                !!touched.glRemainingAmountToCollect ||
                !!touched.plRemainingAmountToCollect
              }
            />
          ) : (
            <Typography color="text.secondary">
              {numberWithCurrencyAndComma(row.remainingAmountToCollect)}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "adjustedAmount",
      headerName: "Adjust with new loan",
      headerClassName: "loans-details-header",
      sortable: false,
      renderCell: ({row}) => (
        <>
          {row.type !== "Total" ? (
            <IGTextField
              value={row.adjustedAmount ?? ""}
              disabled={
                !isInterestAdjustmentAllowedWithTopUp ||
                (topUpAmountDetails !== null &&
                  topUpAmountDetails !== undefined)
              }
              name="adjustWithNewLoan"
              type="number"
              placeholder="Adjust with new loan"
              InputProps={{
                startAdornment: (
                  <Typography mr={1} color="text.secondary">
                    ₹
                  </Typography>
                ),
              }}
              inputProps={{
                step: 0.01,
              }}
              onChange={(e) => {
                const {value} = e.target;
                setFieldValue(
                  `${row.type.toLowerCase()}AdjustedAmount`,
                  Number(Number(value).toFixed(2)),
                );
              }}
              onBlur={handleBlur}
              error={
                (row.type === "PL" && !!errors.plAdjustedAmount) ||
                (row.type !== "PL" && !!errors.glAdjustedAmount)
              }
              touched={
                (row.type === "PL" && !!touched.plAdjustedAmount) ||
                (row.type !== "PL" && !!touched.glAdjustedAmount)
              }
            />
          ) : (
            <Typography color="text.secondary">
              {numberWithCurrencyAndComma(row.adjustedAmount)}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "waivedOffAmount",
      headerName: "Waive Off Interest",
      headerClassName: "loans-details-header",
      sortable: false,
      renderCell: ({row}) => (
        <>
          {row.type !== "Total" ? (
            <IGTextField
              value={row.waivedOffAmount ?? ""}
              name="waiveOff"
              type="number"
              disabled={
                topUpAmountDetails !== null && topUpAmountDetails !== undefined
              }
              placeholder="Waive off"
              InputProps={{
                startAdornment: (
                  <Typography mr={1} color="text.secondary">
                    ₹
                  </Typography>
                ),
              }}
              inputProps={{
                step: 0.01,
              }}
              onChange={(e) => {
                const {value} = e.target;
                setFieldValue(
                  `${row.type.toLowerCase()}WaivedOffAmount`,
                  Number(Number(value).toFixed(2)),
                );
              }}
              onBlur={handleBlur}
              error={
                (row.type === "PL" && !!errors.plWaivedOffAmount) ||
                (row.type !== "PL" && !!errors.glWaivedOffAmount)
              }
              touched={
                (row.type === "PL" && !!touched.plWaivedOffAmount) ||
                (row.type !== "PL" && !!touched.glWaivedOffAmount)
              }
            />
          ) : (
            <Typography color="text.secondary">
              {numberWithCurrencyAndComma(row.waivedOffAmount)}
            </Typography>
          )}
        </>
      ),
    },
  ];

  // //UI Helpers

  const isAwaitingPaymentFromCustomer = useMemo(() => {
    return (
      !isLoanRenewalDetailsLoading &&
      loanRenewalDetails &&
      topUpAmountDetails &&
      (Number(totalAmountToCollectFromCustomer) > 0 ||
        Number(totalMarginMoney) > 0 ||
        Number(totalOutstandingPublicationChargeAmount) > 0 ||
        Number(
          totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount,
        ) > 0)
    );
  }, [
    isLoanRenewalDetailsLoading,
    loanRenewalDetails,
    topUpAmountDetails,
    totalAmountToCollectFromCustomer,
    totalMarginMoney,
    totalOutstandingPublicationChargeAmount,
    totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount,
  ]);

  const isCurrentMilestoneCompleted = useMemo(() => {
    if (!isLoanRenewalFlow && mileStones) {
      const nextMilestone = mileStones?.payload?.find(
        (item: any) => item.milestone === "CR_GOLD_LOAN_FRESH_SUMMARY",
      );
      if (nextMilestone) {
        const isNextMilestoneCompleted = nextMilestone.isComplete;
        return isNextMilestoneCompleted;
      }
    }
    return false;
  }, [mileStones]);

  const collectFromCustomerDetails = useMemo(() => {
    if (loanRenewalDetails) {
      const customerCollection = [];
      customerCollection.push(
        {
          label: "Margin Money",
          value: numberWithCurrencyAndComma(totalMarginMoney),
        },
        {
          label: "Interest And Other Dues",
          value: numberWithCurrencyAndComma(totalAmountToCollectFromCustomer),
        },
        {
          label: "Due Processing Fee",
          value: numberWithCurrencyAndComma(
            // eslint-disable-next-line max-len
            totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount,
          ),
        },
        {
          label: "Publication Charges",
          value: numberWithCurrencyAndComma(
            totalOutstandingPublicationChargeAmount,
          ),
        },
      );
      return customerCollection;
    }
    return [];
  }, [
    loanRenewalDetails,
    totalAmountToCollectFromCustomer,
    totalMarginMoney,
    totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount,
    totalOutstandingPublicationChargeAmount,
  ]);

  const oldLoanPrincipalDues = useMemo(() => {
    if (loanRenewalDetails) {
      const dues = [];
      if (glRenewalAmountAdjustmentDto) {
        dues.push({
          label: "Outstanding principal:",
          value: numberWithCurrencyAndComma(
            glOutstandingPrincipalAmountWithExcessAmount,
          ),
        });
      }
      if (plRenewalAmountAdjustmentDto) {
        dues.push({
          label: "PL outstanding principal:",
          value: numberWithCurrencyAndComma(
            plOutstandingPrincipalAmountWithExcessAmount,
          ),
        });
      }

      return dues;
    }

    return [];
  }, [
    glOutstandingPrincipalAmountWithExcessAmount,
    glRenewalAmountAdjustmentDto,
    loanRenewalDetails,
    plOutstandingPrincipalAmountWithExcessAmount,
    plRenewalAmountAdjustmentDto,
  ]);

  const newLoanDetails = useMemo(() => {
    if (loanRenewalDetails) {
      const newLoanDetails = [];
      if (newGoldLoanAmount) {
        newLoanDetails.push({
          loanType: "GOLD_LOAN",
          loanAmount: newGoldLoanAmount,
          label: "New Loan Amount:",
          value: numberWithCurrencyAndComma(newGoldLoanAmount),
          pfLabel: "Loan Processing Fees:",
          maxPfLabel: "Loan Max Processing Fees:",
          pfAmount: totalActualPfAmountIncludingGst,
          maxPfAmount: maxPfApplicableIncludingGst,
        });
      }

      if (newPersonalLoanAmount) {
        newLoanDetails.push({
          loanType: "PERSONAL_LOAN",
          loanAmount: newPersonalLoanAmount,
          pfLabel: "PL PF:",
          label: "PL Amount:",
          value: numberWithCurrencyAndComma(newPersonalLoanAmount),
          pfAmount: 0,
          maxPfAmount: 0,
        });
      }
      return newLoanDetails;
    }
    return [];
  }, [loanRenewalDetails, newGoldLoanAmount, newPersonalLoanAmount]);

  const onUpdateMilestoneHandler = useCallback(async () => {
    setIsUpdateMileStoneLoading(true);
    if (
      currentMileStone?.data?.milestone === "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
    ) {
      const response = await updateMileStones({
        accessToken: localStorage.getItem("authToken"),
        crId: crId,
        milestone: "CR_GOLD_LOAN_FRESH_SUMMARY",
      });
      if (response.statusCode === 200) {
        showSuccess("Success");
        getMileStoneOfCr();
        setIsUpdateMileStoneLoading(false);
      } else {
        showError(response?.error?.toString());
        setIsUpdateMileStoneLoading(false);
      }
    } else {
      const response = await updateMileStones({
        accessToken: localStorage.getItem("authToken"),
        crId: crId,
        milestone: "CR_GOLD_LOAN_BT_SUMMARY",
      });
      if (response.statusCode === 200) {
        showSuccess("Success");
        getMileStoneOfCr();
        setIsUpdateMileStoneLoading(false);
      } else {
        showError(response.error.toString());
        setIsUpdateMileStoneLoading(false);
      }
    }
  }, [
    crId,
    currentMileStone?.data?.milestone,
    getMileStoneOfCr,
    showError,
    showSuccess,
  ]);

  useEffect(() => {
    if (!isLoanRenewalFlow && isCurrentMilestoneCompleted) {
      dispatch(
        activeLoansActions.getLoanTopUpAmount({
          crId,
        }),
      );
    }
  }, [crId, dispatch, isCurrentMilestoneCompleted]);

  useEffect(() => {
    if (crId) {
      dispatch(
        activeLoansActions.getLoanRenewalDetails({
          crId,
          requestId: Number(requestId),
        }),
      );
      dispatch(
        transactionsActions.getTransactionsList({
          crId,
        }),
      );
    }
  }, []);

  const onClickEditLoanDetailsHandler = () => {
    if (crId) {
      dispatch(resetLoanDetailsState(crId));
      dispatch(resetTransactionList());
      dispatch(
        activeLoansActions.getLoanRenewalDetails({
          crId,
          requestId: Number(requestId),
        }),
      );
      dispatch(
        transactionsActions.getTransactionsList({
          crId,
        }),
      );
    }
  };

  //reset loan details states on component unmount
  useEffect(() => {
    if (!isLoanRenewalFlow) {
      return () => {
        dispatch(resetTransactionList());
      };
    }
  }, [isLoanRenewalFlow]);

  useEffect(() => {
    return () => {
      dispatch(resetLoanDetailsState(crId));
    };
  }, []);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {!isLoanRenewalFlow && (
          <Typography fontWeight="bold" variant="h5">
            Loan Details
          </Typography>
        )}

        {isLoanRenewalDetailsLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <>
            <LoanDetailsBox>
              <Typography fontWeight="bold" variant="h6">
                Old loan principal dues
              </Typography>
              {oldLoanPrincipalDues?.map((item) => (
                <Box
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={0.5}
                  gap={2}
                  key={item.label}
                >
                  <Typography color="text.secondary" variant="h6">
                    {item.label}
                  </Typography>
                  <Typography variant="h6">{item.value}{"  "}<InfoRounded
                    onMouseEnter={(e) => {
                      tippy(e.currentTarget, {
                        content: "FC + PF + Subvention Recovery",
                        placement: "top",
                      });
                    }}
                  /></Typography>
                </Box>
              ))}
            </LoanDetailsBox>

            <Box mt={2}>
              <Typography mb={1} fontWeight="bold" variant="h6">
                Old loan Interest and other dues
              </Typography>

              <IGTable
                getRowId={(row) => row.id}
                tableCols={tableCols}
                tableData={tableData}
                hideFooter
                hideFooterPagination={true}
                sx={{
                  "& .loans-details-header": {
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: "#000000",
                  },
                  "& .loans-details-header .MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "& .loans-details-header .MuiSvgIcon-root": {
                    fill: "white",
                  },
                }}
                columnVisibilityModel={{
                  adjustedAmount: Boolean(isInterestAdjustmentAllowedWithTopUp),
                }}
              />
              <Box my={2}>
                {Object.entries(errors).map(([key, value], i) => {
                  if (
                    key !== "glRemainingAmountToCollect" &&
                    key !== "plRemainingAmountToCollect" &&
                    key !== "glWaivedOffAmount" &&
                    key !== "plWaivedOffAmount"
                  ) {
                    return (
                      <FormHelperText key={i}>
                        <Typography color="error.main">- {value}</Typography>
                      </FormHelperText>
                    );
                  }
                  return null;
                })}
                {customErrors.map((error) => {
                  return (
                    <FormHelperText key={error} error>
                      <Typography color="error.main"> - {error}</Typography>
                    </FormHelperText>
                  );
                })}
              </Box>
            </Box>
            {!approvalScreenshotFileUrl ? (
              <Box my={2}>
                <IGFileUpload
                  text="Attach Approval Screenshots*"
                  value={approvalScreenshots}
                  onChange={(fileList) => {
                    setApprovalScreenshots(fileList);
                  }}
                  draggable={false}
                  inputProps={{
                    accept: "image/png, image/jpeg, image/jpg, application/pdf",
                  }}
                />
              </Box>
            ) : (
              <Box my={2}>
                <Typography variant="h6" fontWeight="bold">
                  Approval Screenshots
                </Typography>
                <IGButton
                  variant="text"
                  onClick={() => {
                    window.open(approvalScreenshotFileUrl, "_blank");
                  }}
                  startIcon={<Visibility />}
                >
                  View
                </IGButton>
              </Box>
            )}

            <LoanDetailsBox>
              {newLoanDetails?.map((item) => (
                <>
                  <Box my={1} display="flex" gap={1}>
                    <Box>
                      <Typography fontWeight="bold" variant="h6">
                        New loan details
                      </Typography>
                    </Box>
                    <Box>
                      {!topUpAmountDetails && (
                        <IconButton
                          sx={{
                            height: "1px",
                          }}
                          onClick={() => {
                            dispatch(
                              toggleModals({
                                type: "newLoanAmountUpdationModal",
                                value: true,
                              }),
                            ),
                              setSelectedLoanData({
                                loanAmount: Number(item?.loanAmount),
                                loanType: String(item?.loanType),
                                pfAmount: Number(item?.pfAmount),
                              });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      key={item.loanType}
                      flexDirection="column"
                    >
                      <Typography color="text.secondary" variant="h6">
                        {item.label}
                      </Typography>
                      <Typography sx={{width: "100px"}} variant="h6">
                        {item.value}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} key={item.pfLabel} flexDirection="column">
                      <Typography color="text.secondary" variant="h6">
                        {item.pfLabel}
                      </Typography>
                      <Typography sx={{width: "100px"}} variant="h6">
                        {numberWithCurrencyAndComma(item.pfAmount)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      key={item.maxPfLabel}
                      flexDirection="column"
                    >
                      <Typography color="text.secondary" variant="h6">
                        {item.maxPfLabel}
                      </Typography>
                      <Typography sx={{width: "100px"}} variant="h6">
                        {numberWithCurrencyAndComma(item.maxPfAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </LoanDetailsBox>

            <Box mt={1}>
              {isAwaitingPaymentFromCustomer && (
                <Box display="flex" textAlign="center" alignItems="center">
                  <Typography fontWeight={600} variant="h6" color="red">
                    Awaiting Payment From the customer
                  </Typography>
                  <HourglassTopIcon sx={{color: "red"}} />
                </Box>
              )}
            </Box>

            {!topUpAmountDetails &&
              !isTopUpAmountDetailsLoading &&
              transactionsList.length === 0 && (
                <>
                  <Box mt={2} textAlign="center">
                    <IGButton
                      loading={isCalculateTopUpLoading}
                      type="submit"
                      color="golden"
                      disabled={!isValid || customErrors.length > 0}
                    >
                      Submit
                    </IGButton>
                  </Box>
                </>
              )}
          </>
        )}

        {isTopUpAmountDetailsLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <>
            {/* collect from customer section */}
            {topUpAmountDetails && (
              <LoanDetailsBox>
                <Typography my={1} fontWeight="bold" variant="h6">
                  Collect From Customer
                </Typography>

                {collectFromCustomerDetails?.map((item) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="300px"
                    gap={2}
                    key={item.label}
                  >
                    <Typography color="text.secondary" variant="h6">
                      {item.label}
                    </Typography>
                    <Typography variant="h6">{item.value}</Typography>
                  </Box>
                ))}
              </LoanDetailsBox>
            )}

            {/* top up amount section */}
            {topUpAmountDetails && (
              <>
                {topUpAmountDetails.showCustomerTopUpAmount && (
                  <TopUpAmount
                    topUpAmountDetails={topUpAmountDetails}
                    totalAdjustedPfAmountWithTopUp={
                      totalAdjustedPfAmountWithTopUp
                    }
                  />
                )}
                {transactionsList.length === 0 && (
                  <Box mt={2} textAlign="center">
                    <IGButton
                      onClick={() => onClickEditLoanDetailsHandler()}
                      color="inherit"
                    >
                      Edit Loan Details
                    </IGButton>
                  </Box>
                )}
              </>
            )}

            {!isLoanRenewalFlow &&
              !isLoanRenewalDetailsLoading &&
              !isTopUpAmountDetailsLoading &&
              !topUpAmountDetails &&
              transactionsList.length === 0 && (
                <Box mt={2} textAlign="center">
                  <IGButton
                    color="inherit"
                    onClick={() => setIsReturnToValuationModalOpen(true)}
                  >
                    RETURN TO GOLD VALUATION
                  </IGButton>
                </Box>
              )}
          </>
        )}

        {isLoanAmountUpdationModalOpen && selectedLoanData && (
          <NewLoanAmountUpdationModal
            selectedLoanData={selectedLoanData}
            isLoanAmountUpdationModalOpen={isLoanAmountUpdationModalOpen}
            crId={crId}
          />
        )}

        {isReturnToValuationModalOpen && transactionsList.length === 0 && (
          <ReturnToGoldValuationPopUp
            getMileStoneOfCr={getMileStoneOfCr}
            isReturnToValuationModalOpen={isReturnToValuationModalOpen}
            setIsReturnToValuationModalOpen={setIsReturnToValuationModalOpen}
            crId={selectedPayload.id}
          />
        )}

        {topUpAmountDetails &&
          transactionsList.length === 0 &&
          !shareOfferWithCustomerSuccess && (
            <Box mt={2} textAlign="center">
              <IGButton
                loading={isShareOfferWithCustomerLoading}
                color="golden"
                onClick={() =>
                  dispatch(
                    transactionsActions.shareOfferWithCustomer({
                      crId,
                    }),
                  )
                }
              >
                Share offer with customer and proceed
              </IGButton>
            </Box>
          )}

        <Box mt={2} textAlign="center">
          {topUpAmountDetails &&
            transactionsList.length === 0 &&
            totalAmountToCollectFromCustomer === 0 &&
            totalMarginMoney === 0 &&
            shareOfferWithCustomerSuccess &&
            topUpAmountDetails.showCustomerTopUpAmount && (
              <IGButton
                sx={{
                  width: "250px",
                }}
                loading={
                  isProceedAfterAdjustmentLoading || isCreateTransactionLoading
                }
                onClick={() =>
                  dispatch(
                    transactionsActions.proceedAfterAdjustment({
                      crId: crId,
                    }),
                  )
                }
                color="golden"
                fullWidth={false}
              >
                CREATE TRANSACTION
              </IGButton>
            )}{" "}
        </Box>
        {transactionsList.length !== 0 && (
          <TransactionDetails transactionsList={transactionsList} />
        )}
        {!isCurrentMilestoneCompleted &&
          !isLoanRenewalFlow &&
          transactionsList.length !== 0 && (
            <IGButton
              sx={{marginTop: 1}}
              loading={isUpdateMileStoneLoading}
              onClick={onUpdateMilestoneHandler}
              fullWidth
              color="golden"
            >
              Proceed To Next Milestone
            </IGButton>
          )}
      </form>
    </Box>
  );
};

export default LoanDetails;
