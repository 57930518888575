import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {Box, FormControlLabel, Checkbox} from "@mui/material";
import {setDisclaimerMileStone} from "app/store/activeLoans/activeLoans.reducer";
import {IGButton} from "app/components";
import {activeLoansSelectors} from "app/store/activeLoans";

const BtScoreCardDisclaimer = () => {
  const dispatch = useAppDispatch();
  const selectedMileStone = useAppSelector(activeLoansSelectors.getSelectedBtScoreCardMilestone);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
  return (
    <Box
      bgcolor="#FFFFFF"
      width="100%"
      boxShadow="0px -4px 10px -2px #00000040;"
      sx={{
        position: "sticky",
        bottom: "0",
        zIndex: 2,
      }}
    >
      <Box
        p={3}
        textAlign="center"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isDisclaimerChecked}
              onChange={() => setIsDisclaimerChecked(!isDisclaimerChecked)}
            />
          }
          label="I agree I have checked all the details and they are correct"
        />
        <IGButton
          disabled={!isDisclaimerChecked}
          onClick={() =>
            dispatch(
              setDisclaimerMileStone({
                milestoneName: selectedMileStone,
                isChecked: true,
              }),
            )
          }
        >
          Confirm
        </IGButton>
      </Box>
    </Box>
  );
};

export default BtScoreCardDisclaimer;
