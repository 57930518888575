import {IGDialogActions, IGDialogState} from "../igDialog";
import {ReactNode} from "react";
import store from "../store";

interface ConfirmPayload extends Omit<IGDialogState, "open" | "type" | "showActions"> {
  content: ReactNode
}

interface NoActionPayload {
  icon?: ReactNode
  message: string
  timer?: boolean
}

export const useIGDialog = () => {
  return {
    showConfirmDialog: (config: ConfirmPayload) => {
      store.dispatch(IGDialogActions.open({
        type: "confirm",
        showActions: true,
        ...config,
      }));
    },
    showSuccessDialog: ({message, ...rest}: NoActionPayload) => {
      store.dispatch(IGDialogActions.open({
        type: "success",
        content: message,
        showActions: false,
        ...rest,
      }));
    },
    showErrorDialog: ({message, ...rest}: NoActionPayload) => {
      store.dispatch(IGDialogActions.open({
        type: "error",
        content: message,
        showActions: false,
        ...rest,
      }));
    },
    showLoadingDialog: ({message, ...rest}: NoActionPayload) => {
      store.dispatch(IGDialogActions.open({
        type: "loading",
        content: message,
        showActions: false,
        ...rest,
      }));
    },
  };
};