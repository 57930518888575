import React, {FC, useState} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {IGSelect, IGDialog} from "app/components";
import {SEND_BACK_TO_SCHEDULING_REASON} from "app/enums/gold_loan/activeLoans";

interface SendBackToSchedulingDialogProps {
  open: boolean
  crId: number
}

const SendBackToSchedulingDialog: FC<SendBackToSchedulingDialogProps> = ({
  open,
  crId,
}) => {

  const dispatch = useAppDispatch();
  const {sendBackToScheduling: sendBackToSchedulingLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  const [sendBackToSchedulingReason, setSendBackToSchedulingReason] = useState(
    Object.keys(SEND_BACK_TO_SCHEDULING_REASON)[0],
  );

  const onClose = () => {
    dispatch(toggleModals({
      type: "sendBackToSchedulingDialog",
      value: false,
    }));
  };

  const sendCRBackToScheduling = () => {
    dispatch(activeLoansActions.sendBackToScheduling({
      crId,
      reason: sendBackToSchedulingReason,
    }));
  };

  return (
    <IGDialog
      open={open}
      title="Send CR to Scheduling"
      onClose={onClose}
      onConfirm={sendCRBackToScheduling}
      onCancel={onClose}
      confirmProps={{
        text: "Send",
        loading: sendBackToSchedulingLoading,
      }}
      cancelProps={{
        text: "Cancel",
      }}
    >
      <span>Are you sure you want to send this CR back to Scheduling?</span>
      <IGSelect<string>
        name="sendBackReason"
        value={sendBackToSchedulingReason}
        onChange={(e) => setSendBackToSchedulingReason(e.target.value)}
        options={Object.entries(SEND_BACK_TO_SCHEDULING_REASON)
          .map(([value, text]) => ({text, value}))
        }
      />
    </IGDialog>
  );
};

export default SendBackToSchedulingDialog;