import React, {useEffect} from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {IGDialog} from "app/components";
import {useAppDispatch} from "app/store/hooks";
import {useAppSelector} from "app/store/hooks";
import {OrnamentsSelectors} from "app/store/ornaments";
import OrnamentsTable from "./OrnamentsTable";
import {resetOrnamentsList} from "app/store/ornaments/ornaments.reducer";
import OrnamentDetails from "./OrnamentDetails/OrnamentDetails";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {activeLoansSelectors} from "app/store/activeLoans";

interface CMOrnamentsProps {
  isVisible: boolean;
  crJobId: number;
  isOrnamentsDialogOpen: boolean;
  getMileStoneOfCr: () => void;
  onViewClickHandler: () => void;
  crId: number;
  selectedPayload: any;
}

const CMOrnaments = ({
  isOrnamentsDialogOpen,
  onViewClickHandler,
  crId,
  getMileStoneOfCr,
  crJobId,
  isVisible,
  selectedPayload,
}: CMOrnamentsProps) => {
  const dispatch = useAppDispatch();
  const ornamentsNamesList = useAppSelector(
    OrnamentsSelectors.getOrnamentsNamesList,
  );
  const ornamentsList = useAppSelector(OrnamentsSelectors.getOrnamentsList);

  const ornamentsFinenessConfigList = useAppSelector(
    OrnamentsSelectors.getOrnamentsFinenessConfigList,
  );

  const isOrnamentCheckerRequired = useAppSelector(
    activeLoansSelectors.getOrnamentCheckerFlag,
  );
  const {
    checkerGoldValue,
    lmGoldValue,
    maxGoldAmountWithoutPL,
    totalCheckerGrossWeight,
    totalCheckerNetWeight,
    totalCheckerImpurityWeight,
  } = useAppSelector(OrnamentsSelectors.getGoldValueStats);

  const goldValueStats = [
    {
      label: "Maker Gold Value",
      value: numberWithCurrencyAndComma(lmGoldValue),
      xs: 2,
    },
    {
      label: "Max Gold Loan Amount",
      value: numberWithCurrencyAndComma(maxGoldAmountWithoutPL),
      xs: 2,
    },
    {
      label: "Total Gross Weight",
      value: `${totalCheckerGrossWeight.toFixed(2)}g`,
      xs: 2,
    },
    {
      label: "Total Net Weight",
      value: `${totalCheckerNetWeight.toFixed(2)}g`,
      xs: 2,
    },
    {
      label: "Total Impurity",
      value: `${totalCheckerImpurityWeight.toFixed(2)}g`,
      xs: 2,
    },
  ];

  if (isOrnamentCheckerRequired) {
    goldValueStats.splice(1, 0, {
      label: "Checker Gold Value",
      value: numberWithCurrencyAndComma(checkerGoldValue),
      xs: 2,
    });
  }

  useEffect(() => {
    return () => {
      dispatch(resetOrnamentsList());
    };
  }, [dispatch]);

  return (
    <IGDialog
      open={isOrnamentsDialogOpen}
      onClose={() => {
        dispatch(resetOrnamentsList());
        onViewClickHandler();
      }}
      // title="Ornament Details"
      fullScreen
      hideFooter
    >
      <Stack gap={1}>
        <Grid
          container
          p={1}
          px={2}
          borderRadius={1}
          bgcolor="#f6f6f6"
          rowGap={2}
          mb={1}
          sx={{
            position: "sticky",
            top: "-16px",
            zIndex: 1,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          {goldValueStats.map((item) => (
            <Grid item xs={item.xs || 12 / goldValueStats.length}>
              <Box display="flex" gap="0.5rem" flexDirection="column">
                <Typography fontSize="1rem" color="gray">
                  {item.label}
                </Typography>
                <Typography fontSize="1.2rem">{item.value}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <OrnamentDetails
          crId={crId}
          crJobId={crJobId}
          isVisible={isVisible}
          getMileStoneOfCr={getMileStoneOfCr}
          onViewClickHandler={onViewClickHandler}
        />

        {isOrnamentCheckerRequired &&
          ornamentsList &&
          ornamentsFinenessConfigList &&
          ornamentsNamesList.every((item) => item.isCheckedByCm) && (
            <Box>
              <OrnamentsTable
                isVisible={isVisible}
                crId={crId}
                crJobId={crJobId}
                selectedPayload={selectedPayload}
                OrnamentsList={ornamentsList}
                ornamentsFinenessConfigList={ornamentsFinenessConfigList}
              />
            </Box>
          )}
      </Stack>
    </IGDialog>
  );
};

export default CMOrnaments;
