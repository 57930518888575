import styled from "@emotion/styled";

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
`;

export default BodyContainer;
