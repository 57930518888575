import styled from "@emotion/styled";
interface Props {
  fontSize?: string,
}
const SubText =  styled.div<Props>`
  font-size: ${({fontSize}) => fontSize ? fontSize : "0.9rem"};
  line-height: 1.33;

  letter-spacing: 0.4px;

  color: rgba(18, 18, 18, 0.5);
  align-item: center;
  margin-bottom: 1rem;
  display: flex;
`;

export default SubText;
