import React, {useCallback, useEffect, useRef} from "react";
import IGDrawer from "../IGDrawer";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {dialerActions, dialerSelectors} from "app/store/dialer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import IGTextField from "../IGTextField";
import IGTable, {IGTableColsInterface} from "../IGTable";
import {DialerAgentSchedule} from "app/store/dialer/dialer.reducer";
import IGButton from "../Button/IGButton";
import {useNavigate} from "react-router-dom";
import {
  setFilters,
  setSearchFilters,
} from "app/store/serviceDesk/services/reducer";
import serviceDeskApi from "app/infra/services/api/serviceDesk/serviceDesk.api";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {authSelectors} from "app/store/auth";
import customerProfileApi from "app/infra/services/api/customerProfile/customerProfile.api";
import {getValidTime} from "_metronic/utils/moment";

const ScheduleDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {showError} = useSnackBar();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const agentSchedule = useAppSelector(dialerSelectors.getAgentSchedule);
  const {getAgentSchedule: getAgentScheduleLoading} = useAppSelector(
    dialerSelectors.getLoading,
  );
  const [customerName, setCustomerName] = React.useState<string>("");
  const [mobile, setMobile] = React.useState<string>("");
  const [pageNo, setPageNo] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [loading, setLoading] = React.useState<boolean>(false);
  const agentScheduleInterval = useRef<NodeJS.Timeout | null>(null);
  const filteredAgentSchedule = agentSchedule.filter((schedule) => {
    if (!schedule.customerName) {
      return (
        schedule.mobile.includes(mobile) &&
        !schedule.isGroupCall
      );
    }
    return (
      schedule.customerName
        ?.toLowerCase()
        .includes(customerName?.toLowerCase()) &&
      schedule.mobile.includes(mobile) &&
      !schedule.isGroupCall
    );
  });
  const filteredGroupSchedule = agentSchedule.filter((schedule) => {
    if (!schedule.customerName) {
      return (
        schedule.mobile.includes(mobile) &&
        schedule.isGroupCall
      );
    }

    return (
      schedule.customerName
        ?.toLowerCase()
        .includes(customerName?.toLowerCase()) &&
      schedule.mobile.includes(mobile) &&
      schedule.isGroupCall
    );
  });

  const handleOnClose = useCallback(() => {
    if (agentScheduleInterval.current) {
      clearInterval(agentScheduleInterval.current);
      agentScheduleInterval.current = null;
    }
    onClose();
  }, [onClose]);

  const handleRequestNavigation = useCallback(
    (requestData) => {
      const {requestType, id, serviceRequestCategory} = requestData || {};

      const navigationURl =
        requestType === "COMPLAINT"
          ? `/gold-loan/service-desk/complaints?searchType=requestId&id=${id}&requestId=${id}&serviceRequestCategory=${serviceRequestCategory}`
          : `/gold-loan/service-desk/loan-services?searchType=requestId&serviceType=${serviceRequestCategory}&id=${id}&requestId=${id}`;

      if (requestType === "SERVICE") {
        dispatch(
          setFilters({
            searchType: "requestId",
            id,
            serviceType: serviceRequestCategory,
          }),
        );
      } else if (requestType === "COMPLAINT") {
        dispatch(
          setSearchFilters({
            searchType: "requestId",
            id,
            serviceRequestCategory,
          }),
        );
      }
      navigate(navigationURl);
      handleOnClose();
    },
    [dispatch, navigate, handleOnClose],
  );

  const getServiceRequestDetails = useCallback(
    async (serviceRequestId) => {
      setLoading(true);
      const serviceRequestDetailResponse = await serviceDeskApi.getServiceRequestById(
        {
          requestId: serviceRequestId,
        },
      );
      if (!serviceRequestDetailResponse.error) {
        const {serviceRequest} = serviceRequestDetailResponse;
        handleRequestNavigation(serviceRequest);
      } else {
        showError(
          serviceRequestDetailResponse.error?.message ||
            "Error fetching service request details",
        );
      }
      setLoading(false);
    },
    [handleRequestNavigation, showError],
  );

  const handleProfileNavigation = useCallback(
    async ({loanId, phoneNo}: { loanId?: number; phoneNo?: string }) => {
      setLoading(true);
      if (loanId) {
        const profileResponse = await customerProfileApi.getCustomerProfileList(
          {
            loanId,
            pageNo: 1,
            pageSize: 10,
          },
        );

        if (!profileResponse.error) {
          const profileList = profileResponse?.response || [];

          if (profileList.length) {
            const {userId, loanId} = profileList[0];
            navigate(`/gold-loan/customer-profiles/${userId}/${loanId}`);
          } else {
            showError("No profile found for the given loan ID");
          }
        } else {
          showError("Error while fetching profile details");
        }
      } else {
        navigate(
          `/gold-loan/customer-profiles?pageNo=1&pageSize=10&searchText=${phoneNo}&currentSearchType=MOBILE`,
        );
      }
      setLoading(false);
      handleOnClose();
    },
    [navigate, handleOnClose, showError],
  );

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(1);
  };

  const handlePageChange = (pageNo: number) => {
    setPageNo(pageNo);
  };

  const tableCols: IGTableColsInterface<DialerAgentSchedule> = [
    {
      field: "callScheduledAt",
      headerName: "Event Created At",
      flex: 0.8,
      renderCell: ({value}) => {
        return getValidTime(value).format("DD MMM YYYY hh:mm A");
      },
    },
    {field: "mobile", headerName: "Mobile", flex: 0.6},
    {field: "customerName", headerName: "Customer Name", flex: 0.8},
    {
      field: "attemptCount",
      headerName: "Attempts",
      flex: 0.5,
      renderCell: ({value}) => value || 0,
    },
    {
      field: "eventDescription",
      headerName: "Event Description",
      flex: 1.5,
      renderCell: ({value}) => <Box>{value || "-"}</Box>,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.8,
      renderCell: ({row}) => {
        const {serviceRequestId, parentLoanId, mobile} = row;
        return (
          <IGButton
            key={serviceRequestId || parentLoanId || mobile}
            onClick={() => {
              if (parentLoanId) {
                handleProfileNavigation({loanId: parentLoanId});
              } else if (serviceRequestId) {
                getServiceRequestDetails(serviceRequestId);
              } else {
                handleProfileNavigation({phoneNo: mobile});
              }
            }}
            loading={loading}
          >
            {serviceRequestId ? "View Request" : "View Profile"}
          </IGButton>
        );
      },
    },
  ];

  useEffect(() => {
    if (isAuthenticated && open) {
      if (agentScheduleInterval.current) {
        clearInterval(agentScheduleInterval.current);
      }
      agentScheduleInterval.current = setInterval(() => {
        dispatch(
          dialerActions.getAgentSchedule({
            pageNo: pageNo,
            pageSize: pageSize,
          }),
        );
      }, 60000);
    } else {
      if (agentScheduleInterval.current) {
        clearInterval(agentScheduleInterval.current);
        agentScheduleInterval.current = null;
      }
    }

    return () => {
      if (agentScheduleInterval.current) {
        clearInterval(agentScheduleInterval.current);
        agentScheduleInterval.current = null;
      }
    };
  }, [isAuthenticated, dispatch, pageNo, pageSize, open]);

  useEffect(() => {
    if (isAuthenticated && open) {
      dispatch(
        dialerActions.getAgentSchedule({
          pageNo: pageNo,
          pageSize: pageSize,
        }),
      );
    }
  }, [dispatch, pageNo, pageSize, isAuthenticated, open]);

  return (
    <IGDrawer
      open={open}
      onClose={handleOnClose}
      title="Today's Schedule"
      PaperProps={{
        style: {
          minWidth: "70%",
        },
      }}
    >
      <Stack p={2} gap={3}>
        <Box>
          <Typography variant="h6" fontWeight="bold" color="text.secondary">
            Filters
          </Typography>
          <Stack flexDirection="row" gap={2} mt={1}>
            <IGTextField
              name="customerName"
              label="Customer Name"
              placeholder="Search by Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
            <IGTextField
              name="mobile"
              label="Mobile"
              placeholder="Search by Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Stack>
        </Box>
        <Accordion
          defaultExpanded
          elevation={1}
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography variant="h6" fontWeight="bold" color="text.secondary">
              Agent Schedule
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <IGTable
              tableData={filteredAgentSchedule}
              getRowId={(row) => row.eventId}
              tableCols={tableCols}
              loading={getAgentScheduleLoading}
              pagination={true}
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
              page={pageNo}
              pageSize={pageSize}
              getRowHeight={() => "auto"}
              sx={{
                "& .MuiDataGrid-cell": {
                  padding: "4px 8px",
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          elevation={1}
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
            <Typography variant="h6" fontWeight="bold" color="text.secondary">
              Group Schedule
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <IGTable
              tableData={filteredGroupSchedule}
              getRowId={(row) => row.eventId}
              tableCols={tableCols}
              loading={getAgentScheduleLoading}
              pagination={true}
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
              page={pageNo}
              pageSize={pageSize}
              getRowHeight={() => "auto"}
              sx={{
                "& .MuiDataGrid-cell": {
                  padding: "4px 8px",
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </IGDrawer>
  );
};

export default ScheduleDrawer;
