import React, {useCallback, useEffect, useRef, useState} from "react";

import isBeneficiaryAdded from "../../../../../../../api/business/isBeneficiaryAdded";
import GreenTickIcon from "../../../../../../assets/GreenTick";
import FailedIcon from "../../../../../../assets/Exclaimation";
import PendingIcon from "../../../../../../assets/Processing";
import styles from "../styles";
import clsx from "clsx";

const BankDetails = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: null,
  });
  const counter = useRef(0);

  const getStatus = useCallback(() => {
    (async () => {
      try {
        const response = await isBeneficiaryAdded({
          accessToken: localStorage.getItem("authToken"),
          bankDetailType: "LOAN_BANK_DETAIL",
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          setStates({
            loading: false,
            payload: response.payload,
            error: false,
          });
        } else {
          setStates({
            loading: false,
            error: true,
            payload: null,
          });
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: null,
        });
      }
    })();
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getStatus();
    }
  }, [getStatus]);

  useEffect(() => {
    const timer = setInterval(() => {
      getStatus();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getStatus, otherRequestTimer]);

  const GetIcon = () => {
    if (states.payload !== null) {
      if (states.payload.status === "CREATED") {
        return <PendingIcon />;
      }

      if (states.payload.status === "FAILED") {
        return <FailedIcon />;
      }

      return <GreenTickIcon />;
    }
    return <></>;
  };

  const getText = () => {
    if (states.payload !== null) {
      if (states.payload.status === "CREATED") {
        return "Pending";
      }

      if (states.payload.status === "FAILED") {
        return "Issue Reported";
      }

      return "Done";
    }
    return "";
  };

  return (
    <div className={classes.textContainer}>
      <div className={classes.text}>STATUS OF ADDING PAYEE</div>
      <div className={clsx(classes.subText, classes.icon)}>
        {<GetIcon />}
        {getText()}
      </div>
    </div>
  );
};

export default BankDetails;
