import {RootState} from "../store";

const AddressSelectors = {
  getAddressList: (state: RootState) => state.address.addressList,
  getLoading: (state: RootState) => state.address.loading,
  getGoogleCoordinatesByLeadId: (state: RootState, leadId: number) =>
    state.address.googleCoordinates[leadId],
  getBlacklistedGoogleCoordinates: (state: RootState) => state.address.blacklistedGoogleCoordinates,
  selectGoogleCoordinatesByLeadId: (state: RootState, leadId: number) =>
    state.address.googleCoordinates[leadId] || [],
};

export default AddressSelectors;
