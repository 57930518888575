import React, {FC} from "react";
import {Box, BoxProps} from "@mui/material";

interface IGSectionProps {
  gap?: BoxProps["gap"]
  padding?: BoxProps["padding"],
  px?: BoxProps["px"],
  py?: BoxProps["py"],
  containerStyle?: BoxProps["style"],
}

const IGSectionContent: FC<IGSectionProps> = ({
  children,
  gap = 1.5,
  padding= 1.5,
  px,
  py,
  containerStyle={},
}) => {

  return (
    <Box
      display="flex" gap={gap} flexGrow={1}
      flexDirection="column" p={padding} px={px} py={py}
      style={containerStyle}
    >
      {children}
    </Box>
  );
};

export default IGSectionContent;