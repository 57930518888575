import React, {useState, useRef, useCallback, useEffect} from "react";
import styles from "../styles";
import getGoldLoanDetail from "api/business/getGoldLoanDetail";

import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";

import {CircularProgress} from "@mui/material";

import updateStatus from "api/business/updateStatus";
import getNextJob from "api/business/getNextJob";
import {useSnackBar} from "app/store/hooks";
import ButtonWithLoader from "app/components/Button/ButtonWithLoader";

const PersonalLoanDetail = ({
  selectedPayload,
  isBackMovement,
  isSameCreditManager,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const classes = styles();
  const snackbar = useSnackBar();

  const counter = useRef(0);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      pl: null,
      gl: null,
    },
    errorMessage: "",
  });
  const [button, setButton] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (counter.current === 0) {
          counter.current = 1;
          const [response, glResponse] = await Promise.all([
            getGoldLoanDetail({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "PERSONAL_LOAN",
            }),
            getGoldLoanDetail({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "GOLD_LOAN",
            }),
          ]);

          if (response.statusCode === 200 && glResponse.statusCode === 200) {
            setStates({
              loading: false,
              error: false,
              payload: {
                pl: response.payload,
                gl: glResponse.payload,
              },
              errorMessage: "",
            });
          } else {
            setStates({
              loading: false,
              error: true,
              payload: {
                gl: null,
                pl: null,
              },
              errorMessage:
                response?.error?.toString() || glResponse?.error?.toString(),
            });
          }
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: {
            gl: null,
            pl: null,
          },
          errorMessage: "Something went wrong",
        });
      }
    })();
  }, [selectedPayload.id]);

  const onClickHandler = useCallback(async () => {
    setButtonLoading(true);
    try {
      const getNextResponse = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (getNextResponse.statusCode === 200) {
        const updateStatusResponse = await updateStatus({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          crJobId: getNextResponse.payload.id,
          remark: "",
          status: "CM_PROCESS_LOAN_PL_APPLICATION_APPROVED",
        });

        if (updateStatusResponse.statusCode === 200) {
          snackbar.showSuccess("Success");

          setButton(false);
          getMileStoneOfCr();
        } else {
          snackbar.showError(updateStatusResponse.error);
        }
      } else {
        snackbar.showError(getNextResponse.error);
      }
    } catch (error) {
      snackbar.showError("Something went wrong");
    }
    setButtonLoading(false);
  }, [getMileStoneOfCr, selectedPayload.id]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const {pl} = states.payload;
  const {gl} = states.payload;

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>INFORMATION</div>

        {/* <div
          className={classes.subText}
        >{`Gold Loan Amount: ${numberWithCurrencyAndComma(
          gl.totalLoanAmount,
        )}`}</div> */}

        {/* <div
          className={classes.subText}
        >{`Requested  Loan Amount: ${numberWithCurrencyAndComma(
          gl.loanRequestedAmount,
        )}`}</div> */}

        {/* <div
          className={classes.subText}
        >{`Loan Amount Requested: ${gl.loanRequestedAmount}`}</div> */}

        <div
          className={classes.subText}
        >{`Personal Loan Amount: ${numberWithCurrencyAndComma(
          pl.totalLoanAmount,
        )}`}</div>

        <div className={classes.subText}>{`Loan Tenure: ${pl.loanTenure}`}</div>

        {/* <div className={classes.subText}>{`PL LTV: ${pl.ltv}%`}</div> */}

        {/* <div
          className={classes.subText}
        >{`Re Payment Frequency: ${pl.rePaymentFrequency}`}</div> */}

        <div
          className={classes.subText}
        >{`Loan Vendor Name: ${pl.loanVendorName}`}</div>

        {/* <div
          className={classes.subText}
        >{`Nucleus Scheme Name: ${pl.goldLoanSchemeName ||
          pl.nucleusSchemeCode}`}</div> */}
      </div>

      {!isBackMovement && button && isSameCreditManager && (
        <div className={classes.buttonContainer}>
          <ButtonWithLoader
            onClick={onClickHandler}
            className={classes.ApproveButton}
            loading={buttonLoading}
          >
            APPROVE
          </ButtonWithLoader>
        </div>
      )}
    </>
  );
};

export default PersonalLoanDetail;
