import React, {useState, useCallback, useRef, useEffect} from "react";
import styles from "../styles";
import isBeneficiaryAddedApi from "../../../../../../../api/business/isBeneficiaryAdded";
import {CircularProgress} from "@mui/material";

import GreenTickIcon from "../../../../../../assets/GreenTick";
import FailedIcon from "../../../../../../assets/Exclaimation";
import PendingIcon from "../../../../../../assets/Processing";
import clsx from "clsx";

const Status = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();

  const counter = useRef(0);

  const [states, setStates] = useState({
    loading: false,
    error: false,
    payload: null,
  });

  const getStatusOfBank = useCallback(async () => {
    (async () => {
      try {
        setStates({
          loading: false,
          error: false,
          payload: null,
        });

        const response = await isBeneficiaryAddedApi({
          accessToken: window.localStorage.getItem("authToken"),
          bankDetailType: "TAKEOVER_BANK_DETAIL",
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          setStates({
            error: false,
            loading: false,
            payload: response.payload,
          });
        } else {
          setStates({
            error: true,
            loading: false,
            payload: null,
          });
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: null,
        });
      }
    })();
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getStatusOfBank();
    }
  }, [getStatusOfBank]);

  useEffect(() => {
    const timer = setInterval(() => {
      getStatusOfBank();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getStatusOfBank, otherRequestTimer]);

  if (states.error) {
    return <div>Something went Wrong</div>;
  }

  if (states.loading || states.payload === null) {
    return <CircularProgress />;
  }

  const GetIcon = () => {
    if (states.payload !== null) {
      if (states.payload.status === "CREATED") {
        return <PendingIcon />;
      }

      if (states.payload.status === "FAILED") {
        return <FailedIcon />;
      }

      return <GreenTickIcon />;
    }
    return <></>;
  };

  const getText = () => {
    if (states.payload !== null) {
      if (states.payload.status === "CREATED") {
        return "Pending";
      }

      if (states.payload.status === "FAILED") {
        return "Issue Reported";
      }

      return "Done";
    }
    return "";
  };

  // console.log(states);
  return (
    <div className={classes.textContainer}>
      <div className={classes.text}>STATUS OF ADDING PAYEE</div>

      <div className={clsx(classes.subText, classes.icon)}>
        {<GetIcon />}
        {getText()}
      </div>
    </div>
  );
};

export default Status;
