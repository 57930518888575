import {createAction} from "@reduxjs/toolkit";

import {
  LoanRenewalRebookResponse,
  GetLoanRenewalRebookMilestonesPayload,
  ServiceRequestIdPayload,
  UpdateLoanRenewalRebookMilestonePayload,
  DeleteLoanDocumentPayload,
  CreateLoanDocumentPayload,
  UpdateLenderDetailsPayload,
  DocumentListByMilestonePayload,
  GetLoanRenewalOfferPayload,
  CrIdPayload,
  GetDigitalDocumentLink,
  LoanRenewalFeatureFlagPayload,
  CrJobIdPayload,
  PostLenderGatingPayload,
  GetEncryptedDocumentDetail,
} from "./loanRenewalRebook.types";

const loanRenewalRebookActionTypes = {
  GetLoanRenewRebookMilestones: "[Loan Renewal Rebook] Get Loan Renewal Rebook Milestones ",
  GetLoanRenewRebookMilestonesSuccess:
    "[Loan Renewal Rebook] Get Loan Renewal Rebook Milestones Success",
  GetLoanRenewRebookMilestonesFailure:
    "[Loan Renewal Rebook] Get Loan Renewal Rebook Milestones Failure",
  CheckLoanRenewalAllowed: "[Loan Renewal Rebook] Check Loan Renewal Allowed ",
  CheckLoanRenewalAllowedSuccess: "[Loan Renewal Rebook] Check Loan Renewal Allowed Success",
  CheckLoanRenewalAllowedFailure: "[Loan Renewal Rebook] Check Loan Renewal Allowed Failure",
  GetLoanRenewRebookJobDetails: "[Loan Renewal Rebook] Get Loan Renewal Rebook Job Details ",
  GetLoanRenewRebookJobDetailsSuccess:
    "[Loan Renewal Rebook] Get Loan Renewal Rebook Job Details Success",
  GetLoanRenewRebookJobDetailsFailure:
    "[Loan Renewal Rebook] Get Loan Renewal Rebook Job Details Failure",
  CreateLoanRenewRebookJob: "[Loan Renewal Rebook] Create Loan Renewal Rebook Job",
  CreateLoanRenewRebookJobSuccess: "[Loan Renewal Rebook] Create Loan Renewal Rebook Job Success",
  CreateLoanRenewRebookJobFailure: "[Loan Renewal Rebook] Create Loan Renewal Rebook Job Failure",
  GetLoanRenewRebookConfig: "[Loan Renewal Rebook] Get Loan Renewal Rebook Config ",
  GetLoanRenewRebookConfigSuccess: "[Loan Renewal Rebook] Get Loan Renewal Rebook Config Success",
  GetLoanRenewRebookConfigFailure: "[Loan Renewal Rebook] Get Loan Renewal Rebook Config Failure",
  UpdateLoanRenewRebookMilestone: "[Loan Renewal Rebook] Update Loan Renewal Rebook Milestone ",
  UpdateLoanRenewRebookMilestoneSuccess:
    "[Loan Renewal Rebook] Update Loan Renewal Rebook Milestone Success",
  UpdateLoanRenewRebookMilestoneFailure:
    "[Loan Renewal Rebook] Update Loan Renewal Rebook Milestone Failure",
  DeleteLoanDocument: "[Loan Renewal Rebook] Delete Loan Document ",
  DeleteLoanDocumentSuccess: "[Loan Renewal Rebook] Delete Loan Document Success",
  DeleteLoanDocumentFailure: "[Loan Renewal Rebook] Delete Loan Document Failure",
  CreateLoanDocument: "[Loan Renewal Rebook] Create Loan Document ",
  CreateLoanDocumentSuccess: "[Loan Renewal Rebook] Create Loan Document Success",
  CreateLoanDocumentFailure: "[Loan Renewal Rebook] Create Loan Document Failure",
  GetDocumentListByMilestone: "[Loan Renewal Rebook] Get Document List By Milestone ",
  GetDocumentListByMilestoneSuccess: "[Loan Renewal Rebook] Get Document List By Milestone Success",
  GetDocumentListByMilestoneFailure: "[Loan Renewal Rebook] Get Document List By Milestone Failure",
  GetLoanRenewalOffer: "[Loan Renewal Rebook] Get Loan Renewal Offer ",
  GetLoanRenewalOfferSuccess: "[Loan Renewal Rebook] Get Loan Renewal Offer Success",
  GetLoanRenewalOfferFailure: "[Loan Renewal Rebook] Get Loan Renewal Offer Failure",
  PostLenderGating: "[Loan Renewal Rebook] Post Lender Gating ",
  PostLenderGatingSuccess: "[Loan Renewal Rebook] Post Lender Gating Success",
  PostLenderGatingFailure: "[Loan Renewal Rebook] Post Lender Gating Failure",
  GetLenderGatingStatus: "[Loan Renewal Rebook] Get Lender Gating Status ",
  GetLenderGatingStatusSuccess: "[Loan Renewal Rebook] Get Lender Gating Status Success",
  GetLenderGatingStatusFailure: "[Loan Renewal Rebook] Get Lender Gating Status Failure",
  GetLoanRenewalFeatureFlag: "[Loan Renewal Rebook] Get Loan Renewal Feature Flag ",
  GetLoanRenewalFeatureFlagSuccess: "[Loan Renewal Rebook] Get Loan Renewal Feature Flag Success",
  GetLoanRenewalFeatureFlagFailure: "[Loan Renewal Rebook] Get Loan Renewal Feature Flag Failure",
  CloseRenewalLoan: "[Loan Renewal Rebook] Close Renewal Loan ",
  CloseRenewalLoanSuccess: "[Loan Renewal Rebook]  Close Renewal Loan Success",
  CloseRenewalLoanFailure: "[Loan Renewal Rebook]  Close Renewal Loan Failure",
  GetLoanConfigDetails: "[Loan Renewal Rebook] Get Loan Config Details ",
  GetLoanConfigDetailsSuccess: "[Loan Renewal Rebook]  Get Loan Config Details Success",
  GetLoanConfigDetailsFailure: "[Loan Renewal Rebook]  Get Loan Config Details Failure",
  GetRenewRebookLoanDetails: "[Loan Renewal Rebook] Get Renew Rebook Loan Details",
  GetRenewRebookLoanDetailsSuccess: "[Loan Renewal Rebook]  Get Renew Rebook Loan Details Success",
  GetRenewRebookLoanDetailsFailure: "[Loan Renewal Rebook]  Get Renew Rebook Loan  Details Failure",
  AssignLoanRenewalJob: "[Loan Renewal Rebook] Assign Loan Renewal Job",
  AssignLoanRenewalJobSuccess: "[Loan Renewal Rebook] Assign Loan Renewal Job Success",
  AssignLoanRenewalJobFailure: "[Loan Renewal Rebook] Assign Loan Renewal Job Failure",
  UnAssignLoanRenewalJob: "[Loan Renewal Rebook] UnAssign Loan Renewal Job",
  UnAssignLoanRenewalJobSuccess: "[Loan Renewal Rebook] UnAssign Loan Renewal Job Success",
  UnAssignLoanRenewalJobFailure: "[Loan Renewal Rebook] UnAssign Loan Renewal Job Failure",
  GetEncryptedDocumentDetail: "[Loan Renewal Rebook] Get Encrypted Document Detail",
  GetEncryptedDocumentDetailSuccess: "[Loan Renewal Rebook]  Get Encrypted Document Detail Success",
  GetEncryptedDocumentDetailFailure: "[Loan Renewal Rebook]  Get Encrypted Document Detail Failure",

  GetLoanCreationStatus: "[Loan Renewal Rebook] Get Loan Creation Status",
  GetLoanCreationStatusSuccess: "[Loan Renewal Rebook]  GetnLoan Creation Status Success",
  GetLoanCreationStatusFailure: "[Loan Renewal Rebook]  Get Loan Creation Status Failure",
  GetAutoLoanRenewalStatus: "[Loan Renewal Rebook]  Get Auto Loan Renewal Status",
  CreateAutoLoanRenewalJob: "[Loan Renewal Rebook]  Create Auto Loan Renewal Job",
};

const loanRenewRebookActions = {
  getloanRenewRebookMilestones: createAction<GetLoanRenewalRebookMilestonesPayload>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookMilestones,
  ),
  getloanRenewRebookMilestonesSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookMilestonesSuccess,
  ),
  getloanRenewRebookMilestonesFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookMilestonesFailure,
  ),
  checkLoanRenewalAllowed: createAction<ServiceRequestIdPayload>(
    loanRenewalRebookActionTypes.CheckLoanRenewalAllowed,
  ),
  checkLoanRenewalAllowedSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CheckLoanRenewalAllowedSuccess,
  ),
  checkLoanRenewalAllowedFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CheckLoanRenewalAllowedFailure,
  ),
  getloanRenewRebookJobDetails: createAction<ServiceRequestIdPayload>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookJobDetails,
  ),
  getloanRenewRebookJobDetailsSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookJobDetailsSuccess,
  ),
  getloanRenewRebookJobDetailsFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookJobDetailsFailure,
  ),
  createRenewRebookJob: createAction<ServiceRequestIdPayload>(
    loanRenewalRebookActionTypes.CreateLoanRenewRebookJob,
  ),
  createRenewRebookJobSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CreateLoanRenewRebookJobSuccess,
  ),
  createRenewRebookJobFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CreateLoanRenewRebookJobFailure,
  ),
  getloanRenewRebookConfig: createAction(loanRenewalRebookActionTypes.GetLoanRenewRebookConfig),
  getloanRenewRebookConfigSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookConfigSuccess,
  ),
  getloanRenewRebookConfigFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewRebookConfigFailure,
  ),
  updateLoanRenewRebookMilestone: createAction<UpdateLoanRenewalRebookMilestonePayload>(
    loanRenewalRebookActionTypes.UpdateLoanRenewRebookMilestone,
  ),
  updateLoanRenewRebookMilestoneSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.UpdateLoanRenewRebookMilestoneSuccess,
  ),
  updateLoanRenewRebookMilestoneFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.UpdateLoanRenewRebookMilestoneFailure,
  ),
  deleteLoanDocument: createAction<DeleteLoanDocumentPayload>(
    loanRenewalRebookActionTypes.DeleteLoanDocument,
  ),
  deleteLoanDocumentSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.DeleteLoanDocumentSuccess,
  ),
  deleteLoanDocumentFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.DeleteLoanDocumentFailure,
  ),
  createLoanDocument: createAction<CreateLoanDocumentPayload>(
    loanRenewalRebookActionTypes.CreateLoanDocument,
  ),
  createLoanDocumentSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CreateLoanDocumentSuccess,
  ),
  createLoanDocumentFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CreateLoanDocumentFailure,
  ),
  getDocumentListByMilestone: createAction<DocumentListByMilestonePayload>(
    loanRenewalRebookActionTypes.GetDocumentListByMilestone,
  ),
  getDocumentListByMilestoneSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetDocumentListByMilestoneSuccess,
  ),
  getDocumentListByMilestoneFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetDocumentListByMilestoneFailure,
  ),
  getLoanRenewalOffer: createAction<GetLoanRenewalOfferPayload>(
    loanRenewalRebookActionTypes.GetLoanRenewalOffer,
  ),
  getLoanRenewalOfferSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewalOfferSuccess,
  ),
  getLoanRenewalOfferFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewalOfferFailure,
  ),
  postLenderGating: createAction<PostLenderGatingPayload>(
    loanRenewalRebookActionTypes.PostLenderGating,
  ),
  postLenderGatingSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.PostLenderGatingSuccess,
  ),
  postLenderGatingFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.PostLenderGatingFailure,
  ),
  getLenderGatingStatus: createAction<CrIdPayload>(
    loanRenewalRebookActionTypes.GetLenderGatingStatus,
  ),
  getLenderGatingStatusSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLenderGatingStatusSuccess,
  ),
  getLenderGatingStatusFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLenderGatingStatusFailure,
  ),
  getLoanRenewalFlagStatus: createAction<LoanRenewalFeatureFlagPayload>(
    loanRenewalRebookActionTypes.GetLoanRenewalFeatureFlag,
  ),
  getLoanRenewalFlagStatusSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewalFeatureFlagSuccess,
  ),
  getLoanRenewalFlagStatusFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanRenewalFeatureFlagFailure,
  ),

  closeRenewalLoan: createAction<CrIdPayload>(loanRenewalRebookActionTypes.CloseRenewalLoan),
  closeRenewalLoanSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CloseRenewalLoanSuccess,
  ),
  closeRenewalLoanFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.CloseRenewalLoanFailure,
  ),

  getLoanConfigDetails: createAction<CrIdPayload>(
    loanRenewalRebookActionTypes.GetLoanConfigDetails,
  ),
  getLoanConfigDetailsSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanConfigDetailsSuccess,
  ),
  getLoanConfigDetailsFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanConfigDetailsFailure,
  ),
  getRenewRebookLoanDetails: createAction<CrIdPayload>(
    loanRenewalRebookActionTypes.GetRenewRebookLoanDetails,
  ),
  getRenewRebookLoanDetailsSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetRenewRebookLoanDetailsSuccess,
  ),
  getRenewRebookLoanDetailsFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetRenewRebookLoanDetailsFailure,
  ),
  assignLoanRenewalJob: createAction<CrJobIdPayload>(
    loanRenewalRebookActionTypes.AssignLoanRenewalJob,
  ),
  assignLoanRenewalJobSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.AssignLoanRenewalJobSuccess,
  ),
  assignLoanRenewalJobFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.AssignLoanRenewalJobFailure,
  ),
  unAssignLoanRenewalJob: createAction<CrJobIdPayload>(
    loanRenewalRebookActionTypes.UnAssignLoanRenewalJob,
  ),
  unAssignLoanRenewalJobSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.UnAssignLoanRenewalJobSuccess,
  ),
  unAssignLoanRenewalJobFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.UnAssignLoanRenewalJobFailure,
  ),

  getEncryptedDocumentDetail: createAction<GetEncryptedDocumentDetail>(
    loanRenewalRebookActionTypes.GetEncryptedDocumentDetail,
  ),
  getEncryptedDocumentDetailSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetEncryptedDocumentDetailSuccess,
  ),
  getEncryptedDocumentDetailFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetEncryptedDocumentDetailFailure,
  ),

  getLoanCreationStatus: createAction<CrIdPayload>(
    loanRenewalRebookActionTypes.GetLoanCreationStatus,
  ),
  getLoanCreationStatusSuccess: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanCreationStatusSuccess,
  ),
  getLoanCreationStatusFailure: createAction<LoanRenewalRebookResponse>(
    loanRenewalRebookActionTypes.GetLoanCreationStatusFailure,
  ),
  getAutoLoanRenewalStatus: createAction<CrIdPayload>(
    loanRenewalRebookActionTypes.GetAutoLoanRenewalStatus,
  ),
  createAutoLoanRenewalJob: createAction<ServiceRequestIdPayload>(
    loanRenewalRebookActionTypes.CreateAutoLoanRenewalJob,
  ),
};

export default loanRenewRebookActions;

export {loanRenewalRebookActionTypes};
