import {goldApiService} from "app/infra/plugins/axios";
import {
  GiftCardConfigResources,
  FetchGiftCardConfigProps,
  UpdateGiftCardConfigProps,
} from "./types";

const GIFTCARD_CONFIG_ENDPOINTS: GiftCardConfigResources = {
  FETCH_GIFTCARD_CONFIG: {
    URL: "user/gift-card/payment-config",
    METHOD: "GET",
  },
  UPDATE_GIFTCARD_CONFIG: {
    URL: "user/gift-card/:id/payment-config",
    METHOD: "PUT",
  },
};

const giftCardConfigApi = {
  fetchGiftCardConfig: async (payload: FetchGiftCardConfigProps) => {
    const {response, error} = await goldApiService({
      resource: GIFTCARD_CONFIG_ENDPOINTS.FETCH_GIFTCARD_CONFIG,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error: error,
        isFetching: false,
        giftCardConfig: [],
      };
    }

    return {
      error: null,
      isFetching: false,
      giftCardConfig: response,
    };
  },
  updateGiftCardConfig: async (
    id: number,
    payload: UpdateGiftCardConfigProps,
  ) => {
    const {response, error} = await goldApiService({
      resource: GIFTCARD_CONFIG_ENDPOINTS.UPDATE_GIFTCARD_CONFIG,
      options: {
        pathVars: {
          id,
        },
        data: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error: error,
        isFetching: false,
        giftCardConfig: [],
      };
    }

    return {
      error: null,
      isFetching: false,
      giftCardConfig: response,
    };
  },
};

export default giftCardConfigApi;
export {GIFTCARD_CONFIG_ENDPOINTS};
