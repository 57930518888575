import styled from "@emotion/styled";

const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 500;

  line-height: 1.71;

  letter-spacing: 0.1px;
`;

export default Title;
