import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuIconProps, MENU_ICONS} from ".";

const LockerReleaseRequest: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.LockerReleaseRequest} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

export default LockerReleaseRequest;