import React, {useEffect, useMemo, useState} from "react";

import Created from "./Created";
import CustomerLeftHome from "./CustomerLeftHome";
import TakeOverBankDetails from "./TakeOverBankDetails";
import CheckRTGS from "./CheckRTGS";
import TakeOverPayment from "./TakeoverPayment";
import GoldRelease from "./GoldRelease";
import CustomerLocationCheck from "./CustomerLocationCheck";
import BankDetails from "./BankDetails";
import GoldValuation from "./GoldValuation";
import GoldLoanDetails from "./GoldLoanDetails";
import PersonalLoan from "./PersonalLoan";
import PersonalLoanDetails from "./PersonalLoanDetails";
import ReviewDetails from "./ReviewDetails";
import Summary from "./Summary";
import FinalPayment from "./FinalPayment";
import PersonalDetails from "./PersonalDetails";
import AddressDetail from "./AddressDetails";
import UpdateKYC from "./UpdateKYC";
import NewUploadKYC from "./NewUploadKYC";
import CommunicationDetails from "./CommunicationDetails";
import EmploymentDetails from "./EmploymentDetails";
import ReachedVault from "./ReachedVault";
import LockerSummary from "./LockerSummary";
import Closed from "./Closed";
import LenderAccountDetails from "./LenderAccountDetails";
import Leegality from "./Leegality";
import LoanDetails from "./LoanDetails";

import Snackbar from "../../../../../components/Snackbar";
import UploadKYC from "./UploadKYC";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";
import {
  transactionsActions,
  transactionsSelectors,
} from "app/store/transactions";

const transactionFlowTimer = 15000;
let timer;

const MileStone = ({
  selectedPayload,
  currentMileStone,
  isSameCreditManager,
  isBackMovement,
  setCurrentMileStone,
  setCurrentIndex,
  mileStones,
  currentIndex,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const user = useAppSelector(authSelectors.getUser);
  const dispatch = useAppDispatch();
  const [snackbar, setSnackbar] = useState({
    snackbar: false,
    message: "",
  });

  const isShowTransactionFlowEnable = useAppSelector(
    transactionsSelectors.showTransactionFlow,
  );

  // Ameyo Dialer Helpers
  const canAccessAmeyoToolbar = useMemo(
    () =>
      user &&
      user?.userRoleList?.some((role) =>
        [RolesEnum.SCHEDULER, RolesEnum.ADMIN].includes(role),
      ),
    [user],
  );

  const handleDialer = (e, mobile) => {
    if (canAccessAmeyoToolbar) {
      try {
        e.preventDefault();
        e.stopPropagation();
        window.ameyo.integration.doDial(mobile);
      } catch (e) {
        setSnackbar({
          snackbar: true,
          message: "Unable to dial the number",
        });
      }
    } else {
      setSnackbar({
        snackbar: true,
        message: "You do not have access to this feature",
      });
    }
  };

  useEffect(() => {
    if (selectedPayload.creditManagerId === null) {
      setSnackbar({
        message:
          "This request needs to be assigned to you to approve/ reject request",
        snackbar: true,
      });
    }
  }, [selectedPayload.creditManagerId]);

  const updateIndex = (i) => {
    const updatedMileStones = mileStones.payload[i];
    setCurrentIndex(i + 1);
    setCurrentMileStone({
      data: updatedMileStones,
      index: i,
    });
  };

  useEffect(() => {
    if (selectedPayload.id) {
      dispatch(
        transactionsActions.showTransactionFlow({
          crId: selectedPayload.id,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (selectedPayload.id) {
      timer = setInterval(() => {
        dispatch(
          transactionsActions.showTransactionFlow({
            crId: selectedPayload.id,
          }),
        );
      }, [transactionFlowTimer]);
      return () => {
        clearInterval(timer);
      };
    }
  }, [dispatch, selectedPayload.id]);

  const lists = useMemo(
    () => [
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_START",
          "CR_GOLD_LOAN_FRESH_START",
          "CR_GOLD_LOCKER_PICKUP_START",
        ],
        component: (
          <Created
            selectedPayload={selectedPayload}
            handleDialer={handleDialer}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_CHECK_CUSTOMER_HOME",
          "CR_GOLD_LOAN_FRESH_CHECK_CUSTOMER_HOME",
          "CR_GOLD_LOCKER_PICKUP_CHECK_CUSTOMER_HOME",
        ],
        component: <CustomerLeftHome selectedPayload={selectedPayload} />,
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_LOAN_KYC_DETAIL",
          "CR_GOLD_LOAN_FRESH_UPDATE_KYC",
        ],

        component: (
          <NewUploadKYC
            selectedPayload={selectedPayload}
            getMileStoneOfCr={getMileStoneOfCr}
            isSameCreditManager={isSameCreditManager}
            isBackMovement={isBackMovement}
            currentMileStone={currentMileStone}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_TAKEOVER_BANK_DETAIL"],
        component: (
          <TakeOverBankDetails
            otherRequestTimer={otherRequestTimer}
            selectedPayload={selectedPayload}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_CHECK_RTGS"],
        component: (
          <CheckRTGS
            isSameCreditManager={isSameCreditManager}
            isBackMovement={isBackMovement}
            selectedPayload={selectedPayload}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_TAKEOVER_PAYMENT"],
        component: (
          <TakeOverPayment
            otherRequestTimer={otherRequestTimer}
            selectedPayload={selectedPayload}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_GOLD_RELEASE"],
        component: (
          <GoldRelease
            otherRequestTimer={otherRequestTimer}
            selectedPayload={selectedPayload}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_CUSTOMER_LOCATION_CHECK"],
        component: <CustomerLocationCheck selectedPayload={selectedPayload} />,
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_LOAN_BANK_DETAIL",
          "CR_GOLD_LOAN_FRESH_BANK_DETAIL",
        ],
        component: (
          <BankDetails
            otherRequestTimer={otherRequestTimer}
            selectedPayload={selectedPayload}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_LM_GOLD_VALUATION",
          "CR_GOLD_LOAN_FRESH_GOLD_VALUATION",
          "CR_GOLD_LOCKER_PICKUP_GOLD_VALUATION",
        ],
        component: (
          <GoldValuation
            isSameCreditManager={isSameCreditManager}
            selectedPayload={selectedPayload}
            otherRequestTimer={otherRequestTimer}
            isBackMovement={isBackMovement}
            currentMileStone={currentMileStone}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_GOLD_LOAN",
          "CR_GOLD_LOAN_FRESH_GOLD_LOAN",
        ],
        component: (
          <GoldLoanDetails
            isSameCreditManager={isSameCreditManager}
            selectedPayload={selectedPayload}
            isBackMovement={isBackMovement}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_PERSONAL_LOAN",
          "CR_GOLD_LOAN_FRESH_PERSONAL_LOAN",
        ],
        component: (
          <PersonalLoan
            isSameCreditManager={isSameCreditManager}
            selectedPayload={selectedPayload}
            isBackMovement={isBackMovement}
            updateIndex={updateIndex}
            currentIndex={currentIndex}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_PERSONAL_LOAN_DETAIL",
          "CR_GOLD_LOAN_FRESH_PERSONAL_LOAN_DETAIL",
        ],
        component: (
          <PersonalLoanDetails
            isBackMovement={isBackMovement}
            selectedPayload={selectedPayload}
            isSameCreditManager={isSameCreditManager}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_REVIEW_DETAIL",
          "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL",
        ],
        component: (
          <>
            {isShowTransactionFlowEnable ? (
              <LoanDetails
                updateIndex={updateIndex}
                selectedPayload={selectedPayload}
                currentIndex={currentIndex}
                isBackMovement={isBackMovement}
                isSameCreditManager={isSameCreditManager}
                currentMileStone={currentMileStone}
                otherRequestTimer={otherRequestTimer}
                getMileStoneOfCr={getMileStoneOfCr}
                crId={selectedPayload.id}
                mileStones={mileStones}
                isLoanRenewalFlow={false}
              />
            ) : (
              <ReviewDetails
                updateIndex={updateIndex}
                selectedPayload={selectedPayload}
                currentIndex={currentIndex}
                isBackMovement={isBackMovement}
                isSameCreditManager={isSameCreditManager}
                currentMileStone={currentMileStone}
                otherRequestTimer={otherRequestTimer}
                getMileStoneOfCr={getMileStoneOfCr}
                crId={selectedPayload.id}
              />
            )}
          </>
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_FRESH_LENDER_DOCUMENT_REVIEW",
          "CR_GOLD_LOAN_BT_LENDER_DOCUMENT_REVIEW",
        ],
        component: (
          <Leegality
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            isBackMovement={isBackMovement}
            isSameCreditManager={isSameCreditManager}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_SUMMARY",
          "CR_GOLD_LOAN_FRESH_SUMMARY",
        ],
        component: (
          <Summary
            isSameCreditManager={isSameCreditManager}
            selectedPayload={selectedPayload}
            isBackMovement={isBackMovement}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_FINAL_PAYMENT",
          "CR_GOLD_LOAN_FRESH_FINAL_PAYMENT",
        ],
        component: (
          <FinalPayment
            isSameCreditManager={isSameCreditManager}
            isBackMovement={isBackMovement}
            selectedPayload={selectedPayload}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_PICKUP_PERSONAL_DETAIL"],
        component: (
          <PersonalDetails
            selectedPayload={selectedPayload}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_PICKUP_ADDRESS_DETAIL"],
        component: (
          <AddressDetail
            selectedPayload={selectedPayload}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_PICKUP_KYC_DETAIL"],
        component: (
          <UpdateKYC
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_PICKUP_COMMUNICATION_DETAIL"],
        component: (
          <CommunicationDetails
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_PICKUP_EMPLOYMENT_DETAIL"],
        component: (
          <EmploymentDetails
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOCKER_PICKUP_REACHED_VAULT",
          "CR_GOLD_LOCKER_PICKUP_SUBMIT_TO_VAULT",
        ],
        component: (
          <ReachedVault
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOCKER_SUMMARY"],
        component: (
          <LockerSummary
            selectedPayload={selectedPayload}
            currentMileStone={currentMileStone}
            otherRequestTimer={otherRequestTimer}
          />
        ),
      },
      {
        milestonesEnum: [
          "CR_GOLD_LOAN_BT_LENDER_DETAIL",
          "CR_GOLD_LOAN_FRESH_LENDER_DETAIL",
        ],
        component: (
          <LenderAccountDetails
            selectedPayload={selectedPayload}
            isSameCreditManager={isSameCreditManager}
            currentMileStone={currentMileStone}
            getMileStoneOfCr={getMileStoneOfCr}
            isBackMovement={isBackMovement}
          />
        ),
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_CLOSED", "CR_GOLD_LOAN_FRESH_CLOSED"],
        component: <Closed />,
      },
      {
        milestonesEnum: ["CR_GOLD_LOAN_BT_UPLOAD_KYC"],
        component: (
          <UploadKYC
            currentMileStone={currentMileStone}
            selectedPayload={selectedPayload}
            isSameCreditManager={isSameCreditManager}
            isBackMovement={isBackMovement}
            otherRequestTimer={otherRequestTimer}
            getMileStoneOfCr={getMileStoneOfCr}
          />
        ),
      },
    ],
    [
      currentMileStone,
      isBackMovement,
      isSameCreditManager,
      selectedPayload,
      otherRequestTimer,
      getMileStoneOfCr,
      currentIndex,
      updateIndex,
    ],
  );

  const currentSelectedEnum = useMemo(() => currentMileStone.data?.milestone, [
    currentMileStone,
  ]);

  const SelectedComponent = useMemo(
    () =>
      lists.filter((list) =>
        list.milestonesEnum.find((e) => e === currentSelectedEnum),
      ),
    [lists, currentSelectedEnum],
  );

  return (
    <>
      <Snackbar setSnackbar={setSnackbar} snackbar={snackbar} />

      {Boolean(SelectedComponent) &&
        SelectedComponent.length !== 0 &&
        SelectedComponent[0].component}
    </>
  );
};

export default MileStone;
