import {
  AlgoApiInterface,
  AlgoResources,
  DeleteAgentTripPayload,
  GetAgentListPayload,
  GetAgentRouteListPayload,
  GetAgentScheduleDetailsPayload,
  GetAlgoRunStatusPayload,
  GetCustomerDetailsPayload,
  GetRunsPayload,
  GetCustomerListPayload,
  SaveAgentRoutePayload,
  StartAlgoPayload,
  GetAlgoRunReportPayload,
  ConfirmAlgoRunPayload,
  GetTripDetailsPayload,
  CreateAgentTripPayload,
  GetAvailableAgentListPayload,
} from "./types";
import {goldApiService} from "app/infra/plugins/axios";

const ALGO_ENDPOINTS: AlgoResources = {
  GET_RUNS: {
    URL: "/scheduling-algo/city-list", // ? date
    METHOD: "GET",
  },
  GET_AGENT_LIST: {
    URL: "/scheduling-algo/:algoRunId/agent-list",
    METHOD: "GET",
  },
  GET_CUSTOMER_LIST: {
    URL: "/scheduling-algo/:algoRunId/customer-list",
    METHOD: "GET",
  },
  GET_AGENT_ROUTE_LIST: {
    URL: "/scheduling-algo/route/:routeId/trip-list",
    METHOD: "GET",
  },
  GET_CUSTOMER_DETAIL: {
    URL: "/scheduling-algo/:schedulingRequestId/customer-detail",
    METHOD: "GET",
  },
  DELETE_AGENT_TRIP: {
    URL: "/scheduling-algo/:schedulingRequestId/trip/:tripId",
    METHOD: "DELETE",
  },
  CREATE_AGENT_TRIP: {
    URL: "/scheduling-algo/:schedulingRequestId/trip",
    METHOD: "POST",
  },
  SAVE_AGENT_ROUTE: {
    URL: "/scheduling-algo/route/:routeId",
    METHOD: "POST",
  },
  START_ALGO: {
    URL: "/scheduling-algo/run", // ? cityId, date
    METHOD: "POST",
  },
  GET_ALGO_RUN_STATUS: {
    URL: "/scheduling-algo/request/:algoRunId",
    METHOD: "GET",
  },
  GET_AGENT_SCHEDULING_DETAILS: {
    URL: "/scheduling-algo/:schedulingRequestId/agent-schedule",
    METHOD: "GET",
  },
  GET_ALGO_RUN_REPORT: {
    URL: "/scheduling-algo/:schedulingRequestId/report",
    METHOD: "GET",
  },
  CONFIRM_ALGO_RUN: {
    URL: "/scheduling-algo/:schedulingRequestId/confirm",
    METHOD: "POST",
  },
  GET_TRIP_DETAILS: {
    URL:
      "/scheduling-algo/:schedulingRequestId/schedulingNode/:startSchedulingNodeId/trip-detail",
    METHOD: "GET",
  },
  GET_AVAILABLE_AGENT_LIST: {
    URL: "/scheduling-algo/:schedulingRequestId/available-agent-list", // ? cityId, startTime, endTime
    METHOD: "GET",
  },
};

const AlgoApi: AlgoApiInterface = {
  getRuns: async (payload: GetRunsPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_RUNS,
      options: {
        queryParams: {
          date: payload.date,
        },
      },
    });

    if (error) {
      return {error, data: null};
    }
    return {
      data: response,
      error: null,
    };
  },
  getAgentList: async (payload: GetAgentListPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_AGENT_LIST,
      options: {
        pathVars: {
          algoRunId: payload.algoRunId,
        },
      },
    });
    if (error) {
      return {error, agentList: []};
    }
      return {error: null, agentList: response};

  },
  getCustomerList: async (payload: GetCustomerListPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_CUSTOMER_LIST,
      options: {
        pathVars: {
          algoRunId: payload.algoRunId,
        },
      },
    });
    if (error) {
      return {error, userList: []};
    }
      return {error: null, userList: response};

  },
  getAgentRouteList: async (payload: GetAgentRouteListPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_AGENT_ROUTE_LIST,
      options: {
        pathVars: {
          routeId: payload.routeId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {response, error: null};

  },
  deleteAgentTrip: async (payload: DeleteAgentTripPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.DELETE_AGENT_TRIP,
      options: {
        pathVars: {
          tripId: payload.tripId,
          schedulingRequestId: payload.schedulingRequestId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  saveAgentRoute: async (payload: SaveAgentRoutePayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.SAVE_AGENT_ROUTE,
      options: {
        pathVars: {
          routeId: payload.routeId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  startAlgo: async (payload: StartAlgoPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.START_ALGO,
      options: {
        queryParams: {
          cityId: payload.cityId,
          date: payload.date,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  getAlgoRunStatus: async (payload: GetAlgoRunStatusPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_ALGO_RUN_STATUS,
      options: {
        pathVars: {
          algoRunId: payload.algoRunId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  getAgentSchedulingDetails: async (
    payload: GetAgentScheduleDetailsPayload,
  ) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_AGENT_SCHEDULING_DETAILS,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
        queryParams: {
          agentIdList: payload.agentIdList,
        },
      },
    });
    if (error) {
      return {error, schedulingDetails: null};
    }
      return {schedulingDetails: response, error: null};

  },
  getCustomerDetails: async (payload: GetCustomerDetailsPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_CUSTOMER_DETAIL,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
        queryParams: {
          schedulingNodeId: payload.schedulingNodeId,
        },
      },
    });
    if (error) {
      return {error, customerDetails: null};
    }
      return {error: null, customerDetails: response};

  },
  getAlgoRunReport: async (payload: GetAlgoRunReportPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_ALGO_RUN_REPORT,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
      },
    });
    if (error) {
      return {error, algoReport: null};
    }
      return {error: null, algoReport: response};

  },
  confirmAlgoRun: async (payload: ConfirmAlgoRunPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.CONFIRM_ALGO_RUN,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  getTripDetails: async (payload: GetTripDetailsPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_TRIP_DETAILS,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
          startSchedulingNodeId: payload.startSchedulingNodeId,
        },
      },
    });
    if (error) {
      return {error, tripDetails: null};
    }
      return {error: null, tripDetails: response};

  },
  createAgentTrip: async (payload: CreateAgentTripPayload) => {
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.CREATE_AGENT_TRIP,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
        data: {
          agentId: payload.agentId,
          agentType: payload.agentType,
          endNodeId: payload.endNodeId || null,
          endTime: payload.endTime,
          startNodeId: payload.startNodeId,
          startTime: payload.startTime,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
      return {error: null, response};

  },
  getAvailableAgentList: async (payload: GetAvailableAgentListPayload) => {
    Object.keys(payload).forEach((key) => {
      if (!payload[key as keyof GetAvailableAgentListPayload]) {
        const err =  new Error("Invalid payload for fetching agent list");
        return {error: err, response: null};
      }
    });
    const {response, error} = await goldApiService({
      resource: ALGO_ENDPOINTS.GET_AVAILABLE_AGENT_LIST,
      options: {
        pathVars: {
          schedulingRequestId: payload.schedulingRequestId,
        },
        queryParams: {
          cityId: payload.cityId,
          startTime: payload.startTime,
          endTime: payload.endTime,
          roleName: payload.roleName,
        },
      },
    });
    if (error) {
      return {error, agentList: []};
    }
      return {error, agentList: response};

  },
};

export default AlgoApi;

export {ALGO_ENDPOINTS};
