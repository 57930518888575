import {goldApiService} from "app/infra/plugins/axios";
import {AutoDialerApi, AutoDialerResources} from "./autoDialer.types";

const AutoDialerEndpoints: AutoDialerResources = {
  GET_EVENT_DETAILS: {
    URL: "call-masking/scheduled-outbound-event/:requestId",
    METHOD: "GET",
  },
  GET_AGENT_SCHEDULE: {
    URL: "/call-masking/cst-agent-call-schedule",
    METHOD: "GET",
  },
};

const autoDialerApi: AutoDialerApi = {
  getEventDetails: async (payload) => {
    const {response, error} = await goldApiService({
      resource: AutoDialerEndpoints.GET_EVENT_DETAILS,
      options: {
        pathVars: {
          requestId: payload.requestId,
        },
      },
    });
    return {response, error};
  },
  getAgentSchedule: async (payload) => {
    const {response, error} = await goldApiService({
      resource: AutoDialerEndpoints.GET_AGENT_SCHEDULE,
      options: {
        queryParams: {
          pageNo: payload.pageNo,
          pageSize: payload.pageSize,
        },
      },
    });
    return {response, error};
  },
};

export default autoDialerApi;
export {AutoDialerEndpoints};
