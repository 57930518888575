import {authActions, authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";
import BugsnagNotify from "app/infra/services/bugsnag";

const NotFound = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const {logout: logoutLoading} = useAppSelector(authSelectors.getLoading);
  const user = useAppSelector(authSelectors.getUser);

  useEffect(() => {
    if (!isAuthenticated && !logoutLoading) {
      navigate("/auth", {replace: true});
    }
    if (isAuthenticated) {
      BugsnagNotify({
        error: {
          name: `404 - ${window.location.pathname}`,
          message: "NotFound",
        },
        user,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, logoutLoading]);

  return (
    <>
      <div className="error__title">404</div>
      <div className="error__subtitle">NotFound</div>
      <div className="error__description">
        It looks like you don't have acess to this feature
      </div>
      <button
        disabled={!isAuthenticated}
        className={`error__button error__button--active ${clsx({
          "kt-spinner kt-spinner--right kt-spinner--lg kt-spinner--light": logoutLoading,
        })}`}
        onClick={() => {
          dispatch(authActions.logout());
        }}
        style={{paddingRight: logoutLoading ? "2.5rem" : "2rem"}}
      >
        Logout
      </button>
      <button className="error__button">
        <Link to="/">Go Back</Link>
      </button>
    </>
  );
};

export default NotFound;
