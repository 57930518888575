import React, {useEffect, useState, useCallback, useRef} from "react";
import styles from "../styles";
import {CircularProgress} from "@mui/material";
import getGoldLoanDetail from "api/business/getGoldLoanDetail";
import getPledgeCard from "api/business/getPledgeCard";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

const PersonalLoanNotTaken = ({
  selectedPayload,
  finalLtv,
  isBackMovement,
  isPledgeCardVisible,
}) => {
  const classes = styles();
  const accessToken = useAppSelector(authSelectors.getAuthToken);

  const [state, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      goldLoan: null,
      pledgeCard: null,
    },
  });

  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    const needPledgeCard = isBackMovement;
    try {
      const [goldLoanResponse, getPledgeCardResponse] = await Promise.all([
        getGoldLoanDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "GOLD_LOAN",
        }),
        needPledgeCard
          ? getPledgeCard({
              accessToken,
              crId: selectedPayload.id,
              loanType: "GOLD_LOAN",
            })
          : null,
      ]);

      if (
        goldLoanResponse.statusCode === 200 &&
        (needPledgeCard ? getPledgeCardResponse.statusCode === 200 : true)
      ) {
        setStates({
          loading: false,
          error: false,
          payload: {
            goldLoan: goldLoanResponse.payload,
            pledgeCard: getPledgeCardResponse?.payload,
          },
        });
      } else {
        setStates({
          loading: false,
          error: true,
          errorMessage: goldLoanResponse.error || getPledgeCardResponse.error,
          payload: {
            goldLoan: null,
            pledgeCard: null,
          },
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: {
          pledgeCard: null,
          goldLoan: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails, selectedPayload.id]);

  if (state.loading) {
    return <CircularProgress />;
  }
  if (state.error) {
    return <div>{state.errorMessage.toString()}</div>;
  }

  const {goldLoan} = state.payload;

  return (
    <div>
      {/* <div className={classes.textContainer}>
        <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

        <div className={classes.subText}>
          {`${numberWithCurrencyAndComma(
            goldLoan.totalLoanAmount,
          )} / ${finalLtv}%`}
        </div>
      </div> */}

      <div className={classes.textContainer}>
        <div className={classes.text}>GOLD LOAN INFORMATION</div>

        <div className={classes.subText}>
          {"Loan Account Number: "}

          <span
            style={{
              color: "#ce9f29",
            }}
            className={classes.subText}
          >
            {goldLoan?.loanAccountNumber}
          </span>
        </div>

        <div
          className={classes.subText}
        >{`Amount Disbursed: ${numberWithCurrencyAndComma(
          goldLoan.totalLoanAmount,
        )}`}</div>

        <div className={classes.subText}>{`Lender: ${goldLoan?.loanVendorName ||
          "N/A"}`}</div>

        <div
          className={classes.subText}
        >{`Amount Transferred By: ${goldLoan?.transferredByAgentName ||
          "N/A"}`}</div>

        {/* <div
          className={classes.subText}
        >{`Scheme: ${goldLoan.nucleusSchemeCode}`}</div> */}
        {isPledgeCardVisible && isBackMovement && (
          <a
            href={state.payload.pledgeCard.link}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"View Pledge Card"}
          </a>
        )}
      </div>
    </div>
  );
};

export default PersonalLoanNotTaken;
