import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {useParams} from "react-router-dom";
import {loanActions, loanSelectors} from "app/store/loan";
import {Box, Divider, Grid} from "@mui/material";
import CustomerDetails from "./CustomerDetails";
import LoanDetails from "./LoanDetails";
import AccountDetails from "./AccountDetails";
import LoanSummary from "./LoanSummary";
import PaymentDetails from "./PaymentDetails";
import InstallmentScheduleDetails from "./InstallmentScheduleDetails";
import {IGLoading} from "app/components";
import {RolesEnum} from "app/enums/roles";
import {authSelectors} from "app/store/auth";
import {resetLoanList} from "app/store/loan/loan.reducer";
import {isNil} from "lodash";
import Downloads from "./Downloads";

interface ProfilePageProps {}

const Index: FC<ProfilePageProps> = () => {
  const dispatch = useAppDispatch();
  const {loanId, userId} = useParams();

  const user = useAppSelector(authSelectors.getUser);
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const {isGoldLoanTaken, goldLoanAccountNumber, goldLoanIgId} =
    loanDetails || {};
  const isLender = user?.userRoleList?.includes(RolesEnum.LENDER) || false;

  useEffect(() => {
    dispatch(resetLoanList());
  }, [dispatch]);

  useEffect(() => {
    if (!loanId) return;
    if (!loanDetails) {
      dispatch(
        loanActions.getLoanDetails({
          loanId: Number(loanId),
        }),
      );
    }
  }, [dispatch, loanDetails, loanId]);

  if (!loanId || isNaN(Number(loanId))) {
    return <IGLoading />;
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomerDetails />
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <LoanDetails />
        </Grid>
        <Grid item xs={12}>
          <AccountDetails />
        </Grid>

        {!isNil(goldLoanAccountNumber) &&
          goldLoanAccountNumber?.charAt(0) !== "F" && (
            <Grid item xs={12}>
              <LoanSummary />
            </Grid>
          )}

        <Grid item xs={12}>
          <PaymentDetails />
        </Grid>
        {!isNil(goldLoanAccountNumber) &&
          goldLoanAccountNumber?.charAt(0) !== "F" && (
            <Grid item xs={12}>
              <InstallmentScheduleDetails />
            </Grid>
          )}
        {loanDetails && (
          <Grid item xs={12}>
            <Downloads {
              ...{
                isLender,
                isGoldLoanTaken,
                goldLoanAccountNumber,
                goldLoanIgId,
                status: loanDetails.status,
                loanId: loanId,
                crId: loanDetails.crId,
              }
            } />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Index;
