import React, {useCallback, useEffect, useRef, useState} from "react";
import SnackBar from "../../../../../../components/Snackbar";
import verifyLenderDetails from "api/activeLoan/verifyLenderDetails";
import postLoanLenderDetails from "api/activeLoan/postLoanLenderDetails";
import getLoanLenderDetails from "api/activeLoan/getLoanLenderDetails";
import updateStatusApi from "api/business/updateStatus";
import getNextJob from "api/business/getNextJob";
import Loading from "app/components/Loading";
import ViewLenderAccountDetails from "./ViewLenderAccountDetails";
import VerifyLenderAccountDetails from "./VerifyLenderAccountDetails";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";

const LenderAccountDetails = ({
  selectedPayload,
  isSameCreditManager,
  getMileStoneOfCr,
  isBackMovement,
}) => {
  const [updationResponse, setUpdationResponse] = useState({
    gold: {},
    personal: {},
  });
  const counter = useRef(0);
  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
    buttonLoading: false,
  });
  const [nextJobResponse, setNextJobResponse] = useState(null);
  const [glVerifyButtonLoading, setGlVerifyButtonLoading] = useState(false);
  const [plVerifyButtonLoading, setPlVerifyButtonLoading] = useState(false);

  const [loanVerified, setLoanVerified] = useState({
    gold: false,
    personal: false,
  });
  const [snackbar, setSnackbar] = useState({
    message: "",
    snackbar: false,
  });
  const accessToken = localStorage.getItem("authToken");

  const [goldLoanDisplayValue, setGoldLoanDisplayValue] = useState(null);
  const [personalLoanDisplayValue, setPersonalLoanDisplayValue] = useState(
    null,
  );

  const branches = [
    {name: "Indrapuram", value: "1019"},
    {name: "Noida 27", value: "1007"},
    {name: "Indore", value: "1010"},
    {name: "Ujjain", value: "1030"},
    {name: "Ghaziabad", value: "1015"},
  ];

  const [valuesChanged, setValuesChanged] = useState({
    gold: true,
    personal: true,
  });

  const [info, setInfo] = useState({
    glbranch: "",
    plbranch: "",
    glaccount: "",
    placcount: "",
    glconfirmaccount: "",
    plconfirmaccount: "",
  });
  // const preventDef = (e) => e.preventDefault();

  const onChangeInfo = (e) => {
    if (e.target.id.includes("gl")) {
      setValuesChanged({
        ...valuesChanged,
        gold: true,
      });
    } else if (e.target.id.includes("pl")) {
      setValuesChanged({
        ...valuesChanged,
        personal: true,
      });
    }

    setInfo({
      ...info,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (counter.current === 0) {
          counter.current = 1;

          const nextResponse = await getNextJob({
            accessToken: accessToken,
            crId: selectedPayload.id,
            jobType: "CM_PROCESS_LOAN",
          });

          if (nextResponse.statusCode === 200) {
            setNextJobResponse(nextResponse.payload);
          }

          setStates({
            ...states,
            loading: false,
          });

          const {payload, error, statusCode} = await getLoanLenderDetails({
            accessToken,
            crId: selectedPayload.id,
            loanType: "GOLD_LOAN",
          });

          if (statusCode === 200) {
            setGoldLoanDisplayValue(payload);
            const {lenderBranchId, lenderLoanId} = payload;
            if (lenderBranchId) {
              setInfo((prevState) => ({
                ...prevState,
                glbranch: lenderBranchId,
              }));
            }
            if (lenderLoanId) {
              setInfo((prevState) => ({
                ...prevState,
                glaccount: lenderLoanId,
                glconfirmaccount: lenderLoanId,
              }));
            }
          } else {
            setSnackbar({
              message: error || "Error while fetching data",
              snackbar: true,
            });
          }

          if (selectedPayload.isPersonalLoanTaken) {
            const {
              payload: plPayload,
              error: plError,
              statusCode: plStatusCode,
            } = await getLoanLenderDetails({
              accessToken,
              crId: selectedPayload.id,
              loanType: "PERSONAL_LOAN",
            });

            if (plStatusCode === 200) {
              setPersonalLoanDisplayValue(plPayload);
              const {lenderBranchId, lenderLoanId} = plPayload;
              if (lenderBranchId) {
                setInfo((prevState) => ({
                  ...prevState,
                  plbranch: lenderBranchId,
                }));
              }
              if (lenderLoanId) {
                setInfo((prevState) => ({
                  ...prevState,
                  placcount: lenderLoanId,
                  plconfirmaccount: lenderLoanId,
                }));
              }
            } else {
              setSnackbar({
                message: plError,
                snackbar: true,
              });
            }
          }
        }
      } catch (e) {
        setSnackbar({
          message: "Error while fetching data",
          snackbar: true,
        });
      }
    }
    fetchData();
  }, [
    accessToken,
    isBackMovement,
    isSameCreditManager,
    selectedPayload.id,
    selectedPayload.isPersonalLoanTaken,
    states,
  ]);

  useEffect(() => {
    const timerId = setInterval(async () => {
      const nextResponse = await getNextJob({
        accessToken: accessToken,
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (nextResponse.statusCode === 200) {
        setNextJobResponse(nextResponse.payload);
      }
    }, 20000);

    return () => clearInterval(timerId);
  }, [accessToken, selectedPayload.id]);

  const onVerify = useCallback(
    async (e) => {
      const isGoldLoan = e.target.id === "gl";
      const isPersonalLoan = e.target.id === "pl";

      if (isGoldLoan && info.glaccount !== info.glconfirmaccount) {
        setSnackbar({
          snackbar: true,
          message: "Account number are not same in Gold Loan",
        });

        return;
      } else if (isPersonalLoan && info.placcount !== info.plconfirmaccount) {
        setSnackbar({
          snackbar: true,
          message: "Account number are not same in Personal Loan",
        });

        return;
      }
      try {
        if (isGoldLoan) {
          setGlVerifyButtonLoading(true);
        } else if (isPersonalLoan) {
          setPlVerifyButtonLoading(true);
        }

        const {statusCode, error} = await verifyLenderDetails({
          accessToken,
          crId: selectedPayload.id,
          confirmLenderLoanId: isGoldLoan
            ? info.glconfirmaccount
            : isPersonalLoan
            ? info.plconfirmaccount
            : "",
          lenderBranchId: isGoldLoan
            ? info.glbranch
            : isPersonalLoan
            ? info.plbranch
            : "",
          lenderLoanId: isGoldLoan
            ? info.glaccount
            : isPersonalLoan
            ? info.placcount
            : "",
          loanType: isGoldLoan
            ? "GOLD_LOAN"
            : isPersonalLoan
            ? "PERSONAL_LOAN"
            : "",
        });

        setGlVerifyButtonLoading(false);
        setPlVerifyButtonLoading(false);

        if (isGoldLoan && statusCode === 200) {
          setLoanVerified({
            ...loanVerified,
            gold: true,
          });

          setValuesChanged({
            ...valuesChanged,
            gold: false,
          });

          return;
        }

        if (isPersonalLoan && statusCode === 200) {
          setLoanVerified({
            ...loanVerified,
            personal: true,
          });

          setValuesChanged({
            ...valuesChanged,
            personal: false,
          });

          return;
        }

        if (statusCode !== 200) {
          setSnackbar({
            ...snackbar,
            snackbar: true,
            message: error || "Unable to verify",
          });
        }
      } catch (e) {
        setSnackbar({
          snackbar: true,
          message: "Unable to verify this loan details",
        });
      }
    },
    [
      accessToken,
      info.glaccount,
      info.glbranch,
      info.glconfirmaccount,
      info.placcount,
      info.plbranch,
      info.plconfirmaccount,
      loanVerified,
      selectedPayload.id,
      snackbar,
      valuesChanged,
    ],
  );

  const updateStatus = useCallback(() => {
    async function fetchData() {
      try {
        const {statusCode, payload, error} = await getNextJob({
          accessToken: accessToken,
          crId: selectedPayload.id,
          jobType: "CM_PROCESS_LOAN",
        });

        if (statusCode === 200) {
          const {
            statusCode: updateStatusCode,
            error: updateError,
          } = await updateStatusApi({
            accessToken,
            crId: selectedPayload.id,
            remark: "",
            crJobId: payload.id,
            status: "CM_PROCESS_LOAN_LENDER_DETAIL_ADDED",
          });

          if (updateStatusCode === 200) {
            setSnackbar({
              message: "Success: Data updated",
              snackbar: true,
            });
            getMileStoneOfCr();
          } else {
            setSnackbar({
              message: updateError || "Error while updating milestone",
              snackbar: true,
            });
          }
        } else {
          setSnackbar({
            message: error || "Unable to fetch next Job",
            snackbar: true,
          });
        }
      } catch (e) {
        setSnackbar({
          message: "Unable to fetch next Job",
          snackbar: true,
        });
      }
    }
    fetchData();
  }, [accessToken, getMileStoneOfCr, selectedPayload.id]);

  const onSubmit = useCallback(async () => {
    const isGoldLoanVerified = loanVerified.gold;
    const isPersonalLoanVerified = loanVerified.personal;
    const status = {
      gold: false,
      personal:
        !selectedPayload.isPersonalLoanTaken ? true
       : personalLoanDisplayValue?.lenderName !== lenderNameEnum.SHIVALIK,
    };

    const goldConfig = isGoldLoanVerified
      ? {
          ...{
            accessToken,
            crId: selectedPayload.id,
            confirmLenderLoanId: info.glconfirmaccount,
            lenderBranchId: info.glbranch,
            lenderLoanId: info.glaccount,
            loanType: "GOLD_LOAN",
          },
        }
      : null;

    const personalConfig = isPersonalLoanVerified
      ? {
          ...{
            accessToken,
            crId: selectedPayload.id,
            confirmLenderLoanId: info.plconfirmaccount,
            lenderBranchId: info.plbranch,
            lenderLoanId: info.placcount,
            loanType: "PERSONAL_LOAN",
          },
        }
      : null;

    try {
      setStates({
        ...states,
        buttonLoading: true,
      });
      if (isGoldLoanVerified && goldConfig) {
        const {statusCode, error, payload} = await postLoanLenderDetails(
          goldConfig,
        );

        if (statusCode === 200) {
          setUpdationResponse({
            ...updationResponse,
            gold: payload,
          });
          status.gold = true;
        } else {
          setSnackbar({
            message: error || "Error while updating GL info",
            snackbar: true,
          });
        }
      }
      if (isPersonalLoanVerified && personalConfig) {
        const {statusCode, error, payload} = await postLoanLenderDetails(
          personalConfig,
        );

        if (statusCode === 200) {
          setUpdationResponse({
            ...updationResponse,
            personal: payload,
          });
          status.personal = true;
        } else {
          setSnackbar({
            message: error || "Error while updating PL info",
            snackbar: true,
          });
        }
      }

      setStates({
        ...states,
        buttonLoading: false,
      });

      if (status.gold && status.personal) {
        updateStatus();
      }
    } catch (e) {
      setSnackbar({
        snackbar: true,
        message: "Error while updating details",
      });
    }
  }, [
    accessToken,
    info.glaccount,
    info.glbranch,
    info.glconfirmaccount,
    info.placcount,
    info.plbranch,
    info.plconfirmaccount,
    loanVerified.gold,
    loanVerified.personal,
    selectedPayload.id,
    selectedPayload.isPersonalLoanTaken,
    states,
    updateStatus,
    updationResponse,
    personalLoanDisplayValue,
  ]);

  if (states.loading) {
    return <Loading />;
  }

  if (states.error) {
    return <div>{states.errorMessage}</div>;
  }

  return (
    <>
      {/* Component to render form for verifying lender account details  */}

      <VerifyLenderAccountDetails
        isSameCreditManager={isSameCreditManager}
        isBackMovement={isBackMovement}
        info={info}
        onChangeInfo={onChangeInfo}
        nextJobResponse={nextJobResponse}
        branches={branches}
        glVerifyButtonLoading={glVerifyButtonLoading}
        plVerifyButtonLoading={plVerifyButtonLoading}
        states={states}
        loanVerified={loanVerified}
        personalLoanDisplayValue={personalLoanDisplayValue}
        selectedPayload={selectedPayload}
        valuesChanged={valuesChanged}
        onSubmit={onSubmit}
        onVerify={onVerify}
      />

      {/* Component to render lender account details  */}

      <ViewLenderAccountDetails
        isSameCreditManager={isSameCreditManager}
        isBackMovement={isBackMovement}
        goldLoanDisplayValue={goldLoanDisplayValue}
        selectedPayload={selectedPayload}
        personalLoanDisplayValue={personalLoanDisplayValue}
      />

      <SnackBar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
};

export default LenderAccountDetails;
