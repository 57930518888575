import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "../../infra/services/api";
import {call, getContext, put, takeEvery, takeLeading} from "redux-saga/effects";
import {layoutActions, LayoutActionTypes} from "./actions";
import {GetFeatureFlagPayload, GetSubMenuPayload} from "../../infra/services/api/layout/types";

const {
  getSubMenuSuccess,
  getSubMenuFailure,
  getLenderMenuFailure,
  getLenderMenuSuccess,
  getFeatureFlagsFailure,
  getFeatureFlagsSuccess,
} = layoutActions;

function* getSubMenuFlow(action: PayloadAction<GetSubMenuPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    action.payload.menuName === "customerAnalytics"
      ? api.layout.getLenderDashboardList
      : api.layout.getSubMenu,
    action.payload,
  );

  if (error) {
    yield put(getSubMenuFailure({response, error}));
  } else {
    yield put(getSubMenuSuccess({response, error}));
  }
}

function* getLenderMenuFlow() {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(api.layout.getLenderMenu);
  if (error) {
    yield put(getLenderMenuFailure({response, error}));
  } else {
    yield put(getLenderMenuSuccess({response, error}));
  }
}

function* getFeatureFlagsFlow(action: PayloadAction<GetFeatureFlagPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.layout.getFeatureFlags,
    action.payload,
  );
  if (error) {
    yield put(getFeatureFlagsFailure({response, error}));
  } else {
    yield put(getFeatureFlagsSuccess({response, error}));
  }
}

export default function* layoutSaga() {
  yield takeEvery(LayoutActionTypes.GetSubMenu, getSubMenuFlow);
  yield takeLeading(LayoutActionTypes.GetLenderMenu, getLenderMenuFlow);
  yield takeLeading(LayoutActionTypes.GetFeatureFlags, getFeatureFlagsFlow);
}