interface ActionState {
  loading: boolean;
  error: string | null;
}

const createActionsInitialState = (actions: string[]): Record<string, ActionState> => {
  return actions.reduce((state: Record<string, ActionState>, action) => {
    state[action] = {
      loading: false,
      error: null,
    };
    return state;
  }, {});
};

const setActionLoading = (state: any, action: any) => {
  state.actions[action.payload.action] = {
    loading: true,
    error: null,
  };
};

const setActionSuccess = (state: any, action: any) => {
  state.actions[action.payload.action] = {
    loading: false,
    error: null,
  };
};

const setActionError = (state: any, action: any) => {
  state.actions[action.payload.action] = {
    loading: false,
    error: action.payload.error,
  };
};

export {
  createActionsInitialState,
  setActionLoading,
  setActionSuccess,
  setActionError,
};
