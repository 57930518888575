export const creditReportStatusEnum = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILED",
  IN_PROGRESS: "IN_PROGRESS",
  PUBLISHED: "PUBLISHED",
};

export const creditSessionStatusEnum = {
  NO_USER_FOUND:"NO_USER_FOUND",
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
  NO_SCORE_RECEIVED: "NO_SCORE_RECEIVED",
  MASKED_INPUT_OTP_SENT:"MASKED_INPUT_OTP_SENT",
  OTP_SENT:"OTP_SENT",
  INTERMEDIATE_STATE:"INTERMEDIATE_STATE",
  MASKED_INPUT_FETCHED:"MASKED_INPUT_FETCHED",
};

export const checkFlagEnum = {
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
};

export const recommendationStatusEnum = {
  REJECT: "REJECT",
  APPROVE: "APPROVE",
};

export const ltvListEnum = {
  SHIVALIK: "SHIVALIK",
  PIRAMAL: "PIRAMAL",
  FINCARE: "FINCARE",
};

export const INTERMEDIATE_STATE_LIST = [
  creditSessionStatusEnum.MASKED_INPUT_OTP_SENT,
  creditSessionStatusEnum.OTP_SENT,
  creditSessionStatusEnum.MASKED_INPUT_FETCHED,
];
