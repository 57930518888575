import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
  loanSchemeType: string;
}

export const getAllSchemeList = async ({crId, loanSchemeType}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    GET_SCHEME_LIST: {
      URL: "admin/business/cr/:crId/scheme-list",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.GET_SCHEME_LIST,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        loanSchemeType,
      },
    },
  });
};

export default getAllSchemeList;
