import styled from "@emotion/styled";
import {Button, Grid} from "@mui/material";

// ------> CSS Props Interfaces <------
interface StepsContainerProps {
  disabled: boolean;
}

interface StepWrapperProps {
  highLight: boolean;
}

// ------> CSS <------
const NoteContainer = styled(Grid)`
  background: white;
  min-height: 60%;
  max-height: 90%;
  overflow-x: hidden;
  width: 100%;
  overflow-y: auto;
  position: relative;
  z-index: inherit;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.img`
  max-height: 50vh;
  max-width: 50vh;
`;

const StepsContainer = styled.div<StepsContainerProps>`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  cusror: ${({disabled}) => disabled ? "disabled" : "pointer"};
`;

const StepWrapper = styled(Button)<StepWrapperProps>`
  cursor: pointer;
  color: ${({highLight}) => highLight && "grey"};
`;


export {
  NoteContainer,
  TitleContainer,
  ImageWrapper,
  ImageContainer,
  StepsContainer,
  StepWrapper,
};
