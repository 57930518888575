import {createAction} from "@reduxjs/toolkit";
import {
  GetCurrentTaskStatusDetailPayload,
  OfferUpdateByCMPayload,
  ParentLoanIdPayload,
  ServiceRequestIdPayload,
  UpdateCustomerRequirementsByCSTPayload,
} from "app/infra/services/api/rateChange/rateChange.types";
import {CurrentOfferDetail, CurrentTaskStatusDetail, CustomerRequirementAndCompetitorDetails, LoanCurrentPlan, LoanRateChangeHistory, OfferVersionHistory} from "./rateChange.types";

const rateChangeActionTypes = {
  GetLoanRateChangeHistory: "[RateChange] Get Loan Rate Change History",
  GetLoanRateChangeHistorySuccess:
    "[RateChange] Get Loan Rate Change History Success",
  GetLoanRateChangeHistoryFailure:
    "[RateChange] Get Loan Rate Change History Failure",
  GetLoanCurrentPlan: "[RateChange] Get Loan Current Plan",
  GetLoanCurrentPlanSuccess: "[RateChange] Get Loan Current Plan Success",
  GetLoanCurrentPlanFailure: "[RateChange] Get Loan Current Plan Failure",
  GetOfferVersionHistory: "[RateChange] Get Offer Version History",
  GetOfferVersionHistorySuccess:
    "[RateChange] Get Offer Version History Success",
  GetOfferVersionHistoryFailure:
    "[RateChange] Get Offer Version History Failure",
  GetCustomerRequirementAndCompetitorDetails:
    "[RateChange] Get Customer Requirement And Competitor Details",
  GetCustomerRequirementAndCompetitorDetailsSuccess:
    "[RateChange] Get Customer Requirement And Competitor Details Success",
  GetCustomerRequirementAndCompetitorDetailsFailure:
    "[RateChange] Get Customer Requirement And Competitor Details Failure",
  GetCurrentOfferDetail: "[RateChange] Get Current Offer Detail",
  GetCurrentOfferDetailSuccess: "[RateChange] Get Current Offer Detail Success",
  GetCurrentOfferDetailFailure: "[RateChange] Get Current Offer Detail Failure",
  GetCurrentTaskStatusDetail: "[RateChange] Get Current Task Status Detail",
  GetCurrentTaskStatusDetailSuccess:
    "[RateChange] Get Current Task Status Detail Success",
  GetCurrentTaskStatusDetailFailure:
    "[RateChange] Get Current Task Status Detail Failure",
  UpdateCustomerRequirementsByCst:
    "[RateChange] Update Customer Requirements By Cst",
  UpdateCustomerRequirementsByCstSuccess:
    "[RateChange] Update Customer Requirements By Cst Success",
  UpdateCustomerRequirementsByCstFailure:
    "[RateChange] Update Customer Requirements By Cst Failure",
  ShareCmOfferWithCustomer: "[RateChange] Share Cm Offer With Customer",
  ShareCmOfferWithCustomerSuccess:
    "[RateChange] Share Cm Offer With Customer Success",
  ShareCmOfferWithCustomerFailure:
    "[RateChange] Share Cm Offer With Customer Failure",
  UpdateOfferByCm: "[RateChange] Update Offer By Cm",
  UpdateOfferByCmSuccess: "[RateChange] Update Offer By Cm Success",
  UpdateOfferByCmFailure: "[RateChange] Update Offer By Cm Failure",
};

const rateChangeActions = {
  getLoanRateChangeHistory: createAction<ParentLoanIdPayload>(
    rateChangeActionTypes.GetLoanRateChangeHistory,
  ),
  getLoanRateChangeHistorySuccess: createAction<LoanRateChangeHistory>(
    rateChangeActionTypes.GetLoanRateChangeHistorySuccess,
  ),
  getLoanRateChangeHistoryFailure: createAction<{ error: string }>(
    rateChangeActionTypes.GetLoanRateChangeHistoryFailure,
  ),
  getLoanCurrentPlan: createAction<ServiceRequestIdPayload>(
    rateChangeActionTypes.GetLoanCurrentPlan,
  ),
  getLoanCurrentPlanSuccess: createAction<LoanCurrentPlan>(
    rateChangeActionTypes.GetLoanCurrentPlanSuccess,
  ),
  getLoanCurrentPlanFailure: createAction<{ error: string }>(
    rateChangeActionTypes.GetLoanCurrentPlanFailure,
  ),
  getOfferVersionHistory: createAction<GetCurrentTaskStatusDetailPayload>(
    rateChangeActionTypes.GetOfferVersionHistory,
  ),
  getOfferVersionHistorySuccess: createAction<OfferVersionHistory[]>(
    rateChangeActionTypes.GetOfferVersionHistorySuccess,
  ),
  getOfferVersionHistoryFailure: createAction<{ error: string }>(
    rateChangeActionTypes.GetOfferVersionHistoryFailure,
  ),
  getCustomerRequirementAndCompetitorDetails: createAction<
    ServiceRequestIdPayload
  >(rateChangeActionTypes.GetCustomerRequirementAndCompetitorDetails),
  getCustomerRequirementAndCompetitorDetailsSuccess: createAction<
    CustomerRequirementAndCompetitorDetails
  >(rateChangeActionTypes.GetCustomerRequirementAndCompetitorDetailsSuccess),
  getCustomerRequirementAndCompetitorDetailsFailure: createAction<{
    error: string;
  }>(rateChangeActionTypes.GetCustomerRequirementAndCompetitorDetailsFailure),
  getCurrentOfferDetail: createAction<ServiceRequestIdPayload>(
    rateChangeActionTypes.GetCurrentOfferDetail,
  ),
  getCurrentOfferDetailSuccess: createAction<CurrentOfferDetail>(
    rateChangeActionTypes.GetCurrentOfferDetailSuccess,
  ),
  getCurrentOfferDetailFailure: createAction<{error: string}>(
    rateChangeActionTypes.GetCurrentOfferDetailFailure,
  ),
  getCurrentTaskStatusDetail: createAction<GetCurrentTaskStatusDetailPayload>(
    rateChangeActionTypes.GetCurrentTaskStatusDetail,
  ),
  getCurrentTaskStatusDetailSuccess: createAction<CurrentTaskStatusDetail>(
    rateChangeActionTypes.GetCurrentTaskStatusDetailSuccess,
  ),
  getCurrentTaskStatusDetailFailure: createAction<{error: string}>(
    rateChangeActionTypes.GetCurrentTaskStatusDetailFailure,
  ),
  updateCustomerRequirementsByCst: createAction<
    UpdateCustomerRequirementsByCSTPayload
  >(rateChangeActionTypes.UpdateCustomerRequirementsByCst),
  updateCustomerRequirementsByCstSuccess: createAction(
    rateChangeActionTypes.UpdateCustomerRequirementsByCstSuccess,
  ),
  updateCustomerRequirementsByCstFailure: createAction<{error: string}>(
    rateChangeActionTypes.UpdateCustomerRequirementsByCstFailure,
  ),
  shareCmOfferWithCustomer: createAction<ServiceRequestIdPayload>(
    rateChangeActionTypes.ShareCmOfferWithCustomer,
  ),
  shareCmOfferWithCustomerSuccess: createAction(
    rateChangeActionTypes.ShareCmOfferWithCustomerSuccess,
  ),
  shareCmOfferWithCustomerFailure: createAction<{error: string}>(
    rateChangeActionTypes.ShareCmOfferWithCustomerFailure,
  ),
  updateOfferByCm: createAction<OfferUpdateByCMPayload>(
    rateChangeActionTypes.UpdateOfferByCm,
  ),
  updateOfferByCmSuccess: createAction(
    rateChangeActionTypes.UpdateOfferByCmSuccess,
  ),
  updateOfferByCmFailure: createAction<{error: string}>(
    rateChangeActionTypes.UpdateOfferByCmFailure,
  ),
};

export default rateChangeActions;
export {rateChangeActionTypes};
