import React, {useMemo} from "react";
import {Box} from "@mui/material";
import IGKeyValue from "app/components/IGLabelValue";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";

interface CreditDefaultHistoryProps {
  crId: number;
}
const CreditDefaultHistory = ({crId}: CreditDefaultHistoryProps) => {
  const creditDefaultDetails = useAppSelector(state =>
    activeLoansSelectors.getCreditDefaultHistoryByCrId(state, crId),
  );
  const creditDefaultFields = useMemo(() => {
    if (creditDefaultDetails) {
      const {
        userType,
        totalDefaultedLoanCount,
        totalOverdueLoanCount,
        totalLoanCountHavingMoreThanSixtyDpdInLastSixMonths,
        totalLoanCountHavingMoreThanThirtyDpdInLastThreeMonths,
        totalLoanCountHavingMoreThanZeroDpdInLastThreeMonths,
        totalLoanCountHavingSpecialMentionAccountInDpd,
        cibilReportRemarks,
      } = creditDefaultDetails ?? {};
      return [
        {
          label: "IG User Type",
          value: userType,
        },
        {
          label: "No. of loans in default",
          value: totalDefaultedLoanCount,
        },
        {
          label: "Current Overdue loans",
          value: totalOverdueLoanCount,
        },
        {
          label: "No. of loans with more than 60 DPD in last 6 months",
          value: totalLoanCountHavingMoreThanSixtyDpdInLastSixMonths,
        },
        {
          label: "No. of loans with more than 30 DPD in last 3 months",
          value: totalLoanCountHavingMoreThanThirtyDpdInLastThreeMonths,
        },
        {
          label: "No. of loans having more than 0 DPD in last 3 months",
          value: totalLoanCountHavingMoreThanZeroDpdInLastThreeMonths,
        },
        {
          label: "Special Mention Account",
          value: totalLoanCountHavingSpecialMentionAccountInDpd,
        },
        {
          label: "Cibil Report Generation Remark",
          value: cibilReportRemarks,
        },
      ];
    }
    return [];
  }, [creditDefaultDetails]);
  return (
    <Box mt={2} p={1} borderRadius="4px" bgcolor="#FFFFFF" border="1px solid #0000001F">
      {creditDefaultFields.map(item => (
        <IGKeyValue label={item.label} value={item.value} />
      ))}
    </Box>
  );
};

export default CreditDefaultHistory;
