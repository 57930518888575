import styled from "@emotion/styled";

const SendSMSContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default SendSMSContainer;
