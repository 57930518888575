import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import getDocument from "api/business/getDocument";
import Video from "../CommonComponents/Video";
import styles from "../styles";

const CustomerLeftHome = ({selectedPayload}) => {
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: null,
    errorMessage: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getDocument({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          documentType: [
            "CUSTOMER_LOCALITY_VIDEO",
            "AGENT_SELFIE_IN_FRONT_OF_CUSTOMER_HOME",
          ],
        });

        if (response.statusCode === 200) {
          setStates({
            error: false,
            errorMessage: "",
            loading: false,
            payload: response.payload,
          });
        } else {
          setStates({
            error: true,
            errorMessage: response.error,
            loading: false,
            payload: null,
          });
        }
      } catch (error) {
        setStates({
          error: true,
          errorMessage: "Something went wrong",
          loading: false,
          payload: null,
        });
      }
    })();
  }, [selectedPayload.id]);

  const imageOnClickHandler = (url) => {
    const image = new Image();
    image.src = url;

    const w = window.open("");
    w.document.write(image.outerHTML);
  };

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const customerLocalityVideo = states.payload?.find((res) => {
    return res.documentType === "CUSTOMER_LOCALITY_VIDEO";
  });

  const agentSelfie = states.payload?.find((e) => {
    return e.documentType === "AGENT_SELFIE_IN_FRONT_OF_CUSTOMER_HOME";
  });

  return (
    <>
      <Video name="CUSTOMER LOCALITY VIDEO" video={customerLocalityVideo} />

      {agentSelfie && (
        <div>
          <div
            onClick={() => {
              imageOnClickHandler(agentSelfie.fileUrl);
            }}
            className={classes.link}
          >
            Selfie At Customer Home
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerLeftHome;
