import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {MenuIconProps} from ".";

const UserTierConfig: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={solid("user-gear")} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

export default UserTierConfig;