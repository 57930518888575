import {createAction} from "@reduxjs/toolkit";
import {GetAgentsPayload, LmAvailabilityResponse} from "app/infra/services/api/scheduling/lmAvailability/types";
import {UpdateAgentSchedulePayload} from "app/infra/services/api/serviceDesk/agentAvailability/types";
import {GenericResponse} from "app/typings/api/goldApi.types";

export const agentAvailabilityActionTypes = {
  GetAgents: "[Agent Availability] Get Agents",
  GetAgentsSuccess: "[Agent Availability] Get Agents Success",
  GetAgentsFailure: "[Agent Availability] Get Agents Failure",
  GetAgentScheduleList: "[Agent Availability] Get Agent Schedule List",
  GetAgentScheduleListSuccess: "[Agent Availability] Get Agent Schedule List Success",
  GetAgentScheduleListFailure: "[Agent Availability] Get Agent Schedule List Failure",
  UpdateAgentSchedule: "[Agent Availability] Update Time Slot",
  UpdateAgentScheduleSuccess: "[Agent Availability] Update Time Slot Success",
  UpdateAgentScheduleFailure: "[Agent Availability] Update Time Slot Failure",
};
export const agentAvailabilityActions = {
  getAgents: createAction<GetAgentsPayload>(
    agentAvailabilityActionTypes.GetAgents,
  ),
  getAgentsSuccess: createAction<LmAvailabilityResponse>(
    agentAvailabilityActionTypes.GetAgentsSuccess,
  ),
  getAgentsFailure: createAction<LmAvailabilityResponse>(
    agentAvailabilityActionTypes.GetAgentsFailure,
  ),
  updateAgentSchedule: createAction<UpdateAgentSchedulePayload>(
    agentAvailabilityActionTypes.UpdateAgentSchedule,
  ),
  updateAgentScheduleSuccess: createAction<GenericResponse>(
    agentAvailabilityActionTypes.UpdateAgentScheduleSuccess,
  ),
  updateAgentScheduleFailure: createAction<GenericResponse>(
    agentAvailabilityActionTypes.UpdateAgentScheduleFailure,
  ),
  getAgentScheduleList: createAction(
    agentAvailabilityActionTypes.GetAgentScheduleList,
  ),
  getAgentScheduleListSuccess: createAction<GenericResponse>(
    agentAvailabilityActionTypes.GetAgentScheduleListSuccess,
  ),
  getAgentScheduleListFailure: createAction<GenericResponse>(
    agentAvailabilityActionTypes.GetAgentScheduleListFailure,
  ),
};
