import React from "react";
import {RootState} from "app/store/store";
import {Stack, Typography} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import {PersonRounded, CallRounded, LocationOn} from "@mui/icons-material";

const BtScoreCustomerDetailsOverview = ({crId} : {crId: number}) => {
  const customerDetails =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreCardCustomerDetailsByCrId(
        state,
        Number(crId),
      ),
    ) ?? {};
  const {
    customerGender,
    customerName,
    customerPhoneNumber,
    customerLocation,
    customerAgeInYearAndMonth,
  } = customerDetails ?? {};
  return (
    <>
      <Typography
        fontWeight={400}
        variant="h5"
        fontSize="24px"
        lineHeight="32px"
      >
        {customerName}
      </Typography>
      <Stack direction="row" gap={1} mt={1.5}>
        <PersonRounded />
        <Typography
          fontWeight={400}
          variant="subtitle1"
          fontSize="16px"
          lineHeight="18px"
        >
          {customerGender}, {customerAgeInYearAndMonth}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1} mt={1.5}>
        <CallRounded />
        <Typography
          fontWeight={400}
          variant="subtitle1"
          fontSize="16px"
          lineHeight="18px"
        >
          {customerPhoneNumber}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1} mt={1.5}>
        <LocationOn />
        <Typography
          fontWeight={400}
          variant="subtitle1"
          fontSize="16px"
          lineHeight="18px"
        >
          {customerLocation}
        </Typography>
      </Stack>
    </>
  );
};

export default BtScoreCustomerDetailsOverview;
