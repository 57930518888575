import {RootState} from "../store";

const customerProfileSelectors = {
  getOverduePaymentDetails: (state: RootState) =>
    state.customerProfile.overduePaymentDetail,
  getReminderList: (state: RootState) => state.customerProfile.reminderList,
  getCustomerProfileList: (state: RootState) =>
    state.customerProfile.customerProfileList,
  getCustomerDetails: (state: RootState, userId: number) => {
    return state.customerProfile.customerProfileList.find(
      (c) => c.id === userId,
    );
  },
  getInwardReceiptImageByloanId: (state: RootState, loanId: number) =>
    state.customerProfile.inwardReceiptImage[loanId],
  getLoading: (state: RootState) => state.customerProfile.loading,
  getErrors: (state: RootState) => state.customerProfile.errors,
  getOpenModals: (state: RootState) => state.customerProfile.openModals,
  getBatchRefundStatus: (state: RootState) =>
    state.customerProfile.batchRefundStatus,
};

export default customerProfileSelectors;
