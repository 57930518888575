import {IGDialog, IGButton} from "app/components";
import React, {useState} from "react";
import {Box} from "@mui/material";
import returnToGoldValuation from "api/activeLoan/returnToGoldValuation";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

interface ReturnToGoldValuationPopUpProps {
  isReturnToValuationModalOpen: boolean;
  setIsReturnToValuationModalOpen: (args: boolean) => void;
  getMileStoneOfCr: () => void;
  crId: number;
}
const ReturnToGoldValuationPopUp = ({
  isReturnToValuationModalOpen,
  setIsReturnToValuationModalOpen,
  getMileStoneOfCr,
  crId,
}: ReturnToGoldValuationPopUpProps) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const {showError, showSuccess} = useSnackBar();
  const [isReturnToValuationLoading, setIsReturnToValuationLoading] = useState(
    false,
  );
  const onClickConfirmHandler = async () => {
    setIsReturnToValuationLoading(true);
    const returnToValuationResponse = await returnToGoldValuation({
      accessToken,
      crId: crId,
    });

    if (returnToValuationResponse.statusCode === 200) {
      getMileStoneOfCr();
      showSuccess("Success");
      setIsReturnToValuationModalOpen(false);
    } else {
      showError(
        `Error: ${returnToValuationResponse.error || "Something went wrong"}`,
      );
    }
    setIsReturnToValuationLoading(false);
  };
  return (
    <Box>
      <IGDialog
        open={isReturnToValuationModalOpen}
        onClose={() => setIsReturnToValuationModalOpen(false)}
        title="You will have to redo all the process starting from gold valuation.
        Are you sure you want to go back ?"
        hideFooter
      >
        <Box mt={2} gap={1} display="flex" justifyContent="flex-end">
          <IGButton
            color="inherit"
            onClick={() => setIsReturnToValuationModalOpen(false)}
          >
            Cancel
          </IGButton>
          <IGButton
            loading={isReturnToValuationLoading}
            onClick={onClickConfirmHandler}
          >
            Confirm
          </IGButton>
        </Box>
      </IGDialog>
    </Box>
  );
};

export default ReturnToGoldValuationPopUp;
