import React, {useEffect, useCallback, useState, useRef} from "react";

import getEmploymentDetails from "../../../../../../../api/business/getEmploymentDetails";
import styles from "../styles";
import {CircularProgress} from "@mui/material";
import numberWithCurrencyAndComma from "../../../../../../../lib/numberWithCurrencyAndComma";

const EmploymentDetails = ({
  selectedPayload,
  // currentMileStone,
  otherRequestTimer,
}) => {
  const counter = useRef(0);
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
    payload: {
      employmentDetails: null,
    },
  });

  const getDetails = useCallback(async () => {
    try {
      const [response] = await Promise.all([
        getEmploymentDetails({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
      ]);

      if (response.statusCode === 200) {
        setStates({
          error: false,
          errorMessage: "",
          loading: false,
          payload: {
            employmentDetails: response.payload,
          },
        });
      } else {
        setStates({
          error: true,
          errorMessage: response.error.toString(),
          loading: false,
          payload: {
            employmentDetails: null,
          },
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went Wrong",
        loading: false,
        payload: {
          employmentDetails: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getDetails, otherRequestTimer]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const list = [
    {
      text: "OCCUPATION",
      subText: states.payload.employmentDetails.occupationType,
    },
    {
      text: "NATURE OF BUSINESS",
      subText: states.payload.employmentDetails.natureOfBusiness,
    },
    {
      text: "INDUSTRY",
      subText: states.payload.employmentDetails.businessIndustry,
    },
    {
      text: "YEARS IN BUSINESS",
      subText: states.payload.employmentDetails.yearInIndustry,
    },
    {
      text: "GROSS YEARLY INCOME",
      subText: numberWithCurrencyAndComma(
        states.payload.employmentDetails.annualIncome,
      ),
    },
    {
      text: "REMARKS",
      subText: states.payload.employmentDetails.remark,
    },
  ];

  return (
    <>
      {list.map((text) => (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>{text.text}</div>

            <div className={classes.subText}>{text.subText}</div>
          </div>
        </>
      ))}
    </>
  );
};

export default EmploymentDetails;
