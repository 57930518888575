import React, {useCallback, useEffect, useRef, useState} from "react";

import getNextJob from "api/business/getNextJob";
import styles from "../styles";
import {CircularProgress, InputAdornment, Box, Typography} from "@mui/material";
import updateStatusApi from "api/business/updateStatus";
import TakeOverAmountKYCApi from "api/business/TakeOverAmountKYC";
import getTakeOverDetails from "api/business/getTakeOverDetails";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import TakeoverAmountCheckerModal from "./TakeoverAmountCheckerModal";
import {IGButton, IGTextField} from "app/components";
import {authSelectors} from "app/store/auth";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {CrRejectionSourceEnum} from "app/enums/gold_loan/activeLoans";

import RejectApplication from "../CommonComponents/Modal/ReasonToReject";
import AskLmForAdditionalDetail from "../CommonComponents/Modal/AskLmForAdditionalDetail";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansSelectors} from "app/store/activeLoans";

const CheckRTGS = ({selectPayload, otherRequestTimer, getMileStoneOfCr, setCrJobId}) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const {showError, showSuccess, showWarning} = useSnackBar();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [amount, setAmount] = useState("");
  const [isTakeoverAmountCheckerModalOpen, setIsTakeoverAmountCheckerModalOpen] = useState(false);
  const [checkerTakeoverAmount, setCheckerTakeoverAmount] = useState("");
  const [isConfirmBtnLoading, setIsConfirmBtnLoading] = useState(false);
  const openModals = useAppSelector(activeLoansSelectors.getOpenModals);
  const [makerBtnLoading, setMakerBtnLoading] = useState(false);
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      approval: null,
      skip: null,
      isSkip: false,
      takeoverDetails: null,
    },
    errorMessage: "",
  });

  const counter = useRef(0);

  const getNextJOb = useCallback(async () => {
    try {
      const [response, skipResponse, getTakeOverDetailsResponse] = await Promise.all([
        getNextJob({
          accessToken,
          crId: selectPayload.id,
          jobType: "CM_TAKEOVER_RTGS_RECEIPT_APPROVAL",
        }),
        getNextJob({
          accessToken,
          crId: selectPayload.id,
          jobType: "CM_TAKEOVER_SKIP_RTGS_RECEIPT_APPROVAL",
        }),
        getTakeOverDetails({
          accessToken,
          crId: selectPayload.id,
        }),
      ]);

      if (skipResponse.statusCode === 200) {
        setStates({
          error: false,
          loading: false,
          payload: {
            approval: response.payload,
            isSkip: true,
            skip: skipResponse.payload,
            takeoverDetails: getTakeOverDetailsResponse.payload,
          },
          errorMessage: "",
        });
      } else {
        if (response.statusCode === 200 || getTakeOverDetailsResponse.statusCode === 200) {
          setStates({
            error: false,
            loading: false,
            payload: {
              approval: response.payload,
              isSkip: false,
              skip: skipResponse.payload,
              takeoverDetails: getTakeOverDetailsResponse.payload,
            },
            errorMessage: "",
          });
        } else {
          setStates({
            error: true,
            loading: false,
            payload: {
              approval: null,
              isSkip: null,
              skip: null,
              takeoverDetails: null,
            },
            errorMessage:
              response?.error?.toString() ||
              getTakeOverDetailsResponse?.error?.toString() ||
              skipResponse?.error?.toString(),
          });
        }
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: {
          approval: null,
          isSkip: false,
          skip: null,
          takeoverDetails: null,
        },
      });
    }
  }, [selectPayload.id]);

  useEffect(() => {
    (async () => {
      if (counter.current === 0) {
        counter.current = 1;
        getNextJOb();
      }
    })();
  }, [getNextJOb, selectPayload]);

  useEffect(() => {
    const timer = setInterval(() => {
      getNextJOb();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getNextJOb, otherRequestTimer]);

  const getNextJobId = useCallback(() => {
    if (states.payload.skip !== null) {
      return states.payload?.skip?.id;
    }
    if (states.payload.approval !== null) {
      return states.payload?.approval?.id;
    }

    return "";
  }, [states.payload.approval, states.payload.skip]);

  useEffect(() => {
    if (states.payload.takeoverDetails?.takeoverAmount) {
      setAmount(states.payload.takeoverDetails?.takeoverAmount);
    } else {
      setAmount("");
    }
  }, [states.payload.takeoverDetails?.takeoverAmount]);

  const onClickApproveHandler = useCallback(async () => {
    try {
      if (amount.length === 0) {
        showWarning("Please enter the takeover amount that should be transferred to customer.");
        return;
      }
      setIsConfirmBtnLoading(true);
      const nextJobId = getNextJobId();

      if (nextJobId.length === 0) {
        showWarning("No Next Job Found");
        setIsConfirmBtnLoading(false);

        return;
      }
      const {error: validateTakeoverAmountError} = await activeLoansApi.validateTakeoverAmount({
        crId: selectPayload.id,
        takeoverAmountToTransfer: amount,
        takeoverAmountToTransferInWords: checkerTakeoverAmount,
      });

      if (!validateTakeoverAmountError) {
        if (nextJobId) {
          const response = await updateStatusApi({
            accessToken,
            crId: selectPayload.id,
            crJobId: nextJobId,
            remark: "",
            status: "CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_PAYMENT_REQUESTED",
          });

          if (response.statusCode === 200) {
            showSuccess("Success");
            setIsTakeoverAmountCheckerModalOpen(false);
            getMileStoneOfCr();
            getNextJOb();
            setCheckerTakeoverAmount("");
            setAmount("");
          } else {
            showError(response.error);
          }
        } else {
          showError("Next Job not available");
        }
      } else {
        showError(validateTakeoverAmountError.message);
      }
      setIsConfirmBtnLoading(false);
    } catch (error) {
      showError("Something went wrong");
      setIsConfirmBtnLoading(false);
    }
  }, [
    accessToken,
    amount,
    checkerTakeoverAmount,
    getMileStoneOfCr,
    getNextJOb,
    getNextJobId,
    selectPayload.id,
    showError,
    showSuccess,
    showWarning,
  ]);

  const onClickTakeoverMakerHandler = useCallback(async () => {
    setMakerBtnLoading(true);
    const makerTakeoverResponse = await TakeOverAmountKYCApi({
      accessToken,
      crId: selectPayload.id,
      takeoverAmountToTransfer: amount,
    });
    if (makerTakeoverResponse.statusCode === 200) {
      showSuccess("Takeover maker has been done!");
      setMakerBtnLoading(false);
      getNextJOb();
    } else {
      setMakerBtnLoading(false);
      showError(makerTakeoverResponse.error?.toString());
    }
  }, [accessToken, amount, selectPayload.id, showError, showSuccess]);

  useEffect(() => {
    if (states.payload.skip !== null) {
      setCrJobId(states.payload.skip?.id);
    } else if (states.payload.approval !== null) {
      setCrJobId(states.payload.approval?.id);
    }
  }, [states.payload.approval, states.payload.skip]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const takeoverAmount = states.payload.takeoverDetails?.takeoverAmount;
  const pendingDue = states.payload.takeoverDetails?.outstandingDue;

  const getStatus = () => {
    if (states.payload.approval) {
      return (
        states.payload.approval &&
        states.payload.approval.status !== "CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_PAYMENT_REQUESTED"
      );
    }

    return (
      states.payload.skip &&
      states.payload.skip.status !== "CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_PAYMENT_REQUESTED"
    );
  };

  const onClickRejectHandler = () => {
    dispatch(
      toggleModals({
        type: "rejectCr",
        value: true,
      }),
    );
  };

  const onClickSendMessageHandler = () => {
    dispatch(
      toggleModals({
        type: "askLmForAdditionalDetail",
        value: true,
      }),
    );
  };

  return (
    <>
      {takeoverAmount !== null && (
        <Box mt={2}>
          <Typography fontWeight={600}>{`Takeover Amount:  ${numberWithCurrencyAndComma(
            takeoverAmount,
          )}`}</Typography>
        </Box>
      )}

      <Box mt={0.5}>
        <Typography fontWeight={600}>{`Pending Dues:  ${numberWithCurrencyAndComma(
          pendingDue,
        )}`}</Typography>
      </Box>

      <Box mt={0.5}>
        <Typography fontWeight={600}>{`CM Remarks: ${states.payload?.takeoverDetails
          ?.takeoverKycCmRemarks || "N/A"}`}</Typography>
      </Box>

      {openModals.rejectCr && (
        <RejectApplication
          crId={selectPayload.id}
          crJobId={getNextJobId()}
          open={openModals.rejectCr}
          rejectionSource={CrRejectionSourceEnum.TAKEOVER_RTGS}
          getMileStoneOfCr={getMileStoneOfCr}
          getNextJobApi={getNextJOb}
          crJobStatus="CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_UPDATE_REQUESTED"
          title="REJECT RTGS"
          confirmText="REJECT RTGS"
          source="TAKEOVER_RTGS"
        />
      )}

      {openModals.askLmForAdditionalDetail && (
        <AskLmForAdditionalDetail
          crId={selectPayload.id}
          crJobId={getNextJobId()}
          open={openModals.askLmForAdditionalDetail}
          sendBackSource={CrRejectionSourceEnum.TAKEOVER_RTGS}
          getMileStoneOfCr={getMileStoneOfCr}
          getNextJobApi={getNextJOb}
          crJobStatus="CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_UPDATE_REQUESTED"
          source="TAKEOVER_RTGS"
          title="ASK LM FOR ADDITIONAL DETAILS"
          confirmText="SEND BACK"
        />
      )}
      {!states.error && getStatus() && (
        <Box my={1}>
          {!states?.payload?.takeoverDetails?.makerId && (
            <IGTextField
              fullWidth
              label="Please enter the amount that needs to be transferred"
              name="amount"
              onChange={event => setAmount(event.target.value)}
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <div style={{margin: 0}} className={classes.subText}>
                    Rs.
                  </div>
                </InputAdornment>
              }
            />
          )}

          {!states.payload.takeoverDetails?.makerId && (
            <Box mt={1}>
              <IGButton
                fullWidth
                color="golden"
                onClick={() => onClickTakeoverMakerHandler()}
                loading={makerBtnLoading}
              >
                COMPLETED TAKEOVER AMOUNT MAKER
              </IGButton>
            </Box>
          )}

          {states.payload.takeoverDetails?.makerId
          && !states.payload.takeoverDetails?.checkerId && (
            <Box mt={1}>
              <IGButton
                color="golden"
                fullWidth
                onClick={() => setIsTakeoverAmountCheckerModalOpen(true)}
              >
                COMPLETE TAKEOVER AMOUNT CHECKER
              </IGButton>
            </Box>
          )}

          <Box mt={1}>
            <IGButton fullWidth color="inherit" onClick={onClickSendMessageHandler}>
              ASK LM FOR ADDITIONAL DETAILS
            </IGButton>
          </Box>
          <Box mt={1}>
            <IGButton
              variant="text"
              sx={{
                textDecoration: "underline",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                  textDecoration: "underline",
                },
              }}
              fullWidth
              onClick={onClickRejectHandler}
              color="inherit"
              size="large"
            >
              REJECT RTGS
            </IGButton>
          </Box>
        </Box>
      )}
      {isTakeoverAmountCheckerModalOpen && (
        <TakeoverAmountCheckerModal
          isTakeoverAmountCheckerModalOpen={isTakeoverAmountCheckerModalOpen}
          setIsTakeoverAmountCheckerModalOpen={setIsTakeoverAmountCheckerModalOpen}
          onClickApproveHandler={onClickApproveHandler}
          setCheckerTakeoverAmount={setCheckerTakeoverAmount}
          checkerTakeoverAmount={checkerTakeoverAmount}
          makerTakeoverAmount={amount}
          isConfirmBtnLoading={isConfirmBtnLoading}
          setAmount={setAmount}
          crId={selectPayload.id}
          getNextJOb={getNextJOb}
        />
      )}
    </>
  );
};

export default CheckRTGS;
