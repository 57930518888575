import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import personalDetailsActions from "./personalDetails.actions";
import {
  PersonalDetailsInterface,
  AddressDetailsInterface,
  DocumentsMasterList,
  KycDocuments,
} from "app/models/loanRenewalRebook/personalDetails.model";

type LoadingErrorState =
  | "getPersonalDetails"
  | "getAddressDetails"
  | "updatePersonalDetails"
  | "getDocumentsMasterList"
  | "getKycDocuments"
  | "getGuarantorPersonalDetails"
  | "getGuarantorAddressDetails";

export interface PersonalDetailsState {
  personalDetails: PersonalDetailsInterface | null;
  addressDetails: AddressDetailsInterface[];
  documentsMasterList: DocumentsMasterList[];
  kycDocuments: KycDocuments[];
  guarantorPersonalDetails: PersonalDetailsInterface | null;
  guarantorAddressDetails: AddressDetailsInterface[];

  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: PersonalDetailsState = {
  personalDetails: null,
  addressDetails: [],
  documentsMasterList: [],
  kycDocuments: [],
  guarantorPersonalDetails: null,
  guarantorAddressDetails: [],
  loading: {
    getPersonalDetails: false,
    getAddressDetails: false,
    updatePersonalDetails: false,
    getDocumentsMasterList: false,
    getKycDocuments: false,
    getGuarantorPersonalDetails: false,
    getGuarantorAddressDetails: false,
  },

  errors: {
    getPersonalDetails: null,
    getAddressDetails: null,
    updatePersonalDetails: null,
    getDocumentsMasterList: null,
    getKycDocuments: null,
    getGuarantorPersonalDetails: null,
    getGuarantorAddressDetails: null,
  },
};

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    resetGuarantorPersonalDetails: state => {
      state.guarantorPersonalDetails = null;
    },
  },
  extraReducers: builder =>
    builder

      .addCase(personalDetailsActions.getPersonalDetails, state => {
        state.loading.getPersonalDetails = true;
        state.errors.getPersonalDetails = initialState.errors.getPersonalDetails;
      })
      .addCase(personalDetailsActions.getPersonalDetailsSuccess, (state, action) => {
        state.personalDetails = action.payload.response;
        state.loading.getPersonalDetails = false;
      })
      .addCase(personalDetailsActions.getPersonalDetailsFailure, (state, action) => {
        state.loading.getPersonalDetails = false;
        state.errors.getPersonalDetails = action.payload.error;
      })
      .addCase(personalDetailsActions.getAddressDetails, state => {
        state.loading.getAddressDetails = true;
        state.errors.getAddressDetails = initialState.errors.getAddressDetails;
      })
      .addCase(personalDetailsActions.getAddressDetailsSuccess, (state, action) => {
        state.addressDetails = action.payload.response;
        state.loading.getAddressDetails = false;
      })
      .addCase(personalDetailsActions.getAddressDetailsFailure, (state, action) => {
        state.loading.getAddressDetails = false;
        state.errors.getAddressDetails = action.payload.error;
      })
      .addCase(personalDetailsActions.updatePersonalDetails, state => {
        state.loading.updatePersonalDetails = true;
        state.errors.updatePersonalDetails = initialState.errors.updatePersonalDetails;
      })
      .addCase(personalDetailsActions.updatePersonalDetailsSuccess, (state, action) => {
        state.loading.updatePersonalDetails = false;
      })
      .addCase(personalDetailsActions.updatePersonalDetailsFailure, (state, action) => {
        state.loading.updatePersonalDetails = false;
        state.errors.updatePersonalDetails = action.payload.error;
      })
      .addCase(personalDetailsActions.getDocumentsMasterList, state => {
        state.loading.getDocumentsMasterList = true;
        state.errors.getDocumentsMasterList = initialState.errors.getDocumentsMasterList;
      })
      .addCase(personalDetailsActions.getDocumentsMasterListSuccess, (state, action) => {
        state.documentsMasterList = action.payload.response;
        state.loading.getDocumentsMasterList = false;
      })
      .addCase(personalDetailsActions.getDocumentsMasterListFailure, (state, action) => {
        state.loading.getDocumentsMasterList = false;
        state.errors.getDocumentsMasterList = action.payload.error;
      })
      .addCase(personalDetailsActions.getKycDocuments, state => {
        state.loading.getKycDocuments = true;
        state.errors.getKycDocuments = initialState.errors.getKycDocuments;
      })
      .addCase(personalDetailsActions.getKycDocumentsSuccess, (state, action) => {
        state.kycDocuments = action.payload.response;
        state.loading.getKycDocuments = false;
      })
      .addCase(personalDetailsActions.getKycDocumentsFailure, (state, action) => {
        state.loading.getKycDocuments = false;
        state.errors.getKycDocuments = action.payload.error;
      })
      .addCase(personalDetailsActions.getGuarantorPersonalDetails, state => {
        state.loading.getGuarantorPersonalDetails = true;
        state.errors.getGuarantorPersonalDetails = initialState.errors.getGuarantorPersonalDetails;
      })
      .addCase(personalDetailsActions.getGuarantorPersonalDetailsSuccess, (state, action) => {
        state.guarantorPersonalDetails = action.payload.response;
        state.loading.getGuarantorPersonalDetails = false;
      })
      .addCase(personalDetailsActions.getGuarantorPersonalDetailsFailure, (state, action) => {
        state.loading.getGuarantorPersonalDetails = false;
        state.errors.getGuarantorPersonalDetails = action.payload.error;
      })

      .addCase(personalDetailsActions.getGuarantorAddressDetails, state => {
        state.loading.getGuarantorAddressDetails = true;
        state.errors.getGuarantorAddressDetails = initialState.errors.getGuarantorAddressDetails;
      })
      .addCase(personalDetailsActions.getGuarantorAddressDetailsSuccess, (state, action) => {
        state.guarantorAddressDetails = action.payload.response;
        state.loading.getGuarantorAddressDetails = false;
      })
      .addCase(personalDetailsActions.getGuarantorAddressDetailsFailure, (state, action) => {
        state.loading.getGuarantorAddressDetails = false;
        state.errors.getGuarantorAddressDetails = action.payload.error;
      }),
});

const {resetGuarantorPersonalDetails} = personalDetailsSlice.actions;
export default personalDetailsSlice.reducer;

export {resetGuarantorPersonalDetails};
