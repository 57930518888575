import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  call,
  getContext,
  put,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import {algoActions, algoActionTypes} from "./actions";
import {
  AlgoResponse,
  ConfirmAlgoRunPayload,
  CreateAgentTripPayload,
  DeleteAgentTripPayload,
  GetAgentListPayload,
  GetAgentRouteListPayload,
  GetAgentScheduleDetailsPayload,
  GetAlgoRunReportPayload,
  GetAlgoRunStatusPayload,
  GetAvailableAgentListPayload,
  GetCustomerDetailsPayload,
  GetCustomerListPayload,
  GetTripDetailsPayload,
  SaveAgentRoutePayload,
  StartAlgoPayload,
} from "app/infra/services/api/scheduling/algo/types";
import {snackBarActions} from "../../snackbar";
import {GetLMsPayload} from "app/infra/services/api/scheduling/types";

const {
  getRunsSuccess,
  getRunsFailure,
  getAgentListSuccess,
  getAgentListFailure,
  getCustomerListSuccess,
  getCustomerListFailure,
  getAgentRouteListSuccess,
  getAgentRouteListFailure,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
  deleteAgentTripSuccess,
  deleteAgentTripFailure,
  startAlgoSuccess,
  startAlgoFailure,
  getAlgoRunStatusSuccess,
  getAlgoRunStatusFailure,
  getAgentsScheduleDetailsSuccess,
  getAgentsScheduleDetailsFailure,
  getAlgoRunReportSuccess,
  getAlgoRunReportFailure,
  confirmAlgoRunSuccess,
  confirmAlgoRunFailure,
  getTripDetailsSuccess,
  getTripDetailsFailure,
  saveAgentRouteSuccess,
  saveAgentRouteFailure,
  createAgentTripSuccess,
  createAgentTripFailure,
  getLMsSuccess,
  getLMsFailure,
  getLMAvailabilityFailure,
  getLMAvailabilitySuccess,
  getAvailableAgentListSuccess,
  getAvailableAgentListFailure,
} = algoActions;

function* getRunsFlow(action: PayloadAction<{ date: number }>) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(api.algo.getRuns, action.payload);
  if (error) {
    yield put(getRunsFailure({data, error}));
  } else {
    yield put(getRunsSuccess({data, error}));
  }
}

function* getAgentListFlow(action: PayloadAction<GetAgentListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {agentList, error} = yield call(
    api.algo.getAgentList,
    action.payload,
  );
  if (error) {
    yield put(getAgentListFailure({agentList, error}));
  } else {
    yield put(getAgentListSuccess({agentList, error}));
  }
}

function* getCustomerListFlow(action: PayloadAction<GetCustomerListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {userList, error} = yield call(
    api.algo.getCustomerList,
    action.payload,
  );
  if (error) {
    yield put(getCustomerListFailure({userList, error}));
  } else {
    yield put(getCustomerListSuccess({userList, error}));
  }
}

function* getAgentRouteListFlow(
  action: PayloadAction<GetAgentRouteListPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.getAgentRouteList,
    action.payload,
  );
  if (error) {
    yield put(getAgentRouteListFailure({response, error}));
  } else {
    yield put(getAgentRouteListSuccess({response, error}));
  }
}

function* deleteAgentTripFlow(action: PayloadAction<DeleteAgentTripPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.deleteAgentTrip,
    action.payload,
  );
  if (error) {
    yield put(deleteAgentTripFailure({response, error}));
  } else {
    yield put(
      deleteAgentTripSuccess({
        response: {
          ...response,
          ...action.payload,
        },
        error,
      }),
    );
  }
}

function* deleteAgentTripSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "Agent trip deleted successfully",
      variant: "success",
    }),
  );
}

function* startAlgoFlow(action: PayloadAction<StartAlgoPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(api.algo.startAlgo, action.payload);
  if (error) {
    yield put(startAlgoFailure({response, error}));
  } else {
    yield put(startAlgoSuccess({response, error}));
  }
}

function* getAlgoRunStatusFlow(action: PayloadAction<GetAlgoRunStatusPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.getAlgoRunStatus,
    action.payload,
  );
  if (error) {
    yield put(getAlgoRunStatusFailure({response, error}));
  } else {
    yield put(getAlgoRunStatusSuccess({response, error}));
  }
}

function* getAgentSchedulingDetailsFlow(
  action: PayloadAction<GetAgentScheduleDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {schedulingDetails, error} = yield call(
    api.algo.getAgentSchedulingDetails,
    action.payload,
  );
  if (error) {
    yield put(getAgentsScheduleDetailsFailure({error, schedulingDetails}));
  } else {
    yield put(getAgentsScheduleDetailsSuccess({error, schedulingDetails}));
  }
}

function* getAlgoRunReportFlow(action: PayloadAction<GetAlgoRunReportPayload>) {
  const api: GoldApi = yield getContext("api");

  const {algoReport, error} = yield call(
    api.algo.getAlgoRunReport,
    action.payload,
  );
  if (error) {
    yield put(getAlgoRunReportFailure({error, algoReport}));
  } else {
    yield put(getAlgoRunReportSuccess({error, algoReport}));
  }
}

function* confirmAlgoRunFlow(action: PayloadAction<ConfirmAlgoRunPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.confirmAlgoRun,
    action.payload,
  );
  if (error) {
    yield put(confirmAlgoRunFailure({error, response}));
  } else {
    yield put(confirmAlgoRunSuccess({error, response}));
  }
}

function* getCustomerDetailsFlow(
  action: PayloadAction<GetCustomerDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {customerDetails, error} = yield call(
    api.algo.getCustomerDetails,
    action.payload,
  );
  if (error) {
    yield put(getCustomerDetailsFailure({customerDetails, error}));
  } else {
    yield put(getCustomerDetailsSuccess({customerDetails, error}));
  }
}

function* getTripDetailsFlow(action: PayloadAction<GetTripDetailsPayload>) {
  const api: GoldApi = yield getContext("api");

  const {tripDetails, error} = yield call(
    api.algo.getTripDetails,
    action.payload,
  );
  if (error) {
    yield put(getTripDetailsFailure({tripDetails, error}));
  } else {
    yield put(getTripDetailsSuccess({tripDetails, error}));
  }
}

function* saveAgentRouteFlow(action: PayloadAction<SaveAgentRoutePayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.saveAgentRoute,
    action.payload,
  );
  if (error) {
    yield put(saveAgentRouteFailure({response, error}));
  } else {
    yield put(
      saveAgentRouteSuccess({
        response: {
          ...response,
          routeId: action.payload.routeId,
        },
        error,
      }),
    );
  }
}

function* createAgentTripFlow(action: PayloadAction<CreateAgentTripPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.algo.createAgentTrip,
    action.payload,
  );
  if (error) {
    yield put(createAgentTripFailure({response, error}));
  } else {
    yield put(
      createAgentTripSuccess({
        response: {
          ...response,
          ...action.payload,
        },
        error,
      }),
    );
  }
}

function* getLMsFlow(action: PayloadAction<GetLMsPayload>) {
  const api: GoldApi = yield getContext("api");

  const {loanManagers, error} = yield call(
    api.lmAvailability.getLoanManagers,
    action.payload,
  );
  if (error) {
    yield put(getLMsFailure({error}));
  } else {
    yield put(getLMsSuccess({loanManagers}));
  }
}

function* getLMsAvailabilityFlow(
  action: PayloadAction<GetAgentScheduleDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {schedulingDetails, error} = yield call(
    api.algo.getAgentSchedulingDetails,
    action.payload,
  );
  if (error) {
    yield put(getLMAvailabilityFailure({error}));
  } else {
    yield put(getLMAvailabilitySuccess({schedulingDetails, error}));
  }
}

function* getAvailableAgentListFlow(
  action: PayloadAction<GetAvailableAgentListPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {agentList, error} = yield call(
    api.algo.getAvailableAgentList,
    action.payload,
  );
  if (error) {
    yield put(getAvailableAgentListFailure({error, agentList: []}));
  } else {
    yield put(getAvailableAgentListSuccess({agentList, error}));
  }
}

function* generalErrorFlow(action: PayloadAction<AlgoResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message,
      variant: "error",
    }),
  );
}

// >>>>>>>> Algo Sagas <<<<<<<<<

export default function* algoSaga() {
  yield takeLeading(algoActionTypes.GetRuns, getRunsFlow);
  yield takeLeading(algoActionTypes.GetRunsFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetAgentList, getAgentListFlow);
  yield takeLeading(algoActionTypes.GetAgentListFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetCustomerList, getCustomerListFlow);
  yield takeLeading(algoActionTypes.GetCustomerListFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetAgentRouteList, getAgentRouteListFlow);
  yield takeLeading(algoActionTypes.GetAgentRouteListFailure, generalErrorFlow);

  yield takeEvery(algoActionTypes.DeleteAgentTrip, deleteAgentTripFlow);
  yield takeEvery(
    algoActionTypes.DeleteAgentTripSuccess,
    deleteAgentTripSuccessFlow,
  );
  yield takeLeading(algoActionTypes.DeleteAgentTripFailure, generalErrorFlow);

  yield takeEvery(algoActionTypes.CreateAgentTrip, createAgentTripFlow);
  yield takeLeading(
    algoActionTypes.CreateAgentTripSuccess,
    function* createAgentTripSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Agent trip created successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeEvery(algoActions.createAgentTripFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.StartAlgo, startAlgoFlow);
  yield takeLeading(algoActionTypes.StartAlgoFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetAlgoRunStatus, getAlgoRunStatusFlow);
  yield takeLeading(algoActionTypes.GetAlgoRunStatusFailure, generalErrorFlow);

  yield takeLeading(
    algoActionTypes.GetAgentScheduleDetails,
    getAgentSchedulingDetailsFlow,
  );
  yield takeLeading(
    algoActionTypes.GetAgentScheduleDetailsFailure,
    generalErrorFlow,
  );

  yield takeLeading(algoActionTypes.GetAlgoRunReport, getAlgoRunReportFlow);
  yield takeLeading(algoActionTypes.GetAlgoRunReportFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.ConfirmAlgoRun, confirmAlgoRunFlow);
  yield takeLeading(algoActionTypes.ConfirmAlgoRunFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetCustomerDetail, getCustomerDetailsFlow);
  yield takeLeading(algoActionTypes.GetCustomerDetailFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetTripDetails, getTripDetailsFlow);
  yield takeLeading(algoActionTypes.GetTripDetailsFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.SaveAgentRoute, saveAgentRouteFlow);
  yield takeLeading(
    algoActionTypes.SaveAgentRouteSuccess,
    function* saveAgentRouteSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Agent route saved successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLeading(algoActionTypes.SaveAgentRouteFailure, generalErrorFlow);
  yield takeLeading(algoActionTypes.SaveAgentRouteFailure, generalErrorFlow);

  yield takeLeading(algoActionTypes.GetLMs, getLMsFlow);
  yield takeLeading(algoActionTypes.GetLMAvailability, getLMsAvailabilityFlow);

  yield takeLeading(
    algoActionTypes.GetAvailableAgentList,
    getAvailableAgentListFlow,
  );
}
