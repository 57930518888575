import styled from "@emotion/styled";
import {Modal} from "@mui/material";

const ModalComponent = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ModalComponent;
