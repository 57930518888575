import {createSlice} from "@reduxjs/toolkit";
import {FetchWhitelistResponse} from "app/infra/services/api/users/types";
import {whitelistUsersAction} from "./actions";

export interface WhitelistState {
  whitelistData: FetchWhitelistResponse;
  isFetching: boolean;
  isDeleting: boolean;
  deletingError: any | null;
  isUpdating: boolean;
  updatingError: any | null;
  error: any | null;
}

// >>>>>>> WhitelistUser Initial State <<<<<<<<<
const initialState: WhitelistState = {
  whitelistData: [],
  isDeleting: false,
  isUpdating: false,
  updatingError: null,
  deletingError: null,
  isFetching: true,
  error: null,
};

export const whitelistUserSlice = createSlice({
  name: "whitelistUser",
  initialState,
  reducers: {
    resetWhitelistState: state => {
      state.whitelistData = [];
      state.isFetching = false;
      state.error = null;
    },
  },
  extraReducers: {
    [whitelistUsersAction.getWhitelistUsers.type]: (state, action) => {
      state.isFetching = true;
      state.error = null;
    },
    [whitelistUsersAction.getWhitelistUsersSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.whitelistData = action.payload.whitelistData;
    },
    [whitelistUsersAction.getWhitelistUsersError.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [whitelistUsersAction.createWhitelistUser.type]: (state, action) => {
      state.isUpdating = true;
      state.updatingError = null;
    },
    [whitelistUsersAction.createWhitelistUserSuccess.type]: (state, action) => {
      state.isUpdating = false;
      state.updatingError = null;
    },
    [whitelistUsersAction.createWhitelistUserError.type]: (state, action) => {
      state.isUpdating = false;
      state.updatingError = action.payload.error;
    },
    [whitelistUsersAction.deleteWhitelistUser.type]: (state, action) => {
      state.isDeleting = true;
      state.deletingError = null;
    },
    [whitelistUsersAction.deleteWhitelistUserSuccess.type]: (state, action) => {
      state.isDeleting = false;
      state.deletingError = null;
    },
    [whitelistUsersAction.deleteWhitelistUserError.type]: (state, action) => {
      state.isDeleting = false;
      state.deletingError = action.payload.error;
    },
  },
});

const {resetWhitelistState} = whitelistUserSlice.actions;
export default whitelistUserSlice.reducer;
export {resetWhitelistState};
