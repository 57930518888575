import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "firebase/app-check";
import "firebase/app-check";
import firebaseAppConfig from "./index";
import BugsnagNotify from "_metronic/utils/BugsnagNotify";

const getFirebaseAppCheckToken = async () => {
  let appcheckToken = null;
  try {
    const appCheck = initializeAppCheck(firebaseAppConfig(), {
      provider: new ReCaptchaV3Provider(
        process.env.REACT_APP_CAPTCHA_V3_SITE_KEY,
      ),
      isTokenAutoRefreshEnabled: true,
    });
    // eslint-disable-next-line no-console
    const appCheckTokenResponse = await getToken(appCheck, true).catch(
      (error) => {
        BugsnagNotify({error});
        return null;
      },
    );
    if (appCheckTokenResponse) {
      // eslint-disable-next-line no-console
      // console.log("appCheckTokenResponse", appCheckTokenResponse);
      appcheckToken = appCheckTokenResponse.token;
    }
  } catch (err) {
    BugsnagNotify({error: err});
    appcheckToken = null;
  }
  return appcheckToken;
};

export default getFirebaseAppCheckToken;
