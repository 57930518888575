/* eslint-disable no-console */
import React, {memo, useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import LogoutPage from "../pages/auth/Logout";
import Unathorised from "../pages/errors/Unauthorised";
import NotFound from "../pages/errors/NotFound";
import Login from "app/pages/auth/Login";
import ErrorWrapper from "app/pages/errors/ErrorWraper";
import AppLayout from "../AppLayout";
import ProtectedRoutes from "./ProtectedRoute";
import DashboardRoutes from "./Dashboard";
import AuthPage from "../pages/auth/AuthPage";
import {useAppDispatch} from "app/store/hooks";
import {layoutActions} from "app/store/layout";


const MainRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(layoutActions.getFeatureFlags({
      moduleEnumList: [
        "AUTH",
      ],
    }));
  }, []);

  return (
    <>
      <Routes>
        <Route path="/auth" element={<AuthPage />}>
          <Route path="" element={<Login />} />
        </Route>

        <Route path="/error" element={<ErrorWrapper />}>
          <Route path="404" element={<NotFound />} />
          <Route path="unauthorized" element={<Unathorised />} />
        </Route>

        <Route path="/logout" element={<LogoutPage />} />

        <Route
          path="/"
          element={
            <ProtectedRoutes>
              <AppLayout />
            </ProtectedRoutes>
          }
        >
          {DashboardRoutes()}
        </Route>

        <Route path="*" element={<ErrorWrapper />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default memo(MainRoutes);
