import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  profileContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: "#282f48",
    borderRadius: "5px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  container: {
    display: "flex",
    padding: "1rem",
    "& > h6": {
      color: "white",
    },
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#282f48",
      borderRadius: "5px",
      cursor: "pointer",
      "& > h6": {
        color: "white",
      },
    },
  },
  button: {
    marginTop: 10,
    fontSize: 12,
    marginLeft: 5,
  },
  image: {
    width: "30px",
    height: "30px",
    borderRadius: "20px",
    marginLeft: "10px",
  },
}));

export default useStyles;
