import {createSlice} from "@reduxjs/toolkit";
import {paginationSliceCreator, PaginationState} from "../factoryReducers";
import internalUserAction from "./action";

interface InternalUserState {
  internalUser: {
    isFetching: boolean;
    userList: any;
    filters: any;
    refresh: boolean;
    pagination: PaginationState;
  };
  cityList: {
    cityList: { id: number; name: string }[];
    isFetching: boolean;
  };
  allRoles: {
    allRoles: { id: number; roleName: string }[];
    isFetching: boolean;
  };
  userCities: {
    userCities: number[];
    isFetching: boolean;
    selectedCityId: number [] | string [];
    isChangedFlag: boolean;
  };
  userRoles: {
    userRoles: [];
    isFetching: boolean;
    priorityRole: string;
    submitRoles: boolean;
    deleteRoleConfirmation: {
      open: boolean;
      roleName: string;
      roleId: number;
    };
  };
  userTypes: string [];
  modal: {
    open: boolean;
    userId: number;
    parentData: any;
    type: string;
    isFetching: boolean;
  };
  loading: {
    userTypes: boolean;
  }
  error: {
    userTypes: string;
  }
}
const initialState: InternalUserState = {
  internalUser: {
    isFetching: false,
    userList: {},
    filters: {},
    refresh: false,
    pagination: {
      pageNo: 1,
      pageSize: 100,
      paginatedIndexes: [],
    },
  },
  cityList: {
    cityList: [],
    isFetching: false,
  },
  allRoles: {
    allRoles: [],
    isFetching: false,
  },
  userCities: {
    userCities: [],
    isFetching: false,
    selectedCityId: [],
    isChangedFlag: false,
  },
  userRoles: {
    userRoles: [],
    isFetching: false,
    priorityRole: "",
    submitRoles: false,
    deleteRoleConfirmation: {
      open: false,
      roleName: "",
      roleId: 0,
    },
  },
  userTypes: [],
  loading: {
    userTypes: false,
  },
  error: {
    userTypes: "",
  },
  modal: {
    open: false,
    userId: 0,
    parentData: null,
    type: "",
    isFetching: false,
  },
};

const internalUserPaginationReducer = paginationSliceCreator("internalUser");
export const internalUserSlice = createSlice({
  name: "internalUser",
  initialState,
  reducers: {
    setUserCities: (state, action) => {
      state.userCities.userCities = action.payload;
      state.userCities.isChangedFlag = true;
    },
    setSelectedCityId: (state, action) => {
      state.userCities.selectedCityId = action.payload;
    },
    changePriorityRole: (state, action) => {
      state.userRoles.priorityRole = action.payload;
      state.userRoles.submitRoles = true;
    },
    setUserRoles: (state, action) => {
      state.userRoles.userRoles = action.payload;
      state.userRoles.submitRoles = true;
    },
    setDeleteConfirmation: (state, action) => {
      state.userRoles.deleteRoleConfirmation = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    resetModal: (state) => {
      state.modal = {
        open: false,
        type: "",
        parentData: null,
        userId: 0,
        isFetching: false,
      };
      state.userRoles = {
        userRoles: [],
        isFetching: false,
        priorityRole: "",
        submitRoles: false,
        deleteRoleConfirmation: {
          open: false,
          roleId: 0,
          roleName: "",
        },
      };
      state.userCities = {
        userCities: [],
        isFetching: false,
        selectedCityId: [],
        isChangedFlag: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(internalUserAction.getAllCities, (state) => {
        state.cityList.isFetching = true;
      })
      .addCase(internalUserAction.getAllCitiesSuccess, (state, action) => {
        state.cityList.isFetching = false;
        state.cityList.cityList = action.payload.response;
      })
      .addCase(internalUserAction.getAllCitiesFailure, (state) => {
        state.cityList.isFetching = false;
      })
      .addCase(internalUserAction.getUserCities, (state) => {
        state.userCities.isFetching = true;
      })
      .addCase(internalUserAction.getUserCitiesSuccess, (state, action) => {
        state.userCities.isFetching = false;
        state.userCities.userCities = action.payload.response;
      })
      .addCase(internalUserAction.getUserCitiesFailure, (state) => {
        state.userCities.isFetching = false;
      })
      .addCase(internalUserAction.changeUserCity, (state) => {
        state.userCities.isFetching = true;
      })
      .addCase(internalUserAction.changeUserCitySuccess, (state, action) => {
        state.userCities.isFetching = false;
      })
      .addCase(internalUserAction.changeUserCityFailure, (state) => {
        state.userCities.isFetching = false;
      })
      .addCase(internalUserAction.changeUserStatus, (state) => {
        state.modal.isFetching = true;
      })
      .addCase(internalUserAction.changeUserStatusSuccess, (state, action) => {
        state.modal.isFetching = false;
        state.internalUser.refresh = !state.internalUser.refresh;
      })
      .addCase(internalUserAction.changeUserStatusFailure, (state) => {
        state.modal.isFetching = false;
      })
      .addCase(internalUserAction.updateUserRoles, (state) => {
        state.userRoles.isFetching = true;
      })
      .addCase(internalUserAction.updateUserRolesSuccess, (state, action) => {
        state.userRoles.isFetching = false;
      })
      .addCase(internalUserAction.updateUserRolesFailure, (state) => {
        state.userRoles.isFetching = false;
      })
      .addCase(internalUserAction.getAllRoles, (state) => {
        state.allRoles.isFetching = true;
      })
      .addCase(internalUserAction.getAllRolesSuccess, (state, action) => {
        state.allRoles.isFetching = false;
        state.allRoles.allRoles = action.payload.response;
      })
      .addCase(internalUserAction.getAllRolesFailure, (state) => {
        state.allRoles.isFetching = false;
      })
      .addCase(internalUserAction.getUserRoles, (state) => {
        state.userRoles.isFetching = true;
      })
      .addCase(internalUserAction.getUserRolesSuccess, (state, action) => {
        state.userRoles.isFetching = false;
        action.payload.response.lmUserRoleList.forEach((row: any) => {
          row.new = false;
        });
        state.userRoles.userRoles = action.payload.response.lmUserRoleList;
        state.userRoles.priorityRole = action.payload.response.priorityRoleId;
      })
      .addCase(internalUserAction.getUserRolesFailure, (state) => {
        state.userRoles.isFetching = false;
      })
      .addCase(internalUserAction.getInternalUserList, (state) => {
        state.internalUser.isFetching = true;
      })
      .addCase(
        internalUserAction.getInternalUserListSuccess,
        (state, action) => {
          state.internalUser.isFetching = false;
          state.internalUser.userList = [];
          action.payload.response.forEach((user: any) => {
            state.internalUser.userList[user.id] = user;
          });
        },
      )
      .addCase(internalUserAction.getInternalUserListFailure, (state) => {
        state.internalUser.isFetching = false;
      })
      .addCase(internalUserAction.getAllUserTypes, (state) => {
        state.loading.userTypes = true;
        state.error.userTypes = "";
      })
      .addCase(internalUserAction.getAllUserTypesSuccess, (state, action) => {
        state.loading.userTypes = false;
        state.error.userTypes = "";
        state.userTypes = action.payload.response;
      })
      .addCase(internalUserAction.getAllUserTypesFailure, (state, action) => {
        state.loading.userTypes = false;
        state.error.userTypes = action.payload.error;
      })
      .addMatcher(
        (action) => action.type.startsWith("internalUserPagination"),
        (state, action) => {
          state.internalUser.pagination = internalUserPaginationReducer(
            state.internalUser.pagination,
            action,
          );
        },
      );
  },
});

const {
  setUserCities,
  setSelectedCityId,
  resetModal,
  changePriorityRole,
  setUserRoles,
  setDeleteConfirmation,
  setModal,
} = internalUserSlice.actions;

export default internalUserSlice.reducer;
export {
  setUserCities,
  setSelectedCityId,
  resetModal,
  changePriorityRole,
  setUserRoles,
  setDeleteConfirmation,
  setModal,
  internalUserPaginationReducer,
};
