import {PayloadAction} from "@reduxjs/toolkit";
import {
  call,
  getContext,
  put,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {unscheduledActions, unscheduledActionTypes} from "./actions";
import {
  BypassScanningPayload,
  CloseLMJobPayload,
  CreateJobFromLeadPayload,
  createRMJobPayload,
  GetLeadDetailPayload,
  GetLeadListPayload,
  GetLoanLockersPayload,
  GetLoanServicingJobListPayload,
  GetLocationFromAddressPayload,
  PickUpLocationPayload,
  UnscheduledResponse,
  UpdateLeadPayload,
  updateRmJobPayload,
  GetLeadCreditDetailsPayload,
  GetLeadCreditReportStatusPayload,
  GetLockerByLeadIdPayload,
  GetDeliverySlotConfigPayload,
  GetDeliverySlotChangeableDatePayload,
  UpdateDeliverySlotPayload,
  RemoveAgentPayload,
} from "app/infra/services/api/scheduling/unscheduled/types";
import {GoldApi} from "app/infra/services/api";
import {refreshLSJobList, toggleDrawerOpen} from "./reducer";
import {UnscheduledState} from "./types";
import {snackBarActions} from "../../snackbar";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {
  GetNoteListPayload,
  UpdateNoteListPayload,
} from "app/infra/services/api/scheduling/openCR/types";
import {getValidTime} from "_metronic/utils/moment";

const {
  getLeadListSuccess,
  getLeadListFailure,
  createRMJobFailure,
  createRMJobSuccess,
  getLoanServicingJobListFailure,
  getLoanServicingJobListSuccess,
  getLeadDetail,
  getLeadDetailSuccess,
  getLeadDetailFailure,
  getUnqualifiedReasonsSuccess,
  getUnqualifiedReasonsFailure,
  updateLeadDetailSuccess,
  updateLeadDetailFailure,
  getLoanLockersSuccess,
  getLoanLockersFailure,
  createJobFromLeadSuccess,
  createJobFromLeadFailure,
  getSendBackReasonListSuccess,
  getSendBackReasonListFailure,
  getPickupLocationFailure,
  getPickupLocationSuccess,
  updateRmJobFailure,
  updateRmJobSuccess,
  getTimeSlotListSuccess,
  getTimeSlotListFailure,
  getNoteListFailure,
  getNoteListSuccess,
  updateNoteListFailure,
  updateNoteListSuccess,
  getLocationFromAddressSuccess,
  getLocationFromAddressFailure,
  closeLMJobSuccess,
  closeLMJobFailure,
  getBypassPacketScanningListFailure,
  getBypassPacketScanningListSuccess,
  approveBypassScanningFailure,
  approveBypassScanningSuccess,
  getLeadCreditDetailsSuccess,
  getLeadCreditDetailsFailure,
  getLeadCreditReportStatusSuccess,
  getLeadCreditReportStatusFailure,
  getLockerByLeadIdSuccess,
  getLockerByLeadIdFailure,
  getGoldDeliverySlotChangeableDateFailure,
  getGoldDeliverySlotChangeableDateSuccess,
  getGoldDeliverySlotsFailure,
  getGoldDeliverySlotsSuccess,
  updateGoldDeliverySlotFailure,
  updateGoldDeliverySlotSuccess,
  removeAgentJobSuccess,
  removeAgentJobFailure,
} = unscheduledActions;

function* generalErrorFlow(action: PayloadAction<{ error: GoldApiError }>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getLeadListFlow(action: PayloadAction<GetLeadListPayload>) {
  const api: GoldApi = yield getContext("api");

  const unscheduledState: UnscheduledState = yield call(
    api.unscheduled.getLeadList,
    action.payload,
  );
  if (unscheduledState.error) {
    yield put(getLeadListFailure(unscheduledState));
  } else {
    yield put(getLeadListSuccess(unscheduledState));
  }
}

function* createRMJobFlow(action: PayloadAction<createRMJobPayload>) {
  const api: GoldApi = yield getContext("api");
  const unscheduled: UnscheduledState = yield call(
    api.unscheduled.createRMJob,
    action.payload,
  );
  if (unscheduled.error) {
    yield put(createRMJobFailure(unscheduled));
    yield put(
      snackBarActions.open({
        message: unscheduled.error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(createRMJobSuccess());
  }
}

function* getLoanServicingFlow(
  action: PayloadAction<GetLoanServicingJobListPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const unscheduledState: UnscheduledState = yield call(
    api.unscheduled.getLoanServicingJobList,
    action.payload,
  );
  if (unscheduledState.error) {
    yield put(getLoanServicingJobListFailure(unscheduledState));
  } else {
    yield put(getLoanServicingJobListSuccess(unscheduledState));
  }
}

function* getLeadDetailFlow(action: PayloadAction<GetLeadDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const unscheduledState: UnscheduledState = yield call(
    api.unscheduled.getLeadDetail,
    action.payload,
  );
  if (unscheduledState.error) {
    yield put(getLeadDetailFailure(unscheduledState));
  } else {
    yield put(getLeadDetailSuccess(unscheduledState));
  }
}

function* getUnqualifiedReasonsFlow() {
  const api: GoldApi = yield getContext("api");

  const {reasons, error} = yield call(api.unscheduled.getUnqualifiedReasons);
  if (error) {
    yield put(getUnqualifiedReasonsFailure({reasons, error}));
  } else {
    yield put(getUnqualifiedReasonsSuccess({reasons, error}));
  }
}

function* getLoanLockersFlow(action: PayloadAction<GetLoanLockersPayload>) {
  const api: GoldApi = yield getContext("api");

  const unscheduledState: UnscheduledState = yield call(
    api.unscheduled.getLoanLockers,
    action.payload,
  );
  if (unscheduledState.error) {
    yield put(getLoanLockersFailure(unscheduledState));
  } else {
    yield put(getLoanLockersSuccess(unscheduledState));
  }
}

function* updateLeadDetailFlow(
  action: PayloadAction<{
    leadId: number;
    type: string;
    payload: UpdateLeadPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, data} = yield call(
    api.unscheduled.updateLeadDetails,
    action.payload.leadId,
    action.payload.type,
    action.payload.payload,
  );
  if (error) {
    yield put(updateLeadDetailFailure({error, data}));
  } else {
    yield put(getLeadDetail({id: action.payload.leadId}));
    let payload = {...data, keepLeadInList: true};
    if (action.payload.type === "address") {
      payload = {address: data, keepLeadInList: true};
    } else if (
      action.payload.type === "stage" &&
      ["SCHEDULER_UNQUALIFIED", "SCHEDULER_SEND_TO_IS"].includes(
        action.payload.payload.leadStage,
      )
    ) {
      payload = {...data, keepLeadInList: false};
      yield put(toggleDrawerOpen(false));
    }
    yield put(updateLeadDetailSuccess({data: payload, error}));
  }
}

function* updateLeadDetailFailureFlow(
  action: PayloadAction<{
    error: GoldApiError | null;
    data: Record<string, any> | null;
  }>,
) {
  yield put(
    snackBarActions.open({
      message:
        action.payload?.error?.message || "Lead details could not be updated",
      variant: "error",
    }),
  );
}

function* createJobFromLeadFlow(
  action: PayloadAction<CreateJobFromLeadPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {error, data} = yield call(
    api.unscheduled.createJobFromLead,
    action.payload,
  );
  if (error) {
    yield put(createJobFromLeadFailure({data, error}));
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(createJobFromLeadSuccess({data, error}));
  }
}

function* createJobFromLeadFailureFlow(
  action: PayloadAction<{
    error: GoldApiError | null;
    data: Record<string, any> | null;
  }>,
) {
  yield put(
    snackBarActions.open({
      message: action.payload?.error?.message || "Job could not be created",
      variant: "error",
    }),
  );
}

function* getPickupLocationFlow(action: PayloadAction<PickUpLocationPayload>) {
  const api: GoldApi = yield getContext("api");
  const response: UnscheduledState = yield call(
    api.unscheduled.getPickupLocation,
    action.payload,
  );
  if (response.error) {
    yield put(getPickupLocationFailure(response));
  } else {
    yield put(getPickupLocationSuccess(response));
  }
}

function* getSendBackReasonListFlow() {
  const api: GoldApi = yield getContext("api");
  const {error, reasonList} = yield call(
    api.unscheduled.getSendBackReasonList,
  );

  if (error) {
    yield put(getSendBackReasonListFailure({error, reasonList: []}));
  } else {
    yield put(getSendBackReasonListSuccess({error: null, reasonList}));
  }
}

function* updateRmJobFlow(action: PayloadAction<updateRmJobPayload>) {
  const api: GoldApi = yield getContext("api");
  const {error, data} = yield call(
    api.unscheduled.updateRmJob,
    action.payload,
  );
  if (error) {
    yield put(updateRmJobFailure({error, data: null}));
    yield put(
      snackBarActions.open({
        message: error?.message || "Job could not be updated.",
        variant: "error",
      }),
    );
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(updateRmJobSuccess({error: null, data}));
    yield put(
      snackBarActions.open({
        message: "Job successfully updated.",
        variant: "success",
      }),
    );
  }
}

function* deleteRmJobFlow(action: PayloadAction<PickUpLocationPayload>) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.unscheduled.deleteRmJob, action.payload);
  if (error) {
    yield put(updateRmJobFailure({error}));
    yield put(
      snackBarActions.open({
        message: error?.message || "Job could not be deleted.",
        variant: "error",
      }),
    );
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(updateRmJobSuccess({error: null}));
    yield put(
      snackBarActions.open({
        message: "Job successfully Deleted.",
        variant: "success",
      }),
    );
  }
}

function* getTimeSlotListFlow() {
  const api: GoldApi = yield getContext("api");
  const {error, response} = yield call(api.unscheduled.getTimeSlotList);
  if (error) {
    yield put(getTimeSlotListFailure());
    yield put(
      snackBarActions.open({
        message: error?.message || "Couldn't fetch time slots.",
        variant: "error",
      }),
    );
  } else {
    yield put(getTimeSlotListSuccess({response, error: null}));
  }
}

function* getNoteListFlow(action: PayloadAction<GetNoteListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {noteList, error} = yield call(
    api.unscheduled.getNoteList,
    action.payload,
  );
  if (error) {
    yield put(getNoteListFailure({noteList, error}));
  } else {
    yield put(getNoteListSuccess({noteList, error}));
  }
}

function* updateNotesFlow(action: PayloadAction<UpdateNoteListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {note, error} = yield call(
    api.unscheduled.updateNotes,
    action.payload,
  );
  if (error) {
    yield put(updateNoteListFailure({note, error}));
  } else {
    yield put(updateNoteListSuccess({note, error}));
  }
}

function* getLocationFromAddressFlow(
  action: PayloadAction<GetLocationFromAddressPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {addressDetails, error} = yield call(
    api.unscheduled.getLocationFromAddress,
    action.payload,
  );
  if (error) {
    yield put(
      getLocationFromAddressFailure({
        type: action.payload.type,
        addressDetails: addressDetails,
        error: error,
      }),
    );
  } else {
    yield put(
      getLocationFromAddressSuccess({
        type: action.payload.type,
        addressDetails: addressDetails,
        error: null,
      }),
    );
  }
}

function* closeLMJobFlow(action: PayloadAction<CloseLMJobPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.unscheduled.closeLMJob,
    action.payload,
  );
  if (error) {
    yield put(closeLMJobFailure({response: null, error}));
  } else {
    yield put(closeLMJobSuccess({response, error: null}));
  }
}

function* byPassPacketScanningListSaga(
  action: PayloadAction<BypassScanningPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.getBypassPacketList,
    action.payload,
  );
  if (error) {
    yield put(getBypassPacketScanningListFailure({error}));
    // yield put(resetRowAction());
  } else if (response) {
    yield put(getBypassPacketScanningListSuccess({response}));
  }
}

function* approveByPassScanningFlow(
  action: PayloadAction<BypassScanningPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.approveBypassPacketScanning,
    action.payload,
  );
  if (error) {
    yield put(approveBypassScanningFailure({error}));
  } else if (response) {
    yield put(approveBypassScanningSuccess({response}));
    yield put(
      snackBarActions.open({message: response.message, variant: "success"}),
    );
    yield put(refreshLSJobList());
  }
}

function* getLeadCreditDetailsFlow(
  action: PayloadAction<GetLeadCreditDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.unscheduled.getLeadCreditDetails,
    action.payload,
  );
  if (error) {
    yield put(getLeadCreditDetailsFailure({response: null, error}));
  } else {
    yield put(getLeadCreditDetailsSuccess({response, error: null}));
  }
}

function* getLeadCreditReportStatusFlow(
  action: PayloadAction<GetLeadCreditReportStatusPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.unscheduled.getLeadCreditReportStatus,
    action.payload,
  );
  if (error) {
    yield put(getLeadCreditReportStatusFailure({response: null, error}));
  } else {
    yield put(getLeadCreditReportStatusSuccess({response, error: null}));
  }
}

function* getLockerByLeadIdFlow(
  action: PayloadAction<GetLockerByLeadIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.getLockerByLeadId,
    action.payload,
  );
  if (error) {
    yield put(getLockerByLeadIdFailure({response: null, error}));
  } else {
    yield put(getLockerByLeadIdSuccess({response, error: null}));
  }
}

function* getDeliverySlotsFlow(
  action: PayloadAction<GetDeliverySlotConfigPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.getDeliverySlots,
    action.payload,
  );
  if (error) {
    yield put(getGoldDeliverySlotsFailure({response: null, error}));
  } else {
    yield put(getGoldDeliverySlotsSuccess({response, error: null}));
  }
}

function* getDeliverySlotChangeableDateFlow(
  action: PayloadAction<GetDeliverySlotChangeableDatePayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.getDeliverySlotChangeableDate,
    action.payload,
  );
  if (error) {
    yield put(
      getGoldDeliverySlotChangeableDateFailure({response: null, error}),
    );
  } else {
    yield put(
      getGoldDeliverySlotChangeableDateSuccess({response, error: null}),
    );
  }
}

function* updateDeliverySlotFlow(
  action: PayloadAction<UpdateDeliverySlotPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.unscheduled.updateDeliverySlot,
    action.payload,
  );
  if (error) {
    yield put(updateGoldDeliverySlotFailure({response: null, error}));
  } else {
    yield put(updateGoldDeliverySlotSuccess({response, error: null}));
    if (
      getValidTime(action.payload.selectedDate)
        .startOf("date")
        .valueOf() >=
      getValidTime(action.payload.effectiveFrom)
        .startOf("date")
        .valueOf()
    ) {
      yield put(
        unscheduledActions.getGoldDeliverySlots({
          deliveryDate: action.payload.effectiveFrom,
        }),
      );
    }
    yield put(
      snackBarActions.open({
        message: "Slots Updated Successfully",
        variant: "success",
      }),
    );
  }
}

function* removeAgentFlow(action: PayloadAction<RemoveAgentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {error, data} = yield call(
    api.unscheduled.removeAgent,
    action.payload,
  );
  if (error) {
    yield put(removeAgentJobFailure({error}));
    yield put(
      snackBarActions.open({
        message: error?.message || "Agent could not be removed.",
        variant: "error",
      }),
    );
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(removeAgentJobSuccess({error: null}));
    yield put(
      snackBarActions.open({
        message: "Agent removed successfully.",
        variant: "success",
      }),
    );
  }
}

// >>>>>>>> Unscheduled Sagas <<<<<<<<<

export default function* unscheduledSaga() {
  yield takeLeading(unscheduledActionTypes.GetLeadList, getLeadListFlow);
  yield takeLeading(
    unscheduledActionTypes.GetLoanServicingJobList,
    getLoanServicingFlow,
  );
  yield takeLatest(unscheduledActionTypes.DeleteRmJob, deleteRmJobFlow);
  yield takeLatest(
    unscheduledActionTypes.GetPickUpLocation,
    getPickupLocationFlow,
  );

  yield takeLatest(unscheduledActionTypes.GetLeadDetail, getLeadDetailFlow);

  yield takeLatest(
    unscheduledActionTypes.GetUnqualifiedReasons,
    getUnqualifiedReasonsFlow,
  );

  yield takeLatest(unscheduledActionTypes.GetLoanLockers, getLoanLockersFlow);

  yield takeLatest(
    unscheduledActionTypes.UpdateLeadDetail,
    updateLeadDetailFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.UpdateLeadDetailSuccess,
    function* updateLeadDetailSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Lead details updated successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    unscheduledActionTypes.UpdateLeadDetailFailure,
    updateLeadDetailFailureFlow,
  );

  yield takeLatest(
    unscheduledActionTypes.CreateJobFromLead,
    createJobFromLeadFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.CreateJobFromLeadSuccess,
    function* createJobFromLeadSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Job created successfully",
          variant: "success",
        }),
      );
    },
  );

  yield takeLatest(
    unscheduledActionTypes.CreateJobFromLeadFailure,
    createJobFromLeadFailureFlow,
  );
  yield takeLatest(unscheduledActionTypes.CreateRMJob, createRMJobFlow);
  yield takeLatest(
    unscheduledActionTypes.CreateJobFromLeadFailure,
    createJobFromLeadFailureFlow,
  );

  yield takeLeading(
    unscheduledActionTypes.GetSendBackReasonList,
    getSendBackReasonListFlow,
  );
  yield takeLatest(unscheduledActionTypes.UpdateRmJob, updateRmJobFlow);
  yield takeLatest(unscheduledActionTypes.GetTimeSlotList, getTimeSlotListFlow);
  yield takeLatest(unscheduledActionTypes.GetNoteList, getNoteListFlow);
  yield takeLatest(unscheduledActionTypes.UpdateNoteList, updateNotesFlow);

  yield takeLatest(
    unscheduledActionTypes.getLocationFromAddress,
    getLocationFromAddressFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.getLocationFromAddressFailure,
    function* getLocationFromAddressFailureFlow(
      action: PayloadAction<UnscheduledResponse>,
    ) {
      yield put(
        snackBarActions.open({
          message:
            action.payload?.error?.message ||
            "Address could not be found, Please add more details and try again!",
          variant: "error",
        }),
      );
    },
  );
  yield takeLatest(
    unscheduledActionTypes.GetBypassPacketScanningList,
    byPassPacketScanningListSaga,
  );
  yield takeLatest(unscheduledActionTypes.CloseLMJob, closeLMJobFlow);
  yield takeLatest(
    unscheduledActionTypes.GetBypassPacketScanningListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    unscheduledActionTypes.ApproveBypassScanning,
    approveByPassScanningFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.ApproveBypassScanningFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLeadCreditDetails,
    getLeadCreditDetailsFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLeadCreditDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLeadCreditReportStatus,
    getLeadCreditReportStatusFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLeadCreditReportStatusFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLockerByLeadId,
    getLockerByLeadIdFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetLockerByLeadIdFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetGoldDeliverySlots,
    getDeliverySlotsFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.GetGoldDeliverySlotChangeableDate,
    getDeliverySlotChangeableDateFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.UpdateGoldDeliverySlot,
    updateDeliverySlotFlow,
  );
  yield takeLatest(
    unscheduledActionTypes.RemoveAgentJob,
    removeAgentFlow,
  );
}
