import React, {useEffect} from "react";
import {RootState} from "app/store/store";
import {Grid, Box} from "@mui/material";
import {IGButton, IGDialog, IGLoading} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {
  resetBtScoreCardDetails,
  setSelectedBtScoreCardMilestone,
  toggleModals,
} from "app/store/activeLoans/activeLoans.reducer";
import BTScoreCardMilestones from "./BTScoreCardMilestones";
import BTScoreCardCustomerDetails from "./BTScoreCardCustomerDetails";
import BtScoreCardDisclaimer from "./BTScoreCardDiscalaimer";
import BtScoreSection from "./BTScoreSection";
import BTScoreCardSelectedMilestone from "./BTScoreCardSelectedMilestone";
import ActionHistoryDialog from "../Dialogs/ActionHistoryDialog";

const BTScoreCard = () => {
  const dispatch = useAppDispatch();

  const selectedCr = useAppSelector(activeLoansSelectors.getSelectedCR);
  const {id: crId} = selectedCr ?? {};
  const {
    btScoreCard: isBtScoreCardModalOpen,
    actionHistory: isActionHistoryModalOpen,
  } = useAppSelector(activeLoansSelectors.getOpenModals);
  const selectedMilestone = useAppSelector(activeLoansSelectors.getSelectedBtScoreCardMilestone);
  const {ruleMetaDataJson} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreCardConfigBySelectedMilestone(
        state,
        Number(crId),
        selectedMilestone,
      ),
    ) ?? {};

  const {headerDisplayName} = ruleMetaDataJson ?? {};

  const {
    getBtScoreCardDetails: isBtScoreCardDetailsLoading,
    getBtScoreCardCustomerDetails: isBtScoreCardCustomerDetailsLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);

  const isMilestoneCheckedByCm = useAppSelector(
    activeLoansSelectors.getBtScoreCardMilestoneCheckedByCm,
  );

  const isLoading = isBtScoreCardDetailsLoading || isBtScoreCardCustomerDetailsLoading;

  const initializeDataFetch = () => {
    if (crId) {
      dispatch(
        activeLoansActions.getBtScoreCardDetails({
          crId,
        }),
      );
      dispatch(
        activeLoansActions.getBtScoreCardCustomerDetails({
          crId,
        }),
      );
    }
  };

  useEffect(() => {
    if (crId) {
      initializeDataFetch();
    }
    return () => {
      dispatch(resetBtScoreCardDetails());
      dispatch(setSelectedBtScoreCardMilestone("GUARANTOR_RULE_V1"));
    };
  }, []);

  return (
    <IGDialog
      fullScreen
      hideFooter
      title={`BT Score Card / ${headerDisplayName}`}
      titleProps={{
        fontWeight: 400,
        fontSize: "1.2rem",
      }}
      open={isBtScoreCardModalOpen}
      onClose={() =>
        dispatch(
          toggleModals({
            type: "btScoreCard",
            value: false,
          }),
        )
      }
    >
      {isLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end" my={1}>
            <IGButton
              variant="outlined"
              onClick={() =>
                dispatch(
                  toggleModals({
                    type: "actionHistory",
                    value: true,
                  }),
                )
              }
            >
              Action History
            </IGButton>
          </Box>
          <Grid container overflow="hidden">
            <Grid item xs={3}>
              <BTScoreCardCustomerDetails crId={Number(crId)} />
              <BTScoreCardMilestones crId={Number(crId)} />
            </Grid>

            <Grid
              item
              position="relative"
              xs={6}
              sx={{
                overflow: "scroll",
                scrollbarWidth: "thin",
              }}
              maxHeight="90vh"
            >
              <BTScoreCardSelectedMilestone crId={Number(crId)} />
              {!isMilestoneCheckedByCm[selectedMilestone]?.isDisclaimerChecked && (
                <BtScoreCardDisclaimer />
              )}
            </Grid>

            <Grid item xs={3}>
              <BtScoreSection crId={Number(crId)} />
            </Grid>
          </Grid>
        </>
      )}
      {isActionHistoryModalOpen && <ActionHistoryDialog crId={Number(crId)} />}
    </IGDialog>
  );
};

export default BTScoreCard;
