import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {UserIdPayload} from "app/infra/services/api/internalUser/types";
import {
  AllowPaymentPayload,
  CancelRequestPayload,
  UpdateDeliverSlotPayload,
} from "app/infra/services/api/serviceDesk/closure/types";
import {
  CheckClosureAmountPayload,
  RequestIdPayload,
} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {snackBarActions} from "app/store/snackbar";
import {GenericResponse} from "app/typings/api/goldApi.types";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {closeAllModals, reloadList, resetRowAction, toggleModals} from "../reducer";
import {closureActions, closureActionTypes} from "./action";
import {ClosureAmountData} from "./types";
import complaintActions from "../../complaints/actions";

function* checkClosureAmountSaga(
  action: PayloadAction<CheckClosureAmountPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getClosureAmount,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getClosureAmountFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(
      closureActions.getClosureAmountSuccess({
        response: response as ClosureAmountData,
      }),
    );
    if (action.payload.sendSms) {
      yield put(
        snackBarActions.open({
          message: "Loan closure amount has been shared with the costumer.",
          variant: "success",
        }),
      );
    }
  }
}

function* paymentDetailSaga(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getPaymentDetail,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getPaymentDetailFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(closureActions.getPaymentDetailSuccess({response}));
  }
}

function* bankDetailsSaga(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getBankDetails,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getBankDetailsFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(closureActions.getBankDetailsSuccess({response}));
  }
}
function* upiDetailsSaga(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getUPIDetails,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getUPIDetailsFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(closureActions.getUPIDetailsSuccess({response}));
  }
}

function* allowPaymentSaga(action: PayloadAction<AllowPaymentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.allowClosurePayment,
    action.payload,
  );
  if (error) {
    yield put(closureActions.allowPaymentFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(closureActions.allowPaymentSuccess());
    yield put(
      snackBarActions.open({
        message: "In-app closure payment has been allowed.",
        variant: "success",
      }),
    );
    yield put(resetRowAction());
    yield put(reloadList());
    yield put(closeAllModals());
  }
}

function* cancelRequestSaga(action: PayloadAction<CancelRequestPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.cancelRequest,
    action.payload,
  );
  if (error) {
    yield put(closureActions.cancelRequestFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
    yield put(resetRowAction());
  } else if (response) {
    yield put(closureActions.cancelRequestSuccess());
    yield put(toggleModals({
      type: "modifyRequest",
      value: false,
    }));
    yield put(
      snackBarActions.open({
        message: response.message,
        variant: "success",
      }),
    );
    yield put(complaintActions.getRequestStatusCount());
    yield put(resetRowAction());
    yield put(reloadList());
    yield put(toggleModals({type: "modifyRequest", value: false}));
  }
}

function* getAvailableDeliverySlotsFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getAvailableDeliverySlots,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getAvailableDeliverySlotsFailure({error}));
  } else if (response) {
    yield put(closureActions.getAvailableDeliverySlotsSuccess({response}));
  }
}

function* updateDeliverySlotFlow(
  action: PayloadAction<UpdateDeliverSlotPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.updateDeliverySlot,
    action.payload,
  );
  if (error) {
    yield put(closureActions.updateDeliverySlotFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(closureActions.updateDeliverySlotSuccess({
      response,
    }));
    yield put(
      snackBarActions.open({
        message: "Delivery slot updated successfully.",
        variant: "success",
      }),
    );
    yield put(closeAllModals());
  }
}

function* getDeliverySlotDetailFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.closure.getDeliverySlotDetail,
    action.payload,
  );
  if (error) {
    yield put(closureActions.getDeliverySlotDetailFailure({error}));
  } else if (response) {
    yield put(
      closureActions.getDeliverySlotDetailSuccess({
        response,
        requestId: action.payload.requestId,
      }),
    );
  }
}


export default function* closureSaga() {
  yield takeLatest(closureActionTypes.GetClosureAmount, checkClosureAmountSaga);
  yield takeLatest(closureActionTypes.GetPaymentDetail, paymentDetailSaga);
  yield takeLatest(closureActionTypes.GetBankDetails, bankDetailsSaga);
  yield takeLatest(closureActionTypes.GetUPIDetails, upiDetailsSaga);
  yield takeLatest(closureActionTypes.AllowPayment, allowPaymentSaga);
  yield takeLatest(closureActionTypes.CancelRequest, cancelRequestSaga);
  yield takeLatest(
    closureActionTypes.GetAvailableDeliverySlots,
    getAvailableDeliverySlotsFlow,
  );
  yield takeLatest(
    closureActionTypes.UpdateDeliverySlot,
    updateDeliverySlotFlow,
  );
  yield takeLatest(
    closureActionTypes.GetDeliverySlotDetail,
    getDeliverySlotDetailFlow,
  );
}
