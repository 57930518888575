import React from "react";

const GreenTick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0L48 0 48 48 0 48z"
            transform="translate(-296 -104) translate(296 104)"
          />
          <g fill="#00B3A6">
            <path
              d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0zm0 3c9.407 0 17 7.593 17 17s-7.593 17-17 17S3 29.407 3 20 10.593 3 20 3zm7.47 10.986c-.389.012-.759.174-1.03.453l-8.94 8.94-3.94-3.94c-.376-.391-.934-.55-1.46-.412-.526.137-.936.547-1.073 1.073-.137.526.02 1.084.412 1.46l5 5c.586.586 1.536.586 2.122 0l10-10c.442-.43.575-1.09.334-1.659-.24-.57-.806-.932-1.424-.915z"
              transform="translate(-296 -104) translate(296 104) translate(4 4)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GreenTick;
