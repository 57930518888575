import React, {useState, useEffect} from "react";
import RowContainer from "../styles/RowContainer";
import SubTitle from "../styles/SubTitle";
import Title from "../styles/Title";
import MiniTitle from "../styles/MiniTitle";
import getTakeoverDetail from "api/scheduling/getTakeoverDetail";
import Loading from "app/components/Loading";

const OccupationDetails = ({selectedPayload}) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });
  const [payload, setPayload] = useState({});

  useEffect(() => {
    (async () => {
      const response = await getTakeoverDetail({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
      });

      if (response.statusCode === 200) {
        setPayload(response.payload);
        setState(() => ({
          loading: false,
          error: false,
          errorMessage: "",
        }));
      } else {
        setState(() => ({
          loading: false,
          error: true,
          errorMessage: response.error || "Something went wrong",
        }));
      }
    })();
  }, []);

  const details = [
    {
      key: "Customer Occupation",
      value: payload?.customerOccupation,
    },
    {
      key: "Customer Work Experience",
      value: payload?.customerWorkExperience,
    },
    {
      key: "Customer ITR Filling Status",
      value: payload.customerITRFillingStatus,
    },
  ];

  if (state.loading) {
    return <Loading height={"20vh"} />;
  }

  return (
    <div>
      <Title>
        Please verify all details and mark checkbox to proceed to next step or
        send back and ask LM to fill correctly
      </Title>

      <RowContainer>
        {details.map((e) => {
          return (
            <div key={e.key}>
              <SubTitle>{e.key}</SubTitle>
              <MiniTitle>{e.value}</MiniTitle>
            </div>
          );
        })}
      </RowContainer>
    </div>
  );
};

export default OccupationDetails;
