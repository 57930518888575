import {createAction} from "@reduxjs/toolkit";
import {
  OccupationDetailsResponse,
  GetOccupationDetailsPayload,
  GetWorkSectorDetailsPayload,
  PostLoanRequirementReasonPayload,
} from "./occupationDetails.types";
import {
  OccupationAndOtherDetailsForm,
  WorkSectorInterface,
} from "app/models/loanRenewalRebook/occupationDetails.model";

const occupationDetailsActionTypes = {
  GetOccupationDetails: "[Occupation Details] Get Occupation Details",
  GetOccupationDetailsSuccess:
    "[Occupation Details] Get Occupation Details Success",
  GetOccupationDetailsFailure:
    "[Occupation Details] Get Occupation Details Failure",
  UpdateOccupationDetails: "[Occupation Details] Update Occupation Details",
  UpdateOccupationDetailsSuccess:
    "[Occupation Details] Update Occupation Details Success",
  UpdateOccupationDetailsFailure:
    "[Occupation Details] Update Occupation Details Failure",
  GetWorkSectorDetails: "[Occupation Details] Get Work Sector Details",
  GetWorkSectorDetailsSuccess:
    "[Occupation Details] Get Work Sector Details Success",
  GetWorkSectorDetailsFailure:
    "[Occupation Details] Get Work Sector Details Failure",
  PostLoanPurposeReason: "[Occupation Details] Post Loan Purpose Reason",
  PostLoanPurposeReasonSuccess:
    "[Occupation Details] Post Loan Purpose Reason Success",
  PostLoanPurposeReasonFailure:
    "[Occupation Details] Post Loan Purpose Reason Failure",
};

const occupationDetailsActions = {
  getOccupationDetails: createAction<GetOccupationDetailsPayload>(
    occupationDetailsActionTypes.GetOccupationDetails,
  ),
  getOccupationDetailsSuccess: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.GetOccupationDetailsSuccess,
  ),
  getOccupationDetailsFailure: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.GetOccupationDetailsFailure,
  ),
  updateOccupationDetails: createAction<{
    crId: number;
    crJobId: number;
    payload: OccupationAndOtherDetailsForm;
    loanRequirementReason: string;
  }>(occupationDetailsActionTypes.UpdateOccupationDetails),
  updateOccupationDetailsSuccess: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.UpdateOccupationDetailsSuccess,
  ),
  updateOccupationDetailsFailure: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.UpdateOccupationDetailsFailure,
  ),

  getWorkSectorDetails: createAction<GetWorkSectorDetailsPayload>(
    occupationDetailsActionTypes.GetWorkSectorDetails,
  ),
  getWorkSectorDetailsSuccess: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.GetWorkSectorDetailsSuccess,
  ),
  getWorkSectorDetailsFailure: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.GetWorkSectorDetailsFailure,
  ),

  postLoanPurposeReason: createAction<PostLoanRequirementReasonPayload>(
    occupationDetailsActionTypes.PostLoanPurposeReason,
  ),

  postLoanPurposeReasonSuccess: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.PostLoanPurposeReasonSuccess,
  ),
  postLoanPurposeReasonFailure: createAction<OccupationDetailsResponse>(
    occupationDetailsActionTypes.PostLoanPurposeReasonFailure,
  ),
};

export default occupationDetailsActions;

export {occupationDetailsActionTypes};
