import React, {useState, useEffect, useRef} from "react";

import TakeOverDetails from "./TakeOverDetails";
import getCustomerBankDetails from "../../../../../../../api/business/getCustomerBankDetails";
import {CircularProgress} from "@mui/material";

const TakeOverBankDetails = ({selectedPayload, otherRequestTimer}) => {
  const [states, setStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const ref = useRef(0);

  useEffect(() => {
    (async () => {
      if (ref.current === 0) {
        ref.current = 1;
        const response = await getCustomerBankDetails({
          accessToken: localStorage.getItem("authToken"),
          bankDetailType: "TAKEOVER_BANK_DETAIL",
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          setStates({
            loading: false,
            error: false,
            payload: response.payload,
            errorMessage: "",
          });
        } else {
          setStates({
            loading: false,
            error: true,
            payload: null,
            errorMessage: response.error,
          });
        }
      }
    })();
  }, [selectedPayload.id, states]);

  if (states.error) {
    return <div>{states.errorMessage}</div>;
  }

  if (states.loading || states.payload === null) {
    return <CircularProgress />;
  }

  return (
    <TakeOverDetails
      bankDetails={states.payload}
      selectedPayload={selectedPayload}
      otherRequestTimer={otherRequestTimer}
    />
  );
};

export default TakeOverBankDetails;
