import {goldApiService} from "../../../plugins/axios";
import {CityApiInterface, CityApiResources} from "app/store/city/city.types";

const CITY_ENDPOINTS: CityApiResources = {
  GET_CITY_LIST: {
    URL: "/lead/partner/role-city-list",
    METHOD: "GET",
  },
};

const cityApi: CityApiInterface = {
  getCityList: async () => {
    return await goldApiService({
      resource: CITY_ENDPOINTS.GET_CITY_LIST,
    });
  },
};

export {CITY_ENDPOINTS};
export default cityApi;