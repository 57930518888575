import {createAction} from "@reduxjs/toolkit";
import {CityResponse} from "./city.types";

const cityActionTypes = {
  GetCityList: "[City] Get City List",
  GetCityListSuccess: "[City] Get City List Success",
  GetCityListFailure: "[City] Get City List Failure",
};

const cityActions = {
  getCityList: createAction(
    cityActionTypes.GetCityList,
  ),
  getCityListSuccess: createAction<CityResponse>(
    cityActionTypes.GetCityListSuccess,
  ),
  getCityListFailure: createAction<CityResponse>(
    cityActionTypes.GetCityListFailure,
  ),
};

export default cityActions;

export {
  cityActionTypes,
};