import {createAction} from "@reduxjs/toolkit";
import {
  ChangePasswordPayload,
  GoogleLoginPayload,
  LoginPayload,
} from "app/infra/services/api/auth/types";

// >>>>>>> Auth ActionTypes <<<<<<<<<
export const actionTypes = {
  Login: "[Auth] Login",
  LoginSuccess: "[Auth] Login Success",
  LoginFailure: "[Auth] Login Failure",
  Logout: "[Auth] Logout",
  LogoutSuccess: "[Auth] Logout Success",
  LogoutFailure: "[Auth] Logout Failure",
  Register: "[Auth] Register",
  RegisterSuccess: "[Auth] Register Success",
  RegisterFailure: "[Auth] Register Failure",
  RequestUser: "[Auth] Request User",
  RequestUserSuccess: "[Auth] Request User Success",
  RequestUserFailure: "[Auth] Request User Failure",
  RenewAccessToken: "[Auth] Refresh Token",
  RenewAccessTokenSuccess: "[Auth] Refresh Token Success",
  RenewAccessTokenFailure: "[Auth] Refresh Token Failure",
  ChangePassword: "[Auth] Change Password",
  ChangePasswordSuccess: "[Auth] Change Password Success",
  ChangePasswordFailure: "[Auth] Change Password Failure",
  PostGoogleLogin: "[Auth] Post Google Login",
  SendOtp: "[Auth] Send OTP",
  SendOtpSuccess: "[Auth] Send OTP Success",
  SendOtpFailure: "[Auth] Send OTP Failure",
  VerifyOtp: "[Auth] Verify OTP",
  VerifyOtpSuccess: "[Auth] Verify OTP Success",
  VerifyOtpFailure: "[Auth] Verify OTP Failure",
};

// >>>>>>> Action Payload Interfaces <<<<<<<<<
interface RenewAccessTokenSuccessPayload {
  authToken: string;
}

interface RenewAccessTokenFailurePayload {
  error: string;
}

export interface VerifyOtpPayload {
  email: string;
  otp: number;
  otpId: number;
}

// >>>>>>> Auth Actions <<<<<<<<<
// Created using createAction() to be type safe
export const actions = {
  login: createAction<LoginPayload>(actionTypes.Login),
  loginSuccess: createAction<any>(actionTypes.LoginSuccess),
  loginFailure: createAction<any>(actionTypes.LoginFailure),
  logout: createAction(actionTypes.Logout),
  logoutSuccess: createAction<any>(actionTypes.LogoutSuccess),
  logoutFailure: createAction<any>(actionTypes.LogoutFailure),
  register: createAction(actionTypes.Register),
  registerSuccess: createAction(actionTypes.RegisterSuccess),
  registerFailure: createAction(actionTypes.RegisterFailure),
  requestUser: createAction(actionTypes.RequestUser),
  requestUserSuccess: createAction(actionTypes.RequestUserSuccess),
  requestUserFailure: createAction(actionTypes.RequestUserFailure),
  renewAccessToken: createAction<string>(actionTypes.RenewAccessToken),
  renewAccessTokenSuccess: createAction<RenewAccessTokenSuccessPayload>(
    actionTypes.RenewAccessTokenSuccess,
  ),
  renewAccessTokenFailure: createAction<RenewAccessTokenFailurePayload>(
    actionTypes.RenewAccessTokenFailure,
  ),
  changePassword: createAction<ChangePasswordPayload>(
    actionTypes.ChangePassword,
  ),
  changePasswordSuccess: createAction<any>(
    actionTypes.ChangePasswordSuccess,
  ),
  changePasswordFailure: createAction<any>(
    actionTypes.ChangePasswordFailure,
  ),
  postGoogleLogin: createAction<GoogleLoginPayload>(
    actionTypes.PostGoogleLogin,
  ),
  sendOtp: createAction<LoginPayload>(actionTypes.SendOtp),
  sendOtpSuccess: createAction<any>(actionTypes.SendOtpSuccess),
  sendOtpFailure: createAction<any>(actionTypes.SendOtpFailure),
  verifyOtp: createAction<VerifyOtpPayload>(actionTypes.VerifyOtp),
  verifyOtpSuccess: createAction<any>(actionTypes.VerifyOtpSuccess),
  verifyOtpFailure: createAction<any>(actionTypes.VerifyOtpFailure),
};
