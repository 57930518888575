import React, {useRef, useState, useCallback, useEffect} from "react";
import styles from "../styles";
import {CircularProgress} from "@mui/material";
import getGoldDropLocation from "../../../../../../../api/business/getGoldDropLocation";

const ReachedVault = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      goldDropLocation: null,
    },
    errorMessage: "",
  });

  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    try {
      if (counter.current === 0) {
        const [response] = await Promise.all([
          getGoldDropLocation({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
          }),
        ]);

        if (response.statusCode === 200) {
          setStates({
            error: false,
            errorMessage: "",
            loading: false,
            payload: {
              goldDropLocation: response.payload,
            },
          });
        } else {
          setStates({
            error: true,
            errorMessage: response?.error?.toString(),
            loading: false,
            payload: {
              goldDropLocation: null,
            },
          });
        }
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: {
          goldDropLocation: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getDetails, otherRequestTimer]);

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  if (states.loading) {
    return <CircularProgress />;
  }

  const list = [
    {
      text: "VAULT NAME",
      subText: states.payload.goldDropLocation.name,
    },
    {
      text: "VAULT ADDRESS",
      subText: states.payload.goldDropLocation.address,
    },
  ];

  return (
    <>
      {list.map((text) => (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>{text.text}</div>

            <div className={classes.subText}>{text.subText}</div>
          </div>
        </>
      ))}
    </>
  );
};

export default ReachedVault;
