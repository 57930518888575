import {RootState} from "app/store/store";

const personalDetailsSelectors = {
  getPersonalDetails: (state: RootState) =>
    state.personalDetails.personalDetails,
  getAddressDetailsByType: (state: RootState, addressType: string) =>
    state.personalDetails.addressDetails.find(
      (item) => item.addressType === addressType,
    ),
  getDocumentsMasterList: (state: RootState) =>
    state.personalDetails.documentsMasterList,
  getKycDocuments: (state: RootState) => state.personalDetails.kycDocuments,
  getLoading: (state: RootState) => state.personalDetails.loading,
  getErrors: (state: RootState) => state.personalDetails.errors,
  getGuarantorPersonalDetails: (state: RootState) =>
    state.personalDetails.guarantorPersonalDetails,
  getGuarantorAddressDetailsByType: (state: RootState, addressType: string) =>
    state.personalDetails.guarantorAddressDetails.find(
      (item) => item.addressType === addressType,
    ),
};

export default personalDetailsSelectors;
