import React from "react";
import {RootState} from "app/store/store";
import {Box, Typography, Stack, Divider} from "@mui/material";
import {Money} from "@mui/icons-material";
import {IGButton} from "app/components";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import BtScoreCustomerDetailsOverview from "./BTScoreCustomerDetailsOverview";

const BTScoreCardCustomerDetails = ({crId}: { crId: number }) => {
  const customerDetails =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreCardCustomerDetailsByCrId(
        state,
        Number(crId),
      ),
    ) ?? {};
  const {takeoverPreviousLoanAmount, takeoverClosureAmount} =
    customerDetails ?? {};
  return (
    <Box>
      <Box my={3}>
        <BtScoreCustomerDetailsOverview crId={Number(crId)} />
      </Box>
      <Divider
        sx={{
          padding: "1px",
        }}
      />
      <Box my={3}>
        <IGButton color="warning" variant="text">
          BALANCE TRANSFER
        </IGButton>

        <Stack direction="row" mt={1} gap={1}>
          <Money />
          <Stack direction="column" mb={2}>
            <Typography variant="subtitle1" color="gray" fontSize="16px">
              Closure Amount
            </Typography>
            <Typography variant="h6" fontWeight={500}>
              {takeoverClosureAmount}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" mt={1} gap={1}>
          <Money />
          <Stack direction="column" mb={2}>
            <Typography variant="subtitle1" color="gray" fontSize="16px">
              Previous Loan Amount
            </Typography>
            <Typography variant="h6" fontWeight={500}>
              {takeoverPreviousLoanAmount}
            </Typography>
          </Stack>
        </Stack>
        <Divider
          sx={{
            padding: "1px",
          }}
        />
      </Box>
    </Box>
  );
};

export default BTScoreCardCustomerDetails;
