import styled from "@emotion/styled";
import {Chip} from "@mui/material";

const DocBlacklistedStatusChip = styled(Chip)`
  &.successChip {
    background: #c8e6c9;
    color: #307e34;

    span {
      font-weight: bold;
    }
  }
  &.errorChip {
    background: #f2c1c1;
    color: #c62828;

    svg {
      color: #c62828;
    }

    span {
      font-weight: bold;
    }
  }
`;

export default DocBlacklistedStatusChip;
