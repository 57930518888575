import {
  LeadInterface,
  PartnerAnalytics,
  LeadUnQualifiedReason,
  ParentLeadIdCreditDetail,
} from "app/models/lead";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import leadActions from "./lead.actions";
import {getValueFromQueryParams} from "_metronic/utils/utils";
import {GridSortDirection} from "@mui/x-data-grid";
import {getCurrentTime} from "_metronic/utils/moment";
import {
  ParentLeadDocument,
  ParentLeadDocumentConfig,
  ParentLeadDocumentFileDetailIdPayload,
  ParentLeadIdPayload,
  ParentLeadMasterDocumentConfigIdPayload,
} from "app/infra/services/api/lead/lead.types";

type LoadingErrorState =
  | "getLeadList"
  | "downloadCSV"
  | "addNewLead"
  | "bulkLeadUpload"
  | "getAnalytics"
  | "getLeadUnQualifiedReasons"
  | "getParentLeadId"
  | "getParentLeadIdCreditDetail"
  | "getParentLeadDocumentConfig"
  | "getParentLeadDocument"
  | "deleteParentLeadDocument"
  | "updateParentLeadDocument"
  | "updateLeadOnLSQ"
  | "postLeadReAttribute";

interface LeadFilters {
  search: string;
  createdAtMin: number | null;
  createdAtMax: number | null;
  partnerLeadStageList: string;
  cityList: string;
  goldLoanTypeList: string;
  loanAmountMin: number;
  loanAmountMax: number;
  sortField?: string;
  sort?: GridSortDirection;
}

export interface LeadState {
  partnerLeadList: LeadInterface[];
  filters: LeadFilters;
  paginationData: {
    pageNo: number;
    pageSize: number;
  };
  leadUnQualifiedReasons: LeadUnQualifiedReason[];
  analyticsDetails: PartnerAnalytics;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  openModals: {
    addNewLead: boolean;
  };
  listReload: boolean;
  creditDetails: {
    [leadId: number]: ParentLeadIdCreditDetail;
  };
  parentLeadId: number | null;
  parentLeadDocumentConfig: Record<number, Record<number, ParentLeadDocumentConfig>>;
  parentLeadDocuments: Record<number, Record<number, ParentLeadDocument>>;
}

const initialState: LeadState = {
  partnerLeadList: [],
  parentLeadDocumentConfig: {},
  parentLeadDocuments: {},
  paginationData: {
    pageNo: Number(getValueFromQueryParams("pageNo")) || 1,
    pageSize: Number(getValueFromQueryParams("pageSize")) || 10,
  },
  filters: {
    search: getValueFromQueryParams("search") || "",
    createdAtMin:
      Number(getValueFromQueryParams("createdAtMin")) ||
      getCurrentTime()
        .subtract(1, "months")
        .startOf("day")
        .valueOf(),
    createdAtMax:
      Number(getValueFromQueryParams("createdAtMax")) ||
      getCurrentTime()
        .endOf("day")
        .valueOf(),
    partnerLeadStageList: getValueFromQueryParams("partnerLeadStageList") || "ALL",
    cityList: getValueFromQueryParams("cityList") || "ALL",
    goldLoanTypeList: getValueFromQueryParams("goldLoanTypeList") || "ALL",
    loanAmountMin: Number(getValueFromQueryParams("loanAmountMin")) || 0,
    loanAmountMax: Number(getValueFromQueryParams("loanAmountMax")) || 0,
    sortField: getValueFromQueryParams("sortField") || "",
    sort: (getValueFromQueryParams("sort") as GridSortDirection) || undefined,
  },
  analyticsDetails: {
    disbursedLeadCount: 0,
    inProgressLeadCount: 0,
    totalDisbursedAmountRs: 0,
    unqualifiedLeadCount: 0,
    totalLeadCount: 0,
  },
  leadUnQualifiedReasons: [],
  openModals: {
    addNewLead: false,
  },
  parentLeadId: null,
  creditDetails: {},
  loading: {
    getLeadList: false,
    downloadCSV: false,
    addNewLead: false,
    bulkLeadUpload: false,
    getAnalytics: false,
    getLeadUnQualifiedReasons: false,
    getParentLeadId: false,
    getParentLeadIdCreditDetail: false,
    getParentLeadDocumentConfig: false,
    getParentLeadDocument: false,
    deleteParentLeadDocument: false,
    updateParentLeadDocument: false,
    updateLeadOnLSQ: false,
    postLeadReAttribute: false,
  },
  errors: {
    getLeadList: null,
    downloadCSV: null,
    addNewLead: null,
    bulkLeadUpload: null,
    getAnalytics: null,
    getLeadUnQualifiedReasons: null,
    getParentLeadId: null,
    getParentLeadIdCreditDetail: null,
    getParentLeadDocumentConfig: null,
    getParentLeadDocument: null,
    deleteParentLeadDocument: null,
    updateParentLeadDocument: null,
    updateLeadOnLSQ: null,
    postLeadReAttribute: null,
  },
  listReload: false,
};

const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    setFilters: (state, action: PayloadAction<Partial<LeadFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setPaginationData: (
      state,
      action: PayloadAction<Partial<typeof initialState.paginationData>>,
    ) => {
      state.paginationData = {
        ...state.paginationData,
        ...action.payload,
      };
    },

    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
    setParentLeadId: (state, action) => {
      const {response} = action.payload;
      state.parentLeadId = response?.id;
    },

    setCreditDetails: (state, action) => {
      const {response, leadId} = action.payload;
      state.creditDetails[leadId] = response;
    },
    resetFilters: state => {
      state.filters = initialState.filters;
    },

    resetLeadUnQualifiedReasons: state => {
      state.leadUnQualifiedReasons = initialState.leadUnQualifiedReasons;
    },
    setParentLeadDocumentConfig: (
      state,
      action: PayloadAction<{
        parentLeadId: number;
        documentConfigList: ParentLeadDocumentConfig[];
      }>,
    ) => {
      const {parentLeadId, documentConfigList} = action.payload;
      state.parentLeadDocumentConfig[parentLeadId] = documentConfigList.reduce((acc, item) => {
        acc[item.fwcParentLeadMasterDocumentConfigId] = item;
        return acc;
      }, {} as Record<number, ParentLeadDocumentConfig>);
    },
    setParentLeadDocuments: (state, action: PayloadAction<ParentLeadDocument>) => {
      const {parentLeadId, fwcParentLeadMasterDocumentConfigId} = action.payload;
      state.parentLeadDocuments[parentLeadId] = {
        ...(state.parentLeadDocuments[parentLeadId] || {}),
        [fwcParentLeadMasterDocumentConfigId]: action.payload,
      };
    },
    removeParentLeadDocument: (
      state,
      action: PayloadAction<
        ParentLeadIdPayload &
          ParentLeadDocumentFileDetailIdPayload &
          ParentLeadMasterDocumentConfigIdPayload
      >,
    ) => {
      const {
        parentLeadId,
        parentLeadDocumentFileDetailId,
        parentLeadMasterDocumentConfigId,
      } = action.payload;
      const parentLeadDocument =
        state.parentLeadDocuments[parentLeadId]?.[parentLeadMasterDocumentConfigId];
      if (parentLeadDocument) {
        state.parentLeadDocuments[parentLeadId][parentLeadMasterDocumentConfigId].fileDetailList = [
          ...parentLeadDocument.fileDetailList.filter(
            item => item.id !== parentLeadDocumentFileDetailId,
          ),
        ];
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(leadActions.getPartnerLeadList, state => {
        state.loading.getLeadList = true;
        state.partnerLeadList = initialState.partnerLeadList;
        state.errors.getLeadList = initialState.errors.getLeadList;
      })
      .addCase(leadActions.getPartnerLeadListSuccess, (state, action) => {
        state.loading.getLeadList = false;
        const {pageNo, pageSize, result} = action.payload.response;
        const currentPaginationData = {...state.paginationData};
        if (currentPaginationData.pageNo !== pageNo) {
          state.paginationData.pageNo = pageNo;
        }
        if (currentPaginationData.pageSize !== pageSize) {
          state.paginationData.pageSize = pageSize;
        }
        state.partnerLeadList = result;
      })
      .addCase(leadActions.getPartnerLeadListFailure, (state, action) => {
        state.loading.getLeadList = false;
        state.errors.getLeadList = action.payload.error;
      })
      .addCase(leadActions.downloadCSV, state => {
        state.loading.downloadCSV = true;
        state.errors.downloadCSV = initialState.errors.downloadCSV;
      })
      .addCase(leadActions.downloadCSVSuccess, state => {
        state.loading.downloadCSV = false;
      })
      .addCase(leadActions.downloadCSVFailure, (state, action) => {
        state.loading.downloadCSV = false;
        state.errors.downloadCSV = action.payload.error;
      })
      .addCase(leadActions.addNewLead, state => {
        state.loading.addNewLead = true;
        state.errors.addNewLead = initialState.errors.addNewLead;
      })
      .addCase(leadActions.addNewLeadSuccess, state => {
        state.loading.addNewLead = false;
        state.openModals.addNewLead = false;
        state.listReload = !state.listReload;
      })
      .addCase(leadActions.addNewLeadFailure, (state, action) => {
        state.loading.addNewLead = false;
        state.errors.addNewLead = action.payload.error;
      })
      .addCase(leadActions.bulkLeadUpload, state => {
        state.loading.bulkLeadUpload = true;
        state.errors.bulkLeadUpload = initialState.errors.bulkLeadUpload;
      })
      .addCase(leadActions.bulkLeadUploadSuccess, state => {
        state.loading.bulkLeadUpload = false;
        state.openModals.addNewLead = false;
      })
      .addCase(leadActions.bulkLeadUploadFailure, (state, action) => {
        state.loading.bulkLeadUpload = false;
        state.errors.bulkLeadUpload = action.payload.error;
      })
      .addCase(leadActions.getAnalytics, state => {
        state.loading.getAnalytics = true;
        state.analyticsDetails = initialState.analyticsDetails;
        state.errors.getAnalytics = initialState.errors.getAnalytics;
      })
      .addCase(leadActions.getAnalyticsSuccess, (state, action) => {
        state.loading.getAnalytics = false;
        state.analyticsDetails = action.payload.response;
      })
      .addCase(leadActions.getAnalyticsFailure, (state, action) => {
        state.loading.getAnalytics = false;
        state.errors.getAnalytics = action.payload.error;
      })

      .addCase(leadActions.getLeadUnQualifiedReasons, state => {
        state.loading.getLeadUnQualifiedReasons = true;
        state.leadUnQualifiedReasons = initialState.leadUnQualifiedReasons;
        state.errors.getLeadUnQualifiedReasons = initialState.errors.getLeadUnQualifiedReasons;
      })
      .addCase(leadActions.getLeadUnQualifiedReasonsSuccess, (state, action) => {
        state.loading.getLeadUnQualifiedReasons = false;
        state.leadUnQualifiedReasons = action.payload.response;
      })
      .addCase(leadActions.getLeadUnQualifiedReasonsFailure, (state, action) => {
        state.loading.getLeadUnQualifiedReasons = false;
        state.errors.getLeadUnQualifiedReasons = action.payload.error;
      }),
});

export default leadSlice.reducer;

const {
  toggleModals,
  resetFilters,
  setPaginationData,
  setFilters,
  resetLeadUnQualifiedReasons,
  setLoading: setLeadLoading,
  setError: setLeadError,
  setParentLeadId,
  setCreditDetails,
  setParentLeadDocumentConfig,
  setParentLeadDocuments,
  removeParentLeadDocument,
} = leadSlice.actions;

export {
  toggleModals,
  setFilters,
  resetFilters,
  setPaginationData,
  resetLeadUnQualifiedReasons,
  setLeadLoading,
  setLeadError,
  setParentLeadId,
  setCreditDetails,
  setParentLeadDocumentConfig,
  setParentLeadDocuments,
  removeParentLeadDocument,
};
