import {ThemeProvider, createTheme, Typography} from "@mui/material";
import {IGSelect, IGTextField} from "app/components";
import {authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React, {useMemo} from "react";
import {StyledServerContainer} from "../styled";
import {availableServers, serverChanged} from "app/store/auth/reducer";
import {useIGTranslation} from "app/infra/plugins/i18n";

const {hostname} = window.location;
const ServerSwitch = () => {
  const dispatch = useAppDispatch();
  const {t} = useIGTranslation();
  const server = useAppSelector(authSelectors.getServer);

  const renderServerSwitch = useMemo(() => {
    return (
      process.env.REACT_APP_ENV === "development" &&
      hostname !== t("DASHBOARD_DEMO")
    );
  }, [t]);

  return (
    <>
      {renderServerSwitch && (
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
              sdGrey: {},
              golden: {},
            },
          })}
        >
          <StyledServerContainer>
            <IGTextField
              name="server"
              value={server}
              label="Custom Server"
              placeholder="Enter Server prefix"
              onChange={(e) => {
                dispatch(serverChanged(e.target.value));
                // eslint-disable-next-line no-console
                console.log("Server Changed:", e.target.value);
              }}
            />
            <Typography
              sx={{
                fontFamily: "Anonymous Pro, monospace",
                ml: 2,
              }}
            >
              Server:
            </Typography>
            <IGSelect
              name="server"
              value={server}
              options={availableServers
                .filter((server) => server !== "api")
                .map((server) => ({
                  value: server,
                  text: server,
                }))}
              onChange={(e) => {
                dispatch(serverChanged(e.target.value));
                // eslint-disable-next-line no-console
                console.log("Server Changed:", e.target.value);
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                ".MuiPaper-elevation1 MuiMenu-paper": {
                  backgroundColor: "#000",
                  backgroundImage: "none",
                },
                color: "#bfffa5",
                fontFamily: "Anonymous Pro, monospace",
              }}
              MenuProps={{
                sx: {
                  ".MuiMenu-paper": {
                    backgroundColor: "#000",
                    backgroundImage: "none",
                    color: "#bfffa5",
                    fontFamily: "Anonymous Pro, monospace",
                  },
                },
              }}
            />
          </StyledServerContainer>
        </ThemeProvider>
      )}
    </>
  );
};

export default ServerSwitch;
