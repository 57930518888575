import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";

import creditDetailsActions from "./creditDetails.actions";
import {creditDetailsActionTypes} from "./creditDetails.actions";

import {snackBarActions} from "app/store/snackbar";
import {
  CrIdPayload,
  CreditDetailsResponse,
  CreditSessionStatusPayload,
  GetTakeoverCreditReportHtmlPayload,
} from "./creditDetails.types";
import {
  creditReportHtmlReceived,
  setCreditDetailsError,
  setCreditDetailsLoading,
} from "./creditDetails.reducer";

const {
  getCreditScoreDetailsSuccess,
  getCreditScoreDetailsFailure,
  initiateCreditConsentSuccess,
  initiateCreditConsentFailure,
  getCreditSessionStatusFailure,
  getCreditSessionStatusSuccess,
} = creditDetailsActions;

function* generalErrorFlow(action: PayloadAction<CreditDetailsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getCreditScoreDetailsFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.creditDetails.getCreditScoreDetails,
    action.payload,
  );
  if (error) {
    yield put(getCreditScoreDetailsFailure({response: null, error}));
  } else {
    yield put(getCreditScoreDetailsSuccess({response, error: null}));
  }
}
function* initiateCreditConsentFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.creditDetails.initiateCreditConsent,
    action.payload,
  );
  if (error) {
    yield put(initiateCreditConsentFailure({response: null, error}));
  } else {
    yield put(initiateCreditConsentSuccess({response, error: null}));
    yield put(
      snackBarActions.open({
        message: "Consent has been successfully generated!",
        variant: "success",
      }),
    );
  }
}

function* getTakeoverCreditReportFlow(
  action: PayloadAction<GetTakeoverCreditReportHtmlPayload>,
) {
  yield put(
    setCreditDetailsLoading({key: "getTakeoverCreditReport", value: true}),
  );
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.creditDetails.getTakeoverCreditReportHtml,
    action.payload,
  );
  if (error) {
    yield put(
      setCreditDetailsError({key: "getTakeoverCreditReport", value: error}),
    );
  } else {
    yield put(creditReportHtmlReceived({report: response}));
  }
  yield put(
    setCreditDetailsLoading({key: "getTakeoverCreditReport", value: false}),
  );
}

function* getCreditSessionStatusFlow(
  action: PayloadAction<CreditSessionStatusPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.creditDetails.getCreditSessionStatus,
    action.payload,
  );
  if (error) {
    yield put(getCreditSessionStatusFailure({response: null, error}));
  } else {
    yield put(getCreditSessionStatusSuccess({response, error: null}));
  }
}

export default function* creditDetailsSaga() {
  yield takeLatest(
    creditDetailsActionTypes.GetCreditScoreDetails,
    getCreditScoreDetailsFlow,
  );
  yield takeLatest(
    creditDetailsActionTypes.InitiateCreditConsent,
    initiateCreditConsentFlow,
  );
  yield takeLatest(
    creditDetailsActionTypes.GetCreditScoreDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    creditDetailsActionTypes.InitiateCreditConsentFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    creditDetailsActionTypes.GetTakeoverCreditReportHtml,
    getTakeoverCreditReportFlow,
  );

  yield takeLatest(
    creditDetailsActionTypes.GetCreditSessionStatus,
    getCreditSessionStatusFlow,
  );
  yield takeLatest(
    creditDetailsActionTypes.GetCreditScoreDetailsFailure,
    generalErrorFlow,
  );
}
