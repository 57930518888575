import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const ArrowUpFromBracket: React.FC = () => {
  return <FontAwesomeIcon icon={faArrowUpFromBracket} size="1x" />;
};

export default ArrowUpFromBracket;
