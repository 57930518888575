import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {featureFlagActionTypes} from "./featureFlags.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  setFeatureFlag,
  setFeatureFlagsError,
  setFeatureFlagsLoading,
  setFeatureFlagFromList,
} from "./featureFlags.reducer";
import {FeatureFlagStatusPayload} from "app/infra/services/api/app/app.api";

function* getFeatureFlagFlow(action: PayloadAction<FeatureFlagStatusPayload>) {
  const api: GoldApi = yield getContext("api");
  yield put(setFeatureFlagsLoading(true));
  const {response, error} = yield call(
    api.app.getFeatureFlagStatus,
    action.payload,
  );
  if (error) {
    yield put(
      setFeatureFlagsError(error.message || "Failed to get feature flag"),
    );
  } else {
    yield put(
      setFeatureFlag({
        moduleEnum: action.payload.moduleEnum,
        moduleFeatureEnum: action.payload.moduleFeatureEnum,
        value: response,
      }),
    );
  }
  yield put(setFeatureFlagsLoading(false));
}

function* getFeatureFlagListFlow(
  action: PayloadAction<FeatureFlagStatusPayload[]>,
) {
  const api: GoldApi = yield getContext("api");
  yield put(setFeatureFlagsLoading(true));
  const {response, error} = yield call(
    api.app.getFeatureFlagStatusList,
    action.payload,
  );
  if (error) {
    yield put(
      setFeatureFlagsError(error.message || "Failed to get feature flag list"),
    );
  } else {
    yield put(setFeatureFlagFromList(response));
  }
  yield put(setFeatureFlagsLoading(false));
}

export default function* featureFlagsSaga() {
  yield takeLatest(featureFlagActionTypes.GetFeatureFlag, getFeatureFlagFlow);
  yield takeLatest(
    featureFlagActionTypes.GetFeatureFlagList,
    getFeatureFlagListFlow,
  );
}
