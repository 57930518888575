import React, {useCallback, useEffect, useRef, useState} from "react";

import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

import Container from "../styles/Container";
import SubTitle from "../styles/SubTitle";
import MiniTitle from "../styles/MiniTitle";
import Title from "../styles/Title";
import ColumnContainer from "../styles/ColumnContainer";
import RowContainer from "../styles/RowContainer";
import Loading from "app/components/Loading";
import getPersonalDetails from "api/business/getPersonalDetail";


const NomineeDetails = ({crId}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });
  const [personalDetails, setPersonalDetails] = useState();
  const countRef = useRef(0);

  const fetchPersonalDetails = useCallback(async () => {
    setStates({
      ...states,
      loading: true,
    });
    try {
      const {payload, statusCode, error} = await getPersonalDetails({
        accessToken,
        crId,
        personalDetailType: "LOAN_PERSONAL_DETAIL",
      });

      if (statusCode === 200) {
        setPersonalDetails(payload);
        setStates({
          ...states,
          loading: false,
        });
      } else {
        setStates({
          ...states,
          loading: false,
          error: true,
          errorMessage: error,
        });
      }
    } catch (e) {
      setStates({
        ...states,
        loading: false,
        error: true,
        errorMessage: "Error in Loading",
      });
    }
  }, [accessToken, crId, states]);

  useEffect(() => {
    if (countRef.current === 0) {
      countRef.current = 1;
      fetchPersonalDetails();
    }
  });

  if (states.loading || !personalDetails) {
    return <Loading height="20vh" />;
  }

  if (states.error) {
    return <Container>{states.errorMessage}</Container>;
  }

  return (
    <Container>
      <Title>
        Please verify all details and mark checkbox to proceed to next step or
        send back and ask LM to fill correctly
      </Title>

      <RowContainer>
        <ColumnContainer>
          <SubTitle>Name</SubTitle>
          <MiniTitle>{personalDetails?.nomineeName || "N/A"}</MiniTitle>
        </ColumnContainer>
        <ColumnContainer>
          <SubTitle>Relation</SubTitle>
          <MiniTitle>
            {personalDetails.relationshipWithNomineeDisplayValue || "N/A"}
          </MiniTitle>
        </ColumnContainer>
        <ColumnContainer>
          <SubTitle>Date of Birth</SubTitle>
          <MiniTitle>{personalDetails.nomineeDOB || "N/A"}</MiniTitle>
        </ColumnContainer>
      </RowContainer>
      <RowContainer>
        <ColumnContainer>
          <SubTitle>Address</SubTitle>
          <MiniTitle>{personalDetails.nomineeAddress || "N/A"}</MiniTitle>
        </ColumnContainer>
      </RowContainer>
    </Container>
  );
};

export default NomineeDetails;
