import styled from "@emotion/styled";

interface Props {
  borderLeft: string;
}

const AddressContainer = styled.div<Props>`
  margin-top: 1rem;

  width: 48%;

  border-left: ${({borderLeft}) => borderLeft};
  padding-left: ${({borderLeft}) => borderLeft && "16px"};
  margin-left: ${({borderLeft}) => borderLeft && "6px"};

  // display: flex;
  // justify-content: space-between;
`;

export default AddressContainer;
