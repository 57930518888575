import React from "react";

import SubTitle from "../../styles/SubTitle";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import HeaderContainer from "./styles/HeaderContainer";
import {ZoomOutMap} from "@mui/icons-material";
import BodyContainer from "./styles/BodyContainer";
import Title from "../../styles/Title";
import ColumnWrapper from "../styles/ColumnWrapper";
import DocumentWrapper from "../styles/DocumentWrapper";
import {Box} from "@mui/material";
import DocBlacklistedStatusChip from "../styles/DocBlackListedStatusChip";

const Aadhar = ({aadharDetails, setOpenAadhaarModal, title = "AADHAR"}) => {
  return (
    <DocumentWrapper>
      <HeaderContainer>
        <Title>{title}</Title>
        {aadharDetails && (
          <Box mb={1} mr={1}>
            <DocBlacklistedStatusChip
              className={
                aadharDetails[0]?.isBlackListed ? "errorChip" : "successChip"
              }
              size="small"
              label={
                aadharDetails[0]?.isBlackListed
                  ? aadharDetails[0]?.blacklistReason === "IG_EMPLOYEE"
                    ? "Blacklisted: IG employee"
                    : "Blacklisted"
                  : "Approved"
              }
            />
          </Box>
        )}

        <ZoomOutMap onClick={() => setOpenAadhaarModal(true)} />
      </HeaderContainer>
      <BodyContainer>
        <ColumnWrapper>
          <SubTitle>Number</SubTitle>
          <Title>
            {(aadharDetails.length > 0 && aadharDetails[0].documentNumber) ||
              "Not Available"}
          </Title>
          <SubTitle>Remarks</SubTitle>
          <Title>
            {(aadharDetails[0].length > 0 && aadharDetails[0].remark) || ""}
          </Title>
        </ColumnWrapper>
        <ColumnWrapper>
          <SubTitle>Photo Verification</SubTitle>
          {/* aadhar photo */}
          <DocumentImageWrapper>
            {aadharDetails.length > 0 && aadharDetails[0].fileUrl && (
              <DocIconWrapper
                src={aadharDetails[0].fileUrl}
                alt="aadhar_front"
              />
            )}
            {aadharDetails.length > 0 && aadharDetails[0].backFileUrl && (
              <DocIconWrapper
                src={aadharDetails[0].backFileUrl}
                alt="aadhar_back"
              />
            )}
          </DocumentImageWrapper>
        </ColumnWrapper>
      </BodyContainer>
    </DocumentWrapper>
  );
};

export default Aadhar;
