import React, {useCallback, useRef} from "react";
import {makeStyles} from "@mui/styles";
import {Paper} from "@mui/material";
import {Col, Row} from "react-bootstrap";

import axios from "_metronic/utils/axios";
import {API_ENDPOINTS} from "app/constants/constants";
import {useSnackBar} from "../../../store/hooks";

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function UserDetails(props) {
  const classes = useStyles2();
  const {showError} = useSnackBar();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState({
    filters: false,
  });

  const verticalData = [
    {
      name: "Country",
      value: "country",
    },
    {
      name: "Region",
      value: "region",
    },
    {
      name: "City",
      value: "city",
    },
    {
      name: "Device",
      value: "device",
    },
    {
      name: "Device type",
      value: "deviceType",
    },
    {
      name: "Platform",
      value: "platform",
    },
    {
      name: "Operating system",
      value: "os",
    },
    {
      name: "Carrier",
      value: "carrier",
    },
    {
      name: "IP address",
      value: "ipAddress",
    },
    {
      name: "Last used",
      value: "lastUsed",
    },
    {
      name: "Start version",
      value: "startVersion",
    },
    {
      name: "Version",
      value: "version",
    },
  ];

  const counter = useRef(0);

  const fetchTableData = useCallback(
    (userId) => {
      setLoading({...loading, filters: true});
      axios
        .request({
          method: API_ENDPOINTS.USER.GET_DEVICE_DETAILS.METHOD,
          url: API_ENDPOINTS.USER.GET_DEVICE_DETAILS.URL.replace(
            ":userId",
            userId,
          ),
          data: null,
        })
        .then((response) => {
          setLoading({...loading, filters: false});
          if (response && response.data && response.data.result) {
            setData(response.data.result);
          }
        })
        .catch((err) => {
          setLoading({...loading, filters: false});
          if (
            err &&
            err.response &&
            [401, 403, 422].includes(err.response.status)
          ) {
            showError(
                err.response.data &&
                err.response.data.error &&
                err.response.data.error.message,
            );
          } else {
            showError("Something went wrong");
          }
        });
    },
    [loading],
  );

  React.useEffect(() => {
    if (props.userId && counter.current === 0) {
      counter.current = 1;
      fetchTableData(props.userId);
    }
    return () => {};
  }, [fetchTableData, props.userId]);

  return (
    <Paper className={classes.root}>

      {/* HEADER */}
      {props.header ? (
        <Row className="mx-0">
          <Col className={classes.header}>
            DEVICE DETAILS FOR - {props.userId}
          </Col>
        </Row>
      ) : null}

      {verticalData && !loading.filters ? (
        <div className="mx-5 my-5">
          {verticalData.map((row, index) => {
            return (
              <Row key={index} className="my-4" justify="center">
                <Col md="5" xs="5" align="left">
                  {row.name}
                </Col>
                <Col md="1" xs="1">
                  :
                </Col>
                <Col md="6" xs="6" align="left">
                  <span style={{overflowWrap: "break-word"}}>
                    {data[row.value]}
                  </span>
                </Col>
              </Row>
            );
          })}
        </div>
      ) : (
        <Row align="center">
          <Col>
            <span
              className="spinner-grow spinner-grow-xl"
              style={{width: "100px", height: "100px", color: "#FF92AC"}}
            ></span>
          </Col>
        </Row>
      )}
    </Paper>
  );
}
