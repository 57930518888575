import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const getDocumentForMilestone = async ({accessToken, crId, milestoneName}) => {
    try {
        const response = await axios.get(
            `${getApiUrl()}/business/cr/${crId}/milestone/document-list?milestoneName=${milestoneName}`,
            {
                headers: {
                    "x-access-token": accessToken,
                    "Content-Type": "application-json",
                },
            },
        );

        const result = await response.data;

        if (result.status !== 200 || result.error !== null) {
            throw new Error(result.error);
        }

        return {
            statusCode: 200,
            payload: result.result,
            message: "Success",
        };
    } catch (error) {
        return ErrorResponseHandler(error);
    }
};

export default getDocumentForMilestone;
