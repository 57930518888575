import React, {useCallback, useEffect, useState} from "react";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import styles from "../../styles";
import updateStatus from "api/business/updateStatus";
import getNextJob from "api/business/getNextJob";
import {CircularProgress} from "@mui/material";
import {useAppDispatch, useSnackBar} from "app/store/hooks";
import PersonalLoanChecker from "./PersonalLoanChecker";
import GoldLoanChecker from "../PersonalNotTaken/GoldLoanChecker";
import {IGButton} from "app/components";
import createLoanOnLms from "api/business/createLoanOnLms";
import {Box} from "@mui/material";

const ApplicationGenerated = ({
  payload,
  selectedPayload,
  isBackMovement,
  isSameCreditManager,
  ltv,
  getMileStoneOfCr,
  personalLoanApplicationNo,
  goldLoanApplicationNo,
  getDetails,
  requesting,
  onModalHandler,
}) => {
  const classes = styles();
  const snackbar = useSnackBar();
  const dispatch = useAppDispatch();
  const {goldLoan} = payload;
  const [plState, setPLState] = useState(true);
  const [glState, setGLState] = useState(true);

  const {personalLoan} = payload;

  const [
    isPersonalLoanCheckerDisable,
    setIsPersonalLoanCheckerDisable,
  ] = useState(true);
  const [isGoldLoanCheckerDisable, setIsGoldLoanCheckerDisable] = useState(
    true,
  );

  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [isLoanOnLmsLoading, setIsLoanOnLmsLoading] = useState(false);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
    payload: {
      nextJobResponse: null,
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const getNextJobResponse = await getNextJob({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          jobType: "CM_PROCESS_LOAN",
        });

        if (getNextJobResponse.statusCode === 200) {
          setStates({
            error: false,
            errorMessage: "",
            loading: false,
            payload: {
              nextJobResponse: getNextJobResponse.payload,
            },
          });
        } else {
          setStates({
            error: true,
            errorMessage: getNextJobResponse.error.toString(),
            loading: false,
            payload: {
              nextJobResponse: null,
            },
          });
        }
      } catch (error) {
        setStates({
          error: true,
          errorMessage: "Something went wrong",
          loading: false,
          payload: {
            nextJobResponse: null,
          },
        });
      }
    })();
  }, [selectedPayload.id]);

  const onClickHandler = useCallback(
    async (states) => {
      try {
        const getNextJobResponse = await getNextJob({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          jobType: "CM_PROCESS_LOAN",
        });

        if (getNextJobResponse.statusCode === 200) {
          //  console.log("calling");
          const response = await updateStatus({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
            crJobId: getNextJobResponse.payload.id,
            remark: "",
            status: states,
          });

          if (response.statusCode === 200) {
            getDetails();
            getMileStoneOfCr();
            setIsPersonalLoanCheckerDisable(true);
            setIsGoldLoanCheckerDisable(true);
            setApproveButtonLoading(false);
            if (states === "CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE") {
              setGLState(false);
            } else {
              setPLState(false);
            }
          } else {
            snackbar.showError(response.error);
          }
        } else {
          snackbar.showError(getNextJobResponse.error);
        }
      } catch (error) {
        snackbar.showError("Something went wrong");
      }
    },
    [getMileStoneOfCr, selectedPayload.id],
  );

  const createLoanOnLmsHandler = useCallback(async () => {
    setIsLoanOnLmsLoading(true);
    try {
      const {error: createLoanOnLmsError} = await createLoanOnLms({
        crId: selectedPayload.id,
      });
      if (!createLoanOnLmsError) {
        setIsLoanOnLmsLoading(false);
        onClickHandler("CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE");
        snackbar.showSuccess("LM has been notified to provide loan documents!");
      } else {
        setIsLoanOnLmsLoading(false);
        snackbar.showError(createLoanOnLmsError?.message);
      }
    } catch {
      setIsLoanOnLmsLoading(false);
    }
  }, [onClickHandler, selectedPayload.id]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (!isPersonalLoanCheckerDisable) {
    return (
      <PersonalLoanChecker
        crId={selectedPayload.id}
        selectedPayload={selectedPayload}
        personalLoanApplicationNo={personalLoanApplicationNo}
        onClickHandler={onClickHandler}
        setIsPersonalLoanCheckerDisable={setIsPersonalLoanCheckerDisable}
        isPersonalLoanCheckerDisable={isPersonalLoanCheckerDisable}
        approveButtonLoading={approveButtonLoading}
        setApproveButtonLoading={setApproveButtonLoading}
      />
    );
  }

  if (!isGoldLoanCheckerDisable) {
    return (
      <GoldLoanChecker
        crId={selectedPayload.id}
        goldLoanApplicationNo={goldLoanApplicationNo}
        onClickHandler={onClickHandler}
        setIsGoldLoanCheckerDisable={setIsGoldLoanCheckerDisable}
        isGoldLoanCheckerDisable={isGoldLoanCheckerDisable}
        approveButtonLoading={approveButtonLoading}
        setApproveButtonLoading={setApproveButtonLoading}
        getDetails={getDetails}
        isSameCreditManager={isSameCreditManager}
      />
    );
  }
  return (
    <div>
      {/* <div className={classes.textContainer}>
        <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

        <div className={classes.subText}>
          {`${numberWithCurrencyAndComma(
            goldLoan.totalLoanAmount + personalLoan.totalLoanAmount,
          )} / ${ltv}%`}
        </div>
      </div> */}

      <div className={classes.textContainer}>
        <div className={classes.text}>GOLD LOAN INFORMATION</div>

        <div className={classes.subText}>
          {"Application number: "}
          {goldLoan.applicationNumber}
        </div>

        <div
          className={classes.subText}
        >{`Gold Loan Amount: ${numberWithCurrencyAndComma(
          goldLoan.totalLoanAmount,
        )}`}</div>

        <div className={classes.subText}>{"Gold Loan"}</div>

        <div
          className={classes.subText}
        >{`Branch: ${goldLoan.loanBranch}`}</div>

        <div
          className={classes.subText}
        >{`Lender: ${goldLoan.loanVendorName}`}</div>

        {selectedPayload.milestone !== "COMPLETED" &&
          selectedPayload.milestone !== "CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
            <div
              className={classes.subText}
            >{`CM Scheme Name: ${goldLoan.schemeType}`}</div>
          )}

        {/* <div
          className={classes.subText}
        >{`Scheme Selected: ${goldLoan.goldLoanSchemeName ||
          goldLoan.nucleusSchemeCode}`}</div> */}

        <div
          className={classes.subText}
        >{`Annual Interest Rate: ${goldLoan.annualInterestRate}%`}</div>
        {/* <div
          className={classes.subText}
        >{`Subvented Annual Interest Rate: ${goldLoan.subventedAnnualInterestRate}%`}
        </div> */}

        {goldLoan.loanAccountNumber && (
          <div
            className={classes.subText}
          >{`Loan Account Number: ${goldLoan.loanAccountNumber}`}</div>
        )}
        {glState &&
          states.payload.nextJobResponse &&
          isSameCreditManager &&
          !isBackMovement &&
          !goldLoan.checkerId && (
            <>
              <Box mt={1}>
                <IGButton
                  color="golden"
                  fullWidth
                  onClick={() => {
                    setIsGoldLoanCheckerDisable(false);
                  }}
                >
                  Verify Gold Loan Details
                </IGButton>
              </Box>
            </>
          )}
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>PERSONAL LOAN INFORMATION</div>

        <div className={classes.subText}>
          {"Application number: "}
          {personalLoan.applicationNumber}
        </div>

        <div className={classes.subText}>{"Personal Loan"}</div>

        <div
          className={classes.subText}
        >{`Branch: ${personalLoan.loanBranch}`}</div>

        <div
          className={classes.subText}
        >{`Lender: ${personalLoan.loanVendorName}`}</div>

        <div
          className={classes.subText}
        >{`Personal Loan Amount: ${numberWithCurrencyAndComma(
          personalLoan.totalLoanAmount,
        )}`}</div>

        <div
          className={classes.subText}
        >{`Annual Interest Rate: ${personalLoan.annualInterestRate}%`}</div>
        {/* <div
          className={classes.subText}
        >{`Subvented Annual Interest Rate: ${personalLoan.subventedAnnualInterestRate}%`}
        </div> */}
        {personalLoan.loanAccountNumber && (
          <div
            className={classes.subText}
          >{`Loan Account Number: ${personalLoan.loanAccountNumber}`}</div>
        )}

        {plState &&
          states.payload.nextJobResponse &&
          isSameCreditManager &&
          !isBackMovement &&
          !personalLoan.checkerId && (
            <>
              <Box mt={1}>
                <IGButton
                  color="golden"
                  fullWidth
                  onClick={() => {
                    setIsPersonalLoanCheckerDisable(false);
                  }}
                >
                  VERIFY PERSONAL LOAN DETAILS
                </IGButton>
              </Box>
            </>
          )}
      </div>

      <div
        style={{
          fontSize: "10px",
          maxWidth: "fit-content",
        }}
        className={classes.subText}
      >
        *Please review all details mentioned in this section.
      </div>
      {/* {!(goldLoan.checkerId && personalLoan.checkerId) && (
        <>
          <IGButton
            onClick={onModalHandler}
            color="golden"
            sx={{height: "4rem"}}
            variant="outlined"
            fullWidth
            disabled={requesting}
          >
            RETURN TO GOLD VALUATION
          </IGButton>
        </>
      )} */}
      {isSameCreditManager &&
        goldLoan.checkerId &&
        personalLoan.checkerId &&
        !goldLoan?.loanAccountNumber &&
        !personalLoan?.loanAccountNumber && (
          <Box>
            <IGButton
              sx={{
                mt: 1,
              }}
              color="golden"
              fullWidth
              loading={isLoanOnLmsLoading}
              onClick={() => createLoanOnLmsHandler()}
            >
              CREATE LOAN ON LMS
            </IGButton>
          </Box>
        )}
    </div>
  );
};

export default ApplicationGenerated;
