/* eslint-disable no-console */
import React from "react";
import store from "app/store/store";
import moment from "moment-timezone";
import {API_ENDPOINTS, API_EXCEPTIONS, USER_ROLES} from "../../app/constants/constants";
import importedAxios from "../../_metronic/utils/axios";
import BugsnagNotify from "../utils/BugsnagNotify";
import {authActions} from "app/store/auth";
import {getAppSignature, renewAccessTokenHandler} from "app/infra/plugins/axios";
import {resetAuthState, setAppSignature} from "app/store/auth/reducer";

const appVersion = require("../../../package.json").version;

export function removeCSSClass(ele, cls) {
  const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios) {
  axios.interceptors.request.use(
    async request => {
      const env = process.env.REACT_APP_ENV;
      const server = env === "development" ? (localStorage.getItem("server") || "dev") : "api";
      request.baseURL = `https://${server}.${process.env.REACT_APP_API_BASE_URL}`;
      request.headers["Accept"] = "*";
      request.headers["device-type"] = "DASHBOARD";
      request.headers["device-env"] = process.env.REACT_APP_ENV;
      request.headers["device-version"] = appVersion;

      const localAppSignature = store.getState().auth.appSignature;
      const appSignature = localAppSignature || (await getAppSignature());

      request.headers["x-app-signature"] = appSignature;
      if (!localAppSignature) store.dispatch(setAppSignature(appSignature));

      const {authToken} = store.getState().auth;

      if (
        authToken &&
        (request.auth === undefined ||
          request.auth === null ||
          request.auth === true) &&
        authToken !== "null"
      ) {
        const parsedToken = parseJwt(authToken);
        if (parsedToken.exp <= Math.round(Date.now() / 1000)) {
          // console.log("Token expired, refreshing...");
          const {refreshToken} = store.getState().auth;
          if (refreshToken !== "null") {
            const newAuthToken = await renewAccessTokenHandler(refreshToken);

            if (newAuthToken) {
              request.headers["x-access-token"] = newAuthToken;
              return request;
            }
            throw new Error("Token refresh failed");
          }
          forceLogout();
          BugsnagNotify({
            error: {
              name: "Refresh Token Missing",
            }, source: "API", response: {
              refreshToken,
              authToken,
            },
          });

        } else {
          if (!request.headers["x-access-token"]) request.headers["x-access-token"] = authToken;
          return request;
        }
      } else if (!authToken) {
        forceLogout();
        BugsnagNotify({
          error: {
            name: "Auth Token Token Missing",
          }, source: "API", response: {
            state: store.getState(),
          },
        });
      }
      return request;
    },
    err => Promise.reject(err),
  );
  axios.interceptors.response.use(
    response => {
      // log(
      //   `[oldApiService] ${response.status} ${response.config.url.replace(
      //     process.env.REACT_APP_API_BASE_URL,
      //     ""
      //   )} --> `,
      //   response.data.result
      // );
      return response;
    },
    error => {
      // log(
      //   `[oldApiService] ${
      //     error.response.status
      //   } ${error.response.config.url.replace(
      //     process.env.REACT_APP_API_BASE_URL,
      //     ""
      //   )} --> `,
      //   error?.response?.data
      // );
      if (error.response) {
        const {status} = error.response;
        if (status.toString()[0] !== "4") {
          BugsnagNotify({error, source: "API", response: error.response.data});
        }
      }

      if (
        error &&
        error.response &&
        [401, 403].includes(error.response.status)
      ) {
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code === API_EXCEPTIONS.FWC_TOKEN_EXCEPTION
        ) {
          // console.log("Refreshing token");
          const {refreshToken} = store.getState().auth;

          refreshToken
            ? store.dispatch(authActions.renewAccessToken(refreshToken))
            : store.dispatch(resetAuthState());
        } else {
          store.dispatch(resetAuthState());
        }
      }
      return Promise.reject(error);
    },
  );
}

export function getPrice(assetName, partnerName) {
  importedAxios
    .request({
      method: API_ENDPOINTS.USER.GET_PRICE.METHOD,
      url:
        `${API_ENDPOINTS.USER.GET_PRICE.URL
        }?assetName=${assetName}&partnerName=${partnerName}`,
      data: null,
    })
    .then(response => {
      if (response && response.data && response.data.result) {
        localStorage.setItem(
          `price_${assetName}_${partnerName}`,
          response.data.result.sellPrice,
        );
        return;
      }
    })
    .catch(err => {
      localStorage.setItem(`price_${assetName}_${partnerName}`, null);
    });
}

export function getAnalyticsTabs(callback) {
  importedAxios
    .request({
      method: API_ENDPOINTS.ANALYTICS.GET_ANALYTICS_TABS.METHOD,
      url: API_ENDPOINTS.ANALYTICS.GET_ANALYTICS_TABS.URL,
      data: null,
    })
    .then(response => {
      if (response && response.data && response.data.result) {
        return callback(null, response.data.result);
      }
    })
    .catch(err => {
      return callback(err);
    });
}

export function openInNewTab(url) {
  const win = window.open(url, "_blank");
  win.focus();
}

export function getFilterMap(filters) {
  const filterMap = {};
  Object.keys(USER_ROLES).forEach(
    role =>
      (filterMap[role] = !USER_ROLES[role]?.showBulkData ? filters : undefined),
  );
  return filterMap;
}

export function getSearchQuery(page, rowsPerPage, searchFilter) {
  const userRole = localStorage.getItem("userRole");
  let queryParams = `pageNo=${page + 1}&pageSize=${rowsPerPage}`;
  let searchApplied = false;
  if (searchFilter) Object.keys(searchFilter).forEach(key => {
    // return is used in the forEach loop just to skip when the emiOrderType
    //  is 'REDEEM'. because we cannot have emiOrderType = 'REDEEM' in query
    // params as we are passing it as boolean somewhere else.
    if (key === "emiOrderType" && searchFilter[key] === "REDEEM") {
      return;
    }
    if (searchFilter[key]) {
      queryParams += `&${key}=${searchFilter[key]}`;
      searchApplied = true;
    }
  });
  if (!searchApplied && !USER_ROLES[userRole]?.showBulkData) return true;
  return queryParams;
}

export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split("&")
      .reduce((params, param) => {
        const [key, value] = param.split("=");
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, " "))
          : "";
        return params;
      }, {})
    : {};
}

export function formatDate(date, time = true, type = "") {
  const moment_date = moment(date).tz("Asia/Kolkata");
  if (type === "PROMO") {
    return moment_date.isValid()
      ? moment_date.format("YYYY-MM-DDTHH:mm")
      : "N/A";
  }
  if (type === "SERVICE_DESK") {
    return moment_date.isValid()
      ? moment_date.format("DD/MM/YYYY, hh:mm A")
      : "N/A";
  }
  if (type === "SCHEDULING") {
    return moment_date.format("YYYY-MM-DD");
  }
  if (time) return moment_date.isValid()
    ? moment_date.format("DD-MM-YYYY HH:mm:ss")
    : "N/A";
  return moment_date.isValid() ? moment_date.format("DD/MM/YYYY") : "N/A";
}

export function formatCustomDateTime(date, format) {
  return moment(date).isValid()
    ? moment(date)
      .tz("Asia/Kolkata")
      .format(format)
    : "N/A";
}

export function formatMoment(date, format = "", returnDefault = true, notValidMessage = "-") {
  return moment(date).isValid()
    ? moment(date).tz("Asia/Kolkata").format(format)
    : (returnDefault ? moment().tz("Asia/Kolkata").format(format) : notValidMessage);
}

export function forceLogout() {
  store.dispatch(resetAuthState());
}

export function urlify(text, onClickHandler, classes) {
  return (
    <div className={classes} onClick={onClickHandler}>
      {text}
    </div>
  );
}

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(`${key}_expiresIn`, "");
  } catch (e) {
    // console.log(
    //   `removeStorage: Error removing key [${
    //     key
    //     }] from localStorage: ${
    //     JSON.stringify(e)}`,
    // );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(`${key}_expiresIn`);
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // console.log(
    //   `getStorage: Error reading key [${
    //     key
    //     }] from localStorage: ${
    //     JSON.stringify(e)}`,
    // );
    return null;
  }

}

/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiresIn`, schedule);
  } catch (e) {
    // console.log(
    //   `setStorage: Error setting key [${
    //     key
    //     }] in localStorage: ${
    //     JSON.stringify(e)}`,
    // );
    return false;
  }
  return true;
}

export function checkValidPhoneNumber(inputtxt) {
  const phoneno = /^\d{10}$/;
  if (inputtxt.match(phoneno)) {
    return true;
  }
  return false;
}

export const getValueFromQueryParams = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const setValueInQueryParams = (key, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (value === null || value === undefined) {
    urlParams.delete(key);
  } else {
    urlParams.set(key, value);
  }
  window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
};

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export const getHoursAndMins = (millies) => {
  const duration = moment.duration(millies);
  const hours = Math.floor(duration.asHours());
  const mins = duration.minutes();
  return `${hours} hours ${mins} mins`;
};

export const checkIfPathOrParamsValid = (payload={}) => {
  for (const value of Object.values(payload)) {
    if (
      value === undefined
      || value === null
      || Number.isNaN(value)
    ) {
      return false;
    }
  }
  return true;
};