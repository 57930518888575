import React, {FC, useEffect} from "react";
import {useParams} from "react-router-dom";
import copy from "clipboard-copy";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {loanActions, loanSelectors} from "app/store/loan";
import {IGButton, IGDialog, IGLoading, IGTextField} from "app/components";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  Paper,
  Alert,
  Stack,
} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {
  VirtualAddressModel,
  VirtualAddressVAEnum,
} from "app/models/loan.model";
import {servicesSelectors} from "app/store/serviceDesk/services";
import {getValidTime} from "_metronic/utils/moment";
import {useIGDialog} from "app/store/hooks/IGDialogHook";
import customerProfileApi from "app/infra/services/api/customerProfile/customerProfile.api";
import {customerProfileActions} from "app/store/customerProfile";
import {selectServiceRequestById} from "app/store/serviceDesk/reducer";

interface VATableProps {}

interface PartPaymentDialogProps {
  open: boolean;
  onClose: () => void;
}

const PartPaymentAmountDialog: React.FC<PartPaymentDialogProps> = ({
  open,
  onClose,
}) => {
  const {loanId} = useParams();
  const dispatch = useAppDispatch();
  const {showConfirmDialog} = useIGDialog();
  const {requestId} = useAppSelector(servicesSelectors.getRowAction);
  const serviceRequest = useAppSelector((state) =>
    selectServiceRequestById(state, requestId),
  );
  const [partPaymentAmount, setPartPaymentAmount] = React.useState<number>();

  const showFinalDialog = () => {
    showConfirmDialog({
      title: "Part Payment VA",
      content: (
        <>
          <Typography>
            Part-payment amount will be utilized to settle overdue amount, and
            any remaining amount will then be used for principal reduction.
          </Typography>
          <br />
          <Typography mt={2}>
            Please ensure that this part-payment VA is used for one time payment
            only.
          </Typography>
        </>
      ),
      onConfirm: () => {
        dispatch(
          loanActions.generateVirtualAddress({
            loanId: Number(loanId || serviceRequest?.loanId),
            virtualAddressType: "PART_PREPAYMENT",
            isInAppPaymentAllowed: false,
          }),
        );
        onClose();
      },
      onCancel: () => {
        onClose();
      },
      confirmProps: {
        text: "View Part-Payment VA",
      },
      cancelProps: {
        text: "Cancel",
      },
    });
  };
  return (
    <IGDialog
      open={open}
      onClose={onClose}
      title="Part Payment VA"
      onConfirm={async () => {
        const {response} = await customerProfileApi.validatePartPaymentVA({
          parentLoanId: Number(loanId || serviceRequest?.loanId),
          partPaymentAmount: partPaymentAmount || 0,
        });

        if (response) {
          showConfirmDialog({
            title: "Part Payment VA",
            content: (
              <>
                <Typography>
                  It seems that the customer is interested in closing the loan
                  without incurring foreclosure charges. Hence, they are
                  requesting the part payment option.
                </Typography>
                <br />
                <Typography mt={2}>
                  Let's aim to directly address the exact issue and consider
                  sharing the part payment VA only as a last resort, if
                  necessary.
                </Typography>
              </>
            ),
            onConfirm: () => {
              showFinalDialog();
            },
            confirmProps: {
              text: "Confirm",
            },
            cancelProps: {
              text: "Cancel",
            },
            onCancel: () => {
              onClose();
            },
          });
        } else {
          showFinalDialog();
        }
      }}
      onCancel={onClose}
    >
      <Typography>
        Please enter the part payment amount that the customer wishes to pay
      </Typography>
      <br />
      <IGTextField
        name="partPaymentAmount"
        value={partPaymentAmount ?? ""}
        label="Part Payment Amount"
        type="number"
        inputProps={{
          min: 0,
        }}
        onChange={(e) => {
          if (!e.target.value) {
            setPartPaymentAmount(undefined);
          } else {
            setPartPaymentAmount(Number(e.target.value));
          }
        }}
      />
    </IGDialog>
  );
};

const VATable: FC<VATableProps> = () => {
  const {showSuccess} = useSnackBar();
  const {loanId} = useParams();
  const dispatch = useAppDispatch();
  const {showConfirmDialog} = useIGDialog();
  const {t, tErrors} = useIGTranslation();

  const {requestId} = useAppSelector(servicesSelectors.getRowAction);
  const serviceRequest = useAppSelector((state) =>
    selectServiceRequestById(state, requestId),
  );
  const virtualAddressList = useAppSelector((state) =>
    loanSelectors.getAllVirtualAddresses(
      state,
      Number(loanId || serviceRequest?.loanId),
    ),
  );
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(
      state,
      Number(loanId || serviceRequest?.loanId),
    ),
  );
  const {
    getAllVirtualAddresses: getAllVirtualAddressesLoading,
    generateVirtualAddress: generateVirtualAddressLoading,
  } = useAppSelector(loanSelectors.getLoading);
  const {
    getAllVirtualAddresses: getAllVirtualAddressesErrors,
  } = useAppSelector(loanSelectors.getErrors);

  const [currentGeneratedEnum, setCurrentGeneratedEnum] = React.useState<
    VirtualAddressVAEnum
  >();
  const [showPartPaymentDialog, setShowPartPaymentDialog] = React.useState<
    boolean
  >(false);
  const [isInAppPaymentAllowed, setIsInAppPaymentAllowed] = React.useState<
    boolean
  >(false);

  const generateVa = async (type: VirtualAddressVAEnum) => {
    setCurrentGeneratedEnum(type);

    if (type === "PART_PREPAYMENT") {
      showConfirmDialog({
        title: "Part Payment VA",
        content: (
          <>
            <Typography>
              {isInAppPaymentAllowed
                ? "In-App part payment is already enabled. Do you still want to generate part-payment VA?"
                : "Do you want to enable part payment option on consumer app?"}
            </Typography>
          </>
        ),
        onConfirm: () => {
          if (isInAppPaymentAllowed) return;
          setShowPartPaymentDialog(true);
        },
        confirmProps: {
          text: isInAppPaymentAllowed
            ? "No"
            : "No, generate part payment VA only",
        },
        cancelProps: {
          text: isInAppPaymentAllowed ? "Yes" : "Yes, enable in-app payment",
        },
        onCancel: () => {
          if (isInAppPaymentAllowed) {
            setShowPartPaymentDialog(true);
          } else {
            dispatch(
              customerProfileActions.enablePartPaymentFlag({
                loanId: Number(loanId || serviceRequest?.loanId),
              }),
            );
          }
        },
      });
    } else {
      dispatch(
        loanActions.generateVirtualAddress({
          loanId: Number(loanId || serviceRequest?.loanId),
          virtualAddressType: type,
        }),
      );
    }
  };

  const fields: { name: string; field: keyof VirtualAddressModel }[] = [
    {
      name: t("CUSTOMER_PROFILE.VIRTUAL_ADDRESS"),
      field: "virtualAddress",
    },
    {
      name: t("CUSTOMER_PROFILE.ACCOUNT_NUMBER"),
      field: "accountNumber",
    },
    {
      name: t("CUSTOMER_PROFILE.ACCOUNT_NAME"),
      field: "accountName",
    },
    {
      name: t("CUSTOMER_PROFILE.IFSC_CODE"),
      field: "ifscCode",
    },
    {
      name: t("CUSTOMER_PROFILE.UPI_ADDRESS"),
      field: "upiAddress",
    },
  ];

  useEffect(() => {
    if (!loanId && !requestId) return;
    dispatch(
      loanActions.getAllVirtualAddresses({
        loanId: Number(loanId || serviceRequest?.loanId),
        requestId: loanId ? undefined : requestId,
      }),
    );
  }, [dispatch, loanId, requestId, serviceRequest]);

  useEffect(() => {
    if (!loanDetails) return;
    setIsInAppPaymentAllowed(loanDetails.partPaymentEnabledOnApp);
  }, [loanDetails]);

  if (getAllVirtualAddressesLoading) {
    return <IGLoading height="300px" />;
  }

  if (getAllVirtualAddressesErrors) {
    return (
      <Alert severity="error" color="error" sx={{width: "100%"}}>
        {getAllVirtualAddressesErrors?.message ||
          tErrors("SOMETHING_WENT_WRONG")}
      </Alert>
    );
  }

  return (
    <>
      <Paper elevation={2} sx={{p: 2, overflow: "auto"}}>
        {virtualAddressList &&
          virtualAddressList.length > 0 &&
          virtualAddressList.map((address, index) => {
            return (
              <Accordion
                key={address.virtualAddressType}
                expanded={!!address.virtualAddress}
              >
                <AccordionSummary>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack>
                      <Typography fontWeight="bold">
                        {address.virtualAddressTypeDisplayName}
                      </Typography>
                      {address.expiryDate && (
                        <Typography variant="caption">
                          Valid Till:{" "}
                          {getValidTime(address.expiryDate).format(
                            "DD MMM,YYYY",
                          )}
                        </Typography>
                      )}
                    </Stack>
                    {loanId && (
                      <IGButton
                        onClick={() => generateVa(address.virtualAddressType)}
                        loading={
                          generateVirtualAddressLoading &&
                          currentGeneratedEnum === address.virtualAddressType
                        }
                        sx={{
                          ml: 4,
                        }}
                      >
                        {`Generate ${address.virtualAddressTypeDisplayName} VA`}
                      </IGButton>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <Box
                      p={1}
                      border="1px solid"
                      borderRadius={1}
                      bgcolor="rgba(206, 159, 41, 0.08)"
                    >
                      <Grid container spacing={1}>
                        {fields.map((item) => (
                          <>
                            <Grid item xs={4}>
                              <Typography>{item.name}</Typography>
                            </Grid>
                            <Grid item xs={8} fontWeight="bold">
                              <Typography>
                                {address[item.field] || "-"}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Box>
                    <Typography
                      mt={1}
                      onClick={() => {
                        copy(
                          `VA Type: ${address.virtualAddressTypeDisplayName}\nVA: ${address.virtualAddress}\nAccount Number: ${address.accountNumber}\nAccount Name: ${address.accountName}\nIFSC Code: ${address.ifscCode}\nUPI Address: ${address.upiAddress}`,
                        );
                        showSuccess("Copied to clipboard");
                      }}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color="primary"
                    >
                      Copy VA Details
                    </Typography>
                  </>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Paper>
      {showPartPaymentDialog && (
        <PartPaymentAmountDialog
          open={showPartPaymentDialog}
          onClose={() => setShowPartPaymentDialog(false)}
        />
      )}
    </>
  );
};

export default VATable;
