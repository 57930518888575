import React, {FC, useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {PersonRounded} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {addressActions, addressSelectors} from "app/store/address";
import {customerProfileSelectors} from "app/store/customerProfile";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {IGBoxBackdrop} from "app/components";
import lenderCustomerProfileActions from "app/store/lenderCustomerProfile/lenderCustomerProfile.actions";
import lenderCustomerProfileSelectors from "app/store/lenderCustomerProfile/lenderCustomerProfile.selectors";
import {formatMoment} from "../../../../../../_metronic/utils/utils";
import {AddressModel} from "app/models/address.model";

interface CustomerDetailsProps {
}

const CustomerDetails: FC<CustomerDetailsProps> = () => {

  const dispatch = useAppDispatch();
  const {loanId, userId} = useParams();
  const {t} = useIGTranslation();

  const customerDetails = useAppSelector((state) =>
    lenderCustomerProfileSelectors.getCustomerDetails(state, Number(userId)),
  );
  const addressList = useAppSelector(addressSelectors.getAddressList);
  const {
    getAddressList: getAddressListLoading,
  } = useAppSelector(addressSelectors.getLoading);
  const {
    getCustomerDetails: getCustomerDetailsLoading,
  } = useAppSelector(customerProfileSelectors.getLoading);

  const residentialAddress = useMemo(() => {
    return addressList.filter(
      (address: AddressModel) => address.addressType === "RESIDENTIAL_ADDRESS",
    )?.[0];
  }, [addressList]);

  const permanentAddress = useMemo(() => {
    return addressList.filter(
      (address: AddressModel) => address.addressType === "PERMANENT_ADDRESS",
    )?.[0];
  }, [addressList]);

  useEffect(() => {
    if (!loanId) return;
    dispatch(
      addressActions.getLenderAddressList({
        loanId: Number(loanId),
      }),
    );
    dispatch(
      lenderCustomerProfileActions.getCustomerDetails({
        loanId: Number(loanId),
      }),
    );
  }, [loanId, dispatch]);

  const headerDetailsList = [
    {
      name: t("NAME"),
      value: customerDetails?.name || "-",
    },
    {
      name: t("DOB"),
      value: formatMoment(customerDetails?.dob, "DD/MM/YYYY", false),
    },
    {
      name: t("RESIDENTIAL_ADDRESS"),
      value: `
      ${residentialAddress?.addressLine1 || ""} 
      ${residentialAddress?.addressLine2 || ""} 
      ${residentialAddress?.addressLine3 || ""}
      `,
      subValue: residentialAddress ? `
        Landmark - 
        ${residentialAddress?.landmark || ""} 
        ${residentialAddress?.state || ""} 
        ${residentialAddress?.pincode || ""}
      ` : "",
    },
    {
      name: t("PERMANENT_ADDRESS"),
      value: `
      ${permanentAddress?.addressLine1 || ""} 
      ${permanentAddress?.addressLine2 || ""} 
      ${permanentAddress?.addressLine3 || ""}
      `,
      subValue: permanentAddress ? `
        Landmark - 
        ${permanentAddress?.landmark || ""} 
        ${permanentAddress?.state || ""} 
        ${permanentAddress?.pincode || ""}
      ` : "",
    },
  ];

  return (
    <Box border="1px solid #D1D5DB" p={2} borderRadius={1} position="relative">
      <IGBoxBackdrop
        loading={getAddressListLoading || getCustomerDetailsLoading}
      />

      <Box display="flex" alignItems="flex-start" gap={4}>
        <Box
          height={84} width={84} borderRadius={1} overflow="hidden"
          border="1px solid #2c363b"
        >
          {customerDetails?.profilePicture ? (
            <img
              height="100%" width="100%"
              src={customerDetails?.profilePicture}
              alt=""
            />
          ): (
            <PersonRounded sx={{height: "100%", width: "100%"}}/>
          )}
        </Box>

        {headerDetailsList.map((i) => (
          <Box key={i.name} display="flex" flexDirection="column">
            <Typography variant="subtitle1" color="#64748B" mb={1}>{i.name}</Typography>
            <Box flex={1} alignSelf="flex-end">
              <Typography variant="h6" color="#334155" fontWeight="bold">{i.value}</Typography>
              {i.subValue && (
                <Typography variant="h6" color="#334155" fontWeight="bold">{i.subValue}</Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomerDetails;