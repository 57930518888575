/* eslint-disable no-console */
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  RemoveCircleRounded,
  PhoneRounded,
  LocalPhone,
} from "@mui/icons-material";
import {Backdrop, Box} from "@mui/material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {IframeContainer, IframeHeader, ToolbarIconWrapper} from "./styles";
import Bugsnag from "@bugsnag/js";
import IGButton from "../Button/IGButton";
import {dialerActions, dialerSelectors} from "app/store/dialer";
import {RolesEnum} from "app/enums/roles";
import {whitelistedUsers} from "./ameyoAccessList";
import EventDetails from "./EventDetails";
import ScheduleDrawer from "./ScheduleDrawer";
import IGLoading from "../Loading";

const AmeyoToolbar = () => {
  const dispatch = useAppDispatch();
  const [showToolbar, setShowAmeyoToolbar] = useState(false);
  const user = useAppSelector(authSelectors.getUser);
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const {getEventDetails: getEventDetailsLoading} = useAppSelector(
    dialerSelectors.getLoading,
  );
  const [eventDetails, setEventDetails] = useState({
    requestId: "",
    mobile: "",
  });
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showTodaySchedule, setShowTodaySchedule] = useState(false);
  const backdropTimeout = useRef(null);

  const canAccessAmeyoToolbar = useMemo(
    () =>
      user &&
      (whitelistedUsers.includes(user.email) ||
        user?.userRoleList?.some((role) =>
          [RolesEnum.ADMIN, RolesEnum.SCHEDULER, RolesEnum.AMEYO_USER].includes(
            role,
          ),
        )),
    [user],
  );

  async function customShowCrm(phoneNo, additionalParams) {
    console.log("customShowCrm : ", phoneNo, additionalParams);
    setShowAmeyoToolbar(true);
    const newAddParam = JSON.parse(additionalParams || "{}");
    const customerInfo = JSON.parse(newAddParam.customerInfo || "{}");

    if (newAddParam?.callType === "outbound.auto.preview.dial") {
      if (backdropTimeout.current) {
        clearTimeout(backdropTimeout.current);
      }
      setShowBackdrop(true);
      backdropTimeout.current = setTimeout(() => {
        setShowBackdrop(false);
        clearTimeout(backdropTimeout.current);
        backdropTimeout.current = null;
      }, 8000);
    }

    if (customerInfo?.request_id) {
      setEventDetails({
        requestId: customerInfo.request_id,
        mobile: phoneNo,
      });
      dispatch(
        dialerActions.getEventDetails({requestId: customerInfo.request_id}),
      );
    } else {
      setEventDetails({
        requestId: "",
        mobile: phoneNo,
      });
    }
  }

  function handleLogin(reason) {
    console.log("handleLogin : ", reason);
  }
  function handleOnLoad() {
    console.log("handleOnLoad : success");
  }
  function handleLoginStatus(status) {
    localStorage.setItem("ameyoLoginStatus", status);
    console.log(`handleLoginStatus : ${status}`);
  }
  function handleReady(status) {
    console.log("handleReady >>>", status);
  }
  function handleBreak(status) {
    console.log(`Break : ${status}`);
  }
  function handleLogout(status) {
    console.log(`Logout : ${status}`);
  }

  const assignEventHandlers = () => {
    Bugsnag.leaveBreadcrumb("Assigning Event Handlers");
    console.log("Assigning Event Handlers", ameyo.integration);
    const customIntegration = {};
    customIntegration.showCrm = customShowCrm;
    customIntegration.loginHandler = handleLogin;
    customIntegration.onLoadHandler = handleOnLoad;
    customIntegration.loginStatusHandler = handleLoginStatus;
    customIntegration.readyHandler = handleReady;
    customIntegration.breakHandler = handleBreak;
    customIntegration.logoutHandler = handleLogout;
    ameyo.integration.registerCustomFunction("showCrm", customIntegration);
    ameyo.integration.registerCustomFunction("loginHandler", customIntegration);
    ameyo.integration.registerCustomFunction(
      "onLoadHandler",
      customIntegration,
    );
    ameyo.integration.registerCustomFunction(
      "loginStatusHandler",
      customIntegration,
    );
    ameyo.integration.registerCustomFunction("readyHandler", customIntegration);
    ameyo.integration.registerCustomFunction("breakHandler", customIntegration);
    ameyo.integration.registerCustomFunction(
      "logoutHandler",
      customIntegration,
    );
  };

  const injectAmeyoScript = () => {
    // CHECK IF SCRIPT IS ALREADY LOADED
    let ameyoScript = document.getElementById("ameyo-dialer-script");

    if (ameyoScript) {
      console.log("Script Already Exists");
      Bugsnag.leaveBreadcrumb("Script Already Exists");
      return;
    }

    ameyoScript = document.createElement("script");
    ameyoScript.src = process.env.REACT_APP_AMEYO_SCRIPT_URL;
    ameyoScript.id = "ameyo-dialer-script";
    document.body.appendChild(ameyoScript);

    // init iframe on script load
    ameyoScript.addEventListener("load", () => {
      assignEventHandlers();
      console.log("Script Loaded --> Assigned Event Handlers");
      Bugsnag.leaveBreadcrumb("Script Loaded --> Assigned Event Handlers");
    });
  };

  const toggleIframe = () => {
    const iframeDiv = document.getElementById("ameyoIframe");

    if (iframeDiv) {
      if (iframeDiv.style.display === "block") {
        iframeDiv.style.display = "none";
        setShowAmeyoToolbar(false);
      } else if (iframeDiv.style.display === "none") {
        if (!canAccessAmeyoToolbar) return;

        iframeDiv.style.display = "block";
        setShowAmeyoToolbar(true);
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      window.ameyo?.integration?.doLogout();
    }
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <Backdrop open={showBackdrop} style={{zIndex: 9998}}>
        <LocalPhone
          sx={{
            color: "white",
            fontSize: "5rem",
            // pulse animation
            animation: "pulse 1s infinite",
            "@keyframes pulse": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.1)",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
        />
      </Backdrop>
      <IframeContainer id="iframeDivContainer">
        <IframeHeader id="iframeHeader" open={showToolbar}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: "4px 8px",
                display: showToolbar ? "block" : "none",
              }}
            >
              <IGButton
                onClick={() => setShowTodaySchedule(!showTodaySchedule)}
                sx={{
                  mr: 1,
                }}
              >
                Today's Schedule
              </IGButton>
              {getEventDetailsLoading && <IGLoading height="4rem" />}
              {eventDetails.requestId || eventDetails.mobile ? (
                <EventDetails
                  id={eventDetails.requestId}
                  phoneNo={eventDetails.mobile}
                />
              ) : null}
            </Box>
            <ToolbarIconWrapper>
              {showToolbar ? (
                <RemoveCircleRounded onClick={toggleIframe} />
              ) : (
                <PhoneRounded onClick={toggleIframe} />
              )}
            </ToolbarIconWrapper>
          </Box>
        </IframeHeader>
        <iframe
          style={{
            display: showToolbar ? "block" : "none",
          }}
          id="ameyoIframe"
          height="450px"
          width="350px"
          allow="camera *; geolocation *; microphone *; autoplay *"
          src={`${process.env.REACT_APP_AMEYO_TOOLBAR_URL}?origin=${window.location.origin}`}
          title="ameyo-toolbar"
          onLoad={() => {
            console.log("Iframe Loaded");
            Bugsnag.leaveBreadcrumb("Iframe Loaded");
            injectAmeyoScript();
          }}
        ></iframe>
      </IframeContainer>
      <div
        id="crmPage"
        style={{
          display: "none",
        }}
      ></div>
      <ScheduleDrawer
        open={showTodaySchedule}
        onClose={() => setShowTodaySchedule(false)}
      />
    </>
  );
};

export default AmeyoToolbar;
