import React, {useCallback, useEffect, useState, useRef} from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import {Grid, Typography} from "@mui/material";
import Filter from "./Filter";
import {useSnackBar} from "app/store/hooks";
import {Header, Body} from "./styles";
import Table from "app/components/Tables";
import {whitelistUsersAction, whitelistUserSelectors} from "app/store/users";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {WhiteListUserEnum} from "app/enums/whitelistUser";
import {
  checkIPv4orIPv6,
  checkIsAlphanumeric,
  checkIsNumberic,
} from "lib/regexChecks";
import {IGDialog, IGSelect, IGTextField} from "app/components";

const searchFiltersInitialValue = {
  id: "",
  whitelistId: "",
  whitelistType: "",
};

const WhitelistUsers = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const counterRef = useRef(0);
  const [rowId, setRowId] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newWhitelistId, setNewWhitelistId] = useState("");
  const [newWhitelistType, setNewWhitelistType] = useState("DEVICE_ID");
  const [currentPage, setCurrentPage] = useState(1);
  const mainTableHeader = [
    "ID",
    "WHITELIST TYPE",
    "WHITELIST ID",
    "UPDATED AT",
    "CREATED AT",
    "DELETE",
  ];

  const [searchFilters, setSearchFilters] = useState(searchFiltersInitialValue);

  const fitlerPropRef = useRef({});

  const whitelistData = useAppSelector(
    whitelistUserSelectors.getWhitelistUsers,
  );

  const {isFetching, isUpdating, isDeleting} = useAppSelector(
    whitelistUserSelectors.getState,
  );

  const handleClose = () => setOpenEditModal(edit => !edit);

  const handleDeleteClose = () => setOpenDeleteModal(del => !del);

  const getWhitelistUsers = useCallback(
    props => {
      try {
        const filterOverride = {};
        for (const [key, value] of searchParams) {
          if (key in searchFilters) {
            filterOverride[key] = value;
          }
        }

        setSearchFilters(el => {
          return {
            ...searchFilters,
            ...filterOverride,
            ...props,
          };
        });

        const defaultParams = {
          pageNo: currentPage,
          pageSize: 10,
          ...filterOverride,
          ...props,
        };

        for (const [key, value] of Object.entries(defaultParams)) {
          if (!value) {
            delete defaultParams[key];
          }
        }

        setSearchParams(createSearchParams(defaultParams));

        dispatch(whitelistUsersAction.getWhitelistUsers(defaultParams));
      } catch (e) {
        snackbar.showError(e || "Error while getting whitelist users");
      }
    },
    [
      dispatch,
      snackbar,
      currentPage,
      searchParams,
      searchFilters,
      setSearchParams,
    ],
  );

  const handleModify = () => {
    try {
      dispatch(
        whitelistUsersAction.createWhitelistUser({
          modifyPayload: {
            whitelistId: newWhitelistId,
            whitelistType: newWhitelistType,
          },
          fetchPayload: {},
        }),
      );

      const handleModifyModalClose = () => {
        if (!isUpdating) {
          setOpenEditModal(false);
          setNewWhitelistId("");
        }
      };

      setTimeout(handleModifyModalClose, 100);
    } catch (e) {
      snackbar.showError(e || "Error while creating whitelist user");
    }
  };

  const handleDelete = () => {
    try {
      dispatch(
        whitelistUsersAction.deleteWhitelistUser({
          deletePayload: {
            id: rowId,
          },
          fetchPayload: {},
        }),
      );

      const handleDeleteModalClose = () => {
        if (!isDeleting) {
          setOpenDeleteModal(false);
        }
      };

      setTimeout(handleDeleteModalClose, 100);
    } catch (e) {
      snackbar.showError(e || "Error while deleting whitelist user");
    }
  };

  const onClickNextHandler = useCallback(() => {
    getWhitelistUsers({pageNo: currentPage + 1, ...fitlerPropRef.current});
    setCurrentPage(page => page + 1);
  }, [currentPage, getWhitelistUsers]);

  const onClickPrevHandler = useCallback(() => {
    if (currentPage > 0) {
      getWhitelistUsers({pageNo: currentPage - 1, ...fitlerPropRef.current});
      setCurrentPage(page => page - 1);
    }
  }, [currentPage, getWhitelistUsers]);

  useEffect(() => {
    if (counterRef.current === 0) {
      getWhitelistUsers();
      counterRef.current = 1;
    }
  }, [getWhitelistUsers]);

  const handleReset = useCallback(() => {
    navigate(window.location.pathname);
    setSearchFilters(searchFiltersInitialValue);
    fitlerPropRef.current = searchFiltersInitialValue;
    getWhitelistUsers(searchFiltersInitialValue);
  }, [setSearchFilters, getWhitelistUsers, navigate]);

  const handleFilter = useCallback(() => {
    navigate(
      `${window.location.pathname}?${createSearchParams(searchFilters)}`,
    );

    getWhitelistUsers(searchFilters);
  }, [searchFilters, getWhitelistUsers, navigate]);

  /**
   *
   * @param {string} value new value recived on onChange.
   * @param {WhiteListEnum} filterType to check the type of Id.
   * @param {onChangeEvent} setCallback to set New value after check.
   * @returns void
   */
  const handleWhitelistIdChange = (value, filterType, setCallback) => {
    // return if filter type is not in Whitelist Enum.
    if (!Object.keys(WhiteListUserEnum).includes(filterType)) {
      return;
    }

    if (filterType === "USER_ID") {
      if (value === "" || parseInt(value).toString() === value) {
        setCallback(value);
        return;
      }
      return;
    }

    if (filterType === "DEVICE_ID") {
      if (value === "" || checkIsAlphanumeric(value)) {
        setCallback(value);
        return;
      }
      return;
    }

    // because the value is in Enum, otherwise would have been returned from top.
    setCallback(value);
  };

  const isError = () =>
    newWhitelistType === "DEVICE_IP"
      ? !checkIPv4orIPv6(newWhitelistId)
      : newWhitelistType === "DEVICE_ID"
        ? !(newWhitelistId.length > 5)
        : !checkIsNumberic(newWhitelistId);

  return (
    <div>
      <Header>Whitelist Users</Header>
      <Body>
        <Table
          title="WHITELIST USERS"
          tableHeaders={mainTableHeader}
          tableData={whitelistData}
          isPaginated={true}
          currentPage={currentPage}
          onClickNextHandler={onClickNextHandler}
          onClickPrevHandler={onClickPrevHandler}
          enableLoading={true}
          loadingState={isFetching || isUpdating || isDeleting}
          enableAdding={true}
          setRowId={setRowId}
          setAddEditModal={setOpenEditModal}
          enableDeletion={true}
          setOpenDeleteModal={setOpenDeleteModal}
          filterComponent={
            <Filter
              {...{
                searchFilters,
                setSearchFilters,
                handleFilter,
                handleReset,
              }}
            />
          }
        />
      </Body>

      <IGDialog
        title="Create New User"
        subtitle="To create new user please enter both Whitelist Id and Type."
        open={openEditModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        onCancel={handleClose}
        onConfirm={handleModify}
        confirmProps={{
          text: "Create",
          disabled: isError(),
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IGSelect
              name="whilteListType"
              label="Whitelist Type"
              value={newWhitelistType}
              onChange={e => {
                setNewWhitelistId("");
                setNewWhitelistType(e.target.value);
              }}
              options={Object.entries(WhiteListUserEnum)
                .map(([key, value]) => ({
                  text: key,
                  value: value,
                }))}
            />
          </Grid>

          <Grid item xs={12}>
            <IGTextField
              value={newWhitelistId}
              onChange={e =>
                handleWhitelistIdChange(
                  e.target.value,
                  newWhitelistType,
                  setNewWhitelistId,
                )
              }
              name="whitelistId"
              label="Whitelist Id"
              error={isError()}
              touched={isError()}
              helperText={isError() ? "Invalid Input" : ""}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </IGDialog>

      <IGDialog
        title="Delete User"
        open={openDeleteModal}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        onCancel={handleDeleteClose}
        confirmProps={{
          text: "Delete",
          color: "error",
        }}
      >
        <Typography>
          Are you sure you want to delete this user?
        </Typography>
      </IGDialog>
    </div>
  );
};

export default WhitelistUsers;
