import {createAction} from "@reduxjs/toolkit";
import {RefferalConfigState} from "./reducer";

export const RefferalConfigTypes = {
  GetRefferalConfig: "[Refferal Config] Get Refferal Config",
  GetRefferalConfigSuccess: "[Refferal Config] Get Refferal Config Success",
  GetRefferalConfigError: "[Refferal Config] Get Refferal Config Error",
  UpdateRefferalConfig: "[Refferal Config] Udpate Refferal Config User",
  UpdateRefferalConfigSuccess:
    "[Refferal Config] Udpate Refferal Config Success",
  UpdateRefferalConfigError: "[Refferal Config] Udpate Refferal Config Error",
  UpdateRefferalImage: "[Refferal Config] Udpate Refferal Image",
  UpdateRefferalImageSuccess: "[Refferal Config] Udpate Refferal Image Success",
  UpdateRefferalImageError: "[Refferal Config] Udpate Refferal Image Error",
};

export const refferalConfigAction = {
  getRefferalConfig: createAction(RefferalConfigTypes.GetRefferalConfig),
  getRefferalConfigSuccess: createAction<RefferalConfigState>(
    RefferalConfigTypes.GetRefferalConfigSuccess,
  ),
  getRefferalConfigError: createAction<RefferalConfigState>(
    RefferalConfigTypes.GetRefferalConfigError,
  ),
  updateRefferalConfig: createAction(RefferalConfigTypes.UpdateRefferalConfig),
  updateRefferalConfigSuccess: createAction<RefferalConfigState>(
    RefferalConfigTypes.UpdateRefferalConfigSuccess,
  ),
  updateRefferalConfigError: createAction<RefferalConfigState>(
    RefferalConfigTypes.UpdateRefferalConfigError,
  ),
  updateRefferalImage: createAction(RefferalConfigTypes.UpdateRefferalImage),
  updateRefferalImageSuccess: createAction(
    RefferalConfigTypes.UpdateRefferalImageSuccess,
  ),
  updateRefferalImageError: createAction<RefferalConfigState>(
    RefferalConfigTypes.UpdateRefferalImageError,
  ),
};
