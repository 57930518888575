import {createAction} from "@reduxjs/toolkit";
import {
  LmUserCityPayload,
  UpdateUserRolePayload,
  UserIdPayload,
  UserStatusPayload,
  UserOrganizationRolePayload,
} from "app/infra/services/api/internalUser/types";
import {QueryParams} from "app/typings/api/goldApi.types";
import {setPaginationFor} from "../factoryReducers";

interface SetFilterPayload {
  email?: string;
  mobile?: number;
  name?: string;
  userId?: number;
  userStatus?: string;
  fromTime?: string | number;
  toTime?: string | number;
}

const internalUserActionTypes = {
  GetInternalUserList: "[Internal User] Get Internal User List Start",
  GetInternalUserListSuccess: "[Internal User] Get Internal User List Success",
  GetInternalUserListFailure: "[Internal User] Get Internal User List Failure",
  GetAllCities: "[Internal User] Get All Cities",
  GetAllCitiesSuccess: "[Internal User] Get All Cities Success",
  GetAllCitiesFailure: "[Internal User] Get All Cities Failure",
  GetUserCities: "[Internal User] Get User Cities",
  GetUserCitiesSuccess: "[Internal User] Get User Cities Success",
  GetUserCitiesFailure: "[Internal User] Get User Cities Failure",
  GetAllRoles: "[Internal User] Get All Roles",
  GetAllRolesSuccess: "[Internal User] Get All Roles Success",
  GetAllRolesFailure: "[Internal User] Get All Roles Failure",
  GetUserRoles: "[Internal User] Get User Roles",
  GetUserRolesSuccess: "[Internal User] Get User Roles Success",
  GetUserRolesFailure: "[Internal User] Get User Roles Failure",
  ChangeUserCity: "[Internal User] Change Lm User City",
  ChangeUserCitySuccess: "[Internal User] Change Lm User City Success",
  ChangeUserCityFailure: "[Internal User] Change Lm User City Failure",
  ChangeUserStatus: "[Internal User] Change Lm User Status",
  ChangeUserStatusSuccess: "[Internal User] Change Lm User Status Success",
  ChangeUserStatusFailure: "[Internal User] Change Lm User Status Failure",
  UpdateUserRoles: "[Internal User] Change Lm User Roles",
  UpdateUserRolesSuccess: "[Internal User] Change Lm User Roles Success",
  UpdateUserRolesFailure: "[Internal User] Change Lm User Roles Failure",
  SetFilter: "[Internal User] Set Filters For Internal User",
  GetAllUserTypes: "[Internal User] Get All User Types",
  GetAllUserTypesSuccess: "[Internal User] Get All User Types Success",
  GetAllUserTypesFailure: "[Internal User] Get All User Types Failure",
  ChangeOrganizationRole: "[Internal User] Change Organization Role",
  ChangeOrganizationRoleSuccess:
    "[Internal User] Change Organization Role Success",
  ChangeOrganizationRoleFailure:
    "[Internal User] Change Organization Role Failure",
};

const internalUserAction = {
  getInternalUserList: createAction<{queryParams: QueryParams}>(
    internalUserActionTypes.GetInternalUserList,
  ),
  getInternalUserListSuccess: createAction<any>(
    internalUserActionTypes.GetInternalUserListSuccess,
  ),
  getInternalUserListFailure: createAction<any>(
    internalUserActionTypes.GetInternalUserListFailure,
  ),
  getAllCities: createAction(internalUserActionTypes.GetAllCities),
  getAllCitiesSuccess: createAction<any>(
    internalUserActionTypes.GetAllCitiesSuccess,
  ),
  getAllCitiesFailure: createAction(
    internalUserActionTypes.GetAllCitiesFailure,
  ),
  getUserCities: createAction<UserIdPayload>(
    internalUserActionTypes.GetUserCities,
  ),
  getUserCitiesSuccess: createAction<any>(
    internalUserActionTypes.GetUserCitiesSuccess,
  ),
  getUserCitiesFailure: createAction(
    internalUserActionTypes.GetUserCitiesFailure,
  ),
  changeUserCity: createAction<LmUserCityPayload>(
    internalUserActionTypes.ChangeUserCity,
  ),
  changeUserCitySuccess: createAction<any>(
    internalUserActionTypes.ChangeUserCitySuccess,
  ),
  changeUserCityFailure: createAction(
    internalUserActionTypes.ChangeUserCityFailure,
  ),
  changeUserStatus: createAction<UserStatusPayload>(
    internalUserActionTypes.ChangeUserStatus,
  ),
  changeUserStatusSuccess: createAction<any>(
    internalUserActionTypes.ChangeUserStatusSuccess,
  ),
  changeUserStatusFailure: createAction(
    internalUserActionTypes.ChangeUserStatusFailure,
  ),
  updateUserRoles: createAction<UpdateUserRolePayload>(
    internalUserActionTypes.UpdateUserRoles,
  ),
  updateUserRolesSuccess: createAction(
    internalUserActionTypes.UpdateUserRolesSuccess,
  ),
  updateUserRolesFailure: createAction(
    internalUserActionTypes.UpdateUserRolesFailure,
  ),
  getAllRoles: createAction(internalUserActionTypes.GetAllRoles),
  getAllRolesSuccess: createAction<any>(
    internalUserActionTypes.GetAllRolesSuccess,
  ),
  getAllRolesFailure: createAction(internalUserActionTypes.GetAllRolesFailure),

  getUserRoles: createAction<UserIdPayload>(
    internalUserActionTypes.GetUserRoles,
  ),
  getUserRolesSuccess: createAction<any>(
    internalUserActionTypes.GetUserRolesSuccess,
  ),
  getUserRolesFailure: createAction(
    internalUserActionTypes.GetUserRolesFailure,
  ),
  getAllUserTypes: createAction(internalUserActionTypes.GetAllUserTypes),
  getAllUserTypesSuccess: createAction<any>(
    internalUserActionTypes.GetAllUserTypesSuccess,
  ),
  getAllUserTypesFailure: createAction<any>(
    internalUserActionTypes.GetAllUserTypesFailure,
  ),
  changeOrganizationRole: createAction<UserOrganizationRolePayload>(
    internalUserActionTypes.ChangeOrganizationRole,
  ),
  changeOrganizationRoleSuccess: createAction<any>(
    internalUserActionTypes.ChangeOrganizationRoleSuccess,
  ),
  changeOrganizationRoleFailure: createAction<any>(
    internalUserActionTypes.ChangeOrganizationRoleFailure,
  ),
  setPagination: setPaginationFor("internalUser"),
  setFilter: createAction<SetFilterPayload>(internalUserActionTypes.SetFilter),
};

export {internalUserActionTypes};
export default internalUserAction;
