import styled from "@emotion/styled";

interface Props {
  borderLeft: string;
  height: string;
}

const StyledDocumentContainer = styled.div<Props>`
  width: 100%;
  min-height: ${({height}) => height || "90"}%;
  border: ${({borderLeft}) => borderLeft && "1px solid #ce9f29"};
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  padding: 10px;
`;

export default StyledDocumentContainer;
