import styled from "@emotion/styled";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5rem;
`;

const Container = styled.div`
  height: 3rem;
  width: 100%;

  background-color: #fbf8ee;
  border: solid 2px #f5e9bd;
  border-radius: 12px;
  padding: 2rem 2rem 2rem 1.2rem;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
`;

const DocIconWrapper = styled.img`
  height: 6rem;
  width: 6rem;

  margin-right: 0.5rem;
`;

const DocTitle = styled.div`
  font-color: black;
  font-weight: 900;
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentIconWrapper = styled.img`
  height: 5rem;
  width: 5rem;

  margin-right: 0.25rem;
`;

const DocumentImageWrapper = styled.div`
  display: flex;
  margin-top: 1.2rem;
`;

const LeegalityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const MainTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 900;
  color: #d39c2f;
  line-height: 1.7;
  letter-spacing: 0.1px;
  margin-right: 1rem;
`;

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 900;
  color: #d39c2f;
  line-height: 1.7;
  letter-spacing: 0.1px;
`;
const GlIconImgWrapper = styled.img`
  width: auto;
  height: 2.5rem;
`;

const ViewPdfButton = styled.div`
  background-color: #fff4e0;
  color: #D39C2F;
  border-radius: 1rem;
  width: 50%;
  cursor: pointer;
  padding: 0.25rem 1.5rem 0.25rem 2.25rem;
`;

export {
  ButtonContainer,
  Container,
  DocIconWrapper,
  DocTitle,
  DocumentIconWrapper,
  DocumentImageWrapper,
  LeegalityContainer,
  MainTitle,
  Title,
  ViewPdfButton,
  GlIconImgWrapper,
};
