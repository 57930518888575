import React, {useCallback, useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {IGButton} from "app/components";
import {useAppDispatch, useSnackBar} from "app/store/hooks";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import getLoanSummaryDocument from "api/customerProfiles/getLoanSummaryDocument";
import getDocument from "api/business/getDocument";
import IGDocumentViewer from "app/components/IGDocumentViewer";
import {SignedSummaryDocEnum} from "app/enums/customerProfiles";
import {useParams} from "react-router-dom";
import {loanActions, loanSelectors} from "app/store/loan";

const DownloadLoanSummary = () => {

  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const {showError} = useSnackBar();
  const {loanId} = useParams();
  const dispatch = useAppDispatch();

  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );

  // local states
  const [signedLoanSummaryDoc, setSignedLoanSummaryDoc] = useState<any>(null);
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  const onClickDownloadDigitalLoanSummary = useCallback(async () => {
    try {
      const {response, error} = await getLoanSummaryDocument({
        loanId: String(loanId),
      });

      if (response) {
        window.open(response?.link);
      } else {
        showError(error?.message || "Unable to fetch Digital Loan Summary");
      }
    } catch (error) {
      showError("Something went wrong");
    }
  }, [loanId, showError]);

  const onClickDownloadSignedLoanSummary = useCallback(async () => {
    if (!loanDetails?.crId) {
      showError("crID not available");
      return;
    }
    const {payload: signedLoanSummaryPayload} = await getDocument({
      accessToken,
      crId: Number(loanDetails?.crId),
      documentType: [SignedSummaryDocEnum.SIGNED_LOAN_SUMMARY_DOCUMENT],
    });
    if (signedLoanSummaryPayload && signedLoanSummaryPayload.length) {
      setSignedLoanSummaryDoc(signedLoanSummaryPayload[0]);
      setIsDocumentReady(true);
    } else {
      showError("Signed loan summary not available");
    }
  }, [accessToken, loanDetails?.crId, showError]);

  useEffect(() => {
    if (!loanId) return;
    if (!loanDetails) {
      dispatch(
        loanActions.getLoanDetails({
          loanId: Number(loanId),
        }),
      );
    }
  }, [dispatch, loanDetails, loanId]);

  return (
    <>
      <Typography variant="h6">Download Loan Summary</Typography>
      <IGButton
        color="golden"
        onClick={() => onClickDownloadDigitalLoanSummary()}
        sx={{m: 1}}
      >
        DIGITAL LOAN SUMMARY
      </IGButton>

      <IGButton
        color="golden"
        onClick={() => {
          onClickDownloadSignedLoanSummary();
        }}
        sx={{m: 1}}
      >
        SIGNED LOAN SUMMARY
      </IGButton>

      {/* integrating IgDocument viewer in case of signed summary bcoz in case of
      signed response comes with multiple images not a single pdf file */}

      {isDocumentReady && (
        <IGDocumentViewer
          document={signedLoanSummaryDoc}
          open={isDocumentReady}
          onClose={() => setIsDocumentReady(false)}
        />
      )}
    </>
  );
};

export default DownloadLoanSummary;
