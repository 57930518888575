import React, {useCallback, useMemo} from "react";
import {loanKycDocumentTypeEnum as docType} from "app/enums/gold_loan/activeLoans/index";
import DocView from "../../../CommonComponents/DocView";
import BottomModal from "./ModalBottom";

const DocViewModals = ({
  documents,
  setDocView,
  docView,
  mainDocumentType,
  documentAddress,
  residentialAddress,
  personalDetail,
  permanentAddress,
}) => {
  const renderBottomModal = useCallback(
    (document) => {
      const fullName = `${personalDetail?.firstName} ${personalDetail?.lastName}`;

      return (
        <BottomModal
          mainDocumentType={mainDocumentType}
          permanentAddress={permanentAddress}
          personalDetail={personalDetail}
          residentialAddress={residentialAddress}
          mainDocumentDetails={documents.mainDocument}
          documentAddress={documentAddress}
          docDetails={document}
          fullName={fullName}
        />
      );
    },
    [
      documentAddress,
      mainDocumentType,
      permanentAddress,
      residentialAddress,
      personalDetail,
      documents,
    ],
  );

  const cleanDocData = useMemo(() => {
    return Object.keys(documents)
      .map((key) => {
        let documentTitle, documentType, modalTitle, showBottomModal;
        const document = documents[key];

        if (!document) return null;

        switch (key) {
          case "aadhar":
            modalTitle = "Aadhar Details";
            documentTitle = `${docType.AADHAR} Photo`;
            documentType = docType.AADHAR;
            showBottomModal = true;
            break;
          case "voterId":
            modalTitle = "Voter ID Details";
            documentTitle = "Voter ID Photo";
            documentType = docType.VOTER_ID;
            showBottomModal = true;
            break;
          case "drivingLicense":
            modalTitle = "Driving License Details";
            documentTitle = "Driving License Photo";
            documentType = docType.DRIVING_LICENSE;
            showBottomModal = true;
            break;
          case "customerImage":
            modalTitle = "Customer Image";
            documentTitle = "Customer Image";
            documentType = docType.CUSTOMER_IMAGE;
            showBottomModal = false;
            break;
          case "customerHomeImage":
            modalTitle = "Customer Home Image";
            documentTitle = "Customer Home Image";
            documentType = docType.CUSTOMER_HOME_IMAGE;
            showBottomModal = false;
            break;
          case "pan":
            modalTitle = "PAN Details";
            documentTitle = "PAN Card Photo";
            documentType = docType.PAN;
            showBottomModal = true;
            break;
          default:
            break;
        }

        const renderSecondDoc = ![
          docType.CUSTOMER_IMAGE,
          docType.CUSTOMER_HOME_IMAGE,
        ].includes(documentType);

        return {
          key,
          documentType,
          documentTitle,
          modalTitle,
          renderSecondDoc,
          docsList: [document.fileUrl, document.backFileUrl].filter(
            (d) => d,
          ),
          secondDocsList: renderSecondDoc
            ? [
                documents.customerImage?.fileUrl,
                documents.customerImage?.backFileUrl,
              ].filter((doc) => doc)
            : false,
          secondDocTitle: renderSecondDoc ? "Customer Image" : false,
          BottomComponent: showBottomModal
            ? () => renderBottomModal(document)
            : () => <></>,
        };
      })
      .filter((doc) => doc);
  }, [documents, renderBottomModal]);

  return (
    <>
      {cleanDocData.map((doc) => {
        return (
          <DocView
            open={docView === doc.documentType}
            docsList={doc.docsList}
            docTitle={doc.documentTitle}
            secondDocsList={doc.secondDocsList}
            secondDocTitle={doc.secondDocTitle}
            BottomComponent={doc.BottomComponent}
            title={doc.modalTitle}
            onCloseHandler={() => setDocView(null)}
          />
        );
      })}
    </>
  );
};

export default DocViewModals;
