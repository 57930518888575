import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@mui/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  LinearProgress,
  Radio,
} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {Col, Row} from "react-bootstrap";

import {formatDate} from "_metronic";
import axios from "_metronic/utils/axios";
import {API_ENDPOINTS} from "app/constants/constants";
import {useSnackBar} from "app/store/hooks";
import {IGButton} from "app/components";

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function BankDetails(props) {
  const classes = useStyles2();
  const {showError} = useSnackBar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [loading, setLoading] = React.useState({
    filters: false,
  });

  const headers = [
    "Holder Name",
    "Account Number",
    "Bank Code",
    "Bank Name",
    "IFSC Code",
    "Is Active?",
    "Created at",
  ];

  const valueKeys = [
    "accountHolderName",
    "accountNumber",
    "bankCode",
    "bankName",
    "ifscCode",
    "isActive",
    "createdAt",
  ];

  const counter = useRef(0);

  const fetchTableData = useCallback(
    (userId) => {
      setLoading({...loading, filters: true});
      axios
        .request({
          method: API_ENDPOINTS.USER.GET_BANK_DETAILS.METHOD,
          url: API_ENDPOINTS.USER.GET_BANK_DETAILS.URL.replace(
            ":userId",
            userId,
          ),
          data: null,
        })
        .then((response) => {
          setLoading({...loading, filters: false});
          if (response && response.data && response.data.result) {
            response.data.result.forEach((row) => {
              row.isActive = row.isActive ? "True" : "False";
              row.createdAt = formatDate(row.createdAt);
            });
            setRows(response.data.result);
          }
        })
        .catch((err) => {
          setLoading({...loading, filters: false});
          if (
            err &&
            err.response &&
            [401, 403, 422].includes(err.response.status)
          ) {
            showError(
                err.response.data &&
                err.response.data.error &&
                err.response.data.error.message,
            );
          } else {
            showError("Something went wrong");
          }
        });
    },
    [loading],
  );

  React.useEffect(() => {
    if (props.userId && counter.current === 0) {
      counter.current = 1;
      fetchTableData(props.userId);
    }
    return () => {};
  }, [fetchTableData, props.userId]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 100));
  }

  function applyFilters() {
    if (parseInt(searchFilter.userId) <= 0) {
      showError("User ID cannot be negative.");
      return;
    }
  }

  function clearFilters() {
    setSearchFilter({
      emiOrderStatus: "",
      userId: "",
    });
  }

  return (
    <Paper className={classes.root}>

      {/* HEADER */}
      {props.header ? (
        <Row className="mx-0">
          <Col className={classes.header}>
            BANK DETAILS FOR USER - {props.userId}
          </Col>
        </Row>
      ) : null}

      {/* FILTERS */}
      {props.filters ? (
        <Row className="my-3 mx-3">
          <Col md="2">
            <IGButton
              color="golden"
              onClick={() => applyFilters()}
            >
              Apply
            </IGButton>
            <IGButton
              color="sdGrey"
              onClick={() => clearFilters()}
            >
              Clear
            </IGButton>
          </Col>
        </Row>
      ) : null}

      {props.horizontalDetails ? (
        <Row className="my-2">
          {props.horizontalDetails.map((detail) => {
            return (
              <Col key={detail.name} className="mx-3" md="2">
                <Row>
                  <Col className="font-weight-bold">{detail.name}</Col>
                </Row>
                <Row>
                  <Col>{detail.value}</Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      ) : null}

      {/* TABLE */}
      {props.table ? (
        <div className={classes.tableWrapper}>
          {!loading.filters ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {props.select ? (
                    <TableCell align="center" key="Select"></TableCell>
                  ) : null}
                  {headers.map((header) => (
                    <TableCell align="center" key={header}>
                      {header}
                    </TableCell>
                  ))}
                  {props.actions ? (
                    <TableCell align="center" key="Actions">
                      Actions
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      onClick={() => {
                        if (!props.onSelect) {
                          return;
                        }
                        props.onSelect(row.id);
                        setSelectedRow(row.id);
                      }}
                      style={
                        props.select && selectedRow === row.id
                          ? {backgroundColor: "#FF92AC"}
                          : {}
                      }
                    >
                      {props.select ? (
                        <TableCell align="center" key="Select">
                          <Radio checked={selectedRow === row.id} />
                        </TableCell>
                      ) : null}
                      {valueKeys.map((key) => (
                        <TableCell
                          align="center"
                          key={key}
                          style={{minWidth: "100px"}}
                        >
                          {row[key]}
                        </TableCell>
                      ))}
                      {props.actions ? (
                        <TableCell align="center"></TableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={10}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {"aria-label": "Rows per page"},
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <LinearProgress />
          )}
        </div>
      ) : null}
    </Paper>
  );
}
