import {goldApiService} from "app/infra/plugins/axios";

export const CreateNucleasLoanAccounnt = async ({crId}) => {
  const resource = {
    URL: "/admin/business/cr/:crId/create-nucleus-loan-account",
    METHOD: "POST",
  };
  return await goldApiService({
    resource,
    options: {
      pathVars: {crId},
    },
  });
};
