import React, {useState, useEffect, useCallback, FC, ChangeEvent} from "react";
import {Box, Grid} from "@mui/material";
import Filter from "./Filter";
import {useAppSelector, useAppDispatch} from "app/store/hooks";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import ActiveLoanListing from "./ActiveLoanListing";
import {GetActiveLoansCRListPayload} from "app/infra/services/api/activeLoans/types";
import {cmStatusEnum} from "app/enums/gold_loan/activeLoans/filter";
import {useSearchParams} from "react-router-dom";
import {
  resetFiltersState,
  setActiveTab,
  resetActiveLoansState,
} from "app/store/activeLoans/activeLoans.reducer";
import {IGSection, IGSectionContent, IGSectionHeader} from "app/components";
import {StyledTabsContainer, StyledTab} from "app/components/Tabs/styles";
import {ActiveLoansType} from "app/models/activeLoans/activeLoans.model";

interface ActionLoanPropsTypes {
  locker: boolean;
}

const loanJobsTimer = 1000;

const ActiveLoan: FC<ActionLoanPropsTypes> = ({locker}) => {
  const [drawer, setDrawer] = useState(false);

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromURL = Number(searchParams.get("activeLoansPageNo"));
  const pageSizeFromURL = Number(searchParams.get("activeLoansPageSize"));
  const activeTab = useAppSelector(activeLoansSelectors.getActiveTab);

  const activeLoansTabsList = useAppSelector(
    activeLoansSelectors.getActiveLoansTabsList,
  );

  const filtersData = useAppSelector(activeLoansSelectors.getFilters);
  const {pageNo, pageSize} = useAppSelector(activeLoansSelectors.getPagination);

  const getCRType = useCallback(() => {
    if (locker) {
      return "GOLD_LOCKER_PICKUP,GOLD_LOCKER_RELEASE";
    }
    return "GOLD_LOAN_BT,GOLD_LOAN_FRESH";
  }, [locker]);

  const getCustomerRequestsList = useCallback(() => {
    const payload: GetActiveLoansCRListPayload = {
      pageNo: pageNo,
      pageSize: pageSize,
      crId: filtersData.selectedCrId || "",
      crType: getCRType(),
      fetchActive: filtersData.fetchActive,
      sortCriteria: filtersData.sortCriteria || "",
      sortOrder: filtersData.sortOrder || "",
      isCmQueue: activeTab === "CREDIT_QUEUE",
    };

    if (!Boolean(filtersData.selectedCrId)) {
      payload.startTime = filtersData.fromDate;
      payload.endTime = filtersData.toDate;
    }

    if (filtersData.cmStatus !== cmStatusEnum.ALL_CM_STATUS) {
      payload.cmStatus = filtersData.cmStatus;
    }

    if (filtersData.selectedCityId !== 0) {
      payload.cityId = filtersData.selectedCityId;
    }

    dispatch(activeLoansActions.getCrList(payload));
  }, [pageSize, pageNo, filtersData, activeTab, getCRType, drawer]);

  const onChangeTab = (event: ChangeEvent<{}>, newValue: ActiveLoansType) => {
    dispatch(setActiveTab(newValue));
  };

  useEffect(() => {
    for (const [field, value] of Object.entries(filtersData)) {
      if (value && value !== "null") {
        searchParams.set(field, String(value));
      } else {
        searchParams.delete(field);
      }
      setSearchParams(searchParams, {replace: true});
    }
  }, [filtersData]);

  useEffect(() => {
    if (pageFromURL !== pageNo) {
      searchParams.set("activeLoansPageNo", String(pageNo));
    }
    if (pageSizeFromURL !== pageSize) {
      searchParams.set("activeLoansPageSize", String(pageSize));
    }
    setSearchParams(searchParams, {replace: true});
  }, [pageNo, pageSize]);

  useEffect(() => {
    dispatch(activeLoansActions.getCitiesList());
    dispatch(activeLoansActions.getActiveLoansTabsList());
  }, []);

  useEffect(() => {
    getCustomerRequestsList();
  }, [getCustomerRequestsList]);

  useEffect(() => {
    return () => {
      dispatch(resetFiltersState());
    };
  }, []);

  return (
    <IGSection>
      <IGSectionHeader title="CURRENT ACTIVE JOBS">
        <Box mt={2} mb={-3}>
          <StyledTabsContainer value={activeTab} onChange={onChangeTab}>
            {activeLoansTabsList?.map((i: any, index: number) => (
              <StyledTab
                iconPosition="start"
                key={i.tabName + index}
                label={i.tabName}
                value={i.tabEnum}
                className={activeTab === i.tabEnum ? "selected" : ""}
              />
            ))}
          </StyledTabsContainer>
        </Box>
      </IGSectionHeader>

      <IGSectionContent>
        <Grid container bgcolor="#f5f5f5" spacing={2} px={2}>
          <Grid item xs={12}>
            <Filter />
          </Grid>

          <Grid item xs={12}>
            <ActiveLoanListing
              drawer={drawer}
              setDrawer={setDrawer}
              cr={getCRType()}
              isLocker={getCRType().includes("GOLD_LOCKER_PICKUP")}
              filtersData={filtersData}
              locker={locker}
            />
          </Grid>
        </Grid>
      </IGSectionContent>
    </IGSection>
  );
};

export default ActiveLoan;
