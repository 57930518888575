import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {IGDialog, IGTextField, IGButton} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  loanRenewRebookActions,
  loanRenewRebookSelectors,
} from "app/store/loanRenewalRebook";
import {toggleModals} from "app/store/loanRenewalRebook/loanRenewalRebook.reducer";

interface LoanCancelPopUpProps {
  crId: number;
}
const LoanCancelPopUp = ({crId}: LoanCancelPopUpProps) => {
  const dispatch = useAppDispatch();
  const {isLoanCancelModalOpen} = useAppSelector(
    loanRenewRebookSelectors.getOpenModals,
  );
  const {closeRenewalLoan: isCloseLoanLoading} = useAppSelector(
    loanRenewRebookSelectors.getLoading,
  );
  const [confirmationText, setConfirmationText] = useState<null | string>(null);
  return (
    <IGDialog
      open={isLoanCancelModalOpen}
      title={`CrId #${crId}`}
      onClose={() =>
        dispatch(
          toggleModals({
            type: "isLoanCancelModalOpen",
            value: false,
          }),
        )
      }
      hideFooter
    >
      <Box mt={0.75}>
        <Typography mb={2} variant="h6" color="red">
          CRITICAL: Once you trigger loan cancelation, this loan will be closed
          in our system.
        </Typography>

        <Typography mb={2} variant="body1">
          Type
          <span
            style={{
              fontWeight: "bold",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            CONFIRM
          </span>
          to agree.
        </Typography>
        <IGTextField
          name="confirmationText"
          label="CONFIRM"
          placeholder="CONFIRM"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
        />
        <Box mt={2} display="flex" gap={1} justifyContent="flex-end">
          <IGButton
            color="inherit"
            onClick={() =>
              dispatch(
                toggleModals({
                  type: "isLoanCancelModalOpen",
                  value: false,
                }),
              )
            }
          >
            Cancel
          </IGButton>
          <IGButton
            disabled={confirmationText !== "CONFIRM"}
            color="error"
            loading={isCloseLoanLoading}
            onClick={() =>
              dispatch(
                loanRenewRebookActions.closeRenewalLoan({
                  crId: Number(crId),
                }),
              )
            }
          >
            Confirm
          </IGButton>
        </Box>
      </Box>
    </IGDialog>
  );
};

export default LoanCancelPopUp;
