import {goldApiService} from "app/infra/plugins/axios";
import {GoldApiResource} from "app/typings/api/goldApi.types";

const getLoanSummaryDocument = async ({
  loanId,
}: {
  loanId: string;
}) => {
  const resource: GoldApiResource = {
    URL: "/admin/business/loan/:loanId/loan-summary-document",
    METHOD: "GET",
  };
  const path = {loanId};

  return goldApiService({
    resource,
    options: {
      pathVars: path,
    },
  });
};

export default getLoanSummaryDocument;
