import styled from "@emotion/styled";
import {Button} from "@mui/material";

const StyledIconButton = styled(Button)`
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    display: none;
  }
  width: 100px;
  padding: 0;
  margin-right: 8px;
`;

export default StyledIconButton;