import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./styles";
import {Step, StepLabel, Stepper, Tooltip} from "@mui/material";

import {ArrowDropDownRounded, ArrowDropUpRounded} from "@mui/icons-material";
import "./index.css";

const StepperComponent = ({
  mileStones,
  index,
  setCurrentMileStone,
  setCurrentIndex,
  // isBackMovement,
  // currentMileStone,
  isThere,
}) => {
  const getSteps = useCallback(
    (isCollapsed, currentStep) => {
      const totalArray = mileStones;
      if (isCollapsed) {
        return totalArray;
      }
      return totalArray.slice(currentStep, currentStep + 2);
    },
    [mileStones],
  );

  const ref = useRef(0);

  const classes = styles();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [currentStep, setCurrentStep] = useState(index);

  const steps = getSteps(isCollapsed, currentStep);

  useEffect(() => {
    if (ref.current === 0 && index !== currentStep) {
      ref.current = 1;
      setCurrentStep(index);
    }
  }, [currentStep, index]);

  const handleCollapsed = (value) => {
    setIsCollapsed(value);
  };

  const getActiveStep = useCallback(() => {
    // if (isBackMovement) {
    //   return currentStep;
    // }
    if (isCollapsed) {
      return index;
    }
    return Math.abs(currentStep - index);
  }, [currentStep, index, isCollapsed]);

  const handleStep = useCallback(
    (activeIndex) => {
      if (activeIndex <= index && isCollapsed) {
        setCurrentStep(activeIndex);
        handleCollapsed(false);
        setCurrentMileStone({
          index: index,
          data: mileStones[activeIndex],
        });
        setCurrentIndex(activeIndex);
      }
    },
    [index, isCollapsed, mileStones, setCurrentIndex, setCurrentMileStone],
  );

  const onToggleHandler = useCallback(() => {
    handleCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <div className={classes.stepper}>
      <Stepper
        className={classes.stepperWrapper}
        activeStep={getActiveStep()}
        orientation="vertical"
      >
        {steps.map((label, stepIndex) => {
          const date = new Date(label.updatedAt);
          return (
            <Step key={label.id}>
              <StepLabel
                className={classes.button}
                onClick={() => handleStep(stepIndex)}
                StepIconComponent={(params) => {
                  const {active, completed} = params;

                  if (isThere && stepIndex === 0) {
                    return <div className={classes.yellow} />;
                  }

                  if (completed) {
                    return <div className={classes.iconCompleted}>✓</div>;
                  }
                  if (active) {
                    return <div className={classes.iconCompleted}>✓</div>;
                  }
                  return <div className={classes.error} />;
                }}
              >
                <div className={classes.textContainer}>
                  {isCollapsed && index >= stepIndex ? (
                    <div className={classes.link}>{label.milestoneName}</div>
                  ) : (
                    <>
                      <div>{label.milestoneName}</div>
                    </>
                  )}

                  {label.isComplete && (
                    <div>
                      {date.toLocaleString("en-In", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                        day: "2-digit",
                        month: "long",
                      })}
                    </div>
                  )}
                </div>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Tooltip title={isCollapsed ? "Collapse" : "Expand"} arrow>
        <div
          onClick={onToggleHandler}
          className={classes.iconContainer}
        >
          {isCollapsed
            ? <ArrowDropUpRounded fontSize="large"/>
            : <ArrowDropDownRounded fontSize="large"/>
          }
        </div>
      </Tooltip>
    </div>
  );
};

export default StepperComponent;
