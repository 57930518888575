import {goldApiService} from "app/infra/plugins/axios";
import {NPACasesApiInterface, NPACasesApiResourcesInterface} from "./types";

const NPACasesApiResources: NPACasesApiResourcesInterface = {
  GET_AUCTION_DETAILS: {
    URL: "/admin/auction/shivalik",
    METHOD: "GET",
  },
  UPLOAD_DAILY_NPA_REPORT: {
    URL: "/admin/auction/shivalik/upload-daily-npa-report",
    METHOD: "POST",
  },
  UPLOAD_AUCTION_SHEET: {
    URL: "/admin/auction/shivalik/upload-auction-sheet",
    METHOD: "POST",
  },
  UPDATE_CUTOFF_DATE: {
    URL: "/admin/auction/shivalik/next-cutoff-date",
    METHOD: "POST",
  },
  UPDATE_AUCTION_DATE: {
    URL: "/admin/auction/shivalik/next-auction-date",
    METHOD: "POST",
  },
};

const npaCasesApi: NPACasesApiInterface = {
  getAuctionDetails: async () => {
    return await goldApiService({
      resource: NPACasesApiResources.GET_AUCTION_DETAILS,
    });
  },
  uploadDailyNpaReport: async (formData) => {
    return await goldApiService({
      resource: NPACasesApiResources.UPLOAD_DAILY_NPA_REPORT,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {data: formData},
    });
  },
  uploadAuctionSheet: async (formData) => {
    return await goldApiService({
      resource: NPACasesApiResources.UPLOAD_AUCTION_SHEET,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {data: formData},
    });
  },
  updateCutoffDate: async ({nextCutoffDate}) => {
    return await goldApiService({
      resource: NPACasesApiResources.UPDATE_CUTOFF_DATE,
      options: {data: {newDate: nextCutoffDate}},
    });
  },
  updateAuctionDate: async ({nextAuctionDate}) => {
    return await goldApiService({
      resource: NPACasesApiResources.UPDATE_AUCTION_DATE,
      options: {data: {newDate: nextAuctionDate}},
    });
  },
};

export {NPACasesApiResources};
export default npaCasesApi;
