import React from "react";
import {Box, Typography} from "@mui/material";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {OverdueList} from "app/models/activeLoans/activeLoans.model";

interface OverDueDetailListProps {
  overDueList: OverdueList[];
}
const OverDueDetailList = ({overDueList}: OverDueDetailListProps) => {
  const tableCols: IGTableColsInterface<OverdueList> = [
    {
      field: "parentLoanId",
      headerName: "Loan Id",
      flex: 1.5,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "dpd",
      headerName: "DPD",
      flex: 1.5,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      field: "overdueAmount",
      headerName: "Over Due Amount",
      flex: 1.5,
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
  ];
  return (
    <Box>
      <IGTable
        hideFooterPagination
        getRowId={row => row?.parentLoanId}
        tableData={overDueList}
        tableCols={tableCols}
      />
    </Box>
  );
};

export default OverDueDetailList;
