import React, {useState} from "react";
import {TextFieldProps} from "@mui/material";
import {DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider} from "@mui/x-date-pickers";
import {IGTextField} from "../index";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Moment} from "moment-timezone";
import "./IGDatePicker.scss";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface IGDatePickerProps<TInputDate, TDate = TInputDate>
  extends Optional<DesktopDatePickerProps<TInputDate, TDate>, "renderInput"> {
  name: TextFieldProps["name"]
  label: TextFieldProps["label"]
  value: TInputDate
  onChange: DesktopDatePickerProps<TInputDate, TDate>["onChange"]
  renderInput?: DesktopDatePickerProps<TInputDate, TDate>["renderInput"]
  size?: TextFieldProps["size"]
  variant?: TextFieldProps["variant"]
  error?: TextFieldProps["error"]
  touched?: boolean
  helperText?: TextFieldProps["helperText"]
  required?: boolean
  sx?: TextFieldProps["sx"]
}

function IGDatePicker<TInputDate = Moment | null, IDate = TInputDate>({
  value,
  onChange,
  label,
  name,
  onOpen,
  onClose,
  renderInput,
  size = "small",
  disablePast = true,
  showToolbar = false,
  closeOnSelect = true,
  InputProps = {
    fullWidth: true,
  },
  error = false,
  touched = false,
  helperText = "",
  required = false,
  variant = "outlined",
  inputFormat = "ddd, DD MMM, YYYY",
  sx = {},
  ...rest
}: IGDatePickerProps<TInputDate, IDate>) {

  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker<TInputDate, IDate>
        disablePast={disablePast}
        showToolbar={showToolbar}
        closeOnSelect={closeOnSelect}
        open={pickerOpen}
        label={label || name}
        inputFormat={inputFormat}
        onChange={onChange}
        onOpen={() => {
          if (rest.disabled) return;
          setPickerOpen(true);
          onOpen && onOpen();
        }}
        onClose={() => {
          setPickerOpen(false);
          onClose && onClose();
        }}
        value={value}
        InputProps={InputProps}
        renderInput={renderInput
          ? renderInput
          : (props) =>
            <IGTextField
              {...props}
              sx={sx}
              required={required}
              size={size}
              name={name || ""}
              className="ig-date-picker"
              // onClick={(e) => {
              //   setPickerOpen(true);
              //   props.onClick && props.onClick(e);
              // }}
              variant={variant}
              value={value}
              error={error}
              touched={touched}
              helperText={helperText}
            />
        }
        {...rest}
      />
    </LocalizationProvider>
  );
}

export default IGDatePicker;