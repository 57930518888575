import {createSlice} from "@reduxjs/toolkit";
import {CityInterface, LenderInterface} from "../../models/lead";
import {schedulingActions} from "./actions";
import {unscheduledReducer} from "./unscheduled";
import {openCRReducer} from "./openCr";
import {
  initialState as UnscheduledInitialState,
} from "./unscheduled/reducer";
import {UnscheduledState} from "./unscheduled/types";
import {
  initialState as OpenCRInitialState,
  OpenCRState,
} from "./openCr/reducer";
import {AlgoState, initialState as AlgoInitialState} from "./algo/reducer";
import {algoReducer} from "./algo";
import {CallingHistory, SchedulingViews} from "app/infra/services/api/scheduling/types";
import {
  initialState as LMAvailabilityInitialState,
  LMAvailability,
} from "./lmAvailability/reducer";
import {lmAvailabilityReducer} from "./lmAvailability";

export interface SchedulingState {
  cities: {
    error: any | null;
    isFetching: boolean;
    data: CityInterface[];
  };
  currentView: SchedulingViews;
  lenderList: LenderInterface[]
  callingHistory: CallingHistory[],
  isFetching: boolean;
  loading: {
    getCallingHistory: boolean
  }
  error: any | null;
  unscheduled: UnscheduledState;
  openCR: OpenCRState;
  algo: AlgoState;
  lmAvailability: LMAvailability;
}

// >>>>>>> Scheduling Initial State <<<<<<<<<
const initialState: SchedulingState = {
  cities: {
    isFetching: true,
    data: [],
    error: null,
  },
  lenderList: [],
  isFetching: false,
  callingHistory: [],
  loading: {
    getCallingHistory: false,
  },
  error: null,
  currentView: "UNSCHEDULED",
  unscheduled: UnscheduledInitialState,
  openCR: OpenCRInitialState,
  algo: AlgoInitialState,
  lmAvailability: LMAvailabilityInitialState,
};

export const schedulingSlice = createSlice({
  name: "scheduling",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(schedulingActions.getCities, (state, action) => {
        state.isFetching = true;
        state.cities.isFetching = true;
        state.cities.error = null;
      })
      .addCase(schedulingActions.getCitiesSuccess, (state, action) => {
        state.isFetching = false;
        state.cities.isFetching = false;
        state.cities.error = null;
        state.cities.data = action.payload.cities;
      })
      .addCase(schedulingActions.getCitiesFailure, (state, action) => {
        state.isFetching = false;
        state.cities.isFetching = false;
        state.cities.error = action.payload.error;
      })
      .addCase(schedulingActions.changeCurrentView, (state, action) => {
        state.currentView = action.payload;
      })
      .addCase(schedulingActions.getLenderList, state => {
        state.isFetching = true;
      })
      .addCase(schedulingActions.getLenderListSuccess, (state, action) => {
        state.isFetching = false;
        state.lenderList = action.payload.lenderList;
      })
      .addCase(schedulingActions.getLenderListFailure, (state, action) => {
        state.isFetching = false;
        state.error = action.payload.error;
      })
      .addCase(schedulingActions.getCallingHistory, state => {
        state.loading.getCallingHistory = true;
      })
      .addCase(schedulingActions.getCallingHistorySuccess, (state, action) => {
        state.loading.getCallingHistory = false;
        state.callingHistory = action.payload.callingHistory;
      })
      .addCase(schedulingActions.getCallingHistoryFailure, (state, action) => {
        state.loading.getCallingHistory = false;
        state.error = action.payload.error;
      })
      .addMatcher(
        action =>
          action.type.startsWith("[Unscheduled]") ||
          action.type.startsWith("unscheduled"),
        (state, action) => {
          const data = unscheduledReducer(state.unscheduled, action);
          state.unscheduled = {
            ...data,
          };
        },
      )
      .addMatcher(
        action =>
          action.type.startsWith("[OpenCR]") ||
          action.type.startsWith("openCR"),
        (state, action) => {
          const data = openCRReducer(state.openCR, action);
          state.openCR = {
            ...data,
          };
        },
      )
      .addMatcher(
        action =>
          action.type.startsWith("[Algo]") || action.type.startsWith("algo"),
        (state, action) => {
          const data = algoReducer(state.algo, action);
          state.algo = {
            ...data,
          };
        },
      )
      .addMatcher(
        action =>
          action.type.startsWith("[LM Availability]") ||
          action.type.startsWith("lmAvailability"),
        (state, action) => {
          const data = lmAvailabilityReducer(state.lmAvailability, action);
          state.lmAvailability = {
            ...data,
          };
        },
      );
  },
});

export default schedulingSlice.reducer;
