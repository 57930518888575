import React, {useEffect, useMemo, useState} from "react";
import {PictureAsPdfOutlined} from "@mui/icons-material";
import DocViewModal from "./DocViewModal";
import {StyledThumbnail} from "./styled";

export interface IGDocumentInterface {
  documentType: string;
  fileUrl: string;
  fileTitle?: string;
  backFileUrl?: string;
  backFileTitle?: string;
  fileType?: string;
  crDocumentImageResponseDtoList?: {fileUrl: string; imageTitle?: string}[];
}

interface IGDocumentViewerProps {
  document: IGDocumentInterface;
  open?: boolean;
  onClose?: () => void;
  thumbHeight?: string | number;
  thumbWidth?: string | number;
  download?: boolean;
}

const IGDocumentViewer: React.FC<IGDocumentViewerProps> = ({
  document,
  open = false,
  onClose = () => {},
  thumbHeight = "5rem",
  thumbWidth = "5rem",
  download = false,
}) => {
  const [openDocView, setOpenDocView] = useState(false);

  const imagesWithFileType = useMemo(() => {
    const images = [document.fileUrl];

    if (document.backFileUrl) {
      images.push(document.backFileUrl);
    }

    document.crDocumentImageResponseDtoList &&
      document.crDocumentImageResponseDtoList.forEach((image) => {
        images.push(image.fileUrl);
      });
    return {
      images,
      fileType:
        document.fileType?.toLowerCase() ||
        (document.fileUrl?.includes(".pdf") ? "application/pdf" : "image"),
    };
  }, [document]);

  const imageTitles = useMemo(() => {
    const titles = [];
    if (document.fileTitle) {
      titles.push(document.fileTitle);
    }
    if (document.backFileTitle) {
      titles.push(document.backFileTitle);
    }
    document.crDocumentImageResponseDtoList &&
      document.crDocumentImageResponseDtoList.forEach((image) => {
        titles.push(image.imageTitle);
      });
    return titles;
  }, [document]);

  useEffect(() => {
    setOpenDocView(open);
  }, [open]);

  const thumbnail = imagesWithFileType.images[0];
  const isPdf = imagesWithFileType.fileType?.includes("pdf");

  return (
    <>
      <>
        {/* hiding thumbail in case of force open*/}
        {thumbnail && !open && (
          <>
            {isPdf ? (
              <PictureAsPdfOutlined
                style={{fontSize: thumbHeight, color: "#FF0000"}}
                onClick={() => setOpenDocView(true)}
              />
            ) : (
              <StyledThumbnail
                src={thumbnail}
                thumbHeight={thumbHeight}
                thumbWidth={thumbWidth}
                alt={`thumbnail-${document?.documentType}`}
                onClick={() => setOpenDocView(true)}
              />
            )}
          </>
        )}
      </>

      <DocViewModal
        open={openDocView}
        onClose={() => {
          setOpenDocView(false);
          onClose && onClose();
        }}
        imageList={imagesWithFileType}
        imageTitles={imageTitles}
        title={document?.documentType.replaceAll("_", " ")}
        download={download}
      />
    </>
  );
};

export default IGDocumentViewer;
