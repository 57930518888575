import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "admin/business/cr/:crId/return-to-summary-milestone",
  METHOD: "POST",
};

export const returnToSummaryMilestone = async ({crId, remark, remarkEnum}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {remark, remarkEnum},
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export default returnToSummaryMilestone;
