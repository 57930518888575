import {createAction} from "@reduxjs/toolkit";
import {FeatureFlagStatusPayload} from "app/infra/services/api/app/app.api";

const featureFlagActionTypes = {
  GetFeatureFlag: "[FeatureFlag] Get Feature Flag",
  GetFeatureFlagSuccess: "[FeatureFlag] Get Feature Flag Success",
  GetFeatureFlagFailure: "[FeatureFlag] Get Feature Flag Failure",
  GetFeatureFlagList: "[FeatureFlag] Get Feature Flag List",
};

const featureFlagActions = {
  getFeatureFlag: createAction<FeatureFlagStatusPayload>(featureFlagActionTypes.GetFeatureFlag),
  getFeatureFlagSuccess: createAction<{ response: boolean }>(
    featureFlagActionTypes.GetFeatureFlagSuccess,
  ),
  getFeatureFlagFailure: createAction<{
    error: string;
  }>(featureFlagActionTypes.GetFeatureFlagFailure),
  getFeatureFlagList: createAction<FeatureFlagStatusPayload[]>(
    featureFlagActionTypes.GetFeatureFlagList,
  ),
};

export {featureFlagActionTypes};
export default featureFlagActions;
