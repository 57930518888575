import {Box, Typography} from "@mui/material";
import {IGButton, IGDrawer, IGLoading} from "app/components";
import {customerProfileSelectors} from "app/store/customerProfile";
import {useAppSelector} from "app/store/hooks";
import React from "react";
import {useParams} from "react-router-dom";
import indiagoldLogo from "./indiagold_logo.png";
import "./styles.css";
import GoldenDivider from "app/pages/home/GoldLoan/DrawerList/MileStoneCard/NewUploadKYC/KYCDetails/styles/GoldenDivider";
import {jsPDF} from "jspdf";
import {getCurrentTime} from "_metronic/utils/moment";
import {loanSelectors} from "app/store/loan";
import {formatMoment} from "_metronic";

const GenerateNOC = () => {
  const {userId, loanId} = useParams();
  const customerDetails = useAppSelector((state) =>
    customerProfileSelectors.getCustomerDetails(state, Number(userId)),
  );
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const {
    getAllUserLoans: getAllUserLoansLoading,
  } = useAppSelector(loanSelectors.getLoading);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const generatePdf = () => {
    setLoading(true);
    const doc = new jsPDF({
      unit: "px",
    });
    const soaElement = document.getElementById("noc");

    if (soaElement) {
      doc.html(soaElement, {
        callback: function(doc) {
          doc.save(`NOC_${getCurrentTime().valueOf()}.pdf`);
          setLoading(false);
        },
        html2canvas: {
          scale: 0.65,
        },
        autoPaging: "text",
      });
    }
  };

  return (
    <div>
      <Typography variant="h6"> NOC</Typography>
      <Box display="flex" gap={2}>
        <IGButton
          onClick={() => setOpen(true)}
          sx={{
            m: 1,
            width: "120px",
          }}
          color="golden"
        >
          Get NOC
        </IGButton>
      </Box>
      <IGDrawer open={open} onClose={() => setOpen(false)}>
        {getAllUserLoansLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <Box p={2}>
            <IGButton loading={loading} onClick={generatePdf}>
              Download
            </IGButton>
            <div className="c21 doc-content" id="noc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={indiagoldLogo}
                  alt="indiagold Logo"
                  style={{
                    width: "139px",
                    height: "32px",
                  }}
                />
                <div>
                  <p className="c45">
                    <span className="c16 c29 c12 c43">
                      FLAT WHITE CAPITAL PRIVATE LIMITED
                    </span>
                  </p>
                  <p className="c23 c39">
                    <span className="c12">CIN - U65GGGDL2020PTC361351</span>
                  </p>
                  <p className="c33 c23">
                    <span className="c16 c12 c29">Sr. No. FWC001</span>
                  </p>
                </div>
              </div>
              <p className="c22 c23 c33">
                <span className="c9"></span>
              </p>
              <p className="c44 c23">
                <span className="c6">Dear </span>
                <span className="c14">{customerDetails?.name}</span>
                <span className="c6">
                  ,<br />
                  Greetings from the Team
                </span>
                <span className="c16">&nbsp;</span>
                <span className="c6">indiagold</span>
                <span className="c6">,</span>
              </p>
              <p className="c22 c23 c44">
                <span className="c6"></span>
              </p>
              <p className="c17">
                <span className="c16 c29 c32">
                  SUBJECT: - NOC with respect to Account number-
                </span>
                <span className="c16 c29 c38 c32">{loanId}</span>
              </p>
              <p className="c0 c22 c23">
                <span className="c9"></span>
              </p>
              <p className="c18">
                <span className="c6">
                  We hereby, confirm that indiagold has no charge, lien or claim
                  whatsoever, in respect of the said property:
                </span>
              </p>
              <p className="c13">
                <span className="c6"></span>
              </p>
              <p className="c23 c26">
                <span className="c6">
                  We also confirm that the indiagold Account Number{" "}
                </span>
                <span className="c38 c16 c29 c49">{loanId}</span>
                <span className="c11">&nbsp;</span>
                <span className="c6">
                  has been repaid in full in our books as on{" "}
                  {formatMoment(loanDetails?.loanCloseDate, "DD MMM, YYYY")} and
                  that no amount is now due in respect of the said contact.
                </span>
              </p>
              <p className="c0 c22 c23">
                <span className="c6"></span>
              </p>
              <p className="c0 c23">
                <span className="c6">
                  Kindly acknowledge receipt of this letter.
                </span>
              </p>
              <p className="c1">
                <span className="c6"></span>
              </p>
              <p className="c8">
                <span className="c6">
                  For clarification kindly contact indiagold Customer Care on
                  &ndash; 080- 68495878 or email{" "}
                </span>
                <span className="c6">
                  <a className="c28" href="mailto:support@indiagold.co">
                    support@indiagold.co.
                  </a>
                </span>
              </p>
              <p className="c0 c22">
                <span className="c6"></span>
              </p>
              <p className="c0 c22">
                <span className="c6"></span>
              </p>
              <p className="c0 c20">
                <span className="c6">
                  Thank you for banking with indiagold. We look forward to
                  strengthen our banking relationship with you.
                </span>
              </p>
              <p className="c0 c22">
                <span className="c6"></span>
              </p>
              <div>
                <p className="c0 c22">
                  <span className="c6"></span>
                </p>
                <p className="c50">
                  <GoldenDivider />
                </p>
                <table className="c34">
                  <tr className="c47">
                    <td className="c46" colSpan={1} rowSpan={1}>
                      <p className="c24">
                        <span className="c6">OFFICE ADDRESS:</span>
                      </p>
                    </td>
                    <td className="c37" colSpan={1} rowSpan={1}>
                      <p className="c0">
                        <span className="c6">PHONE NUMBER:</span>
                      </p>
                    </td>
                    <td className="c5" colSpan={1} rowSpan={1}>
                      <p className="c0">
                        <span className="c6">WEBSITE:</span>
                      </p>
                    </td>
                    <td className="c40" colSpan={1} rowSpan={1}>
                      <p className="c0">
                        <span className="c6">REGISTERED ADDRESS:</span>
                      </p>
                    </td>
                  </tr>
                  <tr className="c47">
                    <td className="c46" colSpan={1} rowSpan={1}>
                      <p className="c24">
                        <span className="c6">
                          indiagold, 5th Floor, Plot 64, Sector 44, Gurugram -
                          122003
                        </span>
                      </p>
                    </td>
                    <td className="c37" colSpan={1} rowSpan={1}>
                      <p className="c24">
                        <span className="c6">+91 9310366544</span>
                      </p>
                      <p className="c36">
                        <span className="c6">EMAIL:</span>
                      </p>
                      <p className="c41">
                        <span className="c16">
                          <a className="c28" href="mailto:hello@indiagold.co">
                            hello@indiagold.co
                          </a>
                        </span>
                      </p>
                    </td>
                    <td className="c5" colSpan={1} rowSpan={1}>
                      <p className="c15">
                        <span className="c32 c19 c16">
                          <a className="c28" href="http://www.indiagold.co/">
                            www.indiagold.co
                          </a>
                        </span>
                      </p>
                    </td>
                    <td className="c40" colSpan={1} rowSpan={1}>
                      <p className="c15">
                        <span className="c16">Innov8, 2</span>
                        <span className="c16 c30">nd </span>
                        <span className="c6">Floor, 44 Regal</span>
                      </p>
                      <p className="c3">
                        <span className="c6">building,Connaught</span>
                      </p>
                      <p className="c3">
                        <span className="c6">Palace- New Delhi - 110001</span>
                      </p>
                    </td>
                  </tr>
                </table>
                <p className="c35">
                  <GoldenDivider />
                </p>
              </div>
            </div>
          </Box>
        )}
      </IGDrawer>
    </div>
  );
};

export default GenerateNOC;
