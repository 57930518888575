import React, {useCallback} from "react";
import {Snackbar, IconButton} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";

function SimpleSnackbar({snackbar, setSnackbar, position}) {
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackbar &&
        setSnackbar({
          ...snackbar,
          message: "",
          snackbar: false,
        });
    },
    [setSnackbar, snackbar],
  );

  return (
    <>
      <Snackbar
        anchorOrigin={
          position || {
            vertical: "top",
            horizontal: "center",
          }
        }
        open={snackbar.snackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackbar.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

export default SimpleSnackbar;
