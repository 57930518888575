import {createAction} from "@reduxjs/toolkit";
import {
  GetCollateralMovementPayload,
  CollateralMovementResponse,
  GetInwardReceiptImagePayload,
} from "./collateralMovement.types";
import {setPaginationFor} from "../factoryReducers";

const collateralMovementActionTypes = {
  GetAllCollateralMovement: "[collateralMovement] Get All Collateral Movement",
  GetAllCollateralMovementSuccess:
    "[collateralMovement] Get All  Collateral Movement Success",
  GetAllCollateralMovementFailure:
    "[collateralMovement] Get All Collateral Movement Failure",
  GetInwardReceiptImage: "[collateralMovement] Get Inward Receipt Image",
  GetInwardReceiptImageSuccess:
    "[collateralMovement] Get Inward Receipt Image Success",
  GetInwardReceiptImageFailure:
    "[collateralMovement] Get Inward Receipt Image Failure",
};

const collateralMovementActions = {
  getAllCollateralMovement: createAction<GetCollateralMovementPayload>(
    collateralMovementActionTypes.GetAllCollateralMovement,
  ),
  getAllCollateralMovementSuccess: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetAllCollateralMovementSuccess,
  ),
  getAllCollateralMovementFailure: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetAllCollateralMovementFailure,
  ),
  getInwardReceiptImage: createAction<GetInwardReceiptImagePayload>(
    collateralMovementActionTypes.GetInwardReceiptImage,
  ),
  getInwardReceiptImageSuccess: createAction<{
    response: {
      receiptImage: string;
    };
    crId: number;
  }>(collateralMovementActionTypes.GetInwardReceiptImageSuccess),
  getInwardReceiptImageFailure: createAction<CollateralMovementResponse>(
    collateralMovementActionTypes.GetInwardReceiptImageFailure,
  ),
  setCollateralMovementPagination: setPaginationFor("collateralMovement"),
};

export default collateralMovementActions;

export {collateralMovementActionTypes};
