import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReschedulingLoanStatusEnum} from "app/enums/serviceDesk";
import {
  JumperRemovalChildStatusResponseDto,
  ValidateJumperResponseDTO,
} from "app/models/serviceDesk";
import {getValueFromQueryParams} from "_metronic";
import complaintActions from "./actions";
import {ComplaintsType} from "app/enums/gold_loan/serviceDesk";
import {RequestStatusCountDetails, SupportConversationDetails} from "./types";

export interface ComplaintsState {
  jumperRemoval: {
    initiateJumperFormData: {
      subventionRestoreRequested: boolean;
      proofFile: File | null;
      parentLoanId: number;
      serviceRequestId: number;
    };
    loanJumperDetails: {
      childLoanList: ValidateJumperResponseDTO[] | [];
      parentLoanJumperRemovalAllowed: boolean;
      subventionRestoreAllowed: boolean;
    };
    loanJumperStatus: {
      parentLoanStatus:
        | typeof ReschedulingLoanStatusEnum[
          keyof typeof ReschedulingLoanStatusEnum
        ]
        | null;
      goldLoan: JumperRemovalChildStatusResponseDto;
      personalLoan: JumperRemovalChildStatusResponseDto;
      message: string | null;
    };
  };
  closeTicketQuestions: {
      category: string;
      id: number;
      optionList: [
        {
          id: number;
          text: string;
          type: string;
        }
      ],
      text: string;
      type: string;
  }[];
  statusCount: RequestStatusCountDetails;
  conversations: SupportConversationDetails[];
  loading: {
    validateJumperRemoval: boolean;
    initiateJumperRemoval: boolean;
    retryJumperRemoval: boolean;
    getJumperRemovalStatus: boolean;
    getRequestStatusCount: boolean;
    getCustomerAgentConversation: boolean;
    postAgentReplyToConversation: boolean;
    updateMobileNumber: boolean;
    getCloseTicketQuestion: boolean;
    closeFreshDeskTicket: boolean;
  };
  error: {
    validateJumperRemoval: any;
    initiateJumperRemoval: any;
    retryJumperRemoval: any;
    getJumperRemovalStatus: any;
    getRequestStatusCount: any;
    getCustomerAgentConversation: any;
    postAgentReplyToConversation: any;
    updateMobileNumber: any;
    getCloseTicketQuestion: any;
    closeFreshDeskTicket: any;
  };
  modals: {
    authoriseRateChange: boolean;
    postReplyToCustomer: boolean;
    updateMobileNumber: boolean;
  };
  activeTab: ComplaintsType;
}

const initialState: ComplaintsState = {
  jumperRemoval: {
    initiateJumperFormData: {
      subventionRestoreRequested: false,
      proofFile: null,
      parentLoanId: parseInt(getValueFromQueryParams("parentLoanId") || "0"),
      serviceRequestId: parseInt(getValueFromQueryParams("serviceRequestId") || "0"),
    },
    loanJumperDetails: {
      childLoanList: [],
      parentLoanJumperRemovalAllowed: false,
      subventionRestoreAllowed: false,
    },
    loanJumperStatus: {
      parentLoanStatus: null,
      goldLoan: {
        jumperRemovalError: null,
        jumperRemovalStatus: null,
        jumperRemovalStartDate: null,
        jumperRemovalEndDate: null,
        jumperChargeRemovalStatus: null,
        jumperChargeRemovalError: null,
        jumperChargeRemovalEndDate: null,
        jumperChargeRemovalStartDate: null,
        subventionChangeError: null,
        subventionChangeStatus: null,
        subventionChangeEndDate: null,
        subventionChangeStartDate: null,
      },
      personalLoan: {
        jumperRemovalError: null,
        jumperRemovalStatus: null,
        jumperRemovalStartDate: null,
        jumperRemovalEndDate: null,
        jumperChargeRemovalStatus: null,
        jumperChargeRemovalError: null,
        jumperChargeRemovalEndDate: null,
        jumperChargeRemovalStartDate: null,
        subventionChangeError: null,
        subventionChangeStatus: null,
        subventionChangeEndDate: null,
        subventionChangeStartDate: null,
      },
      message: null,
    },
  },
  closeTicketQuestions: [],
  statusCount: {
    CONTACTED_CUSTOMER: 0,
    IN_PROGRESS: 0,
    OPEN: 0,
    UNABLE_TO_CONTACT: 0,
  },
  conversations: [],
  loading: {
    validateJumperRemoval: false,
    initiateJumperRemoval: false,
    retryJumperRemoval: false,
    getJumperRemovalStatus: false,
    getRequestStatusCount: false,
    getCustomerAgentConversation: false,
    postAgentReplyToConversation: false,
    updateMobileNumber: false,
    getCloseTicketQuestion: false,
    closeFreshDeskTicket: false,
  },
  error: {
    validateJumperRemoval: null,
    initiateJumperRemoval: null,
    retryJumperRemoval: null,
    getJumperRemovalStatus: null,
    getRequestStatusCount: null,
    getCustomerAgentConversation: null,
    postAgentReplyToConversation: null,
    updateMobileNumber: null,
    getCloseTicketQuestion: null,
    closeFreshDeskTicket: null,
  },
  modals: {
    authoriseRateChange: false,
    postReplyToCustomer: false,
    updateMobileNumber: false,
  },
  activeTab: "GOLD_LOAN",
};

const complaintsSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<ComplaintsType>) => {
      state.activeTab = action.payload;
    },
    openModal: (
      state,
      action: PayloadAction<keyof ComplaintsState["modals"]>,
    ) => {
      state.modals[action.payload] = true;
    },
    closeModal: (
      state,
      action: PayloadAction<keyof ComplaintsState["modals"]>,
    ) => {
      state.modals[action.payload] = false;
    },
    setInitiateJumperFormData: (
      state,
      action: PayloadAction<
        {
          key: keyof ComplaintsState["jumperRemoval"]["initiateJumperFormData"],
          value: boolean | number | File | null
        }[]
      >,
    ) => {
      action.payload.forEach((item) => {
        const newFormData = {
          ...state.jumperRemoval.initiateJumperFormData,
          [item.key]: item.value,
        };
        state.jumperRemoval.initiateJumperFormData = newFormData;
      });
    },
    resetJumperRemovalStatus: (state) => {
      state.jumperRemoval.loanJumperStatus =
        initialState.jumperRemoval.loanJumperStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(complaintActions.validateJumperRemoval, (state, action) => {
        state.loading.validateJumperRemoval = true;
        state.error.validateJumperRemoval = null;
      })
      .addCase(
        complaintActions.validateJumperRemovalSuccess,
        (state, action) => {
          state.loading.validateJumperRemoval = false;
          state.error.validateJumperRemoval = null;
          state.jumperRemoval.loanJumperDetails.childLoanList =
            action.payload.loanJumperDetails.childLoanList;
          state.jumperRemoval.loanJumperDetails.parentLoanJumperRemovalAllowed =
            action.payload.loanJumperDetails.parentLoanJumperRemovalAllowed;
          state.jumperRemoval.loanJumperDetails.subventionRestoreAllowed =
            action.payload.loanJumperDetails.subventionRestoreAllowed;
        },
      )
      .addCase(
        complaintActions.validateJumperRemovalFailure,
        (state, action) => {
          state.loading.validateJumperRemoval = false;
          state.error.validateJumperRemoval = action.payload.error;
        },
      )
      .addCase(complaintActions.initiateJumperRemoval, (state, action) => {
        state.loading.initiateJumperRemoval = true;
        state.error.initiateJumperRemoval = null;
      })
      .addCase(
        complaintActions.initiateJumperRemovalSuccess,
        (state, action) => {
          state.loading.initiateJumperRemoval = false;
          state.error.initiateJumperRemoval = null;
        },
      )
      .addCase(
        complaintActions.initiateJumperRemovalFailure,
        (state, action) => {
          state.loading.initiateJumperRemoval = false;
          state.error.initiateJumperRemoval = action.payload.error;
        },
      )
      .addCase(complaintActions.retryJumperRemoval, (state, action) => {
        state.loading.retryJumperRemoval = true;
        state.error.retryJumperRemoval = null;
      })
      .addCase(complaintActions.retryJumperRemovalSuccess, (state, action) => {
        state.loading.retryJumperRemoval = false;
        state.error.retryJumperRemoval = null;
      })
      .addCase(complaintActions.retryJumperRemovalFailure, (state, action) => {
        state.loading.retryJumperRemoval = false;
        state.error.retryJumperRemoval = action.payload.error;
      })
      .addCase(complaintActions.getJumperRemovalStatus, (state, action) => {
        state.loading.getJumperRemovalStatus = true;
        state.error.getJumperRemovalStatus = null;
      })
      .addCase(
        complaintActions.getJumperRemovalStatusSuccess,
        (state, action) => {
          const {
            parentLoanStatus,
            goldLoan,
            personalLoan,
            message,
          } = action.payload.loanJumperStatus;
          const {
            personalLoan: initialPersonalLoan,
            goldLoan: initialGoldLoan,
          } = initialState.jumperRemoval.loanJumperStatus;
          state.loading.getJumperRemovalStatus = false;
          state.error.getJumperRemovalStatus = null;
          state.jumperRemoval.loanJumperStatus.parentLoanStatus =
            parentLoanStatus;
          state.jumperRemoval.loanJumperStatus.goldLoan =
            goldLoan || initialGoldLoan;
          state.jumperRemoval.loanJumperStatus.personalLoan =
            personalLoan || initialPersonalLoan;
          state.jumperRemoval.loanJumperStatus.message = message;
        },
      )
      .addCase(
        complaintActions.getJumperRemovalStatusFailure,
        (state, action) => {
          state.loading.getJumperRemovalStatus = false;
          state.error.getJumperRemovalStatus = action.payload.error;
          state.error.getJumperRemovalStatus =
            initialState.jumperRemoval.loanJumperStatus;
        },
      )
      .addCase(
        complaintActions.getRequestStatusCount,
        (state, action) => {
          state.loading.getRequestStatusCount = true;
          state.error.getRequestStatusCount = null;
        },
      )
      .addCase(
        complaintActions.getRequestStatusCountSuccess,
        (state, action) => {
          state.loading.getRequestStatusCount = false;
          state.error.getRequestStatusCount = null;
          state.statusCount = action.payload;
        },
      )
      .addCase(
        complaintActions.getRequestStatusCountFailure,
        (state, action) => {
          state.loading.getRequestStatusCount = false;
          state.error.getRequestStatusCount = action.payload.error;
        },
      )
      .addCase(
        complaintActions.getCustomerAgentConversation,
        (state, action) => {
          state.loading.getCustomerAgentConversation = true;
          state.error.getCustomerAgentConversation = null;
          state.conversations = [];
        },
      )
      .addCase(
        complaintActions.getCustomerAgentConversationSuccess,
        (state, action) => {
          state.loading.getCustomerAgentConversation = false;
          state.error.getCustomerAgentConversation = null;
          state.conversations = action.payload;
        },
      )
      .addCase(
        complaintActions.getCustomerAgentConversationFailure,
        (state, action) => {
          state.loading.getCustomerAgentConversation = false;
          state.error.getCustomerAgentConversation = action.payload.error;
        },
      )
      .addCase(
        complaintActions.postAgentReplyToConversation,
        (state) => {
          state.loading.postAgentReplyToConversation = true;
          state.error.postAgentReplyToConversation = null;
        },
      )
      .addCase(
        complaintActions.postAgentReplyToConversationSuccess,
        (state, action) => {
          state.loading.postAgentReplyToConversation = false;
          state.error.postAgentReplyToConversation = null;
          state.conversations = [...action.payload];
          state.modals.postReplyToCustomer = false;
        },
      )
      .addCase(
        complaintActions.postAgentReplyToConversationFailure,
        (state, action) => {
          state.loading.postAgentReplyToConversation = false;
          state.error.postAgentReplyToConversation = action.payload.error;
        },
      )
      .addCase(
        complaintActions.updateServiceRequestMobileNumber,
        (state) => {
          state.loading.updateMobileNumber = true;
          state.error.updateMobileNumber = null;
        },
      )
      .addCase(
        complaintActions.updateServiceRequestMobileNumberSuccess,
        (state) => {
          state.loading.updateMobileNumber = false;
          state.error.updateMobileNumber = null;
        },
      )
      .addCase(
        complaintActions.updateServiceRequestMobileNumberFailure,
        (state, action) => {
          state.loading.updateMobileNumber = false;
          state.error.updateMobileNumber = action.payload.error;
        },
      )
      .addCase(
        complaintActions.getCloseTicketQuestions,
      (state) => {
        state.loading.getCloseTicketQuestion = true;
        state.error.getCloseTicketQuestion = null;
      },
      )
      .addCase(
        complaintActions.getCloseTicketQuestionsSuccess,
        (state, action) => {
          state.loading.getCloseTicketQuestion = false;
          state.error.getCloseTicketQuestion = null;
          state.closeTicketQuestions = action.payload;
        },
      )
      .addCase(
        complaintActions.getCloseTicketQuestionsFailure,
        (state, action) => {
          state.loading.getCloseTicketQuestion = false;
          state.error.getCloseTicketQuestion = action.payload.error;
        },
      )
      .addCase(
        complaintActions.closeFreshDeskTicket,
        (state) => {
          state.loading.closeFreshDeskTicket = true;
          state.error.closeFreshDeskTicket = null;
        },
      )
      .addCase(
        complaintActions.closeFreshDeskTicketSuccess,
        (state) => {
          state.loading.closeFreshDeskTicket = false;
          state.error.closeFreshDeskTicket = null;
        },
      )
      .addCase(
        complaintActions.closeFreshDeskTicketFailure,
        (state, action) => {
          state.loading.closeFreshDeskTicket = false;
          state.error.closeFreshDeskTicket = action.payload.error;
        },
      );
  },
});

const {
  openModal,
  closeModal,
  setInitiateJumperFormData,
  resetJumperRemovalStatus,
  setActiveTab: setComplaintsActiveTab,
} = complaintsSlice.actions;

export default complaintsSlice.reducer;
export {
  initialState,
  openModal,
  closeModal,
  setInitiateJumperFormData,
  resetJumperRemovalStatus,
  setComplaintsActiveTab,
};
