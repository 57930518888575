import React, {useMemo} from "react";
import FormWrapper from "./styles/FormWrapper";
import Heading from "./styles/Heading";
import InputLabel from "./styles/InputLabel";
import FormContainer from "./styles/FormContainer";
import Input from "./styles/Input";
import InputWrapper from "./styles/InputWrapper";
import Button from "./styles/Button";
import SuccessMessage from "./styles/SuccessMessage";
import SubmitButtonWrapper from "./styles/SubmitButtonWrapper";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";

const VerifyLenderAccountDetails = ({
  isSameCreditManager,
  isBackMovement,
  info,
  onChangeInfo,
  nextJobResponse,
  branches,
  glVerifyButtonLoading,
  plVerifyButtonLoading,
  states,
  loanVerified,
  personalLoanDisplayValue,
  selectedPayload,
  valuesChanged,
  onSubmit,
  onVerify,
}) => {
  const renderSubmitButton = useMemo(() => {
    const isPlVerificationRequired =
      selectedPayload.isPersonalLoanTaken &&
      personalLoanDisplayValue?.lenderName === lenderNameEnum.SHIVALIK;
    return (
      loanVerified.gold &&
      (isPlVerificationRequired ? loanVerified.personal : true) &&
      !valuesChanged.gold &&
      (isPlVerificationRequired ? !valuesChanged.personal : true)
    );
  }, [
    loanVerified,
    valuesChanged,
    selectedPayload.isPersonalLoanTaken,
    personalLoanDisplayValue,
  ]);

  return (
    <>
      {isSameCreditManager && !isBackMovement && (
        <>
          <FormWrapper>
            <Heading>Gold Loan Information</Heading>
            <FormContainer>
              <InputWrapper>
                <InputLabel htmlFor='glbranch'>Select Lender Branch</InputLabel>
                <select
                  id='glbranch'
                  value={info.glbranch}
                  onChange={onChangeInfo}
                  disabled={!nextJobResponse}
                >
                  <option key='demogl' value={""}></option>
                  {branches.map((branch) => (
                    <option key={branch.value} value={branch.value}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </InputWrapper>
              <InputWrapper>
                <InputLabel htmlFor='glaccount'>
                  Lender Account Number
                </InputLabel>
                <Input
                  id='glaccount'
                  value={info.glaccount}
                  onChange={onChangeInfo}
                  disabled={!nextJobResponse}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel htmlFor='glconfirmaccount'>
                  Confirm Account Number
                </InputLabel>
                <Input
                  id='glconfirmaccount'
                  value={info.glconfirmaccount}
                  onChange={onChangeInfo}
                  disabled={!nextJobResponse}
                />
              </InputWrapper>
              <InputWrapper>
                <Button
                  id='gl'
                  onClick={onVerify}
                  disabled={
                    !info.glaccount ||
                    !info.glconfirmaccount ||
                    !info.glbranch ||
                    !nextJobResponse ||
                    glVerifyButtonLoading
                  }
                >
                  {glVerifyButtonLoading ? "Loading.." : "Verify"}
                </Button>
                {loanVerified.gold && !valuesChanged.gold && (
                  <SuccessMessage>Successfully Verified</SuccessMessage>
                )}
              </InputWrapper>
            </FormContainer>
          </FormWrapper>
          {selectedPayload.isPersonalLoanTaken &&
            personalLoanDisplayValue?.lenderName ===
              lenderNameEnum.SHIVALIK && (
              <FormWrapper>
                <Heading>Personal Loan Information</Heading>
                <FormContainer>
                  <InputWrapper>
                    <InputLabel htmlFor='plbranch'>
                      Select Lender Branch
                    </InputLabel>
                    <select
                      id='plbranch'
                      value={info.plbranch}
                      onChange={onChangeInfo}
                      disabled={!nextJobResponse}
                    >
                      <option key='demo' value={""}></option>
                      {branches.map((branch) => (
                        <option key={branch.value} value={branch.value}>
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel htmlFor='placcount'>
                      Lender Account Number
                    </InputLabel>
                    <Input
                      id='placcount'
                      value={info.placcount}
                      onChange={onChangeInfo}
                      disabled={!nextJobResponse}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel htmlFor='plconfirmaccount'>
                      Confirm Account Number
                    </InputLabel>
                    <Input
                      id='plconfirmaccount'
                      value={info.plconfirmaccount}
                      onChange={onChangeInfo}
                      disabled={!nextJobResponse}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Button
                      id='pl'
                      onClick={onVerify}
                      disabled={
                        !info.placcount ||
                        !info.plconfirmaccount ||
                        !info.plbranch ||
                        !nextJobResponse ||
                        plVerifyButtonLoading
                      }
                    >
                      {plVerifyButtonLoading ? "Loading..." : "Verify"}
                    </Button>
                    {loanVerified.personal && !valuesChanged.personal && (
                      <SuccessMessage>Successfully Verified</SuccessMessage>
                    )}
                  </InputWrapper>
                </FormContainer>
              </FormWrapper>
            )}
          {renderSubmitButton && (
            <SubmitButtonWrapper>
              <Button
                id='submit'
                onClick={onSubmit}
                disabled={!nextJobResponse && states.buttonLoading}
              >
                {states.buttonLoading ? "Loading..." : "Submit & Proceed"}
              </Button>
            </SubmitButtonWrapper>
          )}
        </>
      )}
    </>
  );
};

export default VerifyLenderAccountDetails;
