import {RootState} from "../store";

const loanPaymentSelectors = {
  getLoanPaymentList: (state: RootState) => state.loanPayment.loanPaymentList,
  getLoading: (state: RootState) => state.loanPayment.loading,
  getRefundStatus: (state: RootState, paymentId: number) =>
    state.loanPayment.refundStatus[paymentId],
  getShiftPaymentStatus: (state: RootState, paymentId: number) =>
    state.loanPayment.shiftPaymentStatus[paymentId],
};

export default loanPaymentSelectors;