import React, {useMemo} from "react";
import {RootState} from "app/store/store";
import {useAppSelector} from "app/store/hooks";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IGKeyValue from "app/components/IGLabelValue";
import {activeLoansSelectors} from "app/store/activeLoans";
import {isNil} from "lodash";

type RatingLevel = "HIGH" | "MEDIUM" | "LOW";
type ProgressColor = "success" | "warning" | "error" | "inherit";
const BTScoreAccordion = ({crId}: { crId: number }) => {
  const selectedMilestone = useAppSelector(activeLoansSelectors.getSelectedBtScoreCardMilestone);

  const {ruleMetaDataJson} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreCardConfigBySelectedMilestone(state, crId, selectedMilestone),
    ) ?? {};
  const {entityRating, ruleConditionList} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreDetailsByMilestone(state, crId, selectedMilestone),
    ) ?? {};

  const {ratingAchievedInPercentage, ratingAchieved, ratingLevelEnum, maxRating} =
    entityRating ?? {};
  const {headerDisplayName} = ruleMetaDataJson ?? {};
  const getColors = useMemo(() => {
    const colors: Record<
      RatingLevel,
      {
        backgroundColor: string;
        iconColor: string;
        progressColor: ProgressColor;
      }
    > = {
      HIGH: {
        backgroundColor: "#E8F5E9",
        iconColor: "#2E7D32",
        progressColor: "success",
      },
      MEDIUM: {
        backgroundColor: "#FFF8E1",
        iconColor: "#ED6C02",
        progressColor: "warning",
      },
      LOW: {
        backgroundColor: "#FEEBEE",
        iconColor: " #D32F2F",
        progressColor: "error",
      },
    };

    return (ratingLevel: RatingLevel | undefined) =>
      colors[ratingLevel as RatingLevel] || {
        backgroundColor: "#FAFAFA",
        iconColor: "#000000",
        progressColor: "inherit",
      };
  }, []);

  const {backgroundColor, iconColor, progressColor} = getColors(ratingLevelEnum as RatingLevel);

  return (
    <Box mt={2}>
      <Accordion
        sx={{
          padding: 0,
          borderRadius: "10px",
          border: "3px solid #FFFFFF",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: iconColor, fontSize: "1.7rem"}} />}
          sx={{
            bgcolor: backgroundColor,
            borderRadius: "8px",
            height: "70px",
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress
                value={ratingAchievedInPercentage}
                variant="determinate"
                color={progressColor}
                size="4rem"
              />
              <Typography fontSize="1rem" position="absolute">
                {isNil(entityRating) ? "NA" : `${ratingAchieved}/${maxRating}`}
              </Typography>
            </Box>
            <Typography fontSize="1.1rem" fontWeight={400} lineHeight="26px" variant="subtitle2">
              {headerDisplayName}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "16px",
          }}
        >
          {ruleConditionList?.map(l => (
            <IGKeyValue
              labelContainerProps={{
                xs: 7,
              }}
              label={l.conditionDisplayName}
              value={`${l.rating?.ratingAchieved} /${l.rating?.maxRating}`}
              valueContainerProps={{
                xs: 5,
              }}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BTScoreAccordion;
