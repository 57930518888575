import styled from "@emotion/styled";
import {Tab, Tabs} from "@mui/material";

export const StyledTabsContainer = styled(Tabs)`
  .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  text-transform: uppercase;
  min-width: 72px;
  font-weight: bold;
  margin-right: 0;
  color: #fff !important;
  min-height: 50px !important;
  padding: 1rem 2rem;
  font-size: 0.875rem;

  :hover {
    color: #fff;
  }

  &.Mui-selected {
    color: #1976d2 !important;
    background: #f5f5f5;
    border: none;
    opacity: 1;
    border-radius: 4px 4px 0 0;
  }

  :focus {
    color: #000;
  }

  .MuiTab-wrapper {
    flex-direction: row;
    align-items: center;

    svg {
      margin-bottom: 0 !important;
      margin-right: 8px;
    }
  }
`;