import {END, eventChannel} from "redux-saga";

export function countdown({
  seconds,
  intervalTime,
}: {
  seconds: number;
  intervalTime: number;
}) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      seconds -= 1;
      if (seconds > 0) {
        emitter(seconds);
      } else {
        // this causes the channel to close
        emitter(END);
      }
    }, intervalTime);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}