import {createSlice} from "@reduxjs/toolkit";
import {FetchGiftCardConfigResponse} from "app/infra/services/api/giftCardConfig/types";
import {giftCardConfigAction} from "./actions";

export interface GiftCardConfigState {
  giftCardConfigData: FetchGiftCardConfigResponse;
  isFetching: boolean;
  error: any | null;
  isUpdating: boolean;
  updateError: any | null;
}

// >>>>>>> Gift Card Config Initial State <<<<<<<<<
const initialState: GiftCardConfigState = {
  giftCardConfigData: [],
  isFetching: true,
  error: null,
  isUpdating: false,
  updateError: null,
};

export const giftCardConfig = createSlice({
  name: "whitelistUser",
  initialState,
  reducers: {},
  extraReducers: {
    [giftCardConfigAction.getGiftCardConfig.type]: state => {
      state.isFetching = true;
      state.error = null;
    },
    [giftCardConfigAction.getGiftCardConfigSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.giftCardConfigData = action.payload.giftCardConfig;
    },
    [giftCardConfigAction.getGiftCardConfigError.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [giftCardConfigAction.updateGiftCardConfig.type]: state => {
      state.isUpdating = true;
      state.updateError = null;
    },
    [giftCardConfigAction.updateGiftCardConfigSuccess.type]: state => {
      state.isUpdating = false;
      state.updateError = null;
    },
    [giftCardConfigAction.updateGiftCardConfigError.type]: (state, action) => {
      state.isUpdating = false;
      state.updateError = action.payload.error;
    },
  },
});

export default giftCardConfig.reducer;
