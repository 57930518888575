import {LoanManager} from "app/models/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {agentAvailabilityActions} from "./actions";
import {
  CustomerSupportUserScheduleReasonEnum,
  CustomerSupportUserScheduleStatusEnum,
} from "app/infra/services/api/serviceDesk/agentAvailability/types";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {Moment} from "moment-timezone";

export interface AgentSchedule {
  name: string;
  startTime: number;
  endTime: number;
  status: CustomerSupportUserScheduleStatusEnum;
  reason: CustomerSupportUserScheduleReasonEnum;
  lmUserId: number;
  remark?: string;
}

interface SelectedTimeSlotInterface {
  start: Moment;
  end: Moment;
  agentId: number;
}

export type AgentAvailabilityModals = "blockTime" | "unblockTime";

export interface AgentAvailability {
  agentsById: { [key: number]: LoanManager };
  agentScheduleById: { [key: number]: AgentSchedule };
  selectedJob: any;
  selectedTimeSlot: SelectedTimeSlotInterface | null;
  loading: {
    getAgents: boolean;
    getAgentScheduleList: boolean;
    updateAgentSchedule: boolean;
  };
  error: {
    getAgents: GoldApiError | null;
    getAgentScheduleList: GoldApiError | null;
    updateAgentSchedule: GoldApiError | null;
  };
  modal: {
    blockTime: boolean;
    unblockTime: boolean;
  };
}

const initialState: AgentAvailability = {
  agentsById: {},
  agentScheduleById: {},
  selectedJob: null,
  selectedTimeSlot: null,
  loading: {
    getAgents: false,
    getAgentScheduleList: false,
    updateAgentSchedule: false,
  },
  error: {
    getAgents: null,
    getAgentScheduleList: null,
    updateAgentSchedule: null,
  },
  modal: {
    blockTime: false,
    unblockTime: false,
  },
};

export const agentAvailabilitySlice = createSlice({
  name: "agentAvailability",
  initialState,
  reducers: {
    modalToggled: (
      state,
      action: PayloadAction<{ type: AgentAvailabilityModals; value: boolean }>,
    ) => {
      state.modal[action.payload.type] = action.payload.value;
    },
    jobSelected: (state, action) => {
      state.selectedJob = action.payload;
    },
    timeSlotSelected: (
      state,
      action: PayloadAction<SelectedTimeSlotInterface>,
    ) => {
      state.selectedTimeSlot = action.payload;
    },
  },
  extraReducers: {
    [agentAvailabilityActions.getAgents.type]: (state) => {
      state.loading.getAgents = true;
      state.error.getAgents = null;
    },
    [agentAvailabilityActions.getAgentsSuccess.type]: (state, action) => {
      state.loading.getAgents = false;
      state.error.getAgents = null;
      state.agentsById = action.payload.response?.reduce(
        (acc: any, agent: LoanManager) => {
          acc[agent.id] = agent;
          return acc;
        },
        {},
      );
    },
    [agentAvailabilityActions.getAgentsFailure.type]: (state, action) => {
      state.loading.getAgents = false;
      state.error.getAgents = action.payload.error;
    },
    [agentAvailabilityActions.getAgentScheduleList.type]: (state) => {
      state.loading.getAgentScheduleList = true;
      state.error.getAgentScheduleList = null;
    },
    [agentAvailabilityActions.getAgentScheduleListSuccess.type]: (
      state,
      action,
    ) => {
      state.loading.getAgentScheduleList = false;
      state.error.getAgentScheduleList = null;
      state.agentScheduleById = action.payload.response.reduce(
        (acc: any, agentSchedule: AgentSchedule) => {
          const uniqueId = agentSchedule.lmUserId + agentSchedule.startTime;
          acc[uniqueId] = agentSchedule;
          return acc;
        },
        {},
      );
    },
    [agentAvailabilityActions.getAgentScheduleListFailure.type]: (
      state,
      action,
    ) => {
      state.loading.getAgentScheduleList = false;
      state.error.getAgentScheduleList = action.payload.error;
    },
    [agentAvailabilityActions.updateAgentSchedule.type]: (state) => {
      state.loading.updateAgentSchedule = true;
      state.error.updateAgentSchedule = null;
    },
    [agentAvailabilityActions.updateAgentScheduleSuccess.type]: (
      state,
      action,
    ) => {
      state.loading.updateAgentSchedule = false;
      state.error.updateAgentSchedule = null;
      state.modal.blockTime = false;
      state.modal.unblockTime = false;
    },
    [agentAvailabilityActions.updateAgentScheduleFailure.type]: (
      state,
      action,
    ) => {
      state.loading.updateAgentSchedule = false;
      state.error.updateAgentSchedule = action.payload.error;
    },
  },
});

const {
  modalToggled,
  jobSelected,
  timeSlotSelected,
} = agentAvailabilitySlice.actions;

export default agentAvailabilitySlice.reducer;

export {initialState, modalToggled, jobSelected, timeSlotSelected};
