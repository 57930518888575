import React, {FC, useEffect} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {CheckCircleOutlineRounded, HighlightOffRounded} from "@mui/icons-material";
import {IGSection, IGSectionContent, IGSectionHeader, IGTable} from "app/components";
import Filter from "./Filter";
import {IGTableColsInterface} from "app/components/IGTable";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {CustomerProfileModel} from "app/models/customerProfile.model";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";
import {customerProfileActions, customerProfileSelectors} from "app/store/customerProfile";
import {GetCustomerProfileListPayload} from "app/infra/services/api/customerProfile/types";

interface CustomerProfileProps {
}

const CustomerProfile: FC<CustomerProfileProps> = () => {

  const {t} = useIGTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentSearchType = searchParams.get("currentSearchType") || "LOAN_ID";
  const searchText = searchParams.get("searchText") || "";
  const pageNo = Number(searchParams.get("pageNo")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 10;

  const user = useAppSelector(authSelectors.getUser);
  const customerProfileList = useAppSelector(
    customerProfileSelectors.getCustomerProfileList,
  );
  const {
    getCustomerProfileList: getCustomerProfileListLoading,
  } = useAppSelector(customerProfileSelectors.getLoading);

  const tableCols: IGTableColsInterface<CustomerProfileModel> = [
    {
      headerName: "CR ID",
      field: "loanId",
      renderCell: ({value, row}) => (
        <Typography
          variant="body2"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="rgb(206, 159, 41)"
          onClick={() => navigate(`${row.userId}/${value}`)}
        >
          {value}
        </Typography>
      ),
    },
    {
      headerName: t("CUSTOMER_NAME"),
      field: "name",
      renderCell: ({value, row}) => (
        <Typography
          variant="body2"
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="rgb(206, 159, 41)"
          onClick={() => navigate(`${row.userId}/${row.loanId}`)}
        >
          {value}
        </Typography>
      ),
    },
    {
      headerName: t("MOBILE"),
      field: "mobile",
    },
    {
      headerName: t("LOAN_STATUS"),
      field: "status",
    },
    {
      headerName: "Total Exposure",
      field: "totalLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
  ];

  const onPageChange = (page: number) => {
    searchParams.set("pageNo", String(page));
    setSearchParams(searchParams);
  };

  const onPageSizeChange = (pageSize: number) => {
    searchParams.set("pageSize", String(pageSize));
    searchParams.set("pageNo", "1");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const payload: GetCustomerProfileListPayload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    if (currentSearchType === "MOBILE" && searchText) {
      payload.mobile = Number(searchText);
    }
    if (currentSearchType === "LOAN_ID" && searchText) {
      payload.loanId = Number(searchText);
    }
    if (currentSearchType === "LANID" && searchText) {
      payload.lanId = searchText;
    }
    if (currentSearchType === "SB_LAN_ID" && searchText) {
      payload.lenderLoanId = searchText;
    }
    if (!getCustomerProfileListLoading) {
      dispatch(customerProfileActions.getCustomerProfileList({
        ...payload,
      }));
    }
  }, [location]);

  useEffect(() => {
    const params = {
      pageNo,
      pageSize,
      searchText,
      currentSearchType,
    };
    for (const key in params) {
      if (!searchParams.has(key)) {
        searchParams.set(key, String(params[key as keyof typeof params]));
      }
    }
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <IGSection>
      <IGSectionHeader title="Customer Profile"/>
      <IGSectionContent>
        <Box flex={0}>
          <Filter/>
        </Box>
        <Box flex={1}>
          <IGTable<CustomerProfileModel>
            autoHeight={false}
            loading={getCustomerProfileListLoading}
            tableCols={tableCols}
            tableData={customerProfileList}
            getRowId={(row) => row.loanId || Math.random()}
            columnVisibilityModel={{
              actions: !user?.userRoleList?.includes(RolesEnum.LENDER),
            }}
            page={pageNo}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            paginationType="single"
            disabledNext={customerProfileList.length < pageSize}
            disabledPrev={pageNo <= 1}
          />
        </Box>
      </IGSectionContent>
    </IGSection>
  );
};

export default CustomerProfile;