import styled from "@emotion/styled";

interface Props {
  marginLeft: boolean;
}

const DetailWrapper = styled.div<Props>`
  margin-left: ${({marginLeft}) => marginLeft && "2rem"};
`;

export default DetailWrapper;
