import {createAction} from "@reduxjs/toolkit";
import {
  CustomerProfileResponse,
  GetCustomerProfileListPayload,
  InitializeCustomerProfilePayload,
  LoanIdPayload,
  UserIdPayload,
  GetInwardReceiptImagePayload,
} from "../../infra/services/api/customerProfile/types";

const customerProfileActionTypes = {
  GetOverduePaymentDetails: "[Customer Profile] Get Overdue Payment Detail",
  GetOverduePaymentDetailsFailure:
    "[Customer Profile] Get Overdue Payment Detail Failure",
  GetOverduePaymentDetailsSuccess:
    "[Customer Profile] Get Overdue Payment Detail Success",
  GetReminders: "[Customer Profile] Get Reminders",
  GetRemindersSuccess: "[Customer Profile] Get Reminders Success",
  GetRemindersFailure: "[Customer Profile] Get Reminders Failure",
  SendReminder: "[Customer Profile] Get Send Reminder",
  SendReminderSuccess: "[Customer Profile] Get Send Reminder Success",
  SendReminderFailure: "[Customer Profile] Get Send Reminder Failure",
  InitializeCustomerProfile: "[Customer Profile] Initialize Customer Profile",
  InitializeCustomerProfileSuccess:
    "[Customer Profile] Initialize Customer Profile Success",
  InitializeCustomerProfileFailure:
    "[Customer Profile] Initialize Customer Profile Failure",
  GetCustomerProfileList: "[Customer Profile] Get Customer Profile List",
  GetCustomerProfileListSuccess:
    "[Customer Profile] Get Customer Profile List Success",
  GetCustomerProfileListFailure:
    "[Customer Profile] Get Customer Profile List Failure",
  GetCustomerDetails: "[Customer Profile] Get Customer Details",
  GetCustomerDetailsSuccess: "[Customer Profile] Get Customer Details Success",
  GetCustomerDetailsFailure: "[Customer Profile] Get Customer Details Failure",
  GetInwardReceiptImage: "[Customer Profile] Get Inward Receipt Image",
  GetInwardReceiptImageSuccess:
    "[Customer Profile] Get Inward Receipt Image Success",
  GetInwardReceiptImageFailure:
    "[Customer Profile] Get Inward Receipt Image Failure",
  EnablePartPaymentFlag: "[Customer Profile] Enable Part Payment Flag",
  EnablePartPaymentFlagSuccess:
    "[Customer Profile] Enable Part Payment Flag Success",
  EnablePartPaymentFlagFailure:
    "[Customer Profile] Enable Part Payment Flag Failure",
  ShiftPayment: "[Customer Profile] Shift Payment",
  ShiftPaymentSuccess: "[Customer Profile] Shift Payment Success",
  ShiftPaymentFailure: "[Customer Profile] Shift Payment Failure",
  UploadBatchClosureRefundReport: "[LoanPayment] Upload Batch Closure Refund Report",
  UploadBatchClosureRefundReportSuccess: "[LoanPayment] Upload Batch Closure Refund Report Success",
  UploadBatchClosureRefundReportFailure: "[LoanPayment] Upload Batch Closure Refund Report Failure",
};

const customerProfileActions = {
  getOverduePaymentDetails: createAction<LoanIdPayload>(
    customerProfileActionTypes.GetOverduePaymentDetails,
  ),
  getOverduePaymentDetailsSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetOverduePaymentDetailsSuccess,
  ),
  getOverduePaymentDetailsFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetOverduePaymentDetailsFailure,
  ),
  getReminders: createAction<LoanIdPayload>(
    customerProfileActionTypes.GetReminders,
  ),
  getRemindersSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetRemindersSuccess,
  ),
  getRemindersFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetRemindersFailure,
  ),
  sendReminder: createAction<LoanIdPayload>(
    customerProfileActionTypes.SendReminder,
  ),
  sendReminderSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.SendReminderSuccess,
  ),
  sendReminderFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.SendReminderFailure,
  ),
  initializeCustomerProfile: createAction<InitializeCustomerProfilePayload>(
    customerProfileActionTypes.InitializeCustomerProfile,
  ),
  initializeCustomerProfileSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.InitializeCustomerProfileSuccess,
  ),
  initializeCustomerProfileFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.InitializeCustomerProfileFailure,
  ),
  getCustomerProfileList: createAction<GetCustomerProfileListPayload>(
    customerProfileActionTypes.GetCustomerProfileList,
  ),
  getCustomerProfileListSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetCustomerProfileListSuccess,
  ),
  getCustomerProfileListFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetCustomerProfileListFailure,
  ),
  getCustomerDetails: createAction<UserIdPayload>(
    customerProfileActionTypes.GetCustomerDetails,
  ),
  getCustomerDetailsSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetCustomerDetailsSuccess,
  ),
  getCustomerDetailsFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetCustomerDetailsFailure,
  ),
  getInwardReceiptImage: createAction<GetInwardReceiptImagePayload>(
    customerProfileActionTypes.GetInwardReceiptImage,
  ),
  getInwardReceiptImageSuccess: createAction<{
    response: {
      receiptImage: string;
    };
    loanId: number;
  }>(customerProfileActionTypes.GetInwardReceiptImageSuccess),
  getInwardReceiptImageFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.GetInwardReceiptImageFailure,
  ),
  enablePartPaymentFlag: createAction<LoanIdPayload>(
    customerProfileActionTypes.EnablePartPaymentFlag,
  ),
  enablePartPaymentFlagSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.EnablePartPaymentFlagSuccess,
  ),
  enablePartPaymentFlagFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.EnablePartPaymentFlagFailure,
  ),
  uploadBatchClosureRefundReport: createAction<FormData>(
    customerProfileActionTypes.UploadBatchClosureRefundReport,
  ),
  uploadBatchClosureRefundReportSuccess: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.UploadBatchClosureRefundReportSuccess,
  ),
  uploadBatchClosureRefundReportFailure: createAction<CustomerProfileResponse>(
    customerProfileActionTypes.UploadBatchClosureRefundReportFailure,
  ),
};

export {customerProfileActionTypes};

export default customerProfileActions;
