import {createSlice} from "@reduxjs/toolkit";
import {FetchRefferalConfigResponse} from "app/infra/services/api/refferalConfig/types";
import {refferalConfigAction} from "./actions";

export interface RefferalConfigState {
  refferalConfigData: FetchRefferalConfigResponse | null;
  isFetching: boolean;
  error: any | null;
  isUpdating: boolean;
  updateError: any | null;
  updatingImage: boolean;
  updatingImageError: any | null;
}

// >>>>>>> Refferal Config Initial State <<<<<<<<<
const initialState: RefferalConfigState = {
  refferalConfigData: null,
  isFetching: true,
  error: null,
  isUpdating: false,
  updateError: null,
  updatingImage: false,
  updatingImageError: null,
};

export const refferalConfig = createSlice({
  name: "referralConfig",
  initialState,
  reducers: {},
  extraReducers: {
    [refferalConfigAction.getRefferalConfig.type]: (state) => {
      state.isFetching = true;
      state.error = null;
    },
    [refferalConfigAction.getRefferalConfigSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.refferalConfigData = action.payload.referralConfig;
    },
    [refferalConfigAction.getRefferalConfigError.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [refferalConfigAction.updateRefferalConfig.type]: (state) => {
      state.isUpdating = true;
      state.updateError = null;
    },
    [refferalConfigAction.updateRefferalConfigSuccess.type]: (state) => {
      state.isUpdating = false;
      state.updateError = null;
    },
    [refferalConfigAction.updateRefferalConfigError.type]: (state, action) => {
      state.isUpdating = false;
      state.updateError = action.payload.error;
    },
    [refferalConfigAction.updateRefferalImage.type]: (state) => {
      state.updatingImage = true;
    },
    [refferalConfigAction.updateRefferalImageSuccess.type]: (state) => {
      state.updatingImage = false;
    },
    [refferalConfigAction.updateRefferalImageError.type]: (state, action) => {
      state.updatingImage = false;
      state.updatingImageError = action.payload.error;
    },
  },
});

export default refferalConfig.reducer;
