import React, {useCallback, useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Col, Row} from "react-bootstrap";
import {Slide, Divider, Dialog, Paper} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import axios from "../../../../_metronic/utils/axios";
import {openInNewTab, formatDate} from "../../../../_metronic/utils/utils";
import {API_ENDPOINTS} from "../../../constants/constants";
import UpiDetails from "./UpiDetails";
import BankDetails from "./BankDetails";
import CardDetails from "./CardDetails";
import AddressDetails from "./AddressDetails";
import ReferralDetails from "./ReferralDetails";
import ROUTES from "app/constants/routes";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import goldApi, {goldApiService} from "app/infra/plugins/axios";
import {setComplaintsActiveTab} from "app/store/serviceDesk/complaints/reducer";

const {DG_ORDER, DIGITAL_GOLD, EMI_ORDERS, USERS} = ROUTES;

const ModalTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
    marginBottom: "1rem",
  },
  docContainer: {
    padding: "1rem",
    borderRadius: "8px",
  },
  imageHolder: {
    height: "25rem",
  },
}));

export default function UserDetails({dialog, header}) {
  const {id: userId} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles2();
  const snackbar = useSnackBar();
  const [kycDocumentAPIResponse, setKycDocumentAPIResponse] = React.useState(
    null,
  );
  const [id, setId] = React.useState(userId);
  const [data, setData] = React.useState({});
  const [goldLoanList, setGoldLoanList] = React.useState([]);
  const [requestList, setRequestList] = React.useState([]);
  const user = useAppSelector(authSelectors.getUser);
  const [modal, setModal] = React.useState({
    bankDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    cardDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    upiDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    addressDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    referralDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
  });
  const [loading, setLoading] = React.useState({
    filters: false,
  });
  const isAdmin = !!user?.userRoleList?.some((role) =>
    ["ADMIN", "FOUNDER"].includes(role),
  );

  const verticalData = [
    {
      name: "Status",
      value: "status",
    },
    {
      name: "Suspension reason",
      value: "suspensionReason",
    },
    {
      name: "Last active",
      value: "lastUsed",
    },
    {
      name: "Registration date",
      value: "createdAt",
    },
    {
      name: "Device",
      value: "device",
    },
    {
      name: "Device ID(s)",
      value: "devices",
    },
    {
      name: "OS version",
      value: "os",
    },
    {
      name: "App version",
      value: "startVersion",
    },
    {
      name: "Language",
      value: "languageCode",
    },
    {
      name: "Operator",
      value: "carrier",
    },
  ];

  const verticalData2 = [
    {
      name: "User IP",
      value: "ipAddress",
    },
    {
      name: "Name",
      value: "name",
    },
    {
      name: "Mobile no",
      value: "mobile",
    },
    {
      name: "User ID",
      value: "id",
    },
    {
      name: "Email",
      value: "email",
    },
    {
      name: "Age",
      value: "age",
    },
    {
      name: "Gender",
      value: "gender",
    },
    {
      name: "State",
      value: "state",
    },
    {
      name: "City",
      value: "city",
    },
    {
      name: "Sell status",
      value: "sellStatus",
    },
    {
      name: "KYC status",
      value: "isKycDone",
    },
  ];

  const verticalData3 = [
    {
      name: "DG buy orders",
      value: "totalBuyOrder",
    },
    {
      name: "DG sell orders",
      value: "totalSellOrder",
    },
    {
      name: "DG failed orders",
      value: "totalFailedOrder",
    },
    {
      name: "Total order amount",
      value: "totalOrderAmount",
    },
    {
      name: "Gold Balance(gms)",
      value: "balance",
    },
    {
      name: "Gold Price(INR)",
      value: "price",
    },
    {
      name: "Gold Balance(INR)",
      value: "balanceInr",
    },
  ];

  const verticalData4 = [
    {
      name: "EMI order count",
      value: "emiOrderCount",
    },
    {
      name: "EMI Redeem order count",
      value: "emiRedeemOrderCount",
    },
    {
      name: "Redeem Order Gold Weight",
      value: "redeemOrderGoldWeight",
    },
    {
      name: "Total Amount Paid",
      value: "totalAmountPaid",
    },
    {
      name: "Total amount pending",
      value: "totalAmountPending",
    },
    {
      name: "Total EMI amount paid",
      value: "totalEmiAmountPaid",
    },
    {
      name: "Total EMI amount pending",
      value: "totalEmiAmountPending",
    },
    {
      name: "Upcoming EMI date",
      value: "upcomingEmiDate",
    },
  ];

  const counter = useRef(0);

  const fetchKYCDocument = useCallback(
    (isKycDone) => {
      if (
        userId &&
        isKycDone &&
        isAdmin
      ) {
        axios
          .request({
            method: API_ENDPOINTS.USER.GET_ALL_DOCUMENTS.METHOD,
            url: API_ENDPOINTS.USER.GET_ALL_DOCUMENTS.URL.replace(
              ":userId",
              userId,
            ),
            data: null,
          })
          .then((data) => {
            setKycDocumentAPIResponse(data.data.result);
          })
          .catch(() => {
            snackbar.showError(
              "Something went wrong while fetching KYC document",
            );
          });
      }
    },
    [userId, isAdmin, snackbar],
  );

  const fetchTableData = useCallback(
    (userId) => {
      if (counter.current === 0) {
        counter.current = 1;
        setLoading({...loading, filters: true});
        axios
          .request({
            method: API_ENDPOINTS.USER.GET_USER_DETAILS.METHOD,
            url: API_ENDPOINTS.USER.GET_USER_DETAILS.URL.replace(
              ":userId",
              userId,
            ),
            data: null,
          })
          .then((response) => {
            if (response && response.data && response.data.result) {
              setData({});
              const {isKycDone} = response.data.result;
              response.data.result["isKycDone"] = isKycDone
                ? "DONE"
                : "NOT DONE";
              setData(response.data.result);
              fetchKYCDocument(isKycDone);
              setLoading({...loading, filters: false});
              fetchUserVault(userId, (error, userVault) => {
                response.data.result = Object.assign(
                  response.data.result,
                  userVault,
                );
                const price = localStorage.getItem("price_GOLD_AUGMONT");
                if (price && price !== "null") {
                  response.data.result.price = price;
                  response.data.result.balanceInr = (
                    price * response.data.result.balance
                  ).toFixed(2);
                }
                setData({});
                setData(response.data.result);
                fetchUserAdvocate(userId, (error, userAdvocate) => {
                  response.data.result["userAdvocate"] = userAdvocate;
                  setData({});
                  setData(response.data.result);
                  fetchDeviceDetails(userId, (error, deviceDetails) => {
                    if (deviceDetails !== null) {
                      deviceDetails.lastUsed = formatDate(
                        deviceDetails?.lastUsed,
                        false,
                      );
                    }
                    response.data.result = Object.assign(
                      response.data.result,
                      deviceDetails,
                    );
                    setData({});
                    setData(response.data.result);
                    fetchEmiStats(userId, (error, emiStats) => {
                      response.data.result = Object.assign(
                        response.data.result,
                        emiStats,
                      );
                      setData({});
                      setData(response.data.result);
                      fetchDgStats(userId, (error, dgStats) => {
                        response.data.result = Object.assign(
                          response.data.result,
                          dgStats,
                        );
                        response.data.result.createdAt = formatDate(
                          response.data.result.createdAt,
                        );
                        response.data.result.upcomingEmiDate = formatDate(
                          response.data.result.upcomingEmiDate,
                        );
                        setData({});
                        setData(response.data.result);
                        fetchUserPermission(userId, (error, permission) => {
                          if (response.data.result !== null) {
                            response.data.result.sellStatus =
                              !permission?.sellStatus
                                ? `BLOCKED (${permission?.sellBanReason})`
                                : "ACTIVE";
                          }

                          setData({});
                          setData(response.data.result);
                          fetchDeviceList(userId, (error, devices) => {
                            response.data.result.devices = (devices || []).join(
                              ",",
                            );
                            setData({});
                            setData(response.data.result);
                          });
                        });
                      });
                    });
                  });
                });
              });
            }
          })
          .catch((err) => {
            setLoading({...loading, filters: false});
            if (
              err &&
              err.response &&
              [401, 403, 422].includes(err.response.status)
            ) {
              snackbar.showError(
                err?.response?.data?.error?.message ||
                  "Something went wrong while fetching User Details",
              );
            } else {
              snackbar.showError("Something went wrong");
            }
          });
      }
    },
    [loading, snackbar, fetchKYCDocument],
  );

  const handleDocumentDownload = (docId, name) => {
    axios
      .request({
        method: API_ENDPOINTS.USER.DOWNLOAD_DOCUMENT.METHOD,
        url: `${API_ENDPOINTS.USER.DOWNLOAD_DOCUMENT.URL}?documentDetailId=${docId}`,
        data: null,
      })
      .then((data) => {
        const {kycDocumentSignedUrl} = data.data.result;
        // followed the axios approach from here.
        axios({
          url: kycDocumentSignedUrl,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {
            snackbar.showError("Unable to download file");
          });
      })
      .catch(() => {
        snackbar.showError("Unable to download file");
      });
  };

  React.useEffect(() => {
    if (userId) {
      fetchTableData(userId);
      setId(userId);
    }
  }, [fetchTableData, userId, fetchKYCDocument]);

  function fetchUserVault(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_USER_VAULT.METHOD,
        url: `${API_ENDPOINTS.USER.GET_USER_VAULT.URL.replace(
          ":userId",
          userId,
        )}?assetName=GOLD&partnerName=AUGMONT`,
        data: null,
      })
      .then((response2) => {
        if (response2 && response2.data && response2.data.result) {
          return callback(null, response2.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchUserAdvocate(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.REFERRAL.GET_USER_ADVOCATE.METHOD,
        url: API_ENDPOINTS.REFERRAL.GET_USER_ADVOCATE.URL.replace(
          ":userId",
          userId,
        ),
        data: null,
      })
      .then((response2) => {
        if (response2 && response2.data && response2.data.result) {
          return callback(null, response2.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchDeviceDetails(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_DEVICE_DETAILS.METHOD,
        url: API_ENDPOINTS.USER.GET_DEVICE_DETAILS.URL.replace(
          ":userId",
          userId,
        ),
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          return callback(null, response.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchEmiStats(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.EMI.GET_EMI_ORDER_STATS.METHOD,
        url: API_ENDPOINTS.EMI.GET_EMI_ORDER_STATS.URL.replace(
          ":userId",
          userId,
        ),
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          return callback(null, response.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchDgStats(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.DG.GET_DG_ORDER_STATS.METHOD,
        url: API_ENDPOINTS.DG.GET_DG_ORDER_STATS.URL.replace(":userId", userId),
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          return callback(null, response.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchUserPermission(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_USER_PERMISSION.METHOD,
        url: API_ENDPOINTS.USER.GET_USER_PERMISSION.URL.replace(
          ":userId",
          userId,
        ),
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          return callback(null, response.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  function fetchDeviceList(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_DEVICE_LIST.METHOD,
        url: API_ENDPOINTS.USER.GET_DEVICE_LIST.URL.replace(":userId", userId),
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          return callback(null, response.data.result);
        }
        return callback(null, {});
      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  async function fetchGoldLoanList(mobile) {
    const {response} = await goldApiService({
      resource: {
        MEHTOD: "GET",
        URL: "/admin/service-desk/loan/list",
      },
      options: {
        queryParams: {
          customerMobile: mobile,
        },
      },
    });

    if (response.length > 0) {
      setGoldLoanList(response);
    }
  }

  async function fetchRequestList(mobile) {
    const {response} = await goldApiService({
      resource: {
        MEHTOD: "GET",
        URL: "/admin/service-desk/request-list",
      },
      options: {
        queryParams: {
          customerMobile: mobile,
          pageSize: 100,
          pageNo: 1,
        },
      },
    });

    if (response.length > 0) {
      setRequestList(response);
    }
  }

  useEffect(() => {
    if (data.mobile && !goldLoanList.length) {
      fetchGoldLoanList(data.mobile);
    }

    if (data.mobile && !requestList.length) {
      fetchRequestList(data.mobile);
    }
  }, [data, goldLoanList, requestList]);

  return (
    <Paper className={classes.root}>
      {/* HEADER */}
      {header ? (
        <Row className="mx-0">
          <Col className={classes.header}>USER PROFILE - {id}</Col>
        </Row>
      ) : null}

      {/* DIALOG */}
      {dialog ? (
        <Dialog
          open={modal.bankDetails.open}
          TransitionComponent={ModalTransition}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, bankDetails: {open: false, userId: null}})
          }
        >
          {modal.bankDetails.userId ? (
            <BankDetails userId={modal.bankDetails.userId} header table />
          ) : null}
        </Dialog>
      ) : null}
      {dialog ? (
        <Dialog
          open={modal.cardDetails.open}
          TransitionComponent={ModalTransition}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, cardDetails: {open: false, userId: null}})
          }
        >
          {modal.cardDetails.userId ? (
            <CardDetails userId={modal.cardDetails.userId} header table />
          ) : null}
        </Dialog>
      ) : null}
      {dialog ? (
        <Dialog
          open={modal.upiDetails.open}
          TransitionComponent={ModalTransition}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, upiDetails: {open: false, userId: null}})
          }
        >
          {modal.upiDetails.userId ? (
            <UpiDetails userId={modal.upiDetails.userId} header table />
          ) : null}
        </Dialog>
      ) : null}
      {dialog ? (
        <Dialog
          open={modal.addressDetails.open}
          TransitionComponent={ModalTransition}
          maxWidth="md"
          onClose={() =>
            setModal({
              ...modal,
              addressDetails: {open: false, userId: null},
            })
          }
        >
          {modal.addressDetails.userId ? (
            <AddressDetails userId={modal.addressDetails.userId} header table />
          ) : null}
        </Dialog>
      ) : null}
      {dialog ? (
        <Dialog
          open={modal.referralDetails.open}
          TransitionComponent={ModalTransition}
          maxWidth="md"
          onClose={() =>
            setModal({
              ...modal,
              referralDetails: {open: false, userId: null},
            })
          }
        >
          {modal.referralDetails.userId ? (
            <ReferralDetails
              userId={modal.referralDetails.userId}
              header
              table
            />
          ) : null}
        </Dialog>
      ) : null}

      {!loading.filters ? (
        <div className="mx-3 my-3">
          <Row>
            <Col md="3" xs="12" className="mx-3">
              <Row>
                <Col style={{textAlign: "left"}}>
                  <img
                    src={
                      data.profilePicture
                        ? data.profilePicture
                        : "/media/users/profile.png"
                    }
                    style={{width: "70%"}}
                    alt="profile"
                  />
                </Col>
              </Row>
              {verticalData.map((row, index) => {
                return (
                  <Row key={index} className="my-4" justify="center">
                    <Col md="5" xs="5" align="left">
                      <span style={{fontWeight: "bold"}}>{row.name}</span>
                    </Col>
                    <Col md="1" xs="1">
                      :
                    </Col>
                    <Col md="6" xs="4" align="left">
                      <span style={{overflowWrap: "break-word"}}>
                        {data[row.value]}
                      </span>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Divider variant="fullWidth" flexItem orientation="vertical" />
            <Col md="4" xs="12" className="mx-3">
              {verticalData2.map((row, index) => {
                return (
                  <Row key={index} className="my-4" justify="center">
                    <Col md="4" xs="5" align="left">
                      <span style={{fontWeight: "bold"}}>{row.name}</span>
                    </Col>
                    <Col md="1" xs="1">
                      :
                    </Col>
                    <Col md="7" xs="4" align="left">
                      <span style={{overflowWrap: "break-word"}}>
                        {row.value === "email" ? (
                          <span>
                            <a href={`mailto:${row.value}`}>{data.email}</a>
                            {data.emailVerified ? " (Verified)" : ""}
                          </span>
                        ) : (
                          data[row.value]
                        )}
                      </span>
                    </Col>
                  </Row>
                );
              })}
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      setModal({
                        ...modal,
                        cardDetails: {open: true, userId: id},
                      })
                    }
                  >
                    <b>
                      <u>Card details</u>
                    </b>
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      setModal({
                        ...modal,
                        bankDetails: {open: true, userId: id},
                      })
                    }
                  >
                    <b>
                      <u>Bank details</u>
                    </b>
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      setModal({
                        ...modal,
                        addressDetails: {open: true, userId: id},
                      })
                    }
                  >
                    <b>
                      <u>Address details</u>
                    </b>
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      setModal({
                        ...modal,
                        upiDetails: {open: true, userId: id},
                      })
                    }
                  >
                    <b>
                      <u>UPI details</u>
                    </b>
                  </span>
                </Col>
              </Row>
              {data.userAdvocate && data.userAdvocate.isAdvocatePresent ? (
                <Row className="my-2">
                  <Col>
                    <span
                      style={{
                        color: "#4050B5",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Referred by: {data.userAdvocate.name} (
                    </span>
                    <span
                      style={{
                        color: "#4050B5",
                        fontStyle: "italic underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                      }}
                      onClick={() =>
                        openInNewTab(
                          `/${DIGITAL_GOLD}/${USERS}/all-users/${data.userAdvocate.userId}`,
                        )
                      }
                    >
                      <b>
                        <u>{data.userAdvocate.userId}</u>
                      </b>
                    </span>
                    )
                  </Col>
                </Row>
              ) : null}
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      setModal({
                        ...modal,
                        referralDetails: {open: true, userId: id},
                      })
                    }
                  >
                    <b>
                      <u>Users Referred</u>
                    </b>
                  </span>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <span
                    style={{
                      color: "#000",
                      fontStyle: "italic underline",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                  >
                    <b>
                      <u>Gold Loan ID: </u>
                    </b>
                  </span>
                </Col>
                <Col style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                    {goldLoanList.map((loan) => {
                      return (
                        <span
                          key={loan.id}
                          style={{
                            color: "#4050B5",
                            fontStyle: "italic underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "1.3rem",
                          }}
                          onClick={() =>
                            openInNewTab(
                              `/gold-loan/customer-profiles/${userId}/${loan.id}`,
                            )
                          }
                        >
                          <b>
                            <u>{loan.id}</u>
                          </b>
                        </span>
                      );
                    })}
                </Col>
                <Col>
                <span
                    style={{
                      color: "#000",
                      fontStyle: "italic underline",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                  >
                    <b>
                      <u>Request ID: </u>
                    </b>
                  </span>
                </Col>
                <Col style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                    {requestList.map((request) => {
                      return (
                        <span
                          key={request.id}
                          style={{
                            color: "#4050B5",
                            fontStyle: "italic underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "1.3rem",
                          }}
                          onClick={() => {
                            let url = "";

                            if (request.requestType === "SERVICE") {
                              url = `/gold-loan/service-desk/loan-services?searchType=requestId&id=${request.id}&serviceType=${request.serviceRequestCategory}&requestId=${request.id}`;
                              openInNewTab(
                                url,
                              );
                              return;
                            }
                            if (request.requestType === "COMPLAINT") {
                              if (request.product === "NON_GOLD_LOAN") {
                                dispatch(setComplaintsActiveTab("NON_GOLD_LOAN"));
                              }
                              url = `/gold-loan/service-desk/complaints?searchType=requestId&id=${request.id}&requestId=${request.id}`;
                              navigate(url);
                              return;
                            }
                          }}
                        >
                          <b>
                            <u>{request.id}</u>
                          </b>
                        </span>
                      );
                    })}
                </Col>
              </Row>
            </Col>
            <Divider variant="fullWidth" flexItem orientation="vertical" />
            <Col md="4" xs="12" className="mx-3">
              {verticalData3.map((row, index) => {
                return (
                  <Row key={index} className="my-4" justify="center">
                    <Col md="7" xs="5" align="left">
                      <span style={{fontWeight: "bold"}}>{row.name}</span>
                    </Col>
                    <Col md="1" xs="1">
                      :
                    </Col>
                    <Col md="4" xs="4" align="left">
                      <span style={{overflowWrap: "break-word"}}>
                        {data[row.value]}
                      </span>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      openInNewTab(`/${DIGITAL_GOLD}/${DG_ORDER}?userId=${id}`)
                    }
                  >
                    <b>
                      <u>View all DG orders</u>
                    </b>
                  </span>
                </Col>
              </Row>
              <Divider variant="fullWidth" className="my-4" />
              {verticalData4.map((row, index) => {
                return (
                  <Row key={index} className="my-4" justify="center">
                    <Col md="7" xs="5" align="left">
                      <span style={{fontWeight: "bold"}}>{row.name}</span>
                    </Col>
                    <Col md="1" xs="1">
                      :
                    </Col>
                    <Col md="4" xs="4" align="left">
                      <span style={{overflowWrap: "break-word"}}>
                        {data[row.value]}
                      </span>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col>
                  <span
                    style={{
                      color: "#4050B5",
                      fontStyle: "italic underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                    onClick={() =>
                      openInNewTab(
                        `/${DIGITAL_GOLD}/${EMI_ORDERS}?userId=${id}`,
                      )
                    }
                  >
                    <b>
                      <u>View all EMI orders</u>
                    </b>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-5"></Row>
        </div>
      ) : (
        <Row align="center">
          <Col>
            <span
              className="spinner-grow spinner-grow-xl"
              style={{width: "200px", height: "200px", color: "#FF92AC"}}
            ></span>
          </Col>
        </Row>
      )}

      {isAdmin &&
        data.isKycDone === "DONE" &&
        kycDocumentAPIResponse && (
          <div>
            <Row className="mx-0">
              <Col className={classes.header}>KYC Documents</Col>
            </Row>
            <div
              style={{
                display: "flex",
                gap: "4rem",
                width: "80%",
                paddingTop: "2rem",
                paddingLeft: "2rem",
                paddingBottom: "5rem",
              }}
            >
              {kycDocumentAPIResponse.map((document) => (
                <Paper
                  variant="elevation"
                  elevation={3}
                  className={classes.docContainer}
                >
                  <div>
                    <img
                      className={classes.imageHolder}
                      src={document.signedUrlForDocument}
                      alt="document"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      Document ID: {document.id}
                    </div>
                    <div>
                      <DownloadIcon
                        onClick={() =>
                          handleDocumentDownload(
                            document.id,
                            document.documentUrl,
                          )
                        }
                        style={{cursor: "pointer", heigth: "2rem"}}
                      />
                    </div>
                  </div>
                </Paper>
              ))}
            </div>
          </div>
        )}
    </Paper>
  );
}
