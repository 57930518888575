import {goldApiService} from "app/infra/plugins/axios";

const RESOURCE = {
  URL: "/business/cr/:crId/bank-detail",
  METHOD: "GET",
};
const getCustomerBankAccountDetails = async ({
  bankDetailType,
  crId,
}) => {
  return await goldApiService({
    resource: RESOURCE,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        bankDetailType,
      },
    },
  });
};

export default getCustomerBankAccountDetails;
