import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const getNotifications = async ({accessToken, fromTime, pageSize}) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "x-access-token": accessToken,
        "Content-type": "application/json",
      },
      url: `${getApiUrl()}/admin/business/notification/list?fromTime=${fromTime}&pageSize=${pageSize}`,
    });

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getNotifications;
