import React, {FC} from "react";
import {LeegalityContainer, Title} from "../styles";
import {Typography} from "@mui/material";

interface DocumentStatusContainerProps {
  type: string;
  isDocStatusCompleted: boolean;
}

const DocumentStatusContainer: FC<DocumentStatusContainerProps> = ({
  type,
  isDocStatusCompleted,
}) => {
  return (
    <LeegalityContainer>
      <Title>{type} Document Status</Title>
      {isDocStatusCompleted ? (
        <Typography
          variant="h6"
          sx={{
            color: "green",
            fontWeight: "bold",
          }}
        >
          Documents Submitted by LM
        </Typography>
      ) : (
        <Typography
          variant="h6"
          sx={{
            color: "red",
            fontWeight: "bold",
          }}
        >
          Documents Not submitted
        </Typography>
      )}
    </LeegalityContainer>
  );
};

export default DocumentStatusContainer;
