import React, {useCallback, useRef} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@mui/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  LinearProgress,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {Col, Row} from "react-bootstrap";

import {
  getQueryStringParams,
  getFilterMap,
  getSearchQuery,
  openInNewTab,
  formatDate,
} from "_metronic";
import axios from "_metronic/utils/axios";
import UserDetails from "./UserDetails";
import UpiDetails from "./UpiDetails";
import BankDetails from "./BankDetails";
import CardDetails from "./CardDetails";
import DeviceDetails from "./DeviceDetails";
import AddressDetails from "./AddressDetails";
import UserActionsDropdown from "./components/UserActionsDropdown";
import {API_ENDPOINTS} from "app/constants/constants";
import ROUTES from "app/constants/routes";
import {useSnackBar} from "app/store/hooks";
import {IGDatePicker, IGDialog, IGSelect, IGTextField, IGButton} from "app/components";

const {DIGITAL_GOLD, USERS} = ROUTES;

const useStyles1 = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight/>
        ) : (
          <KeyboardArrowLeft/>
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft/>
        ) : (
          <KeyboardArrowRight/>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function Users(props) {
  const classes = useStyles2();
  const {showError, showSuccess} = useSnackBar();
  const userRole = localStorage.getItem("userRole");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalRows, setTotalRows] = React.useState(0);
  const [newStatus, setNewStatus] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState({
    email: "",
    mobile: "",
    name: "",
    userId: "",
    userStatus: "",
    fromTime: "",
    toTime: "",
  });
  const [fromTime, setFromTime] = React.useState("");
  const [toTime, setToTime] = React.useState("");
  const [modal, setModal] = React.useState({
    changeStatus: {
      open: false,
      userId: null,
      parentData: null,
      index: null,
    },
    banSell: {
      open: false,
      userId: null,
      reason: "",
    },
    banDevice: {
      open: false,
      userId: null,
      reason: "",
    },
    userDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    profilePic: {
      open: false,
      userId: null,
      image: null,
    },
    bankDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    cardDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    upiDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    deviceDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
    addressDetails: {
      open: false,
      userId: null,
      parentData: null,
    },
  });
  const [loading, setLoading] = React.useState({
    changeStatus: false,
    filters: false,
    actions: false,
    banDevice: false,
    banSell: false,
  });
  const filterMap = getFilterMap(["userId", "mobile"]);

  const headers = [
    "User ID",
    "Mobile no",
    "Name",
    "Email",
    "Is email verified?",
    "Gold balance(gms)",
    "Status",
    "Created at",
    "Suspension Reason",
    "Age",
    "Gender",
    "State",
    "Language",
  ];

  const valueKeys = [
    "id",
    "mobile",
    "name",
    "email",
    "isEmailVerified",
    "balance",
    "status",
    "createdAt",
    "suspensionReason",
    "age",
    "genderEnum",
    "state",
    "languageCode",
  ];

  const userStatusList = ["ACTIVE", "SUSPENDED", "DELETED"];

  const counter = useRef(0);

  const fetchBulkVaultInfo = useCallback(
    (userList, callback) => {
      if (userList.length) {
        axios
          .request({
            method: API_ENDPOINTS.USER.GET_BULK_USER_VAULT.METHOD,
            url:
              `${API_ENDPOINTS.USER.GET_BULK_USER_VAULT.URL
              }?assetName=GOLD&partnerName=AUGMONT`,
            data: {
              userIds: userList,
            },
          })
          .then(response2 => {
            if (response2 && response2.data && response2.data.result) {
              const vaultMap = {};
              response2.data.result.forEach(row => {
                vaultMap[row.userId] = row;
              });
              return callback(null, vaultMap);
            }
            return callback(null, {});

          })
          .catch(err => {
            setLoading({...loading, filters: false});
            if (
              err &&
              err.response &&
              [401, 403, 422].includes(err.response.status)
            ) {
              showError(
                err.response.data &&
                err.response.data.error &&
                err.response.data.error.message,
              );
            } else {
              showError("Something went wrong");
            }
            return callback(err, null);
          });
      } else {
        return callback(null, {});
      }
    },
    [loading],
  );

  const fetchTableData = useCallback(() => {
    const queryParams = getSearchQuery(page, rowsPerPage, searchFilter);
    if (!queryParams) return;

    setLoading({...loading, filters: true});
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_ALL_USERS.METHOD,
        url: `${API_ENDPOINTS.USER.GET_ALL_USERS.URL}?${queryParams}`,
        data: null,
      })
      .then(response => {
        if (response && response.data && response.data.result) {
          const userList = [];
          response.data.result.forEach(row => {
            row.isEmailVerified = row.isEmailVerified ? "True" : "False";
            row.createdAt = formatDate(row.createdAt);
            row.suspensionReason = row.suspensionReason?.replace("_", " ");
            userList.push(row.id);
          });
          fetchBulkVaultInfo(userList, (error, vaultMap) => {
            if (error) {
              return;
            }
            response.data.result.forEach(row => {
              if (vaultMap[row.id]) {
                const vault = vaultMap[row.id];
                row.balance = vault.balance;
              }
            });
            setLoading({...loading, filters: false});
            setRows(response.data.result);
            if (response.data.result.length < rowsPerPage) {
              setTotalRows(page * rowsPerPage + response.data.result.length);
            } else {
              setTotalRows((page + 1) * rowsPerPage + 1);
            }
          });
        }
      })
      .catch(err => {
        setLoading({...loading, filters: false});
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          showError(
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message,
          );
        } else {
          showError("Something went wrong");
        }
      });
  }, [fetchBulkVaultInfo, loading, page, rowsPerPage, searchFilter]);

  React.useEffect(() => {
    const queryParams = getQueryStringParams(window.location.search);
    Object.keys(queryParams).forEach(key => {
      if (key in searchFilter) {
        searchFilter[key] = queryParams[key];
        setSearchFilter({
          ...searchFilter,
          key: queryParams[key],
        });
      }
    });
    if (counter.current === 0) {
      counter.current = 1;
      fetchTableData();
    }
    return () => {
    };
  }, [fetchTableData, page, rowsPerPage, searchFilter]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 100));
  }

  function changeUserStatus(userId) {
    if (!userStatusList.includes(newStatus)) {
      showError("Invalid Status");
      return;
    }
    setLoading({...loading, changeStatus: true});
    axios
      .request({
        method: API_ENDPOINTS.USER.CHANGE_USER_STATUS.METHOD,
        url: API_ENDPOINTS.USER.CHANGE_USER_STATUS.URL.replace(
          ":userId",
          userId,
        ).replace(":status", newStatus),
        data: null,
      })
      .then(response => {
        setLoading({...loading, changeStatus: false});
        if (response && response.data && response.data.result) {
          showSuccess("Status updated successfully!");
          rows[modal.changeStatus.index].status = newStatus;
          setRows(rows);
          setModal({...modal, changeStatus: {open: false, userId: null}});
          setNewStatus("");
        }
      })
      .catch(err => {
        setLoading({...loading, changeStatus: false});
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          showError(
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message,
          );
        } else {
          showError("Something went wrong");
        }
      });
  }

  function banSell() {
    if (!modal.banSell.reason.trim()) {
      showError("Reason is required");
      return;
    }
    loading.banSell = true;
    axios
      .request({
        method: API_ENDPOINTS.USER.CHANGE_SELL_STATUS.METHOD,
        url: API_ENDPOINTS.USER.CHANGE_SELL_STATUS.URL,
        data: {
          userId: modal.banSell.userId,
          adminNote: modal.banSell.reason,
          sellBan: true,
        },
      })
      .then(response => {
        loading.banSell = false;
        if (response && response.data && response.data.result) {
          setModal({
            ...modal,
            banSell: {open: false, userId: null, reason: ""},
          });
          showSuccess("User sell banned successfully!");
        }
      })
      .catch(err => {
        loading.banSell = false;
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          showError(
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message);
        } else {
          showError("Something went wrong");
        }
      });
  }

  function banDevice() {
    if (!modal.banDevice.reason.trim()) {
      showError("Reason is required");
      return;
    }
    loading.banDevice = true;
    axios
      .request({
        method: API_ENDPOINTS.APP_CONFIG.BAN_DEVICE.METHOD,
        url: API_ENDPOINTS.APP_CONFIG.BAN_DEVICE.URL,
        data: {
          userId: modal.banDevice.userId,
          reason: modal.banDevice.reason,
        },
      })
      .then(response => {
        loading.banDevice = false;
        if (response && response.data && response.data.result) {
          setModal({
            ...modal,
            banDevice: {open: false, userId: null, reason: ""},
          });
          showSuccess("Device banned successfully!");
        }
      })
      .catch(err => {
        loading.banDevice = false;
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          showError(
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message);
        } else {
          showError("Something went wrong");
        }
      });
  }

  function applyFilters() {
    let atLeastOneFilterSelected = false;
    Object.keys(searchFilter).forEach(key =>
      searchFilter[key] ? (atLeastOneFilterSelected = true) : null,
    );
    if (!atLeastOneFilterSelected && !fromTime && !toTime) return;

    if (parseInt(searchFilter.userId) <= 0) {
      showError("User ID cannot be negative.");
      return;
    }
    if (
      searchFilter.mobile &&
      (parseInt(searchFilter.mobile) <= 0 || searchFilter.mobile.length !== 10)
    ) {
      showError("Phone No not valid.");
      return;
    }
    searchFilter.fromTime = moment(fromTime)
      .startOf("day")
      .valueOf();
    searchFilter.toTime = moment(toTime)
      .endOf("day")
      .valueOf();
    if (page === 0) fetchTableData();
    else setPage(0);
  }

  function clearFilters() {
    setSearchFilter({
      email: "",
      mobile: "",
      name: "",
      userId: "",
      userStatus: "",
      fromTime: "",
      toTime: "",
    });
    setFromTime("");
    setToTime("");
    if (page === 0) fetchTableData();
    else setPage(0);
  }

  return (
    <Paper className={classes.root}>

      {/* DIALOG */}
      {props.dialog && (
        <IGDialog
          title="Change Status"
          open={modal.changeStatus.open}
          onClose={() => {
            setModal({
              ...modal,
              changeStatus: {open: false, userId: null, index: null},
            });
            setNewStatus("");
          }}
          onCancel={() => {
            setModal({
              ...modal,
              changeStatus: {open: false, userId: null, index: null},
            });
            setNewStatus("");
          }}
          onConfirm={() => changeUserStatus(modal.changeStatus.userId)}
          confirmProps={{
            disabled: loading.changeStatus,
            loading: loading.changeStatus,
            text: "Change Status",
          }}
        >
          <IGSelect
            name="userStatus"
            label="User Status"
            value={newStatus}
            onChange={e => setNewStatus(e.target.value)}
            options={userStatusList.map(userStatus => ({
              text: userStatus,
              value: userStatus,
            }))}
          />
        </IGDialog>
      )}

      {props.dialog && (
        <IGDialog
          title="Ban Device"
          open={modal.banDevice.open}
          onClose={() => {
            setModal({
              ...modal,
              banDevice: {open: false, userId: null, reason: ""},
            });
          }}
          onCancel={() => {
            setModal({
              ...modal,
              banDevice: {open: false, userId: null, reason: ""},
            });
          }}
          confirmProps={{text: "Ban"}}
          onConfirm={() => banDevice()}
        >
          <IGTextField
            name="reason"
            label="Reason"
            value={modal.banDevice.reason}
            onChange={e =>
              setModal({
                ...modal,
                banDevice: {...modal.banDevice, reason: e.target.value},
              })
            }
          />
        </IGDialog>
      )}

      {props.dialog && (
        <IGDialog
          title="Ban Sell"
          open={modal.banSell.open}
          onClose={() => {
            setModal({
              ...modal,
              banSell: {open: false, userId: null, reason: ""},
            });
          }}
          onCancel={() => {
            setModal({
              ...modal,
              banSell: {open: false, userId: null, reason: ""},
            });
          }}
          onConfirm={() => banSell()}
          confirmProps={{text: "Ban"}}
        >
          <IGTextField
            name="reason"
            label="Reason"
            value={modal.banSell.reason}
            onChange={e =>
              setModal({
                ...modal,
                banSell: {...modal.banSell, reason: e.target.value},
              })
            }
          />
        </IGDialog>
      )}

      {props.dialog ? (
        <IGDialog
          hideFooter
          title="User Details"
          open={modal.userDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, userDetails: {open: false, userId: null}})
          }
        >
          {modal.userDetails.userId ? (
            <UserDetails
              userId={modal.userDetails.userId}
              header
              onClick={() => {
                setSearchFilter({
                  ...searchFilter,
                  userId: modal.userDetails.userId,
                });
                searchFilter.userId = modal.userDetails.userId;
                applyFilters();
                setModal({
                  ...modal,
                  userDetails: {open: false, userId: null},
                });
              }}
            />
          ) : null}
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.profilePic.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, profilePic: {open: false, image: null}})
          }
        >
          <Row className="mx-0">
            <Col className={classes.header}>
              USER PROFILE PIC FOR - {modal.profilePic.userId}
            </Col>
          </Row>
          <Row className="mx-0">
            <Col
              className="m-5 pa-5"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontStyle: "bold",
                color: "red",
              }}
            >
              {modal.profilePic.image ? (
                <img alt="profile" src={modal.profilePic.image}/>
              ) : (
                <span>No Profile picture!</span>
              )}
            </Col>
          </Row>
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.bankDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, bankDetails: {open: false, userId: null}})
          }
        >
          {modal.bankDetails.userId ? (
            <BankDetails userId={modal.bankDetails.userId} header table/>
          ) : null}
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.cardDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, cardDetails: {open: false, userId: null}})
          }
        >
          {modal.cardDetails.userId ? (
            <CardDetails userId={modal.cardDetails.userId} header table/>
          ) : null}
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.upiDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, upiDetails: {open: false, userId: null}})
          }
        >
          {modal.upiDetails.userId ? (
            <UpiDetails userId={modal.upiDetails.userId} header table/>
          ) : null}
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.addressDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({
              ...modal,
              addressDetails: {open: false, userId: null},
            })
          }
        >
          {modal.addressDetails.userId ? (
            <AddressDetails userId={modal.addressDetails.userId} header table/>
          ) : null}
        </IGDialog>
      ) : null}
      {props.dialog ? (
        <IGDialog
          hideFooter
          open={modal.deviceDetails.open}
          maxWidth="md"
          onClose={() =>
            setModal({...modal, deviceDetails: {open: false, userId: null}})
          }
        >
          {modal.deviceDetails.userId ? (
            <DeviceDetails userId={modal.deviceDetails.userId} header/>
          ) : null}
        </IGDialog>
      ) : null}

      {/* HEADER */}
      {props.header ? (
        <Row>
          <Col className={classes.header}>USERS</Col>
        </Row>
      ) : null}

      {/* FILTERS */}
      {props.filters ? (
        <div>
          <Row className="my-3 mx-3">
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("userStatus") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGSelect
                  name="userStatus"
                  label="User Status"
                  value={searchFilter.userStatus}
                  onChange={e =>
                    setSearchFilter({
                      ...searchFilter,
                      userStatus: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                  options={["", ...userStatusList].map(userStatus => ({
                    text: userStatus.length > 0 ? userStatus : "-",
                    value: userStatus,
                  }))}
                />
              </Col>
            ) : null}
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("userId") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGTextField
                  name="userId"
                  label="User ID"
                  type="number"
                  value={searchFilter.userId}
                  onChange={e =>
                    setSearchFilter({...searchFilter, userId: e.target.value})
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                />
              </Col>
            ) : null}
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("name") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGTextField
                  name="name"
                  label="Name"
                  value={searchFilter.name}
                  onChange={e =>
                    setSearchFilter({...searchFilter, name: e.target.value})
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                />
              </Col>
            ) : null}
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("mobile") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGTextField
                  name="mobileNo"
                  type="number"
                  label="Mobile No"
                  value={searchFilter.mobile}
                  onChange={e =>
                    setSearchFilter({...searchFilter, mobile: e.target.value})
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                />
              </Col>
            ) : null}
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("fromTime") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <form className={classes.container} noValidate>
                  <IGDatePicker
                    disablePast={false}
                    name="fromDate"
                    label="From Date"
                    value={fromTime}
                    onChange={(date) => setFromTime(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        applyFilters();
                      }
                    }}
                  />
                </form>
              </Col>
            ) : null}
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("toTime") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <form className={classes.container} noValidate>
                  <IGDatePicker
                    disablePast={false}
                    name="toDate"
                    label="To Date"
                    value={toTime}
                    onChange={(date) => setToTime(date)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        applyFilters();
                      }
                    }}
                  />
                </form>
              </Col>
            ) : null}
          </Row>
          <Row className="my-3 mx-3">
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("email") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGTextField
                  name="email"
                  label="Email"
                  type="email"
                  value={searchFilter.email}
                  onChange={e =>
                    setSearchFilter({...searchFilter, email: e.target.value})
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                />
              </Col>
            ) : null}
            <Col md="3" xs="6" className="mt-4">
              <IGButton
                disabled={loading.filters}
                color="golden"
                onClick={() => applyFilters()}
                loading={loading.filters}
              >
                Apply
              </IGButton>
              <IGButton
                disabled={loading.filters}
                loading={loading.filters}
                color="sdGrey"
                onClick={() => clearFilters()}
                sx={{
                  marginLeft: 1,
                }}
              >
                Clear
              </IGButton>
            </Col>
          </Row>
        </div>
      ) : null}

      {/* TABLE */}
      {props.table ? (
        <div className={classes.tableWrapper}>
          {!loading.filters ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    <TableCell align="center" key={header}>
                      {header}
                    </TableCell>
                  ))}
                  {props.actions ? (
                    <TableCell align="center" key="Actions">
                      Actions
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    {valueKeys.map(key => (
                      <TableCell
                        align="center"
                        key={key}
                        style={{minWidth: "100px"}}
                      >
                        {["DELETED"].includes(row[key]) ? (
                          <span
                            style={{color: "#F60357", fontWeight: "bold"}}
                          >
                            {row[key]}
                          </span>
                        ) : ["ACTIVE"].includes(row[key]) ? (
                          <span style={{color: "green", fontWeight: "bold"}}>
                            {row[key]}
                          </span>
                        ) : ["SUSPENDED"].includes(row[key]) ? (
                          <span style={{color: "#cc0", fontWeight: "bold"}}>
                            {row[key]}
                          </span>
                        ) : key === "id" && userRole !== "AUGMONT" ? (
                          <span
                            style={{
                              color: "blue",
                              fontStyle: "italic underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              openInNewTab(
                                `/${DIGITAL_GOLD}/${USERS}/all-users/${row.id}`,
                              )
                            }
                          >
                            <b>
                              <u>{row[key]}</u>
                            </b>
                          </span>
                        ) : (
                          row[key]
                        )}
                      </TableCell>
                    ))}
                    {props.actions ? (
                      <TableCell align="center">
                        <div>
                          <UserActionsDropdown
                            row={row}
                            index={index}
                            modal={modal}
                            setModal={setModal}
                            setNewStatus={setNewStatus}
                          />
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={10}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {"aria-label": "Rows per page"},
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <LinearProgress/>
          )}
        </div>
      ) : null}
    </Paper>
  );
}
