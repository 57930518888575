import {RootState} from "../store";
import {LoanInstallmentModel, VirtualAddressModel} from "../../models/loan.model";

const LoanSelectors = {
  getLoanList: (state: RootState) => Object.values(state.loan.loans),
  getLoanDetails: (state: RootState, loanId: number) =>
    state.loan.loans[loanId],
  getLenderLoanDetails: (state: RootState, igLoanId: number) =>
    state.loan.loans[igLoanId],
  getUserLoans: (state: RootState, userId: number) =>
    Object.values(state.loan.loans).filter(l => l.userId === userId),
  getAllUserLoans: (state: RootState) => state.loan.allUserLoanList,
  getLoanInstalmentList: (
    state: RootState, loanId: number,
  ): LoanInstallmentModel[] =>
    Object.values(state.loan.loans).find(
      l => l.id === loanId,
    )?.loanInstallmentList || [],
  getLenderLoanInstalmentList: (
    state: RootState, igLoanId: number,
  ): LoanInstallmentModel[] =>
    Object.values(state.loan.loans).find(
      l => l.igLoanId === igLoanId,
    )?.loanInstallmentList || [],
  getAllVirtualAddresses: (
    state: RootState, loanId: number,
  ): VirtualAddressModel[] =>
    Object.values(state.loan.loans).find(
      l => l.id === loanId,
    )?.virtualAddressList || [],
  getLoading: (state: RootState) => state.loan.loading,
  getErrors: (state: RootState) => state.loan.errors,
};

export default LoanSelectors;