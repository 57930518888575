import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {agentAvailabilityActions, agentAvailabilityActionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {snackBarActions} from "../../snackbar";
import {UpdateAgentSchedulePayload} from "app/infra/services/api/serviceDesk/agentAvailability/types";
import {GetAgentsPayload} from "app/infra/services/api/scheduling/lmAvailability/types";

const {
  getAgentScheduleList,
  getAgentScheduleListFailure,
  getAgentScheduleListSuccess,
  getAgentsSuccess,
  getAgentsFailure,
  updateAgentScheduleSuccess,
  updateAgentScheduleFailure,
} = agentAvailabilityActions;

function* getAgentScheduleListFlow() {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.agentAvailability.getAgentScheduleList,
  );
  if (error) {
    yield put(getAgentScheduleListFailure({error, response: null}));
    yield put(
      snackBarActions.open({
        message: error.message || "Fetching agents' schedule failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getAgentScheduleListSuccess({error: null, response}));
  }
}

function* updateAgentScheduleFlow(
  action: PayloadAction<UpdateAgentSchedulePayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.agentAvailability.updateAgentSchedule,
    action.payload,
  );
  if (error) {
    yield put(updateAgentScheduleFailure({error, response: null}));
    yield put(
      snackBarActions.open({
        message: error.message || "Updating agent's schedule failed",
        variant: "error",
      }),
    );
  } else {
    yield put(
      updateAgentScheduleSuccess({
        error: null,
        response: {
          ...response,
          ...action.payload,
        },
      }),
    );
    yield put(
      snackBarActions.open({
        message: "Agent's availability has been updated successfully.",
        variant: "success",
      }),
    );
    yield put(getAgentScheduleList());
  }
}

function* getAgentsFlow(action: PayloadAction<GetAgentsPayload>) {
  const api: GoldApi = yield getContext("api");

  const {loanManagers, error} = yield call(
    api.lmAvailability.getAgents,
    action.payload,
  );
  if (error) {
    yield put(getAgentsFailure({error, loanManagerList: null}));
    yield put(
      snackBarActions.open({
        message: error.message || "Fetching agents' failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getAgentsSuccess({error, response: loanManagers}));
  }
}

export default function* agentAvailabilitySaga() {
  yield takeLatest(agentAvailabilityActionTypes.GetAgents, getAgentsFlow);

  yield takeLatest(
    agentAvailabilityActionTypes.GetAgentScheduleList,
    getAgentScheduleListFlow,
  );

  yield takeLatest(
    agentAvailabilityActionTypes.UpdateAgentSchedule,
    updateAgentScheduleFlow,
  );
  yield takeLatest(
    agentAvailabilityActionTypes.UpdateAgentScheduleSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Agent time blocked successfully",
          variant: "success",
        }),
      );
    },
  );
}
