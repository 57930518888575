import React, {useCallback, useEffect, useRef, useState, useMemo} from "react";

import styles from "../styles";
import getDocumentAPi from "api/business/getDocument";
import getLocationCoordinate from "api/business/getLocationCoordinate";
import getTakeOverDetails from "api/accounts/getTakeOverDetails";
import CheckRTGSComponent from "./CheckRTGS";
import {CircularProgress} from "@mui/material";
import ImageLink from "../CommonComponents/ImageLink";
import getCustomerBankAccountDetails from "api/accounts/getCustomerBankAccountDetails";
import getDocument from "api/business/getDocument";
import {useSnackBar, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {IGDocumentViewer, IGButton} from "app/components";
import CustomerBankDetails from "app/components/Accounts/Components/CustomerBankDetails";
import {Box, Typography, Divider} from "@mui/material";
import {AddBeneficiaryDocumentTypes} from "app/enums/accounts";
import {useIGTranslation} from "app/infra/plugins/i18n";
import activeLoansApi from "app/infra/services/api/activeLoans/api";

const CheckRTGS = ({
  selectedPayload,
  isSameCreditManager,
  isBackMovement,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const classes = styles();
  const {showError, showSuccess} = useSnackBar();
  const {tErrors} = useIGTranslation();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [isVerifyLenderLoading, setIsVerifyLenderLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      document: null,
    },
    errorMessage: "",
  });

  const [bankDetails, setBankDetails] = useState(null);
  const [chequeDocuments, setChequeDocuments] = useState(null);
  const [crJobId, setCrJobId] = useState(null);
  const [lenderTakeoverBankDetails, setLenderTakeoverBankDetails] = useState({
    lenderAccountNumber: null,
    lenderIfscCode: null,
    isLenderAccountNumberVerified: false,
  });

  const onClickHandler = useCallback(
    async (locationType) => {
      setButtonLoading(true);
      const response = await getLocationCoordinate({
        accessToken,
        crId: selectedPayload.id,
        locationType,
      });
      if (response.statusCode === 200) {
        const location = response.payload.locationCoordinates;

        if (location === null) {
          showError("Location is null");
          setButtonLoading(false);
          return;
        }
        const locationArray = location.split(",");

        const lat = locationArray[0];
        const lng = locationArray[1];

        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lng},${lat}`,
        );
      }
      setButtonLoading(false);
    },
    [selectedPayload.id],
  );

  const counter = useRef(0);

  const getDocuments = useCallback(() => {
    (async () => {
      try {
        const response = await getDocumentAPi({
          accessToken,
          crId: selectedPayload.id,
          documentType: [
            "TAKEOVER_PAYMENT_RTGS_RECEIPT",
            "TAKEOVER_PAYMENT_RTGS_RECEIPT_ZOOMED",
          ],
        });

        if (response.statusCode === 200) {
          setStates({
            error: false,
            loading: false,
            errorMessage: "",
            payload: {
              document: response.payload,
            },
          });
        } else {
          setStates({
            error: true,
            errorMessage: response?.error?.toString(),
            loading: false,
            payload: {
              document: null,
            },
          });
        }
      } catch (error) {
        setStates({
          error: true,
          errorMessage: "Something went Wrong",
          loading: false,
          payload: {
            document: null,
          },
        });
      }
    })();
  }, [selectedPayload]);

  const fetchCustomerBankAccountDetails = useCallback(() => {
    (async () => {
      try {
        const {
          response: bankDataResponse,
          error: bankDataResponseError,
        } = await getCustomerBankAccountDetails({
          bankDetailType: "TAKEOVER_BANK_DETAIL",
          crId: selectedPayload.id,
        });

        if (!bankDataResponseError) {
          setBankDetails(bankDataResponse);
        } else {
          showError(
            bankDataResponseError?.message || "Unable to get Bank Details",
          );
        }
      } catch (err) {
        showError("Unable to fetch Bank Details");
      }
    })();
  }, [showError, selectedPayload.id]);

  const fetchTakeoverBankDetails = useCallback(async () => {
    const {
      payload: takeoverBankDetailsPayload,
      statusCode,
    } = await getTakeOverDetails({
      accessToken,
      crId: selectedPayload.id,
    });
    if (statusCode === 200) {
      const {
        lenderBankAccountNumber,
        isLenderBankAccountNumberVerified,
        lenderIfscCode,
      } = takeoverBankDetailsPayload ?? {};
      setLenderTakeoverBankDetails((prevState) => ({
        ...prevState,
        lenderAccountNumber: lenderBankAccountNumber,
        lenderIfscCode: lenderIfscCode,
        isLenderAccountNumberVerified: isLenderBankAccountNumberVerified,
      }));
    } else {
      showError("Unable to fetch takeover bank details!");
    }
  }, [accessToken, selectedPayload.id]);

  const fetchChequeDocuments = useCallback(async () => {
    const {payload: chequeDocumentPayload, statusCode} = await getDocument({
      accessToken,
      crId: selectedPayload.id,
      documentType: [
        AddBeneficiaryDocumentTypes.CUSTOMER_LOAN_BANK_CHEQUE,
        AddBeneficiaryDocumentTypes.CUSTOMER_TAKEOVER_BANK_CHEQUE,
        AddBeneficiaryDocumentTypes.SM_CUSTOMER_LOAN_BANK_CHEQUE,
      ],
    });

    if (statusCode === 200 && chequeDocumentPayload) {
      setChequeDocuments(chequeDocumentPayload);
    } else {
      showError("Not able to fetch cheque documents!");
    }
  }, [accessToken, selectedPayload.id]);

  const onClickExternalLenderVerifyHandler = useCallback(async () => {
    setIsVerifyLenderLoading(true);
    const verifyLenderResponse = await activeLoansApi.verifyTakeoverAccount({
      crId: selectedPayload.id,
      crJobId: crJobId,
      lenderBankAccountNumber: lenderTakeoverBankDetails?.lenderAccountNumber,
    });
    if (verifyLenderResponse.statusCode === 200) {
      showSuccess("External lender account has been successfully verified!");
      fetchTakeoverBankDetails();
      setIsVerifyLenderLoading(false);
    } else {
      setIsVerifyLenderLoading(false);
      showError(verifyLenderResponse.error?.toString());
    }
  }, [
    accessToken,
    selectedPayload.id,
    showError,
    showSuccess,
    lenderTakeoverBankDetails?.lenderAccountNumber,
    crJobId,
  ]);

  useEffect(() => {
    fetchCustomerBankAccountDetails();
    fetchChequeDocuments();
    fetchTakeoverBankDetails();
  }, []);

  const customerBankDetails = useMemo(
    () => [
      {
        label: "Account Holders Name",
        value:
          bankDetails?.registeredAccountHolderName ||
          bankDetails?.beneficiaryName,
      },
      {
        label: "IFSC Code",
        value: bankDetails?.ifscCode,
      },
      {
        label: "Branch",
        value: bankDetails?.branch,
      },
      {
        label: "Account Number",
        value: bankDetails?.accountNumber,
      },
      {
        label: "Bank Name",
        value: bankDetails?.bankName,
      },
      {
        label: "Bank City",
        value: bankDetails?.city,
      },
      {
        label: "cheque",
        value: (
          <>
            {chequeDocuments?.map((document, i) => (
              <IGDocumentViewer document={document} key={i} />
            ))}
          </>
        ),
        width: 12,
      },
    ],
    [
      bankDetails?.registeredAccountHolderName,
      bankDetails?.beneficiaryName,
      bankDetails?.bankName,
      bankDetails?.accountNumber,
      bankDetails?.ifscCode,
      bankDetails?.branch,
      bankDetails?.city,
      chequeDocuments,
    ],
  );

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDocuments();
    }
  }, [getDocuments]);

  useEffect(() => {
    const timerId = setInterval(() => {
      getDocuments();
    }, otherRequestTimer - 1000);

    return () => {
      clearInterval(timerId);
    };
  });

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const takeOverPaymentReceipt = states.payload.document?.find((res) => {
    return res.documentType === "TAKEOVER_PAYMENT_RTGS_RECEIPT";
  });

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>BRANCH LOCATION SENT BY LM</div>
        <div
          onClick={() => onClickHandler("TAKEOVER_BANK_LOCATION")}
          disabled={buttonLoading}
          className={classes.button}
        >
          View Location
        </div>
      </div>

      <ImageLink image={takeOverPaymentReceipt} name="RTGS Photo" />

      <Box my={2}>
        <Divider />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography
          sx={{
            textDecoration: "underline",
          }}
          my={0.5}
          fontWeight={600}
        >
          External Lender Collection Account
        </Typography>
        {!lenderTakeoverBankDetails?.isLenderAccountNumberVerified && (
          <IGButton
            color="golden"
            loading={isVerifyLenderLoading}
            onClick={() => {
              if (crJobId !== null) {
                onClickExternalLenderVerifyHandler();
              } else {
                showError("CrjobId doesn't exist");
              }
            }}
          >
            Verify Closure account
          </IGButton>
        )}
      </Box>
      <Box display="flex" gap={6}>
        <Box>
          <Typography color="gray">Account Number</Typography>
          <Typography fontWeight={800}>
            {lenderTakeoverBankDetails?.lenderAccountNumber || "N/A"}
          </Typography>
        </Box>
        <Box>
          <Typography color="gray">IFSC Code</Typography>
          <Typography fontWeight={800}>
            {lenderTakeoverBankDetails?.lenderIfscCode || "N/A"}
          </Typography>
        </Box>
      </Box>

      <Box my={2}>
        <Divider />
      </Box>

      <Box p={0.25}>
        <Typography
          sx={{
            textDecoration: "underline",
          }}
          my={0.5}
          fontWeight={600}
        >
          Customer Bank Account Details
        </Typography>
        <CustomerBankDetails customerBankDetails={customerBankDetails} />
      </Box>

      {!isBackMovement && isSameCreditManager && (
        <CheckRTGSComponent
          otherRequestTimer={otherRequestTimer}
          selectPayload={selectedPayload}
          getMileStoneOfCr={getMileStoneOfCr}
          setCrJobId={setCrJobId}
        />
      )}
    </>
  );
};

export default CheckRTGS;
