import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const completeMileStones = async ({
  accessToken,
  crId,
  milestone,
  status = true,
}) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/admin/business/cr/${crId}/milestone-status?milestone=${milestone}&status=${status}`,
      {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "application-json",
        },
      },
    );

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default completeMileStones;
