import axios from "axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const createDocument = async ({
  accessToken,
  crId,
  crJobId,
  fileContentType,
  fileData,
  fileName,
  documentType,
  documentNumber,
  physicalState,
  fileType,
  verificationStatus,
  remark,
  receivingDate,
  isAddressProof,
  isIdProof,
  backFileName,
  backFileContentType,
}) => {
  try {
    const response = await axios(
      `${getApiUrl()}/business/cr/${crId}/document?crJobId=${crJobId}&fileContentType=${fileContentType}&fileName=${fileName}&documentType=${documentType}&isAddressProof=false&isIdProof=false&documentNumber=${documentNumber}&physicalState=${physicalState}&fileType=${fileType}&receivingDate=${receivingDate}&remark=${remark}&verificationStatus=${verificationStatus}&backFileContentType=${backFileContentType}&backFileName=${backFileName}&isAddressProof=${isAddressProof}&isIdProof=${isIdProof}`,
      {
        method: "POST",
        data: fileData,
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
      },
    );

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      error: null,
      statusCode: 200,
      payload: result.result,
      message: "Success",
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default createDocument;
