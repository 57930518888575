import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {addressActionTypes} from "./address.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  GetBlacklistedGoogleCoordinates,
  GetGoogleCoorindatesFromAddress,
  UserIdPayload,
} from "./address.types";
import {GoldApi} from "../../infra/services/api";
import {addressActions} from "./index";
import {LoanIdPayload} from "../loan/loan.types";
import {
  setAddressError,
  setAddressLoading,
  setBlacklistedGoogleCoordinates,
  setGoogleCoordinates,
} from "./address.reducer";
import {snackBarActions} from "../snackbar";

const {
  getAddressListSuccess,
  getAddressListFailure,
  getLenderAddressListSuccess,
  getLenderAddressListFailure,
} = addressActions;

function* getAddressListFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.address.getAddressList, action.payload);
  if (error) {
    yield put(getAddressListFailure({response: null, error}));
  } else {
    yield put(getAddressListSuccess({response, error: null}));
  }
}

function* getLenderAddressListFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.address.getLenderAddressList, action.payload);
  if (error) {
    yield put(getLenderAddressListFailure({response: null, error}));
  } else {
    yield put(getLenderAddressListSuccess({response, error: null}));
  }
}

function* getGoogleCoordinatesFromAddressFlow(
  action: PayloadAction<GetGoogleCoorindatesFromAddress>,
) {
  yield put(setAddressLoading({key: "getGoogleCoorindatesFromAddress", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.address.getGoogleCoorindatesFromAddress,
    action.payload,
  );
  if (error) {
    yield put(setAddressError({key: "getGoogleCoorindatesFromAddress", value: error}));
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      setGoogleCoordinates({
        leadId: action.payload.leadId,
        googleCoordinates: response,
      }),
    );
  }
  yield put(setAddressLoading({key: "getGoogleCoorindatesFromAddress", value: false}));
}

function* getBlacklistedGoogleCoordinatesFlow(
  action: PayloadAction<GetBlacklistedGoogleCoordinates>,
) {
  yield put(setAddressLoading({key: "getBlacklistedGoogleCoordinates", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.address.getBlacklistedGoogleCoordinates,
    action.payload,
  );
  if (error) {
    yield put(setAddressError({key: "getBlacklistedGoogleCoordinates", value: error}));
    yield put(
      snackBarActions.open({
        message: error?.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      setBlacklistedGoogleCoordinates({
        response,
      }),
    );
  }
  yield put(setAddressLoading({key: "getBlacklistedGoogleCoordinates", value: false}));
}

export default function* addressSaga() {
  yield takeLatest(addressActionTypes.GetAddressList, getAddressListFlow);

  yield takeLatest(addressActionTypes.GetLenderAddressList, getLenderAddressListFlow);
  yield takeLatest(
    addressActionTypes.GetGoogleCoordinatesFromAddress,
    getGoogleCoordinatesFromAddressFlow,
  );
  yield takeLatest(
    addressActionTypes.GetBlacklistedGoogleCoordinates,
    getBlacklistedGoogleCoordinatesFlow,
  );
}
