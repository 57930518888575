import {authActions, authSelectors} from "app/store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React, {useEffect, useState} from "react";
import {useIdleTimer} from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";

const timeout = 1000 * 60 * 30;
const promptTimeout = 1000 * 30;

const SessionTimeout = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const onPrompt = () => {
    setTimeoutModalOpen(true);
    setRemainingTime(remainingTime);
  };

  const onIdle = () => {
    setTimeoutModalOpen(false);
    setRemainingTime(0);
    if (!isAuthenticated) return;
    dispatch(authActions.logout());
  };

  const onActive = () => {
    setTimeoutModalOpen(false);
    setRemainingTime(0);
  };


  const {getRemainingTime, isPrompted, start} = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    startManually: true,
    onActive,
    crossTab: true,
  });

  const handleStillHere = () => {
    setTimeoutModalOpen(false);
    start();
  };

  useEffect(() => {
    if (isAuthenticated) {
      start();
    }
  }, [isAuthenticated, start]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainingTime(getRemainingTime());
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isPrompted, getRemainingTime, isAuthenticated]);


  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <SessionTimeoutDialog
        remainingTime={remainingTime}
        onContinue={handleStillHere}
        open={timeoutModalOpen}
        setOpen={(open: boolean) => setTimeoutModalOpen(open)}
      />
    </>
  );
};

export default SessionTimeout;
