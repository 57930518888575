import React, {FC} from "react";
import {IGLoading} from "../index";
import {Box, BoxProps} from "@mui/material";

interface IGBoxBackdropProps extends BoxProps {
  loading?: boolean
}

const IGBoxBackdrop: FC<IGBoxBackdropProps> = ({loading}) => {

  if (!loading) return <></>;

  return (
    <Box
      position="absolute" top={0} bottom={0} left={0} right={0}
      bgcolor="rgba(0, 0, 0, 0.3)"
    >
      <IGLoading height="100%"/>
    </Box>
  );
};

export default IGBoxBackdrop;