import {goldApiService} from "app/infra/plugins/axios";
import {
  BankDetailsApiInterface,
  BankDetailsApiResources,
  GetBankDetailsPayload,
  UpdateBankDetailsPayload,
  VerifyBankDetailsPayload,
} from "app/store/loanRenewalRebook/bankDetails/bankDetails.types";

const BANK_DETAILS_ENDPOINTS: BankDetailsApiResources = {
  GET_BANK_DETAILS: {
    URL: "/admin/renew-rebook/:crId/bank-detail",
    METHOD: "GET",
  },
  UPDATE_BANK_DETAILS: {
    URL: "/admin/renew-rebook/:crId/bank-detail",
    METHOD: "POST",
  },
  VERIFY_BANK_DETAILS: {
    URL: "business/cr/:crId/verify-bank-detail",
    METHOD: "POST",
  },
};

const bankDetailsApi: BankDetailsApiInterface = {
  getBankDetails: async (payload: GetBankDetailsPayload) => {
    return await goldApiService({
      resource: BANK_DETAILS_ENDPOINTS.GET_BANK_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  updateBankDetails: async (
    crId: number,
    payload: UpdateBankDetailsPayload,
  ) => {
    return await goldApiService({
      resource: BANK_DETAILS_ENDPOINTS.UPDATE_BANK_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        data: {
          ...payload,
        },
      },
    });
  },
  verifyBankDetails: async (payload: VerifyBankDetailsPayload) => {
    return await goldApiService({
      resource: BANK_DETAILS_ENDPOINTS.VERIFY_BANK_DETAILS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          bankDetailType: payload.bankDetailType,
        },
      },
    });
  },
};

export {BANK_DETAILS_ENDPOINTS};
export default bankDetailsApi;
