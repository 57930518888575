import React, {FC, useMemo} from "react";
import {openInNewTab} from "_metronic/utils/utils";
import ROUTES from "app/constants/routes";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import IGMenu from "app/components/Menu";

const {DIGITAL_GOLD, EMI_ORDERS, DG_ORDER} = ROUTES;

interface UserActionsDropdownProps {
  row: any,
  index: number,
  modal: any,
  setModal: any,
  setNewStatus: any,
}

const UserActionsDropdown: FC<UserActionsDropdownProps> = ({
  row,
  index,
  modal,
  setModal,
  setNewStatus,
}) => {
  const user = useAppSelector(authSelectors.getUser);

  const userActions = useMemo(() => {
    const isAdmin = user?.userRoleList?.includes("ADMIN");
    const actions = [
      {
        id: "change-status",
        label: "Change Status",
        renderCondition: !user?.userRoleList?.includes("CST"),
        onClick: () => {
          setModal({
            ...modal,
            changeStatus: {
              open: true,
              userId: row.id,
              parentData: row,
              index: index,
            },
          });
          setNewStatus(row.status);
        },
      },
      {
        id: "ban-sell",
        label: "Ban Sell",
        renderCondition: isAdmin,
        onClick: () => {
          setModal({
            ...modal,
            banSell: {
              open: true,
              userId: row.id,
              reason: "",
            },
          });
        },
      },
      {
        id: "ban-device",
        label: "Ban Device",
        renderCondition: isAdmin,
        onClick: () => {
          setModal({
            ...modal,
            banDevice: {
              open: true,
              userId: row.id,
              reason: "",
            },
          });
        },
      },
      {
        id: "user-details",
        label: "User Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            userDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "profile-pic",
        label: "Profile Pic",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            profilePic: {
              open: true,
              image: row.profilePicture,
              userId: row.id,
            },
          });
        },
      },
      {
        id: "upi-details",
        label: "UPI Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            upiDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "card-details",
        label: "Card Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            cardDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "bank-details",
        label: "Bank Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            bankDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "device-details",
        label: "Device Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            deviceDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "address-details",
        label: "Address Details",
        renderCondition: true,
        onClick: () => {
          setModal({
            ...modal,
            addressDetails: {
              open: true,
              userId: row.id,
              parentData: row,
            },
          });
        },
      },
      {
        id: "emi-orders",
        label: "EMI Orders",
        renderCondition: true,
        onClick: () => {
          openInNewTab(
            `/${DIGITAL_GOLD}/${EMI_ORDERS}?userId=${row.id}`,
          );
        },
      },
      {
        id: "dg-orders",
        label: "DG Orders",
        renderCondition: true,
        onClick: () => {
          openInNewTab(
            `/${DIGITAL_GOLD}/${DG_ORDER}?userId=${row.id}`,
          );
        },
      },
    ];
    return actions;
  }, [
    row,
    index,
    modal,
    setModal,
    setNewStatus,
    user?.userRoleList,
  ]);

  return (
    <IGMenu
      actionButtonProps={{
        id: `user-actions-button-${index}`,
        label: "Actions",
      }}
      actionMenuProps={{
        id: `user-actions-menu-${index}`,
        items: userActions,
      }}
    />
  );
};

export default UserActionsDropdown;
