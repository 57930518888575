import styled from "@emotion/styled";

const LenderSubtitle = styled.div`
  font-size: 0.875rem;
  line-height: 1.33;
  display: flex;
  letter-spacing: 0.4px;
  color: rgba(18, 18, 18, 0.5);
  margin-bottom: 0.5rem;
  width: 20%;
`;

export default LenderSubtitle;
