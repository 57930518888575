import React, {useCallback} from "react";
import {CircularProgress} from "@mui/material";

import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import createNucleusApplication from "api/business/createApplicationInNucleus";
// import getLoanAgreementConsentStatusApi from "api/business/getLoanAgreementConsentStatus";
// import sendLoanAgreementConsentApi from "api/business/sendLoanAgreementConsent";

// import {openInNewTab} from "_metronic/utils/utils";
import styles from "../../styles";

import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

// let mileStonesRequestInterval;

const ApplicationNotGenerated = ({
  requesting,
  setRequesting,
  payload,
  selectedPayload,
  setIsApplicationGenerated,
  getDetails,
  isSameCreditManager,
  ltv,
  getMileStoneOfCr,
  isBackMovement,
  isOfferApplicable,
  isOfferApplied,
}) => {
  const classes = styles();
  const {showError, showSuccess} = useSnackBar();
  const authToken = useAppSelector(authSelectors.getAuthToken);
  // const [consentStatus, setConsentStatus] = useState(true);
  // const [loanConsentLink, setLoanConsentLink] = useState("");
  const {goldLoan} = payload;
  // const vendorName = goldLoan.loanVendorName;
  // const mileStonesRequestTimer = 20000;

  const onClickHandler = useCallback(async () => {
    setRequesting(true);
    try {
      const response = await createNucleusApplication({
        accessToken: authToken,
        crId: selectedPayload.id,
      });

      if (response.statusCode === 200) {
        getDetails();
        setIsApplicationGenerated(true);
        showSuccess("Successfully submitted");
        getMileStoneOfCr();
      } else {
        showError(response.error.toString());
      }
    } catch (error) {
      showError("Something went wrong");
    }
    setRequesting(false);
  }, [
    getDetails,
    getMileStoneOfCr,
    selectedPayload.id,
    setIsApplicationGenerated,
    setRequesting,
    authToken,
    showSuccess,
    showError,
  ]);
  // const getLoanAgreementConsentStatus = useCallback(async () => {
  //   if (vendorName === "PIRAMAL") {
  //     setRequesting(true);
  //     try {
  //       const getStatusResponse = await getLoanAgreementConsentStatusApi({
  //         accessToken: authToken,
  //         crId: selectedPayload.id,
  //       });

  //       if (getStatusResponse.statusCode === 200) {
  //         setConsentStatus(getStatusResponse.payload.consentGiven);
  //       } else {
  //         showError("Something went wrong!");
  //       }
  //     } catch (error) {
  //       showError(error);
  //     }
  //     setRequesting(false);
  //   }
  // }, [authToken, selectedPayload.id, vendorName, showError, setRequesting]);

  // const sendLoanAgreementConsent = useCallback(async () => {
  //   setRequesting(true);
  //   if (vendorName !== "PIRAMAL") {
  //     showWarning("Lender Mismatch!");
  //     return;
  //   }
  //   try {
  //     const getStatusResponse = await sendLoanAgreementConsentApi({
  //       accessToken: authToken,
  //       crId: selectedPayload.id,
  //     });

  //     if (getStatusResponse.statusCode === 200) {
  //       setLoanConsentLink(getStatusResponse.payload.link);
  //       showSuccess("SMS sent successfully!");
  //     } else {
  //       showError("Something went wrong!");
  //     }
  //   } catch (error) {
  //     showError(error);
  //   }
  //   setRequesting(false);
  // }, [
  //   authToken,
  //   selectedPayload.id,
  //   vendorName,
  //   showError,
  //   showSuccess,
  //   showWarning,
  //   setRequesting,
  // ]);

  // useEffect(() => {
  //   getLoanAgreementConsentStatus();
  // }, []);

  // useEffect(() => {
  //   mileStonesRequestInterval = setInterval(() => {
  //     getLoanAgreementConsentStatus();
  //   }, mileStonesRequestTimer);
  //   return () => {
  //     clearInterval(mileStonesRequestInterval);
  //   };
  // }, []);

  return (
    <div>
      <div className={classes.textContainer}>
        <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

        <div className={classes.subText}>
          {`${numberWithCurrencyAndComma(goldLoan.totalLoanAmount)} / ${ltv}%`}
        </div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>GOLD LOAN INFORMATION</div>

        <div
          className={classes.subText}
        >{`Gold Loan Amount: ${numberWithCurrencyAndComma(
          goldLoan.totalLoanAmount,
        )}`}</div>

        <div
          className={classes.subText}
        >{`Lender: ${goldLoan.loanVendorName}`}</div>

        <div
          className={classes.subText}
        >{`Processing Fee: ${numberWithCurrencyAndComma(
          goldLoan?.processingFeeIncludingGst,
        )}`}</div>
        <div
          className={classes.subText}
        >{`Loan disburse amount to customer: ${numberWithCurrencyAndComma(
          goldLoan?.loanDisbursedAmount,
        )}`}</div>

        {selectedPayload.milestone !== "COMPLETED" &&
          selectedPayload.milestone !== "CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
            <div
              className={classes.subText}
            >{`CM Scheme Name: ${goldLoan.schemeType}`}</div>
          )}

        {/* <div
          className={classes.subText}
        >{`Scheme Selected: ${goldLoan.goldLoanSchemeName ||
          goldLoan.nucleusSchemeCode}`}</div> */}

        {/* <div
          className={classes.subText}
        >{`Assigned To: ${goldLoan.createdByAgentName}`}</div> */}

        <div
          className={classes.subText}
        >{`Interest Rate: ${goldLoan.annualInterestRate}% ARR`}</div>
        {/* <div
          className={classes.subText}
        >{`Subvented Annual Interest Rate: ${goldLoan.subventedAnnualInterestRate}% ARR`}
        </div> */}
      </div>

      {/* {!consentStatus && vendorName === "PIRAMAL" && (
        <>
          <div className={classes.subText}>
            Loan Agreement Approval SMS:
            <button
              disabled={consentStatus}
              onClick={() => {
                sendLoanAgreementConsent();
              }}
              className={classes.LinkButton}
            >
              SEND LINK
            </button>
          </div>
          {loanConsentLink && (
            <>
              <span
                className={classes.subText}
                onClick={() => {
                  openInNewTab(loanConsentLink);
                }}
              >
                Link:
                <span className={`${classes.link  } mt-0 ml-1`}>
                  {loanConsentLink}
                </span>{" "}
              </span>
            </>
          )}
        </>
      )} */}

      {isSameCreditManager && !isBackMovement && (
        <>
          {requesting && <CircularProgress />}

          <div className={classes.buttonContainer}>
            <button
              // disabled={!consentStatus || requesting}
              onClick={onClickHandler}
              className={classes.ApproveButton}
            >
              SUBMIT
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationNotGenerated;
