import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
  loanType: string;
}

export const postLoanApprovalEmail = async ({crId, loanType}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    POST_EMAIL_APPROVAL: {
      URL: "admin/business/cr/:crId/send-loan-approval-mail-to-lender",
      METHOD: "POST",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.POST_EMAIL_APPROVAL,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        loanType,
      },
    },
  });
};

export default postLoanApprovalEmail;
