import React, {useEffect, useMemo, useState} from "react";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
  TableHead,
} from "@mui/material";
import {IGTextField, IGSelect, IGButton, IGLoading} from "app/components";
import OrnamentsImagesPreview from "../OrnamentsImagesPreview";
import {useFormik} from "formik";
import {useAppDispatch} from "app/store/hooks";
import {useAppSelector} from "app/store/hooks";
import {OrnamentsSelectors, ornamentsActions} from "app/store/ornaments";
import {OrnamentImageModel, OrnamentsModel} from "app/models/ornaments.model";
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded,
} from "@mui/icons-material";
import {
  setSelectedOrnamentId,
  setOrnamentFormValues,
} from "app/store/ornaments/ornaments.reducer";
import {OrnamentCMFormValues} from "app/store/ornaments/ornaments.types";

interface OrnamentsTableProps {
  crId: number;
  isVisible: boolean;
  crJobId: number;
  selectedPayload: any;
  OrnamentsList: OrnamentsModel[];
  ornamentsFinenessConfigList: {
    fineness: string;
    liveGoldRate: string;
  }[];
}

interface OrnamentsBulkFormData {
  [key: string]: OrnamentCMFormValues;
}

const OrnamentsTable = ({
  crId,
  OrnamentsList,
  ornamentsFinenessConfigList,
  isVisible,
  crJobId,
  selectedPayload,
}: OrnamentsTableProps) => {
  const dispatch = useAppDispatch();

  interface OrnamentsPacketImages {
    title?: string;
    url: string;
  }
  [];
  const [ornamentsPackets, setOrnamentsPacket] = useState<
    OrnamentsPacketImages[]
  >([]);
  const ornamentId = useAppSelector(OrnamentsSelectors.getSelectedOrnamentId);

  const {
    getOrnamentsList: isOrnamentsListLoading,
    updateOrnamentDetails: isUpdatedOrnamentDetailsLoading,
  } = useAppSelector(OrnamentsSelectors.getLoading);
  useEffect(() => {
    dispatch(
      ornamentsActions.getOrnamentsPacket({
        crId,
      }),
    );
  }, [crId, dispatch]);

  const ornamentsPacketList = useAppSelector(
    OrnamentsSelectors.getOrnamentsPacket,
  );

  const intitalValuesMemo = useMemo(() => {
    if (OrnamentsList && ornamentsFinenessConfigList) {
      const ornamentObj: OrnamentsBulkFormData = {};
      for (const ornament of OrnamentsList) {
        ornamentObj[ornament.id] = {
          ...ornamentObj[ornament.id],
          crOrnamentId: ornament.id,
          newFinenessVal: ornament?.newFinenessVal,
          newImpurityWeightInGrams: ornament?.newImpurityWeightInGrams,
          newOtherImpurityWeight: ornament?.newOtherImpurityWeight,
          newStoneWeightInGrams: ornament?.newStoneWeightInGrams,
        };
      }

      return ornamentObj;
    }
    return {};
  }, [OrnamentsList, ornamentsFinenessConfigList]);

  useEffect(() => {
    if (ornamentsPacketList) {
      const tempArr = ornamentsPacketList.map((item) => ({
        title: `Ornament Packet No :- ${item.packetName}`,
        url: item.packetPhoto,
      }));
      setOrnamentsPacket([...tempArr]);
    }
  }, [ornamentsPacketList]);

  const {handleChange, values, handleSubmit, setFieldValue} = useFormik<
    OrnamentsBulkFormData
  >({
    initialValues: {
      ...intitalValuesMemo,
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // await onsubmit(values);
      const bulkUpdatePayload = Object.values(values);
      dispatch(
        ornamentsActions.updateOrnamentDetails({
          crId: crId,
          payload: {
            crOrnamentWeightKarateChangeRequestDtoList: bulkUpdatePayload,
          },
        }),
      );
      actions.setSubmitting(false);
    },
  });
  useEffect(() => {
    if (ornamentId) {
      dispatch(
        setOrnamentFormValues({
          ornamentId: ornamentId,
          values: values[ornamentId],
        }),
      );
    }
  }, [dispatch, ornamentId, values]);

  const columns = [
    {
      title: "ID",
      field: "id",
    },
    {
      title: "Ornament type",
      field: "ornamentType",
    },
    {
      title: "Quantity",
      field: "quantity",
    },
    {
      isHeader: true,
      title: "WEIGHT (GMS)",
      field: "id",
    },
    {
      title: "Gross Weight",
      field: "grossWeightInGrams",
    },
    {
      title: "Net gold weight (Maker)",
      field: "netWeightInGrams",
    },
    {
      title: "Net gold weight (Checker) ",
      field: "newNetWeightInGrams",
    },
    {
      isHeader: true,
      title: "PURITY",
      field: "id",
    },
    {
      title: "Purity (Maker)",
      field: "finenessVal",
    },
    {
      title: "Final Purity (Checker)",
      field: "newFinenessVal",
      renderCell: ({id}: OrnamentsModel) => {
        return (
          <IGSelect
            key={id}
            value={values[id]?.newFinenessVal ?? ""}
            fullWidth={false}
            variant="outlined"
            name={`${id}.newFinenessVal`}
            onChange={(event) => {
              dispatch(setSelectedOrnamentId({ornamentId: id}));
              setFieldValue(`${id}.newFinenessVal`, event.target.value);
            }}
            options={ornamentsFinenessConfigList.map((item) => ({
              text: item.fineness,
              value: item.fineness,
            }))}
          />
        );
      },
    },
    {
      title: "Hallmark",
      field: "isHallmark",
      renderCell: ({isHallmark}: OrnamentsModel) =>
        isHallmark ? (
          <CheckCircleOutlineRounded color="success" />
        ) : (
          <HighlightOffRounded color="error" />
        ),
    },
    {
      isHeader: true,
      title: "IMPURITIES (GMS)",
      field: "id",
    },
    {
      title: "Impurity (Maker)",
      field: "impurityWeightInGrams",
    },
    {
      title: "Impurity (Checker)",
      field: "newImpurityWeightInGrams",
      renderCell: ({id}: OrnamentsModel) => (
        <IGTextField
          key={id}
          name={`${id}.newImpurityWeightInGrams`}
          variant="standard"
          type="number"
          sx={{
            width: "40px",
          }}
          fullWidth={false}
          onChange={(event) => {
            dispatch(setSelectedOrnamentId({ornamentId: id}));
            setFieldValue(`${id}.newImpurityWeightInGrams`, event.target.value);
          }}
          value={values[id]?.newImpurityWeightInGrams ?? ""}
        />
      ),
    },

    {
      title: "Other Impurity (Maker)",
      field: "otherImpurityWeight",
    },
    {
      title: "Other Impurity (Checker)",
      field: "newOtherImpurityWeight",
      renderCell: ({id}: OrnamentsModel) => (
        <IGTextField
          key={id}
          name={`${id}.newOtherImpurityWeight`}
          variant="standard"
          type="number"
          fullWidth={false}
          sx={{
            width: "40px",
          }}
          disabled={
            selectedPayload?.loanType === "RENEW" ||
            selectedPayload?.loanType === "REBOOK"
          }
          onChange={(event) => {
            dispatch(setSelectedOrnamentId({ornamentId: id}));
            setFieldValue(`${id}.newOtherImpurityWeight`, event.target.value);
          }}
          value={values[id]?.newOtherImpurityWeight ?? ""}
        />
      ),
    },

    {
      title: "Stone Impurity (Maker)",
      field: "stoneWeightInGrams",
    },
    {
      title: "Stone Impurity (Checker)",
      field: "netWeightInGrams",
      renderCell: ({id}: OrnamentsModel) => (
        <IGTextField
          key={id}
          name={`${id}.newStoneWeightInGrams`}
          variant="standard"
          type="number"
          fullWidth={false}
          sx={{
            width: "40px",
          }}
          onChange={(event) => {
            dispatch(setSelectedOrnamentId({ornamentId: id}));
            setFieldValue(`${id}.newStoneWeightInGrams`, event.target.value);
          }}
          value={values[id]?.newStoneWeightInGrams ?? ""}
        />
      ),
    },
  ];

  return (
    <>
      {isOrnamentsListLoading ? (
        <>
          <IGLoading height="15vh" />
        </>
      ) : (
        <Box
          display="flex"
          gap="1rem"
          bgcolor="#ffffff"
          border="#ffffff"
          position="relative"
        >
          <form onSubmit={handleSubmit}>
            <TableContainer
              component={Paper}
              elevation={2}
              sx={{
                maxHeight: "86vh",
                width: "calc(100vw - 48px)",
                overflowX: "scroll",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: "0",
                        background: "white",
                        zIndex: "999999 !important",
                      }}
                    >
                      <IGButton
                        loading={isUpdatedOrnamentDetailsLoading}
                        disabled={!crJobId || !isVisible}
                        type="submit"
                      >
                        Save All
                      </IGButton>
                    </TableCell>
                    {OrnamentsList.map(() => (
                      <TableCell />
                    ))}
                  </TableRow>
                </TableHead>
                {columns.map((item) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "& th, td": {
                            padding: "8px",
                          },
                          "& .ornament-table-cell": {
                            paddingLeft: "16px",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            height: item.isHeader ? "50px" : "auto",
                            borderRight: "1px solid #f0f0f0",
                            width: "220px",
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: "9999 !important",
                          }}
                        >
                          {item.isHeader ? (
                            <Typography
                              fontWeight={400}
                              color="rgba(0, 0, 0, 0.38)"
                            >
                              {item.title}
                            </Typography>
                          ) : (
                            <Typography
                              fontWeight={500}
                              color="black"
                              variant="body1"
                            >
                              {item.title}
                            </Typography>
                          )}
                        </TableCell>
                        {OrnamentsList.map((ornament: OrnamentsModel) => {
                          return (
                            <TableCell
                              className="ornament-table-cell"
                              sx={{
                                paddingLeft: "16px",
                                height: item.isHeader ? "50px" : "auto",
                              }}
                            >
                              {item.isHeader
                                ? null
                                : item.renderCell
                                ? item.renderCell(ornament)
                                : ornament[item.field as keyof typeof ornament]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })}
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: "1px solid #f0f0f0",
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: "9999 !important",
                      verticalAlign: "baseline",
                    }}
                  >
                    <Box m={0.5}>
                      <Typography fontWeight={400} color="rgba(0, 0, 0, 0.38)">
                        ORNAMENTS PACKET
                      </Typography>
                      <OrnamentsImagesPreview imagesList={ornamentsPackets} />,
                    </Box>
                  </TableCell>
                  {OrnamentsList.map((ornament: OrnamentsModel) => {
                    // eslint-disable-next-line max-len
                    const imagesList = ornament.crOrnamentImageResponseDtoList.map(
                      (element: OrnamentImageModel) => ({
                        title: element.ornamentImageType,
                        url: element.imageUrl,
                      }),
                    );
                    return (
                      <TableCell sx={{
                      verticalAlign: "baseline",
                      }}>
                        <OrnamentsImagesPreview imagesList={imagesList} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </Table>
            </TableContainer>
          </form>
        </Box>
      )}
    </>
  );
};

export default OrnamentsTable;
