import React, {useEffect} from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
} from "@mui/material";
import styles from "./styles";
import "./style.css";
import {IGLoading} from "app/components";

const Ornaments = ({
  ornaments=[],
  getOrnamentsCallback,
  states,
}) => {
  const classes = styles();

  const imageOnClickHandler = url => {
    const image = new Image();
    image.src = url;

    const w = window.open("");
    w.document.write(image.outerHTML);
  };

  useEffect(() => {
    getOrnamentsCallback();
  }, []);

  if (states.loading) {
    return <IGLoading height="10vh" />;
  }

  if (states.error) {
    return <div>{states.errorMessage}</div>;
  }

  if (!ornaments?.length) {
    return <div>No ornaments found</div>;
  }

  return (
    <TableContainer className="tableContainer">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center">
              BASIC
            </TableCell>
            <TableCell colSpan={3} align="center">
              WEIGHT (GMS)
            </TableCell>
            <TableCell colSpan={2} align="center">
              PURITY
            </TableCell>
            <TableCell colSpan={2} align="center">
              IMPURITY (GMS)
            </TableCell>
            <TableCell colSpan={2} align="center">
              OTHER IMPURITY
            </TableCell>
            <TableCell colSpan={2} align="center">
              STONE WEIGHT
            </TableCell>

            <TableCell align="center">IMAGES</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">ORNAMENT TYPE</TableCell>
            <TableCell align="center">QTY</TableCell>
            <TableCell align="center">GROSS ORNAMENT WEIGHT </TableCell>
            <TableCell align="center">NET GOLD WEIGHT </TableCell>

            <TableCell align="center">NET GOLD WEIGHT (NEW)</TableCell>

            <TableCell align="center">IMPURITY (OLD)</TableCell>

            <TableCell align="center">IMPURITY (NEW)</TableCell>

            <TableCell align="center">OTHER IMPURITY (OLD)</TableCell>

            <TableCell align="center">OTHER IMPURITY (NEW)</TableCell>

            <TableCell align="center">STONE IMPURITY (OLD)</TableCell>
            <TableCell align="center">STONE IMPURITY (NEW)</TableCell>
            <TableCell align="center">PROOFS</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(ornaments || [])?.map(row => {
            return (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">{row.ornamentType}</TableCell>
                <TableCell align="center">{row.quantity}</TableCell>
                <TableCell align="center">{row.grossWeightInGrams}</TableCell>
                <TableCell align="center">{row.netWeightInGrams}</TableCell>
                <TableCell align="center">
                  {parseFloat(
                    row.grossWeightInGrams -
                      row.newImpurityWeightInGrams -
                      row.newOtherImpurityWeight -
                      row.newStoneWeightInGrams,
                  ).toFixed(2)}
                </TableCell>

                <TableCell align="center">{`${row.impurityWeightInGrams} gms`}</TableCell>
                <TableCell align="center">
                  <Typography>{row.newImpurityWeightInGrams}</Typography>
                </TableCell>
                <TableCell align="center">{`${row.otherImpurityWeight} gms`}</TableCell>
                <TableCell align="center">
                  <Typography>{row.newOtherImpurityWeight}</Typography>
                </TableCell>
                <TableCell align="center">{`${row.stoneWeightInGrams} gms`}</TableCell>
                <TableCell align="center">
                  <Typography>{row.newStoneWeightInGrams}</Typography>
                </TableCell>

                <TableCell align="center">
                  {row.crOrnamentImageResponseDtoList.map(list => (
                    <div>
                      <div
                        className={classes.link}
                        onClick={() => {
                          imageOnClickHandler(list.imageUrl);
                        }}
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        {list.ornamentImageType || list.remark}
                      </div>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">
              {parseFloat(
                ornaments.reduce((arr, current) => {
                  return arr + current.quantity;
                }, 0),
              ).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {parseFloat(
                ornaments.reduce((arr, current) => {
                  return arr + current.grossWeightInGrams;
                }, 0),
              ).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {parseFloat(
                ornaments.reduce((arr, current) => {
                  return arr + current.netWeightInGrams;
                }, 0),
              ).toFixed(2)}
            </TableCell>

            <TableCell align="center"></TableCell>

            <TableCell align="center">
              {parseFloat(
                ornaments.reduce((arr, current) => {
                  return arr + current.impurityWeightInGrams;
                }, 0),
              ).toFixed(2)}
            </TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center">
              {parseFloat(
                ornaments?.reduce((arr, row) => {
                  return arr + row.otherImpurityWeight;
                }, 0),
              ).toFixed(2)}
            </TableCell>
            <TableCell align="center"></TableCell>

            <TableCell align="center">
              {parseFloat(
                ornaments?.reduce((arr, row) => {
                  return arr + row.stoneWeightInGrams;
                }, 0),
              ).toFixed(2)}
            </TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Ornaments;
