import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  loanType: string
}

export const GetLenderConfig = async ({loanType}: Props) => {
  const API_ENDPOINTS : ApiResources = {
    GET_LENDER_CONFIG : {
      URL: "/business/config/cr/lender",
      METHOD: "GET",
  }};
  return await goldApiService({
    resource: API_ENDPOINTS.GET_LENDER_CONFIG,
    options: {
      queryParams: {loanType},
    },
  });
};
