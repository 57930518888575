
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";

import {GoldApi} from "../../infra/services/api";

import {snackBarActions} from "../snackbar";
import goldReleasActions, {goldReleaseActionTypes} from "./goldRelease.actions";
import {GenericResponse} from "app/typings/api/goldApi.types";
import {ClosureListPayload, CrIdPayload} from "./goldRelease.types";

const {
  getClosureListFailure,
  getClosureListSuccess,
  getHandoverImagesFailure,
  getHandoverImagesSuccess,
} = goldReleasActions;

function* generalErrorFlow(action: PayloadAction<GenericResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getClosureListFlow(
  action: PayloadAction<ClosureListPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.goldRelease.getClosureList,
    action.payload,
  );
  if (error) {
    yield put(getClosureListFailure({error: error.message}));
  } else {
    yield put(getClosureListSuccess({response}));
  }
}
function* getHandoverImagesFlow(
  action: PayloadAction<CrIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.goldRelease.getHandoverImages,
    action.payload,
  );
  if (error) {
    yield put(getHandoverImagesFailure({error: error.message}));
  } else {
    yield put(getHandoverImagesSuccess({response, crId: action.payload.crId}));
  }
}

export default function* goldReleaseSaga() {
  yield takeLatest(
    goldReleaseActionTypes.GetClosureList,
    getClosureListFlow,
  );

  yield takeLatest(
    goldReleaseActionTypes.GetHandoverImages,
    getHandoverImagesFlow,
  );

  yield takeLatest(
    goldReleaseActionTypes.GetClosureListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    goldReleaseActionTypes.GetHandoverImagesFailure,
    generalErrorFlow,
  );
}
