import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {loanSummaryActionTypes} from "./loanSummary.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {GetLoanSummaryPayload} from "./loanSummary.types";
import {GoldApi} from "../../infra/services/api";
import {loanSummaryActions} from "./index";

const {
  getLoanSummaryDetailsSuccess,
  getLoanSummaryDetailsFailure,
  getLenderLoanSummaryDetailsFailure,
  getLenderLoanSummaryDetailsSuccess,
} = loanSummaryActions;

function* getLoanSummaryDetailFlow(
  action: PayloadAction<GetLoanSummaryPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanSummary.getLoanSummaryDetails,
    action.payload,
  );
  if (error) {
    yield put(getLoanSummaryDetailsFailure({response: null, error}));
  } else {
    yield put(getLoanSummaryDetailsSuccess({response, error: null}));
  }
}

function* getLenderLoanSummaryDetailFlow(
  action: PayloadAction<GetLoanSummaryPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.loanSummary.getLenderLoanSummaryDetails,
    action.payload,
  );
  if (error) {
    yield put(getLenderLoanSummaryDetailsFailure({response: null, error}));
  } else {
    yield put(getLenderLoanSummaryDetailsSuccess({response, error: null}));
  }
}
export default function* loanSummarySaga() {
  yield takeLatest(
    loanSummaryActionTypes.GetLoanSummaryDetails,
    getLoanSummaryDetailFlow,
  );

  yield takeLatest(
    loanSummaryActionTypes.GetLenderLoanSummaryDetails,
    getLenderLoanSummaryDetailFlow,
  );
}