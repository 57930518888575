import {goldApiService} from "app/infra/plugins/axios";
import {GoldReleaseApiInterface, GoldReleaseApiResources} from "app/store/goldRelease/goldRelease.types";

const GoldReleaseEndpoints: GoldReleaseApiResources = {
  GET_CLOSURE_LIST: {
    URL: "admin/business/lender/closure-list/report",
    METHOD: "GET",
  },
  GET_HANDOVER_IMAGES: {
    URL: "admin/business/lender/:crId/gold-handover-image",
    METHOD: "GET",
  },
};

const goldReleaseApi: GoldReleaseApiInterface = {
  getClosureList: async (payload) => {
    return await goldApiService({
      resource: GoldReleaseEndpoints.GET_CLOSURE_LIST,
      options: {
        queryParams: {...payload},
      },
    });
  },
  getHandoverImages: async (payload) => {
    return await goldApiService({
      resource: GoldReleaseEndpoints.GET_HANDOVER_IMAGES,
      options: {
        pathVars: {...payload},
      },
    });
  },
};

export default goldReleaseApi;
