import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/business/cr/:crId/attribute",
  METHOD: "GET",
};
export const fetchLoanRequirementReason = async ({crId}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
    },
  });
  return response;
};

export default fetchLoanRequirementReason;
