import styled from "@emotion/styled";

const StyledNotifProfileContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
`;

export default StyledNotifProfileContainer;