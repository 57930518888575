import React, {FC} from "react";
import {IconButton, TextField, TextFieldProps} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";

type IGTextFieldProps = {
  name: string
  value: unknown
  touched?: boolean
  clearable?: boolean
  onClear?: () => void
} & TextFieldProps;

const IGTextField: FC<IGTextFieldProps> = ({
  name,
  value,
  onChange,
  onBlur,
  id,
  label,
  InputProps,
  onClear,
  variant = "outlined",
  type = "text",
  size = "small",
  fullWidth = true,
  error = false,
  helperText = "",
  touched = false,
  clearable = false,
  sx={},
  ...rest
}) => {

  const onCloseButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClear) {
      onClear();
      return;
    }
    if (onChange && e.target) {
      onChange(
        {
          target: {
            ...e.target,
            value: "",
            name: name,
          },
        } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      );
    }
  };

  return (
    <TextField
      fullWidth={fullWidth}
      size={size}
      id={id || name}
      name={name}
      label={label}
      type={type}
      value={value}
      variant={variant}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
      helperText={error ? (touched && helperText) : helperText}
      InputProps={{
        ...InputProps,
        endAdornment: InputProps?.endAdornment
          ? InputProps.endAdornment
          : (clearable && value) ? (
            <IconButton
              size={size}
              onClick={onCloseButtonClick}
              sx={{marginRight: 1.5}}
            >
              <CloseRounded/>
            </IconButton>
          ) : <></>,
      }}
      sx={{
        ...sx,
        "& legend": {display: label ? "block" : "none"},
        "& fieldset": {top: label ? -5 : 0},
      }}
      {...rest}
    />
  );
};

export default IGTextField;
