import * as yup from "yup";
import React, {useState, useEffect, useMemo} from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {useFormik} from "formik";
import {
  IGButton,
  IGDocumentViewer,
  IGFileUpload,
  IGLoading,
  IGSelect,
  IGTextField,
} from "app/components";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import AgriMatchingDocsPopUp from "./AgriMatchingDocsPopUp";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {ViewPdfButton} from "../../../Leegality/styles";
import ArrowUpFromBracket from "app/AppLayout/Icons/ArrowUpFromBracket";
import {fileType} from "app/enums/generalPurpose";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {LandDocumentDetailPayload} from "app/infra/services/api/activeLoans/types";
import {
  useIGValidations,
  useNumberValidations,
  useStringValidations,
} from "app/validations";
import {isEmpty} from "lodash";

interface LandOwnerFormValues {
  landOwnerName: string | null;
  // landOwnerAadhar: string | null;
  // landOwnerPan?: string | null;
  tehsil: string | null;
  village: string | null;
  surveryNumber: string | null;
  khataNo: string | null;
  landOwnerRelationship: string | null;
  city: string | null;
  fwcStateId: number | null;
}

interface AgriDocumentDetailsProps {
  crId: number;
  crJobId: number;
  isRenewRebookFlow: boolean;
}

const AgriDocumentDetails = ({crId, crJobId}: AgriDocumentDetailsProps) => {
  const {showError} = useSnackBar();
  const dispatch = useAppDispatch();
  const [landDocument, setLandDocument] = useState<any>([]);
  const [approvalScreenshot, setApprovalScreenshot] = useState<any>([]);
  const {
    deleteLandDocumentDetail: isDeleteDocLoading,
    postLandDocumentDetail: isPostLandDocDetailsLoading,
    getLandDocumentDetail: isLandDocDetailLoading,
    getMatchingLandDocumentDetail: isMatchingDocLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);
  const landDocumentDetail = useAppSelector(
    activeLoansSelectors.getLandDocumentDetail,
  );
  const matchingLandDocumentList = useAppSelector(
    activeLoansSelectors.getMatchingLandDocumentDetail,
  );

  const borrowerList = useAppSelector(
    activeLoansSelectors.getLandOwnerBorrowerList,
  );

  const stateList = useAppSelector(activeLoansSelectors.getLandStateList);
  const {isMatchingDocsPopupOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );

  const totalAllowedAgriAmount = useAppSelector(
    activeLoansSelectors.getAgriTotalLoanAmount,
  );
  const {isCrLandDocumentDetailAvailable, crLandDocumentDetailDto} =
    landDocumentDetail ?? {};
  const {
    ownerAadhaar,
    ownerName,
    ownerPan,
    tehsil,
    village,
    pattaNumber,
    surveyNumber,
    borrowerOwnerRelationship,
    city,
    fwcStateId,
    originalLandDocumentId,
  } = crLandDocumentDetailDto ?? {};

  const handleLandDocumentDetailUpdate = async (
    values: LandOwnerFormValues,
  ) => {
    const {
      landOwnerName,
      landOwnerRelationship,
      village,
      tehsil,
      khataNo,
      surveryNumber,
      city,
      fwcStateId,
    } = values ?? {};
    const payload = new FormData();
    payload.append("landProof", landDocument[0]);
    payload.append("landProofApproval", approvalScreenshot[0]);

    if (landDocument.length === 0 || approvalScreenshot.length === 0) {
      showError("Please upload the docs!");
      return;
    }
    if (!crJobId) {
      showError("Please assign this job first!");
      return;
    }

    const data: LandDocumentDetailPayload = {
      crId: crId,
      crJobId: crJobId,
      ownerName: landOwnerName ?? "",
      village: village ?? "",
      tehsil: tehsil ?? "",
      surveyNumber: surveryNumber ?? "",
      city: city ?? "",
      fwcStateId: fwcStateId ?? 0,
      formData: payload,
      borrowerOwnerRelationship: landOwnerRelationship ?? "",
    };

    if (khataNo !== null && khataNo !== undefined && khataNo !== "") {
      data.pattaNumber = khataNo;
    }

    dispatch(activeLoansActions.postLandDocumentDetail(data));
  };

  const validationSchema = useIGValidations({
    landOwnerName: useStringValidations({required: true, max: 100}),
    // landOwnerAadhar: yup
    //   .string()
    //   .max(12, "Aadhar number must be exactly 12 digits")
    //   .min(12, "Aadhar number must be exactly 12 digits")
    //   .test("isNumber", "Aadhar number must be a number", (value) => {
    //     return value ? /^\d+$/.test(value ?? "") : true;
    //   })
    //   .test(
    //     "aadharOrPan",
    //     "Either Aadhar or PAN number is required",
    //     (value, context) => {
    //       return !!value || !!context.parent.landOwnerPan;
    //     },
    //   ),
    // landOwnerPan: yup
    //   .string()
    //   .max(10, "PAN number must be exactly 10 characters")
    //   .min(10, "PAN number must be exactly 10 characters")
    //   .test(
    //     "aadharOrPan",
    //     "Either Aadhar or PAN number is required",
    //     (value, context) => {
    //       return !!value || !!context.parent.landOwnerAadhar;
    //     },
    //   ),
    tehsil: useStringValidations({required: true, max: 60}),
    village: useStringValidations({required: true, max: 60}),
    surveryNumber: useStringValidations({required: true, max: 75}),
    city: useStringValidations({required: true, max: 60}),
    fwcStateId: useNumberValidations({required: true}),
    landOwnerRelationship: useStringValidations({required: true}),
    khataNo: useStringValidations({max: 45}),
  }).test("aadharOrPan", "Either Aadhar or PAN number is required", (obj) => {
    return !!obj.landOwnerAadhar || !!obj.landOwnerPan;
  });

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    handleBlur,
    dirty,
  } = useFormik<LandOwnerFormValues>({
    enableReinitialize: true,
    initialValues: {
      landOwnerName: ownerName ?? "",
      tehsil: tehsil ?? "",
      village: village ?? "",
      surveryNumber: surveyNumber ?? "",
      khataNo: pattaNumber ?? "",
      landOwnerRelationship: borrowerOwnerRelationship ?? "",
      city: city ?? "",
      fwcStateId: fwcStateId || null,
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(false);
      await handleLandDocumentDetailUpdate(values);
    },
  });

  /* eslint-disable no-console */
  console.log(errors, "errors");

  const viewPdf = (link: string) => {
    window.open(link, "_blank");
  };

  const renderMatchingDocDisclaimerBlock = useMemo(() => {
    if (isMatchingDocLoading) {
      return <Typography>Loading...</Typography>;
    }
    if (matchingLandDocumentList.length > 0) {
      return (
        <Box display="flex" justifyContent="space-between">
          <Typography color="red">
            Similar Documents found! Review all documents
          </Typography>
          <IGButton
            onClick={() =>
              dispatch(
                toggleModals({
                  type: "isMatchingDocsPopupOpen",
                  value: true,
                }),
              )
            }
          >
            View matched docs
          </IGButton>
        </Box>
      );
    }
    return (
      <Typography color="green">
        No Matching document found. Please complete the checker step.
      </Typography>
    );
  }, [matchingLandDocumentList, dispatch, isMatchingDocLoading]);

  const renderExposureDisclaimer = useMemo(() => {
    if (originalLandDocumentId) {
      return (
        <Box>
          <Typography>
            Max possible agri loan Amount :
            {`${numberWithCurrencyAndComma(totalAllowedAgriAmount)}`}
          </Typography>
        </Box>
      );
    }
  }, [originalLandDocumentId, totalAllowedAgriAmount]);

  useEffect(() => {
    dispatch(
      activeLoansActions.getLandDocumentDetail({
        crId: crId,
      }),
    );

    dispatch(activeLoansActions.getLandStateList());
    dispatch(activeLoansActions.getLandOwnerBorrowerList());
  }, []);

  useEffect(() => {
    if (isCrLandDocumentDetailAvailable && !originalLandDocumentId) {
      dispatch(
        activeLoansActions.getMatchingLandDocumentDetail({
          crId: crId,
        }),
      );
    }
  }, [isCrLandDocumentDetailAvailable]);

  useEffect(() => {
    if (originalLandDocumentId) {
      dispatch(
        activeLoansActions.getAgriExposureAmount({
          crId: crId,
        }),
      );
    }
  }, [originalLandDocumentId]);

  return (
    <>
      {isLandDocDetailLoading ? (
        <IGLoading height="15vh" />
      ) : (
        <Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <IGTextField
                  name="landOwnerName"
                  label="Enter Land Owner Name"
                  required
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.landOwnerName}
                  onChange={handleChange}
                  error={touched.landOwnerName && Boolean(errors.landOwnerName)}
                  onBlur={handleBlur}
                  touched={touched.landOwnerName}
                  helperText={touched.landOwnerName && errors.landOwnerName}
                />
              </Grid>

              {/* <Grid item xs={4}>
                <IGTextField
                  name="landOwnerAadhar"
                  label="Enter Land Owner Aadhar Number"
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.landOwnerAadhar}
                  onChange={handleChange}
                  error={
                    touched.landOwnerAadhar && Boolean(errors.landOwnerAadhar)
                  }
                  onBlur={handleBlur}
                  touched={touched.landOwnerAadhar}
                  helperText={touched.landOwnerAadhar && errors.landOwnerAadhar}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <IGTextField
                  name="landOwnerPan"
                  label="Enter Land Owner Pan"
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.landOwnerPan}
                  onChange={handleChange}
                  error={touched.landOwnerPan && Boolean(errors.landOwnerPan)}
                  onBlur={handleBlur}
                  touched={touched.landOwnerPan}
                  helperText={touched.landOwnerPan && errors.landOwnerPan}
                />
              </Grid> */}
              <Grid item xs={4}>
                <IGTextField
                  name="city"
                  label="Enter Land city"
                  required
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.city}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                  onBlur={handleBlur}
                  touched={touched.city}
                  helperText={touched.city && errors.city}
                />
              </Grid>
              <Grid item xs={4}>
                <IGSelect<number>
                  name="fwcStateId"
                  label="Land state"
                  type="number"
                  placeholder="Please select land state"
                  options={stateList.map((i: any) => ({
                    text: i?.name,
                    value: i?.id,
                  }))}
                  required
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.fwcStateId ?? 0}
                  onChange={handleChange}
                  error={touched.fwcStateId && Boolean(errors.fwcStateId)}
                  onBlur={handleBlur}
                  touched={touched.fwcStateId}
                  helperText={touched.fwcStateId && errors.fwcStateId}
                />
              </Grid>
              <Grid item xs={4}>
                <IGTextField
                  name="tehsil"
                  label="Enter Land Tehsil"
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.tehsil}
                  onChange={handleChange}
                  required
                  error={touched.tehsil && Boolean(errors.tehsil)}
                  onBlur={handleBlur}
                  touched={touched.tehsil}
                  helperText={touched.tehsil && errors.tehsil}
                />
              </Grid>
              <Grid item xs={4}>
                <IGTextField
                  name="village"
                  label="Enter Land Village"
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.village}
                  onChange={handleChange}
                  required
                  error={touched.village && Boolean(errors.village)}
                  onBlur={handleBlur}
                  touched={touched.village}
                  helperText={touched.village && errors.village}
                />
              </Grid>
              <Grid item xs={4}>
                <IGTextField
                  name="surveryNumber"
                  label="Enter Survey Number"
                  required
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.surveryNumber}
                  onChange={handleChange}
                  error={touched.surveryNumber && Boolean(errors.surveryNumber)}
                  onBlur={handleBlur}
                  touched={touched.surveryNumber}
                  helperText={touched.surveryNumber && errors.surveryNumber}
                />
              </Grid>
              <Grid item xs={4}>
                <IGTextField
                  name="khataNo"
                  label="Enter Land Khata Number"
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.khataNo}
                  onChange={handleChange}
                  error={touched.khataNo && Boolean(errors.khataNo)}
                  onBlur={handleBlur}
                  touched={touched.khataNo}
                  helperText={touched.khataNo && errors.khataNo}
                />
              </Grid>
              <Grid item xs={4}>
                <IGSelect<string>
                  name="landOwnerRelationship"
                  label="Land Owner Relationship"
                  options={borrowerList.map((i: any) => ({
                    text: i?.displayValue,
                    value: i?.key,
                  }))}
                  required
                  disabled={isCrLandDocumentDetailAvailable}
                  value={values.landOwnerRelationship || ""}
                  onChange={handleChange}
                  error={
                    touched.landOwnerRelationship &&
                    Boolean(errors.landOwnerRelationship)
                  }
                  onBlur={handleBlur}
                  touched={touched.landOwnerRelationship}
                  helperText={
                    touched.landOwnerRelationship &&
                    errors.landOwnerRelationship
                  }
                />
              </Grid>
            </Grid>

            {!isCrLandDocumentDetailAvailable ? (
              <Stack spacing={2} mt={2} width="30%">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography variant="h6">
                    Land Document <sup>*</sup>
                  </Typography>
                  <IGFileUpload
                    text=""
                    value={landDocument}
                    onChange={(fileList) => setLandDocument(fileList)}
                    draggable={false}
                    inputProps={{
                      accept:
                        "image/png, image/jpeg, image/jpg, application/pdf",
                    }}
                    buttonText="Upload"
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography variant="h6">
                    Approval Screenshots <sup>*</sup>
                  </Typography>
                  <IGFileUpload
                    text=""
                    value={approvalScreenshot}
                    onChange={(fileList) => setApprovalScreenshot(fileList)}
                    draggable={false}
                    inputProps={{
                      accept:
                        "image/png, image/jpeg, image/jpg, application/pdf",
                    }}
                    buttonText="Upload"
                  />
                </Stack>
              </Stack>
            ) : (
              <Grid container mt={2}>
                {crLandDocumentDetailDto?.landDocumentProof && (
                  <Grid item xs={6}>
                    <Typography>
                      {crLandDocumentDetailDto?.landDocumentProof?.documentType.replaceAll(
                        "_",
                        " ",
                      )}
                    </Typography>
                    {crLandDocumentDetailDto?.landDocumentProof?.fileType ===
                    fileType.PDF ? (
                      <ViewPdfButton
                        onClick={() =>
                          viewPdf(
                            crLandDocumentDetailDto?.landDocumentProof?.fileUrl,
                          )
                        }
                      >
                        <b>View</b> &nbsp;
                        <ArrowUpFromBracket />
                      </ViewPdfButton>
                    ) : (
                      <IGDocumentViewer
                        document={crLandDocumentDetailDto?.landDocumentProof}
                        key={
                          crLandDocumentDetailDto?.landDocumentProof
                            .documentType
                        }
                      />
                    )}
                  </Grid>
                )}
                {crLandDocumentDetailDto?.landDocumentProofApproval && (
                  <Grid item xs={6}>
                    <Typography>
                      {crLandDocumentDetailDto?.landDocumentProofApproval?.documentType.replaceAll(
                        "_",
                        " ",
                      )}
                    </Typography>
                    {crLandDocumentDetailDto?.landDocumentProofApproval
                      ?.fileType === fileType.PDF ? (
                      <ViewPdfButton
                        onClick={() =>
                          viewPdf(
                            crLandDocumentDetailDto?.landDocumentProofApproval
                              ?.fileUrl,
                          )
                        }
                      >
                        <b>View</b> &nbsp;
                        <ArrowUpFromBracket />
                      </ViewPdfButton>
                    ) : (
                      <IGDocumentViewer
                        document={
                          crLandDocumentDetailDto?.landDocumentProofApproval
                        }
                        key={
                          crLandDocumentDetailDto?.landDocumentProofApproval
                            .documentType
                        }
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            )}

            {isCrLandDocumentDetailAvailable && !originalLandDocumentId && (
              <Grid container mt={2}>
                <Grid item xs={12}>
                  {renderMatchingDocDisclaimerBlock}
                </Grid>
              </Grid>
            )}

            {originalLandDocumentId && (
              <Grid container mt={2}>
                <Grid item xs={12}>
                  {renderExposureDisclaimer}
                </Grid>
              </Grid>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: 2,
                mt: 2,
                width: "100%",
              }}
            >
              <IGButton
                color="error"
                variant="text"
                loading={isDeleteDocLoading}
                onClick={() => {
                  setLandDocument([]);
                  setApprovalScreenshot([]);
                  dispatch(
                    activeLoansActions.deleteLandDocumentDetail({
                      crId,
                    }),
                  );
                }}
                sx={{
                  textDecoration: "underline",
                }}
              >
                Discard Current Document
              </IGButton>
              <IGButton
                type="submit"
                loading={isPostLandDocDetailsLoading}
                disabled={
                  isCrLandDocumentDetailAvailable || !isEmpty(errors) || !dirty
                }
              >
                Verify Land Document
              </IGButton>
            </Box>
          </form>
          {isMatchingDocsPopupOpen && landDocumentDetail && (
            <AgriMatchingDocsPopUp
              matchingLandDocumentList={matchingLandDocumentList}
              currentAgriDocDetail={[
                landDocumentDetail?.crLandDocumentDetailDto,
              ]}
              crId={crId}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AgriDocumentDetails;
