import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export interface DialerAgentSchedule {
  attemptCount: number;
  customerName: string;
  eventDescription: string;
  eventId: number;
  groupName: string;
  isGroupCall: boolean;
  mobile: string;
  parentLoanId: number;
  serviceRequestId: number;
  callScheduledAt: number;
}
interface DialerState {
  eventDetailsById: {
    [key: string]: {
      eventDescription: string;
      eventType: string;
      serviceRequestId: string;
      loanId: string;
      mobile: string;
    };
  };
  agentSchedule: Array<DialerAgentSchedule>;
  loading: {
    getEventDetails: boolean;
    getAgentSchedule: boolean;
  };
  errors: {
    getEventDetails: null | string;
    getAgentSchedule: null | string;
  };
}
const initialState: DialerState = {
  eventDetailsById: {},
  agentSchedule: [],
  loading: {
    getEventDetails: false,
    getAgentSchedule: false,
  },
  errors: {
    getEventDetails: null,
    getAgentSchedule: null,
  },
};
const dialerReducer = createSlice({
  initialState,
  name: "dialer",
  reducers: {
    setEventDetails: (
      state,
      action: PayloadAction<{
        requestId: string;
        eventDetails: {
          eventDescription: string;
          eventType: string;
          serviceRequestId: string;
          loanId: string;
          mobile: string;
        };
      }>,
    ) => {
      state.eventDetailsById[action.payload.requestId] =
        action.payload.eventDetails;
    },
    setAgentSchedule: (state, action: PayloadAction<DialerAgentSchedule[]>) => {
      state.agentSchedule = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<{
        key: keyof DialerState["loading"];
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: keyof DialerState["errors"];
        value: string;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setEventDetails,
  setLoading: setDialerLoading,
  setError: setDialerErrors,
  setAgentSchedule,
} = dialerReducer.actions;
export default dialerReducer.reducer;
