import {products, Product} from "app/models/product";
import {RolesEnum} from "app/enums/roles";
import log from "app/infra/services/log";

// Base and User model interfaces
export interface UserInterface {
  id: string;
  age?: number | null;
  name: string;
  email: string;
  status: string;
  mobile?: string;
  gender?: string;
  isBusy?: boolean | null;
  lmTeamId: string | null;
  userRole: string;
  createdAt?: number | null;
  updatedAt?: number | null;
  homeNumber?: number | null;
  userRoleList: string[];
  profilePicture?: string | null;
  lmUserSessionId?: number | null;
  internalUserAddressDto?: string | null;
  // Product and Features related user properties
  products?: (Product | null)[];
}

interface Roles {
  [key: string]: {
    name: string;
    products?: any;
  };
}

const roles: Roles = {
  [RolesEnum.ADMIN]: {
    name: RolesEnum.ADMIN,
    products: [
      products.digitalGold,
      products.goldLoan,
      products.lenderPortal,
    ],
  },
  [RolesEnum.CST]: {
    name: RolesEnum.CST,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.A_HIMANSHU]: {
    name: RolesEnum.A_HIMANSHU,
    products: [products.digitalGold],
  },
  [RolesEnum.AUGMONT]: {
    name: RolesEnum.AUGMONT,
    products: [products.digitalGold],
  },
  [RolesEnum.ACCOUNT_TEAM]: {
    name: RolesEnum.ACCOUNT_TEAM,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.FINANCE_TEAM]: {
    name: RolesEnum.FINANCE_TEAM,
    products: [products.goldLoan],
  },
  [RolesEnum.CREDIT_MANAGER]: {
    name: RolesEnum.CREDIT_MANAGER,
    products: [products.goldLoan],
  },
  [RolesEnum.SCHEDULER]: {
    name: RolesEnum.SCHEDULER,
    products: [products.goldLoan],
  },
  [RolesEnum.SCHEDULER_TEAM_LEAD]: {
    name: RolesEnum.SCHEDULER_TEAM_LEAD,
    products: [products.goldLoan],
  },
  [RolesEnum.BUSINESS]: {
    name: RolesEnum.BUSINESS,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.FOUNDER]: {
    name: RolesEnum.FOUNDER,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.CUSTOMER_SUPPORT]: {
    name: RolesEnum.CUSTOMER_SUPPORT,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.SENIOR_OPERATIONS]: {
    name: RolesEnum.SENIOR_OPERATIONS,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD]: {
    name: RolesEnum.CUSTOMER_SUPPORT_TEAM_LEAD,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.INSIDE_SALES_LOAN]: {
    name: RolesEnum.INSIDE_SALES_LOAN,
    products: [products.goldLoan],
  },
  [RolesEnum.INSIDE_SALES_LOCKER]: {
    name: RolesEnum.INSIDE_SALES_LOCKER,
    products: [products.goldLoan],
  },
  [RolesEnum.INSIDE_SALES_TEAM_LEAD]: {
    name: RolesEnum.INSIDE_SALES_TEAM_LEAD,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.CITY_HEAD]: {
    name: RolesEnum.CITY_HEAD,
    products: [products.goldLoan],
  },
  [RolesEnum.INSIDE_SALES_AFFILIATE]: {
    name: RolesEnum.INSIDE_SALES_AFFILIATE,
    products: [products.goldLoan],
  },
  [RolesEnum.LENDER]: {
    name: RolesEnum.LENDER,
    products: [products.goldLoan, products.lenderPortal],
  },
  [RolesEnum.LOAN_MANAGER]: {
    name: RolesEnum.LOAN_MANAGER,
    products: [],
  },
  [RolesEnum.LOAN_MANAGER_LEAD]: {
    name: RolesEnum.LOAN_MANAGER_LEAD,
    products: [products.goldLoan],
  },
  [RolesEnum.AUDIT_MANAGER_HEAD]: {
    name: RolesEnum.AUDIT_MANAGER_HEAD,
    products: [products.goldLoan],
  },
  [RolesEnum.CLOSURE_RETENTION_TEAM]: {
    name: RolesEnum.CLOSURE_RETENTION_TEAM,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.ISR_RETENTION_TEAM_LEAD]: {
    name: RolesEnum.ISR_RETENTION_TEAM_LEAD,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.TRAINING_MANAGER]: {
    name: RolesEnum.TRAINING_MANAGER,
    products: [products.goldLoan],
  },
  [RolesEnum.LEAD_AFFILIATES]: {
    name: RolesEnum.LEAD_AFFILIATES,
    products: [products.partnerDashboard],
  },
  [RolesEnum.SUPPORT_AND_RETENTION]: {
    name: RolesEnum.SUPPORT_AND_RETENTION,
    products: [products.digitalGold, products.goldLoan],
  },
  [RolesEnum.USER_ADMIN]: {
    name: RolesEnum.USER_ADMIN,
    products: [products.digitalGold],
  },
  [RolesEnum.LENDER_FINCARE_ADMIN]: {
    name: RolesEnum.LENDER_FINCARE_ADMIN,
    products: [products.lenderPortal],
  },
  [RolesEnum.LENDER_PIRAMAL_ADMIN]: {
    name: RolesEnum.LENDER_PIRAMAL_ADMIN,
    products: [products.lenderPortal],
  },
  [RolesEnum.LENDER_SHIVALIK_ADMIN]: {
    name: RolesEnum.LENDER_SHIVALIK_ADMIN,
    products: [products.lenderPortal],
  },
  [RolesEnum.LENDER_SHIVALIK_READ_ONLY]: {
    name: RolesEnum.LENDER_SHIVALIK_READ_ONLY,
    products: [products.lenderPortal],
  },
  [RolesEnum.LENDER_PIRAMAL_READ_ONLY]: {
    name: RolesEnum.LENDER_PIRAMAL_READ_ONLY,
    products: [products.lenderPortal],
  },
  [RolesEnum.COLLECTION_MANAGER]: {
    name: RolesEnum.LENDER_SHIVALIK_ADMIN,
    products: [products.goldLoan],
  },
  [RolesEnum.LOAN_OPS_ADMIN]: {
    name: RolesEnum.LOAN_OPS_ADMIN,
    products: [products.goldLoan],
  },
};

//User Model
export default function User(userData: UserInterface): UserInterface {
  const userProducts: Product[] = [];
  userData.userRoleList.forEach((role: string) => {
    const roleData = roles[role];
    roleData?.products?.forEach((product: Product) => {
      if (!userProducts.find(p => p.id === product.id)) {
        userProducts.push(product);
      }
    });
  });

  const user = {
    id: userData.id || "",
    age: userData.age || null,
    name: userData.name || "",
    email: userData.email || "",
    status: userData.status || "",
    mobile: userData.mobile || "",
    userRole: userData.userRole || "",
    lmTeamId: userData.lmTeamId || "",
    createdAt: userData.createdAt || null,
    updatedAt: userData.updatedAt || null,
    homeNumber: userData.homeNumber || null,
    userRoleList: userData.userRoleList || [],
    profilePicture: userData.profilePicture || null,
    lmUserSessionId: userData.lmUserSessionId || null,
    internalUserAddressDto: userData.internalUserAddressDto || null,
    products: userProducts || [],
  };
  log("User : ", user);
  return user;
}

export interface LoanManager extends UserInterface {
  accessToken: string | null;
  refreshToken: string | null;
}
