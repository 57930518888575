import React from "react";

const NavBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#121212">
          <g>
            <g>
              <path
                d="M7.735.493C7.541.5 7.355.58 7.22.72l-7 7c-.293.293-.293.767 0 1.06l7 7c.188.196.467.275.73.207.263-.069.468-.274.537-.537.068-.263-.01-.542-.207-.73L2.56 9h14.69c.27.004.522-.138.658-.372.137-.234.137-.522 0-.756-.136-.234-.388-.376-.658-.372H2.56l5.72-5.72c.222-.215.288-.545.168-.83-.12-.284-.404-.466-.713-.457z"
                transform="translate(-16 -40) translate(16 40) translate(3 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NavBackIcon;
