import styled from "@emotion/styled";
import {AppBar} from "@mui/material";

const StyledAppBar = styled(AppBar)`
  z-index: ${(props) => props.theme.zIndex.drawer + 10};
  background-color: #fff !important;
  margin-left: 0;
  width: 100%;
  height: 64px;
`;

export default StyledAppBar;