import {createSlice} from "@reduxjs/toolkit";
import {closureActions} from "./action";
import {ClosureState} from "./types";

const initialState: ClosureState = {
  closureAmountData: {
    data: null,
    isFetched: false,
  },
  closureDetail: {
    totalRefundAmount: 0,
    isLoanAutoClosed: false,
    fwcServiceRequestClosureDetailDtoList: [],
  },
  bankList: [],
  upiList: [],
  deliverySlots: null,
  deliverySlotDetail: {},
  paymentDetail: null,
  bankDetail: null,
  loading: {
    allowPayment: false,
    cancelRequest: false,
    closureAmount: false,
    paymentDetail: false,
    bankDetailFromIFSC: false,
    agentList: false,
    bankList: false,
    upiList: false,
    getAvailableDeliverySlots: false,
    getDeliverySlotDetail: false,
    updateDeliverySlot: false,
  },
  error: {
    allowPayment: null,
    cancelRequest: null,
    closureAmount: null,
    agentList: null,
    bankDetailFromIFSC: null,
    bankList: null,
    upiList: null,
    paymentDetail: null,
    getAvailableDeliverySlots: null,
    getDeliverySlotDetail: null,
    updateDeliverySlot: null,
  },
};

const closureSlice = createSlice({
  name: "closure",
  initialState,
  reducers: {
    setGetClosureAmountFetched: (state, action) => {
      state.closureAmountData.isFetched = action.payload;
    },
    resetClosureAmountData: (state) => {
      state.closureAmountData.isFetched = false;
      state.closureAmountData.data = null;
    },
  },
  extraReducers: {
    [closureActions.getClosureAmount.type]: (state) => {
      state.loading.closureAmount = true;
    },
    [closureActions.getClosureAmountSuccess.type]: (state, action) => {
      state.loading.closureAmount = false;
      state.closureAmountData.isFetched = true;
      state.closureAmountData.data = action.payload.response;
    },
    [closureActions.getClosureAmountFailure.type]: (state, action) => {
      state.loading.closureAmount = false;
      state.closureAmountData.data = null;
      state.error.closureAmount = action.payload.error;
      state.closureAmountData.isFetched = false;
    },
    [closureActions.getPaymentDetail.type]: (state) => {
      state.loading.paymentDetail = true;
    },
    [closureActions.getPaymentDetailSuccess.type]: (state, action) => {
      state.loading.paymentDetail = false;
      state.paymentDetail = action.payload.response;
      state.error.paymentDetail = null;
    },
    [closureActions.getPaymentDetailFailure.type]: (state, action) => {
      state.loading.paymentDetail = false;
      state.paymentDetail = null;
      state.error.paymentDetail = action.payload.error;
    },
    [closureActions.getBankDetails.type]: (state) => {
      state.loading.bankList = true;
    },
    [closureActions.getBankDetailsSuccess.type]: (state, action) => {
      state.loading.bankList = false;
      state.bankList = action.payload.response;
      state.error.bankList = null;
    },
    [closureActions.getBankDetailsFailure.type]: (state, action) => {
      state.loading.bankList = false;
      state.bankList = [];
      state.error.bankList = action.payload.error;
    },
    [closureActions.getUPIDetails.type]: (state) => {
      state.loading.upiList = true;
    },
    [closureActions.getUPIDetailsSuccess.type]: (state, action) => {
      state.loading.upiList = false;
      state.upiList = action.payload.response;
      state.error.upiList = null;
    },
    [closureActions.getUPIDetailsFailure.type]: (state, action) => {
      state.loading.upiList = false;
      state.upiList = [];
      state.error.upiList = action.payload.error;
    },
    [closureActions.getBankFromIFSC.type]: (state) => {
      state.loading.bankDetailFromIFSC = true;
      state.bankDetail = null;
    },
    [closureActions.getBankFromIFSCSuccess.type]: (state, action) => {
      state.loading.bankDetailFromIFSC = false;
      state.bankDetail = action.payload.response;
      state.error.bankDetailFromIFSC = null;
    },
    [closureActions.getBankFromIFSCFailure.type]: (state, action) => {
      state.loading.bankDetailFromIFSC = false;
      state.bankDetail = null;
      state.error.bankDetailFromIFSC = action.payload.error;
    },
    [closureActions.allowPayment.type]: (state) => {
      state.loading.allowPayment = true;
    },
    [closureActions.allowPaymentFailure.type]: (state, action) => {
      state.loading.allowPayment = false;
      state.error.allowPayment = action.payload.error;
    },
    [closureActions.allowPaymentSuccess.type]: (state) => {
      state.loading.allowPayment = false;
    },
    [closureActions.cancelRequest.type]: (state) => {
      state.loading.cancelRequest = true;
    },
    [closureActions.cancelRequestFailure.type]: (state, action) => {
      state.loading.cancelRequest = false;
      state.error.cancelRequest = action.payload.error;
    },
    [closureActions.cancelRequestSuccess.type]: (state) => {
      state.loading.cancelRequest = false;
    },
    [closureActions.getAvailableDeliverySlots.type]: (state) => {
      state.loading.getAvailableDeliverySlots = true;
      state.error.getAvailableDeliverySlots = null;
      state.deliverySlots = null;
    },
    [closureActions.getAvailableDeliverySlotsSuccess.type]: (state, action) => {
      state.loading.getAvailableDeliverySlots = false;
      state.error.getAvailableDeliverySlots = null;
      state.deliverySlots = action.payload.response;
    },
    [closureActions.getAvailableDeliverySlotsFailure.type]: (state, action) => {
      state.loading.getAvailableDeliverySlots = false;
      state.error.getAvailableDeliverySlots = action.payload.error;
    },
    [closureActions.updateDeliverySlot.type]: (state) => {
      state.loading.updateDeliverySlot = true;
      state.error.updateDeliverySlot = null;
    },
    [closureActions.updateDeliverySlotSuccess.type]: (state) => {
      state.loading.updateDeliverySlot = false;
      state.error.updateDeliverySlot = null;
    },
    [closureActions.updateDeliverySlotFailure.type]: (state, action) => {
      state.loading.updateDeliverySlot = false;
      state.error.updateDeliverySlot = action.payload.error;
    },
    [closureActions.getDeliverySlotDetail.type]: (state, action) => {
      state.loading.getDeliverySlotDetail = true;
      state.error.getDeliverySlotDetail = null;
      state.deliverySlotDetail[action.payload.requestId] =
        initialState.deliverySlotDetail[action.payload.requestId];
    },
    [closureActions.getDeliverySlotDetailSuccess.type]: (state, action) => {
      state.loading.getDeliverySlotDetail = false;
      state.error.getDeliverySlotDetail = null;
      state.deliverySlotDetail[action.payload.requestId] =
        action.payload.response;
    },
    [closureActions.getDeliverySlotDetailFailure.type]: (state, action) => {
      state.loading.getDeliverySlotDetail = false;
      state.error.getDeliverySlotDetail = action.payload.error;
    },
  },
});

export const {
  setGetClosureAmountFetched,
  resetClosureAmountData,
} = closureSlice.actions;

export {initialState as closureState};

export default closureSlice.reducer;
