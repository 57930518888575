import React, {useCallback, useEffect, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";

import Container from "../../styles/Container";
import Title from "../../styles/Title";
import NotesArea from "../styles/NotesArea";
import NotesWrapper from "../styles/NotesWrapper";
import DocumentContainer from "../styles/DocumentContainer";

import DocView from "../../../CommonComponents/DocView";

import SubTitle from "../../styles/SubTitle";
import IconWrapper from "../styles/IconWrapper";
import InfoIcon from "app/assets/uploadKYC/InfoIcon";
import InfoIconWrapper from "../styles/InfoIconWrapper";
import getDocument from "api/business/getDocument";
import BottomModalContainer from "./styles/BottomModalContainer";
import AadhaarNumberWrapper from "./styles/AadhaarNumberWrapper";
import Aadhar from "./Addhar";
import PanCard from "./PanCard";
import HeaderContainer from "./styles/HeaderContainer";
import UpdateName from "../UpdateNameModal";

export const ModalContent = ({doc, title, customerName}) => {
  return (
    <BottomModalContainer>
      <HeaderContainer>
        <div>
          <SubTitle>{title}</SubTitle>
          <AadhaarNumberWrapper>
            {doc && doc[0]?.documentNumber}
          </AadhaarNumberWrapper>
        </div>
        <div className="mr-4">
          <SubTitle>Customer Name</SubTitle>
          <Title>{customerName}</Title>
        </div>
      </HeaderContainer>
      <SubTitle>Remarks</SubTitle>
      <Title>{doc?.remark || "There is no remarks as of now."}</Title>
    </BottomModalContainer>
  );
};

const Shivalik = ({lenderDetails, crId, customerName}) => {
  const accessToken = localStorage.getItem("authToken");
  const [states, setStates] = useState({
    error: false,
    errorMessage: "",
    loading: false,
  });

  const [openAadhaarModal, setOpenAadhaarModal] = useState(false);
  const [openPanModal, setOpenPanModal] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);

  const [aadharDetails, setAadharDetails] = useState();
  const [panDetails, setPanDetails] = useState();

  const countRef = useRef(0);

  const fullName = Object.values(customerName).join(" ");

  const fetchDocuments = useCallback(async () => {
    const {payload, error, statusCode} = await getDocument({
      accessToken,
      crId,
      documentType: ["AADHAR", "PAN", "FORM60"],
    });

    if (statusCode === 200) {
      setAadharDetails(payload.filter(doc => doc.documentType === "AADHAR"));
      const pan = payload.filter(doc => doc.documentType === "PAN");
      const formSixty = payload.filter(doc => doc.documentType === "FORM60");
      if (pan[0] && pan[0].documentNumber) {
        setPanDetails(pan);
      } else {
        setPanDetails(formSixty);
      }
      setPanDetails(
        payload.filter(
          doc =>
            (doc.documentType === "PAN" && doc.documentNumber) ||
            doc.documentType === "FORM60",
        ),
      );
    } else {
      setStates({
        ...states,
        error: true,
        errorMessage: error,
      });
    }
  }, [accessToken, crId, states]);

  useEffect(() => {
    if (countRef.current === 0) {
      countRef.current = 1;

      fetchDocuments();
    }
  }, [fetchDocuments]);

  const AadhaarBottomModal = () => (
    <ModalContent
      title={"Aadhaar"}
      doc={aadharDetails}
      customerName={fullName}
    />
  );


  const PanBottomModal = () => (
    <ModalContent title={"Pan Card"} doc={panDetails} customerName={fullName} />
  );

  return (
    <Container>
      {fullName && (
        <UpdateName
          open={openNameModal}
          onClose={() => setOpenNameModal(false)}
          customerName={customerName}
        />
      )}
      <h6>
        Customer Name : {fullName}{" "}
        <IconButton onClick={() => setOpenNameModal(true)}>
          <EditOutlined style={{color: "#ce9f29"}} />
        </IconButton>
      </h6>
      <Title>
        Please verify all details according to the Lender selected by the LM
        <IconWrapper src={lenderDetails.icon} alt="bank icon" />
      </Title>

      <NotesArea>
        {lenderDetails?.lenderValidationText.map((note) => (
          <NotesWrapper>
            <InfoIconWrapper>
              <InfoIcon />
            </InfoIconWrapper>
            {note}
          </NotesWrapper>
        ))}
      </NotesArea>

      <DocumentContainer>
        {aadharDetails && aadharDetails.length > 0 && (
          <Aadhar
            aadharDetails={aadharDetails}
            setOpenAadhaarModal={setOpenAadhaarModal}
            key={aadharDetails}
          />
        )}
        {panDetails && panDetails.length > 0 && (
          <PanCard
            panDetails={panDetails}
            setOpenPanModal={setOpenPanModal}
            key={panDetails}
          />
        )}
      </DocumentContainer>

      {aadharDetails && aadharDetails.length > 0 && (
        <DocView
          open={openAadhaarModal}
          docsList={[
            aadharDetails[0].fileUrl,
            aadharDetails[0].backFileUrl,
          ].filter((doc) => doc)}
          BottomComponent={AadhaarBottomModal}
          title="Aadhaar Details"
          onCloseHandler={() => setOpenAadhaarModal(false)}
        />
      )}

      {panDetails && panDetails.length > 0 && (
        <DocView
          open={openPanModal}
          docsList={[panDetails[0].fileUrl, panDetails[0].backFileUrl].filter(
            (doc) => doc,
          )}
          title="Pan/Form-60 Details"
          BottomComponent={PanBottomModal}
          onCloseHandler={() => setOpenPanModal(false)}
        />
      )}
    </Container>
  );
};

export default Shivalik;
