import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
  accountHolderNameByAgent: string;
  crBankDetailTypeEnum: string;
}

export const updatedBankDetailVerificationStatus = async ({
  crId,
  accountHolderNameByAgent,
  crBankDetailTypeEnum,
}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    UPDATE_VERIFICATION_STATUS: {
      URL: "admin/bank-detail/:crId/approve",
      METHOD: "POST",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.UPDATE_VERIFICATION_STATUS,
    options: {
      pathVars: {
        crId,
      },
      data: {
        accountHolderNameByAgent,
        crBankDetailTypeEnum,
      },
    },
  });
};

export default updatedBankDetailVerificationStatus;
