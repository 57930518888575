import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: "1.5rem",
    fontWeight: "500",
    letterSpacing: "0.18px",
    // marginBottom: "2.75rem",
  },
}));

export default useStyles;
