import {createAction} from "@reduxjs/toolkit";
import {GetLoanSummaryPayload, LoanSummaryResponse} from "./loanSummary.types";

const loanSummaryActionTypes = {
  GetLoanSummaryDetails: "[LoanSummary] Get LoanSummary Details",
  GetLoanSummaryDetailsSuccess: "[LoanSummary] Get LoanSummary Details Success",
  GetLoanSummaryDetailsFailure: "[LoanSummary] Get LoanSummary Details Failure",
  GetLenderLoanSummaryDetails: "[LoanSummary] Get Lender LoanSummary Details",
  GetLenderLoanSummaryDetailsSuccess: "[LoanSummary] Get Lender LoanSummary Details Success",
  GetLenderLoanSummaryDetailsFailure: "[LoanSummary] Get Lender LoanSummary Details Failure",
};

const loanSummaryActions = {
  getLoanSummaryDetails: createAction<GetLoanSummaryPayload>(
    loanSummaryActionTypes.GetLoanSummaryDetails,
  ),
  getLoanSummaryDetailsSuccess: createAction<LoanSummaryResponse>(
    loanSummaryActionTypes.GetLoanSummaryDetailsSuccess,
  ),
  getLoanSummaryDetailsFailure: createAction<LoanSummaryResponse>(
    loanSummaryActionTypes.GetLoanSummaryDetailsFailure,
  ),
  getLenderLoanSummaryDetails: createAction<GetLoanSummaryPayload>(
    loanSummaryActionTypes.GetLenderLoanSummaryDetails,
  ),
  getLenderLoanSummaryDetailsSuccess: createAction<LoanSummaryResponse>(
    loanSummaryActionTypes.GetLenderLoanSummaryDetailsSuccess,
  ),
  getLenderLoanSummaryDetailsFailure: createAction<LoanSummaryResponse>(
    loanSummaryActionTypes.GetLenderLoanSummaryDetailsFailure,
  ),
};

export default loanSummaryActions;

export {
  loanSummaryActionTypes,
};