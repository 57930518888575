import {RootState} from "../../store";

const lmAvailabilitySelectors = {
  getIsFetching: (state: RootState) =>
    state.scheduling.lmAvailability.isFetching,
  getLMs: (state: RootState) => state.scheduling.lmAvailability.loanManagerList,
  getSelectedLMs: (state: RootState) =>
    state.scheduling.lmAvailability.selectedLoanManagerList,
  getLMAvailability: (state: RootState) =>
    state.scheduling.lmAvailability.loanManagerAvailability,
  getLoaders: (state: RootState) => state.scheduling.lmAvailability.loaders,
  getLoading: (state: RootState) => state.scheduling.lmAvailability.loading,
  getError: (state: RootState) => state.scheduling.lmAvailability.error,
  getDialogOpen: (state: RootState) =>
    state.scheduling.lmAvailability.blockTimeDialogOpen,
  getSelectedJob: (state: RootState) =>
    state.scheduling.lmAvailability.selectedJob,
  getSelectedTimeSlot: (state: RootState) =>
    state.scheduling.lmAvailability.selectedTimeSlot,
  getETA: (state: RootState) => state.scheduling.lmAvailability.etaList,
  getOpenCrEta: (state: RootState) =>
    state.scheduling.lmAvailability.openCREtaList,
  getUnScheduledManualAssignment: (state: RootState) =>
    state.scheduling.lmAvailability.unscheduledFeatureFlag,
  getUnScheduledClusterDetail: (state: RootState) =>
    state.scheduling.lmAvailability.unscheduledClusterDetail,
  getUnScheduledAgentReport: (state: RootState) =>
    state.scheduling.lmAvailability.unscheduledAgentReport,
};

export default lmAvailabilitySelectors;
