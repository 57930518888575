import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources} from "app/typings/api/goldApi.types";
import {
  PaymentsApiInterface,
  GetPaymentsList,
  GetLoanServiceRequestIdList,
  UpdateUncategorisedPayment,
} from "./types";

const PAYMENTS_END_POINTS: ApiResources = {
  PAYMENT_LIST: {
    URL: "/admin/service-desk/loan/payment-list",
    METHOD: "GET",
  },
  LOAN_SERVICE_REQUEST_ID_LIST: {
    URL: "/admin/service-desk/loan/:loanId/service-request-id/list",
    METHOD: "GET",
  },
  UPDATE_UNCATEGORISED_PAYMENT: {
    URL: "/admin/service-desk/loan/payment/:userGoldLoanPaymentId",
    METHOD: "PUT",
  },
};

const closureApi: PaymentsApiInterface = {
  getPaymentsList: async (payload: GetPaymentsList) => {
    const {response, error} = await goldApiService({
      resource: PAYMENTS_END_POINTS.PAYMENT_LIST,
      options: {
        queryParams: {...payload},
      },
    });

    if (error) {
      return {error};
    }
    return {paymentsList: response};
  },
  getLoanServiceRequestIdList: async ({
    actualPurpose,
    loanId,
  }: GetLoanServiceRequestIdList) => {
    const {response, error} = await goldApiService({
      resource: PAYMENTS_END_POINTS.LOAN_SERVICE_REQUEST_ID_LIST,
      options: {
        queryParams: {actualPurpose},
        pathVars: {loanId},
      },
    });

    if (error) {
      return {error};
    }
    return {loanServiceRequestIdList: response};
  },
  updateUncategorisedPayment: async ({
    requestId,
    actualPurpose,
    userGoldLoanPaymentId,
  }: UpdateUncategorisedPayment) => {
    return await goldApiService({
      resource: PAYMENTS_END_POINTS.UPDATE_UNCATEGORISED_PAYMENT,
      options: {
        pathVars: {userGoldLoanPaymentId},
        queryParams: {requestId, actualPurpose},
      },
    });
  },
};

export default closureApi;
