import moment from "moment";

export interface Address {
  addressCoordinates: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  addressType: string | null;
  city: string;
  crId: number;
  crJobId: number;
  landmark: string | null;
  landmarkCoordinates: string | null;
  locality: string | null;
  nucleusStateCode: number | null;
  pincode: number | null;
  state: string | null;
}

export interface TakeOverDetail {
  bankClosingTime: string;
  bankDisplayName: string;
  bankDistance: number;
  bankName: string;
  crJobId: number;
  currentAnnualRoi: number;
  currentLoanAmount: number;
  currentLtv: number;
  customerITRFillingStatus: string;
  customerOccupation: string;
  customerWorkExperience: number;
  lenderBranchAddress: string;
  lenderBranchClosingTime: string;
  lenderDistance: number;
  lenderName: string;
  lenderType: string;
  outstandingDue: number;
  takeoverAmount: number | null;
}

export interface PrimaryLMJob {
  agentId: number | null;
  agentName: number | null;
  crId: number;
  crJobId: number | null;
  crType: number | null;
  dealValue: number | null;
  endTime: number | null;
  id: number;
  leadId: number | null;
  name: string | null;
  remark: string | null;
  remarkEnum: string | null;
  reportingTime: number | null;
  startTime: number | null;
  status: string;
  type: string;
  verificationOtp: string | null;
}

export interface CRInterface {
  agent: string | null;
  primaryAgentName: string | null;
  primarySalesAgentName: string | null;
  businessFieldAgentResponseDtoList: any[];
  callConfirmationStatus: string | null;
  cityId: number;
  crGoldDropLocationDto: string | null;
  originDate: number;
  creditManagerId: number | null;
  lastCMName: string | null;
  dealValue: number;
  endTime: string | null;
  id: number;
  isPersonalLoanTaken: boolean;
  loanClassification: string;
  milestone: string;
  milestoneName: string;
  mobile: string;
  name: string;
  createdAt: number | null;
  oldLoanId: number | null;
  personalLoanTaken: boolean;
  rebookReason: string | null;
  rebookRemark: string | null;
  reportingTime: string | null;
  secondAgent: string | null;
  startTime: string | null;
  thirdAgent: string | null;
  type: string;
  reasonForScheduling: string | null;
  // lead source details
  advocateEmail: string | null;
  leadReferralCode: string | null;
  leadSource: string | null;
  originalSource: string;
  rmName: string;
  // address details
  addresses: Address[];
  // takeover details
  takeoverDetails: TakeOverDetail;
  // primary job details
  primaryLMJob: PrimaryLMJob;
  // eligible job list
  eligibleJobList: string[];
  // meeting time
  meetingDate: string | null;
  meetingStartTime: number | null;
  meetingEndTime: number | null;
  internalBt?: boolean | null;
  btAmountPaidBy?: string | null;
  loanTopUpAmountDetails: LoanTopUpAmount | null;
  loanRenewalDetails: LoanRenewalDetails | null;
  leadId: number;
  loanType: string;
  actionRequired?: string | null;
  isDataPurged?: boolean;
}

export default function CR(cr: CRInterface): CRInterface {
  return {
    agent: cr.agent || null,
    primaryAgentName: cr.primaryAgentName || null,
    primarySalesAgentName: cr.primarySalesAgentName || null,
    businessFieldAgentResponseDtoList:
      cr.businessFieldAgentResponseDtoList || [],
    callConfirmationStatus: cr.callConfirmationStatus || "",
    cityId: cr.cityId || 1,
    crGoldDropLocationDto: cr.crGoldDropLocationDto || null,
    originDate: cr.originDate || moment().valueOf(),
    creditManagerId: cr.creditManagerId || 0,
    lastCMName: cr.lastCMName || null,
    dealValue: cr.dealValue || 0,
    endTime: cr.endTime || null,
    originalSource: cr.originalSource || "",
    id: cr.id || 0,
    isPersonalLoanTaken: cr.isPersonalLoanTaken || false,
    loanClassification: cr.loanClassification || "",
    milestone: cr.milestone || "",
    milestoneName: cr.milestoneName || "",
    mobile: cr.mobile || "",
    createdAt: cr.createdAt || null,
    name: cr.name || "",
    oldLoanId: cr.oldLoanId || null,
    personalLoanTaken: cr.personalLoanTaken || false,
    rebookReason: cr.rebookReason || null,
    rebookRemark: cr.rebookRemark || null,
    reportingTime: cr.reportingTime || null,
    secondAgent: cr.secondAgent || null,
    startTime: cr.startTime || null,
    thirdAgent: cr.thirdAgent || null,
    type: cr.type || "",
    reasonForScheduling: cr.reasonForScheduling || null,
    advocateEmail: cr.advocateEmail || null,
    leadReferralCode: cr.leadReferralCode || null,
    leadSource: cr.leadSource || null,
    addresses: cr.addresses || [],
    takeoverDetails: cr.takeoverDetails || ({} as TakeOverDetail),
    primaryLMJob: cr.primaryLMJob || ({} as PrimaryLMJob),
    eligibleJobList: cr.eligibleJobList || [],
    meetingDate: null,
    meetingStartTime: null,
    meetingEndTime: null,
    internalBt: cr.internalBt || null,
    btAmountPaidBy: cr.btAmountPaidBy || null,
    rmName: cr.rmName || "",
    leadId: cr.leadId || 0,
    loanRenewalDetails: cr.loanRenewalDetails || null,
    loanTopUpAmountDetails: cr.loanTopUpAmountDetails || null,
    loanType: cr.loanType || "",
    actionRequired: cr.actionRequired || null,
  };
}

export interface LoanAmountSummary {
  loanAmount: number;
  loanTypeEnum: string;
  loanTypeName: string;
  loanTypeShortName: string;
  oldOutstandingPrincipleAmount: number;
}

export interface CMRenewalAmountAdjustmentResponse {
  totalReceivableAmountWithoutAdjustment: number;
  totalReceivableAmountWithAdjustment: number;
  glRenewalAmountAdjustmentDto: {
    netReceivableAmountWithoutAdjustment: number;
  };
  plRenewalAmountAdjustmentDto: {
    netReceivableAmountWithoutAdjustment: number;
  };
}
export interface LoanTopUpAmount {
  glCustomerDisbursalAmount: number;
  plCustomerDisbursalAmount: number;
  totalCustomerDisbursalAmount: number;
  showCustomerTopUpAmount: boolean;
  fwcSrCmRenewalAmountAdjustmentResponseDto: CMRenewalAmountAdjustmentResponse;
}

export interface LoanRenewalDetails {
  glRenewalAmountAdjustmentDto: {
    adjustedAmount: number;
    childLoanId: number;
    interestAmountTillDateIncludingExcessAmount: number;
    interestAmountTillDateWithoutExcessAmount: number;
    loanType: string;
    outstandingPrincipalAmountWithExcessAmount: number;
    outstandingPrincipalAmountWithoutExcessAmount: number;
    interestAmountTillDateIncludingForeclosureAndExcessAmount: number;
    remainingAmountToCollect: number;
    remainingExcessAmount: number;
    waivedOffAmount: number;
    currentForeclosureChargeAmount: number;
  };
  plRenewalAmountAdjustmentDto: {
    adjustedAmount: number;
    childLoanId: number;
    interestAmountTillDateIncludingExcessAmount: number;
    interestAmountTillDateWithoutExcessAmount: number;
    loanType: string;
    outstandingPrincipalAmountWithExcessAmount: number;
    outstandingPrincipalAmountWithoutExcessAmount: number;
    interestAmountTillDateIncludingForeclosureAndExcessAmount: number;
    remainingAmountToCollect: number;
    remainingExcessAmount: number;
    waivedOffAmount: number;
    currentForeclosureChargeAmount: number;
  };
  serviceRequestId: number;
  approvalScreenshotFileUrl: string;
  loanId: number;
  totalAdjustedAmount: number;
  totalAmountToCollectFromCustomer: number;
  totalInterestIncludingExcessAmount: number;
  totalInterestWithoutExcessAmount: number;
  totalNewLoanAmount: number;
  totalOutstandingPrincipalAmountWithoutExcessAmount: number;
  totalOutstandingPrincipalAmountWithExcessAmount: number;
  totalInterestIncludingForeclosureAndExcessAmount: number;
  totalReceivableAmountWithAdjustment: number;
  totalReceivableAmountWithoutAdjustment: number;
  totalWaivedOffAmount: number;
  newPersonalLoanAmount: number;
  newGoldLoanAmount: number;
  totalMarginMoney: number;
  interestAdjustmentAllocationExist: boolean;
  maxAllowedGlAdjustedInterestAmount: number;
  maxAllowedPlAdjustedInterestAmount: number;
  maxWaivedAllowedForTransactionModule: number;
  totalRemainingPfAmountIncludingGstToCollectIncludingExcessAmount: number;
  maxPfApplicableIncludingGst: number;
  totalActualPfAmountIncludingGst: number;
  isInterestAdjustmentAllowedWithTopUp: boolean;
  totalAdjustedPfAmountWithTopUp: number;
  totalOutstandingPublicationChargeAmount: number;
}
