export type PaymentDetailType =
  | "GOLD_LOAN_PAYMENT_DETAIL"
  | "GOLD_LOAN_LENDER_TO_INDIAGOLD_ACCOUNT"
  | "PERSONAL_LOAN_PAYMENT_DETAIL"
  | "PERSONAL_LOAN_LENDER_TO_GOLD_LOAN_LENDER"
  | "TAKEOVER_PAYMENT_DETAIL"
  | "GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG"
  | "PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG"
  | "GL_NON_PRINCIPAL_ADJUSTED_FROM_IG"
  | "PL_NON_PRINCIPAL_ADJUSTED_FROM_IG"
  | "PL_PRINCIPAL_SETTLE_FROM_PL"
  | "PL_PRINCIPAL_SETTLE_FROM_GL"
  | "GL_PRINCIPAL_SETTLE_FROM_GL"
  | "GL_PRINCIPAL_SETTLE_FROM_PL"
  | "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL"
  | "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL"
  | "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL"
  | "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL"
  | "GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER"
  | "PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER"
  | "GOLD_LOAN_PROCESSING_FEE";

interface TypeEnum {
  [key: string]: PaymentDetailType;
}

export const PaymentDetailsEnums: TypeEnum = {
  GOLD_LOAN_PAYMENT_DETAIL: "GOLD_LOAN_PAYMENT_DETAIL",
  GOLD_LOAN_LENDER_TO_INDIAGOLD_ACCOUNT:
    "GOLD_LOAN_LENDER_TO_INDIAGOLD_ACCOUNT",
  PERSONAL_LOAN_PAYMENT_DETAIL: "PERSONAL_LOAN_PAYMENT_DETAIL",
  PERSONAL_LOAN_LENDER_TO_GOLD_LOAN_LENDER:
    "PERSONAL_LOAN_LENDER_TO_GOLD_LOAN_LENDER",
  TAKEOVER_PAYMENT_DETAIL: "TAKEOVER_PAYMENT_DETAIL",
  GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG: "GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG",
  PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG: "PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG",
  GL_NON_PRINCIPAL_ADJUSTED_FROM_IG: "GL_NON_PRINCIPAL_ADJUSTED_FROM_IG",
  PL_NON_PRINCIPAL_ADJUSTED_FROM_IG: "PL_NON_PRINCIPAL_ADJUSTED_FROM_IG",
  PL_PRINCIPAL_SETTLE_FROM_PL: "PL_PRINCIPAL_SETTLE_FROM_PL",
  PL_PRINCIPAL_SETTLE_FROM_GL: "PL_PRINCIPAL_SETTLE_FROM_GL",
  GL_PRINCIPAL_SETTLE_FROM_GL: "GL_PRINCIPAL_SETTLE_FROM_GL",
  GL_PRINCIPAL_SETTLE_FROM_PL: "GL_PRINCIPAL_SETTLE_FROM_PL",
  GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL: "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL",
  GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL: "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL",
  PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL: "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL",
  PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL: "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL",
  GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER: "GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER",
  PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER: "PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER",
  GOLD_LOAN_PROCESSING_FEE: "GOLD_LOAN_PROCESSING_FEE",
};

export const renderPaymentJobTitleBlock = (paymentType: any) => {
  switch (paymentType) {
    case PaymentDetailsEnums.GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER:
      return "Old Loan interest Payment";
    case PaymentDetailsEnums.PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER:
      return "Old PL interest Payment";
    case PaymentDetailsEnums.GOLD_LOAN_PAYMENT_DETAIL:
      return "Disbursal To Customer";
    case PaymentDetailsEnums.GOLD_LOAN_LENDER_TO_INDIAGOLD_ACCOUNT:
      return "Reimburse to Collection account";
    case PaymentDetailsEnums.PERSONAL_LOAN_LENDER_TO_GOLD_LOAN_LENDER:
      return "Refund To BT Lender";
    case PaymentDetailsEnums.TAKEOVER_PAYMENT_DETAIL:
      return "Takeover Payment Details";
    case PaymentDetailsEnums.PERSONAL_LOAN_PAYMENT_DETAIL:
      return "PL Disbursal To Customer";
    case PaymentDetailsEnums.GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG:
      return "Old Loan dues by IG as Waiveoff";

    case PaymentDetailsEnums.PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG:
      return "Old PL dues by IG as Waiveoff";

    case PaymentDetailsEnums.GL_NON_PRINCIPAL_ADJUSTED_FROM_IG:
      return "Old GL dues by IG as Adjustment";

    case PaymentDetailsEnums.PL_NON_PRINCIPAL_ADJUSTED_FROM_IG:
      return "Old PL dues by IG as Adjustment";
    case PaymentDetailsEnums.PL_PRINCIPAL_SETTLE_FROM_PL:
      return "PL Disbursal to PL closure";

    case PaymentDetailsEnums.PL_PRINCIPAL_SETTLE_FROM_GL:
      return "GL Disbursal to PL closure";

    case PaymentDetailsEnums.GL_PRINCIPAL_SETTLE_FROM_GL:
      return "New Loan Disbursal to Old Loan closure";

    case PaymentDetailsEnums.GL_PRINCIPAL_SETTLE_FROM_PL:
      return "PL Disbursal to GL closure";
    case PaymentDetailsEnums.GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL:
      return "GL Disbursal to IG for GL interest recovery";

    case PaymentDetailsEnums.GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL:
      return "PL Disbursal to IG for GL interest recovery";

    case PaymentDetailsEnums.PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL:
      return "PL Disbursal to IG For PL interest recovery";

    case PaymentDetailsEnums.PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL:
      return "GL Disbursal to IG For PL interest recovery";
    case PaymentDetailsEnums.GOLD_LOAN_PROCESSING_FEE:
      return "Processing Fee";
    default:
      return "Payment Details";
  }
};
