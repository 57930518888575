import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CR, {CRInterface} from "app/models/openCr";
import {OpenCRActions} from "./actions";
import {
  ClusterDetailResponse,
  NoteInterface,
} from "../../../infra/services/api/scheduling/openCR/types";
import {getValueFromQueryParams} from "_metronic";
import {NullLiteral} from "typescript";
import {GoldApiError} from "app/typings/api/goldApi.types";

export interface OpenCRState {
  crList: {
    isFetching: boolean;
    data: CRInterface[];
  };
  crDetail: {
    isFetching: boolean;
    data: CRInterface;
  };
  paginationData: {
    pageNo: number;
    pageSize: number;
    totalCount: number;
  };
  noteList: NoteInterface[];
  drawerOpen: boolean;
  isFetching: boolean;
  error: any | null;
  openCrFeatureFlag: boolean;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  loading: Record<LoadingErrorState, boolean>;
  openCrClusterDetail: ClusterDetailResponse | null;
  openCrAgentReport: boolean | null;
}

type LoadingErrorState =
  | "openCrFeatureFlag"
  | "openCrClusterDetail"
  | "openCrAgentReport"
  | "enableOpenCrManualAssignment";

// >>>>>>> Scheduling Initial State <<<<<<<<<
const initialState: OpenCRState = {
  crList: {
    isFetching: false,
    data: [],
  },
  crDetail: {
    isFetching: false,
    data: new (CR as any)({}),
  },
  paginationData: {
    pageNo: Number(getValueFromQueryParams("crPageNo") || 1),
    pageSize: 10,
    totalCount: 0,
  },
  noteList: [],
  drawerOpen: false,
  isFetching: false,
  error: null,
  openCrFeatureFlag: false,
  openCrClusterDetail: null,
  openCrAgentReport: null,
  loading: {
    openCrFeatureFlag: false,
    openCrClusterDetail: false,
    openCrAgentReport: false,
    enableOpenCrManualAssignment: false,
  },
  errors: {
    openCrFeatureFlag: null,
    openCrClusterDetail: null,
    openCrAgentReport: null,
    enableOpenCrManualAssignment: null,
  },
};

export const schedulingSlice = createSlice({
  name: "openCR",
  initialState,
  reducers: {
    resetSchedulingState: (state) => {
      state.crList.isFetching = false;
      state.crList.data = [];
      state.crDetail.isFetching = false;
      state.crDetail.data = new (CR as any)({});
      state.isFetching = false;
      state.error = null;
      state.drawerOpen = false;
    },
    toggleDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.paginationData.pageNo = action.payload;
    },
    setRowSize: (state, action: PayloadAction<number>) => {
      state.paginationData.pageSize = action.payload;
    },
    setOpenCrNewView: (state, action: PayloadAction<boolean>) => {
      state.openCrFeatureFlag = action.payload;
    },
    setOpenCrClusterDetail: (
      state,
      action: PayloadAction<ClusterDetailResponse>,
    ) => {
      state.openCrClusterDetail = action.payload;
    },
    setOpenCrAgentReport: (state, action: PayloadAction<boolean>) => {
      state.openCrAgentReport = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: {
    [OpenCRActions.getCRList.type]: (state) => {
      state.crList.isFetching = true;
      state.isFetching = true;
    },
    [OpenCRActions.getCRListSuccess.type]: (state, action) => {
      state.crList.isFetching = false;
      state.isFetching = false;
      state.paginationData.pageNo = action.payload.crList.pageNo || 1;
      state.paginationData.pageSize = action.payload.crList.pageSize || 10;
      state.paginationData.totalCount = action.payload.crList.totalCount || 0;
      state.crList.data = action.payload.crList.result;
    },
    [OpenCRActions.getCRListFailure.type]: (state, action) => {
      state.crList.isFetching = false;
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [OpenCRActions.getCRDetail.type]: (state) => {
      state.crDetail.isFetching = true;
      state.isFetching = true;
      state.crDetail.data = new (CR as any)({});
    },
    [OpenCRActions.getCRDetailSuccess.type]: (state, action) => {
      state.crDetail.isFetching = false;
      state.isFetching = false;
      state.crDetail.data = {
        ...state.crDetail.data,
        ...action.payload.crDetail,
      };
    },
    [OpenCRActions.getCRDetailFailure.type]: (state, action) => {
      state.crDetail.isFetching = false;
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [OpenCRActions.getLeadSourceDetail.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getLeadSourceDetailSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data = {
        ...state.crDetail.data,
        ...action.payload.leadSourceDetail,
      };
    },
    [OpenCRActions.getLeadSourceDetailFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getAddressDetail.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getAddressDetailSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data!.addresses = [...action.payload.addresses];
    },
    [OpenCRActions.getAddressDetailFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getTakeOverDetail.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getTakeOverDetailSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data!.takeoverDetails = {
        ...action.payload.takeOverDetails,
      };
    },
    [OpenCRActions.getTakeOverDetailFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getPrimaryLMJobDetail.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getPrimaryLMJobDetailSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data!.primaryLMJob = {
        ...action.payload.primaryLMJob,
      };
    },
    [OpenCRActions.getPrimaryLMJobDetailFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getEligibleJobs.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getEligibleJobsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data!.eligibleJobList = [...action.payload.eligibleJobs];
    },
    [OpenCRActions.getEligibleJobsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getMeetingTime.type]: (state) => {
      state.isFetching = true;
      state.crDetail.isFetching = true;
    },
    [OpenCRActions.getMeetingTimeSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.crDetail.data = {
        ...state.crDetail.data,
        ...action.payload.data,
      };
    },
    [OpenCRActions.getMeetingTimeFailure.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.updateCallingStatus.type]: (state) => {
      state.isFetching = true;
    },
    [OpenCRActions.updateCallingStatusSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.data!.callConfirmationStatus =
        action.payload.data.callConfirmationStatus;
      const oldCRList: CRInterface[] = state.crList.data;
      const crIndex = oldCRList.findIndex(
        (cr: CRInterface) => cr.id === state.crDetail.data!.id,
      );
      if (crIndex !== -1) {
        state.crList.data = [...oldCRList.slice(0, crIndex)];
        if (
          "keepInList" in action.payload.data &&
          action.payload.data.keepInList
        ) {
          state.crList.data.push({
            ...oldCRList[crIndex],
            callConfirmationStatus: action.payload.data.callConfirmationStatus,
          });
        }
        state.crList.data = [
          ...state.crList.data,
          ...oldCRList.slice(crIndex + 1, oldCRList.length),
        ];
      }

      // update note list if note is added
      if (action.payload.data.note && action.payload.data.note.length > 0) {
        state.noteList.unshift({
          agentId: 0, // not needed so we can pass 0 as dummy,
          callConfirmationStatus: action.payload.data.callConfirmationStatus,
          crId: action.payload.data.crId,
          note: action.payload.data.note,
          createdAt: new Date().valueOf(),
        });
      }
    },
    [OpenCRActions.updateCallingStatusFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.updateMeetingTime.type]: (state) => {
      state.isFetching = true;
    },
    [OpenCRActions.updateMeetingTimeSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.crDetail.data = {
        ...state.crDetail.data,
        ...action.payload.data,
      };
      const oldCRList: CRInterface[] = state.crList.data;
      const crIndex = oldCRList.findIndex(
        (cr: CRInterface) => cr.id === state.crDetail.data!.id,
      );
      if (crIndex !== -1) {
        state.crList.data = [
          ...oldCRList.slice(0, crIndex),
          {
            ...oldCRList[crIndex],
            ...action.payload.data,
          },
          ...oldCRList.slice(crIndex + 1, oldCRList.length),
        ];
      }
    },
    [OpenCRActions.updateMeetingTimeFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.crAssignJob.type]: (state) => {
      state.isFetching = true;
    },
    [OpenCRActions.crAssignJobSuccess.type]: (state) => {
      state.isFetching = false;
      const oldCRList: CRInterface[] = state.crList.data;
      const leadIndex = oldCRList.findIndex(
        (cr: CRInterface) => cr.id === state.crDetail.data!.id,
      );
      if (leadIndex !== -1) {
        state.crList.data = [
          ...oldCRList.slice(0, leadIndex),
          ...oldCRList.slice(leadIndex + 1, oldCRList.length),
        ];
      }
    },
    [OpenCRActions.crAssignJobFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.crCreateJob.type]: (state) => {
      state.isFetching = true;
    },
    [OpenCRActions.crCreateJobSuccess.type]: (state) => {
      state.isFetching = false;
      const oldCRList: CRInterface[] = state.crList.data;
      const leadIndex = oldCRList.findIndex(
        (cr: CRInterface) => cr.id === state.crDetail.data!.id,
      );
      if (leadIndex !== -1) {
        state.crList.data = [
          ...oldCRList.slice(0, leadIndex),
          ...oldCRList.slice(leadIndex + 1, oldCRList.length),
        ];
      }
    },
    [OpenCRActions.crCreateJobFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload?.error;
    },
    [OpenCRActions.getNoteList.type]: (state) => {
      state.isFetching = true;
    },
    [OpenCRActions.getNoteListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.noteList = action.payload.noteList;
    },
    [OpenCRActions.getNoteListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
  },
});

const {
  resetSchedulingState,
  toggleDrawerOpen,
  setCurrentPage,
  setRowSize,
  setOpenCrNewView,
  setOpenCrClusterDetail,
  setLoading: setOpenCrLoading,
  setError: setOpenCrError,
  setOpenCrAgentReport,
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
export {
  resetSchedulingState,
  toggleDrawerOpen,
  setCurrentPage,
  setRowSize,
  setOpenCrNewView,
  initialState,
  setOpenCrLoading,
  setOpenCrError,
  setOpenCrClusterDetail,
  setOpenCrAgentReport,
};
