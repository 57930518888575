import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {getValueFromQueryParams} from "_metronic/utils/utils";
import {
  paginationSliceCreator,
  getInitialState,
  PaginationState,
} from "../factoryReducers";
import {getValidTime} from "_metronic/utils/moment";
import {ClosureRequestInterface} from "app/models/closureRequest.model";
import {goldReleaseActions} from ".";

type LoadingErrorState = "getClosureList" | "getHandoverImages";

interface GoldReleaseFilters {
  startTime: number;
  endTime: number;
  lenderLoanId?: number | null;
  pageNo?: number;
  pageSize?: number;
}

export interface GoldReleaseState {
  closureRequests: Record<number, ClosureRequestInterface>;
  handoverImages: Record<number, {
    goldHandoverImageUrl: string;
    signedPledgeCardImages: string[];
  }>;
  filters: GoldReleaseFilters;
  selectedCr: number | null;
  pagination: PaginationState;
  openModals: {
    handoverImage: boolean;
  }
  pageNoAndKeyMapping: Record<number, number>;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, string | null>;
}

const initialState: GoldReleaseState = {
  closureRequests: {},
  handoverImages: {},
  selectedCr: null,
  filters: {
    lenderLoanId: getValueFromQueryParams("lenderLoanId") ? Number(getValueFromQueryParams("lenderLoanId")) : null,
    startTime:
      Number(getValueFromQueryParams("startTime")) ||
      getValidTime()
        .subtract("10", "days")
        .startOf("day")
        .valueOf(),
    endTime:
      Number(getValueFromQueryParams("endTime")) ||
      getValidTime()
        .endOf("day")
        .valueOf(),
    pageNo: Number(getValueFromQueryParams("pageNo")) || 1,
    pageSize: Number(getValueFromQueryParams("pageSize")) || 10,
  },
  loading: {
    getClosureList: false,
    getHandoverImages: false,
  },
  errors: {
    getClosureList: null,
    getHandoverImages: null,
  },
  openModals: {
    handoverImage: false,
  },
  pageNoAndKeyMapping: {},
  pagination: getInitialState("goldRelease"),
};

const goldReleasePaginationReducer = paginationSliceCreator("goldRelease");

const goldReleaseSlice = createSlice({
  name: "goldRelease",
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<{"handoverImage": boolean}>) => {
      state.openModals.handoverImage = action.payload.handoverImage;
    },
    setSelectedCr: (state, action: PayloadAction<number>) => {
      state.selectedCr = action.payload;
    },
    setFilters: (
      state,
      action: PayloadAction<Partial<GoldReleaseFilters>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      state.pagination.pageNo = 1;
    },
    resetFilters: (state) => {
      state.filters = {
        lenderLoanId: null,
        startTime: getValidTime()
          .subtract("10", "days")
          .startOf("day")
          .valueOf(),
        endTime: getValidTime()
          .endOf("day")
          .valueOf(),
      };
      state.pagination.pageNo = 1;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(goldReleaseActions.getClosureList, (state) => {
        state.loading.getClosureList = true;
        state.closureRequests = initialState.closureRequests;
        state.errors.getClosureList = initialState.errors.getClosureList;
      })
      .addCase(goldReleaseActions.getClosureListSuccess, (state, action) => {
        const currentPageNo = state.pagination.pageNo;
        state.loading.getClosureList = false;
        state.closureRequests = {};
        // Set pageNoAndKeyMapping where key is pageNo and value is lastItemCreatedAt
        if (action.payload.response.length) {
          state.pageNoAndKeyMapping[currentPageNo] =
            action.payload.response[action.payload.response.length - 1]
              .loanCreatedAt;
        }
        action?.payload?.response?.forEach(
          (closureRequest: ClosureRequestInterface) => {
            state.closureRequests[closureRequest.lenderLoanId] = closureRequest;
          },
        );
      })
      .addCase(goldReleaseActions.getClosureListFailure, (state, action) => {
        state.loading.getClosureList = false;
        state.errors.getClosureList = action.payload.error;
      })
      .addCase(goldReleaseActions.getHandoverImages, (state) => {
        state.loading.getHandoverImages = true;
        state.errors.getHandoverImages =
          initialState.errors.getHandoverImages;
      })
      .addCase(
        goldReleaseActions.getHandoverImagesSuccess,
        (state, action) => {
          state.loading.getHandoverImages = false;
          state.errors.getHandoverImages =
            initialState.errors.getHandoverImages;
          state.handoverImages[action.payload.crId] = {
            goldHandoverImageUrl: action.payload.response.goldHandoverImageUrl,
            signedPledgeCardImages:
              action.payload.response.signedPledgeCardImages,
          };
        },
      )
      .addCase(
        goldReleaseActions.getHandoverImagesFailure,
        (state, action) => {
          state.loading.getHandoverImages = false;
          state.errors.getHandoverImages = action.payload.error;
          state.openModals.handoverImage = false;
        },
      )
      .addMatcher(
        (action) => action.type.startsWith("goldReleasePagination"),
        (state, action) => {
          state.pagination = goldReleasePaginationReducer(
            state.pagination,
            action,
          );
        },
      ),
});

export default goldReleaseSlice.reducer;

const {
  resetFilters,
  setFilters,
  toggleModal,
  setSelectedCr,
} = goldReleaseSlice.actions;

export {
  setFilters,
  resetFilters,
  toggleModal,
  setSelectedCr,
};
