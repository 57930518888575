import {
  AddressModel,
  BlacklistedGoogleCoordinates,
  GoogleCoordinates,
  FormattedGoogleCoordinates,
} from "../../models/address.model";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GoldApiError} from "../../typings/api/goldApi.types";
import addressActions from "./address.actions";
import {setActionError, setActionLoading, createActionsInitialState} from "../redux.utils";

type LoadingErrorState = "getAddressList";

type AddressActions = "getGoogleCoorindatesFromAddress" | "getBlacklistedGoogleCoordinates";
export interface AddressState {
  addressList: AddressModel[];
  googleCoordinates: Record<number, FormattedGoogleCoordinates[]>;
  blacklistedGoogleCoordinates: BlacklistedGoogleCoordinates | null;
  actions: ReturnType<typeof createActionsInitialState>;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: AddressState = {
  addressList: [],
  googleCoordinates: {},
  blacklistedGoogleCoordinates: null,
  actions: createActionsInitialState([
    "getGoogleCoorindatesFromAddress",
    "getBlacklistedGoogleCoordinates",
  ]),
  loading: {
    getAddressList: false,
  },
  errors: {
    getAddressList: null,
  },
};

const AddressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setActionLoading,
    setActionError,
    setGoogleCoordinates: (
      state,
      action: PayloadAction<{
        leadId: number;
        googleCoordinates: GoogleCoordinates[];
      }>,
    ) => {
      const coordinatesArray = action.payload.googleCoordinates.map(
        (location: GoogleCoordinates, index: number) => ({
          lat: parseFloat(String(location.latitude)),
          lng: parseFloat(String(location.longitude)),
          label: location.address,
          info: location.formattedAddress,
          position: String.fromCharCode(65 + index),
        }),
      );
      state.googleCoordinates[action.payload.leadId] = coordinatesArray;
    },

    setBlacklistedGoogleCoordinates: (state, action) => {
      state.blacklistedGoogleCoordinates = action.payload.response;
    },
    resetGoogleCoordinates: state => {
      state.googleCoordinates = [];
      state.blacklistedGoogleCoordinates = null;
    },
  },

  extraReducers: builder =>
    builder
      .addCase(addressActions.getAddressList, state => {
        state.loading.getAddressList = true;
        state.errors.getAddressList = initialState.errors.getAddressList;
        state.addressList = initialState.addressList;
      })
      .addCase(addressActions.getAddressListSuccess, (state, action) => {
        state.loading.getAddressList = false;
        state.addressList = [...state.addressList, ...action.payload.response];
      })
      .addCase(addressActions.getAddressListFailure, (state, action) => {
        state.loading.getAddressList = false;
        state.errors.getAddressList = action.payload.error;
      })
      .addCase(addressActions.getLenderAddressList, state => {
        state.loading.getAddressList = true;
        state.errors.getAddressList = initialState.errors.getAddressList;
        state.addressList = initialState.addressList;
      })
      .addCase(addressActions.getLenderAddressListSuccess, (state, action) => {
        state.loading.getAddressList = false;
        state.addressList = [...state.addressList, ...action.payload.response];
      })
      .addCase(addressActions.getLenderAddressListFailure, (state, action) => {
        state.loading.getAddressList = false;
        state.errors.getAddressList = action.payload.error;
      }),
});

export const selectAddressAction = (state: RootState, action: AddressActions) =>
  state.address.actions[action];

const {
  setActionError: setAddressError,
  setActionLoading: setAddressLoading,
  setGoogleCoordinates,
  resetGoogleCoordinates,
  setBlacklistedGoogleCoordinates,
} = AddressSlice.actions;

export default AddressSlice.reducer;
export {
  setAddressError,
  setAddressLoading,
  setGoogleCoordinates,
  resetGoogleCoordinates,
  setBlacklistedGoogleCoordinates,
};
