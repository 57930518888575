/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {authActions, authSelectors} from "../../../store/auth";
import axios from "../../../../_metronic/utils/axios";
import {API_ENDPOINTS} from "../../../constants/constants";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import User from "app/models/user";
import ProfileMenu from "./Menu";
import styles from "./styles";
import ChangePasswordModal from "./ChangePasswordModal";

function UserProfile() {
  const classes = styles();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const [modal, setModal] = useState({
    changePassword: {
      open: false,
      oldPassword: "",
      newPassword: "",
      confirmedNewPassword: "",
      email: "",
    },
  });
  const [changePassword, setChangePassword] = useState(false);

  function changePasswordHandler() {
    if (!modal.changePassword.email) {
      snackbar.showWarning("Email is required");
      return;
    }
    if (!modal.changePassword.oldPassword) {
      snackbar.showWarning("Old password is required");
      return;
    }
    if (!modal.changePassword.newPassword) {
      snackbar.showWarning("New password is required");
      return;
    }
    if (!modal.changePassword.confirmedNewPassword) {
      snackbar.showWarning("Confirmation password is required");
      return;
    }
    if (
      modal.changePassword.newPassword !==
      modal.changePassword.confirmedNewPassword
    ) {
      snackbar.showWarning(
        "New password and confirmation password do not match",
      );
      return;
    }
    setChangePassword(true);
    axios
      .request({
        method: API_ENDPOINTS.AUTH.CHANGE_PASSWORD.METHOD,
        url: API_ENDPOINTS.AUTH.CHANGE_PASSWORD.URL,
        data: {
          email: modal.changePassword.email,
          oldPassword: modal.changePassword.oldPassword,
          newPassword: modal.changePassword.newPassword,
          confirmedNewPassword: modal.changePassword.confirmedNewPassword,
        },
      })
      .then((response) => {
        setChangePassword(false);
        if (response && response.data && response.data.result) {
          snackbar.showSuccess("Password updated successfully!");
          setModal({
            ...modal,
            changePassword: {
              open: false,
              email: "",
              oldPassword: "",
              newPassword: "",
              confirmedNewPassword: "",
            },
          });

          // Create new user and login successfully
          const {
            accessToken,
            refreshToken,
            ...userData
          } = response.data.result;
          const user = new User({
            ...userData,
          });

          dispatch(
            authActions.loginSuccess({
              authToken: accessToken,
              refreshToken: refreshToken,
              isAuthenticated: true,
              user,
            }),
          );
        }
      })
      .catch((err) => {
        setChangePassword(false);
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          snackbar.showError(
            err.response.data &&
              err.response.data.error &&
              err.response.data.error.message,
          );
        } else {
          snackbar.showError("Something went wrong");
        }
      });
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className={classes.profileContainer}>
      {/* Change Passoword Modal */}
      <ChangePasswordModal
        modal={modal}
        setModal={setModal}
        changePassword={changePassword}
        changePasswordHandler={changePasswordHandler}
      />
      {/* Profile Menu */}
      <ProfileMenu
        showAvatar
        showHi
        changePasswordHandler={() => {
          setModal({
            ...modal,
            changePassword: {
              ...modal.changePassword,
              open: true,
            },
          });
        }}
      />
    </div>
  );
}

export default UserProfile;
