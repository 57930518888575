import {RootState} from "app/store/store";

const schedulingSelectors = {
  getCities: (state: RootState) => state.scheduling.cities,
  getError: (state: RootState) => state.scheduling.error,
  getCurrentView: (state: RootState) => state.scheduling.currentView,
  getLenderList: (state: RootState) => state.scheduling.lenderList,
  getLoading: (state: RootState) => state.scheduling.loading,
  getCallingHistory: (state: RootState) =>
    state.scheduling.callingHistory,
};
export default schedulingSelectors;
