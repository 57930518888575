import {createAction} from "@reduxjs/toolkit";
import {
  AlgoResponse,
  ChangeAlgoScreenPayload,
  ConfirmAlgoRunPayload,
  CreateAgentTripPayload,
  DeleteAgentTripPayload,
  GetAgentListPayload,
  GetAgentRouteListPayload,
  GetAgentScheduleDetailsPayload,
  GetAlgoRunReportPayload,
  GetAlgoRunStatusPayload,
  GetAvailableAgentListPayload,
  GetCustomerDetailsPayload,
  GetCustomerListPayload,
  GetRunsPayload,
  GetTripDetailsPayload,
  SaveAgentRoutePayload,
  StartAlgoPayload,
} from "app/infra/services/api/scheduling/algo/types";
import {GetLMsPayload} from "app/infra/services/api/scheduling/types";

export const algoActionTypes = {
  GetRuns: "[Algo] Get Runs",
  GetRunsSuccess: "[Algo] Get Runs Success",
  GetRunsFailure: "[Algo] Get Runs Failure",
  GetAgentList: "[Algo] Get Agent List",
  GetAgentListSuccess: "[Algo] Get Agent List Success",
  GetAgentListFailure: "[Algo] Get Agent List Failure",
  GetAgentRouteList: "[Algo] Get Agent Route List",
  GetAgentRouteListSuccess: "[Algo] Get Agent Route List Success",
  GetAgentRouteListFailure: "[Algo] Get Agent Route List Failure",
  GetCustomerList: "[Algo] Get Customer List",
  GetCustomerListSuccess: "[Algo] Get Customer List Success",
  GetCustomerListFailure: "[Algo] Get Customer List Failure",
  SaveAgentRoute: "[Algo] Save Agent Route",
  SaveAgentRouteSuccess: "[Algo] Save Agent Route Success",
  SaveAgentRouteFailure: "[Algo] Save Agent Route Failure",
  DeleteAgentTrip: "[Algo] Delete Agent Trip",
  DeleteAgentTripSuccess: "[Algo] Delete Agent Trip Success",
  DeleteAgentTripFailure: "[Algo] Delete Agent Trip Failure",
  StartAlgo: "[Algo] Start Algo",
  StartAlgoSuccess: "[Algo] Start Algo Success",
  StartAlgoFailure: "[Algo] Start Algo Failure",
  GetAlgoRunStatus: "[Algo] Get Algo Run Status",
  GetAlgoRunStatusSuccess: "[Algo] Get Algo Run Status Success",
  GetAlgoRunStatusFailure: "[Algo] Get Algo Run Status Failure",
  ChangeAlgoScreen: "[Algo] Change Algo Screen",
  GetAgentScheduleDetails: "[Algo] Get Agent Schedule Details",
  GetAgentScheduleDetailsSuccess: "[Algo] Get Agent Schedule Details Success",
  GetAgentScheduleDetailsFailure: "[Algo] Get Agent Schedule Details Failure",
  GetCustomerDetail: "[Algo] Get Customer Detail",
  GetCustomerDetailSuccess: "[Algo] Get Customer Detail Success",
  GetCustomerDetailFailure: "[Algo] Get Customer Detail Failure",
  GetAlgoRunReport: "[Algo] Get Algo Run Report",
  GetAlgoRunReportSuccess: "[Algo] Get Algo Run Report Success",
  GetAlgoRunReportFailure: "[Algo] Get Algo Run Report Failure",
  ConfirmAlgoRun: "[Algo] Confirm Algo Run",
  ConfirmAlgoRunSuccess: "[Algo] Confirm Algo Run Success",
  ConfirmAlgoRunFailure: "[Algo] Confirm Algo Run Failure",
  GetTripDetails: "[Algo] Get Trip Details",
  GetTripDetailsSuccess: "[Algo] Get Trip Details Success",
  GetTripDetailsFailure: "[Algo] Get Trip Details Failure",
  UpdateAlgoRunDetails: "[Algo] Update Algo Run Detail",
  CreateAgentTrip: "[Algo] Create Agent Trip",
  CreateAgentTripSuccess: "[Algo] Create Agent Trip Success",
  CreateAgentTripFailure: "[Algo] Create Agent Trip Failure",
  UpdateSelectedLMs: "[Algo] Update Selected LMs",
  UpdateLMAvailability: "[Algo] Update LM Availability",
  GetLMs: "[Algo] Get LMs",
  GetLMsSuccess: "[Algo] Get LMs Success",
  GetLMsFailure: "[Algo] Get LMs Failure",
  GetLMAvailability: "[Algo] Get LM Availability",
  GetLMAvailabilitySuccess: "[Algo] Get LM Availability Success",
  GetLMAvailabilityFailure: "[Algo] Get LM Availability Failure",
  GetAvailableAgentList: "[Algo] Get Available Agent List",
  GetAvailableAgentListSuccess: "[Algo] Get Available Agent List Success",
  GetAvailableAgentListFailure: "[Algo] Get Available Agent List Failure",
};

export const algoActions = {
  getRuns: createAction<GetRunsPayload>(algoActionTypes.GetRuns),
  getRunsSuccess: createAction<AlgoResponse>(algoActionTypes.GetRunsSuccess),
  getRunsFailure: createAction<AlgoResponse>(algoActionTypes.GetRunsFailure),
  getAgentList: createAction<GetAgentListPayload>(algoActionTypes.GetAgentList),
  getAgentListSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAgentListSuccess,
  ),
  getAgentListFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAgentListFailure,
  ),
  getAgentRouteList: createAction<GetAgentRouteListPayload>(
    algoActionTypes.GetAgentRouteList,
  ),
  getAgentRouteListSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAgentRouteListSuccess,
  ),
  getAgentRouteListFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAgentRouteListFailure,
  ),
  getCustomerList: createAction<GetCustomerListPayload>(
    algoActionTypes.GetCustomerList,
  ),
  getCustomerListSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetCustomerListSuccess,
  ),
  getCustomerListFailure: createAction<AlgoResponse>(
    algoActionTypes.GetCustomerListFailure,
  ),
  deleteAgentTrip: createAction<DeleteAgentTripPayload>(
    algoActionTypes.DeleteAgentTrip,
  ),
  deleteAgentTripSuccess: createAction<AlgoResponse>(
    algoActionTypes.DeleteAgentTripSuccess,
  ),
  deleteAgentTripFailure: createAction<AlgoResponse>(
    algoActionTypes.DeleteAgentTripFailure,
  ),
  saveAgentRoute: createAction<SaveAgentRoutePayload>(
    algoActionTypes.SaveAgentRoute,
  ),
  saveAgentRouteSuccess: createAction<AlgoResponse>(
    algoActionTypes.SaveAgentRouteSuccess,
  ),
  saveAgentRouteFailure: createAction<AlgoResponse>(
    algoActionTypes.SaveAgentRouteFailure,
  ),
  startAlgo: createAction<StartAlgoPayload>(algoActionTypes.StartAlgo),
  startAlgoSuccess: createAction<AlgoResponse>(
    algoActionTypes.StartAlgoSuccess,
  ),
  startAlgoFailure: createAction<AlgoResponse>(
    algoActionTypes.StartAlgoFailure,
  ),
  getAlgoRunStatus: createAction<GetAlgoRunStatusPayload>(
    algoActionTypes.GetAlgoRunStatus,
  ),
  getAlgoRunStatusSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAlgoRunStatusSuccess,
  ),
  getAlgoRunStatusFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAlgoRunStatusFailure,
  ),
  changeAlgoScreen: createAction<ChangeAlgoScreenPayload>(
    algoActionTypes.ChangeAlgoScreen,
  ),
  getAgentsScheduleDetails: createAction<GetAgentScheduleDetailsPayload>(
    algoActionTypes.GetAgentScheduleDetails,
  ),
  getAgentsScheduleDetailsSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAgentScheduleDetailsSuccess,
  ),
  getAgentsScheduleDetailsFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAgentScheduleDetailsFailure,
  ),
  getCustomerDetails: createAction<GetCustomerDetailsPayload>(
    algoActionTypes.GetCustomerDetail,
  ),
  getCustomerDetailsSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetCustomerDetailSuccess,
  ),
  getCustomerDetailsFailure: createAction<AlgoResponse>(
    algoActionTypes.GetCustomerDetailFailure,
  ),
  getAlgoRunReport: createAction<GetAlgoRunReportPayload>(
    algoActionTypes.GetAlgoRunReport,
  ),
  getAlgoRunReportSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAlgoRunReportSuccess,
  ),
  getAlgoRunReportFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAlgoRunReportFailure,
  ),
  confirmAlgoRun: createAction<ConfirmAlgoRunPayload>(
    algoActionTypes.ConfirmAlgoRun,
  ),
  confirmAlgoRunSuccess: createAction<AlgoResponse>(
    algoActionTypes.ConfirmAlgoRunSuccess,
  ),
  confirmAlgoRunFailure: createAction<AlgoResponse>(
    algoActionTypes.ConfirmAlgoRunFailure,
  ),
  getTripDetails: createAction<GetTripDetailsPayload>(
    algoActionTypes.GetTripDetails,
  ),
  getTripDetailsSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetTripDetailsSuccess,
  ),
  getTripDetailsFailure: createAction<AlgoResponse>(
    algoActionTypes.GetTripDetailsFailure,
  ),
  updateAlgoRunDetails: createAction<Record<string, any>>(
    algoActionTypes.UpdateAlgoRunDetails,
  ),
  createAgentTrip: createAction<CreateAgentTripPayload>(
    algoActionTypes.CreateAgentTrip,
  ),
  createAgentTripSuccess: createAction<AlgoResponse>(
    algoActionTypes.CreateAgentTripSuccess,
  ),
  createAgentTripFailure: createAction<AlgoResponse>(
    algoActionTypes.CreateAgentTripFailure,
  ),
  updateSelectedLMs: createAction<any>(algoActionTypes.UpdateSelectedLMs),
  updateLMAvailability: createAction<any>(algoActionTypes.UpdateLMAvailability),
  getLMs: createAction<GetLMsPayload>(algoActionTypes.GetLMs),
  getLMsSuccess: createAction<any>(algoActionTypes.GetLMsSuccess),
  getLMsFailure: createAction<any>(algoActionTypes.GetLMsFailure),
  getLMAvailability: createAction<GetAgentScheduleDetailsPayload>(
    algoActionTypes.GetLMAvailability,
  ),
  getLMAvailabilitySuccess: createAction<any>(
    algoActionTypes.GetLMAvailabilitySuccess,
  ),
  getLMAvailabilityFailure: createAction<any>(
    algoActionTypes.GetLMAvailabilityFailure,
  ),
  getAvailableAgentList: createAction<GetAvailableAgentListPayload>(
    algoActionTypes.GetAvailableAgentList,
  ),
  getAvailableAgentListSuccess: createAction<AlgoResponse>(
    algoActionTypes.GetAvailableAgentListSuccess,
  ),
  getAvailableAgentListFailure: createAction<AlgoResponse>(
    algoActionTypes.GetAvailableAgentListFailure,
  ),
};
