import {createSlice} from "@reduxjs/toolkit";
import {FetchUserTierConfigResponse} from "app/infra/services/api/userTierConfig/types";
import {userTierConfigAction} from "./actions";

export interface UserTierConfigState {
  userTierConfigData: FetchUserTierConfigResponse;
  isFetching: boolean;
  error: any | null;
  isUpdating: boolean;
  updateError: any | null;
}

// >>>>>>> User Tier Config Initial State <<<<<<<<<
const initialState: UserTierConfigState = {
  userTierConfigData: [],
  isFetching: true,
  error: null,
  isUpdating: false,
  updateError: null,
};

export const userTierConfig = createSlice({
  name: "userTierConfig",
  initialState,
  reducers: {},
  extraReducers: {
    [userTierConfigAction.getUserTierConfig.type]: state => {
      state.isFetching = true;
      state.error = null;
    },
    [userTierConfigAction.getUserTierConfigSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.error = null;
      state.userTierConfigData = action.payload.userTierConfig;
    },
    [userTierConfigAction.getUserTierConfigError.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [userTierConfigAction.updateUserTierConfig.type]: state => {
      state.isUpdating = true;
      state.updateError = null;
    },
    [userTierConfigAction.updateUserTierConfigSuccess.type]: state => {
      state.isUpdating = false;
      state.updateError = null;
    },
    [userTierConfigAction.updateUserTierConfigError.type]: (state, action) => {
      state.isUpdating = false;
      state.updateError = action.payload.error;
    },
  },
});

export default userTierConfig.reducer;
