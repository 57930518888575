import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getValueFromQueryParams, setValueInQueryParams} from "_metronic/utils/utils";
import {
  paginationSliceCreator,
  getInitialState,
  PaginationState,
} from "../factoryReducers";
import {lenderClosureJobActions} from ".";
import {LenderClosureJob} from "app/models/lenderClosureJob.model";
import {ClosureJobDetails, HandoverJobDetails, LenderCity, LmAgent, RequestLoanDetails, RequestPaymentDetails} from "./lenderClosureJob.types";
import {
  LenderClosureJobStatusEnum,
  LenderGoldHandoverJobStatusEnum,
  LenderJobTypeEnum,
} from "app/enums/lenderPortal";

type LoadingErrorState =
  "getClosureJobs" |
  "getAgentList" |
  "getLoanDetails" |
  "getPaymentDetails" |
  "getClosureJobDetails" |
  "updateJobStatus" |
  "unassignAgent" |
  "assignAgent" |
  "reassignAgent" |
  "getHandoverImages" |
  "getLMUserList" |
  "sendOtp" |
  "verifyOtp" |
  "getHandoverJobDetails" |
  "getCityList";

export interface LenderClosureJobFilters {
  startTime?: number | null;
  endTime?: number | null;
  lenderLoanId?: number | null;
  serviceRequestId?: number | null;
  jobStatusList?:
    | LenderClosureJobStatusEnum[]
    | LenderGoldHandoverJobStatusEnum[]
    | null;
  jobTypeEnum?: LenderJobTypeEnum | null;
  assignedToList?: string[] | null;
  fwcBusinessCityIdList?: number[] | null;
  searchType?: string | null;
}

export interface LenderClosureJobState {
  closureJobsById: Record<number, LenderClosureJob>;
  handoverImages: Record<
    number,
    {
      goldHandoverImageUrl: string;
      signedPledgeCardImages: string[];
    }
  >;
  agentsById: Record<number, LmAgent>;
  filters: LenderClosureJobFilters;
  selectedCrId: number | null;
  pagination: PaginationState;
  openModals: {
    handoverImage: boolean;
    rejectJob: boolean;
    reassignAgent: boolean;
    verifyOtp: boolean;
  };
  requestLoanDetails: RequestLoanDetails | null;
  requestPaymentDetails: RequestPaymentDetails[] | null;
  closureJobDetails: ClosureJobDetails | null;
  handoverJobDetails: HandoverJobDetails | null;
  pageNoAndKeyMapping: Record<number, number>;
  loading: Record<LoadingErrorState, boolean>;
  selectedRowId: number | null;
  errors: Record<LoadingErrorState, string | null>;
  otpId: string | null;
  reload: boolean;
  cities: LenderCity[];
  paginationOrder: number[];
}

const initialState: LenderClosureJobState = {
  closureJobsById: {},
  agentsById: {},
  handoverImages: {},
  requestLoanDetails: null,
  requestPaymentDetails: null,
  closureJobDetails: null,
  handoverJobDetails: null,
  selectedCrId: null,
  cities: [],
  filters: {
    lenderLoanId: getValueFromQueryParams("lenderLoanId")
      ? Number(getValueFromQueryParams("lenderLoanId"))
      : null,
    serviceRequestId: getValueFromQueryParams("serviceRequestId")
      ? Number(getValueFromQueryParams("serviceRequestId"))
      : null,
    jobStatusList: getValueFromQueryParams("jobStatusList")?.split(",") as
      | LenderClosureJobStatusEnum[]
      | LenderGoldHandoverJobStatusEnum[],
    jobTypeEnum:
      (getValueFromQueryParams("jobTypeEnum") as LenderJobTypeEnum) ||
      null,
    assignedToList: getValueFromQueryParams("assignedToList")?.split(",") || null,
    startTime: getValueFromQueryParams("startTime")
      ? Number(getValueFromQueryParams("startTime"))
      : null,
    endTime: getValueFromQueryParams("endTime")
      ? Number(getValueFromQueryParams("endTime"))
      : null,
    searchType: getValueFromQueryParams("searchType") || "LAN",
    fwcBusinessCityIdList: getValueFromQueryParams("fwcBusinessCityIdList")
      ? getValueFromQueryParams("fwcBusinessCityIdList")?.split(",").map(Number)
      : null,
  },
  loading: {
    getClosureJobs: false,
    getAgentList: false,
    getLoanDetails: false,
    getPaymentDetails: false,
    getClosureJobDetails: false,
    updateJobStatus: false,
    unassignAgent: false,
    assignAgent: false,
    reassignAgent: false,
    getHandoverImages: false,
    getLMUserList: false,
    sendOtp: false,
    verifyOtp: false,
    getHandoverJobDetails: false,
    getCityList: false,
  },
  errors: {
    getClosureJobs: null,
    getAgentList: null,
    getLoanDetails: null,
    getPaymentDetails: null,
    getClosureJobDetails: null,
    updateJobStatus: null,
    unassignAgent: null,
    assignAgent: null,
    reassignAgent: null,
    getHandoverImages: null,
    getLMUserList: null,
    sendOtp: null,
    verifyOtp: null,
    getHandoverJobDetails: null,
    getCityList: null,
  },
  openModals: {
    handoverImage: false,
    rejectJob: false,
    reassignAgent: false,
    verifyOtp: false,
  },
  pageNoAndKeyMapping: {},
  selectedRowId: null,
  otpId: null,
  reload: false,
  paginationOrder: [],
  pagination: getInitialState("lenderClosureJob"),
};

const lenderClosureJobPaginationReducer = paginationSliceCreator(
  "lenderClosureJob",
);

const lenderClosureJobSlice = createSlice({
  name: "lenderClosureJob",
  initialState,
  reducers: {
    toggleModal: (
      state,
      action: PayloadAction<{
        modal: keyof LenderClosureJobState["openModals"];
        open: boolean;
      }>,
    ) => {
      state.openModals[action.payload.modal] = action.payload.open;
    },
    setSelectedCrId: (state, action: PayloadAction<number>) => {
      state.selectedCrId = action.payload;
    },
    setFilters: (
      state,
      action: PayloadAction<Partial<LenderClosureJobFilters>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      Object.entries(state.filters).forEach(([key, value]) => {
        if (key === "jobTypeEnum") return;
        setValueInQueryParams(key, value);
      });
      state.pagination.pageNo = 1;
      state.pagination.pageSize = 10;
    },
    resetFilters: (state) => {
      state.filters = {
        lenderLoanId: null,
        startTime: null,
        endTime: null,
        jobStatusList: null,
        assignedToList: null,
        serviceRequestId: null,
        jobTypeEnum: LenderJobTypeEnum.LENDER_LOAN_CLOSURE,
      };
      state.pagination.pageNo = 1;
      state.pagination.pageSize = 10;
    },
    setJobType: (state, action: PayloadAction<LenderJobTypeEnum>) => {
      state.filters.jobTypeEnum = action.payload;
    },
    setSelectedRowId: (state, action: PayloadAction<number | null>) => {
      state.selectedRowId = action.payload;
    },
    toggleReload: (state) => {
      state.reload = !state.reload;
    },
    resetClosureJobsById: (state) => {
      state.closureJobsById = initialState.closureJobsById;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(lenderClosureJobActions.getClosureJobs, (state) => {
        state.loading.getClosureJobs = true;
        state.closureJobsById = initialState.closureJobsById;
        state.paginationOrder = initialState.paginationOrder;
        state.errors.getClosureJobs = initialState.errors.getClosureJobs;
      })
      .addCase(
        lenderClosureJobActions.getClosureJobsSuccess,
        (state, action) => {
          state.loading.getClosureJobs = false;
          state.closureJobsById = {};
          state.paginationOrder = [];
          action?.payload?.forEach((closureRequest: LenderClosureJob) => {
            state.closureJobsById[
              closureRequest.serviceRequestId
            ] = closureRequest;
            state.paginationOrder.push(closureRequest.serviceRequestId);
          });
        },
      )
      .addCase(
        lenderClosureJobActions.getClosureJobsFailure,
        (state, action) => {
          state.loading.getClosureJobs = false;
          state.errors.getClosureJobs = action.payload.error;
        },
      )
      .addCase(lenderClosureJobActions.getLmUserList, (state) => {
        state.loading.getAgentList = true;
        state.errors.getAgentList = initialState.errors.getAgentList;
        state.agentsById = initialState.agentsById;
      })
      .addCase(
        lenderClosureJobActions.getLmUserListSuccess,
        (state, action) => {
          state.loading.getAgentList = false;
          state.errors.getAgentList = null;
          action?.payload?.forEach((agent: LmAgent) => {
            state.agentsById[agent.id] = agent;
          });
        },
      )
      .addCase(
        lenderClosureJobActions.getLoanDetails,
        (state) => {
          state.loading.getLoanDetails = true;
          state.errors.getLoanDetails = initialState.errors.getLoanDetails;
        },
      )
      .addCase(
        lenderClosureJobActions.getLoanDetailsSuccess,
        (state, action) => {
          state.requestLoanDetails = action.payload;
          state.loading.getLoanDetails = false;
          state.errors.getLoanDetails = null;
        },
      )
      .addCase(
        lenderClosureJobActions.getLoanDetailsFailure,
        (state, action) => {
          state.errors.getLoanDetails = action.payload.error;
          state.loading.getLoanDetails = false;
        },
      )
      .addCase(
        lenderClosureJobActions.getPaymentDetails,
        (state) => {
          state.loading.getPaymentDetails = true;
          state.errors.getPaymentDetails =
            initialState.errors.getPaymentDetails;
        },
      )
      .addCase(
        lenderClosureJobActions.getPaymentDetailsSuccess,
        (state, action) => {
          state.requestPaymentDetails = action.payload;
          state.loading.getPaymentDetails = false;
          state.errors.getPaymentDetails = null;
        },
      )
      .addCase(
        lenderClosureJobActions.getPaymentDetailsFailure,
        (state, action) => {
          state.errors.getPaymentDetails = action.payload.error;
          state.loading.getPaymentDetails = false;
        },
      )
      .addCase(
        lenderClosureJobActions.getClosureJobDetails,
        (state) => {
          state.loading.getClosureJobDetails = true;
          state.errors.getClosureJobDetails =
            initialState.errors.getClosureJobDetails;
        },
      )
      .addCase(
        lenderClosureJobActions.getClosureJobDetailsSuccess,
        (state, action) => {
          state.closureJobDetails = action.payload;
          state.loading.getClosureJobDetails = false;
          state.errors.getClosureJobDetails = null;
        },
      )
      .addCase(
        lenderClosureJobActions.getClosureJobDetailsFailure,
        (state, action) => {
          state.errors.getClosureJobDetails = action.payload.error;
          state.loading.getClosureJobDetails = false;
        },
      )
      .addCase(
        lenderClosureJobActions.getHandoverJobDetails,
        (state) => {
          state.loading.getHandoverJobDetails = true;
          state.errors.getHandoverJobDetails =
            initialState.errors.getHandoverJobDetails;
        },
      )
      .addCase(
        lenderClosureJobActions.getHandoverJobDetailsSuccess,
        (state, action) => {
          state.handoverJobDetails = action.payload;
          state.loading.getHandoverJobDetails = false;
          state.errors.getHandoverJobDetails = null;
        },
      )
      .addCase(
        lenderClosureJobActions.getHandoverJobDetailsFailure,
        (state, action) => {
          state.errors.getHandoverJobDetails = action.payload.error;
          state.loading.getHandoverJobDetails = false;
        },
      )
      .addCase(
        lenderClosureJobActions.getAgentList,
        (state) => {
          state.loading.getAgentList = true;
          state.errors.getAgentList = initialState.errors.getAgentList;
          state.agentsById = initialState.agentsById;
        },
      )
      .addCase(
        lenderClosureJobActions.getAgentListSuccess,
        (state, action) => {
          state.errors.getAgentList = null;
          action.payload?.forEach((agent: LmAgent) => {
            state.agentsById[agent.id] = agent;
          });
          state.loading.getAgentList = false;
        },
      )
      .addCase(
        lenderClosureJobActions.getAgentListFailure,
        (state, action) => {
          state.errors.getAgentList = action.payload.error;
          state.loading.getAgentList = false;
        },
      )
      .addCase(
        lenderClosureJobActions.sendOtp,
        (state) => {
          state.loading.sendOtp = true;
          state.errors.sendOtp = initialState.errors.sendOtp;
        },
      )
      .addCase(
        lenderClosureJobActions.sendOtpSuccess,
        (state, action) => {
          state.otpId = action.payload.otpId;
          state.errors.sendOtp = null;
          state.loading.sendOtp = false;
          state.errors.verifyOtp = null;
          state.openModals.verifyOtp = true;
        },
      )
      .addCase(
        lenderClosureJobActions.sendOtpFailure,
        (state, action) => {
          state.errors.sendOtp = action.payload.error;
          state.loading.sendOtp = false;
        },
      )
      .addCase(
        lenderClosureJobActions.verifyOtp,
        (state) => {
          state.loading.verifyOtp = true;
          state.errors.verifyOtp = initialState.errors.verifyOtp;
        },
      )
      .addCase(
        lenderClosureJobActions.verifyOtpSuccess,
        (state) => {
          state.errors.verifyOtp = null;
          state.loading.verifyOtp = false;
          state.openModals.verifyOtp = false;
          state.reload = !state.reload;
        },
      )
      .addCase(
        lenderClosureJobActions.verifyOtpFailure,
        (state, action) => {
          state.errors.verifyOtp = action.payload.error;
          state.loading.verifyOtp = false;
        },
      )
      .addCase(
        lenderClosureJobActions.assignAgentSuccess,
        (state) => {
          state.reload = !state.reload;
        },
      )
      .addCase(
        lenderClosureJobActions.reassignAgentSuccess,
        (state) => {
          state.openModals.reassignAgent = false;
          state.reload = !state.reload;
        },
      )
      .addCase(
        lenderClosureJobActions.unassignAgentSuccess,
        (state) => {
          state.reload = !state.reload;
        },
      )
      .addCase(
        lenderClosureJobActions.updateJobStatusSuccess,
        (state) => {
          state.openModals.rejectJob = false;
          state.reload = !state.reload;
        },
      )
      .addCase(
        lenderClosureJobActions.getCityList,
        (state) => {
          state.cities = initialState.cities;
          state.loading.getCityList = true;
          state.errors.getCityList = initialState.errors.getCityList;
        },
      )
      .addCase(
        lenderClosureJobActions.getCityListSuccess,
        (state, action) => {
          state.cities = action.payload;
          state.loading.getCityList = false;
          state.errors.getCityList = null;
        },
      )
      .addCase(
        lenderClosureJobActions.getCityListFailure,
        (state, action) => {
          state.errors.getCityList = action.payload.error;
          state.loading.getCityList = false;
        },
      )
      .addMatcher(
        (action) => action.type.startsWith("lenderClosureJobPagination"),
        (state, action) => {
          state.pagination = lenderClosureJobPaginationReducer(
            state.pagination,
            action,
          );
        },
      ),
});

export default lenderClosureJobSlice.reducer;

const {
  resetFilters,
  setFilters,
  toggleModal,
  setSelectedCrId,
  setJobType,
  setSelectedRowId,
  resetClosureJobsById,
} = lenderClosureJobSlice.actions;

export {
  setFilters,
  resetFilters,
  toggleModal,
  setSelectedCrId,
  setJobType,
  setSelectedRowId,
  resetClosureJobsById,
};
