import auth from "./auth/api";
import unscheduled from "./scheduling/unscheduled/api";
import openCR from "./scheduling/openCR/api";
import algo from "./scheduling/algo/api";
import lmAvailability from "./scheduling/lmAvailability/api";
import schedulingGeneral from "./scheduling/api";
import giftCardConfig from "./giftCardConfig/api";
import users from "./users/api";
import userTierConfig from "./userTierConfig/api";
import refferalConfig from "./refferalConfig/api";
import serviceDesk from "./serviceDesk/serviceDesk.api";
import closure from "./serviceDesk/closure/api";
import serviceDeskPayments from "./serviceDesk/payments/api";
import internalUserApi from "./internalUser/api";
import layoutApi from "./layout/api";
import activeLoans from "./activeLoans/api";
import renewRebook from "./serviceDesk/renewRebook/api";
import complaintApi from "./serviceDesk/complaints/api";
import agentAvailability from "./serviceDesk/agentAvailability/api";
import lead from "./lead/lead.api";
import city from "./city/city.api";
import customerProfile from "./customerProfile/customerProfile.api";
import loan from "./loan/loan.api";
import address from "./address/address.api";
import loanSummary from "./loanSummary/loanSummary.api";
import loanPayment from "./loanPayment/loanPayment.api";
import lenderLoanApi from "./lenderLoan/lenderLoan.api";
import goldRelease from "./goldRelease/goldRelease.api";
import ornamentsApi from "./ornaments/ornaments.api";
import lenderCustomerProfile from "./lenderPortal/customerProfiles/customerProfile.api";
import partRelease from "./serviceDesk/partRelease/partRelease.api";
import collateralMovementApi from "./collateralMovement/collateralMovement.api";
import bankDetailsApi from "./loanRenewalRebook/bankDetails/bankDetails.api";
import occupationDetailsApi from "./loanRenewalRebook/occupationDetails/occupdationDetails.api";
import loanRenewalRebookApi from "./loanRenewalRebook/loanRenewalRebook.api";
import cifDetailsApi from "./loanRenewalRebook/cifValidation/cif.api";
import personalDetailsApi from "./loanRenewalRebook/personalDetails/personalDetails.api";
import rateChangeApi from "./rateChange/rateChange.api";
import creditDetailsApi from "./loanRenewalRebook/creditDetails/creditDetails.api";
import transactionsApi from "./transactions/transactions.api";
import lenderClosureJobApi from "./lenderPortal/closureJob/closureJob.api";
import autoDialerApi from "./autoDialer/autoDialer.api";
import appApi from "./app/app.api";

export interface GoldApi {
  app: typeof appApi;
  auth: typeof auth;
  schedulingGeneral: typeof schedulingGeneral;
  unscheduled: typeof unscheduled;
  giftCardConfig: typeof giftCardConfig;
  openCR: typeof openCR;
  users: typeof users;
  algo: typeof algo;
  lmAvailability: typeof lmAvailability;
  userTierConfig: typeof userTierConfig;
  refferalConfig: typeof refferalConfig;
  serviceDesk: typeof serviceDesk;
  serviceDeskPayments: typeof serviceDeskPayments;
  internalUser: typeof internalUserApi;
  layout: typeof layoutApi;
  closure: typeof closure;
  activeLoans: typeof activeLoans;
  renewRebook: typeof renewRebook;
  complaintApi: typeof complaintApi;
  agentAvailability: typeof agentAvailability;
  customerProfile: typeof customerProfile;
  lead: typeof lead;
  city: typeof city;
  loan: typeof loan;
  address: typeof address;
  loanSummary: typeof loanSummary;
  loanPayment: typeof loanPayment;
  lenderLoan: typeof lenderLoanApi;
  goldRelease: typeof goldRelease;
  ornaments: typeof ornamentsApi;
  lenderCustomerProfile: typeof lenderCustomerProfile;
  partRelease: typeof partRelease;
  collateralMovement: typeof collateralMovementApi;
  bankDetails: typeof bankDetailsApi;
  occupationDetails: typeof occupationDetailsApi;
  loanRenewRebook: typeof loanRenewalRebookApi;
  cifDetails: typeof cifDetailsApi;
  personalDetails: typeof personalDetailsApi;
  rateChange: typeof rateChangeApi;
  creditDetails: typeof creditDetailsApi;
  transactions: typeof transactionsApi;
  lenderClosureJob: typeof lenderClosureJobApi;
  autoDialerApi: typeof autoDialerApi;
}

const api: GoldApi = {
  app: appApi,
  auth,
  schedulingGeneral,
  unscheduled,
  openCR,
  algo,
  lmAvailability,
  giftCardConfig,
  users,
  userTierConfig,
  refferalConfig,
  serviceDesk,
  serviceDeskPayments,
  internalUser: internalUserApi,
  layout: layoutApi,
  closure,
  activeLoans,
  renewRebook,
  complaintApi,
  agentAvailability,
  customerProfile,
  lead,
  city,
  loan,
  address,
  loanSummary,
  loanPayment,
  lenderLoan: lenderLoanApi,
  goldRelease,
  ornaments: ornamentsApi,
  lenderCustomerProfile,
  partRelease,
  collateralMovement: collateralMovementApi,
  bankDetails: bankDetailsApi,
  occupationDetails: occupationDetailsApi,
  loanRenewRebook: loanRenewalRebookApi,
  cifDetails: cifDetailsApi,
  personalDetails: personalDetailsApi,
  rateChange: rateChangeApi,
  creditDetails: creditDetailsApi,
  transactions: transactionsApi,
  lenderClosureJob: lenderClosureJobApi,
  autoDialerApi,
};

export default api;
