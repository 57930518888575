import React, {useCallback} from "react";
import {Modal, Fade} from "@mui/material";

import styles from "./styles";
import clx from "clsx";

const ModalComponent = ({
  children,
  open,
  setOpen,
  title,
  description,
  canClose,
  width = "50%",
  className,
  style,
  ...restProps
}) => {
  const classes = styles({width: width});
  const handleClose = useCallback(() => {
    if (canClose) {
      setOpen(!open);
    }
  }, [open, setOpen, canClose]);

  return (
    <Modal
      {...restProps}
      aria-labelledby={title}
      aria-describedby={description}
      className={clx(classes.modal)}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{
        ...style,
      }}
    >
      <Fade in={open}>
        <div className={clx(classes.paper, className)}>{children}</div>
      </Fade>
    </Modal>
  );
};

ModalComponent.defaultProps = {
  canClose: true,
};

export default ModalComponent;
