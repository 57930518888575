import React, {useEffect} from "react";
import {authActions} from "../../store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {useNavigate} from "react-router-dom";

const Logout: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(authActions.logout());
    }
    navigate("/auth");
  }, [navigate, dispatch, isAuthenticated]);

  return null;
};

export default Logout;
