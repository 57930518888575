import apiService from "_metronic/utils/apiService";

const getOrnaments = async ({loanId}) => {
  const resource = {
    URL: "/admin/business/loan/:loanId/ornament-detail",
    METHOD: "GET",
  };
  const path = {loanId};
  return apiService(resource, {path});

};

export default getOrnaments;
