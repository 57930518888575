import * as yup from "yup";
import {ParamsInterface} from "./index";
import {TestConfig} from "yup/lib/util/createValidation";
import {useIGTranslation} from "../infra/plugins/i18n";

interface StringValidationOptions {
  required?: boolean | string;
  min?: number | ParamsInterface<number>;
  max?: number | ParamsInterface<number>;
  length?: number | ParamsInterface<number>;
  email?: boolean | string;
  custom?: TestConfig<string>;
  nullable?: boolean;
}

const ERRORS_MAPPING: Record<keyof StringValidationOptions, string> = {
  required: "REQUIRED",
  max: "MAX_STRING",
  min: "MIN_STRING",
  length: "LENGTH",
  email: "EMAIL",
  custom: "TEST_STRING",
  nullable: "NULLABLE",
};

const useStringValidations = (
  options: StringValidationOptions = {},
  fieldName = "This field",
): yup.StringSchema => {
  const {tErrors} = useIGTranslation();

  let validator = yup.string();

  if (!options) {
    return validator;
  }

  if (options.nullable) {
    // @ts-ignore
    validator = validator.nullable(true);
    delete options.nullable;
  }

  if (options.custom) {
    const ruleValue = options.custom;
    if ("test" in ruleValue) {
      // @ts-ignore
      validator = validator.test({
        name: ruleValue?.name || "",
        message: ruleValue?.message || "",
        params: ruleValue?.params || {},
        exclusive: ruleValue?.exclusive,
        test:
          ruleValue?.test ||
          function() {
            return true;
          },
      });
    }
    delete options.custom;
  }

  let ruleName: keyof typeof options;
  for (ruleName in options) {
    const ruleValue = options[ruleName];

    switch (typeof ruleValue) {
      case "object":
        // @ts-ignore
        // if custom message is provided
        validator = validator[ruleName](ruleValue.value, ruleValue.message);
        break;

      case "string":
        // @ts-ignore
        validator = validator[ruleName](ruleValue);
        break;

      case "number":
        // @ts-ignore
        validator = validator[ruleName](
          ruleValue,
          tErrors(`${ERRORS_MAPPING[ruleName]}`, {
            field: fieldName,
            [ruleName]: ruleValue,
          }),
        );
        break;

      case "boolean":
      default:
        // @ts-ignore
        validator = validator[ruleName](
          tErrors(`${ERRORS_MAPPING[ruleName]}`, {field: fieldName}),
        );
        break;
    }
  }

  return validator;
};

export default useStringValidations;
