import styled from "@emotion/styled";
interface Props {
  fontSize?: string;
  verticalAlign?: string;
  marginTop?: string;
  marginBottom?: string;
}
const GoldTitle = styled.div<Props>`
  font-size: ${({fontSize}) => (fontSize ? fontSize : "1rem")};
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: 0.53px;
  margin-bottom: ${({marginBottom}) =>
    marginBottom ? marginBottom : "1rem"};
  margin-top: ${({marginTop}) => marginTop};
  color: #ce9f29;
  align-items: ${({verticalAlign}) => verticalAlign};
  display: flex;
  justify-content: space-between;
`;

export default GoldTitle;
