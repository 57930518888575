import React, {useMemo, useState, MouseEvent, useEffect} from "react";
import {Box, BoxProps, TextFieldProps, Popover, Divider, Stack, IconButton} from "@mui/material";
import {IGButton, IGTextField} from "../index";
import {LocalizationProvider, PickersDay, StaticDatePicker} from "@mui/x-date-pickers";

import {IGDatePickerProps} from "../IGDatePicker";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {CloseRounded, DateRangeRounded} from "@mui/icons-material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

type CustomTextFieldProps = {
  name: string
  touched?: boolean
} & TextFieldProps;

export interface Ranges<T> {
  text: string
  value: T[]
}

interface Props<T> {
  value: T[]
  onChange: (range: T[]) => void
  inputFormat?: string,
  separator?: string
  width?: BoxProps["width"],
  customRanges?: Ranges<T>[]
  extendDefaultRanges?: boolean
  clearable?: boolean
  onClear?: () => void,
}

export type IGDateRangePickerProps<T> = Props<T>
  & Omit<CustomTextFieldProps, "onChange">
  & Omit<IGDatePickerProps<T, T>, "value" | "onChange" | "label">;

function IGDateRangePicker<T = Date>({
  name,
  value,
  label,
  onClick,
  onChange,
  onClear,
  size = "small",
  customRanges,
  // inputFormat = "dd MMM, yyyy, E",
  inputFormat = "DD MMM, yyyy, ddd",
  separator = "⇋",
  width = 350,
  fullWidth = true,
  showToolbar = false,
  closeOnSelect = true,
  clearable = true,
  disableHighlightToday = true,
  extendDefaultRanges = false,
  placeholder="",
  ...props
}: IGDateRangePickerProps<T>) {

  const {
    moment,
    date,
    formatByString,
  } = new AdapterMoment();

  type IRange = T | null;

  const initialCalenderRanges: Ranges<moment.Moment>[] = [
    {
      text: "Today",
      value: [moment(), moment()],
    },
    {
      text: "Yesterday",
      value: [
        moment().subtract(1, "day"),
        moment().subtract(1, "day"),
      ],
    },
    {
      text: "This week",
      value: [
        moment().startOf("week"),
        moment().endOf("week"),
      ],
    },
    {
      text: "Last week",
      value: [
        moment().subtract(1, "weeks").startOf("week"),
        moment().subtract(1, "weeks").endOf("week"),
      ],
    },
    {
      text: "This month",
      value: [
        moment().startOf("month"),
        moment().endOf("month"),
      ],
    },
    {
      text: "Last month",
      value: [
        moment().subtract(1, "months").startOf("month"),
        moment().subtract(1, "months").endOf("month"),
      ],
    },
  ];

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [internalRange, setInternalRange] = useState<IRange[]>([...value]);
  const [touched, setTouched] = useState({
    start: false,
    end: false,
  });
  const [mouseOverDate, setMouseOverDate] = useState<moment.Moment | null>(
    null,
  );
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(-1);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleApply = () => {
    onChange(internalRange as T[]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setInternalRange(value);
    setTouched({...touched, start: false, end: false});
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-picker-popover" : undefined;

  const selectedDays = useMemo(() => {
    const dates = [];
    let start = moment(internalRange[0]).startOf("day");
    let end = moment(internalRange[1]);
    if (touched.start && !(touched.end)) {
      if (mouseOverDate) {
        if (mouseOverDate.isSameOrBefore(start)) {
          end = start.clone();
          start = mouseOverDate.clone().startOf("day");
        } else {
          end = mouseOverDate.clone();
        }
      }
    }
    while (start.isSameOrBefore(end)) {
      dates.push(start.toDate().toString());
      start.add(1, "days");
    }
    return dates;
  }, [internalRange, mouseOverDate, touched, moment]);

  const onCloseButtonClick = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (onClear) {
      onClear();
      return;
    }
    onChange([null, null] as unknown as T[]);
  };

  const isAnyDateSet = useMemo(() => {
    return internalRange.every(i => i !== null);
  }, [internalRange]);

  const textFieldValue = useMemo(() => {
    if (!(isAnyDateSet)) return "";

    const formattedRanges = internalRange.map((d, i) =>
      d ? formatByString(date(d) || moment(), inputFormat) : "",
    );
    return formattedRanges.join(`   ${separator}   `);
  }, [internalRange, inputFormat, separator]);

  const isChanged = useMemo(() => {
    return moment(value[0]).isSame(moment(internalRange[0]), "day")
    && moment(value[1]).isSame(moment(internalRange[1]), "day");
  }, [internalRange, value]);

  const calenderRanges = useMemo(() => {
    if (customRanges) {
      if (extendDefaultRanges) {
        return initialCalenderRanges.concat(
          customRanges.map(r => ({...r, value: r.value.map(v => moment(v))})),
        );
      }
      return customRanges;
    }
    return initialCalenderRanges;
  }, [customRanges, initialCalenderRanges, extendDefaultRanges]);

  useEffect(() => {
    setInternalRange(value);
  }, [value]);

  useEffect(() => {
    for (const [key, range] of Object.entries(calenderRanges)) {
      if (
        range.value[0].isSame(moment(internalRange[0]), "day")
        && range.value[1].isSame(moment(internalRange[1]), "day")
      ) {
        setSelectedRangeIndex(Number(key));
        return;
      }
    }
    setSelectedRangeIndex(-1);
  }, [internalRange, calenderRanges]);

  useEffect(() => {
    setTouched({
      start: false,
      end: false,
    });
  }, [open]);

  return (
    <Box width={fullWidth ? "100%" : width}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <IGTextField
          fullWidth={true}
          name={name}
          InputProps={{
            readOnly: true,
            endAdornment:
              <Stack flexDirection="row" sx={{position: "relative", right: "-12px"}}>
                {isAnyDateSet && clearable && (
                  <IconButton
                    size={size}
                    onClick={onCloseButtonClick}
                  >
                    <CloseRounded/>
                  </IconButton>
                )}
                <IconButton>
                  <DateRangeRounded/>
                </IconButton>
              </Stack>,
          }}
          label={label}
          value={textFieldValue}
          placeholder={placeholder}
          onClick={(e) => {
            handleClick(e);
            onClick && onClick(e);
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              {calenderRanges.length > 0 && (
                <>
                  <Box>
                    <Stack spacing={2} mt={2} direction="column">
                      {calenderRanges.map(({text, value}, index) => (
                        <IGButton
                          key={`range_${text}`}
                          variant={index === selectedRangeIndex ? "contained" : "text"}
                          onClick={() => {
                            setSelectedRangeIndex(index);
                            setInternalRange([
                              ...value.map(v => moment(v).toDate()),
                            ] as unknown as T[]);
                          }}
                        >
                          {text}
                        </IGButton>
                      ))}
                    </Stack>
                  </Box>
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{
                      marginLeft: 3,
                      marginRight: 2,
                    }}
                  />
                </>
              )}
              <Box>
                <StaticDatePicker<T>
                  showToolbar={showToolbar}
                  closeOnSelect={closeOnSelect}
                  value={internalRange[1]}
                  disableHighlightToday={disableHighlightToday}
                  displayStaticWrapperAs="desktop"
                  renderDay={(day, _selectedDays, pickersDayProps) => {
                    return <PickersDay
                      {...pickersDayProps}
                      selected={selectedDays.includes(
                        (day as unknown as Date).toString(),
                      )}
                      onMouseOver={(event) => {
                        const el = event.target as HTMLButtonElement;
                        const dateString = el.getAttribute("aria-label");
                        if (dateString) {
                          setMouseOverDate(moment(dateString));
                        }
                      }}
                      onMouseOut={() => setMouseOverDate(null)}
                    />;
                  }}
                  onChange={(date: T | null) => {
                    if (date) {
                      setSelectedRangeIndex(-1);
                      if (!(touched.start)) {
                        setTouched({...touched, start: true});
                        setInternalRange([date, date]);
                        return;
                      }
                      if (!(touched.end)) {
                        setTouched({...touched, start: false, end: false});
                        // if selected date is before first selected date then
                        if (moment(date).isBefore(moment(internalRange[0]))) {
                          const newInternalRange = [...internalRange];
                          newInternalRange[0] = date;
                          newInternalRange[1] = internalRange[0];
                          setInternalRange(newInternalRange);
                          return;
                        }
                        const newInternalRange = [...internalRange];
                        newInternalRange[1] = date;
                        setInternalRange(newInternalRange);
                        return;
                      }
                    }
                  }}
                  renderInput={(params) => <IGTextField name="start" value={textFieldValue} {...params}/>}
                  {...props}
                />
                <Box
                  display="flex"
                  px={3}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IGButton
                    variant="outlined"
                    sx={{mr: 2}}
                    disabled={isChanged}
                    onClick={() => setInternalRange([...value])}
                  >
                    Cancel
                  </IGButton>
                  <IGButton disabled={isChanged} onClick={handleApply}>
                    Apply
                  </IGButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Popover>
      </LocalizationProvider>
    </Box>
  );
}

export default IGDateRangePicker;