import React, {useCallback} from "react";
import styles from "../styles";
import getCustomerLocationCoordinates from "../../../../../../../api/business/getLocationCoordinate";

const CustomerLocationCheck = ({selectedPayload}) => {
  const classes = styles();

  const onClickHandler = useCallback(() => {
    (async () => {
      const response = await getCustomerLocationCoordinates({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        locationType: "CUSTOMER_LOCATION_FROM_LM_AFTER_GOLD_RELEASE",
      });
      if (response.statusCode === 200) {
        const location = response.payload.locationCoordinates;

        if (location === null) {
          alert("Location is null");
          return;
        }
        const locationArray = location.split(",");

        const lat = locationArray[0];
        const lng = locationArray[1];

        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lng},${lat}`,
        );
      }
    })();
  }, [selectedPayload.id]);

  return (
    <div className={classes.textContainer}>
      <div className={classes.text}>CUSTOMER LOCATION SHARED BY LM</div>
      <div onClick={onClickHandler} className={classes.link}>
        View Location
      </div>
    </div>
  );
};

export default CustomerLocationCheck;
