import React, {FC, useMemo} from "react";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {LoanModel} from "app/models/loan.model";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppSelector} from "app/store/hooks";
import {loanSelectors} from "app/store/loan";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";

interface AccountDetailsProps {
}

const AccountDetails: FC<AccountDetailsProps> = () => {

  const {t, tTableHeader} = useIGTranslation();
  const {loanId} = useParams();

  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLenderLoanDetails(state, Number(loanId)),
  );
  const loanInstallmentList = useAppSelector((state) =>
    loanSelectors.getLenderLoanInstalmentList(state, Number(loanId)),
  );
  const {getLoanDetails} = useAppSelector(loanSelectors.getLoading);

  const balanceInstallments = useMemo(() => {
    return loanInstallmentList.filter((el) => el.status === "PENDING")?.length || "0";
  }, [loanInstallmentList]);

  const tableCols: IGTableColsInterface<LoanModel> = [
    {
      headerName: "Total Sanctioned Amount",
      field: "disbursedLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("SCHEME"),
      field: "goldLoanSchemeName",
    },
    {
      headerName: t("CUSTOMER_PROFILE.BALANCE_TOTAL_INSTALLMENT"),
      field: "null",
      renderCell: () => <>
        {balanceInstallments}/{loanInstallmentList.length}
      </>,
    },
    {
      headerName: "Booking ROI (GL)",
      field: "goldLoanInterestRate",
      renderCell: ({value}) => `${value} %`,
    },
    {
      headerName: "Booking ROI (PL)",
      field: "personalLoanInterestRate",
      renderCell: ({value}) => `${value} %`,
    },
  ];

  return (
    <IGTable
      hideFooter
      loading={getLoanDetails}
      tableCols={tableCols}
      tableData={loanDetails?.igLoanId ? [loanDetails] : []}
      getRowId={row => row.igLoanId}
      components={{
        Header: (params) => (
          <Box {...params} p={2}>
            <Typography variant="h5">
              {tTableHeader("ACCOUNT_DETAILS")}
            </Typography>
          </Box>
        ),
      }}
      columnVisibilityModel={{
        personalLoanInterestRate: loanDetails?.isPersonalLoanTaken || false,
      }}
    />
  );
};

export default AccountDetails;