import {createAction} from "@reduxjs/toolkit";
import {LoanIdPayload} from "app/infra/services/api/customerProfile/types";
import {AddressResponse, GetBlacklistedGoogleCoordinates, GetGoogleCoorindatesFromAddress, UserIdPayload} from "./address.types";

const addressActionTypes = {
  GetAddressList: "[Address] Get Address List",
  GetAddressListSuccess: "[Address] Get Address List Success",
  GetAddressListFailure: "[Address] Get Address List Failure",
  GetLenderAddressList: "[Address] Get Lender Address List",
  GetLenderAddressListSuccess: "[Address] Get Lender Address List Success",
  GetLenderAddressListFailure: "[Address] Get Lender Address List Failure",
  GetGoogleCoordinatesFromAddress: "[Address] Get Google Coordinates From Address",
  GetBlacklistedGoogleCoordinates: "[Address] Get Blacklisted Google Coordinates",
};

const addressActions = {
  getAddressList: createAction<UserIdPayload>(addressActionTypes.GetAddressList),
  getAddressListSuccess: createAction<AddressResponse>(addressActionTypes.GetAddressListSuccess),
  getAddressListFailure: createAction<AddressResponse>(addressActionTypes.GetAddressListFailure),
  getLenderAddressList: createAction<LoanIdPayload>(addressActionTypes.GetLenderAddressList),
  getLenderAddressListSuccess: createAction<AddressResponse>(
    addressActionTypes.GetLenderAddressListSuccess,
  ),
  getLenderAddressListFailure: createAction<AddressResponse>(
    addressActionTypes.GetLenderAddressListFailure,
  ),
  getGoogleCoordinatesFromAddress: createAction<GetGoogleCoorindatesFromAddress>(
    addressActionTypes.GetGoogleCoordinatesFromAddress,
  ),
  getBlacklistedGoogleCoordinates: createAction<GetBlacklistedGoogleCoordinates>(
    addressActionTypes.GetBlacklistedGoogleCoordinates,
  ),
};

export default addressActions;

export {addressActionTypes};
