import {RootState} from "../store";

const featureFlagSelectors = {
  getFeatureFlagStatus: (
    state: RootState,
    moduleEnum: string,
    moduleFeatureEnum: string,
  ) => {
    return state.featureFlags.featureFlags[moduleEnum]?.[moduleFeatureEnum];
  },
  getLoading: (state: RootState) => state.featureFlags.loading,
  getError: (state: RootState) => state.featureFlags.error,
};

export default featureFlagSelectors;
