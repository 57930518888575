import styled from "@emotion/styled";

interface Props {
  height?: React.CSSProperties["height"]
}

const Container = styled.div<Props>`
  min-height: ${({height}) =>
    height === undefined ? "100%" : height};
  height: ${({height}) => height};

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Container;
