import styled from "@emotion/styled";

interface ItemProps {
  textAlign?: string;
}

const IconImgWrapper = styled.img`
  width: 110px;
  height: 35px;
`;

const Item = styled.span<ItemProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center
`;

const RedItem = styled.span<ItemProps>`
  color: #f44336;
  height: 100%;
  display: flex;
  align-items: center
`;

const HeaderItem = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.1px;
`;

const CustomChip = styled.span`
  width: 100px;
  background-color: #f44336;
  color: #ffff;
  text-align: center;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.1px;
  &::after{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent #f44336 transparent transparent;
  },
`;

const ChipContainer = styled.span<ItemProps>`
  height: 100%;
  display: flex;
  align-items: center
`;

export {
  IconImgWrapper,
  Item,
  RedItem,
  HeaderItem,
  CustomChip,
  ChipContainer,
};
