import React, {useEffect, useState, useRef, useCallback} from "react";

import PersonalLoanDetail from "./PersonalLoanDetails";
import isPersonalLoanTaken from "api/business/isPersonalLoanTaken";
import {CircularProgress} from "@mui/material";
import styles from "../styles";

const PersonalLoanDetails = ({
  selectedPayload,
  isBackMovement,
  isSameCreditManager,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const classes = styles();

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      isPersonalLoanTaken: null,
    },
    errorMessage: "",
  });
  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    try {
      const response = await isPersonalLoanTaken({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
      });

      if (response.statusCode === 200) {
        setStates({
          loading: false,
          error: false,
          errorMessage: "",
          payload: {
            isPersonalLoanTaken: response.payload.isPersonalLoanTaken,
          },
        });
      } else {
        setStates({
          error: true,
          loading: false,
          errorMessage: response.error.toString(),
          payload: {
            isPersonalLoanTaken: null,
          },
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went Wrong",
        loading: false,
        payload: {
          isPersonalLoanTaken: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  if (!states.payload.isPersonalLoanTaken) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>Personal Loan Not Taken</div>
      </div>
    );
  }

  return (
    <PersonalLoanDetail
      {...{
        selectedPayload,
        isBackMovement,
        isSameCreditManager,
        otherRequestTimer,
        getMileStoneOfCr,
      }}
    />
  );
};

export default PersonalLoanDetails;
