import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@mui/styles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {Col, Row} from "react-bootstrap";
import {
  LinearProgress,
  IconButton,
  Paper,
  TablePagination,
  TableRow,
  TableFooter,
  TableCell,
  TableHead,
  TableBody,
  Table,
} from "@mui/material";

import {openInNewTab} from "_metronic";
import axios from "_metronic/utils/axios";
import {API_ENDPOINTS} from "app/constants/constants";
import {useSnackBar} from "../../../store/hooks";

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
    maxHeight: "500px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function BannedUsers(props) {
  const classes = useStyles2();
  const {showError} = useSnackBar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState({
    filters: false,
  });

  const headers = ["User ID", "Mobile no", "Name", "Email", "State"];

  const valueKeys = ["id", "mobile", "name", "email", "state"];

  const counter = useRef(0);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 100));
  }

  const funcCounter = useRef(0);

  const fetchTableData = useCallback(
    (deviceId) => {
      if (funcCounter.current === 0) {
        funcCounter.current = 1;
        setLoading({...loading, filters: true});
        axios
          .request({
            method: API_ENDPOINTS.APP_CONFIG.GET_USERS_FOR_DEVICE.METHOD,
            url:
              `${API_ENDPOINTS.APP_CONFIG.GET_USERS_FOR_DEVICE.URL
              }?deviceId=${deviceId}`,
            data: null,
          })
          .then((response) => {
            if (response && response.data && response.data.result) {
              setLoading({
                ...loading,
                filters: false,
              });
              setRows(response.data.result);
              if (response.data.result.length < rowsPerPage) {
                setTotalRows(page * rowsPerPage + response.data.result.length);
              } else {
                setTotalRows((page + 1) * rowsPerPage + 1);
              }
            }
          })
          .catch((err) => {
            setLoading({...loading, filters: false});
            if (
              err &&
              err.response &&
              [401, 403, 422].includes(err.response.status)
            ) {
              showError(
                  err.response.data &&
                  err.response.data.error &&
                  err.response.data.error.message,
              );
            } else {
              showError("Something went wrong");
            }
          });
      }
    },
    [loading, page, rowsPerPage],
  );

  React.useEffect(() => {
    if (props.deviceId && counter.current === 0) {
      counter.current = 1;
      fetchTableData(props.deviceId);
    }
    return () => {};
  }, [fetchTableData, page, props.deviceId, rowsPerPage]);

  return (
    <Paper className={classes.root}>

      {/* HEADER */}
      {props.header ? (
        <Row>
          <Col className={classes.header}>
            BANNED USERS FOR DEVICE ID - {props.deviceId}
          </Col>
        </Row>
      ) : null}

      {/* TABLE */}
      {props.table ? (
        <div className={classes.tableWrapper}>
          {!loading.filters ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell align="center" key={header}>
                      {header}
                    </TableCell>
                  ))}
                  {props.actions ? (
                    <TableCell align="center" key="Actions">
                      Actions
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    {valueKeys.map((key) => (
                      <TableCell
                        align="center"
                        key={key}
                        style={{minWidth: "100px"}}
                      >
                        {key === "id" ? (
                          <span
                            style={{
                              color: "blue",
                              fontStyle: "italic underline",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              openInNewTab(`/digital-gold/users/user/${row.id}`)
                            }
                          >
                            <b>
                              <u>{row[key]}</u>
                            </b>
                          </span>
                        ) : (
                          row[key]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={10}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {"aria-label": "Rows per page"},
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <LinearProgress />
          )}
        </div>
      ) : null}
    </Paper>
  );
}
