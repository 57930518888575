import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";

interface IGKeyValueProps {
  label: string;
  value?: string | number;
  labelProps?: React.ComponentProps<typeof Typography>;
  valueProps?: React.ComponentProps<typeof Typography>;
  labelContainerProps?: React.ComponentProps<typeof Grid>;
  valueContainerProps?: React.ComponentProps<typeof Grid>;
}
const IGKeyValue: FC<IGKeyValueProps> = ({
  label,
  value,
  labelProps,
  valueProps,
  labelContainerProps,
  valueContainerProps,
}) => {
  return (
    <Grid container p={1}>
      <Grid item xs={4} {...labelContainerProps}>
        <Typography color="text.secondary" variant="body1" {...labelProps}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} alignItems="end" {...valueContainerProps}>
        <Typography
          variant="body1"
          textAlign="right"
          sx={{
            overflowWrap: "anywhere",
          }}
          {...valueProps}
        >
          {value ?? "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IGKeyValue;
