import {RootState} from "../store";

const internalUserSelectors = {
  getInternalUsers: (state: RootState) => {
    const internaluser = {...state.internalUser.internalUser};
    internaluser.userList = Object.keys(
      state.internalUser.internalUser.userList,
    ).map((userId) => state.internalUser.internalUser.userList[userId]);
    return internaluser;
  },
  getCities: (state: RootState) => state.internalUser.cityList,
  getRoles: (state: RootState) => state.internalUser.allRoles,
  getUserCities: (state: RootState) => state.internalUser.userCities,
  getUserRoles: (state: RootState) => state.internalUser.userRoles,
  getModal: (state: RootState) => state.internalUser.modal,
  getLoading: (state: RootState) => state.internalUser.loading,
  getError: (state: RootState) => state.internalUser.error,
  getUserTypes: (state: RootState) => state.internalUser.userTypes,
  getPagination: (state: RootState) =>
    state.internalUser.internalUser.pagination,
};
export default internalUserSelectors;
