import styled from "@emotion/styled";

const Title = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.13;
  width: 80%;
  letter-spacing: 0.53px;
  margin-bottom: 1rem; 
  display: flex;
  justify-content: space-between;
`;

export default Title;
