import styled from "@emotion/styled";
interface Props {
  color?: string;
}
const ZoomOutIconWrapper = styled.div<Props>`
  margin-left: 1rem;
  cursor: pointer;
  color: ${({color}) => color};
`;

export default ZoomOutIconWrapper;
