import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {ornamentsActionTypes} from "./ornaments.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  GetDisbursedJobOrnamentsPayload,
  OrnamentsResponse,
  CrIdPayload,
  UpdateOrnamentDetailsPayload,
  SelectedOrnamentPayload,
  DownloadOrnamentCsvPayload,
} from "./ornaments.types";
import {GoldApi} from "../../infra/services/api";
import ornamentsActions from "./ornaments.actions";
import {snackBarActions} from "../snackbar";

const {
  getDisbursedJobsOrnamentsListSuccess,
  getDisbursedJobsOrnamentsListFailure,
  getOrnamentsListSuccess,
  getOrnamentsListFailure,
  getOrnamentsFinenessConfigSuccess,
  getOrnamentsFinenessConfigFailure,
  getOrnamentsPacketSuccess,
  getOrnamentsPacketFailure,
  updateOrnamentDetailsSuccess,
  updateOrnamentDetailsFailure,
  getOrnamentsNamesListSuccess,
  getOrnamentsNamesListFailure,
  getSelectedOrnamentDetailsSuccess,
  getSelectedOrnamentDetailsFailure,
  downloadOrnamentsCsvSuccess,
  downloadOrnamentsCsvFailure,
  getOrnamentsNamesList,
  getOrnamentsList,
} = ornamentsActions;

function* generalErrorFlow(action: PayloadAction<OrnamentsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* generalSuccessFlow(action: PayloadAction<OrnamentsResponse>) {
  yield put(
    snackBarActions.open({
      message: "Successfully Updated Details for the given ornament",
      variant: "success",
    }),
  );
}

function* getOrnamentsListFlow(
  action: PayloadAction<GetDisbursedJobOrnamentsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getDisbursedJobsOrnamentsList,
    action.payload,
  );
  if (error) {
    yield put(getDisbursedJobsOrnamentsListFailure({response: null, error}));
  } else {
    yield put(getDisbursedJobsOrnamentsListSuccess({response, error: null}));
  }
}
function* getOrnamentsNamesFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getOrnamentsNamesList,
    action.payload,
  );
  if (error) {
    yield put(getOrnamentsNamesListFailure({response: null, error}));
  } else {
    yield put(getOrnamentsNamesListSuccess({response, error: null}));
  }
}

function* getSelectedOrnamentDetailsFlow(
  action: PayloadAction<SelectedOrnamentPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getSelectedOrnamentDetails,
    action.payload,
  );
  if (error) {
    yield put(getSelectedOrnamentDetailsFailure({response: null, error}));
  } else {
    yield put(getSelectedOrnamentDetailsSuccess({response, error: null}));
  }
}
function* getCMJobsOrnamentsListFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getOrnamentsList,
    action.payload,
  );
  if (error) {
    yield put(getOrnamentsListFailure({response: null, error}));
  } else {
    yield put(getOrnamentsListSuccess({response, error: null}));
  }
}

function* getOrnamentsFinenessConfigFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getOrnamentsFinenessConfig,
    action.payload,
  );
  if (error) {
    yield put(getOrnamentsFinenessConfigFailure({response: null, error}));
  } else {
    yield put(getOrnamentsFinenessConfigSuccess({response, error: null}));
  }
}

function* getOrnamentsPacketFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.getOrnamentsPacket,
    action.payload,
  );
  if (error) {
    yield put(getOrnamentsPacketFailure({response: null, error}));
  } else {
    yield put(getOrnamentsPacketSuccess({response, error: null}));
  }
}
function* updateOrnamentDetailsFlow(
  action: PayloadAction<{
    crId: number;
    payload: UpdateOrnamentDetailsPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");
  const {error, data} = yield call(
    api.ornaments.updateOrnamentDetails,
    action.payload.payload,
    action.payload.crId,
  );
  if (error) {
    yield put(updateOrnamentDetailsFailure({error, data: null}));
  } else {
    yield put(updateOrnamentDetailsSuccess({data, error: null}));
    yield put(
      getOrnamentsNamesList({
        crId: action.payload.crId,
      }),
    );
    yield put(
      getOrnamentsList({
        crId: action.payload.crId,
      }),
    );
  }
}

function* downloadOrnamentsCSVFlow(
  action: PayloadAction<DownloadOrnamentCsvPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.ornaments.downloadOrnamentsCSV,
    action.payload,
  );
  if (error) {
    yield put(downloadOrnamentsCsvFailure({response: null, error}));
  } else {
    yield put(downloadOrnamentsCsvSuccess({response, error: null}));
    if (response) {
      window.open(response);
    }
  }
}

export default function* ornamentsSaga() {
  yield takeLatest(
    ornamentsActionTypes.GetDisbursedJobsOrnamentsList,
    getOrnamentsListFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetDisbursedJobsOrnamentsListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsNamesList,
    getOrnamentsNamesFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetSelectedOrnamentDetails,
    getSelectedOrnamentDetailsFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsList,
    getCMJobsOrnamentsListFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsFinenessConfig,
    getOrnamentsFinenessConfigFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsPacket,
    getOrnamentsPacketFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.UpdateOrnamentDetails,
    updateOrnamentDetailsFlow,
  );

  yield takeLatest(
    ornamentsActionTypes.DownloadOrnamentsCsv,
    downloadOrnamentsCSVFlow,
  );

  yield takeLatest(
    ornamentsActionTypes.DownloadOrnamentsCsvFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    ornamentsActionTypes.UpdateOrnamentDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.UpdateOrnamentDetailsSuccess,
    generalSuccessFlow,
  );

  yield takeLatest(
    ornamentsActionTypes.GetSelectedOrnamentDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsNamesListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsFinenessConfigFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    ornamentsActionTypes.GetOrnamentsPacketFailure,
    generalErrorFlow,
  );
}
