import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/business/v2/cr/:crId/lender-document",
  METHOD: "GET",
};

export const getLenderDocuments = async ({
  crId,
  loanType,
}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        loanType,
      },
    },
  });
  return response;
};

export default getLenderDocuments;
