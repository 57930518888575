import {IconButton, Typography, Box} from "@mui/material";
import React from "react";
import AddressContainer from "../styles/AddressContainer";
import IconWrapper from "../styles/IconWrapper";
import PiramalAddress from "../styles/PiramalAddress";
import GoldTitle from "../styles/GoldTitle";
import PiramalDocContainer from "../styles/PiramalDocContainer";
import Title from "../../styles/Title";
import {EditOutlined, ZoomOutMap} from "@mui/icons-material";
import ZoomOutIconWrapper from "../styles/ZoomOutIcon";
import DetailWrapper from "../styles/DetailWrapper";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import AddressWrapper from "../styles/AddressWrapper";
import SubTitle from "../../styles/SubTitle";
import TitleContainer from "../styles/TitleContainer";
import ZoomOutWrapper from "../styles/ZoomOutWrapper";
import IGLink from "app/components/Link";

const CustomerDetails = ({
  lenderDetails,
  setOpenNameModal,
  fullName = "",
  personalDetail,
  residentialAddress,
  onLocationClickHandler,
  customerImage,
  setOpenCustomerImageModal,
  customerHomeImage,
  setOpenCustomerHomeImageModal,
  partnerLeadSource,
  permanentAddress,
}) => {
  const {addressLine1, addressLine2, city, pincode, state, ownershipType} =
    permanentAddress ?? {};
  return (
    <>
      <GoldTitle
        verticalAlign="center"
        fontSize="1.2rem"
        marginBottom="0.5rem"
        marginTop="0.5rem"
      >
        Details Provided by Customer
        <IconWrapper src={lenderDetails?.icon} alt="bank-icon" />
      </GoldTitle>

      <PiramalAddress>
        <AddressContainer>
          <PiramalDocContainer>
            <SubTitle>Indiagold User Id</SubTitle>
            <Title>{personalDetail?.loanUserId || "N/A"}</Title>
          </PiramalDocContainer>
          <PiramalDocContainer>
            <SubTitle>Name</SubTitle>
            <Title style={{justifyContent: "left"}}>
              {fullName}
              <IconButton
                onClick={() => setOpenNameModal(true)}
                style={{
                  padding: "0em",
                  paddingLeft: "0.3em",
                  paddingRight: "0.3em",
                }}
              >
                <EditOutlined style={{color: "#ce9f29", height: ".8em"}} />
              </IconButton>
            </Title>
          </PiramalDocContainer>
          <PiramalDocContainer>
            <SubTitle>DOB</SubTitle>
            <Title>{personalDetail?.dob || "N/A"}</Title>
          </PiramalDocContainer>
          <PiramalDocContainer>
            <SubTitle>Age</SubTitle>
            <Title>
              {personalDetail?.ageInYearsMonthDaysFromDOB ? (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "1rem",
                    color:
                      personalDetail.ageInYearsMonthDaysFromDOB >= "70"
                        ? "red"
                        : "green",
                  }}
                >
                  {personalDetail.ageInYearsMonthDaysFromDOB}
                </Typography>
              ) : (
                <>
                  <Typography>{"NA"}</Typography>
                </>
              )}
            </Title>
          </PiramalDocContainer>
          <PiramalDocContainer>
            <SubTitle>GENDER</SubTitle>
            <Title>{personalDetail?.gender || "N/A"}</Title>
          </PiramalDocContainer>
          <PiramalDocContainer>
            <SubTitle>FATHER NAME</SubTitle>
            <Title>{personalDetail?.fatherName || "N/A"}</Title>
          </PiramalDocContainer>
        </AddressContainer>

        <Box display="flex" gap={2} justifyContent="space-between">
          <AddressContainer>
            <SubTitle>Residential Address</SubTitle>
            <AddressWrapper>
              <TitleContainer>
                <Title>{residentialAddress?.addressLine1}</Title>
              </TitleContainer>
              {residentialAddress?.addressLine2 && (
                <TitleContainer>
                  <Title>{residentialAddress?.addressLine2}</Title>
                </TitleContainer>
              )}
              {residentialAddress?.city && (
                <TitleContainer>
                  <Title>{residentialAddress?.city}</Title>
                </TitleContainer>
              )}
              <TitleContainer>
                <Title>{`${residentialAddress?.state} ${residentialAddress?.pincode}`}</Title>
              </TitleContainer>

              {residentialAddress?.ownershipType && (
                <TitleContainer>
                  <Title>{residentialAddress?.ownershipType}</Title>
                </TitleContainer>
              )}

              <IGLink onClick={onLocationClickHandler}>Map Link</IGLink>
              <AddressContainer>
                <AddressWrapper>
                  <SubTitle>Lead Source</SubTitle>
                  <Title>{partnerLeadSource || "NA"}</Title>
                </AddressWrapper>
              </AddressContainer>
            </AddressWrapper>
          </AddressContainer>
          <AddressContainer>
            <SubTitle>Permanent Address</SubTitle>
            <AddressWrapper>
              <TitleContainer>
                <Title>{addressLine1}</Title>
              </TitleContainer>
              {addressLine2 && (
                <TitleContainer>
                  <Title>{addressLine2}</Title>
                </TitleContainer>
              )}
              {city && (
                <TitleContainer>
                  <Title>{city}</Title>
                </TitleContainer>
              )}
              <TitleContainer>
                <Title>{`${state} ${pincode}`}</Title>
              </TitleContainer>

              {ownershipType && (
                <TitleContainer>
                  <Title>{ownershipType}</Title>
                </TitleContainer>
              )}
            </AddressWrapper>
          </AddressContainer>
        </Box>

        <AddressContainer>
          <ZoomOutWrapper>
            <SubTitle>
              Customer Photo
              <ZoomOutIconWrapper color="#ce9f29">
                <ZoomOutMap onClick={() => setOpenCustomerImageModal(true)} />
              </ZoomOutIconWrapper>
            </SubTitle>
          </ZoomOutWrapper>
          <DetailWrapper marginLeft={true}>
            <DocumentImageWrapper>
              <DocIconWrapper
                src={customerImage?.fileUrl}
                alt="customerImage"
                onClick={() => setOpenCustomerImageModal(true)}
              />
            </DocumentImageWrapper>
          </DetailWrapper>
        </AddressContainer>

        {customerHomeImage && (
          <AddressContainer>
            <ZoomOutWrapper>
              <SubTitle>
                Customer Home Photo
                <ZoomOutIconWrapper color="#ce9f29">
                  <ZoomOutMap
                    onClick={() => setOpenCustomerHomeImageModal(true)}
                  />
                </ZoomOutIconWrapper>
              </SubTitle>
            </ZoomOutWrapper>
            <DetailWrapper marginLeft={true}>
              <DocumentImageWrapper>
                <DocIconWrapper
                  src={customerHomeImage?.fileUrl}
                  alt="customerHomeImage"
                  onClick={() => setOpenCustomerHomeImageModal(true)}
                />
              </DocumentImageWrapper>
            </DetailWrapper>
          </AddressContainer>
        )}
      </PiramalAddress>
    </>
  );
};

export default CustomerDetails;
