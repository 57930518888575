import {IGButton, IGDrawer} from "app/components";
import React, {useState} from "react";
import {jsPDF} from "jspdf";
import customerProfileApi from "app/infra/services/api/customerProfile/customerProfile.api";
import {Box, Typography} from "@mui/material";
import {useSnackBar} from "app/store/hooks";
import "./styles.css";
import {getCurrentTime} from "_metronic/utils/moment";
import IgLogo from "./indiagold_logo.png";

const GenerateSoa = ({goldLoanIgId}: { goldLoanIgId: number }) => {
  const {showError} = useSnackBar();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consumerData, setConsumerData] = useState<{
    customerName: string;
    email: string;
    address: string;
    mobile: string;
    pincode: string;
    loanId: string;
    loanSchemeName: string;
    bookingRate: string;
    loanStatus: string;
    loanROI: string;
    rebateROI: string;
    principalOutstanding: string;
    loanTenure: string;
    agentCustomerSupportNumber: string;
    partPaymentReschedulingList: {
      effectiveDate: string;
      transactionDate: string;
      transaction: string;
      transactionNarration: string;
    }[];
    loanStatementPeriod: string;
    transactionList: {
      date: string;
      transactionType: string;
      debitAmount: string;
      creditAmount: string;
      closingBalance: string;
      drCr: string;
    }[];
  }>({
    customerName: "",
    email: "",
    address: "",
    mobile: "",
    pincode: "",
    loanId: "",
    loanSchemeName: "",
    bookingRate: "",
    loanStatus: "",
    loanROI: "",
    principalOutstanding: "",
    loanTenure: "",
    partPaymentReschedulingList: [],
    loanStatementPeriod: "",
    agentCustomerSupportNumber: "",
    transactionList: [],
    rebateROI: "",
  });

  const {
    customerName,
    email,
    address,
    mobile,
    pincode,
    loanId,
    loanSchemeName,
    bookingRate,
    loanStatus,
    loanROI,
    rebateROI,
    principalOutstanding,
    loanTenure,
    partPaymentReschedulingList,
    loanStatementPeriod,
    transactionList,
    agentCustomerSupportNumber,
  } = consumerData ?? {};

  const fetchConsumerSoaData = async () => {
    setLoading(true);
    const {
      error,
      response,
    } = await customerProfileApi.getConsumerSoaDataForPDF({
      goldLoanIgId,
    });

    if (response) {
      setConsumerData(response);
      setOpen(true);
    }

    if (error) {
      showError(error.message || "Something Went Wrong");
    }

    setLoading(false);
  };

  const generatePdf = () => {
    setLoading(true);
    const doc = new jsPDF({
      unit: "px",
    });
    const soaElement = document.getElementById("soa-report");

    if (soaElement) {
      doc.html(soaElement, {
        callback: function(doc) {
          doc.save(`SOA_${loanId}_${getCurrentTime().valueOf()}.pdf`);
          setLoading(false);
        },
        margin: 15,
        html2canvas: {
          scale: 0.41,
        },
        autoPaging: "text",
      });
    }
  };

  return (
    <div>
      <Typography variant="h6">SOA Report</Typography>
      <IGButton
        color="golden"
        sx={{
          m: 1,
        }}
        onClick={() => {
          fetchConsumerSoaData();
        }}
        loading={loading}
      >
        Generate GL SOA
      </IGButton>
      <IGDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            p: 2,
            width: "80vw",
          }}
        >
          <IGButton loading={loading} onClick={generatePdf}>
            Download
          </IGButton>
          <div
            style={{
              overflow: "scroll",
            }}
            >
          <div
            id="soa-report"
            className="ritz grid-container"
            dir="ltr"
            style={{
              padding: "10px",
              width: "1000px",
            }}
          >
            <table className="waffle" cellSpacing="0" cellPadding="0">
              <thead></thead>
              <tbody>
                <tr
                  style={{
                    height: "65px",
                    border: "none",
                  }}
                >
                  <td className="s0" style={{border: "none"}}>
                    <div style={{width: "139px", height: "35px", padding: "1rem"}}>
                      <img
                        src={IgLogo}
                        style={{
                          width: "inherit",
                          height: "inherit",
                          objectFit: "scale-down",
                          objectPosition: "left bottom",
                        }}
                      />
                    </div>
                  </td>
                  <td style={{
                    textAlign: "right",
                    color: "rgb(0, 0, 0.5)",
                    fontWeight: "bold",
                  }}>
                    {getCurrentTime().format("DD/MM/YYYY HH:mm:ss")}
                  </td>
                </tr>
                <tr
                  className="left-border right-border"
                  style={{
                    marginTop: "3rem",
                    borderTop: "1px SOLID black",
                  }}
                >
                  <td className="label">Name of Customer</td>
                  <td className="value">{customerName}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Email</td>
                  <td className="value">{email}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Address</td>
                  <td className="value">{address}</td>
                </tr>
                <tr className="left-border right-border">
                <td className="label">Pincode</td>
                  <td className="value">{pincode}</td>
                  <td className="label">Country</td>
                  <td className="value">India</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Mobile Number</td>
                  <td className="value">{mobile}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Loan ID</td>
                  <td className="value">{loanId}</td>
                  <td className=" label">Loan Scheme Name</td>
                  <td className="value">{loanSchemeName}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className=" label">Borrower Name</td>
                  <td className="value">{customerName}</td>
                  <td className=" label">Booking Rate(pa)</td>
                  <td className="value">{bookingRate}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className=" label">Loan Status</td>
                  <td className="value">{loanStatus}</td>
                  {rebateROI ? (
                    <>
                      <td className=" label">Rebate Rate (pa)</td>
                      <td className="value">{rebateROI}</td>
                    </>
                  ) : (
                    <>
                    <td className=" label">Loan ROI (Current)</td>
                    <td className="value">{loanROI}</td>
                    </>
                  )}
                </tr>
                <tr className="left-border right-border">
                  <td className=" label">Principal Oustanding</td>
                  <td className="value">{principalOutstanding}</td>
                  <td className=" label">Loan Tenure</td>
                  <td className="value">{loanTenure}</td>
                </tr>
                {partPaymentReschedulingList.length ? (
                  <>
                    <tr
                      style={{
                        marginTop: "2rem",
                        borderTop: "1px solid black",
                      }}
                      className="left-border right-border"
                    >
                      <td className="s5 softmerge">
                        <div className="softmerge-inner">
                          Part Payment/Rescheduling
                        </div>
                      </td>
                    </tr>
                    <tr className="left-border right-border">
                      <td className="s8">S.No</td>
                      <td className="s8">Effective Date</td>
                      <td className="s8">Transaction Date</td>
                      <td className="s8">Transaction</td>
                      <td className="s9 softmerge">
                        <div className="softmerge-inner">
                          Transaction Narration
                        </div>
                      </td>
                    </tr>
                    {partPaymentReschedulingList.map(
                      (
                        {
                          effectiveDate,
                          transactionDate,
                          transaction,
                          transactionNarration,
                        },
                        index,
                      ) => {
                        return (
                          <tr className="left-border right-border bottom-border" key={index}>
                            <td className="s11">{index + 1}</td>
                            <td className="s11">{effectiveDate}</td>
                            <td className="s11">{transactionDate}</td>
                            <td className="s11">{transaction}</td>
                            <td className="s12" dir="ltr">
                              {transactionNarration}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </>
                ) : null}
                <tr
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  <td className="s14 softmerge">
                    <div className="softmerge-inner">
                      Loan Statement for period between {loanStatementPeriod}
                    </div>
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "1px solid black",
                  }}
                >
                  <td className="s18">Date</td>
                  <td className="s18">Transaction type</td>
                  <td className="s18">Debit</td>
                  <td className="s18">Credit</td>
                  <td className="s18">Closing Balance</td>
                  <td className="s18">DR/CR</td>
                </tr>
                {transactionList.map(
                  (
                    {
                      date,
                      transactionType,
                      debitAmount,
                      creditAmount,
                      closingBalance,
                      drCr,
                    },
                    index,
                  ) => {
                    return (
                      <tr
                        className="left-border right-border bottom-border"
                        key={index}
                      >
                        <td className="s12">{date}</td>
                        <td className="s12">{transactionType}</td>
                        <td className="s12">{debitAmount}</td>
                        <td className="s12">{creditAmount}</td>
                        <td className="s12">{closingBalance}</td>
                        <td className="s12">{drCr}</td>
                      </tr>
                    );
                  },
                )}
                <tr>
                  <td className="s19" colSpan={6}>
                    Please note that the closure amount calculation is based on
                    accrued interest, charges and principal outstanding on the
                    date your closure request is processed by our team
                  </td>
                </tr>
                <tr>
                  <td className="s19" colSpan={6}>
                    This is a system generated report. For any clarifications,
                    customers are requested to contact us within 7 days of the
                    receipt of this statement. indiagold reserves the right to
                    rectify any errors/discrepancies with due intimation to the
                    customer.
                  </td>
                </tr>
                <tr style={{marginTop: "2rem", marginBottom: "2rem"}}>
                  <td className="s20 softmerge">
                    <div
                      className="softmerge-inner"
                      style={{borderBottom: "1px dashed black"}}
                    ></div>
                  </td>
                </tr>
                <tr
                  className="left-border right-border"
                  style={{borderTop: "1px solid black"}}
                >
                  <td className="label">Office Address</td>
                  <td className="value">
                    5th Floor, Holly Hocks Pvt Ltd, Plot #64, Sector 44,
                    Gurgaon, Haryana, 122002
                  </td>
                  <td className="label">Phone Number</td>
                  <td className="value">{agentCustomerSupportNumber}</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Address Line 1</td>
                  <td className="value">5th Floor, Holly Hocks Pvt Ltd</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Address Line 2</td>
                  <td className="value">Plot #64, Sector 44</td>
                  <td className="label">Email</td>
                  <td className="value">hello@indiagold.co</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Address Line 3</td>
                  <td className="value">Gurgaon, Haryana, 122002</td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">Website</td>
                  <td className="value">https://www.indiagold.co</td>
                  <td className="label">Registered Address</td>
                  <td className="value">
                    5th Floor, Holly Hocks Pvt Ltd, Plot #64, Sector 44,
                    Gurgaon, Haryana, 122002
                  </td>
                </tr>
                <tr className="left-border right-border">
                  <td className="label">CIN</td>
                  <td className="value">U65999DL2020PTC361351</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>

        </Box>
      </IGDrawer>
    </div>
  );
};

export default GenerateSoa;
