import React from "react";

import PaymentDetails from "./PaymentDetails";

const TakeOverPayment = ({selectedPayload, otherRequestTimer}) => {
  return (
    <PaymentDetails
      otherRequestTimer={otherRequestTimer}
      selectedPayload={selectedPayload}
    />
  );
};

export default TakeOverPayment;
