import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";

import {GoldApi} from "../../infra/services/api";
import {snackBarActions} from "../snackbar";
import transactionsActions from "./transactions.actions";
import {transactionsActionTypes} from "./transactions.actions";
import {
  TransactionsResponse,
  CrIdPayload,
  GetAdjustmentRenewalRoiPayload,
} from "./transactions.types";
import {activeLoansActions} from "../activeLoans";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";

const {
  getTransactionsListSuccess,
  getTransactionsListFailure,
  createTransactionSuccess,
  createTransactionFailure,
  getTransactionsList,
  proceedAfterAdjustmentFailure,
  proceedAfterAdjustmentSuccess,
  createTransaction,
  showTransactionFlowSuccess,
  showTransactionFlowFailure,
  getAdjustmentRenewalRoiFailure,
  getAdjustmentRenewalRoiSuccess,
  shareOfferWithCustomerSuccess,
  shareOfferWithCustomerFailure,
} = transactionsActions;

function* generalErrorFlow(action: PayloadAction<TransactionsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* shareOfferCustomerSuccessFlow(
  action: PayloadAction<TransactionsResponse>,
) {
  yield put(
    snackBarActions.open({
      message: "Offer Shared Successfully!",
      variant: "success",
    }),
  );
}

function* getTransactionsListFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.getTransactionsList,
    action.payload,
  );
  if (error) {
    yield put(getTransactionsListFailure({response: null, error}));
  } else {
    yield put(getTransactionsListSuccess({response, error: null}));
  }
}
function* proceedAfterAdjustmentFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.proceedAfterAdjustment,
    action.payload,
  );
  if (error) {
    yield put(proceedAfterAdjustmentFailure({response: null, error}));
  } else {
    yield put(proceedAfterAdjustmentSuccess({response, error: null}));
    yield put(
      createTransaction({
        crId: action.payload.crId,
      }),
    );
  }
}

function* createTransactionFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.createTransaction,
    action.payload,
  );
  if (error) {
    yield put(createTransactionFailure({response: null, error}));
  } else {
    yield put(createTransactionSuccess({response, error: null}));
    yield put(
      getTransactionsList({
        crId: action.payload.crId,
      }),
    );
  }
}

function* showTransactionFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.showTransactionFlow,
    action.payload,
  );
  if (error) {
    yield put(showTransactionFlowFailure({response: null, error}));
  } else {
    yield put(showTransactionFlowSuccess({response, error: null}));
  }
}

function* getAdjustmentRenewalRoiFlow(
  action: PayloadAction<GetAdjustmentRenewalRoiPayload & RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.getAdjustmentRenewalRoi,
    action.payload,
  );
  if (error) {
    yield put(getAdjustmentRenewalRoiFailure({response: null, error}));
  } else {
    yield put(getAdjustmentRenewalRoiSuccess({response, error: null}));
  }
  console.log("Transaction Saga > getAdjustmentRenewalRoiFlow > response: ", action.payload.requestId);
  yield put(
    activeLoansActions.getLoanRenewalDetails({
      crId: action.payload.crId,
      requestId: action.payload.requestId,
    }),
  );
}

function* shareOfferWithCustomerFlow(action: PayloadAction<CrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.transactions.shareOfferWithCustomer,
    action.payload,
  );
  if (error) {
    yield put(shareOfferWithCustomerFailure({response: null, error}));
  } else {
    yield put(shareOfferWithCustomerSuccess({response, error: null}));
  }
}

export default function* transactionsSaga() {
  yield takeLatest(
    transactionsActionTypes.GetTransactionsList,
    getTransactionsListFlow,
  );
  yield takeLatest(
    transactionsActionTypes.GetTransactionsListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    transactionsActionTypes.CreateTransaction,
    createTransactionFlow,
  );
  yield takeLatest(
    transactionsActionTypes.CreateTransactionFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ProceedAfterAdjustment,
    proceedAfterAdjustmentFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ProceedAfterAdjustmentFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ShowTransactionFlow,
    showTransactionFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ShowTransactionFlowFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    transactionsActionTypes.GetAdjustmentRenewalRoi,
    getAdjustmentRenewalRoiFlow,
  );
  yield takeLatest(
    transactionsActionTypes.GetAdjustmentRenewalRoiFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    transactionsActionTypes.ShareOfferWithCustomer,
    shareOfferWithCustomerFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ShareOfferWithCustomerFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    transactionsActionTypes.ShareOfferWithCustomerSuccess,
    shareOfferCustomerSuccessFlow,
  );
}
