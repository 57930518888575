import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const getInitialData = async ({accessToken, crId}) => {
  try {
    const response = await axios.get(
      `${getApiUrl()}/admin/business/cr/${crId}/lead-source`,
      {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "application-json",
        },
      },
    );

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getInitialData;
