import React, {useCallback, useState} from "react";

import updateStatus from "api/business/updateStatus";
import {useAppDispatch, useSnackBar} from "app/store/hooks";
import {Typography, Box} from "@mui/material";
import {IGButton, IGDialog, IGTextField} from "app/components";
import {useIGTranslation} from "app/infra/plugins/i18n";

const ApproveApplication = ({
  selectedPayload,
  crJobId,
  setApproveApplication,
  approveApplication,
  getMileStoneOfCr,
  getNextJobApi,
  takeoverCustomerAge,
  jobType,
}) => {
  const snackbar = useSnackBar();
  const {t, tButton, tModalTitle} = useIGTranslation();
  const [message, setMessage] = useState("");
  const dispatch = useAppDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    setButtonLoading(true);
    try {
      const response = await updateStatus({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        crJobId,
        remark: message,
        status: "COMPLETED",
      });

      if (response.statusCode === 200) {
        snackbar.showSuccess("Status updated successfully");
        setApproveApplication(false);
        getMileStoneOfCr();
        getNextJobApi();

        // window.location.reload();
      } else {
        snackbar.showError(response.error.toString());
      }
    } catch (error) {
      snackbar.showError("Something went Wrong");
    }
    setButtonLoading(false);
  }, [
    crJobId,
    getMileStoneOfCr,
    getNextJobApi,
    message,
    selectedPayload.id,
    setApproveApplication,
    snackbar,
  ]);

  return (
    <>
      <IGDialog
        open={approveApplication}
        onClose={() => setApproveApplication(false)}
        title={tModalTitle("REASON_FOR_APPROVAL")}
        hideFooter
        fullWidth
      >
        <Box display="flex" flexDirection="column" gap="1rem">
          {/* rendering this block only in case of takeover kyc approval not in fresh */}
          {jobType === "CM_TAKEOVER_KYC_APPROVAL" && takeoverCustomerAge && (
            <>
              {takeoverCustomerAge > "70" ? (
                <>
                  <Typography variant="h6">
                    {t("ACTIVE_LOANS.CUSTOMER_AGE_DISCLAIMER")}
                  </Typography>
                  <Typography variant="subtitle-1">
                    {t("ACTIVE_LOANS.AGE_DISCLAIMER_SUBTITLE")}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <IGTextField
            placeholder="Reason"
            value={message}
            size="large"
            onChange={(event) => setMessage(event.target.value)}
          />
          <IGButton
            color="golden"
            onClick={onClickHandler}
            loading={buttonLoading}
          >
            {tButton("APPROVE")}
          </IGButton>
        </Box>
      </IGDialog>
    </>
  );
};

export default ApproveApplication;
