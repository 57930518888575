import {createAction} from "@reduxjs/toolkit";
import {
  GetFeatureFlagPayload,
  GetSubMenuPayload,
  LayoutResponse,
} from "../../infra/services/api/layout/types";

export const LayoutActionTypes = {
  GetSubMenu: "[Layout] Get SubMenu",
  GetSubMenuSuccess: "[Layout] Get SubMenu Success",
  GetSubMenuFailure: "[Layout] Get SubMenu Failure",
  GetLenderMenu: "[Layout] Get Lender Menu",
  GetLenderMenuSuccess: "[Layout] Get Lender Menu Success",
  GetLenderMenuFailure: "[Layout] Get Lender Menu Failure",
  GetFeatureFlags: "[Layout] Get Feature Flags",
  GetFeatureFlagsSuccess: "[Layout] Get Feature Flags Success",
  GetFeatureFlagsFailure: "[Layout] Get Feature Flags Failure",
};

export const layoutActions = {
  getSubMenu: createAction<GetSubMenuPayload>(LayoutActionTypes.GetSubMenu),
  getSubMenuSuccess: createAction<LayoutResponse>(
    LayoutActionTypes.GetSubMenuSuccess,
  ),
  getSubMenuFailure: createAction<LayoutResponse>(
    LayoutActionTypes.GetSubMenuFailure,
  ),
  getLenderMenu: createAction(LayoutActionTypes.GetLenderMenu),
  getLenderMenuSuccess: createAction<LayoutResponse>(
    LayoutActionTypes.GetLenderMenuSuccess,
  ),
  getLenderMenuFailure: createAction<LayoutResponse>(
    LayoutActionTypes.GetLenderMenuFailure,
  ),
  getFeatureFlags: createAction<GetFeatureFlagPayload>(
    LayoutActionTypes.GetFeatureFlags,
  ),
  getFeatureFlagsSuccess: createAction<LayoutResponse>(
    LayoutActionTypes.GetFeatureFlagsSuccess,
  ),
  getFeatureFlagsFailure: createAction<LayoutResponse>(
    LayoutActionTypes.GetFeatureFlagsFailure,
  ),
};
