import React, {FC} from "react";
import {Button, ButtonProps, CircularProgress, CircularProgressProps} from "@mui/material";
import "./index.scss";

interface IGButtonProps extends ButtonProps {
  loading?: boolean
  loadingProps?: CircularProgressProps & {
    position?: "start" | "end"
  }
}

const BUTTON_LOADER_SIZE = {
  small: 16,
  medium: 18,
  large: 20,
};

/**
 * IGButton component which uses mui 5 Button as base
 * @param children
 * @param startIcon
 * @param endIcon
 * @param loadingProps
 * @param loading
 * @param position
 * @param onClick
 * @param variant
 * @param props
 * @constructor
 */
const IGButton: FC<IGButtonProps> = ({
  children,
  className,
  startIcon = "",
  endIcon = "",
  loadingProps= {
    position: "end",
  },
  color = "primary",
  loading = false,
  onClick = (_) => {},
  disableElevation = true,
  variant = "contained",
  disabled = false,
  ...props
}) => {

  const Loader = () => {
    return (
      <CircularProgress
        size={BUTTON_LOADER_SIZE[props?.size || "medium"]}
        {...loadingProps}
      />
    );
  };

  return (
    <Button
      startIcon={loading && loadingProps?.position === "start" ? <Loader/> : startIcon}
      endIcon={loading && loadingProps?.position === "end" ? <Loader/> : endIcon}
      disabled={loading || disabled}
      onClick={onClick}
      variant={variant}
      color={color}
      disableElevation={disableElevation}
      sx={{
        cursor: loading || disabled ? "not-allowed" : "pointer",
      }}
      className={["ig-button", className].join(" ")}
      {...props}
    >
      {children}
    </Button>
  );
};
export default IGButton;
