import React from "react";
import styles from "./styles";

const Heading = ({header}) => {
  const classes = styles();

  return <div className={classes.container}>{header}</div>;
};

export default Heading;
