const {REACT_APP_DEV_URL, REACT_APP_PROD_URL} = process.env;

import {availableServers} from "app/store/auth/reducer";
import store from "app/store/store";

const getApiUrl = () => {
  const env = process.env.REACT_APP_ENV;
  const server = env === "development" ? (store?.getState().auth.server || localStorage.getItem("server") || "dev") : "api";

  if (server === "localhost") {
    return "http://localhost:8080";
  }

  if (!availableServers.includes(server)) {
    return server;
  }

  if (env === "development") {
    return `https://${server}.${REACT_APP_DEV_URL}`;
  } else if (env === "production") {
    return `https://${server}.${REACT_APP_PROD_URL}`;
  }
  return "http://localhost:3001";
};

export default getApiUrl;
