import {PayloadAction} from "@reduxjs/toolkit";
import {ClosureJobPayload, CrJobIdPayload, GetLMUserListPayload, ReassignAgentPayload, SendOtpPayload, UpdateCRJobStatusPayload, VerifyOtpPayload} from "./lenderClosureJob.types";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {lenderClosureJobActionTypes, lenderClosureJobActions} from ".";
import {snackBarActions} from "../snackbar";
import {ServiceRequestIdPayload} from "app/infra/services/api/rateChange/rateChange.types";
import {LoanIdPayload} from "../loan/loan.types";
import {LenderJobTypeEnum} from "app/enums/lenderPortal";

const {
  getClosureJobsFailure,
  getClosureJobsSuccess,
  getLmUserListFailure,
  getLmUserListSuccess,
  updateJobStatusFailure,
  updateJobStatusSuccess,
  unassignAgentFailure,
  unassignAgentSuccess,
  assignAgentFailure,
  assignAgentSuccess,
  reassignAgentFailure,
  reassignAgentSuccess,
  getLoanDetailsFailure,
  getLoanDetailsSuccess,
  getPaymentDetailsFailure,
  getPaymentDetailsSuccess,
  getClosureJobDetailsFailure,
  getClosureJobDetailsSuccess,
  getAgentListFailure,
  getAgentListSuccess,
  sendOtpFailure,
  sendOtpSuccess,
  verifyOtpFailure,
  verifyOtpSuccess,
  getHandoverJobDetailsFailure,
  getHandoverJobDetailsSuccess,
  getCityListFailure,
  getCityListSuccess,
} = lenderClosureJobActions;

function* getClosureJobsFlow(action: PayloadAction<ClosureJobPayload>) {
  const api: GoldApi = yield getContext("api");

  const cleanedPayload: Record<string, any> = Object.keys(
    action.payload,
  ).reduce((acc, key) => {
    if (
      action.payload[key as keyof ClosureJobPayload] !== undefined &&
      action.payload[key as keyof ClosureJobPayload] !== null &&
      !["jobTypeEnum", "searchType"].includes(key)
    ) {
      acc[key] = action.payload[key as keyof ClosureJobPayload];
    }
    return acc;
  }, {} as Record<string, any>);

  const {response, error} = yield call(
    action.payload.jobTypeEnum === LenderJobTypeEnum.LENDER_LOAN_CLOSURE
      ? api.lenderClosureJob.getClosureJobs
      : api.lenderClosureJob.getHandoverJobs,
    cleanedPayload,
  );

  if (!error) {
    yield put(getClosureJobsSuccess(response));
  } else {
    yield put(getClosureJobsFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getLMUserListFlow(action: PayloadAction<GetLMUserListPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getLmUserList,
    action.payload,
  );
  if (!error) {
    yield put(getLmUserListSuccess(response));
  } else {
    yield put(getLmUserListFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* updateCrJobStatusFlow(
  action: PayloadAction<UpdateCRJobStatusPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.updateJobStatus,
    action.payload,
  );
  if (!error) {
    yield put(updateJobStatusSuccess(response));
  } else {
    yield put(updateJobStatusFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* unassignJobFlow(action: PayloadAction<CrJobIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.unassignAgent,
    action.payload,
  );
  if (!error) {
    yield put(unassignAgentSuccess(response));
  }
  else {
    yield put(unassignAgentFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* assignJobFlow(action: PayloadAction<CrJobIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.assignAgent,
    action.payload,
  );
  if (!error) {
    yield put(assignAgentSuccess(response));
  }
  else {
    yield put(assignAgentFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* reassignJobFlow(action: PayloadAction<ReassignAgentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.reassignAgent,
    action.payload,
  );
  if (!error) {
    yield put(reassignAgentSuccess(response));
  }
  else {
    yield put(reassignAgentFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getLoanDetailsFlow(action: PayloadAction<ServiceRequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getLoanDetails,
    action.payload,
  );
  if (!error) {
    yield put(getLoanDetailsSuccess(response));
  }
  else {
    yield put(getLoanDetailsFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getPaymentDetailsFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getPaymentDetails,
    action.payload,
  );
  if (!error) {
    yield put(getPaymentDetailsSuccess(response));
  } else {
    yield put(getPaymentDetailsFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getClosureJobDetailsFlow(
  action: PayloadAction<CrJobIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getClosureJobDetails,
    action.payload,
  );
  if (!error) {
    yield put(getClosureJobDetailsSuccess(response));
  } else {
    yield put(getClosureJobDetailsFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getHandoverJobDetailsFlow(
  action: PayloadAction<CrJobIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getHandoverJobDetails,
    action.payload,
  );
  if (!error) {
    yield put(getHandoverJobDetailsSuccess(response));
  } else {
    yield put(getHandoverJobDetailsFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getAgentListFlow(action: PayloadAction<CrJobIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.getAgentList,
    action.payload,
  );
  if (!error) {
    yield put(getAgentListSuccess(response));
  } else {
    yield put(getAgentListFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* sendOtpFlow(action: PayloadAction<SendOtpPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.sendOtp,
    action.payload,
  );
  if (!error) {
    yield put(sendOtpSuccess(response));
  } else {
    yield put(sendOtpFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* verifyOtpFlow(action: PayloadAction<VerifyOtpPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderClosureJob.verifyOtp,
    action.payload,
  );
  if (!error) {
    yield put(verifyOtpSuccess(response));
  } else {
    yield put(verifyOtpFailure({error: error.message}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getCityListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.lenderClosureJob.getCityList);
  if (!error) {
    yield put(getCityListSuccess(response));
  } else {
    yield put(getCityListFailure({error}));
  }
}

export default function* lenderClosureJobSaga() {
  yield takeLatest(
    lenderClosureJobActionTypes.GetClosureJobs,
    getClosureJobsFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetLMUserList,
    getLMUserListFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.UpdateJobStatus,
    updateCrJobStatusFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.UnassignAgent,
    unassignJobFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.AssignAgent,
    assignJobFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.ReassignAgent,
    reassignJobFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetLoanDetails,
    getLoanDetailsFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetPaymentDetails,
    getPaymentDetailsFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetClosureJobDetails,
    getClosureJobDetailsFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetAgentList,
    getAgentListFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.SendOtp,
    sendOtpFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.VerifyOtp,
    verifyOtpFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetHandoverJobDetails,
    getHandoverJobDetailsFlow,
  );
  yield takeLatest(
    lenderClosureJobActionTypes.GetCityList,
    getCityListFlow,
  );
};