import React from "react";

import PersonalLoanTaken from "./PersonalLoanNotTaken";

const Application = ({
  selectedPayload,
  ltv,
  getMileStoneOfCr,
  isBackMovement,
  goldLoanApplicationNo,
  onModalHandler,
  requesting,
  isSameCreditManager,
}) => {
  return (
    <>
      <PersonalLoanTaken
        ltv={ltv}
        getMileStoneOfCr={getMileStoneOfCr}
        selectedPayload={selectedPayload}
        isBackMovement={isBackMovement}
        goldLoanApplicationNo={goldLoanApplicationNo}
        onModalHandler={onModalHandler}
        requesting={requesting}
        isSameCreditManager={isSameCreditManager}
      />
    </>
  );
};

export default Application;
