import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice} from "@reduxjs/toolkit";
import cifDetailsActions from "./cifValidation.actions";
import {CifDetailsInterface} from "app/models/loanRenewalRebook/cifDetails.model";

type LoadingErrorState = "getCifDetails" | "updateCifDetails";

export interface CifDetailsState {
  cifDetails: CifDetailsInterface[];
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: CifDetailsState = {
  cifDetails: [],
  loading: {
    getCifDetails: false,
    updateCifDetails: false,
  },

  errors: {
    getCifDetails: null,
    updateCifDetails: null,
  },
};

const cifDetailsSlice = createSlice({
  name: "cifDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder

      .addCase(cifDetailsActions.getCifDetails, (state) => {
        state.loading.getCifDetails = true;
        state.errors.getCifDetails = initialState.errors.getCifDetails;
      })
      .addCase(cifDetailsActions.getCifDetailsSuccess, (state, action) => {
        state.cifDetails = action.payload.response;
        state.loading.getCifDetails = false;
      })
      .addCase(cifDetailsActions.getCifDetailsFailure, (state, action) => {
        state.loading.getCifDetails = false;
        state.errors.getCifDetails = action.payload.error;
      }),
});

export default cifDetailsSlice.reducer;
