import {createAction} from "@reduxjs/toolkit";
import {
  CheckJumperRemovalStatusParams,
  InitiateJumperRemovalParams,
  PostAgentReplyPayload,
  UpdateMobileNumberPayload,
  ValidateJumperParams,
  GetCloseTicketQuestionsPayload,
  CloseFreshDeskTicketPayload,
} from "app/infra/services/api/serviceDesk/complaints/types";
import {RequestStatusCountDetails, SupportConversationDetails} from "./types";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {ErrorResponse, SuccessMessageResponse} from "app/typings/api/goldApi.types";

const complaintActionTypes = {
  ValidateJumper: "[Complaints] Validate Jumper",
  ValidateJumperSuccess: "[Complaints] Validate Jumper Success",
  ValidateJumperFailure: "[Complaints] Validate Jumper Failure",
  InitiateJumperRemoval: "[Complaints] Initiate Jumper Removal",
  InitiateJumperRemovalSuccess: "[Complaints] Initiate Jumper Removal Success",
  InitiateJumperRemovalFailure: "[Complaints] Initiate Jumper Removal Failure",
  RetryJumperRemoval: "[Complaints] Retry Jumper Removal",
  RetryJumperRemovalSuccess: "[Complaints] Retry Jumper Removal Success",
  RetryJumperRemovalFailure: "[Complaints] Retry Jumper Removal Failure",
  GetJumperRemovalStatus: "[Complaints] Get Jumper Removal Status",
  GetJumperRemovalStatusSuccess:
    "[Complaints] Get Jumper Removal Status Success",
  GetJumperRemovalStatusFailure:
    "[Complaints] Get Jumper Removal Status Failure",
  GetRequestStatusCount: "[Complaints] Get Request Status Count",
  GetRequestStatusCountSuccess: "[Complaints] Get Request Status Count Success",
  GetRequestStatusCountFailure: "[Complaints] Get Request Status Count Failure",
  GetCustomerAgentConversation: "[Complaints] Get Customer Agent Conversation",
  GetCustomerAgentConversationSuccess:
    "[Complaints] Get Customer Agent Conversation Success",
  GetCustomerAgentConversationFailure:
    "[Complaints] Get Customer Agent Conversation Failure",
  PostAgentReplyToConversation:
    "[Complaints] Post Agent Reply To Conversation",
  PostAgentReplyToConversationSuccess:
    "[Complaints] Post Agent Reply To Conversation Success",
  PostAgentReplyToConversationFailure:
    "[Complaints] Post Agent Reply To Conversation Failure",
  UpdateServiceRequestMobileNumber:
    "[Complaints] Update Service Request Mobile Number",
  UpdateServiceRequestMobileNumberSuccess:
    "[Complaints] Update Service Request Mobile Number Success",
  UpdateServiceRequestMobileNumberFailure:
    "[Complaints] Update Service Request Mobile Number Failure",
  GetCloseTicketQuestions: "[Complaints] Get Close Ticket Questions",
  GetCloseTicketQuestionsSuccess:
    "[Complaints] Get Close Ticket Questions Success",
  GetCloseTicketQuestionsFailure:
    "[Complaints] Get Close Ticket Questions Failure",
  CloseFreshDeskTicket: "[Complaints] Close Fresh Desk Ticket",
  CloseFreshDeskTicketSuccess: "[Complaints] Close Fresh Desk Ticket Success",
  CloseFreshDeskTicketFailure: "[Complaints] Close Fresh Desk Ticket Failure",
};

const complaintActions = {
  validateJumperRemoval: createAction<ValidateJumperParams>(
    complaintActionTypes.ValidateJumper,
  ),
  validateJumperRemovalSuccess: createAction<any>(
    complaintActionTypes.ValidateJumperSuccess,
  ),
  validateJumperRemovalFailure: createAction<any>(
    complaintActionTypes.ValidateJumperFailure,
  ),
  initiateJumperRemoval: createAction<InitiateJumperRemovalParams>(
    complaintActionTypes.InitiateJumperRemoval,
  ),
  initiateJumperRemovalSuccess: createAction<any>(
    complaintActionTypes.InitiateJumperRemovalSuccess,
  ),
  initiateJumperRemovalFailure: createAction<any>(
    complaintActionTypes.InitiateJumperRemovalFailure,
  ),
  retryJumperRemoval: createAction<ValidateJumperParams>(
    complaintActionTypes.RetryJumperRemoval,
  ),
  retryJumperRemovalSuccess: createAction<any>(
    complaintActionTypes.RetryJumperRemovalSuccess,
  ),
  retryJumperRemovalFailure: createAction<any>(
    complaintActionTypes.RetryJumperRemovalFailure,
  ),
  getJumperRemovalStatus: createAction<CheckJumperRemovalStatusParams>(
    complaintActionTypes.GetJumperRemovalStatus,
  ),
  getJumperRemovalStatusSuccess: createAction<any>(
    complaintActionTypes.GetJumperRemovalStatusSuccess,
  ),
  getJumperRemovalStatusFailure: createAction<any>(
    complaintActionTypes.GetJumperRemovalStatusFailure,
  ),
  getRequestStatusCount: createAction(
    complaintActionTypes.GetRequestStatusCount,
  ),
  getRequestStatusCountSuccess: createAction<RequestStatusCountDetails>(
    complaintActionTypes.GetRequestStatusCountSuccess,
  ),
  getRequestStatusCountFailure: createAction<ErrorResponse>(
    complaintActionTypes.GetRequestStatusCountFailure,
  ),
  getCustomerAgentConversation: createAction<RequestIdPayload>(
    complaintActionTypes.GetCustomerAgentConversation,
  ),
  getCustomerAgentConversationSuccess: createAction<
    SupportConversationDetails[]
  >(complaintActionTypes.GetCustomerAgentConversationSuccess),
  getCustomerAgentConversationFailure: createAction<ErrorResponse>(
    complaintActionTypes.GetCustomerAgentConversationFailure,
  ),
  postAgentReplyToConversation: createAction<PostAgentReplyPayload>(
    complaintActionTypes.PostAgentReplyToConversation,
  ),
  postAgentReplyToConversationSuccess: createAction<
    SupportConversationDetails[]
  >(complaintActionTypes.PostAgentReplyToConversationSuccess),
  postAgentReplyToConversationFailure: createAction<ErrorResponse>(
    complaintActionTypes.PostAgentReplyToConversationFailure,
  ),
  updateServiceRequestMobileNumber: createAction<UpdateMobileNumberPayload>(
    complaintActionTypes.UpdateServiceRequestMobileNumber,
  ),
  updateServiceRequestMobileNumberSuccess: createAction<SuccessMessageResponse>(
    complaintActionTypes.UpdateServiceRequestMobileNumberSuccess,
  ),
  updateServiceRequestMobileNumberFailure: createAction<ErrorResponse>(
    complaintActionTypes.UpdateServiceRequestMobileNumberFailure,
  ),
  getCloseTicketQuestions: createAction<GetCloseTicketQuestionsPayload>(
    complaintActionTypes.GetCloseTicketQuestions,
  ),
  getCloseTicketQuestionsSuccess: createAction<any>(
    complaintActionTypes.GetCloseTicketQuestionsSuccess,
  ),
  getCloseTicketQuestionsFailure: createAction<any>(
    complaintActionTypes.GetCloseTicketQuestionsFailure,
  ),
  closeFreshDeskTicket: createAction<CloseFreshDeskTicketPayload>(
    complaintActionTypes.CloseFreshDeskTicket,
  ),
  closeFreshDeskTicketSuccess: createAction<any>(
    complaintActionTypes.CloseFreshDeskTicketSuccess,
  ),
  closeFreshDeskTicketFailure: createAction<any>(
    complaintActionTypes.CloseFreshDeskTicketFailure,
  ),
};

export {complaintActionTypes};

export default complaintActions;
