import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {IGSelect} from "app/components";
import {useAppSelector} from "app/store/hooks";
import {OrnamentsSelectors} from "app/store/ornaments";
import {activeLoansSelectors} from "app/store/activeLoans";

interface Props {
  weightInfo: {
    makerGrossWeight?: number;
    makerNetWeight?: number;
    makerPurity: string;
    checkerGrossWeight?: number;
    checkerNetWeight?: number;
    checkerPurity: string;
    makerStoneWeight?: number;
    checkerStoneWeight?: number;
    makerImpurityWeight?: number;
    checkerImpurityWeight?: number;
    makerOtherImpurityWeight?: number;
    checkerOtherImpurityWeight?: number;
  };
  disableForm: boolean;
  selectedOrnamentId: number;
  handleChange: (e: any) => void;
  isLender: boolean;
}
const WeightInfoTable = ({
  weightInfo,
  disableForm,
  selectedOrnamentId,
  handleChange,
  isLender,
}: Props) => {
  const ornamentsFinenessConfigList = useAppSelector(
    OrnamentsSelectors.getOrnamentsFinenessConfigList,
  );
  const ornamentsNamesList = useAppSelector(
    OrnamentsSelectors.getOrnamentsNamesList,
  );
  const isOrnamentCheckerRequired = useAppSelector(
    activeLoansSelectors.getOrnamentCheckerFlag,
  );

  const {
    makerGrossWeight,
    makerNetWeight,
    makerPurity,
    checkerGrossWeight,
    checkerNetWeight,
    checkerPurity,
  } = weightInfo;

  const rows = [];

  if (isOrnamentCheckerRequired) {
    rows.push({
      name: "Checker",
      grossWeight: checkerGrossWeight,
      netWeight: checkerNetWeight,
      purity: checkerPurity,
    });
  }

  if (!isLender) {
    rows.unshift({
      name: "Maker",
      grossWeight: makerGrossWeight,
      netWeight: makerNetWeight,
      purity: makerPurity,
    });
  }

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        mt: 1,
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
      }}
    >
      <Table
        aria-label="Weight Info"
        sx={{
          "& th, td": {
            padding: "8px",
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              bgcolor: "#000",
              "& th": {
                color: "#fff",
              },
            }}
          >
            {!isLender && <TableCell></TableCell>}
            <TableCell>Gross Weight</TableCell>
            <TableCell>Net Weight</TableCell>
            <TableCell>Purity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{"&:last-child td, &:last-child th": {border: 0}}}
            >
              {!isLender && (
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
              )}
              <TableCell>{row.grossWeight}g</TableCell>
              <TableCell>{row.netWeight}g</TableCell>
              <TableCell>
                {row.name === "Checker" ? (
                  <>
                    {isOrnamentCheckerRequired ? (
                      <IGSelect<string>
                        name="newFinenessVal"
                        fullWidth={false}
                        value={row.purity}
                        onChange={handleChange}
                        disabled={
                          disableForm ||
                          ornamentsNamesList?.find(
                            (item) => item.id === selectedOrnamentId,
                          )?.isCheckedByCm
                        }
                        options={
                          ornamentsFinenessConfigList?.map((item) => ({
                            text: item.fineness,
                            value: item.fineness,
                          })) || []
                        }
                      />
                    ) : null}
                  </>
                ) : (
                  row.purity
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WeightInfoTable;
