import {call, fork, getContext, put, takeLatest} from "redux-saga/effects";
import {lmAvailabilityActions, lmAvailabilityActionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  BlockAgentTimePayload,
  DeleteTimeSlotPayload,
  GetAgentsPayload,
  GetLMAvailabilityPayload,
  GetLMsPayload,
  GetUnschedulViewPayload,
  LeadIdPayload,
  UpdateTimeSlotPayload,
} from "app/infra/services/api/scheduling/lmAvailability/types";
import {GoldApi} from "app/infra/services/api";
import {snackBarActions} from "../../snackbar";
import {AlgoResponse} from "../../../infra/services/api/scheduling/algo/types";
import {
  setEtaList,
  setLmError,
  setLmLoading,
  setOpenCrEtaList,
  setUnscheduledAgentReport,
  setUnscheduledClusterDetail,
  setUnscheduledNewView,
} from "./reducer";
import {GetOpenCrViewPayload} from "app/infra/services/api/scheduling/openCR/types";
import {
  GetClusterDetailPayload,
  GetUnscheduledAgentReportPayload,
} from "app/infra/services/api/scheduling/unscheduled/types";

const {
  getLMsSuccess,
  getLMsFailure,
  blockAgentTimeSuccess,
  blockAgentTimeFailure,
  getLMAvailabilitySuccess,
  getLMAvailabilityFailure,
  deleteTimeSlotSuccess,
  deleteTimeSlotFailure,
  updateTimeSlotSuccess,
  updateTimeSlotFailure,
} = lmAvailabilityActions;

function* getUnscheduledAgentReport(
  action: PayloadAction<GetUnscheduledAgentReportPayload>,
) {
  yield put(setLmLoading({key: "unscheduledAgentReport", value: true}));
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.unscheduled.getUnscheduleAgentReport,
    action.payload,
  );
  if (error) {
    yield put(setLmError({key: "unscheduledAgentReport", value: error}));
  } else {
    yield put(setUnscheduledAgentReport(response));
  }
}

function* getUnscheduledClusterDetail(
  action: PayloadAction<GetClusterDetailPayload>,
) {
  yield put(setLmLoading({key: "unscheduleClusterDetail", value: true}));
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.unscheduled.getUnscheduleClusterDetail,
    action.payload,
  );
  if (error) {
    yield put(setLmError({key: "unscheduleClusterDetail", value: error}));
  } else {
    yield put(setUnscheduledClusterDetail(response));
  }
}

function* getLMAvailabilityFlow(
  action: PayloadAction<GetLMAvailabilityPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {loanManagerAvailability, error} = yield call(
    api.lmAvailability.getLoanManagerAvailability,
    action.payload,
  );
  if (error) {
    yield put(getLMAvailabilityFailure({error, loanManagerAvailability: []}));
  } else {
    yield put(
      getLMAvailabilitySuccess({
        error: null,
        loanManagerAvailability,
      }),
    );
  }
}

function* getETAList(action: PayloadAction<GetUnschedulViewPayload>) {
  yield put(setLmLoading({key: "getETA", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lmAvailability.getScheduledLoanManagers,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
    yield put(setLmError({key: "getETA", value: error}));
    yield put(setEtaList([]));
  } else {
    // @ts-ignore
    yield fork(getLMAvailabilityFlow, {
      payload: {
        agentIdList: response.map((lm: any) => lm.lmUserId).join(","),
        date: action.payload.date,
      },
      type: lmAvailabilityActionTypes.GetLMAvailability,
    });
    yield put(setEtaList(response));
  }
  yield put(setLmLoading({key: "getETA", value: false}));
}

function* getOpenCrEta(action: PayloadAction<GetOpenCrViewPayload>) {
  yield put(setLmLoading({key: "getOpenCrEta", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lmAvailability.getOpenCrEta,
    action.payload,
  );
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
    yield put(setLmError({key: "getOpenCrEta", value: error}));
    yield put(setOpenCrEtaList([]));
  } else {
    yield fork(getLMAvailabilityFlow, {
      payload: {
        agentIdList: response.map((lm: any) => lm.lmUserId).join(","),
        date: action.payload.date,
      },
      type: lmAvailabilityActionTypes.GetLMAvailability,
    });
  }
  yield put(setOpenCrEtaList(response));
  yield put(setLmLoading({key: "getOpenCrEta", value: false}));
}

function* getUnScheduledView(action: PayloadAction<GetUnschedulViewPayload>) {
  yield put(setLmLoading({key: "unscheduleFeatureFlag", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response} = yield call(
    api.lmAvailability.getUnscheduledView,
    action.payload,
  );
  yield put(setUnscheduledNewView(response));
}

function* getLMsFlow(action: PayloadAction<GetLMsPayload>) {
  const api: GoldApi = yield getContext("api");

  const {loanManagers, error} = yield call(
    api.lmAvailability.getLoanManagers,
    action.payload,
  );
  if (error) {
    yield put(getLMsFailure({error, loanManagerList: null}));
  } else {
    yield put(getLMsSuccess({error, loanManagerList: loanManagers}));
  }
}

function* getAgentsFlow(action: PayloadAction<GetAgentsPayload>) {
  const api: GoldApi = yield getContext("api");

  const {loanManagers, error} = yield call(
    api.lmAvailability.getAgents,
    action.payload,
  );
  if (error) {
    yield put(getLMsFailure({error, loanManagerList: null}));
  } else {
    yield put(getLMsSuccess({error, loanManagerList: loanManagers}));
  }
}

function* blockAgentTimeFlow(action: PayloadAction<BlockAgentTimePayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.lmAvailability.blockAgentTime,
    action.payload,
  );
  if (error) {
    yield put(blockAgentTimeFailure({error, response}));
  } else {
    yield put(blockAgentTimeSuccess({error, response}));
  }
}

function* deleteTimeSlotFlow(action: PayloadAction<DeleteTimeSlotPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.lmAvailability.deleteTimeSlot,
    action.payload,
  );
  if (error) {
    yield put(deleteTimeSlotFailure({error, response: null}));
  } else {
    yield put(
      deleteTimeSlotSuccess({
        error: null,
        response: {
          ...response,
          lmScheduleId: action.payload.lmScheduleId,
        },
      }),
    );
  }
}

function* enableLeadManualAssignmentFlow(action: PayloadAction<LeadIdPayload>) {
  yield put(
    setLmLoading({key: "enableLeadManualAssignmentFlow", value: true}),
  );
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.lmAvailability.enableLeadManualAssignmentFlow,
    action.payload,
  );
  if (error) {
    yield put(
      setLmError({key: "enableLeadManualAssignmentFlow", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error,
        variant: "failure",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: response?.message || "Manual flow Enabled!",
        variant: "success",
      }),
    );
    yield put(setUnscheduledNewView(response));
  }
  yield put(
    setLmLoading({key: "enableLeadManualAssignmentFlow", value: false}),
  );
}

function* updateTimeSlotFlow(action: PayloadAction<UpdateTimeSlotPayload>) {
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.lmAvailability.updateTimeSlot,
    action.payload,
  );
  if (error) {
    yield put(updateTimeSlotFailure({error, response: null}));
  } else {
    yield put(
      updateTimeSlotSuccess({
        error: null,
        response: {
          ...response,
          ...action.payload,
        },
      }),
    );
  }
}

function* generalErrorFlow(action: PayloadAction<AlgoResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message,
      variant: "error",
    }),
  );
}

export default function* lmAvailabilitySaga() {
  yield takeLatest(lmAvailabilityActionTypes.GetLMs, getLMsFlow);
  yield takeLatest(
    lmAvailabilityActionTypes.GetUnscheduledAgentReport,
    getUnscheduledAgentReport,
  );
  yield takeLatest(
    lmAvailabilityActionTypes.GetUnscheduledClusterDetail,
    getUnscheduledClusterDetail,
  );
  yield takeLatest(lmAvailabilityActionTypes.GetAgents, getAgentsFlow);
  yield takeLatest(lmAvailabilityActionTypes.GetOpenCrETA, getOpenCrEta);

  yield takeLatest(
    lmAvailabilityActionTypes.BlockAgentTime,
    blockAgentTimeFlow,
  );
  yield takeLatest(
    lmAvailabilityActionTypes.BlockAgentTimeSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Agent time blocked successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    lmAvailabilityActionTypes.BlockAgentTimeFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lmAvailabilityActionTypes.GetLMAvailability,
    getLMAvailabilityFlow,
  );

  yield takeLatest(lmAvailabilityActionTypes.GetETA, getETAList);
  yield takeLatest(
    lmAvailabilityActionTypes.GetUnschedulView,
    getUnScheduledView,
  );

  yield takeLatest(
    lmAvailabilityActionTypes.DeleteTimeSlot,
    deleteTimeSlotFlow,
  );
  yield takeLatest(
    lmAvailabilityActionTypes.DeleteTimeSlotSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Agent time slot deleted successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    lmAvailabilityActionTypes.DeleteTimeSlotFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lmAvailabilityActionTypes.UpdateTimeSlot,
    updateTimeSlotFlow,
  );
  yield takeLatest(
    lmAvailabilityActionTypes.UpdateTimeSlotSuccess,
    function*() {
      yield put(
        snackBarActions.open({
          message: "Agent time slot updated successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    lmAvailabilityActionTypes.UpdateTimeSlotFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lmAvailabilityActionTypes.EnableLeadManualAssignment,
    enableLeadManualAssignmentFlow,
  );
}
