import React, {useState} from "react";
import postUpdateStatus from "api/accounts/postUpdateStatus";
import {useSnackBar} from "app/store/hooks";
import {IGButton, IGModal, IGTextField} from "app/components";
import {Grid} from "@mui/material";

const RemarkForm = ({
  crId,
  crJobId,
  feedbackFormOpen,
  setFeedbackFormOpen,
  setDrawer,
  getListOfAccountJobs,
}) => {
  const {showError, showSuccess} = useSnackBar();
  const [remark, setRemark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("authToken");

  const sendFeedback = () => {
    (async () => {
      try {
        if (crJobId) {
          setIsLoading(true);
          const {statusCode, error} = await postUpdateStatus({
            accessToken,
            remark,
            status: "FAILED",
            crId,
            crJobId,
          });
          setIsLoading(false);
          if (statusCode === 200) {
            showSuccess(error || "Remark successfully submitted.");
            setDrawer(false);
            getListOfAccountJobs();
            setFeedbackFormOpen(false);
          } else {
            showError(error || "Failed to update Status.");
          }
        } else {
          setIsLoading(false);
          showError("crJobId not found");
        }
      } catch (err) {
        setIsLoading(false);
        showError("Unable to send feedback.");
      }
    })();
  };

  return (
    <>
      <IGModal
        open={feedbackFormOpen}
        onClose={() => setFeedbackFormOpen(!feedbackFormOpen)}
        title="Update Feedback"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IGTextField
              multiline
              rows="10"
              cols="37"
              placeholder="Reason for rejection."
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <IGButton
              fullWidth
              color="golden"
              onClick={sendFeedback}
              disabled={remark.length === 0 || isLoading}
              loading={isLoading}
            >
              SEND FEEDBACK
            </IGButton>
          </Grid>
        </Grid>
      </IGModal>
    </>
  );
};

export default RemarkForm;
