import React, {useState} from "react";
import {AccessTimeRounded} from "@mui/icons-material";
import {TextFieldProps} from "@mui/material";
import {
  DesktopDatePickerProps,
  DesktopTimePicker,
  DesktopTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {IGTextField} from "../index";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

interface IGTimePickerProps<TInputDate, TDate = TInputDate>
  extends Optional<DesktopTimePickerProps<TInputDate, TDate>, "renderInput"> {
  name: TextFieldProps["name"]
  label: TextFieldProps["label"]
  value: TInputDate
  onChange: DesktopDatePickerProps<TInputDate, TDate>["onChange"]
  renderInput?: DesktopDatePickerProps<TInputDate, TDate>["renderInput"]
  size?: TextFieldProps["size"]
  variant?: TextFieldProps["variant"]
  error?: TextFieldProps["error"]
  touched?: boolean
  helperText?: TextFieldProps["helperText"]
  required?: boolean
}

function IGTimePicker<TInputDate = Date, TDate = TInputDate>({
  value,
  onChange,
  label,
  name,
  onOpen,
  onClose,
  renderInput,
  size = "small",
  showToolbar = true,
  closeOnSelect = true,
  InputProps = {
   fullWidth: true,
   endAdornment: <AccessTimeRounded/>,
  },
  error = false,
  touched = false,
  helperText = "",
  required = false,
  variant = "outlined",
  inputFormat = "hh:mm a",
  ampmInClock = true,
  ...rest
}: IGTimePickerProps<TInputDate, TDate>) {

  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopTimePicker
        showToolbar={showToolbar}
        closeOnSelect={closeOnSelect}
        open={pickerOpen}
        ampmInClock={ampmInClock}
        label={label || name}
        inputFormat={inputFormat}
        onChange={onChange}
        onOpen={() => {
          setPickerOpen(true);
          onOpen && onOpen();
        }}
        onClose={() => {
          setPickerOpen(false);
          onClose && onClose();
        }}
        value={value}
        InputProps={InputProps}
        renderInput={renderInput
          ? renderInput
          : (props) =>
            <IGTextField
              {...props}
              required={required}
              size={size}
              name={name || ""}
              onClick={(e) => {
                setPickerOpen(true);
                props.onClick && props.onClick(e);
              }}
              variant={variant}
              value={value}
              error={error}
              touched={touched}
              helperText={helperText}
            />
        }
        {...rest}
      />
    </LocalizationProvider>
  );
}

export default IGTimePicker;