import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/business/lender/cr/:crId/cif-detail",
  METHOD: "GET",
};

export const getCifDetails = async ({
  crId,
  lenderName,
}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        lenderName,
      },
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export default getCifDetails;
