// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {CSSProperties, useRef, useState} from "react";
import LeftIcon from "@mui/icons-material/RotateLeftOutlined";
import RightIcon from "@mui/icons-material/RotateRightOutlined";
import {IconButton} from "@mui/material";
import {ImageWrapper, ImageContainer, DocViewContainer, DocViewHeader} from "./styles";
import {Box} from "@mui/material";
import {Typography} from "@mui/material";

//hieght and width  are recommended to be equal if rotate
//hieght and width are supposed to be numeric if zoom
// src is compulsory

interface Props {
  zoom: boolean;
  rotate: boolean;
  height: string | number;
  width: string | number;
  src: string;
  imageTitle?: string;
  style?: CSSProperties;
}

const ImageComponent = ({
  zoom,
  rotate,
  height,
  width,
  src,
  style,
  imageTitle,
  ...restProps
}: Props) => {
  const [rotation, setRotation] = useState(0);
  const [isZoom, setZoom] = useState(false);
  const [XY, setXY] = useState({mouseX: 0, mouseY: 0});
  const [customzIndex, setCustomzIndex] = useState(1300);

  const rotateRight = () => setRotation(rotation >= 270 ? 0 : rotation + 90);

  const rotateLeft = () =>
    setRotation(rotation <= 0 ? rotation + 270 : rotation - 90);

  const imageRef = useRef<HTMLDivElement>() as React.MutableRefObject<
    HTMLInputElement
  >;

  const handleMouseMovement = (e: React.MouseEvent<HTMLElement>) => {
    if (imageRef.current !== null) {
      const {
        left: offsetLeft,
        top: offsetTop,
        width: actualWidth,
        height: actualHeight,
      } = imageRef.current.getBoundingClientRect();
      let x = ((e.clientX - offsetLeft) / actualWidth) * 100;
      let y = ((e.clientY - offsetTop) / actualHeight) * 100;
      if ((rotation / 90) % 2 === 1) {
        [x, y] = [y, x];
      }
      setXY({
        mouseX: x,
        mouseY: y,
      });
    }
  };
  const {mouseX, mouseY} = XY;

  return (
    <DocViewContainer customzIndex={customzIndex}>
      <DocViewHeader>
        <Typography variant="h6" color="textSecondary">{imageTitle}</Typography>
        {rotate ? (
          <Box>
            <IconButton onClick={rotateLeft}>
              <LeftIcon style={{color: "#ce9f29"}} />
            </IconButton>
            <IconButton onClick={rotateRight}>
              <RightIcon style={{color: "#ce9f29"}} />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}
      </DocViewHeader>

      <div className='d-flex justify-content-center'>
        <ImageContainer
          style={{
            transform: `rotate(${rotation}deg)`,
            height: height,
            width: width,
          }}
          ref={imageRef}
          // onMouseOver={() => setZoom(!!zoom)}
          onMouseOver={() => {setZoom(!!zoom); setCustomzIndex(1310);}}
          onMouseOut={() => {setZoom(false); setCustomzIndex(1300); } }
          onMouseMove={handleMouseMovement}

        >
          <ImageWrapper
            {...restProps}
            src={src}
            alt='image'
            style={{
              borderRadius: "11px",
              maxHeight: height,
              maxWidth: width,
              transform: isZoom ? "scale(3)" : "scale(1.0)",
              transformOrigin: `${mouseX}% ${mouseY}%`,
              ...style,
            }}
          />
        </ImageContainer>
      </div>
    </DocViewContainer>
  );
};

export default ImageComponent;
