import React, {memo, useCallback} from "react";
import styles from "./styles";
import {
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import {Edit as EditIcon, DeleteForeverOutlined} from "@mui/icons-material";
import styled from "@emotion/styled";
import {IGButton} from "app/components";

const ButtonWrapper = styled.div`
  cursor: pointer;
`;

/**
 * @param `title` is the heading of the table.
 * @param `tableHeaders` is an array of strings which will become the headers of the table.
 * @param `tableData` is an array of objects, each object is a row in the table.
 * @param `toggleText` it will appear near the name of the table and will be used as button.
 * @param `toggleAction` this is a function which will toogle the table.
 * @param `haveTotal` is `boolean` show if table have a total column.
 * @param `totalData` is the object and have same structure as `tableData` object except the first column(which is reserved for showing Total ->)
 * @param `enableEditing` is `boolean` allows editing a row. Need to pass in header.
 * @param `filterComponent` is `React.Node` component which is used to filter the table.
 * @param `isPaginated` is `boolean` indicate if the table is paginated or not.
 * @param `currentPage` is a `number` indicate current page if table is paginated.
 * @param `onClickNextHandler` is a 'callback` handle the next button click if the table is paginated.
 * @param `onClickPrevHandler` is a `callback` handles the prev button click if the table is paginated.
 * @param `enableLoading` is a `boolean` which indicate if loading should be enabled.
 * @param `loadingState` is a `boolean` which triggers the loading state.
 * @param `setOpenEditModal` is a `React.SetStateAction<boolean>` which when `true` opens a Modal for editing.
 * @param `enableDeletion` is boolean which add a delete button at the of the row. Need to pass `DELETE` in header to adjust.
 * @param `setRowId` is a `React.SetStateAction<boolean>` which set the id of the row which we try to delete.
 * @param `enableAdding` is a `boolean` enables new addition button at top.
 * @param `setAddEditModal` is a `React.SetStateAction<boolean>` callback which toggle on click on add button.
 * @param `setOpenDeleteModal` is a `React.SetStateAction<boolean>` callback which toggle on click on delete icon.
 */
const Tables = ({
  title,
  tableHeaders,
  tableData,
  toggleAction,
  toggleText,
  haveTotal,
  totalData,
  enableEditing,
  filterComponent,
  isPaginated,
  currentPage,
  onClickNextHandler,
  onClickPrevHandler,
  enableLoading,
  loadingState,
  setOpenEditModal,
  enableDeletion,
  setRowId,
  enableAdding,
  setAddEditModal,
  setOpenDeleteModal,
}) => {
  const classes = styles();

  const tableRows = useCallback(
    e => {
      const temp = [];
      let counter = 0;
      for (const k in e) {
        temp.push(
          <TableCell
            key={`table-cell-${  k  }-${  e[k]?.toString()  }${counter++}`}
            align="center"
            className={classes.tableCell}
          >
            {e[k]}
          </TableCell>,
        );
      }
      if (enableEditing) {
        temp.push(
          <TableCell
            key={`table-edit-button-${  counter++}`}
            align="center"
            className={classes.tableCell}
          >
            <ButtonWrapper
              onClick={() => {
                setOpenEditModal(true);
                setRowId(e.id);
              }}
            >
              <EditIcon />
            </ButtonWrapper>
          </TableCell>,
        );
      }
      if (enableDeletion) {
        temp.push(
          <TableCell
            key={`table-edit-button-${  counter++}`}
            align="center"
            className={classes.tableCell}
          >
            <ButtonWrapper
              onClick={() => {
                setOpenDeleteModal(true);
                setRowId(e.id);
              }}
            >
              <DeleteForeverOutlined />
            </ButtonWrapper>
          </TableCell>,
        );
      }

      return temp;
    },
    [
      classes.tableCell,
      enableEditing,
      setOpenEditModal,
      setRowId,
      enableDeletion,
      setOpenDeleteModal,
    ],
  );

  const getTotalRow = useCallback(() => {
    const temp = [];
    for (const k in totalData) {
      temp.push(
        <TableCell
          key={`table-total-${  k}`}
          align="center"
          className={classes.totalCell}
        >
          {totalData[k]}
        </TableCell>,
      );
    }

    return temp;
  }, [classes.totalCell, totalData]);

  return (
    <>
      <div className={`${classes.tableContainer  } w-100`}>
        <div className={classes.tableHeader}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{title}</div>
            {toggleText && (
              <div onClick={toggleAction} className={classes.toggleText}>
                {toggleText}
              </div>
            )}
            <div>
              {enableAdding && (
                <IGButton
                  color="golden"
                  onClick={setAddEditModal}
                >
                  Add New
                </IGButton>
              )}
            </div>
          </div>
          {filterComponent}
        </div>
        <div>
          {enableLoading && loadingState ? (
            <div className={classes.loaderContainer}>
              <CircularProgress variant="indeterminate" />
            </div>
          ) : !tableData ? (
            <div className={classes.emptyData}>No data available</div>
          ) : (
            <TableContainer>
              <Table aria-labelledby="table" size="medium" aria-label="table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((e, i) => (
                      <TableCell align="center" key={`table-head-${  i}`}>
                        {e}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData &&
                    tableData.map((e, i) => {
                      return (
                        <TableRow key={`table-row-${  i}`}>
                          {tableRows(e)}
                        </TableRow>
                      );
                    })}

                  {haveTotal && (
                    <TableRow className={classes.totalRow}>
                      {getTotalRow()}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {tableData && isPaginated && (
          <div className={classes.paginationButtonContiner}>
            <IGButton
              onClick={onClickPrevHandler}
              disabled={currentPage === 1}
            >
              Prev
            </IGButton>
            <div className={classes.currentPageNumber}>{currentPage}</div>
            <IGButton
              onClick={onClickNextHandler}
              disabled={tableData.length < 10}
            >
              Next
            </IGButton>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Tables);
