import {CityInterface} from "app/models/lead";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice} from "@reduxjs/toolkit";
import cityActions from "./city.actions";

type LoadingErrorState = "getCityList";

export interface CityState {
  cityList: CityInterface[]
  loading: Record<LoadingErrorState, boolean>
  errors: Record<LoadingErrorState, GoldApiError | null>
}

const initialState: CityState = {
  cityList: [],
  loading: {
    getCityList: false,
  },
  errors: {
    getCityList: null,
  },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers:
    builder => builder
      .addCase(cityActions.getCityList,
        (state) => {
          state.loading.getCityList = true;
          state.cityList = initialState.cityList;
          state.errors.getCityList = initialState.errors.getCityList;
        },
      )
      .addCase(cityActions.getCityListSuccess,
        (state, action) => {
          state.loading.getCityList = false;
          state.cityList = action.payload.response;
        },
      )
      .addCase(cityActions.getCityListFailure,
        (state, action) => {
          state.loading.getCityList = false;
          state.errors.getCityList = action.payload.error;
        },
      )
  ,
});

export default citySlice.reducer;