import {Box, Grid, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import OrnamentsAndPledgeCard from "./PledgeCard";
import DownloadLoanSummary from "./DownloadLoanSummary";
import Ornaments from "./Ornaments";
import {isNil} from "lodash";
import {IGButton} from "app/components";
import GenerateSoa from "./GenerateSOA";
import customerProfileApi from "app/infra/services/api/customerProfile/customerProfile.api";
import {useSnackBar} from "app/store/hooks";
import getCSV from "api/business/getCSV";
import GenerateNOC from "./GenerateNOC";

const Downloads = ({
  isLender,
  isGoldLoanTaken,
  goldLoanAccountNumber,
  goldLoanIgId,
  status,
  loanId,
  crId,
}: {
  isLender: boolean;
  isGoldLoanTaken: boolean;
  goldLoanAccountNumber: string;
  goldLoanIgId: number;
  status: string;
  loanId: string;
  crId: number;
}) => {
  const {showError} = useSnackBar();
  const [state, setState] = useState({
    loading: false,
    glSoaLink: null,
    plSoaLink: null,
  });

  const isNucleusLoan = useMemo(() => {
    return !isNil(goldLoanAccountNumber) &&
    (goldLoanAccountNumber.charAt(0) === "F" ||
      goldLoanAccountNumber.charAt(0) === "P");
  }, [
    goldLoanAccountNumber,
  ]);

  const fetchSoaReport = useCallback(async () => {
    if (!loanId) {
      showError("LoanId not available!");
      return;
    }
    setState((prevState: any) => ({
      ...prevState,
      loading: true,
    }));
    if (!loanId) {
      showError("LoanId not available!");
      return;
    }
    try {
      const {
        error: consumerSoaReportError,
        response: consumerSoaReportResponse,
      } = await customerProfileApi.getConsumerSoaReport({
        loanId: Number(loanId),
      });

      if (!consumerSoaReportError) {
        setState((prevState: any) => ({
          ...prevState,
          glSoaLink: consumerSoaReportResponse?.goldLoanSoaReportLink,
          plSoaLink: consumerSoaReportResponse?.personalLoanSoaReportLink,
        }));
      } else {
        showError(consumerSoaReportError?.message);
      }
    } catch (error) {
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, [loanId]);

  const viewSoaReport = (link: string) => {
    window.open(link);
  };

  const onClickCSVDownloadHandler = async () => {
    if (!crId) {
      showError("CRId not available!");
      return;
    }
    setState((prevState: any) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const response: any = await getCSV({
        accessToken: localStorage.getItem("authToken"),
        crId: Number(crId),
      });

      if (response && response.statusCode === 200) {
        window.open(response.payload);
      }
    } catch (error: any) {
      showError(error?.message || "Something went wrong");
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    if (isNucleusLoan) {
      fetchSoaReport();
    }
  }, [fetchSoaReport, isNucleusLoan]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OrnamentsAndPledgeCard />
          </Grid>

          {!isLender && isGoldLoanTaken && (
            <Grid item xs={6}>
              <DownloadLoanSummary />
            </Grid>
          )}

          <Grid item xs={6}>
            <Box mt={2}>
              <Typography variant="h6">Download CSV</Typography>

              <IGButton
                color="golden"
                sx={{m: 1}}
                loading={state.loading}
                onClick={onClickCSVDownloadHandler}
              >
                Download CSV
              </IGButton>
            </Box>
          </Grid>

          {!isLender && isGoldLoanTaken && (
            <Grid item xs={6}>
              <Ornaments />
            </Grid>
          )}

          {isNucleusLoan && !isNil(state.glSoaLink) && (
            <Grid item xs={12}>
              <Typography variant="h6"> SOA Report</Typography>
              <Box display="flex" gap={2}>
                <IGButton
                  onClick={() =>
                    state.glSoaLink && viewSoaReport(String(state.glSoaLink))
                  }
                  sx={{
                    m: 1,
                    width: "120px",
                  }}
                  color="golden"
                >
                  View GL Soa
                </IGButton>
                {!isNil(state.plSoaLink) && (
                  <>
                    <IGButton
                      onClick={() => viewSoaReport(String(state.plSoaLink))}
                      sx={{
                        m: 1,
                        width: "120px",
                      }}
                      color="golden"
                    >
                      View PL Soa
                    </IGButton>
                  </>
                )}
              </Box>
            </Grid>
          )}

          {!isNucleusLoan && isGoldLoanTaken && (
            <Grid item xs={6}>
              <GenerateSoa goldLoanIgId={goldLoanIgId} />
            </Grid>
          )}
          {status === "CLOSED" && (
            <Grid item xs={6}>
              <GenerateNOC />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Downloads;
