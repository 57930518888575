const API_EXCEPTIONS = {
  FWC_TOKEN_EXCEPTION: "FwcTokenException",
  UNAUTHORIZED: "Unauthorized",
  UNKNOWN_EXCEPTION: "UnknownException",
  INVALID_URL: "INVALID_URL",
};

const API_ENDPOINTS = {
  AUTH: {
    LOGIN: {
      URL: "login",
      METHOD: "POST",
    },
    REGISTER: {
      URL: "signup",
      METHOD: "POST",
    },
    REFRESH_TOKEN: {
      URL: "renew-access-token",
      METHOD: "POST",
    },
    CHANGE_PASSWORD: {
      URL: "change-password",
      METHOD: "POST",
    },
  },
  EMI: {
    DELIVERY_PARTNER_LIST: {
      URL: "/emi/delivery-partner-list",
      METHOD: "GET",
    },
    GET_ALL_EMI_ORDERS: {
      URL: "/emi/order",
      METHOD: "GET",
    },
    GET_ALL_EMI_OF_ORDER: {
      URL: "/emi/order/:emiOrderId/emi",
      METHOD: "GET",
    },
    DOWNLOAD_ORDER_SUMMARY: {
      URL: "/emi/download-order-summary",
      METHOD: "GET",
    },
    DOWNLOAD_CANCEL_SUMMARY: {
      URL: "/emi/download-cancel-summary",
      METHOD: "GET",
    },
    DOWNLOAD_CATALOG: {
      URL: "/emi/download-catalog",
      METHOD: "GET",
    },
    UPLOAD_ORDER_SUMMARY: {
      URL: "/emi/upload-catalog",
      METHOD: "POST",
    },
    GET_ORDER_INVOICE: {
      URL: "/emi/order/:emiOrderId/invoice",
      METHOD: "GET",
    },
    GET_ORDER_PERFORMA_INVOICE: {
      URL: "/emi/order/:emiOrderId/performa-invoice",
      METHOD: "GET",
    },
    GET_EMI_INVOICE: {
      URL: "/emi/order-emi/:emiId/invoice",
      METHOD: "GET",
    },
    CHANGE_ORDER_STATUS: {
      URL: "/emi/order/:emiOrderId/change-order-status",
      METHOD: "PUT",
    },
    GET_SINGLE_ORDER: {
      URL: "/emi/order/:emiOrderId",
      METHOD: "GET",
    },
    CANCEL_ORDER: {
      URL: "/emi/order/:emiOrderId/cancel",
      METHOD: "POST",
    },
    GET_ALL_EMI_PRODUCTS: {
      URL: "/emi/product",
      METHOD: "GET",
    },
    GET_ALL_PRODUCT_CATEGORIES: {
      URL: "/emi/product/category/list",
      METHOD: "GET",
    },
    GET_EMI_ORDER_STATS: {
      URL: "/emi/user/:userId/order-stats",
      METHOD: "POST",
    },
  },
  USER: {
    GET_ALL_USERS: {
      URL: "/user-list",
      METHOD: "GET",
    },
    CHANGE_USER_STATUS: {
      URL: "/user/:userId/change-status/:status",
      METHOD: "PUT",
    },
    GET_USER_DETAILS: {
      URL: "/user/:userId",
      METHOD: "GET",
    },
    GET_USER_VAULT: {
      URL: "/user/:userId/vault",
      METHOD: "GET",
    },
    GET_BULK_USER_VAULT: {
      URL: "/user/vault",
      METHOD: "POST",
    },
    GET_UPI_DETAILS: {
      URL: "/user/:userId/upi-detail",
      METHOD: "GET",
    },
    GET_BANK_DETAILS: {
      URL: "/user/:userId/bank-detail",
      METHOD: "GET",
    },
    GET_CARD_DETAILS: {
      URL: "/user/:userId/card-detail",
      METHOD: "GET",
    },
    GET_DEVICE_DETAILS: {
      URL: "/user/:userId/device-detail",
      METHOD: "GET",
    },
    GET_ADDRESS_DETAILS: {
      URL: "/user/:userId/address",
      METHOD: "GET",
    },
    GET_USER_PERMISSION: {
      URL: "/user/:userId/user-permission",
      METHOD: "GET",
    },
    GET_DEVICE_LIST: {
      URL: "/user/:userId/device-list",
      METHOD: "GET",
    },
    GET_PRICE: {
      URL: "price",
      METHOD: "GET",
    },
    CHANGE_SELL_STATUS: {
      URL: "/sell-status",
      METHOD: "PUT",
    },
    GET_SELL_BANNED: {
      URL: "/sell-ban",
      METHOD: "GET",
    },
    UPLOAD_GOLD_LOAN_FILE: {
      URL: "/gold-loan",
      METHOD: "POSt",
    },
    GET_USER_GIFT_ORDER_PARENT: {
      URL: "/gift/order/:orderId/sender",
      METHOD: "GET",
    },
  },
  INTERNAL_USER: {
    GET_ALL_INTERNAL_USERS: {
      URL: "/internal-user-list",
      METHOD: "GET",
    },
    GET_USERS_ROLES: {
      URL: "/internal-user/:userId/roles",
      METHOD: "GET",
    },
    GET_ALL_ROLES: {
      URL: "/internal-user/all-roles",
      METHOD: "GET",
    },
    UPDATE_USER_ROLES: {
      URL: "/internal-user/:userId/update-roles",
      METHOD: "PUT",
    },
    CREATE_USER: {
      URL: "/internal-user",
      METHOD: "POST",
    },
    GET_ALL_CITIES: {
      URL: "/internal-user/all-city",
      METHOD: "GET",
    },
  },
  PROMO: {
    GET_ALL_PROMOS: {
      URL: "/promo/campaign",
      METHOD: "GET",
    },
    GET_PROMO_RULES: {
      URL: "/promo/campaign/:promoCampaignId/rules",
      METHOD: "GET",
    },
    UPDATE_PROMO_STATUS: {
      URL: "/promo/campaign/:promoCampaignId",
      METHOD: "PUT",
    },
    CANCEL_GOLDBACK: {
      URL: "/dg/order/mark-reverted-as-failed",
      METHOD: "POST",
    },
    RETRY_GOLDBACK: {
      URL: "/promo/:orderId/retry-goldback",
      METHOD: "POST",
    },
  },
  DG: {
    GET_ALL_DG_ORDERS: {
      URL: "/dg/order",
      METHOD: "GET",
    },
    GET_ORDER_INVOICE: {
      URL: "/dg/order/:orderId/invoice",
      METHOD: "GET",
    },
    DOWNLOAD_ORDER_SUMMARY: {
      URL: "/dg/download-order-summary",
      METHOD: "GET",
    },
    GET_SINGLE_ORDER: {
      URL: "/dg/order/:orderId",
      METHOD: "GET",
    },
    GET_DG_ORDER_STATS: {
      URL: "/dg/user/:userId/order-stats",
      METHOD: "POST",
    },
  },
  PAYMENT: {
    GET_ALL_PAYMENTS: {
      URL: "/payment",
      METHOD: "GET",
    },
    GET_ALL_PAYOUTS: {
      URL: "/payment/payout",
      METHOD: "GET",
    },
    DOWNLOAD_PAYMENT_PAYOUT_SUMMARY: {
      URL: "payment/summary",
      METHOD: "GET",
    },
    DOWNLOAD_PAYMENT_SUMMARY: {
      URL: "payment/payment-summary",
      METHOD: "GET",
    },
    DOWNLOAD_PAYOUT_SUMMARY: {
      URL: "payment/payout-summary",
      METHOD: "GET",
    },
    GET_PAYMENT_FOR_REFERENCE: {
      URL: "payment/reference/:referenceId",
      METHOD: "GET",
    },
    GET_PAYOUT_FOR_REFERENCE: {
      URL: "payment/payout/reference/:referenceId",
      METHOD: "GET",
    },
    GET_BULK_PAYMENT_FOR_REFERENCE: {
      URL: "payment/bulk/reference-id",
      METHOD: "POST",
    },
    GET_BULK_PAYOUT_FOR_REFERENCE: {
      URL: "payment/payout/bulk/reference-id",
      METHOD: "POST",
    },
    GET_PAYMENT_FOR_ID: {
      URL: "payment/id/:id",
      METHOD: "GET",
    },
    GET_PAYOUT_FOR_ID: {
      URL: "payment/payout/id/:id",
      METHOD: "GET",
    },
  },
  PROMOTION: {
    GET_ALL_CAMPAIGNS: {
      URL: "/promo/gift-campaign",
      METHOD: "GET",
    },
    CREATE_CAMPAIGN: {
      URL: "/promo/gift-campaign",
      METHOD: "POST",
    },
    DISTRIBUTE_GIFT: {
      URL: "/promo/distribute-gift",
      METHOD: "POST",
    },
    GET_AFFILIATE_PARTNERS: {
      URL: "/promo/affiliate-partner",
      METHOD: "GET",
    },
    GET_PROMO_PARENT_ORDER_INFO: {
      URL: "/promo/:orderId/get-promo-parent",
      METHOD: "GET",
    },
  },
  SUPPORT: {
    GET_TICKET_CATEGORY_LIST: {
      URL: "/support/ticket/category/list",
      METHOD: "GET",
    },
  },
  ANALYTICS: {
    GET_METABASE_EMBED_LINK: {
      URL: "/analytics/metabase-embed-url",
      METHOD: "GET",
    },
    GET_ANALYTICS_TABS: {
      URL: "/analytics/tab-list",
      METHOD: "GET",
    },
  },
  REFERRAL: {
    GET_USER_ADVOCATE: {
      URL: "/referral/user/:userId/advocate",
      METHOD: "GET",
    },
    GET_USER_FRIENDS: {
      URL: "/referral/user/:userId/friend",
      METHOD: "GET",
    },
  },
  APP_CONFIG: {
    GET_ALL_DEEP_LINKS: {
      URL: "/app-config/deep-link",
      METHOD: "GET",
    },
    GET_ALL_DYNAMIC_LINKS: {
      URL: "/app-config/dynamic-link",
      METHOD: "GET",
    },
    CREATE_DYNAMIC_LINKS: {
      URL: "/app-config/dynamic-link",
      METHOD: "POST",
    },
    GET_BANNED_DEVICES: {
      URL: "/app-config/device-blacklist",
      METHOD: "GET",
    },
    BAN_DEVICE: {
      URL: "/app-config/device-blacklist",
      METHOD: "POST",
    },
    GET_USERS_FOR_DEVICE: {
      URL: "/app-config/device-user-list",
      METHOD: "GET",
    },
  },
};

export {API_ENDPOINTS, API_EXCEPTIONS};
