import React from "react";
import {Box, Link, List, ListItemText} from "@mui/material";

interface OrnamentsImagesPreviewProps {
  imagesList: {
    title?: string;
    url: string;
  }[];
}
const OrnamentsImagesPreview = ({imagesList}: OrnamentsImagesPreviewProps) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        {imagesList.slice(0, 3).map((item: any, index: number) => (
          <Box
            sx={{
              marginLeft: index === 0 ? 0 : -3,
            }}
          >
            <img
              style={{
                borderRadius: "38px",
                border: "1px solid rgba(0, 0, 0, 0.6)",
                boxShadow: "12px -1px 15px -5px rgba(0, 0, 0, 0.04)",
              }}
              src={item.url}
              width="40px"
              height="40px"
            />
          </Box>
        ))}
      </Box>
      <Box>
        <List>
          {imagesList.map((image) => (
            <ListItemText>
              <Link href={image.url} target="_blank">
                {image.title?.replaceAll("_", " ")}
              </Link>
            </ListItemText>
          ))}
        </List>
      </Box>
    </>
  );
};

export default OrnamentsImagesPreview;
