import {goldApiService} from "app/infra/plugins/axios";
import {
  UserTierConfigResources,
  FetchUserTierConfigProps,
  UpdateUserTierConfigProps,
} from "./types";

const USERTIER_CONFIG_ENDPOINTS: UserTierConfigResources = {
  FETCH_USERTIER_CONFIG: {
    URL: "admin/dg/user-tier-order-config",
    METHOD: "GET",
  },
  UPDATE_USERTIER_CONFIG: {
    URL: "admin/dg/user-tier-order-config/:id",
    METHOD: "PUT",
  },
};

const userTierConfigApi = {
  fetchUserTierConfig: async (payload: FetchUserTierConfigProps) => {
    const {response, error} = await goldApiService({
      resource: USERTIER_CONFIG_ENDPOINTS.FETCH_USERTIER_CONFIG,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error: error,
        userTierConfig: [],
      };
    }

    return {
      error: null,
      userTierConfig: response,
    };
  },

  updateUserTierConfig: async (
    id: number,
    payload: UpdateUserTierConfigProps,
  ) => {
    const {response, error} = await goldApiService({
      resource: USERTIER_CONFIG_ENDPOINTS.UPDATE_USERTIER_CONFIG,
      options: {
        data: {
          ...payload,
        },
        pathVars: {
          id,
        },
      },
    });

    if (error) {
      return {
        error: error,
        userTierConfig: [],
      };
    }

    return {
      error: null,
      userTierConfig: response,
    };
  },
};

export default userTierConfigApi;
export {USERTIER_CONFIG_ENDPOINTS};
