import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import SuccessOrFailureText from "../SuccessOrFailureText";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import OtherChargesModal from "./OtherChargesModal";
import {getValidTime} from "_metronic/utils/moment";
import {IGTable} from "app/components";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {loanSummaryActions, loanSummarySelectors} from "app/store/loanSummary";
import {IGTableColsInterface} from "app/components/IGTable";
import {LoanSummaryModel} from "app/models/loanSummary.model";
import {loanSelectors} from "app/store/loan";

const LoanSummary = () => {
  const {loanId} = useParams();
  const {t, tTableHeader} = useIGTranslation();
  const dispatch = useAppDispatch();
  const [openOtherChargesModal, setOpenOtherChargesModal] = useState(false);

  const loanSummary = useAppSelector((state) =>
    loanSummarySelectors.getLenderLoanSummary(state, Number(loanId)),
  );
  const loanInstallmentList = useAppSelector((state) =>
    loanSelectors.getLenderLoanInstalmentList(state, Number(loanId)),
  );
  const {getLoanSummaryDetails} = useAppSelector(
    loanSummarySelectors.getLoading,
  );

  const balanceInstallments = useMemo(() => {
    return loanInstallmentList.filter((el) => el.status === "PENDING")?.length || "0";
  }, [loanInstallmentList]);

  useEffect(() => {
    if (!loanId) return;
    if (getLoanSummaryDetails || Boolean(loanSummary)) return;
    dispatch(
      loanSummaryActions.getLenderLoanSummaryDetails({
        loanId: Number(loanId),
        getDetail: true,
      }),
    );
  }, [dispatch, loanId, loanSummary]);

  const tableCols: IGTableColsInterface<LoanSummaryModel> = [
    {
      field: "interestTillDate",
      headerName: t("CUSTOMER_PROFILE.INTEREST_TILL_DATE"),
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "nextInstalmentAmount",
      headerName: t("CUSTOMER_PROFILE.NEXT_INSTALMENT"),
      renderCell: ({value, row}) =>
        <>
          {numberWithCurrencyAndComma(value)}{" "}
          {row.nextInstalmentDate
            ? ` on ${getValidTime(row.nextInstalmentDate).format("DD/MM/YYYY")}`
            : ""
          }
        </>,
    },
    {
      field: "amountPaid",
      headerName: t("PAID"),
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "totalOverDueAmount",
      headerName: t("OVERDUE"),
      sortable: false,
      renderHeader: ({colDef}) => {
        if (loanSummary && loanSummary.overDueDay >= 30) {
          return (
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {colDef.headerName}
            </span>
          );
        }
        return (
          <span style={{fontWeight: 500}}>
            {colDef.headerName}
          </span>
        );
      },
      renderCell: ({value}) =>
        <>
          {
            SuccessOrFailureText({
              text: numberWithCurrencyAndComma(value),
              status: value < 0 ? "SUCCESS" : "FAIL",
            })
          }
        </>,
    },
    {
      field: "otherCharges",
      headerName: t("CUSTOMER_PROFILE.OTHER_CHARGES"),
      renderCell: ({value}) =>
        <Typography
          sx={{
            textDecoration: "underline",
            color: "gold",
            cursor: "pointer",
          }}
          onClick={() => setOpenOtherChargesModal(true)}
        >
          {numberWithCurrencyAndComma(value)}
        </Typography>,
    },
  ];

  return (
    <div>
      <IGTable
        hideFooter
        loading={getLoanSummaryDetails}
        tableCols={tableCols}
        tableData={loanSummary ? [loanSummary] : []}
        getRowId={row => row.igLoanId}
        components={{
          Header: (params) => (
            <Box {...params} p={2}>
              <Typography variant="h5">
                {tTableHeader("LOAN_SUMMARY")}
              </Typography>
            </Box>
          ),
        }}
      />
      {openOtherChargesModal && loanId && (
        <OtherChargesModal
          open={openOtherChargesModal}
          setOpen={() => setOpenOtherChargesModal(false)}
        />
      )}
    </div>
  );
};
export default LoanSummary;
