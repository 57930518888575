import React from "react";

import UploadKYC from "../UploadKYC/UploadKYC";
import ApproveButton from "../UploadKYC/ApproveButton";

const UpdateKYC = ({
  selectedPayload,
  currentMileStone,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  return (
    <>
      <UploadKYC
        selectedPayload={selectedPayload}
        currentMileStone={currentMileStone}
        otherRequestTimer={otherRequestTimer}
      />

      <ApproveButton
        {...{
          getMileStoneOfCr,
          otherRequestTimer,
          selectedPayload,
        }}
      />
    </>
  );
};

export default UpdateKYC;
