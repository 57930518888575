import {IGModal} from "app/components";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {closeModal} from "app/store/customerProfile/customerProfile.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import React from "react";
import {loanPaymentSelectors} from "app/store/loanPayment";
import {RootState} from "app/store/store";

const ShiftPaymentStatusModal = ({paymentId}: { paymentId: number }) => {
  const dispatch = useAppDispatch();
  const refundStatus = useAppSelector((state: RootState) =>
    loanPaymentSelectors.getShiftPaymentStatus(state, paymentId),
  );

  return (
    <IGModal
      open={true}
      onClose={() => {
        dispatch(closeModal("shiftPaymentStatus"));
      }}
    >
      <>
        {refundStatus?.length && (
          <TableContainer component={Paper}>
            <Table
              sx={{
                "& th": {
                  backgroundColor: "#000",
                  color: "#fff",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Payment ID</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Refund Reason</TableCell>
                  <TableCell>Source Loan ID</TableCell>
                  <TableCell>Target Loan ID</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>Attachments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refundStatus?.map((row) => (
                  <TableRow key={row.loanPaymentId}>
                    <TableCell>{row.loanPaymentId}</TableCell>
                    <TableCell>{row.actionTakenBy}</TableCell>
                    <TableCell>{row.shiftLoanPaymentReason}</TableCell>
                    <TableCell>
                      {row.sourceLoanId}
                    </TableCell>
                    <TableCell>{row.targetLoanId}</TableCell>
                    <TableCell>{row.remark}</TableCell>
                    <TableCell>
                      <Typography>
                        <a href={row.internalApprovalAttachmentUrl}>
                          Internal Approval Attachment
                        </a>
                      </Typography>
                      <Typography>
                        <a href={row.shivalikConfirmationAttachmentLinkUrl}>
                          Shivalik Confirmation Attachment
                        </a>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    </IGModal>
  );
};

export default ShiftPaymentStatusModal;
