import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/admin/business/cr/:crId/checker/validate-loan-value",
  METHOD: "POST",
};

export const checkerValidation = async ({crId, loanType, fileData}) => {
  const data = fileData;
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        loanType,
      },
      data,
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export default checkerValidation;
