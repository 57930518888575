import React from "react";
import styles from "../styles";


const ImageLink = ({image, name, onClick}) => {
  const classes = styles();

const imageOnClickHandler = (url) => {
  if (onClick) {
    onClick(url);
  } else {
    const image = new Image();
    image.src = url;

    const w = window.open("");
    w.document.write(image.outerHTML);
  }
};

  return (
    <>
      {image && (
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            flexDirection: "column",
          }}
        >
          <div className={classes.subText}>{name}</div>

          <div>
            {(image.fileUrl || image.url) && (
              <img
                onClick={() => {
                  imageOnClickHandler(image.fileUrl || image.imageUrl);
                }}
                src={image.fileUrl || image.imageUrl}
                className={classes.image}
                alt="pledgeCard"
              />
            )}

            {image.backFileUrl && (
              <img
                style={{marginLeft: "10px"}}
                src={image.backFileUrl}
                className={classes.image}
                alt="pledgeCard"
                onClick={() => {
                  imageOnClickHandler(image.backFileUrl);
                }}
              />
            )}

            {image?.crDocumentImageResponseDtoList &&
              image?.crDocumentImageResponseDtoList?.map((e, index) => {
                return (
                  <img
                    src={e.fileUrl}
                    className={classes.image}
                    style={{marginLeft: "10px"}}
                    alt="pledgeCard"
                    key={e.crDocumentId + index}
                    onClick={() => {
                      imageOnClickHandler(e.fileUrl);
                    }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageLink;

