import {AuctionStatusEnum} from "app/enums/serviceDesk";
import {AuctionDone, AuctionDoneOutlined} from "../assets/AuctionDone";
import {AuctionInProgress, AuctionInProgressOutlined} from "../assets/AuctionInProgress";
import {AuctionToBeDone, AuctionToBeDoneOutlined} from "../assets/AuctionToBeDone";
import {AuctionStatus} from "app/models/services";
import {colors} from "@mui/material";

export const getAuctionIconByStatus = (
  status: AuctionStatus,
  outlined?: boolean,
) => {
  switch (status) {
    case AuctionStatusEnum.AUCTIONED:
      return outlined ? AuctionDoneOutlined: AuctionDone;
    case AuctionStatusEnum.AUCTION_IN_PROGRESS:
      return outlined ? AuctionInProgressOutlined : AuctionInProgress;
    case AuctionStatusEnum.TO_BE_AUCTIONED:
      return outlined ? AuctionToBeDoneOutlined : AuctionToBeDone;
    default:
      return () => null;
  }
};

export const getAuctionStatusColor = (status: AuctionStatus) => {
  switch (status) {
    case AuctionStatusEnum.AUCTIONED:
      return {
        color: colors.red[400],
        borderColor: colors.red[400],
        backgroundColor: colors.red[50],
      };
    case AuctionStatusEnum.AUCTION_IN_PROGRESS:
      return {
        color: colors.orange[400],
        borderColor: colors.orange[400],
        backgroundColor: colors.orange[50],
      };
    case AuctionStatusEnum.TO_BE_AUCTIONED:
      return {
        color: colors.blueGrey[400],
        borderColor: colors.blueGrey[400],
        backgroundColor: colors.blueGrey[50],
      };
    default:
      return "default";
  }
};
