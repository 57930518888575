import React, {useState} from "react";
import {Box, Grid, Divider} from "@mui/material";
import {IGDialog, IGButton} from "app/components";
import AgriMatchingDocumentsCard from "../AgriMatchingDocuments";
import {CrLandDocumentDetailDto} from "app/models/activeLoans/activeLoans.model";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";

interface AgriMatchingDocsPopUpProps {
  matchingLandDocumentList: CrLandDocumentDetailDto[];
  currentAgriDocDetail: CrLandDocumentDetailDto[];
  crId: number;
}

const AgriMatchingDocsPopUp = ({
  matchingLandDocumentList,
  currentAgriDocDetail,
  crId,
}: AgriMatchingDocsPopUpProps) => {
  const dispatch = useAppDispatch();
  const {isMatchingDocsPopupOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const [isLastMatchingDoc, setIsLastMatchingDoc] = useState(false);

  const {
    markLandDocument: isMarkLandDocLoading,
    markLandDocumentAsSame: isMarkSameDocLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);
  const [selectedMatchedDocumentId, setSelectedMatchedDocumentId] = useState<
    number[]
  >([]);
  const overrideLandDocumentDuplicateCheck = useAppSelector(
    activeLoansSelectors.getOverrideLandDocumentDuplicateCheck,
  );

  const [docChoosen, setDocChoosen] = useState(0);

  return (
    <IGDialog
      title="Agri Documents"
      maxWidth="lg"
      open={isMatchingDocsPopupOpen}
      onClose={() =>
        dispatch(
          toggleModals({
            type: "isMatchingDocsPopupOpen",
            value: false,
          }),
        )
      }
      hideFooter
      subtitle={
        selectedMatchedDocumentId.length > 0
          ? `Selected Document# ${docChoosen + 1} `
          : ""
      }
    >
      <Box maxWidth="100%" textAlign="center" display="flex">
        <Box width="50%" p={1}>
          <AgriMatchingDocumentsCard
            matchingDocumentsList={matchingLandDocumentList}
            isOriginalDocument={false}
            selectedMatchedDocumentId={selectedMatchedDocumentId}
            setSelectedMatchedDocumentId={setSelectedMatchedDocumentId}
            setIsLastMatchingDoc={setIsLastMatchingDoc}
            setDocChoosen={setDocChoosen}
          />
        </Box>

        <Divider flexItem orientation="vertical" />
        <Box width="50%" p={1}>
          <AgriMatchingDocumentsCard
            matchingDocumentsList={currentAgriDocDetail}
            isOriginalDocument={true}
            selectedMatchedDocumentId={selectedMatchedDocumentId}
            setSelectedMatchedDocumentId={setSelectedMatchedDocumentId}
            setIsLastMatchingDoc={setIsLastMatchingDoc}
            setDocChoosen={setDocChoosen}
          />
        </Box>
      </Box>
      {isLastMatchingDoc && (
        <Grid container mt={2}>
          <Grid item xs={12} textAlign="center">
            <IGButton
              disabled={selectedMatchedDocumentId.length === 0}
              loading={isMarkLandDocLoading}
              onClick={() =>
                dispatch(
                  activeLoansActions.markLandDocument({
                    crId,
                    matchingDocumentId: Number(selectedMatchedDocumentId),
                    currentDocumentId: Number(currentAgriDocDetail[0]?.id),
                  }),
                )
              }
            >
              Update Document Status
            </IGButton>
          </Grid>
          <Grid item xs={12} mt={1} textAlign="center">
            <IGButton
              color="inherit"
              variant="text"
              disabled={selectedMatchedDocumentId.length > 0 || !overrideLandDocumentDuplicateCheck}
              loading={isMarkSameDocLoading}
              onClick={() =>
                dispatch(
                  activeLoansActions.markLandDocumentAsSame({
                    crId,
                  }),
                )
              }
            >
              Mark current doc as new doc
            </IGButton>
          </Grid>
        </Grid>
      )}
    </IGDialog>
  );
};

export default AgriMatchingDocsPopUp;
