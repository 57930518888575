import {LoanApiInterface, LoanApiResources} from "app/store/loan/loan.types";
import {goldApiService} from "../../../plugins/axios";

const LOAN_ENDPOINTS: LoanApiResources = {
  GET_LOAN_DETAILS: {
    URL: "/admin/business/loan/:loanId/loan-detail",
    METHOD: "GET",
  },
  GET_LOAN_REPAYMENT_SCHEDULE: {
    URL: "/admin/business/loan/:loanId/repayment-schedule",
    METHOD: "GET",
  },
  GET_ALL_USER_LOANS: {
    URL: "/admin/business/user/:userId/loan-detail",
    METHOD: "GET",
  },
  GET_ALL_VIRTUAL_ADDRESS: {
    URL: "/admin/business/loan/virtual-address/config",
    METHOD: "GET",
  },
  GET_ALL_VIRTUAL_ADDRESS_BY_REQUEST_ID: {
    URL: "/admin/service-desk/request/:requestId/virtual-address",
    METHOD: "GET",
  },
  GENERATE_VIRTUAL_ADDRESS: {
    URL: "/admin/business/loan/:loanId/virtual-address/generate",
    METHOD: "POST",
  },
  GET_LENDER_LOAN_REPAYMENT_SCHEDULE: {
    URL: "/admin/business/lender/loan/:loanId/repayment-schedule",
    METHOD: "GET",
  },
  GET_LENDER_LOAN_DETAILS: {
    URL: "/admin/business/lender/loan/:loanId/loan-detail",
    METHOD: "GET",
  },
};

const loanApi: LoanApiInterface = {
  getLoanDetails: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_LOAN_DETAILS,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getLoanRepaymentSchedule: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_LOAN_REPAYMENT_SCHEDULE,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getAllUserLoans: async ({userId}) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_ALL_USER_LOANS,
      options: {
        pathVars: {userId},
      },
    });
  },
  getAllVirtualAddress: async ({requestId}) => {
    if (requestId) {
      return await goldApiService({
        resource: LOAN_ENDPOINTS.GET_ALL_VIRTUAL_ADDRESS_BY_REQUEST_ID,
        options: {
          pathVars: {requestId},
        },
      });
    }
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_ALL_VIRTUAL_ADDRESS,
    });
  },
  generateVirtualAddress: async ({
    loanId,
    virtualAddressType,
    isInAppPaymentAllowed=false,
  }) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GENERATE_VIRTUAL_ADDRESS,
      options: {
        pathVars: {loanId},
        data: {virtualAddressType, isInAppPaymentAllowed},
      },
    });
  },
  getLenderLoanRepaymentSchedule: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_LENDER_LOAN_REPAYMENT_SCHEDULE,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getLenderLoanDetails: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_ENDPOINTS.GET_LENDER_LOAN_DETAILS,
      options: {
        pathVars: {loanId},
      },
    });
  },
};

export {LOAN_ENDPOINTS};
export default loanApi;