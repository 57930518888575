import React, {useCallback} from "react";
import {Box} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import GuarantorDetails from "../GuarantorDetails";
import BTScoreCreditDetails from "../CreditDetails";

interface BTScoreCardSelectedMilestoneProps {
  crId: number;
}
const BTScoreCardSelectedMilestone = ({crId}: BTScoreCardSelectedMilestoneProps) => {
  const selectedMilestone = useAppSelector(activeLoansSelectors.getSelectedBtScoreCardMilestone);

  const renderBtScoreCardMilestone = useCallback(
    selectedMilestone => {
      switch (selectedMilestone) {
        case "GUARANTOR_RULE_V1":
          return <GuarantorDetails crId={Number(crId)} />;
        case "TAKEOVER_CREDIT_REPORT_RULE_V1":
          return <BTScoreCreditDetails crId={Number(crId)} />;
        default:
          return <GuarantorDetails crId={Number(crId)} />;
      }
    },
    [selectedMilestone, crId],
  );
  return <Box>{renderBtScoreCardMilestone(selectedMilestone)}</Box>;
};

export default BTScoreCardSelectedMilestone;
