import React, {useEffect, useState, useCallback, useRef, useMemo} from "react";
import Heading from "app/pages/home/GoldLoan/Lead/components/Heading";
import styles from "../../styles";
import RowContainer from "../../NewUploadKYC/styles/RowContainer";
import ColumnContainer from "../../NewUploadKYC/styles/ColumnContainer";
import SubTitle from "../../NewUploadKYC/styles/SubTitle";
import MiniTitle from "../../NewUploadKYC/styles/MiniTitle";
import Container from "../../NewUploadKYC/styles/Container";
import {AttachFileRounded, Close} from "@mui/icons-material";
import {
  Divider,
  Checkbox,
  IconButton,
  Box,
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  TextField,
  FormControlLabel,
} from "@mui/material";
import {IGButton} from "app/components";
import getLenderDetails from "api/business/getLenderDetails";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import IconWrapper from "../../NewUploadKYC/KYCDetails/styles/IconWrapper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import checkerValidation from "api/business/checkerValidation";
import createDocument from "api/business/createDocument";
import {useIGTranslation} from "app/infra/plugins/i18n";
import getNextJob from "api/business/getNextJob";
import skipGatingEnable from "api/activeLoan/skipGatingEnable";
import {RolesEnum} from "app/enums/roles";
import getProcessingFeeConfig from "api/activeLoan/getProcessingFeeConfig";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {isNil} from "lodash";

const GoldLoanChecker = ({
  goldLoanApplicationNo,
  getDetails,
  crId,
  onClickHandler,
  setIsGoldLoanCheckerDisable,
  isGoldLoanCheckerDisable,
  approveButtonLoading,
  setApproveButtonLoading,
  isSameCreditManager,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  const [loanAmount, setLoanAmount] = useState(null);
  const [monthlyRoi, setMonthlyRoi] = useState(null);
  const [customerRoi, setCustomerRoi] = useState(null);
  const [lenderIcon, setLenderIcon] = useState(null);
  const [disbursedAmount, setDisbursedAmount] = useState(null);
  const [cibilReport, setCibilReport] = useState(null);
  const [processingFee, setProcessingFee] = useState(0);
  const [loanAmountValidation, setLoanAmountValidation] = useState(null);
  const [monthlyRoiValidation, setMonthlyRoiValidation] = useState(null);
  const [customerRoiValidation, setCustomerRoiValidation] = useState(null);
  const [subventedValidation, setSubventedValidation] = useState(null);
  const [disbursedAmountValidation, setDisbursedAmountValidation] = useState(
    null,
  );
  const [processingFeeValidation, setProcessingFeeValidation] = useState(null);
  const [isApproveButtonDisable, setIsApproveButtonDisable] = useState(true);
  const [selectedCrJobId, setSelectedCrJobId] = useState(null);
  const [isGatingSkipLoading, setIsGatingSkipLoading] = useState(false);
  const [lenderName, setLenderName] = useState("");
  const [inputKey, setInputKey] = useState(Date.now());
  const [isProcessingFeeApplicable, setIsProcessingFeeApplicable] = useState(
    false,
  );

  const {showSuccess, showError} = useSnackBar();
  const {tErrors} = useIGTranslation();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const userInfo = useAppSelector(authSelectors.getUser);
  const {glMakerScreenDisplayInputFields} =
    useAppSelector(activeLoansSelectors.getLoanMakerFieldsFlag) ?? {};

  const {isSubventionActive} = glMakerScreenDisplayInputFields ?? {};

  const hiddenFileInput = useRef(null);

  const subventedRoi = useMemo(() => {
    return Number(monthlyRoi) - Number(customerRoi);
  }, [monthlyRoi, customerRoi]);

  const fetchLenderDetail = useCallback(
    async (loanType) => {
      const {payload, statusCode} = await getLenderDetails({
        accessToken,
        crId,
        loanType,
      });

      if (statusCode === 200) {
        setLenderIcon(payload?.icon);
        setLenderName(payload?.lenderName);
      }
    },
    [accessToken, crId],
  );

  const getNextJobApi = useCallback(async () => {
    try {
      const {payload, error} = await getNextJob({
        accessToken,
        crId: crId,
        jobType: "CM_PROCESS_LOAN",
      });

      if (payload && !error) {
        setSelectedCrJobId(payload.id);
      } else {
        showError(error || "not able to fetch");
      }
    } catch (error) {
      showError(tErrors("SOMETHING_WENT_WRONG"));
    }
  }, [accessToken, showError, tErrors, crId]);

  const fetchProcessingFeeConfig = useCallback(async () => {
    const {response, error} = await getProcessingFeeConfig({
      crId: crId,
      lenderEnum: lenderNameEnum.SHIVALIK,
    });
    if (!error) {
      setIsProcessingFeeApplicable(response?.isProcessingFeeApplicable);
    } else {
      showError(error.message);
    }
  }, [crId]);

  const handleClipboardEvents = (e) => {
    e.preventDefault();
  };

  const onFileChangeHandler = useCallback((event) => {
    const selectedFile = event.target.files[0];
    setCibilReport(selectedFile);
  }, []);

  const onFileClearHandler = useCallback(() => {
    setInputKey(Date.now());
    setCibilReport(null);
  }, []);

  const uploadTransUnionCibilReport = async () => {
    if (!cibilReport) {
      return null;
    }
    if (!selectedCrJobId) {
      return {message: "Something went wrong, please reload."};
    }
    const formData = new FormData();
    formData.append("fileData", cibilReport);
    const {error} = await createDocument({
      accessToken,
      crId,
      crJobId: selectedCrJobId,
      documentType: "TRANSUNION_CREDIT_REPORT",
      fileContentType: "application/pdf",
      fileData: formData,
      fileName: cibilReport.name,
    });
    if (error) {
      return error;
    }
    return error || null;
  };

  const onCheckerSubmitHandler = useCallback(async () => {
    if (
      isNil(loanAmount) ||
      isNil(monthlyRoi) ||
      isNil(processingFee) ||
      (isSubventionActive && isNil(customerRoi)) ||
      isNil(disbursedAmount)
    ) {
      showError("Please provide the required values");
      return;
    }
    setApproveButtonLoading(true);

    if (loanAmount?.length !== 0 && monthlyRoi?.length !== 0) {
      const fileUploadError = await uploadTransUnionCibilReport();

      if (fileUploadError) {
        showError(
          fileUploadError.message ||
            "Something went wrong in uploading image, please retry",
        );
        onFileClearHandler();
        setApproveButtonLoading(false);
        return;
      }

      const formData = new FormData();

      formData.append("loanAmount", loanAmount);
      formData.append("monthlyRoi", monthlyRoi);
      formData.append(
        "customerRoi",
        isSubventionActive ? customerRoi : monthlyRoi,
      );
      formData.append("subventedRoi", subventedRoi);
      formData.append("disbursedAmount", disbursedAmount);
      formData.append("processingFeeIncludingGst", processingFee);

      const {
        error: checkerValidationError,
        statusCode: checkerValidationCode,
      } = await checkerValidation({
        accessToken,
        crId,
        fileData: formData,
        loanType: "GOLD_LOAN",
      });
      if (checkerValidationCode === 200) {
        setApproveButtonLoading(false);
        setIsGoldLoanCheckerDisable(true);
        showSuccess("Given Values have been successfully validated!");
        getDetails();
        dispatch(
          activeLoansActions.getLatestStatus({
            crId: crId,
            crJobType: "CM_PROCESS_LOAN",
          }),
        );
      } else {
        setApproveButtonLoading(false);
        setLoanAmountValidation(checkerValidationError?.fieldError?.loanAmount);
        setMonthlyRoiValidation(checkerValidationError?.fieldError?.monthlyRoi);
        setCustomerRoiValidation(
          checkerValidationError?.fieldError?.customerRoi,
        );
        setSubventedValidation(
          checkerValidationError?.fieldError?.subventedRoi,
        );
        setDisbursedAmountValidation(
          checkerValidationError?.fieldError?.disbursedAmount,
        );
        setProcessingFeeValidation(
          checkerValidationError?.fieldError?.processingFeeIncludingGst,
        );
        showError(checkerValidationError?.message);
      }
    }
  }, [
    setIsGoldLoanCheckerDisable,
    loanAmount,
    monthlyRoi,
    customerRoi,
    accessToken,
    crId,
    processingFee,
    subventedRoi,
    processingFee,
    disbursedAmount,
    setApproveButtonLoading,
    onClickHandler,
    showError,
  ]);

  const onClickSkipGatingHandler = useCallback(async () => {
    setIsGatingSkipLoading(true);
    const {error, response} = await skipGatingEnable({
      crId: crId,
      lenderName: lenderName,
    });
    if (!error) {
      showSuccess(response.message || "successfully updated!");
      setIsGatingSkipLoading(false);
    } else {
      setIsGatingSkipLoading(false);
      showError(error.message);
    }
  }, [crId, lenderName, showError, showSuccess]);

  useEffect(() => {
    getNextJobApi();
    fetchProcessingFeeConfig();
    dispatch(
      activeLoansActions.getMakerFieldsFlag({
        crId,
      }),
    );
  }, []);

  useEffect(() => {
    fetchLenderDetail("GOLD_LOAN");
  }, [fetchLenderDetail]);

  useEffect(() => {
    if (!isSubventionActive) {
      setCustomerRoi(monthlyRoi);
    }
  }, [isSubventionActive, monthlyRoi]);

  return (
    <>
      {isGoldLoanCheckerDisable ? (
        <CircularProgress variant="indeterminate" />
      ) : (
        <>
          <div className={classes.arrowOutlined}>
            <IconButton onClick={() => setIsGoldLoanCheckerDisable(true)}>
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <div className={classes.loanConfirmContainer}>
            <Heading header="Confirm loan value" />
            <div className={classes.validateText}>
              Please validate the correct loan details and ROI mentioned for the
              loan
            </div>
            <Container className={classes.confirmContainer}>
              <Divider light={true} />
              <div className={classes.loanConfirmRowContainer}>
                <RowContainer>
                  <ColumnContainer>
                    <SubTitle>CR ID</SubTitle>
                    <MiniTitle style={{fontWeight: "bold"}}>{crId}</MiniTitle>
                  </ColumnContainer>
                  <ColumnContainer>
                    <SubTitle>Application Number</SubTitle>
                    <MiniTitle style={{fontWeight: "bold"}}>
                      {goldLoanApplicationNo}
                    </MiniTitle>
                  </ColumnContainer>
                </RowContainer>
              </div>
              <Divider light={true} />
            </Container>
          </div>
          <FormControl variant="outlined" fullWidth>
            <Box p={2} sx={{width: "100%"}}>
              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="lender"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    Lender
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <IconWrapper
                    width={"auto"}
                    src={lenderIcon}
                    alt="bank-icon"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>{}</span>
                </Grid>
              </Grid>
              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="total-loan-value"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    GL Sanctioned
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="GL Sanctioned"
                    placeholder="Amount in INR"
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    value={loanAmount}
                    onChange={(event) => setLoanAmount(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {loanAmountValidation ? loanAmountValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="amount-disbursed"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    Amount to be Disbursed
                  </Typography>
                  <p> (Accounts will transfer this to customer) </p>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Amount in INR"
                    label="Amount to be disbursed"
                    onWheel={(event) => event.target.blur()}
                    value={disbursedAmount}
                    onChange={(event) => setDisbursedAmount(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {disbursedAmountValidation ? disbursedAmountValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="amount-disbursed"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    Processing Fee
                  </Typography>
                  {/* <p> (Accounts will transfer this to customer) </p> */}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Amount in INR"
                    label="Processing Fee"
                    onWheel={(event) => event.target.blur()}
                    value={processingFee}
                    onChange={(event) => setProcessingFee(event.target.value)}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {processingFeeValidation ? processingFeeValidation : ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="monthly-roi"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    Monthly ROI(after rebate)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="in %"
                    value={monthlyRoi}
                    label="Monthly ROI(after rebate)"
                    onWheel={(event) => event.target.blur()}
                    onChange={(event) => {
                      setMonthlyRoi(event.target.value);
                    }}
                    onCut={handleClipboardEvents}
                    onCopy={handleClipboardEvents}
                    onPaste={handleClipboardEvents}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <span className={classes.validationError}>
                    {monthlyRoiValidation ? monthlyRoiValidation : ""}
                  </span>
                </Grid>
              </Grid>
              {isSubventionActive && (
                <Grid container className={classes.checkerGrid}>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      id="customer-roi"
                      variant="standard"
                      className={classes.checkerLabel}
                    >
                      Customer ROI
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="in %"
                      label="Customer ROI"
                      value={isSubventionActive ? customerRoi : monthlyRoi}
                      onWheel={(event) => event.target.blur()}
                      onChange={(event) => setCustomerRoi(event.target.value)}
                      onCut={handleClipboardEvents}
                      onCopy={handleClipboardEvents}
                      onPaste={handleClipboardEvents}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span className={classes.validationError}>
                      {customerRoiValidation ? customerRoiValidation : ""}
                    </span>
                  </Grid>
                </Grid>
              )}

              {isSubventionActive && (
                <Grid container className={classes.checkerGrid}>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      id="subvention-roi"
                      variant="standard"
                      className={classes.checkerLabel}
                    >
                      IG subvention
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={subventedRoi}
                      label="IG Subvention"
                      onWheel={(event) => event.target.blur()}
                      disabled
                      onCut={handleClipboardEvents}
                      onCopy={handleClipboardEvents}
                      onPaste={handleClipboardEvents}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <span className={classes.validationError}>
                      {subventedValidation ? subventedValidation : ""}
                    </span>
                  </Grid>
                </Grid>
              )}

              <Grid container className={classes.checkerGrid}>
                <Grid item xs={4}>
                  <Typography
                    color="text.secondary"
                    id="subvention-roi"
                    variant="standard"
                    className={classes.checkerLabel}
                  >
                    Transunion credit report
                  </Typography>
                </Grid>

                <Grid item xs={4} className={classes.checkerUploadDocument}>
                  <input
                    ref={hiddenFileInput}
                    hidden
                    type="file"
                    id="file"
                    key={inputKey}
                    disabled={false}
                    accept="application/pdf"
                    multiple
                    onChange={(event) => onFileChangeHandler(event)}
                  />
                  <IGButton
                    value="text"
                    disabled={false}
                    startIcon={
                      <AttachFileRounded sx={{transform: "rotate(-315deg)"}} />
                    }
                    onClick={() => hiddenFileInput.current?.click()}
                  >
                    Upload File
                  </IGButton>
                  {cibilReport && (
                    <Box
                      maxHeight={175}
                      overflow="auto"
                      className={classes.checkerUploadDocument}
                    >
                      <Typography>{cibilReport.name}</Typography>
                      <IconButton
                        onClick={() => {
                          onFileClearHandler();
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <FormControlLabel
                style={{fontWeight: "bold"}}
                control={
                  <Checkbox
                    onChange={() =>
                      setIsApproveButtonDisable(!isApproveButtonDisable)
                    }
                    name="checkedF"
                    sx={{
                      color: "#ce9f29",
                      "&.Mui-checked": {
                        color: "#ce9f29",
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{fontWeight: "bold"}}>
                    Please verify that all the information mentioned above is
                    correct
                  </Typography>
                }
              />
            </Box>
          </FormControl>

          <Box display="flex" justifyContent="space-between">
            <IGButton
              sx={{
                marginLeft: 1,
              }}
              color="golden"
              onClick={() => {
                onCheckerSubmitHandler();
              }}
              loading={approveButtonLoading}
              disabled={isApproveButtonDisable}
            >
              Approve & Notify LM for Loan Documents
            </IGButton>

            {isSameCreditManager &&
              userInfo?.userRoleList.includes(RolesEnum.LOAN_OPS_ADMIN) && (
                <IGButton
                  loading={isGatingSkipLoading}
                  onClick={onClickSkipGatingHandler}
                >
                  Skip Gating
                </IGButton>
              )}
          </Box>
        </>
      )}
    </>
  );
};

export default GoldLoanChecker;
