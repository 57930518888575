import React, {useCallback, useEffect, useRef, useState} from "react";

import Notifications from "./Notification";
import throttle from "lodash.throttle";
import {CircularProgress} from "@mui/material";
import getNotifications from "../../../../api/notification/getNotification";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

const NotificationDrawer = ({setNotifications, notifications}) => {
  const notificationRef = useRef();
  const authToken = useAppSelector(authSelectors.getAuthToken);

  const [states, setStates] = useState({
    loading: false,
    error: false,
    errorMessage: "",
  });

  const data = useRef(true);
  const counter = useRef(0);
  const loadingState = useRef(false);

  const handleNotification = useCallback(async () => {
    if (data.current) {
      counter.current = 1;
      try {
        loadingState.current = true;
        setStates({
          ...states,
          loading: true,
        });

        const response = await getNotifications({
          accessToken: authToken,
          pageSize: 10,
          fromTime: notifications[notifications.length - 1].createdAt,
        });

        if (response.statusCode === 200) {
          counter.current = 0;

          if (
            response.payload.businessNotificationResponseDtoList.length < 10
          ) {
            data.current = false;
          }

          setStates({
            error: false,
            errorMessage: "",
            loading: false,
          });
          loadingState.current = false;

          setNotifications([
            ...notifications,
            ...response.payload.businessNotificationResponseDtoList,
          ]);
        } else {
          setStates({
            error: true,
            errorMessage: response.error,
            loading: false,
          });
          loadingState.current = false;
        }
      } catch (error) {
        counter.current = 0;
        loadingState.current = false;

        setStates({
          loading: false,
          error: true,
          errorMessage: "Something went wrong",
        });
      }
    }
  }, [notifications, setNotifications, states, authToken]);

  const counterMounting = useRef(0);

  useEffect(() => {
    const ref = notificationRef.current;
    if (counterMounting.current === 0) {
      counterMounting.current = 1;
      ref.addEventListener(
        "scroll",
        throttle(
          (e) => {
            e.preventDefault();
            if (
              e.target.scrollHeight - e.target.scrollTop < 300 &&
              data.current &&
              counter.current === 0
            ) {
              counter.current = 1;
              handleNotification();
            }
          },
          3000,
          {
            trailing: true,
          },
        ),
      );
      return () => {
        ref.removeEventListener("scroll", handleNotification);
      };
    }
  }, [handleNotification, notificationRef]);

  return (
    <div ref={notificationRef} className="kt-notification">
      {notifications?.map((e) => {
        return (
          <Notifications
            key={e.notificationCode}
            title={e.title}
            body={e.body}
            link={e.link}
          />
        );
      })}
      <div>{loadingState.current && <CircularProgress />}</div>
    </div>
  );
};

export default NotificationDrawer;
