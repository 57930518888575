import React, {memo, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ROUTES from "app/constants/routes";

const Notification = ({title, body, link}) => {
  const navigate = useNavigate();
  const NotificationonClickHandler = useCallback(() => {
    if (link.includes("/gold-active-loan")) {
      const activeCrId = ((link?.split("?") || [])[1].split("=") || [])[1];
      navigate(`/${ROUTES.GOLD_LOAN}/active-loans/${activeCrId}`);
    }
    if (link.includes("/accounts")) {
      navigate(`/${ROUTES.GOLD_LOAN}/accounts`);
    }
  }, [link, navigate]);

  return (
    <div onClick={NotificationonClickHandler} className="kt-notification__item">
      <div className="kt-notification__item-icon">
        <AssignmentIndOutlinedIcon color="success" />
      </div>
      <div className="kt-notification__item-details">
        <div className="kt-notification__item-title kt-font-bold">{title}</div>
        <div className="kt-notification__item-time">{body}</div>
      </div>
      <ArrowForwardIosOutlinedIcon />
    </div>
  );
};

export default memo(Notification);
