import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface GoldloanMakerDetailsPropTypes {
  crId: number;
  crJobId: number;
  totalGlAmount: string;
  disburseGlAmount: string;
  loanType: string;
  schemeType: string;
  loanTenure: string;
  subventedMonthlyInterestRate: string;
  monthlyInterestRate: string;
  schemeCodeId: String;
  processingFeeIncludingGst: string;
  btRecoveryAmountFromCustomer: string | null;
  insuranceAmount: string;
  recoveryReasonEnum: string | null;
  igRecoveryAmountFromCustomerForBt?: number | null;
  netTopUpForCustomer?: number | null;
}

export const postGoldLoanMakerDetails = async ({
  crId,
  crJobId,
  totalGlAmount,
  disburseGlAmount,
  schemeType,
  loanType,
  subventedMonthlyInterestRate,
  monthlyInterestRate,
  schemeCodeId,
  loanTenure,
  processingFeeIncludingGst,
  btRecoveryAmountFromCustomer,
  insuranceAmount,
  recoveryReasonEnum,
  igRecoveryAmountFromCustomerForBt,
  netTopUpForCustomer,
}: GoldloanMakerDetailsPropTypes) => {
  const API_ENDPOINTS: ApiResources = {
    POST_GL_MAKER_DETAILS: {
      URL: "/admin/business/loan/cr/:crId/process-loan-maker",
      METHOD: "POST",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.POST_GL_MAKER_DETAILS,
    options: {
      pathVars: {
        crId,
      },
      data: {
        crJobId,
        totalGlAmount,
        disburseGlAmount,
        loanType,
        schemeType,
        subventedMonthlyInterestRate,
        monthlyInterestRate,
        schemeCodeId,
        loanTenure,
        processingFeeIncludingGst,
        btRecoveryAmountFromCustomer,
        insuranceAmount,
        recoveryReasonEnum,
        igRecoveryAmountFromCustomerForBt,
        netTopUpForCustomer,
      },
    },
  });
};

export default postGoldLoanMakerDetails;
