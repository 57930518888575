import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {call, getContext, put, take, takeLatest} from "redux-saga/effects";
import partReleaseActions, {partReleaseActionTypes} from "./partRelease.actions";
import {AllowPaymentPayload, GetOfferHistoryOrnamentDetailPayload, GetOrnamentListPayload, ShareOfferPayload, UpdateCMOfferPayload, UpdateCstOfferPayload, UpdateOfferRoiPayload} from "app/infra/services/api/serviceDesk/partRelease/types";
import {
  cmConfigReceived,
  cmOfferDetailReceived,
  consumerOfferDetailReceived,
  cstConfigReceived,
  netPayableAmountReceived,
  newLoanOfferDetailReceived,
  offerHistoryOrnamentsReceived,
  offerHistoryReceived,
  ornamentListReceived,
  partReleaseSetActionError,
  partReleaseSetActionLoading,
  partReleaseSetActionSuccess,
  partReleaseToggleModal,
} from "./partRelease.reducer";
import {Channel} from "redux-saga";
import {countdown} from "app/store/utils.saga";
import {snackBarActions} from "app/store/snackbar";
import servicesActions from "../actions";
import {PartReleaseOrnament} from "./partRelease.types";
import {closeAllModals, reloadList, resetRowAction} from "../reducer";

const {
  getPartReleaseDataFailure,
  getPartReleaseDataSuccess,
} = partReleaseActions;

function* getPartReleaseOfferHistoryFlow(
  action: PayloadAction<GetOrnamentListPayload>,
) {
  yield put(partReleaseSetActionLoading({action: "getPartReleaseOfferHistory"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.partRelease.getPartReleaseOfferHistory,
    action.payload,
  );
  if (error) {
    yield put(partReleaseSetActionError({action: "getPartReleaseOfferHistory", error: error.message || "Something went wrong"}));
  } else {
    yield put(offerHistoryReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getPartReleaseOfferHistory"}));

  }
}

function* getPartReleaseDataFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.partRelease.getPartReleaseData,
    action.payload,
  );

  if (error) {
    yield put(getPartReleaseDataFailure({error: error.message}));
  } else {
    yield put(getPartReleaseDataSuccess({response}));
  }
}

function* getOrnamentListFlow(action: PayloadAction<GetOrnamentListPayload>) {
  yield put(partReleaseSetActionLoading({action: "getOrnamentList"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getOrnamentList, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getOrnamentList", error: error.message || "Something went wrong"}));
  } else {
    yield put(ornamentListReceived({
      requestId: action.payload.requestId,
      response: response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getOrnamentList"}));

    const isAnyOrnamentSelected =
      (response as PartReleaseOrnament[]).reduce(
        (acc, item) => (item?.selected ? acc + 1 : acc),
        0,
      ) > 0;
    if (isAnyOrnamentSelected) {
      yield put(partReleaseActions.getNewLoanOfferDetail({
        requestId: action.payload.requestId,
        offerBy: action.payload.offerBy,
      }));
    }
  }
}
function* updateCstOfferFlow(action: PayloadAction<UpdateCstOfferPayload>) {
  yield put(partReleaseSetActionLoading({action: "updateCstOffer"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.updateCstOffer, action.payload);
  if (error) {
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
    yield put(partReleaseSetActionError({action: "updateCstOffer", error: error.message || "Something went wrong"}));
  } else {
    yield put(partReleaseActions.getOrnamentList({
      requestId: action.payload.requestId,
      offerBy: "DEFAULT_OFFER",
    }));
    yield put(partReleaseSetActionSuccess({action: "updateCstOffer"}));
  }
}
function* getOfferHistoryOrnamentDetailFlow(
  action: PayloadAction<GetOfferHistoryOrnamentDetailPayload>,
) {
  yield put(partReleaseSetActionLoading({action: "getOfferHistoryOrnamentDetail"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.partRelease.getOfferHistoryOrnamentSelectionDetail,
    action.payload,
  );
  if (error) {
    yield put(partReleaseSetActionError({action: "getOfferHistoryOrnamentDetail", error: error.message || "Something went wrong"}));
  } else {
    yield put(offerHistoryOrnamentsReceived({
      offerHistoryId: action.payload.offerHistoryId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getOfferHistoryOrnamentDetail"}));
  }
}
function* getConsumerOfferDetailFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(partReleaseSetActionLoading({action: "getConsumerOfferDetail"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getConsumerOfferDetail, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getConsumerOfferDetail", error: error.message || "Something went wrong"}));
  } else {
    yield put(consumerOfferDetailReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getConsumerOfferDetail"}));
  }
}
function* getNewOfferDetailFlow(action: PayloadAction<GetOrnamentListPayload>) {
  yield put(partReleaseSetActionLoading({action: "getNewLoanOfferDetail"}));
  const api: GoldApi = yield getContext("api");
  const chan: Channel<number> = yield call(countdown, {
    seconds: 61,
    intervalTime: 1000,
  });

  try {
    while (true) {
      const seconds: number = yield take(chan);
      console.log("Seconds ---> ", seconds);
      if (seconds % 4 === 0) {
        console.log("Fetching Offer Expiry Status");
        const {response, error} = yield call(
          api.partRelease.checkIsOfferExpired,
          {
            requestId: action.payload.requestId,
            offerBy: action.payload.offerBy,
          },
        );

        if (error) {
          yield put(
            snackBarActions.open({message: error.message, variant: "error"}),
          );
        }

        if (response === false) {
          localStorage.setItem("IS_OFFER_EXPIRED", "false");
          chan.close();
        }
      }
    }
  } finally {
    if (localStorage.getItem("IS_OFFER_EXPIRED") === "false") {
      if (action.payload.offerBy === "DEFAULT_OFFER") {
        const {response, error} = yield call(
          api.partRelease.getNewLoanOfferDetail,
          action.payload,
        );
        if (error) {
          yield put(partReleaseSetActionError({action: "getNewLoanOfferDetail", error: error.message || "Something went wrong"}));
        } else {
          yield put(newLoanOfferDetailReceived({
            requestId: action.payload.requestId,
            response,
          }));
          yield put(partReleaseSetActionSuccess({action: "getNewLoanOfferDetail"}));
          yield put(
            partReleaseActions.getNetPayableAmount({
              requestId: action.payload.requestId,
            }),
          );
        }
      } else {
        const {response, error} = yield call(
          api.partRelease.getCmOfferDetail,
          action.payload,
        );
        if (error) {
          yield put(partReleaseSetActionError({action: "getNewLoanOfferDetail", error: error.message || "Something went wrong"}));
        } else {
          yield put(cmOfferDetailReceived({
            requestId: action.payload.requestId,
            response,
          }));
          yield put(partReleaseSetActionSuccess({action: "getNewLoanOfferDetail"}));
          // yield put(
          //   partReleaseActions.getNetPayableAmount({
          //     requestId: action.payload.requestId,
          //   }),
          // );
        }
      }

    }
    yield put(partReleaseSetActionSuccess({action: "getNewLoanOfferDetail"}));
    localStorage.removeItem("IS_OFFER_EXPIRED");
    console.log("countdown terminated");
  }
}
function* getNetPayableAmountFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(partReleaseSetActionLoading({action: "getNetPayableAmount"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getNetPayableAmount, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getNetPayableAmount", error: error.message || "Something went wrong"}));
  } else {
    yield put(netPayableAmountReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getNetPayableAmount"}));
  }
}
function* checkIsOfferExpiredFlow(action: PayloadAction<GetOrnamentListPayload>) {
  yield put(partReleaseSetActionLoading({action: "checkIsOfferExpired"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.checkIsOfferExpired, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "checkIsOfferExpired", error: error.message || "Something went wrong"}));
  } else {
    yield put(partReleaseSetActionSuccess({action: "checkIsOfferExpired"}));
  }
}
function* shareOfferFlow(action: PayloadAction<ShareOfferPayload>) {
  yield put(partReleaseSetActionLoading({action: "shareOffer"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.shareOffer, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "shareOffer", error: error.message || "Something went wrong"}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(partReleaseSetActionSuccess({action: "shareOffer"}));
    yield put(servicesActions.getServiceRequestData({requestId: action.payload.requestId}));
    if (action.payload.shareWith === "CUSTOMER_APP") {
      yield put(
        partReleaseToggleModal({
          key: "shareOfferWithCustomer",
          value: false,
        }),
      );
      yield put(
        partReleaseActions.getCstConfig({
          requestId: action.payload.requestId,
        }),
      );
      yield put(snackBarActions.open({message: "Offer Shared Successfully", variant: "success"}));
    }
    if (action.payload.shareWith === "CM") {
      yield put(snackBarActions.open({message: "Customer Requirements Shared Successfully with CM", variant: "success"}));
      yield put(
        partReleaseActions.getCstConfig({
          requestId: action.payload.requestId,
        }),
      );
    }
    if (action.payload.shareWith === "CST") {
      yield put(snackBarActions.open({message: "Offer Shared with CST", variant: "success"}));
      yield put(
        partReleaseActions.getCmConfig({
          requestId: action.payload.requestId,
        }),
      );
    }
  }
}
function* getCstConfigFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(partReleaseSetActionLoading({action: "getCstConfig"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getCstConfig, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getCstConfig", error: error.message || "Something went wrong"}));
  } else {
    yield put(cstConfigReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getCstConfig"}));
  }
}
function* getCmConfigFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(partReleaseSetActionLoading({action: "getCmConfig"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getCmConfig, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getCmConfig", error: error.message || "Something went wrong"}));
  } else {
    yield put(cmConfigReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getCmConfig"}));
  }
}
function* getCmOfferDetailFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(partReleaseSetActionLoading({action: "getCmOfferDetail"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.partRelease.getCmOfferDetail, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "getCmOfferDetail", error: error.message || "Something went wrong"}));
  } else {
    yield put(cmOfferDetailReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(partReleaseSetActionSuccess({action: "getCmOfferDetail"}));
  }
}
function* updateCmOfferFlow(action: PayloadAction<UpdateCMOfferPayload>) {
  yield put(partReleaseSetActionLoading({action: "updateCmOffer"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.updateCmOffer, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "updateCmOffer", error: error.message || "Something went wrong"}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(partReleaseSetActionSuccess({action: "updateCmOffer"}));
    yield put(servicesActions.getServiceRequestData({requestId: action.payload.requestId}));
    yield put(partReleaseActions.getCmConfig({requestId: action.payload.requestId}));
    yield put(partReleaseActions.getOrnamentList({
      requestId: action.payload.requestId,
      offerBy: "CM",
    }));
  }
}

function* updateOfferRoiFlow(action: PayloadAction<UpdateOfferRoiPayload>) {
  yield put(partReleaseSetActionLoading({action: "updateOfferRoi"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.updateOfferRoi, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "updateOfferRoi", error: error.message || "Something went wrong"}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(partReleaseSetActionSuccess({action: "updateOfferRoi"}));
    yield put(servicesActions.getServiceRequestData({requestId: action.payload.requestId}));
    yield put(partReleaseActions.getCmConfig({requestId: action.payload.requestId}));
    yield put(partReleaseActions.getCmOfferDetail({requestId: action.payload.requestId}));
    yield put(partReleaseToggleModal({key: "editRoi", value: false}));
  }

}

function* allowPaymentFlow(action: PayloadAction<AllowPaymentPayload>) {
  yield put(partReleaseSetActionLoading({action: "allowPayment"}));
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.partRelease.allowPayment, action.payload);
  if (error) {
    yield put(partReleaseSetActionError({action: "allowPayment", error: error.message || "Something went wrong"}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(
      snackBarActions.open({
        message: "In-app part-release payment has been allowed.",
        variant: "success",
      }),
    );
    yield put(partReleaseSetActionSuccess({action: "allowPayment"}));
    yield put(resetRowAction());
    yield put(reloadList());
    yield put(closeAllModals());
  }
}

export default function* partReleaseSaga() {
  yield takeLatest(
    partReleaseActionTypes.GetPartReleaseOfferHistory,
    getPartReleaseOfferHistoryFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetPartReleaseData,
    getPartReleaseDataFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetOrnamentList,
    getOrnamentListFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.UpdateCstOffer,
    updateCstOfferFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetOfferHistoryOrnamentSelectionDetail,
    getOfferHistoryOrnamentDetailFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetConsumerOfferDetail,
    getConsumerOfferDetailFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetNewLoanOfferDetail,
    getNewOfferDetailFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.GetNetPayableAmount,
    getNetPayableAmountFlow,
  );
  yield takeLatest(
    partReleaseActionTypes.CheckIsOfferExpired,
    checkIsOfferExpiredFlow,
  );
  yield takeLatest(partReleaseActionTypes.ShareOffer, shareOfferFlow);
  yield takeLatest(partReleaseActionTypes.GetCstConfig, getCstConfigFlow);
  yield takeLatest(partReleaseActionTypes.GetCmConfig, getCmConfigFlow);
  yield takeLatest(partReleaseActionTypes.GetCmOfferDetail, getCmOfferDetailFlow);
  yield takeLatest(partReleaseActionTypes.UpdateCmOffer, updateCmOfferFlow);
  yield takeLatest(partReleaseActionTypes.UpdateOfferRoi, updateOfferRoiFlow);
  yield takeLatest(partReleaseActionTypes.AllowPayment, allowPaymentFlow);
}