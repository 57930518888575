import ActiveLoan from "app/pages/home/GoldLoan/ActiveLoan";
import React from "react";
import {Outlet, Route} from "react-router-dom";

const ActiveLoanRoutes = () => {
  return (
    <Route path="active-loans" element={<Outlet/>}>
      <Route index element={<ActiveLoan/>}/>
      <Route path=":activeCrIdGoldLoan" element={<ActiveLoan />} />
    </Route>
  );
};

export default ActiveLoanRoutes;
