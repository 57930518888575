import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
  lenderEnum: string;
}

export const getProcessingFeeConfig = async ({crId, lenderEnum}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    GET_PROCESSING_FEE_CONFIG: {
      URL: "/business-dashboard/processing-fee/cr/:crId/config",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.GET_PROCESSING_FEE_CONFIG,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        lenderEnum,
      },
    },
  });
};

export default getProcessingFeeConfig;
