import React, {useCallback, useEffect, useState} from "react";
import {RootState} from "app/store/store";
import {Box, Typography, Tooltip} from "@mui/material";
import GuarantorProfileDetails from "./GuarantorProfileDetails";
import GuaranterDocumentDetails from "./GuarantorDocumentDetails";
import GuarantorCreditCheck from "./GuarantorCreditCheck";
import GuarantorSupplementDocuments from "./GuarantorSupplementDocuments";
import GuarantorAddressDetails from "./GuarantorAddressDetails";
import InfoIcon from "@mui/icons-material/Info";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";
import fetchEmploymentDetails from "api/business/fetchAdminEmploymentDetails";
import {resetGuarantorPersonalDetails} from "app/store/loanRenewalRebook/personalDetails/personalDetails.reducer";
import {
  personalDetailsActions,
  personalDetailsSelectors,
} from "app/store/loanRenewalRebook/personalDetails";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import {customerPersonalDetailsEnum} from "app/enums/accounts";
import {IGLoading} from "app/components";

const GuarantorDetails = ({crId}: { crId: number }) => {
  const dispatch = useAppDispatch();
  const {showError} = useSnackBar();
  const {
    getKycDocuments: iskycDocLoading,
    getGuarantorPersonalDetails: isPersonalDetailsLoading,
    getGuarantorAddressDetails: isAddressDetailsLoading,
  } = useAppSelector(personalDetailsSelectors.getLoading);
  const {getCreditSessionStatus: isCreditSessionLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );
  const selectedMilestone = useAppSelector(activeLoansSelectors.getSelectedBtScoreCardMilestone);
  const {entityRating} =
    useAppSelector((state: RootState) =>
      activeLoansSelectors.getBtScoreDetailsByMilestone(state, crId, selectedMilestone),
    ) ?? {};

  const {ratingLevelEnum} = entityRating ?? {};
  const isLoading = iskycDocLoading || isAddressDetailsLoading || isCreditSessionLoading;

  const [guarantorOccupation, setGuarantorOccupation] = useState("");

  const fetchOccupationalDetails = useCallback(async () => {
    const {error, response} = await fetchEmploymentDetails({
      crId,
      crEmploymentDetailType: "GUARANTOR_EMPLOYMENT_DETAIL",
    });

    if (!error) {
      setGuarantorOccupation(response?.occupationTypeDisplayValue);
    } else {
      showError(error?.message || "not able to fetch occupdation details");
    }
  }, [crId, showError]);

  const initGuarantorDataFetch = () => {
    if (crId) {
      fetchOccupationalDetails();
      dispatch(
        personalDetailsActions.getGuarantorPersonalDetails({
          crId,
          personalDetailType: customerPersonalDetailsEnum.GUARANTOR_PERSONAL_DETAIL,
        }),
      );

      dispatch(
        personalDetailsActions.getKycDocuments({
          crId,
          isRenewRebook: false,
          documentTypeList: [
            "GUARANTOR_AADHAAR",
            "GUARANTOR_PAN",
            "GUARANTOR_CO_BORROWER_FORM",
            "GUARANTOR_BANK_STATEMENT",
            "GUARANTOR_POST_DATED_CHEQUE",
            "GUARANTOR_RESIDENCE_PROOF",
          ],
        }),
      );

      dispatch(
        personalDetailsActions.getGuarantorAddressDetails({
          crId,
          addressType: ["GUARANTOR_RESIDENTIAL_ADDRESS", "GUARANTOR_PERMANENT_ADDRESS"],
        }),
      );
      dispatch(
        creditDetailsActions.getCreditSessionStatus({
          crId,
          cibilConsentType: "GUARANTOR_CIBIL_CONSENT",
        }),
      );
    }
  };

  useEffect(() => {
    initGuarantorDataFetch();
    return () => {
      dispatch(resetGuarantorPersonalDetails());
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <Box
          bgcolor="#FAFAFA"
          p="24px 50px 24px 24px"
          borderRight="1px solid #0000001F"
          borderLeft="1px solid #0000001F"
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" fontSize="20px" fontWeight={500}>
              Guarantor's Details
            </Typography>
            {ratingLevelEnum !== "HIGH" && (
              <Tooltip title="Refer to tips section" placement="left-start">
                <InfoIcon
                  sx={{
                    color: "#B23842",
                  }}
                />
              </Tooltip>
            )}
          </Box>

          <Box my={2}>
            <GuarantorProfileDetails guarantorOccupation={guarantorOccupation} />
          </Box>
          <Typography variant="h6" fontWeight={500} fontSize="20px">
            KYC Documents
          </Typography>
          <Box my={2}>
            <GuaranterDocumentDetails />
          </Box>

          <Box my={4} display="flex" gap={1}>
            <GuarantorCreditCheck />
            <GuarantorSupplementDocuments />
          </Box>
          <Typography variant="h6" fontWeight={500} fontSize="20px">
            Address
          </Typography>
          <Box my={2}>
            <GuarantorAddressDetails />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GuarantorDetails;
