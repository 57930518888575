import {Grid, Stack, Typography} from "@mui/material";
import serviceDeskApi from "app/infra/services/api/serviceDesk/serviceDesk.api";
import {dialerSelectors} from "app/store/dialer";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import {
  setFilters,
  setSearchFilters,
} from "app/store/serviceDesk/services/reducer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import IGButton from "../Button/IGButton";
import {ArrowRight} from "@mui/icons-material";
import customerProfileApi from "app/infra/services/api/customerProfile/customerProfile.api";
import {isEmpty} from "lodash";

const EventDetails = ({id, phoneNo}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {showError} = useSnackBar();
  const eventById = useAppSelector((state) =>
    dialerSelectors.getEventById(state, id),
  );
  const eventByMobile = useAppSelector((state) =>
    dialerSelectors.getEventByMobile(state, phoneNo),
  );
  const [serviceRequestDetails, setServiceRequestDetails] = useState({});
  const [profileDetails, setProfileDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const {eventDescription, serviceRequestId, loanId, mobile} =
    eventById || eventByMobile || {};

  const handleRequestNavigation = (requestData) => {
    const {requestType, id, serviceRequestCategory} =
      requestData || serviceRequestDetails || {};

    const navigationURl =
      requestType === "COMPLAINT"
        ? `/gold-loan/service-desk/complaints?searchType=requestId&id=${id}&requestId=${id}&serviceRequestCategory=${serviceRequestCategory}`
        : `/gold-loan/service-desk/loan-services?searchType=requestId&serviceType=${serviceRequestCategory}&id=${id}&requestId=${id}`;

    if (requestType === "SERVICE") {
      dispatch(
        setFilters({
          searchType: "requestId",
          id,
          serviceType: serviceRequestCategory,
        }),
      );
    } else if (requestType === "COMPLAINT") {
      dispatch(
        setSearchFilters({
          searchType: "requestId",
          id,
          serviceRequestCategory,
        }),
      );
    }
    navigate(navigationURl);
  };

  const getServiceRequestDetails = async (serviceRequestId) => {
    setLoading(true);

    if (!isEmpty(serviceRequestDetails) && serviceRequestDetails.id === serviceRequestId) {
      handleRequestNavigation(serviceRequestDetails);
      setLoading(false);
      return;
    }

    const serviceRequestDetailResponse = await serviceDeskApi.getServiceRequestById(
      {
        requestId: serviceRequestId,
      },
    );

    if (!serviceRequestDetailResponse.error) {
      const {serviceRequest} = serviceRequestDetailResponse;
      setServiceRequestDetails(serviceRequest);
      // For automatic navigation to the service request page
      handleRequestNavigation(serviceRequest);
    }

    setLoading(false);
  };

  const getProfileDetails = async (loanId) => {
    setLoading(true);

    if (!isEmpty(profileDetails) && profileDetails.loanId === loanId) {
      navigate(
        `/gold-loan/customer-profiles/${profileDetails.userId}/${profileDetails.loanId}`,
      );
      setLoading(false);
      return;
    }

    const profileResponse = await customerProfileApi.getCustomerProfileList({
      loanId,
      pageNo: 1,
      pageSize: 10,
    });

    if (!profileResponse.error) {
      const profileList = profileResponse?.response || [];

      if (profileList.length) {
        const {userId, loanId} = profileList[0];
        setProfileDetails(profileList[0]);
        navigate(`/gold-loan/customer-profiles/${userId}/${loanId}`);
      } else {
        showError("No profile found for the given loan ID");
      }
    } else {
      showError("Error while fetching profile details");
    }
    setLoading(false);
  };

  const handleProfileNavigation = () => {
    navigate(
      `/gold-loan/customer-profiles?pageNo=1&pageSize=10&searchText=${phoneNo}&currentSearchType=MOBILE`,
    );
  };

  useEffect(() => {
    if (serviceRequestId) {
      getServiceRequestDetails(serviceRequestId);
    } else if (loanId) {
      getProfileDetails(loanId);
    } else {
      handleProfileNavigation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestId, loanId]);

  return (
    <>
      <Grid container spacing={2}>
        {eventDescription && (
          <Grid item xs={12}>
            <Typography fontWeight="bold" mt={2}>
              Event Description:
            </Typography>
            <Typography ml={1}>{eventDescription}</Typography>
          </Grid>
        )}
        {serviceRequestId && (
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight="bold">Service Request ID:</Typography>
              <Typography>{serviceRequestId}</Typography>
              <IGButton
                variant="text"
                endIcon={<ArrowRight />}
                onClick={handleRequestNavigation}
                loading={loading}
              >
                View Request
              </IGButton>
            </Stack>
          </Grid>
        )}
        {loanId ? (
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight="bold">Loan ID:</Typography>
              <Typography ml={1}>{loanId}</Typography>
              <IGButton
                variant="text"
                endIcon={<ArrowRight />}
                onClick={() => getProfileDetails(loanId)}
                loading={loading}
              >
                View Profile
              </IGButton>
            </Stack>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Typography fontWeight="bold">Mobile:</Typography>
            <Typography ml={1}>{mobile}</Typography>
            <IGButton
              variant="text"
              endIcon={<ArrowRight />}
              onClick={handleProfileNavigation}
              loading={loading}
            >
              Search Profile
            </IGButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EventDetails;
