import {goldApiService} from "app/infra/plugins/axios";
import {
  CifValidationApiInterface,
  GetDocumentListByCrIdPayload,
  CifValidationApiResources,
} from "app/store/loanRenewalRebook/cifValidation/cifValidation.types";

const CIF_DETAILS_ENDPOINTS: CifValidationApiResources = {
  GET_CIF_DETAILS: {
    URL: "/admin/business/v2/cr/:crId/document-list",
    METHOD: "GET",
  },
  UPDATE_CIF_DETAILS: {
    URL: "/admin/renew-rebook/:crId/cif-upload",
    METHOD: "POST",
  },
};

const cifDetailsApi: CifValidationApiInterface = {
  getCifDetails: async ({crId, documentTypeList}: GetDocumentListByCrIdPayload) => {
    return await goldApiService({
      resource: CIF_DETAILS_ENDPOINTS.GET_CIF_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          documentTypeList: documentTypeList,
        },
      },
    });
  },
  updateCifDetails: async ({crId, cifNumber}) => {
    return await goldApiService({
      resource: CIF_DETAILS_ENDPOINTS.UPDATE_CIF_DETAILS,
      options: {
        queryParams: {
          cifNumber,
        },
        pathVars: {
          crId,
        },
      },
    });
  },
};

export {CIF_DETAILS_ENDPOINTS};
export default cifDetailsApi;
