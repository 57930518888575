import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanActions, loanSelectors} from "app/store/loan";
import {useParams} from "react-router-dom";
import {Box, Divider, Grid, Stack, Typography} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {getValidTime} from "_metronic/utils/moment";
import {IGBoxBackdrop} from "app/components";
import {loanSummarySelectors} from "app/store/loanSummary";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";

interface LoanDetailsProps {}

const LoanDetails: FC<LoanDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const {loanId} = useParams();
  const {t} = useIGTranslation();

  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLenderLoanDetails(state, Number(loanId)),
  );
  const loanSummary = useAppSelector((state) =>
    loanSummarySelectors.getLenderLoanSummary(state, Number(loanId)),
  );
  const {getLoanDetails: getLoanDetailsLoading} = useAppSelector(
    loanSelectors.getLoading,
  );
  const {
    getLoanSummaryDetails: getLoanSummaryDetailsLoading,
  } = useAppSelector(loanSummarySelectors.getLoading);

  useEffect(() => {
    if (!loanId) return;
    dispatch(
      loanActions.getLenderLoanDetails({
        loanId: Number(loanId),
      }),
    );
  }, [dispatch, loanId]);

  return (
    <Box border="1px solid #D1D5DB" p={2} borderRadius={1} position="relative">
      <IGBoxBackdrop
        loading={getLoanSummaryDetailsLoading || getLoanDetailsLoading}
      />

      <Stack
        direction="row"
        spacing={2}
        divider={<Divider flexItem orientation="vertical" />}
      >
        <Grid item xs={4}>
          <Box display="flex" gap={2} mb={1}>
            <Box flex={1}>
              <Typography variant="subtitle1" color="#64748B">
                {t("LOAN_START_DATE")}:
              </Typography>
              <Typography variant="h6">
                {loanDetails?.loanStartDate
                  ? getValidTime(loanDetails?.loanStartDate).format(
                      "DD MMM, YYYY",
                    )
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1" color="#64748B">
              {t("LOAN_MATURITY_DATE")}:
            </Typography>
            <Typography variant="h6">
              {loanSummary?.maturityDate
                ? getValidTime(loanSummary?.maturityDate).format(
                    "DD MMM, YYYY",
                  )
                : "-"}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1" color="#64748B">
              {t("STATUS")}:
            </Typography>
            <Typography variant="h6">{loanDetails?.status}</Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box mb={1}>
            <Typography variant="subtitle1" color="#64748B">
              GL LAN ID:
            </Typography>
            <Typography variant="h6">{loanDetails?.glLanId || "-"}</Typography>
          </Box>
          {loanDetails?.igGlLoanId && (
            <Box>
              <Typography variant="subtitle1" color="#64748B">
                IG GL LOAN ID:
              </Typography>
              <Typography variant="h6">{loanDetails?.igGlLoanId}</Typography>
            </Box>
          )}
          {loanDetails?.disbursedGoldLoanAmount && (
            <Box>
              <Typography variant="subtitle1" color="#64748B">
                Sanctioned GL Amount:
              </Typography>
              <Typography variant="h6">
                {numberWithCurrencyAndComma(
                  loanDetails?.disbursedGoldLoanAmount,
                )}
              </Typography>
            </Box>
          )}
        </Grid>

        {loanDetails?.isPersonalLoanTaken && (
          <Grid item xs={4}>
            <Box mb={1}>
              <Typography variant="subtitle1" color="#64748B">
                PL LAN ID
              </Typography>
              <Typography variant="h6">
                {loanDetails?.plLanId || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="#64748B">
                IG PL LOAN ID
              </Typography>
              <Typography variant="h6">{loanDetails?.igPlLoanId}</Typography>
            </Box>
            {loanDetails?.disbursedPersonalLoanAmount && (
              <Box>
                <Typography variant="subtitle1" color="#64748B">
                  Sanctioned PL Amount:
                </Typography>
                <Typography variant="h6">
                  {numberWithCurrencyAndComma(
                    loanDetails?.disbursedPersonalLoanAmount,
                  )}
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default LoanDetails;
