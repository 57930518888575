import {goldApiService} from "app/infra/plugins/axios";
import {RenewRebookApiInterface} from "./types";
import {ApiResources} from "app/typings/api/goldApi.types";
import {ServiceRequestCategory} from "app/enums/serviceDesk";

const RENEW_REBOOK_END_POINTS: ApiResources = {
  SAVE_CUSTOMER_REQUIREMENT_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/customer-requirement",
    METHOD: "PUT",
  },
  GET_OUR_OFFER: {
    URL: "/admin/service-desk/request/:requestId/cst-offer-detail",
    METHOD: "GET",
  },
  GET_VERSION_HISTORY: {
    URL: "/admin/service-desk/request/:requestId/renewal-history",
    METHOD: "GET",
  },
  UPDATE_SHARE_STATUS: {
    URL: "/admin/service-desk/request/:requestId/update-share-status", // shareWith
    METHOD: "PUT",
  },
  GET_NET_PAYABLE: {
    URL: "/admin/service-desk/loan/:loanId/request/:requestId/renewal-net-payable-amount",
    METHOD: "GET",
  },
  GET_ORNAMENT_DETAILS: {
    URL: "/admin/service-desk/:loanId/request/:requestId/cm-ornaments-list",
    METHOD: "GET",
  },
  UPDATE_ORNAMENT_DETAILS: {
    URL: "/admin/service-desk/:loanId/request/:requestId/ornament/:ornamentId",
    METHOD: "PUT",
  },
  GET_CUSTOMER_FINANCIALS: {
    URL: "/admin/service-desk/request/:requestId/customer-financial-detail",
    METHOD: "GET",
  },
  GET_CUSTOMER_REQUIREMENTS: {
    URL: "/admin/service-desk/request/:requestId/customer-requirement",
    METHOD: "GET",
  },
  GET_REPAYMENT_SCHEDULE: {
    URL: "/admin/business/loan/:loanId/repayment-schedule",
    METHOD: "GET",
  },
  SAVE_CM_OFFER_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/calculate-cm-offer",
    METHOD: "POST",
  },
  GET_CM_OFFER_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/cm-offer",
    METHOD: "GET",
  },
  GET_RENEW_REBOOK_CONFIG: {
    URL: "/admin/service-desk/config",
    METHOD: "GET",
  },
  GET_CM_CONFIG: {
    URL: "/admin/service-desk/request/:requestId/cm-config",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/loan-renewal-closure-detail", // igClosureDate, loanType
    METHOD: "GET",
  },
  GET_LOAN_PART_RELEASE_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/part-release-detail",
    METHOD: "GET",
  },
  CLOSE_LOAN_RENEWAL: {
    URL: "/admin/service-desk/request/:requestId/close-loan-renewal",
    METHOD: "POST",
  },
  CLOSE_LOAN_PART_RELEASE: {
    URL: "/admin/service-desk/request/:requestId/close-loan-part-release",
    METHOD: "POST",
  },
  GET_LOAN_RENEWAL_MAPPED_PAYMENTS: {
    URL: "/admin/service-desk/request/:requestId/loan-renewal-payment",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_STATUS: {
    URL: "/admin/service-desk/loan/request/:serviceRequestId/loan-detail",
    METHOD: "GET",
  },
  UPDATE_ROI: {
    URL: "/admin/service-desk/request/:requestId/update-cm-offer-roi",
    METHOD: "PUT",
  },
  GET_USER_ACCEPTED_OFFER: {
    URL: "/admin/service-desk/request/:requestId/renewal-consumer-offer-detail",
    METHOD: "GET",
  },
  GET_GOLD_RATE: {
    URL: "/admin/service-desk/loan/:loanId/gold-rate",
    METHOD: "GET",
  },
  GET_CM_ADJUSTMENT_RENEWAL_DETAILS: {
    URL: "/admin/service-desk/renew-rebook/request/:requestId/cm-adjustment-renewal-detail",
    METHOD: "GET",
  },
  UPDATE_CM_ADJUSTMENT_RENEWAL_DETAILS: {
    URL: "/admin/service-desk/renew-rebook/request/:requestId/cm-adjustment-renewal-amount",
    METHOD: "POST",
  },
  GET_OFFER_EXPIRY_STATUS: {
    URL: "/admin/service-desk/renew-rebook/request/:requestId/is-offer-expired",
    METHOD: "GET",
  },
};

const renewRebookApi: RenewRebookApiInterface = {
  saveCustomerReqDetails: async ({requestId, formData}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.SAVE_CUSTOMER_REQUIREMENT_DETAILS,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {
          requestId,
        },
        data: formData,
      },
    });
  },
  getOurOffer: async ({requestId}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_OUR_OFFER,
      options: {
        pathVars: {
          requestId,
        },
      },
    });
    if (error) {
      return {ourOffer: null, error};
    }
    return {ourOffer: response, error: null};
  },
  getVersionHistory: async ({requestId, loanRenewalOfferBy}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_VERSION_HISTORY,
      options: {
        pathVars: {
          requestId,
        },
        queryParams: {
          loanRenewalOfferBy,
        },
      },
    });
    if (error) {
      return {versionHistory: [], error};
    }
    return {versionHistory: response, error: null};
  },
  updateShareStatus: async ({requestId, shareWith, expectedPaymentDate}) => {
    const queryParams: Record<string, any> = {
      shareWith,
    };

    if (expectedPaymentDate) {
      queryParams.expectedPaymentDate = expectedPaymentDate;
    }

    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.UPDATE_SHARE_STATUS,
      options: {
        pathVars: {requestId},
        queryParams,
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  getNetPayable: async ({loanId, requestId}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_NET_PAYABLE,
      options: {
        pathVars: {
          loanId,
          requestId,
        },
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  getOrnamentDetails: async ({
    requestId,
    loanId,
    isAgriOptionSelectedByCM,
  }) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_ORNAMENT_DETAILS,
      options: {
        pathVars: {loanId, requestId},
        queryParams: {
          isAgriOptionSelectedByCM,
        },
      },
    });
  },
  updateOrnamentDetails: async (payload) => {
    const {
      loanId,
      requestId,
      ornamentId,
      isAgriOptionSelectedByCM,
      ...data
    } = payload;
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.UPDATE_ORNAMENT_DETAILS,
      options: {
        pathVars: {
          loanId: loanId,
          requestId: requestId,
          ornamentId: ornamentId,
        },
        data: {...data},
        queryParams: {
          isAgriOptionSelectedByCM,
        },
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  getCustomerFinancials: async ({requestId}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_CUSTOMER_FINANCIALS,
      options: {
        pathVars: {requestId},
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  getCustomerRequirements: async ({requestId}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_CUSTOMER_REQUIREMENTS,
      options: {
        pathVars: {requestId},
      },
    });
    if (error) {
      return {response: null, error};
    }
    return {response, error: null};
  },
  getRepaymentHistory: async ({loanId}) => {
    const {response, error} = await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_REPAYMENT_SCHEDULE,
      options: {
        pathVars: {loanId},
      },
    });
    if (error) {
      return {repaymentHistory: [], error};
    }
    return {repaymentHistory: response, error: null};
  },
  saveCMOfferDetails: async ({
    requestId,
    isAgriOptionSelectedByCM,
    ...payload
  }) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.SAVE_CM_OFFER_DETAILS,
      options: {
        pathVars: {requestId},
        queryParams: {
          isAgriOptionSelectedByCM,
        },
        data: {
          ...payload,
        },
      },
    });
  },
  getCMOfferDetails: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_CM_OFFER_DETAILS,
      options: {pathVars: {requestId}},
    });
  },
  getRenewRebookConfig: async () => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_RENEW_REBOOK_CONFIG,
    });
  },
  getCMConfig: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_CM_CONFIG,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getPaymentDetails: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_PAYMENT_DETAILS,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getLoanRenewalClosureDetail: async ({
    requestId,
    requestCategory,
    ...payload
  }) => {
    return await goldApiService({
      resource:
        requestCategory === "PART_RELEASE"
          ? RENEW_REBOOK_END_POINTS.GET_LOAN_PART_RELEASE_CLOSURE_DETAIL
          : RENEW_REBOOK_END_POINTS.GET_LOAN_RENEWAL_CLOSURE_DETAIL,
      options: {
        pathVars: {requestId},
        queryParams: {
          ...payload,
        },
      },
    });
  },
  closeLoanRenewal: async ({
    requestId,
    loanType,
    igClosureDate,
    lenderClosureAmount,
    lenderClosureDate,
    waiveOffAmount,
    dummyReceiptAmount,
    requestCategory,
  }) => {
    return await goldApiService({
      resource:
        requestCategory === ServiceRequestCategory.PART_RELEASE
          ? RENEW_REBOOK_END_POINTS.CLOSE_LOAN_PART_RELEASE
          : RENEW_REBOOK_END_POINTS.CLOSE_LOAN_RENEWAL,
      options: {
        pathVars: {requestId},
        data: {
          loanType,
          igClosureDate,
          lenderClosureAmount,
          lenderClosureDate,
          waiveOffAmount,
          dummyReceiptAmount,
        },
      },
    });
  },
  getLoanRenewalMappedPayments: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_LOAN_RENEWAL_MAPPED_PAYMENTS,
      options: {
        pathVars: {requestId},
      },
    });
  },
  getLoanRenewalStatus: async ({requestId, loanType}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_LOAN_RENEWAL_STATUS,
      options: {
        pathVars: {serviceRequestId: requestId},
        queryParams: {
          loanType,
        },
      },
    });
  },
  updateRoi: async ({requestId, formData}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.UPDATE_ROI,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {requestId},
        data: formData,
      },
    });
  },
  getUserAcceptedOffer: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_USER_ACCEPTED_OFFER,
      options: {
        pathVars: {requestId},
      },
    });
  },

  getGoldRate: async ({loanId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_GOLD_RATE,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getCMAdjustmentRenewalDetails: async ({requestId}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_CM_ADJUSTMENT_RENEWAL_DETAILS,
      options: {
        pathVars: {requestId},
      },
    });
  },
  updateCMAdjustmentRenewalDetails: async ({requestId, data}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.UPDATE_CM_ADJUSTMENT_RENEWAL_DETAILS,
      options: {
        pathVars: {requestId},
        data,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getOfferExpiryStatus: async ({requestId, offerBy}) => {
    return await goldApiService({
      resource: RENEW_REBOOK_END_POINTS.GET_OFFER_EXPIRY_STATUS,
      options: {
        pathVars: {requestId},
        queryParams: {
          offerBy,
        },
      },
    });
  },
};

export {RENEW_REBOOK_END_POINTS};

export default renewRebookApi;
