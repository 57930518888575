import styled from "@emotion/styled";

const ZoomOutIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  position: relative;
  top: 0.5rem;
  right: 0.5rem;
  height: 0;
`;

export default ZoomOutIconWrapper;
