import React from "react";
import {useParams} from "react-router-dom";
import {Box, Typography, Stack, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {IGDialog, IGFileUpload, IGSelect, IGTextField} from "app/components";
import {closeModal} from "app/store/customerProfile/customerProfile.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {useIGDialog} from "app/store/hooks/IGDialogHook";
import IGKeyValue from "app/components/IGLabelValue";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {loanPaymentActions, loanPaymentSelectors} from "app/store/loanPayment";

interface ShiftPaymentFormData {
  paymentId: number;
  paymentAmount: number;
  reason: string;
  otherReason: string;
  igApprovalFile: any;
  shivalikApprovalFile: any;
  isDoneAtShivalikEnd: boolean;
}

const ShiftPaymentModal = ({
  paymentId,
  paymentAmount,
}: {
  paymentId: number;
  paymentAmount: number;
}) => {
  const dispatch = useAppDispatch();
  const {loanId} = useParams();
  const {showConfirmDialog} = useIGDialog();
  const {
    shiftLoanPayment: shiftLoanPaymentLoading,
  } = useAppSelector(loanPaymentSelectors.getLoading);
  const [formData, setFormData] = React.useState<ShiftPaymentFormData>({
    paymentId: paymentId ?? 0,
    paymentAmount: paymentAmount ?? 0,
    reason: "",
    otherReason: "",
    igApprovalFile: [],
    shivalikApprovalFile: [],
    isDoneAtShivalikEnd: false,
  });

  const isSubmitDisabled =
    !formData.reason ||
    (formData.reason === "Other" && !formData.otherReason) ||
    !formData.igApprovalFile.length ||
    !formData.shivalikApprovalFile.length ||
    formData.isDoneAtShivalikEnd === false;

  const onSubmit = (formPayload: ShiftPaymentFormData) => {
    showConfirmDialog({
      content: (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography fontWeight="bold" variant="h6">
            Confirm Shift Payment
          </Typography>
          <Stack
            mt={2}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "4px",
              backgroundColor: "#F9F9F9",
              padding: "16px",
              width: "550px",
            }}
          >
            <IGKeyValue label="Payment ID" value={formData.paymentId ?? 0} />
            <IGKeyValue
              label="Payment Amount"
              value={numberWithCurrencyAndComma(formData.paymentAmount)}
            />
            <IGKeyValue
              label="Reason"
              value={
                formData.reason === "Other"
                  ? formData.otherReason
                  : formData.reason
              }
            />
            <IGKeyValue
              label="IG Approval File"
              value={formData.igApprovalFile[0]?.name}
            />
            <IGKeyValue
              label="Shivalik Approval File"
              value={formData.shivalikApprovalFile[0]?.name}
            />
          </Stack>
        </Box>
      ),
      onConfirm: () => {
        // Submit the form
        const formData = new FormData();
        formData.append("shiftLoanPaymentRequestDto", JSON.stringify({
          userGoldLoanPaymentId: paymentId,
          targetLoanId: loanId,
          shiftLoanPaymentReason: formPayload.reason,
          otherReason: formPayload.otherReason,
          isDoneAtShivalikEnd: formPayload.isDoneAtShivalikEnd,
        }));
        formData.append("igApprovalFile", formPayload.igApprovalFile[0]);
        formData.append(
          "shivalikApprovalFile",
          formPayload.shivalikApprovalFile[0],
        );
        dispatch(loanPaymentActions.shiftLoanPayment({
          formData,
        }));
      },
      maxWidth: "sm",
    });
  };

  return (
    <IGDialog
      title="Shift Payment"
      open={true}
      onClose={() => {
        dispatch(closeModal("shiftPayment"));
      }}
      onCancel={() => {
        dispatch(closeModal("shiftPayment"));
      }}
      confirmProps={{
        text: "Submit",
        disabled: isSubmitDisabled,
        loading: shiftLoanPaymentLoading,
      }}
      onConfirm={() => {
        onSubmit(formData);
      }}
    >
      <Box>
        <Typography color="#FF7043">
          Please verify the shift payment details as this action cannot be
          reversed!
        </Typography>
        <Stack spacing={2} mt={2}>
          <IGTextField
            name="paymentId"
            label="Payment ID"
            value={paymentId ?? ""}
            disabled
          />
          <IGTextField
            name="paymentAmount"
            label="Payment Amount"
            value={formData.paymentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
            disabled
          />
          <IGSelect
            name="reason"
            label="Reason"
            options={[
              {text: "Customer Request", value: "Customer Request"},
              {text: "System Error", value: "System Error"},
              {text: "Other", value: "Other"},
            ]}
            value={formData.reason}
            onChange={(e) => {
              setFormData({...formData, reason: e.target.value});
            }}
            required
          />
          <IGTextField
            name="otherReason"
            label="Other Remarks"
            placeholder="Enter your remarks here..."
            multiline
            rows={3}
            value={formData.otherReason}
            onChange={(e) => {
              setFormData({...formData, otherReason: e.target.value});
            }}
            required={formData.reason === "Other"}
          />
          <Box>
            <Typography variant="body2" color="textSecondary">
              IG Approval Screenshots
            </Typography>
            <IGFileUpload
              text=""
              value={formData.igApprovalFile as any}
              onChange={(file) => {
                setFormData({...formData, igApprovalFile: file});
              }}
              draggable={false}
              inputProps={{
                accept: "image/png, image/jpeg, image/jpg, application/pdf",
              }}
            />
          </Box>
          <Box>
          <FormControl>
            <FormLabel id="shivalik-radio">
              Have you received confirmation from shivalik?
            </FormLabel>
            <RadioGroup
            row
              aria-labelledby="shivalik-radio"
              defaultValue="false"
              name="radio-buttons-group"
              onChange={(e) => {
                setFormData({...formData, isDoneAtShivalikEnd: e.target.value === "true"});
              }}
            >
              <FormControlLabel value="true" control={<Radio />} label="Yes" />
              <FormControlLabel value="false" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Shivalik Approval Screenshots
            </Typography>
            <IGFileUpload
              text=""
              value={formData.shivalikApprovalFile as any}
              onChange={(file) => {
                setFormData({...formData, shivalikApprovalFile: file});
              }}
              draggable={false}
              inputProps={{
                accept: "image/png, image/jpeg, image/jpg, application/pdf",
              }}
            />
          </Box>
        </Stack>
      </Box>
    </IGDialog>
  );
};

export default ShiftPaymentModal;
