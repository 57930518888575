import {createAction} from "@reduxjs/toolkit";
import {
  GetDisbursedJobOrnamentsPayload,
  OrnamentsResponse,
  CrIdPayload,
  UpdateOrnamentDetailsPayload,
  SelectedOrnamentPayload,
  LenderPayload,
  DownloadOrnamentCsvPayload,
} from "./ornaments.types";

const ornamentsActionTypes = {
  GetDisbursedJobsOrnamentsList:
    "[Ornaments] Get Disbursed Jobs Ornaments List",
  GetDisbursedJobsOrnamentsListSuccess:
    "[Ornaments] Get Disbursed Jobs Ornaments List Success",
  GetDisbursedJobsOrnamentsListFailure:
    "[Ornaments] Get Disbursed Jobs Ornaments List Failure",
  GetOrnamentsNamesList: "[Ornaments] Get Ornaments Names List",
  GetOrnamentsNamesListSuccess: "[Ornaments] Get Ornaments Names List Success",
  GetOrnamentsNamesListFailure: "[Ornaments] Get Ornaments Names List Failure",
  GetSelectedOrnamentDetails: "[Ornaments] Get Selected Ornament Details",
  GetSelectedOrnamentDetailsSuccess:
    "[Ornaments] Get Selected Ornament Details Success",
  GetSelectedOrnamentDetailsFailure:
    "[Ornaments] Get Selected Ornament Details Failure",
  GetOrnamentsList: "[Ornaments] Get Ornaments List",
  GetOrnamentsListSuccess: "[Ornaments] Get Ornaments List Success",
  GetOrnamentsListFailure: "[Ornaments] Get Ornaments List Failure",
  GetOrnamentsFinenessConfig: "[Ornaments] Get Ornaments Fineness Config",
  GetOrnamentsFinenessConfigSuccess:
    "[Ornaments] Get Ornaments Fineness Config Success",
  GetOrnamentsFinenessConfigFailure:
    "[Ornaments] Get Ornaments Fineness Config Failure",
  GetOrnamentsPacket: "[Ornaments] Get Ornaments Packet",
  GetOrnamentsPacketSuccess: "[Ornaments] Get Ornaments Packet Success",
  GetOrnamentsPacketFailure: "[Ornaments] Get Ornaments Packet Failure",
  UpdateOrnamentDetails: "[Ornaments] Update Ornament Details",
  UpdateOrnamentDetailsSuccess: "[Ornaments] Update Ornament Details Success",
  UpdateOrnamentDetailsFailure: "[Ornaments] Update Ornament Details Failure",
  DownloadOrnamentsCsv: "[Ornaments] Download Ornaments Csv ",
  DownloadOrnamentsCsvSuccess: "[Ornaments] Download Ornaments Csv Success",
  DownloadOrnamentsCsvFailure: "[Ornaments] Download Ornaments Csv Failure",
};

const ornamentsActions = {
  getDisbursedJobsOrnamentsList: createAction<GetDisbursedJobOrnamentsPayload>(
    ornamentsActionTypes.GetDisbursedJobsOrnamentsList,
  ),
  getDisbursedJobsOrnamentsListSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetDisbursedJobsOrnamentsListSuccess,
  ),
  getDisbursedJobsOrnamentsListFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetDisbursedJobsOrnamentsListFailure,
  ),
  getOrnamentsNamesList: createAction<CrIdPayload & LenderPayload>(
    ornamentsActionTypes.GetOrnamentsNamesList,
  ),
  getOrnamentsNamesListSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsNamesListSuccess,
  ),
  getOrnamentsNamesListFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsNamesListFailure,
  ),
  getSelectedOrnamentDetails: createAction<
    SelectedOrnamentPayload & LenderPayload
  >(ornamentsActionTypes.GetSelectedOrnamentDetails),
  getSelectedOrnamentDetailsSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetSelectedOrnamentDetailsSuccess,
  ),
  getSelectedOrnamentDetailsFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetSelectedOrnamentDetailsFailure,
  ),
  getOrnamentsList: createAction<CrIdPayload & LenderPayload>(
    ornamentsActionTypes.GetOrnamentsList,
  ),
  getOrnamentsListSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsListSuccess,
  ),
  getOrnamentsListFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsListFailure,
  ),
  getOrnamentsFinenessConfig: createAction<CrIdPayload & LenderPayload>(
    ornamentsActionTypes.GetOrnamentsFinenessConfig,
  ),
  getOrnamentsFinenessConfigSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsFinenessConfigSuccess,
  ),
  getOrnamentsFinenessConfigFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsFinenessConfigFailure,
  ),
  getOrnamentsPacket: createAction<CrIdPayload>(
    ornamentsActionTypes.GetOrnamentsPacket,
  ),
  getOrnamentsPacketSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsPacketSuccess,
  ),
  getOrnamentsPacketFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.GetOrnamentsPacketFailure,
  ),
  updateOrnamentDetails: createAction<{
    crId: number;
    payload: UpdateOrnamentDetailsPayload;
  }>(ornamentsActionTypes.UpdateOrnamentDetails),
  updateOrnamentDetailsSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.UpdateOrnamentDetailsSuccess,
  ),
  updateOrnamentDetailsFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.UpdateOrnamentDetailsFailure,
  ),
  downloadOrnamentsCsv: createAction<DownloadOrnamentCsvPayload>(
    ornamentsActionTypes.DownloadOrnamentsCsv,
  ),
  downloadOrnamentsCsvSuccess: createAction<OrnamentsResponse>(
    ornamentsActionTypes.DownloadOrnamentsCsvSuccess,
  ),
  downloadOrnamentsCsvFailure: createAction<OrnamentsResponse>(
    ornamentsActionTypes.DownloadOrnamentsCsvFailure,
  ),
};

export default ornamentsActions;

export {ornamentsActionTypes};
