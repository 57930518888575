import React, {useCallback, useEffect, useState} from "react";
import {Grid} from "@mui/material";

import Container from "../styles/Container";
import DetailContainer from "./styles/DetailContainer";
import Title from "../styles/Title";
import SubText from "../styles/SubText";
import ButtonWithLoader from "app/components/Button/ButtonWithLoader";
import getCustomerLocationCoordinates from "api/business/getLocationCoordinate";

import GoldenDivider from "./styles/GoldenDivider";
import StyledDocumentContainer from "./styles/KycDocumentContainer";

import getDocument from "api/business/getDocument";
import getMainKYCDocument from "api/business/getMainKycDocument";
import getAllAddress from "api/business/getAllAddress";
import getDocumentAddress from "api/business/getDocumentAddress";
import {
  StartKyc as startKycApi,
  KycStatus as kycStatusApi,
} from "api/business/kyc";
import UpdateName from "./UpdateNameModal";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import CifValidation from "./Shivalik/CifValidation";

import {
  loanKycDocumentTypeEnum as docType,
  loanKycStatusEnum,
} from "app/enums/gold_loan/activeLoans/index";
import DocumentContainerComponent from "./components/DocumentContainer";
import CustomerDetails from "./components/CustomerDetails";
import {authSelectors} from "app/store/auth";
import DocViewModals from "./components/DocViewModals";
import {useIGTranslation} from "app/infra/plugins/i18n";
import getPartnerLeadSource from "api/activeLoan/getLeadSource";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {featureFlagSelectors} from "app/store/featureFlags";
import {activeLoansSelectors} from "app/store/activeLoans";

const KycDetails = ({
  lenderDetails,
  crId,
  hideSensitiveInfo,
  customerName,
  personalDetail,
  setKycStatusCheck,
  setShowKycDetailsAlert,
}) => {
  const {lenderName} = lenderDetails;
  const {t} = useIGTranslation();
  const {showError, showSuccess, showWarning} = useSnackBar();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );
  const selectedCr = useAppSelector(activeLoansSelectors.getSelectedCR);
  const [checkButtonDisable, setCheckButtonDisable] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [kycStatusDetail, setKycStatusDetail] = useState({
    status: null,
  });
  const [loading, setLoading] = useState(false);

  const [mainDocumentType, setMainDocumentType] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [documentAddress, setDocumentAddress] = useState();
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [docView, setDocView] = useState(null);
  const [documents, setDocuments] = useState({
    mainDocument: null,
    aadhar: null,
    pan: null,
    voterId: null,
    drivingLicense: null,
    customerImage: null,
    customerHomeImage: null,
  });
  const [permanentAddress, setPermanentAddress] = useState();
  const [residentialAddress, setResidentialAddress] = useState();
  const [partnerLeadSource, setPartnerLeadSource] = useState("");
  const [isBlacklistCheckRequired, setIsBlacklistCheckRequired] = useState(
    null,
  );
  const fullName = Object.values(customerName).join(" ");
  const {isDataPurged} = selectedCr || {};

  const onLocationClickHandler = useCallback(() => {
    (async () => {
      const response = await getCustomerLocationCoordinates({
        accessToken,
        crId: crId,
        locationType: "CUSTOMER_LOCATION_FROM_LM",
      });
      if (response.statusCode === 200) {
        const location = response.payload.locationCoordinates;

        if (location === null) {
          showError("Location not available");
          return;
        }
        const locationArray = location.split(",");

        const lat = locationArray[0];
        const lng = locationArray[1];

        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lng},${lat}`,
        );
      }
    })();
  }, [crId, showError, accessToken]);

  const fetchKycStatus = useCallback(
    async (documentType, showMessage = false) => {
      try {
        const {response, statusCode, error} = await kycStatusApi({
          crId,
        });

        if (statusCode === 200 && !error) {
          setKycStatusDetail(response);

          setKycStatusCheck(response);

          if (response.status !== loanKycStatusEnum.FAILED) {
            if (documentType !== null) {
              setSelectedDocument(documentType);

              setMainDocumentType(documentType);

              if (lenderName === "PIRAMAL") {
                const {
                  response: docPayload,
                  error: docError,
                } = await getDocumentAddress({crId});

                if (!docError) {
                  setDocumentAddress(docPayload);
                }
              }
            }
            setCheckButtonDisable(true);
            if (showMessage) showSuccess("KYC process initiated");
          } else {
            setCheckButtonDisable(false);
          }
        } else {
          showError(error || "Failed to fetch kyc status");
        }
      } catch (error) {
        showError("Something went wrong");
      }
    },
    [crId, showSuccess, showError, setKycStatusCheck, lenderName],
  );

  const startKyc = useCallback(async () => {
    setLoading(true);
    if (checkButtonDisable) {
      fetchKycStatus(mainDocumentType, true);
    } else {
      if (selectedDocument === "") {
        showWarning("Please select document type");
        setLoading(false);
        return;
      }
      setCheckButtonDisable(true);
      try {
        const {response, error} = await startKycApi({
          crId,
          documentType: selectedDocument,
        });

        if (!error) {
          showSuccess(response.message);

          setCheckButtonDisable(true);
          fetchKycStatus(mainDocumentType);
        } else {
          setCheckButtonDisable(false);
          showError(error.message || "Failed to start kyc");
        }
      } catch (error) {
        showError("Something went wrong");
      }
    }

    setLoading(false);
  }, [
    crId,
    showError,
    showWarning,
    showSuccess,
    selectedDocument,
    checkButtonDisable,
    fetchKycStatus,
    mainDocumentType,
  ]);

  const fetchDocuments = useCallback(async () => {
    setDocumentsLoading(true);
    const {payload, error, statusCode} = await getDocument({
      accessToken: accessToken,
      crId,
      documentType: [
        docType.AADHAR,
        docType.VOTER_ID,
        docType.DRIVING_LICENSE,
        docType.CUSTOMER_IMAGE,
        docType.PAN,
        docType.CUSTOMER_HOME_IMAGE,
      ],
    });

    if (statusCode === 200) {
      if (payload.length > 0) {
        setDocuments((documents) => ({
          ...documents,
          aadhar: payload.find((doc) => doc.documentType === docType.AADHAR),
          voterId: payload.find((doc) => doc.documentType === docType.VOTER_ID),
          drivingLicense: payload.find(
            (doc) => doc.documentType === docType.DRIVING_LICENSE,
          ),
          customerImage: payload.find(
            (doc) => doc.documentType === docType.CUSTOMER_IMAGE,
          ),
          pan: payload.find((doc) => doc.documentType === docType.PAN),
          customerHomeImage: payload.find(
            (doc) => doc.documentType === docType.CUSTOMER_HOME_IMAGE,
          ),
        }));
      }

      if (lenderName === "PIRAMAL") {
        const {
          payload: mainPayload,
          statusCode: mainStatusCode,
        } = await getMainKYCDocument({
          accessToken: accessToken,
          crId,
        });

        if (mainStatusCode === 200) {
          setDocuments((documents) => ({
            ...documents,
            mainDocument: mainPayload,
          }));
          setMainDocumentType(mainPayload?.mainKycDocument);
          if (mainPayload?.mainKycDocument) {
            fetchKycStatus(mainPayload.mainKycDocument);
          } else {
            setCheckButtonDisable(false);
          }
        }
      }
    } else {
      showError(error || "Failed to fetch documents");
    }
    setDocumentsLoading(false);
  }, [accessToken, crId, fetchKycStatus, showError, lenderName]);

  const fetchAddress = useCallback(async () => {
    const {response, statusCode, error} = await getAllAddress({
      crId: crId,
      addressType: "PERMANENT_ADDRESS,RESIDENTIAL_ADDRESS",
    });

    if (statusCode === 200) {
      const permanentAdr = response.filter(
        (address) => address.addressType === "PERMANENT_ADDRESS",
      );
      const residentialAdr = response.filter(
        (address) => address.addressType === "RESIDENTIAL_ADDRESS",
      );

      if (permanentAdr.length > 0) {
        setPermanentAddress(permanentAdr[0]);
      }
      if (residentialAdr.length > 0) {
        setResidentialAddress(residentialAdr[0]);
      }

      if (lenderName === "PIRAMAL") {
        const {
          response: docPayload,
          error: docError,
        } = await getDocumentAddress({crId});

        if (!docError) {
          setDocumentAddress(docPayload);
        }
      }
    } else {
      showError(error.message || "Failed to fetch address");
    }
  }, [crId, showError, lenderName]);

  const fetchPartnerLeadSource = useCallback(async () => {
    const {response, error} = await getPartnerLeadSource({
      crId,
    });
    if (!error) {
      setPartnerLeadSource(response.leadPartner);
    } else {
      showError(error.message);
    }
  }, [crId]);

  const fetchBlackListCheckRequired = useCallback(async () => {
    const {response, error} = await activeLoansApi.getBlackListCheckRequired(
      crId,
    );
    if (!error) {
      setIsBlacklistCheckRequired(response);
    } else {
      showError(error.message);
    }
  }, [crId, showError]);

  useEffect(() => {
    fetchDocuments();
    fetchAddress();
    fetchPartnerLeadSource();
    fetchBlackListCheckRequired();
  }, []);

  useEffect(() => {
    if (isBlacklistCheckRequired === false) {
      setShowKycDetailsAlert(false);
    } else if (isBlacklistCheckRequired === true && documents) {
      if (documents.aadhar?.isBlackListed || documents.pan?.isBlackListed) {
        setShowKycDetailsAlert(true);
      }
    }
  }, [documents, isBlacklistCheckRequired, setShowKycDetailsAlert]);

  const selectKycDocument = useCallback(
    (document) => {
      if (selectedDocument === document) {
        setSelectedDocument("");
      } else {
        setSelectedDocument(document);
      }
    },
    [selectedDocument],
  );

  const kycStatusRow = useCallback(() => {
    switch (kycStatusDetail.status) {
      case loanKycStatusEnum.FAILED:
        return <Title className="text-danger">{t("FAILED")}</Title>;
      case loanKycStatusEnum.COMPLETED:
        return <Title className="text-success">{t("SUCCESS")}</Title>;
      case loanKycStatusEnum.AADHAAR_VERIFIED:
        return <Title className="text-success">{t("SUCCESS")}</Title>;
      default:
        return <Title className="text-danger">{t("PENDING")}</Title>;
    }
  }, [kycStatusDetail.status, t]);

  const renderDocuments = useCallback(() => {
    const documentsToRender = ["aadhar", "pan"];

    if (!isMonitoringCheckActive && !isDataPurged) {
      documentsToRender.push("voterId", "drivingLicense");
    }
    return (
      <>
        {Object.keys(documents).map((key) => {
          if (!documentsToRender.includes(key)) {
            return <></>;
          }

          let documentTitle, documentType;
          const document = documents[key];

          switch (key) {
            case "aadhar":
              documentTitle = docType.AADHAR;
              documentType = docType.AADHAR;
              break;
            case "voterId":
              documentTitle = "VOTER ID";
              documentType = docType.VOTER_ID;
              break;
            case "drivingLicense":
              documentTitle = "DRIVING LICENSE";
              documentType = docType.DRIVING_LICENSE;
              break;
            case "pan":
              documentTitle = "PAN";
              documentType = docType.PAN;
              break;
            default:
              break;
          }

          const renderDivider = () => {
            if (
              ["aadhar", "pan"].includes(key) ||
              lenderName === "PIRAMAL" ||
              hideSensitiveInfo
            ) {
              return true;
            }
            return false;
          };

          return (
            <Grid item xs={12} md={6}>
              <DocumentContainerComponent
                key={key}
                documentsLoading={documentsLoading}
                selectedDocument={selectedDocument}
                selectKycDocument={selectKycDocument}
                document={document}
                setOpenModal={() => setDocView(documentType)}
                checkButtonDisable={checkButtonDisable}
                isChecked={selectedDocument === documentType}
                onCheck={() => selectKycDocument(documentType)}
                title={documentTitle}
                borderLeft={["pan", "drivingLicense"].includes(key)}
                renderSwitch={
                  documentType !== docType.PAN && lenderName === "PIRAMAL"
                }
              />
              {renderDivider() ? <GoldenDivider /> : <></>}
            </Grid>
          );
        })}
      </>
    );
  }, [
    documents,
    checkButtonDisable,
    documentsLoading,
    selectedDocument,
    selectKycDocument,
    lenderName,
    hideSensitiveInfo,
  ]);

  return (
    <>
      <Container>
        {isMonitoringCheckActive && isDataPurged ? null : (
          <CustomerDetails
            lenderDetails={lenderDetails}
            setOpenNameModal={setOpenNameModal}
            fullName={fullName}
            permanentAddress={permanentAddress}
            personalDetail={personalDetail}
            residentialAddress={residentialAddress}
            onLocationClickHandler={onLocationClickHandler}
            customerImage={documents.customerImage}
            setOpenCustomerImageModal={() => setDocView(docType.CUSTOMER_IMAGE)}
            customerHomeImage={documents.customerHomeImage}
            setOpenCustomerHomeImageModal={() =>
              setDocView(docType.CUSTOMER_HOME_IMAGE)
            }
            partnerLeadSource={partnerLeadSource}
          />
        )}

        <GoldenDivider />

        <Grid container rowSpacing={2}>
          {renderDocuments()}

          {lenderName === "PIRAMAL" ? (
            <Grid item xs={12} md={12}>
              {/* KYC Status */}
              <StyledDocumentContainer height="100">
                <DetailContainer>
                  <SubText>KYC Status</SubText>
                  <>{kycStatusRow()}</>
                </DetailContainer>
                <DetailContainer>
                  <SubText>KYC Remark</SubText>
                  <Title>
                    {kycStatusDetail?.remark ? kycStatusDetail.remark : "N/A"}
                  </Title>
                </DetailContainer>

                {!hideSensitiveInfo && (
                  <ButtonWithLoader
                    loading={loading}
                    onClick={() => {
                      startKyc();
                    }}
                    disabled={
                      loading ||
                      kycStatusDetail.status === loanKycStatusEnum.COMPLETED
                    }
                  >
                    {checkButtonDisable ? "REFRESH" : "START KYC"}
                  </ButtonWithLoader>
                )}
              </StyledDocumentContainer>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        {/* All Modal */}
        <DocViewModals
          docView={docView}
          setDocView={setDocView}
          documents={documents}
          mainDocumentType={mainDocumentType}
          documentAddress={documentAddress}
          permanentAddress={permanentAddress}
          customerName={customerName}
          personalDetail={personalDetail}
          residentialAddress={residentialAddress}
        />

        {fullName && (
          <UpdateName
            open={openNameModal}
            onClose={() => setOpenNameModal(false)}
            customerName={customerName}
            crId={crId}
          />
        )}

        {lenderName === "SHIVALIK" && !hideSensitiveInfo ? (
          <GoldenDivider />
        ) : (
          <></>
        )}
        {lenderName === "SHIVALIK" ? (
          <CifValidation
            hideSensitiveInfo={hideSensitiveInfo}
            crId={crId}
            lenderName={lenderName}
          />
        ) : (
          <> </>
        )}
      </Container>
    </>
  );
};

export default KycDetails;

// TODO:

// - Hide bottom modal from customer photo modal
// Show kyc address section only when modalDOc == main doc
// DOC Number in Bottom modal - show only the relevant doc number (Don't pick from main document)
//
