import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    cursor: "pointer",
    padding: 0,
  },
  stepper: {
    position: "relative",
  },
  iconContainer: {
    zIndex: 100,
    textAlign: "center",
    padding: "0.5rem",
    cursor: "pointer",
    color: "#00b3a6",
    "& svg": {
      fontSize: "3.5rem",
    },
  },
  iconCompleted: {
    background: "rgba(18,18,18,0.05)",
    border: "2px solid #00b3a6",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#00b3a6",
  },
  success: {
    background: "#00b3a6",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  yellow: {
    background: "#ce9f29",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  error: {
    background: "white",
    width: "16px",
    height: "16px",
    borderRadius: "50%",
  },
  stepperWrapper: {
    background: "rgba(18,18,18,0.05)",
    padding: "1rem",
    borderRadius: 4,
  },
  verticalStepper: {
    marginLeft: "8px",
  },
  link: {
    textDecoration: "underline",
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
