import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";
import NewSnackbar from "app/components/Snackbar2";
import Routes from "./app/router/Routes";
import firebase from "./firebase";
import IGDialogHook from "./app/components/IGDialog/IGDialogHook";
import AmeyoToolbar from "app/components/Ameyo";
import SessionTimeout from "app/pages/home/SessionTimeout";
import "tippy.js/dist/tippy.css";
import {useIGTranslation} from "app/infra/plugins/i18n";

firebase();
const {hostname} = window.location;
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const App: React.FC = () => {
  const {t} = useIGTranslation();

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID || ""}
    >
      <SessionTimeout />
      {hostname !== t("LENDER_INDIAGOLD_CO") && (
        <AmeyoToolbar />
      )}
      <ScrollToTop />
      <NewSnackbar />
      <IGDialogHook />
      <Routes />
    </GoogleOAuthProvider>
  );
};

export default App;
