import React, {useCallback, useEffect} from "react";
import {Link} from "react-router-dom";
import {
  List,
  ListItemText,
  ListItemIcon,
  Collapse,
  ListItemButton,
  Box,
} from "@mui/material";
import {MenuRounded, ExpandLess, ExpandMore} from "@mui/icons-material";
import styled from "@emotion/styled";
import tippy from "tippy.js";

const StyledMenuItem = styled(ListItemButton)`
  background-color: ${({active}) =>
    active ? "rgba(206,159,41,0.08)" : "transparent"};
`;

const StyledSubMenu = styled(Collapse)`
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const LayoutMenu = ({
  open,
  menuList,
  activeTab,
  activeSubMenu,
  activeMenu,
  toggleMenu,
  setOpen,
  setActiveMenu,
  setToggleMenu,
  setActiveSubMenu,
}) => {
  const assignTooltips = useCallback(() => {
    menuList.forEach((menu) => {
      tippy(`#${menu.path}`, {
        content: menu.name,
        placement: "right",
        arrow: true,
        animation: "fade",
      });
    });
  }, [menuList]);

  useEffect(() => {
    assignTooltips();
  }, [assignTooltips]);

  if (menuList.length === 0) return <></>;

  return menuList.map((menuItem) => (
    <React.Fragment key={menuItem.path}>
      <Link to={`/${activeTab.path}/${menuItem.path}`}>
        <StyledMenuItem
          id={menuItem.path}
          button
          style={{
            padding: "1rem",
            borderRadius: "4px",
          }}
          active={activeMenu.path === menuItem.path}
          onClick={() => {
            setActiveMenu(menuItem);

            if (menuItem.isMultiMenu) {
              setToggleMenu((prevMenu) =>
                menuItem.path === prevMenu ? null : menuItem.path,
              );
            }

            if (!open) {
              setOpen(true);
            }
          }}
        >
          <ListItemIcon
            sx={{
              margin: "4px 8px",
              minWidth: "3rem",
            }}
          >
            {menuItem.icon ? (
              <menuItem.icon isActive={menuItem.path === activeMenu.path} />
            ) : (
              <MenuRounded />
            )}
          </ListItemIcon>

          {open && (
            <ListItemText
              sx={{
                color: "text.primary",
              }}
              primary={menuItem.name}
            />
          )}


          {open && menuItem.isMultiMenu && (
            <>
              {toggleMenu === menuItem.path ? (
                <ExpandLess
                  sx={{
                    fill: "black",
                    color: "black",
                  }}
                />
              ) : (
                <ExpandMore
                  color="inherit"
                  sx={{
                    fill: "black",
                    color: "black",
                  }}
                />
              )}
            </>
          )}
        </StyledMenuItem>
      </Link>

      {open && menuItem.isMultiMenu && (
        <>
          <StyledSubMenu
            in={menuItem.path === toggleMenu}
            timeout="auto"
            sx={{
              padding: menuItem.path === toggleMenu ? "8px" : "0",
            }}
          >
            <List component="div" disablePadding>
              {menuItem.subMenu.map((e) => {
                return (
                  <Link
                    key={e.path}
                    to={`/${activeTab.path}/${menuItem.path}/${e.path}`}
                  >
                    <ListItemButton
                      onClick={() => {
                        setActiveSubMenu(e);
                      }}
                      button
                      sx={{
                        backgroundColor: `${e.path === activeSubMenu.path ? "rgba(206,159,41,0.08)" : "transparent"}`,
                        "&>div>span": {
                          color: `${e.path === activeSubMenu.path ? "#ce9f29" : "text.primary"}`,
                        },
                        borderRadius: "4px",
                      }}
                    >
                      {e.icon && (
                        <ListItemIcon>
                          <e.icon isActive={e.path === activeSubMenu.path} />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        sx={{
                          color: "text.primary",
                        }}
                        primary={e.name}
                      />
                    </ListItemButton>
                  </Link>
                );
              })}
            </List>
          </StyledSubMenu>
        </>
      )}
    </React.Fragment>
  ));
};

export default LayoutMenu;
