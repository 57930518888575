import {createAction} from "@reduxjs/toolkit";

import {
  CrIdPayload,
  CreditDetailsResponse,
  CreditSessionStatusPayload,
  GetTakeoverCreditReportHtmlPayload,
  InitiateCreditConsent,
} from "./creditDetails.types";

const creditDetailsActionTypes = {
  GetCreditScoreDetails: "[Credit Details] Get Credit Score Details",
  GetCreditScoreDetailsSuccess:
    "[Credit Details] Get Credit Score Details Success",
  GetCreditScoreDetailsFailure:
    "[Credit Details] Get Credit Score Details Failure",
  InitiateCreditConsent: "[Credit Details] Initiate Credit Consent",
  InitiateCreditConsentSuccess:
    "[Credit Details] Initiate Credit Consent Success",
  InitiateCreditConsentFailure:
    "[Credit Details] Initiate Credit Consent Failure",
  GetTakeoverCreditReportHtml:
    "[Credit Details] Get Takeover Credit Report Html",
  GetCreditSessionStatus: "[Credit Details] Get Credit Session Status",
  GetCreditSessionStatusSuccess:
    "[Credit Details] Get Credit Session Status Success",
  GetCreditSessionStatusFailure:
    "[Credit Details] Get Credit Session Status Failure",
};

const creditDetailsActions = {
  getCreditScoreDetails: createAction<CrIdPayload>(
    creditDetailsActionTypes.GetCreditScoreDetails,
  ),
  getCreditScoreDetailsSuccess: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.GetCreditScoreDetailsSuccess,
  ),
  getCreditScoreDetailsFailure: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.GetCreditScoreDetailsFailure,
  ),
  initiateCreditConsent: createAction<InitiateCreditConsent>(
    creditDetailsActionTypes.InitiateCreditConsent,
  ),
  initiateCreditConsentSuccess: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.InitiateCreditConsentSuccess,
  ),
  initiateCreditConsentFailure: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.InitiateCreditConsentFailure,
  ),

  getTakeoverCreditReportHtml: createAction<GetTakeoverCreditReportHtmlPayload>(
    creditDetailsActionTypes.GetTakeoverCreditReportHtml,
  ),
  getCreditSessionStatus: createAction<CreditSessionStatusPayload>(
    creditDetailsActionTypes.GetCreditSessionStatus,
  ),
  getCreditSessionStatusSuccess: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.GetCreditSessionStatusSuccess,
  ),
  getCreditSessionStatusFailure: createAction<CreditDetailsResponse>(
    creditDetailsActionTypes.GetCreditSessionStatusFailure,
  ),
};

export default creditDetailsActions;

export {creditDetailsActionTypes};
