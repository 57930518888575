import {LoanSummaryModel} from "../../models/loanSummary.model";
import {createSlice, current} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import loanActions from "./loanSummary.actions";

type LoadingErrorState = "getLoanSummaryDetails";

export interface LoanSummaryState {
  loanSummaryList: LoanSummaryModel[],
  loading: Record<LoadingErrorState, boolean>
  errors: Record<LoadingErrorState, GoldApiError | null>
}

const initialState: LoanSummaryState = {
  loanSummaryList: [],
  loading: {
    getLoanSummaryDetails: false,
  },
  errors: {
    getLoanSummaryDetails: null,
  },
};

const LoanSummarySlice = createSlice({
  name: "loanSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loanActions.getLoanSummaryDetails,
        (state) => {
          state.loading.getLoanSummaryDetails = true;
          state.errors.getLoanSummaryDetails = null;
        },
      )
      .addCase(loanActions.getLoanSummaryDetailsSuccess,
        (state, action) => {
          state.loading.getLoanSummaryDetails = false;
          const index = state.loanSummaryList.findIndex(
            s => s.loanId === action.payload.response.loanId,
          );
          if (index !== -1) {
            state.loanSummaryList[index] = {
              ...state.loanSummaryList[index],
              ...action.payload.response,
            };
          } else {
            state.loanSummaryList.push(action.payload.response);
          }
        },
      )
      .addCase(loanActions.getLoanSummaryDetailsFailure,
        (state, action) => {
          state.loading.getLoanSummaryDetails = false;
          state.errors.getLoanSummaryDetails = action.payload.error;
        },
      )
      .addCase(loanActions.getLenderLoanSummaryDetails,
        (state) => {
          state.loading.getLoanSummaryDetails = true;
          state.errors.getLoanSummaryDetails = null;
        },
      )
      .addCase(loanActions.getLenderLoanSummaryDetailsSuccess,
        (state, action) => {
          state.loading.getLoanSummaryDetails = false;
          const currentSummaryList = current(state.loanSummaryList);
          const index = currentSummaryList.findIndex(
            s => s.igLoanId === action.payload.response.igLoanId,
          );
          if (index !== -1) {
            state.loanSummaryList[index] = {
              ...currentSummaryList[index],
              ...action.payload.response,
            };
          } else {
            state.loanSummaryList.push(action.payload.response);
          }
        },
      )
      .addCase(loanActions.getLenderLoanSummaryDetailsFailure,
        (state, action) => {
          state.loading.getLoanSummaryDetails = false;
          state.errors.getLoanSummaryDetails = action.payload.error;
        },
      )
  ,
});

export default LoanSummarySlice.reducer;