import styled from "@emotion/styled";

const ModalContainer = styled.div`
  background: white;
  padding: 2rem;

  width: 30vw;
`;

export default ModalContainer;
