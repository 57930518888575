import {RootState} from "../store";

const OrnamentsSelectors = {
  getDisbursedJobsOrnamentsList: (state: RootState) =>
    Object.values(state.ornaments.disbursedJobsOrnamentsById),
  getOrnamentsNamesList: (state: RootState) =>
    state.ornaments.ornamentsNamesList,
  getSelectedOrnamentId: (state: RootState) =>
    state.ornaments.selectedOrnamentId,
  getGoldValueStats: (state: RootState) =>
    state.ornaments.goldValueStats,
  getOrnamentFormValues: (state: RootState, id: number) =>
    state.ornaments.ornamentFormValues[id],
  getSelectedOrnamentsDetails: (state: RootState) =>
    state.ornaments.selectedOrnamentDetails,
  getOrnamentsList: (state: RootState) => state.ornaments.ornamentsList,
  getOrnamentsFinenessConfigList: (state: RootState) =>
    state.ornaments.ornamentsFinenessConfigList,
  getOrnamentsPacket: (state: RootState) => state.ornaments.ornamentsPacket,
  getLoading: (state: RootState) => state.ornaments.loading,
  getErrors: (state: RootState) => state.ornaments.errors,
};

export default OrnamentsSelectors;
