import React, {useCallback, useState} from "react";
import {Box} from "@mui/material";
import {IGButton, IGDialog, IGTextField} from "app/components";
import wordsToNumbers from "lib/wordsToNumbers";
import {useSnackBar} from "app/store/hooks";
import activeLoansApi from "app/infra/services/api/activeLoans/api";

interface TakeoverAmountCheckerModalProps {
  isTakeoverAmountCheckerModalOpen: boolean;
  setIsTakeoverAmountCheckerModalOpen: (args: boolean) => void;
  onClickApproveHandler: () => void;
  checkerTakeoverAmount: string;
  setCheckerTakeoverAmount: (args: string) => void;
  makerTakeoverAmount: string;
  isConfirmBtnLoading: boolean;
  setAmount: (args: string) => void;
  crId: number;
  getNextJOb: () => void;
}
const TakeoverAmountCheckerModal = ({
  isTakeoverAmountCheckerModalOpen,
  setIsTakeoverAmountCheckerModalOpen,
  onClickApproveHandler,
  checkerTakeoverAmount,
  setCheckerTakeoverAmount,
  makerTakeoverAmount,
  isConfirmBtnLoading,
  crId,
  setAmount,
  getNextJOb,
}: TakeoverAmountCheckerModalProps) => {
  const {showSuccess, showError} = useSnackBar();
  const [errorMessage, setErrorMessage] = useState("");
  const [redoBtnLoading, setRedoBtnLoading] = useState(false);

  const checkAmount = () => {
    try {
      const checkerAmountInWords = checkerTakeoverAmount.trim();
      const checkerAmountNumber = wordsToNumbers(checkerAmountInWords);

      if (Number(makerTakeoverAmount) === checkerAmountNumber) {
        setErrorMessage("");
        showSuccess(
          "Both Maker and checker amounts have been succesfully matched!",
        );
        onClickApproveHandler();
      } else {
        setErrorMessage(
          "Checker TakeoverAmount doesn't match with maker takeover amount, Please try again.",
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else if (typeof error === "string") {
        setErrorMessage(error);
      } else {
        setErrorMessage("An unknown error occurred.");
      }
    }
  };

  const onClickRedoMakerHandler = useCallback(async () => {
    setRedoBtnLoading(true);
    const {error: redoMakerError} = await activeLoansApi.redoTakeoverAmount(
      crId,
    );
    if (!redoMakerError) {
      setRedoBtnLoading(false);
      getNextJOb();
      setIsTakeoverAmountCheckerModalOpen(false);
      setCheckerTakeoverAmount("");
      setAmount("");
    } else {
      setRedoBtnLoading(false);
      showError(redoMakerError?.message);
    }
  }, [
    crId,
    getNextJOb,
    setAmount,
    setCheckerTakeoverAmount,
    setIsTakeoverAmountCheckerModalOpen,
    showError,
  ]);
  return (
    <Box>
      <IGDialog
        title="Please Confirm Takeover Amount"
        open={isTakeoverAmountCheckerModalOpen}
        onClose={() => setIsTakeoverAmountCheckerModalOpen(false)}
        hideFooter
      >
        <Box my={1}>
          <IGTextField
            variant="outlined"
            name="checkerTakeoverAmount"
            value={checkerTakeoverAmount}
            onChange={(event) => setCheckerTakeoverAmount(event?.target.value)}
            placeholder="Enter takeover amount in words (e.g., fifty one thousand)"
          />

          {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
        </Box>
        <Box display="flex" gap={1} justifyContent="flex-end">
          <IGButton
            color="inherit"
            loading={redoBtnLoading}
            onClick={() => {
              onClickRedoMakerHandler();
            }}
          >
            Redo Maker
          </IGButton>
          <IGButton loading={isConfirmBtnLoading} onClick={() => checkAmount()}>
            Initiate Takeover Payment
          </IGButton>
        </Box>
      </IGDialog>
    </Box>
  );
};

export default TakeoverAmountCheckerModal;
