import React from "react";

const UploadIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="cloud-upload-round-24px 1" clipPath="url(#clip0_517_14179)">
        <path
          id="Vector"
          d="M51.6 26.7733C49.7867 17.5733 41.7067 10.6667 32 10.6667C24.2933 10.6667 17.6 15.04 14.2667 21.44C6.24 22.2933 0 29.0933 0 37.3333C0 46.16 7.17333 53.3333 16 53.3333H50.6667C58.0267 53.3333 64 47.36 64 40C64 32.96 58.5333 27.2533 51.6 26.7733ZM37.3333 34.6667V45.3333H26.6667V34.6667H18.6667L31.0667 22.2667C31.6 21.7333 32.4267 21.7333 32.96 22.2667L45.3333 34.6667H37.3333Z"
          fill="#1976D2"
        />
      </g>
      <defs>
        <clipPath id="clip0_517_14179">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
