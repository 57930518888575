import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  data: {
    margin: "8px",
    marginLeft: "0px",
    fontSize: "12px",
    color: "grey",
  },
  valueText: {
    fontSize: "1rem",
    color: "black",
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(18,18,18,0.5)",
  },
  subText: {
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    color: "#121212",
    marginLeft: "0.2rem",
    marginRight: "1rem",
    display: "flex",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    marginTop: "0.5rem",
    color: "#ce9f29",
    cursor: "pointer",
  },
  textContainer: {
    marginTop: "2rem",
  },
  button: {
    color: "#ce9f29",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
    textDecoration: "underline",
    marginTop: ".5rem",
    cursor: "pointer",
  },
  buttonContainer: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  ApproveButton: {
    background: "#ce9f29",
    border: "none",
    width: "100%",
    height: "48px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
  },
  LinkButton: {
    background: "#ce9f29",
    height: "27px",
    border: "none",
    fontFamily: "Roboto",
    fontSize: "13px",
    marginLeft: "10px",
    fontWeight: 500,
    letterSpacing: "0.5px",
  },
  reject: {
    background: "white",
    border: "1px solid #e2e2e2",
    width: "100%",
    height: "48px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "1.25px",
  },
  image: {
    width: 44,
    height: 44,
    borderRadius: 4,
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
    color: "#121212",
  },
  textArea: {
    width: "100%",
    fontFamily: "Roboto",
    border: "0.5px solid #e2e2e2",
    fontSize: "14px",
    minHeight: "10vh",
    marginTop: "1rem",
  },
  icon: {
    "&>svg": {
      width: "20px",
      height: "20px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftFooterContainer: {
    marginLeft: "20px",
    paddingLeft: "20px",
    borderLeft: "1px solid #e2e2e2",
  },
  subList: {
    marginTop: "10px",
    "&>img:first-of-type": {
      marginLeft: "0px !important",
    },
  },
  videoContainer: {
    "&>video": {
      maxHeight: "80vh",
    },
  },
  maxModalHeight: {
    maxHeight: "80vh",
    overflowY: "scroll",
  },

  Button: {
    background: "#ce9f29",
    color: "black",
    border: "none",
    height: "30px",
    fontFamily: "Roboto",
    fontSize: "10px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    marginLeft: "1.5rem",
  },
  containerForButton: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },

  loanConfirmContainer: {
    marginLeft: "1.5rem",
  },

  loanConfirmRowContainer: {
    width: "75%",
    marginTop: "20px",
  },

  confirmContainer: {
    marginTop: "30px",
  },

  checkerGrid: {
    marginBottom: "20px",
    alignItems: "center",
  },

  checkerLabel: {
    fontSize: "15px",
    fontWeight: "bold",
  },

  checkerUploadDocument: {
    display: "contents",
  },

  validationError: {
    color: "red",
    fontWeight: "bold",
  },

  arrowOutlined: {display: "flex", justifyContent: "flex-end"},

  validateText: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    marginTop: "1rem",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "0.4px",
    color: "rgba(18,18,18,0.5)",
  },

  inputLabelPl: {
    marginTop: "1.5rem",
    marginLeft: "0.5rem",
  },

  personalLoanMakerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  selectedSchemeContainer: {
    width: "60%",
    marginTop: "2rem",
  },

  creditReportFileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "28%",
  },

  cibilScoreContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  customerRoiContainer: {
    display: "flex",
    marginTop: "1rem",
  },

  creditReportFileNameContainer: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default useStyles;
