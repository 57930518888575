import React, {useEffect, useState, useCallback, useRef} from "react";

import Title from "../styles/Title";
import TitleBar from "./styles/TitleBar";
import DocumentContainer from "./styles/DocumentContainer";
import SubTitle from "../styles/SubTitle";
import getDocumentForMilestone from "api/business/getDocumentForMilestone";
import Loading from "app/components/Loading";
import IGDocumentViewer from "app/components/IGDocumentViewer";
import {List, ListItem, ListItemText, ListItemIcon} from "@mui/material";

const LenderDocuments = ({crId, currentMileStone}) => {
  const accessToken = localStorage.getItem("authToken");
  const countRef = useRef(0);
  const [states, setStates] = useState({
    loading: false,
    error: false,
    errorMessage: "",
  });
  const [allDocuments, setAllDocuments] = useState(null);

  const fetchDocumentsList = useCallback(async () => {
    const {payload, error, statusCode} = await getDocumentForMilestone({
      accessToken,
      crId,
      milestoneName: currentMileStone.data.milestone,
    });

    if (statusCode === 200 && payload.length !== 0) {
      setAllDocuments(payload);
    } else {
      setStates({
        ...states,
        errorMessage: error,
      });
    }
  }, [accessToken, crId, currentMileStone.data.milestone, states]);

  useEffect(() => {
    if (countRef.current === 0) {
      countRef.current = 1;

      fetchDocumentsList();
    }
  }, [fetchDocumentsList]);

  return (
    <>
      <TitleBar>
        <Title>
          Please verify all details and mark checkbox to proceed to next step or
          send back and ask LM to fill correctly
        </Title>
        <div>{/* icon here. */}</div>

        <DocumentContainer>
          <TitleBar>
            <SubTitle>PHOTOS AND DOCUMENTS</SubTitle>
          </TitleBar>

          {allDocuments ? (
            <List>
              {allDocuments.map((document) => (
                <>
                  {document?.fileType !== "VIDEO" && (
                    <ListItem divider sx={{paddingLeft: 0}}>
                      <ListItemIcon>
                        <IGDocumentViewer
                          key={document.documentType}
                          document={document}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={document.documentType.replaceAll("_", " ")}
                        sx={{m: 1}}
                      />
                    </ListItem>
                  )}
                </>
              ))}
            </List>
          ) : (
            <Loading height="10vh" />
          )}
        </DocumentContainer>
      </TitleBar>
    </>
  );
};

export default LenderDocuments;
