import styled from "@emotion/styled";

const GoldenUnderline = styled.div`
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;

  color: #ce9f29;

  text-decoration: underline;

  margin: 0.75rem 0;

  cursor: pointer;
`;

export const GoldenText = styled.span`
  color: #ce9f29;
  margin: 0.75rem 0;
  font-weight: 600;
`;
export default GoldenUnderline;
