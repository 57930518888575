import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface CheckCustomerRoiEditableProps {
  crId: number;
}

export const checkCustomerRoiEditable = async ({
  crId,
}: CheckCustomerRoiEditableProps) => {
  const API_ENDPOINTS: ApiResources = {
    CHECK_CUSTOMER_ROI_EDITABLE: {
      URL: "admin/business/cr/:crId/is-customer-roi-editable",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.CHECK_CUSTOMER_ROI_EDITABLE,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default checkCustomerRoiEditable;
