import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface SkipGatingEnableProps {
  crId: number;
  lenderName: string;
}

export const skipGatingEnable = async ({
  crId,
  lenderName,
}: SkipGatingEnableProps) => {
  const API_ENDPOINTS: ApiResources = {
    SKIP_GATING_ENABLE: {
      URL: "admin/business/v2/cr/:crId/skip-gating-enable",
      METHOD: "PUT",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.SKIP_GATING_ENABLE,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        lenderName,
      },
    },
  });
};

export default skipGatingEnable;
