const check = (value, list) => {
  if (value && list) {
    let objectWithMatchingvalue = list?.filter(
      (obj) => obj.enumName === value,
    )[0];
    let displayName = objectWithMatchingvalue?.displayName;
    if (!objectWithMatchingvalue) {
      objectWithMatchingvalue = list?.filter(
        (obj) => obj.statusEnum === value,
      )[0];
      displayName = objectWithMatchingvalue?.statusDisplayName;
    }
    if (displayName) {
      return displayName;
    }
      return value;

  }
    return "-";

};
export default check;
