import {RootState} from "app/store/store";

const algoSelectors = {
  getAllRuns: (state: RootState) => state.scheduling.algo.allRuns,
  getAgentList: (state: RootState) =>
    state.scheduling.algo.detailView.agentList,
  getCustomerList: (state: RootState) =>
    state.scheduling.algo.detailView.customerList,
  getAlgoRun: (state: RootState) => state.scheduling.algo.algoRun,
  getCurrentRouteList: (state: RootState) =>
    state.scheduling.algo.detailView.currentRouteList,
  getFilters: (state: RootState) => state.scheduling.algo.detailView.filters,
  getIsFetching: (state: RootState) => state.scheduling.algo.isFetching,
  getError: (state: RootState) => state.scheduling.algo.error,
  getShowRouteDeleteConfirmationModal: (state: RootState) =>
    state.scheduling.algo.detailView.showRouteDeleteConfirmationModal,
  getSelectedTrip: (state: RootState) =>
    state.scheduling.algo.detailView.selectedTrip,
  getLMAvailability: (state: RootState) =>
    state.scheduling.algo.loanManagersAvailability,
  getSelectedCustomer: (state: RootState) =>
    state.scheduling.algo.detailView.selectedCustomer,
  getSelectedAgent: (state: RootState) =>
    state.scheduling.algo.detailView.selectedAgent,
  getCurrentCityId: (state: RootState) => state.scheduling.algo.currentCityId,
  getConfirmLoanManagers: (state: RootState) =>
    state.scheduling.algo.confirmView.loanManagers,
  getConfirmLMAvailability: (state: RootState) =>
    state.scheduling.algo.confirmView.lmAvailability,
  getSelectedDate: (state: RootState) => state.scheduling.algo.selectedDate,
  getReportFetched: (state: RootState) =>
    state.scheduling.algo.confirmView.reportFetched,
  getUnscheduledLoans: (state: RootState) =>
    state.scheduling.algo.confirmView.unscheduledLoans,
  getAvailableAgentList: (state: RootState) =>
    state.scheduling.algo.confirmView.availableLoanManagers,
  getLoaders: (state: RootState) => state.scheduling.algo.loaders,
};
export default algoSelectors;
