import {
  OrnamentsApiInterface,
  OrnamentsApiResources,
  GetDisbursedJobOrnamentsPayload,
} from "app/store/ornaments/ornaments.types";
import {goldApiService} from "../../../plugins/axios";

const ORNAMENTS_ENDPOINTS: OrnamentsApiResources = {
  GET_DISBURSED_JOBS_ORNAMENTS: {
    URL: "/admin/business/lender/cr/:crId/ornament",
    METHOD: "GET",
  },
  GET_ORNAMENTS_NAMES_LIST: {
    URL: "/business-dashboard/ornament/cr/:crId/ornament-name-list",
    METHOD: "GET",
  },
  GET_SELECTED_ORNAMENT_DETAILS: {
    URL: "/business-dashboard/ornament/cr/:crId/ornament/:ornamentId",
    METHOD: "GET",
  },
  GET_ORNAMENTS_LIST: {
    URL: "/business-dashboard/ornament/cr/:crId/ornament-detail-list",
    METHOD: "GET",
  },
  GET_ORNAMENTS_FINENESS_CONFIG: {
    URL: "admin/business/fineness-config",
    METHOD: "GET",
  },
  LENDER_GET_ORNAMENTS_NAMES_LIST: {
    URL: "/admin/business/lender/ornament/cr/:crId/ornament-name-list",
    METHOD: "GET",
  },
  LENDER_GET_SELECTED_ORNAMENT_DETAILS: {
    URL: "/admin/business/lender/ornament/cr/:crId/ornament/:ornamentId",
    METHOD: "GET",
  },
  LENDER_GET_ORNAMENTS_LIST: {
    URL: "/admin/business/lender/ornament/cr/:crId/ornament-detail-list",
    METHOD: "GET",
  },
  LENDER_GET_ORNAMENTS_FINENESS_CONFIG: {
    URL: "/admin/business/lender/fineness-config",
    METHOD: "GET",
  },
  GET_ORNAMENTS_PACKET: {
    URL: "/business/cr/:crId/lm-packet",
    METHOD: "GET",
  },
  UPDATE_ORNAMENT_DETAILS: {
    URL: "/business-dashboard/ornament/cr/:crId/ornament-list",
    METHOD: "PUT",
  },
  DOWNLOAD_ORNAMENTS_CSV: {
    URL: "/admin/business/cr/:crId/ornament-csv",
    METHOD: "GET",
  },
  DOWNLOAD_LENDER_ORNAMENT_CSV: {
    URL: "/business/lender/approval/cr/:crId/ornament-csv",
    METHOD: "GET",
  },
};

const ornamentsApi: OrnamentsApiInterface = {
  getDisbursedJobsOrnamentsList: async (
    payload: GetDisbursedJobOrnamentsPayload,
  ) => {
    return await goldApiService({
      resource: ORNAMENTS_ENDPOINTS.GET_DISBURSED_JOBS_ORNAMENTS,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          ornamentImageType: payload.ornamentImageType,
        },
      },
    });
  },
  getOrnamentsNamesList: async ({crId, isLender}) => {
    return await goldApiService({
      resource: isLender
        ? ORNAMENTS_ENDPOINTS.LENDER_GET_ORNAMENTS_NAMES_LIST
        : ORNAMENTS_ENDPOINTS.GET_ORNAMENTS_NAMES_LIST,
      options: {
        pathVars: {crId},
      },
    });
  },
  getSelectedOrnamentDetails: async ({crId, ornamentId, isLender}) => {
    return await goldApiService({
      resource: isLender
        ? ORNAMENTS_ENDPOINTS.LENDER_GET_SELECTED_ORNAMENT_DETAILS
        : ORNAMENTS_ENDPOINTS.GET_SELECTED_ORNAMENT_DETAILS,
      options: {
        pathVars: {crId, ornamentId},
      },
    });
  },
  getOrnamentsList: async ({crId, isLender}) => {
    return await goldApiService({
      resource: isLender
        ? ORNAMENTS_ENDPOINTS.LENDER_GET_ORNAMENTS_LIST
        : ORNAMENTS_ENDPOINTS.GET_ORNAMENTS_LIST,
      options: {
        pathVars: {crId},
      },
    });
  },
  getOrnamentsFinenessConfig: async ({crId, isLender}) => {
    return await goldApiService({
      resource: isLender
        ? ORNAMENTS_ENDPOINTS.LENDER_GET_ORNAMENTS_FINENESS_CONFIG
        : ORNAMENTS_ENDPOINTS.GET_ORNAMENTS_FINENESS_CONFIG,
      options: {
        queryParams: {crId},
      },
    });
  },
  getOrnamentsPacket: async ({crId}) => {
    return await goldApiService({
      resource: ORNAMENTS_ENDPOINTS.GET_ORNAMENTS_PACKET,
      options: {
        pathVars: {crId},
      },
    });
  },
  updateOrnamentDetails: async (payload, crId) => {
    return await goldApiService({
      resource: ORNAMENTS_ENDPOINTS.UPDATE_ORNAMENT_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        data: {
          ...payload,
        },
      },
    });
  },
  downloadOrnamentsCSV: async ({crId, isLenderFlow = false}) => {
    return await goldApiService({
      resource: isLenderFlow
        ? ORNAMENTS_ENDPOINTS.DOWNLOAD_LENDER_ORNAMENT_CSV
        : ORNAMENTS_ENDPOINTS.DOWNLOAD_ORNAMENTS_CSV,
      options: {
        pathVars: {crId},
      },
    });
  },
};

export {ORNAMENTS_ENDPOINTS};
export default ornamentsApi;
