import React from "react";
import {Route} from "react-router-dom";
import CustomerProfiles from "app/pages/home/GoldLoan/CustomerProfiles";
import ProfilePage from "app/pages/home/GoldLoan/CustomerProfiles/ProfilePage";
import CustomerProfilesWrapper from "app/pages/home/GoldLoan/CustomerProfiles/Wrapper";

const CustomerProfileRoutes = () => {
  return (
    <Route path="customer-profiles" element={<CustomerProfilesWrapper />}>
      <Route index element={<CustomerProfiles />} />
      <Route path=":userId/:loanId" element={<ProfilePage />} />
    </Route>
  );
};

export default CustomerProfileRoutes;
