import {createAction} from "@reduxjs/toolkit";

import {
  TransactionsResponse,
  CrIdPayload,
  GetAdjustmentRenewalRoiPayload,
} from "./transactions.types";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";

const transactionsActionTypes = {
  GetTransactionsList: "[Transactions] Get Transactions List",
  GetTransactionsListSuccess: "[Transactions] Get Transactions List Success",
  GetTransactionsListFailure: "[Transactions] Get Transactions List Failure",
  ProceedAfterAdjustment: "[Transactions] Proceed After Adjustment",
  ProceedAfterAdjustmentSuccess:
    "[Transactions] Proceed After Adjustment Success",
  ProceedAfterAdjustmentFailure:
    "[Transactions] Proceed After Adjustment Failure",
  CreateTransaction: "[Transactions] Create Transactions",
  CreateTransactionSuccess: "[Transactions] Create Transactions Success",
  CreateTransactionFailure: "[Transactions] Create Transactions Failure",
  ShowTransactionFlow: "[Transactions] Show Transaction Flow",
  ShowTransactionFlowSuccess: "[Transactions] Show Transaction Flow Success",
  ShowTransactionFlowFailure: "[Transactions] Show Transaction Flow Failure",
  GetAdjustmentRenewalRoi: "[Transactions] Get Adjustment Renewal Roi",
  GetAdjustmentRenewalRoiSuccess:
    "[Transactions] Get Adjustment Renewal Roi Success",
  GetAdjustmentRenewalRoiFailure:
    "[Transactions] Get Adjustment Renewal Roi Failure",
  ShareOfferWithCustomer: "[Transactions] Share Offer With Customer",
  ShareOfferWithCustomerSuccess:
    "[Transactions] Share Offer With Customer Success",
  ShareOfferWithCustomerFailure:
    "[Transactions] Share Offer With Customer Failure",
};

const transactionsActions = {
  getTransactionsList: createAction<CrIdPayload>(
    transactionsActionTypes.GetTransactionsList,
  ),
  getTransactionsListSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.GetTransactionsListSuccess,
  ),
  getTransactionsListFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.GetTransactionsListFailure,
  ),
  proceedAfterAdjustment: createAction<CrIdPayload>(
    transactionsActionTypes.ProceedAfterAdjustment,
  ),
  proceedAfterAdjustmentSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.ProceedAfterAdjustmentSuccess,
  ),
  proceedAfterAdjustmentFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.ProceedAfterAdjustmentFailure,
  ),
  createTransaction: createAction<CrIdPayload>(
    transactionsActionTypes.CreateTransaction,
  ),
  createTransactionSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.CreateTransactionSuccess,
  ),
  createTransactionFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.CreateTransactionFailure,
  ),
  showTransactionFlow: createAction<CrIdPayload>(
    transactionsActionTypes.ShowTransactionFlow,
  ),
  showTransactionFlowSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.ShowTransactionFlowSuccess,
  ),
  showTransactionFlowFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.ShowTransactionFlowFailure,
  ),
  getAdjustmentRenewalRoi: createAction<
    GetAdjustmentRenewalRoiPayload & RequestIdPayload
  >(transactionsActionTypes.GetAdjustmentRenewalRoi),
  getAdjustmentRenewalRoiSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.GetAdjustmentRenewalRoiSuccess,
  ),
  getAdjustmentRenewalRoiFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.GetAdjustmentRenewalRoiFailure,
  ),
  shareOfferWithCustomer: createAction<CrIdPayload>(
    transactionsActionTypes.ShareOfferWithCustomer,
  ),
  shareOfferWithCustomerSuccess: createAction<TransactionsResponse>(
    transactionsActionTypes.ShareOfferWithCustomerSuccess,
  ),
  shareOfferWithCustomerFailure: createAction<TransactionsResponse>(
    transactionsActionTypes.ShareOfferWithCustomerFailure,
  ),
};

export default transactionsActions;

export {transactionsActionTypes};
