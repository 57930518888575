import styled from "@emotion/styled";

const DocIconWrapper = styled.img`
  height: 5rem;
  width: 5rem;

  margin-right: 0.25rem;
`;

export default DocIconWrapper;
