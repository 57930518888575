import React, {useEffect, useMemo, Suspense} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {servicesActions, servicesSelectors} from "app/store/serviceDesk/services";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";
import {IGLoading} from "app/components";
import {isEmpty} from "lodash";

export interface ServiceDeskOutletContext {
  isReadOnly: boolean;
}

const ServiceDesk = () => {
  const {pathname} = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(authSelectors.getUser);
  const servicesConfig = useAppSelector(
    (state) => servicesSelectors.getServiceConfig(state, "GOLD_LOAN"),
  );
  const complaintConfig = useAppSelector(
    (state) => servicesSelectors.getComplaintConfig(state, "GOLD_LOAN"),
  );
  const isComplaints = useMemo(() => pathname.includes("complaints"), [
    pathname,
  ]);
  const isReadOnly = useMemo(() => {
    return user?.userRoleList?.includes(RolesEnum.CITY_HEAD);
  }, [user]);
  const configToUse = isComplaints ? complaintConfig : servicesConfig;

  useEffect(() => {
    if (isEmpty(configToUse)) {
      dispatch(
        servicesActions.getServiceRequestConfigList({
          product: "GOLD_LOAN",
          requestType: isComplaints ? "COMPLAINT" : "SERVICE",
        }),
      );
    }
    dispatch(servicesActions.getAgentList());
  }, [dispatch, isComplaints, configToUse]);

  return (
    <Suspense fallback={<IGLoading/>}>
      <Outlet context={{isReadOnly} as ServiceDeskOutletContext}/>
    </Suspense>
  );
};

export default ServiceDesk;
