import {RootState} from "../store";

const authSelectors = {
  getAuthToken: (state: RootState) => state.auth.authToken,
  getRefreshToken: (state: RootState) => state.auth.refreshToken,
  getIsAuthenticated: (state: RootState) => state.auth.isAuthenticated,
  getUser: (state: RootState) => state.auth.user,
  getLoading: (state: RootState) => state.auth.loading,
  getIsGoogleAuth: (state: RootState) => state.auth.isGoogleAuth,
  getServer: (state: RootState) => state.auth.server,
  getOtpId: (state: RootState) => state.auth.otpId,
  getError: (state: RootState) => state.auth.error,
};

export default authSelectors;
