import React from "react";

const ProcessingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0L48 0 48 48 0 48z"
            transform="translate(-296 -104) translate(296 104)"
          />
          <path
            fill="#ce9f29"
            fillRule="nonzero"
            d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16m0-36c5.304 0 10.391 2.107 14.142 5.858C41.892 13.608 44 18.696 44 24c0 11.046-8.954 20-20 20-11.06 0-20-9-20-20C4 12.954 12.954 4 24 4m1 10v10.5l9 5.34-1.5 2.46L22 26V14h3z"
            transform="translate(-296 -104) translate(296 104)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ProcessingIcon;
