import {createAction} from "@reduxjs/toolkit";
import {GiftCardConfigState} from "./reducer";

export const GiftCardConfigTypes = {
  GetGiftCardConfig: "[Gift Card Config] Get Gift Card Config",
  GetGiftCardConfigSuccess: "[Gift Card Config] Get Gift Card Config Success",
  GetGiftCardConfigError: "[Gift Card Config] Get Gift Card Config Error",
  UpdateGiftCardConfig: "[Gift Card Config] Udpate Gift Card Config User",
  UpdateGiftCardConfigSuccess:
    "[Gift Card Config] Udpate Gift Card Config Success",
  UpdateGiftCardConfigError: "[Gift Card Config] Udpate Gift Card Config Error",
};

export const giftCardConfigAction = {
  getGiftCardConfig: createAction(GiftCardConfigTypes.GetGiftCardConfig),
  getGiftCardConfigSuccess: createAction<GiftCardConfigState>(
    GiftCardConfigTypes.GetGiftCardConfigSuccess,
  ),
  getGiftCardConfigError: createAction<GiftCardConfigState>(
    GiftCardConfigTypes.GetGiftCardConfigError,
  ),
  updateGiftCardConfig: createAction<Record<string, any>>(
    GiftCardConfigTypes.UpdateGiftCardConfig,
  ),
  updateGiftCardConfigSuccess: createAction<GiftCardConfigState>(
    GiftCardConfigTypes.UpdateGiftCardConfigSuccess,
  ),
  updateGiftCardConfigError: createAction<GiftCardConfigState>(
    GiftCardConfigTypes.UpdateGiftCardConfigError,
  ),
};
