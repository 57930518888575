import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/business/config/cr/:crId/pl-lender/:lenderName/scheme-list",
  METHOD: "GET",
};

export const getPersonalLoanSchemes = async ({
crId,
lenderName,
}) => {

  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
       pathVars: {
        lenderName,
        crId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export default getPersonalLoanSchemes;
