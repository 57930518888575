/* eslint-disable no-unused-vars */

export type LenderClosureJobStatus = "CREATED" | "LENDER_CLOSURE_DETAIL_VERIFIED" | "LENDER_CLOSURE_DETAIL_VERIFICATION_ON_HOLD"| "COMPLETED";

export enum LenderJobTypeEnum {
  LENDER_LOAN_CLOSURE= "LENDER_LOAN_CLOSURE",
  LENDER_GOLD_HANDOVER= "LENDER_GOLD_HANDOVER",
}

export enum LenderClosureJobStatusEnum {
  CREATED="Open",
  LENDER_CLOSURE_DETAIL_VERIFICATION_ON_HOLD="Rejected",
  LENDER_CLOSURE_DETAIL_VERIFIED="Pending in CBS",
  COMPLETED="Closed in CBS",
}

export type LenderGoldHandoverJobStatus = "CREATED" | "COMPLETED";

export enum LenderGoldHandoverJobStatusEnum {
  CREATED="Open",
  COMPLETED="Completed",
}