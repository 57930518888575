import {createAction} from "@reduxjs/toolkit";
import {UserIdPayload} from "app/infra/services/api/internalUser/types";
import {
  AllowPaymentPayload,
  CancelRequestPayload,
  UpdateDeliverSlotPayload,
} from "app/infra/services/api/serviceDesk/closure/types";
import {
  CheckClosureAmountPayload,
  RequestIdPayload,
} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {AvailableDeliverySlots, ClosureAmountData, DeliverySlotDetail} from "./types";

const closureActionTypes = {
  GetClosureAmount: "[Closure] Get Closure Amount",
  GetClosureAmountSuccess: "[Closure] Get Closure Amount Success",
  GetClosureAmountFailure: "[Closure] Get Closure Amount Failure",

  GetClosureDetail: "[Closure] Get Closure Detail",
  GetClosureDetailSuccess: "[Closure] Get Closure Detail Success",
  GetClosureDetailFailure: "[Closure] Get Closure Detail Failure",

  GetValidateLoanClose: "[Closure] Get Validate Loan Close",
  GetValidateLoanCloseSuccess: "[Closure] Get Validate Loan Close Success",
  GetValidateLoanCloseFailure: "[Closure] Get Validate Loan Close Failure",

  GetAgentList: "[Closure] Get Agen tList",
  GetAgentListSuccess: "[Closure] Get Agen tList Success",
  GetAgentListFailure: "[Closure] Get Agen tList Failure",

  GetPaymentDetail: "[Closure] Get Payment Detail",
  GetPaymentDetailSuccess: "[Closure] Get Payment Detail Success",
  GetPaymentDetailFailure: "[Closure] Get Payment Detail Failure",

  GetBankDetails: "[Closure] Get Bank Details",
  GetBankDetailsSuccess: "[Closure] Get Bank Details Success",
  GetBankDetailsFailure: "[Closure] Get Bank Details Failure",

  GetUPIDetails: "[Closure] Get UPI Details",
  GetUPIDetailsSuccess: "[Closure] Get UPI Details Success",
  GetUPIDetailsFailure: "[Closure] Get UPI Details Failure",

  GetBankFromIFSC: "[Closure] Get Bank From IFSC",
  GetBankFromIFSCSuccess: "[Closure] Get Bank From IFSC Success",
  GetBankFromIFSCFailure: "[Closure] Get Bank From IFSC Failure",

  GetBypassDocument: "[Closure] Get Bypass Document",
  GetBypassDocumentSuccess: "[Closure] Get Bypass Document Success",
  GetBypassDocumentFailure: "[Closure] Get Bypass Document Failure",

  PutLoanDetail: "[Closure] Put Loan Detail",
  PutLoanDetailSuccess: "[Closure] Put Loan Detail Success",
  PutLoanDetailFailure: "[Closure] Put Loan Detail Failure",

  Refund: "[Closure] Refund",
  RefundSuccess: "[Closure] Refund Success",
  RefundFailure: "[Closure] Refund Failure",

  RetryRefund: "[Closure] Retry Refund",
  RetryRefundSuccess: "[Closure] Retry Refund Success",
  RetryRefundFailure: "[Closure] Retry Refund Failure",

  AddBankDetails: "[Closure] Add Bank Details",
  AddBankDetailsSuccess: "[Closure] Add Bank Details Success",
  AddBankDetailsFailure: "[Closure] Add Bank Details Failure",

  AddUPIDetails: "[Closure] Add Bank Details",
  AddUPIDetailsSuccess: "[Closure] Add Bank Details Success",
  AddUPIDetailsFailure: "[Closure] Add Bank Details Failure",

  VerifyAccountDetails: "[Closure]  Verify Account Details",
  VerifyAccountDetailsSuccess: "[Closure]  Verify Account Details Success",
  VerifyAccountDetailsFailure: "[Closure]  Verify Account Details Failure",

  VerifyVPA: "[Closure] Verify VPA",
  VerifyVPASuccess: "[Closure] Verify VPA Success",
  VerifyVPAFailure: "[Closure] Verify VPA Failure",

  AllowPayment: "[Closure] Allow Payment",
  AllowPaymentSuccess: "[Closure] Allow Payment Success",
  AllowPaymentFailure: "[Closure] Allow Payment Failure",

  CancelRequest: "[Closure] Cancel Request",
  CancelRequestSuccess: "[Closure] Cancel Request Success",
  CancelRequestFailure: "[Closure] Cancel Request Failure",

  CloseLoan: "[Closure] Close Loan",
  CloseLoanSuccess: "[Closure] Close Loan Success",
  CloseLoanFailure: "[Closure] Close Loan Failure",

  GetAvailableDeliverySlots: "[Closure] Get Available Delivery Slots",
  GetAvailableDeliverySlotsSuccess:
    "[Closure] Get Available Delivery Slots Success",
  GetAvailableDeliverySlotsFailure:
    "[Closure] Get Available Delivery Slots Failure",
  UpdateDeliverySlot: "[Closure] Update Delivery Slot",
  UpdateDeliverySlotSuccess: "[Closure] Update Delivery Slot Success",
  UpdateDeliverySlotFailure: "[Closure] Update Delivery Slot Failure",
  GetDeliverySlotDetail: "[Closure] Get Delivery Slot Detail",
  GetDeliverySlotDetailSuccess: "[Closure] Get Delivery Slot Detail Success",
  GetDeliverySlotDetailFailure: "[Closure] Get Delivery Slot Detail Failure",
};

const closureActions = {
  getClosureAmount: createAction<CheckClosureAmountPayload>(
    closureActionTypes.GetClosureAmount,
  ),
  getClosureAmountSuccess: createAction<{ response: ClosureAmountData | null }>(
    closureActionTypes.GetClosureAmountSuccess,
  ),
  getClosureAmountFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetClosureAmountFailure,
  ),

  getClosureDetail: createAction(closureActionTypes.GetClosureDetail),
  getClosureDetailSuccess: createAction(
    closureActionTypes.GetClosureDetailSuccess,
  ),
  getClosureDetailFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetClosureDetailFailure,
  ),

  getValidateLoanClose: createAction(closureActionTypes.GetValidateLoanClose),
  getValidateLoanCloseSuccess: createAction(
    closureActionTypes.GetValidateLoanCloseSuccess,
  ),
  getValidateLoanCloseFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetValidateLoanCloseFailure,
  ),

  getAgentList: createAction(closureActionTypes.GetAgentList),
  getAgentListSuccess: createAction(closureActionTypes.GetAgentListSuccess),
  getAgentListFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetAgentListFailure,
  ),

  getPaymentDetail: createAction<RequestIdPayload>(
    closureActionTypes.GetPaymentDetail,
  ),
  getPaymentDetailSuccess: createAction<any>(
    closureActionTypes.GetPaymentDetailSuccess,
  ),
  getPaymentDetailFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetPaymentDetailFailure,
  ),

  getBankDetails: createAction<UserIdPayload>(
    closureActionTypes.GetBankDetails,
  ),
  getBankDetailsSuccess: createAction<any>(
    closureActionTypes.GetBankDetailsSuccess,
  ),
  getBankDetailsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetBankDetailsFailure,
  ),
  getUPIDetails: createAction<UserIdPayload>(closureActionTypes.GetUPIDetails),
  getUPIDetailsSuccess: createAction<any>(
    closureActionTypes.GetUPIDetailsSuccess,
  ),
  getUPIDetailsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetUPIDetailsFailure,
  ),

  getBankFromIFSC: createAction(closureActionTypes.GetBankFromIFSC),
  getBankFromIFSCSuccess: createAction(
    closureActionTypes.GetBankFromIFSCSuccess,
  ),
  getBankFromIFSCFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetBankFromIFSCFailure,
  ),

  getBypassDocument: createAction(closureActionTypes.GetBypassDocument),
  getBypassDocumentSuccess: createAction(
    closureActionTypes.GetBypassDocumentSuccess,
  ),
  getBypassDocumentFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetBypassDocumentFailure,
  ),

  putLoanDetail: createAction(closureActionTypes.PutLoanDetail),
  putLoanDetailSuccess: createAction(closureActionTypes.PutLoanDetailSuccess),
  putLoanDetailFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.PutLoanDetailFailure,
  ),

  refund: createAction(closureActionTypes.Refund),
  refundSuccess: createAction(closureActionTypes.RefundSuccess),
  refundFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.RefundFailure,
  ),

  retryRefund: createAction(closureActionTypes.RetryRefund),
  retryRefundSuccess: createAction(closureActionTypes.RetryRefundSuccess),
  retryRefundFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.RetryRefundFailure,
  ),

  addBankDetails: createAction(closureActionTypes.AddBankDetails),
  addBankDetailsSuccess: createAction(closureActionTypes.AddBankDetailsSuccess),
  addBankDetailsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.AddBankDetailsFailure,
  ),

  addUPIDetails: createAction(closureActionTypes.AddUPIDetails),
  addUPIDetailsSuccess: createAction(closureActionTypes.AddUPIDetailsSuccess),
  addUPIDetailsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.AddUPIDetailsFailure,
  ),

  verifyAccountDetails: createAction(closureActionTypes.VerifyAccountDetails),
  verifyAccountDetailsSuccess: createAction(
    closureActionTypes.VerifyAccountDetailsSuccess,
  ),
  verifyAccountDetailsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.VerifyAccountDetailsFailure,
  ),

  verifyVPA: createAction(closureActionTypes.VerifyVPA),
  verifyVPASuccess: createAction(closureActionTypes.VerifyVPASuccess),
  verifyVPAFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.VerifyVPAFailure,
  ),

  allowPayment: createAction<AllowPaymentPayload>(
    closureActionTypes.AllowPayment,
  ),
  allowPaymentSuccess: createAction(closureActionTypes.AllowPaymentSuccess),
  allowPaymentFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.AllowPaymentFailure,
  ),

  cancelRequest: createAction<CancelRequestPayload>(
    closureActionTypes.CancelRequest,
  ),
  cancelRequestSuccess: createAction(closureActionTypes.CancelRequestSuccess),
  cancelRequestFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.CancelRequestFailure,
  ),

  closeLoan: createAction(closureActionTypes.CloseLoan),
  closeLoanSuccess: createAction(closureActionTypes.CloseLoanSuccess),
  closeLoanFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.CloseLoanFailure,
  ),

  getAvailableDeliverySlots: createAction<RequestIdPayload>(
    closureActionTypes.GetAvailableDeliverySlots,
  ),
  getAvailableDeliverySlotsSuccess: createAction<{
    response: AvailableDeliverySlots | null;
  }>(closureActionTypes.GetAvailableDeliverySlotsSuccess),
  getAvailableDeliverySlotsFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetAvailableDeliverySlotsFailure,
  ),

  updateDeliverySlot: createAction<UpdateDeliverSlotPayload>(
    closureActionTypes.UpdateDeliverySlot,
  ),
  updateDeliverySlotSuccess: createAction<{ response: DeliverySlotDetail }>(
    closureActionTypes.UpdateDeliverySlotSuccess,
  ),
  updateDeliverySlotFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.UpdateDeliverySlotFailure,
  ),

  getDeliverySlotDetail: createAction<RequestIdPayload>(
    closureActionTypes.GetDeliverySlotDetail,
  ),
  getDeliverySlotDetailSuccess: createAction<{
    response: DeliverySlotDetail;
    requestId: number;
  }>(closureActionTypes.GetDeliverySlotDetailSuccess),
  getDeliverySlotDetailFailure: createAction<{ error: GoldApiError }>(
    closureActionTypes.GetDeliverySlotDetailFailure,
  ),
};

export {closureActionTypes, closureActions};
