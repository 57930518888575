import React, {FC, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import {IGButton} from "app/components";
import getPledgeCardApi from "api/customerProfiles/getPledgeCard";
import getDocument from "api/business/getDocument";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {useSnackBar} from "app/store/hooks";
import IGDocumentViewer from "app/components/IGDocumentViewer";
import {SignedPledgeDocEnum, loanTypeEnum} from "app/enums/customerProfiles";
import {fileType} from "app/enums/generalPurpose";
import {RolesEnum} from "app/enums/roles";
import {loanActions, loanSelectors} from "app/store/loan";

interface OrnamentsAndPledgeCardProps {}

const OrnamentsAndPledgeCard: FC<OrnamentsAndPledgeCardProps> = () => {
  const {loanId} = useParams();
  const dispatch = useAppDispatch();
  const {showError} = useSnackBar();

  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const user = useAppSelector(authSelectors.getUser);
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [signedPledgeDocs, setSignedPledgeDocs] = useState<any>(null);
  const isLender = user?.userRoleList?.includes(RolesEnum.LENDER);
  const {isGoldLoanTaken, isPersonalLoanTaken} = loanDetails || {};

  const onClickHandler = useCallback(
    async (loanType) => {
      try {
        const {response, error} = await getPledgeCardApi({
          loanId: Number(loanId),
          loanType,
        });

        if (!error) {
          window.open(response?.link);
        } else {
          showError(error.message || "Not able to fetch pledge card");
        }
      } catch (error) {
        showError("Something went wrong");
      }
    },
    [loanId, showError],
  );

  const fetchSignedPledgeCardHandler = useCallback(
    async (documentType) => {
      if (!loanDetails?.crId) {
        showError("crID not available");
        return;
      }
      const {payload: signedPledgeDocsPayload} = await getDocument({
        accessToken,
        crId: loanDetails?.crId,
        documentType: [documentType],
      });

      if (signedPledgeDocsPayload && signedPledgeDocsPayload.length) {
        if (signedPledgeDocsPayload[0]?.fileType !== fileType.PDF) {
          setSignedPledgeDocs(signedPledgeDocsPayload[0]);
          setIsDocumentReady(true);
        } else if (signedPledgeDocsPayload[0]?.fileType === fileType.PDF) {
          window.open(signedPledgeDocsPayload[0]?.fileUrl);
        }
      } else {
        showError("Signed pledge card not available");
      }
    },
    [accessToken, loanDetails?.crId, showError],
  );

  useEffect(() => {
    if (!loanId) return;
    if (!loanDetails) {
      dispatch(
        loanActions.getLoanDetails({
          loanId: Number(loanId),
        }),
      );
    }
  }, [dispatch, loanDetails, loanId]);

  return (
    <>
      <Typography variant="h6">
        {!isGoldLoanTaken ? "Download Sanction Letter" : "Download Pledge Card"}
      </Typography>
      {isGoldLoanTaken ? (
        <>
          <IGButton
            color="golden"
            onClick={() => onClickHandler(loanTypeEnum.GOLD_LOAN)}
            sx={{m: 1}}
          >
            DIGITAL GL PLEDGE CARD
          </IGButton>

          {!isLender && (
            <IGButton
              color="golden"
              onClick={() =>
                fetchSignedPledgeCardHandler(
                  SignedPledgeDocEnum.GL_SIGNED_PLEDGE_CARD,
                )
              }
              sx={{m: 1}}
            >
              SIGNED GL PLEDGE CARD
            </IGButton>
          )}
        </>
      ) : null}

      {isPersonalLoanTaken ? (
        <>
          <IGButton
            color="golden"
            onClick={() => onClickHandler(loanTypeEnum.PERSONAL_LOAN)}
            sx={{m: 1}}
          >
            {isGoldLoanTaken
              ? "DIGITAL PL PLEDGE CARD"
              : "DIGITAL PL SANCTION LETTER"}
          </IGButton>

          {!isLender && (
            <IGButton
              color="golden"
              onClick={() =>
                fetchSignedPledgeCardHandler(
                  SignedPledgeDocEnum.PL_SIGNED_SANCTION_LETTER,
                )
              }
              sx={{m: 1}}
            >
              {isGoldLoanTaken
                ? "SIGNED PL PLEDGE CARD"
                : "SIGNED PL SANCTION LETTER"}
            </IGButton>
          )}
        </>
      ) : null}

      {/* integrating IgDocument viewer in case of signed summary as in case of
      signed response comes with multiple images not a single pdf file */}

      {isDocumentReady && (
        <IGDocumentViewer
          document={signedPledgeDocs}
          open={isDocumentReady}
          onClose={() => setIsDocumentReady(false)}
        />
      )}
    </>
  );
};

export default OrnamentsAndPledgeCard;
