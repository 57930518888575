import {goldApiService} from "app/infra/plugins/axios";

import {
  LoanRenewalRebookApiInterface,
  LoanRenewalRebookApiResources,
  GetLoanRenewalRebookMilestonesPayload,
} from "app/store/loanRenewalRebook/loanRenewalRebook.types";

const LOAN_RENEWAL_REBOOK_ENDPOINTS: LoanRenewalRebookApiResources = {
  GET_MILESTONES: {
    URL: "/admin/business/cr/:crId/milestone",
    METHOD: "GET",
  },
  CHECK_RENEW_REBOOK_ALLOWED: {
    URL: "/admin/renew-rebook/:serviceRequestId/status",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_JOB_DETAILS: {
    URL: "/admin/renew-rebook/:serviceRequestId/customer-request",
    METHOD: "GET",
  },
  CREATE_LOAN_RENEWAL_JOB: {
    URL: "/admin/renew-rebook/:serviceRequestId",
    METHOD: "POST",
  },
  GET_LOAN_RENEWAL_CONFIG: {
    URL: "/admin/renew-rebook/config",
    METHOD: "GET",
  },
  UPDATE_LOAN_RENEWAL_REBOOK_MILESTONE: {
    URL: "/admin/renew-rebook/:crId/status",
    METHOD: "PUT",
  },
  DELETE_LOAN_DOCUMENT: {
    URL: "/admin/renew-rebook/:crId/document",
    METHOD: "DELETE",
  },
  CREATE_LOAN_DOCUMENT: {
    URL: "/admin/renew-rebook/:crId/document",
    METHOD: "POST",
  },
  UPDATE_LENDER_DETAILS: {
    URL: "/admin/renew-rebook/cr/:crId/lender-detail",
    METHOD: "PUT",
  },
  GET_DOCUMENT_LIST_MILESTONE: {
    URL: "/admin/renew-rebook/cr/:crId/milestone-document-list",
    METHOD: "GET",
  },
  GET_LOAN_RENEWAL_OFFER: {
    URL: "admin/renew-rebook/cr/:crId/cm-offer",
    METHOD: "GET",
  },
  POST_LENDER_GATING: {
    URL: "admin/renew-rebook/:crId/lender-gating",
    METHOD: "PUT",
  },
  GET_LENDER_GATING_STATUS: {
    URL: "admin/renew-rebook/:crId/lender-gating/status",
    METHOD: "GET",
  },
  GET_DIGITAL_DOCUMENT_LINK: {
    URL: "business/v2/cr/:crId/digital-document-link",
    METHOD: "GET",
  },
  GET_DIGITAL_DOCUMENT_LINK_RENEW_REBOOK: {
    URL: "/admin/renew-rebook/cr/:crId/digital-document-link",
    METHOD: "GET",
  },
  GET_DOCUMENT: {
    URL: "admin/business/v2/cr/:crId/document-list",
    METHOD: "GET",
  },
  GET_FEATURE_FLAG_STATUS: {
    URL: "/config/app/is-feature-active",
    METHOD: "GET",
  },
  CLOSE_RENEWAL_LOAN: {
    URL: "admin/business/cr/:crId/cancel",
    METHOD: "POST",
  },
  GET_LOAN_CONFIG_DETAILS: {
    URL: "admin/renew-rebook/cr/:crId/loan-config-details",
    METHOD: "GET",
  },
  CREATE_LOAN_ON_LENDER: {
    URL: "admin/renew-rebook/cr/:crId/create-loan",
    METHOD: "POST",
  },
  GET_LOAN_RENEWAL_DETAILS: {
    URL: "admin/renew-rebook/cr/:crId/loan-detail",
    METHOD: "GET",
  },
  ASSIGN_LOAN_RENEWAL_JOB: {
    URL: "admin/renew-rebook/cr-job/:crJobId/assign",
    METHOD: "PUT",
  },
  UNASSIGN_LOAN_RENEWAL_JOB: {
    URL: "admin/renew-rebook/cr-job/:crJobId/remove",
    METHOD: "PUT",
  },
  LOAN_RENEWAL_CHECKER: {
    URL: "admin/renew-rebook/:crId/checker/validate-loan-value",
    METHOD: "POST",
  },
  LOAN_RENEWAL_LENDER_DOCS: {
    URL: "/admin/renew-rebook/:crId/lender-document",
    METHOD: "GET",
  },
  LOAN_RENEWAL_POST_DOCS: {
    URL: "/admin/renew-rebook/cr/:crId/post-loan-approval-document",
    METHOD: "GET",
  },

  //this end point can only be used in renew rebook flow, please do not use this anywhere else
  GET_ENCRYPTED_DOCUMENT_DETAIL: {
    URL: "/admin/renew-rebook/cr/:crId/document-detail",
    METHOD: "GET",
  },
  LOAN_CREATION_STATUS: {
    URL: "/admin/renew-rebook/cr/:crId/loan-creation-status",
    METHOD: "GET",
  },
  GET_AUTO_LOAN_RENEWAL_STATUS: {
    URL: "/admin/renew-rebook/cr/:crId/auto-loan-renewal-status",
    METHOD: "GET",
  },

  CREATE_AUTO_LOAN_RENEWAL_JOB: {
    URL: "/admin/renew-rebook/:serviceRequestId/auto",
    METHOD: "POST",
  },
};

const loanRenewalRebookApi: LoanRenewalRebookApiInterface = {
  getLoanRenewalRebookMilestones: async (payload: GetLoanRenewalRebookMilestonesPayload) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_MILESTONES,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
  },
  checkIsRenewRebookAllowed: async ({serviceRequestId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CHECK_RENEW_REBOOK_ALLOWED,
      options: {
        pathVars: {
          serviceRequestId: serviceRequestId,
        },
      },
    });
  },
  getLoanRenewalJobDetails: async ({serviceRequestId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LOAN_RENEWAL_JOB_DETAILS,
      options: {
        pathVars: {
          serviceRequestId: serviceRequestId,
        },
      },
    });
  },
  createLoanRenewalJob: async ({serviceRequestId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CREATE_LOAN_RENEWAL_JOB,
      options: {
        pathVars: {
          serviceRequestId: serviceRequestId,
        },
      },
    });
  },
  getLoanRenewalConfig: async () => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LOAN_RENEWAL_CONFIG,
    });
  },
  updateMileStoneStatus: async ({crId, crJobId, crJobStatus}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.UPDATE_LOAN_RENEWAL_REBOOK_MILESTONE,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          crJobId: crJobId,
          crJobStatus: crJobStatus,
        },
      },
    });
  },
  deleteLoanDocument: async ({crId, crJobId, documentType}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.DELETE_LOAN_DOCUMENT,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          crJobId,
          documentType,
        },
      },
    });
  },
  createLoanDocument: async ({
    crId,
    formData,
    documentType,
    crJobId,
    isAddressProof,
    isIdProof,
    receivingDate,
    fileType,
    physicalState,
    fileContentType,
    backFileContentType,
    fileName,
    backFileName,
  }) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CREATE_LOAN_DOCUMENT,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          documentType,
          isAddressProof,
          crJobId,
          isIdProof,
          receivingDate,
          fileType,
          physicalState,
          fileContentType,
          backFileContentType,
          fileName,
          backFileName,
        },
        data: formData,
      },
    });
  },
  updateLenderDetails: async ({crId, lenderName, aadhaarAddressSameAsPermanent}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.UPDATE_LENDER_DETAILS,
      options: {
        pathVars: {
          crId,
        },
        data: {
          lenderName,
          aadhaarAddressSameAsPermanent,
        },
      },
    });
  },
  getDocumentListByMilestone: async ({crId, crMilestoneEnum}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_DOCUMENT_LIST_MILESTONE,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          crMilestoneEnum,
        },
      },
    });
  },
  getLoanRenewalOffer: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LOAN_RENEWAL_OFFER,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  postLenderGating: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.POST_LENDER_GATING,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getLenderGatingStatus: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LENDER_GATING_STATUS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getDigitalDocumentLink: async ({
    crId,
    crJobId,
    lenderName,
    documentEnum,
    isRenewRebook = false,
  }) => {
    return await goldApiService({
      resource: isRenewRebook
        ? LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_DIGITAL_DOCUMENT_LINK_RENEW_REBOOK
        : LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_DIGITAL_DOCUMENT_LINK,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          crJobId,
          lenderName,
          documentEnum,
        },
      },
    });
  },
  getDocument: async ({crId, documentTypeList}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_DOCUMENT,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          documentTypeList,
        },
      },
    });
  },
  getFeatureFlagStatus: async ({moduleEnum, moduleFeatureEnum}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_FEATURE_FLAG_STATUS,
      options: {
        queryParams: {
          moduleEnum,
          moduleFeatureEnum,
        },
      },
    });
  },
  closeRenewalLoan: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CLOSE_RENEWAL_LOAN,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getLoanConfigDetails: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LOAN_CONFIG_DETAILS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  createLoanOnLender: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CREATE_LOAN_ON_LENDER,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getRenewRebookLoanDetail: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_LOAN_RENEWAL_DETAILS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  assignLoanRenewalJob: async ({crJobId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.ASSIGN_LOAN_RENEWAL_JOB,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  unAssignLoanRenewalJob: async ({crJobId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.UNASSIGN_LOAN_RENEWAL_JOB,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },

  loanRenewalChecker: async ({crId, fileData, loanType}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.LOAN_RENEWAL_CHECKER,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          loanType,
        },
        data: fileData,
      },
    });
  },

  getLoanRenewalLenderDocs: async ({crId, loanType}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.LOAN_RENEWAL_LENDER_DOCS,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          loanType,
        },
      },
    });
  },

  postLoanApprovalRenewalDocs: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.LOAN_RENEWAL_POST_DOCS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getEncryptedDocumentDetail: async ({crId, documentType}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_ENCRYPTED_DOCUMENT_DETAIL,
      options: {
        pathVars: {
          crId,
        },
        queryParams: {
          documentType,
        },
      },
    });
  },

  getLoanCreationStatus: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.LOAN_CREATION_STATUS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
  getAutoLoanRenewalStatus: async ({crId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.GET_AUTO_LOAN_RENEWAL_STATUS,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },

  createAutoLoanRenewalJob: async ({serviceRequestId}) => {
    return await goldApiService({
      resource: LOAN_RENEWAL_REBOOK_ENDPOINTS.CREATE_AUTO_LOAN_RENEWAL_JOB,
      options: {
        pathVars: {
          serviceRequestId: serviceRequestId,
        },
      },
    });
  },
};

export {LOAN_RENEWAL_REBOOK_ENDPOINTS};
export default loanRenewalRebookApi;
