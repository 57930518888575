import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  call,
  getContext,
  put,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {OpenCRActions, OpenCRActionTypes} from "./actions";
import {
  setOpenCrAgentReport,
  setOpenCrClusterDetail,
  setOpenCrError,
  setOpenCrLoading,
  setOpenCrNewView,
  toggleDrawerOpen,
} from "./reducer";
import {
  CRAssignJobPayload,
  CRCreateJobPayload,
  CrIdPayload,
  GetCRDetailPayload,
  GetCRListPayload,
  GetNoteListPayload,
  GetOpenCrViewPayload,
  OpenCRResponse,
  UpdateCallingStatusPayload,
  UpdateMeetingTimePayload,
} from "app/infra/services/api/scheduling/openCR/types";
import {snackBarActions} from "../../snackbar";
import {UNQUALIFIED_STATUS} from "app/enums/scheduling";
import {GetUnscheduledAgentReportPayload} from "app/infra/services/api/scheduling/unscheduled/types";

const {
  getCRListSuccess,
  getCRListFailure,
  getCRDetailSuccess,
  getCRDetailFailure,
  getLeadSourceDetailSuccess,
  getLeadSourceDetailFailure,
  getAddressDetailSuccess,
  getAddressDetailFailure,
  getTakeOverDetailSuccess,
  getTakeOverDetailFailure,
  getPrimaryLMJobDetailSuccess,
  getPrimaryLMJobDetailFailure,
  getEligibleJobsSuccess,
  getEligibleJobsFailure,
  getMeetingTimeSuccess,
  getMeetingTimeFailure,
  updateCallingStatusSuccess,
  updateCallingStatusFailure,
  updateMeetingTimeSuccess,
  updateMeetingTimeFailure,
  crAssignJobSuccess,
  crAssignJobFailure,
  crCreateJobSuccess,
  crCreateJobFailure,
  getNoteListSuccess,
  getNoteListFailure,
  enableOpenCrManualAssignment,
} = OpenCRActions;

function* getCRListFlow(action: PayloadAction<GetCRListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {error, crList} = yield call(api.openCR.getCRList, action.payload);

  if (error) {
    yield put(getCRListFailure({error, crList: null}));
  } else {
    yield put(getCRListSuccess({error, crList}));
  }
}

function* getOpenCrAgentReport(
  action: PayloadAction<GetUnscheduledAgentReportPayload>,
) {
  yield put(setOpenCrLoading({key: "openCrAgentReport", value: true}));
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.openCR.getOpenCrAgentReport,
    action.payload,
  );
  if (error) {
    yield put(setOpenCrError({key: "openCrAgentReport", value: error}));
  } else {
    yield put(setOpenCrAgentReport(response));
  }
}

function* getOpenCrView(action: PayloadAction<GetOpenCrViewPayload>) {
  yield put(setOpenCrLoading({key: "openCrFeatureFlag", value: true}));
  const api: GoldApi = yield getContext("api");
  const {response} = yield call(api.openCR.getOpenCrView, action.payload);
  yield put(setOpenCrNewView(response));
}

function* getOpenCrClusterDetail(action: PayloadAction<GetNoteListPayload>) {
  yield put(setOpenCrLoading({key: "openCrClusterDetail", value: true}));

  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.openCR.getOpenCrClusterDetail,
    action.payload,
  );
  if (error) {
    yield put(setOpenCrError({key: "openCrClusterDetail", value: error}));
  } else {
    yield put(setOpenCrClusterDetail(response));
  }
}

function* getCRDetailFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {crDetail, error} = yield call(
    api.openCR.getCRDetail,
    action.payload,
  );
  if (error) {
    yield put(getCRDetailFailure({crDetail: null, error}));
  } else {
    yield put(getCRDetailSuccess({crDetail, error}));
  }
}

function* getLeadSourceDetailsFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {leadSourceDetail, error} = yield call(
    api.openCR.getLeadSourceDetails,
    action.payload,
  );
  if (error) {
    yield put(getLeadSourceDetailFailure({leadSourceDetail, error}));
  } else {
    yield put(getLeadSourceDetailSuccess({leadSourceDetail, error}));
  }
}

function* getAddressDetailsFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {addresses, error} = yield call(
    api.openCR.getAddressDetails,
    action.payload,
  );
  if (error) {
    yield put(getAddressDetailFailure({addresses, error}));
  } else {
    yield put(getAddressDetailSuccess({addresses, error}));
  }
}

function* getTakeOverDetailsFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {takeOverDetails, error} = yield call(
    api.openCR.getTakeOverDetails,
    action.payload,
  );
  if (error) {
    yield put(getTakeOverDetailFailure({takeOverDetails, error}));
  } else {
    yield put(getTakeOverDetailSuccess({takeOverDetails, error}));
  }
}

function* getPrimaryLMJobDetailsFlow(
  action: PayloadAction<GetCRDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {primaryLMJob, error} = yield call(
    api.openCR.getPrimaryLMJobDetails,
    action.payload,
  );
  if (error) {
    yield put(getPrimaryLMJobDetailFailure({primaryLMJob, error}));
  } else {
    yield put(getPrimaryLMJobDetailSuccess({primaryLMJob, error}));
  }
}

function* getEligibleJobsFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {eligibleJobs, error} = yield call(
    api.openCR.getEligibleJobs,
    action.payload,
  );
  if (error) {
    yield put(getEligibleJobsFailure({eligibleJobs, error}));
  } else {
    yield put(getEligibleJobsSuccess({eligibleJobs, error}));
  }
}

function* getMeetingTimeFlow(action: PayloadAction<GetCRDetailPayload>) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(api.openCR.getMeetingTime, action.payload);
  if (error) {
    yield put(getMeetingTimeFailure({data, error}));
  } else {
    yield put(getMeetingTimeSuccess({data, error}));
  }
}

function* updateCallingStatusFlow(
  action: PayloadAction<UpdateCallingStatusPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(
    api.openCR.updateCallingStatus,
    action.payload,
  );
  if (error) {
    yield put(updateCallingStatusFailure({data, error}));
  } else {
    let payload = {
      ...data,
      callConfirmationStatus: action.payload.status,
      note: action.payload.note,
      keepInList: true,
    };
    if (action.payload.status === UNQUALIFIED_STATUS.value) {
      payload = {...data, keepInList: false};
      yield put(toggleDrawerOpen(false));
    }
    yield put(updateCallingStatusSuccess({data: payload, error}));
  }
}

function* updateCallingStatusFailureFlow(
  action: PayloadAction<OpenCRResponse>,
) {
  yield put(
    snackBarActions.open({
      message: action.payload?.error?.message || "Details could not be updated",
      variant: "error",
    }),
  );
}

function* updateMeetingTimeFlow(
  action: PayloadAction<UpdateMeetingTimePayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(
    api.openCR.updateMeetingTime,
    action.payload,
  );
  if (error) {
    yield put(updateMeetingTimeFailure({data, error}));
  } else {
    yield put(updateMeetingTimeSuccess({data, error}));
  }
}

function* updateMeetingTimeFailureFlow(action: PayloadAction<OpenCRResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload?.error?.message || "Details could not be updated",
      variant: "error",
    }),
  );
}

function* crAssignJobFlow(action: PayloadAction<CRAssignJobPayload>) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(api.openCR.assignCRJob, action.payload);
  if (error) {
    yield put(crAssignJobFailure({data, error}));
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(crAssignJobSuccess({data, error}));
  }
}

function* crAssignJobFailureFlow(action: PayloadAction<OpenCRResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload?.error?.message || "Job could not be created",
      variant: "error",
    }),
  );
}

function* crCreateJobFlow(action: PayloadAction<CRCreateJobPayload>) {
  const api: GoldApi = yield getContext("api");

  const {data, error} = yield call(api.openCR.createCRJob, action.payload);
  if (error) {
    yield put(crCreateJobFailure({data, error}));
  } else {
    yield put(toggleDrawerOpen(false));
    yield put(crCreateJobSuccess({data, error}));
  }
}

function* getNoteListFlow(action: PayloadAction<GetNoteListPayload>) {
  const api: GoldApi = yield getContext("api");

  const {noteList, error} = yield call(
    api.openCR.getNoteList,
    action.payload,
  );
  if (error) {
    yield put(getNoteListFailure({noteList, error}));
  } else {
    yield put(getNoteListSuccess({noteList, error}));
  }
}

function* enableOpenCrManualAssignmentFlow(action: PayloadAction<CrIdPayload>) {
  yield put(
    setOpenCrLoading({key: "enableOpenCrManualAssignment", value: true}),
  );
  const api: GoldApi = yield getContext("api");

  const {response, error} = yield call(
    api.openCR.enableOpenCrManualAssignmentFlow,
    action.payload,
  );
  if (error) {
    yield put(
      setOpenCrLoading({key: "enableOpenCrManualAssignment", value: error}),
    );
    yield put(
      snackBarActions.open({
        message: error,
        variant: "failure",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: response?.message || "Manual flow Enabled!",
        variant: "success",
      }),
    );
    yield put(setOpenCrNewView(response));
  }
  yield put(
    setOpenCrLoading({key: "enableOpenCrManualAssignment", value: false}),
  );
}

function* crCreateJobFailureFlow(action: PayloadAction<OpenCRResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload?.error?.message || "Job could not be created",
      variant: "error",
    }),
  );
}

// >>>>>>>> Open CR Sagas <<<<<<<<<

export default function* unscheduledSaga() {
  yield takeLeading(
    OpenCRActionTypes.GetOpenCrAgentReport,
    getOpenCrAgentReport,
  );

  yield takeLeading(
    OpenCRActionTypes.GetOpenCrClusterDetail,
    getOpenCrClusterDetail,
  );

  yield takeLeading(OpenCRActionTypes.GetCRList, getCRListFlow);

  yield takeLatest(OpenCRActionTypes.GetOpenCrView, getOpenCrView);

  yield takeLatest(OpenCRActionTypes.GetCRDetail, getCRDetailFlow);

  yield takeLatest(
    OpenCRActionTypes.GetLeadSourceDetail,
    getLeadSourceDetailsFlow,
  );

  yield takeLatest(OpenCRActionTypes.GetAddressDetail, getAddressDetailsFlow);

  yield takeLatest(OpenCRActionTypes.GetTakeOverDetail, getTakeOverDetailsFlow);

  yield takeLatest(
    OpenCRActionTypes.GetPrimaryLMJobDetail,
    getPrimaryLMJobDetailsFlow,
  );

  yield takeLatest(OpenCRActionTypes.GetEligibleJobs, getEligibleJobsFlow);

  yield takeLatest(OpenCRActionTypes.GetMeetingTime, getMeetingTimeFlow);

  yield takeLatest(
    OpenCRActionTypes.UpdateCallingStatus,
    updateCallingStatusFlow,
  );
  yield takeLatest(
    OpenCRActionTypes.UpdateCallingStatusSuccess,
    function* updateCallingStatusSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Open CR details updated successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    OpenCRActionTypes.UpdateCallingStatusFailure,
    updateCallingStatusFailureFlow,
  );

  yield takeLatest(OpenCRActionTypes.UpdateMeetingTime, updateMeetingTimeFlow);
  yield takeLatest(
    OpenCRActionTypes.UpdateMeetingTimeSuccess,
    function* updateMeetingTimeSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Open CR details updated successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    OpenCRActionTypes.UpdateMeetingTimeFailure,
    updateMeetingTimeFailureFlow,
  );

  yield takeLatest(OpenCRActionTypes.CRAssignJob, crAssignJobFlow);
  yield takeLatest(
    OpenCRActionTypes.CRAssignJobSuccess,
    function* crAssignJobSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Job created successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    OpenCRActionTypes.CRAssignJobFailure,
    crAssignJobFailureFlow,
  );

  yield takeLatest(OpenCRActionTypes.CRCreateJob, crCreateJobFlow);
  yield takeLatest(
    OpenCRActionTypes.CRCreateJobSuccess,
    function* crCreateJobSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Job created successfully",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    OpenCRActionTypes.CRCreateJobFailure,
    crCreateJobFailureFlow,
  );

  yield takeLatest(OpenCRActionTypes.GetNoteList, getNoteListFlow);

  yield takeLatest(
    OpenCRActionTypes.EnableOpenCrManualAssignment,
    enableOpenCrManualAssignmentFlow,
  );
}
