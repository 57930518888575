const wordsToNumbers = (words: string): number => {
  const wordsToNumbersMap: {[key: string]: number} = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    thirty: 30,
    forty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90,
    hundred: 100,
    thousand: 1000,
    lakh: 100000,
    million: 1000000,
  };

  const wordsArray: string[] = words.toLowerCase().split(" ");

  let total: number = 0;
  let currentNumber: number = 0;

  for (const word of wordsArray) {
    const number: number | undefined = wordsToNumbersMap[word];
    if (number !== undefined) {
      if (number >= 100000) {
        total = (total + currentNumber) * number;
        currentNumber = 0;
      } else if (number >= 1000) {
        if (total > 99999) {
          total += currentNumber * number;
        } else {
          total = (total + currentNumber) * number;
        }
        currentNumber = 0;
      } else if (number >= 100) {
        currentNumber *= number;
      } else {
        currentNumber += number;
      }
    } else {
      throw new Error(`Unknown word: ${word}`);
    }
  }

  return total + currentNumber;
};

export default wordsToNumbers;
