import React, {useState, useEffect} from "react";
import RowContainer from "../styles/RowContainer";
import SubTitle from "../styles/SubTitle";
import Title from "../styles/Title";
import MiniTitle from "../styles/MiniTitle";
import getTakeoverDetail from "api/scheduling/getTakeoverDetail";
import Loading from "app/components/Loading";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import getDocument from "api/business/getDocument";
import Aadhar from "../../../NewUploadKYC/KYCDetails/Shivalik/Addhar";
import {Divider} from "@mui/material";
import {ModalContent} from "../../../NewUploadKYC/KYCDetails/Shivalik";
import DocView from "../../../CommonComponents/DocView";

const PledgeCardDetails = ({selectedPayload}) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });
  const [payload, setPayload] = useState({});
  const [pledgecard, setPledgeCard] = useState([]);
  const [pledgecardModal, setPledgecardModal] = useState(false);

  useEffect(() => {
    (async () => {
      const [response, pledgeCardResponse] = await Promise.all([
        getTakeoverDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
        getDocument({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          documentType: ["TAKEOVER_PLEDGE_CARD"],
        }),
      ]);

      if (
        response.statusCode === 200 &&
        pledgeCardResponse.statusCode === 200
      ) {
        setPayload(response.payload);
        setState(() => ({
          loading: false,
          error: false,
          errorMessage: "",
        }));
        setPledgeCard(pledgeCardResponse.payload);
      } else {
        setState(() => ({
          loading: false,
          error: true,
          errorMessage: response.error || "Something went wrong",
        }));
      }
    })();
  }, [selectedPayload.id]);

  if (state.loading) {
    return <Loading height={"20vh"} />;
  }

  const details = [
    {
      key: "Lender Name",
      value: payload.lenderName,
    },
    {
      key: "Current Annual ROI",
      value: `${payload.currentAnnualRoi}%`,
    },
    {
      key: "Current LTV",
      value: `${payload.currentLtv}%`,
    },
    {
      key: "Current Loan Amount",
      value: numberWithCurrencyAndComma(payload.currentLoanAmount),
    },
    {
      key: "Bank Name",
      value: payload.bankDisplayName,
    },
    {
      key: "Bank Distance",
      value: `${payload.bankDistance}km`,
    },
    {
      key: "Bank Closing Time",
      value: payload.bankClosingTime,
    },
    {
      key: "Lender Type",
      value: payload.lenderType,
    },
    {
      key: "Lender Address",
      value: payload.lenderBranchAddress,
    },
  ];

  const PledgeCardModal = () => (
    <ModalContent doc={pledgecard} title="Pledge Card" />
  );

  return (
    <div>
      <Title>
        Please verify all details and mark checkbox to proceed to next step or
        send back and ask LM to fill correctly
      </Title>

      <RowContainer>
        {details.map((e) => {
          return (
            <div key={e.key}>
              <SubTitle>{e.key}</SubTitle>
              <MiniTitle>{e.value}</MiniTitle>
            </div>
          );
        })}
      </RowContainer>

      <Divider style={{marginTop: "2rem", marginBottom: "1rem"}} />

      {pledgecard.length !== 0 && (
        <Aadhar
          title="Pledge Card"
          setOpenAadhaarModal={setPledgecardModal}
          aadharDetails={pledgecard}
        />
      )}

      {pledgecard && pledgecard.length > 0 && (
        <DocView
          open={pledgecardModal}
          docsList={[
            pledgecard[0].fileUrl,
            pledgecard[0].backfileUrl,
          ].filter((doc) => doc)}
          BottomComponent={PledgeCardModal}
          title="Pledge Card Details"
          onCloseHandler={() => setPledgecardModal(false)}
        />
      )}
    </div>
  );
};

export default PledgeCardDetails;
