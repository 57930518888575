export const SignedSummaryDocEnum = {
  SIGNED_LOAN_SUMMARY_DOCUMENT: "SIGNED_LOAN_SUMMARY_DOCUMENT",
};

export const SignedPledgeDocEnum = {
  GL_SIGNED_PLEDGE_CARD: "GL_SIGNED_PLEDGE_CARD",
  PL_SIGNED_SANCTION_LETTER: "PL_SIGNED_SANCTION_LETTER",
};

export const loanTypeEnum = {
  GOLD_LOAN: "GOLD_LOAN",
  PERSONAL_LOAN: "PERSONAL_LOAN",
};

export const RemarkEnums = {
  PROMISED_TO_PAY: "PROMISED_TO_PAY",
  OTHERS: "OTHERS",
};

export const VirtualAddressTypesEnum = {
  PART_RELEASE: "PART_RELEASE",
  CLOSURE: "CLOSURE",
  REBOOK: "REBOOK",
  RENEW: "RENEW",
};