import {createAction} from "@reduxjs/toolkit";
import {AllowPaymentPayload, GetOrnamentListPayload, ShareOfferPayload, UpdateCMOfferPayload, UpdateCstOfferPayload, UpdateOfferRoiPayload} from "app/infra/services/api/serviceDesk/partRelease/types";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";

const partReleaseActionTypes = {
  GetPartReleaseOfferHistory: "[Part Release] Get Part Release Offer History",
  GetPartReleaseOfferHistorySuccess: "[Part Release] Get Part Release Offer History Success",
  GetPartReleaseOfferHistoryFailure: "[Part Release] Get Part Release Offer History Failure",
  GetPartReleaseData: "[Part Release] Get Part Release Data",
  GetPartReleaseDataSuccess: "[Part Release] Get Part Release Data Success",
  GetPartReleaseDataFailure: "[Part Release] Get Part Release Data Failure",
  GetOrnamentList: "[Part Release] Get Ornament List",
  UpdateCstOffer: "[Part Release] Update CST Offer",
  GetOfferHistoryOrnamentSelectionDetail: "[Part Release] Get Offer History Ornament Selection Detail",
  GetConsumerOfferDetail: "[Part Release] Get Consumer Offer Detail",
  GetNewLoanOfferDetail: "[Part Release] Get New Loan Offer Detail",
  GetNetPayableAmount: "[Part Release] Get Net Payable Amount",
  CheckIsOfferExpired: "[Part Release] Check Is Offer Expired",
  ShareOffer: "[Part Release] Share Offer",
  GetCstConfig: "[Part Release] Get CST Config",
  GetCmConfig: "[Part Release] Get CM Config",
  GetCmOfferDetail: "[Part Release] Get CM Offer Detail",
  UpdateCmOffer: "[Part Release] Update CM Offer",
  UpdateOfferRoi: "[Part Release] Update Offer ROI",
  AllowPayment: "[Part Release] Allow Payment",
};

const partReleaseActions = {
  getPartReleaseOfferHistory: createAction<GetOrnamentListPayload>(
    partReleaseActionTypes.GetPartReleaseOfferHistory,
  ),
  getPartReleaseData: createAction<RequestIdPayload>(
    partReleaseActionTypes.GetPartReleaseData,
  ),
  getPartReleaseDataSuccess: createAction<{
    response: any;
  }>(partReleaseActionTypes.GetPartReleaseDataSuccess),
  getPartReleaseDataFailure: createAction<{ error: string }>(
    partReleaseActionTypes.GetPartReleaseDataFailure,
  ),
  getOrnamentList: createAction<GetOrnamentListPayload>(
    partReleaseActionTypes.GetOrnamentList,
  ),
  updateCstOffer: createAction<UpdateCstOfferPayload>(partReleaseActionTypes.UpdateCstOffer),
  getOfferHistoryOrnamentSelectionDetail: createAction<{
    requestId: number;
    offerBy: "DEFAULT_OFFER" | "CM";
    offerHistoryId: number;
  }>(partReleaseActionTypes.GetOfferHistoryOrnamentSelectionDetail),
  getConsumerOfferDetail: createAction<RequestIdPayload>(
    partReleaseActionTypes.GetConsumerOfferDetail,
  ),
  getNewLoanOfferDetail: createAction<GetOrnamentListPayload>(
    partReleaseActionTypes.GetNewLoanOfferDetail,
  ),
  getNetPayableAmount: createAction<RequestIdPayload>(
    partReleaseActionTypes.GetNetPayableAmount,
  ),
  checkIsOfferExpired: createAction<GetOrnamentListPayload>(
    partReleaseActionTypes.CheckIsOfferExpired,
  ),
  shareOffer: createAction<ShareOfferPayload>(
    partReleaseActionTypes.ShareOffer,
  ),
  getCstConfig: createAction<RequestIdPayload>(partReleaseActionTypes.GetCstConfig),
  getCmConfig: createAction<RequestIdPayload>(partReleaseActionTypes.GetCmConfig),
  getCmOfferDetail: createAction<RequestIdPayload>(partReleaseActionTypes.GetCmOfferDetail),
  updateCmOffer: createAction<UpdateCMOfferPayload>(partReleaseActionTypes.UpdateCmOffer),
  updateOfferRoi: createAction<UpdateOfferRoiPayload>(partReleaseActionTypes.UpdateOfferRoi),
  allowPayment: createAction<AllowPaymentPayload>(partReleaseActionTypes.AllowPayment),
};

export default partReleaseActions;
export {partReleaseActionTypes};