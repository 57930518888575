import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import SuccessOrFailureText from "../SuccessOrFailureText";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import OtherChargesModal from "./OtherChargesModal";
import {getValidTime} from "_metronic/utils/moment";
import {IGTable} from "app/components";
import OverduePaymentDetailModal from "./OverduePaymentDetailModal";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {
  loanSummaryActions,
  loanSummarySelectors,
} from "app/store/loanSummary";
import {IGTableColsInterface} from "app/components/IGTable";
import {LoanSummaryModel} from "app/models/loanSummary.model";
import {loanSelectors, loanActions} from "app/store/loan";
import {isNil} from "lodash";

const LoanSummary = () => {
  const {loanId} = useParams();
  const {t, tTableHeader} = useIGTranslation();
  const dispatch = useAppDispatch();
  const [openOtherChargesModal, setOpenOtherChargesModal] = useState(false);
  const [openOverdueModal, setOpenOverdueModal] = useState(false);

  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const loanSummary = useAppSelector((state) =>
    loanSummarySelectors.getLoanSummary(state, Number(loanId)),
  );
  const {getLoanSummaryDetails} = useAppSelector(
    loanSummarySelectors.getLoading,
  );

  useEffect(() => {
    if (!loanId) return;
    dispatch(
      loanActions.getLoanDetails({
        loanId: Number(loanId),
      }),
    );
    dispatch(
      loanSummaryActions.getLoanSummaryDetails({
        loanId: Number(loanId),
        getDetail: true,
      }),
    );
  }, []);

  const tableCols: IGTableColsInterface<LoanSummaryModel> = [
    {
      field: "balancePrincipal",
      headerName: t("BALANCE_PRINCIPAL"),
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "interestTillDate",
      headerName: t("CUSTOMER_PROFILE.INTEREST_TILL_DATE"),
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "nextInstalmentAmount",
      headerName: "Next Due",
      renderCell: ({value, row}) => (
        <>
          {numberWithCurrencyAndComma(value)}{" "}
          {row.nextInstalmentDate
            ? ` on ${getValidTime(row.nextInstalmentDate).format("DD/MM/YYYY")}`
            : ""}
        </>
      ),
    },
    {
      field: "amountPaid",
      headerName: t("PAID"),
      renderCell: ({value}) => <>{numberWithCurrencyAndComma(value)}</>,
    },
    {
      field: "totalOverDueAmount",
      headerName: t("OVERDUE"),
      sortable: false,
      renderHeader: ({colDef}) => {
        if (loanSummary && loanSummary.overDueDay >= 30) {
          return (
              <span
                style={{
                  fontWeight: 500,
                  textDecoration: "underline",
                  color: "goldenrod",
                  cursor: "pointer",
                }}
                onClick={() => setOpenOverdueModal(true)}
              >
                {colDef.headerName} | DPD
              </span>
          );
        }
        return (
            <span style={{fontWeight: 500}}>{colDef.headerName} | DPD</span>
        );
      },
      renderCell: ({value, row}) => (
        <Box sx={{
          display: "flex",
          flexDirection: "column",
        }}>
          {SuccessOrFailureText({
            text: numberWithCurrencyAndComma(value),
            status: value < 0 ? "SUCCESS" : "FAIL",
          })}
          <Typography color="text.secondary" fontSize={12}>
            {row.overDueDay} days
          </Typography>
        </Box>
      ),
    },
    {
      field: "unsettledAmount",
      headerName: "Overflow Amount",
      renderCell: ({value}) => (
        <>
          {SuccessOrFailureText({
            text: numberWithCurrencyAndComma(value),
            status: value < 0 ? "SUCCESS" : "FAIL",
          })}
        </>
      ),
    },
    {
      field: "otherCharges",
      headerName: t("CUSTOMER_PROFILE.OTHER_CHARGES"),
      renderCell: ({value}) => (
        <Typography
          sx={{
            textDecoration: "underline",
            color: "gold",
            cursor: "pointer",
          }}
          onClick={() => setOpenOtherChargesModal(true)}
        >
          {numberWithCurrencyAndComma(value)}
        </Typography>
      ),
    },
  ];

  return (
    <>
      {!isNil(loanDetails?.goldLoanAccountNumber) &&
      loanDetails?.goldLoanAccountNumber.charAt(0) === "F" ? (
        <></>
      ) : (
        <IGTable
          hideFooter
          loading={getLoanSummaryDetails}
          tableCols={tableCols}
          tableData={loanSummary ? [loanSummary] : []}
          getRowId={(row) => row.loanId}
          components={{
            Header: (params) => (
              <Box {...params} p={2}>
                <Typography variant="h5">
                  {tTableHeader("LOAN_SUMMARY")}
                </Typography>
              </Box>
            ),
          }}
        />
      )}

      {openOtherChargesModal && loanId && (
        <OtherChargesModal
          open={openOtherChargesModal}
          setOpen={() => setOpenOtherChargesModal(false)}
        />
      )}
      {openOverdueModal && (
        <OverduePaymentDetailModal
          open={openOverdueModal}
          onClose={() => setOpenOverdueModal(false)}
        />
      )}
    </>
  );
};
export default LoanSummary;
