import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/business/config/cr/:crId/pl-lender",
  METHOD: "GET",
};

export const getPersonalLoanLenders = async (crId) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    },
  });
  return response;
};

export default getPersonalLoanLenders;
