import React, {useEffect, useState, useCallback} from "react";
// import Text from "./styles/Text";
// import Link from "./styles/Link";
import Aadhar from "../../../NewUploadKYC/KYCDetails/Shivalik/Addhar";
import PanCard from "../../../NewUploadKYC/KYCDetails/Shivalik/PanCard";
import DocView from "../../../CommonComponents/DocView";
import {ModalContent} from "../../../NewUploadKYC/KYCDetails/Shivalik/index";
import Video from "../../../CommonComponents/Video";

import getDocument from "api/business/getDocument";
import Loading from "app/components/Loading";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {useSnackBar} from "app/store/hooks";

const KYCDetails = ({isFresh, selectedPayload, setShowTakeoverKycAlert}) => {
  const {showError} = useSnackBar();
  const [openAadhaarModal, setOpenAadhaarModal] = useState(false);
  const [openPanModal, setOpenPanModal] = useState(false);

  const [state, setState] = useState({
    loading: false,
    payload: undefined,
    error: false,
    errorMessage: "",
  });

  const [aadhar, setAddhar] = useState({});
  const [pan, setPan] = useState({});
  const [video, setVideo] = useState({});

  const crId = selectedPayload?.id || {};
  const docs = isFresh
    ? ["AADHAR", "PAN", "CUSTOMER_KYC_VIDEO"]
    : ["TAKEOVER_AADHAR", "TAKEOVER_PAN", "CUSTOMER_KYC_VIDEO"];

  const fetchBlackListCheckRequired = useCallback(async () => {
    const {response, error} = await activeLoansApi.getBlackListCheckRequired(
      crId,
    );
    if (!error) {
      if (response && aadhar && pan) {
        const {isBlackListed: isAadhaarBlackListed} = aadhar;
        const {isBlackListed: isPanBlackListed} = pan;
        if (isAadhaarBlackListed || isPanBlackListed) {
          setShowTakeoverKycAlert(true);
        }
      } else {
        setShowTakeoverKycAlert(false);
      }
    } else {
      showError(error.message);
    }
  }, [crId, showError]);

  const getDetails = useCallback(async () => {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    const response = await getDocument({
      accessToken: localStorage.getItem("authToken"),
      crId: selectedPayload.id,
      documentType: docs,
    });

    if (response.statusCode === 200) {
      setState((state) => ({
        ...state,
        loading: false,
        payload: response.payload,
      }));
      setAddhar(
        response.payload.find(
          (e) =>
            e.documentType === "TAKEOVER_AADHAR" || e.documentType === "AADHAR",
        ),
      );
      setPan(
        response.payload.find(
          (e) => e.documentType === "TAKEOVER_PAN" || e.documentType === "PAN",
        ),
      );
      setVideo(
        response.payload.find((e) => e.documentType === "CUSTOMER_KYC_VIDEO"),
      );
    } else {
      setState((state) => ({
        ...state,
        loading: false,
        error: true,
        errorMessage: response.error || "Something went wrong",
      }));
    }
  }, []);

  useEffect(() => {
    getDetails();
    fetchBlackListCheckRequired();
  }, []);

  if (state.error) {
    return <div>{state.errorMessage}</div>;
  }

  if (state.loading || !state.payload) {
    return <Loading height={"30vh"} />;
  }

  const AadhaarBottomModal = () => (
    <ModalContent title={"Aadhar Card"} doc={[aadhar]} />
  );

  const PanBottomModal = () => <ModalContent title={"Pan Card"} doc={[pan]} />;

  return (
    <div>
      <Video name={"COSTUMER VIDEO KYC"} video={video} />

      <div style={{display: "flex", gap: "2rem"}}>
        <Aadhar
          setOpenAadhaarModal={setOpenAadhaarModal}
          aadharDetails={[aadhar]}
        />
        <PanCard setOpenPanModal={setOpenPanModal} panDetails={[pan]} />
      </div>

      {aadhar && (
        <DocView
          open={openAadhaarModal}
          docsList={[aadhar.fileUrl, aadhar.backFileUrl].filter((doc) => doc)}
          BottomComponent={AadhaarBottomModal}
          title="Aadhaar Details"
          onCloseHandler={() => setOpenAadhaarModal(false)}
        />
      )}

      {pan && (
        <DocView
          open={openPanModal}
          docsList={[pan.fileUrl, pan.backFileUrl].filter((doc) => doc)}
          title="Pan Details"
          BottomComponent={PanBottomModal}
          onCloseHandler={() => setOpenPanModal(false)}
        />
      )}
    </div>
  );
};

export default KYCDetails;
