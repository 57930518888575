import {CustomerProfileApiResources, CustomerProfileApiInterface} from "./types";
import {goldApiService} from "../../../../plugins/axios";

const CUSTOMER_PROFILE_ENDPOINTS: CustomerProfileApiResources = {
  GET_OVERDUE_PAYMENT_DETAIL: {
    URL: "/admin/payment-overdue/:loanId/user-detail",
    METHOD: "GET",
  },
  GET_REMINDER: {
    URL: "/admin/payment-overdue/:loanId/remark",
    METHOD: "GET",
  },
  SEND_REMINDER: {
    URL: "/admin/payment-overdue/:loanId/send-reminder",
    METHOD: "POST",
  },
  GET_CUSTOMER_PROFILE_LIST: {
    URL: "/admin/business/lender/customer-profile", // startTime, endTime, pageNo, pageSize
    METHOD: "GET",
  },
  GET_CUSTOMER_DETAILS: {
    URL: "/admin/business/lender/loan/:loanId/user-profile",
    METHOD: "GET",
  },
};

const customerProfileApi: CustomerProfileApiInterface = {
  getOverduePaymentDetails: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_OVERDUE_PAYMENT_DETAIL,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getReminder: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_REMINDER,
      options: {
        pathVars: {loanId},
      },
    });
  },
  sentReminder: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.SEND_REMINDER,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getCustomerProfileList: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CUSTOMER_PROFILE_LIST,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  getCustomerDetails: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CUSTOMER_DETAILS,
      options: {
        pathVars: {loanId},
      },
    });
  },
};

export {CUSTOMER_PROFILE_ENDPOINTS};

export default customerProfileApi;