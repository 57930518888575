import styled from "@emotion/styled";

interface ComponentContainerProps {
  active: boolean;
}

const ComponentContainer = styled.div<ComponentContainerProps>`
  border-radius: 4px;
  border: solid 1px #f3e5b6;
  border-top: ${({active}) => active ? "none" : "solid 1px #f3e5b6"};
  border-top-left-radius: ${({active}) => active ? "0px" : "4px"};
  border-top-right-radius: ${({active}) => active ? "0px" : "4px"};
  padding: 1rem;
`;

export default ComponentContainer;
