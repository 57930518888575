import React from "react";
import {Typography} from "@mui/material";
import BottomModalContainer from "../Shivalik/styles/BottomModalContainer";
import HeaderContainer from "../Shivalik/styles/HeaderContainer";
import Title from "../../styles/Title";
import AddressContainer from "../styles/AddressContainer";
import AddressWrapper from "../styles/AddressWrapper";
import TitleContainer from "../styles/TitleContainer";
import LenderSubtitle from "../styles/LenderSubtitle";
import GoldenDivider from "../styles/GoldenDivider";
import SubTitle from "../../styles/SubTitle";
import PiramalAddress from "../styles/PiramalAddress";
import AddressComponent from "./AddressComponent";

const BottomModal = ({
  mainDocumentType,
  permanentAddress,
  personalDetail,
  residentialAddress,
  documentAddress,
  docDetails,
  fullName,
}) => {
  return (
    <BottomModalContainer>
      <HeaderContainer>
        <AddressContainer>
          <Typography variant="h6" color="textSecondary">
            Personal Details
          </Typography>
          {permanentAddress && (
            <AddressWrapper>
              <TitleContainer>
                <LenderSubtitle>Name</LenderSubtitle>
                <Title>{fullName}</Title>
              </TitleContainer>
              <TitleContainer>
                <LenderSubtitle>DOB</LenderSubtitle>
                <Title>
                  {personalDetail?.dob ? personalDetail?.dob : "N/A"}
                </Title>
              </TitleContainer>

              <TitleContainer>
                <LenderSubtitle>Number</LenderSubtitle>
                <Title style={{fontWeight: "900", fontSize: "20px"}}>
                  {docDetails?.documentNumber || "N/A"}
                </Title>
              </TitleContainer>
              <TitleContainer>
                <LenderSubtitle>Remark</LenderSubtitle>
                <Title>{docDetails?.remark ? docDetails?.remark : "N/A"}</Title>
              </TitleContainer>
            </AddressWrapper>
          )}
        </AddressContainer>
        <AddressContainer borderLeft="solid 1px #ce9f29">
          <Typography variant="h6" color="textSecondary">
            Residential Address
          </Typography>
          {residentialAddress && (
            <AddressComponent address={residentialAddress} />
          )}
        </AddressContainer>
      </HeaderContainer>
      {documentAddress && mainDocumentType === docDetails.documentType && (
        <>
          <GoldenDivider />
          <PiramalAddress>
            <div>
              <SubTitle>
                Address retrieved from
                {mainDocumentType === "AADHAR"
                  ? " Aadhar via UIDAI"
                  : " Document"}
              </SubTitle>
              {documentAddress && (
                <AddressComponent address={documentAddress} />
              )}
            </div>
          </PiramalAddress>
        </>
      )}
    </BottomModalContainer>
  );
};

export default BottomModal;
