import React, {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {IGDialog, IGTextField, IGSelectMultiple} from "app/components";
import {Stack} from "@mui/material";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {IGLoading} from "app/components";

const ReasonToReject = ({
  crId,
  crJobId,
  open,
  rejectionSource,
  getMileStoneOfCr,
  getNextJobApi,
  crJobStatus,
  title,
  confirmText,
  source,
}) => {
  const dispatch = useAppDispatch();
  const rejectCrConfig = useAppSelector(activeLoansSelectors.getRejectCrConfig);
  const {rejectCrConfig: rejectCrConfigLoading, rejectCr: rejectCrLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const [remark, setRemark] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const {rejectionCategoryConfigList, rejectionSubCategoryConfigList} = rejectCrConfig ?? {};

  const onClose = () => {
    dispatch(
      toggleModals({
        type: "rejectCr",
        value: false,
      }),
    );
  };

  const handleSubcategoryChange = (categoryId, selectedValues) => {
    setSelectedSubcategories(prevState => ({
      ...prevState,
      [categoryId]: selectedValues,
    }));
  };

  const getSubcategoriesForCategory = categoryId => {
    return rejectionSubCategoryConfigList.filter(
      subCategory => subCategory.rejectionCategoryId === categoryId,
    );
  };

  const isConfirmDisabled = useMemo(() => {
    const hasIncompleteCategory = selectedCategories.some(categoryId => {
      const selectedSubcategory = selectedSubcategories[categoryId];
      return !selectedSubcategory || selectedSubcategory.length === 0;
    });

    return hasIncompleteCategory || !remark;
  }, [selectedCategories, selectedSubcategories, remark]);

  const onConfirm = () => {
    const categoryList = selectedCategories.map(categoryId => ({
      categoryId: categoryId,
      subCategoryIdList: selectedSubcategories[categoryId] || [],
    }));

    dispatch(
      activeLoansActions.rejectCr({
        crId: crId,
        crJobId: crJobId,
        crJobStatus: crJobStatus,
        source: rejectionSource,
        actionType: "REJECT",
        remark: remark,
        categoryList: categoryList,
        source: source,
      }),
    );

    getMileStoneOfCr();
    getNextJobApi();
  };

  useEffect(() => {
    dispatch(
      activeLoansActions.getRejectCrConfig({
        source: source,
        actionType: "REJECT",
      }),
    );
  }, [dispatch]);

  return (
    <IGDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title={title}
      maxWidth="md"
      confirmProps={{
        text: confirmText,
        disabled: isConfirmDisabled,
        loading: rejectCrConfigLoading || rejectCrLoading,
      }}
      onConfirm={onConfirm}
      subtitle="Please provide more details"
    >
      {rejectCrConfigLoading || rejectCrLoading ? (
        <IGLoading height={"10vh"} />
      ) : (
        <Stack spacing={2}>
          <IGSelectMultiple
            required
            name="rejectionCategory"
            label="What is the reason for rejecting"
            options={rejectionCategoryConfigList.map(l => ({
              value: l.id,
              text: l.displayValue,
            }))}
            value={selectedCategories || []}
            onChange={e => setSelectedCategories(e.target.value)}
            inputLabelOptions={{
              sx: {
                fontWeight: "bold",
              },
            }}
          />

          {selectedCategories.map(categoryId => {
            const categoryName = rejectionCategoryConfigList.find(item => item?.id === categoryId)
              ?.displayValue;
            const subcategories = getSubcategoriesForCategory(categoryId);
            return (
              <IGSelectMultiple
                required
                key={categoryId}
                name="rejectionSubCategory"
                label={`Rejection subcategory for ${categoryName}`}
                options={subcategories.map(l => ({
                  value: l.id,
                  text: l.displayValue,
                }))}
                value={selectedSubcategories[categoryId] || []}
                onChange={e => handleSubcategoryChange(categoryId, e.target.value)}
                chipOptions={{
                  variant: "outlined",
                }}
                inputLabelOptions={{
                  sx: {
                    fontWeight: "bold",
                  },
                }}
              />
            );
          })}

          <IGTextField
            required
            multiline
            rows="5"
            cols="37"
            label="Remarks"
            placeholder="Remarks"
            value={remark}
            onChange={event => setRemark(event.target.value)}
            inputProps={{
              maxLength: 1200,
            }}
          />
        </Stack>
      )}
    </IGDialog>
  );
};

export default ReasonToReject;
