import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  CREDIT_SESSION_STATUS: {
    URL: "/business/cr/:crId/cibil-session",
    METHOD: "GET",
  },
  TAKEOVER_CREDIT_SESSION_STATUS: {
    URL: "/business/cr/:crId/takeover/cibil-session",
    METHOD: "GET",
  },
};

export const getGoldLoanCreditSessionStatus = async ({crId}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.CREDIT_SESSION_STATUS,
    options: {
      pathVars: {
        crId,
      },
    },
  });
  return response;
};

export const getGoldLoanTakeoverCreditSessionStatus = async ({crId}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.TAKEOVER_CREDIT_SESSION_STATUS,
    options: {
      pathVars: {
        crId,
      },
    },
  });
  return response;
};
