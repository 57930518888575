export const loanTypeEnum = {
  GOLD_LOAN_BT: "GOLD_LOAN_BT",
  GOLD_LOAN_FRESH: "GOLD_LOAN_FRESH",
};
export const SEND_BACK_TO_SCHEDULING_REASON = {
  INSUFFICIENT_GOLD: "Insufficient Gold",
  CUSTOMER_DROPPED_LOAN_NOT_BOOKED: "Customer dropped loan not booked",
  INCOMPLETE_DOCUMENTS: "Incomplete Documents",
  CREDIT_REJECT: "Credit Reject",
  KYC_DONE_LOAN_NOT_BOOKED: "Kyc done loan not booked",
  TAKEOVER_PAYMENT_DONE_LOAN_NOT_BOOKED:
    "Takeover Payment done,loan not booked",
  SPURIOUS_GOLD: "Spurious gold",
  LENDER_REJECTED_INITIATE_RECOVERY: "Lender rejected initiate recovery",
};

export const loanKycDocumentTypeEnum = {
  DRIVING_LICENSE: "DRIVING_LICENSE",
  VOTER_ID: "VOTER_ID",
  AADHAR: "AADHAR",
  CUSTOMER_IMAGE: "CUSTOMER_IMAGE",
  PAN: "PAN",
  CUSTOMER_HOME_IMAGE: "CUSTOMER_HOME_IMAGE",
};

export const loanKycStatusEnum = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  AADHAAR_VERIFIED: "AADHAAR_VERIFIED",
  FAILED: "FAILED",
};

export const loanGatingStatusEnum = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  KYC_PENDING: "KYC_PENDING",
};

export const lenderNameEnum = {
  PIRAMAL: "PIRAMAL",
  SHIVALIK: "SHIVALIK",
  FINCARE: "FINACARE",
  LIQUILOANS: "LIQUILOANS",
  LIQUILOANS_NDX_P2P: "LIQUILOANS_NDX_P2P",
  SUNITA_FINANCE: "SUNITA_FINANCE",
};

export const loanType = {
  GOLD_LOAN: {
    key: "GOLD_LOAN",
    value: "Gold Loan",
  },
  PERSONAL_LOAN: {
    key: "PERSONAL_LOAN",
    value: "Personal Loan",
  },
};

export const newLoanTypeEnum = {
  GOLD_LOAN: "GOLD_LOAN",
  PERSONAL_LOAN: "PERSONAL_LOAN",
};

export const docFlowEnum = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const cmStatusLenderDocEnum = {
  CM_PROCESS_GL_LENDER_DOCUMENT_APPROVED:
    "CM_PROCESS_GL_LENDER_DOCUMENT_APPROVED",
  CM_PROCESS_PL_LENDER_DOCUMENT_APPROVED:
    "CM_PROCESS_PL_LENDER_DOCUMENT_APPROVED",
  CM_PROCESS_GL_LENDER_DOCUMENT_REJECTED:
    "CM_PROCESS_GL_LENDER_DOCUMENT_REJECTED",
  CM_PROCESS_PL_LENDER_DOCUMENT_REJECTED:
    "CM_PROCESS_PL_LENDER_DOCUMENT_REJECTED",
  FL_GL_LENDER_DOCUMENT_APPROVED: "FL_GL_LENDER_DOCUMENT_APPROVED",
};

export const cmStatusDisbursalEnum = {
  CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE:
    "CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE",
  CM_PROCESS_PERSONAL_LOAN_DISBURSAL_MAKER_DONE:
    "CM_PROCESS_PERSONAL_LOAN_DISBURSAL_MAKER_DONE",
};

export const docSendBackReasonEnum = {
  GL_LENDER_DOC_ISSUE: "GL_LENDER_DOC_ISSUE",
  PL_LENDER_DOC_ISSUE: "PL_LENDER_DOC_ISSUE",
};

export const TakeoverPaymentDocEnum = {
  RTGS_POST_PAYMENT_APPROVAL_SLIP: "RTGS_POST_PAYMENT_APPROVAL_SLIP",
};

export const SkipCifEnableOptions = [
  "LM_FACING_APP_ISSUE",
  "BIOMETRIC_DEVICE_NOT_WORKING",
  "NO_VALIDATION_DOCUMENT_FOUND",
  "PAN_MISMATCH",
  "AADHAAR_MISMATCH",
  "CIF_NOT_FOUND",
  "LENDER_OUTAGE",
  "REEBOK_LOAN",
  "OTHER",
];

export const CrRejectionSourceEnum = {
  TAKEOVER_KYC: "TAKEOVER_KYC",
  TAKEOVER_RTGS: "TAKEOVER_RTGS",
};
