import {
  CRAssignJobPayload,
  CRCreateJobPayload,
  GetCRDetailPayload,
  GetCRListPayload,
  GetNoteListPayload,
  GetOpenCrViewPayload,
  OpenCRApiInterface,
  OpenCRResources,
  UpdateCallingStatusPayload,
  UpdateMeetingTimePayload,
} from "./types";
import {goldApiService} from "app/infra/plugins/axios";
import {getValidTime} from "_metronic/utils/moment";
import {GetUnscheduledAgentReportPayload} from "../unscheduled/types";

const OpenCR_ENDPOINTS: OpenCRResources = {
  GET_CR_LIST: {
    URL: "/scheduling/customer-request",
    METHOD: "GET",
  },
  GET_CR_DETAIL: {
    URL: "scheduling/cr/:crId",
    METHOD: "GET",
  },
  GET_LEAD_SOURCE_DETAIL: {
    URL: "/admin/business/cr/:crId/lead-source",
    METHOD: "GET",
  },
  GET_ADDRESS_DETAILS: {
    URL: "/business/cr/:crId/address",
    METHOD: "GET",
  },
  GET_TAKEOVER_DETAILS: {
    URL: "/business/cr/:crId/takeover-detail",
    METHOD: "GET",
  },
  GET_PRIMARY_LM_JOB_DETAILS: {
    URL: "/admin/business/cr/:crId/primary-lm-job",
    METHOD: "GET",
  },
  GET_ELIGIBLE_JOBS: {
    URL: "/scheduling/customer-request/:crId/eligible-job-list",
    METHOD: "GET",
  },
  GET_MEETING_TIME: {
    URL: "/admin/business/cr/:crId/meeting-time",
    METHOD: "GET",
  },
  UPDATE_CONFIRMATION_STATUS: {
    URL: "/scheduling/call-status",
    METHOD: "PUT",
  },
  UPDATE_MEETING_TIME: {
    URL: "/admin/business/cr/:crId/meeting-time",
    METHOD: "POST",
  },
  ASSIGN_JOB_CR: {
    URL: "/scheduling/customer-request/:crId/assign",
    METHOD: "POST",
  },
  CREATE_JOB_CR: {
    URL: "/scheduling/customer-request/:crId/create",
    METHOD: "POST",
  },
  GET_NOTE_LIST: {
    URL: "/scheduling/customer-request/:crId/note-list",
    METHOD: "GET",
  },
  GET_OPENCR_MANUAL_ASSIGNMENT: {
    URL: "/scheduling/real-time/cr/manual-assignment",
    METHOD: "GET",
  },
  GET_CLUSTER_DETAIL: {
    URL: "/scheduling/real-time/cr/cluster-detail", // ? crId
    METHOD: "GET",
  },
  GET_AGENT_REPORT: {
    URL: "/scheduling/real-time/cr/report",
    METHOD: "POST",
  },
  ENABLE_CR_MANUAL_ASSIGNMENT_FLOW: {
    URL: "/scheduling/real-time/cr/manual-assignment",
    METHOD: "POST",
  },
};

const OpenCRApi: OpenCRApiInterface = {
  getOpenCrClusterDetail: async (payload: GetNoteListPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_CLUSTER_DETAIL,
      options: {
        queryParams: {
          crId: payload.crId,
        },
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {response, error: null};
  },

  getOpenCrAgentReport: async (payload: GetUnscheduledAgentReportPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_AGENT_REPORT,
      options: {
        data: {
          entityId: payload.entityId,
          selectedAgentRank: payload.selectedAgentRank,
          agentId: payload.agentId,
        },
      },
    });
    if (error) {
      return {error, response: []};
    }
    return {response: response, error: null};
  },

  getCRList: async (payload: GetCRListPayload) => {
    const queryParams: Record<string, any> = {
      cityId: payload.cityId,
      pageNo: payload.pageNo,
      pageSize: payload.pageSize,
      status: payload.status,
      name: payload.name,
      crId: payload.crId,
      maskedNumber: payload.maskedNumber,
      sendBackReason: payload.sendBackReason,
      crType: payload.crType,
      sortOrder: "ASCENDING",
      sortCriteria: "CUSTOMER_REQUEST_ID",
    };
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_CR_LIST,
      options: {
        queryParams: queryParams,
      },
    });

    if (error) {
      return {
        error,
        crList: null,
      };
    }
    return {
      error: null,
      crList: {...response},
    };
  },
  getOpenCrView: async (payload: GetOpenCrViewPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_OPENCR_MANUAL_ASSIGNMENT,
      options: {
        queryParams: {
          crId: payload.crId,
        },
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {response, error: null};
  },
  getCRDetail: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_CR_DETAIL,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });
    if (error) {
      return {
        error,
        crDetail: null,
      };
    }
    return {
      error: null,
      crDetail: response,
    };
  },
  getLeadSourceDetails: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_LEAD_SOURCE_DETAIL,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });

    if (error) {
      return {error, leadSourceDetail: null};
    }
    return {error: null, leadSourceDetail: response};
  },
  getAddressDetails: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_ADDRESS_DETAILS,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });

    if (error) {
      return {error, addresses: null};
    }
    return {error: null, addresses: response};
  },
  getTakeOverDetails: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_TAKEOVER_DETAILS,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });
    if (error) {
      return {error, takeOverDetails: null};
    }
    return {error: null, takeOverDetails: response};
  },
  getPrimaryLMJobDetails: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_PRIMARY_LM_JOB_DETAILS,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });

    if (error) {
      return {error, primaryLMJob: null};
    }
    return {error: null, primaryLMJob: response};
  },
  getEligibleJobs: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_ELIGIBLE_JOBS,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });

    if (error) {
      return {error, eligibleJobs: null};
    }
    return {error: null, eligibleJobs: response};
  },
  getMeetingTime: async (data: GetCRDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_MEETING_TIME,
      options: {
        pathVars: {
          crId: data.id,
        },
      },
    });
    if (error) {
      return {error, data: null};
    }
    return {
      error: null,
      data: {
        meetingDate: response.startTime
          ? getValidTime(response.startTime)
          : null,
        meetingStartTime: response.startTime,
        meetingEndTime: response.endTime,
      },
    };
  },
  updateCallingStatus: async (data: UpdateCallingStatusPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.UPDATE_CONFIRMATION_STATUS,
      options: {
        data: {
          crId: data.crId,
          status: data.status,
          remark: data.remark,
          note: data.note,
        },
      },
    });

    if (error) {
      return {error, data: null};
    }
    return {error: null, data: response};
  },
  updateMeetingTime: async (data: UpdateMeetingTimePayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.UPDATE_MEETING_TIME,
      options: {
        pathVars: {
          crId: data.crId,
        },
        data: {
          startTime: data.startTime,
          endTime: data.endTime,
        },
      },
    });
    if (error) {
      return {error, data: null};
    }
    return {
      error: null,
      data: {
        ...response,
        meetingDate: getValidTime(data.startTime),
        meetingStartTime: data.startTime,
        meetingEndTime: data.endTime,
      },
    };
  },
  assignCRJob: async (data: CRAssignJobPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.ASSIGN_JOB_CR,
      options: {
        pathVars: {
          crId: data.crId,
        },
        data: {
          ...data,
        },
      },
    });
    if (error) {
      return {error, data: null};
    }
    return {error: null, data: response};
  },
  createCRJob: async (data: CRCreateJobPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.CREATE_JOB_CR,
      options: {
        pathVars: {
          crId: data.crId,
        },
        data: {
          ...data,
        },
      },
    });
    if (error) {
      return {error, data: null};
    }
    return {error: null, data: response};
  },
  getNoteList: async (payload: GetNoteListPayload) => {
    const {response, error} = await goldApiService({
      resource: OpenCR_ENDPOINTS.GET_NOTE_LIST,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
    if (error) return {noteList: [], error};
    return {noteList: response, error: null};
  },

  enableOpenCrManualAssignmentFlow: async ({crId}) => {
    return await goldApiService({
      resource: OpenCR_ENDPOINTS.ENABLE_CR_MANUAL_ASSIGNMENT_FLOW,
      options: {
        queryParams: {
          crId,
        },
      },
    });
  },
};

export default OpenCRApi;

export {OpenCR_ENDPOINTS};
