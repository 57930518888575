import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";

import occupationDetailsActions from "./occupationDetails.actions";
import {occupationDetailsActionTypes} from "./occupationDetails.actions";
import {
  GetOccupationDetailsPayload,
  GetWorkSectorDetailsPayload,
  OccupationDetailsResponse,
  PostLoanRequirementReasonPayload,
} from "./occupationDetails.types";

import {snackBarActions} from "app/store/snackbar";
import {OccupationAndOtherDetailsForm} from "app/models/loanRenewalRebook/occupationDetails.model";
import loanRenewRebookActions from "../loanRenewalRebook.actions";

const {
  getOccupationDetailsFailure,
  getOccupationDetailsSuccess,
  updateOccupationDetailsSuccess,
  updateOccupationDetailsFailure,
  getWorkSectorDetailsSuccess,
  getWorkSectorDetailsFailure,
  postLoanPurposeReason,
  postLoanPurposeReasonSuccess,
  postLoanPurposeReasonFailure,
} = occupationDetailsActions;

function* generalErrorFlow(action: PayloadAction<OccupationDetailsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getOccupationDetailsFlow(
  action: PayloadAction<GetOccupationDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.occupationDetails.getOccupationDetails,
    action.payload,
  );
  if (error) {
    yield put(getOccupationDetailsFailure({response: null, error}));
  } else {
    yield put(getOccupationDetailsSuccess({response, error: null}));
  }
}

function* postLoanPurposeReasonFlow(
  action: PayloadAction<PostLoanRequirementReasonPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.occupationDetails.postLoanRequirementReason,
    action.payload,
  );
  if (error) {
    yield put(postLoanPurposeReasonFailure({response: null, error}));
  } else {
    yield put(postLoanPurposeReasonSuccess({response, error: null}));
    yield put(
      loanRenewRebookActions.updateLoanRenewRebookMilestone({
        crId: action.payload.crId,
        crJobId: action.payload.crJobId,
        crJobStatus: "CM_RR_EMPLOYMENT_DETAIL_VERIFIED",
      }),
    );
  }
}
function* updateOccupationDetailsFlow(
  action: PayloadAction<{
    crId: number;
    crJobId: number;
    payload: OccupationAndOtherDetailsForm;
    loanRequirementReason: string;
  }>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.occupationDetails.updateOccupationDetails,
    action.payload.crId,
    action.payload.payload,
  );
  if (error) {
    yield put(updateOccupationDetailsFailure({response: null, error}));
  } else {
    yield put(updateOccupationDetailsSuccess({response, error: null}));
    yield put(
      postLoanPurposeReason({
        crId: action.payload.crId,
        crJobId: action.payload.crJobId,
        loanRequirementReason: action.payload.loanRequirementReason,
        loanRequirementReasonDisplayValue: "",
      }),
    );
  }
}

function* getWorkSectorDetailsFlow(
  action: PayloadAction<GetWorkSectorDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.occupationDetails.getWorkSectorDetails,
    action.payload,
  );
  if (error) {
    yield put(getWorkSectorDetailsFailure({response: null, error}));
  } else {
    yield put(getWorkSectorDetailsSuccess({response, error: null}));
  }
}

export default function* occupationDetailsSaga() {
  yield takeLatest(
    occupationDetailsActionTypes.GetOccupationDetails,
    getOccupationDetailsFlow,
  );
  yield takeLatest(
    occupationDetailsActionTypes.GetOccupationDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    occupationDetailsActionTypes.UpdateOccupationDetails,
    updateOccupationDetailsFlow,
  );
  yield takeLatest(
    occupationDetailsActionTypes.UpdateOccupationDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    occupationDetailsActionTypes.GetWorkSectorDetails,
    getWorkSectorDetailsFlow,
  );
  yield takeLatest(
    occupationDetailsActionTypes.GetWorkSectorDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    occupationDetailsActionTypes.PostLoanPurposeReason,
    postLoanPurposeReasonFlow,
  );
  yield takeLatest(
    occupationDetailsActionTypes.PostLoanPurposeReasonFailure,
    generalErrorFlow,
  );
}
