import styled from "@emotion/styled";
import {Button, Grid} from "@mui/material";

// ------> CSS Props Interfaces <------
interface StepWrapperProps {
  highLight: boolean;
}

interface StyledThumbnailProps {
  thumbHeight?: string | number
  thumbWidth?: string | number
}

// ------> CSS <------


const StyledThumbnail = styled("img")<StyledThumbnailProps>`
  height: ${({thumbHeight}) => thumbHeight ? thumbHeight : "5rem"};
  width: ${({thumbWidth}) => thumbWidth ? thumbWidth : "5rem"};
  border-radius: 11px;
  margin-right: 0.25rem;
`;

const NoteContainer = styled(Grid)`
  background: white;
  min-height: 60%;
  max-height: 90%;
  overflow-x: hidden;
  width: 100%;
  overflow-y: auto;
  position: relative;
  z-index: inherit;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StepWrapper = styled(Button)<StepWrapperProps>`
  cusror: ${({highLight}) => highLight ? "disabled" : "pointer"};
  color: ${({highLight}) => highLight && "grey"};
`;


export {
  NoteContainer,
  StepsContainer,
  StepWrapper,
  StyledThumbnail,
};
