import styled from "@emotion/styled";

const SuccessMessage = styled.div`
  font-size: 1.25rem;
  color: green;
  font-weight: 500;
  margin-bottom: 2.5rem;
`;

export default SuccessMessage;
