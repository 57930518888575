import React, {useState} from "react";
import {Grid, Typography} from "@mui/material";
import {SkipNext, SkipPrevious} from "@mui/icons-material";
import ImageComponent from "app/components/Image";
import IGModal from "app/components/Modal/IGModal";
import {StepWrapper, StepsContainer, NoteContainer} from "./styles";

const DocView = ({
  open,
  docsList,
  title = "AADHAAR DETAILS",
  BottomComponent = () => <></>,
  onCloseHandler,
  prevHandler,
  nextHandler,
  docCount,
  docTitle = null,
  secondDocsList = null,
  secondDocTitle = null,
}) => {
  const [count, setCount] = useState(0);
  let {innerHeight} = window;
  innerHeight = innerHeight < 580 ? innerHeight : 580;

  const onClickHandlePrev = () => {
    if (prevHandler) {
      prevHandler();
      return;
    }

    if (count > 0) {
      setCount((count) => count - 1);
    }
  };
  const onClickHandleNext = () => {
    if (nextHandler) {
      nextHandler();
      return;
    }

    if (count < docsList.length - 1) {
      setCount((count) => count + 1);
    }
  };

  return (
    <>
      <IGModal
        open={open}
        width="50%"
        onClose={onCloseHandler}
        title={title}
        subtitle={`Photos Uploaded (${docsList?.length})`}
      >
        <NoteContainer container>
          <Grid item xs={secondDocsList ? 6 : 12}>
            {docsList.length === 0 ? (
              <Typography
                textAlign="center"
                variant="h6"
                fontWeight="bold"
                color="gray"
                mt={16}
              >
                Document Verified!
              </Typography>
            ) : (
              <ImageComponent
                src={docsList[docCount || count]}
                height={innerHeight / 2.5}
                width={innerHeight / 2.5}
                imageTitle={docTitle}
                rotate
                zoom
              />
            )}

            {docsList.length > 1 ? (
              <StepsContainer>
                <StepWrapper
                  highLight={(docCount || count) === 0}
                  onClick={onClickHandlePrev}
                  startIcon={<SkipPrevious />}
                >
                  Prev
                </StepWrapper>
                <StepWrapper
                  highLight={docsList.length - 1 === (docCount || count)}
                  onClick={onClickHandleNext}
                  endIcon={<SkipNext />}
                >
                  Next
                </StepWrapper>
              </StepsContainer>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6}>
            {secondDocsList && (
              <ImageComponent
                src={secondDocsList[0]}
                height={innerHeight / 2.5}
                width={innerHeight / 2.5}
                imageTitle={secondDocTitle}
                rotate
                zoom
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <BottomComponent />
          </Grid>
        </NoteContainer>
      </IGModal>
    </>
  );
};

export default DocView;
