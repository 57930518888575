import {PayloadAction} from "@reduxjs/toolkit";
import {put, getContext, call, takeLeading} from "redux-saga/effects";
import {snackBarActions} from "../snackbar";
import {
  FetchUserTierConfigProps,
  UpdateUserTierConfigProps,
} from "app/infra/services/api/userTierConfig/types";
import {GoldApi} from "app/infra/services/api";
import {UserTierConfigState} from "./reducer";
import {userTierConfigAction, UserTierConfigTypes} from "./actions";

// >>>>>>>>> User Tier Config Actions <<<<<<<<
const {
  getUserTierConfig,
  getUserTierConfigSuccess,
  getUserTierConfigError,
  updateUserTierConfigSuccess,
  updateUserTierConfigError,
} = userTierConfigAction;

// >>>>>>>> User Tier Config Flows <<<<<<<<<
function* fetchUserTierConfigFlow(
  action: PayloadAction<FetchUserTierConfigProps>,
) {
  const api: GoldApi = yield getContext("api");

  const userTierConfigState: UserTierConfigState = yield call(
    api.userTierConfig.fetchUserTierConfig,
    action.payload,
  );

  if (userTierConfigState.error) {
    yield put(getUserTierConfigError(userTierConfigState));
    yield put(
      snackBarActions.open({
        message: "Fetching User Tier Config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getUserTierConfigSuccess(userTierConfigState));
  }
}

function* updateUserTierConfigFlow(
  action: PayloadAction<{
    id: number;
    updatePayload: UpdateUserTierConfigProps;
    fetchPayload: FetchUserTierConfigProps;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const userTierConfigState: UserTierConfigState = yield call(
    api.userTierConfig.updateUserTierConfig,
    action.payload.id,
    action.payload.updatePayload,
  );

  if (userTierConfigState.error) {
    yield put(updateUserTierConfigError(userTierConfigState));
    yield put(
      snackBarActions.open({
        message: "Updating User Tier Config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(updateUserTierConfigSuccess(userTierConfigState));
    yield put(getUserTierConfig());

    yield put(
      snackBarActions.open({
        message: "User Tier Config Updated Sucessfully",
        variant: "success",
      }),
    );
  }
}

export default function* userTierConfigSaga() {
  yield takeLeading(
    UserTierConfigTypes.GetUserTierConfig,
    fetchUserTierConfigFlow,
  );

  yield takeLeading(
    UserTierConfigTypes.UpdateUserTierConfig,
    updateUserTierConfigFlow,
  );
}
