import {createAction} from "@reduxjs/toolkit";

const dialerActionTypes = {
  GetEventDetails: "[Dialer] Get Event Details",
  GetAgentSchedule: "[Dialer] Get Agent Schedule",
};

const dialerActions = {
  getEventDetails: createAction<{
    request_id: string;
  }>(dialerActionTypes.GetEventDetails),
  getAgentSchedule: createAction<{
    pageNo: number;
    pageSize: number;
  }>(dialerActionTypes.GetAgentSchedule),
};

export default dialerActions;
export {dialerActionTypes};
