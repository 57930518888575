import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface FeatureFlagState {
  featureFlags: Record<
    string,
    {
      [key: string]: boolean;
    }
  >;
  loading: boolean;
  error: string;
}

const initialState: FeatureFlagState = {
  featureFlags: {},
  loading: false,
  error: "",
};

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFeatureFlag: (
      state,
      action: PayloadAction<{
        moduleEnum: string;
        moduleFeatureEnum: string;
        value: boolean;
      }>,
    ) => {
      if (!state.featureFlags[action.payload.moduleEnum]) {
        state.featureFlags[action.payload.moduleEnum] = {};
      }
      state.featureFlags[action.payload.moduleEnum][
        action.payload.moduleFeatureEnum
      ] = action.payload.value;
    },
    setFeatureFlagFromList: (
      state,
      action: PayloadAction<
        {
          moduleEnum: string;
          moduleFeatureEnum: string;
          isFeatureActive: boolean;
        }[]
      >,
    ) => {
      action.payload.forEach((flag) => {
        if (!state.featureFlags[flag.moduleEnum]) {
          state.featureFlags[flag.moduleEnum] = {};
        }
        state.featureFlags[flag.moduleEnum][flag.moduleFeatureEnum] =
          flag.isFeatureActive;
      });
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetFeatureFlags: (state) => {
      state.featureFlags = {};
    },
  },
});

export const {
  setFeatureFlag,
  setFeatureFlagFromList,
  resetFeatureFlags,
  setLoading: setFeatureFlagsLoading,
  setError: setFeatureFlagsError,
} = featureFlagsSlice.actions;
export default featureFlagsSlice.reducer;
