import styled from "@emotion/styled";

const CheckBoxContainer = styled.div`
  padding: 1rem;

  display: flex;
  align-items: center;
`;

export default CheckBoxContainer;
