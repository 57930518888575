import React, {useCallback, useMemo} from "react";
import {Outlet, Route} from "react-router-dom";
import {authSelectors} from "app/store/auth";
import {useAppSelector} from "app/store/hooks";
import {FeatureData, GoldReleaseJobsView, LenderClosureJobsView} from "app/AppLayout/getAllComponents";
import ServiceDeskRoutes from "./ServiceDesk";
import CustomerProfileRoutes from "./CustomerProfile";
import UserRoutes from "./Users";
import SchedulingRoutes from "./Scheduling";
import ActiveLoanRoutes from "./ActiveLoans";
import dynamicAnalyticsRoutes from "./DynamicAnalyticsRoutes";
import {MenuComponent, Product, SubMenuComponent} from "../models/product";
import LenderCustomerProfilePage from "app/pages/home/LenderPortal/CustomerProfiles/ProfilePage";
import {layoutSelector} from "app/store/layout";
import NPACases from "app/pages/home/LenderPortal/NPACases";
import {clearAuthLocalStorage} from "app/infra/services/api/auth/adaptor";

// - This component maps the user accesible Products/features
// to the components
//
// - User Accessible products are defined in user state.
// - Products have an availableFeature list.
// - Each feature in availableFeatures has a whitelistedRoles list.
// - The whitelistedRoles list is a list of roles that are allowed to access the feature.
// - We use this list to map a feature to a component.
const DashboardRoutes = () => {
  const user = useAppSelector(authSelectors.getUser);
  const dynamicRoutes = useAppSelector(layoutSelector.getDynamicRoutes);

  const getDefaultFeature = useCallback(
    (product: Product) => {
      if (!user) {
        return;
      }

      const defaultFeature = user?.userRoleList?.some((role) => {
        return product.defaultFeature?.whitelistedRoles.includes(role);
      })
        ? product.defaultFeature
        : product.availableFeatures.find((f) =>
            user?.userRoleList?.some((role) => {
              return f.whitelistedRoles.includes(role);
            }),
          );

      const feature = FeatureData.find((feature) => {
        return feature.path === defaultFeature?.path;
      });

      if (feature && feature.isMultiMenu) {
        const whitelistedSubModules = defaultFeature?.subFeatures
          ?.filter((sm) =>
            user?.userRoleList?.some((role) => {
              return sm.whitelistedRoles.includes(role);
            }),
          )
          .map((sm) => sm.path);

        return {
          ...feature,
          subMenu: feature.subMenu.filter((subFeature) =>
            whitelistedSubModules?.includes(subFeature.path),
          ),
        };
      }

      return feature;
    },
    [user],
  );

  const getAccessibleFeatures = useCallback(
    (product: Product) => {
      if (!user) {
        return [];
      }

      const whitelistedModules = product.availableFeatures.filter((f) =>
        user?.userRoleList?.some((role) => {
          return f.whitelistedRoles.includes(role);
        }),
      );
      const availableFeaturePaths = whitelistedModules.map(
        (feature) => feature.path,
      );
      let accessibleComponents = FeatureData.filter((feature) =>
        availableFeaturePaths.includes(feature.path),
      );

      accessibleComponents = accessibleComponents.map((feature) => {
        const featureModule = whitelistedModules.find(
          (f) => f.path === feature.path,
        );

        if ("dynamicSubMenu" in feature && feature.dynamicSubMenu) {
          const subMenu = generateSubRoutes(feature);
          const whitelistedSubModules = featureModule?.subFeatures
            ?.filter((sm) =>
              user?.userRoleList?.some((role) => {
                return sm.whitelistedRoles.includes(role);
              }),
            )
            .map((sm) => sm.path);
          const staticSubmenu = feature.subMenu?.filter((subFeature) =>
            whitelistedSubModules?.includes(subFeature.path),
          ) || [];
          return {
            ...feature,
            isMultiMenu: subMenu.length > 0 || staticSubmenu.length > 0,
            subMenu: [
              ...staticSubmenu,
              ...subMenu,
            ],
          };
        }

        if (feature.isMultiMenu) {
          const whitelistedSubModules = featureModule?.subFeatures
            ?.filter((sm) =>
              user?.userRoleList?.some((role) => {
                return sm.whitelistedRoles.includes(role);
              }),
            )
            .map((sm) => sm.path);

          return {
            ...feature,
            subMenu: feature.subMenu.filter((subFeature) =>
              whitelistedSubModules?.includes(subFeature.path),
            ),
          };
        }

        return feature;
      });

      return accessibleComponents;
    },
    [user, dynamicRoutes],
  );

  const generateSubRoutes = (feature: MenuComponent): SubMenuComponent[] => {
    if (
      dynamicRoutes &&
      feature.generateSubMenu &&
      feature.id &&
      feature.id in dynamicRoutes &&
      dynamicRoutes[feature.id].length > 0
    ) {
      const subRoutes = dynamicRoutes[feature.id];
      return feature.generateSubMenu(subRoutes) || [];
    }
    return [];
  };

  const dashboardRoutes = useMemo(() => {
    if (!user) {
      return;
    }

    if (!user.products || user.products.length === 0) {
      clearAuthLocalStorage();
      return;
    }

    return user.products.map((product) => {
      const defaultFeature = getDefaultFeature(product as Product);
      const accessibleFeatures = getAccessibleFeatures(product as Product);

      return (
        <Route key={product?.path} path={product?.path} element={<Outlet />}>
          <Route
            index
            element={
              defaultFeature?.isMultiMenu
                ? defaultFeature?.subMenu[0]?.component?.()
                : defaultFeature?.component?.()
            }
          />
          {accessibleFeatures.map((feature) => {
            switch (feature.path) {
              case "service-desk":
                return ServiceDeskRoutes(feature);
              case "active-loans":
                return ActiveLoanRoutes();
              case "customer-profiles":
                return CustomerProfileRoutes();
              case "users":
                return UserRoutes();
              case "scheduling":
                return SchedulingRoutes(feature);
              case "analytics":
                return dynamicAnalyticsRoutes(feature);
              case "customer-analytics":
                return dynamicAnalyticsRoutes(feature);
              default:
                return (
                  <Route
                    key={feature.path}
                    path={feature.path}
                    element={feature.component?.()}
                  >
                    {feature.isMultiMenu && (
                      <Route
                        index
                        element={feature.subMenu[0]?.component?.()}
                      />
                    )}
                    {feature.isMultiMenu &&
                      feature.subMenu.map((subMenu) => {
                        return (
                          <Route
                            key={subMenu.path}
                            path={subMenu.path}
                            element={subMenu.component?.()}
                          />
                        );
                      })}
                  </Route>
                );
            }
          })}
        </Route>
      );
    });
  }, [user, getDefaultFeature, getAccessibleFeatures]);

  const indexRoute = useMemo(() => {
    if (!user) {
      return;
    }

    if (!user.products || user.products.length === 0) {
      clearAuthLocalStorage();
      return;
    }

    const defaultProduct = user.products.find((product) =>
      product?.availableFeatures.some((feature) =>
        user?.userRoleList?.some((role) => {
          return feature.whitelistedRoles.includes(role);
        }),
      ),
    );

    if (!defaultProduct) {
      return;
    }

    const defaultFeature = getAccessibleFeatures(defaultProduct)[0];

    return (
      <Route
        index
        element={
          defaultFeature.isMultiMenu
            ? defaultFeature.subMenu[0]?.component?.()
            : defaultFeature?.component?.()
        }
      />
    );
  }, [user, getAccessibleFeatures]);

  if (!user || !user.products || user.products.length === 0) {
    clearAuthLocalStorage();
    return <></>;
  }

  return (
    <>
      {indexRoute}
      {dashboardRoutes}
      <Route path="/lender/customer-details/customer-profile/:userId/:loanId" element={<LenderCustomerProfilePage />} />
      <Route path="/lender/customer-details/:userId/:loanId" element={<LenderCustomerProfilePage />} />
      <Route path="/lender/loan-management/closure/:jobId" element={<LenderClosureJobsView />} />
      <Route path="/lender/loan-management/gold-handover/:jobId" element={<GoldReleaseJobsView />} />
      <Route path="/lender/loan-management/npa-cases" element={<NPACases />} />
    </>
  );
};

export default DashboardRoutes;
