import {LenderClosureJob} from "app/models/lenderClosureJob.model";
import {RootState} from "../store";

const lenderClosureJobSelectors = {
  getClosureJobList: (state: RootState) => {
    const sortedList: LenderClosureJob[] = [];
    state.lenderClosureJob.paginationOrder.forEach((id: number) => {
      sortedList.push(state.lenderClosureJob.closureJobsById[id]);
    },
    );
    return sortedList;
  },
  getLmUserList: (state: RootState) =>
    Object.values(state.lenderClosureJob.agentsById),
  getClosureJobById: (state: RootState, id: number) =>
    state.lenderClosureJob.closureJobsById[id],
  getLoading: (state: RootState) => state.lenderClosureJob.loading,
  getErrors: (state: RootState) => state.lenderClosureJob.errors,
  getFilters: (state: RootState) => state.lenderClosureJob.filters,
  getOpenModals: (state: RootState) => state.lenderClosureJob.openModals,
  getSelectedRowId: (state: RootState) => state.lenderClosureJob.selectedRowId,
  getLoanDetails: (state: RootState) =>
    state.lenderClosureJob.requestLoanDetails,
  getPaymentDetails: (state: RootState) =>
    state.lenderClosureJob.requestPaymentDetails,
  getOtpId: (state: RootState) => state.lenderClosureJob.otpId,
  getReload: (state: RootState) => state.lenderClosureJob.reload,
  getClosureJobDetails: (state: RootState) =>
    state.lenderClosureJob.closureJobDetails,
  getHandoverJobDetails: (state: RootState) =>
    state.lenderClosureJob.handoverJobDetails,
  getPaginationOrder: (state: RootState) =>
    state.lenderClosureJob.paginationOrder,
  getCities: (state: RootState) => state.lenderClosureJob.cities,
  getPagination: (state: RootState) => state.lenderClosureJob.pagination,
};

export default lenderClosureJobSelectors;