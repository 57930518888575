import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const addressAddress = async ({
  accessToken,
  crId,
  addressCoordinates,
  addressLine1,
  addressLine2,
  addressLine3,
  addressType,
  city,
  crJobId,
  landmark,
  landmarkCoordinates,
  locality,
  pincode,
  state,
  ownershipType,
  yearsInResidence,
}) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/business/cr/${crId}/address`,
      {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "application-json",
        },
        addressCoordinates,
        addressLine1,
        addressLine2,
        addressLine3,
        addressType,
        city,
        crId,
        crJobId,
        landmark,
        landmarkCoordinates,
        locality,
        pincode,
        state,
        ownershipType,
        yearsInResidence,
      },
    );

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default addressAddress;
