import {createAction} from "@reduxjs/toolkit";
import {
  RequestIdPayload,
  ServiceDeskResponse,
  SaveCustomerReqDetailsPayload,
  IDsPayload,
  LoanIdPayload,
  UpdateShareStatusPayload,
  SaveCMOfferDetailsPayload,
  UpdateOrnamentPayload,
  GetVersionHistoryPayload,
  GetLoanRenewalClosureDetailPayload,
  CloseLoanRenewalPayload,
  LoanTypePayload,
  LoanRenewalStatusResponse,
  UpdateRoiPayload,
  UpdateCMAdjustmentRenewalDetailsPayload,
  AgriOptionPayload,
} from "app/infra/services/api/serviceDesk/renewRebook/types";
import {setPaginationFor} from "app/store/factoryReducers";
import {GenericResponse} from "app/typings/api/goldApi.types";
import {CMAdjustmentRenewalDetail} from "./types";

const renewRebookActionTypes = {
  SaveCustomerReqDetails: "[RenewRebook] Save Customer Requirement Details",
  SaveCustomerReqDetailsSuccess:
    "[RenewRebook] Save Customer Requirement Details Success",
  SaveCustomerReqDetailsFailure:
    "[RenewRebook] Save Customer Requirement Details Failure",
  GetOurOffer: "[RenewRebook] Get Our Offer",
  GetOurOfferSuccess: "[RenewRebook] Get Our Offer Success",
  GetOurOfferFailure: "[RenewRebook] Get Our Offer Failure",
  GetVersionHistory: "[RenewRebook] Get Version History",
  GetVersionHistorySuccess: "[RenewRebook] Get Version History Success",
  GetVersionHistoryFailure: "[RenewRebook] Get Version History Failure",
  UpdateShareStatus: "[RenewRebook] Update Share Status",
  UpdateShareStatusSuccess: "[RenewRebook] Update Share Status Success",
  UpdateShareStatusFailure: "[RenewRebook] Update Share Status Failure",
  GetNetPayable: "[RenewRebook] Get Net Payable",
  GetNetPayableSuccess: "[RenewRebook] Get Net Payable Success",
  GetNetPayableFailure: "[RenewRebook] Get Net Payable Failure",
  GetOrnamentDetails: "[RenewRebook] Get Ornament Details",
  GetOrnamentDetailsSuccess: "[RenewRebook] Get Ornament Details Success",
  GetOrnamentDetailsFailure: "[RenewRebook] Get Ornament Details Failure",
  UpdateOrnamentDetails: "[RenewRebook] Update Ornament Details",
  UpdateOrnamentDetailsSuccess: "[RenewRebook] Update Ornament Details Success",
  UpdateOrnamentDetailsFailure: "[RenewRebook] Update Ornament Details Failure",
  GetCustomerFinancials: "[RenewRebook] Get Customer Financials",
  GetCustomerFinancialsSuccess: "[RenewRebook] Get Customer Financials Success",
  GetCustomerFinancialsFailure: "[RenewRebook] Get Customer Financials Failure",
  GetCustomerRequirements: "[RenewRebook] Get Customer Requirements",
  GetCustomerRequirementsSuccess:
    "[RenewRebook] Get Customer Requirements Success",
  GetCustomerRequirementsFailure:
    "[RenewRebook] Get Customer Requirements Failure",
  GetRepaymentHistory: "[RenewRebook] Get Repayment History",
  GetRepaymentHistorySuccess: "[RenewRebook] Get Repayment History Success",
  GetRepaymentHistoryFailure: "[RenewRebook] Get Repayment History Failure",
  GetCMOfferDetails: "[RenewRebook] Get CM Offer Details",
  GetCMOfferDetailsSuccess: "[RenewRebook] Get CM Offer Details Success",
  GetCMOfferDetailsFailure: "[RenewRebook] Get CM Offer Details Failure",
  SaveCMOfferDetails: "[RenewRebook] Save CM Offer Details",
  SaveCMOfferDetailsSuccess: "[RenewRebook] Save CM Offer Details Success",
  SaveCMOfferDetailsFailure: "[RenewRebook] Save CM Offer Details Failure",
  GetRenewRebookConfig: "[RenewRebook] Get Renew Rebook Config",
  GetRenewRebookConfigSuccess: "[RenewRebook] Get Renew Rebook Config Success",
  GetRenewRebookConfigFailure: "[RenewRebook] Get Renew Rebook Config Failure",
  GetCMConfig: "[RenewRebook] Get CM Config",
  GetCMConfigSuccess: "[RenewRebook] Get CM Config Success",
  GetCMConfigFailure: "[RenewRebook] Get CM Config Failure",
  GetPaymentDetails: "[RenewRebook] Get Payment Details",
  GetPaymentDetailsSuccess: "[RenewRebook] Get Payment Details Success",
  GetPaymentDetailsFailure: "[RenewRebook] Get Payment Details Failure",
  GetLoanRenewalClosureDetail: "[RenewRebook] Get Loan Renewal Closure Detail",
  GetLoanRenewalClosureDetailSuccess:
    "[RenewRebook] Get Loan Renewal Closure Detail Success",
  GetLoanRenewalClosureDetailFailure:
    "[RenewRebook] Get Loan Renewal Closure Detail Failure",
  CloseLoanRenewal: "[RenewRebook] Close Loan Renewal",
  CloseLoanRenewalSuccess: "[RenewRebook] Close Loan Renewal Success",
  CloseLoanRenewalFailure: "[RenewRebook] Close Loan Renewal Failure",
  GetLoanRenewalMappedPayments:
    "[RenewRebook] Get Loan Renewal Mapped Payments",
  GetLoanRenewalMappedPaymentsSuccess:
    "[RenewRebook] Get Loan Renewal Mapped Payments Success",
  GetLoanRenewalMappedPaymentsFailure:
    "[RenewRebook] Get Loan Renewal Mapped Payments Failure",
  GetLoanRenewalStatus: "[RenewRebook] Get Loan Renewal Status",
  GetLoanRenewalStatusSuccess: "[RenewRebook] Get Loan Renewal Status Success",
  GetLoanRenewalStatusFailure: "[RenewRebook] Get Loan Renewal Status Failure",
  UpdateRoi: "[RenewRebook] Update Roi",
  UpdateRoiSuccess: "[RenewRebook] Update Roi Success",
  UpdateRoiFailure: "[RenewRebook] Update Roi Failure",
  GetUserAcceptedOffer: "[RenewRebook] Get User Accepted Offer",
  GetUserAcceptedOfferSuccess: "[RenewRebook] Get User Accepted Offer Success",
  GetUserAcceptedOfferFailure: "[RenewRebook] Get User Accepted Offer Failure",
  GetGoldRate: "[RenewRebook] Get Gold Rate",
  GetGoldRateSuccess: "[RenewRebook] Get Gold Rate Success",
  GetGoldRateFailure: "[RenewRebook] Get Gold Rate Failure",
  GetCMAdjustmentRenewalDetails:
    "[RenewRebook] Get CM Adjustment Renewal Details",
  GetCMAdjustmentRenewalDetailsSuccess:
    "[RenewRebook] Get CM Adjustment Renewal Details Success",
  GetCMAdjustmentRenewalDetailsFailure:
    "[RenewRebook] Get CM Adjustment Renewal Details Failure",
  UpdateCMAdjustmentRenewalDetails:
    "[RenewRebook] Update CM Adjustment Renewal Details",
  UpdateCMAdjustmentRenewalDetailsSuccess:
    "[RenewRebook] Update CM Adjustment Renewal Details Success",
  UpdateCMAdjustmentRenewalDetailsFailure:
    "[RenewRebook] Update CM Adjustment Renewal Details Failure",
};

const renewRebookActions = {
  saveCustomerReqDetails: createAction<SaveCustomerReqDetailsPayload>(
    renewRebookActionTypes.SaveCustomerReqDetails,
  ),
  saveCustomerReqDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.SaveCustomerReqDetailsSuccess,
  ),
  saveCustomerReqDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.SaveCustomerReqDetailsFailure,
  ),
  getOurOffer: createAction<IDsPayload>(renewRebookActionTypes.GetOurOffer),
  getOurOfferSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetOurOfferSuccess,
  ),
  getOurOfferFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetOurOfferFailure,
  ),
  getVersionHistory: createAction<GetVersionHistoryPayload>(
    renewRebookActionTypes.GetVersionHistory,
  ),
  getVersionHistorySuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetVersionHistorySuccess,
  ),
  getVersionHistoryFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetVersionHistoryFailure,
  ),
  updateShareStatus: createAction<UpdateShareStatusPayload>(
    renewRebookActionTypes.UpdateShareStatus,
  ),
  updateShareStatusSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateShareStatusSuccess,
  ),
  updateShareStatusFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateShareStatusFailure,
  ),
  getNetPayable: createAction<IDsPayload>(renewRebookActionTypes.GetNetPayable),
  getNetPayableSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetNetPayableSuccess,
  ),
  getNetPayableFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetNetPayableFailure,
  ),
  getOrnamentsDetails: createAction<IDsPayload & AgriOptionPayload>(
    renewRebookActionTypes.GetOrnamentDetails,
  ),
  getOrnamentsDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetOrnamentDetailsSuccess,
  ),
  getOrnamentsDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetOrnamentDetailsFailure,
  ),
  updateOrnamentsDetails: createAction<
    UpdateOrnamentPayload & AgriOptionPayload
  >(renewRebookActionTypes.UpdateOrnamentDetails),
  updateOrnamentsDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateOrnamentDetailsSuccess,
  ),
  updateOrnamentsDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateOrnamentDetailsFailure,
  ),
  getCustomerFinancials: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetCustomerFinancials,
  ),
  getCustomerFinancialsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCustomerFinancialsSuccess,
  ),
  getCustomerFinancialsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCustomerFinancialsFailure,
  ),
  getCustomerRequirements: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetCustomerRequirements,
  ),
  getCustomerRequirementsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCustomerRequirementsSuccess,
  ),
  getCustomerRequirementsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCustomerRequirementsFailure,
  ),
  getRepaymentHistory: createAction<LoanIdPayload>(
    renewRebookActionTypes.GetRepaymentHistory,
  ),
  getRepaymentHistorySuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetRepaymentHistorySuccess,
  ),
  getRepaymentHistoryFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetRepaymentHistoryFailure,
  ),
  saveCMOfferDetails: createAction<
    SaveCMOfferDetailsPayload & AgriOptionPayload
  >(renewRebookActionTypes.SaveCMOfferDetails),
  saveCMOfferDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.SaveCMOfferDetailsSuccess,
  ),
  saveCMOfferDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.SaveCMOfferDetailsFailure,
  ),
  getCMOfferDetails: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetCMOfferDetails,
  ),
  getCMOfferDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCMOfferDetailsSuccess,
  ),
  getCMOfferDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCMOfferDetailsFailure,
  ),
  getRenewRebookConfig: createAction(
    renewRebookActionTypes.GetRenewRebookConfig,
  ),
  getRenewRebookConfigSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetRenewRebookConfigSuccess,
  ),
  getRenewRebookConfigFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetRenewRebookConfigFailure,
  ),
  getCMConfig: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetCMConfig,
  ),
  getCMConfigSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCMConfigSuccess,
  ),
  getCMConfigFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCMConfigFailure,
  ),
  getPaymentDetails: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetPaymentDetails,
  ),
  getPaymentDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetPaymentDetailsSuccess,
  ),
  getPaymentDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetPaymentDetailsFailure,
  ),
  getLoanRenewalClosureDetails: createAction<
    GetLoanRenewalClosureDetailPayload
  >(renewRebookActionTypes.GetLoanRenewalClosureDetail),
  getLoanRenewalClosureDetailsSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetLoanRenewalClosureDetailSuccess,
  ),
  getLoanRenewalClosureDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetLoanRenewalClosureDetailFailure,
  ),
  closeLoanRenewal: createAction<CloseLoanRenewalPayload>(
    renewRebookActionTypes.CloseLoanRenewal,
  ),
  closeLoanRenewalSuccess: createAction<GenericResponse>(
    renewRebookActionTypes.CloseLoanRenewalSuccess,
  ),
  closeLoanRenewalFailure: createAction<GenericResponse>(
    renewRebookActionTypes.CloseLoanRenewalFailure,
  ),
  getLoanRenewalMappedPayments: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetLoanRenewalMappedPayments,
  ),
  getLoanRenewalMappedPaymentsSuccess: createAction<GenericResponse>(
    renewRebookActionTypes.GetLoanRenewalMappedPaymentsSuccess,
  ),
  getLoanRenewalMappedPaymentsFailure: createAction<GenericResponse>(
    renewRebookActionTypes.GetLoanRenewalMappedPaymentsFailure,
  ),
  getLoanRenewalStatus: createAction<LoanTypePayload>(
    renewRebookActionTypes.GetLoanRenewalStatus,
  ),
  getLoanRenewalStatusSuccess: createAction<LoanRenewalStatusResponse>(
    renewRebookActionTypes.GetLoanRenewalStatusSuccess,
  ),
  getLoanRenewalStatusFailure: createAction<GenericResponse>(
    renewRebookActionTypes.GetLoanRenewalStatusFailure,
  ),
  updateRoi: createAction<UpdateRoiPayload>(renewRebookActionTypes.UpdateRoi),
  updateRoiSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateRoiSuccess,
  ),
  updateRoiFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateRoiFailure,
  ),
  getUserAcceptedOffer: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetUserAcceptedOffer,
  ),
  getUserAcceptedOfferSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetUserAcceptedOfferSuccess,
  ),
  getUserAcceptedOfferFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetUserAcceptedOfferFailure,
  ),
  getGoldRate: createAction<LoanIdPayload>(renewRebookActionTypes.GetGoldRate),
  getGoldRateSuccess: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetGoldRateSuccess,
  ),
  getGoldRateFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetGoldRateFailure,
  ),
  getCMAdjustmentRenewalDetails: createAction<RequestIdPayload>(
    renewRebookActionTypes.GetCMAdjustmentRenewalDetails,
  ),
  getCMAdjustmentRenewalDetailsSuccess: createAction<CMAdjustmentRenewalDetail>(
    renewRebookActionTypes.GetCMAdjustmentRenewalDetailsSuccess,
  ),
  getCMAdjustmentRenewalDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.GetCMAdjustmentRenewalDetailsFailure,
  ),
  updateCMAdjustmentRenewalDetails: createAction<
    UpdateCMAdjustmentRenewalDetailsPayload
  >(renewRebookActionTypes.UpdateCMAdjustmentRenewalDetails),
  updateCMAdjustmentRenewalDetailsSuccess: createAction<
    CMAdjustmentRenewalDetail
  >(renewRebookActionTypes.UpdateCMAdjustmentRenewalDetailsSuccess),
  updateCMAdjustmentRenewalDetailsFailure: createAction<ServiceDeskResponse>(
    renewRebookActionTypes.UpdateCMAdjustmentRenewalDetailsFailure,
  ),
  setServiceRequestsPagination: setPaginationFor("services"),
};

export {renewRebookActionTypes};
export default renewRebookActions;
