import {createSlice} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import {PayloadAction} from "@reduxjs/toolkit";
import transactionsActions from "./transactions.actions";
import {
  Transactions,
  LoanAdjustmentRenewalDetails,
} from "app/models/transactions.model";

type LoadingErrorState =
  | "getTransactionsList"
  | "createTransaction"
  | "proceedAfterAdjustment"
  | "showTransactionFlow"
  | "getAdjustmentRenewalRoi"
  | "shareOfferWithCustomer";

export interface TransactionsState {
  transactionsList: Transactions[];
  isTransactionFlowEnable: boolean;
  shareOfferWithCustomerSuccess: boolean;
  adjustmentLoanRenewalRoi: LoanAdjustmentRenewalDetails | null;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: TransactionsState = {
  transactionsList: [],
  shareOfferWithCustomerSuccess: false,
  isTransactionFlowEnable: false,
  adjustmentLoanRenewalRoi: null,
  loading: {
    getTransactionsList: false,
    createTransaction: false,
    proceedAfterAdjustment: false,
    showTransactionFlow: false,
    getAdjustmentRenewalRoi: false,
    shareOfferWithCustomer: false,
  },
  errors: {
    getTransactionsList: null,
    createTransaction: null,
    proceedAfterAdjustment: null,
    showTransactionFlow: null,
    getAdjustmentRenewalRoi: null,
    shareOfferWithCustomer: null,
  },
};

const TransactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    resetTransactionList: (state) => {
      state.transactionsList = [];
      state.shareOfferWithCustomerSuccess = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(transactionsActions.getTransactionsList, (state) => {
        state.loading.getTransactionsList = true;
        state.errors.getTransactionsList =
          initialState.errors.getTransactionsList;
      })
      .addCase(
        transactionsActions.getTransactionsListSuccess,
        (state, action) => {
          state.loading.getTransactionsList = false;
          state.transactionsList = action.payload.response;
          state.errors.getTransactionsList =
            initialState.errors.getTransactionsList;
        },
      )
      .addCase(
        transactionsActions.getTransactionsListFailure,
        (state, action) => {
          state.loading.getTransactionsList = false;
          state.errors.getTransactionsList = action.payload.error;
        },
      )
      .addCase(transactionsActions.createTransaction, (state) => {
        state.loading.createTransaction = true;
        state.errors.createTransaction = initialState.errors.createTransaction;
      })
      .addCase(
        transactionsActions.createTransactionSuccess,
        (state, action) => {
          state.loading.createTransaction = false;
          state.errors.createTransaction =
            initialState.errors.createTransaction;
        },
      )
      .addCase(
        transactionsActions.createTransactionFailure,
        (state, action) => {
          state.loading.createTransaction = false;
          state.errors.createTransaction = action.payload.error;
        },
      )
      .addCase(transactionsActions.proceedAfterAdjustment, (state) => {
        state.loading.proceedAfterAdjustment = true;
        state.errors.proceedAfterAdjustment =
          initialState.errors.proceedAfterAdjustment;
      })
      .addCase(
        transactionsActions.proceedAfterAdjustmentSuccess,
        (state, action) => {
          state.loading.proceedAfterAdjustment = false;
          state.errors.proceedAfterAdjustment =
            initialState.errors.proceedAfterAdjustment;
        },
      )
      .addCase(
        transactionsActions.proceedAfterAdjustmentFailure,
        (state, action) => {
          state.loading.proceedAfterAdjustment = false;
          state.errors.proceedAfterAdjustment = action.payload.error;
        },
      )
      .addCase(transactionsActions.showTransactionFlow, (state) => {
        state.loading.showTransactionFlow = true;
        state.errors.showTransactionFlow =
          initialState.errors.showTransactionFlow;
      })
      .addCase(
        transactionsActions.showTransactionFlowSuccess,
        (state, action) => {
          state.loading.showTransactionFlow = false;
          state.isTransactionFlowEnable =
            action.payload.response.showTransactionFlow;
          state.errors.showTransactionFlow =
            initialState.errors.showTransactionFlow;
        },
      )
      .addCase(
        transactionsActions.showTransactionFlowFailure,
        (state, action) => {
          state.loading.showTransactionFlow = false;
          state.errors.showTransactionFlow = action.payload.error;
        },
      )
      .addCase(transactionsActions.getAdjustmentRenewalRoi, (state) => {
        state.loading.getAdjustmentRenewalRoi = true;
        state.errors.getAdjustmentRenewalRoi =
          initialState.errors.getAdjustmentRenewalRoi;
      })
      .addCase(
        transactionsActions.getAdjustmentRenewalRoiSuccess,
        (state, action) => {
          state.loading.getAdjustmentRenewalRoi = false;
          state.adjustmentLoanRenewalRoi = action.payload.response;
          state.errors.getAdjustmentRenewalRoi =
            initialState.errors.getAdjustmentRenewalRoi;
        },
      )
      .addCase(
        transactionsActions.getAdjustmentRenewalRoiFailure,
        (state, action) => {
          state.loading.getAdjustmentRenewalRoi = false;
          state.errors.getAdjustmentRenewalRoi = action.payload.error;
        },
      )
      .addCase(transactionsActions.shareOfferWithCustomer, (state) => {
        state.loading.shareOfferWithCustomer = true;
        state.shareOfferWithCustomerSuccess = false;
        state.errors.shareOfferWithCustomer =
          initialState.errors.shareOfferWithCustomer;
      })
      .addCase(
        transactionsActions.shareOfferWithCustomerSuccess,
        (state, action) => {
          state.shareOfferWithCustomerSuccess = true;
          state.loading.shareOfferWithCustomer = false;
          state.errors.shareOfferWithCustomer =
            initialState.errors.shareOfferWithCustomer;
        },
      )
      .addCase(
        transactionsActions.shareOfferWithCustomerFailure,
        (state, action) => {
          state.loading.shareOfferWithCustomer = false;
          state.errors.shareOfferWithCustomer = action.payload.error;
        },
      ),
});

const {resetTransactionList} = TransactionsSlice.actions;
export {resetTransactionList};

export default TransactionsSlice.reducer;
