import React from "react";

import styles from "../styles";

import StatusButton from "./Status";

const TakeOverDetails = ({
  selectedPayload,
  bankDetails,
  otherRequestTimer,
}) => {
  const classes = styles();

  return (
    <div>
      <div className={classes.textContainer}>
        <div className={classes.text}>BANK DETAILS SHARED BY CUSTOMER</div>
        <div className={classes.subText}>{bankDetails.beneficiaryName}</div>
        <div
          className={classes.subText}
        >{`Account Number: ${bankDetails.accountNumber}`}</div>

        <div className={classes.subText}>{`Branch: ${bankDetails.branch}`}</div>
        <div
          className={classes.subText}
        >{`IFSC Code: ${bankDetails.ifscCode}`}</div>

        <div className={classes.subText}>{`City: ${bankDetails.city}`}</div>
      </div>

      <StatusButton
        otherRequestTimer={otherRequestTimer}
        selectedPayload={selectedPayload}
      />
    </div>
  );
};

export default TakeOverDetails;
