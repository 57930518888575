import {RootState} from "../store";

const lenderCustomerProfileSelectors = {
  getOverduePaymentDetails: (state: RootState) =>
    state.lenderCustomerProfile.overduePaymentDetail,
  getReminderList: (state: RootState) =>
    state.lenderCustomerProfile.reminderList,
  getCustomerProfileList: (state: RootState) =>
    state.lenderCustomerProfile.customerProfileList,
  getCustomerDetails: (state: RootState, userId: number) => {
    return state.lenderCustomerProfile.customerProfileList.find(
      (c) => c.id === userId,
    );
  },
  getLoading: (state: RootState) => state.lenderCustomerProfile.loading,
  getErrors: (state: RootState) => state.lenderCustomerProfile.errors,
};

export default lenderCustomerProfileSelectors;