const routeNames = {
  goldLoan: "gold-loan",
  // -------> /digital-gold/scheduling <-------
  scheduling: "scheduling",
  jobView: "job-view",
  resourceView: "resource-view",
  lmAvailability: "lm-availability",
  unscheduled: "unscheduled",
  openCR: "openCR",
  algo: "algo",
  leads: "leads",
  // -------> /digital-gold/users <------
  users: "users",
  allUsers: "all-users",
  bannedUsers: "banned-users",
  bannedDevices: "banned-devices",
  whitelistUser: "whitelist-users",
  userDetails: ":id",

  analytics: "analytics",
  leadCreditHistory: "lead-credit-history",
  leadDocuments: "lead-documents",
  crmLead: "crm-lead",
};

export default routeNames;
