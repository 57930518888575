import styled from "@emotion/styled";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin-bottom: 1.5rem;

  width: 60%;
`;

export default RowContainer;
