import {
  GetCollateralMovementPayload,
  CollateralMovementApiInterface,
  CollateralMovementApiResources,
  GetInwardReceiptImagePayload,
} from "./../../../../store/collateralMovement/collateralMovement.types";
import {goldApiService} from "../../../plugins/axios";

const COLLATERAL_MOVEMENT_ENDPOINTS: CollateralMovementApiResources = {
  GET_ALL_COLLATERAL_MOVEMENT: {
    URL: "/business-dashboard/locker/all-collateral-movement",
    METHOD: "GET",
  },
  GET_INWARD_RECEIPT_IMAGE: {
    URL: "/business-dashboard/locker/cr/:crId/gold-submission-receipt-detail",
    METHOD: "GET",
  },
};

const collateralMovementApi: CollateralMovementApiInterface = {
  getAllCollateralMovement: async (payload: GetCollateralMovementPayload) => {
    return await goldApiService({
      resource: COLLATERAL_MOVEMENT_ENDPOINTS.GET_ALL_COLLATERAL_MOVEMENT,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  getInwardReceiptImage: async ({crId}: GetInwardReceiptImagePayload) => {
    return await goldApiService({
      resource: COLLATERAL_MOVEMENT_ENDPOINTS.GET_INWARD_RECEIPT_IMAGE,
      options: {
        pathVars: {
          crId,
        },
      },
    });
  },
};

export {COLLATERAL_MOVEMENT_ENDPOINTS};
export default collateralMovementApi;
