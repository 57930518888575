import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Divider, Grid, Typography, Box} from "@mui/material";
import Loading from "app/components/Loading";
import SidebarHeader from "../../SidebarHeader";
import SidebarTextValue from "../../SidebarTextValue";
import ImageLink from "../../../DrawerList/MileStoneCard/CommonComponents/ImageLink";
import DocView from "../../../DrawerList/MileStoneCard/CommonComponents/DocView";
import RemarkForm from "../RemarkForm";
import postUpdateStatus from "api/accounts/postUpdateStatus";
import getCustomerBankAccountDetails from "api/accounts/getCustomerBankAccountDetails";
import getDocuments from "api/business/getDocument";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {
  PayoutAccountVerificationFailedReasonEnum,
  PayoutAccountVerificationStatusEnum,
  AddBeneficiaryDocumentTypes,
  UserRelationshipsEnum,
  JobTypeEnum,
} from "app/enums/accounts";
import {authSelectors} from "app/store/auth";
import {
  AddBeneficiaryContainer,
  NameMismatchDetailsContainer,
  RelationShipContainer,
  StyledTitle,
  StyledValue,
  VerificationStatusContainer,
} from "./styled";
import styles from "../../styles";
import {IGButton, IGTextField} from "app/components";
import getPersonalDetails from "api/business/getPersonalDetail";
import {customerPersonalDetailsEnum} from "app/enums/accounts";
import updatedBankDetailVerificationStatus from "api/accounts/updateBankVerificationStatus";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {loanTypeEnum} from "app/enums/customerProfiles";

const {PENDING, SUCCESS, FAILED} = PayoutAccountVerificationStatusEnum;
const {
  ACCOUNT_INACTIVE,
  NAME_MISMATCH,
} = PayoutAccountVerificationFailedReasonEnum;
const {BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT} = AddBeneficiaryDocumentTypes;

const AddBeneficiary = ({
  bankDetails,
  setDrawer,
  getListOfAccountJobs,
  resetSelectedRow,
  selectedPayload,
}) => {
  // Hooks, styles & selectors
  const classes = styles();
  const {showError, showSuccess} = useSnackBar();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  // States
  const [beneficiaryConsent, setBeneficiaryConsent] = useState(false);
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const [accountHolderNameByAgent, setAccountHolderNameByAgent] = useState(
    null,
  );
  const [fieldData, setFieldData] = useState({
    accountNumber: "",
    beneficiaryName: "",
    bankDetailType: "",
    branch: "",
    city: "",
    ifscCode: "",
    registeredAccountHolderName: "",
    isValid: false,
    accountHolderNameByAgent: "",
    fwcBankAccountVerificationDetailDto: {
      status: "",
      failureReason: "",
    },
    relationshipWithBankOwner: "",
    documents: [],
    lenderIfscValidationStatus: "",
  });
  const [states, setStates] = useState({
    loading: true,
    error: false,
  });
  const [openCheque, setOpenCheque] = useState(false);
  const [docViewTitle, setDocViewTitle] = useState("");
  const [relationshipDocument, setRelationShipDocument] = useState(null);
  const [customerFullName, setCustomerFullName] = useState("");
  const [isUpdateBankStatusLoading, setIsUpdateBankStatusLoading] = useState(
    false,
  );
  const [isIfscVerifyLoading, setIsIfscVerifyLoading] = useState(false);

  // Api Callbacks & Handlers
  const fetchDocuments = useCallback(
    async (documentEnums) => {
      try {
        const response = await getDocuments({
          accessToken,
          crId: bankDetails.crId,
          documentType: [...documentEnums],
        });

        if (response.statusCode === 200) {
          if (
            documentEnums.includes(BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT)
          ) {
            // Set relationship document if document type is bank account name mismatch proof
            setRelationShipDocument(
              response.payload.find(
                (doc) =>
                  // eslint-disable-next-line max-len
                  doc.documentType ===
                  BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT,
              ),
            );
          } else {
            // Set cheque documents in field Data
            setFieldData((prevState) => ({
              ...prevState,
              documents: response.payload || [],
            }));
          }
        } else {
          showError(response?.error || "Unable to get documents");
          setStates({
            error: response?.error || "Unable to get documents",
            loading: false,
          });
        }
      } catch (error) {
        setStates({
          error: true,
          loading: false,
        });
      }
    },
    [accessToken, bankDetails.crId, showError],
  );

  const getCustomerBankAccountAndDocuments = useCallback(async () => {
    try {
      const {response, error} = await getCustomerBankAccountDetails({
        bankDetailType: bankDetails.bankDetailType,
        crId: bankDetails.crId,
      });

      if (!error) {
        setFieldData((prevState) => ({
          ...prevState,
          ...response,
        }));
        if (
          response.fwcBankAccountVerificationDetailDto?.status === FAILED &&
          response.fwcBankAccountVerificationDetailDto?.failureReason ===
            NAME_MISMATCH
        ) {
          await fetchDocuments(["BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT"]);
        }

        await fetchDocuments([
          "CUSTOMER_TAKEOVER_BANK_CHEQUE",
          "CUSTOMER_LOAN_BANK_CHEQUE",
          "SM_CUSTOMER_LOAN_BANK_CHEQUE",
        ]);

        setStates({
          ...states,
          loading: false,
        });
      } else {
        setStates({
          ...states,
          error: true,
        });
        showError(error || "Unable to get customer account details");
      }
    } catch (err) {
      showError("Unable to get customer account details.");
    }
  }, [
    bankDetails.bankDetailType,
    bankDetails.crId,
    showError,
    states,
    fetchDocuments,
  ]);

  const onClickReverifyIfsc = useCallback(async () => {
    setIsIfscVerifyLoading(true);
    const {error} = await activeLoansApi.getLenderIfscStatus({
      crId: bankDetails.crId,
      bankDetailType: bankDetails.bankDetailType,
      loanType: loanTypeEnum.GOLD_LOAN,
    });

    if (!error) {
      setIsIfscVerifyLoading(false);
      getCustomerBankAccountAndDocuments();
      showSuccess("Lender Ifsc has been successfully reverified!");
    } else {
      setIsIfscVerifyLoading(false);
      showError(error?.message);
    }
  }, [bankDetails, showSuccess, showError]);

  const acceptOnClickHandler = useCallback(async () => {
    try {
      if (bankDetails.id) {
        setStates({
          ...states,
          loading: true,
        });
        const {statusCode} = await postUpdateStatus({
          accessToken,
          remark: "",
          status: "COMPLETED",
          crId: bankDetails.crId,
          crJobId: bankDetails.id,
        });
        if (statusCode === 200) {
          showSuccess("Status updated sucessfully.");
          setStates({
            ...states,
            loading: false,
          });
          setDrawer(false);
          resetSelectedRow();
          getListOfAccountJobs();
        } else {
          showError("Status Update failed");
          setStates({
            ...states,
            loading: false,
            error: "Status Update failed",
          });
        }
      } else {
        showError("Unable to find crJobId");
        setStates({
          ...states,
          loading: false,
          error: "Unable to find crJobId",
        });
      }
      setFeedbackFormOpen(false);
    } catch (err) {
      showError("Status update failed.");
      setStates({
        ...states,
        loading: false,
        error: "Status update failed.",
      });
    }
  }, [
    accessToken,
    bankDetails.crId,
    bankDetails.id,
    resetSelectedRow,
    getListOfAccountJobs,
    setDrawer,
    showError,
    showSuccess,
    states,
  ]);

  const rejectOnClickHandler = useCallback(() => {
    setFeedbackFormOpen(true);
  }, [setFeedbackFormOpen]);

  const fetchPersonalDetails = useCallback(
    async (customerPersonalDetailType) => {
      const {payload: personalDetailsPayload, error} = await getPersonalDetails(
        {
          accessToken,
          crId: bankDetails.crId,
          personalDetailType: customerPersonalDetailType,
        },
      );

      if (personalDetailsPayload) {
        const {firstName, middleName, lastName} = personalDetailsPayload;
        setCustomerFullName(
          `${firstName || ""} ${middleName || ""} ${lastName || ""}`,
        );
      } else {
        showError(error);
      }
    },
    [bankDetails.crId, accessToken, showError],
  );

  const onClickBankDetailVerificationStatus = useCallback(
    async (crBankDetailTypeEnum) => {
      setIsUpdateBankStatusLoading(true);
      const {error} = await updatedBankDetailVerificationStatus({
        crId: bankDetails.crId,
        accountHolderNameByAgent: accountHolderNameByAgent,
        crBankDetailTypeEnum: crBankDetailTypeEnum,
      });

      if (!error) {
        showSuccess("Successfully updated!");
        getCustomerBankAccountAndDocuments();
      } else {
        showError(error?.message);
      }
      setIsUpdateBankStatusLoading(false);
    },
    [bankDetails.crId, accountHolderNameByAgent],
  );
  // Effects
  useEffect(() => {
    getCustomerBankAccountAndDocuments();
  }, []);

  useEffect(() => {
    if (
      bankDetails.taskType ===
      JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL
    ) {
      fetchPersonalDetails(
        customerPersonalDetailsEnum.TAKEOVER_PERSONAL_DETAIL,
      );
    } else {
      fetchPersonalDetails(customerPersonalDetailsEnum.LOAN_PERSONAL_DETAIL);
    }
  }, [bankDetails.taskType]);

  // Beneficiary Data Memo
  const beneficiaryDetails = useMemo(
    () => [
      {
        header: "Customer Name",
        text: customerFullName || "NA",
      },
      {
        header: "Account Holder Name",
        text:
          fieldData?.registeredAccountHolderName ||
          fieldData?.accountHolderNameByAgent,
        width: 8,
      },
      {
        header: "Account Number",
        text: fieldData.accountNumber,
      },
      {
        header: "IFSC Code",
        text: fieldData.ifscCode,
      },
      {
        header: "Branch",
        text: fieldData.branch,
      },
      {
        header: "Bank City",
        text: fieldData.city,
      },
    ],
    [
      customerFullName,
      fieldData.accountNumber,
      fieldData.branch,
      fieldData.ifscCode,
      fieldData.city,
      fieldData.registeredAccountHolderName,
      fieldData.accountHolderNameByAgent,
    ],
  );

  // Render Helpers
  const renderChequeDocuments = useCallback(() => {
    if (fieldData.documents?.length > 0) {
      return fieldData.documents?.map((doc) => {
        return (
          <ImageLink
            {...{
              image: doc,
              onClick: (url) => {
                setOpenCheque(url);
                setDocViewTitle(
                  doc.documentType?.replaceAll("_", " ") || "Cheque",
                );
              },
            }}
          />
        );
      });
    }
    return null;
  }, [fieldData.documents]);

  const renderStatusBlock = useCallback(() => {
    let color, text;
    switch (fieldData.fwcBankAccountVerificationDetailDto?.status) {
      case PENDING:
        color = "primary";
        text = "";
        break;
      case SUCCESS:
        color = "green";
        text = "Verified";
        break;
      case FAILED:
        color = "error";
        if (
          fieldData.fwcBankAccountVerificationDetailDto?.failureReason ===
          ACCOUNT_INACTIVE
        ) {
          text = "";
        } else if (
          fieldData.fwcBankAccountVerificationDetailDto?.failureReason ===
          NAME_MISMATCH
        ) {
          text = "Name Mismatch Found";
        } else {
          color = "black";
          text = "Please Verify Manually";
        }
        break;
      default:
        color = "primary";
        text = "";
        break;
    }

    if (!text) {
      return <></>;
    }

    return (
      <VerificationStatusContainer item xs={12}>
        <StyledTitle variant="body1">Verification Status</StyledTitle>
        <StyledValue variant="body1" color={color}>
          {text}
        </StyledValue>
      </VerificationStatusContainer>
    );
  }, [
    fieldData.fwcBankAccountVerificationDetailDto?.status,
    fieldData.fwcBankAccountVerificationDetailDto?.failureReason,
  ]);

  const renderIfscStatusBlock = useCallback(() => {
    let color, text;
    switch (fieldData.lenderIfscValidationStatus) {
      case SUCCESS:
        color = "green";
        text = "Success";
        break;
      case FAILED:
        color = "error";
        text = "Failed";
        break;
      case "UNVERIFIED":
        color = "primary";
        text = "Unverified";
        break;
      default:
        color = "primary";
        text = "";
        break;
    }

    if (!text) {
      return <></>;
    }

    return (
      <VerificationStatusContainer item xs={12}>
        <StyledTitle variant="body1">Lender Ifsc Status</StyledTitle>
        <StyledValue variant="body1" color={color}>
          {text}
        </StyledValue>
      </VerificationStatusContainer>
    );
  }, [fieldData.lenderIfscValidationStatus]);

  if (states.loading) {
    return (
      <AddBeneficiaryContainer container>
        <Loading />
      </AddBeneficiaryContainer>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth:
            bankDetails.taskType ===
            JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL
              ? "500px"
              : "800px",
        }}
      >
        {/* Beneficiary Details */}
        {beneficiaryDetails.map((item, idx) => (
          <Grid item xs={item.width || 4} key={item.header + idx}>
            <SidebarHeader headerText={item.header} />
            {item.header === "Account Holder Name" ? (
              <>
                {!item.text && !fieldData.isValid ? (
                  <Box>
                    <IGTextField
                      sx={{
                        width: "200px",
                      }}
                      name="accountHolderNameByAgent"
                      value={accountHolderNameByAgent}
                      autoComplete="off"
                      onChange={(e) =>
                        setAccountHolderNameByAgent(e.target.value)
                      }
                    />
                  </Box>
                ) : (
                  <SidebarTextValue
                    textValue={item.text}
                    style={{
                      color:
                        fieldData.fwcBankAccountVerificationDetailDto
                          ?.failureReason === NAME_MISMATCH
                          ? "red"
                          : "black",
                    }}
                  />
                )}
              </>
            ) : (
              <SidebarTextValue textValue={item.text} />
            )}
          </Grid>
        ))}

        {/* Cheque Documents */}
        {selectedPayload.milestone !== "COMPLETED" &&
          selectedPayload.milestone !== "CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
            <Grid item xs={8}>
              <SidebarHeader headerText="Cheque" />
              {renderChequeDocuments()}
            </Grid>
          )}

        {/* rendering this actional btn only in case of cm add beneficiary job */}
        {(bankDetails.taskType ===
          JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL ||
          bankDetails.taskType ===
            JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL) &&
          !fieldData.isValid && (
            <Grid item xs={12} textAlign="right">
              <IGButton
                loading={isUpdateBankStatusLoading}
                onClick={() =>
                  onClickBankDetailVerificationStatus(
                    bankDetails.bankDetailType,
                  )
                }
              >
                Approve
              </IGButton>
            </Grid>
          )}

        <Grid item xs={12}>
          <Divider sx={{margin: "1rem 0"}} />
        </Grid>

        {/* rendering this only in case of cm bank details  */}
        {bankDetails.taskType ===
          JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL && (
          <>
            {fieldData.lenderIfscValidationStatus && renderIfscStatusBlock()}

            {(fieldData.lenderIfscValidationStatus === FAILED ||
              fieldData.lenderIfscValidationStatus === "UNVERIFIED") && (
              <Grid
                mb={0.5}
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
              >
                <IGButton
                  size="small"
                  loading={isIfscVerifyLoading}
                  onClick={() => onClickReverifyIfsc()}
                >
                  verify
                </IGButton>
              </Grid>
            )}
          </>
        )}

        {/* Verification Status */}
        {fieldData.fwcBankAccountVerificationDetailDto &&
          (fieldData?.accountHolderNameByAgent ? null : renderStatusBlock())}

        {/*
         * Verification Status = Failed
         * Reason = Name Mismatch
         */}
        {NAME_MISMATCH ===
          fieldData.fwcBankAccountVerificationDetailDto?.failureReason && (
          <NameMismatchDetailsContainer item xs={12}>
            <Typography variant="body1" sx={{margin: "1rem 0"}}>
              Customer name doesn't match with the Account holder name. Please
              check the relation between these individuals.
            </Typography>
            <RelationShipContainer>
              <StyledTitle variant="body1">Relationship</StyledTitle>
              <StyledValue variant="body1">
                {UserRelationshipsEnum[fieldData.relationshipWithBankOwner] ||
                  "NA"}
              </StyledValue>
            </RelationShipContainer>
            <RelationShipContainer>
              <StyledTitle variant="body1">Relationship Document</StyledTitle>
              {relationshipDocument && (
                <ImageLink
                  {...{
                    image: relationshipDocument,
                    onClick: (url) => {
                      setOpenCheque(url);
                      setDocViewTitle(
                        relationshipDocument.documentType?.replaceAll(
                          "_",
                          " ",
                        ) || "Relationship Document",
                      );
                    },
                  }}
                />
              )}
            </RelationShipContainer>
          </NameMismatchDetailsContainer>
        )}

        {/* Add beneficiary Consent */}
        {bankDetails.taskType ===
          JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL && (
          <>
            <Grid item xs={12}>
              <div className={classes.checkBoxContainer}>
                <label>
                  <input
                    type="checkbox"
                    className={classes.checkBox}
                    onChange={() => setBeneficiaryConsent(!beneficiaryConsent)}
                  />
                  {"  "}I have added the given account as a beneficiary
                </label>
              </div>
            </Grid>
          </>
        )}

        {/* Actions */}
        {bankDetails.taskType ===
          JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL && (
          <>
            <IGButton
              color="golden"
              fullWidth
              loading={states.loading}
              disabled={!beneficiaryConsent}
              onClick={acceptOnClickHandler}
              sx={{margin: "1rem 0"}}
            >
              YES, MARK COMPLETED
            </IGButton>
            <IGButton
              color="golden"
              variant="outlined"
              fullWidth
              onClick={rejectOnClickHandler}
            >
              REPORT ISSUE
            </IGButton>
          </>
        )}

        {/* Feedback form */}
        {feedbackFormOpen && (
          <RemarkForm
            crId={bankDetails.crId}
            crJobId={bankDetails.id}
            feedbackFormOpen={feedbackFormOpen}
            setFeedbackFormOpen={setFeedbackFormOpen}
            setDrawer={setDrawer}
            getListOfAccountJobs={getListOfAccountJobs}
          />
        )}
      </Grid>

      {/* Document Viewer modal */}
      <DocView
        open={openCheque}
        onCloseHandler={() => {
          setOpenCheque(false);
          setDocViewTitle("");
        }}
        docsList={[openCheque]}
        title={docViewTitle}
      />
    </>
  );
};

export default AddBeneficiary;
