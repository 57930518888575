import React, {useState, useEffect, useRef, useCallback} from "react";
import styles from "../styles";

import isGoldReleased from "../../../../../../../api/business/isGoldReleased";
import Snackbar from "../../../../../../components/Snackbar";

import GreenTickIcon from "../../../../../../assets/GreenTick";
import FailedIcon from "../../../../../../assets/Exclaimation";
import ProcessingIcon from "../../../../../../assets/Processing";
import {CircularProgress} from "@mui/material";
import clsx from "clsx";

const GoldRelease = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const ref = useRef(0);

  const getgold = useCallback(() => {
    (async () => {
      try {
        const response = await isGoldReleased({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        });
        if (response.statusCode === 200) {
          setStates({
            error: false,
            loading: false,
            payload: response.payload,
            errorMessage: "",
          });
        } else {
          setStates({
            error: true,
            loading: false,
            payload: null,
            errorMessage: response?.error?.toString(),
          });
        }
      } catch (error) {
        setStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: "Something went wrong",
        });
      }
    })();
  }, [selectedPayload.id]);

  useEffect(() => {
    if (ref.current === 0) {
      ref.current = 1;
      getgold();
    }
  }, [getgold, selectedPayload.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      getgold();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getgold, otherRequestTimer]);

  const [snackbar, setSnackbar] = useState({
    snackbar: false,
    message: "",
  });

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const getIcon = (status) => {
    if (status !== undefined) {
      if (status === "SUCCESS") {
        return <GreenTickIcon />;
      }

      if (status === "PENDING") {
        return <ProcessingIcon />;
      }

      return <FailedIcon />;
    }
      return <></>;

  };

  const getText = (status) => {
    if (status !== undefined) {
      if (status === "SUCCESS") {
        return "Successfully";
      }
      if (status === "PENDING") {
        return "Pending";
      }
      return "Failed";
    }
      return "";

  };

  const Icon = getIcon(states.payload.goldReleaseStatus);
  const iconText = getText(states.payload.goldReleaseStatus);

  return (
    <>
      {states.payload.goldReleaseStatus !== undefined && (
        <div className={classes.textContainer}>
          <div className={classes.text}>GOLD RELEASE STATUS</div>
          <div className={clsx(classes.subText, classes.icon)}>
            {Icon}
            {iconText}
          </div>
        </div>
      )}

      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />

      {states.payload.remark !== null && (
        <div className={classes.textContainer}>
          <div className={classes.text}>Remark</div>
          <div className={classes.subText}>{states.payload.remark}</div>
        </div>
      )}

      {/* {states.payload.goldReleaseStatus === "FAILED" && (
        <div className={classes.textContainer}>
          <div className={classes.text}>END JOB DETAILS</div>
          <textarea
            placeholder="Write explanation for ending job and share date and subject of email by which written approval was taken to end job."
            value={message}
            className={classes.textArea}
            onChange={(event) => setMessage(event.target.value)}
          />

          <button onClick={onClickEndJobHandler} className={classes.reject}>
            END THIS JOB
          </button>
        </div>
      )} */}
    </>
  );
};

export default GoldRelease;
