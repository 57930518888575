import React, {FC, useEffect, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanPaymentSelectors} from "app/store/loanPayment";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {loanPaymentActions} from "app/store/loanPayment";
import {useParams} from "react-router-dom";
import {IGTable} from "app/components";
import {IGTableColsInterface} from "app/components/IGTable";
import {LoanPaymentModel} from "app/models/loanPayment.model";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {Box, Typography} from "@mui/material";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";
import {Info} from "@mui/icons-material";
import tippy from "tippy.js";
import {loanSelectors} from "app/store/loan";

interface PaymentDetailsProps {
}

const PaymentDetails: FC<PaymentDetailsProps> = () => {

  const dispatch = useAppDispatch();
  const {
    t, tTableHeader,
  } = useIGTranslation();
  const {loanId} = useParams();

  const totalRowId = Math.random();

  const user = useAppSelector(authSelectors.getUser);
  const loanPaymentList = useAppSelector(
    loanPaymentSelectors.getLoanPaymentList,
  );
  const {
    getLoanPaymentDetails,
  } = useAppSelector(loanPaymentSelectors.getLoading);
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLenderLoanDetails(state, Number(loanId)),
  );

  const paymentTableCols: IGTableColsInterface<LoanPaymentModel> = [
    {
      headerName: t("DATE"),
      field: "createdAt",
      renderCell: ({value, row}) =>
        row.status === "isTotal"
          ? t("TOTAL")
          : getValidTime(value).format("DD/MM/YYYY"),
    },
    {
      headerName: t("AMOUNT"),
      field: "paymentAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("MODE"),
      field: "paymentMode",
      renderCell: ({value}) => value?.toUpperCase().replace(/_/g, " "),
    },
    {
      headerName: t("STATUS"),
      field: "status",
      renderCell: ({value, row}) => {
        const isError = ["AUTO-REFUNDED", "FAILED"].includes(value);
        const tooltipText =
          row.errorDescription || row.orderPaymentInfoDto?.supportTeamMessage;

        return (
          <Box
            display="flex"
            alignItems="center"
            onMouseEnter={(e) => {
              if (tooltipText) {
                tippy(e.currentTarget, {
                  content: tooltipText,
                  allowHTML: true,
                  placement: "top",
                });
              }
            }}
          >
            <Box>
              {value !== "isTotal" && (
                <Typography color={isError ? "error" : ""}>{value}</Typography>
              )}
            </Box>
            {isError && tooltipText && (
              <Box ml={1}>
                <Info fontSize="small" color="error" />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      headerName: "Received Via",
      field: "receivedVia",
      renderCell: ({value}) => value,
    },
  ];

  if (loanDetails?.isPersonalLoanTaken) {
    paymentTableCols.splice(4, 0, {
      headerName: t("CUSTOMER_PROFILE.TYPE_ALLOCATION", {type: "GL"}),
      field: "goldLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    }, {
      headerName: t("CUSTOMER_PROFILE.TYPE_ALLOCATION", {type: "PL"}),
      field: "personalLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    });
  }

  useEffect(() => {
    dispatch(loanPaymentActions.getLenderLoanPaymentDetails({
      loanId: Number(loanId),
    }));
  }, [dispatch, loanId]);

  const loanPaymentData = useMemo(() => {
    const paymentList = [...loanPaymentList];
    paymentList.push({
      status: "isTotal",
      id: totalRowId.toString(),
      paymentAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => acc + cv.paymentAmount, 0),
      ),
      goldLoanAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => acc + cv.goldLoanAmount, 0),
      ),
      personalLoanAmount: numberWithCurrencyAndComma(
        loanPaymentList.reduce((acc, cv) => acc + cv.personalLoanAmount, 0),
      ),
    } as LoanPaymentModel);
    return paymentList;
  }, [loanPaymentList, totalRowId]);


  return (
    <div>
        <IGTable<LoanPaymentModel>
          hideFooter
          loading={getLoanPaymentDetails}
          tableCols={paymentTableCols}
          tableData={loanPaymentData}
          selectedRowId={totalRowId}
          getRowId={row => row.id}
          sx={{
            ".Mui-selected": {
              fontWeight: "bold",
            },
          }}
          components={{
            Header: (params) => (
              <Box
                {...params} p={2}
                display="flex" alignItems="center" gap={2}
              >
                <Typography variant="h5">
                  {tTableHeader("PAYMENTS")}
                </Typography>
              </Box>
            ),
          }}
          columnVisibilityModel={{
            paymentVa: !user?.userRoleList?.includes(RolesEnum.LENDER),
          }}
        />
    </div>
  );
};

export default PaymentDetails;