import React, {FC} from "react";
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import {Box, FormControl, InputLabel, MenuItem, Pagination, PaginationItem, Select} from "@mui/material";

export interface IGTablePaginationProps {
  disabledNext?: boolean
  disabledPrev?: boolean
  paginationType?: "single" | "multiple"
  pageSizes?: number[]
}

const IGTablePagination: FC<IGTablePaginationProps> = ({
  disabledNext,
  disabledPrev,
  paginationType,
  pageSizes,
}) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const defaultPageSizes = [10, 25, 50];

  return (
    <Box display="flex" alignItems="center">
      <Box width={100}>
        <FormControl fullWidth size="small">
          <InputLabel id="rows-per-page-label">Rows Per Page</InputLabel>
          <Select
            labelId="rows-per-page-label"
            id="rows-per-page"
            value={pageSize}
            label="Rows Per Page"
            onChange={(event) => {
              if (apiRef && apiRef.current) {
                apiRef.current.setPageSize(Number(event.target.value));
              }
            }}
          >
            {(pageSizes || defaultPageSizes).map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        {paginationType === "single" && (
          <>
            <PaginationItem
              type="previous"
              disabled={disabledPrev}
              onClick={() => {
                if (apiRef && apiRef.current) apiRef.current.setPage(page - 1);
              }}
            />
            <PaginationItem
              type="page"
              page={page + 1}
              selected={true}
              color="primary"
            />
            <PaginationItem
              type="next"
              disabled={disabledNext}
              onClick={() => {
                if (apiRef && apiRef.current) apiRef.current.setPage(page + 1);
              }}
            />
          </>
        )}
        {paginationType === "multiple" && (
          <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) =>
              apiRef && apiRef.current?.setPage(value - 1)
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default IGTablePagination;