import styled from "@emotion/styled";
import {Switch} from "@mui/material";

const DocumentChooseSwitch = styled(Switch)(() => {
  return {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "green !important",
      "&:hover": {
        backgroundColor: "rgba(60, 179, 113, 0.54) !important",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "green !important",
    },
  };
});

export default DocumentChooseSwitch;
