import React, {useCallback, useState, useEffect} from "react";
import {Box, Grid, Divider, Typography} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import getLenderDetails from "api/business/getLenderDetails";
import GoldenDivider from "../NewUploadKYC/KYCDetails/styles/GoldenDivider";

import {
  LeegalityContainer,
  MainTitle,
  Container,
  Title,
  ButtonContainer,
} from "./styles/index";
import DocumentDetails from "./DocumentDetails";
import getLenderDocuments from "api/goldLoan/getLenderDocument";
import getNextJob from "api/business/getNextJob";
import updateStatus from "api/business/updateStatus";
import {useSnackBar} from "app/store/hooks";
import getCrDocumentFlowDetail from "api/goldLoan/getCrDocumentFlowDetail";
import {
  lenderNameEnum,
  docFlowEnum,
  loanType,
  cmStatusLenderDocEnum,
} from "app/enums/gold_loan/activeLoans";
import getPostLoanApprovalDocuments from "api/goldLoan/getPostLoanApprovalDoc";
import {
  cmStatusDisbursalEnum,
  docSendBackReasonEnum,
} from "app/enums/gold_loan/activeLoans";
import {createLoanLmsLender} from "api/activeLoan/createLoanLmsLender";
import {IGButton} from "app/components";
import {useIGTranslation} from "app/infra/plugins/i18n";
import DocumentSendBackDialog from "./DocumentSendBackDialog";
import DocumentStatusContainer from "./DocumentStatusContainer";
import DocumentFlowContainer from "./DocumentFlowContainer";
import checkOldLoanClosed from "api/activeLoan/checkCurrentLoanClosed";

const Leegality = ({
  selectedPayload,
  isSameCreditManager,
  getMileStoneOfCr,
  currentMileStone,
}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);

  const {showError, showSuccess} = useSnackBar();

  // local states

  const [lenderIconGl, setLenderIconGl] = useState(null);
  const [lenderName, setLenderName] = useState(null);
  const [lenderIconPL, setLenderIconPL] = useState(null);
  const [lenderDocsDetailsPl, setLenderDocsDetailsPl] = useState([]);
  const [isDocStatusCompletedGl, setIsDocStatusCompletedGl] = useState(null);
  const [isDocStatusCompletedPl, setIsDocStatusCompletedPl] = useState(null);
  const [documentsTypeGl, setDocumentsTypeGl] = useState([]);
  const [documentsTypePl, setDocumentsTypePl] = useState([]);
  const [isGLApproveDocBtnLoading, setIsGLApproveBtnLoading] = useState(false);
  const [isPLApproveDocBtnLoading, setIsPLApproveBtnLoading] = useState(false);
  const [docFlowGl, setDocFlowGl] = useState(null);
  const [docFlowPl, setDocFlowPl] = useState(null);
  const [postLoanApprovalDocDetails, setPostLoanApprovalDocDetails] = useState(
    [],
  );
  const [isPostLoanDocCompleted, setIsPostLoanDocCompleted] = useState(null);
  const [showGLActionableBtn, setShowGLActionableBtn] = useState(null);
  const [showPLActionableBtn, setShowPLActionableBtn] = useState(false);
  const [crJobId, setCrJobId] = useState(null);
  const [isPLDocsApproved, setIsPLDocsApproved] = useState(false);
  const [activeSendBackEnum, setActiveSendBackEnum] = useState("");
  const [isSendBackDialogOpen, setIsSendBackDialogOpen] = useState(false);

  const {t, tErrors} = useIGTranslation();

  //handler to fetch lender icons for GL.

  const fetchLenderIcon = useCallback(async () => {
    const {payload, statusCode, error} = await getLenderDetails({
      accessToken,
      crId: selectedPayload.id,
      loanType: loanType.GOLD_LOAN.key,
    });

    if (statusCode === 200) {
      setLenderIconGl(payload?.icon);
      setLenderName(payload?.lenderName);
    } else {
      showError(error.message || "Unable to fetch lender icon");
    }
  }, [accessToken, selectedPayload.id, setLenderIconGl, setLenderName]);

  //handler to fetch lender icons for PL.

  const fetchLenderIconPL = useCallback(async () => {
    const {payload, statusCode, error} = await getLenderDetails({
      accessToken,
      crId: selectedPayload.id,
      loanType: loanType.PERSONAL_LOAN.key,
    });

    if (statusCode === 200) {
      setLenderIconPL(payload?.icon);
    } else {
      showError(error.message || "Unable to fetch lender icon");
    }
  }, [accessToken, selectedPayload.id, setLenderIconPL]);

  //Handler to fetch all lender-docs and constructing an array based on doc type and its status.

  const fetchLenderDocuments = useCallback(async () => {
    try {
      const [
        lenderDocsGoldLoanResponse,
        lenderDocsPersonalLoanResponse,
      ] = await Promise.all([
        getLenderDocuments({
          accessToken,
          crId: selectedPayload.id,
          loanType: loanType.GOLD_LOAN.key,
        }),
        getLenderDocuments({
          accessToken,
          crId: selectedPayload.id,
          loanType: loanType.PERSONAL_LOAN.key,
        }),
      ]);
      if (
        lenderDocsGoldLoanResponse.statusCode === 200 &&
        lenderDocsPersonalLoanResponse.statusCode === 200
      ) {
        setLenderDocsDetailsPl(lenderDocsPersonalLoanResponse?.response);
        const tempDocTypeArrGl = lenderDocsGoldLoanResponse.response.map(
          (item) => ({
            name: item.name,
            documentType: item.documentType,
          }),
        );
        setDocumentsTypeGl([...tempDocTypeArrGl]);

        const tempDocTypeArrPl = lenderDocsPersonalLoanResponse.response.map(
          (item) => ({
            name: item.name,
            documentType: item.documentType,
          }),
        );
        setDocumentsTypePl([...tempDocTypeArrPl]);
        const docStatusCheckGl = lenderDocsGoldLoanResponse.response.every(
          (item) => {
            return item.status === "COMPLETED";
          },
        );
        setIsDocStatusCompletedGl(docStatusCheckGl);

        const docStatusCheckPl = lenderDocsPersonalLoanResponse.response.every(
          (item) => {
            return item.status === "COMPLETED";
          },
        );
        setIsDocStatusCompletedPl(docStatusCheckPl);
      }
    } catch (err) {
      showError(tErrors("SOMETHING_WENT_WRONG"));
    }
  }, [selectedPayload.id, accessToken]);

  //Fetch post loan approval documents

  const fetchPostLoanApprovalDocuments = useCallback(async () => {
    const {response, error} = await getPostLoanApprovalDocuments({
      accessToken,
      crId: selectedPayload.id,
    });

    if (!error) {
      const tempPostApprovalDocDetails = response?.map((postDoc) => ({
        name: postDoc.name,
        documentType: postDoc.documentType,
      }));
      setPostLoanApprovalDocDetails([...tempPostApprovalDocDetails]);
      const postLoanDocStatusCheck = response?.every((item) => {
        return item.status === "COMPLETED";
      });
      setIsPostLoanDocCompleted(postLoanDocStatusCheck);
    } else {
      showError(error.message || "unable to fetch Post loan approval docs");
    }
  }, [accessToken, selectedPayload.id, showError]);

  //lifecycle methods to fetch lender documents and corresponding icons for gl and pl.

  useEffect(() => {
    fetchLenderDocuments();
  }, [fetchLenderDocuments]);

  useEffect(() => {
    fetchLenderIcon();
  }, [fetchLenderIcon]);

  useEffect(() => {
    if (lenderDocsDetailsPl.length !== 0) {
      fetchLenderIconPL();
    }
  }, [lenderDocsDetailsPl]);

  useEffect(() => {
    fetchPostLoanApprovalDocuments();
  }, []);

  //handler to check doc flow for GL whether its online/offline.

  const fetchCrDocumentFlowDetailGl = useCallback(async () => {
    const {response, statusCode, error} = await getCrDocumentFlowDetail({
      accessToken,
      crId: selectedPayload.id,
      loanType: loanType.GOLD_LOAN.key,
    });

    if (statusCode === 200) {
      setDocFlowGl(response?.legalityFlowType);
    } else {
      showError(error.message || "unable to fetch GL doc flow");
    }
  }, [accessToken, selectedPayload.id, showError]);

  useEffect(() => {
    fetchCrDocumentFlowDetailGl();
  }, []);

  //handler to check doc flow for PL whether its online/offline.

  const fetchCrDocumentFlowDetailPl = useCallback(async () => {
    const {response, statusCode, error} = await getCrDocumentFlowDetail({
      accessToken,
      crId: selectedPayload.id,
      loanType: loanType.PERSONAL_LOAN.key,
    });

    if (statusCode === 200) {
      setDocFlowPl(response?.legalityFlowType);
    } else {
      showError(error.message || "unable to fetch Pl doc flow");
    }
  }, [accessToken, selectedPayload.id, showError]);

  useEffect(() => {
    if (lenderDocsDetailsPl.length !== 0) {
      fetchCrDocumentFlowDetailPl();
    }
  }, [lenderDocsDetailsPl]);

  //handler to fetch cm status for disabling approve button.
  const getNextJobApi = useCallback(async () => {
    try {
      const response = await getNextJob({
        accessToken,
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode === 200) {
        setCrJobId(response.payload.id);
        if (
          response?.payload?.status ===
            /* eslint-disable-next-line max-len */
            cmStatusDisbursalEnum.CM_PROCESS_PERSONAL_LOAN_DISBURSAL_MAKER_DONE ||
          response?.payload?.status ===
            cmStatusDisbursalEnum.CM_PROCESS_GOLD_LOAN_DISBURSAL_MAKER_DONE
        ) {
          setShowGLActionableBtn(true);
          setShowPLActionableBtn(true);
        } else if (
          response.payload.status ===
          /* eslint-disable-next-line max-len */
          cmStatusLenderDocEnum.CM_PROCESS_PL_LENDER_DOCUMENT_APPROVED
        ) {
          setIsPLDocsApproved(true);
          setShowGLActionableBtn(true);
        } else if (currentMileStone?.data?.isComplete) {
          setIsPLDocsApproved(true);
        }
      } else {
        setShowGLActionableBtn(false);
        setShowPLActionableBtn(false);
      }
    } catch (error) {
      showError(tErrors("SOMETHING_WENT_WRONG"));
    }
  }, [
    accessToken,
    currentMileStone?.data?.isComplete,
    selectedPayload.id,
    showError,
    tErrors,
  ]);

  //in case loan has been closed GL docs needs to be visible!
  useEffect(() => {
    if (
      selectedPayload.milestone === "COMPLETED" ||
      selectedPayload.milestone === "CLOSED" ||
      selectedPayload.milestone === "CR_GOLD_LOAN_FRESH_CLOSED" ||
      selectedPayload.milestone === "CR_GOLD_LOAN_BT_CLOSED"
    ) {
      setIsPLDocsApproved(true);
    }
  }, [selectedPayload.milestone]);

  useEffect(() => {
    getNextJobApi();
  }, []);

  //handler for approval of GL/PL docs
  const onClickLenderDocsApprovalHandler = useCallback(
    async (cmSelectedApprovalDocStatus) => {
      try {
        if (
          cmSelectedApprovalDocStatus ===
          cmStatusLenderDocEnum.CM_PROCESS_GL_LENDER_DOCUMENT_APPROVED
        ) {
          setIsGLApproveBtnLoading(true);
          const checkloanClosed = await checkOldLoanClosed({
            crId: selectedPayload.id,
          });
          if (checkloanClosed.statusCode === 200) {
            const loanLmsLenderResponse = await createLoanLmsLender({
              crId: selectedPayload.id,
            });
            if (loanLmsLenderResponse.statusCode === 200) {
              const updateStatusResponse = await updateStatus({
                accessToken,
                crId: selectedPayload.id,
                crJobId: crJobId,
                remark: "",
                status: cmSelectedApprovalDocStatus,
              });

              if (updateStatusResponse.statusCode === 200) {
                showSuccess(`${t("ACTIVE_LOANS.LAN_SUCCESSFULLY_CREATED")}`);
                setIsGLApproveBtnLoading(false);
                getMileStoneOfCr();
                setShowGLActionableBtn(false);
                getNextJobApi();
              } else {
                showError(updateStatusResponse.error.toString());
                setIsGLApproveBtnLoading(false);
              }
            } else {
              showError(loanLmsLenderResponse.error.message);
              setIsGLApproveBtnLoading(false);
            }
          } else {
            showError(checkloanClosed.error.message);
            setIsGLApproveBtnLoading(false);
          }
        } else if (
          cmSelectedApprovalDocStatus ===
          cmStatusLenderDocEnum.CM_PROCESS_PL_LENDER_DOCUMENT_APPROVED
        ) {
          setIsPLApproveBtnLoading(true);
          const updateStatusResponse = await updateStatus({
            accessToken,
            crId: selectedPayload.id,
            crJobId: crJobId,
            remark: "",
            status: cmSelectedApprovalDocStatus,
          });

          if (updateStatusResponse.statusCode === 200) {
            showSuccess(`${t("ACTIVE_LOANS.PL_DOCS_APPROVED")}`);
            setIsPLApproveBtnLoading(false);
            setShowPLActionableBtn(false);
            setIsPLDocsApproved(true);
            getNextJobApi();
          } else {
            showError(updateStatusResponse.error.toString());
            setIsPLApproveBtnLoading(false);
          }
        }
      } catch (error) {
        showError(t("ERROR"));
        setIsGLApproveBtnLoading(false);
        setIsPLApproveBtnLoading(false);
      }
    },
    [
      selectedPayload.id,
      accessToken,
      crJobId,
      showSuccess,
      t,
      getMileStoneOfCr,
      getNextJobApi,
      showError,
    ],
  );

  const onModalHandler = () => {
    setIsSendBackDialogOpen((state) => !state);
  };

  return (
    <>
      <Container>
        <MainTitle>Lender Document</MainTitle>
      </Container>
      <Box
        p={2}
        sx={{
          width: "100%",
          border: 2,
          borderColor: "#F5E9BD",
          borderRadius: 2,
        }}
      >
        {/* personal loan documents status section */}
        {lenderDocsDetailsPl.length !== 0 && (
          <DocumentStatusContainer
            type={loanType.PERSONAL_LOAN.value}
            isDocStatusCompleted={isDocStatusCompletedPl}
          />
        )}

        {/* personal loan documents approval section */}
        {lenderDocsDetailsPl.length !== 0 && isDocStatusCompletedPl && (
          <>
            <DocumentFlowContainer
              title={`${t("ACTIVE_LOANS.PL_LOAN_DOCS")}`}
              lenderIcon={lenderIconPL}
              documentsType={documentsTypePl}
              docFlow={docFlowPl}
              crID={selectedPayload.id}
            />
            {isSameCreditManager &&
              showPLActionableBtn &&
              docFlowPl === docFlowEnum.OFFLINE && (
                <ButtonContainer>
                  <IGButton
                    color="golden"
                    fullWidth
                    onClick={() =>
                      onClickLenderDocsApprovalHandler(
                        /* eslint-disable-next-line max-len */
                        cmStatusLenderDocEnum.CM_PROCESS_PL_LENDER_DOCUMENT_APPROVED,
                      )
                    }
                    loading={isPLApproveDocBtnLoading}
                  >
                    APPROVE PERSONAL LOAN DOCUMENTS
                  </IGButton>

                  <IGButton
                    sx={{margin: "1rem"}}
                    fullWidth
                    onClick={() => {
                      onModalHandler();
                      setActiveSendBackEnum(
                        docSendBackReasonEnum.PL_LENDER_DOC_ISSUE,
                      );
                    }}
                    color="inherit"
                    disabled={docFlowPl === docFlowEnum.ONLINE}
                  >
                    ASK LM TO EDIT DOCUMENT
                  </IGButton>
                </ButtonContainer>
              )}
          </>
        )}

        {lenderDocsDetailsPl.length !== 0 && <GoldenDivider />}

        {/* gold loan documents  status section */}
        {(lenderDocsDetailsPl.length === 0 || isPLDocsApproved) && (
          <>
            <DocumentStatusContainer
              type={loanType.GOLD_LOAN.value}
              isDocStatusCompleted={isDocStatusCompletedGl}
            />
          </>
        )}

        {/* gold loan documents approval section */}
        {(lenderDocsDetailsPl.length === 0 || isPLDocsApproved) &&
          isDocStatusCompletedGl && (
            <>
              {lenderName === lenderNameEnum.PIRAMAL && (
                <LeegalityContainer>
                  <Title>Digital Loan Agreement</Title>
                  <Typography
                    variant="h6"
                    sx={{color: "green", fontWeight: "bold"}}
                  >
                    COMPLETED
                  </Typography>
                </LeegalityContainer>
              )}
              <DocumentFlowContainer
                title={`${t("ACTIVE_LOANS.GL_LOAN_DOCS")}`}
                lenderIcon={lenderIconGl}
                documentsType={documentsTypeGl}
                docFlow={docFlowGl}
                crID={selectedPayload.id}
              />
              {isSameCreditManager &&
                showGLActionableBtn &&
                docFlowGl === docFlowEnum.OFFLINE && (
                  <ButtonContainer>
                    <IGButton
                      color="golden"
                      fullWidth
                      onClick={() =>
                        onClickLenderDocsApprovalHandler(
                          /* eslint-disable-next-line max-len */
                          cmStatusLenderDocEnum.CM_PROCESS_GL_LENDER_DOCUMENT_APPROVED,
                        )
                      }
                      loading={isGLApproveDocBtnLoading}
                    >
                      APPROVE GOLD LOAN DOCUMENTS
                    </IGButton>

                    <IGButton
                      sx={{margin: "1rem"}}
                      fullWidth
                      onClick={() => {
                        onModalHandler();
                        setActiveSendBackEnum(
                          docSendBackReasonEnum.GL_LENDER_DOC_ISSUE,
                        );
                      }}
                      color="inherit"
                      disabled={docFlowGl === docFlowEnum.ONLINE}
                    >
                      ASK LM TO EDIT DOCUMENT
                    </IGButton>
                  </ButtonContainer>
                )}
            </>
          )}

        {/* render send back component for send back to edit docs flow */}
        <DocumentSendBackDialog
          crID={selectedPayload.id}
          getMileStoneOfCr={getMileStoneOfCr}
          fetchLenderDocuments={fetchLenderDocuments}
          activeSendBackEnum={activeSendBackEnum}
          onModalHandler={onModalHandler}
          setIsSendBackDialogOpen={setIsSendBackDialogOpen}
          isSendBackDialogOpen={isSendBackDialogOpen}
        />

        {/* render post loan approval documents section */}
        {isPostLoanDocCompleted && (
          <>
            <GoldenDivider />
            <LeegalityContainer>
              <Title>Other Documents</Title>
            </LeegalityContainer>

            <Grid container spacing={1}>
              {postLoanApprovalDocDetails.map((item, index) => (
                <>
                  <Grid item xs={4} rowSpacing={1}>
                    <DocumentDetails
                      key={index}
                      docType={item.documentType}
                      docName={item.name}
                      crId={selectedPayload.id}
                    />
                  </Grid>
                  {postLoanApprovalDocDetails.length - 1 !== index && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{background: "#ce9f29"}}
                    ></Divider>
                  )}
                </>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default Leegality;
