import {
  GetDisbursedJobsOrnaments,
  OrnamentFinenessConfig,
  OrnamentsModel,
  OrnamentsPacket,
  GetOrnamentsNames,
  OrnamentsFormValues,
} from "app/models/ornaments.model";
import {createSlice} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import ornamentsActions from "./ornaments.actions";
import {PayloadAction} from "@reduxjs/toolkit";

type LoadingErrorState =
  | "getDisbursedJobsOrnamentsList"
  | "getOrnamentsNamesList"
  | "getSelectedOrnamentDetails"
  | "getOrnamentsList"
  | "getOrnamentsFinenessConfig"
  | "getOrnamentsPacket"
  | "updateOrnamentDetails"
  | "downloadOrnamentsCsv";

export interface OrnamentState {
  disbursedJobsOrnamentsById: Record<number, GetDisbursedJobsOrnaments>;
  ornamentsNamesList: GetOrnamentsNames[];
  selectedOrnamentDetails: OrnamentsModel | null;
  selectedOrnamentId: null | number;
  ornamentsList: OrnamentsModel[];
  ornamentsFinenessConfigList: OrnamentFinenessConfig[];
  ornamentsPacket: OrnamentsPacket[];
  goldValueStats: {
    checkerGoldValue: number;
    lmGoldValue: number;
    maxGoldAmountWithoutPL: number;
    totalCheckerGrossWeight: number;
    totalCheckerNetWeight: number;
    totalCheckerImpurityWeight: number;
  };
  ornamentFormValues: {
    [key: number]: OrnamentsFormValues;
  };
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: OrnamentState = {
  disbursedJobsOrnamentsById: {},
  ornamentsNamesList: [],
  selectedOrnamentId: null,
  selectedOrnamentDetails: null,
  ornamentsList: [],
  ornamentsFinenessConfigList: [],
  ornamentsPacket: [],
  goldValueStats: {
    checkerGoldValue: 0,
    lmGoldValue: 0,
    maxGoldAmountWithoutPL: 0,
    totalCheckerGrossWeight: 0,
    totalCheckerNetWeight: 0,
    totalCheckerImpurityWeight: 0,
  },
  ornamentFormValues: {},

  loading: {
    getDisbursedJobsOrnamentsList: false,
    getOrnamentsNamesList: false,
    getSelectedOrnamentDetails: false,
    getOrnamentsList: false,
    getOrnamentsFinenessConfig: false,
    getOrnamentsPacket: false,
    updateOrnamentDetails: false,
    downloadOrnamentsCsv: false,
  },
  errors: {
    getDisbursedJobsOrnamentsList: null,
    getOrnamentsNamesList: null,
    getSelectedOrnamentDetails: null,
    getOrnamentsList: null,
    getOrnamentsFinenessConfig: null,
    getOrnamentsPacket: null,
    updateOrnamentDetails: null,
    downloadOrnamentsCsv: null,
  },
};

const OrnamentsSlice = createSlice({
  name: "ornaments",
  initialState,
  reducers: {
    resetOrnamentsList: (state) => {
      state.ornamentsNamesList = [];
      state.selectedOrnamentDetails = null;
      state.ornamentsList = [];
      state.ornamentsFinenessConfigList = [];
      state.ornamentsPacket = [];
      state.selectedOrnamentId = null;
      state.goldValueStats = initialState.goldValueStats;
      state.ornamentFormValues = {};
    },
    setSelectedOrnamentId: (
      state,
      action: PayloadAction<{ornamentId: null | number}>,
    ) => {
      state.selectedOrnamentId = action.payload.ornamentId;
    },
    setOrnamentFormValues: (
      state,
      action: PayloadAction<{ornamentId: number; values: OrnamentsFormValues}>,
    ) => {
      state.ornamentFormValues[action.payload.ornamentId] =
        action.payload.values;
        state.goldValueStats = state.ornamentsList.reduce(
          (acc, current) => {
            const {grossWeightInGrams} = current;
            const ornamentToUse =
              current.id === action.payload.ornamentId
                ? action.payload.values
                : current;
            const {
              newImpurityWeightInGrams,
              newOtherImpurityWeight,
              newStoneWeightInGrams,
              newFinenessVal,
            } = ornamentToUse;
            const ornamentsLiveRate = state.ornamentsFinenessConfigList.find(
              (item) => item.fineness === newFinenessVal,
            )?.liveGoldRate;
            const ornamentImpurityWeight =
              Number(newImpurityWeightInGrams) +
              Number(newOtherImpurityWeight) +
              Number(newStoneWeightInGrams);
            const ornamentNetWeight =
              grossWeightInGrams - ornamentImpurityWeight;
            const originalNetWeight =
              grossWeightInGrams -
              current.impurityWeightInGrams -
              current.stoneWeightInGrams -
              current.otherImpurityWeight;
            const newCheckerGoldValue =
              acc.checkerGoldValue +
              Number(ornamentsLiveRate) * ornamentNetWeight;
            const newCheckerGrossWeight =
              acc.totalCheckerGrossWeight + grossWeightInGrams;
            const newCheckerImpurityWeight =
              acc.totalCheckerImpurityWeight + ornamentImpurityWeight;
            const newCheckerNetWeight =
              acc.totalCheckerNetWeight +
              grossWeightInGrams -
              ornamentImpurityWeight;
            const newLmGoldValue =
              acc.lmGoldValue + originalNetWeight * current.valuationPrice;
            return {
              ...acc,
              checkerGoldValue: newCheckerGoldValue,
              lmGoldValue: newLmGoldValue,
              maxGoldAmountWithoutPL: newCheckerGoldValue * 0.75,
              totalCheckerGrossWeight: newCheckerGrossWeight,
              totalCheckerNetWeight: newCheckerNetWeight,
              totalCheckerImpurityWeight: newCheckerImpurityWeight,
            };
          },
          {
            checkerGoldValue: 0,
            lmGoldValue: 0,
            maxGoldAmountWithoutPL: 0,
            totalCheckerGrossWeight: 0,
            totalCheckerNetWeight: 0,
            totalCheckerImpurityWeight: 0,
          },
        );
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(ornamentsActions.getDisbursedJobsOrnamentsList, (state) => {
        state.loading.getDisbursedJobsOrnamentsList = true;
        state.errors.getDisbursedJobsOrnamentsList =
          initialState.errors.getDisbursedJobsOrnamentsList;
      })
      .addCase(
        ornamentsActions.getDisbursedJobsOrnamentsListSuccess,
        (state, action) => {
          state.disbursedJobsOrnamentsById = {};
          action.payload.response.forEach(
            (disbursedOrnament: GetDisbursedJobsOrnaments) => {
              state.disbursedJobsOrnamentsById[
                disbursedOrnament.id
              ] = disbursedOrnament;
            },
          );
          state.loading.getDisbursedJobsOrnamentsList = false;
          state.errors.getDisbursedJobsOrnamentsList =
            initialState.errors.getDisbursedJobsOrnamentsList;
        },
      )
      .addCase(
        ornamentsActions.getDisbursedJobsOrnamentsListFailure,
        (state, action) => {
          state.loading.getDisbursedJobsOrnamentsList = false;
          state.errors.getDisbursedJobsOrnamentsList = action.payload.error;
        },
      )
      .addCase(ornamentsActions.getOrnamentsNamesList, (state) => {
        state.loading.getOrnamentsNamesList = true;
        state.errors.getOrnamentsNamesList =
          initialState.errors.getOrnamentsNamesList;
      })
      .addCase(
        ornamentsActions.getOrnamentsNamesListSuccess,
        (state, action) => {
          state.ornamentsNamesList = action.payload.response;
          state.loading.getOrnamentsNamesList = false;
          state.errors.getOrnamentsNamesList =
            initialState.errors.getOrnamentsNamesList;

          if (state.selectedOrnamentId === null) {
            state.selectedOrnamentId = state.ornamentsNamesList[0]?.id;
          }
        },
      )
      .addCase(
        ornamentsActions.getOrnamentsNamesListFailure,
        (state, action) => {
          state.loading.getOrnamentsNamesList = false;
          state.errors.getOrnamentsNamesList = action.payload.error;
          state.selectedOrnamentId = null;
        },
      )
      .addCase(ornamentsActions.getSelectedOrnamentDetails, (state) => {
        state.loading.getSelectedOrnamentDetails = true;
        state.errors.getSelectedOrnamentDetails =
          initialState.errors.getSelectedOrnamentDetails;
      })
      .addCase(
        ornamentsActions.getSelectedOrnamentDetailsSuccess,
        (state, action) => {
          state.selectedOrnamentDetails = action.payload.response;
          state.loading.getSelectedOrnamentDetails = false;
          state.errors.getSelectedOrnamentDetails =
            initialState.errors.getSelectedOrnamentDetails;
        },
      )
      .addCase(
        ornamentsActions.getSelectedOrnamentDetailsFailure,
        (state, action) => {
          state.loading.getSelectedOrnamentDetails = false;
          state.errors.getSelectedOrnamentDetails = action.payload.error;
        },
      )
      .addCase(ornamentsActions.getOrnamentsList, (state) => {
        state.loading.getOrnamentsList = true;
        state.errors.getOrnamentsList = initialState.errors.getOrnamentsList;
      })
      .addCase(ornamentsActions.getOrnamentsListSuccess, (state, action) => {
        state.ornamentsList = action.payload.response;
        state.loading.getOrnamentsList = false;
        state.errors.getOrnamentsList = initialState.errors.getOrnamentsList;
      })
      .addCase(ornamentsActions.getOrnamentsListFailure, (state, action) => {
        state.loading.getOrnamentsList = false;
        state.errors.getOrnamentsList = action.payload.error;
      })
      .addCase(ornamentsActions.getOrnamentsFinenessConfig, (state) => {
        state.loading.getOrnamentsFinenessConfig = true;
        state.errors.getOrnamentsFinenessConfig =
          initialState.errors.getOrnamentsFinenessConfig;
      })
      .addCase(
        ornamentsActions.getOrnamentsFinenessConfigSuccess,
        (state, action) => {
          state.ornamentsFinenessConfigList = action.payload.response;
          state.loading.getOrnamentsFinenessConfig = false;
          state.errors.getOrnamentsFinenessConfig =
            initialState.errors.getOrnamentsFinenessConfig;
        },
      )
      .addCase(
        ornamentsActions.getOrnamentsFinenessConfigFailure,
        (state, action) => {
          state.loading.getOrnamentsFinenessConfig = false;
          state.errors.getOrnamentsFinenessConfig = action.payload.error;
        },
      )
      .addCase(ornamentsActions.getOrnamentsPacket, (state) => {
        state.loading.getOrnamentsPacket = true;
        state.errors.getOrnamentsPacket =
          initialState.errors.getOrnamentsPacket;
      })
      .addCase(ornamentsActions.getOrnamentsPacketSuccess, (state, action) => {
        state.ornamentsPacket = action.payload.response;
        state.loading.getOrnamentsPacket = false;
        state.errors.getOrnamentsPacket =
          initialState.errors.getOrnamentsPacket;
      })
      .addCase(ornamentsActions.getOrnamentsPacketFailure, (state, action) => {
        state.loading.getOrnamentsPacket = false;
        state.errors.getOrnamentsPacket = action.payload.error;
      })
      .addCase(ornamentsActions.updateOrnamentDetails, (state) => {
        state.loading.updateOrnamentDetails = true;
        state.errors.updateOrnamentDetails =
          initialState.errors.updateOrnamentDetails;
      })
      .addCase(
        ornamentsActions.updateOrnamentDetailsSuccess,
        (state) => {
          state.loading.updateOrnamentDetails = false;
          state.errors.updateOrnamentDetails =
            initialState.errors.updateOrnamentDetails;
          const index = state.ornamentsNamesList.findIndex(
            (item) => item.id === state.selectedOrnamentId,
          );
          if (index !== -1) {
            const nextOrnamentItemIndex =
              (index + 1) % state.ornamentsNamesList.length;

            state.selectedOrnamentId =
              state.ornamentsNamesList[nextOrnamentItemIndex].id;
          }
        },
      )
      .addCase(
        ornamentsActions.updateOrnamentDetailsFailure,
        (state, action) => {
          state.loading.updateOrnamentDetails = false;
          state.errors.updateOrnamentDetails = action.payload.error;
        },
      )
      .addCase(ornamentsActions.downloadOrnamentsCsv, (state) => {
        state.loading.downloadOrnamentsCsv = true;
        state.errors.downloadOrnamentsCsv =
          initialState.errors.downloadOrnamentsCsv;
      })
      .addCase(ornamentsActions.downloadOrnamentsCsvSuccess, (state) => {
        state.loading.downloadOrnamentsCsv = false;
      })
      .addCase(
        ornamentsActions.downloadOrnamentsCsvFailure,
        (state, action) => {
          state.loading.downloadOrnamentsCsv = false;
          state.errors.downloadOrnamentsCsv = action.payload.error;
        },
      ),
});

const {
  resetOrnamentsList,
  setSelectedOrnamentId,
  setOrnamentFormValues,
} = OrnamentsSlice.actions;

export {resetOrnamentsList, setSelectedOrnamentId, setOrnamentFormValues};

export default OrnamentsSlice.reducer;
