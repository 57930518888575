import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
}

export const validateGoldLoanAmount = async ({crId}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    VALIDATE_GOLD_LOAN_AMOUNT: {
      URL: "/business/cr/:crId/validate-gold-loan-amount",
      METHOD: "POST",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.VALIDATE_GOLD_LOAN_AMOUNT,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default validateGoldLoanAmount;
