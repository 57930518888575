import api from "./api";
import log from "./log";
import BugsnagNotify from "./bugsnag";

export interface GoldInfraServices {
  api: typeof api;
  log: typeof log;
  BugsnagNotify: typeof BugsnagNotify;
}

const services: GoldInfraServices = {
  api,
  log,
  BugsnagNotify,
};
export default services;
