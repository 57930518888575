import React from "react";

const AuctionDone = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#EF5350" />
      <path
        d="M9.69278 5.3845L6.30809 2L5.69278 2.61572L5.84639 3.07696L3.10748 5.81528L2.61575 5.69258L2 6.30786L5.38468 9.69236L6.00043 9.07707L5.87728 8.5845L6.92341 7.53843L9.38468 10L10 9.38472L7.53829 6.92314L8.61619 5.84661L9.07746 6.00065L9.69278 5.3845Z"
        fill="#FEEBEE"
      />
    </svg>
  );
};

const AuctionDoneOutlined = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3856 6.769L8.61619 0L7.38555 1.23144L7.69278 2.15393L2.21497 7.63057L1.23151 7.38515L0 8.61572L6.76936 15.3847L8.00087 14.1541L7.75457 13.169L9.84682 11.0769L14.7694 16L16 14.7694L11.0766 9.84629L13.2324 7.69323L14.1549 8.00131L15.3856 6.769Z"
        fill="#EF5350"
      />
    </svg>
  );
};

export {AuctionDone, AuctionDoneOutlined};
