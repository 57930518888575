import React from "react";
import {Box, Chip, Typography, Grid, Stack} from "@mui/material";
import {LenderApprovalBreach} from "app/models/activeLoans/activeLoans.model";
import {
  PersonOutlined,
  CallOutlined,
  PinDropOutlined,
} from "@mui/icons-material";
import {isNil} from "lodash";
import styled from "@emotion/styled";

interface LenderApprovalDetailsProps {
  approvalDetails: LenderApprovalBreach;
  index: number;
  isRenewRebookFlow?: boolean;
}

const LenderApprovalTypoWrapper = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
const LenderApprovalDetails = ({
  approvalDetails,
  index,
  isRenewRebookFlow,
}: LenderApprovalDetailsProps) => {
  const {
    breachType,
    lenderApprovalStatusEnum,
    pocDetails,
    remark,
    lenderApprovalStatus,
  } = approvalDetails ?? {};
  const {
    primaryPocName,
    primaryPocPhone,
    secondaryPocName,
    secondaryPocPhone,
    branchName,
  } = pocDetails ?? {};
  return (
    <Box
      mt={2}
      border="1px solid #F3E5B6"
      borderRadius={1}
      padding="24px"
      width={!isRenewRebookFlow ? "535px" : "100%"}
      maxHeight="350px"
      bgcolor="#FBF8EE"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            fontWeight={500}
            fontSize="14px"
            lineHeight="24px"
            key={index}
          >
            {breachType}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Chip
            size="small"
            color={
              lenderApprovalStatusEnum === "APPROVED"
                ? "success"
                : lenderApprovalStatusEnum === "REJECTED"
                ? "error"
                : "warning"
            }
            label={lenderApprovalStatus}
          />
        </Grid>
        {!isNil(pocDetails) && (
          <>
            <Grid item xs={isRenewRebookFlow ? 3 : 6}>
              <LenderApprovalTypoWrapper variant="body2" color="text.secondary">
                Primary Point of contact:
              </LenderApprovalTypoWrapper>

              {!isNil(branchName) && (
                <Stack mt={1} spacing={1} direction="row">
                  <PinDropOutlined />
                  <LenderApprovalTypoWrapper variant="body2">
                    {branchName}
                  </LenderApprovalTypoWrapper>
                </Stack>
              )}

              <Stack mt={1} spacing={1} direction="row">
                <PersonOutlined />
                <LenderApprovalTypoWrapper variant="body2">
                  {primaryPocName}
                </LenderApprovalTypoWrapper>
              </Stack>
              <Stack mt={1} spacing={1} direction="row">
                <CallOutlined />
                <LenderApprovalTypoWrapper variant="body2">
                  {primaryPocPhone}
                </LenderApprovalTypoWrapper>
              </Stack>
            </Grid>
            {!isNil(secondaryPocName) && (
              <Grid item xs={isRenewRebookFlow ? 3 : 6}>
                <LenderApprovalTypoWrapper
                  variant="body2"
                  color="text.secondary"
                >
                  Secondary Point of contact:
                </LenderApprovalTypoWrapper>

                <Stack mt={1} spacing={1} direction="row">
                  <PersonOutlined />
                  <LenderApprovalTypoWrapper variant="body2">
                    {secondaryPocName}
                  </LenderApprovalTypoWrapper>
                </Stack>
                <Stack mt={1} spacing={1} direction="row">
                  <CallOutlined />
                  <LenderApprovalTypoWrapper variant="body2">
                    {secondaryPocPhone}
                  </LenderApprovalTypoWrapper>
                </Stack>
              </Grid>
            )}
          </>
        )}

        {lenderApprovalStatusEnum === "REJECTED" && (
          <Grid mt={2} item xs={isRenewRebookFlow ? 3 : 6}>
            <Typography
              color="text.secondary"
              variant="body1"
              fontWeight={500}
              fontSize="14px"
              lineHeight="24px"
            >
              Remarks
            </Typography>
            <Box
              bgcolor="#ffffff"
              width="486px"
              height="100px"
              border="1px solid #0000001F"
              p={1}
            >
              <LenderApprovalTypoWrapper variant="body2">
                {remark}
              </LenderApprovalTypoWrapper>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LenderApprovalDetails;
