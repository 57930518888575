import {
  CheckCircleRounded,
  ErrorOutlineRounded,
  PhoneCallbackRounded, SettingsEthernetRounded,
  WatchLaterRounded,
} from "@mui/icons-material";

export const LEAD_CONFIRMATION_STATUS = [
  {
    name: "Callback",
    value: "SCHEDULER_CALLBACK",
    icon: PhoneCallbackRounded,
  },
  {
    name: "Unable To Contact",
    value: "SCHEDULER_UNABLE_TO_CONTACT",
    icon: ErrorOutlineRounded,
  },
  {
    name: "Scheduling",
    value: "SCHEDULING",
    icon: WatchLaterRounded,
  },
  {
    name: "Queued",
    value: "SCHEDULED",
    icon: WatchLaterRounded,
  },
  {
    name: "Branch Walkin",
    value: "BRANCH_WALKIN",
    icon: SettingsEthernetRounded,
  },
];

export const OPEN_CR_CONFIRMATION_STATUS = [
  {
    name: "Pending",
    value: "PENDING",
    icon: WatchLaterRounded,
  },
  {
    name: "Callback",
    value: "CALLBACK",
    icon: PhoneCallbackRounded,
  },
  {
    name: "Unable To Contact",
    value: "UNABLE_TO_CONTACT",
    icon: ErrorOutlineRounded,
  },
  {
    name: "Confirmed",
    value: "CONFIRMED",
    icon: CheckCircleRounded,
  },
];

export const UNQUALIFIED_STATUS = {
  name: "Unqualified",
  value: "UNQUALIFIED",
};
export const LM_AVAILABILITY_TIME_SLOTS = {
  MIN: "09:00",
  MAX: "22:00",
};

export const LM_USER_API_ROLES = ["LOAN_MANAGER", "LOAN_MANAGER_LEAD"];
export const LM_USER_LM_AVAILABILITY_API_ROLES = ["LOAN_MANAGER"];
export const AVAILABLE_AGENT_API_ROLES = ["LOAN_MANAGER", "LOAN_MANAGER_LEAD"];