import styled from "@emotion/styled";

const InputWrapper = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export default InputWrapper;
