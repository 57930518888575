import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources, QueryParams} from "app/typings/api/goldApi.types";
import {
  InternalUserApiInterface,
  LmUserCityPayload,
  UserIdPayload,
  UserStatusPayload,
  UserOrganizationRolePayload,
} from "./types";

const API_ENPOINTS: ApiResources = {
  GET_ALL_INTERNAL_USERS: {
    URL: "/admin/internal-user-list",
    METHOD: "GET",
  },
  GET_USERS_ROLES: {
    URL: "/admin/internal-user/:userId/roles",
    METHOD: "GET",
  },
  GET_ALL_ROLES: {
    URL: "/admin/internal-user/all-roles",
    METHOD: "GET",
  },
  UPDATE_USER_ROLES: {
    URL: "/admin/internal-user/:userId/update-roles",
    METHOD: "PUT",
  },
  CHANGE_LM_USER_STATUS: {
    URL: "/admin/business/lm-user/:userId/change-status/:status",
    METHOD: "PUT",
  },
  GET_ALL_LM_CITIES: {
    URL: "/admin/internal-user/all-city",
    METHOD: "GET",
  },
  GET_USER_LM_CITY: {
    URL: "/admin/lm-user/:userId/city-list",
    METHOD: "GET",
  },
  CHANGE_LM_USER_CITY: {
    URL: "/admin/lm-user/:userId/change-city",
    METHOD: "GET",
  },
  GET_USER_TYPES: {
    URL: "/admin/internal-user/all-auth-user-types",
    METHOD: "GET",
  },
  CHANGE_ORGANIZATION_ROLE: {
    URL: "/admin/internal-user/:userId/update-org-roles",
    METHOD: "PUT",
  },
};

const internalUserApi: InternalUserApiInterface = {
  getAllInternalUser: async (payload: {queryParams: QueryParams}) => {
    const {queryParams} = payload;
    return await goldApiService({
      resource: API_ENPOINTS.GET_ALL_INTERNAL_USERS,
      options: {queryParams},
    });
  },
  getAllRoles: async () => {
    return await goldApiService({
      resource: API_ENPOINTS.GET_ALL_ROLES,
    });
  },
  updateUserRole: async ({userId, data}: {userId: number; data: any}) => {
    return await goldApiService({
      resource: API_ENPOINTS.UPDATE_USER_ROLES,
      options: {pathVars: {userId}, data},
    });
  },
  getUserRole: async ({userId}: UserIdPayload) => {
    return await goldApiService({
      resource: API_ENPOINTS.GET_USERS_ROLES,
      options: {pathVars: {userId}},
    });
  },
  getAllCities: async () => {
    return await goldApiService({
      resource: API_ENPOINTS.GET_ALL_LM_CITIES,
    });
  },
  getLmUserCities: async ({userId}: UserIdPayload) => {
    return await goldApiService({
      resource: API_ENPOINTS.GET_USER_LM_CITY,
      options: {pathVars: {userId}},
    });
  },
  changeLmUserCity: async ({userId, cityIdList}: LmUserCityPayload) => {
    return await goldApiService({
      resource: API_ENPOINTS.CHANGE_LM_USER_CITY,
      options: {
        pathVars: {userId},
        queryParams: {cityIdList},
      },
    });
  },
  changeLmUserStatus: async ({userId, status}: UserStatusPayload) => {
    return await goldApiService({
      resource: API_ENPOINTS.CHANGE_LM_USER_STATUS,
      options: {
        pathVars: {userId, status},
      },
    });
  },
  getUserTypes: async () => {
    return await goldApiService({
      resource: API_ENPOINTS.GET_USER_TYPES,
    });
  },
  changeOrganizationRole: async ({
    userId,
    fwcOrganizationRoleEnum,
  }: UserOrganizationRolePayload) => {
    return await goldApiService({
      resource: API_ENPOINTS.CHANGE_ORGANIZATION_ROLE,
      options: {
        pathVars: {userId},
        queryParams: {
          fwcOrganizationRoleEnum,
        },
      },
    });
  },
};

export default internalUserApi;
