import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  START_KYC: {
    URL: "/business/lender/cr/:crId/kyc-lender",
    METHOD: "POST",
  },
  KYC_STATUS: {
    URL: "/business/lender/cr/:crId/kyc-status",
    METHOD: "GET",
  },
};

export const StartKyc = async ({crId, documentType}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.START_KYC,
    options: {
      pathVars: {crId},
      queryParams:{documentType},
    },
  });
  return response;
};

export const KycStatus = async ({crId}) => {
    const response = await goldApiService({
      resource: API_ENDPOINTS.KYC_STATUS,
      options: {
        pathVars: {crId},
      },
    });
    return response;
  };


