import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/admin/business/cr/:crId/job/:crJobId/personal-loan-create",
  METHOD: "POST",
};
export const createPersonalLoan = async ({crJobId, crId, fileData}) => {
  const data = fileData;
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    options: {
      data,
      pathVars: {
        crJobId,
        crId,
      },
    },
  });
  return response;
};

export default createPersonalLoan;
