import React from "react";
import {Box} from "@mui/material";
import {useParams, useNavigate, Outlet, useLocation} from "react-router-dom";
import NavBackIcon from "./icons/NavBackIcon";
import {IGButton} from "app/components";

interface locationState {
  from: string;
}

const CPWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as locationState;
  const {userId, loanId} = useParams();

  const onClickBackButton = () => {
    navigate("/gold-loan/customer-profiles");
  };

  return (
    <>
      {userId && loanId && (
        <Box display="flex" p={2} gap={2}>
        <Box
          onClick={onClickBackButton}
          sx={{
            cursor: "pointer",
          }}
        >
          <NavBackIcon />
        </Box>
        {state?.from === "service-desk" && (
          <IGButton
            variant="outlined"
            color="golden"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go to service desk
          </IGButton>
        )}
        </Box>
      )}

      <Outlet />
    </>
  );
};

export default CPWrapper;
