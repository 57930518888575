import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number
  lenderName: string
}

export const GetLenderExposure = async ({crId, lenderName}: Props) => {
  const API_ENDPOINTS : ApiResources = {
    GET_LENDER_EXPOSURE : {
    URL: "/business/lender/cr/:crId/exposure-detail",
    METHOD: "GET",
  }};

  return await goldApiService({
    resource: API_ENDPOINTS.GET_LENDER_EXPOSURE,
    options: {
      pathVars:{
        crId,
      },
      queryParams:{
        lenderName,
      },
    },
  });
};
