import {RootState} from "app/store/store";

const creditDetailsSelectors = {
  getCreditScoreDetails: (state: RootState) =>
    state.creditDetails.creditScoreDetails,
  getCreditConsentLink: (state: RootState) =>
    state.creditDetails.creditConsentLink,
  getCreditReportHtml: (state: RootState) =>
    state.creditDetails.takeovercreditReport,

  getCreditSessionStatus: (state: RootState) =>
    state.creditDetails.creditSessionStatus,
  getOpenModals: (state: RootState) => state.creditDetails.openModals,
  getLoading: (state: RootState) => state.creditDetails.loading,
  getErrors: (state: RootState) => state.creditDetails.errors,
};

export default creditDetailsSelectors;
