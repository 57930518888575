import React from "react";
import {Route, Outlet, Navigate} from "react-router-dom";
import BannedDevices from "app/pages/home/User/BannedDevices";
import BannedSellUsers from "app/pages/home/User/BannedSellUsers";
import Users from "app/pages/home/User/Users";
import UserDetails from "app/pages/home/User/User";
import WhitelistUsers from "app/pages/home/User/WhitelistUsers";
import routeNames from "./routeNames";

const UserRoutes = () => {
  return (
    <>
      <Route path={routeNames.users} element={<Outlet />}>
        <Route index element={<Navigate to="all-users" />} />
        <Route
          path={`${routeNames.allUsers}/${routeNames.userDetails}`}
          element={<UserDetails dialog header />}
        />
        <Route
          path={routeNames.allUsers}
          element={<Users header dialog filters table actions />}
        />
        <Route
          path={routeNames.bannedUsers}
          element={<BannedSellUsers header table dialog actions />}
        />
        <Route
          path={routeNames.bannedDevices}
          element={<BannedDevices header table dialog actions />}
        />
        <Route path={routeNames.whitelistUser} element={<WhitelistUsers />} />
      </Route>
    </>
  );
};

export default UserRoutes;
