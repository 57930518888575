import styled from "@emotion/styled";
import {Box, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {getCurrentTime, getValidTime} from "_metronic/utils/moment";
import {
  IGButton,
  IGDatePicker,
  IGFileUpload,
  IGLoading,
  IGSection,
  IGSectionContent,
  IGSectionHeader,
} from "app/components";
import IGKeyValue from "app/components/IGLabelValue";
import React, {useCallback, useMemo} from "react";
import BackgroundImage from "./Frame.jpg";
import {UploadRounded, WatchLater} from "@mui/icons-material";
import {Moment} from "moment";
import npaCasesApi from "app/infra/services/api/lenderPortal/npaCases/npaCases.api";
import {useSnackBar} from "app/store/hooks";
import {MomentInput} from "moment-timezone";

const StyledPaper = styled(Paper)`
  width: 90%;
  padding: 1.5rem;
`;

const StyledBox = styled(Box)<{
  marginBottom?: string;
}>`
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: ${({marginBottom}) =>
    marginBottom ? `${marginBottom}` : "2rem"};
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
`;

const NPACases = () => {
  const {showSuccess, showError} = useSnackBar();
  const [report, setReport] = React.useState<FileList | any[]>([]);
  const [sheet, setSheet] = React.useState<FileList | any[]>([]);
  const [cutOffDate, setCutOffDate] = React.useState<Moment | null>(null);
  const [auctionDate, setAuctionDate] = React.useState<Moment | null>(
    null,
  );
  const [auctionDetails, setAuctionDetails] = React.useState<any>({
    lastCutoffDate: null,
    lastAuctionDate: null,
    lastDailyNpaReportUploadTimestamp: null,
    lastAuctionSheetUploadTimestamp: null,
    lastConfigUpdateTimestamp: null,
    lastMonthCutoffDate: null,
    lastMonthAuctionDate: null,
    thisMonthCutoffDate: null,
    thisMonthAuctionDate: null,
  });
  const [loading, setLoading] = React.useState<any>({
    auctionDetails: false,
    uploadReport: false,
    uploadSheet: false,
    updateDate: false,
  });
  const [errors, setErrors] = React.useState<{
    updateCutOffDate: string;
    updateAuctionDate: string;
  }>({
    updateCutOffDate: "",
    updateAuctionDate: "",
  });

  const isModifyDisabled = useMemo(() => {
    if (
      auctionDetails.thisMonthAuctionDate &&
      auctionDetails.thisMonthCutoffDate
    ) {
      return (
        getValidTime(auctionDetails.thisMonthAuctionDate).isSame(
          auctionDate as MomentInput,
          "d",
        ) &&
        getValidTime(auctionDetails.thisMonthCutoffDate).isSame(
          cutOffDate as MomentInput,
          "d",
        )
      );
    }
    return false;
  }, [auctionDate, cutOffDate, auctionDetails]);

  const initialize = useCallback(async () => {
    setLoading((prevState: any) => ({
      ...prevState,
      auctionDetails: true,
    }));

    const {response} = await npaCasesApi.getAuctionDetails();

    if (response) {
      setAuctionDetails(response);
      if (response.thisMonthAuctionDate) {
        setAuctionDate(getValidTime(response.thisMonthAuctionDate));
      }
      if (response.thisMonthCutoffDate) {
        setCutOffDate(getValidTime(response.thisMonthCutoffDate));
      }
    }

    setLoading((prevState: any) => ({
      ...prevState,
      auctionDetails: false,
    }));
  }, []);

  const uploadReport = useCallback(async () => {
    setLoading((prevState: any) => ({
      ...prevState,
      uploadReport: true,
    }));

    const formData = new FormData();
    formData.append("file", report[0]);
    const {error} = await npaCasesApi.uploadDailyNpaReport(formData);

    if (!error) {
      showSuccess("Report uploaded successfully");
      setReport([]);
      initialize();
    } else {
      showError(error.message);
    }

    setLoading((prevState: any) => ({
      ...prevState,
      uploadReport: false,
    }));
  }, [report, showSuccess, showError, initialize]);

  const uploadSheet = useCallback(async () => {
    setLoading((prevState: any) => ({
      ...prevState,
      uploadSheet: true,
    }));

    const formData = new FormData();
    formData.append("file", sheet[0]);
    const {error} = await npaCasesApi.uploadAuctionSheet(formData);

    if (!error) {
      showSuccess("Sheet uploaded successfully");
      setSheet([]);
      initialize();
    } else {
      showError(error.message);
    }

    setLoading((prevState: any) => ({
      ...prevState,
      uploadSheet: false,
    }));
  }, [sheet, showSuccess, initialize, showError]);

  const updateDates = useCallback(async () => {
    setErrors({
      updateCutOffDate: "",
      updateAuctionDate: "",
    });
    setLoading((prevState: any) => ({
      ...prevState,
      updateDate: true,
    }));
    if (cutOffDate && !getValidTime(auctionDetails.thisMonthCutoffDate).isSame(
      cutOffDate as MomentInput,
      "d",
    )) {
      const {error: cutOffError} = await npaCasesApi.updateCutoffDate({
        nextCutoffDate: cutOffDate.valueOf(),
      });

      if (cutOffError) {
        showError(cutOffError.message);
        setErrors((prev) => ({
          ...prev,
          updateCutOffDate: cutOffError.message,
        }));
      } else {
        showSuccess("Cut-off date updated successfully");
      }
    }

    if (
      auctionDate &&
      !getValidTime(auctionDetails.thisMonthAuctionDate).isSame(
        auctionDate as MomentInput,
        "d",
      )
    ) {
      const {error: auctionError} = await npaCasesApi.updateAuctionDate({
        nextAuctionDate: auctionDate.valueOf(),
      });

      if (auctionError) {
        showError(auctionError.message);
        setErrors((prev) => ({
          ...prev,
          updateAuctionDate: auctionError.message,
        }));
      } else {
        showSuccess("Auction date updated successfully");
      }
    }

    setLoading((prevState: any) => ({
      ...prevState,
      updateDate: false,
    }));
    initialize();
  }, [
    cutOffDate,
    auctionDate,
    showError,
    showSuccess,
    initialize,
    auctionDetails,
  ]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <IGSection>
      <IGSectionHeader>
        <Typography variant="h5">NPA Cases</Typography>
      </IGSectionHeader>
      <IGSectionContent
        containerStyle={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "500px",
          backgroundPosition: "bottom right",
        }}
      >
        {loading.auctionDetails ? (
          <IGLoading height="50vh" />
        ) : (
          <Grid container gap={2} rowGap={4}>
            <Grid item xs={12} md={4}>
              <StyledPaper elevation={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="h6">DAILY REPORT</Typography>
                  {auctionDetails.lastDailyNpaReportUploadTimestamp &&
                    getValidTime(
                      auctionDetails.lastDailyNpaReportUploadTimestamp,
                    ).isBefore(getCurrentTime().startOf("D")) && (
                      <Typography variant="body1">
                        <WatchLater color="warning" /> Pending
                      </Typography>
                    )}
                </Box>
                <Typography variant="caption" color="text.secondary">
                  Last uploaded on:{" "}
                  {auctionDetails.lastDailyNpaReportUploadTimestamp
                    ? getValidTime(
                        auctionDetails.lastDailyNpaReportUploadTimestamp,
                      ).format("DD MMM, YY, hh:mm a")
                    : "N/A"}
                </Typography>
                <Box my={2}>
                  <IGFileUpload
                    value={report as FileList}
                    onChange={(fileList) => {
                      setReport(fileList);
                    }}
                    multiple={false}
                    draggable={false}
                    buttonText="Add File"
                    text=""
                    inputProps={{
                      accept: ".csv",
                    }}
                  />
                </Box>
                <IGButton
                  sx={{mt: 2}}
                  fullWidth
                  disabled={report.length === 0}
                  onClick={uploadReport}
                  loading={loading.uploadReport}
                  startIcon={<UploadRounded />}
                >
                  Upload Latest Report
                </IGButton>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledPaper elevation={2}>
                <Typography variant="h6">AUCTION SHEET</Typography>
                <Typography variant="caption" color="text.secondary">
                  Last uploaded on:{" "}
                  {auctionDetails.lastAuctionSheetUploadTimestamp
                    ? getValidTime(
                        auctionDetails.lastAuctionSheetUploadTimestamp,
                      ).format("DD MMM, YY, hh:mm a")
                    : "N/A"}
                </Typography>
                <Box my={2}>
                  <IGFileUpload
                    value={sheet as FileList}
                    onChange={(fileList) => {
                      setSheet(fileList);
                    }}
                    multiple={false}
                    draggable={false}
                    buttonText="Add File"
                    text=""
                    inputProps={{
                      accept: ".csv",
                    }}
                  />
                </Box>
                <IGButton
                  sx={{mt: 2}}
                  fullWidth
                  onClick={uploadSheet}
                  disabled={sheet.length === 0}
                  loading={loading.uploadSheet}
                  startIcon={<UploadRounded />}
                >
                  Upload Latest Sheet
                </IGButton>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={8}>
              <StyledPaper elevation={2}>
                <Typography variant="h6">CUT-OFF & AUCTION DATE</Typography>
                <Typography variant="caption" color="text.secondary">
                  Last uploaded on:{" "}
                  {auctionDetails.lastConfigUpdateTimestamp
                    ? getValidTime(
                        auctionDetails.lastConfigUpdateTimestamp,
                      ).format("DD MMM, YY, hh:mm a")
                    : "N/A"}
                </Typography>
                <Box display="flex" gap={2}>
                  <Stack width="40%">
                    <StyledBox marginBottom="1rem">
                      <IGKeyValue
                        label="Last Month Cut-off Date"
                        value={
                          auctionDetails.lastMonthCutoffDate
                            ? getValidTime(
                                auctionDetails.lastMonthCutoffDate,
                              ).format("DD MMM, YY")
                            : "N/A"
                        }
                        {...{
                          labelContainerProps: {xs: 6},
                          valueContainerProps: {xs: 6},
                        }}
                      />
                    </StyledBox>
                    <StyledBox>
                      <IGKeyValue
                        label="Last Month Auction Date"
                        value={
                          auctionDetails.lastMonthAuctionDate
                            ? getValidTime(
                                auctionDetails.lastMonthAuctionDate,
                              ).format("DD MMM, YY")
                            : "N/A"
                        }
                        {...{
                          labelContainerProps: {xs: 6},
                          valueContainerProps: {xs: 6},
                        }}
                      />
                    </StyledBox>
                  </Stack>
                  <Divider orientation="vertical" flexItem />
                  <Stack width="50%">
                    <Box mt="1rem" p="1rem" display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        width="90%"
                      >
                        CURRENT CUT-OFF DATE
                      </Typography>
                      <IGDatePicker
                        name="cut-off-date"
                        label="Select Date"
                        minDate={
                          auctionDetails.thisMonthCutoffDate
                            ? getValidTime(auctionDetails.thisMonthCutoffDate).add(1, "d")
                            : getCurrentTime().startOf("D")
                        }
                        maxDate={getCurrentTime().endOf("M")}
                        value={cutOffDate}
                        onChange={(date) => {
                          setCutOffDate(date);
                        }}
                        error={Boolean(errors.updateCutOffDate)}
                      />
                    </Box>
                    {errors.updateCutOffDate && (
                      <Box display="flex" justifyContent="flex-end">
                        <Typography variant="caption" color="error">
                          {errors.updateCutOffDate}
                          </Typography>
                      </Box>
                    )}
                    <Box mt="1rem" p="1rem" display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        width="90%"
                      >
                        CURRENT AUCTION DATE
                      </Typography>
                      <IGDatePicker
                        name="auction-date"
                        label="Select Date"
                        minDate={getCurrentTime().startOf("D")}
                        value={auctionDate}
                        onChange={(date) => {
                          setAuctionDate(date);
                        }}
                        error={Boolean(errors.updateAuctionDate)}
                      />
                    </Box>
                    {errors.updateAuctionDate && (
                      <Box display="flex" justifyContent="flex-end">
                      <Typography variant="caption" color="error">
                        {errors.updateAuctionDate}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  <IGButton
                    sx={{mt: 2}}
                    onClick={() => updateDates()}
                    disabled={!cutOffDate && !auctionDate || isModifyDisabled}
                    loading={loading.updateDate}
                  >
                    {auctionDetails.thisMonthAuctionDate || auctionDetails.thisMonthCutoffDate ? "Modify" : "Save"}
                  </IGButton>
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        )}
      </IGSectionContent>
    </IGSection>
  );
};

export default NPACases;
