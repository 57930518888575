/**
 * Using Adaptors to convert data recieved from API into -
 * data that can be consumed by the application.
 *
 * Example: Converting AuthResponse(from API) to -
 *  AuthState(consists of tokens & User Model).
 *
 *  */
import User from "app/models/user";
import {forgotLastLocation} from "app/router/RouterHelpers";
import {
  AuthResponse,
  AuthResponseAdaptor as AuthResponseAdaptorType,
} from "app/infra/services/api/auth/types";

const AuthResponseAdaptor: AuthResponseAdaptorType = (
  authResponse: AuthResponse,
) => {
  const {response, error} = authResponse;

  const authState: Record<string, any> = {
    error: "",
    authToken: null,
    refreshToken: null,
    user: null,
  };

  if (error) {
    // For backwards compatibility
    clearAuthLocalStorage();

    authState.error = error.message;

    return authState;
  }

  if (response.otpId) {
    authState.otpId = response.otpId;
  }

  // Parse response only if it has accessToken
  if (response.accessToken) {
    const {accessToken, refreshToken, ...userData} = response;

    authState.authToken = accessToken;

    if (refreshToken) {
      authState.refreshToken = refreshToken;
      updateAuthLocalStorage({refreshToken, accessToken});
    }

    if (userData?.id) {
      // Create new user from Model
      const user = new (User as any)({
        ...userData,
      });

      authState.user = user;
      updateAuthLocalStorage({user});
    } else {
      updateAuthLocalStorage({accessToken});
    }
  } else {
    // For backwards compatibility
    clearAuthLocalStorage();
  }

  return authState;
};

// Util: function removing all auth related localStorage keys
export const clearAuthLocalStorage = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userRole");
};

// Util: function updating localStorage with new tokens
export const updateAuthLocalStorage = (data: any) => {
  if (data.accessToken) {
    localStorage.setItem("authToken", data.accessToken);
  }

  if (data.refreshToken) {
    localStorage.setItem("refreshToken", data.refreshToken);
  }

  if (data.user) {
    localStorage.setItem("id", data.user?.id);
    localStorage.setItem("name", data.user?.name);
    localStorage.setItem("email", data.user?.email);
    localStorage.setItem("userRole", data.user?.userRole);
  }
};

export default AuthResponseAdaptor;
