// New store with typescript:
import {configureStore} from "@reduxjs/toolkit";
import {createLogger} from "redux-logger";
import createSagaMiddleware from "redux-saga";
import services from "app/infra/services";
import {appReducer, rootReducer} from "./rootReducer";
import {rootSaga} from "./rootSaga";
import storage from "redux-persist/lib/storage";
import {persistStore, persistReducer} from "redux-persist";


const sagaMiddleware = createSagaMiddleware({
  context: {
    api: services.api,
    log: services.log,
    BugsnagNotify: services.BugsnagNotify,
  },
});

const logger = createLogger({
  collapsed: true,
  duration: true,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};


const persistReducerConfig = persistReducer(
  persistConfig,
  rootReducer as typeof appReducer,
);

const middlewares: any = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

// configureStore: automatically configure the Redux DevTools extension
// so that you can inspect the store while developing.
const store = configureStore({
  reducer: persistReducerConfig,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === "production" ? false : {
    shouldCatchErrors: true,
  },
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;

// Old Store:
// import { applyMiddleware, compose, createStore } from "redux";
// import createSagaMiddleware from "redux-saga";
// import { persistStore } from "redux-persist";

// import { rootReducer, rootSaga } from "./rootDuck";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(
//     rootReducer,
//     composeEnhancers(applyMiddleware(sagaMiddleware))
// );

// /**
//  * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
//  * @see https://github.com/rt2zz/redux-persist#persistor-object
//  */
// export const persistor = persistStore(store);

// sagaMiddleware.run(rootSaga);

// export default store;
