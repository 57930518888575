import React, {useEffect, useRef, useState, useCallback} from "react";

import Modal from "../../../../../../components/Modal";
import getNextJob from "../../../../../../../api/business/getNextJob";
import ReasonToRejectModal from "../CommonComponents/Modal/RequestApproval";
import styles from "../styles";

const UpdateToLm = ({
  otherRequestTimer,
  selectedPayload,
  getMileStoneOfCr,
}) => {
  const classes = styles();

  const [nextJobStates, setNextJobStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const [requestUpdateToLM, setRequestUpdateToLM] = useState(false);

  const counters = useRef(0);

  const getNextJobApi = useCallback(async () => {
    try {
      setNextJobStates({
        loading: true,
        error: false,
        payload: null,
      });
      const response = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode === 200) {
        setNextJobStates({
          loading: false,
          error: false,
          payload: response.payload,
          errorMessage: "",
        });
      } else {
        setNextJobStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: response.error,
        });
      }
    } catch (error) {
      setNextJobStates({
        loading: false,
        error: true,
        payload: null,
        errorMessage: "Something went wrong",
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    (async () => {
      if (counters.current === 0) {
        counters.current = 1;
        getNextJobApi();
      }
    })();
  }, [
    getNextJobApi,
    selectedPayload.id,
    selectedPayload.milestone,
    selectedPayload.type,
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      getNextJobApi();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getNextJobApi, otherRequestTimer]);

  const crJobId = nextJobStates.payload?.id;

  return (
    <div className={classes.buttonContainer}>
      <Modal
        open={requestUpdateToLM}
        setOpen={setRequestUpdateToLM}
        width="90%"
      >
        <ReasonToRejectModal
          {...{
            getMileStoneOfCr,
            getNextJobApi,
            crJobId,
            selectedPayload,
            setRejectModal: setRequestUpdateToLM,
          }}
        />
      </Modal>

      {crJobId && (
        <div className={classes.buttonContainer}>
          <div className={classes.buttonContainer}>
            <button
              onClick={() => {
                setRequestUpdateToLM(true);
              }}
              className={classes.ApproveButton}
            >
              SEND UPDATE TO LM
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateToLm;
