import styled from "@emotion/styled";
import StyledAppBar from "./StyledAppBar";
import StyledHeaderContainer from "./StyledHeaderContainer";
import StyledIconButton from "./StyledIconButton";
import StyledNotifProfileContainer from "./StyledNotifProfileContainer";

import {IGButton} from "app/components";

const StyledTabButton = styled(IGButton)`
  margin-right: ${props => props.theme.spacing(1)};
  margin-left: ${props => props.theme.spacing(1)};
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: ${props => props.active ? "rgba(206, 159, 41, 0.5)" : "white"};
  color: ${props => props.theme.palette.text.secondary};
`;

const StyledServerContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem 0.5rem 1rem;
  background: #000;
  color: #bfffa5;
  font-family: "Anonymous Pro", monospace;
`;

export {
  StyledAppBar,
  StyledHeaderContainer,
  StyledIconButton,
  StyledTabButton,
  StyledNotifProfileContainer,
  StyledServerContainer,
};