import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {useParams} from "react-router-dom";
import {loanActions, loanSelectors} from "app/store/loan";
import {Box, Divider, Grid} from "@mui/material";
import CustomerDetails from "./CustomerDetails";
import LoanDetails from "./LoanDetails";
import AccountDetails from "./AccountDetails";
import LoanSummary from "./LoanSummary";
import PaymentDetails from "./PaymentDetails";
import InstallmentScheduleDetails from "./InstallmentScheduleDetails";
import {IGLoading} from "app/components";

interface ProfilePageProps {
}

const LenderCustomerProfilePage: FC<ProfilePageProps> = () => {
  const dispatch = useAppDispatch();
  const {loanId} = useParams();
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLenderLoanDetails(state, Number(loanId)),
  );

  useEffect(() => {
    if (!loanId) return;
    if (!loanDetails) {
      dispatch(loanActions.getLenderLoanDetails({
        loanId: Number(loanId),
      }));
    }
  }, [dispatch, loanDetails, loanId]);

  if (!loanId || isNaN(Number(loanId))) {
    return (
      <IGLoading/>
    );
  }

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomerDetails/>
        </Grid>
        <Divider/>
        <Grid item xs={12}>
          <LoanDetails/>
        </Grid>
        <Grid item xs={12}>
          <AccountDetails/>
        </Grid>
        <Grid item xs={12}>
          <LoanSummary/>
        </Grid>
        <Grid item xs={12}>
          <PaymentDetails/>
        </Grid>
        <Grid item xs={12}>
          <InstallmentScheduleDetails/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LenderCustomerProfilePage;