import React, {FC, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import tippy from "tippy.js";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  AccessTimeRounded,
  AddCircleRounded,
  CheckCircleOutlineRounded,
  Info,
  RemoveCircleRounded,
} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanActions, loanSelectors} from "app/store/loan";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {getValidTime} from "_metronic/utils/moment";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {LoanInstallmentModel} from "app/models/loan.model";
import {isNil} from "lodash";
import {featureFlagSelectors} from "app/store/featureFlags";

interface InstallmentScheduleDetailsProps {}

const statusMapping: Record<string, any> = {
  PENDING: (
    <Chip
      color="info"
      label="Pending"
      icon={<AccessTimeRounded />}
      size="small"
    />
  ),
  PAID: (
    <Chip
      color="success"
      label="Paid"
      icon={<CheckCircleOutlineRounded />}
      size="small"
    />
  ),
  DEFAULT: "-",
};
const InstallmentScheduleDetails: FC<InstallmentScheduleDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const {t, tTableHeader} = useIGTranslation();
  const {loanId} = useParams<string>();

  const totalRowId = Math.random();
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );
  const loanInstallmentList = useAppSelector((state) =>
    loanSelectors.getLoanInstalmentList(state, Number(loanId)),
  );
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const {
    getLoanRepaymentSchedule: getLoanRepaymentScheduleLoading,
  } = useAppSelector(loanSelectors.getLoading);

  const [openRowId, setOpenRowId] = useState(0);

  const AmountBreakUpBox = ({
    row,
    field,
  }: {
    row: LoanInstallmentModel;
    field: keyof LoanInstallmentModel;
  }) => {
    return (
      <Box>
        <Typography
          fontWeight={row.status === "isTotal" ? "bold" : "normal"}
          variant="inherit"
        >
          {numberWithCurrencyAndComma(row[field])}
        </Typography>
        <Collapse in={row.instalmentNumber === openRowId}>
          <Stack
            py={1}
            px={2}
            direction="column"
            mt={1}
            justifyContent="space-between"
            bgcolor="#FFFBF1"
            border={1}
            borderRadius={1}
            gap={2}
            borderColor="rgba(0, 0, 0, 0.12)"
          >
            {row?.loanComponentInstallmentResponseDtoList?.map((item) => (
              <Box>
                <Typography variant="subtitle2" color="#64748B">
                  {item.loanType === "PERSONAL_LOAN" ? "PL" : "GL"}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {numberWithCurrencyAndComma(item[field])}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Collapse>
      </Box>
    );
  };

  const tableCols: IGTableColsInterface<LoanInstallmentModel> = [
    {
      headerName: t("CUSTOMER_PROFILE.INSTALMENT_NO"),
      field: "instalmentNumber",
      flex: 0,
      renderCell: ({value, row}) =>
        row.status === "isTotal" ? t("TOTAL") : value,
    },
    {
      headerName: "Due Date",
      field: "instalmentDueDate",
      flex: 0,
      renderCell: ({value, row}) =>
        row.status === "isTotal"
          ? "-"
          : getValidTime(value).format("DD/MM/YYYY"),
    },
    {
      headerName: t("DUE_AMOUNT"),
      field: "instalmentAmount",
      renderCell: ({row}) => (
        <AmountBreakUpBox row={row} field="instalmentAmount" />
      ),
    },
    {
      headerName: "Interest Due",
      field: "interestAmount",
      renderCell: ({row}) => (
        <AmountBreakUpBox row={row} field="interestAmount" />
      ),
    },
    {
      headerName: "Principal Due",
      field: "principleAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("PAID_AMOUNT"),
      field: "instalmentAmountPaid",
      renderCell: ({row}) => (
        <AmountBreakUpBox row={row} field="instalmentAmountPaid" />
      ),
    },
    {
      headerName: "Interest Amount Paid",
      field: "interestAmountPaid",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: "Principal Amount Paid",
      field: "principalAmountPaid",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("CUSTOMER_PROFILE.EFFECTIVE_RATE_TYPE", {
        type: loanDetails?.isGoldLoanTaken ? "GL" : "PL",
      }),
      field: "effectiveInterestRate",
      renderCell: ({value, row}) => (
        <Box
          id={`${row.instalmentNumber}-${value}`}
          display="flex"
          gap={1}
          alignItems="center"
          width="100%"
          onMouseEnter={(e) => {
            if (row.status !== "isTotal" && row.infoMessage) {
              tippy(e.currentTarget, {
                content: row.infoMessage,
                placement: "top",
                arrow: true,
              });
            }
          }}
        >
          <Typography>
            {row.status === "isTotal" ? "-" : `${value || 0} %`}
          </Typography>
          {row.status !== "isTotal" && row.infoMessage && (
            <Info color="primary" />
          )}
        </Box>
      ),
    },
    {
      headerName: t("DAYS"),
      field: "interestDays",
      flex: 0,
    },
    {
      headerName: t("STATUS"),
      field: "status",
      renderCell: ({value}) =>
        value === "isTotal"
          ? "-"
          : value in statusMapping
          ? statusMapping[value]
          : statusMapping.DEFAULT,
    },
    {
      headerName: t("ACTIONS"),
      field: "actions",
      renderCell: ({row}) =>
        row.status !== "isTotal" && (
          <IconButton
            onClick={() => {
              if (row.instalmentNumber === openRowId) {
                setOpenRowId(0);
              } else {
                setOpenRowId(row.instalmentNumber);
              }
            }}
          >
            {row.instalmentNumber === openRowId ? (
              <RemoveCircleRounded />
            ) : (
              <AddCircleRounded />
            )}
          </IconButton>
        ),
    },
  ];

  const loanInstallmentData = useMemo(() => {
    if (loanInstallmentList && loanInstallmentList.length !== 0) {
      const instalmentData = [...loanInstallmentList];
      instalmentData.push({
        instalmentNumber: totalRowId,
        status: "isTotal",
        instalmentAmount: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.instalmentAmount, 0),
        ),
        instalmentAmountPaid: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.instalmentAmountPaid, 0),
        ),
        interestAmount: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.interestAmount, 0),
        ),
        principleAmount: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.principleAmount, 0),
        ),
        principalAmountPaid: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.principalAmountPaid, 0),
        ),
        interestAmountPaid: numberWithCurrencyAndComma(
          instalmentData.reduce((acc, cv) => acc + cv.interestAmountPaid, 0),
        ),
      } as LoanInstallmentModel);

      return instalmentData;
    }
    return [];
  }, [loanInstallmentList, totalRowId]);

  useEffect(() => {
    if (!loanId || isNaN(Number(loanId))) return;
    dispatch(
      loanActions.getLoanRepaymentSchedule({
        loanId: Number(loanId),
      }),
    );
  }, [dispatch, loanId]);

  return (
    <IGTable<LoanInstallmentModel>
      hideFooter
      loading={getLoanRepaymentScheduleLoading}
      tableCols={tableCols}
      tableData={loanInstallmentData}
      selectedRowId={totalRowId}
      getRowId={(row) => row.instalmentNumber}
      getRowHeight={({id}) => {
        if (Number(id) === openRowId) return "auto";
        return 52;
      }}
      getCellClassName={({row}) => {
        if (row.instalmentNumber === openRowId) {
          return "row-open haha";
        }
        return "haha";
      }}
      sx={{
        ".Mui-selected": {
          fontWeight: "bold",
        },
        ".row-open": {
          alignItems: "flex-start",
          paddingTop: 1.25,
          paddingBottom: 1.25,
        },
        backgroundColor: "#fff",
        ".haha": {
          transition: "all 500ms ease-in",
        },
      }}
      components={{
        Header: (params) => (
          <Box {...params} p={2}>
            <Typography variant="h5">
              {tTableHeader("INSTALMENT_SCHEDULE")}
            </Typography>
          </Box>
        ),
      }}
      columnVisibilityModel={{
        actions:
          (loanDetails?.isPersonalLoanTaken && !isMonitoringCheckActive) ||
          false,
      }}
    />
  );
};

export default InstallmentScheduleDetails;
