import React, {FC} from "react";
import {Typography, CircularProgress} from "@mui/material";
import Container from "./styles/Container";

interface LoadingProps {
  height?: React.CSSProperties["height"]
  loadingMessage?: string
}

const IGLoading: FC<LoadingProps> = ({
  height,
  loadingMessage,
}: LoadingProps): JSX.Element => (
  <Container height={height}>
    {loadingMessage && (
      <Typography variant="h6" mb="2rem">{loadingMessage}</Typography>
    )}
    <CircularProgress color="primary" variant="indeterminate" />
  </Container>
);

IGLoading.defaultProps = {
  height: "100vh",
};

export default IGLoading;
