import {all, call, getContext, put, takeLatest} from "redux-saga/effects";
import customerProfileActions, {lenderCustomerProfileActionTypes} from "./lenderCustomerProfile.actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {
  CustomerProfileResponse, GetCustomerProfileListPayload,
  InitializeCustomerProfilePayload,
  LoanIdPayload, UserIdPayload,
} from "../../infra/services/api/customerProfile/types";
import {GoldApi} from "../../infra/services/api";
import {snackBarActions} from "../snackbar";
import {LoanResponse} from "../loan/loan.types";
import {AddressResponse} from "../address/address.types";

const {
  getOverduePaymentDetailsSuccess,
  getOverduePaymentDetailsFailure,
  getRemindersSuccess,
  getRemindersFailure,
  sendReminderSuccess,
  sendReminderFailure,
  getCustomerProfileListSuccess,
  getCustomerProfileListFailure,
  getCustomerDetailsSuccess,
  getCustomerDetailsFailure,
} = customerProfileActions;

function* generalErrorFlow(action: PayloadAction<CustomerProfileResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* successFlow(action: PayloadAction<string>) {
  yield put(
    snackBarActions.open({
      message: action.payload,
      variant: "success",
    }),
  );
}

function* getOverduePaymentDetailsFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderCustomerProfile.getOverduePaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getOverduePaymentDetailsFailure({response: null, error}));
  } else {
    yield put(getOverduePaymentDetailsSuccess({response, error: null}));
  }
}

function* getRemindersFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderCustomerProfile.getReminder,
    action.payload,
  );
  if (error) {
    yield put(getRemindersFailure({response: null, error}));
  } else {
    yield put(getRemindersSuccess({response, error: null}));
  }
}

function* sentRemindersFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderCustomerProfile.sentReminder,
    action.payload,
  );
  if (error) {
    yield put(sendReminderFailure({response: null, error}));
  } else {
    yield put(sendReminderSuccess({response, error: null}));
  }
}

function* initializeCustomerProfileFlow(
  action: PayloadAction<InitializeCustomerProfilePayload>,
) {
  const api: GoldApi = yield getContext("api");
  yield all([
    call(api.loan.getLoanDetails, action.payload),
    call(api.address.getAddressList, action.payload),
  ]);
}

function* getCustomerProfileListFlow(
  action: PayloadAction<GetCustomerProfileListPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderCustomerProfile.getCustomerProfileList,
    action.payload,
  );
  if (error) {
    yield put(getCustomerProfileListFailure({response: null, error}));
  } else {
    yield put(getCustomerProfileListSuccess({response, error: null}));
  }
}

function* getCustomerDetailsFlow(
  action: PayloadAction<LoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.lenderCustomerProfile.getCustomerDetails,
    action.payload,
  );
  if (error) {
    yield put(getCustomerDetailsFailure({response: null, error}));
  } else {
    yield put(getCustomerDetailsSuccess({response, error: null}));
  }
}

export default function* lenderCustomerProfileSaga() {
  yield takeLatest(
    lenderCustomerProfileActionTypes.GetOverduePaymentDetails,
    getOverduePaymentDetailsFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.GetOverduePaymentDetailsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.GetReminders,
    getRemindersFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.SendReminder,
    sentRemindersFlow,
  );
  yield takeLatest(
    lenderCustomerProfileActionTypes.SendReminderSuccess,
    function* sendReminderSuccessFlow() {
      yield put(
        snackBarActions.open({
          message: "Reminder was sent successfully!",
          variant: "success",
        }),
      );
    },
  );
  yield takeLatest(
    lenderCustomerProfileActionTypes.SendReminderFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.InitializeCustomerProfile,
    initializeCustomerProfileFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.GetCustomerProfileList,
    getCustomerProfileListFlow,
  );
  yield takeLatest(
    lenderCustomerProfileActionTypes.GetCustomerProfileListFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    lenderCustomerProfileActionTypes.GetCustomerDetails,
    getCustomerDetailsFlow,
  );
}