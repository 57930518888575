import {
  LenderLoanApiInterface,
  LenderLoanApiResources,
  GetDisbursedLoansPayload,
} from "app/store/lenderLoan/lenderLoan.types";
import {goldApiService} from "../../../plugins/axios";

const LENDER_LOAN_ENDPOINTS: LenderLoanApiResources = {
  GET_DISBURSED_LOANS: {
    URL: "admin/business/lender/job-list",
    METHOD: "GET",
  },
  POST_JOB_AS_RECEIVED: {
    URL: "admin/business/lender/cr/:crId/job/:crJobId/status",
    METHOD: "POST",
  },
  ASSIGN_DISBURSAL_JOB: {
    URL: "/admin/business/lender/cr-job/:crJobId/assign",
    METHOD: "PUT",
  },
  UNASSIGN_DISBURSAL_JOB: {
    URL: "/admin/business/lender/cr-job/:crJobId/remove",
    METHOD: "PUT",
  },
  GET_LOAN_BOOKING_JOB_LIST: {
    URL: "/business/lender/approval/loan-booking/job-list",
    METHOD: "GET",
  },
  GET_LOAN_BOOKING_JOB_DETAILS: {
    URL: "/business/lender/approval/request/:requestId/detail",
    METHOD: "GET",
  },
  ASSIGN_LOAN_BOOKING_JOB: {
    URL: "/business/lender/approval/request/:requestId/assign-to-me",
    METHOD: "PUT",
  },
  UNASSIGN_LOAN_BOOKING_JOB: {
    URL: "/business/lender/approval/request/:requestId/un-assign",
    METHOD: "PUT",
  },
  APPROVE_LOAN_BOOKING_JOB: {
    URL: "/business/lender/approval/request/:requestId/approve",
    METHOD: "PUT",
  },
  REJECT_LOAN_BOOKING_JOB: {
    URL: "/business/lender/approval/request/:requestId/reject",
    METHOD: "PUT",
  },
  GET_LOAN_BOOKING_DOCUMENTS: {
    URL: "/business/lender/approval/cr/:crId/document-list",
    METHOD: "GET",
  },
  GET_REMARKS_HISTORY: {
    URL: "/business/lender/approval/request/:requestId/remark-history",
    METHOD: "GET",
  },
};

const lenderLoanApi: LenderLoanApiInterface = {
  getDisbursedLoans: async (payload: GetDisbursedLoansPayload) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.GET_DISBURSED_LOANS,
      options: {
        queryParams: {...payload},
      },
    });
  },
  markDisbursedJobAsReceieved: async (payload) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.POST_JOB_AS_RECEIVED,
      options: {
        pathVars: {
          crId: payload.crId,
          crJobId: payload.crJobId,
        },
        data: {
          remark: payload.remark,
          status: payload.status,
        },
      },
    });
  },
  assignDisbursalJobToMe: async (payload) => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.ASSIGN_DISBURSAL_JOB,
      options: {
        pathVars: {
          crJobId: payload.crJobId,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  unassignDisbursalJob: async (payload) => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.UNASSIGN_DISBURSAL_JOB,
      options: {
        pathVars: {
          crJobId: payload.crJobId,
        },
        data: {
          agentId: payload.agentId,
          remark: payload.remark,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  getLoanBookingJobList: async (payload) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.GET_LOAN_BOOKING_JOB_LIST,
      options: {
        queryParams: {...payload},
      },
    });
  },
  getLoanBookingJobDetails: async ({requestId}) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.GET_LOAN_BOOKING_JOB_DETAILS,
      options: {
        pathVars: {
          requestId,
        },
      },
    });
  },
  assignLoanBookingJobToMe: async payload => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.ASSIGN_LOAN_BOOKING_JOB,
      options: {
        pathVars: {
          requestId: payload.requestId,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  unAssignLoanBookingJobToMe: async payload => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.UNASSIGN_LOAN_BOOKING_JOB,
      options: {
        pathVars: {
          requestId: payload.requestId,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  approveLoanBookingJob: async payload => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.APPROVE_LOAN_BOOKING_JOB,
      options: {
        pathVars: {
          requestId: payload.requestId,
        },
        data: {
          remarks: payload.remarks,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  rejectLoanBookingJob: async payload => {
    const {response, error} = await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.REJECT_LOAN_BOOKING_JOB,
      options: {
        pathVars: {
          requestId: payload.requestId,
        },
        data: {
          remarks: payload.remarks,
        },
      },
    });

    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },

  getLoanBookingDocuments: async ({crId}) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.GET_LOAN_BOOKING_DOCUMENTS,
      options: {
        pathVars: {crId},
      },
    });
  },

  getRemarksHistory: async ({requestId}) => {
    return await goldApiService({
      resource: LENDER_LOAN_ENDPOINTS.GET_REMARKS_HISTORY,
      options: {
        pathVars: {requestId},
      },
    });
  },
};

export {LENDER_LOAN_ENDPOINTS};
export default lenderLoanApi;
