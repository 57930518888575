import React, {useState, useRef, useCallback, useEffect} from "react";

import styles from "../styles";
import {CircularProgress} from "@mui/material";
import getAddressDetails from "../../../../../../../api/business/getAddress";

const AddressDetail = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      addressDetails: null,
    },
    errorMessage: "",
  });

  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    try {
      if (counter.current === 0) {
        const [response] = await Promise.all([
          getAddressDetails({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
            addressType: "RESIDENTIAL_ADDRESS",
          }),
        ]);

        if (response.statusCode === 200) {
          setStates({
            error: false,
            errorMessage: "",
            loading: false,
            payload: {
              addressDetails: response.payload,
            },
          });
        } else {
          setStates({
            error: true,
            errorMessage: response.error.toString(),
            loading: false,
            payload: {
              addressDetails: null,
            },
          });
        }
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: {
          addressDetails: null,
        },
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getDetails, otherRequestTimer]);

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.payload.addressDetails.length === 0) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>Address not present</div>
      </div>
    );
  }

  const address = states.payload.addressDetails[0];

  const list = [
    {
      text: "ADDRESS",
      subText: `${address.addressLine1} ${
        address.addressLine2 !== null ? address.addressLine2 : ""
      } ${address.addressLine3 !== null ? address.addressLine3 : ""}`,
    },
    {
      text: "PINCODE",
      subText: address.pincode,
    },
    {
      text: "STATE",
      subText: address.state,
    },
    {
      text: "CITY",
      subText: address.city,
    },
    {
      text: "LANDMARK",
      subText: address.landmark,
    },
  ];

  return (
    <>
      {list.map((text) => (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>{text.text}</div>

            <div className={classes.subText}>{text.subText}</div>
          </div>
        </>
      ))}
    </>
  );
};

export default AddressDetail;
