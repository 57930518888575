import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  CreateWhitelistPayload,
  DeleteWhitelistPayload,
  FetchWhitelistPayload,
} from "app/infra/services/api/users/types";
import {put, getContext, call, takeLeading} from "redux-saga/effects";
import {snackBarActions} from "../snackbar";
import {whitelistUsersAction, WhitelistUsersTypes} from "./actions";
import {whitelistUserSlice, WhitelistState} from "./reducer";

// >>>>>>>>> WhitelistUsers Actions <<<<<<<<
const {
  getWhitelistUsers,
  getWhitelistUsersSuccess,
  getWhitelistUsersError,
  createWhitelistUserSuccess,
  createWhitelistUserError,
  deleteWhitelistUserError,
  deleteWhitelistUserSuccess,
} = whitelistUsersAction;

// >>>>>>>> WhitelistUsers Flows <<<<<<<<<
function* fetchWhitelistUserFlow(action: PayloadAction<FetchWhitelistPayload>) {
  const api: GoldApi = yield getContext("api");

  const whitelistUserState: WhitelistState = yield call(
    api.users.fetchWhiteList,
    action.payload,
  );

  if (whitelistUserState.error) {
    yield put(getWhitelistUsersError(whitelistUserState));
    yield put(
      snackBarActions.open({
        message: whitelistUserState.error || "Fetching whitelist Users failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getWhitelistUsersSuccess(whitelistUserState));
  }
}

function* createWhiteistUserFlow(
  action: PayloadAction<{
    modifyPayload: CreateWhitelistPayload;
    fetchPayload: FetchWhitelistPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const whitelistUserState: WhitelistState = yield call(
    api.users.createWhitelistUser,
    action.payload.modifyPayload,
  );

  if (whitelistUserState.error) {
    yield put(createWhitelistUserError(whitelistUserState));
    // yield put(
    //   snackBarActions.open({
    //     message: whitelistUserState.error || "Creating whitelist Users failed",
    //     variant: "error",
    //   })
    // );
  } else {
    yield put(createWhitelistUserSuccess(whitelistUserState));

    yield put(getWhitelistUsers());
  }
}

function* deleteWhiteistUserFlow(
  action: PayloadAction<{
    deletePayload: DeleteWhitelistPayload;
    fetchPayload: FetchWhitelistPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const whitelistUserState: WhitelistState = yield call(
    api.users.deleteWhitelistUser,
    action.payload.deletePayload,
  );

  if (whitelistUserState.error) {
    yield put(deleteWhitelistUserError(whitelistUserState));
  } else {
    yield put(deleteWhitelistUserSuccess(whitelistUserState));

    yield put(getWhitelistUsers());
  }
}

function* createWhitelistSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "User created successfully",
      variant: "success",
    }),
  );
}

function* deleteWhitelistSuccessFlow() {
  yield put(
    snackBarActions.open({
      message: "User deleted successfully",
      variant: "warning",
    }),
  );
}
// >>>>>>>> WhitelistUsers Sagas <<<<<<<<<

export default function* schedulingSaga() {
  yield takeLeading(
    WhitelistUsersTypes.GetWhitelistUsers,
    fetchWhitelistUserFlow,
  );
  yield takeLeading(
    WhitelistUsersTypes.GetWhitelistUsersError,
    function* fetchingUserFailureFlow() {
      yield put(
        snackBarActions.open({
          message: "Unable to fetch user",
          variant: "error",
        }),
      );
    },
  );

  yield takeLeading(
    WhitelistUsersTypes.CreateWhitelistUser,
    createWhiteistUserFlow,
  );
  yield takeLeading(
    WhitelistUsersTypes.CreateWhitelistUserSuccess,
    createWhitelistSuccessFlow,
  );
  yield takeLeading(
    WhitelistUsersTypes.CreateWhitelistUserError,
    function* createWhitelistErrorFlow() {
      yield put(
        snackBarActions.open({
          message: "Unable to create new user.",
          variant: "error",
        }),
      );
    },
  );

  yield takeLeading(
    WhitelistUsersTypes.DeleteWhitelistUser,
    deleteWhiteistUserFlow,
  );
  yield takeLeading(
    WhitelistUsersTypes.DeleteWhitelistUserSuccess,
    deleteWhitelistSuccessFlow,
  );
  yield takeLeading(
    WhitelistUsersTypes.DeleteWhitelistUserError,
    function* deleteWhitelistErrorFlow() {
      yield put(
        snackBarActions.open({
          message: "Unable to delete this user.",
          variant: "error",
        }),
      );
    },
  );
}
