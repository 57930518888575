import React from "react";

/**
 *
 * @param `text` is the text that you wante to be rendered
 * inside the component
 * @param `status` can have the value `FAIL` or `SUCCESS` or `null`
 * `FAIL` gives the color RED `SUCCESS` gives the colour GREEN.
 *
 * Any other params pass will be passed to the parent `div` of the
 * text.
 * @returns
 */
const SuccessOrFailureText = ({text, status, ...props}) => {
  return (
    <div
      style={{
        color:
          status === "FAIL" ? "red" : status === "SUCCESS" ? "green" : "black",
      }}
      {...props}
    >
      {text}
    </div>
  );
};

export default SuccessOrFailureText;
