import {
  goldApiService,
  renewAccessTokenHandler,
} from "app/infra/plugins/axios";
import authAdaptor from "./adaptor";
// Type Imports
import {
  LoginPayload,
  AuthResources,
  RegisterPayload,
  AuthApiInterface,
  ChangePasswordPayload,
  GoogleLoginPayload,
} from "./types";

// >>>>>>> Auth API <<<<<<<<<<
const AUTH_ENDPOINTS: AuthResources = {
  LOGIN: {
    URL: "/admin/login",
    METHOD: "POST",
  },
  LOGOUT: {
    URL: "/admin/business/logout",
    METHOD: "DELETE",
  },
  REGISTER: {
    URL: "signup",
    METHOD: "POST",
  },
  REFRESH_TOKEN: {
    URL: "/admin/renew-access-token",
    METHOD: "POST",
  },
  CHANGE_PASSWORD: {
    URL: "/admin/change-password",
    METHOD: "POST",
  },
  GOOGLE_LOGIN: {
    URL: "/admin/google-login",
    METHOD: "POST",
  },
  SEND_OTP: {
    URL: "/admin/send-login-otp",
    METHOD: "POST",
  },
  VERIFY_OTP: {
    URL: "/admin/verify-login-otp",
    METHOD: "POST",
  },
};

const authApi: AuthApiInterface = {
  login: async (data: LoginPayload) => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.LOGIN,
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({response, error});

    return authState;
  },

  // Note: Sign Up is not working as of now leaving a register placeholder
  // for future use
  register: (RegisterPayload: RegisterPayload) => {
    return null;
  },

  logout: async () => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.LOGOUT,
    });

    const authState = authAdaptor({response, error});

    return authState;
  },

  refreshToken: async (token) => {
    const authToken = await renewAccessTokenHandler(token);

    if (!authToken) {
      return {authToken: null, error: null};
    }

    return {authToken, error: null};
  },

  changePassword: async (data: ChangePasswordPayload) => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.CHANGE_PASSWORD,
      options: {
        data,
      },
    });

    const authState = authAdaptor({response, error});

    return authState;
  },

  postGoogleLogin: async (data: GoogleLoginPayload) => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.GOOGLE_LOGIN,
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({response, error});

    return authState;
  },

  sendOtp: async (data: any) => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.SEND_OTP,
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({response, error});

    return authState;
  },

  verifyOtp: async (data) => {
    const {response, error} = await goldApiService({
      resource: AUTH_ENDPOINTS.VERIFY_OTP,
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({response, error});

    return authState;
  },
};

export default authApi;

export {AUTH_ENDPOINTS};
