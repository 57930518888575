/* eslint-disable no-console */
import {PayloadAction} from "@reduxjs/toolkit";
import {
  call,
  getContext,
  put,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {
  GetServiceRequestConfigListPayload,
  ModifyRequestPayload,
  RequestIdPayload,
  ReassignAgentPayload,
  ServiceDeskResponse,
  UpdateRequestPaylaod,
  ConfirmCustomerAvailabilityPayload,
  BypassScanningPayload,
  UserIdPayload,
  UpdateSoaVerificationPayload,
  LoanIdPayload,
  EventMasterListPayload,
  CreateEventPayload,
  LanIdPayload,
  SendSurveyLinkPayload,
} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {snackBarActions} from "app/store/snackbar";
import {GenericResponse} from "app/typings/api/goldApi.types";
import servicesActions, {servicesActionTypes} from "./actions";
import {reloadList, resetRowAction, setReload, toggleModals} from "./reducer";
import complaintActions from "../complaints/actions";
import {countdown} from "app/store/utils.saga";
import {Channel} from "redux-saga";
import {IGDialogActions} from "app/store/igDialog";
import {serviceRequestsReceived, setServiceRequestActionError, setServiceRequestActionLoading, setServiceRequestActionSuccess, serviceRequestUpdated, serviceDeskConfigUpdated, serviceRequestTatDetailsReceived, serviceDeskAgentsReceived} from "../reducer";

const {
  getLoanDetailSuccess,
  getLoanDetailFailure,
  getLoansByMobileNumberSuccess,
  getLoansByMobileNumberFailure,
  createNewRequestSuccess,
  createNewRequestFailure,
  reassignAgentSuccess,
  reassignAgentFailure,
  getCustomerAvailabilitySuccess,
  getCustomerAvailabilityFailure,
  confirmCustomerAvailabilitySuccess,
  confirmCustomerAvailabilityFailure,
  getBypassPacketScanningListSuccess,
  getBypassPacketScanningListFailure,
  approveBypassScanningSuccess,
  approveBypassScanningFailure,
  getPaymentDetailsSuccess,
  getPaymentDetailsFailure,
  getForeClosureChargeFailure,
  getForeClosureChargeSuccess,
  waiveOffForeclosureChargeFailure,
  waiveOffForeclosureChargeSuccess,
  getAllLoansSuccess,
  getAllLoansFailure,
  getCancellationReasonListSuccess,
  getCancellationReasonListFailure,
  getSoaVerificationFailure,
  getSoaVerificationSuccess,
  updateSoaVerificationFailure,
  updateSoaVerificationSuccess,
  validateRateChangeRequestFailure,
  validateRateChangeRequestSuccess,
  getEventHistoryFailure,
  getEventHistorySuccess,
  createEventFailure,
  createEventSuccess,
  getLatestEventFailure,
  getLatestEventSuccess,
  getEventMasterListFailure,
  getEventMasterListSuccess,
  getLenderClosureAmountFailure,
  getLenderClosureAmountSuccess,
} = servicesActions;

function* generalErrorFlow(action: PayloadAction<ServiceDeskResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getServiceRequestsFlow(action: PayloadAction<any>) {
  yield put(setServiceRequestActionLoading({action: "getAllServiceRequests"}));
  const api: GoldApi = yield getContext("api");

  const {serviceRequests, error} = yield call(
    api.serviceDesk.getServiceRequests,
    action.payload,
  );

  if (error) {
    yield put(setServiceRequestActionError({action: "getAllServiceRequests", error: error.message || "Something went wrong"}));
  } else {
    yield put(serviceRequestsReceived(serviceRequests));
    yield put(setServiceRequestActionSuccess({action: "getAllServiceRequests"}));
  }
}

function* getCurrentServiceRequestFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  yield put(setServiceRequestActionLoading({action: "getServiceRequestDetails"}));
  const api: GoldApi = yield getContext("api");
  const {serviceRequest, error} = yield call(
    api.serviceDesk.getServiceRequestById,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
    yield put(setServiceRequestActionError({action: "getServiceRequestDetails", error: error.message || "Something went wrong"}));
  } else {
    if (serviceRequest.offerCalculationStatus === "SUCCESS") {
      localStorage.setItem("OFFER_CALCULATION_STATUS", "SUCCESS");
    }
    yield put(serviceRequestUpdated(serviceRequest));
    yield put(setServiceRequestActionSuccess({action: "getServiceRequestDetails"}));
  }
}

function* updateServiceRequestFlow(
  action: PayloadAction<UpdateRequestPaylaod>,
) {
  yield put(setServiceRequestActionLoading({action: "getServiceRequestDetails"}));
  const api: GoldApi = yield getContext("api");
  const {serviceRequest, error} = yield call(
    api.serviceDesk.updateRequestDetails,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
    yield put(setServiceRequestActionError({action: "getServiceRequestDetails", error: error.message || "Something went wrong"}));
  } else {
    yield put(
      snackBarActions.open({message: "Request Updated!", variant: "success"}),
    );
    yield put(serviceRequestUpdated(serviceRequest));
    yield put(reloadList());
    yield put(toggleModals({type: "update", value: false}));
    yield put(setServiceRequestActionSuccess({action: "getServiceRequestDetails"}));
  }
}

function* getServiceRequestConfigListFlow(
  action: PayloadAction<GetServiceRequestConfigListPayload>,
) {
  yield put(
    setServiceRequestActionLoading({action: "getServiceRequestConfig"}),
  );
  const api: GoldApi = yield getContext("api");
  const {configList, error} = yield call(
    api.serviceDesk.getServiceRequestConfigList,
    action.payload,
  );
  if (error) {
    yield put(
      setServiceRequestActionError({
        action: "getServiceRequestConfig",
        error: error.message || "Something Went Wrong",
      }),
    );
  } else {
    yield put(serviceDeskConfigUpdated({
      requestType: action.payload.requestType,
      product: action.payload.product,
      config: configList,
    }));
    yield put(setServiceRequestActionSuccess({action: "getServiceRequestConfig"}));
  }
}

function* getAgentListFlow() {
  yield put(setServiceRequestActionLoading({action: "getAgentList"}));
  const api: GoldApi = yield getContext("api");
  const {agentList, error} = yield call(api.serviceDesk.getAgentList);
  if (error) {
    yield put(
      setServiceRequestActionError({
        action: "getAgentList",
        error: error.message || "Something Went Wrong",
      }),
    );
  } else {
    yield put(serviceDeskAgentsReceived(agentList));
    yield put(setServiceRequestActionSuccess({action: "getAgentList"}));
  }
}

function* getLoanDetailFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getLoanDetail,
    action.payload,
  );
  if (error) {
    yield put(getLoanDetailFailure({response, error}));
  } else if (response) {
    yield put(getLoanDetailSuccess({response, error}));
  }
}

function* getLoansByMobileNumberFlow(action: PayloadAction<number>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getLoansByMobileNumber,
    action.payload,
  );
  if (error) {
    yield put(getLoansByMobileNumberFailure({response, error}));
  } else {
    yield put(getLoansByMobileNumberSuccess({response, error}));
  }
}

function* createNewRequestFlow(action: PayloadAction<{ formData: any }>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.createNewRequest,
    action.payload,
  );
  if (error) {
    yield put(createNewRequestFailure({response: null, error}));
  } else {
    yield put(createNewRequestSuccess({response, error: null}));
  }
}

function* modifyRequestFlow(action: PayloadAction<ModifyRequestPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.serviceDesk.modifyRequest,
    action.payload,
  );
  if (error) {
    yield put(servicesActions.modifyRequestFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else if (response) {
    yield put(servicesActions.modifyRequestSuccess());
    yield put(
      snackBarActions.open({
        message: response.message,
        variant: "success",
      }),
    );
    yield put(complaintActions.getRequestStatusCount());
    yield put(resetRowAction());
    yield put(reloadList());
  }
}

function* reAssignAgentFlow(action: PayloadAction<ReassignAgentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.serviceDesk.reassignAgent,
    action.payload,
  );
  if (error) {
    yield put(reassignAgentFailure({response: null, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(reassignAgentSuccess({response, error: null}));
  }
}

function* getCustomerAvailabilitySaga(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {customerAvailability, error} = yield call(
    api.serviceDesk.getCustomerAvailability,
    action.payload,
  );
  if (error) {
    yield put(getCustomerAvailabilityFailure({error}));
  } else {
    yield put(
      getCustomerAvailabilitySuccess({
        customerAvailability,
        error: null,
      }),
    );
  }
}

function* confirmCustomerAvailabilitySaga(
  action: PayloadAction<ConfirmCustomerAvailabilityPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error}: GenericResponse = yield call(
    api.serviceDesk.confirmCustomerAvailability,
    action.payload,
  );
  if (error) {
    yield put(confirmCustomerAvailabilityFailure({response, error}));
  } else {
    yield put(confirmCustomerAvailabilitySuccess({response, error}));
    yield put(
      snackBarActions.open({message: response.message, variant: "success"}),
    );
  }
}

function* byPassPacketScanningListSaga(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getBypassPacketList,
    action.payload,
  );
  if (error) {
    yield put(getBypassPacketScanningListFailure({response, error}));
    yield put(resetRowAction());
  } else if (response) {
    yield put(getBypassPacketScanningListSuccess({response, error}));
  }
}

function* approveByPassScanningFlow(
  action: PayloadAction<BypassScanningPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.approveBypassPacketScanning,
    action.payload,
  );
  if (error) {
    yield put(approveBypassScanningFailure({response, error}));
  } else if (response) {
    yield put(approveBypassScanningSuccess({response, error}));
    yield put(
      snackBarActions.open({message: response.message, variant: "success"}),
    );
  }
}

function* getPaymentDetails(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getPaymentDetails,
    action.payload,
  );
  if (error) {
    yield put(getPaymentDetailsFailure({response, error}));
  } else {
    yield put(getPaymentDetailsSuccess({response, error}));
  }
}

function* getForeclosureChargeFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getForeclosureCharge,
    action.payload,
  );
  if (error) {
    yield put(getForeClosureChargeFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(getForeClosureChargeSuccess({response, error}));
  }
}

function* waiveOffForeclosureChargeFlow(
  action: PayloadAction<{
    formData: FormData;
    requestId: number;
    isRenewRebook: boolean;
  }>,
) {

  if (action.payload.isRenewRebook) {
    yield put(
      IGDialogActions.open({
        type: "loading",
        content: "Please Wait! Offer Calculation in progress.",
        showActions: false,
        timer: true,
        persist: true,
      }),
    );
    const api: GoldApi = yield getContext("api");
    const {response, error} = yield call(
      api.serviceDesk.waiveOffForeclosureCharge,
      action.payload,
    );
    if (error) {
      yield put(waiveOffForeclosureChargeFailure({response, error}));
      yield put(
        snackBarActions.open({message: error.message, variant: "error"}),
      );
    } else {
      let isOfferCalculated;
      const chan: Channel<number> = yield call(countdown, {
        seconds: 61,
        intervalTime: 1000,
      });
      localStorage.setItem("OFFER_CALCULATION_STATUS", "PENDING");

      try {
        while (true) {
          isOfferCalculated =
            localStorage.getItem("OFFER_CALCULATION_STATUS") === "SUCCESS";
          console.log("Loop ---> Is offer calculated ?", isOfferCalculated);
          if (isOfferCalculated) {
            console.log("Success ---> Offer Calculated");
            chan.close();
          }

          const seconds: number = yield take(chan);
          console.log("Seconds ---> ", seconds);
          if (seconds % 3 === 0) {
            console.log("Fetching service data");
            yield put(
              servicesActions.getServiceRequestData({
                requestId: action.payload.requestId,
              }),
            );
          }
        }
      } finally {
        if (isOfferCalculated) {
          yield put(waiveOffForeclosureChargeSuccess({response, error}));
          yield put(
            snackBarActions.open({
              message: "Charge waived-off successfully.",
              variant: "success",
            }),
          );
          yield put(IGDialogActions.close());
        } else {
          yield put(
            IGDialogActions.open({
              type: "error",
              content: "Something went wrong ! Please reload the window.",
              showActions: false,
            }),
          );
        }
        localStorage.removeItem("OFFER_CALCULATION_STATUS");
        console.log("countdown terminated");
      }
    }
  } else {
    const api: GoldApi = yield getContext("api");
    const {response, error} = yield call(
      api.serviceDesk.waiveOffForeclosureCharge,
      action.payload,
    );
    if (error) {
      yield put(waiveOffForeclosureChargeFailure({response, error}));
      yield put(
        snackBarActions.open({message: error.message, variant: "error"}),
      );
    } else {
      yield put(waiveOffForeclosureChargeSuccess({response, error}));
      yield put(
        snackBarActions.open({
          message: "Charge waived-off successfully.",
          variant: "success",
        }),
      );
    }
  }
}

function* getAllLoansSaga(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getAllLoans,
    action.payload,
  );
  if (error) {
    yield put(getAllLoansFailure({response, error}));
  } else {
    yield put(getAllLoansSuccess({response, error}));
  }
}

function* getCancellationReasonListFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getCancellationReasonList,
    action.payload,
  );
  if (error) {
    yield put(getCancellationReasonListFailure({response, error}));
  } else {
    yield put(getCancellationReasonListSuccess({response, error}));
  }
}

function* getSoaVerificationFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getSoaVerification,
    action.payload,
  );
  if (error) {
    yield put(getSoaVerificationFailure({response, error}));
  } else {
    yield put(getSoaVerificationSuccess({response, error}));
  }
}

function* updateSoaVerificationFlow(
  action: PayloadAction<UpdateSoaVerificationPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.updateSoaVerification,
    action.payload,
  );
  if (error) {
    yield put(updateSoaVerificationFailure({response, error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(updateSoaVerificationSuccess({response, error}));
    yield put(
      snackBarActions.open({message: response.message, variant: "success"}),
    );
    yield put(
      setReload({
        key: "soaVerification",
        value: true,
      }),
    );
  }
}

function* validateRateChangeRequestFlow(action: PayloadAction<LoanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.validateRateChangeRequest,
    action.payload,
  );
  if (error) {
    yield put(validateRateChangeRequestFailure({response, error}));
  } else {
    yield put(validateRateChangeRequestSuccess({response, error}));
  }
}

function* getTatDetailsFlow(action: PayloadAction<RequestIdPayload>) {
  yield put(setServiceRequestActionLoading({action: "getTatDetails"}));
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getTatDetails,
    action.payload,
  );
  if (error) {
    yield put(setServiceRequestActionError({action: "getTatDetails", error: error.message || "Something Went Wrong"}));
  } else {
    yield put(serviceRequestTatDetailsReceived({
      requestId: action.payload.requestId,
      response,
    }));
    yield put(setServiceRequestActionSuccess({action: "getTatDetails"}));
  }
}

function* getLenderClosureAmountFlow(action: PayloadAction<LanIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getLenderClosureAmount,
    action.payload,
  );
  if (error) {
    yield put(getLenderClosureAmountFailure({response, error}));
    // yield put(snackBarActions.open({message: error.message || "Something Went Wrong", variant: "error"}));
  } else {
    yield put(getLenderClosureAmountSuccess({response, error}));
  }
}

function* getEventHistoryFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getEventHistory,
    action.payload,
  );
  if (error) {
    yield put(getEventHistoryFailure({response, error}));
  } else {
    yield put(
      getEventHistorySuccess({
        response,
        error,
        requestId: action.payload.requestId,
      }),
    );
  }
}

function* getEventMasterListFlow(
  action: PayloadAction<EventMasterListPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getEventMasterList,
    action.payload,
  );
  if (error) {
    yield put(getEventMasterListFailure({response, error}));
  } else {
    yield put(getEventMasterListSuccess({response, error}));
  }
}

function* createEventFlow(action: PayloadAction<CreateEventPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.createEvent,
    action.payload,
  );
  if (error) {
    yield put(createEventFailure({response, error}));
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
  } else {
    yield put(createEventSuccess({response, error}));
    yield put(
      servicesActions.getLatestEvent({
        requestId: action.payload.requestId,
      }),
    );
    yield put(
      servicesActions.getEventHistory({
        requestId: action.payload.requestId,
      }),
    );
  }
}

function* getLatestEventFlow(action: PayloadAction<RequestIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.getLatestEvent,
    action.payload,
  );
  if (error) {
    yield put(getLatestEventFailure({response, error}));
  } else {
    yield put(getLatestEventSuccess({response, error}));
  }
}

function* sendSurveyLinkFlow(aciton: PayloadAction<SendSurveyLinkPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.serviceDesk.sendSurveyLink,
    aciton.payload,
  );
  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message,
        variant: "error",
      }),
    );
  } else {
    yield put(
      snackBarActions.open({
        message: response.message || "Survey Link Sent Successfully",
        variant: "success",
      }),
    );

  }

}

export default function* servicesSaga() {
  yield takeLatest(
    servicesActionTypes.GetServiceRequests,
    getServiceRequestsFlow,
  );
  yield takeLatest(
    servicesActionTypes.GetServiceRequestData,
    getCurrentServiceRequestFlow,
  );
  yield takeLatest(
    servicesActionTypes.UpdateServiceRequestData,
    updateServiceRequestFlow,
  );
  yield takeEvery(
    servicesActionTypes.GetServiceRequestConfigList,
    getServiceRequestConfigListFlow,
  );
  yield takeLatest(servicesActionTypes.GetAgentList, getAgentListFlow);
  yield takeLatest(
    servicesActionTypes.GetLoansByMobileNumber,
    getLoansByMobileNumberFlow,
  );
  yield takeLatest(servicesActionTypes.CreateNewRequest, createNewRequestFlow);
  yield takeLatest(
    servicesActionTypes.CreateNewRequestFailure,
    generalErrorFlow,
  );
  yield takeLatest(servicesActionTypes.ModifyRequest, modifyRequestFlow);
  yield takeLatest(servicesActionTypes.GetLoanDetail, getLoanDetailFlow);
  yield takeLatest(servicesActionTypes.GetLoanDetailFailure, generalErrorFlow);
  yield takeLatest(servicesActionTypes.ReassignAgent, reAssignAgentFlow);
  yield takeLatest(
    servicesActionTypes.GetCustomerAvailability,
    getCustomerAvailabilitySaga,
  );
  yield takeLatest(
    servicesActionTypes.GetCustomerAvailabilityFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    servicesActionTypes.ConfirmCustomerAvailability,
    confirmCustomerAvailabilitySaga,
  );
  yield takeLatest(
    servicesActionTypes.ConfirmCustomerAvailabilityFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    servicesActionTypes.GetBypassPacketScanningList,
    byPassPacketScanningListSaga,
  );
  yield takeLatest(
    servicesActionTypes.GetBypassPacketScanningListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    servicesActionTypes.ApproveBypassScanning,
    approveByPassScanningFlow,
  );
  yield takeLatest(servicesActionTypes.GetPaymentDetails, getPaymentDetails);
  yield takeLatest(
    servicesActionTypes.GetForeClosureCharge,
    getForeclosureChargeFlow,
  );
  yield takeLatest(
    servicesActionTypes.WaiveOffForeclosureCharge,
    waiveOffForeclosureChargeFlow,
  );
  yield takeLatest(servicesActionTypes.GetAllLoans, getAllLoansSaga);
  yield takeLatest(
    servicesActionTypes.GetCancellationReasonList,
    getCancellationReasonListFlow,
  );
  yield takeLatest(
    servicesActionTypes.GetSoaVerification,
    getSoaVerificationFlow,
  );
  yield takeLatest(
    servicesActionTypes.UpdateSoaVerification,
    updateSoaVerificationFlow,
  );
  yield takeLatest(
    servicesActionTypes.ValidateRateChangeRequest,
    validateRateChangeRequestFlow,
  );
  yield takeLatest(servicesActionTypes.GetTatDetails, getTatDetailsFlow);
  yield takeLatest(servicesActionTypes.GetEventHistory, getEventHistoryFlow);
  yield takeLatest(
    servicesActionTypes.GetEventMasterList,
    getEventMasterListFlow,
  );
  yield takeLatest(servicesActionTypes.CreateEvent, createEventFlow);
  yield takeLatest(servicesActionTypes.GetLatestEvent, getLatestEventFlow);
  yield takeLatest(
    servicesActionTypes.GetLenderClosureAmount,
    getLenderClosureAmountFlow,
  );
  yield takeLatest(servicesActionTypes.SendSurveyLink, sendSurveyLinkFlow);
}
