import React, {useCallback} from "react";
import {CircularProgress} from "@mui/material";

import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import createNucleusApplication from "api/business/createApplicationInNucleus";

import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import styles from "../../styles";

const ApplicationNotGenerated = ({
  setIsApplicationGenerated,
  payload,
  getDetails,
  selectedPayload,
  isSameCreditManager,
  isBackMovement,
  ltv,
  getMileStoneOfCr,
  requesting,
  setRequesting,
}) => {
  const classes = styles();
  const {showError, showSuccess} = useSnackBar();
  const authToken = useAppSelector(authSelectors.getAuthToken);
  const {goldLoan, personalLoan} = payload;

  const onClickHandler = useCallback(async () => {
    setRequesting(true);
    try {
      const createNucleusApplicationResponse = await createNucleusApplication({
        accessToken: authToken,
        crId: selectedPayload.id,
      });

      if (createNucleusApplicationResponse.statusCode === 200) {
        getDetails();
        setIsApplicationGenerated(true);
        showSuccess("Success");
        getMileStoneOfCr();
      } else {
        showError(createNucleusApplicationResponse.error.toString());
      }
    } catch (error) {
      showError("Something went wrong");
    }
    setRequesting(false);
  }, [
    getDetails,
    getMileStoneOfCr,
    selectedPayload.id,
    setIsApplicationGenerated,
    setRequesting,
    authToken,
    showError,
    showSuccess,
  ]);

  return (
    <>
      {/* <div className={classes.textContainer}>
        <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

        <div className={classes.subText}>
          {`${numberWithCurrencyAndComma(
            goldLoan.totalLoanAmount + personalLoan.totalLoanAmount,
          )} / ${ltv}%`}
        </div>
      </div> */}

      <div className={classes.textContainer}>
        <div className={classes.text}>GOLD LOAN INFORMATION</div>

        <div className={classes.subText}>
          {`Gold Loan Amount: ${numberWithCurrencyAndComma(
            goldLoan.totalLoanAmount,
          )}`}
        </div>

        <div className={classes.subText}>
          {`Lender: ${goldLoan.loanVendorName}`}
        </div>

        {selectedPayload.milestone !== "COMPLETED" &&
          selectedPayload.milestone !== "CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_FRESH_CLOSED" &&
          selectedPayload.milestone !== "CR_GOLD_LOAN_BT_CLOSED" && (
            <div
              className={classes.subText}
            >{`CM Scheme Name: ${goldLoan.schemeType}`}</div>
          )}

        {/* <div className={classes.subText}>
          {`Scheme Selected: ${goldLoan.goldLoanSchemeName ||
            goldLoan.nucleusSchemeCode}`}
        </div> */}

        <div className={classes.subText}>
          {`Annual Interest Rate: ${goldLoan.annualInterestRate}%`}
        </div>
        {/* <div className={classes.subText}>
          {`Subvented Annual Interest Rate: ${goldLoan.subventedAnnualInterestRate}%`}
        </div> */}
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>PERSONAL LOAN INFORMATION</div>

        <div className={classes.subText}>
          {`Personal Loan Amount: ${numberWithCurrencyAndComma(
            personalLoan.totalLoanAmount,
          )}`}
        </div>

        <div className={classes.subText}>
          {`Lender: ${personalLoan.loanVendorName}`}
        </div>

        <div className={classes.subText}>
          {`Annual Interest Rate: ${personalLoan.annualInterestRate}%`}
        </div>
        {/* <div className={classes.subText}>
          {`Subvented Annual Interest Rate: ${personalLoan.subventedAnnualInterestRate}%`}
        </div> */}
      </div>

      {isSameCreditManager && !isBackMovement && (
        <>
          {requesting && <CircularProgress />}

          <div className={classes.buttonContainer}>
            <button
              disabled={requesting}
              onClick={onClickHandler}
              className={classes.ApproveButton}
            >
              SUBMIT
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ApplicationNotGenerated;
