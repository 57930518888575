import {RootState} from "app/store/store";

const bankDetailsSelectors = {
  getBankDetails: (state: RootState) => state.bankDetails.bankDetails,
  getOpenModals: (state: RootState) => state.bankDetails.openModals,
  getLoading: (state: RootState) => state.bankDetails.loading,
  getErrors: (state: RootState) => state.bankDetails.errors,
};

export default bankDetailsSelectors;
