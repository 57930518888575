import {createAction} from "@reduxjs/toolkit";

import {
  GetDocumentListByCrIdPayload,
  CifValidationResponse,
} from "./cifValidation.types";

const cifDetailsActionTypes = {
  GetCifDetails: "[Cif Details] Get Cif Details ",
  GetCifDetailsSuccess: "[Cif Details] Get Cif Details  Success",
  GetCifDetailsFailure: "[Cif Details] Get Cif Details  Failure",
};

const cifDetailsActions = {
  getCifDetails: createAction<GetDocumentListByCrIdPayload>(
    cifDetailsActionTypes.GetCifDetails,
  ),
  getCifDetailsSuccess: createAction<CifValidationResponse>(
    cifDetailsActionTypes.GetCifDetailsSuccess,
  ),
  getCifDetailsFailure: createAction<CifValidationResponse>(
    cifDetailsActionTypes.GetCifDetailsFailure,
  ),
};

export default cifDetailsActions;

export {cifDetailsActionTypes};
