import styled from "@emotion/styled";
import {Box} from "@mui/material";

const LoanDetailsBox = styled(Box)`
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fffbf1;
  margin-top: 2rem;
`;

export default LoanDetailsBox;
