import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/admin/business/v2/cr/:crId/post-loan-approval-document",
  METHOD: "GET",
};

export const getPostLoanApprovalDocuments = async ({
  crId,
  signingMode = "OFFLINE",
}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        signingMode,
      },
    },
  });
  return response;
};

export default getPostLoanApprovalDocuments;
