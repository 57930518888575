import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuIconProps, MENU_ICONS} from ".";

const UserMain: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.Users} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

const UserBanned: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.BannedUsers} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

const UserInternal: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.InternalUsers} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

const UserWhitelisted: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.WhitelistUsers} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

const DeviceBanned: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.BannedDevices} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

const UserInternalAll: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon icon={MENU_ICONS.AllInternalUsers} size="2x" style={{
      color: isActive ? "#ce9f29" : "#2C3639",
    }}/>
  );
};

export default UserMain;
export {
  UserBanned,
  UserInternal,
  UserWhitelisted,
  DeviceBanned,
  UserInternalAll,
};
