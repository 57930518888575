import styled from "@emotion/styled";
import {Box} from "@mui/material";

interface DocViewContainerProps {
  customzIndex?: number;
}

const DocViewContainer = styled(Box)<DocViewContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${({customzIndex}) => customzIndex};
`;

const DocViewHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: move;
`;

const ImageWrapper = styled.img`
  // max-height: 50vh;
  // max-width: 50vh;
  overflow: visible;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {DocViewContainer, DocViewHeader, ImageContainer, ImageWrapper};
