import React from "react";
import {Box, Typography} from "@mui/material";
import {IGButton} from "app/components";
import PoweredBy from "../CreditMeter/images/powered.png";
import {
  creditDetailsActions,
  creditDetailsSelectors,
} from "app/store/loanRenewalRebook/creditDetails";
import {useAppDispatch, useAppSelector} from "app/store/hooks";

interface CreditConsentSmsProps {
  crId: number;
}
const CreditConsentSms = ({crId}: CreditConsentSmsProps) => {
  const dispatch = useAppDispatch();
  const {initiateCreditConsent: isInitiateConsentLoading} = useAppSelector(
    creditDetailsSelectors.getLoading,
  );
  const {consentStatus} = useAppSelector(creditDetailsSelectors.getCreditSessionStatus) ?? {};
  return (
    <Box bgcolor="#FFFFFF" p={2} my={1} border="1px solid #0000001f" borderRadius="6px">
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight={500} mb={1}>
          Credit Score
        </Typography>
        {!consentStatus && <Typography variant="h6">Not Generated</Typography>}

        <img style={{maxWidth: "170px"}} src={PoweredBy} alt="PoweredBy" />
      </Box>
      <Box mt={4}>
        <IGButton
          loading={isInitiateConsentLoading}
          onClick={() =>
            dispatch(
              creditDetailsActions.initiateCreditConsent({
                crId: crId,
                isRenewRebook: false,
              }),
            )
          }
          variant="contained"
        >
          SEND CONSENT SMS
        </IGButton>
      </Box>
    </Box>
  );
};

export default CreditConsentSms;
