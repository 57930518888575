import React, {useCallback, useEffect, useState} from "react";
import IGDocumentViewer from "app/components/IGDocumentViewer";
import Loading from "app/components/Loading";
import getDocument from "api/business/getDocument";
import DetailWrapper from "../../NewUploadKYC/KYCDetails/styles/DetailWrapper";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {fileType} from "app/enums/generalPurpose";
import ArrowUpFromBracket from "app/AppLayout/Icons/ArrowUpFromBracket";
import {DocTitle, ViewPdfButton} from "../styles/index";

//  component to render documents on each iteration of documentsType of GL and PL.

const DocumentDetails = ({docType, docName, crId}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [document, setDocument] = useState(null);

  const fetchDocumentDetails = useCallback(async () => {
    const {payload, statusCode} = await getDocument({
      accessToken,
      crId,
      documentType: [docType],
    });

    if (statusCode === 200) {
      if (payload.length) {
        setDocument(payload[0]);
      }
    } else {
    }
  }, [accessToken, crId, docType]);

  //lifecycle method

  useEffect(() => {
    fetchDocumentDetails();
  }, [fetchDocumentDetails]);

  //fn to show pdf in leegality online instead of images based on filetype pdf.

  const viewPdf = (link) => {
    window.open(link, "_blank");
  };

  if (!document) {
    return <Loading height='10vh' />;
  }


  return (
    <>
      <DocTitle>{docName}</DocTitle>
      {document && document?.fileType === fileType.PDF ? (
        <DetailWrapper marginLeft={true}>
          <ViewPdfButton
            sx={{marginTop: "1rem", marginBottom: "1rem"}}
            onClick={() => viewPdf(document.fileUrl)}
          >
            <b>View</b> &nbsp;
            <ArrowUpFromBracket />
          </ViewPdfButton>
        </DetailWrapper>
      ) : (
        <DetailWrapper marginLeft={true}>
          <IGDocumentViewer document={document} />
        </DetailWrapper>
      )}
    </>
  );
};

export default DocumentDetails;
