import React from "react";
import {Navigate} from "react-router-dom";
import {authActions, authSelectors} from "../../store/auth";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {GoogleLogin} from "@react-oauth/google";
import {useIGTranslation} from "../../infra/plugins/i18n";

const {hostname} = window.location;

const GLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const {t} = useIGTranslation();
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const user = useAppSelector(authSelectors.getUser);

  const handleGoogleSuccessResponse = (response: any) => {
    // dispatch logout if type of google response is GoogleLoginResponseOffline
    if (response.credential) {
      dispatch(
        authActions.postGoogleLogin({
          idToken: response.credential,
        }),
      );
    } else {
      dispatch(
        authActions.loginFailure({
          error: {
            message: "Device Offline",
          },
        }),
      );
    }
  };

  const handleGoogleFailureResponse = () => {
    dispatch(
      authActions.loginFailure({
        authToken: null,
        refreshToken: null,
        user: null,
        error: {
          message: "Google: Login Failed",
        },
      }),
    );
  };

  if (isAuthenticated && user) {
    return <Navigate to="/" />;
  } else if (hostname === t("LENDER_INDIAGOLD_CO") || hostname === t("LENDER_BESTGOLDINDIA_COM")) {
    return <></>;
  }

  return (
      <GoogleLogin
        onSuccess={handleGoogleSuccessResponse}
        onError={handleGoogleFailureResponse}
        locale="en_IN"
        useOneTap
      />
  );
};

export default GLogin;
