import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import loanRenewRebookActions from "./loanRenewalRebook.actions";
import {
  LoanRenewRebookMilestonesInterface,
  LoanRenewRebookJobDetails,
  LoanRenewRebookConfig,
  LoanRenewalOfferDetail,
  LoanDigitalDocumentLink,
  RenewRebookLoanBookingDetails,
  LoanCreationStatus,
  AutoLoanRenewalStatus,
} from "app/models/loanRenewalRebook/loanRenewalRebook.model";
import {KycDocuments} from "app/models/loanRenewalRebook/personalDetails.model";

type LoadingErrorState =
  | "getloanRenewRebookMilestones"
  | "checkLoanRenewalAllowed"
  | "getLoanRenewRebookJobDetails"
  | "createLoanRenewRebookJob"
  | "getloanRenewRebookConfig"
  | "updateLoanRenewRebookMilestone"
  | "deleteLoanDocument"
  | "createLoanDocument"
  | "getDocumentListByMilestone"
  | "getLoanRenewalOffer"
  | "postLenderGating"
  | "getLenderGatingStatus"
  | "getLoanRenewalFlagStatus"
  | "closeRenewalLoan"
  | "getLoanConfigDetails"
  | "getRenewRebookLoanDetails"
  | "assignLoanRenewalJob"
  | "unAssignLoanRenewalJob"
  | "getEncryptedDocumentDetail"
  | "getLoanCreationStatus"
  | "getAutoLoanRenewalStatus"
  | "createAutoLoanRenewalJob";

export interface LoanRenewalRebookState {
  loanRenewRebookMilestones: LoanRenewRebookMilestonesInterface[];
  loanRenewRebookJobDetails: LoanRenewRebookJobDetails | null;
  loanRenewalDetails: RenewRebookLoanBookingDetails | null;
  loanRenewRebookConfig: LoanRenewRebookConfig;
  documentListByMilestone: string[];
  loanRenewalOfferDetail: LoanRenewalOfferDetail | null;
  isLenderGatingDone: boolean;
  isLoanRenewalFlagEnabled: boolean;
  isLoanDetailsEditable: boolean;
  encryptedDocumentDetail: KycDocuments | null;
  loanCreationStatus: LoanCreationStatus | null;
  openModals: {
    loanCheckerModal: boolean;
    isLoanCancelModalOpen: boolean;
  };
  isLoanRenewalAllowed: boolean | null;
  selectedMileStone: {
    stageName: string | null;
    stageEnum: string | null;
  };
  selectedLender: string | null;
  autoLoanRenewalStatus: AutoLoanRenewalStatus | null;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: LoanRenewalRebookState = {
  loanRenewRebookMilestones: [],
  loanRenewRebookJobDetails: null,
  loanRenewRebookConfig: {},
  documentListByMilestone: [],
  encryptedDocumentDetail: null,
  isLoanRenewalAllowed: null,
  loanRenewalOfferDetail: null,
  loanRenewalDetails: null,
  isLenderGatingDone: false,
  isLoanRenewalFlagEnabled: false,
  isLoanDetailsEditable: true,
  loanCreationStatus: null,
  openModals: {
    loanCheckerModal: false,
    isLoanCancelModalOpen: false,
  },
  selectedMileStone: {
    stageName: null,
    stageEnum: null,
  },
  selectedLender: null,
  autoLoanRenewalStatus: null,
  loading: {
    getloanRenewRebookMilestones: false,
    checkLoanRenewalAllowed: false,
    getLoanRenewRebookJobDetails: false,
    createLoanRenewRebookJob: false,
    getloanRenewRebookConfig: false,
    updateLoanRenewRebookMilestone: false,
    deleteLoanDocument: false,
    createLoanDocument: false,
    getDocumentListByMilestone: false,
    getLoanRenewalOffer: false,
    postLenderGating: false,
    getLenderGatingStatus: false,
    getLoanRenewalFlagStatus: false,
    closeRenewalLoan: false,
    getLoanConfigDetails: false,
    getRenewRebookLoanDetails: false,
    assignLoanRenewalJob: false,
    unAssignLoanRenewalJob: false,
    getEncryptedDocumentDetail: false,
    getLoanCreationStatus: false,
    getAutoLoanRenewalStatus: false,
    createAutoLoanRenewalJob: false,
  },

  errors: {
    getloanRenewRebookMilestones: null,
    checkLoanRenewalAllowed: null,
    getLoanRenewRebookJobDetails: null,
    createLoanRenewRebookJob: null,
    getloanRenewRebookConfig: null,
    updateLoanRenewRebookMilestone: null,
    deleteLoanDocument: null,
    createLoanDocument: null,
    getDocumentListByMilestone: null,
    getLoanRenewalOffer: null,
    postLenderGating: null,
    getLenderGatingStatus: null,
    getLoanRenewalFlagStatus: null,
    closeRenewalLoan: null,
    getLoanConfigDetails: null,
    getRenewRebookLoanDetails: null,
    assignLoanRenewalJob: null,
    unAssignLoanRenewalJob: null,
    getEncryptedDocumentDetail: null,
    getLoanCreationStatus: null,
    getAutoLoanRenewalStatus: null,
    createAutoLoanRenewalJob: null,
  },
};

const loanRenewalRebookSlice = createSlice({
  name: "loanRenewalRebook",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    setSelectedMileStone: (
      state,
      action: PayloadAction<{
        stageEnum: string | null;
        stageName: string | null;
      }>,
    ) => {
      state.selectedMileStone.stageEnum = action.payload.stageEnum;
      state.selectedMileStone.stageName = action.payload.stageName;
    },
    setSelectedLender: (state, action: PayloadAction<{ selectedLender: null | string }>) => {
      state.selectedLender = action.payload.selectedLender;
    },
    resetLoanRenewalJobDetails: state => {
      state.loanRenewRebookJobDetails = null;
    },

    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
    setAutoLoanRenewalStatus: (state, action) => {
      state.autoLoanRenewalStatus = action.payload?.response;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(loanRenewRebookActions.getloanRenewRebookMilestones, state => {
        state.loading.getloanRenewRebookMilestones = true;
        state.errors.getloanRenewRebookMilestones =
          initialState.errors.getloanRenewRebookMilestones;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookMilestonesSuccess, (state, action) => {
        state.loanRenewRebookMilestones = action.payload.response;
        state.loading.getloanRenewRebookMilestones = false;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookMilestonesFailure, (state, action) => {
        state.loading.getloanRenewRebookMilestones = false;
        state.errors.getloanRenewRebookMilestones = action.payload.error;
      })
      .addCase(loanRenewRebookActions.checkLoanRenewalAllowed, state => {
        state.loading.checkLoanRenewalAllowed = true;
        state.errors.checkLoanRenewalAllowed = initialState.errors.checkLoanRenewalAllowed;
      })
      .addCase(loanRenewRebookActions.checkLoanRenewalAllowedSuccess, (state, action) => {
        state.isLoanRenewalAllowed = action.payload.response;
        state.loading.checkLoanRenewalAllowed = false;
      })
      .addCase(loanRenewRebookActions.checkLoanRenewalAllowedFailure, (state, action) => {
        state.loading.checkLoanRenewalAllowed = false;
        state.errors.checkLoanRenewalAllowed = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookJobDetails, state => {
        state.loading.getLoanRenewRebookJobDetails = true;
        state.errors.getLoanRenewRebookJobDetails =
          initialState.errors.getLoanRenewRebookJobDetails;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookJobDetailsSuccess, (state, action) => {
        state.loanRenewRebookJobDetails = action.payload.response;
        state.loading.getLoanRenewRebookJobDetails = false;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookJobDetailsFailure, (state, action) => {
        state.loading.getLoanRenewRebookJobDetails = false;
        state.errors.getLoanRenewRebookJobDetails = action.payload.error;
      })
      .addCase(loanRenewRebookActions.createRenewRebookJob, state => {
        state.loading.createLoanRenewRebookJob = true;
        state.errors.createLoanRenewRebookJob = initialState.errors.createLoanRenewRebookJob;
      })
      .addCase(loanRenewRebookActions.createRenewRebookJobSuccess, (state, action) => {
        state.loading.createLoanRenewRebookJob = false;
      })
      .addCase(loanRenewRebookActions.createRenewRebookJobFailure, (state, action) => {
        state.loading.createLoanRenewRebookJob = false;
        state.errors.createLoanRenewRebookJob = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookConfig, state => {
        state.loading.getloanRenewRebookConfig = true;
        state.errors.getloanRenewRebookConfig = initialState.errors.getloanRenewRebookConfig;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookConfigSuccess, (state, action) => {
        state.loanRenewRebookConfig = action.payload.response;
        state.loading.getloanRenewRebookConfig = false;
      })
      .addCase(loanRenewRebookActions.getloanRenewRebookConfigFailure, (state, action) => {
        state.loading.getloanRenewRebookConfig = false;
        state.errors.getloanRenewRebookConfig = action.payload.error;
      })
      .addCase(loanRenewRebookActions.updateLoanRenewRebookMilestone, state => {
        state.loading.updateLoanRenewRebookMilestone = true;
        state.errors.updateLoanRenewRebookMilestone =
          initialState.errors.updateLoanRenewRebookMilestone;
      })
      .addCase(loanRenewRebookActions.updateLoanRenewRebookMilestoneSuccess, (state, action) => {
        state.loading.updateLoanRenewRebookMilestone = false;
        state.selectedMileStone.stageEnum = null;
        state.selectedMileStone.stageEnum = null;
      })
      .addCase(loanRenewRebookActions.updateLoanRenewRebookMilestoneFailure, (state, action) => {
        state.loading.updateLoanRenewRebookMilestone = false;
        state.errors.updateLoanRenewRebookMilestone = action.payload.error;
      })
      .addCase(loanRenewRebookActions.deleteLoanDocument, state => {
        state.loading.deleteLoanDocument = true;
        state.errors.deleteLoanDocument = initialState.errors.deleteLoanDocument;
      })
      .addCase(loanRenewRebookActions.deleteLoanDocumentSuccess, (state, action) => {
        state.loading.deleteLoanDocument = false;
      })
      .addCase(loanRenewRebookActions.deleteLoanDocumentFailure, (state, action) => {
        state.loading.deleteLoanDocument = false;
        state.errors.deleteLoanDocument = action.payload.error;
      })
      .addCase(loanRenewRebookActions.createLoanDocument, state => {
        state.loading.createLoanDocument = true;
        state.errors.createLoanDocument = initialState.errors.createLoanDocument;
      })
      .addCase(loanRenewRebookActions.createLoanDocumentSuccess, (state, action) => {
        state.loading.createLoanDocument = false;
      })
      .addCase(loanRenewRebookActions.createLoanDocumentFailure, (state, action) => {
        state.loading.createLoanDocument = false;
        state.errors.createLoanDocument = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getDocumentListByMilestone, state => {
        state.loading.getDocumentListByMilestone = true;
        state.errors.getDocumentListByMilestone = initialState.errors.getDocumentListByMilestone;
      })
      .addCase(loanRenewRebookActions.getDocumentListByMilestoneSuccess, (state, action) => {
        state.documentListByMilestone = action.payload.response;
        state.loading.getDocumentListByMilestone = false;
      })
      .addCase(loanRenewRebookActions.getDocumentListByMilestoneFailure, (state, action) => {
        state.loading.getDocumentListByMilestone = false;
        state.errors.getDocumentListByMilestone = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalOffer, state => {
        state.loading.getLoanRenewalOffer = true;
        state.errors.getLoanRenewalOffer = initialState.errors.getLoanRenewalOffer;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalOfferSuccess, (state, action) => {
        state.loanRenewalOfferDetail = action.payload.response;
        state.loading.getLoanRenewalOffer = false;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalOfferFailure, (state, action) => {
        state.loading.getLoanRenewalOffer = false;
        state.errors.getLoanRenewalOffer = action.payload.error;
      })
      .addCase(loanRenewRebookActions.postLenderGating, state => {
        state.loading.postLenderGating = true;
        state.errors.postLenderGating = initialState.errors.postLenderGating;
      })
      .addCase(loanRenewRebookActions.postLenderGatingSuccess, state => {
        state.loading.postLenderGating = false;
      })
      .addCase(loanRenewRebookActions.postLenderGatingFailure, (state, action) => {
        state.loading.postLenderGating = false;
        state.errors.postLenderGating = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getLenderGatingStatus, state => {
        state.loading.getLenderGatingStatus = true;
        state.errors.getLenderGatingStatus = initialState.errors.getLenderGatingStatus;
      })
      .addCase(loanRenewRebookActions.getLenderGatingStatusSuccess, (state, action) => {
        state.isLenderGatingDone = action.payload.response;
        state.loading.getLenderGatingStatus = false;
      })
      .addCase(loanRenewRebookActions.getLenderGatingStatusFailure, (state, action) => {
        state.loading.getLenderGatingStatus = false;
        state.errors.getLenderGatingStatus = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalFlagStatus, state => {
        state.loading.getLoanRenewalFlagStatus = true;
        state.errors.getLoanRenewalFlagStatus = initialState.errors.getLoanRenewalFlagStatus;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalFlagStatusSuccess, (state, action) => {
        state.isLoanRenewalFlagEnabled = action.payload.response;
        state.loading.getLoanRenewalFlagStatus = false;
      })
      .addCase(loanRenewRebookActions.getLoanRenewalFlagStatusFailure, (state, action) => {
        state.loading.getLoanRenewalFlagStatus = false;
        state.errors.getLoanRenewalFlagStatus = action.payload.error;
      })
      .addCase(loanRenewRebookActions.closeRenewalLoan, state => {
        state.loading.closeRenewalLoan = true;
        state.errors.closeRenewalLoan = initialState.errors.closeRenewalLoan;
      })
      .addCase(loanRenewRebookActions.closeRenewalLoanSuccess, state => {
        state.loading.closeRenewalLoan = false;
      })
      .addCase(loanRenewRebookActions.closeRenewalLoanFailure, (state, action) => {
        state.loading.closeRenewalLoan = false;
        state.errors.closeRenewalLoan = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getLoanConfigDetails, state => {
        state.loading.getLoanConfigDetails = true;
        state.errors.getLoanConfigDetails = initialState.errors.getLoanConfigDetails;
      })
      .addCase(loanRenewRebookActions.getLoanConfigDetailsSuccess, (state, action) => {
        state.loading.getLoanConfigDetails = false;
        state.isLoanDetailsEditable = action.payload?.response?.editDetail;
      })
      .addCase(loanRenewRebookActions.getLoanConfigDetailsFailure, (state, action) => {
        state.loading.getLoanConfigDetails = false;
        state.errors.getLoanConfigDetails = action.payload.error;
      })
      .addCase(loanRenewRebookActions.getRenewRebookLoanDetails, state => {
        state.loading.getRenewRebookLoanDetails = true;
        state.errors.getRenewRebookLoanDetails = initialState.errors.getRenewRebookLoanDetails;
      })
      .addCase(loanRenewRebookActions.getRenewRebookLoanDetailsSuccess, (state, action) => {
        state.loading.getRenewRebookLoanDetails = false;
        state.loanRenewalDetails = action.payload.response;
      })
      .addCase(loanRenewRebookActions.getRenewRebookLoanDetailsFailure, (state, action) => {
        state.loading.getRenewRebookLoanDetails = false;
        state.errors.getRenewRebookLoanDetails = action.payload.error;
      })
      .addCase(loanRenewRebookActions.assignLoanRenewalJob, state => {
        state.loading.assignLoanRenewalJob = true;
        state.errors.assignLoanRenewalJob = initialState.errors.assignLoanRenewalJob;
      })
      .addCase(loanRenewRebookActions.assignLoanRenewalJobSuccess, state => {
        state.loading.assignLoanRenewalJob = false;
        state.errors.assignLoanRenewalJob = null;
      })
      .addCase(loanRenewRebookActions.assignLoanRenewalJobFailure, (state, action) => {
        state.loading.assignLoanRenewalJob = false;
        state.errors.assignLoanRenewalJob = action.payload.error;
      })

      .addCase(loanRenewRebookActions.unAssignLoanRenewalJob, state => {
        state.loading.unAssignLoanRenewalJob = true;
        state.errors.unAssignLoanRenewalJob = initialState.errors.unAssignLoanRenewalJob;
      })
      .addCase(loanRenewRebookActions.unAssignLoanRenewalJobSuccess, state => {
        state.loading.unAssignLoanRenewalJob = false;
        state.errors.unAssignLoanRenewalJob = null;
      })
      .addCase(loanRenewRebookActions.unAssignLoanRenewalJobFailure, (state, action) => {
        state.loading.unAssignLoanRenewalJob = false;
        state.errors.unAssignLoanRenewalJob = action.payload.error;
      })

      .addCase(loanRenewRebookActions.getEncryptedDocumentDetail, state => {
        state.loading.getEncryptedDocumentDetail = true;
        state.errors.getEncryptedDocumentDetail = initialState.errors.getEncryptedDocumentDetail;
      })
      .addCase(loanRenewRebookActions.getEncryptedDocumentDetailSuccess, (state, action) => {
        state.loading.getEncryptedDocumentDetail = false;
        state.encryptedDocumentDetail = action.payload.response;
        state.errors.getEncryptedDocumentDetail = null;
      })
      .addCase(loanRenewRebookActions.getEncryptedDocumentDetailFailure, (state, action) => {
        state.loading.getEncryptedDocumentDetail = false;
        state.errors.getEncryptedDocumentDetail = action.payload.error;
      })

      .addCase(loanRenewRebookActions.getLoanCreationStatus, state => {
        state.loading.getLoanCreationStatus = true;
        state.errors.getLoanCreationStatus = initialState.errors.getLoanCreationStatus;
      })
      .addCase(loanRenewRebookActions.getLoanCreationStatusSuccess, (state, action) => {
        state.loading.getLoanCreationStatus = false;
        state.loanCreationStatus = action.payload.response;
        state.errors.getLoanCreationStatus = null;
      })
      .addCase(loanRenewRebookActions.getLoanCreationStatusFailure, (state, action) => {
        state.loading.getLoanCreationStatus = false;
        state.errors.getLoanCreationStatus = action.payload.error;
      }),
});

export default loanRenewalRebookSlice.reducer;

const {
  setSelectedMileStone,
  toggleModals,
  resetLoanRenewalJobDetails,
  setSelectedLender,
  setAutoLoanRenewalStatus,
  setLoading: setLoanRenewalRebookLoading,
  setError: setLoanRenewalRebookError,
} = loanRenewalRebookSlice.actions;

export {
  setSelectedMileStone,
  toggleModals,
  resetLoanRenewalJobDetails,
  setSelectedLender,
  setAutoLoanRenewalStatus,
  setLoanRenewalRebookLoading,
  setLoanRenewalRebookError,
};
