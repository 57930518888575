import React, {useCallback, useRef} from "react";
import {makeStyles} from "@mui/styles";
import {Paper} from "@mui/material";
import {Col, Row} from "react-bootstrap";

import axios from "_metronic/utils/axios";
import {API_ENDPOINTS} from "app/constants/constants";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function UserDetails(props) {
  const classes = useStyles2();
  const {showError} = useSnackBar();
  const user = useAppSelector(authSelectors.getUser);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState({
    filters: false,
  });

  const verticalData = [
    {
      name: "User ID",
      value: "id",
    },
    {
      name: "Name",
      value: "name",
    },
    {
      name: "Mobile No",
      value: "mobile",
    },
    {
      name: "Email",
      value: "email",
    },
    {
      name: "Gold Balance(gms)",
      value: "balance",
    },
    {
      name: "Gold Price(INR)",
      value: "price",
    },
    {
      name: "Gold Balance(INR)",
      value: "balanceInr",
    },
  ];

  const counter = useRef(0);

  const fetchTableData = useCallback(
    (userId) => {
      setLoading({...loading, filters: true});
      axios
        .request({
          method: API_ENDPOINTS.USER.GET_USER_DETAILS.METHOD,
          url: API_ENDPOINTS.USER.GET_USER_DETAILS.URL.replace(
            ":userId",
            userId,
          ),
          data: null,
        })
        .then((response) => {
          if (response && response.data && response.data.result) {
            if (user?.userRoleList?.includes("AUGMONT")) {
              setLoading({...loading, filters: false});
              setData(response.data.result);
            } else {
              fetchUserVault(userId, (error, userVault) => {
                if (error) {
                  setLoading({...loading, filters: false});
                  setData(response.data.result);
                  return;
                }
                setLoading({...loading, filters: false});
                response.data.result = Object.assign(
                  response.data.result,
                  userVault,
                );
                const price = localStorage.getItem("price_GOLD_AUGMONT");
                if (price && price !== "null") {
                  response.data.result.price = price;
                  response.data.result.balanceInr = (
                    price * response.data.result.balance
                  ).toFixed(2);
                }
                setData(response.data.result);
              });
            }
          }
        })
        .catch((err) => {
          setLoading({...loading, filters: false});
          if (
            err &&
            err.response &&
            [401, 403, 422].includes(err.response.status)
          ) {
            showError(
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.message,
            );
          } else {
            showError("Something went wrong");
          }
        });
    },
    [loading, user, showError],
  );

  React.useEffect(() => {
    if (props.userId && counter.current === 0) {
      counter.current = 1;
      fetchTableData(props.userId);
    }
  }, [fetchTableData, props.userId]);

  function fetchUserVault(userId, callback) {
    axios
      .request({
        method: API_ENDPOINTS.USER.GET_USER_VAULT.METHOD,
        url:
          `${API_ENDPOINTS.USER.GET_USER_VAULT.URL.replace(":userId", userId)
          }?assetName=GOLD&partnerName=AUGMONT`,
        data: null,
      })
      .then((response2) => {
        if (response2 && response2.data && response2.data.result) {
          return callback(null, response2.data.result);
        }
        return callback(null, {});

      })
      .catch((err) => {
        return callback(err, null);
      });
  }

  return (
    <Paper className={classes.root}>

      {/* HEADER */}
      {props.header ? (
        <Row className="mx-0">
          <Col className={classes.header}>
            USER DETAILS FOR -{" "}
            <span
              style={{
                color: "blue",
                fontStyle: "italic underline",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => props.onClick()}
            >
              <b>
                <u>{props.userId}</u>
              </b>
            </span>
          </Col>
        </Row>
      ) : null}

      {verticalData && !loading.filters ? (
        <div className="mx-5 my-5">
          {verticalData.map((row, index) => {
            return (
              <Row key={index} className="my-4" justify="center">
                <Col md="5" xs="5" align="left">
                  {row.name}
                </Col>
                <Col md="1" xs="1">
                  :
                </Col>
                <Col md="4" xs="4" align="left">
                  <span style={{overflowWrap: "break-word"}}>
                    {data[row.value]}
                  </span>
                </Col>
              </Row>
            );
          })}
        </div>
      ) : (
        <Row align="center">
          <Col>
            <span
              className="spinner-grow spinner-grow-xl"
              style={{width: "100px", height: "100px", color: "#FF92AC"}}
            ></span>
          </Col>
        </Row>
      )}
    </Paper>
  );
}
