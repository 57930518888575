import React, {FC} from "react";
import styled from "@emotion/styled";
import {Link} from "@mui/material";

const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: #ce9f29;
  cursor: pointer;

  &:not([href]) {
    color: #ce9f29;
  }
`;

const IGLink: FC = (props) => {
  return (
    <StyledLink
      underline="none"
      {...props}
    >
      {props.children}
    </StyledLink>
  );
};

export default IGLink;