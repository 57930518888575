import {LoanSummaryApiInterface, LoanSummaryApiResources} from "app/store/loanSummary/loanSummary.types";
import {goldApiService} from "../../../plugins/axios";

const LOAN_SUMMARY_ENDPOINTS: LoanSummaryApiResources = {
  GET_LOAN_SUMMARY: {
    URL: "admin/business/loan/:loanId/summary", // getDetail
    METHOD: "GET",
  },
  GET_LENDER_LOAN_SUMMARY: {
    URL: "/admin/business/lender/loan/:loanId/summary",
    METHOD: "GET",
  },
};

const loanSummaryApi: LoanSummaryApiInterface = {
  getLoanSummaryDetails: async ({loanId, getDetail}) => {
    return await goldApiService({
      resource: LOAN_SUMMARY_ENDPOINTS.GET_LOAN_SUMMARY,
      options: {
        pathVars: {loanId},
        queryParams: {getDetail},
      },
    });
  },
  getLenderLoanSummaryDetails: async ({loanId, getDetail}) => {
    return await goldApiService({
      resource: LOAN_SUMMARY_ENDPOINTS.GET_LENDER_LOAN_SUMMARY,
      options: {
        pathVars: {loanId},
        queryParams: {getDetail},
      },
    });
  },
};

export {LOAN_SUMMARY_ENDPOINTS};
export default loanSummaryApi;