import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import {bankDetailsActionTypes} from "./bankDetails.actions";
import bankDetailsActions from "./bankDetails.actions";
import {
  GetBankDetailsPayload,
  BankDetailsResponse,
  UpdateBankDetailsPayload,
  VerifyBankDetailsPayload,
} from "./bankDetails.types";

import {snackBarActions} from "app/store/snackbar";
import loanRenewRebookActions from "../loanRenewalRebook.actions";

const {
  getBankDetailsSuccess,
  getBankDetailsFailure,
  updateBankDetailsSuccess,
  updateBankDetailsFailure,
  verifyBankDetailsSuccess,
  verifyBankDetailsFailure,
  verifyBankDetails,
} = bankDetailsActions;

function* generalErrorFlow(action: PayloadAction<BankDetailsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getBankDetailsFlow(action: PayloadAction<GetBankDetailsPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.bankDetails.getBankDetails,
    action.payload,
  );
  if (error) {
    yield put(getBankDetailsFailure({response: null, error}));
  } else {
    yield put(getBankDetailsSuccess({response, error: null}));
  }
}
function* updateBankDetailsFlow(
  action: PayloadAction<{
    crId: number;
    payload: UpdateBankDetailsPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.bankDetails.updateBankDetails,
    action.payload.crId,
    action.payload.payload,
  );
  if (error) {
    yield put(updateBankDetailsFailure({response: null, error}));
  } else {
    yield put(updateBankDetailsSuccess({response, error: null}));
    yield put(
      verifyBankDetails({
        crId: action.payload.crId,
        crJobId: action.payload.payload.crJobId,
        bankDetailType: "LOAN_BANK_DETAIL",
      }),
    );
  }
}

function* verifyBankDetailsFlow(
  action: PayloadAction<VerifyBankDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.bankDetails.verifyBankDetails,
    action.payload,
  );
  if (error) {
    yield put(verifyBankDetailsFailure({response: null, error}));
  } else {
    yield put(verifyBankDetailsSuccess({response, error: null}));
  }
}

export default function* bankDetailsSaga() {
  yield takeLatest(bankDetailsActionTypes.GetBankDetails, getBankDetailsFlow);
  yield takeLatest(
    bankDetailsActionTypes.GetBankDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    bankDetailsActionTypes.UpdateBankDetails,
    updateBankDetailsFlow,
  );
  yield takeLatest(
    bankDetailsActionTypes.VerifyBankDetails,
    verifyBankDetailsFlow,
  );
  yield takeLatest(
    bankDetailsActionTypes.VerifyBankDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    bankDetailsActionTypes.UpdateBankDetailsFailure,
    generalErrorFlow,
  );
}
