import React, {FC, useState} from "react";
import {IGDialog, IGTextField, IGButton} from "app/components";
import {Box} from "@mui/material";
import {useIGTranslation} from "app/infra/plugins/i18n";
import returnToSummaryMilestone from "api/activeLoan/returnToSummaryMilestone";
import {useSnackBar} from "app/store/hooks";

interface DocumentSendBackDialogProps {
  crID: number;
  getMileStoneOfCr: () => void;
  fetchLenderDocuments: () => void;
  activeSendBackEnum: string;
  onModalHandler: () => void;
  setIsSendBackDialogOpen: any;
  isSendBackDialogOpen: boolean;
}

const DocumentSendBackDialog: FC<DocumentSendBackDialogProps> = ({
  crID,
  getMileStoneOfCr,
  fetchLenderDocuments,
  activeSendBackEnum,
  isSendBackDialogOpen,
  onModalHandler,
  setIsSendBackDialogOpen,
}) => {
  const {showSuccess, showError} = useSnackBar();
  const {t, tModalTitle, tInputLabel} = useIGTranslation();

  const [isSendBackBtnLoading, setIsSendBackBtnLoading] = useState<boolean>(
    false,
  );
  const [sendBackReason, setSendBackReason] = useState<string>("");

  const onReturnToSummaryMilestoneClick = async (sendBackType: string) => {
    if (!sendBackReason) {
      showError("Please provide send back reason");
      return;
    }
    setIsSendBackBtnLoading(true);
    const offerResponse = await returnToSummaryMilestone({
      crId: crID,
      remark: sendBackReason,
      remarkEnum: sendBackType,
    });

    if (offerResponse.statusCode === 200) {
      getMileStoneOfCr();
      showSuccess(`${t("ACTIVE_LOANS.LM_HAS_BEEN_NOTIFIED")}`);
      onModalHandler();
      fetchLenderDocuments();
      setIsSendBackBtnLoading(false);
    } else {
      showError(
        `Error: ${offerResponse?.error?.message || "Something went wrong"}`,
      );
    }
    setIsSendBackBtnLoading(false);
  };

  return (
    <IGDialog
      open={isSendBackDialogOpen}
      hideFooter
      onClose={() => setIsSendBackDialogOpen(false)}
      title={tModalTitle("ARE_YOU_SURE_YOU_WANT_TO_ASK_LM_TO_EDIT")}
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <IGTextField
          name="sendBackReason"
          label={tInputLabel("SEND_BACK_REASON")}
          placeholder={tInputLabel("PLEASE_ENTER", {
            field: "send back reason",
          })}
          fullWidth
          value={sendBackReason}
          onChange={(e) => setSendBackReason(e.target.value)}
        />

        <Box display="flex" justifyContent="flex-end" gap="0.5rem">
          <IGButton
            color="golden"
            onClick={() => {
              onReturnToSummaryMilestoneClick(activeSendBackEnum);
            }}
            loading={isSendBackBtnLoading}
          >
            {`${t("CONFIRM")}`}
          </IGButton>
          <IGButton
            color="inherit"
            onClick={() => {
              setIsSendBackDialogOpen(false);
              setSendBackReason("");
            }}
          >
            {`${t("CANCEL")}`}
          </IGButton>
        </Box>
      </Box>
    </IGDialog>
  );
};

export default DocumentSendBackDialog;
