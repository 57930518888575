import React, {useEffect, useState, useCallback, ChangeEvent} from "react";
import {
  Box,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {IGDocumentViewer} from "app/components";
import SwipeableViews from "react-swipeable-views";
import {ChevronLeftRounded, ChevronRightRounded} from "@mui/icons-material";
import {CrLandDocumentDetailDto} from "app/models/activeLoans/activeLoans.model";
import AgriDocumentLabel from "../AgriDocumentLabel";
import {ViewPdfButton} from "../../../../Leegality/styles";
import ArrowUpFromBracket from "app/AppLayout/Icons/ArrowUpFromBracket";
import {fileType} from "app/enums/generalPurpose";

interface AgriMatchingDocumentsProps {
  matchingDocumentsList: CrLandDocumentDetailDto[];
  isOriginalDocument: boolean;
  selectedMatchedDocumentId: number[];
  setSelectedMatchedDocumentId: (ids: number[]) => void;
  setIsLastMatchingDoc: (args: boolean) => void;
  setDocChoosen: (args: number) => void;
}
const AgriMatchingDocumentsCard = ({
  matchingDocumentsList,
  isOriginalDocument,
  selectedMatchedDocumentId,
  setSelectedMatchedDocumentId,
  setIsLastMatchingDoc,
  setDocChoosen,
}: AgriMatchingDocumentsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = matchingDocumentsList.length - 1;
      } else if (newIndex >= matchingDocumentsList.length) {
        newIndex = 0;
      }
      setActiveIndex(newIndex);
    },
    [matchingDocumentsList.length],
  );

  const viewPdf = (link: string) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        // Move the carousel backward
        updateIndex(activeIndex - 1);
      } else if (e.key === "ArrowRight") {
        // Move the carousel forward
        updateIndex(activeIndex + 1);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeIndex, updateIndex]);

  const handleCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    matchingDocId: number,
    index: number,
  ) => {
    if (event.target.checked) {
      setSelectedMatchedDocumentId([matchingDocId]);
      setDocChoosen(index);
    } else {
      setSelectedMatchedDocumentId([]);
      setDocChoosen(0);
    }
  };

  useEffect(() => {
    if (
      activeIndex === matchingDocumentsList.length - 1 &&
      !isOriginalDocument
    ) {
      setIsLastMatchingDoc(true);
    }
  }, [
    activeIndex,
    isOriginalDocument,
    matchingDocumentsList.length,
    setIsLastMatchingDoc,
  ]);

  return (
    <Box>
      {matchingDocumentsList.length > 0 && (
        <SwipeableViews
          index={activeIndex}
          onChangeIndex={(index: number) => setActiveIndex(index)}
        >
          {matchingDocumentsList.map((item, index) => (
            <>
              <Box id={`doc_${index}`} key={`doc_${index}`}>
                <Typography fontWeight={700} variant="h6" mb={0.75}>
                  {isOriginalDocument
                    ? "Current Agri Document"
                    : `Matching Agri Document #${index + 1}`}
                </Typography>
                {item?.landDocumentProof?.fileType === fileType.PDF ? (
                  <ViewPdfButton
                    onClick={() => viewPdf(item?.landDocumentProof?.fileUrl)}
                  >
                    <b>View</b> &nbsp;
                    <ArrowUpFromBracket />
                  </ViewPdfButton>
                ) : (
                  <IGDocumentViewer
                    key={index}
                    document={item?.landDocumentProof}
                  />
                )}
              </Box>
              <Box>
                <AgriDocumentLabel agriDocDetails={item} />
              </Box>

              {!isOriginalDocument && (
                <Box display="flex" justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="selectedDocument"
                        checked={selectedMatchedDocumentId.includes(
                          item?.id || 0,
                        )}
                        onChange={(event) =>
                          handleCheckBoxChange(event, item?.id || 0, index)
                        }
                        inputProps={{"aria-label": "controlled"}}
                      />
                    }
                    label="Select as matching document"
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m={1}
                  >
                    <IconButton onClick={() => updateIndex(activeIndex - 1)}>
                      <ChevronLeftRounded />
                    </IconButton>
                    <Typography color="gray">{`${activeIndex + 1} of ${
                      matchingDocumentsList.length
                    }`}</Typography>
                    <IconButton onClick={() => updateIndex(activeIndex + 1)}>
                      <ChevronRightRounded />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </>
          ))}
        </SwipeableViews>
      )}
    </Box>
  );
};

export default AgriMatchingDocumentsCard;
