import {RootState} from "app/store/store";

const occupationDetailsSelectors = {
  getOccupationDetails: (state: RootState) =>
    state.occupationDetails.occupationDetails,
  getWorkSectorDetails: (state: RootState) =>
    state.occupationDetails.workSectorDetails,
  getBorrowerCategoryList: (state: RootState) =>
    state.occupationDetails.borrowerCategoryList,
  getWeakerSectionList: (state: RootState) =>
    state.occupationDetails.weakerCategoryList,
  getIndustryList: (state: RootState) =>
    state.occupationDetails.industryTypeList,
  getOccupationList: (state: RootState) =>
    state.occupationDetails.occupationList,
  getSubSectorList: (state: RootState) => state.occupationDetails.subSectorList,
  getloanPurposeList: (state: RootState) =>
    state.occupationDetails.loanPurposeList,
  getLoading: (state: RootState) => state.occupationDetails.loading,
  getErrors: (state: RootState) => state.occupationDetails.errors,
};

export default occupationDetailsSelectors;
