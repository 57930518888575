import {Box, CircularProgress, TextField} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";

import getDocument from "api/business/getDocument";
import getPersonalDetails from "api/business/getPersonalDetail";
import getAddress from "api/business/getAddress";
import updateMileStones from "api/business/completeMileStone";
import getNextJOB from "api/business/getNextJob";
import updateAddress from "api/business/updateAddress";

import updatePersonalApi from "api/business/updatePersonalDetails";
import Addhar from "../CommonComponents/Addhar";
import Pan from "../CommonComponents/Pan";
import {useSnackBar} from "app/store/hooks";
import styles from "../styles";

const ReviewDetails = ({
  selectedPayload,
  updateIndex,
  currentIndex,
  isBackMovement,
  isSameCreditManager,
  currentMileStone,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const classes = styles();
  const snackbar = useSnackBar();
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      document: null,
      personalDetails: null,
      address: null,
      permanentAddress: null,
    },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");

  const [addressLineone, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [addressLineThree, setAddressLineThree] = useState("");
  const [permanentYearsOfResidence, setPermanentYearsOfResidence] = useState(
    "",
  );
  const [
    residentialYearsOfResidence,
    setResidentialYearsOfResidence,
  ] = useState("");
  const [permanentAddressOne, setPermanentAddressOne] = useState("");
  const [permanetAddressTwo, setPermanentAddressTwo] = useState("");
  const [permanentAddressThree, setPermanentAddressThree] = useState("");

  const [ownerShipTypeResidential, setOwnerShipTypeResidential] = useState(
    null,
  );
  const [ownerShipTypePermanent, setOwnerShipTypePermanent] = useState(null);

  const [addhar, setAddhar] = useState("");
  const [pan, setPan] = useState("");

  const counter = useRef(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (counter.current === 0) {
          counter.current = 1;

          if (
            currentMileStone.data.milestone ===
            "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
          ) {
            const [
              documentResponse,
              getPersonDetailsResponse,
              getAddressResponse,
              permanentAddressResponse,
            ] = await Promise.all([
              getDocument({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                documentType: ["AADHAR", "PAN"],
              }),
              getPersonalDetails({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                personalDetailType: "LOAN_PERSONAL_DETAIL",
              }),
              getAddress({
                accessToken: localStorage.getItem("authToken"),
                addressType: "RESIDENTIAL_ADDRESS",
                crId: selectedPayload.id,
              }),
              getAddress({
                accessToken: localStorage.getItem("authToken"),
                addressType: "PERMANENT_ADDRESS",
                crId: selectedPayload.id,
              }),
            ]);

            if (
              documentResponse.statusCode === 200 &&
              getPersonDetailsResponse.statusCode === 200 &&
              getAddressResponse.statusCode === 200 &&
              permanentAddressResponse.statusCode === 200
            ) {
              setStates({
                error: false,
                loading: false,
                payload: {
                  document: documentResponse.payload,
                  personalDetails: getPersonDetailsResponse.payload,
                  address: getAddressResponse.payload,
                  permanentAddress: permanentAddressResponse.payload,
                },
              });

              setFirstName(getPersonDetailsResponse.payload.firstName);
              setLastName(getPersonDetailsResponse.payload.lastName);
              setMiddleName(getPersonDetailsResponse.payload.middleName);

              const addharDocument = documentResponse.payload.find(
                (e) => e.documentType === "AADHAR",
              );

              const panDocument = documentResponse.payload.find((e) => {
                if (
                  currentMileStone.data.milestone ===
                  "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
                ) {
                  return e.documentType === "PAN";
                }
                return e.documentType === "TAKEOVER_PAN";
              });

              setAddhar(addharDocument?.documentNumber);
              setPan(panDocument?.documentNumber);

              setAddressLineOne(
                (getAddressResponse?.payload || [])[0]?.addressLine1,
              );
              setAddressLineTwo(getAddressResponse?.payload[0]?.addressLine2);
              setAddressLineThree(getAddressResponse?.payload[0]?.addressLine3);

              setOwnerShipTypeResidential(
                getAddressResponse?.payload[0]?.ownershipType,
              );

              setResidentialYearsOfResidence(
                getAddressResponse?.payload[0]?.yearsInResidence || 0,
              );

              setPermanentYearsOfResidence(
                permanentAddressResponse?.payload[0]?.yearsInResidence || 0,
              );
              setOwnerShipTypePermanent(
                permanentAddressResponse?.payload[0]?.ownershipType,
              );

              setPermanentAddressOne(
                permanentAddressResponse?.payload[0]?.addressLine1,
              );
              setPermanentAddressTwo(
                permanentAddressResponse?.payload[0]?.addressLine2,
              );
              setPermanentAddressThree(
                permanentAddressResponse?.payload[0]?.addressLine3,
              );
            } else {
              setStates({
                error: true,
                loading: false,
                payload: {
                  document: null,
                  personalDetails: null,
                  address: null,
                  permanentAddress: null,
                },
              });
            }
          } else {
            const [
              documentResponse,
              getPersonDetailsResponse,
              getAddressResponse,
              permanentAddressResponse,
            ] = await Promise.all([
              getDocument({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                documentType: ["AADHAR", "PAN"],
              }),
              getPersonalDetails({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                personalDetailType: "LOAN_PERSONAL_DETAIL",
              }),
              getAddress({
                accessToken: localStorage.getItem("authToken"),
                addressType: "RESIDENTIAL_ADDRESS",
                crId: selectedPayload.id,
              }),
              getAddress({
                accessToken: localStorage.getItem("authToken"),
                addressType: "PERMANENT_ADDRESS",
                crId: selectedPayload.id,
              }),
            ]);

            if (
              documentResponse.statusCode === 200 &&
              getPersonDetailsResponse.statusCode === 200 &&
              getAddressResponse.statusCode === 200 &&
              permanentAddressResponse.statusCode === 200
            ) {
              setStates({
                error: false,
                loading: false,
                payload: {
                  document: documentResponse.payload,
                  personalDetails: getPersonDetailsResponse.payload,
                  address: getAddressResponse.payload,
                  permanentAddress: permanentAddressResponse.payload,
                },
              });

              const addharDocument = documentResponse.payload.find(
                (e) => e.documentType === "AADHAR",
              );

              const panDocument = documentResponse.payload.find((e) => {
                if (
                  currentMileStone.data.milestone ===
                  "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
                ) {
                  return e.documentType === "PAN";
                }
                return e.documentType === "TAKEOVER_PAN";
              });

              setAddhar(addharDocument?.documentNumber);
              setPan(panDocument?.documentNumber);

              setFirstName(getPersonDetailsResponse.payload.firstName);
              setLastName(getPersonDetailsResponse.payload.lastName);
              setMiddleName(getPersonDetailsResponse.payload.middleName);

              setAddressLineOne(
                (getAddressResponse.payload || [])[0]?.addressLine1,
              );
              setAddressLineTwo(
                (getAddressResponse.payload[0] || [])?.addressLine2,
              );
              setAddressLineThree(getAddressResponse?.payload[0]?.addressLine3);
              setResidentialYearsOfResidence(
                getAddressResponse?.payload[0]?.yearsInResidence || 0,
              );

              setOwnerShipTypeResidential(
                getAddressResponse?.payload[0]?.ownershipType,
              );

              setPermanentYearsOfResidence(
                permanentAddressResponse?.payload[0]?.yearsInResidence || 0,
              );

              setOwnerShipTypePermanent(
                permanentAddressResponse?.payload[0]?.ownershipType,
              );

              setPermanentAddressOne(
                permanentAddressResponse.payload[0]?.addressLine1,
              );
              setPermanentAddressTwo(
                permanentAddressResponse.payload[0]?.addressLine2,
              );
              setPermanentAddressThree(
                permanentAddressResponse.payload[0]?.addressLine3,
              );
            } else {
              setStates({
                error: true,
                loading: false,
                payload: {
                  document: null,
                  personalDetails: null,
                  address: null,
                  permanentAddress: null,
                },
              });
            }
          }
        }
      } catch (error) {
        setStates({
          error: true,
          loading: false,
          payload: {
            document: null,
            personalDetails: null,
            address: null,
            permanentAddress: null,
          },
        });
      }
    })();
  }, [
    currentMileStone.data.milestone,
    selectedPayload.id,
    states.payload.document,
  ]);

  const onClickHandler = useCallback(async () => {
    try {
      setLoading(true);

      if (firstName.length === 0) {
        snackbar.showError("First Name is required");
        setLoading(false);

        return;
      }

      if (
        states.payload.permanentAddress.length === 0 &&
        addressLineone.length === 0
      ) {
        snackbar.showError("Address Line One is required");
        setLoading(false);

        return;
      }

      if (
        states.payload.permanentAddress.length !== 0 &&
        permanentAddressOne.length === 0
      ) {
        snackbar.showError("Permanent Address Line One is required");
        setLoading(false);

        return;
      }

      const getNextJobResponse = await getNextJOB({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });

      if (getNextJobResponse.statusCode === 200) {
        const personalResponse = await updatePersonalApi(selectedPayload.id, {
          crJobId: getNextJobResponse.id,
          ...states.payload.personalDetails,
          firstName: firstName,
          lastName: lastName,
          middleName: middleName,
          primaryPhoneNumber: states.payload.personalDetails.primaryPhoneNumber,
          primaryEmail: states.payload.personalDetails.primaryEmail,
        });

        if (personalResponse.statusCode !== 200) {
          snackbar.showError(personalResponse.error.toString());
          setLoading(false);

          return;
        }

        if (states.payload.permanentAddress.length === 0) {
          const [residentialAddressResponse] = await Promise.all([
            updateAddress({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              crJobId: getNextJobResponse.id,
              ...(states.payload.address[0] || {}),
              addressLine1: addressLineone,
              addressLine2: addressLineTwo,
              addressLine3: addressLineThree,
              ownershipType: ownerShipTypeResidential,
              yearsOfResidence: residentialYearsOfResidence,
            }),
          ]);

          if (residentialAddressResponse.statusCode === 200) {
            if (
              currentMileStone.data.milestone ===
              "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
            ) {
              const response = await updateMileStones({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                milestone: "CR_GOLD_LOAN_FRESH_SUMMARY",
              });
              if (response.statusCode === 200) {
                snackbar.showSuccess("Success");
                getMileStoneOfCr();
              } else {
                snackbar.showError(response.error.toString());
              }
            } else {
              const response = await updateMileStones({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                milestone: "CR_GOLD_LOAN_BT_SUMMARY",
              });
              if (response.statusCode === 200) {
                snackbar.showSuccess("Success");
                getMileStoneOfCr();
              } else {
                snackbar.showError(response.error.toString());
              }
            }
          } else {
            snackbar.showError(residentialAddressResponse.error.toString());
            setLoading(false);
          }
        } else {
          const [
            residentialAddressResponse,
            permanentAddressResponse,
          ] = await Promise.all([
            updateAddress({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              crJobId: getNextJobResponse.id,
              ...(states.payload.address[0] || {}),
              addressLine1: addressLineone,
              addressLine2: addressLineTwo,
              addressLine3: addressLineThree,
              ownershipType: ownerShipTypeResidential,
              yearsOfResidence: residentialYearsOfResidence,
            }),
            updateAddress({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              crJobId: getNextJobResponse.id,
              ...states.payload.permanentAddress[0],
              addressLine1: permanentAddressOne,
              addressLine2: permanetAddressTwo,
              addressLine3: permanentAddressThree,
              ownershipType: ownerShipTypePermanent,
              yearsOfResidence: permanentYearsOfResidence,
            }),
          ]);

          if (
            residentialAddressResponse.statusCode === 200 &&
            permanentAddressResponse.statusCode === 200
          ) {
            if (
              currentMileStone.data.milestone ===
              "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
            ) {
              const response = await updateMileStones({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                milestone: "CR_GOLD_LOAN_FRESH_SUMMARY",
              });
              if (response.statusCode === 200) {
                snackbar.showSuccess("Success");

                getMileStoneOfCr();

                // updateIndex(currentIndex + 1);
              } else {
                snackbar.showError(response.error.toString());
                setLoading(false);
              }
            } else {
              const response = await updateMileStones({
                accessToken: localStorage.getItem("authToken"),
                crId: selectedPayload.id,
                milestone: "CR_GOLD_LOAN_BT_SUMMARY",
              });
              if (response.statusCode === 200) {
                snackbar.showSuccess("Success");

                getMileStoneOfCr();
              } else {
                snackbar.showError(response.error.toString());
                setLoading(false);
              }
            }
          } else {
            snackbar.showError(
              residentialAddressResponse.error.toString() ||
                permanentAddressResponse.error.toString(),
            );
            setLoading(false);
          }
        }
      } else {
        snackbar.showError(getNextJobResponse.error.toString());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      snackbar.showError("Something went wrong");
    }
  }, [
    firstName,
    states.payload.permanentAddress,
    states.payload.personalDetails,
    states.payload.address,
    addressLineone,
    permanentAddressOne,
    selectedPayload.id,
    snackbar,
    lastName,
    middleName,
    addressLineTwo,
    addressLineThree,
    ownerShipTypeResidential,
    residentialYearsOfResidence,
    currentMileStone.data.milestone,
    getMileStoneOfCr,
    permanetAddressTwo,
    permanentAddressThree,
    ownerShipTypePermanent,
    permanentYearsOfResidence,
  ]);

  if (states.error) {
    return <div>Something went wrong</div>;
  }

  if (states.loading) {
    return <CircularProgress></CircularProgress>;
  }

  const addharDocument = states.payload?.document?.find(
    (e) => e.documentType === "AADHAR",
  );

  const panDocument = states.payload?.document?.find((e) => {
    if (
      currentMileStone.data.milestone === "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
    ) {
      return e.documentType === "PAN";
    }
    return e.documentType === "TAKEOVER_PAN";
  });

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>PLEASE VERIFY THE FOLLOWING DETAILS</div>

        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <TextField
            fullWidth
            label="Middle Name"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />

          <TextField
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>

        <div className={classes.textContainer}>
          <div className={classes.text}>RESIDENTIAL ADDRESS:</div>

          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              fullWidth
              label="Address Line One"
              value={addressLineone}
              onChange={(e) => setAddressLineOne(e.target.value)}
            />
            <TextField
              label="Address Line two"
              fullWidth
              value={addressLineTwo}
              onChange={(e) => setAddressLineTwo(e.target.value)}
            />

            <TextField
              label="Address Line Three"
              fullWidth
              value={addressLineThree}
              onChange={(e) => setAddressLineThree(e.target.value)}
            />
            <TextField
              sx={{
                marginTop: 1,
              }}
              fullWidth
              value={
                residentialYearsOfResidence ? residentialYearsOfResidence : "0"
              }
              onChange={(e) => setResidentialYearsOfResidence(e.target.value)}
              label="Residentail Address Years Of Residence"
            />
          </Box>

          <div style={{marginTop: "1rem"}}>
            <TextField
              label="OwnerShip Type"
              fullWidth
              value={ownerShipTypeResidential ? ownerShipTypeResidential : "NA"}
            />
          </div>

          {(states.payload.address[0] || {}).pincode && (
            <div className={classes.subText}>
              Pincode:{" "}
              {`${(states.payload.address[0] || {}).pincode} (${
                states?.payload?.address[0].city
              } ${states.payload?.address[0]?.state})`}
            </div>
          )}
        </div>

        {states.payload.permanentAddress.length !== 0 && (
          <div className={classes.textContainer}>
            <div className={classes.text}>PERMANENT ADDRESS:</div>
            <TextField
              sx={{
                marginTop: 1,
              }}
              fullWidth
              value={permanentAddressOne}
              label="Address Line One"
              onChange={(e) => setPermanentAddressOne(e.target.value)}
            />
            <TextField
              sx={{
                marginTop: 1,
              }}
              fullWidth
              value={permanetAddressTwo}
              onChange={(e) => setPermanentAddressTwo(e.target.value)}
              label="Address Line two"
            />
            <TextField
              sx={{
                marginTop: 1,
              }}
              fullWidth
              value={permanentAddressThree}
              onChange={(e) => setPermanentAddressThree(e.target.value)}
              label="Address Line Three"
            />
            <TextField
              sx={{
                marginTop: 1,
              }}
              fullWidth
              value={
                permanentYearsOfResidence ? permanentYearsOfResidence : "0"
              }
              onChange={(e) => setPermanentYearsOfResidence(e.target.value)}
              label="Permanent Years Of Residence"
            />

            <div style={{marginTop: "1rem"}}>
              <TextField
                label="OwnerShip Type"
                fullWidth
                value={ownerShipTypePermanent ? ownerShipTypePermanent : "NA"}
              />
            </div>

            {states.payload.permanentAddress[0].pincode && (
              <div className={classes.subText}>
                Pincode:{" "}
                {`${states.payload.permanentAddress[0].pincode} (${states?.payload?.permanentAddress[0].city} ${states.payload?.permanentAddress[0]?.state})`}
              </div>
            )}
          </div>
        )}

        {/* Removed this block of docs because of rbi compliance */}
        {/* <div className={classes.textContainer}>
          <TextField
            disabled
            fullWidth
            label="ADHAAR"
            value={addhar}
            type="number"
            onChange={(e) => setAddhar(e.target.value)}
          />

          <Addhar addhar={addharDocument} />
        </div> */}

        {/* {panDocument && (
          <div className={classes.textContainer}>
            <TextField
              disabled
              onChange={(e) => setPan(e.target.value)}
              fullWidth
              label="PAN"
              value={pan}
            />

            <Pan pan={panDocument} />
          </div>
        )} */}
      </div>

      {!isBackMovement && isSameCreditManager && (
        <>
          {loading && <CircularProgress />}
          <div className={classes.buttonContainer}>
            <button
              disabled={loading}
              onClick={onClickHandler}
              className={classes.ApproveButton}
            >
              PROCEED
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ReviewDetails;
