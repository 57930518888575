import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";

import personalDetailsActions from "./personalDetails.actions";
import {personalDetailsActionTypes} from "./personalDetails.actions";
import {
  PersonalDetailsResponse,
  GetPersonalDetailsPayload,
  UpdatePersonalDetailsPayload,
  GetKycDocumentPayload,
  GetGuarantorPersonalDetailsPayload,
  GetGuarantorAddressDetailsPayload,
} from "./personalDetails.types";

import {snackBarActions} from "app/store/snackbar";
import loanRenewRebookActions from "../loanRenewalRebook.actions";

const {
  getPersonalDetailsSuccess,
  getPersonalDetailsFailure,
  getAddressDetailsSuccess,
  getAddressDetailsFailure,
  updatePersonalDetailsSuccess,
  updatePersonalDetailsFailure,
  getDocumentsMasterListSuccess,
  getDocumentsMasterListFailure,
  getKycDocumentsSuccess,
  getKycDocumentsFailure,
  getGuarantorPersonalDetailsSuccess,
  getGuarantorPersonalDetailsFailure,
  getGuarantorAddressDetailsSuccess,
  getGuarantorAddressDetailsFailure,
} = personalDetailsActions;

function* generalErrorFlow(action: PayloadAction<PersonalDetailsResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getPersonalDetailsFlow(
  action: PayloadAction<GetPersonalDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getPersonalDetails,
    action.payload,
  );
  if (error) {
    yield put(getPersonalDetailsFailure({response: null, error}));
  } else {
    yield put(getPersonalDetailsSuccess({response, error: null}));
  }
}
function* getAddressDetailsFlow(
  action: PayloadAction<GetPersonalDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getAddressDetails,
    action.payload,
  );
  if (error) {
    yield put(getAddressDetailsFailure({response: null, error}));
  } else {
    yield put(getAddressDetailsSuccess({response, error: null}));
  }
}

function* updatePersonalDetailsFlow(
  action: PayloadAction<{
    crId: number;
    crJobId: number;
    payload: UpdatePersonalDetailsPayload;
  }>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.updatePersonalDetails,
    action.payload.crId,
    action.payload.payload,
  );
  if (error) {
    yield put(updatePersonalDetailsFailure({response: null, error}));
  } else {
    yield put(updatePersonalDetailsSuccess({response, error: null}));
    yield put(
      loanRenewRebookActions.updateLoanRenewRebookMilestone({
        crId: action.payload.crId,
        crJobId: action.payload.crJobId,
        crJobStatus: "CM_RR_PERSONAL_DETAIL_VERIFIED",
      }),
    );
  }
}
function* getDocumentsMasterListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getDocumentsMasterList,
  );
  if (error) {
    yield put(getDocumentsMasterListFailure({response: null, error}));
  } else {
    yield put(getDocumentsMasterListSuccess({response, error: null}));
  }
}

function* getKycDocumentsFlow(action: PayloadAction<GetKycDocumentPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getKycDocuments,
    action.payload,
  );
  if (error) {
    yield put(getKycDocumentsFailure({response: null, error}));
  } else {
    yield put(getKycDocumentsSuccess({response, error: null}));
  }
}

function* getGuarantorPersonalDetailsFlow(
  action: PayloadAction<GetGuarantorPersonalDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getGuarantorPersonalDetails,
    action.payload,
  );
  if (error) {
    yield put(getGuarantorPersonalDetailsFailure({response: null, error}));
  } else {
    yield put(getGuarantorPersonalDetailsSuccess({response, error: null}));
  }
}

function* getGuarantorAddressDetailsFlow(
  action: PayloadAction<GetGuarantorAddressDetailsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.personalDetails.getGuarantorAddressDetails,
    action.payload,
  );
  if (error) {
    yield put(getGuarantorAddressDetailsFailure({response: null, error}));
  } else {
    yield put(getGuarantorAddressDetailsSuccess({response, error: null}));
  }
}

export default function* personalDetailsSaga() {
  yield takeLatest(
    personalDetailsActionTypes.GetPersonalDetails,
    getPersonalDetailsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetPersonalDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetAddressDetails,
    getAddressDetailsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetAddressDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.UpdatePersonalDetails,
    updatePersonalDetailsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.UpdatePersonalDetailsFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetDocumentMasterList,
    getDocumentsMasterListFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetDocumentMasterListFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetKycDocuments,
    getKycDocumentsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetKycDocumentsFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    personalDetailsActionTypes.GetGuarantorPersonalDetails,
    getGuarantorPersonalDetailsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetGuarantorPersonalFailure,
    generalErrorFlow,
  );

  yield takeLatest(
    personalDetailsActionTypes.GetGuarantorAddressDetails,
    getGuarantorAddressDetailsFlow,
  );
  yield takeLatest(
    personalDetailsActionTypes.GetGuarantorAddressFailure,
    generalErrorFlow,
  );
}
