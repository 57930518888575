import React, {useCallback, useEffect, useRef, useState} from "react";

import styles from "../styles";
import getCustomerBankDetails from "../../../../../../../api/business/getCustomerBankDetails";
import {CircularProgress} from "@mui/material";
import BankDetailsComponent from "./BankDetails";
import UpdateToLM from "./UpdateToLM";

const BankDetails = ({
  selectedPayload,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const classes = styles();
  const ref = useRef(0);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: null,
    errorMessage: "",
  });

  const getBankDetails = useCallback(async () => {
    try {
      const response = await getCustomerBankDetails({
        accessToken: localStorage.getItem("authToken"),
        bankDetailType: "LOAN_BANK_DETAIL",
        crId: selectedPayload.id,
      });

      if (response.statusCode === 200) {
        setStates({
          loading: false,
          error: false,
          payload: response.payload,
          errorMessage: "",
        });
      } else {
        setStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: response.error,
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: null,
        errorMessage: "Something went wrong",
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    (async () => {
      if (ref.current === 0) {
        ref.current = 1;
        getBankDetails();
      }
    })();
  }, [getBankDetails, selectedPayload.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      getBankDetails();
    }, otherRequestTimer);

    return () => {
      clearInterval(timer);
    };
  }, [getBankDetails, otherRequestTimer]);

  if (states.loading) {
    return <CircularProgress />;
  }
  if (states.error) {
    return <div>{states.errorMessage}</div>;
  }

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>BANK DETAILS SHARED BY CUSTOMER</div>
        <div
          className={classes.subText}
        >{`Account Number: ${states.payload.accountNumber}`}</div>
        <div
          className={classes.subText}
        >{`Beneficiary Name: ${states.payload.beneficiaryName}`}</div>

        <div
          className={classes.subText}
        >{`IFSC Code: ${states.payload.ifscCode}`}</div>

        <div
          className={classes.subText}
        >{`Branch: ${states.payload.branch}`}</div>
        <div className={classes.subText}>{`City: ${states.payload.city}`}</div>
      </div>

      <BankDetailsComponent
        otherRequestTimer={otherRequestTimer}
        selectedPayload={selectedPayload}
      />

      <UpdateToLM
        otherRequestTimer={otherRequestTimer}
        selectedPayload={selectedPayload}
        getMileStoneOfCr={getMileStoneOfCr}
      />
    </>
  );
};

export default BankDetails;
