import React, {useCallback, useEffect, useState} from "react";

import getNextJob from "api/business/getNextJob";

import PersonalLoanTaken from "./PersonalLoanTaken";
import PersonalLoanNotTaken from "./PersonalLoanNotTaken";
import getDocument from "api/business/getDocument";
import getGoldDropLocation from "api/business/getGoldDropLocation";
import getLTV from "api/business/getLTV";
import getLoanSummaryDocument from "api/business/getLoanSummaryDocument";

import styles from "../styles";
import {CircularProgress} from "@mui/material";
import {Link} from "@mui/material";
import ImageLink from "../CommonComponents/ImageLink";
import {CreateNucleasLoanAccounnt} from "api/activeLoan/createNucleasLoanAccount";
import {useAppDispatch, useAppSelector, useSnackBar} from "app/store/hooks";
import postUpdateStatus from "api/accounts/postUpdateStatus";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";

const FinalPayment = ({
  selectedPayload,
  otherRequestTimer,
  isBackMovement,
  isSameCreditManager,
}) => {
  const classes = styles();
  const {showError, showSuccess} = useSnackBar();

  const dispatch = useAppDispatch();
  const postLoanDocsFlag = useAppSelector(
    activeLoansSelectors.getPostLoanDocsFlag,
  );

  const {isLoanSummaryDocVisible, isPledgeCardVisible} = postLoanDocsFlag ?? {};
  const {isPersonalLoanTaken} = selectedPayload;
  const [buttonState, setButtonState] = useState("enabled");
  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      document: null,
      dropLocation: null,
      ltv: null,
      loanSummaryDocument: null,
    },
    errorMessage: "",
  });

  const [loanSummaryDocument, setLoanSummaryDocument] = useState(null);
  const [isLoanSummaryDisable, setIsLoanSummaryDisable] = useState(true);

  const getDetails = useCallback(async () => {
    try {
      const [
        response,
        dropLocationResponse,
        getLTVResponse,
      ] = await Promise.all([
        getDocument({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          documentType: ["CUSTOMER_HOME_IMAGE"],
        }),
        getGoldDropLocation({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
        getLTV({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        }),
      ]);

      if (
        response.statusCode === 200 &&
        dropLocationResponse.statusCode === 200 &&
        getLTVResponse.statusCode === 200
      ) {
        setStates({
          error: false,
          loading: false,
          payload: {
            document: response.payload,
            dropLocation: dropLocationResponse.payload,
            ltv: getLTVResponse.payload,
          },
          errorMessage: "",
        });
      } else {
        setStates({
          error: true,
          loading: false,
          payload: {
            document: null,
            dropLocation: null,
            ltv: null,
          },
          errorMessage:
            response.error.toString() ||
            dropLocationResponse.error.toString() ||
            getLTVResponse.error.toString(),
        });
        setButtonState("enabled");
      }
    } catch (error) {
      setStates({
        error: true,
        loading: false,
        payload: {
          document: null,
          dropLocation: null,
          ltv: null,
        },
        errorMessage: "",
      });
    }
  }, [selectedPayload.id]);

  const getLoanSummary = useCallback(async () => {
    if (!selectedPayload.id) {
      return;
    }
    try {
      const {response, error} = await getLoanSummaryDocument(
        selectedPayload.id,
      );
      if (!error) {
        setLoanSummaryDocument(response?.link);
        setIsLoanSummaryDisable(false);
      } else {
        setIsLoanSummaryDisable(true);
      }
    } catch (error) {}
  }, [selectedPayload.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    getLoanSummary();
  }, [getLoanSummary]);

  useEffect(() => {
    dispatch(
      activeLoansActions.getPostLoanDocsFlag({
        crId: selectedPayload.id,
      }),
    );
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      getDetails();
    }, otherRequestTimer);

    return () => clearInterval(timer);
  }, [getDetails, otherRequestTimer]);

  const handleClick = async () => {
    // setStates({ ...states, loading: true });
    setButtonState("loading");
    const {response, error} = await CreateNucleasLoanAccounnt({
      crId: selectedPayload.id,
    });
    if (error) {
      showError(error.message);
      setStates({
        ...states,
        error: true,
        loading: false,
        errorMessage: error.message,
      });
    } else {
      showSuccess(response.message);
      const nextJob = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        jobType: "CM_PROCESS_LOAN",
      });
      if (nextJob.error) {
        showError(nextJob.error.message);
        setStates({
          ...states,
          error: true,
          loading: false,
          errorMessage: nextJob.error.message,
        });
      } else {
        const updateStatus = await postUpdateStatus({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          crJobId: nextJob.payload.id,
          status: "CM_PROCESS_LOAN_ACCOUNT_CREATED",
          remark: "",
        });
        if (updateStatus.error) {
          showError(updateStatus.error.message);
          setStates({
            ...states,
            error: true,
            loading: false,
            errorMessage: error.message,
          });
        } else {
          if (updateStatus.payload.message) {
            showSuccess(updateStatus.payload.message);
          }
          setButtonState("disabled");
          return;
        }
      }
    }
    setButtonState("enabled");
  };
  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const {dropLocation} = states.payload;

  const summary = states.payload.ltv;

  const {finalLtv} = summary;

  return (
    <>
      {isPersonalLoanTaken ? (
        <PersonalLoanTaken
          finalLtv={finalLtv}
          isBackMovement={isBackMovement}
          handleClick={handleClick}
          selectedPayload={selectedPayload}
        />
      ) : (
        <PersonalLoanNotTaken
          finalLtv={finalLtv}
          selectedPayload={selectedPayload}
          isBackMovement={isBackMovement}
          buttonState={buttonState}
          isPledgeCardVisible={isPledgeCardVisible}
        />
      )}

      {states.payload.document[0]?.fileUrl && (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>OTHER DETAILS</div>

            {states.payload.document[0] && (
              <>
                <ImageLink
                  image={states.payload.document[0]}
                  name="Photo of customer in front of home gate"
                />
              </>
            )}
          </div>

          <div
            className={classes.subText}
          >{`Vault Name: ${dropLocation.name}, ${dropLocation.address}`}</div>
        </>
      )}

      {isLoanSummaryDocVisible && !isLoanSummaryDisable && (
        <div className={classes.textContainer}>
          <div className={classes.text}>LOAN SUMMARY DOCUMENT</div>
          {isBackMovement && (
            <Link
              href={loanSummaryDocument}
              underline="none"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.71,
                letterSpacing: "0.1px",
                marginTop: "0.5rem",
                color: "#ce9f29",
                cursor: "pointer",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"View Loan Summary Document Card"}
            </Link>
          )}
        </div>
      )}

      {/* {buttonState !== "disabled" && isSameCreditManager && !isBackMovement && (
        <Button
          className={`${classes.ApproveButton} my-3`}
          onClick={handleClick}
          disable={buttonState !== "enabled"}
        >
          {buttonState === "loading" ? <CircularProgress /> : "Confirm"}
        </Button>
      )} */}
    </>
  );
};

export default FinalPayment;
