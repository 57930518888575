import React from "react";
import AddressWrapper from "../styles/AddressWrapper";
import TitleContainer from "../styles/TitleContainer";
import LenderSubtitle from "../styles/LenderSubtitle";
import Title from "../../styles/Title";


const AddressComponent = ({address}) => {

  return (
    <AddressWrapper>
      <TitleContainer>
        <LenderSubtitle>Line 1</LenderSubtitle>
        <Title>{address?.addressLine1}</Title>
      </TitleContainer>
      <TitleContainer>
        <LenderSubtitle>Line 2</LenderSubtitle>
        <Title>{address?.addressLine2}</Title>
      </TitleContainer>
      <TitleContainer>
        <LenderSubtitle>Line 3</LenderSubtitle>
        <Title>{address?.addressLine3}</Title>
      </TitleContainer>
      <TitleContainer>
        <LenderSubtitle>City</LenderSubtitle>
        <Title>{address?.city}</Title>
      </TitleContainer>
      <TitleContainer>
        <LenderSubtitle>State</LenderSubtitle>
        <Title>{address?.state}</Title>
      </TitleContainer>
      <TitleContainer>
        <LenderSubtitle>Pin Code</LenderSubtitle>
        <Title>{address?.pincode}</Title>
      </TitleContainer>
      {address?.ownershipType && (
        <TitleContainer>
          <LenderSubtitle>Type</LenderSubtitle>
          <Title>{address?.ownershipType}</Title>
        </TitleContainer>
      )}
    </AddressWrapper>
  );
};

export default AddressComponent;