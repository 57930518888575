import {Method} from "axios";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {AlgoState} from "app/store/scheduling/algo/reducer";

export interface AlgoResponse {
  error: GoldApiError | null;

  [Key: string]: any;
}

export interface AlgoResponseAdaptorType {
  (algoResponse: AlgoResponse): AlgoState;
}

export interface AlgoResources {
  [key: string]: {
    URL: string;
    METHOD: Method;
  };
}

export interface FiltersData {
  searchTerm: string;
  sortOrder: "ASCENDING" | "DESCENDING";
  isAssigned: boolean | null;
}

export interface ApplyFilterPayload extends FiltersData {}

export interface ChangeAlgoScreenPayload {
  id: number;
}

export interface GetRunsPayload {
  date: number | string; // remove string when fixed from backend
}

export interface StartAlgoPayload {
  cityId: number;
  date: string;
}

export interface GetAgentListPayload {
  algoRunId: number;
}

export interface GetAlgoRunStatusPayload {
  algoRunId: number;
}

export interface GetCustomerListPayload {
  algoRunId: number;
}

export interface GetAgentRouteListPayload {
  routeId: number;
}

export interface DeleteAgentTripPayload {
  schedulingRequestId: number;
  tripId: number;
  selectedView: number;
  deleteType?: string;
  agentId?: number;
}

export interface CreateAgentTripPayload {
  schedulingRequestId: number;
  agentId: number;
  agentType: string;
  endNodeId: number | null;
  endTime: number;
  startNodeId: number;
  startTime: number;
  selectedView: number;
  agentName: string;
}

export interface SaveAgentRoutePayload {
  routeId: number;
  algoRunId: number;
  selectedView: number;
}

export interface GetAgentScheduleDetailsPayload {
  agentIdList: string;
  schedulingRequestId: number;
}

export interface GetAvailableAgentListPayload {
  cityId: number;
  startTime: number;
  endTime: number;
  schedulingRequestId: number;
  roleName: string
}

export interface GetCustomerDetailsPayload {
  schedulingRequestId: number;
  schedulingNodeId: number;
}

export interface GetAlgoRunReportPayload {
  schedulingRequestId: number;
}

export interface ConfirmAlgoRunPayload {
  schedulingRequestId: number;
}

export interface GetTripDetailsPayload {
  schedulingRequestId: number;
  startSchedulingNodeId: number;
}

export interface SchedulingRequest {
  requestId: number;
  runByUserId: number;
  runByUserName: string;
  requestTime: number;
}

export interface AlgoRun {
  cityId: number;
  cityName: string;
  status: string | null;
  schedulingRequestCityResultHistoryList: SchedulingRequest[];
}

export interface RouteInterface {
  endAddress: string;
  endLatitude: string;
  endLongitude: string;
  endTime: number;
  jobType: string;
  loanAmount: number;
  referenceId: number;
  referenceType: string;
  startAddress: string;
  startLatitude: string;
  startLongitude: string;
  startTime: number;
  vaultName: string;
  customerName: string;
  startNodeId: number;
  endNodeId: number;
  tripId: number;
  originDate: number;
}

export interface NodeInterface {
  id: number;
  jobType: string;
  latitude: string;
  loanAmount: number;
  longitude: string;
  nodeId: number;
  nodeType: string;
  preferredAgentId: number;
  referenceId: number;
  referenceType: string;
  schedulingNodeTimeDtoList: [
    {
      endTime: number;
      startTime: number;
    }
  ];
  schedulingRequestId: number;
}

export interface AgentDetail {
  agentId: number;
  agentType: string;
  endNodeDto: NodeInterface;
  endNodeId: number;
  endTime: number;
  id: number;
  referenceId: number;
  referenceType: string;
  schedulingRouteId: number;
  startNodeDto: NodeInterface;
  startNodeId: number;
  startTime: number;
}

export interface AlgoRunErrorInterface {
  errorCode: string
  errorMessage: string
  nodeId: number
  nodeType: string
}

export interface TripInterface extends RouteInterface {
  agentDetails: AgentDetail[];
}

export function Trip(trip: TripInterface): TripInterface {
  return {
    endTime: trip.endTime || 0,
    referenceId: trip.referenceId || 0,
    referenceType: trip.referenceType || "",
    startTime: trip.startTime || 0,
    endAddress: trip.endAddress || "",
    endLatitude: trip.endLatitude || "",
    endLongitude: trip.endLongitude || "",
    jobType: trip.jobType || "",
    loanAmount: trip.loanAmount || 0,
    startAddress: trip.startAddress || "",
    startLatitude: trip.startLatitude || "",
    startLongitude: trip.startLongitude || "",
    vaultName: trip.vaultName || "",
    customerName: trip.customerName || "",
    startNodeId: trip.startNodeId || 0,
    endNodeId: trip.endNodeId || 0,
    tripId: trip.tripId || 0,
    originDate: trip.originDate || 0,
    agentDetails: [],
  };
}

export interface TripPointInterface {
  lat: number;
  lng: number;
  info: string;
  label: string;
}

export interface DirectionInterface {
  origin: TripPointInterface;
  destination: TripPointInterface;
  travelMode: "BICYCLING" | "DRIVING" | "TRANSIT" | "WALKING";
}

export interface AgentScheduleDetailInterface {
  agentId: number | null;
  crJobId: number | null;
  id: number;
  isTimeBlocked: boolean;
  leadId: number;
  lmScheduleType: string;
  lmUserCalendarId: number | null;
  remarks: string;
  type: string;
}

export interface UnscheduledLoanInterface {
  referenceId: number;
  referenceType: string;
  error: string;
  customerName: string;
  primaryAgentName: string;
  secondaryAgentNameList: any[];
  primaryAgentId: number;
  secondaryAgentIdList: any[];
}

export interface AgentInterface {
  agentId: number;
  agentName: string;
  routeId: number;
  tripCount: number;
  confirmed: boolean;
}

export function Agent(agent: AgentInterface) {
  return {
    agentId: agent.agentId || 0,
    agentName: agent.agentName || "",
    routeId: agent.routeId || 0,
    tripCount: agent.tripCount || 0,
    confirmed: agent.confirmed || false,
  };
}

export interface CustomerInterface {
  customerName: string;
  referenceId: number;
  referenceType: string;
  schedulingStartNodeId: number;
  schedulingEndNodeId: number;
  assigned: boolean;
}

export function Customer(customer: CustomerInterface): CustomerInterface {
  return {
    customerName: customer.customerName || "",
    referenceId: customer.referenceId || 0,
    referenceType: customer.referenceType || "",
    schedulingStartNodeId: customer.schedulingStartNodeId || 0,
    schedulingEndNodeId: customer.schedulingEndNodeId || 0,
    assigned: false,
  };
}

export interface AlgoApiInterface {
  getRuns: (payload: GetRunsPayload) => Promise<AlgoResponse>;
  getAgentList: (payload: GetAgentListPayload) => Promise<AlgoResponse>;
  getCustomerList: (payload: GetCustomerListPayload) => Promise<AlgoResponse>;
  getAgentRouteList: (
    payload: GetAgentRouteListPayload
  ) => Promise<AlgoResponse>;
  deleteAgentTrip: (payload: DeleteAgentTripPayload) => Promise<AlgoResponse>;
  saveAgentRoute: (payload: SaveAgentRoutePayload) => Promise<AlgoResponse>;
  startAlgo: (payload: StartAlgoPayload) => Promise<AlgoResponse>;
  getAlgoRunStatus: (payload: GetAlgoRunStatusPayload) => Promise<AlgoResponse>;
  getAgentSchedulingDetails: (
    payload: GetAgentScheduleDetailsPayload
  ) => Promise<AlgoResponse>;
  getCustomerDetails: (
    payload: GetCustomerDetailsPayload
  ) => Promise<AlgoResponse>;
  getAlgoRunReport: (payload: GetAlgoRunReportPayload) => Promise<AlgoResponse>;
  confirmAlgoRun: (payload: ConfirmAlgoRunPayload) => Promise<AlgoResponse>;
  getTripDetails: (payload: GetTripDetailsPayload) => Promise<AlgoResponse>;
  createAgentTrip: (payload: CreateAgentTripPayload) => Promise<AlgoResponse>;
  getAvailableAgentList: (
    payload: GetAvailableAgentListPayload
  ) => Promise<AlgoResponse>;
}
