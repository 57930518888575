import {GoldApiError} from "app/typings/api/goldApi.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getValueFromQueryParams} from "_metronic/utils/utils";
import {GridSortDirection} from "@mui/x-data-grid";
import {
  DisbursedLoansInterface,
  LoanBookingJobDocumentType,
  LoanBookingJobInterface,
  RemarksHistory,
} from "app/models/lenderLoan.model";
import lenderLoanActions from "./lenderLoan.actions";
import {paginationSliceCreator, getInitialState, PaginationState} from "../factoryReducers";
import {getValidTime} from "_metronic/utils/moment";

type LoadingErrorState =
  | "getDisbursedLoans"
  | "markDisbursedJobAsCompleted"
  | "assignDisbursalJobToMe"
  | "UnAssignDisbursalJobToMe"
  | "getLoanBookingJobList"
  | "getLoanBookingJobDetails"
  | "getLoanBookingJobDocuments"
  | "approveLoanBookingJob"
  | "rejectLoanBookingJob"
  | "assignLoanBookingJobToMe"
  | "unassignLoanBookingJobToMe"
  | "getRemarksHistory";

export interface DisbursedLoansFilters {
  currentSearchType: string;
  searchText: string;
  startTime: number | null;
  endTime: number | null;
  sortField?: string;
  sort?: GridSortDirection;
}

export interface LenderLoanState {
  loanBookingJobsById: Record<number, LoanBookingJobInterface>;
  disbursedLoansByCrId: Record<number, DisbursedLoansInterface>;
  activeTabs: {
    loanBooking: string;
    loanSanctioning: string;
  };
  filters: DisbursedLoansFilters;
  selectedCrId: number;
  selectedRequestId: number;
  pagination: PaginationState;
  openModals: {
    markDisbursedJobAsCompleted: boolean;
    approveLoanBookingJob: boolean;
    rejectSanctionedLoan: boolean;
  };
  isListReloadRequired: boolean;
  remarksHistoryByRequestId: Record<number, RemarksHistory[]>;
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
}

const initialState: LenderLoanState = {
  loanBookingJobsById: {},
  disbursedLoansByCrId: {},
  selectedCrId: 0,
  selectedRequestId: 0,
  activeTabs: {
    loanBooking: getValueFromQueryParams("jobStatusList") || "CREATED",
    loanSanctioning: getValueFromQueryParams("jobStatusList") || "PENDING",
  },
  filters: {
    currentSearchType: getValueFromQueryParams("currentSearchType") || "",
    searchText: getValueFromQueryParams("searchText") || "",
    startTime:
      Number(getValueFromQueryParams("startTime")) ||
      getValidTime()
        .subtract("10", "days")
        .startOf("day")
        .valueOf(),
    endTime:
      Number(getValueFromQueryParams("endTime")) ||
      getValidTime()
        .endOf("day")
        .valueOf(),
    sortField: getValueFromQueryParams("sortField") || "",
    sort: (getValueFromQueryParams("sort") as GridSortDirection) || undefined,
  },

  remarksHistoryByRequestId: {},
  openModals: {
    markDisbursedJobAsCompleted: false,
    approveLoanBookingJob: false,
    rejectSanctionedLoan: false,
  },
  isListReloadRequired: false,
  loading: {
    getDisbursedLoans: false,
    markDisbursedJobAsCompleted: false,
    assignDisbursalJobToMe: false,
    UnAssignDisbursalJobToMe: false,
    getLoanBookingJobDetails: false,
    getLoanBookingJobList: false,
    getLoanBookingJobDocuments: false,
    approveLoanBookingJob: false,
    rejectLoanBookingJob: false,
    assignLoanBookingJobToMe: false,
    unassignLoanBookingJobToMe: false,
    getRemarksHistory: false,
  },
  errors: {
    getDisbursedLoans: null,
    markDisbursedJobAsCompleted: null,
    assignDisbursalJobToMe: null,
    UnAssignDisbursalJobToMe: null,
    getLoanBookingJobDetails: null,
    getLoanBookingJobList: null,
    getLoanBookingJobDocuments: null,
    approveLoanBookingJob: null,
    rejectLoanBookingJob: null,
    assignLoanBookingJobToMe: null,
    unassignLoanBookingJobToMe: null,
    getRemarksHistory: null,
  },
  pagination: getInitialState("lenderLoan"),
};

const lenderLoanPaginationReducer = paginationSliceCreator("lenderLoan");

const lenderLoanSlice = createSlice({
  name: "lenderLoan",
  initialState,
  reducers: {
    toggleModals: (
      state,
      action: PayloadAction<{
        type: keyof typeof state.openModals;
        value: boolean;
      }>,
    ) => {
      const {type, value} = action.payload;
      state.openModals[type] = value;
    },
    setFilters: (state, action: PayloadAction<Partial<DisbursedLoansFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
      state.pagination.pageNo = 1;
    },
    resetFilters: state => {
      state.filters = {
        currentSearchType: "",
        searchText: "",
        startTime: getValidTime()
          .subtract("10", "days")
          .startOf("day")
          .valueOf(),
        endTime: getValidTime()
          .endOf("day")
          .valueOf(),
      };
      state.pagination.pageNo = 1;
    },
    setSelectedCrId: (state, action: PayloadAction<{ crId: number }>) => {
      state.selectedCrId = action.payload.crId;
    },
    setSelectedRequestId: (state, action: PayloadAction<{ requestId: number }>) => {
      state.selectedRequestId = action.payload.requestId;
    },
    setActiveTab: (
      state,
      action: PayloadAction<{
        module: "loanBooking" | "loanSanctioning";
        newActiveTab: string;
      }>,
    ) => {
      state.activeTabs[action.payload.module] = action.payload.newActiveTab;
    },
    resetActiveTab: state => {
      state.activeTabs = {
        loanBooking: "CREATED",
        loanSanctioning: "PENDING",
      };
    },
    setLoanBookingJobsById: (
      state,
      action: PayloadAction<{
        jobs: LoanBookingJobInterface[];
        clearAll: boolean;
      }>,
    ) => {
      const {jobs: newJobs, clearAll} = action.payload;

      if (clearAll) {
        const newIdsSet = new Set(newJobs.map(job => job.id));
        for (const id in state.loanBookingJobsById) {
          if (!newIdsSet.has(Number(id))) {
            delete state.loanBookingJobsById[id];
          }
        }
      }
      state.loanBookingJobsById = newJobs.reduce(
        (acc, loanBookingJob) => {
          acc[loanBookingJob.id] = {
            ...(state.loanBookingJobsById[loanBookingJob.id] || {}),
            ...loanBookingJob,
          };
          return acc;
        },
        {...state.loanBookingJobsById},
      );
    },
    setLoanBookingJobDocuments: (
      state,
      action: PayloadAction<{
        requestId: number;
        documents: any;
        documentTypeList: LoanBookingJobDocumentType[];
      }>,
    ) => {
      const currentJob = state.loanBookingJobsById[action.payload.requestId];
      if (currentJob) {
        currentJob.documentList = action.payload.documentTypeList.reduce((acc, type) => {
          acc[type] = action.payload.documents.find((doc: any) => doc.documentType === type);
          return acc;
        }, {} as Record<LoanBookingJobDocumentType, any>);
      }
    },
    setLoading: (
      state,
      action: PayloadAction<{ loadingType: LoadingErrorState; value: boolean }>,
    ) => {
      state.loading[action.payload.loadingType] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        errorType: LoadingErrorState;
        error: GoldApiError | null;
      }>,
    ) => {
      state.errors[action.payload.errorType] = action.payload.error;
    },
    triggerListReload: state => {
      state.isListReloadRequired = !state.isListReloadRequired;
    },
    setRemarksHistory: (
      state,
      action: PayloadAction<{
        requestId: number;
        remarksHistory: RemarksHistory[];
      }>,
    ) => {
      state.remarksHistoryByRequestId[action.payload.requestId] = action.payload.remarksHistory;
    },
    resetLoanBookingJobsList: state => {
      state.loanBookingJobsById = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(lenderLoanActions.getDisbursedLoans, state => {
        state.loading.getDisbursedLoans = true;
        state.disbursedLoansByCrId = initialState.disbursedLoansByCrId;
        state.errors.getDisbursedLoans = initialState.errors.getDisbursedLoans;
      })
      .addCase(lenderLoanActions.getDisbursedLoansSuccess, (state, action) => {
        state.loading.getDisbursedLoans = false;
        state.disbursedLoansByCrId = {};
        action.payload.response.forEach((disbursedLoans: DisbursedLoansInterface) => {
          state.disbursedLoansByCrId[disbursedLoans.crId] = disbursedLoans;
        });
      })
      .addCase(lenderLoanActions.getDisbursedLoansFailure, (state, action) => {
        state.loading.getDisbursedLoans = false;
        state.errors.getDisbursedLoans = action.payload.error;
      })
      .addCase(lenderLoanActions.markDisbursedJobAsCompleted, state => {
        state.loading.markDisbursedJobAsCompleted = true;
        state.errors.markDisbursedJobAsCompleted = initialState.errors.markDisbursedJobAsCompleted;
      })
      .addCase(lenderLoanActions.markDisbursedJobAsCompletedSuccess, state => {
        state.loading.markDisbursedJobAsCompleted = false;
        state.openModals.markDisbursedJobAsCompleted = false;
        state.isListReloadRequired = !state.isListReloadRequired;
      })
      .addCase(lenderLoanActions.markDisbursedJobAsCompletedFailure, (state, action) => {
        state.loading.markDisbursedJobAsCompleted = false;
        state.errors.markDisbursedJobAsCompleted = action.payload.error;
      })
      .addCase(lenderLoanActions.assignDisbursalJobToMe, state => {
        state.loading.assignDisbursalJobToMe = true;
      })
      .addCase(lenderLoanActions.assignDisbursalJobToMeSuccess, state => {
        state.loading.assignDisbursalJobToMe = false;
        state.isListReloadRequired = !state.isListReloadRequired;
      })
      .addCase(lenderLoanActions.assignDisbursalJobToMeFailure, (state, action) => {
        state.loading.assignDisbursalJobToMe = false;
        state.errors.assignDisbursalJobToMe = action.payload.error;
      })
      .addCase(lenderLoanActions.unAssignDisbursalJobToMe, state => {
        state.loading.UnAssignDisbursalJobToMe = false;
      })
      .addCase(lenderLoanActions.unAssignDisbursalJobToMeSuccess, state => {
        state.loading.UnAssignDisbursalJobToMe = false;
        state.isListReloadRequired = !state.isListReloadRequired;
      })
      .addCase(lenderLoanActions.unAssignDisbursalJobToMeFailure, state => {
        state.loading.UnAssignDisbursalJobToMe = false;
      })
      .addMatcher(
        action => action.type.startsWith("lenderLoanPagination"),
        (state, action) => {
          state.pagination = lenderLoanPaginationReducer(state.pagination, action);
        },
      ),
});

export default lenderLoanSlice.reducer;

const {
  resetFilters,
  setFilters,
  toggleModals,
  setSelectedCrId,
  setActiveTab,
  resetActiveTab,
  setLoading: setLenderLoanLoading,
  setError: setLenderLoanError,
  setLoanBookingJobsById,
  setLoanBookingJobDocuments,
  triggerListReload,
  setSelectedRequestId,
  setRemarksHistory,
  resetLoanBookingJobsList,
} = lenderLoanSlice.actions;

export {
  setFilters,
  setSelectedCrId,
  toggleModals,
  resetFilters,
  setActiveTab,
  resetActiveTab,
  setLenderLoanLoading,
  setLenderLoanError,
  setLoanBookingJobsById,
  setLoanBookingJobDocuments,
  triggerListReload,
  setRemarksHistory,
  setSelectedRequestId,
  resetLoanBookingJobsList,
};
