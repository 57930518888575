import {
  CreateJobFromLeadPayload,
  createRMJobPayload,
  GetLeadDetailPayload,
  GetLeadListPayload,
  GetLoanLockersPayload,
  GetLoanServicingJobListPayload,
  PickUpLocationPayload,
  UnscheduledApiInterface,
  updateRmJobPayload,
  UnscheduledResources,
  UpdateLeadPayload,
  GetLeadCreditDetailsPayload,
  GetLeadCreditReportStatusPayload,
  GetClusterDetailPayload,
  GetUnscheduledAgentReportPayload,
  RemoveAgentPayload,
} from "./types";
import {goldApiService} from "app/infra/plugins/axios";

const UNSCHEDULED_ENDPOINTS: UnscheduledResources = {
  GET_LEAD_LIST: {
    URL: "/scheduling/lead-list",
    METHOD: "GET",
  },
  GET_LOAN_SERVICING_JOB_LIST: {
    URL: "/scheduling/loan-closure-cr-list",
    METHOD: "GET",
  },
  GET_LEAD_DETAIL: {
    URL: "/lead-detail/:leadId",
    METHOD: "GET",
  },
  GET_UNQUALIFIED_REASONS: {
    URL: "/scheduling/unqualified-reason-list",
    METHOD: "GET",
  },
  GET_LOAN_LOCKERS: {
    URL: "/admin/business/loan-locker-list", // ?cityId
    METHOD: "GET",
  },
  UPDATE_LEAD_DETAILS: {
    URL: "/lead-detail/:leadId/detail",
    METHOD: "POST",
  },
  UPDATE_LEAD_ADDRESS: {
    URL: "/lead-detail/:leadId/address",
    METHOD: "POST",
  },
  UPDATE_LEAD_MEETING_TIME: {
    URL: "/lead-detail/:leadId/meeting-time",
    METHOD: "POST",
  },
  UPDATE_LEAD_ASSIGN_AGENT: {
    URL: "/lead-detail/:leadId/assign-agents",
    METHOD: "POST",
  },
  UPDATE_LEAD_STAGE: {
    URL: "/lead-detail/:leadId/stage",
    METHOD: "POST",
  },
  CREATE_JOB_FROM_LEAD: {
    URL: "/scheduling/lead/:leadId/cr-job",
    METHOD: "POST",
  },
  CREATE_RM_JOB: {
    URL: "/scheduling/cr/:crId/create-loan-closure-rm-job",
    METHOD: "POST",
  },
  GET_SEND_BACK_REASON_LIST: {
    URL: "/scheduling/send-back-reason-list",
    METHOD: "GET",
  },
  GET_PICKUP_LOCATION: {
    URL: "/admin/business/cr/:crId/pickup-address",
    METHOD: "GET",
  },
  UPDATE_RM_JOB: {
    URL: "/admin/business/cr/:crId/release-job/assign-agent",
    METHOD: "POST",
  },
  DELETE_RM_JOB: {
    URL: "/admin/business/cr/:crId",
    METHOD: "DELETE",
  },
  GET_TIMESLOT_LIST: {
    URL: "/lead/scheduling-slots",
    METHOD: "GET",
  },
  GET_LEAD_NOTE_LIST: {
    URL: "/lead-detail/:crId/note-list",
    METHOD: "GET",
  },
  UPDATE_LEAD_NOTE: {
    URL: "/lead-detail/:crId/note",
    METHOD: "POST",
  },
  GET_LOCATION_FROM_ADDRESS: {
    URL: "/admin/loan-blacklist/address", // ? address
    METHOD: "GET",
  },
  CLOSE_LM_JOB: {
    URL: "/admin/business/cr/:crId/close-lm-job",
    METHOD: "POST",
  },
  GET_BYPASS_SCANNING_URL_LIST: {
    URL: "/admin/business/cr/:crId/crJob/:crJobId/manual-packet-verify",
    METHOD: "GET",
  },
  POST_BYPASS_SCANNING_VERIFY: {
    URL: "/admin/business/cr/:crId/crJob/:crJobId/manual-packet-verify",
    METHOD: "PUT",
  },
  DOWNLOAD_BYPASS_DOCUMENT: {
    URL: "/admin/business/cr/:crId/download-document",
    METHOD: "GET",
  },
  GET_RELEASE_DOCUMENTS: {
    URL: "/admin/business/cr/:crId/release-documents",
    METHOD: "GET",
  },
  GET_LEAD_CREDIT_DETAILS: {
    URL: "/lead/credit-report/admin/:leadId/credit-detail",
    METHOD: "GET",
  },
  GET_LEAD_CREDIT_REPORT_STATUS: {
    URL: "/lead/credit-report/admin/:leadId/status",
    METHOD: "GET",
  },
  GET_SCHEDULING_LOCKER_BY_LEAD_ID: {
    URL: "/scheduling/locker-list", // ? cityId, leadId
    METHOD: "GET",
  },
  GET_GOLD_DELIVERY_SLOTS: {
    URL: "/admin/service-desk/config/gold-delivery-slot",
    METHOD: "GET",
  },
  GET_DELIVERY_SLOT_CHANGEABLE_DATE: {
    URL: "/admin/service-desk/config/gold-delivery-slot/changeable-date",
    METHOD: "GET",
  },
  UPDATE_DELIVERY_SLOT: {
    URL: "/admin/service-desk/config/gold-delivery-slot",
    METHOD: "POST",
  },
  GET_CLUSTER_DETAIL: {
    URL: "/scheduling/real-time/lead/cluster-detail",
    METHOD: "GET",
  },
  GET_SCHEDULING_AGENT_REPORT: {
    URL: "/scheduling/real-time/lead/report",
    METHOD: "POST",
  },
  REMOVE_AGENT_JOB: {
    URL: "/admin/business/cr/:crId/release-job/remove-agent",
    METHOD: "POST",
  },
};

const UnscheduledApi: UnscheduledApiInterface = {
  getUnscheduleClusterDetail: async (payload: GetClusterDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_CLUSTER_DETAIL,
      options: {
        queryParams: {
          leadId: payload.leadId,
        },
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {response, error: null};
  },

  getUnscheduleAgentReport: async (
    payload: GetUnscheduledAgentReportPayload,
  ) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_SCHEDULING_AGENT_REPORT,
      options: {
        data: {
          entityId: payload.entityId,
          selectedAgentRank: payload.selectedAgentRank,
          agentId: payload.agentId,
        },
      },
    });
    if (error) {
      return {error, response: []};
    }
    return {response: response, error: null};
  },

  getLeadList: async (payload: GetLeadListPayload) => {
    const queryParams: Record<string, any> = {
      cityId: payload.cityId,
      pageNo: payload.pageNo,
      pageSize: payload.pageSize,
      leadStage: payload.leadStage,
      mobile: payload.mobile,
      goldLoanType: payload.goldLoanType,
      name: payload.name,
      sortOrder: "DESCENDING",
    };
    if (payload.startTime && payload.endTime) {
      queryParams["startTime"] = payload.startTime;
      queryParams["endTime"] = payload.endTime;
    }
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LEAD_LIST,
      options: {
        queryParams: queryParams,
      },
    });

    if (error) {
      return {error, leadList: []};
    }
    return {error: null, leadList: response};
  },

  getLoanServicingJobList: async (payload: GetLoanServicingJobListPayload) => {
    const queryParams: Record<string, any> = {...payload};
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    if (payload.crJobTypeToIgnore?.length === 0) {
      delete queryParams.crJobTypeToIgnore;
    }
    queryParams.crType = ["GOLD_LOAN_CLOSURE_PACKET_RELEASE"];
    queryParams.sortOrder = "DESCENDING";
    if (payload.startTime && payload.endTime) {
      queryParams["startTime"] = payload.startTime;
      queryParams["endTime"] = payload.endTime;
    }
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LOAN_SERVICING_JOB_LIST,
      options: {
        queryParams: queryParams,
      },
    });

    if (error) {
      return {error, loanServicingJobList: []};
    }
    return {error: null, loanServicingJobList: response || []};
  },

  getPickupLocation: async (data: PickUpLocationPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_PICKUP_LOCATION,
      options: {pathVars: {crId: data.crId}},
    });
    if (error) {
      return {error, data: null};
    }
    return {error: null, data: response};
  },

  createRMJob: async (data: createRMJobPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.CREATE_RM_JOB,
      options: {
        pathVars: {crId: data.crId},
        data: data.data,
      },
    });

    if (error) {
      return {error, data: null};
    }
    return {error: null, data: response};
  },
  getLeadDetail: async (data: GetLeadDetailPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LEAD_DETAIL,
      options: {
        pathVars: {
          leadId: data.id,
        },
      },
    });
    if (error) {
      return {error, leadDetail: null};
    }
    return {error: null, leadDetail: response};
  },
  getUnqualifiedReasons: async () => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_UNQUALIFIED_REASONS,
    });

    if (error) {
      return {
        reasons: [],
        error,
      };
    }

    return {
      error: null,
      reasons: response,
    };
  },
  getLoanLockers: async (data: GetLoanLockersPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LOAN_LOCKERS,
      options: {
        queryParams: {
          cityId: data.cityId,
        },
      },
    });

    if (error) {
      return {
        error,
        loanLockers: [],
      };
    }

    return {
      loanLockers: response,
      error: null,
    };
  },
  updateLeadDetails: async (
    leadId: number,
    type: string,
    payload: UpdateLeadPayload,
  ) => {
    const TYPES: Record<string, string> = {
      detail: "UPDATE_LEAD_DETAILS",
      address: "UPDATE_LEAD_ADDRESS",
      "meeting-time": "UPDATE_LEAD_MEETING_TIME",
      "assign-agents": "UPDATE_LEAD_ASSIGN_AGENT",
      stage: "UPDATE_LEAD_STAGE",
    };
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS[TYPES[type]],
      options: {
        pathVars: {
          leadId: leadId,
        },
        data: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error,
        data: null,
      };
    }

    return {
      data: response,
      error: null,
    };
  },
  createJobFromLead: async (data: CreateJobFromLeadPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.CREATE_JOB_FROM_LEAD,
      options: {
        pathVars: {
          leadId: data.id,
        },
      },
    });

    if (error) {
      return {
        error,
        data: null,
      };
    }

    return {
      data: response,
      error: null,
    };
  },
  getSendBackReasonList: async () => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_SEND_BACK_REASON_LIST,
    });

    if (error) {
      return {reasonList: [], error};
    }
    return {error: null, reasonList: response};
  },
  updateRmJob: async ({crId, agentId}: updateRmJobPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.UPDATE_RM_JOB,
      options: {pathVars: {crId}, queryParams: {agentId}},
    });
    if (error) {
      return {data: null, error};
    }
    return {error: null, data: response};
  },
  deleteRmJob: async ({crId}: PickUpLocationPayload) => {
    const {error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.DELETE_RM_JOB,
      options: {pathVars: {crId}},
    });
    if (error) {
      return {error};
    }
    return {error: null};
  },

  getTimeSlotList: async () => {
    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_TIMESLOT_LIST,
    });
  },

  getNoteList: async ({crId}) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LEAD_NOTE_LIST,
      options: {pathVars: {crId}},
    });
    if (error) return {noteList: [], error};
    return {noteList: response, error: null};
  },

  updateNotes: async ({crId, note}) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.UPDATE_LEAD_NOTE,
      options: {pathVars: {crId}, queryParams: {note}},
    });
    if (error) return {note: null, error};
    return {note: response, error: null};
  },
  getLocationFromAddress: async (payload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LOCATION_FROM_ADDRESS,
      options: {
        queryParams: {
          address: payload.address,
        },
      },
    });
    if (error) {
      return {error, addressDetails: null};
    }
    return {
      error: null,
      addressDetails: response,
    };
  },
  closeLMJob: async (payload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.CLOSE_LM_JOB,
      options: {
        pathVars: {
          crId: payload.crId,
        },
        queryParams: {
          crJobId: payload.crJobId,
          remark: payload.remark,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {
      error: null,
      response,
    };
  },
  getBypassPacketList: async ({crId, crJobId}) => {
    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_BYPASS_SCANNING_URL_LIST,
      options: {pathVars: {crId, crJobId}},
    });
  },
  approveBypassPacketScanning: async ({crId, crJobId, status}) => {
    const options: {
      [key: string]: any;
    } = {
      pathVars: {crId, crJobId},
    };
    if (status) {
      options["queryParams"] = {status};
    }

    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.POST_BYPASS_SCANNING_VERIFY,
      options,
    });
  },
  downloadBypassDocument: async ({
    crId,
    documentKey,
    documentTypeList,
    crJobId,
  }) => {
    const options: {
      [key: string]: any;
    } = {
      pathVars: {crId},
      queryParams: {documentKey},
    };

    if (documentTypeList) {
      options["queryParams"]["documentTypeList"] = documentTypeList;
    }

    if (crJobId) {
      options["queryParams"]["crJobId"] = crJobId;
    }

    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.DOWNLOAD_BYPASS_DOCUMENT,
      options,
    });
  },
  getReleaseDocuments: async ({crId, documentTypeList}) => {
    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_RELEASE_DOCUMENTS,
      options: {pathVars: {crId}, queryParams: {documentTypeList}},
    });
  },
  getLeadCreditDetails: async (data: GetLeadCreditDetailsPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LEAD_CREDIT_DETAILS,
      options: {
        pathVars: {
          leadId: data.id,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {error: null, response};
  },
  getLeadCreditReportStatus: async (data: GetLeadCreditReportStatusPayload) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_LEAD_CREDIT_REPORT_STATUS,
      options: {
        pathVars: {
          leadId: data.id,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {error: null, response};
  },
  getLockerByLeadId: async ({leadId, cityId}) => {
    const {response, error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_SCHEDULING_LOCKER_BY_LEAD_ID,
      options: {
        queryParams: {
          leadId,
          cityId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {error: null, response};
  },
  getDeliverySlots: async ({cityId, deliveryDate}) => {
    const queryParams: Record<string, any> = {
      deliveryDate,
    };
    if (cityId) {
      queryParams.cityId = cityId;
    }
    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_GOLD_DELIVERY_SLOTS,
      options: {
        queryParams,
      },
    });
  },
  getDeliverySlotChangeableDate: async ({cityId}) =>
    await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.GET_DELIVERY_SLOT_CHANGEABLE_DATE,
      options: {
        queryParams: {
          cityId,
        },
      },
    }),
  updateDeliverySlot: async ({
    effectiveFrom,
    cityId,
    goldDeliverySlotConfigDtoList,
  }) => {
    return await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.UPDATE_DELIVERY_SLOT,
      options: {
        data: {
          effectiveFrom,
          cityId,
          goldDeliverySlotConfigDtoList,
        },
      },
    });
  },
  removeAgent: async ({crId}: RemoveAgentPayload) => {
    const {error} = await goldApiService({
      resource: UNSCHEDULED_ENDPOINTS.REMOVE_AGENT_JOB,
      options: {pathVars: {crId}},
    });
    if (error) {
      return {error};
    }
    return {error: null};
  },
};

export default UnscheduledApi;

export {UNSCHEDULED_ENDPOINTS};
