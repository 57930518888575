import {RootState} from "../store";

const transactionsSelectors = {
  getShareOfferWithCustomerSuccess: (state: RootState) =>
  state.transactions.shareOfferWithCustomerSuccess,
  getTransactionsList: (state: RootState) =>
    state.transactions.transactionsList,
  showTransactionFlow: (state: RootState) =>
    state.transactions.isTransactionFlowEnable,
  getAdjustmentRenewalRoi: (state: RootState) =>
    state.transactions.adjustmentLoanRenewalRoi,
  getLoading: (state: RootState) => state.transactions.loading,
  getErrors: (state: RootState) => state.transactions.errors,
};

export default transactionsSelectors;
