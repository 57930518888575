import {PayloadAction} from "@reduxjs/toolkit";
import {put, getContext, call, takeLeading} from "redux-saga/effects";
import {snackBarActions} from "../snackbar";
import {
  FetchGiftCardConfigProps,
  UpdateGiftCardConfigProps,
} from "app/infra/services/api/giftCardConfig/types";
import {GoldApi} from "app/infra/services/api";
import {GiftCardConfigState} from "./reducer";

import {giftCardConfigAction, GiftCardConfigTypes} from "./actions";

// >>>>>>>>> Gift Card Config Actions <<<<<<<<
const {
  getGiftCardConfig,
  getGiftCardConfigSuccess,
  getGiftCardConfigError,
  updateGiftCardConfigSuccess,
  updateGiftCardConfigError,
} = giftCardConfigAction;

// >>>>>>>> WhitelistUsers Flows <<<<<<<<<
function* fetchGiftCardConfigFlow(
  action: PayloadAction<FetchGiftCardConfigProps>,
) {
  const api: GoldApi = yield getContext("api");

  const giftCardConfigState: GiftCardConfigState = yield call(
    api.giftCardConfig.fetchGiftCardConfig,
    action.payload,
  );

  if (giftCardConfigState.error) {
    yield put(getGiftCardConfigError(giftCardConfigState));
    yield put(
      snackBarActions.open({
        message: "Fetching Gift Card Config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(getGiftCardConfigSuccess(giftCardConfigState));
  }
}

function* updateGiftCardConfigFlow(
  action: PayloadAction<{
    id: number;
    updatePayload: UpdateGiftCardConfigProps;
    fetchPayload: FetchGiftCardConfigProps;
  }>,
) {
  const api: GoldApi = yield getContext("api");

  const giftCardConfigState: GiftCardConfigState = yield call(
    api.giftCardConfig.updateGiftCardConfig,
    action.payload.id,
    action.payload.updatePayload,
  );

  if (giftCardConfigState.error) {
    yield put(updateGiftCardConfigError(giftCardConfigState));
    yield put(
      snackBarActions.open({
        message: "Updating Gift Card Config failed",
        variant: "error",
      }),
    );
  } else {
    yield put(updateGiftCardConfigSuccess(giftCardConfigState));
    yield put(getGiftCardConfig());

    yield put(
      snackBarActions.open({
        message: "Gift Card Config Updated Sucessfully",
        variant: "success",
      }),
    );
  }
}

export default function* giftCardConfigSaga() {
  yield takeLeading(
    GiftCardConfigTypes.GetGiftCardConfig,
    fetchGiftCardConfigFlow,
  );

  yield takeLeading(
    GiftCardConfigTypes.UpdateGiftCardConfig,
    updateGiftCardConfigFlow,
  );
}
