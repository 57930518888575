import React, {useCallback, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import getOrnaments from "api/customerProfiles/getOrnaments";
import OrnamentsModal from "./OrnamentsModal";
import {IGButton, IGDocumentViewer, IGModal} from "app/components";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {loanActions, loanSelectors} from "app/store/loan";
import {useParams} from "react-router-dom";
import {useSnackBar} from "app/store/hooks";
import {
  customerProfileActions,
  customerProfileSelectors,
} from "app/store/customerProfile";

const Ornaments = () => {
  const {loanId} = useParams();
  const dispatch = useAppDispatch();

  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const inwardReceiptImage = useAppSelector((state) =>
    customerProfileSelectors.getInwardReceiptImageByloanId(
      state,
      Number(loanId),
    ),
  );

  const {showError} = useSnackBar();
  const [states, setStates] = useState<any>({
    loading: false,
    error: false,
    errorMessage: "",
    payload: null,
  });
  const [ornamentsModal, setOrnamentsModal] = useState(false);

  const [isInwardReceiptAvailable, setIsInwardReceiptAvailable] = useState(
    false,
  );

  const getOrnamentsCallback = useCallback(async () => {
    setStates((prevState: any) => ({
      ...prevState,
      loading: true,
    }));
    try {
      const {response, error} = await getOrnaments({
        loanId: Number(loanId),
      });

      if (response && response.statusCode === 200) {
        setStates({
          loading: false,
          error: false,
          errorMessage: "",
          payload: response.payload || [],
        });
      } else {
        setStates({
          loading: false,
          error: true,
          errorMessage: (error as any)?.error?.toString(),
          payload: null,
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: null,
      });
    }
  }, [loanId]);


  const onClickInwardReceipt = useCallback(async () => {
    if (!loanId) {
      showError("LoanId not available!");
      return;
    }
    dispatch(
      customerProfileActions.getInwardReceiptImage({
        loanId: Number(loanId),
      }),
    );
    setIsInwardReceiptAvailable(true);
  }, [loanId, showError]);

  useEffect(() => {
    if (!loanId) return;

    if (!loanDetails) {
      dispatch(
        loanActions.getLoanDetails({
          loanId: Number(loanId),
        }),
      );
    }
  }, [dispatch, loanDetails, loanId]);

  return (
    <>
      <Typography variant="h6">Ornaments</Typography>

      <Box display="flex" gap={1}>
        <IGButton
          color="golden"
          sx={{m: 1}}
          onClick={() => setOrnamentsModal(true)}
        >
          View Ornaments
        </IGButton>
        <IGButton color="golden" sx={{m: 1}} onClick={onClickInwardReceipt}>
          Inward Receipt(s)
        </IGButton>
      </Box>
      {inwardReceiptImage && isInwardReceiptAvailable && (
        <IGDocumentViewer
          open={isInwardReceiptAvailable}
          onClose={() => setIsInwardReceiptAvailable(false)}
          document={{
            fileUrl: inwardReceiptImage?.inwardReceiptImageUrl,
            fileTitle: "Inward Receipt",
            documentType: "Inward Receipt",
            backFileUrl: "",
            crDocumentImageResponseDtoList: [],
          }}
        />
      )}

      <IGModal
        title="Ornaments"
        open={ornamentsModal}
        onClose={() => setOrnamentsModal(false)}
      >
        <OrnamentsModal
          {...{
            ornaments: states.payload,
            getOrnamentsCallback,
            states,
          }}
        />
      </IGModal>
    </>
  );
};

export default Ornaments;
