import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
  lenderName: string;
  personalLoanAmount: number;
}

export const postPersonalLoanCreditPolicy = async ({
  crId,
  lenderName,
  personalLoanAmount,
}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    POST_PL_CREDIT_POLICY: {
      URL: "/api/v1/business/credit-policy/personal-loan-recommendation",
      METHOD: "POST",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.POST_PL_CREDIT_POLICY,
    options: {
      data: {
        crId,
        lenderName,
        personalLoanAmount,
      },
    },
  });
};

export default postPersonalLoanCreditPolicy;
