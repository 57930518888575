import React from "react";
import {Box, Typography} from "@mui/material";
import {personalDetailsSelectors} from "app/store/loanRenewalRebook/personalDetails";
import {useAppSelector} from "app/store/hooks";

const formatAddress = (address: any) => {
  const {addressLine1, addressLine2, addressLine3, city, state, pincode} =
    address ?? {};
  return [addressLine1, addressLine2, addressLine3, city, state, pincode]
    .filter(Boolean)
    .join(", ");
};
const GuarantorAddressDetails = () => {
  const residentialAddress = useAppSelector((state) =>
    personalDetailsSelectors.getGuarantorAddressDetailsByType(
      state,
      "GUARANTOR_RESIDENTIAL_ADDRESS",
    ),
  );
  const permanentAddress = useAppSelector((state) =>
    personalDetailsSelectors.getGuarantorAddressDetailsByType(
      state,
      "GUARANTOR_PERMANENT_ADDRESS",
    ),
  );

  const formattedResidentialAddress = formatAddress(residentialAddress);
  const formattedPermanentAddress = formatAddress(permanentAddress);
  return (
    <Box
      bgcolor="#FFFFFF;"
      borderRadius="4px"
      padding="16px 16px 16px 24px"
      border="1.26px solid #0000001F"
    >
      <Typography variant="subtitle1" fontSize="16px" color="gray">
        Guarantor's Permanent Address
      </Typography>
      <Typography fontSize="16px" variant="body1">
        {formattedPermanentAddress}
      </Typography>

      <Box mt={3}>
        <Typography variant="subtitle1" fontSize="16px" color="gray">
          Guarantor's Temporary Address
        </Typography>
        <Typography fontSize="16px" variant="body1">
          {formattedResidentialAddress}
        </Typography>
      </Box>
    </Box>
  );
};

export default GuarantorAddressDetails;
