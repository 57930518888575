import * as yup from "yup";
import {ParamsInterface} from "./index";
import {useIGTranslation} from "../infra/plugins/i18n";
import {TestConfig} from "yup/lib/util/createValidation";

interface NumberValidationOptions {
  required?: boolean | string;
  min?: number | ParamsInterface<number>;
  max?: number | ParamsInterface<number>;
  length?: number | ParamsInterface<number>;
  integer?: boolean | string;
  positive?: boolean | string;
  positiveWithZero?: boolean | string;
  isPhoneNumber?: boolean | string;
  nullable?: boolean

  custom?: TestConfig<number>
}

const ERRORS_MAPPING: Record<keyof NumberValidationOptions, string> = {
  required: "REQUIRED",
  max: "MAX_NUMBER",
  min: "MIN_NUMBER",
  length: "NUM_LENGTH",
  integer: "INTEGER",
  positive: "POSITIVE",
  positiveWithZero: "POSITIVE_WITH_ZERO",
  isPhoneNumber: "PHONE_NUMBER",
  nullable: "NULLABLE",
  custom: "TEST_NUMBER",
};

const useNumberValidations = (
  options: NumberValidationOptions = {},
  fieldName = "This field",
): yup.NumberSchema => {

  const {tErrors} = useIGTranslation();

  let validator = yup.number();

  if (!options) {
    return validator;
  }

  if (options.nullable) {
    // @ts-ignore
    validator = validator.nullable(true);
    delete options.nullable;
  }

  // check for numbers
  validator = validator.typeError(tErrors("TYPE", {type: "numbers"}));

  if (options.custom) {
    const ruleValue = options.custom;
    if ("test" in ruleValue) {
      // @ts-ignore
      validator = validator.test({
        name: ruleValue?.name || "",
        message: ruleValue?.message || "",
        params: ruleValue?.params || {},
        exclusive: ruleValue?.exclusive,
        test: ruleValue?.test || function () {return true;},
      });
    }
    delete options.custom;
  }

  if (options.length) {
    const ruleValue = options.length;
    const valToCheck = typeof ruleValue === "object" ? ruleValue.value : ruleValue;
    const message = typeof ruleValue === "object"
      ? ruleValue.message
      : tErrors(`${ERRORS_MAPPING.length}`, {
        field: fieldName,
        length: ruleValue,
      });

    validator = validator.test(
      "numLength",
      "",
      function (value, context) {
        if (value && String(value).length !== valToCheck) {
          return context.createError({
            message: message,
            type: "numLength",
          });
        }
        return true;
      },
    );
    delete options.length;
  }

  if (options.positiveWithZero) {
    validator = validator.test(
      "positiveWithZero",
      "",
      function (value, context) {
        if (value && value < 0) {
          return context.createError({
            message: tErrors(`${ERRORS_MAPPING.positiveWithZero}`, {field: fieldName}),
            type: "positiveWithZero",
          });
        }
        return true;
      },
    );
    delete options.positiveWithZero;
  }

  if (options.isPhoneNumber) {
    validator = validator.test(
      "isPhoneNumber",
      "",
      function (value, context) {
        const regEx = /^\d{10}$/;
        if (value && !(String(value).match(regEx))) {
          return context.createError({
            message: tErrors(`${ERRORS_MAPPING.isPhoneNumber}`, {field: fieldName}),
            type: "isPhoneNumber",
          });
        }
        return true;
      },
    );
    delete options.isPhoneNumber;
  }

  let ruleName: keyof typeof options;
  for (ruleName in options) {

    const ruleValue = options[ruleName];

    switch (typeof ruleValue) {

      case "object":
        // @ts-ignore
        // if custom message is provided
        validator = validator[ruleName](ruleValue.value, ruleValue.message);
        break;

      case "string":
        // @ts-ignore
        validator = validator[ruleName](ruleValue);
        break;

      case "number":
        // @ts-ignore
        validator = validator[ruleName](ruleValue,
          tErrors(
            `${ERRORS_MAPPING[ruleName]}`,
            {field: fieldName, [ruleName]: ruleValue},
          ),
        );
        break;

      case "boolean":
      default:
        // @ts-ignore
        validator = validator[ruleName](
          tErrors(
            `${ERRORS_MAPPING[ruleName]}`,
            {field: fieldName},
          ),
        );
        break;
    }

  }

  return validator;
};

export default useNumberValidations;