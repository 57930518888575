import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@mui/styles";

import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {Col, Row} from "react-bootstrap";
import {
  LinearProgress,
  IconButton,
  Paper,
  TableRow,
  TablePagination,
  TableFooter,
  TableCell,
  TableHead,
  TableBody,
  Table,
} from "@mui/material";
import {
  getQueryStringParams,
  getFilterMap,
  getSearchQuery,
  formatDate,
} from "_metronic";
import axios from "_metronic/utils/axios";
import {API_ENDPOINTS} from "app/constants/constants";
import BannedUsers from "./BannedUsers";
import {useAppSelector, useSnackBar} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {IGMenu, IGDialog, IGTextField, IGButton} from "app/components";

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    minHeight: "400px",
  },
  button: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  formControl: {
    marginBottom: 20,
    minWidth: 200,
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontStyle: "bold",
  },
}));

export default function BannedDevices(props) {
  const classes = useStyles2();
  const {showError} = useSnackBar();
  const {userRole} = useAppSelector(authSelectors.getUser);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState({
    userId: "",
  });
  const [loading, setLoading] = React.useState({
    changeStatus: false,
    filters: false,
    actions: false,
  });
  const [modal, setModal] = React.useState({
    bannedUsers: {
      open: false,
      deviceId: null,
    },
  });

  const filterMap = getFilterMap(["userId", "mobile"]);

  const headers = ["Device ID", "Reason", "User count", "Banned at"];

  const valueKeys = ["deviceId", "reason", "userCount", "createdAt"];

  const counter = useRef(0);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 100));
  }

  const fetchTableData = useCallback(() => {
    const queryParams = getSearchQuery(page, rowsPerPage, searchFilter);
    if (!queryParams) return;

    setLoading({...loading, filters: true});
    axios
      .request({
        method: API_ENDPOINTS.APP_CONFIG.GET_BANNED_DEVICES.METHOD,
        url:
          `${API_ENDPOINTS.APP_CONFIG.GET_BANNED_DEVICES.URL  }?${queryParams}`,
        data: null,
      })
      .then((response) => {
        if (response && response.data && response.data.result) {
          setLoading({...loading, filters: false});
          response.data.result.forEach((row) => {
            row.createdAt = formatDate(row.createdAt);
          });
          setRows(response.data.result);
          if (response.data.result.length < rowsPerPage) {
            setTotalRows(page * rowsPerPage + response.data.result.length);
          } else {
            setTotalRows((page + 1) * rowsPerPage + 1);
          }
        }
      })
      .catch((err) => {
        setLoading({...loading, filters: false});
        if (
          err &&
          err.response &&
          [401, 403, 422].includes(err.response.status)
        ) {
          showError(
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.message,
          );
        } else {
          showError("Something went wrong");
        }
      });
  }, [loading, page, rowsPerPage, searchFilter]);

  React.useEffect(() => {
    const queryParams = getQueryStringParams(window.location.search);
    Object.keys(queryParams).forEach((key) => {
      if (key in searchFilter) {
        searchFilter[key] = queryParams[key];
        setSearchFilter({
          ...searchFilter,
          key: queryParams[key],
        });
      }
    });
    if (counter.current === 0) {
      counter.current = 1;
      fetchTableData();
    }
  }, [fetchTableData, page, rowsPerPage, searchFilter]);

  function applyFilters() {
    let atLeastOneFilterSelected = false;
    Object.keys(searchFilter).forEach((key) =>
      searchFilter[key] ? (atLeastOneFilterSelected = true) : null,
    );
    if (!atLeastOneFilterSelected) return;

    if (parseInt(searchFilter.userId) <= 0) {
      showError("User ID cannot be negative.");
      return;
    }
    if (page === 0) fetchTableData();
    else setPage(0);
  }

  function clearFilters() {
    searchFilter = {
      userId: "",
    };
    setSearchFilter({
      userId: "",
    });
    if (page === 0) fetchTableData();
    else setPage(0);
  }

  return (
    <Paper className={classes.root}>

      {/* HEADER */}
      {props.header ? (
        <Row>
          <Col className={classes.header}>USERS</Col>
        </Row>
      ) : null}

      {/* DIALOG */}
      {props.dialog ? (
        <IGDialog
          hideFooter
          title="Banned Users"
          open={modal.bannedUsers.open}
          maxWidth="md"
          onClose={() =>
            setModal({
              ...modal.bannedUsers,
              bannedUsers: {open: false, deviceId: null},
            })
          }
        >
          {modal.bannedUsers.deviceId ? (
            <BannedUsers deviceId={modal.bannedUsers.deviceId} header table />
          ) : null}
        </IGDialog>
      ) : null}

      {/* FILTERS */}
      {props.filters ? (
        <div>
          <Row className="my-3 mx-3">
            {!filterMap[userRole] ||
            filterMap[userRole].indexOf("userId") !== -1 ? (
              <Col md="2" xs="6" className="mt-4">
                <IGTextField
                  name="userId"
                  label="User ID"
                  value={searchFilter.userId}
                  onChange={(e) =>
                    setSearchFilter({...searchFilter, userId: e.target.value})
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      applyFilters();
                    }
                  }}
                />
              </Col>
            ) : null}
            <Col md="3" xs="6" className="mt-4">
              <IGButton
                disabled={loading.filters}
                loading={loading.filters}
                color="golden"
                onClick={() => applyFilters()}
              >
                Apply
              </IGButton>
              <IGButton
                disabled={loading.filters}
                loading={loading.filters}
                color="sdGrey"
                onClick={() => clearFilters()}
              >
                Clear
              </IGButton>
            </Col>
          </Row>
        </div>
      ) : null}

      {/* TABLE */}
      {props.table ? (
        <div className={classes.tableWrapper}>
          {!loading.filters ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell align="center" key={header}>
                      {header}
                    </TableCell>
                  ))}
                  {props.actions ? (
                    <TableCell align="center" key="Actions">
                      Actions
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    {valueKeys.map((key) => (
                      <TableCell
                        align="center"
                        key={key}
                        style={{minWidth: "100px"}}
                      >
                        {row[key]}
                      </TableCell>
                    ))}
                    {props.actions ? (
                      <TableCell align="center">
                        <div>
                          <IGMenu
                            actionButtonProps={{
                              id: `user-actions-button-${index}`,
                              label: "Actions",
                            }}
                            actionMenuProps={{
                              id: `user-actions-menu-${index}`,
                              items: [
                                {
                                  id: "user-list",
                                  label: "User List",
                                  renderCondition: true,
                                  onClick: () => {
                                    setModal({
                                      ...modal,
                                      bannedUsers: {
                                        open: true,
                                        deviceId: row.deviceId,
                                      },
                                    });
                                  },
                                },
                              ],
                            }}
                          />
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={10}
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {"aria-label": "Rows per page"},
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <LinearProgress />
          )}
        </div>
      ) : null}
    </Paper>
  );
}
