import React, {useCallback, useState} from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import {Logout, Settings} from "@mui/icons-material";
import useStyles from "./styles";
import {useAppSelector, useAppDispatch} from "app/store/hooks";
import {authActions, authSelectors} from "app/store/auth";
import packageJson from "../../../../../package.json";
import {IGLoading} from "app/components";

export default function ProfileMenu({
  showAvatar,
  showHi,
  changePasswordHandler,
}) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {userRole, profilePicture, name} = useAppSelector(
    authSelectors.getUser,
  );
  const {
    logout: logoutLoading,
  } = useAppSelector(authSelectors.getLoading);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOutHandler = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);


  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{p: 0}}
      >
        <div className={classes.container}>
          {showHi && (
            <Typography color="textPrimary" variant="h6">
              Hi,
            </Typography>
          )}

          {showHi && (
            <Typography color="textPrimary" variant="h6">
              {name}
            </Typography>
          )}

          {showAvatar && (
            <img
              className={classes.image}
              alt="Pic"
              src={profilePicture || "/media/users/300_25.jpg"}
            />
          )}
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiMenu-list": {
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={changePasswordHandler}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem onClick={onLogOutHandler}>
          <ListItemIcon>
            {logoutLoading ? (
              <CircularProgress size={16} />
            ) : (
              <Logout fontSize="small" />
            )}
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          Version : {packageJson.version}
        </MenuItem>
        <MenuItem onClick={handleClose}>Role : {userRole}</MenuItem>
      </Menu>
    </div>
  );
}
