import {createAction} from "@reduxjs/toolkit";
import {GenerateVirtualAddressPayload, GetAllVirtualAddressesPayload, LoanIdPayload, LoanResponse, UserIdPayload} from "./loan.types";

const loanActionTypes = {
  GetLoanDetails: "[Loan] Get Loan Details",
  GetLoanDetailsSuccess: "[Loan] Get Loan Details Success",
  GetLoanDetailsFailure: "[Loan] Get Loan Details Failure",
  GetLoanRepaymentSchedule: "[Loan] Get Loan Repayment Schedule",
  GetLoanRepaymentScheduleSuccess: "[Loan] Get Loan Repayment Schedule Success",
  GetLoanRepaymentScheduleFailure: "[Loan] Get Loan Repayment Schedule Failure",
  GetAllUserLoans: "[Loan] Get All User Loans",
  GetAllUserLoansSuccess: "[Loan] Get All User Loans Success",
  GetAllUserLoansFailure: "[Loan] Get All User Loans Failure",
  GetAllVirtualAddresses: "[Loan] Get All Virtual Addresses",
  GetAllVirtualAddressesSuccess: "[Loan] Get All Virtual Addresses Success",
  GetAllVirtualAddressesFailure: "[Loan] Get All Virtual Addresses Failure",
  GenerateVirtualAddress: "[Loan] Generate Virtual Address",
  GenerateVirtualAddressSuccess: "[Loan] Generate Virtual Address Success",
  GenerateVirtualAddressFailure: "[Loan] Generate Virtual Address Failure",
  GetLenderLoanDetails: "[Loan] Get Lender Loan Details",
  GetLenderLoanDetailsSuccess: "[Loan] Get Lender Loan Details Success",
  GetLenderLoanDetailsFailure: "[Loan] Get Lender Loan Details Failure",
  GetLenderLoanRepaymentSchedule: "[Loan] Get Lender Loan Repayment Schedule",
  GetLenderLoanRepaymentScheduleSuccess: "[Loan] Get Lender Loan Repayment Schedule Success",
  GetLenderLoanRepaymentScheduleFailure: "[Loan] Get Lender Loan Repayment Schedule Failure",
};

const loanActions = {
  getLoanDetails: createAction<LoanIdPayload>(
    loanActionTypes.GetLoanDetails,
  ),
  getLoanDetailsSuccess: createAction<LoanResponse>(
    loanActionTypes.GetLoanDetailsSuccess,
  ),
  getLoanDetailsFailure: createAction<LoanResponse>(
    loanActionTypes.GetLoanDetailsFailure,
  ),
  getLoanRepaymentSchedule: createAction<LoanIdPayload>(
    loanActionTypes.GetLoanRepaymentSchedule,
  ),
  getLoanRepaymentScheduleSuccess: createAction<LoanResponse>(
    loanActionTypes.GetLoanRepaymentScheduleSuccess,
  ),
  getLoanRepaymentScheduleFailure: createAction<LoanResponse>(
    loanActionTypes.GetLoanRepaymentScheduleFailure,
  ),
  getAllUserLoans: createAction<UserIdPayload>(
    loanActionTypes.GetAllUserLoans,
  ),
  getAllUserLoansSuccess: createAction<LoanResponse>(
    loanActionTypes.GetAllUserLoansSuccess,
  ),
  getAllUserLoansFailure: createAction<LoanResponse>(
    loanActionTypes.GetAllUserLoansFailure,
  ),
  getAllVirtualAddresses: createAction<GetAllVirtualAddressesPayload>(
    loanActionTypes.GetAllVirtualAddresses,
  ),
  getAllVirtualAddressesSuccess: createAction<LoanResponse>(
    loanActionTypes.GetAllVirtualAddressesSuccess,
  ),
  getAllVirtualAddressesFailure: createAction<LoanResponse>(
    loanActionTypes.GetAllVirtualAddressesFailure,
  ),
  generateVirtualAddress: createAction<GenerateVirtualAddressPayload>(
    loanActionTypes.GenerateVirtualAddress,
  ),
  generateVirtualAddressSuccess: createAction<LoanResponse>(
    loanActionTypes.GenerateVirtualAddressSuccess,
  ),
  generateVirtualAddressFailure: createAction<LoanResponse>(
    loanActionTypes.GenerateVirtualAddressFailure,
  ),
  getLenderLoanDetails: createAction<LoanIdPayload>(
    loanActionTypes.GetLenderLoanDetails,
  ),
  getLenderLoanDetailsSuccess: createAction<LoanResponse>(
    loanActionTypes.GetLenderLoanDetailsSuccess,
  ),
  getLenderLoanDetailsFailure: createAction<LoanResponse>(
    loanActionTypes.GetLenderLoanDetailsFailure,
  ),
  getLenderLoanRepaymentSchedule: createAction<LoanIdPayload>(
    loanActionTypes.GetLenderLoanRepaymentSchedule,
  ),
  getLenderLoanRepaymentScheduleSuccess: createAction<LoanResponse>(
    loanActionTypes.GetLenderLoanRepaymentScheduleSuccess,
  ),
  getLenderLoanRepaymentScheduleFailure: createAction<LoanResponse>(
    loanActionTypes.GetLenderLoanRepaymentScheduleFailure,
  ),
};

export default loanActions;

export {
  loanActionTypes,
};