import React, {useEffect, useRef, useState, useCallback} from "react";
import styles from "../styles";
import getPaymentDetails from "../../../../../../../api/business/getPaymentDetail";
import {CircularProgress, Box, Typography} from "@mui/material";

import GreenTickIcon from "../../../../../../assets/GreenTick";
import PendingIcon from "../../../../../../assets/Processing";
import clsx from "clsx";
import numberWith from "../../../../../../../lib/numberWithCurrencyAndComma";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import getDocument from "api/business/getDocument";
import {IGDocumentViewer} from "app/components";
import {TakeoverPaymentDocEnum} from "app/enums/gold_loan/activeLoans";

const PaymentDetails = ({selectedPayload, otherRequestTimer}) => {
  const classes = styles();
  const counter = useRef(0);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: null,
  });

  const accessToken = useAppSelector(authSelectors.getAuthToken);

  const [takeoverPaymentDoc, setTakeoverPaymentDoc] = useState([]);

  const getPaymentDetailsAPi = useCallback(async () => {
    try {
      const response = await getPaymentDetails({
        accessToken,
        crId: selectedPayload.id,
        paymentDetailType: "TAKEOVER_PAYMENT_DETAIL",
      });

      if (response.statusCode === 200) {
        setStates({
          loading: false,
          error: false,
          payload: response.payload,
        });
      } else {
        setStates({
          loading: false,
          error: true,
          payload: null,
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: null,
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getPaymentDetailsAPi();
    }
  }, [getPaymentDetailsAPi, selectedPayload.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      getPaymentDetailsAPi();
    }, otherRequestTimer);

    return () => {
      clearTimeout(timer);
    };
  }, [getPaymentDetailsAPi, otherRequestTimer]);

  const fetchDocumentDetails = useCallback(async () => {
    const {
      payload: TakeoverDocPayload,
      error: TakeoverDocPayloadError,
    } = await getDocument({
      accessToken,
      crId: selectedPayload.id,
      documentType: [TakeoverPaymentDocEnum.RTGS_POST_PAYMENT_APPROVAL_SLIP],
    });

    if (!TakeoverDocPayloadError) {
      setTakeoverPaymentDoc(TakeoverDocPayload);
    }
  }, [accessToken, selectedPayload.id]);

  useEffect(() => {
    fetchDocumentDetails();
  }, []);

  if (states.error) {
    return <div>Something went Wrong</div>;
  }

  if (states.loading) {
    return <CircularProgress />;
  }

  const GetIcon = () => {
    if (states.error) {
      return <PendingIcon />;
    }
    return <GreenTickIcon />;
  };

  const getText = () => {
    if (states.error) {
      return "Pending";
    }
    return "Success";
  };

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>Status of Takeover Payment</div>
        <div className={clsx(classes.icon, classes.subText)}>
          {<GetIcon />}
          {getText()}
        </div>
      </div>

      {!states.error && (
        <>
          <div className={classes.textContainer}>
            <div className={classes.text}>PAYMENT REFERENCE NUMBER</div>
            <div className={classes.subText}>
              {states.payload.paymentReferenceNumber}
            </div>
          </div>

          <div className={classes.textContainer}>
            <div className={classes.text}>UTR Number</div>
            <div className={classes.subText}>{states.payload.utrNumber}</div>
          </div>

          <div className={classes.textContainer}>
            <div className={classes.text}>PAYMENT AMOUNT</div>
            <div className={classes.subText}>
              {numberWith(states.payload.paymentAmount)}
            </div>
          </div>

          {takeoverPaymentDoc && (
            <>
              {takeoverPaymentDoc.map((doc) => (
                <Box mt={2}>
                  <Typography mb={1} className={classes.text}>
                    {doc.documentType.replaceAll("_", " ")}
                  </Typography>
                  <IGDocumentViewer document={doc} />
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentDetails;
