import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "../../infra/services/api";
import {snackBarActions} from "../snackbar";

import collateralMovementActions, {
  collateralMovementActionTypes,
} from "./collateralMovement.actions";
import {
  GetCollateralMovementPayload,
  CollateralMovementResponse,
  GetInwardReceiptImagePayload,
} from "./collateralMovement.types";

const {
  getAllCollateralMovementSuccess,
  getAllCollateralMovementFailure,
  getInwardReceiptImageSuccess,
  getInwardReceiptImageFailure,
} = collateralMovementActions;

function* generalErrorFlow(action: PayloadAction<CollateralMovementResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getAllCollateralMovementFlow(
  action: PayloadAction<GetCollateralMovementPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.collateralMovement.getAllCollateralMovement,
    action.payload,
  );
  if (error) {
    yield put(getAllCollateralMovementFailure({response: null, error}));
  } else {
    yield put(getAllCollateralMovementSuccess({response, error: null}));
  }
}

function* getInwardReceiptImageFlow(
  action: PayloadAction<GetInwardReceiptImagePayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.collateralMovement.getInwardReceiptImage,
    action.payload,
  );
  if (error) {
    yield put(
      getInwardReceiptImageFailure({response: null, error: error.message}),
    );
  } else {
    yield put(
      getInwardReceiptImageSuccess({response, crId: action.payload.crId}),
    );
  }
}

export default function* collateralMovementSaga() {
  yield takeLatest(
    collateralMovementActionTypes.GetAllCollateralMovement,
    getAllCollateralMovementFlow,
  );
  yield takeLatest(
    collateralMovementActionTypes.GetAllCollateralMovementFailure,
    generalErrorFlow,
  );
  yield takeLatest(
    collateralMovementActionTypes.GetInwardReceiptImage,
    getInwardReceiptImageFlow,
  );
  yield takeLatest(
    collateralMovementActionTypes.GetInwardReceiptImageFailure,
    generalErrorFlow,
  );
}
