import styled from "@emotion/styled";

const Input = styled.input`
  width: 14rem;
  padding-left: 0.5rem;

  outline: none;
`;

export default Input;
