import axios from "_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";

const apiService = async (
  resource,
  options = {},
  contentType = "application/json",
) => {
  let url = getApiUrl() + resource.URL;

  if (options.path) {
    Object.keys(options.path).forEach(
      (key) => (url = url.replace(`:${key}`, `${options.path[key]}`)),
    );
  }
  if (options.queryParams) {
    url +=
      `?${
      Object.keys(options.queryParams)
        .map((key) => `${key  }=${  options.queryParams[key]}`)
        .join("&")}`;
  }
  const default_headers = {
    "Content-Type": contentType,
  };

  let response = null;
  let error = null;
  try {
    const api_response = await axios.request({
      method: resource.METHOD,
      url: url,
      data: options.data,
      auth: resource.AUTH,
      headers: {...default_headers},
    });

    const result = api_response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    response = {
      statusCode: 200,
      payload: result.result,
      message: result.message ? result.message : "Success.",
      error,
    };
  } catch (api_error) {
    error=api_error;
    // eslint-disable-next-line no-console
    // console.log(api_error);

    //To directely show error msg using global snackbar
    // if (options.silent !== true) {
    //     Util.updateSnackbarError(error);
    // }
  }
  return {response, error};
};

export default apiService;
