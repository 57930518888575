import React, {FC} from "react";
import {Typography, Grid, Divider} from "@mui/material";
import {LeegalityContainer, Title} from "../styles";
import {docFlowEnum} from "app/enums/gold_loan/activeLoans";
import IconWrapper from "../../NewUploadKYC/KYCDetails/styles/IconWrapper";
import DocumentDetails from "../DocumentDetails";

interface DocumentFlowContainerProps {
  title: string;
  lenderIcon: string;
  docFlow: string;
  documentsType: any;
  crID: number;
}
const DocumentFlowContainer: FC<DocumentFlowContainerProps> = ({
  title,
  lenderIcon,
  docFlow,
  documentsType,
  crID,
}) => {
  return (
    <>
      <LeegalityContainer>
        <Title>{title}</Title>
        <IconWrapper width={"auto"} src={lenderIcon} alt="bank-icon" />
      </LeegalityContainer>
      {docFlow && (
        <LeegalityContainer>
          <Title>Document Type</Title>
          <Typography
            variant="h6"
            sx={{
              color: docFlow === docFlowEnum.ONLINE ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {docFlow}
          </Typography>
        </LeegalityContainer>
      )}
      <Grid container spacing={1}>
        {documentsType.map((item: any, index: number) => (
          <>
            <Grid item xs={4} rowSpacing={1}>
              <DocumentDetails
                key={index}
                docType={item.documentType}
                docName={item.name}
                crId={crID}
              />
            </Grid>
            {documentsType.length - 1 !== index && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{background: "#ce9f29"}}
              ></Divider>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

export default DocumentFlowContainer;
