import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources} from "app/typings/api/goldApi.types";
import {ComplaintsApiInterface} from "./types";

const COMPLAINT_ENPOINTS: ApiResources = {
  VALIDATE_JUMPER: {
    URL: "/api/v1/admin/gold-loan/rescheduling/jumper-removal/validate",
    METHOD: "POST",
  },
  INITIAITE_JUMPER_REMOVAL: {
    URL: "/api/v1/admin/gold-loan/rescheduling/jumper-removal/initiate",
    METHOD: "POST",
  },
  RETRY_JUMPER_REMOVAL: {
    URL: "/api/v1/admin/gold-loan/rescheduling/jumper-removal/retry",
    METHOD: "POST",
  },
  CHECK_JUMPER_REMOVAL_STATUS: {
    URL: "/api/v1/admin/gold-loan/rescheduling/jumper-removal/status",
    METHOD: "GET",
  },
  GET_CUSTOMER_AGENT_CONVERSATION: {
    URL: "/admin/service-desk/request/:requestId/agent-customer-conversation",
    METHOD: "GET",
  },
  POST_AGENT_REPLY_TO_CONVERSATION: {
    URL: "/admin/service-desk/request/:requestId/agent-conversation-reply",
    METHOD: "POST",
  },
  UPDATE_SERVICE_REQUEST_MOBILE_NUMBER: {
    URL: "/admin/service-desk/request/:requestId/mobile-number",
    METHOD: "PUT",
  },
  GET_REQUEST_STATUS_COUNT: {
    URL: "/admin/service-desk/request/status-count",
    METHOD: "GET",
  },
  GET_CLOSE_TICKET_QUESTIONS: {
    URL: "/admin/service-desk/feedback/questions",
    METHOD: "GET",
  },
  CLOSE_FRESH_DESK_TICKET: {
    URL: "/admin/service-desk/request/:requestId/mark-resolved",
    METHOD: "POST",
  },
};

const complaintsApi: ComplaintsApiInterface = {
  validateJumperRemoval: async ({parentLoanId, serviceRequestId}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.VALIDATE_JUMPER,
      options: {
        data: {
          parentLoanId,
          serviceRequestId,
        },
      },
    });
  },
  initiateJumperRemoval: async ({
    parentLoanId,
    serviceRequestId,
    subventionRestoreRequested,
    proofFile,
  }) => {
    const formData = new FormData();
    formData.append("parentLoanId", parentLoanId.toString());
    formData.append("serviceRequestId", serviceRequestId.toString());
    formData.append(
      "subventionRestoreRequested",
      subventionRestoreRequested.toString(),
    );
    formData.append("proofFile", proofFile);

    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.INITIAITE_JUMPER_REMOVAL,
      options: {
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  retryJumperRemoval: async ({parentLoanId, serviceRequestId}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.RETRY_JUMPER_REMOVAL,
      options: {
        data: {
          parentLoanId,
          serviceRequestId,
        },
      },
    });
  },
  checkJumperRemovalStatus: async ({serviceRequestId}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.CHECK_JUMPER_REMOVAL_STATUS,
      options: {
        queryParams: {
          serviceRequestId,
        },
      },
    });
  },
  getCustomerAgentConversation: async ({requestId}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.GET_CUSTOMER_AGENT_CONVERSATION,
      options: {
        pathVars: {
          requestId,
        },
      },
    });
  },
  postAgentReplyToConversation: async ({requestId, formData}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.POST_AGENT_REPLY_TO_CONVERSATION,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      options: {
        pathVars: {
          requestId,
        },
        data: formData,
      },
    });
  },
  updateServiceRequestMobileNumber: async ({requestId, mobile}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.UPDATE_SERVICE_REQUEST_MOBILE_NUMBER,
      options: {
        pathVars: {
          requestId,
        },
        data: {
          mobile,
        },
      },
    });
  },
  getRequestStatusCount: async () => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.GET_REQUEST_STATUS_COUNT,
      options: {
        queryParams: {
          fwcProductEnum: "NON_GOLD_LOAN",
          serviceRequestTypeEnum: "COMPLAINT",
        },
      },
    });
  },
  getCloseTicketQuestions: async ({
    feedbackQuestionCategory="TICKET_REOPEN",
  }) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.GET_CLOSE_TICKET_QUESTIONS,
      options: {
        queryParams: {
          feedbackQuestionCategory,
        },
      },
    });
  },
  closeFreshDeskTicket: async ({requestId, feedbackResponseCreateDtoList}) => {
    return await goldApiService({
      resource: COMPLAINT_ENPOINTS.CLOSE_FRESH_DESK_TICKET,
      options: {
        pathVars: {
          requestId,
        },
        data: feedbackResponseCreateDtoList,
      },
    });
  },
};

export default complaintsApi;
