import {createAction} from "@reduxjs/toolkit";
import {WhitelistState} from "./reducer";

export const WhitelistUsersTypes = {
  GetWhitelistUsers: "[Whitelist User] Get Whitelist Users",
  GetWhitelistUsersSuccess: "[Whitelist User] Get Whitelist Users Success",
  GetWhitelistUsersError: "[Whitelist User] Get Whitelist Users Error",
  CreateWhitelistUser: "[Whitelist User] Create Whitelist User",
  CreateWhitelistUserSuccess: "[Whitelist User] Create Whitelist User Success",
  CreateWhitelistUserError: "[Whitelist User] Create Whitelist User Error",
  DeleteWhitelistUser: "[Whitelist User] Delete Whitelist User",
  DeleteWhitelistUserSuccess: "[Whitelist User] Delete Whitelist User Success",
  DeleteWhitelistUserError: "[Whitelist User] Delete Whitelist User Error",
};

export const whitelistUsersAction = {
  getWhitelistUsers: createAction(WhitelistUsersTypes.GetWhitelistUsers),
  getWhitelistUsersSuccess: createAction<WhitelistState>(
    WhitelistUsersTypes.GetWhitelistUsersSuccess,
  ),
  getWhitelistUsersError: createAction<WhitelistState>(
    WhitelistUsersTypes.GetWhitelistUsersError,
  ),
  createWhitelistUser: createAction<WhitelistState>(
    WhitelistUsersTypes.CreateWhitelistUser,
  ),
  createWhitelistUserSuccess: createAction<WhitelistState>(
    WhitelistUsersTypes.CreateWhitelistUserSuccess,
  ),
  createWhitelistUserError: createAction<WhitelistState>(
    WhitelistUsersTypes.CreateWhitelistUserError,
  ),
  deleteWhitelistUser: createAction<WhitelistState>(
    WhitelistUsersTypes.DeleteWhitelistUser,
  ),
  deleteWhitelistUserSuccess: createAction<WhitelistState>(
    WhitelistUsersTypes.DeleteWhitelistUserSuccess,
  ),
  deleteWhitelistUserError: createAction<WhitelistState>(
    WhitelistUsersTypes.DeleteWhitelistUserError,
  ),
};
