const numberWithCurrencyAndComma = (number, minimumFractionDigits = 2, nullPlaceHolder = "-") => {
  let finalNumber = number;
  if (typeof number === "string") {
    if (number) {
      finalNumber = number;
    } else {
      return nullPlaceHolder;
    }
  }
  return (finalNumber || 0).toLocaleString("en-IN", {
    currency: "INR",
    currencyDisplay: "symbol",
    minimumFractionDigits: minimumFractionDigits,
    style: "currency",
  });
};

export const decimalDigits = (number, minimumFractionDigits = 2, nullPlaceHolder = "-") => {
  return number ? (number || 0).toLocaleString("en-IN", {
    minimumFractionDigits: minimumFractionDigits,
  }) : nullPlaceHolder;
};

export default numberWithCurrencyAndComma;
