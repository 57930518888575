import {LoanPaymentApiInterface, LoanPaymentApiResources} from "app/store/loanPayment/loanPayment.types";
import {goldApiService} from "../../../plugins/axios";

const LOAN_PAYMENT_ENDPOINTS: LoanPaymentApiResources = {
  GET_LOAN_PAYMENT: {
    URL: "admin/business/loan/:loanId/payment",
    METHOD: "GET",
  },
  GET_LENDER_LOAN_PAYMENT: {
    URL: "/admin/business/lender/loan/:loanId/payment",
    METHOD: "GET",
  },
  GET_REFUND_STATUS: {
    URL: "/admin/loan/payment/:paymentId/refund",
    METHOD: "GET",
  },
  REFUND_PAYMENT: {
    URL: "/admin/loan/payment/:paymentId/refund",
    METHOD: "PUT",
  },
  SHIFT_PAYMENT: {
    URL: "/admin/loan/shift-payment",
    METHOD: "POST",
  },
  GET_SHIFT_PAYMENT_STATUS: {
    URL: "/admin/loan/shift-payment/:paymentId",
    METHOD: "GET",
  },
};

const loanPaymentApi: LoanPaymentApiInterface = {
  getLoanPaymentDetails: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.GET_LOAN_PAYMENT,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getLenderLoanPaymentDetails: async ({loanId}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.GET_LENDER_LOAN_PAYMENT,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getRefundStatus: async ({paymentId}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.GET_REFUND_STATUS,
      options: {
        pathVars: {paymentId},
      },
    });
  },
  refundPayment: async ({paymentId, formData}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.REFUND_PAYMENT,
      options: {
        pathVars: {paymentId},
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  shiftLoanPayment: async ({formData}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.SHIFT_PAYMENT,
      options: {
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getShiftPaymentStatus: async ({paymentId}) => {
    return await goldApiService({
      resource: LOAN_PAYMENT_ENDPOINTS.GET_SHIFT_PAYMENT_STATUS,
      options: {
        pathVars: {paymentId},
      },
    });
  },
};

export {LOAN_PAYMENT_ENDPOINTS};
export default loanPaymentApi;