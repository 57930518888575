import React, {useMemo} from "react";
import DocumentDetailWrapper from "../styles/DocumentDetailWrapper";
import DocumentTitleWrapper from "../styles/DocumentTitleWrapper";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import StyledDocumentContainer from "../styles/KycDocumentContainer";
import DocumentTitleBar from "../styles/DocumentTitleBar";
import DocumentChooseSwitch from "../styles/DocumentChooseSwitch";
import GoldTitle from "../styles/GoldTitle";
import ZoomOutWrapper from "../styles/ZoomOutWrapper";
import Title from "../../styles/Title";
import PiramalDocContainer from "../styles/PiramalDocContainer";
import {ZoomOutMap} from "@mui/icons-material";
import ZoomOutIconWrapper from "../styles/ZoomOutIcon";
import DetailWrapper from "../styles/DetailWrapper";
import SubTitle from "../../styles/SubTitle";
import SubText from "../../styles/SubText";
import Loading from "app/components/Loading";
import styled from "@emotion/styled";
import {Box} from "@mui/material";
import DocBlacklistedStatusChip from "../styles/DocBlackListedStatusChip";
import {useAppSelector} from "app/store/hooks";
import {activeLoansSelectors} from "app/store/activeLoans";

const StyledDocumentStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
`;

const DocumentContainerComponent = ({
  documentsLoading,
  document,
  setOpenModal,
  checkButtonDisable,
  isChecked,
  onCheck,
  title,
  borderLeft,
  renderSwitch,
}) => {
  const selectedCr = useAppSelector(activeLoansSelectors.getSelectedCR);

  const renderChipBlockForDocApprovalStatus = () => {
    if (document) {
      const {isBlackListed, blacklistReason} = document ?? {};
      if (title === "AADHAR" || title === "PAN") {
        return (
          <>
            <Box m={1}>
              <DocBlacklistedStatusChip
                className={isBlackListed ? "errorChip" : "successChip"}
                size="small"
                label={
                  isBlackListed
                    ? blacklistReason === "IG_EMPLOYEE"
                      ? "Blacklisted: IG employee"
                      : "Blacklisted"
                    : "Approved"
                }
              />
            </Box>
          </>
        );
      }
    }
  };

  const documentStatus = useMemo(() => {
    if (document?.documentNumber) {
      return "Submitted";
    }

    if ((title === "AADHAR" || title === "PAN") && selectedCr?.isDataPurged) {
      return "Purged";
    }

    return "Not Submitted";
  }, [document?.documentNumber, title, selectedCr?.isDataPurged]);

  return (
    <StyledDocumentContainer borderLeft={borderLeft}>
      {!documentsLoading && (
        <DocumentTitleBar>
          <DocumentTitleWrapper>
            {renderSwitch ? (
              <DocumentChooseSwitch
                checked={isChecked}
                onChange={() => onCheck()}
                disabled={!document?.documentNumber || checkButtonDisable}
              />
            ) : (
              <></>
            )}
            <GoldTitle>{title}</GoldTitle>
            {renderChipBlockForDocApprovalStatus()}
          </DocumentTitleWrapper>
          <ZoomOutWrapper>
            <StyledDocumentStatus>{documentStatus}</StyledDocumentStatus>
          </ZoomOutWrapper>
        </DocumentTitleBar>
      )}

      {!documentsLoading ? (
        <DocumentDetailWrapper>
          <DetailWrapper>
            <PiramalDocContainer>
              <SubTitle>Document Number</SubTitle>
              <Title>{document?.documentNumber || "N/A"}</Title>
            </PiramalDocContainer>
            <PiramalDocContainer>
              <SubTitle>Remarks</SubTitle>
              <Title>{document?.remark || "N/A"}</Title>
            </PiramalDocContainer>
          </DetailWrapper>
          <DetailWrapper marginLeft={true}>
            {document?.documentNumber && (
              <SubText>
                Photo Verification
                <ZoomOutIconWrapper color="#ce9f29">
                  <ZoomOutMap onClick={() => setOpenModal(true)} />
                </ZoomOutIconWrapper>
              </SubText>
            )}

            {/* Document photo */}
            <DocumentImageWrapper>
              {document?.fileUrl && (
                <DocIconWrapper
                  src={document.fileUrl}
                  alt={`${document?.documentType}_front`}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
              )}
              {document?.backFileUrl && (
                <DocIconWrapper
                  src={document.backFileUrl}
                  alt={`${document?.documentType}_back`}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
              )}
            </DocumentImageWrapper>
          </DetailWrapper>
        </DocumentDetailWrapper>
      ) : (
        <DocumentDetailWrapper>
          <Loading height="20vh" />
        </DocumentDetailWrapper>
      )}
    </StyledDocumentContainer>
  );
};

export default DocumentContainerComponent;
