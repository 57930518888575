import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  GetCurrentTaskStatusDetailPayload,
  ParentLoanIdPayload,
  ServiceRequestIdPayload,
  UpdateCustomerRequirementsByCSTPayload,
  OfferUpdateByCMPayload,
} from "app/infra/services/api/rateChange/rateChange.types";
import {call, getContext, put, takeLatest} from "redux-saga/effects";

import rateChangeActions, {rateChangeActionTypes} from "./rateChange.actions";
import {snackBarActions} from "app/store/snackbar";
import {forceRefresh} from "./rateChange.reducer";

const {
  getLoanCurrentPlanSuccess,
  getLoanCurrentPlanFailure,
} = rateChangeActions;

function* getLoanCurrentPlanFlow(
  action: PayloadAction<ServiceRequestIdPayload>,
) {
    const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getLoanCurrentPlan,
    action.payload,
  );
  if (!error) {
    yield put(getLoanCurrentPlanSuccess(response));
  } else {
    yield put(getLoanCurrentPlanFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getLoanRateChangeHistoryFlow(
  action: PayloadAction<ParentLoanIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getLoanRateChangeHistory,
    action.payload,
  );
  if (!error) {
    yield put(rateChangeActions.getLoanRateChangeHistorySuccess(response));
  } else {
    yield put(rateChangeActions.getLoanRateChangeHistoryFailure({error}));
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getCustomerRequirementAndCompetitorDetailsFlow(
  action: PayloadAction<GetCurrentTaskStatusDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getCustomerRequirementAndCompetitorDetails,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.getCustomerRequirementAndCompetitorDetailsSuccess(
        response,
      ),
    );
  } else {
    yield put(
      rateChangeActions.getCustomerRequirementAndCompetitorDetailsFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getCurrentTaskStatusDetail(
  action: PayloadAction<GetCurrentTaskStatusDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getCurrentTaskStatusDetail,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.getCurrentTaskStatusDetailSuccess(response),
    );
  } else {
    yield put(
      rateChangeActions.getCurrentTaskStatusDetailFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getCurrentOfferDetail(
  action: PayloadAction<ServiceRequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getCurrentOfferDetail,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.getCurrentOfferDetailSuccess(response),
    );
  } else {
    yield put(
      rateChangeActions.getCurrentOfferDetailFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* getOfferVersionHistory(
  action: PayloadAction<GetCurrentTaskStatusDetailPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.rateChange.getOfferVersionHistory,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.getOfferVersionHistorySuccess(response),
    );
  } else {
    yield put(
      rateChangeActions.getOfferVersionHistoryFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* updateCustomerRequirementsByCstFlow(
  action: PayloadAction<UpdateCustomerRequirementsByCSTPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.rateChange.updateCustomerRequirementsByCst,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.updateCustomerRequirementsByCstSuccess(),
    );
    yield put(
      snackBarActions.open({
        variant: "success",
        message: "Customer requirements updated successfully",
      }),
    );
    yield put(
      forceRefresh(),
    );
  } else {
    yield put(
      rateChangeActions.updateCustomerRequirementsByCstFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* updateOfferByCmFlow(
  action: PayloadAction<OfferUpdateByCMPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.rateChange.updateOfferByCm,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.updateOfferByCmSuccess(),
    );
    yield put(
      snackBarActions.open({
        variant: "success",
        message: "Offer updated successfully",
      }),
    );
    yield put(
      forceRefresh(),
    );
  } else {
    yield put(
      rateChangeActions.updateOfferByCmFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}

function* shareCmOfferWithCustomerFlow(
  action: PayloadAction<ServiceRequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.rateChange.shareCmOfferWithCustomer,
    action.payload,
  );
  if (!error) {
    yield put(
      rateChangeActions.shareCmOfferWithCustomerSuccess(),
    );
    yield put(
      snackBarActions.open({
        variant: "success",
        message: "Offer shared successfully",
      }),
    );
    yield put(
      forceRefresh(),
    );
  } else {
    yield put(
      rateChangeActions.shareCmOfferWithCustomerFailure({
        error,
      }),
    );
    yield put(
      snackBarActions.open({
        variant: "error",
        message: error?.message || "Something went wrong",
      }),
    );
  }
}


export default function* rateChangeSaga () {
  yield takeLatest(
    rateChangeActionTypes.GetLoanCurrentPlan,
    getLoanCurrentPlanFlow,
  );
  yield takeLatest(
    rateChangeActionTypes.GetLoanRateChangeHistory,
    getLoanRateChangeHistoryFlow,
  );
  yield takeLatest(
    rateChangeActionTypes.GetCustomerRequirementAndCompetitorDetails,
    getCustomerRequirementAndCompetitorDetailsFlow,
  );
  yield takeLatest(
    rateChangeActionTypes.GetCurrentTaskStatusDetail,
    getCurrentTaskStatusDetail,
  );
  yield takeLatest(
    rateChangeActionTypes.GetCurrentOfferDetail,
    getCurrentOfferDetail,
  );
  yield takeLatest(
    rateChangeActionTypes.GetOfferVersionHistory,
    getOfferVersionHistory,
  );
  yield takeLatest(
    rateChangeActionTypes.UpdateCustomerRequirementsByCst,
    updateCustomerRequirementsByCstFlow,
  );
  yield takeLatest(
    rateChangeActionTypes.UpdateOfferByCm,
    updateOfferByCmFlow,
  );
  yield takeLatest(
    rateChangeActionTypes.ShareCmOfferWithCustomer,
    shareCmOfferWithCustomerFlow,
  );
};