import {createAction} from "@reduxjs/toolkit";
import {
  BlockAgentTimePayload,
  DeleteTimeSlotPayload,
  GetAgentsPayload,
  GetLMAvailabilityPayload,
  GetLMsPayload,
  GetUnschedulViewPayload,
  LeadIdPayload,
  LmAvailabilityResponse,
  UpdateTimeSlotPayload,
} from "app/infra/services/api/scheduling/lmAvailability/types";
import {GetOpenCrViewPayload} from "app/infra/services/api/scheduling/openCR/types";
import {
  GetClusterDetailPayload,
  GetUnscheduledAgentReportPayload,
} from "app/infra/services/api/scheduling/unscheduled/types";

export const lmAvailabilityActionTypes = {
  GetLMs: "[LM Availability] Get Lms",
  GetAgents: "[LM Availability] Get Agents",
  GetLMsSuccess: "[LM Availability] Get Lms Success",
  GetLMsFailure: "[LM Availability] Get Lms Failure",
  BlockAgentTime: "[LM Availability] Block Agent Time",
  BlockAgentTimeSuccess: "[LM Availability] Block Agent Time Success",
  BlockAgentTimeFailure: "[LM Availability] Block Agent Time Failure",
  GetLMAvailability: "[LM Availability] Get LM Availability",
  GetLMAvailabilitySuccess: "[LM Availability] Get LM Availability Success",
  GetLMAvailabilityFailure: "[LM Availability] Get LM Availability Failure",
  DeleteTimeSlot: "[LM Availability] Delete Time Slot",
  DeleteTimeSlotSuccess: "[LM Availability] Delete Time Slot Success",
  DeleteTimeSlotFailure: "[LM Availability] Delete Time Slot Failure",
  UpdateTimeSlot: "[LM Availability] Update Time Slot",
  UpdateTimeSlotSuccess: "[LM Availability] Update Time Slot Success",
  UpdateTimeSlotFailure: "[LM Availability] Update Time Slot Failure",
  GetETA: "[LM Availability] Get ETA",
  GetUnschedulView: "[LM Availability] Get Unscheduled View",
  GetOpenCrETA: "[LM Availability] Get Open Cr ETA",
  GetUnscheduledClusterDetail:
    "[LM Availability] Get Unscheduled Cluster Detail",
  GetUnscheduledAgentReport: "[LM Availability] Get Unscheduled Agent Report",
  EnableLeadManualAssignment: "[LM Availability] Enable Lead Manual Assignment",
};
export const lmAvailabilityActions = {
  getUnscheduledAgentReport: createAction<GetUnscheduledAgentReportPayload>(
    lmAvailabilityActionTypes.GetUnscheduledAgentReport,
  ),
  getUnscheduledClusterDetail: createAction<GetClusterDetailPayload>(
    lmAvailabilityActionTypes.GetUnscheduledClusterDetail,
  ),
  getETA: createAction<GetUnschedulViewPayload>(
    lmAvailabilityActionTypes.GetETA,
  ),
  getOpenCrEta: createAction<GetOpenCrViewPayload>(
    lmAvailabilityActionTypes.GetOpenCrETA,
  ),
  getUnschedulView: createAction<GetUnschedulViewPayload>(
    lmAvailabilityActionTypes.GetUnschedulView,
  ),
  getAgents: createAction<GetAgentsPayload>(
    lmAvailabilityActionTypes.GetAgents,
  ),
  getLMs: createAction<GetLMsPayload>(lmAvailabilityActionTypes.GetLMs),
  getLMsSuccess: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.GetLMsSuccess,
  ),
  getLMsFailure: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.GetLMsFailure,
  ),
  blockAgentTime: createAction<BlockAgentTimePayload>(
    lmAvailabilityActionTypes.BlockAgentTime,
  ),
  blockAgentTimeSuccess: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.BlockAgentTimeSuccess,
  ),
  blockAgentTimeFailure: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.BlockAgentTimeFailure,
  ),
  getLMAvailability: createAction<GetLMAvailabilityPayload>(
    lmAvailabilityActionTypes.GetLMAvailability,
  ),
  getLMAvailabilitySuccess: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.GetLMAvailabilitySuccess,
  ),
  getLMAvailabilityFailure: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.GetLMAvailabilityFailure,
  ),
  deleteTimeSlot: createAction<DeleteTimeSlotPayload>(
    lmAvailabilityActionTypes.DeleteTimeSlot,
  ),
  deleteTimeSlotSuccess: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.DeleteTimeSlotSuccess,
  ),
  deleteTimeSlotFailure: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.DeleteTimeSlotFailure,
  ),
  updateTimeSlot: createAction<UpdateTimeSlotPayload>(
    lmAvailabilityActionTypes.UpdateTimeSlot,
  ),
  updateTimeSlotSuccess: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.UpdateTimeSlotSuccess,
  ),
  updateTimeSlotFailure: createAction<LmAvailabilityResponse>(
    lmAvailabilityActionTypes.UpdateTimeSlotFailure,
  ),
  enableLeadManualAssignment: createAction<LeadIdPayload>(
    lmAvailabilityActionTypes.EnableLeadManualAssignment,
  ),
};
