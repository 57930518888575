import {reject} from "lodash";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {algoActions} from "./actions";
import {
  AgentInterface,
  AgentScheduleDetailInterface,
  AlgoRun,
  ApplyFilterPayload,
  CustomerInterface,
  FiltersData,
  RouteInterface,
  Trip,
  TripInterface,
  UnscheduledLoanInterface,
  AlgoRunErrorInterface,
} from "app/infra/services/api/scheduling/algo/types";
import moment from "moment";
import {ALGO_LOADERS_ENUM} from "../../../infra/services/api/scheduling/types";
import ConvertSchedulingDetailsToEvents
  from "../../../pages/home/GoldLoan/Scheduling/utils/ConvertSchedulingDetailsToEvents";

export interface AlgoState {
  isFetching: boolean;
  error: any | null;
  allRuns: AlgoRun[];
  algoRun: {
    id: number;
    status: string | null;
    noOfAgents: number;
    noOfLoans: number;
    noOfScheduledLoans: number;
    totalLoanAmount: number;
    errorList: AlgoRunErrorInterface[],
  };
  agentsScheduleDetails: AgentScheduleDetailInterface[];
  allLMs: any[];
  loanManagersAvailability: any[];
  currentCityId: number | null;
  selectedDate: Date;
  detailView: {
    customerList: CustomerInterface[];
    filters: FiltersData;
    agentList: AgentInterface[];
    showRouteDeleteConfirmationModal: number;
    currentRouteList: {
      isFetching: boolean;
      currentRouteList: RouteInterface[];
    };
    selectedTrip: TripInterface;
    selectedAgent: AgentInterface | null;
    selectedCustomer: CustomerInterface | null;
    selectedRoute: RouteInterface | null;
    selectedLMs: any[];
    lmAvailability: any[];
  };
  confirmView: {
    reportFetched: boolean;
    loanManagers: {
      isFetching: boolean;
      data: any[];
    };
    availableLoanManagers: {
      isFetching: boolean;
      data: any[];
    };
    lmAvailability: {
      isFetching: boolean;
      data: any[];
    };
    unscheduledLoans: UnscheduledLoanInterface[];
  };
  loaders: Record<ALGO_LOADERS_ENUM, boolean>;
}

// >>>>>>> Algo Initial State <<<<<<<<<
const initialState: AlgoState = {
  isFetching: false,
  error: null,
  allRuns: [],
  algoRun: {
    id: 0,
    status: null,
    noOfAgents: 0,
    noOfLoans: 0,
    noOfScheduledLoans: 0,
    totalLoanAmount: 0,
    errorList: [],
  },
  currentCityId: null,
  selectedDate: new Date(),
  agentsScheduleDetails: [],
  allLMs: [],
  loanManagersAvailability: [],
  detailView: {
    agentList: [],
    customerList: [],
    filters: {
      searchTerm: "",
      sortOrder: "DESCENDING",
      isAssigned: null,
    },
    showRouteDeleteConfirmationModal: 0,
    selectedTrip: new (Trip as any)({}),
    selectedAgent: null,
    selectedCustomer: null,
    selectedRoute: null,
    selectedLMs: [],
    lmAvailability: [],
    currentRouteList: {
      isFetching: false,
      currentRouteList: [],
    },
  },
  confirmView: {
    reportFetched: false,
    loanManagers: {
      isFetching: false,
      data: [],
    },
    availableLoanManagers: {
      isFetching: false,
      data: [],
    },
    lmAvailability: {
      isFetching: false,
      data: [],
    },
    unscheduledLoans: [],
  },
  loaders: {
    GET_ALGO_RUN_LIST: true,
    GET_AGENT_LIST: true,
    GET_CUSTOMER_LIST: true,
    GET_TRIP_LIST: false,
    CONFIRM_AGENT_ROUTE: false,
    DELETE_AGENT_ROUTE: false,
    GET_AGENT_SCHEDULE_DETAILS: false,
    CONFIRM_ALGO_RUN: false,
    CREATE_AGENT_TRIP: false,
  },
};

export const algoSlice = createSlice({
  name: "algo",
  initialState,
  reducers: {
    setAlgoRun: (state, action) => {
      state.algoRun = action.payload;
    },
    setCurrentCityId: (state, action) => {
      state.currentCityId = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setReportFetched: (state, action) => {
      state.confirmView.reportFetched = action.payload;
    },
    decreaseAgentTripCount: (state, action) => {
      const agent = state.detailView.agentList.find(
        agent => agent.agentId === action.payload.agentId,
      );

      if (agent) {
        const agentIndex = state.detailView.agentList.indexOf(agent);
        if (agent.tripCount > 0) {
          agent.tripCount -= 1;
        }

        if (agent.tripCount === 0) {
          agent.confirmed = false;
        }

        state.detailView.agentList[agentIndex] = agent;
      }
    },
    clearTripList: state => {
      state.detailView.currentRouteList.currentRouteList = [];
    },
    clearSelectedLoanManagers: state => {
      state.confirmView.loanManagers.data = [];
      state.detailView.currentRouteList.currentRouteList = [];
    },
    setSelectedAgent: (state, action: PayloadAction<AgentInterface | null>) => {
      state.detailView.selectedAgent = action.payload;
    },
    setSelectedCustomer: (
      state,
      action: PayloadAction<CustomerInterface | null>,
    ) => {
      state.detailView.selectedCustomer = action.payload;
    },
    setSelectedTrip: (state, action: PayloadAction<RouteInterface>) => {
      state.detailView.selectedTrip = {
        ...state.detailView.selectedTrip,
        endAddress: action.payload.endAddress || "",
        endLatitude: action.payload.endLatitude || "",
        endLongitude: action.payload.endLongitude || "",
        endTime: action.payload.endTime || 0,
        jobType: action.payload.jobType || "",
        loanAmount: action.payload.loanAmount || 0,
        referenceId: action.payload.referenceId || 0,
        referenceType: action.payload.referenceType || "",
        startAddress: action.payload.startAddress || "",
        startLatitude: action.payload.startLatitude || "",
        startLongitude: action.payload.startLongitude || "",
        startTime: action.payload.startTime || 0,
        customerName: action.payload.customerName || "",
        vaultName: action.payload.vaultName || "",
        startNodeId: action.payload.startNodeId || 0,
        endNodeId: action.payload.endNodeId || 0,
        tripId: action.payload.tripId || 0,
        originDate: action.payload.originDate || 0,
        agentDetails: [],
      };
    },
    toggleRouteDeleteConfirmationModal: (state, action) => {
      state.detailView.showRouteDeleteConfirmationModal = action.payload;
    },
    updateFilters: (state, action: PayloadAction<ApplyFilterPayload>) => {
      state.detailView.filters = {
        searchTerm: action.payload.searchTerm || "",
        sortOrder:
          action.payload.sortOrder || state.detailView.filters.sortOrder,
        isAssigned: action.payload.isAssigned,
      };
    },
    setAlgoRunErrorList: (
      state,
      action: PayloadAction<AlgoRunErrorInterface[]>,
    ) => {
      state.algoRun.errorList = action.payload;
    },
  },
  extraReducers: {
    [algoActions.getRuns.type]: state => {
      state.isFetching = true;
      state.loaders.GET_ALGO_RUN_LIST = true;
    },
    [algoActions.getRunsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_ALGO_RUN_LIST = false;
      state.allRuns = action.payload.data;
    },
    [algoActions.getRunsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_ALGO_RUN_LIST = false;
      state.selectedDate = new Date();
      state.error = action.payload.error;
    },
    [algoActions.getAgentList.type]: state => {
      state.isFetching = true;
      state.loaders.GET_AGENT_LIST = true;
    },
    [algoActions.getAgentListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_AGENT_LIST = false;
      state.detailView.agentList = action.payload.agentList;
    },
    [algoActions.getAgentListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_AGENT_LIST = false;
      state.error = action.payload.error;
    },
    [algoActions.getCustomerList.type]: state => {
      state.isFetching = true;
      state.loaders.GET_CUSTOMER_LIST = true;
    },
    [algoActions.getCustomerListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_CUSTOMER_LIST = false;
      state.detailView.customerList = action.payload.userList;
    },
    [algoActions.getCustomerListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_CUSTOMER_LIST = false;
      state.error = action.payload.error;
    },
    [algoActions.getAgentRouteList.type]: state => {
      state.isFetching = true;
      state.loaders.GET_TRIP_LIST = true;
      state.detailView.currentRouteList.isFetching = true;
      state.detailView.currentRouteList.currentRouteList = [];
    },
    [algoActions.getAgentRouteListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_TRIP_LIST = false;
      state.detailView.currentRouteList.isFetching = false;
      state.detailView.currentRouteList.currentRouteList =
        action.payload.response;
    },
    [algoActions.getAgentRouteListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_TRIP_LIST = false;
      state.detailView.currentRouteList.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.saveAgentRoute.type]: state => {
      state.isFetching = true;
      state.loaders.CONFIRM_AGENT_ROUTE = true;
    },
    [algoActions.saveAgentRouteSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CONFIRM_AGENT_ROUTE = false;
      const agentIndex = state.detailView.agentList.findIndex(
        a => a.routeId === action.payload.response.routeId,
      );
      if (agentIndex !== -1) {
        state.detailView.agentList[agentIndex] = {
          ...state.detailView.agentList[agentIndex],
          confirmed: true,
        };
      }

      // find next unconfirmed agent and make him selected
      const nextAgent = state.detailView.agentList.find(
        (a, i) =>
          !a.confirmed && a.agentId && i !== agentIndex && a.tripCount > 0,
      );
      if (nextAgent) {
        state.detailView.selectedAgent = nextAgent;
        const agentEl = document.getElementById(
          `user-item-${nextAgent.agentId}`,
        );
        if (agentEl) {
          agentEl.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    },
    [algoActions.saveAgentRouteFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CONFIRM_AGENT_ROUTE = false;
      state.error = action.payload.error;
    },
    [algoActions.deleteAgentTrip.type]: state => {
      state.isFetching = true;
      state.loaders.DELETE_AGENT_ROUTE = true;
    },
    [algoActions.deleteAgentTripSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.DELETE_AGENT_ROUTE = false;

      // Update Current Route List
      state.detailView.currentRouteList.currentRouteList = reject(
        state.detailView.currentRouteList.currentRouteList,
        r => r.tripId === action.payload.response.tripId,
      );

      let agentIds = [action.payload.response.agentId];
      const {deleteType} = action.payload.response;
      if (deleteType === "PRIMARY") {
        agentIds = [state.detailView.selectedAgent?.agentId as number];

        // if trip we are deleting is primary job for that agent then we need to remove trip from all secondary agents assigned to that trip as well
        const isPrimaryJobAgent = state.detailView.selectedTrip
          .agentDetails.findIndex(
          a =>
            a.agentId === (state.detailView.selectedAgent?.agentId as number) &&
            a.agentType === "PRIMARY",
        );
        if (isPrimaryJobAgent !== -1) {
          const secondaryAgentIds = state.detailView.selectedTrip.agentDetails
            .filter(i => i.agentType === "SECONDARY")
            .map(i => i.agentId);
          agentIds.push(...secondaryAgentIds);
        }
      }

      const selectedAgentIndex = state.detailView.agentList.findIndex(
        a => a.agentId === agentIds[0],
      );
      // Update Agent List
      for (const id of agentIds) {
        const agent = state.detailView.agentList.find(
          agent => agent.agentId === id,
        );
        if (agent) {
          const agentIndex = state.detailView.agentList.indexOf(agent);
          if (agent.tripCount > 0) {
            agent.tripCount -= 1;
          }
          if (agent.tripCount === 0) {
            agent.confirmed = false;
          }
          state.detailView.agentList[agentIndex] = agent;
        }
     }
      // find next unconfirmed agent and make him selected
      const nextAgent = state.detailView.agentList.find(
        (a, i) =>
          !a.confirmed &&
          a.agentId &&
          i !== selectedAgentIndex &&
          a.tripCount > 0,
      );
      if (nextAgent) {
        state.detailView.selectedAgent = nextAgent;
      }
      // Hide Confirmation Modal
      state.detailView.showRouteDeleteConfirmationModal = 0;
    },
    [algoActions.deleteAgentTripFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.DELETE_AGENT_ROUTE = false;
      state.detailView.showRouteDeleteConfirmationModal = 0;
      state.error = action.payload.error;
    },
    [algoActions.startAlgo.type]: state => {
      state.isFetching = true;
      state.algoRun = initialState.algoRun;
      state.error = null;
    },
    [algoActions.startAlgoSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.algoRun.id = action.payload.response;
    },
    [algoActions.startAlgoFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.getAlgoRunStatus.type]: state => {
      state.isFetching = true;
    },
    [algoActions.getAlgoRunStatusSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.algoRun.id = action.payload.response.id;
      state.algoRun.status = action.payload.response.status;
      state.algoRun.errorList = action.payload.response.errorList;
      state.currentCityId = action.payload.response.cityId;
      state.selectedDate = moment(
        action.payload.response.runDate,
        "DD-MM-YYYY",
      ).toDate();
    },
    [algoActions.getAlgoRunStatusFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.getAgentsScheduleDetails.type]: state => {
      state.isFetching = true;
      state.loaders.GET_AGENT_SCHEDULE_DETAILS = true;
    },
    [algoActions.getAgentsScheduleDetailsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.confirmView.lmAvailability.data = ConvertSchedulingDetailsToEvents(
        action.payload.schedulingDetails,
      );
      state.loaders.GET_AGENT_SCHEDULE_DETAILS = false;
    },
    [algoActions.getAgentsScheduleDetailsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
      state.loaders.GET_AGENT_SCHEDULE_DETAILS = false;
    },
    [algoActions.getAlgoRunReport.type]: state => {
      state.isFetching = true;
    },
    [algoActions.getAlgoRunReportSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.confirmView.reportFetched = true;
      state.algoRun = {
        ...state.algoRun,
        noOfAgents: action.payload.algoReport.noOfAgents || 0,
        noOfLoans: action.payload.algoReport.noOfLoans || 0,
        noOfScheduledLoans: action.payload.algoReport.noOfScheduledLoans || 0,
        totalLoanAmount: action.payload.algoReport.totalLoanAmount || 0,
      };
    },
    [algoActions.getAlgoRunReportFailure.type]: (state, action) => {
      state.isFetching = false;
      state.confirmView.reportFetched = false;
      state.error = action.payload.error;
    },
    [algoActions.confirmAlgoRun.type]: state => {
      state.isFetching = true;
      state.loaders.CONFIRM_ALGO_RUN = true;
    },
    [algoActions.confirmAlgoRunSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CONFIRM_ALGO_RUN = true;
      state.confirmView.unscheduledLoans = action.payload.response.errorDtoList;
      if (action.payload.response.errorDtoList.length === 0) {
        state.algoRun.status = "CONFIRMED";
      }
    },
    [algoActions.confirmAlgoRunFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CONFIRM_ALGO_RUN = true;
      state.error = action.payload.error;
    },
    [algoActions.getCustomerDetails.type]: state => {
      state.isFetching = true;
      state.loaders.GET_TRIP_LIST = true;
      state.detailView.currentRouteList.isFetching = true;
      state.detailView.currentRouteList.currentRouteList = [];
    },
    [algoActions.getCustomerDetailsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_TRIP_LIST = false;
      state.detailView.currentRouteList.isFetching = false;
      state.detailView.currentRouteList.currentRouteList = [
        action.payload.customerDetails,
      ];
    },
    [algoActions.getCustomerDetailsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_TRIP_LIST = false;
      state.detailView.currentRouteList.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.getTripDetails.type]: state => {
      state.isFetching = true;
    },
    [algoActions.getTripDetailsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.detailView.selectedTrip = {
        ...state.detailView.selectedTrip,
        agentDetails: action.payload.tripDetails,
      };
    },
    [algoActions.getTripDetailsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.updateAlgoRunDetails.type]: (state, action) => {
      state.algoRun = {
        ...state.algoRun,
        ...action.payload,
      };
    },
    [algoActions.createAgentTrip.type]: state => {
      state.isFetching = true;
      state.loaders.CREATE_AGENT_TRIP = true;
    },
    [algoActions.createAgentTripSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CREATE_AGENT_TRIP = false;
      const {agentId} = action.payload.response;
      if (action.payload.response.selectedView === 0) {
        const agentIndex = state.detailView.agentList.findIndex(
          a => a.agentId === agentId,
        );
        if (agentIndex !== -1) {
          const agent = {...state.detailView.agentList[agentIndex]};
          state.detailView.agentList[agentIndex] = {
            ...agent,
            routeId: agent.routeId || action.payload.response.schedulingRouteId,
            tripCount: agent.tripCount + 1,
          };
        } else {
          state.detailView.agentList.push({
            agentId: agentId,
            agentName: action.payload.response.agentName,
            confirmed: false,
            routeId: action.payload.response.schedulingRouteId,
            tripCount: 1,
          });
        }
      }
      if (
        action.payload.response.selectedView === 1 &&
        action.payload.response.agentType === "PRIMARY"
      ) {
        const customerIndex = state.detailView.customerList.findIndex(
          c =>
            c.schedulingStartNodeId ===
            state.detailView.selectedCustomer?.schedulingStartNodeId,
        );
        if (customerIndex !== -1) {
          state.detailView.customerList[customerIndex].assigned = true;

          // this is customer view so only one route
          // update time in route list
          state.detailView.currentRouteList.currentRouteList[0].startTime =
            action.payload.response.startTime;
          state.detailView.currentRouteList.currentRouteList[0].endTime =
            action.payload.response.endTime;

          // update time in selected trip
          state.detailView.selectedTrip.startTime =
            action.payload.response.startTime;
          state.detailView.selectedTrip.endTime =
            action.payload.response.endTime;
        }
      }
      state.detailView.selectedTrip.agentDetails.push({
        agentId: agentId,
        agentType: action.payload.response.agentType,
        endNodeDto: action.payload.response.endNodeDto,
        endNodeId: action.payload.response.endNodeId,
        endTime: action.payload.response.endTime,
        id: action.payload.response.id,
        referenceId: action.payload.response.referenceId,
        referenceType: action.payload.response.referenceType,
        schedulingRouteId: action.payload.response.schedulingRouteId,
        startNodeDto: action.payload.response.startNodeDto,
        startNodeId: action.payload.response.startNodeId,
        startTime: action.payload.response.startTime,
      });
    },
    [algoActions.createAgentTripFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.CREATE_AGENT_TRIP = false;
      state.error = action.payload.error;
    },
    [algoActions.updateSelectedLMs.type]: (state, action) => {
      state.confirmView.loanManagers.data = action.payload;
    },
    [algoActions.updateLMAvailability.type]: (state, action) => {
      state.confirmView.lmAvailability.data = action.payload;
    },
    [algoActions.getLMs.type]: state => {
      state.confirmView.loanManagers.isFetching = true;
    },
    [algoActions.getLMsSuccess.type]: (state, action) => {
      state.confirmView.loanManagers.isFetching = false;
      state.confirmView.loanManagers.data = action.payload.loanManagers.data;
      state.error = null;
    },
    [algoActions.getLMsFailure.type]: (state, action) => {
      state.confirmView.loanManagers.isFetching = false;
      state.error = action.payload.error;
    },
    [algoActions.getLMAvailabilitySuccess.type]: (state, action) => {
      state.confirmView.lmAvailability.isFetching = false;
      state.confirmView.lmAvailability.data = ConvertSchedulingDetailsToEvents(
        action.payload.schedulingDetails,
      );
    },
    [algoActions.getAvailableAgentList.type]: state => {
      state.isFetching = true;
      state.confirmView.availableLoanManagers.isFetching = true;
    },
    [algoActions.getAvailableAgentListSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.confirmView.availableLoanManagers.isFetching = false;
      state.confirmView.availableLoanManagers.data = action.payload.agentList;
    },
    [algoActions.getAvailableAgentListFailure.type]: (state, action) => {
      state.isFetching = false;
      state.confirmView.availableLoanManagers.isFetching = false;
      state.error = action.payload.error;
    },
  },
});
const {
  setAlgoRun,
  setCurrentCityId,
  setSelectedDate,
  setReportFetched,
  decreaseAgentTripCount,
  clearTripList,
  clearSelectedLoanManagers,
  setSelectedAgent,
  setSelectedCustomer,
  setSelectedTrip,
  toggleRouteDeleteConfirmationModal,
  updateFilters,
  setAlgoRunErrorList,
} = algoSlice.actions;

export default algoSlice.reducer;

export {
  initialState,
  setAlgoRun,
  setCurrentCityId,
  setSelectedDate,
  setReportFetched,
  decreaseAgentTripCount,
  clearTripList,
  clearSelectedLoanManagers,
  setSelectedAgent,
  setSelectedCustomer,
  setSelectedTrip,
  toggleRouteDeleteConfirmationModal,
  updateFilters,
  setAlgoRunErrorList,
};
