import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
} from "@mui/material";
import {IGTextField} from "app/components";
import {useAppSelector} from "app/store/hooks";
import {OrnamentsSelectors} from "app/store/ornaments";
import {activeLoansSelectors} from "app/store/activeLoans";

interface Props {
  weightInfo: {
    makerGrossWeight?: number;
    makerNetWeight?: number;
    makerPurity: string;
    checkerGrossWeight?: number;
    checkerNetWeight?: number;
    checkerPurity: string;
    makerStoneWeight?: number;
    checkerStoneWeight?: number;
    makerImpurityWeight?: number;
    checkerImpurityWeight?: number;
    makerOtherImpurityWeight?: number;
    checkerOtherImpurityWeight?: number;
  };
  handleChange: (e: any) => void;
  handleBlur: (e: any) => void;
  disableForm: boolean;
  selectedOrnamentId: number;
  errors: {
    newStoneWeightInGrams?: string;
    newImpurityWeightInGrams?: string;
    newOtherImpurityWeight?: string;
  };
  touched: {
    newStoneWeightInGrams?: boolean;
    newImpurityWeightInGrams?: boolean;
    newOtherImpurityWeight?: boolean;
  };
  isLender: boolean;
}
const ImpurityInfoTable = ({
  weightInfo,
  handleChange,
  disableForm,
  selectedOrnamentId,
  handleBlur,
  errors,
  touched,
  isLender,
}: Props) => {
  const ornamentsNamesList = useAppSelector(
    OrnamentsSelectors.getOrnamentsNamesList,
  );
  const isOrnamentCheckerRequired = useAppSelector(
    activeLoansSelectors.getOrnamentCheckerFlag,
  );
  const {
    makerStoneWeight,
    checkerStoneWeight,
    makerImpurityWeight,
    checkerImpurityWeight,
    makerOtherImpurityWeight,
    checkerOtherImpurityWeight,
  } = weightInfo;

  const rows = [];

  if (isOrnamentCheckerRequired) {
    rows.push({
      name: "Checker",
      stoneWeight: checkerStoneWeight,
      impurityWeight: checkerImpurityWeight,
      otherImpurity: checkerOtherImpurityWeight,
    });
  }

  if (!isLender) {
    rows.unshift({
      name: "Maker",
      stoneWeight: makerStoneWeight,
      impurityWeight: makerImpurityWeight,
      otherImpurity: makerOtherImpurityWeight,
    });
  }

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        mt: 1,
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
      }}
    >
      <Table
        aria-label="Weight Info"
        sx={{
          "& th, td": {
            padding: "8px",
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              bgcolor: "#000",
              "& th": {
                color: "#fff",
              },
            }}
          >
            {!isLender && <TableCell></TableCell>}
            <TableCell>Stone Weight</TableCell>
            <TableCell>Impurity Weight</TableCell>
            <TableCell>Other Impurities</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{"&:last-child td, &:last-child th": {border: 0}}}
            >
              {!isLender && (
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
              )}
              <TableCell>
                {row.name === "Checker" ? (
                  <>
                    {isOrnamentCheckerRequired ? (
                      <IGTextField
                        name="newStoneWeightInGrams"
                        fullWidth={false}
                        value={row.stoneWeight}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        disabled={
                          disableForm ||
                          ornamentsNamesList?.find(
                            (item) => item.id === selectedOrnamentId,
                          )?.isCheckedByCm
                        }
                        variant="standard"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">g</InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "50px",
                        }}
                        error={Boolean(
                          errors.newStoneWeightInGrams &&
                            touched.newStoneWeightInGrams,
                        )}
                        helperText={
                          errors.newStoneWeightInGrams &&
                          touched.newStoneWeightInGrams
                            ? errors.newStoneWeightInGrams
                            : ""
                        }
                        touched={touched.newStoneWeightInGrams}
                      />
                    ) : null}
                  </>
                ) : (
                  `${row.stoneWeight}g`
                )}
              </TableCell>
              <TableCell>
                {row.name === "Checker" ? (
                  <>
                    {isOrnamentCheckerRequired ? (
                      <IGTextField
                        name="newImpurityWeightInGrams"
                        fullWidth={false}
                        type="number"
                        value={row.impurityWeight}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          disableForm ||
                          ornamentsNamesList?.find(
                            (item) => item.id === selectedOrnamentId,
                          )?.isCheckedByCm
                        }
                        variant="standard"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">g</InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "50px",
                        }}
                        error={Boolean(
                          errors.newImpurityWeightInGrams &&
                            touched.newImpurityWeightInGrams,
                        )}
                        helperText={
                          errors.newImpurityWeightInGrams &&
                          touched.newImpurityWeightInGrams
                            ? errors.newImpurityWeightInGrams
                            : ""
                        }
                        touched={touched.newImpurityWeightInGrams}
                      />
                    ) : null}
                  </>
                ) : (
                  `${row.impurityWeight}g`
                )}
              </TableCell>

              <TableCell>
                {row.name === "Checker" ? (
                  <>
                    {isOrnamentCheckerRequired ? (
                      <IGTextField
                        name="newOtherImpurityWeight"
                        fullWidth={false}
                        value={row.otherImpurity}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          disableForm ||
                          ornamentsNamesList?.find(
                            (item) => item.id === selectedOrnamentId,
                          )?.isCheckedByCm
                        }
                        variant="standard"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">g</InputAdornment>
                          ),
                        }}
                        sx={{
                          minWidth: "50px",
                        }}
                        error={Boolean(
                          errors.newOtherImpurityWeight &&
                            touched.newOtherImpurityWeight,
                        )}
                        helperText={
                          errors.newOtherImpurityWeight &&
                          touched.newOtherImpurityWeight
                            ? errors.newOtherImpurityWeight
                            : ""
                        }
                        touched={touched.newOtherImpurityWeight}
                      />
                    ) : null}
                  </>
                ) : (
                  `${row.otherImpurity}g`
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImpurityInfoTable;
