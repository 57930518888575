import {createAction} from "@reduxjs/toolkit";
import {
  GetServiceRequestConfigListPayload,
  RequestIdPayload,
  ServiceDeskResponse,
  UpdateRequestPaylaod,
  ModifyRequestPayload,
  ReassignAgentPayload,
  ConfirmCustomerAvailabilityPayload,
  UpdateSoaVerificationPayload,
  LoanIdPayload,
  EventMasterListPayload,
  CreateEventPayload,
  LanIdPayload,
  SendSurveyLinkPayload,
} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {setPaginationFor} from "app/store/factoryReducers";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {BypassScanningPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {UserIdPayload} from "app/infra/services/api/internalUser/types";

const servicesActionTypes = {
  GetServiceRequests: "[Services] Get Service Requests",
  GetServiceRequestData: "[Services] Get Service Request Data",
  UpdateServiceRequestData: "[Services] Update Service Request Data",
  GetServiceRequestConfigList: "[Services] Get Service Request Config List",
  GetAgentList: "[Services] Get Agent List",
  GetLoansByMobileNumber: "[Services] Get Loans By Mobile Number",
  GetLoansByMobileNumberSuccess: "[Services] Get Loans By Mobile Number Success",
  GetLoansByMobileNumberFailure: "[Services] Get Loans By Mobile Number Failure",
  CreateNewRequest: "[Services] Create New Request",
  CreateNewRequestSuccess: "[Services] Create New Request Success",
  CreateNewRequestFailure: "[Services] Create New Request Failure",
  ModifyRequest: "[Services] Modify Request",
  ModifyRequestSuccess: "[Services] Modify Request Success",
  ModifyRequestFailure: "[Services] Modify Request Failure",
  GetLoanDetail: "[Services] Get Loan Detail",
  GetLoanDetailSuccess: "[Services] Get Loan Detail Success",
  GetLoanDetailFailure: "[Services] Get Loan Detail Failure",
  ReassignAgent: "[Services] Re Assign Agent",
  ReassignAgentSuccess: "[Services] Re Assign Agent Success",
  ReassignAgentFailure: "[Services] Re Assign Agent Failure",
  GetCustomerAvailability: "[Services] Get Customer Availability",
  GetCustomerAvailabilitySuccess: "[Services] Get Customer Availability Success",
  GetCustomerAvailabilityFailure: "[Services] Get Customer Availability Failure",
  ConfirmCustomerAvailability: "[Services] Confirm Customer Availability",
  ConfirmCustomerAvailabilitySuccess: "[Services] Confirm Customer Availability Success",
  ConfirmCustomerAvailabilityFailure: "[Services] Confirm Customer Availability Failure",
  GetBypassPacketScanningList: "[Services] Get Bypass Packet Scanning List",
  GetBypassPacketScanningListSuccess: "[Services] Get Bypass Packet Scanning List Success",
  GetBypassPacketScanningListFailure: "[Services] Get Bypass Packet Scanning List Failure",
  ApproveBypassScanning: "[Services] Approve Bypass Scanning",
  ApproveBypassScanningSuccess: "[Services] Approve Bypass Scanning Success",
  ApproveBypassScanningFailure: "[Services] Approve Bypass Scanning Failure",
  GetPaymentDetails: "[Services] Get Payment Details",
  GetPaymentDetailsSuccess: "[Services] Get Payment Details Success",
  GetPaymentDetailsFailure: "[Services] Get Payment Details Failure",
  GetAllLoans: "[Services] Get All Loans",
  GetAllLoansSuccess: "[Services] Get All Loans Success",
  GetAllLoansFailure: "[Services] Get All Loans Failure",
  GetForeClosureCharge: "[Services] Get Fore Closure Charge",
  GetForeClosureChargeSuccess: "[Services] Get Fore Closure Charge Success",
  GetForeClosureChargeFailure: "[Services] Get Fore Closure Charge Failure",
  WaiveOffForeclosureCharge: "[Services] Waive Off Fore Closure Charge",
  WaiveOffForeclosureChargeSuccess: "[Services] Waive Off Fore Closure Charge Success",
  WaiveOffForeclosureChargeFailure: "[Services] Waive Off Fore Closure Charge Failure",
  GetCancellationReasonList: "[Services] Get Cancellation Reason List",
  GetCancellationReasonListSuccess: "[Services] Get Cancellation Reason List Success",
  GetCancellationReasonListFailure: "[Services] Get Cancellation Reason List Failure",
  GetSoaVerification: "[Services] Get Soa Verification",
  GetSoaVerificationSuccess: "[Services] Get Soa Verification Success",
  GetSoaVerificationFailure: "[Services] Get Soa Verification Failure",
  UpdateSoaVerification: "[Services] Update Soa Verification",
  UpdateSoaVerificationSuccess: "[Services] Update Soa Verification Success",
  UpdateSoaVerificationFailure: "[Services] Update Soa Verification Failure",
  ValidateRateChangeRequest: "[Services] Validate Rate Change Request",
  ValidateRateChangeRequestSuccess: "[Services] Validate Rate Change Request Success",
  ValidateRateChangeRequestFailure: "[Services] Validate Rate Change Request Failure",
  GetTatDetails: "[Services] Get Tat Details",
  GetEventHistory: "[Services] Get Event History",
  GetEventHistorySuccess: "[Services] Get Event History Success",
  GetEventHistoryFailure: "[Services] Get Event History Failure",
  GetEventMasterList: "[Services] Get Event Master List",
  GetEventMasterListSuccess: "[Services] Get Event Master List Success",
  GetEventMasterListFailure: "[Services] Get Event Master List Failure",
  CreateEvent: "[Services] Create Event",
  CreateEventSuccess: "[Services] Create Event Success",
  CreateEventFailure: "[Services] Create Event Failure",
  GetLatestEvent: "[Services] Get Latest Event",
  GetLatestEventSuccess: "[Services] Get Latest Event Success",
  GetLatestEventFailure: "[Services] Get Latest Event Failure",
  GetLenderClosureAmount: "[Services] Get Lender Closure Amount",
  GetLenderClosureAmountSuccess: "[Services] Get Lender Closure Amount Success",
  GetLenderClosureAmountFailure: "[Services] Get Lender Closure Amount Failure",
  SendSurveyLink: "[Services] Send Survey Link",
};

const servicesActions = {
  getServiceRequests: createAction<any>(servicesActionTypes.GetServiceRequests),
  getServiceRequestData: createAction<RequestIdPayload>(
    servicesActionTypes.GetServiceRequestData,
  ),
  updateServiceRequestData: createAction<UpdateRequestPaylaod>(
    servicesActionTypes.UpdateServiceRequestData,
  ),
  getServiceRequestConfigList: createAction<GetServiceRequestConfigListPayload>(
    servicesActionTypes.GetServiceRequestConfigList,
  ),
  getAgentList: createAction(servicesActionTypes.GetAgentList),
  getLoansByMobileNumber: createAction<number>(
    servicesActionTypes.GetLoansByMobileNumber,
  ),
  getLoansByMobileNumberSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLoansByMobileNumberSuccess,
  ),
  getLoansByMobileNumberFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLoansByMobileNumberFailure,
  ),
  createNewRequest: createAction<{ formData: any }>(
    servicesActionTypes.CreateNewRequest,
  ),
  createNewRequestSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.CreateNewRequestSuccess,
  ),
  createNewRequestFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.CreateNewRequestFailure,
  ),
  modifyRequest: createAction<ModifyRequestPayload>(
    servicesActionTypes.ModifyRequest,
  ),
  modifyRequestSuccess: createAction(servicesActionTypes.ModifyRequestSuccess),
  modifyRequestFailure: createAction<{ error: GoldApiError }>(
    servicesActionTypes.ModifyRequestFailure,
  ),
  getLoanDetail: createAction<RequestIdPayload>(
    servicesActionTypes.GetLoanDetail,
  ),
  getLoanDetailSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLoanDetailSuccess,
  ),
  getLoanDetailFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLoanDetailFailure,
  ),
  reassignAgent: createAction<ReassignAgentPayload>(
    servicesActionTypes.ReassignAgent,
  ),
  reassignAgentSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.ReassignAgentSuccess,
  ),
  reassignAgentFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.ReassignAgentFailure,
  ),
  getCustomerAvailability: createAction<RequestIdPayload>(
    servicesActionTypes.GetCustomerAvailability,
  ),
  getCustomerAvailabilitySuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetCustomerAvailabilitySuccess,
  ),
  getCustomerAvailabilityFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetCustomerAvailabilityFailure,
  ),
  confirmCustomerAvailability: createAction<ConfirmCustomerAvailabilityPayload>(
    servicesActionTypes.ConfirmCustomerAvailability,
  ),
  confirmCustomerAvailabilitySuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.ConfirmCustomerAvailabilitySuccess,
  ),
  confirmCustomerAvailabilityFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.ConfirmCustomerAvailabilityFailure,
  ),
  getBypassPacketScanningList: createAction<RequestIdPayload>(
    servicesActionTypes.GetBypassPacketScanningList,
  ),
  getBypassPacketScanningListSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetBypassPacketScanningListSuccess,
  ),
  getBypassPacketScanningListFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetBypassPacketScanningListFailure,
  ),
  approveBypassScanning: createAction<BypassScanningPayload>(
    servicesActionTypes.ApproveBypassScanning,
  ),
  approveBypassScanningSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.ApproveBypassScanningSuccess,
  ),
  approveBypassScanningFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.ApproveBypassScanningFailure,
  ),
  getPaymentDetails: createAction<RequestIdPayload>(
    servicesActionTypes.GetPaymentDetails,
  ),
  getPaymentDetailsSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetPaymentDetailsSuccess,
  ),
  getPaymentDetailsFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetPaymentDetailsFailure,
  ),
  setServiceRequestsPagination: setPaginationFor("services"),
  getAllLoans: createAction<UserIdPayload>(servicesActionTypes.GetAllLoans),
  getAllLoansSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetAllLoansSuccess,
  ),
  getAllLoansFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetAllLoansFailure,
  ),
  getForeClosureCharge: createAction<RequestIdPayload>(
    servicesActionTypes.GetForeClosureCharge,
  ),
  getForeClosureChargeSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetForeClosureChargeSuccess,
  ),
  getForeClosureChargeFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetForeClosureChargeFailure,
  ),
  waiveOffForeclosureCharge: createAction<{
    formData: FormData;
    requestId: number;
    isRenewRebook: boolean;
  }>(servicesActionTypes.WaiveOffForeclosureCharge),
  waiveOffForeclosureChargeSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.WaiveOffForeclosureChargeSuccess,
  ),
  waiveOffForeclosureChargeFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.WaiveOffForeclosureChargeFailure,
  ),
  getCancellationReasonList: createAction<RequestIdPayload>(
    servicesActionTypes.GetCancellationReasonList,
  ),
  getCancellationReasonListSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetCancellationReasonListSuccess,
  ),
  getCancellationReasonListFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetCancellationReasonListFailure,
  ),
  getSoaVerification: createAction<RequestIdPayload>(
    servicesActionTypes.GetSoaVerification,
  ),
  getSoaVerificationSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetSoaVerificationSuccess,
  ),
  getSoaVerificationFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetSoaVerificationFailure,
  ),
  updateSoaVerification: createAction<UpdateSoaVerificationPayload>(
    servicesActionTypes.UpdateSoaVerification,
  ),
  updateSoaVerificationSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.UpdateSoaVerificationSuccess,
  ),
  updateSoaVerificationFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.UpdateSoaVerificationFailure,
  ),
  validateRateChangeRequest: createAction<LoanIdPayload>(
    servicesActionTypes.ValidateRateChangeRequest,
  ),
  validateRateChangeRequestSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.ValidateRateChangeRequestSuccess,
  ),
  validateRateChangeRequestFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.ValidateRateChangeRequestFailure,
  ),
  getTatDetails: createAction<RequestIdPayload>(
    servicesActionTypes.GetTatDetails,
  ),
  getEventHistory: createAction<RequestIdPayload>(
    servicesActionTypes.GetEventHistory,
  ),
  getEventHistorySuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetEventHistorySuccess,
  ),
  getEventHistoryFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetEventHistoryFailure,
  ),
  getEventMasterList: createAction<EventMasterListPayload>(
    servicesActionTypes.GetEventMasterList,
  ),
  getEventMasterListSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetEventMasterListSuccess,
  ),
  getEventMasterListFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetEventMasterListFailure,
  ),
  createEvent: createAction<CreateEventPayload>(
    servicesActionTypes.CreateEvent,
  ),
  createEventSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.CreateEventSuccess,
  ),
  createEventFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.CreateEventFailure,
  ),
  getLatestEvent: createAction<RequestIdPayload>(
    servicesActionTypes.GetLatestEvent,
  ),
  getLatestEventSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLatestEventSuccess,
  ),
  getLatestEventFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLatestEventFailure,
  ),
  getLenderClosureAmount: createAction<LanIdPayload>(
    servicesActionTypes.GetLenderClosureAmount,
  ),
  getLenderClosureAmountSuccess: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLenderClosureAmountSuccess,
  ),
  getLenderClosureAmountFailure: createAction<ServiceDeskResponse>(
    servicesActionTypes.GetLenderClosureAmountFailure,
  ),
  sendSurveyLink: createAction<SendSurveyLinkPayload>(
    servicesActionTypes.SendSurveyLink,
  ),
};

export {servicesActionTypes};
export default servicesActions;
