import {RootState} from "../store";

const goldReleaseSelectors = {
  getClosureList: (state: RootState) =>
    Object.values(state.goldRelease.closureRequests),
  getHandoverImageByCrId: (state: RootState, crId: number) =>
    state.goldRelease.handoverImages[crId],
  getSelectedCr: (state: RootState) => state.goldRelease.selectedCr,
  getOpenModals: (state: RootState) => state.goldRelease.openModals,
  getFilters: (state: RootState) => state.goldRelease.filters,
  getPagination: (state: RootState) => state.goldRelease.pagination,
  getLoading: (state: RootState) => state.goldRelease.loading,
  getErrors: (state: RootState) => state.goldRelease.errors,
  getPageNoAndKeyMapping: (state: RootState) =>
    state.goldRelease.pageNoAndKeyMapping,
};

export default goldReleaseSelectors;
