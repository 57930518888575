import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {CheckJumperRemovalStatusParams, CloseFreshDeskTicketPayload, GetCloseTicketQuestionsPayload, InitiateJumperRemovalParams, PostAgentReplyPayload, UpdateMobileNumberPayload, ValidateJumperParams} from "app/infra/services/api/serviceDesk/complaints/types";
import {snackBarActions} from "app/store/snackbar";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import complaintActions from "./actions";
import {closeModal} from "./reducer";
import {RequestIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";
import {reloadList, toggleModals} from "../services/reducer";

const {
  validateJumperRemoval,
  validateJumperRemovalSuccess,
  validateJumperRemovalFailure,
  initiateJumperRemoval,
  initiateJumperRemovalSuccess,
  initiateJumperRemovalFailure,
  retryJumperRemoval,
  retryJumperRemovalSuccess,
  retryJumperRemovalFailure,
  getJumperRemovalStatus,
  getJumperRemovalStatusSuccess,
  getJumperRemovalStatusFailure,
  getRequestStatusCount,
  getRequestStatusCountSuccess,
  getRequestStatusCountFailure,
  getCustomerAgentConversation,
  getCustomerAgentConversationSuccess,
  getCustomerAgentConversationFailure,
  postAgentReplyToConversation,
  postAgentReplyToConversationSuccess,
  postAgentReplyToConversationFailure,
  updateServiceRequestMobileNumber,
  updateServiceRequestMobileNumberSuccess,
  updateServiceRequestMobileNumberFailure,
  getCloseTicketQuestions,
  getCloseTicketQuestionsFailure,
  getCloseTicketQuestionsSuccess,
  closeFreshDeskTicketFailure,
  closeFreshDeskTicketSuccess,
  closeFreshDeskTicket,
} = complaintActions;

function* validateJumperRemovalFlow(
  action: PayloadAction<ValidateJumperParams>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.validateJumperRemoval,
    action.payload,
  );

  if (error) {
    yield put(validateJumperRemovalFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(validateJumperRemovalSuccess({loanJumperDetails: response}));
  }
}

function* initiateJumperRemovalFlow(
  action: PayloadAction<InitiateJumperRemovalParams>,
) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.complaintApi.initiateJumperRemoval,
    action.payload,
  );

  if (error) {
    yield put(initiateJumperRemovalFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(initiateJumperRemovalSuccess({}));
    yield put(closeModal("authoriseRateChange"));
    yield put(
      getJumperRemovalStatus({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
}

function* retryJumperRemovalFlow(
  action: PayloadAction<ValidateJumperParams>,
) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(
    api.complaintApi.retryJumperRemoval,
    action.payload,
  );
  if (error) {
    yield put(retryJumperRemovalFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(retryJumperRemovalSuccess({}));
    yield put(
      getJumperRemovalStatus({
        serviceRequestId: action.payload.serviceRequestId,
      }),
    );
  }
}

function* getJumperRemovalStatusFlow(
  action: PayloadAction<CheckJumperRemovalStatusParams>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.checkJumperRemovalStatus,
    action.payload,
  );
  if (error) {
    yield put(getJumperRemovalStatusFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(getJumperRemovalStatusSuccess({loanJumperStatus: response}));
  }
}

function* getCustomerAgentConversationFlow(
  action: PayloadAction<RequestIdPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.getCustomerAgentConversation,
    action.payload,
  );
  if (error) {
    yield put(getCustomerAgentConversationFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(getCustomerAgentConversationSuccess(response));
  }
}

function* postAgentReplyToConversationFlow(
  action: PayloadAction<PostAgentReplyPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.postAgentReplyToConversation,
    action.payload,
  );
  if (error) {
    yield put(postAgentReplyToConversationFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(postAgentReplyToConversationSuccess(response));
    yield put(snackBarActions.open({message: "Reply sent", variant: "success"}));
  }
}

function* updateServiceRequestMobileNumberFlow(
  action: PayloadAction<UpdateMobileNumberPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.updateServiceRequestMobileNumber,
    action.payload,
  );
  if (error) {
    yield put(updateServiceRequestMobileNumberFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(updateServiceRequestMobileNumberSuccess(response));
    yield put(toggleModals({type: "updateMobile", value: false}));
    yield put(reloadList());
  }
}

function* getRequestStatusCountFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.complaintApi.getRequestStatusCount);
  if (error) {
    yield put(getRequestStatusCountFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(getRequestStatusCountSuccess(response));
  }
}

function* closeFreshDeskTicketFlow(
  action: PayloadAction<CloseFreshDeskTicketPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.closeFreshDeskTicket,
    action.payload,
  );
  if (error) {
    yield put(closeFreshDeskTicketFailure({error}));
    yield put(
      snackBarActions.open({message: error.message, variant: "error"}),
    );
  } else {
    yield put(closeFreshDeskTicketSuccess(response));
    yield put(toggleModals({type: "closeTicket", value: false}));
    yield put(reloadList());
    yield put(getRequestStatusCount());
    yield put(snackBarActions.open({message: "Ticket closed", variant: "success"}));
  }
}

function* getCloseTicketQuestionsFlow(
  action: PayloadAction<GetCloseTicketQuestionsPayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.complaintApi.getCloseTicketQuestions,
    action.payload,
  );
  if (error) {
    yield put(getCloseTicketQuestionsFailure({error}));
    yield put(snackBarActions.open({message: error.message, variant: "error"}));
  } else {
    yield put(getCloseTicketQuestionsSuccess(response));
  }

}

export default function* complaintsSaga() {
  yield takeLatest(validateJumperRemoval.type, validateJumperRemovalFlow);
  yield takeLatest(initiateJumperRemoval.type, initiateJumperRemovalFlow);
  yield takeLatest(initiateJumperRemovalSuccess.type, function* () {
    yield put(snackBarActions.open({message: "Jumper removal initiated", variant: "success"}));
  });
  yield takeLatest(retryJumperRemoval.type, retryJumperRemovalFlow);
  yield takeLatest(retryJumperRemovalSuccess.type, function* () {
    yield put(snackBarActions.open({message: "Jumper removal initiated", variant: "success"}));
  });
  yield takeLatest(getJumperRemovalStatus.type, getJumperRemovalStatusFlow);
  yield takeLatest(
    getCustomerAgentConversation.type,
    getCustomerAgentConversationFlow,
  );
  yield takeLatest(
    postAgentReplyToConversation.type,
    postAgentReplyToConversationFlow,
  );
  yield takeLatest(
    updateServiceRequestMobileNumber.type,
    updateServiceRequestMobileNumberFlow,
  );
  yield takeLatest(getRequestStatusCount.type, getRequestStatusCountFlow);
  yield takeLatest(closeFreshDeskTicket.type, closeFreshDeskTicketFlow);
  yield takeLatest(getCloseTicketQuestions.type, getCloseTicketQuestionsFlow);
}
