import {LoanManager} from "../../../models/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {lmAvailabilityActions} from "./actions";
import {
  ETAListResponse,
  LOADERS_ENUM,
  SelectedTimeSlotInterface,
} from "app/infra/services/api/scheduling/lmAvailability/types";
import moment from "moment-timezone";
import {capitalize, reject} from "lodash";
import ConvertSchedulingDetailsToEvents from "../../../pages/home/GoldLoan/Scheduling/utils/ConvertSchedulingDetailsToEvents";
import {GoldApiError} from "app/typings/api/goldApi.types";
import {ClusterDetailResponse} from "app/infra/services/api/scheduling/openCR/types";

export interface LMAvailability {
  loanManagerList: { isFetching: boolean; list: LoanManager[] };
  selectedLoanManagerList: LoanManager[];
  loanManagerAvailability: any[];
  isFetching: boolean;
  error: any | null;
  loaders: Record<LOADERS_ENUM, boolean>;
  blockTimeDialogOpen: boolean;
  selectedJob: any;
  selectedTimeSlot: SelectedTimeSlotInterface | null;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  loading: Record<LoadingErrorState, boolean>;
  etaList: ETAListResponse[];
  openCREtaList: ETAListResponse[];
  secondaryAgent: [];
  unscheduledFeatureFlag: boolean;
  unscheduledClusterDetail: ClusterDetailResponse | null;
  unscheduledAgentReport: boolean | null;
}
type LoadingErrorState =
  | "getETA"
  | "unscheduleFeatureFlag"
  | "getOpenCrEta"
  | "unscheduleClusterDetail"
  | "unscheduledAgentReport"
  | "enableLeadManualAssignmentFlow";

const initialState: LMAvailability = {
  loanManagerList: {
    isFetching: false,
    list: [],
  },
  etaList: [],
  openCREtaList: [],
  unscheduledClusterDetail: null,
  selectedLoanManagerList: [],
  loanManagerAvailability: [],
  isFetching: false,
  unscheduledAgentReport: null,
  error: null,
  loaders: {
    BLOCK_AGENT_TIME: false,
    GET_AGENT_AVAILABILITY: true,
    DELETE_TIME_SLOT: false,
    UPDATE_TIME_SLOT: false,
  },
  unscheduledFeatureFlag: false,
  loading: {
    getETA: false,
    getOpenCrEta: false,
    unscheduleFeatureFlag: false,
    unscheduleClusterDetail: false,
    unscheduledAgentReport: false,
    enableLeadManualAssignmentFlow: false,
  },
  errors: {
    getETA: null,
    getOpenCrEta: null,
    unscheduleFeatureFlag: null,
    unscheduleClusterDetail: null,
    unscheduledAgentReport: null,
    enableLeadManualAssignmentFlow: null,
  },
  blockTimeDialogOpen: false,
  selectedJob: null,
  selectedTimeSlot: null,
  secondaryAgent: [],
};

export const lmAvailabilitySlice = createSlice({
  name: "lmAvailability",
  initialState,
  reducers: {
    toggleDialog: (state, action) => {
      state.blockTimeDialogOpen = action.payload;
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
    setSelectedTimeSlot: (
      state,
      action: PayloadAction<SelectedTimeSlotInterface>,
    ) => {
      state.selectedTimeSlot = action.payload;
    },
    setSelectedLoanManagerList: (
      state,
      action: PayloadAction<LoanManager[]>,
    ) => {
      state.selectedLoanManagerList = action.payload;
    },
    setEtaList: (state, action: PayloadAction<ETAListResponse[]>) => {
      state.etaList = action.payload;
    },
    setOpenCrEtaList: (state, action: PayloadAction<ETAListResponse[]>) => {
      state.openCREtaList = action.payload;
    },
    setUnscheduledAgentReport: (state, action: PayloadAction<boolean>) => {
      state.unscheduledAgentReport = action.payload;
    },
    setUnscheduledNewView: (state, action: PayloadAction<boolean>) => {
      state.unscheduledFeatureFlag = action.payload;
    },
    setUnscheduledClusterDetail: (
      state,
      action: PayloadAction<ClusterDetailResponse>,
    ) => {
      state.unscheduledClusterDetail = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: boolean;
      }>,
    ) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setError: (
      state,
      action: PayloadAction<{
        key: LoadingErrorState;
        value: GoldApiError;
      }>,
    ) => {
      state.errors[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: {
    [lmAvailabilityActions.getLMs.type]: (state) => {
      state.isFetching = true;
      state.loanManagerList.isFetching = true;
    },
    [lmAvailabilityActions.getLMsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loanManagerList.isFetching = false;
      state.loanManagerList.list = action.payload.loanManagerList.map(
        (l: LoanManager) => ({...l, title: l.name}),
      );
    },
    [lmAvailabilityActions.getLMsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loanManagerList.isFetching = false;
      state.error = action.payload.error;
    },
    [lmAvailabilityActions.blockAgentTime.type]: (state) => {
      state.isFetching = true;
      state.loaders.BLOCK_AGENT_TIME = true;
    },
    [lmAvailabilityActions.blockAgentTimeSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.BLOCK_AGENT_TIME = false;
      state.blockTimeDialogOpen = false;
      const {
        id,
        leadId,
        remark,
        agentId,
        type,
        lmUserCalendarId,
        crJobId,
        startTime,
        endTime,
      } = action.payload.response;
      state.loanManagerAvailability.push({
        agentId: agentId,
        title: capitalize(type.replaceAll("_", " ")),
        color: "rgba(206, 159, 41, 0.8)",
        crId: lmUserCalendarId,
        crJobId: crJobId,
        end: moment(endTime)
          .tz("Asia/Kolkata")
          .format(),
        id: id,
        leadId: leadId,
        remark: remark,
        resourceId: agentId,
        start: moment(startTime)
          .tz("Asia/Kolkata")
          .format(),
        date: moment(startTime)
          .tz("Asia/Kolkata")
          .format(),
        startAndEndTime: {
          startTime: moment(startTime)
            .tz("Asia/Kolkata")
            .format(),
          endTime: moment(endTime)
            .tz("Asia/Kolkata")
            .format(),
        },
        type: type,
        meta: null,
      });
    },
    [lmAvailabilityActions.blockAgentTimeFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
      state.loaders.BLOCK_AGENT_TIME = false;
    },
    [lmAvailabilityActions.getLMAvailability.type]: (state) => {
      state.isFetching = true;
      state.loaders.GET_AGENT_AVAILABILITY = true;
    },
    [lmAvailabilityActions.getLMAvailabilitySuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_AGENT_AVAILABILITY = false;
      state.loanManagerAvailability = ConvertSchedulingDetailsToEvents(
        action.payload.loanManagerAvailability,
      );
    },
    [lmAvailabilityActions.getLMAvailabilityFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.GET_AGENT_AVAILABILITY = false;
      state.error = action.payload.error;
    },
    [lmAvailabilityActions.deleteTimeSlot.type]: (state) => {
      state.isFetching = true;
      state.loaders.DELETE_TIME_SLOT = true;
    },
    [lmAvailabilityActions.deleteTimeSlotSuccess.type]: (state) => {
      state.isFetching = false;
      state.loaders.DELETE_TIME_SLOT = false;
      state.blockTimeDialogOpen = false;
      state.loanManagerAvailability = reject(
        state.loanManagerAvailability,
        (j) => j.id === state.selectedJob.id,
      );
    },
    [lmAvailabilityActions.deleteTimeSlotFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.DELETE_TIME_SLOT = false;
      state.error = action.payload.error;
    },
    [lmAvailabilityActions.updateTimeSlot.type]: (state) => {
      state.isFetching = true;
      state.loaders.UPDATE_TIME_SLOT = true;
    },
    [lmAvailabilityActions.updateTimeSlotSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.UPDATE_TIME_SLOT = false;
      state.blockTimeDialogOpen = false;
      const jobIndex = state.loanManagerAvailability.findIndex(
        (i) => i.id === state.selectedJob.id,
      );
      if (jobIndex !== -1) {
        state.loanManagerAvailability[jobIndex].date = moment(
          action.payload.response.startTime,
        ).format();
        state.loanManagerAvailability[jobIndex].start = moment(
          action.payload.response.startTime,
        ).format();
        state.loanManagerAvailability[jobIndex].end = moment(
          action.payload.response.endTime,
        ).format();
        state.loanManagerAvailability[jobIndex].startAndEndTime = {
          startTime: moment(action.payload.response.startTime).format(),
          endTime: moment(action.payload.response.endTime).format(),
        };
      }
    },
    [lmAvailabilityActions.updateTimeSlotFailure.type]: (state, action) => {
      state.isFetching = false;
      state.loaders.UPDATE_TIME_SLOT = false;
      state.error = action.payload.error;
    },
  },
});

const {
  toggleDialog,
  setSelectedJob,
  setSelectedTimeSlot,
  setSelectedLoanManagerList,
  setUnscheduledNewView,
  setLoading: setLmLoading,
  setError: setLmError,
  setEtaList,
  setOpenCrEtaList,
  setUnscheduledClusterDetail,
  setUnscheduledAgentReport,
} = lmAvailabilitySlice.actions;

export default lmAvailabilitySlice.reducer;

export {
  initialState,
  toggleDialog,
  setSelectedJob,
  setSelectedTimeSlot,
  setSelectedLoanManagerList,
  setLmError,
  setLmLoading,
  setEtaList,
  setOpenCrEtaList,
  setUnscheduledNewView,
  setUnscheduledClusterDetail,
  setUnscheduledAgentReport,
};
