import React, {useCallback, useEffect, useState, useMemo} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Input,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import IconWrapper from "../../NewUploadKYC/KYCDetails/styles/IconWrapper";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";
import {useSnackBar} from "app/store/hooks";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import {IGButton, IGTextField, IGSelect} from "app/components";

import getNextJob from "api/business/getNextJob";
import createPersonalLoan from "api/business/createPersonalLoan";
import completeStatus from "api/business/completeMileStone";
import getGoldLoanDetail from "api/business/getGoldLoanDetail";
import getPersonalLoanLenders from "api/business/getPersonalLoanLenders";
import getPersonalLoanSchemes from "api/business/getPersonalLoanSchemes";
import getPersonalDetails from "api/business/getPersonalDetail";
import styles from "../../styles";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import postPersonalLoanCreditPolicy from "api/activeLoan/postPersonalLoanCreditPolicy";
import ReturnToGoldValuationPopUp from "./Modals/ReturnToGoldValuationModal";
import validateGoldLoanAmount from "api/activeLoan/validateGoldLoanAmount";

const PersonalLoanNotTaken = ({
  selectedPayload,
  setIsPersonalLoanTaken,
  isSameCreditManager,
  isBackMovement,
  currentMileStone,
  getMileStoneOfCr,
}) => {
  const {milestone} = currentMileStone.data;
  const {id: crId} = selectedPayload;
  const {showError, showSuccess} = useSnackBar();
  const dispatch = useAppDispatch();
  const classes = styles();
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const loanMakerInputFieldsFlags = useAppSelector(
    activeLoansSelectors.getLoanMakerFieldsFlag,
  );

  // States
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedLender, setSelectedLender] = useState("");
  const [selectedScheme, setSelectedScheme] = useState("");
  const [schemeOptions, setSchemeOptions] = useState(null);
  const [lenderOptions, setLenderOptions] = useState(null);
  const [lenderIcon, setLenderIcon] = useState(null);
  const [cibilScore, setCibilScore] = useState(null);
  const [maxGlAmountPercent, setMaxGlAmountPercent] = useState("");
  const [minInterestRate, setMinInterestRate] = useState("");
  const [maxInterestRate, setMaxInterestRate] = useState("");
  const [minimumAmount, setMinimumAmount] = useState("");
  const [creditPdfName, setCreditPdfName] = useState("");
  const [isPLSkipLoading, setIsPLSkipLoading] = useState(false);
  const [selectedTenurePeriod, setSelectedTenurePeriod] = useState("");
  const [
    creditPolicyRecommendations,
    setCreditPolicyRecommendations,
  ] = useState({
    annualRoi: null,
    monthlyRoi: null,
    isRoiEditable: null,
  });

  const [isFetchRoiBtnLoading, setIsFetchRoiBtnLoading] = useState(false);

  const [
    isReturnToValuationModalOpen,
    setIsReturnToValuationModalOpen,
  ] = useState(false);

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      gl: null,
      nextJob: null,
    },
    errorMessage: "",
  });

  const allowedLoanTypes = ["TAKEOVER", "REBOOK", "RENEW", "PART_RELEASE"];
  const isAmountRefundToBTLenderAllowed = allowedLoanTypes.includes(
    selectedPayload?.loanType,
  );

  const isCrGoldLoan = milestone === "CR_GOLD_LOAN_FRESH_PERSONAL_LOAN";

  // UI utils
  const cleanLabelValue = (labelValue) => {
    return !labelValue ? " - " : labelValue;
  };

  /**
   * API callbacks
   */
  const getLoanDetailsAndNextJobDetails = useCallback(async () => {
    try {
      const [response, nextJobResponse] = await Promise.all([
        getGoldLoanDetail({
          accessToken,
          crId: crId,
          loanType: "GOLD_LOAN",
        }),
        getNextJob({
          accessToken,
          crId: crId,
          jobType: "CM_PROCESS_LOAN",
        }),
      ]);

      if (response.statusCode === 200 && nextJobResponse.statusCode === 200) {
        setStates({
          error: false,
          errorMessage: "",
          payload: {
            gl: response.payload,
            nextJob: nextJobResponse.payload,
          },
        });
      } else {
        setStates({
          error: true,
          errorMessage: response?.error?.toString(),
          loading: false,
          payload: {
            gl: null,
            communication: null,
            nextJob: null,
          },
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: {
          gl: null,
          communication: null,
          nextJob: null,
        },
      });
    }
  }, [accessToken, crId]);

  const onBackHandler = useCallback(async () => {
    setIsPLSkipLoading(true);
    try {
      const {error: validateGoldLoanAmountError} = await validateGoldLoanAmount(
        {
          crId: crId,
        },
      );
      if (!validateGoldLoanAmountError) {
        const response = await completeStatus({
          accessToken,
          crId: crId,
          milestone: isCrGoldLoan
            ? "CR_GOLD_LOAN_FRESH_PERSONAL_LOAN_DETAIL"
            : "CR_GOLD_LOAN_BT_PERSONAL_LOAN_DETAIL",
        });

        if (response.statusCode === 200) {
          const anotherStatus = await completeStatus({
            accessToken,
            crId: crId,
            milestone: isCrGoldLoan
              ? "CR_GOLD_LOAN_FRESH_REVIEW_DETAIL"
              : "CR_GOLD_LOAN_BT_REVIEW_DETAIL",
          });

          if (anotherStatus.statusCode === 200) {
            showSuccess("Success");
            setIsPLSkipLoading(false);

            getMileStoneOfCr();
          } else {
            showError(anotherStatus?.error?.toString());
            setIsPLSkipLoading(false);
          }
        } else {
          // window.location.reload();
          showError(response?.error?.toString());
          setIsPLSkipLoading(false);
        }
      } else {
        showError(validateGoldLoanAmountError?.message);
        setIsPLSkipLoading(false);
      }
    } catch (error) {
      showError("Something went wrong");
    }
    setIsPLSkipLoading(false);
    setButtonLoading(false);
  }, [
    getMileStoneOfCr,
    isCrGoldLoan,
    crId,
    accessToken,
    showError,
    showSuccess,
  ]);

  const fetchPersonalLoanLenders = useCallback(async () => {
    const {response, error} = await getPersonalLoanLenders(crId);

    if (!error) {
      setLenderOptions(response);
      if (response.length === 1) {
        setSelectedLender(response[0].lenderName);
        setLenderIcon(response[0].icon);
      }
    }
    if (error) {
      showError(error.message || "unable to fetch Personal loan lenders");
    } else {
    }
  }, [crId, showError]);

  const fetchPersonalLoanSchemes = useCallback(async () => {
    const {response, error} = await getPersonalLoanSchemes({
      crId: crId,
      lenderName: selectedLender,
    });

    if (!error) {
      const {
        id,
        maxGlAmountPercent,
        minAmount,
        maxMonthlyInterestAllowed,
        minMonthlyInterestAllowed,
      } = response[0];
      const schemeArr = response?.map((item) => ({
        schemeName: item?.loanSchemeName,
        schemeId: item?.id,
        eligibleTenureList: item?.eligibleTenureList,
      }));
      setSchemeOptions([...schemeArr]);
      if (schemeArr.length === 1) {
        setSelectedScheme(id);
      }
      setMaxGlAmountPercent(maxGlAmountPercent);
      setMinimumAmount(minAmount);
      setMaxInterestRate(maxMonthlyInterestAllowed || "50");
      setMinInterestRate(minMonthlyInterestAllowed);
      setStates((prevState) => ({
        ...prevState,
        loading: false,
      }));
    } else {
      showError(error.message || "unable to fetch Schemes for selected Lender");
    }
  }, [selectedLender, setSelectedScheme, setSchemeOptions, showError, crId]);

  const fetchPersonalDetails = useCallback(async () => {
    const {payload, statusCode, error} = await getPersonalDetails({
      accessToken,
      crId: crId,
      personalDetailType: "LOAN_PERSONAL_DETAIL",
    });

    if (statusCode === 200) {
      setCibilScore(payload.cibilScrore);
    } else {
      showError(error);
    }
  }, [setCibilScore, crId, accessToken, showError]);

  /**
   * Component Lifecycle
   */
  useEffect(() => {
    getLoanDetailsAndNextJobDetails();
    fetchPersonalDetails();
  }, []);

  useEffect(() => {
    if (selectedPayload.id) {
      dispatch(
        activeLoansActions.getMakerFieldsFlag({
          crId: selectedPayload.id,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (states.payload.gl) {
      fetchPersonalLoanLenders();
    }
  }, [states]);

  useEffect(() => {
    if (selectedLender) {
      fetchPersonalLoanSchemes();
    }
  }, [selectedLender]);

  /**
   * Form Management
   */
  const formFields = [
    {
      key: "personalLoanSanctionedAmount",
      initialValue: null,
      validation: yup
        .number("Only numbers are allowed")
        // .required("This field is required")
        .integer("Decimal not allowed")
        .max(9999999, "This field must be less than 10000000"),
    },
    {
      key: "personalLoanDisbursedAmount",
      initialValue: null,
      validation: yup
        .number("Only numbers are allowed")
        // .required("This field is required")
        .integer("Decimal not allowed")
        .max(9999999, "This field must be less than 10000000"),
    },
    {
      key: "customerCreditScore",
      initialValue: null,
      validation:
        cibilScore === null &&
        yup
          .number("Only numbers are allowed")
          .integer("Decimal not allowed")
          .max(900, "This field must be less than 900")
          .when("noCibilScoreCheck", (noCibilScoreCheck, schema) => {
            if (noCibilScoreCheck) {
              return schema;
            }
            return schema.required("This field is required");
          }),
    },
    {
      key: "creditReportFile",
      initialValue: null,
      validation:
        cibilScore === null &&
        yup.mixed().when("noCibilScoreCheck", (noCibilScoreCheck, schema) => {
          if (noCibilScoreCheck) {
            return schema;
          }
          return schema.required("Please select credit file");
        }),
    },
    {
      key: "noCibilScoreCheck",
      initialValue: null,
      validation: yup.boolean(),
    },
  ];

  formFields.getValidationSchema = (key) =>
    formFields.find((item) => item.key === key)?.validation;

  const validationSchema = yup.object().shape({
    personalLoanSanctionedAmount: formFields.getValidationSchema(
      "personalLoanSanctionedAmount",
    ),
    personalLoanDisbursedAmount: formFields.getValidationSchema(
      "personalLoanDisbursedAmount",
    ),
    customerRoi: formFields.getValidationSchema("customerRoi"),
    customerCreditScore: formFields.getValidationSchema("customerCreditScore"),
    creditReportFile: formFields.getValidationSchema("creditReportFile"),
    noCibilScoreCheck: formFields.getValidationSchema("noCibilScoreCheck"),
  });

  const onSubmitHandler = useCallback(
    async (values) => {
      setButtonLoading(true);
      const response = await getNextJob({
        accessToken,
        crId: crId,
        jobType: "CM_PROCESS_LOAN",
      });

      if (response.statusCode !== 200) {
        showError(response.error);
        setButtonLoading(false);
        return;
      }

      const formData = new FormData();

      formData.append(
        "sanctionLoanAmount",
        values.personalLoanSanctionedAmount,
      );
      formData.append(
        "disbursalLoanAmount",
        values.personalLoanDisbursedAmount,
      );
      formData.append(
        "amountRefundToBtLender",
        isAmountRefundToBTLenderAllowed ? values.amountRefundToBtLender : 0,
      );
      formData.append("lenderEnum", selectedLender);
      if (selectedLender === lenderNameEnum.SUNITA_FINANCE) {
        formData.append(
          "monthlyCustomerRoi",
          !creditPolicyRecommendations?.isRoiEditable
            ? creditPolicyRecommendations?.monthlyRoi
            : values.customerRoi,
        );
      } else {
        formData.append("monthlyCustomerRoi", values.customerRoi);
      }
      if (cibilScore === null) {
        if (values.noCibilScoreCheck) {
          formData.append("customerCreditScore", -1);
        } else {
          formData.append("customerCreditScore", values.customerCreditScore);
        }
        formData.append("creditReportFile", values.creditReportFile);
      } else {
        formData.append("customerCreditScore", cibilScore);
      }
      formData.append("fwcGoldLoanPlanId", selectedScheme);
      formData.append("loanTenure", selectedTenurePeriod);

      const personalLoanResponse = await createPersonalLoan({
        accessToken,
        fileData: formData,
        crId: crId,
        crJobId: response.payload.id,
        // email: email,
      });

      if (!personalLoanResponse.error) {
        const completeMileStoneResponse = await completeStatus({
          accessToken,
          crId: crId,
          milestone: isCrGoldLoan
            ? "CR_GOLD_LOAN_FRESH_PERSONAL_LOAN_DETAIL"
            : "CR_GOLD_LOAN_BT_PERSONAL_LOAN_DETAIL",
        });

        if (completeMileStoneResponse.statusCode === 200) {
          showSuccess("Successfully created!");
          getMileStoneOfCr();
          setIsPersonalLoanTaken(true);
        } else {
          showError(completeMileStoneResponse.error);
        }
      } else {
        showError(personalLoanResponse?.error?.message);
        if (personalLoanResponse.statusCode === 422) {
          setFieldValue("personalLoanSanctionedAmount", "", false);
          setFieldValue("personalLoanDisbursedAmount", "", false);
          setFieldValue("amountRefundToBtLender", "", false);
          setFieldValue("monthlyCustomerRoi", "", false);
        }
      }

      setButtonLoading(false);
    },
    [
      accessToken,
      crId,
      selectedLender,
      creditPolicyRecommendations?.isRoiEditable,
      creditPolicyRecommendations?.monthlyRoi,
      cibilScore,
      selectedScheme,
      selectedTenurePeriod,
      showError,
      isCrGoldLoan,
      showSuccess,
      getMileStoneOfCr,
      setIsPersonalLoanTaken,
      setFieldValue,
      isAmountRefundToBTLenderAllowed,
    ],
  );

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    isValid,
  } = useFormik({
    initialValues: {
      customerCreditScore: null,
      personalLoanSanctionedAmount: "",
      personalLoanDisbursedAmount: "",
      amountRefundToBtLender: "",
      customerRoi: "",
      creditReportFile: null,
      noCibilScoreCheck: false,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  });

  //to get tenure period corresponding to selected scheme
  const selectedSchemeTenureOptions = useMemo(() => {
    if (selectedScheme) {
      const selectedSchemeTenure = schemeOptions?.find(
        (item) => item.schemeId === selectedScheme,
      );
      return selectedSchemeTenure?.eligibleTenureList;
    }
    return [];
  }, [schemeOptions, selectedScheme]);

  const onClickFetchRoiDetails = useCallback(async () => {
    setIsFetchRoiBtnLoading(true);
    const {error, response} = await postPersonalLoanCreditPolicy({
      accessToken,
      crId: crId,
      lenderName: selectedLender,
      personalLoanAmount: Number(values.personalLoanSanctionedAmount),
    });

    if (!error) {
      setIsFetchRoiBtnLoading(false);
      showSuccess("ROI has been successfully fetched!");
      setCreditPolicyRecommendations((prevState) => ({
        ...prevState,
        monthlyRoi: response?.monthlyRoi,
        annualRoi: response?.annualRoi,
        isRoiEditable: response?.isRoiEditable,
      }));
    } else {
      setIsFetchRoiBtnLoading(false);
      showError(error?.message);
    }
  }, [
    accessToken,
    crId,
    selectedLender,
    showError,
    values.personalLoanSanctionedAmount,
  ]);

  // Loading & error handlers
  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  return (
    <div className={classes.textContainer}>
      {!isBackMovement && isSameCreditManager && states.payload.nextJob && (
        <>
          <form onSubmit={handleSubmit}>
            <div className={classes.personalLoanMakerContainer}>
              <div style={{width: "60%"}}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="lenderSelection">Select Lender</InputLabel>
                  <Select
                    fullWidth
                    required
                    id="lenderSelection"
                    name="selectedLender"
                    label="Select Lender"
                    labelId="lenderSelection"
                    variant="outlined"
                    value={selectedLender}
                    onChange={(event) => {
                      setLenderIcon(
                        lenderOptions.find(
                          (lender) => lender.lenderName === event.target.value,
                        ).icon,
                      );
                      setSelectedLender(event.target.value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.selectedLender && Boolean(errors.selectedLender)
                    }
                  >
                    {lenderOptions?.map((lender) => (
                      <MenuItem
                        key={lender.lenderName}
                        value={lender.lenderName}
                      >
                        {lender.lenderName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {selectedLender && (
                <div>
                  <IconWrapper
                    width={"auto"}
                    height={"auto"}
                    src={lenderIcon}
                    alt="bank-icon"
                  />
                </div>
              )}
            </div>

            <div className={classes.selectedSchemeContainer}>
              <FormControl variant="outlined" size="small" fullWidth required>
                <InputLabel id="scheme">Select Scheme</InputLabel>
                <Select
                  required
                  id="scheme"
                  name="selectedScheme"
                  label="Select Scheme"
                  labelId="scheme"
                  variant="outlined"
                  value={selectedScheme}
                  onBlur={handleBlur}
                  onChange={(event) => setSelectedScheme(event.target.value)}
                  error={
                    touched.selectedScheme && Boolean(errors.selectedScheme)
                  }
                >
                  {schemeOptions?.map((scheme) => (
                    <MenuItem key={scheme} value={scheme?.schemeId}>
                      {scheme?.schemeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Box width="60%" mt={2}>
              <IGSelect
                fullWidth={true}
                placeholder="Please select scheme tenure"
                label="Please select scheme tenure"
                name="selectedTenurePeriod"
                value={selectedTenurePeriod}
                onChange={(ev) => {
                  setSelectedTenurePeriod(ev.target.value);
                }}
                options={selectedSchemeTenureOptions?.map((item) => ({
                  text: item,
                  value: item,
                }))}
              />
            </Box>

            <Box display="flex" textAlign="center" gap={1}>
              <IGTextField
                sx={{
                  width: "70%",
                }}
                onBlur={handleBlur}
                fullWidth={false}
                variant="standard"
                name="personalLoanSanctionedAmount"
                value={values.personalLoanSanctionedAmount}
                type="number"
                label={`Enter Personal Loan Sanctioned Amount(Min Rs${cleanLabelValue(
                  minimumAmount,
                )} to Max ${cleanLabelValue(maxGlAmountPercent)}% of GL)`}
                required
                onChange={handleChange}
                helperText={errors?.personalLoanSanctionedAmount}
                touched={touched.personalLoanSanctionedAmount}
                error={
                  touched.personalLoanSanctionedAmount &&
                  Boolean(errors.personalLoanSanctionedAmount)
                }
              />
              {selectedLender === lenderNameEnum.SUNITA_FINANCE && (
                <IGButton
                  disabled={!values.personalLoanSanctionedAmount}
                  loading={isFetchRoiBtnLoading}
                  onClick={() => onClickFetchRoiDetails()}
                  sx={{
                    height: "32px",
                    width: "28%",
                  }}
                >
                  Get ROI
                </IGButton>
              )}
            </Box>

            <IGTextField
              onBlur={handleBlur}
              variant="standard"
              name="personalLoanDisbursedAmount"
              disabled={
                loanMakerInputFieldsFlags?.plMakerScreenDisplayInputFields
                  ?.customerDisbursedAmountDisabled
              }
              value={values.personalLoanDisbursedAmount}
              type="number"
              label="Enter Personal Loan amount to be disbursed to the customer(INR)"
              required
              onChange={handleChange}
              helperText={errors?.personalLoanDisbursedAmount}
              touched={touched.personalLoanDisbursedAmount}
              error={
                touched.personalLoanDisbursedAmount &&
                Boolean(errors.personalLoanDisbursedAmount)
              }
            />

            {isAmountRefundToBTLenderAllowed && (
              <IGTextField
                onBlur={handleBlur}
                disabled={
                  loanMakerInputFieldsFlags?.plMakerScreenDisplayInputFields
                    ?.amountRefundToBtLenderDisabled
                }
                variant="standard"
                name="amountRefundToBtLender"
                value={values.amountRefundToBtLender}
                label="Enter Amount to be refunded to Bt Lender"
                type="number"
                required
                onChange={handleChange}
                helperText={errors?.amountRefundToBtLender}
                touched={touched.amountRefundToBtLender}
                error={
                  touched.amountRefundToBtLender &&
                  Boolean(errors.amountRefundToBtLender)
                }
              />
            )}

            {selectedLender && cibilScore === null && (
              <>
                <div className={classes.cibilScoreContainer}>
                  <div style={{width: "70%"}}>
                    <IGTextField
                      onBlur={handleBlur}
                      name="customerCreditScore"
                      variant="standard"
                      value={values.customerCreditScore}
                      label="Enter Customers credit score as of today"
                      type="number"
                      required={values.noCibilScoreCheck === false}
                      disabled={values.noCibilScoreCheck}
                      onChange={handleChange}
                      helperText={errors?.customerCreditScore}
                      touched={touched.customerCreditScore}
                      error={
                        touched.customerCreditScore &&
                        Boolean(errors.customerCreditScore)
                      }
                      InputLabelProps={{
                        shrink: values.customerCreditScore,
                      }}
                    />
                  </div>
                  <div className={classes.creditReportFileContainer}>
                    <div className={classes.creditReportFileNameContainer}>
                      {creditPdfName}
                    </div>
                    <Button
                      disabled={values.noCibilScoreCheck}
                      variant="contained"
                      component="label"
                    >
                      {creditPdfName.length === 0 ? "Upload PDF" : "Change"}
                      <Input
                        type="file"
                        name="creditReportFile"
                        accept="application/pdf"
                        hidden
                        required={values.noCibilScoreCheck === false}
                        disabled={values.noCibilScoreCheck}
                        onChange={(event) => {
                          if (
                            event.target.files[0]?.name.split(".").at(-1) !==
                            "pdf"
                          ) {
                            setFieldError(
                              "creditReportFile",
                              "Please upload a valid PDF file",
                            );
                            return;
                          }
                          setCreditPdfName(event.target.files[0]?.name);
                          setFieldValue(
                            "creditReportFile",
                            event.target.files[0],
                          );
                        }}
                      />
                    </Button>
                    <div
                      style={{
                        color: "red",
                      }}
                    >
                      {errors.creditReportFile}
                    </div>
                  </div>
                </div>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.noCibilScoreCheck}
                        onChange={async (event) => {
                          const {checked} = event.target;
                          if (checked) {
                            await setFieldValue("customerCreditScore", -1);
                            await setFieldError("customerCreditScore", null);
                          }
                          await setFieldValue("noCibilScoreCheck", checked);
                        }}
                        inputProps={{"aria-label": "controlled"}}
                      />
                    }
                    label="Cibil score not generated for user"
                  />
                </Box>
              </>
            )}
            <div className={classes.customerRoiContainer}>
              <>
                <InputLabel
                  id="customer-roi"
                  variant="standard"
                  className={classes.inputLabelPl}
                >
                  {`Enter Customer ROI(Monthly Min ${cleanLabelValue(
                    minInterestRate,
                  )}% to Max ${cleanLabelValue(maxInterestRate)}%)`}
                </InputLabel>
                <IGTextField
                  name="customerRoi"
                  disabled={
                    selectedLender === lenderNameEnum.SUNITA_FINANCE &&
                    !creditPolicyRecommendations?.isRoiEditable
                  }
                  onBlur={handleBlur}
                  sx={{width: "6rem", m: 1}}
                  value={
                    !creditPolicyRecommendations?.isRoiEditable
                      ? creditPolicyRecommendations?.monthlyRoi
                      : values.customerRoi
                  }
                  type="number"
                  required
                  onChange={(event) =>
                    event.target.value.split(".")[1]?.length > 2
                      ? setFieldValue(
                          "customerRoi",
                          Number(event.target.value).toFixed(2),
                        )
                      : setFieldValue("customerRoi", event.target.value)
                  }
                  helperText={errors?.customerRoi}
                  touched={touched.customerRoi}
                  error={touched.customerRoi && Boolean(errors.customerRoi)}
                />
                <span style={{margin: "1rem", fontSize: "1.5rem"}}> % </span>
              </>
            </div>

            <Box my={1}>
              <IGButton
                type="submit"
                fullWidth
                sx={{
                  padding: 1,
                }}
                color="golden"
                loading={buttonLoading}
              >
                PROCEED
              </IGButton>
            </Box>
            <Box my={1}>
              <IGButton
                onClick={() => setIsReturnToValuationModalOpen(true)}
                color="inherit"
                fullWidth
              >
                RETURN TO GOLD VALUATION
              </IGButton>
            </Box>

            <Box my={1}>
              <IGButton
                sx={{
                  padding: 1,
                }}
                fullWidth
                color="inherit"
                onClick={onBackHandler}
                loading={isPLSkipLoading}
              >
                SKIP
              </IGButton>
            </Box>
          </form>
          {isReturnToValuationModalOpen && (
            <ReturnToGoldValuationPopUp
              isReturnToValuationModalOpen={isReturnToValuationModalOpen}
              crId={selectedPayload.id}
              getMileStoneOfCr={getMileStoneOfCr}
              setIsReturnToValuationModalOpen={setIsReturnToValuationModalOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PersonalLoanNotTaken;
