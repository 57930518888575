import React, {useCallback, useEffect, useState} from "react";
import KycDetails from "./KycDetails";
import getLenderDetails from "api/business/getLenderDetails";
import getPersonalDetails from "api/business/getPersonalDetail";
import Loading from "app/components/Loading";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

const KYCDetails = ({
  crId,
  hideSensitiveInfo,
  setKycStatusCheck,
  setLenderName,
  setShowKycDetailsAlert,
}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [states, setStates] = useState({
    error: false,
    errorMessage: "",
    loading: false,
  });
  const [lenderDetails, setLenderDetails] = useState(null);
  const [personalDetail, setPersonalDetail] = useState(null);
  const [customerName, setCustomerName] = useState({});

  const fetchLenderDetails = useCallback(async () => {
    setStates({...states, loading: true});
    const {payload, error, statusCode} = await getLenderDetails({
      accessToken,
      crId,
    });

    if (statusCode === 200) {
      setLenderDetails(payload);
      setLenderName(payload?.lenderName);
      setStates({...states, loading: false});
    } else {
      setStates({
        ...states,
        error: true,
        errorMessage: error,
        loading: false,
      });
    }
  }, [accessToken, crId, states, setLenderName]);

  const fetchCustomerDetails = useCallback(async () => {
    setStates({...states, loading: true});
    const {payload, error, statusCode} = await getPersonalDetails({
      accessToken,
      crId,
      personalDetailType: "LOAN_PERSONAL_DETAIL",
    });
    if (error) {
      setStates({
        ...states,
        error: true,
        errorMessage: error,
      });
    } else if (statusCode === 200) {
      const customerName = {
        firstName: payload.firstName,
        middleName: payload.middleName,
        lastName: payload.lastName,
      };
      setPersonalDetail(payload);
      setCustomerName(customerName);
    }
    setStates({...states, loading: false});
  }, [accessToken, crId, states]);

  useEffect(() => {
    fetchLenderDetails();
    fetchCustomerDetails();
  }, []);

  if (states.loading || !lenderDetails || !personalDetail) {
    return <Loading />;
  }

  return (
    <>
      <KycDetails
        lenderDetails={lenderDetails}
        crId={crId}
        hideSensitiveInfo={hideSensitiveInfo}
        customerName={customerName}
        personalDetail={personalDetail}
        setKycStatusCheck={setKycStatusCheck}
        setShowKycDetailsAlert={setShowKycDetailsAlert}
      />
    </>
  );
};

export default KYCDetails;
