import styled from "@emotion/styled";

const SubmitButtonWrapper = styled.div`
  margin-top: 1.5rem;
  > button {
    width: 20rem;
    height: 3.5rem;
  }
`;

export default SubmitButtonWrapper;
