import React, {FC} from "react";
import {Box, Divider, Drawer, DrawerProps, IconButton, Typography} from "@mui/material";
import {IGDrawerContentWrapper, IGDrawerTitle, IGDrawerContent} from "./style";
import {CloseRounded} from "@mui/icons-material";

interface IGDrawerProps extends Omit<DrawerProps, "title"> {
  open: boolean
  onClose: () => void
  anchor?: "left" | "top" | "right" | "bottom"
  title?: string | (() => React.ReactNode)
  subTitle?: string
}

/**
 * General Drawer component
 * @param open
 * @param onClose
 * @param title
 * @param subTitle
 * @param anchor
 * @param children
 * @param rest
 * @constructor
 */
const IGDrawer: FC<IGDrawerProps> = ({
  open = false,
  onClose,
  title = () => null,
  subTitle,
  anchor = "right",
  children,
  ...rest
}) => {

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: "300px",
        },
      }}
      ModalProps={{
        style: {
          zIndex: 1300,
        },
      }}
      {...rest}
    >
      <IGDrawerContentWrapper>
        <IconButton className="cls-btn" onClick={onClose}>
          <CloseRounded/>
        </IconButton>
        {typeof title === "string" ? (
          <>
            <IGDrawerTitle>
              <Box className="title">
                <Typography variant="h5">{title}</Typography>
              </Box>
              {subTitle && (
                <Box className="sub-title">
                  <Typography variant="subtitle1">{subTitle}</Typography>
                </Box>
              )}
            </IGDrawerTitle>
            <Divider />
          </>
        ) : title()}
        <IGDrawerContent>
          {children}
        </IGDrawerContent>
      </IGDrawerContentWrapper>
    </Drawer>
  );
};

export default IGDrawer;