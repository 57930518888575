import React, {FC, MouseEventHandler} from "react";
import {
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  TypographyProps,
} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import IGButton from "../Button/IGButton";
// eslint-disable-next-line no-restricted-imports
import {TransitionProps} from "@mui/material/transitions";

const DialogTitleStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      children={props.children}
      mountOnEnter
    />
  );
});

interface IGDialogActionProps extends ButtonProps {
  text?: string;
  loading?: boolean;
}

export interface IGDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  fullWidth?: boolean;
  title?: string;
  subtitle?: string;
  confirmProps?: IGDialogActionProps;
  cancelProps?: IGDialogActionProps;
  hideFooter?: boolean;
  headerIcon?: React.ReactNode | null;
  fullScreen?: boolean;
  titleProps?: TypographyProps;
}

/**
 * General Dialog component which uses mui 5 Dialog as Base.
 * @param open
 * @param onClose
 * @param maxWidth
 * @param fullWidth
 * @param title
 * @param subtitle
 * @param onConfirm
 * @param onCancel
 * @param confirmProps
 * @param cancelProps
 * @param hideFooter
 * @param children
 * @constructor
 */
const IGDialog: FC<IGDialogProps> = ({
  open = false,
  onClose,
  maxWidth = "xs",
  fullWidth = true,
  title = "",
  subtitle = "",
  onConfirm,
  onCancel,
  headerIcon = null,
  confirmProps = {},
  cancelProps = {},
  hideFooter = false,
  children,
  fullScreen = false,
  titleProps = {},
}) => {
  const {
    text: confirmText,
    loading: confirmLoading,
    disabled: confirmDisabled,
  } = confirmProps;

  const {
    text: cancelText,
    loading: cancelLoading,
    disabled: cancelDisabled,
  } = cancelProps;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      {title ? (
        <DialogTitle sx={DialogTitleStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            {headerIcon && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "1rem",
                  height: "2rem",
                  marginRight: "1rem",
                }}
              >
                {headerIcon}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "80%",
              }}
            >
              <Box display="flex">
                <Typography variant="h6" {...titleProps}>
                  {title}
                </Typography>
              </Box>
              {subtitle && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    lineHeight: "1.2",
                    fontSize: "12px",
                  }}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseRounded />
          </IconButton>
        </DialogTitle>
      ) : (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            zIndex: 2,
            backgroundColor: "wheat",
          }}
        >
          <CloseRounded />
        </IconButton>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {!hideFooter && (
        <DialogActions>
          <IGButton
            disabled={cancelLoading || cancelDisabled}
            onClick={onCancel}
            variant="outlined"
            {...cancelProps}
          >
            {cancelText || "Cancel"}
          </IGButton>
          <IGButton
            disabled={confirmLoading || confirmDisabled}
            onClick={onConfirm}
            variant="contained"
            {...confirmProps}
          >
            {confirmText || "Confirm"}
          </IGButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default IGDialog;
