import React from "react";
import {
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
  TextField,
  TextFieldProps,
  capitalize,
} from "@mui/material";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface IGAutocompleteMultipleOption {
  name: string
}

export interface IGAutocompleteMultipleProps<
  T extends IGAutocompleteMultipleOption
>
  extends Optional<AutocompleteProps<T, true, boolean, false>, "renderInput" | "options"> {
  label: string
  value: AutocompleteProps<T, true, boolean, false>["value"]
  onChange: AutocompleteProps<T, true, boolean, false>["onChange"]
  options: AutocompleteProps<T, true, boolean, false>["options"]
  error?: TextFieldProps["error"]
  helperText?: TextFieldProps["helperText"]
  touched?: boolean | undefined,
  textFieldProps?: TextFieldProps,
  required?: boolean
  renderInput?: AutocompleteProps<T, true, boolean, false>["renderInput"]
}

/**
 * IGAutocompleteMultiple component which uses mui 5 Autocomplete as base
 * @param id
 * @param value
 * @param onChange
 * @param getOptionLabel
 * @param renderInput
 * @param label
 * @param options
 * @param disableClearable
 * @param size
 * @param error
 * @param touched
 * @param helperText
 * @param required
 * @param filterSelectedOptions
 * @param filterOptions
 * @param textFieldProps
 * @param props
 * @constructor
 */
function IGAutocompleteMultiple<
  T extends IGAutocompleteMultipleOption = IGAutocompleteMultipleOption
>({
  id,
  value,
  onChange,
  getOptionLabel,
  renderInput,
  label,
  options = [],
  disableClearable = false,
  size = "small",
  error = false,
  touched = undefined,
  helperText = "",
  required = false,
  filterSelectedOptions = true,
  filterOptions,
  textFieldProps = {
    variant: "outlined",
  },
  ...props
}: IGAutocompleteMultipleProps<T>) {

  const handleFilterOptions = (
    options: T[],
    state: FilterOptionsState<T>,
  ): T[] => {
    // return if value is null or undefined
    if (!(value)) return options;

    // if type is generic then it must have text key, and we are filtering based on that
    const filteredOptions = options.filter(o => {
      const optionFound = value.find(
        i => i.name === o.name,
      );
      if (optionFound) return false;
      if (state.inputValue.length > 0) {
        return o.name
          .toLowerCase()
          .includes(state.inputValue.toLowerCase());
      }
      return true;
    });

    // if filterOptions is passed then call the function
    if (filterOptions) return filterOptions(filteredOptions, state);
    return filteredOptions;
  };

  const formattedText = (text: string) => {
    return capitalize(text.replaceAll("_", " ").toLowerCase());
  };

  return (
    <Autocomplete<T, true, boolean, false>
      multiple={true}
      disableClearable={disableClearable}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={handleFilterOptions}
      size={size}
      id={id || label}
      options={options}
      getOptionLabel={(option) => {
        if (getOptionLabel) {
          return getOptionLabel(option);
        }
        return typeof option === "string"
          ? formattedText(String(option))
          : formattedText(option.name);
      }}
      value={value}
      onChange={onChange}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
          required={required}
          label={label}
          error={error}
          helperText={error ? (touched && helperText) : helperText}
        />
      )}
    />
  );
};

export default IGAutocompleteMultiple;