import {IGDialog, IGTextField} from "app/components";
import activeLoansApi from "app/infra/services/api/activeLoans/api";
import {useSnackBar} from "app/store/hooks";
import React from "react";

interface BlacklistModalProps {
  open: boolean;
  onClose: () => void;
  crId: number
}
const BlacklistModal: React.FC<BlacklistModalProps> = ({
  open,
  onClose,
  crId,
}) => {
  const {showError, showSuccess} = useSnackBar();
  const [blacklistReason, setBlacklistReason] = React.useState("");
  const onConfirm = async () => {
    if (!crId) {
      showError("Something went wrong");
      return;
    }
    const response = await activeLoansApi.blacklistLoanNew({
      crId,
      remark: blacklistReason,
    });
    if (!response.error) {
      showSuccess("Blacklisted Successfully");
      onClose();
    } else {
      showError(response.error.message || "Something went wrong");
    }
  };

  return (
    <IGDialog
      open={open}
      onClose={onClose}
      title="Blacklist"
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <IGTextField
        name="blacklistReason"
        label="Remarks"
        placeholder="Enter Remarks"
        value={blacklistReason}
        onChange={(e) => setBlacklistReason(e.target.value)}
        multiline
        rows={4}
        inputProps={{
          maxLength: 128,
        }}
        fullWidth
      />
    </IGDialog>
  );
};

export default BlacklistModal;
