import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuIconProps, MENU_ICONS} from ".";

const UtilitiesIcon: React.FC<MenuIconProps> = ({isActive}) => {
  return (
    <FontAwesomeIcon
      icon={MENU_ICONS.Utilites}
      size="2x"
      style={{
        color: isActive ? "#ce9f29" : "#2C3639",
      }}
    />
  );
};

export default UtilitiesIcon;
