import React from "react";
import {Box, Typography, Grid} from "@mui/material";
import {useAppSelector} from "app/store/hooks";
import {transactionsSelectors} from "app/store/transactions";
import {IGLoading} from "app/components";
import LoanDetailsBox from "../styled";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {Transactions} from "app/models/transactions.model";
import {renderPaymentJobTitleBlock} from "app/pages/home/GoldLoan/Accounts/Drawer/UIHelpers";

interface TransactionDetailsProps {
  transactionsList: Transactions[];
}
const TransactionDetails = ({transactionsList}: TransactionDetailsProps) => {
  const {getTransactionsList: isTransactionsListLoading} = useAppSelector(
    transactionsSelectors.getLoading,
  );

  return (
    <Box>
      {isTransactionsListLoading ? (
        <IGLoading height="10vh" />
      ) : (
        <LoanDetailsBox>
          <Typography fontWeight="bold" variant="h6">
            Transactions Details
          </Typography>
          <Grid mt={2} container spacing={2}>
            {transactionsList?.map((item) => (
              <>
                <Grid item xs={6}>
                  <Typography color="text.secondary" variant="h6">
                    {renderPaymentJobTitleBlock(item?.transactionType)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {numberWithCurrencyAndComma(item.amount)}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </LoanDetailsBox>
      )}
    </Box>
  );
};

export default TransactionDetails;
