import {PayloadAction} from "@reduxjs/toolkit";
import {GoldApi} from "app/infra/services/api";
import {
  LmUserCityPayload,
  UpdateUserRolePayload,
  UserIdPayload,
  UserStatusPayload,
  UserOrganizationRolePayload,
} from "app/infra/services/api/internalUser/types";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {snackBarActions} from "../snackbar";
import internalUserAction, {internalUserActionTypes} from "./action";
import {resetModal} from "./reducer";

function* getInternalUserFlow(action: any) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.getAllInternalUser,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getInternalUserListFailure({error}));
  } else {
    yield put(internalUserAction.getInternalUserListSuccess({response}));
  }
}

function* getRolesFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.internalUser.getAllRoles);

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getAllRolesFailure());
  } else {
    yield put(internalUserAction.getAllRolesSuccess({response}));
  }
}

function* getUserCitiesFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.getLmUserCities,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getUserCitiesFailure());
  } else {
    yield put(internalUserAction.getUserCitiesSuccess({response}));
  }
}

function* getUserRolesFlow(action: PayloadAction<UserIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.getUserRole,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getUserRolesFailure());
  } else {
    yield put(internalUserAction.getUserRolesSuccess({response}));
  }
}

function* getCitiesFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.internalUser.getAllCities);

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getAllCitiesFailure());
  } else {
    yield put(internalUserAction.getAllCitiesSuccess({response}));
  }
}

function* changeUserCitiesFlow(action: PayloadAction<LmUserCityPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.changeLmUserCity,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.changeUserCityFailure());
  } else {
    yield put(
      snackBarActions.open({
        message: "Success!",
        variant: "success",
      }),
    );
    yield put(internalUserAction.changeUserCitySuccess({response}));
    yield put(resetModal());
  }
}

function* changeUserStatusFlow(action: PayloadAction<UserStatusPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.changeLmUserStatus,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.changeUserStatusFailure());
  } else {
    yield put(
      snackBarActions.open({
        message: "Success!",
        variant: "success",
      }),
    );
    yield put(internalUserAction.changeUserStatusSuccess({response}));
    yield put(resetModal());
  }
}

function* updateUserRolesFlow(action: PayloadAction<UpdateUserRolePayload>) {
  const api: GoldApi = yield getContext("api");
  const {error} = yield call(api.internalUser.updateUserRole, action.payload);

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.updateUserRolesFailure());
  } else {
    yield put(
      snackBarActions.open({
        message: "Success!",
        variant: "success",
      }),
    );
    yield put(internalUserAction.updateUserRolesSuccess());
    yield put(resetModal());
  }
}

function* getAllUserTypesFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(api.internalUser.getUserTypes);

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.getAllUserTypesFailure({error}));
  } else {
    yield put(internalUserAction.getAllUserTypesSuccess({response}));
  }
}

function* changeOrganizationRoleFlow(
  action: PayloadAction<UserOrganizationRolePayload>,
) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.internalUser.changeOrganizationRole,
    action.payload,
  );

  if (error) {
    yield put(
      snackBarActions.open({
        message: error.message || "Something went wrong!",
        variant: "error",
      }),
    );
    yield put(internalUserAction.changeOrganizationRoleFailure({error}));
  } else {
    yield put(
      snackBarActions.open({
        message: "Success!",
        variant: "success",
      }),
    );
    yield put(internalUserAction.changeOrganizationRoleSuccess({response}));
    yield put(resetModal());
  }
}

export default function* internalUserSaga() {
  yield takeLatest(
    internalUserActionTypes.GetInternalUserList,
    getInternalUserFlow,
  );
  yield takeLatest(internalUserActionTypes.GetAllCities, getCitiesFlow);
  yield takeLatest(
    internalUserActionTypes.ChangeUserCity,
    changeUserCitiesFlow,
  );
  yield takeLatest(
    internalUserActionTypes.ChangeUserStatus,
    changeUserStatusFlow,
  );
  yield takeLatest(
    internalUserActionTypes.UpdateUserRoles,
    updateUserRolesFlow,
  );
  yield takeLatest(internalUserActionTypes.GetUserCities, getUserCitiesFlow);
  yield takeLatest(internalUserActionTypes.GetUserRoles, getUserRolesFlow);
  yield takeLatest(internalUserActionTypes.GetAllRoles, getRolesFlow);
  yield takeLatest(
    internalUserActionTypes.GetAllUserTypes,
    getAllUserTypesFlow,
  );
  yield takeLatest(
    internalUserActionTypes.ChangeOrganizationRole,
    changeOrganizationRoleFlow,
  );
}
