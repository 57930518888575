import React, {useEffect, useState} from "react";
import RowContainer from "../styles/RowContainer";
import SubTitle from "../styles/SubTitle";
import Title from "../styles/Title";
import MiniTitle from "../styles/MiniTitle";
import getPersonalDetails from "api/business/getPersonalDetail";
import Loading from "app/components/Loading";

const NomineeDetails = ({selectedPayload}) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });
  const [payload, setPayload] = useState({});

  useEffect(() => {
    (async () => {
      const response = await getPersonalDetails({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        personalDetailType: "TAKEOVER_PERSONAL_DETAIL",
      });

      if (response.statusCode === 200) {
        setPayload(response.payload);
        setState(() => ({
          loading: false,
          error: false,
          errorMessage: "",
        }));
      } else {
        setState(() => ({
          loading: false,
          error: true,
          errorMessage: response.error || "Something went wrong",
        }));
      }
    })();
  }, []);

  const details = [
    {
      key: "Nominee Name",
      value: payload.nomineeName,
    },
    {
      key: "Nominee DOB",
      value: payload.nomineeDOB,
    },
    {
      key: "Nominee Address",
      value: payload.nomineeAddress,
    },
    {
      key: "Relation Ship Nominee",
      value: payload.relationshipWithNomineeDisplayValue,
    },
  ];

  if (state.loading) {
    return <Loading height={"20vh"} />;
  }

  return (
    <div>
      <Title>
        Please verify all details and mark checkbox to proceed to next step or
        send back and ask LM to fill correctly
      </Title>

      <RowContainer>
        {details.map(e => {
          return (
            <div key={e.key}>
              <SubTitle>{e.key}</SubTitle>
              <MiniTitle>{e.value}</MiniTitle>
            </div>
          );
        })}
      </RowContainer>
    </div>
  );
};

export default NomineeDetails;
