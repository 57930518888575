import React from "react";

const Exclamation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#B00020">
        <g>
          <g>
            <path
              d="M20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0zm0 3c9.407 0 17 7.593 17 17s-7.593 17-17 17S3 29.407 3 20 10.593 3 20 3zm-.023 5.979c-.828.012-1.489.693-1.477 1.521v12c-.008.541.277 1.044.744 1.317.467.273 1.045.273 1.512 0 .467-.273.752-.776.744-1.317v-12c.006-.406-.153-.796-.44-1.083-.287-.287-.678-.445-1.083-.438zM20 27c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
              transform="translate(-296 -104) translate(296 104) translate(4 4)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Exclamation;
