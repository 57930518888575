import {goldApiService} from "app/infra/plugins/axios";
import {
  FetchWhitelistPayload,
  CreateWhitelistPayload,
  DeleteWhitelistPayload,
  WhitelistResources,
} from "./types";
// import { WhitelistState } from "app/store/users/reducer";

const WHITELIST_ENDPOINTS: WhitelistResources = {
  FETCH_WHITELIST_USER: {
    URL: "/admin/app-config/whitelist",
    METHOD: "GET",
  },
  CREATE_WHITELIST_USER: {
    URL: "/admin/app-config/whitelist",
    METHOD: "POST",
  },
  DELETE_WHITELIST_USER: {
    URL: "/admin/app-config/whitelist",
    METHOD: "DELETE",
  },
};

const whitelistUserApi = {
  fetchWhiteList: async (data: FetchWhitelistPayload) => {
    const {response, error} = await goldApiService({
      resource: WHITELIST_ENDPOINTS.FETCH_WHITELIST_USER,
      options: {
        queryParams: {
          ...data,
        },
      },
    });

    if (error) {
      return {
        error: error,
        isFetching: false,
        whitelistData: [],
      };
    }

    return {
      error: null,
      isFetching: false,
      whitelistData: response,
    };
  },
  createWhitelistUser: async (payload: CreateWhitelistPayload) => {
    const {response, error} = await goldApiService({
      resource: WHITELIST_ENDPOINTS.CREATE_WHITELIST_USER,
      options: {
        data: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error,
        data: null,
      };
    }

    return {
      data: response,
      error: null,
    };
  },
  deleteWhitelistUser: async (payload: DeleteWhitelistPayload) => {
    const {response, error} = await goldApiService({
      resource: WHITELIST_ENDPOINTS.DELETE_WHITELIST_USER,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });

    if (error) {
      return {
        error,
        data: null,
      };
    }

    return {
      data: response,
      error: null,
    };
  },
};

export default whitelistUserApi;
export {WHITELIST_ENDPOINTS};
