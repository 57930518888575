import {RootState} from "../store";

const leadSelectors = {
  getLoading: (state: RootState) => state.lead.loading,
  getErrors: (state: RootState) => state.lead.errors,
  getLeadList: (state: RootState) => state.lead.partnerLeadList,
  getAnalytics: (state: RootState) => state.lead.analyticsDetails,
  getPaginationData: (state: RootState) => state.lead.paginationData,
  getOpenModals: (state: RootState) => state.lead.openModals,
  getLeadUnQualifiedReasons: (state: RootState) => state.lead.leadUnQualifiedReasons,
  getFilters: (state: RootState) => state.lead.filters,
  getListReload: (state: RootState) => state.lead.listReload,
  getParentLeadId: (state: RootState) => state.lead.parentLeadId,
  getCreditDetails: (state: RootState, leadId: number) => state.lead.creditDetails[leadId],
  selectParentLeadDocumentConfigById: (
    state: RootState,
    parentLeadId: number,
    fwcParentLeadMasterDocumentConfigId: number,
  ) => state.lead.parentLeadDocumentConfig[parentLeadId]?.[fwcParentLeadMasterDocumentConfigId],
  selectParentLeadApplicantDocumentConfigKeys: (state: RootState, parentLeadId: number) =>
    Object.entries(state.lead.parentLeadDocumentConfig[parentLeadId] || {})
      .filter(([key, config]) => !config.documentType.includes("GUARANTOR"))
      .map(([key]) => key),
  selectParentLeadGuarantorDocumentConfigKeys: (state: RootState, parentLeadId: number) =>
    Object.entries(state.lead.parentLeadDocumentConfig[parentLeadId] || {})
      .filter(([key, config]) => config.documentType.includes("GUARANTOR"))
      .map(([key]) => key),
  selectUploadedApplicantDocumentsCount: (state: RootState, parentLeadId: number) =>
    Object.values(state.lead.parentLeadDocumentConfig[parentLeadId] || {}).filter(
      config => !config.documentType.includes("GUARANTOR") && config.alreadyUploadedDocCount > 0,
    ).length,
  selectUploadedGuarantorDocumentsCount: (state: RootState, parentLeadId: number) =>
    Object.values(state.lead.parentLeadDocumentConfig[parentLeadId] || {}).filter(
      config => config.documentType.includes("GUARANTOR") && config.alreadyUploadedDocCount > 0,
    ).length,
  selectParentLeadDocument: (
    state: RootState,
    parentLeadId: number,
    fwcParentLeadMasterDocumentConfigId: number,
  ) => state.lead.parentLeadDocuments[parentLeadId]?.[fwcParentLeadMasterDocumentConfigId],
};

export default leadSelectors;
