import React from "react";
import {FilterContainer} from "./styles";
import {WhiteListUserEnum} from "app/enums/whitelistUser";
import {IGButton, IGSelect, IGTextField} from "app/components";

// types
interface SearchFilters {
  id: string;
  whitelistId: string;
  whitelistType: string;
}

export interface FilterProps {
  searchFilters: SearchFilters;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  handleFilter: () => void;
  handleReset: () => void;
}

// component
const Filter = ({
                  searchFilters,
                  setSearchFilters,
                  handleFilter,
                  handleReset,
                }: FilterProps): JSX.Element => {
  const handleFilterChange = (value: string, key: keyof SearchFilters) => {
    setSearchFilters({
      ...searchFilters,
      [key]: value,
    });
  };

  return (
    <FilterContainer>
      <IGTextField
        value={searchFilters.id}
        onChange={e => handleFilterChange(e.target.value, "id")}
        autoFocus
        name="id"
        label="Id"
        type="number"
        autoComplete="off"
      />

      <IGSelect
        name="whiteListType"
        label="Whitelist Type"
        value={searchFilters.whitelistType}
        onChange={(e: any) =>
          handleFilterChange(e.target.value, "whitelistType")
        }
        options={Object.entries(WhiteListUserEnum).map(([key, value]) => ({
          text: key,
          value: value,
        }))}
      />

      <IGTextField
        value={searchFilters.whitelistId}
        onChange={e => handleFilterChange(e.target.value, "whitelistId")}
        name="whiteListId"
        label="Whitelist Id"
        type="email"
        autoComplete="off"
      />

      <IGButton
        disabled={
          !searchFilters.id &&
          !searchFilters.whitelistType &&
          !searchFilters.whitelistId
        }
        onClick={handleFilter}
      >
        Search
      </IGButton>

      <IGButton onClick={handleReset} color="inherit">
        Reset
      </IGButton>
    </FilterContainer>
  );
};

export default Filter;
