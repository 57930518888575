import React, {useMemo} from "react";
import {Grid, Typography, Box} from "@mui/material";
import {CrLandDocumentDetailDto} from "app/models/activeLoans/activeLoans.model";

interface AgriDocumentLabelProps {
  agriDocDetails: CrLandDocumentDetailDto;
}
const AgriDocumentLabel = ({agriDocDetails}: AgriDocumentLabelProps) => {
  const agriDocumentMapping = useMemo(() => {
    if (agriDocDetails) {
      const {
        ownerName,
        borrowerOwnerRelationship,
        surveyNumber,
        pattaNumber,
        village,
        tehsil,
        city,
      } = agriDocDetails ?? {};
      return [
        {
          label: "Owner name",
          value: ownerName,
        },
        // {
        //   label: "Owner aadhar",
        //   value: ownerAadhaar,
        // },
        // {
        //   label: "Owner pan",
        //   value: ownerPan,
        // },
        // {
        //   label: "District",
        //   value: ownerPan,
        // },
        {
          label: "Taluka/Mandal/Tehsil",
          value: tehsil,
        },
        {
          label: "Village",
          value: village,
        },

        {
          label: "Survey number",
          value: surveyNumber,
        },
        {
          label: "khata no/ Patta no",
          value: pattaNumber,
        },
        {
          label: "Borrower Owner Relation",
          value: borrowerOwnerRelationship,
        },
      ];
    }
  }, [agriDocDetails]);
  return (
    <Box
      mt={2}
      p={1}
      borderRadius={3}
      border="1px solid rgba(0, 0, 0, 0.12)"
      bgcolor="#fffbf1"
    >
      <Grid mt={1} container spacing={2}>
        {agriDocumentMapping?.map((item) => (
          <Grid item xs={6} md={4} lg={4} flexDirection="column">
            <Typography fontWeight={600} variant="body1" color="gray">
              {item?.label}
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              {item?.value || "N/A"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgriDocumentLabel;
