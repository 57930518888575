const PayoutAccountVerificationStatusEnum = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

const PayoutAccountVerificationFailedReasonEnum = {
  ACCOUNT_INACTIVE: "ACCOUNT_INACTIVE",
  NAME_MISMATCH: "NAME_MISMATCH",
};

const AddBeneficiaryDocumentTypes = {
  CUSTOMER_LOAN_BANK_CHEQUE: "CUSTOMER_LOAN_BANK_CHEQUE",
  CUSTOMER_TAKEOVER_BANK_CHEQUE: "CUSTOMER_TAKEOVER_BANK_CHEQUE",
  SM_CUSTOMER_LOAN_BANK_CHEQUE: "SM_CUSTOMER_LOAN_BANK_CHEQUE",
  BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT:
    "BANK_ACCOUNT_NAME_MISMATCH_PROOF_DOCUMENT",
};

const UserRelationshipsEnum = {
  SELF: "Self",
  SPOUSE: "Spouse",
  CHILD: "Child",
  PARENT: "Parent",
  OTHER_BBNMR: "Other",
};

const JobTypeEnum = {
  ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL:
    "ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL",
  ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL:
    "ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL",
  ACCOUNT_GOLD_LOAN_PAYMENT: "ACCOUNT_GOLD_LOAN_PAYMENT",
  ACCOUNT_TAKEOVER_PAYMENT: "ACCOUNT_TAKEOVER_PAYMENT",
  ACCOUNT_PERSONAL_LOAN_PAYMENT: "ACCOUNT_PERSONAL_LOAN_PAYMENT",
  ACCOUNT_ADD_BENEFICIARY_SM_LOAN_BANK_DETAIL:
    "ACCOUNT_ADD_BENEFICIARY_SM_LOAN_BANK_DETAIL",
  ACCOUNT_RENEW_REBOOK_CLOSURE_PAYMENT: "ACCOUNT_RENEW_REBOOK_CLOSURE_PAYMENT",
};

const BankDetailTypesEnum = {
  ACCOUNT_ADD_BENEFICIARY_TAKEOVER_BANK_DETAIL: "TAKEOVER_BANK_DETAIL",
  ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL: "LOAN_BANK_DETAIL",
  ACCOUNT_ADD_BENEFICIARY_SM_LOAN_BANK_DETAIL: "SM_LOAN_BANK_DETAIL",
  ACCOUNT_GOLD_LOAN_PAYMENT: "LOAN_BANK_DETAIL",
  ACCOUNT_TAKEOVER_PAYMENT: "TAKEOVER_BANK_DETAIL",
};

const autoDisbursalGLJobTypeEnum = {
  CREATED: "CREATED",
  GL_CUSTOMER_DISBURSAL_DONE: "GL_CUSTOMER_DISBURSAL_DONE",
  COMPLETED: "COMPLETED",
};

const goldLoanPaymentDetailTypeEnum = {
  DISBURSALTOGLCUSTOMER: "GOLD_LOAN_PAYMENT_DETAIL",
  REIMBURSETOODACCOUNT: "GOLD_LOAN_LENDER_TO_INDIAGOLD_ACCOUNT",
};

const loanBranchTypeModelEnum = {
  CUSTODIAN: "CUSTODIAN",
};

const customerPersonalDetailsEnum = {
  LOAN_PERSONAL_DETAIL: "LOAN_PERSONAL_DETAIL",
  TAKEOVER_PERSONAL_DETAIL: "TAKEOVER_PERSONAL_DETAIL",
  GUARANTOR_PERSONAL_DETAIL: "GUARANTOR_PERSONAL_DETAIL",
};

const PaymentsJobTypeEnum = {
  ACCOUNT_GOLD_LOAN_PAYMENT: "ACCOUNT_GOLD_LOAN_PAYMENT",
  ACCOUNT_TAKEOVER_PAYMENT: "ACCOUNT_TAKEOVER_PAYMENT",
  ACCOUNT_PERSONAL_LOAN_PAYMENT: "ACCOUNT_PERSONAL_LOAN_PAYMENT",
  ACCOUNT_RENEW_REBOOK_CLOSURE_PAYMENT: "ACCOUNT_RENEW_REBOOK_CLOSURE_PAYMENT",
};

const JobStatusCompletionEnum = {
  COMPLETED: "COMPLETED",
};

enum AccountsRenewRebookTransactionsEnum {
  GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER = "GL_NON_PRINCIPAL_PAYED_BY_CUSTOMER",
  PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER = "PL_NON_PRINCIPAL_PAYED_BY_CUSTOMER",
  GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG = "GL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG",
  PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG = "PL_NON_PRINCIPAL_WAIVED_OFF_FROM_IG",
  GL_NON_PRINCIPAL_ADJUSTED_FROM_IG = "GL_NON_PRINCIPAL_ADJUSTED_FROM_IG",
  PL_NON_PRINCIPAL_ADJUSTED_FROM_IG = "PL_NON_PRINCIPAL_ADJUSTED_FROM_IG",
  PL_PRINCIPAL_SETTLE_FROM_PL = "PL_PRINCIPAL_SETTLE_FROM_PL",
  PL_PRINCIPAL_SETTLE_FROM_GL = "PL_PRINCIPAL_SETTLE_FROM_GL",
  GL_PRINCIPAL_SETTLE_FROM_PL = "GL_PRINCIPAL_SETTLE_FROM_PL",
  GL_PRINCIPAL_SETTLE_FROM_GL = "GL_PRINCIPAL_SETTLE_FROM_GL",
  GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL = "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL",
  GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL = "GL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL",
  PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL = "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_PL",
  PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL = "PL_ADJUSTED_AMOUNT_RECOVERY_FROM_GL",
  GOLD_LOAN_PAYMENT_DETAIL = "GOLD_LOAN_PAYMENT_DETAIL",
  PERSONAL_LOAN_PAYMENT_DETAIL = "PERSONAL_LOAN_PAYMENT_DETAIL",
}

export {
  PayoutAccountVerificationStatusEnum,
  PayoutAccountVerificationFailedReasonEnum,
  AddBeneficiaryDocumentTypes,
  UserRelationshipsEnum,
  BankDetailTypesEnum,
  JobTypeEnum,
  autoDisbursalGLJobTypeEnum,
  goldLoanPaymentDetailTypeEnum,
  loanBranchTypeModelEnum,
  customerPersonalDetailsEnum,
  PaymentsJobTypeEnum,
  JobStatusCompletionEnum,
  AccountsRenewRebookTransactionsEnum,
};
