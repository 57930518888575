import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const getDocument = async ({accessToken, crId, documentType}) => {
  const updatedList = documentType
    .map((e) => `documentTypeList=${e}`)
    .join("&");

  try {
    const response = await axios.get(
      `${getApiUrl()}/admin/business/v2/cr/${crId}/document-list?${updatedList}`,
      {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "application-json",
        },
      },
    );

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
      error: null,
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default getDocument;
