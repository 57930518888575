const scoreRanges = [
  {min: 0, max: 0, text: "Calculating...", color: "initial"},
  {min: 1, max: 420, text: "LOW", color: "#EB534F"},
  {min: 421, max: 540, text: "GOOD", color: "#EE8232"},
  {min: 541, max: 660, text: "BEST", color: "#F2A73B"},
  {min: 661, max: 780, text: "GREAT", color: "#C1D243"},
  {min: 781, max: 900, text: "BEST", color: "#67BB6A"},
];

export const getCibilScoreText = (
  score: number,
): { text: string; color: string } => {
  // Find the appropriate range
  const range = scoreRanges.find((r) => score >= r.min && score <= r.max);

  // Return the found range or a default value
  return range
    ? {text: range.text, color: range.color}
    : {text: "Calculating...", color: "initial"};
};

export const getCibilRotation = (score: number): number => {
  const minScore = 300;
  const maxScore = 900;
  const minRotation = 0;
  const maxRotation = 270;

  const clampedScore = Math.max(minScore, Math.min(score, maxScore));
  const rotation =
    minRotation +
    ((clampedScore - minScore) / (maxScore - minScore)) *
      (maxRotation - minRotation);

  return rotation;
};
