import React from "react";
import Container from "./styles/Container";
import CapitalizedText from "./styles/CapitalizedText";
import {GoldenText} from "./styles/GoldenUnderline";
import RejectIcon from "@mui/icons-material/Cancel";
import ValidIcon from "@mui/icons-material/VerifiedUser";

import {
  checkFlagEnum,
  recommendationStatusEnum,
  ltvListEnum,
} from "app/enums/gold_loan/recommendation";
import {useIGTranslation} from "app/infra/plugins/i18n";

const ltvList = [
  ltvListEnum.SHIVALIK,
  ltvListEnum.PIRAMAL,
  ltvListEnum.FINCARE,
];

function CreditRecommendation({
  evaluatedRule = null,
  ltvRecommendations = null,
  creditReportHtml = null,
}) {
  const {t} = useIGTranslation();

  const getRecommendationStatus = () => {
    if (evaluatedRule && evaluatedRule?.status) {
      switch (evaluatedRule.status) {
        case recommendationStatusEnum.REJECT:
          return (
            <>
              <RejectIcon color={"Error"}/> &nbsp;
              <span style={{color: "red"}}>{t("REJECT")}</span>
            </>
          );
        case recommendationStatusEnum.APPROVE:
          return (
            <>
              <ValidIcon style={{color: "green"}}/> &nbsp;
              <span style={{color: "green"}}>{t("ELIGIBLE")}</span>
            </>
          );
        default:
          return <span>{t("ACTIVE_LOANS.CHECK_MANUALLY")}</span>;
      }
    }
  };

  const getFlagForStatus = status => {
    switch (status) {
      case checkFlagEnum.FAILURE:
        return <span style={{color: "red"}}>{t("FAIL")}</span>;
      case checkFlagEnum.SUCCESS:
        return <span style={{color: "green"}}>{t("PASS")}</span>;
      default:
        return <span>{t("ACTIVE_LOANS.CHECK_MANUALLY")}</span>;
    }
  };

  return (
    <>
      {evaluatedRule?.conditions.map(item => {
        return (
          <Container>
            {item?.conditionName}
            <span>{getFlagForStatus(item.status)}</span>
          </Container>
        );
      })}

      <Container>
        {t("ACTIVE_LOANS.LOAN_RECOMMENDATION")}
        <span>{getRecommendationStatus()}</span>
      </Container>

      <Container/>
      <Container/>
      {ltvRecommendations && (
        <>
          <Container>
            <GoldenText>{t("ACTIVE_LOANS.LTV_RECOMMENDATION")}</GoldenText>
          </Container>
          {ltvRecommendations.map(item => (
            <div className="d-flex justify-content-between">
              <CapitalizedText>{item.lender.toLowerCase()}</CapitalizedText>
              <CapitalizedText>
                {item.ltvPercentage !== null
                  ? `${item.ltvPercentage}%`
                  : t("ACTIVE_LOANS.NOT_ELIGIBLE")}
              </CapitalizedText>
            </div>
          ))}
          {//to show the ltv not sent from backend as undefined
            ltvList.map(item => {
              if (ltvRecommendations.find(
                e => e.lender === item) === undefined
              ) {
                return (
                  <div className="d-flex justify-content-between">
                    <CapitalizedText>{item.toLowerCase()}</CapitalizedText>
                    <CapitalizedText>{t("ACTIVE_LOANS.NOT_ELIGIBLE")}</CapitalizedText>
                  </div>
                );
              }
              return "";
            })}
        </>
      )}
    </>
  );
}

export default CreditRecommendation;
