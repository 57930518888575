import React, {useState, useCallback, useRef, useEffect} from "react";

import styles from "../styles";
import getGoldLoanDetail from "api/business/getGoldLoanDetail";
import getPledgeCard from "api/business/getPledgeCard";
import {CircularProgress} from "@mui/material";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";

const PersonalLoanTaken = ({selectedPayload, finalLtv, isBackMovement}) => {
  const classes = styles();

  const [state, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      goldLoan: {},
      pledgeCard: {},
      personalLoan: {},
      personalLoanPledgeCard: {},
    },
  });

  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    const needPledgeCard = isBackMovement;
    try {
      const [
        goldLoanResponse,
        getPledgeCardResponse,
        personalLOanPledgeCardResponse,
        personalLoanDetailResponse,
      ] = await Promise.all([
        getGoldLoanDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "GOLD_LOAN",
        }),
        needPledgeCard
          ? getPledgeCard({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "GOLD_LOAN",
            })
          : null,
        needPledgeCard
          ? getPledgeCard({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
              loanType: "PERSONAL_LOAN",
            })
          : null,
        getGoldLoanDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "PERSONAL_LOAN",
        }),
      ]);

      if (
        goldLoanResponse.statusCode === 200 && needPledgeCard
          ? getPledgeCardResponse.statusCode === 200
          : true && needPledgeCard
          ? personalLOanPledgeCardResponse.statusCode === 200
          : true && personalLoanDetailResponse.statusCode === 200
      ) {
        setStates({
          loading: false,
          error: false,
          payload: {
            goldLoan: goldLoanResponse.payload,
            pledgeCard: getPledgeCardResponse?.payload,
            personalLoan: personalLoanDetailResponse.payload,
            personalLoanPledgeCard: personalLOanPledgeCardResponse?.payload,
          },
        });
      } else {
        setStates({
          loading: false,
          error: true,
          errorMessage:
            goldLoanResponse.error ||
            getPledgeCardResponse.error ||
            personalLOanPledgeCardResponse.error ||
            personalLoanDetailResponse.error,
          payload: {
            goldLoan: {},
            pledgeCard: {},
            personalLoan: {},
            personalLoanPledgeCard: {},
          },
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: {
          goldLoan: {},
          pledgeCard: {},
          personalLoan: {},
          personalLoanPledgeCard: {},
        },
      });
    }
  }, [selectedPayload.id, isBackMovement]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails, selectedPayload.id]);

  if (state.loading) {
    return <CircularProgress />;
  }
  if (state.error) {
    return <div>{state.errorMessage.toString()}</div>;
  }

  const {goldLoan, personalLoan} = state.payload;

  return (
    <div>
      {" "}
      <div>
        {/* <div className={classes.textContainer}>
          <div className={classes.text}>TOTAL LOAN VALUE / LTV</div>

          <div className={classes.subText}>
            {`${numberWithCurrencyAndComma(
              goldLoan?.totalLoanAmount + personalLoan?.totalLoanAmount,
            )} / ${finalLtv}%`}
          </div>
        </div> */}

        <div className={classes.textContainer}>
          <div className={classes.text}>GOLD LOAN INFORMATION</div>

          <div className={classes.subText}>
            {"Loan Account Number: "}

            <span
              style={{
                color: "#ce9f29",
              }}
              className={classes.link}
            >
              {goldLoan?.loanAccountNumber}
            </span>
          </div>

          <div
            className={classes.subText}
          >{`Amount Disbursed: ${numberWithCurrencyAndComma(
            goldLoan?.totalLoanAmount,
          )}`}</div>

          <div
            className={classes.subText}
          >{`Lender: ${goldLoan?.loanVendorName || "N/A"}`}</div>

          {/* <div
            className={classes.subText}
          >{`Scheme: ${goldLoan.nucleusSchemeCode}`}</div> */}

          <div
            className={classes.subText}
          >{`Amount Transferred By: ${goldLoan?.transferredByAgentName ||
            "N/A"}`}</div>

          {isBackMovement && (
            <a
              href={state.payload.pledgeCard.link}
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"View Pledge Card"}
            </a>
          )}
        </div>

        <div className={classes.textContainer}>
          <div className={classes.text}>PERSONAL LOAN INFORMATION</div>

          <div className={classes.subText}>
            {"Loan Account Number: "}
            <span
              style={{
                color: "#ce9f29",
              }}
              className={classes.link}
            >
              {personalLoan?.loanAccountNumber}
            </span>
          </div>

          <div
            className={classes.subText}
          >{`Amount Disbursed: ${numberWithCurrencyAndComma(
            personalLoan?.totalLoanAmount,
          )}`}</div>

          <div
            className={classes.subText}
          >{`Lender: ${personalLoan?.loanVendorName || "N/A"}`}</div>

          <div
            className={classes.subText}
          >{`Amount Transferred By: ${personalLoan?.transferredByAgentName ||
            "N/A"}`}</div>

          {isBackMovement && (
            <a
              href={state.payload.personalLoanPledgeCard.link}
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"View Sanction Letter"}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalLoanTaken;
