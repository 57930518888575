import {createAction} from "@reduxjs/toolkit";
import {
  GetCallingHistoryPayload,
  GetRoleBasedCitiesPayload,
  SchedulingResponse,
  SchedulingViews,
} from "app/infra/services/api/scheduling/types";

export const schedulingActionTypes = {
  GetCities: "[Scheduling] Get Cities",
  GetCitiesSuccess: "[Scheduling] Get Cities Success",
  GetCitiesFailure: "[Scheduling] Get Cities Failure",
  GetRoleBasedCities: "[Scheduling] Get Agent Availability Cities",
  GetRoleBasedCitiesSuccess:
    "[Scheduling] Get Agent Availability Cities Success",
  GetRoleBasedCitiesFailure:
    "[Scheduling] Get Agent Availability Cities Failure",
  GetLenderList: "[Scheduling] Get Lender List",
  GetLenderListSuccess: "[Scheduling] Get Lender List Success",
  GetLenderListFailure: "[Scheduling] Get Lender List Failure",
  ChangeCurrentView: "[Scheduling] Change Current View",
  GetCallingHistory: "[OpenCR] Get Calling History",
  GetCallingHistorySuccess: "[OpenCR] Get Calling History Success",
  GetCallingHistoryFailure: "[OpenCR] Get Calling History Failure",
};

export const schedulingActions = {
  getCities: createAction(schedulingActionTypes.GetCities),
  getCitiesSuccess: createAction<SchedulingResponse>(
    schedulingActionTypes.GetCitiesSuccess,
  ),
  getCitiesFailure: createAction<SchedulingResponse>(
    schedulingActionTypes.GetCitiesFailure,
  ),
  changeCurrentView: createAction<SchedulingViews>(
    schedulingActionTypes.ChangeCurrentView,
  ),
  getRoleBasedCities: createAction<GetRoleBasedCitiesPayload>(
    schedulingActionTypes.GetRoleBasedCities,
  ),
  getRoleBasedCitiesSuccess: createAction<SchedulingResponse>(
    schedulingActionTypes.GetRoleBasedCitiesSuccess,
  ),
  getRoleBasedCitiesFailure: createAction<SchedulingResponse>(
    schedulingActionTypes.GetRoleBasedCitiesFailure,
  ),
  getLenderList: createAction(schedulingActionTypes.GetLenderList),
  getLenderListSuccess: createAction<SchedulingResponse>(
    schedulingActionTypes.GetLenderListSuccess,
  ),
  getLenderListFailure: createAction<SchedulingResponse>(
    schedulingActionTypes.GetLenderListFailure,
  ),
  getCallingHistory: createAction<GetCallingHistoryPayload>(
    schedulingActionTypes.GetCallingHistory,
  ),
  getCallingHistorySuccess: createAction<SchedulingResponse>(
    schedulingActionTypes.GetCallingHistorySuccess,
  ),
  getCallingHistoryFailure: createAction<SchedulingResponse>(
    schedulingActionTypes.GetCallingHistoryFailure,
  ),
};
