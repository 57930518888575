import {goldApiService} from "app/infra/plugins/axios";
import {RateChangeApi, RateChangeApiResources} from "./rateChange.types";

const RateChangeApiEndpoints: RateChangeApiResources = {
  GET_LOAN_RATE_CHANGE_HISTORY: {
    URL: "/admin/service-desk/loan/:parentLoanId/roi-history",
    METHOD: "GET",
  },
  GET_LOAN_CURRENT_PLAN: {
    URL: "/admin/service-desk/rate-change/request/:serviceRequestId/loan-plan",
    METHOD: "GET",
  },
  GET_OFFER_VERSION_HISTORY: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/offer-history",
    METHOD: "GET",
  },
  GET_CUSTOMER_REQUIREMENT_AND_COMPETITOR_DETAILS: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/customer-requirement",
    METHOD: "GET",
  },
  GET_CURRENT_OFFER_DETAIL: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/offer-detail",
    METHOD: "GET",
  },
  GET_CURRENT_TASK_STATUS_DETAIL: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/offer-task-detail",
    METHOD: "GET",
  },
  UPDATE_CUSTOMER_REQUIREMENTS_BY_CST: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/cst-customer-requirement",
    METHOD: "PUT",
  },
  SHARE_CM_OFFER_WITH_CUSTOMER: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/share-customer-offer",
    METHOD: "GET",
  },
  UPDATE_OFFER_BY_CM: {
    URL:
      "/admin/service-desk/rate-change/request/:serviceRequestId/cm-offer-detail",
    METHOD: "PUT",
  },
};

const rateChangeApi: RateChangeApi = {
  getLoanRateChangeHistory: async ({parentLoanId}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.GET_LOAN_RATE_CHANGE_HISTORY,
      options: {
        pathVars: {parentLoanId},
      },
    });
    return {response, error};
  },
  getLoanCurrentPlan: async ({serviceRequestId}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.GET_LOAN_CURRENT_PLAN,
      options: {
        pathVars: {serviceRequestId},
      },
    });
    return {response, error};
  },
  getOfferVersionHistory: async ({serviceRequestId, sharedBy}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.GET_OFFER_VERSION_HISTORY,
      options: {
        pathVars: {serviceRequestId},
        queryParams: {
          sharedBy,
        },
      },
    });
    return {response, error};
  },
  getCustomerRequirementAndCompetitorDetails: async ({serviceRequestId}) => {
    const {response, error} = await goldApiService({
      resource:
        RateChangeApiEndpoints.GET_CUSTOMER_REQUIREMENT_AND_COMPETITOR_DETAILS,
      options: {
        pathVars: {serviceRequestId},
      },
    });
    return {response, error};
  },
  getCurrentOfferDetail: async ({serviceRequestId}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.GET_CURRENT_OFFER_DETAIL,
      options: {
        pathVars: {serviceRequestId},
      },
    });
    return {response, error};
  },
  getCurrentTaskStatusDetail: async ({serviceRequestId, sharedBy}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.GET_CURRENT_TASK_STATUS_DETAIL,
      options: {
        pathVars: {serviceRequestId},
        queryParams: {
          sharedBy,
        },
      },
    });
    return {response, error};
  },
  updateCustomerRequirementsByCst: async ({serviceRequestId, formData}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.UPDATE_CUSTOMER_REQUIREMENTS_BY_CST,
      options: {
        pathVars: {serviceRequestId},
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {response, error};
  },
  shareCmOfferWithCustomer: async ({serviceRequestId}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.SHARE_CM_OFFER_WITH_CUSTOMER,
      options: {
        pathVars: {serviceRequestId},
      },
    });
    return {response, error};
  },
  updateOfferByCm: async ({serviceRequestId, ...rest}) => {
    const {response, error} = await goldApiService({
      resource: RateChangeApiEndpoints.UPDATE_OFFER_BY_CM,
      options: {
        pathVars: {serviceRequestId},
        data: rest,
      },
    });
    return {response, error};
  },
};

export default rateChangeApi;
export {RateChangeApiEndpoints};
