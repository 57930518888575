import styled from "@emotion/styled";

const DocumentWrapper = styled.div`
  padding-top: 1rem;
  width: 45%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
`;

export default DocumentWrapper;
