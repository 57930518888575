import styled from "@emotion/styled";

const AadhaarNumberWrapper = styled.div`
  font-size: 2rem;
  font-weight: 500;
  line-height: 0.75;
  letter-spacing: 0.23px;
`;

export default AadhaarNumberWrapper;
