import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface SnackbarState {
  message: string;
  open: boolean;
  variant: "success" | "error" | "info" | "warning";
  autoHideDuration?: number | null;
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  variant: "info",
  autoHideDuration: 5000,
};

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    open: (state, action) => {
      const {message, variant, autoHideDuration} = action.payload;
      let calculatedAutoDuration: null | number = 5000;
      if (autoHideDuration === null) {
        calculatedAutoDuration = null;
      } else if (autoHideDuration) {
        calculatedAutoDuration = autoHideDuration;
      }
      state.open = true;
      state.message = message;
      state.variant = variant || "info";
      state.autoHideDuration = calculatedAutoDuration;
    },
    close: state => {
      state.open = false;
      state.message = "";
    },
  },
});

// Actions
const snackBarActions = snackBarSlice.actions;

// Selectors
const snackbarSelectors = {
  getMessage: (state: RootState) => state.snackbar.message,
  getOpen: (state: RootState) => state.snackbar.open,
  getVariant: (state: RootState) => state.snackbar.variant,
  getAutoHideDuration: (state: RootState) => state.snackbar.autoHideDuration,
};

export default snackBarSlice.reducer;
export {snackBarSlice, snackbarSelectors, snackBarActions};
