import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "app/infra/services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import cifDetailsActions from "./cifValidation.actions";
import {cifDetailsActionTypes} from "./cifValidation.actions";
import {snackBarActions} from "app/store/snackbar";
import {
  CifValidationResponse,
  GetDocumentListByCrIdPayload,
} from "./cifValidation.types";

const {getCifDetailsFailure, getCifDetailsSuccess} = cifDetailsActions;

function* generalErrorFlow(action: PayloadAction<CifValidationResponse>) {
  yield put(
    snackBarActions.open({
      message: action.payload.error?.message || "Something went wrong!!",
      variant: "error",
    }),
  );
}

function* getCifDetailsFlow(action: PayloadAction<GetDocumentListByCrIdPayload>) {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.cifDetails.getCifDetails,
    action.payload,
  );
  if (error) {
    yield put(getCifDetailsFailure({response: null, error}));
  } else {
    yield put(getCifDetailsSuccess({response, error: null}));
  }
}

export default function* cifDetailsSaga() {
  yield takeLatest(cifDetailsActionTypes.GetCifDetails, getCifDetailsFlow);

  yield takeLatest(
    cifDetailsActionTypes.GetCifDetailsFailure,
    generalErrorFlow,
  );
}
