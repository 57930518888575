import {makeStyles} from "@mui/styles";
import styled from "@emotion/styled";
import {Box} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: "40px",
    color: "black",
  },
  midSubText: {
    fontSize: "14px",
    lineHeight: "1.71",
    letterSpacing: "0.1px",
  },
  subText: {
    fontSize: "12px",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
  },
  subHeader: {
    fontSize: "12px",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "rgba(18, 18, 18, 0.5)",
  },
  // profile info styles.
  profileImageContainer: {
    height: "84px",
    width: "84px",
    background: "blue",
  },
  profileInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginLeft: "24px",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "row",
  },
  profileName: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "1.2",
    letterSpacing: "0.15px",
  },
  mainProfileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  addressContainer: {
    width: "25%",
  },

  //   seperator
  seperator: {
    height: "1px",
    width: "100%",
    boxShadow: "inset  0 -0.5px 0 0 #e2e2e2",
    marginTop: "40px",
    marginBottom: "24px",
  },

  //   filters
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  loanIdSelect: {
    marginLeft: "8px",
  },
  navIcon: {
    cursor: "pointer",
  },
}));

export default useStyles;

export const OverduePaymentList = styled(Box)`
  border: 1px solid #0000001f;
  border-radius: 4px;
  
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    &:not(:last-child) {
      border-bottom: 1px solid #0000001f;
    }
  }
`;
