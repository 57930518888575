import {createAction} from "@reduxjs/toolkit";
import {
  PersonalDetailsResponse,
  GetPersonalDetailsPayload,
  GetAddressDetailsPayload,
  UpdatePersonalDetailsPayload,
  GetKycDocumentPayload,
  GetGuarantorPersonalDetailsPayload,
  GetGuarantorAddressDetailsPayload,
} from "./personalDetails.types";

const personalDetailsActionTypes = {
  GetPersonalDetails: "[Personal Details] Get Personal Details",
  GetPersonalDetailsSuccess: "[Personal Details] Get Personal Details Success",
  GetPersonalDetailsFailure: "[Personal Details] Get Personal Details Failure",
  GetAddressDetails: "[Personal Details] Get Address Details",
  GetAddressDetailsSuccess: "[Personal Details] Get Address Details Success",
  GetAddressDetailsFailure: "[Personal Details] Get Address Details Failure",
  UpdatePersonalDetails: "[Personal Details] Update Personal Details",
  UpdatePersonalDetailsSuccess:
    "[Personal Details] Update Personal Details Success",
  UpdatePersonalDetailsFailure:
    "[Personal Details] Update Personal Details Failure",
  GetDocumentMasterList: "[Personal Details] Get Documents Master List",
  GetDocumentMasterListSuccess:
    "[Personal Details] Get Documents Master List Success",
  GetDocumentMasterListFailure:
    "[Personal Details] Get Documents Master List Failure",
  GetKycDocuments: "[Personal Details] Get Kyc Documents",
  GetKycDocumentsSuccess: "[Personal Details] Get Kyc Documents Success",
  GetKycDocumentsFailure: "[Personal Details] Get Kyc Documents Failure",
  GetGuarantorPersonalDetails:
    "[Personal Details] Get Guarantor Personal Details",
  GetGuarantorPersonalSuccess:
    "[Personal Details] Get Guarantor Personal Details Success",
  GetGuarantorPersonalFailure:
    "[Personal Details] Get Guarantor Personal Details Failure",
  GetGuarantorAddressDetails:
    "[Personal Details] Get Guarantor Address Details",
  GetGuarantorAddressSuccess:
    "[Personal Details] Get Guarantor Address Details Success",
  GetGuarantorAddressFailure:
    "[Personal Details] Get Guarantor Address Details Failure",
};

const personalDetailsActions = {
  getPersonalDetails: createAction<GetPersonalDetailsPayload>(
    personalDetailsActionTypes.GetPersonalDetails,
  ),
  getPersonalDetailsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetPersonalDetailsSuccess,
  ),
  getPersonalDetailsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetPersonalDetailsFailure,
  ),
  getAddressDetails: createAction<GetAddressDetailsPayload>(
    personalDetailsActionTypes.GetAddressDetails,
  ),
  getAddressDetailsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetAddressDetailsSuccess,
  ),
  getAddressDetailsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetAddressDetailsFailure,
  ),
  updatePersonalDetails: createAction<{
    crId: number;
    crJobId: number;
    payload: UpdatePersonalDetailsPayload;
  }>(personalDetailsActionTypes.UpdatePersonalDetails),
  updatePersonalDetailsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.UpdatePersonalDetailsSuccess,
  ),
  updatePersonalDetailsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.UpdatePersonalDetailsFailure,
  ),
  getDocumentsMasterList: createAction(
    personalDetailsActionTypes.GetDocumentMasterList,
  ),
  getDocumentsMasterListSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetDocumentMasterListSuccess,
  ),
  getDocumentsMasterListFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetDocumentMasterListFailure,
  ),
  getKycDocuments: createAction<GetKycDocumentPayload>(
    personalDetailsActionTypes.GetKycDocuments,
  ),
  getKycDocumentsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetKycDocumentsSuccess,
  ),
  getKycDocumentsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetKycDocumentsFailure,
  ),

  getGuarantorPersonalDetails: createAction<GetGuarantorPersonalDetailsPayload>(
    personalDetailsActionTypes.GetGuarantorPersonalDetails,
  ),
  getGuarantorPersonalDetailsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetGuarantorPersonalSuccess,
  ),
  getGuarantorPersonalDetailsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetGuarantorPersonalFailure,
  ),

  getGuarantorAddressDetails: createAction<GetGuarantorAddressDetailsPayload>(
    personalDetailsActionTypes.GetGuarantorAddressDetails,
  ),
  getGuarantorAddressDetailsSuccess: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetGuarantorAddressSuccess,
  ),
  getGuarantorAddressDetailsFailure: createAction<PersonalDetailsResponse>(
    personalDetailsActionTypes.GetGuarantorAddressFailure,
  ),
};

export default personalDetailsActions;

export {personalDetailsActionTypes};
