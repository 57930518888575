

import React, {FC, useEffect, useState} from "react";
import {IGButton, IGDialog, IGLoading, IGSelect, IGTextField} from "app/components";
import IGModal from "app/components/Modal/IGModal";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {Grid} from "@mui/material";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {LM_USER_API_ROLES} from "app/enums/scheduling";

interface ManageJobDialogProps {
  open: boolean
  crId: number
}

const ManageJobDialog: FC<ManageJobDialogProps> = ({
  open,
  crId,
}) => {

  const dispatch = useAppDispatch();

  const [selectedAgentId, setSelectedAgentId] = useState<number>(0);
  const [remark, setRemark] = useState("");
  const [actionAfterRemark, setActionAfterRemark] = useState("");

  const {getLMJobDetails: getLMJobDetailsLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );
  const selectedCR = useAppSelector(activeLoansSelectors.getSelectedCR);
  const lmUserList = useAppSelector(activeLoansSelectors.getLMUserList);
  const lmJobDetails = useAppSelector(activeLoansSelectors.getLMJobDetails);
  const {
    assignCurrentLM: assignCurrentLMLoading,
    removeCurrentLM: removeCurrentLMLoading,
    closeJob: closeJobLoading,
  } = useAppSelector(activeLoansSelectors.getLoading);
  const {remarkModal} = useAppSelector(activeLoansSelectors.getOpenModals);

  const onClose = () => {
    dispatch(toggleModals({
      type: "manageJob",
      value: false,
    }));
  };

  useEffect(() => {
    if (open) {
      dispatch(activeLoansActions.getLMJobDetails(crId));
    }
  }, [open, crId]);

  useEffect(() => {
    if (!lmJobDetails[0] || !(selectedCR)) return;
    if (!(lmJobDetails[0].agentId)) {
      const {roleName} = lmJobDetails[0];
      if (roleName && selectedCR.cityId) {
        dispatch(activeLoansActions.getLMUsers({
          roleName: roleName === "LOAN_MANAGER" ? LM_USER_API_ROLES.join(",") : roleName,
          cityId: selectedCR.cityId,
        }));
      }
    }
  }, [lmJobDetails]);

  useEffect(() => {
    if (lmUserList.length > 0) {
      setSelectedAgentId(lmUserList[0].id);
    }
  }, [lmUserList]);

  const onAssignCurrentLMHandler = () => {
    dispatch(activeLoansActions.assignCurrentLM({
      crJobId: lmJobDetails[0].crJobId,
      agentId: selectedAgentId,
      remark: "",
    }));
  };

  const toggleRemarkDialog = (open: boolean) => {
    dispatch(toggleModals({
      type: "remarkModal",
      value: open,
    }));
  };

  const onRemoveCurrentLMHandler = () => {
    toggleRemarkDialog(true);
    setActionAfterRemark("remove");
    setRemark("");
  };

  const closeJobHandler = () => {
    toggleRemarkDialog(true);
    setActionAfterRemark("closeJob");
    setRemark("");
  };

  const onRemarkSubmit = () => {
    if (actionAfterRemark === "remove") {
      dispatch(activeLoansActions.removeCurrentLM({
        crJobId: lmJobDetails[0].crJobId,
        agentId: selectedAgentId,
        remark: remark,
      }));
    }
    if (actionAfterRemark === "closeJob" && selectedCR) {
      dispatch(activeLoansActions.closeLMJob({
        crJobId: lmJobDetails[0].crJobId,
        crId: selectedCR.id,
        remark: remark,
      }));
    }
  };

  return (
    <>
      <IGModal
        open={open}
        onClose={onClose}
        title="Manage Job"
      >
        {getLMJobDetailsLoading
          ? <IGLoading height="135px"/>
          : (
            <>
              {lmJobDetails.length === 0 ? (
                <span>No Job Found</span>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <IGButton onClick={closeJobHandler}>
                      Cancel Job
                    </IGButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <IGTextField
                          disabled={true}
                          name="jobId"
                          label="Job ID"
                          value={lmJobDetails[0].crJobId}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <IGTextField
                          disabled={true}
                          name="jobType"
                          label="Job Type"
                          value={lmJobDetails[0].jobType?.replaceAll("_", " ") || ""}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        {lmJobDetails[0].agentId ? (
                          <IGTextField
                            disabled={true}
                            name="agentName"
                            label="Agent Name"
                            value={lmJobDetails[0].agentName}
                          />
                        ) : (
                          <IGSelect<number>
                            name="agent"
                            value={selectedAgentId}
                            onChange={(event) => {
                              setSelectedAgentId(Number(event.target.value));
                            }}
                            options={lmUserList.map(a => ({
                              text: a.name || "",
                              value: a.id,
                            }))}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {lmJobDetails[0].agentId ? (
                          <IGButton
                            fullWidth
                            color="error"
                            onClick={onRemoveCurrentLMHandler}
                            loading={removeCurrentLMLoading}
                          >
                            Remove
                          </IGButton>
                        ) : (
                          <IGButton
                            fullWidth
                            color="success"
                            onClick={onAssignCurrentLMHandler}
                            loading={assignCurrentLMLoading}
                          >
                            Assign
                          </IGButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}</>
          )}
      </IGModal>
      <IGDialog
        title="Please Enter Remark"
        open={remarkModal}
        onClose={() => toggleRemarkDialog(false)}
        onConfirm={onRemarkSubmit}
        onCancel={() => toggleRemarkDialog(false)}
        confirmProps={{
          loading: removeCurrentLMLoading || closeJobLoading,
        }}
      >
        <IGTextField
          multiline={true}
          rows={5}
          name="remark"
          label="Remark"
          value={remark}
          onChange={(event) => setRemark(event.target.value)}
        />
      </IGDialog>
    </>
  );
};

export default ManageJobDialog;