import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources} from "app/typings/api/goldApi.types";
import {ClosureApiInterface} from "./types";

const CLOSURE_END_POINTS: ApiResources = {
  LOANS_BY_MOBILE_NUMBER: {
    URL: "/admin/service-desk/loan/list",
    METHOD: "GET",
  },
  PAYMENT_LIST: {
    URL: "/admin/service-desk/loan/payment-list",
    METHOD: "GET",
  },
  LOAN_SERVICE_REQUEST_ID_LIST: {
    URL: "/admin/service-desk/loan/:loanId/service-request-id/list",
    METHOD: "GET",
  },
  UPDATE_UNCATEGORISED_PAYMENT: {
    URL: "/admin/service-desk/loan/payment/:userGoldLoanPaymentId",
    METHOD: "PUT",
  },
  SERVICE_REQUEST_LIST: {
    URL: "/admin/service-desk/request-list",
    METHOD: "GET",
  },
  CLOSURE_AMOUNT: {
    URL: "/admin/service-desk/loan/closure-amount",
    METHOD: "GET",
  },
  SERVICE_REQUEST_CONFIG_LIST: {
    URL: "/admin/service-desk/config/request-config/list",
    METHOD: "GET",
  },
  CANCEL_REQUEST: {
    URL: "/admin/service-desk/request/:requestId/cancel",
    METHOD: "POST",
  },
  MODIFY_REQUEST: {
    URL: "/admin/service-desk/request/:requestId/category",
    METHOD: "PUT",
  },
  CLOSE_LOAN: {
    URL: "/admin/service-desk/request/:requestId/loan/close",
    METHOD: "POST",
  },
  ALLOW_CLOSURE_PAYMENT: {
    URL: "/admin/service-desk/request/:requestId/allow-closure-payment",
    METHOD: "POST",
  },
  GET_LOAN_DETAILS: {
    URL: "/admin/service-desk/loan/request/:requestId",
    METHOD: "GET",
  },
  GET_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/closure/detail",
    METHOD: "GET",
  },
  VALIDATE_LOAN_CLOSE: {
    URL: "/admin/service-desk/request/:requestId/loan/close/validate",
    METHOD: "GET",
  },
  AGENT_LIST: {
    URL: "/admin/service-desk/request/agent/list",
    METHOD: "GET",
  },
  GET_PAYMENT_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/payment",
    METHOD: "GET",
  },
  GET_BANK_DETAILS: {
    URL: "/admin/payment/bank-detail",
    METHOD: "GET",
  },
  GET_UPI_DETAILS: {
    URL: "/admin/payment/upi-detail",
    METHOD: "GET",
  },
  GET_BANK_INFORMATION_FROM_IFSC_CODE: {
    URL: "/admin/payment/ifsc/:ifscCode",
    METHOD: "GET",
  },
  GET_BYPASS_SCANNING_URL_LIST: {
    URL: "/admin/service-desk/request/:requestId/cm-approve-packet-scanning",
    METHOD: "GET",
  },
  GET_CUSTOMER_AVAILABILITY: {
    URL: "/admin/service-desk/request/:requestId/customer-availability",
    METHOD: "GET",
  },
  DOWNLOAD_BYPASS_DOCUMENT: {
    URL: "/admin/service-desk/request/:requestId/download-document",
    METHOD: "GET",
  },
  PUT_CLOSURE_DETAIL: {
    URL: "/admin/service-desk/request/:requestId/closure/detail",
    METHOD: "PUT",
  },
  PUT_LOAN_DETAIL: {
    URL: "/admin/service-desk/loan/request/:requestId",
    METHOD: "PUT",
  },
  REASSIGN_AGENT: {
    URL: "/admin/service-desk/request/:requestId/reassign",
    METHOD: "PUT",
  },
  UPDATE_REFUND_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/refund/update",
    METHOD: "PUT",
  },
  Get_REFUND_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/refund",
    METHOD: "GET",
  },
  REFUND: {
    URL: "/admin/service-desk/request/:requestId/refund",
    METHOD: "POST",
  },
  RETRY_REFUND: {
    URL: "/admin/service-desk/request/:requestId/refund-retry",
    METHOD: "PUT",
  },
  ADD_BANK_DETAILS: {
    URL: "/admin/payment/bank-detail",
    METHOD: "POST",
  },
  ADD_UPI_DETAILS: {
    URL: "/admin/payment/upi-detail",
    METHOD: "POST",
  },
  VERIFY_ACCOUNT_DETAILS: {
    URL: "/admin/payment/account-detail/verify",
    METHOD: "POST",
  },
  VERIFY_VPA: {
    URL: "/admin/payment/validate/vpa",
    METHOD: "POST",
  },
  POST_BYPASS_SCANNING_VERIFY: {
    URL: "/admin/service-desk/request/:requestId/cm-approve-packet-scanning",
    METHOD: "PUT",
  },
  POST_CUSTOMER_AVAILABILITY: {
    URL: "/admin/service-desk/request/:requestId/customer-availability",
    METHOD: "POST",
  },
  GET_AVAILABLE_DELIVERY_SLOTS: {
    URL: "/admin/service-desk/request/:requestId/gold-delivery-slot",
    METHOD: "GET",
  },
  UPDATE_DELIVERY_SLOT: {
    URL: "/admin/service-desk/request/:requestId/book-gold-delivery-slot",
    METHOD: "POST",
  },
  GET_DELIVERY_SLOT_DETAILS: {
    URL: "/admin/service-desk/request/:requestId/gold-delivery",
    METHOD: "GET",
  },
};

const closureApi: ClosureApiInterface = {
  getClosureAmount: async (payload) => {
    return await goldApiService({
      resource: CLOSURE_END_POINTS.CLOSURE_AMOUNT,
      options: {
        queryParams: {...payload},
      },
    });
  },
  cancelRequest: async ({requestId, cancellationReason}) => {
    const pathVars = {requestId};
    const queryParams = {cancellationReason};
    const response = await goldApiService({
      resource: CLOSURE_END_POINTS.CANCEL_REQUEST,
      options: {
        pathVars,
        queryParams,
      },
    });
    return response;
  },
  allowClosurePayment: async ({requestId, ...rest}) => {
    const pathVars = {requestId};
    return await goldApiService({
      resource: CLOSURE_END_POINTS.ALLOW_CLOSURE_PAYMENT,
      options: {
        pathVars,
        queryParams: {
          ...rest,
        },
      },
    });
  },
  getPaymentDetail: async ({requestId}) => {
    const pathVars = {requestId};
    return await goldApiService({
      resource: CLOSURE_END_POINTS.GET_PAYMENT_DETAILS,
      options: {pathVars},
    });
  },
  getRefundDetails: async ({requestId}) => {
    return await goldApiService({
      resource: CLOSURE_END_POINTS.Get_REFUND_DETAILS,
      options: {pathVars: {requestId}},
    });
  },
  getBankDetails: async ({userId}) => {
    const sourceProduct = "GOLD_LOAN";
    return await goldApiService({
      resource: CLOSURE_END_POINTS.GET_BANK_DETAILS,
      options: {queryParams: {userId, sourceProduct}},
    });
  },

  getUPIDetails: async ({userId}) => {
    const sourceProduct = "GOLD_LOAN";
    return await goldApiService({
      resource: CLOSURE_END_POINTS.GET_UPI_DETAILS,
      options: {queryParams: {userId, sourceProduct}},
    });
  },
  getAvailableDeliverySlots: async ({requestId}) => {
    return await goldApiService({
      resource: CLOSURE_END_POINTS.GET_AVAILABLE_DELIVERY_SLOTS,
      options: {pathVars: {requestId}},
    });
  },
  updateDeliverySlot: async ({requestId, ...rest}) => {
    return await goldApiService({
      resource: CLOSURE_END_POINTS.UPDATE_DELIVERY_SLOT,
      options: {
        pathVars: {requestId},
        data: {...rest},
      },
    });
  },
  getDeliverySlotDetail: async ({requestId}) => {
    return await goldApiService({
      resource: CLOSURE_END_POINTS.GET_DELIVERY_SLOT_DETAILS,
      options: {pathVars: {requestId}},
    });
  },
};

export default closureApi;
