import React, {useCallback, useState} from "react";

import styles from "../../styles";
import updateStatus from "api/business/updateStatus";
import {Snackbar, IconButton} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import ButtonWithLoader from "app/components/Button/ButtonWithLoader";

const ApproveApplication = ({
  selectedPayload,
  crJobId,
  setRejectModal,
  getNextJOb,
  status = "CM_TAKEOVER_RTGS_RECEIPT_APPROVAL_UPDATE_REQUESTED",
  reasons,
}) => {
  const classes = styles();
  const [message, setMessage] = useState("");
  const [rejectionReasons, setRejectionReasons] = useState(
    reasons ? reasons[0].rejectionReason : "",
  );

  const [notification, setNotifications] = useState({
    status: false,
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const onClickHandler = useCallback(async () => {
    try {
      if (crJobId.length === 0) {
        setNotifications({
          message: "No Job Found",
          status: true,
        });
        return;
      }
      setButtonLoading(true);
      const response = await updateStatus({
        accessToken: localStorage.getItem("authToken"),
        crId: selectedPayload.id,
        crJobId,
        remark: message,
        status,
        remarkEnum: rejectionReasons,
      });

      if (response.statusCode === 200) {
        setNotifications({
          message: "Success",
          status: true,
        });
        setRejectModal(false);
        getNextJOb();
      } else {
        setNotifications({
          message: response.error,
          status: true,
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setNotifications({
        message: "Something went wrong",
        status: true,
      });
      setButtonLoading(false);
    }
  }, [
    crJobId,
    getNextJOb,
    message,
    rejectionReasons,
    selectedPayload.id,
    setRejectModal,
    status,
  ]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotifications({
      message: "",
      status: false,
    });
  };

  return (
    <div>
      <div className={classes.title}>Reason for Update</div>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={notification.status}
        autoHideDuration={6000}
        onClose={handleClose}
        message={notification.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseRounded fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      {reasons && (
        <select
          value={rejectionReasons}
          onChange={ev => setRejectionReasons(ev.target.value)}
          style={{padding: "0.5rem 0.5rem", margin: "0.5rem 0"}}
        >
          {reasons.map(op => (
            <option
              value={op.rejectionReason}
              key={op.rejectionReason + op.rejectionDescription}
            >
              {op.rejectionDescription}
            </option>
          ))}
        </select>
      )}

      <textarea
        placeholder="Please mention exactly what the LM needs to be update in the application and reason for requesting update."
        value={message}
        className={classes.textArea}
        onChange={event => setMessage(event.target.value)}
      />

      <div className={classes.buttonContainer}>
        <ButtonWithLoader
          onClick={onClickHandler}
          className={classes.ApproveButton}
          loading={buttonLoading}
          disabled={!message}
        >
          Update
        </ButtonWithLoader>
      </div>
    </div>
  );
};

export default ApproveApplication;
