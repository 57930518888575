const ROUTES = {
  GOLD_LOAN: "gold-loan",
  DIGITAL_GOLD: "digital-gold",
  GOLD_LOCKER: "gold-locker",
  DG_ORDER: "dg-order",
  EMI_ORDERS: "emi-orders",
  USERS: "users",
  GIFTCARD_CONFIG: "giftcard-config",
  USER_TIER_CONFIG: "user-tier-config",
  REFFERAL_CONFIG: "referral-config",
  SERVICE_DESK: "service-desk",
  SERVICES: "services",
  COMPLAINTS: "complaints",
  REMOVE_JUMPER: "remove-jumper",
  PARTNER_DASHBOARD: "partner",
  LENDER_PORTAL: "lender",
};

export default ROUTES;
