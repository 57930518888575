import {createAction} from "@reduxjs/toolkit";
import {
  GetBankDetailsPayload,
  BankDetailsResponse,
  UpdateBankDetailsPayload,
  VerifyBankDetailsPayload,
} from "./bankDetails.types";

const bankDetailsActionTypes = {
  GetBankDetails: "[Bank Details] Get Bank Details",
  GetBankDetailsSuccess: "[Bank Details] Get Bank Details Success",
  GetBankDetailsFailure: "[Bank Details] Get Bank Details Failure",
  UpdateBankDetails: "[Bank Details] Update Bank Details",
  UpdateBankDetailsSuccess: "[Bank Details] Update Bank Details Success",
  UpdateBankDetailsFailure: "[Bank Details] Update Bank Details Failure",
  VerifyBankDetails: "[Bank Details] Verify Bank Details",
  VerifyBankDetailsSuccess: "[Bank Details] Verify Bank Details Success",
  VerifyBankDetailsFailure: "[Bank Details] Verify Bank Details Failure",
  UpdateBankDetailsMilestone: "[Bank Details] Update Bank Details Milestone",
  UpdateBankDetailsMilestoneSuccess:
    "[Bank Details] Update Bank Details Milestone Success",
  UpdateBankDetailsMilestoneFailure:
    "[Bank Details] Update Bank Details Milestone Failure",
};

const bankDetailsActions = {
  getBankDetails: createAction<GetBankDetailsPayload>(
    bankDetailsActionTypes.GetBankDetails,
  ),
  getBankDetailsSuccess: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.GetBankDetailsSuccess,
  ),
  getBankDetailsFailure: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.GetBankDetailsFailure,
  ),
  updateBankDetails: createAction<{
    crId: number;
    crJobId: number;
    payload: UpdateBankDetailsPayload;
  }>(bankDetailsActionTypes.UpdateBankDetails),
  updateBankDetailsSuccess: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.UpdateBankDetailsSuccess,
  ),
  updateBankDetailsFailure: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.UpdateBankDetailsFailure,
  ),
  verifyBankDetails: createAction<VerifyBankDetailsPayload>(
    bankDetailsActionTypes.VerifyBankDetails,
  ),
  verifyBankDetailsSuccess: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.VerifyBankDetailsSuccess,
  ),
  verifyBankDetailsFailure: createAction<BankDetailsResponse>(
    bankDetailsActionTypes.VerifyBankDetailsFailure,
  ),
};

export default bankDetailsActions;

export {bankDetailsActionTypes};
