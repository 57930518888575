import i18n from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import enStrings from "./strings/en.json";
import enNewString from "./strings/en-new.json";

const resources = {
  en: {
    translation: enStrings,
    newTranslation: enNewString,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    // language to use
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const useIGTranslation = () => {

  const {t} = useTranslation("newTranslation");
  const {t: tInputLabel} = useTranslation("newTranslation", {keyPrefix: "TEXT_FIELD.LABEL"});
  const {t: tIPlaceholder} = useTranslation("newTranslation", {keyPrefix: "TEXT_FIELD.PLACEHOLDER"});
  const {t: tIHelperText} = useTranslation("newTranslation", {keyPrefix: "TEXT_FIELD.HELPER_TEXT"});
  const {t: tSelectLabel} = useTranslation("newTranslation", {keyPrefix: "SELECT.LABEL"});
  const {t: tCheckBoxLabel} = useTranslation("newTranslation", {keyPrefix: "CHECKBOX.LABEL"});
  const {t: tButton} = useTranslation("newTranslation", {keyPrefix: "BUTTON"});
  const {t: tErrors} = useTranslation("newTranslation", {keyPrefix: "ERRORS"});
  const {t: tTableHeader} = useTranslation("newTranslation", {keyPrefix: "TABLE.HEADER"});
  const {t: tTableActions} = useTranslation("newTranslation", {keyPrefix: "TABLE.ACTIONS"});
  const {t: tModalTitle} = useTranslation("newTranslation", {keyPrefix: "MODAL.TITLE"});
  const {t: tModalSubTitle} = useTranslation("newTranslation", {keyPrefix: "MODAL.SUBTITLE"});
  const {t: tDrawerTitle} = useTranslation("newTranslation", {keyPrefix: "DRAWER.TITLE"});
  const {t: tDrawerSubTitle} = useTranslation("newTranslation", {keyPrefix: "DRAWER.SUBTITLE"});
  const {t: tDialogTitle} = useTranslation("newTranslation", {keyPrefix: "DIALOG.TITLE"});
  const {t: tDialogSubTitle} = useTranslation("newTranslation", {keyPrefix: "DIALOG.SUBTITLE"});

  return {
    t,
    tInputLabel,
    tIPlaceholder,
    tIHelperText,
    tSelectLabel,
    tButton,
    tErrors,
    tTableHeader,
    tTableActions,
    tModalTitle,
    tModalSubTitle,
    tDrawerTitle,
    tDrawerSubTitle,
    tCheckBoxLabel,
    tDialogTitle,
    tDialogSubTitle,
  };
};

export default i18n;
