import {SchedulingApiInterface} from "./types";
import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources} from "app/typings/api/goldApi.types";
import {GetRoleBasedCitiesPayload} from "./types";

const SCHEDULING_ENDPOINTS: ApiResources = {
  GET_CITIES: {
    URL: "/admin/business/city-list",
    METHOD: "GET",
  },
  GET_AGENT_AVAILABILITY_CITIES: {
    URL: "/admin/business/v2/role-city-list",
    METHOD: "GET",
  },
  GET_LENDER_LIST: {
    URL: "/business/bt-lender-list",
    METHOD: "GET",
  },
  GET_CALLING_HISTORY: {
    URL: "/admin/call/history/:type",
    METHOD: "GET",
  },
  DELETE_EVENT: {
    URL: "/admin/business/cr/:crId",
    METHOD: "DELETE",
  },
};

const SchedulingApi: SchedulingApiInterface = {
  getCities: async () => {
    const {response, error} = await goldApiService({
      resource: SCHEDULING_ENDPOINTS.GET_CITIES,
    });

    if (error) {
      return {error, cities: []};
    }

    return {cities: response, error: null};
  },
  getRoleBasedCities: async (payload: GetRoleBasedCitiesPayload) => {
    const {response, error} = await goldApiService({
      resource: SCHEDULING_ENDPOINTS.GET_AGENT_AVAILABILITY_CITIES,
      options: {
        queryParams: {
          showAll: payload.showAll,
        },
      },
    });

    if (error) {
      return {error, cities: []};
    }

    return {cities: response, error: null};
  },
  getLenderList: async () => {
    const {response, error} = await goldApiService({
      resource: SCHEDULING_ENDPOINTS.GET_LENDER_LIST,
    });

    if (error) {
      return {error, lenderList: []};
    }

    return {lenderList: response, error: null};
  },
  getCallingHistory: async (payload) => {
    const queryParams: Record<string, any> = {};

    if (payload.type === "lead") queryParams.leadId = payload.id;
    if (payload.type === "cr") queryParams.crId = payload.id;

    const {response, error} = await goldApiService({
      resource: SCHEDULING_ENDPOINTS.GET_CALLING_HISTORY,
      options: {
        pathVars: {
          type: payload.type,
        },
        queryParams,
      },
    });
    if (error) return {callingHistory: [], error};
    return {callingHistory: response, error: null};
  },
  deleteEvent: async (payload) => {
    const {error} = await goldApiService({
      resource: SCHEDULING_ENDPOINTS.DELETE_EVENT,
      options: {
        pathVars: {
          crId: payload.crId,
        },
      },
    });
    if (error) return {error};
    return {error: null};
  },
};

export default SchedulingApi;

export {SCHEDULING_ENDPOINTS};
