import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  GET_DOCUMENT_ADDRESS: {
    URL: "/business/lender/cr/:crId/kyc-document-address",
    METHOD: "GET",
  },
};

const getDocumentAddress = async ({crId}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS.GET_DOCUMENT_ADDRESS,
    options: {
      pathVars: {crId},
    },
  });
  return response;
};

export default getDocumentAddress;
