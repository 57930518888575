import React, {useCallback, useState} from "react";

import Container from "./styles/Container";
import Title from "./styles/Title";
import {Box, Icon, colors} from "@mui/material";

import IconContainer from "./styles/IconContainer";
import {Info, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import ComponentContainer from "./styles/ComponentContainer";
import MainContainer from "./styles/MainContainer";
import CheckBoxContainer from "./styles/CheckBoxContainer";
import CheckBox from "./styles/CheckBox";
import GoldenDivider from "../../NewUploadKYC/KYCDetails/styles/GoldenDivider";

const Accordoin = ({
  Component,
  active,
  title = "Some Title",
  checked,
  setChecked,
  name,
  showCheckBox,
  showAlert = false,
  disabled = false,
}) => {
  const [isActive, setIsActive] = useState(active);
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheck = useCallback(() => {
    setChecked(name);
    setIsChecked((state) => !state);
  }, [setChecked, name]);

  return (
    <>
      <MainContainer>
        <Container
          onClick={() => !disabled && setIsActive((state) => !state)}
          active={isActive}
        >
          <Box display="flex">
            <Title>{title}</Title> &nbsp;
            {showAlert && (
              <Icon component={Info} sx={{color: colors.red[500]}} />
            )}
          </Box>
          {!disabled && (
            <IconContainer>
              {isActive && <KeyboardArrowUp />}
              {!isActive && <KeyboardArrowDown />}
            </IconContainer>
          )}
        </Container>
        {isActive && (
          <ComponentContainer active={isActive}>
            <Component />

            {showCheckBox && <GoldenDivider />}

            {showCheckBox && (
              <CheckBoxContainer>
                <CheckBox
                  checked={isChecked}
                  type="checkbox"
                  onClick={handleCheck}
                />
                I agree I have checked all the details and they are correct
              </CheckBoxContainer>
            )}
          </ComponentContainer>
        )}
      </MainContainer>
    </>
  );
};

// export default memo(Accordoin, (prev, next) => {
//   console.log(prev, next);
//   return isEqual(prev, next);
// });

export default Accordoin;
