import {GoldApi} from "app/infra/services/api";
import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {schedulingActions, schedulingActionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {GetRoleBasedCitiesPayload} from "app/infra/services/api/scheduling/types";

const {
  getCitiesSuccess,
  getCitiesFailure,
  getLenderListSuccess,
  getLenderListFailure,
  getCallingHistorySuccess,
  getCallingHistoryFailure,
} = schedulingActions;

function* getCitiesFlow() {
  const api: GoldApi = yield getContext("api");

  const {cities, error} = yield call(api.schedulingGeneral.getCities);
  if (error) {
    yield put(getCitiesFailure({error, cities: []}));
  } else {
    yield put(getCitiesSuccess({error: null, cities}));
  }
}

function* getRoleBasedCitiesFlow(
  action: PayloadAction<GetRoleBasedCitiesPayload>,
) {
  const api: GoldApi = yield getContext("api");

  const {cities, error} = yield call(
    api.schedulingGeneral.getRoleBasedCities,
    action.payload,
  );
  if (error) {
    yield put(getCitiesFailure({error, cities: []}));
  } else {
    yield put(getCitiesSuccess({error: null, cities}));
  }
}

function* getLenderListFlow() {
  const api: GoldApi = yield getContext("api");

  const {lenderList, error} = yield call(api.schedulingGeneral.getLenderList);
  if (error) {
    yield put(getLenderListFailure({error, lenderList: []}));
  } else {
    yield put(getLenderListSuccess({error: null, lenderList}));
  }
}

function* getCallingHistoryFlow(action: PayloadAction<any>) {
  const api: GoldApi = yield getContext("api");

  const {callingHistory, error} = yield call(
    api.schedulingGeneral.getCallingHistory,
    action.payload,
  );
  if (error) {
    yield put(getCallingHistoryFailure({callingHistory, error}));
  } else {
    yield put(getCallingHistorySuccess({callingHistory, error}));
  }
}

// >>>>>>>> Scheduling Sagas <<<<<<<<<

export default function* schedulingSaga() {
  yield takeLatest(schedulingActionTypes.GetCities, getCitiesFlow);
  yield takeLatest(
    schedulingActionTypes.GetRoleBasedCities,
    getRoleBasedCitiesFlow,
  );

  yield takeLatest(schedulingActionTypes.GetLenderList, getLenderListFlow);

  yield takeLatest(
    schedulingActionTypes.GetCallingHistory,
    getCallingHistoryFlow,
  );
}
