import {all, fork} from "redux-saga/effects";
import {authSaga} from "./auth";
import {unscheduledSaga} from "./scheduling/unscheduled";
import {openCRSaga} from "./scheduling/openCr";
import {schedulingSaga} from "./scheduling";
import {algoSaga} from "./scheduling/algo";
import {lmAvailabilitySaga} from "./scheduling/lmAvailability";
import {giftCardConfigSaga} from "./giftCardConfig";
import {whitelistUserSaga} from "./users";
import {userTierConfigSaga} from "./userTierConfig";
import {refferalConfigSaga} from "./refferalConfig";
import {servicesSaga} from "./serviceDesk/services";
import internalUserSaga from "./internalUser/saga";
import layoutSaga from "./layout/saga";
import closureSaga from "./serviceDesk/services/closure/saga";
import {activeLoansSaga} from "./activeLoans";
import {renewRebookSaga} from "./serviceDesk/services/renewRebook";
import complaintsSaga from "./serviceDesk/complaints/saga";
import {agentAvailabilitySaga} from "./serviceDesk/agentAvailability";
import {customerProfileSaga} from "./customerProfile";
import {leadSaga} from "./lead";
import {citySaga} from "./city";
import {loanSaga} from "./loan";
import {addressSaga} from "./address";
import {loanSummarySaga} from "./loanSummary";
import {loanPaymentSaga} from "./loanPayment";
import {lenderLoanSaga} from "./lenderLoan";
import {goldReleaseSaga} from "./goldRelease";
import {ornamentsSaga} from "./ornaments";
import {lenderCustomerProfileSaga} from "./lenderCustomerProfile";
import {partReleaseSaga} from "./serviceDesk/services/partRelease";
import {rateChangeSaga} from "./serviceDesk/services/rateChange";
import {collateralMovementSaga} from "./collateralMovement";
import {bankDetailsSaga} from "./loanRenewalRebook/bankDetails";
import {occupationDetailsSaga} from "./loanRenewalRebook/occupationDetails";
import {loanRenewalRebookSaga} from "./loanRenewalRebook";
import {cifDetailsSaga} from "./loanRenewalRebook/cifValidation";
import {personalDetailsSaga} from "./loanRenewalRebook/personalDetails";
import {creditDetailsSaga} from "./loanRenewalRebook/creditDetails";
import {transactionsSaga} from "./transactions";
import {lenderClosureJobSaga} from "./lenderClosureJob";
import {dialerSaga} from "./dialer";
import {featureFlagsSaga} from "./featureFlags";

export function* rootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(unscheduledSaga)]);
  yield all([fork(openCRSaga)]);
  yield all([fork(algoSaga)]);
  yield all([fork(schedulingSaga)]);
  yield all([fork(lmAvailabilitySaga)]);
  yield all([fork(giftCardConfigSaga)]);
  yield all([fork(whitelistUserSaga)]);
  yield all([fork(userTierConfigSaga)]);
  yield all([fork(refferalConfigSaga)]);
  yield all([fork(servicesSaga)]);
  yield all([fork(closureSaga)]);
  yield all([fork(internalUserSaga)]);
  yield all([fork(layoutSaga)]);
  yield all([fork(activeLoansSaga)]);
  yield all([fork(renewRebookSaga)]);
  yield all([fork(complaintsSaga)]);
  yield all([fork(agentAvailabilitySaga)]);
  yield all([fork(customerProfileSaga)]);
  yield all([fork(leadSaga)]);
  yield all([fork(citySaga)]);
  yield all([fork(loanSaga)]);
  yield all([fork(addressSaga)]);
  yield all([fork(loanSummarySaga)]);
  yield all([fork(loanPaymentSaga)]);
  yield all([fork(lenderLoanSaga)]);
  yield all([fork(goldReleaseSaga)]);
  yield all([fork(ornamentsSaga)]);
  yield all([fork(lenderCustomerProfileSaga)]);
  yield all([fork(partReleaseSaga)]);
  yield all([fork(collateralMovementSaga)]);
  yield all([fork(bankDetailsSaga)]);
  yield all([fork(occupationDetailsSaga)]);
  yield all([fork(loanRenewalRebookSaga)]);
  yield all([fork(cifDetailsSaga)]);
  yield all([fork(personalDetailsSaga)]);
  yield all([fork(rateChangeSaga)]);
  yield all([fork(creditDetailsSaga)]);
  yield all([fork(transactionsSaga)]);
  yield all([fork(lenderClosureJobSaga)]);
  yield all([fork(dialerSaga)]);
  yield all([fork(featureFlagsSaga)]);
}
