import * as reactRedux from "react-redux";
import {snackBarActions} from "./snackbar";
import type {RootState, AppDispatch} from "./store";
import store from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => reactRedux.useDispatch<AppDispatch>();
export const useAppSelector: reactRedux.TypedUseSelectorHook<RootState> =
  reactRedux.useSelector;
export const useSnackBar = () => {
  return {
    showSuccess: (message: string, autoHideDuration?: number | null) => {
      store.dispatch(snackBarActions.open({
        message,
        variant: "success",
        autoHideDuration,
      }));
    },
    showError: (message: string, autoHideDuration?: number | null) => {
      store.dispatch(snackBarActions.open({
       message,
        variant: "error",
        autoHideDuration,
      }));
    },
    showInfo: (message: string, autoHideDuration?: number | null) => {
      store.dispatch(snackBarActions.open({
        message,
        variant: "info",
        autoHideDuration,
      }));
    },
    showWarning: (message: string, autoHideDuration?: number | null) => {
      store.dispatch(snackBarActions.open({
        message,
        variant: "warning",
        autoHideDuration,
      }));
    },
  };
};