import React, {FC, useMemo} from "react";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {LoanModel} from "app/models/loan.model";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import {useAppSelector} from "app/store/hooks";
import {loanSelectors} from "app/store/loan";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {isNil} from "lodash";
import {featureFlagSelectors} from "app/store/featureFlags";

interface AccountDetailsProps {
}

const AccountDetails: FC<AccountDetailsProps> = () => {
  const {t, tTableHeader} = useIGTranslation();
  const {loanId} = useParams();
  const loanDetails = useAppSelector((state) =>
    loanSelectors.getLoanDetails(state, Number(loanId)),
  );
  const loanInstallmentList = useAppSelector((state) =>
    loanSelectors.getLoanInstalmentList(state, Number(loanId)),
  );
  const {getLoanDetails} = useAppSelector(loanSelectors.getLoading);
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );
  const balanceInstallments = useMemo(() => {
    return loanInstallmentList.filter((el) => el.status === "PENDING")?.length || "0";
  }, [loanInstallmentList]);

  const tableCols: IGTableColsInterface<LoanModel> = [
    {
      headerName: "CR ID",
      field: "id",
    },
    {
      headerName: t("LENDER_NAME"),
      field: isMonitoringCheckActive ? "goldLoanProvider" : "loanLenderDisplayName",
    },
    {
      headerName: t("CUSTOMER_PROFILE.SANCTIONED_AMOUNT"),
      field: "disbursedLoanAmount",
      renderCell: ({value}) => numberWithCurrencyAndComma(value),
    },
    {
      headerName: t("SCHEME"),
      field: "goldLoanSchemeName",
    },
    {
      headerName: t("CUSTOMER_PROFILE.BALANCE_TOTAL_INSTALLMENT"),
      field: "null",
      renderCell: () => <>
        {balanceInstallments}/{loanInstallmentList.length}
      </>,
    },
    {
      headerName: "Customer Effective ROI (GL)",
      field: "goldLoanInterestRate",
      renderCell: ({value}) => `${value} %`,
    },
    {
      headerName: "Booking ROI (GL)",
      field: "goldLoanBookingRate",
      renderCell: ({value}) => `${value} %`,
    },
    {
      headerName: "Customer Effective ROI (PL)",
      field: "personalLoanInterestRate",
      renderCell: ({value}) => `${value} %`,
    },
    {
      headerName: "Rebate ROI",
      field: "glRebateAnnualRate",
      renderCell: ({value}) => `${value} %`,
    },
  ];

  return (
    <IGTable
      hideFooter
      loading={getLoanDetails}
      tableCols={tableCols}
      tableData={loanDetails ? [loanDetails] : []}
      getRowId={(row) => row.id}
      components={{
        Header: (params) => (
          <Box {...params} p={2}>
            <Typography variant="h5">
              {tTableHeader("ACCOUNT_DETAILS")}
            </Typography>
          </Box>
        ),
      }}
      columnVisibilityModel={{
        personalLoanInterestRate:
          (loanDetails?.isPersonalLoanTaken && !isMonitoringCheckActive) || false,
        goldLoanInterestRate: !isNil(loanDetails?.goldLoanInterestRate),
        glRebateAnnualRate: Boolean(loanDetails?.glRebateAnnualRate),
        goldLoanBookingRate: !isNil(loanDetails?.goldLoanBookingRate),
      }}
    />
  );
};

export default AccountDetails;