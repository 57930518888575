import {
  BlockAgentTimePayload,
  DeleteTimeSlotPayload,
  GetAgentsPayload,
  LmAvailabilityApiInterface,
  LmAvailabilityResources,
  UpdateTimeSlotPayload,
  GetUnschedulViewPayload,
} from "./types";
import {goldApiService} from "app/infra/plugins/axios";
import {GetLMAvailabilityPayload, GetLMsPayload} from "./types";
import {GetOpenCrViewPayload} from "../openCR/types";

const LM_AVAILABILITY_ENDPOINTS: LmAvailabilityResources = {
  GET_Agents: {
    URL: "/admin/business/agent-list", // ? cityId, roleName
    METHOD: "GET",
  },
  GET_LMs: {
    URL: "/scheduling/lm-user-list", // ? cityId
    METHOD: "GET",
  },
  BLOCK_AGENT_TIME: {
    URL: "/agent-schedule/:agentId/block",
    METHOD: "POST",
  },
  GET_LM_AVAILABILITY: {
    URL: "/scheduling/lm-schedule-list", // ? agentId, scheduleDate
    METHOD: "GET",
  },
  DELETE_TIME_SLOT: {
    URL: "/agent-schedule/:lmScheduleId",
    METHOD: "DELETE",
  },
  UPDATE_TIME_SLOT: {
    URL: "/agent-schedule/:lmScheduleId/update-time",
    METHOD: "POST",
  },
  GET_LM_MEETING_DETAILS: {
    URL: "/agent-schedule/detail",
    METHOD: "GET",
  },
  GET_CALL_DETAILS: {
    URL: "/agent-schedule/call-detail",
    METHOD: "GET",
  },
  GET_SCHEDULED_LM: {
    URL: "/scheduling/real-time/cluster/lead/lm-list",
    METHOD: "GET",
  },
  GET_OPENCR_LM: {
    URL: "/scheduling/real-time/cluster/cr/lm-list",
    METHOD: "GET",
  },
  GET_UNSCHEDULED_MANUAL_ASSIGNMENT: {
    URL: "/scheduling/real-time/lead/manual-assignment",
    METHOD: "GET",
  },
  ENABLE_LEAD_MANUAL_ASSIGNMENT_FLOW: {
    URL: "/scheduling/real-time/lead/manual-assignment",
    METHOD: "POST",
  },
};

const LmAvailabilityApi: LmAvailabilityApiInterface = {
  blockAgentTime: async (payload: BlockAgentTimePayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.BLOCK_AGENT_TIME,
      options: {
        pathVars: {
          agentId: payload.agentId,
        },
        data: {
          startTime: payload.startTime,
          endTime: payload.endTime,
          reason: payload.reason,
          remark: payload.remark,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {error: null, response};
  },
  deleteTimeSlot: async (payload: DeleteTimeSlotPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.DELETE_TIME_SLOT,
      options: {
        pathVars: {
          lmScheduleId: payload.lmScheduleId,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  updateTimeSlot: async (payload: UpdateTimeSlotPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.UPDATE_TIME_SLOT,
      options: {
        pathVars: {
          lmScheduleId: payload.lmScheduleId,
        },
        data: {
          startTime: payload.startTime,
          endTime: payload.endTime,
        },
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  getLoanManagers: async (payload: GetLMsPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_LMs,
      options: {
        queryParams: {
          cityId: payload.cityId,
          roleName: payload.roleName,
          priorityRole: payload.priorityRole || false,
        },
      },
    });

    if (error) {
      return {error, loanManagers: []};
    }
    return {loanManagers: response, error: null};
  },
  getScheduledLoanManagers: async (payload: GetUnschedulViewPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_SCHEDULED_LM,
      options: {
        queryParams: {
          leadId: payload.leadId,
        },
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {response: response, error: null};
  },
  getOpenCrEta: async (payload: GetOpenCrViewPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_OPENCR_LM,
      options: {
        queryParams: {
          crId: payload.crId,
        },
      },
    });
    if (error) {
      return {error, response: []};
    }
    return {response: response, error: null};
  },
  getUnscheduledView: async (payload: GetUnschedulViewPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_UNSCHEDULED_MANUAL_ASSIGNMENT,
      options: {
        queryParams: {
          leadId: payload.leadId,
        },
      },
    });

    if (error) {
      return {error, response: []};
    }
    return {response, error: null};
  },
  getAgents: async (payload: GetAgentsPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_Agents,
      options: {
        queryParams: {...payload},
      },
    });

    if (error) {
      return {error, loanManagers: []};
    }
    return {loanManagers: response, error: null};
  },
  getLoanManagerAvailability: async (payload: GetLMAvailabilityPayload) => {
    const {response, error} = await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_LM_AVAILABILITY,
      options: {
        queryParams: {
          agentId: payload.agentIdList,
          scheduleDate: payload.date,
        },
      },
    });

    if (error) {
      return {error, loanManagerAvailability: []};
    }
    return {loanManagerAvailability: response, error: null};
  },

  getMeetingDetails: async ({lmScheduleId}) => {
    return await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_LM_MEETING_DETAILS,
      options: {
        queryParams: {
          lmScheduleId,
        },
      },
    });
  },

  getCallDetails: async ({lmScheduleId}) => {
    return await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.GET_CALL_DETAILS,
      options: {
        queryParams: {
          lmScheduleId,
        },
      },
    });
  },

  enableLeadManualAssignmentFlow: async ({leadId}) => {
    return await goldApiService({
      resource: LM_AVAILABILITY_ENDPOINTS.ENABLE_LEAD_MANUAL_ASSIGNMENT_FLOW,
      options: {
        queryParams: {
          leadId,
        },
      },
    });
  },
};

export default LmAvailabilityApi;

export {LM_AVAILABILITY_ENDPOINTS};
