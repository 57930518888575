import React from "react";
import {Link, Outlet} from "react-router-dom";
import "_metronic/_assets/sass/pages/login/login-1.scss";
import bgImage from "assets/bg-1.png";
import Logo from "assets/logo.svg";
import LoginPageImage from "assets/Dashboard.png";

export default function AuthPage() {
  const handleMouseMove = e => {
    document
      .getElementById("kt_login")
      .style.setProperty("--x", `${e.clientX + e.clientY  }px`);
    document.querySelectorAll(".parallex").forEach(element => {
      const movingValue = element.getAttribute("movingvalue");
      const x = e.clientX * movingValue;
      const y = e.clientY * movingValue;
      element.style.transform =
        `translateX(${  x  }px) translateY(${  y  }px)`;
    });
  };

  return (
    <>
      <div
        className="kt-grid kt-grid--ver kt-grid--root "
        onMouseMove={handleMouseMove}
      >
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside w-50">
              <div className="kt-grid__item">
                <Link to="/" className="kt-login__logo">
                  <img alt="Logo" src={Logo} className="kt-login__logo" />
                </Link>
              </div>
              <img
                src={LoginPageImage}
                className="kt-login__image parallex"
                alt="login-page"
                movingvalue="0.03"
              />
            </div>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
