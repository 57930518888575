import {makeStyles, createStyles} from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minWidth: 800,
      maxWidth: 800,
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
    },
    mileStonesContainer: {
      padding: "1.5rem",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "2px",
      color: "#121212",
    },
    textContainer: {
      minHeight: "7vh",
      display: "flex",
      alignItems: "center",
      marginLeft: "25px",
      justifyContent: "space-between",
      marginRight: "20px",
    },
    iconCompleted: {
      background: "rgba(18,18,18,0.05)",
      border: "2px solid #00b3a6",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#00b3a6",
    },
    active: {
      background: "white",
      width: "16px",
      height: "16px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#00b3a6",
    },
  }),
);

export default useStyles;
