import styled from "@emotion/styled";
import {Typography, Grid, Box} from "@mui/material";

const AddBeneficiaryContainer = styled(Grid)`
  max-width: 800px;
`;

const VerificationStatusContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  width: 100%;
`;

const NameMismatchDetailsContainer = styled(Grid)`
  margin: 8px;
`;

const RelationShipContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const StyledTitle = styled(Typography)`
  color: black;
  width: 40%;
  font-weight: bold;
`;

const StyledValue = styled(Typography)`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
`;

export {
  AddBeneficiaryContainer,
  VerificationStatusContainer,
  NameMismatchDetailsContainer,
  RelationShipContainer,
  StyledTitle,
  StyledValue,
};