import React from "react";
import {Outlet, Route} from "react-router-dom";

const DynamicAnalyticsRoutes = (feature) => {
  return (
    <Route path={feature.path} element={<Outlet/>}>
      <Route index element={feature.subMenu[0]?.component()}/>
      {feature.subMenu.map((route) => (
        <Route key={route.path} path={route.path.replace("/", "")} element={route.component()}/>
      ))}
    </Route>
  );
};

export default DynamicAnalyticsRoutes;