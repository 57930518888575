import {PayloadAction, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {servicesReducer} from "./services";
import {closureReducer} from "./services/closure";
import {renewRebookReducer} from "./services/renewRebook";
import {complaintReducer} from "./complaints";
import {
  initialState as servicesState,
} from "./services/reducer";
import {
  initialState as complaintsState,
  ComplaintsState,
} from "./complaints/reducer";
import {agentAvailabilityReducer} from "./agentAvailability";
import {AgentAvailability, initialState as agentAvailabilityState} from "./agentAvailability/reducer";
import {ServicesState} from "./services/types";
import partReleaseReducer from "./services/partRelease/partRelease.reducer";
import {rateChangeReducer} from "./services/rateChange";
import {ServiceRequest} from "app/models/services";
import {RootState} from "../store";
import {createActionsInitialState, setActionError, setActionLoading, setActionSuccess} from "../redux.utils";
import {ServiceDeskConfigList} from "app/models/serviceDesk";
import {ConfigProduct, RequestType} from "app/enums/serviceDesk";

interface ServiceRequestConfig {
  SERVICE: {
    GOLD_LOAN: ServiceDeskConfigList;
    NON_GOLD_LOAN: ServiceDeskConfigList;
  };
  COMPLAINT: {
    GOLD_LOAN: ServiceDeskConfigList;
    NON_GOLD_LOAN: ServiceDeskConfigList;
  };
}

interface CSAgent {
  id: number;
  name: string;
}

interface ServiceDeskState {
  services: ServicesState;
  complaints: ComplaintsState;
  agentAvailability: AgentAvailability;
  serviceRequests: ReturnType<typeof serviceRequestAdaptor.getInitialState>;
  agents: ReturnType<typeof agentsAdaptor.getInitialState>;
  selectedRequestId: number | null;
  config: ServiceRequestConfig;
  actions: ReturnType<typeof createActionsInitialState>;
}

export type ServiceRequestActions =
  | "getAllServiceRequests"
  | "getServiceRequestDetails"
  | "getServiceRequestConfig"
  | "getTatDetails"
  | "getAgentList";


const serviceRequestAdaptor = createEntityAdapter<ServiceRequest>({
  selectId: (service) => service.id,
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});

const agentsAdaptor = createEntityAdapter<CSAgent>();

const initialState: ServiceDeskState = {
  services: servicesState,
  complaints: complaintsState,
  agentAvailability: agentAvailabilityState,
  serviceRequests: serviceRequestAdaptor.getInitialState(),
  selectedRequestId: null,
  agents: agentsAdaptor.getInitialState(),
  config: {
    SERVICE: {
      GOLD_LOAN: {},
      NON_GOLD_LOAN: {},
    },
    COMPLAINT: {
      GOLD_LOAN: {},
      NON_GOLD_LOAN: {},
    },
  },
  actions: createActionsInitialState([
    "getAllServiceRequests",
    "getServiceRequestDetails",
    "getServiceRequestConfig",
    "getTatDetails",
    "getAgentList",
    // Add other actions here...
  ]),
};

const serviceDeskSlice = createSlice({
  name: "serviceDesk",
  initialState,
  reducers: {
    serviceRequestUpdated: (state, action) => {
      serviceRequestAdaptor.upsertOne(state.serviceRequests, action.payload);
    },
    serviceRequestsReceived: (state, action) => {
      serviceRequestAdaptor.setAll(state.serviceRequests, action.payload);
    },
    serviceRequestTatDetailsReceived: (state, action) => {
      const {requestId, response} = action.payload;
      const currentRequest = state.serviceRequests.entities[requestId];
      if (currentRequest) {
        serviceRequestAdaptor.upsertOne(state.serviceRequests, {
          ...currentRequest,
          statusDetailResponseDtoList: response,
        });
      }
    },
    agentsReceived: (state, action: PayloadAction<CSAgent[]>) => {
      agentsAdaptor.setAll(state.agents, action.payload);
    },
    configUpdated: (state, action: PayloadAction<{
      requestType: RequestType,
      product: ConfigProduct,
      config: ServiceDeskConfigList
    }>) => {
      const {requestType, product, config} = action.payload;
      state.config[requestType][product] = config;
    },
    setSelectedRequestId: (state, action: PayloadAction<number>) => {
      state.selectedRequestId = action.payload;
    },
    setActionLoading,
    setActionSuccess,
    setActionError,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) =>
          action.type.startsWith("[Services]") ||
          action.type.startsWith("services"),
        (state, action) => {
          state.services = servicesReducer(state.services, action);
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[Closure]") ||
          action.type.startsWith("closure"),
        (state, action) => {
          const data = closureReducer(state.services.closureState, action);
          state.services.closureState = {...data};
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[Agent Availability]") ||
          action.type.startsWith("agentAvailability"),
        (state, action) => {
          state.agentAvailability = agentAvailabilityReducer(
            state.agentAvailability,
            action,
          );
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[Complaints]") ||
          action.type.startsWith("complaints"),
        (state, action) => {
          state.complaints = complaintReducer(state.complaints, action);
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[RenewRebook]") ||
          action.type.startsWith("renewRebook"),
        (state, action) => {
          const data = renewRebookReducer(
            state.services.renewRebookState,
            action,
          );
          state.services.renewRebookState = {...data};
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[Part Release]") ||
          action.type.startsWith("partRelease"),
        (state, action) => {
          const data = partReleaseReducer(
            state.services.partReleaseState,
            action,
          );
          state.services.partReleaseState = {...data};
        },
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("[RateChange]") ||
          action.type.startsWith("rateChange"),
        (state, action) => {
          const data = rateChangeReducer(
            state.services.rateChangeState,
            action,
          );
          state.services.rateChangeState = {...data};
        },
      );
  },
});

export const selectServiceRequestAction = (state: RootState, action: ServiceRequestActions) =>
  state.serviceDesk.actions[action];
export const {
  selectById: selectServiceRequestById,
  selectIds: selectServiceRequestIds,
  selectEntities: selectServiceRequestEntities,
  selectAll: selectAllServiceRequests,
  selectTotal: selectTotalServiceRequests,
} = serviceRequestAdaptor.getSelectors(
  (state: RootState) => state.serviceDesk.serviceRequests,
);
export const {
  selectAll: selectAllAgents,
} = agentsAdaptor.getSelectors(
  (state: RootState) => state.serviceDesk.agents,
);
export const {
  serviceRequestUpdated: serviceRequestUpdated,
  serviceRequestsReceived,
  setActionError: setServiceRequestActionError,
  setActionLoading: setServiceRequestActionLoading,
  setActionSuccess: setServiceRequestActionSuccess,
  configUpdated: serviceDeskConfigUpdated,
  setSelectedRequestId,
  serviceRequestTatDetailsReceived,
  agentsReceived: serviceDeskAgentsReceived,
} = serviceDeskSlice.actions;
export default serviceDeskSlice.reducer;
export {initialState};
