import styled from "@emotion/styled";

const NotesArea = styled.div`
  padding: 1rem;
  background-color: #fdf1da;

  font-size: 1rem;
  font-weight: 500;
`;

export default NotesArea;
