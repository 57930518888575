import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import KYCDetails from "./KYCDetails";
import Accordoin from "../CommonComponents/Accordoin";
import CreditChecks from "./CreditChecks";
import LenderExposure from "./LenderExposure";
import NomineeDetails from "./NomineeDetails";
import OccupationDetails from "./OccupationDetails";
import LenderDocuments from "./LenderDocuments";
import ButtonContainer from "./styles/ButtonContainer";
import ApproveApplication from "../CommonComponents/Modal/ApproveApplication";
import getNextJob from "api/business/getNextJob";
import ModalContainer from "./styles/ModalContainer";
import ModalComponent from "./styles/ModalComponent";
import MainContainer from "./styles/MainContainer";
import SendMessageToLM from "../CommonComponents/Modal/SendMessageToLM";
import SimpleSnackbar from "app/components/Snackbar";
import getRejectionReason from "api/activeLoan/getRejectReason";
import {
  loanKycStatusEnum,
  lenderNameEnum,
} from "app/enums/gold_loan/activeLoans";
import {IGButton} from "app/components";
import AddBeneficiary from "../../../Accounts/Drawer/AddBeneficiary";
import {JobTypeEnum} from "app/enums/accounts";
import BlacklistModal from "./BlacklistModal";
import {useAppSelector} from "app/store/hooks";
import {featureFlagSelectors} from "app/store/featureFlags";

const NewUploadKYC = ({
  selectedPayload,
  getMileStoneOfCr,
  isSameCreditManager,
  isBackMovement,
  currentMileStone,
}) => {
  const isMonitoringCheckActive = useAppSelector((state) =>
    featureFlagSelectors.getFeatureFlagStatus(
      state,
      "INTERNAL_DASHBOARD",
      "INTERNAL_DASHBOARD_MONITORING_CHECK",
    ),
  );
  const checkList = useRef({
    cibilConsent: false,
    lenderExposure: false,
    occupationalDetails: false,
    nomineeDetails: false,
    kycDetails: false,
    bankAccountDetails: false,
    lenderDocuments: false,
  });
  const [snackState, setSnackState] = useState({
    snackbar: false,
    message: "",
  });
  const [hideSensitiveInfo, setHideSensitiveInfo] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [nextJobStates, setNextJobStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });
  const [approveApplication, setApproveApplication] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const countRef = useRef(0);
  const [reasons, setReasons] = useState("");
  const [showBlacklistModal, setShowBlacklistModal] = useState(false);
  const crJobId = useMemo(
    () => nextJobStates?.payload && nextJobStates.payload?.id,
    [nextJobStates.payload],
  );
  const [kycStatusCheck, setKycStatusCheck] = useState({
    status: "PENDING",
  });
  const [lenderName, setLenderName] = useState("");
  const [showKycDetailsAlert, setShowKycDetailsAlert] = useState(false);

  const {
    id: crId,
    milestone: crMilestone,
    isDataPurged,
  } = selectedPayload || {};

  const handleCheckList = (key) => {
    checkList.current[key] = !checkList.current[key];
  };

  const PersonalDetailsWrapper = React.memo(() => (
    <NomineeDetails crId={crId} />
  ));

  const OccupationDetailsWrapper = useCallback(
    () => <OccupationDetails crId={crId} crJobId={crJobId} />,
    [crId, crJobId],
  );

  const CreditChecksWrapper = useCallback(
    () => (
      <CreditChecks
        crId={crId}
        hideSensitiveInfo={hideSensitiveInfo}
      />
    ),
    [hideSensitiveInfo, crId],
  );

  const LenderExposureWrapper = useCallback(
    () => (
      <LenderExposure crId={crId} setShowAlert={setShowAlert} />
    ),
    [crId],
  );

  const KYCDetailsWrapper = useCallback(
    () => (
      <KYCDetails
        crId={crId}
        hideSensitiveInfo={hideSensitiveInfo}
        setKycStatusCheck={setKycStatusCheck}
        setLenderName={setLenderName}
        setShowKycDetailsAlert={setShowKycDetailsAlert}
      />
    ),
    [hideSensitiveInfo, crId, setKycStatusCheck, setLenderName],
  );

  const bankDetails = {
    crId: crId,
    bankDetailType: "LOAN_BANK_DETAIL",
    taskType: JobTypeEnum.ACCOUNT_ADD_BENEFICIARY_LOAN_BANK_DETAIL,
  };

  const BankAccountDetailsWrapper = useCallback(
    () => (
      <AddBeneficiary
        selectedPayload={selectedPayload}
        bankDetails={bankDetails}
      />
    ),
    [crId, bankDetails],
  );
  const LenderDocumentsWrapper = useCallback(
    () => (
      <LenderDocuments
        crId={crId}
        currentMileStone={currentMileStone}
      />
    ),
    [crId, currentMileStone],
  );

  const getNextJobApi = useCallback(async () => {
    try {
      setNextJobStates({
        loading: true,
        error: false,
        payload: null,
      });
      const response = await getNextJob({
        accessToken: localStorage.getItem("authToken"),
        crId: crId,
        jobType: "CM_LOAN_KYC_APPROVAL",
      });

      if (response.statusCode === 200) {
        setNextJobStates({
          loading: false,
          error: false,
          payload: response.payload,
          errorMessage: "",
        });
      } else {
        setNextJobStates({
          loading: false,
          error: true,
          payload: null,
          errorMessage: response.error,
        });
      }
    } catch (error) {
      setNextJobStates({
        loading: false,
        error: true,
        payload: null,
        errorMessage: "Something went wrong",
      });
    }
  }, [crId]);

  const handleApproveClick = () => {
    if (
      checkList.current.cibilConsent &&
      checkList.current.lenderExposure &&
      checkList.current.nomineeDetails &&
      checkList.current.occupationalDetails &&
      checkList.current.kycDetails &&
      checkList.current.bankAccountDetails &&
      checkList.current.lenderDocuments
    ) {
      setApproveApplication(true);
    } else {
      setSnackState({
        snackbar: true,
        message: "Please check all the details first and tick the checkbox.",
      });
    }
  };
  const fetchRejectionReasons = useCallback(async () => {
    const {payload, error, statusCode} = await getRejectionReason({
      accessToken: localStorage.getItem("authToken"),
    });

    if (statusCode === 200) {
      setReasons(payload);
    } else {
      setReasons({
        status: true,
        message: error || "Error while Updating",
      });
    }
  }, []);

  const handleCheckBoxClick = useCallback((val) => handleCheckList(val), []);

  const dataPurgedInfo = isMonitoringCheckActive && isDataPurged ? "(NA)" : "";

  useEffect(() => {
    if (countRef.current === 0) {
      countRef.current = 1;
      fetchRejectionReasons();
      getNextJobApi();
    }
  }, []);

  useEffect(() => {
    if (isSameCreditManager && !isBackMovement && crJobId) {
      setHideSensitiveInfo(false);
    } else {
      setHideSensitiveInfo(true);
    }
  }, [crJobId, isBackMovement, isSameCreditManager]);

  return (
    <>
      <MainContainer>
        <SimpleSnackbar snackbar={snackState} setSnackbar={setSnackState} />

        <Accordoin
          title={`Credit Score Details ${dataPurgedInfo}`}
          Component={CreditChecksWrapper}
          active={false}
          checked={checkList.current.cibilConsent}
          setChecked={handleCheckBoxClick}
          name="cibilConsent"
          showCheckBox={!hideSensitiveInfo}
          disabled={Boolean(dataPurgedInfo)}
        />
        <Accordoin
          title="Lender Exposure"
          Component={LenderExposureWrapper}
          active={false}
          checked={checkList.current.lenderExposure}
          setChecked={handleCheckBoxClick}
          name="lenderExposure"
          showCheckBox={!hideSensitiveInfo}
          showAlert={showAlert}
        />
        <Accordoin
          title="Nominee Details"
          Component={PersonalDetailsWrapper}
          active={false}
          checked={checkList.current.nomineeDetails}
          showCheckBox={!hideSensitiveInfo}
          setChecked={handleCheckBoxClick}
          name="nomineeDetails"
        />
        <Accordoin
          title={`Occupation And Other Details ${dataPurgedInfo}`}
          Component={OccupationDetailsWrapper}
          active={false}
          checked={checkList.current.occupationalDetails}
          showCheckBox={!hideSensitiveInfo}
          setChecked={handleCheckBoxClick}
          name="occupationalDetails"
          disabled={Boolean(dataPurgedInfo)}
        />
        <Accordoin
          title="KYC Details"
          Component={KYCDetailsWrapper}
          active={false}
          showCheckBox={!hideSensitiveInfo}
          checked={checkList.current.kycDetails}
          setChecked={handleCheckBoxClick}
          name="kycDetails"
          setKycStatusCheck={setKycStatusCheck}
          showAlert={showKycDetailsAlert}
        />
        <Accordoin
          title={`Bank Account Details ${dataPurgedInfo}`}
          Component={BankAccountDetailsWrapper}
          active={false}
          showCheckBox={!hideSensitiveInfo}
          checked={checkList.current.bankAccountDetails}
          setChecked={handleCheckBoxClick}
          name="bankAccountDetails"
          setKycStatusCheck={setKycStatusCheck}
          disabled={Boolean(dataPurgedInfo)}
        />
        {![
          "COMPLETED",
          "CLOSED",
          "CR_GOLD_LOAN_FRESH_CLOSED",
          "CR_GOLD_LOAN_BT_CLOSED",
        ].includes(crMilestone) && (
          <Accordoin
            title={`Supplementary Documents ${dataPurgedInfo}`}
            Component={LenderDocumentsWrapper}
            active={false}
            showCheckBox={!hideSensitiveInfo}
            checked={checkList.current.lenderDocuments}
            setChecked={handleCheckBoxClick}
            name="lenderDocuments"
            disabled={Boolean(dataPurgedInfo)}
          />
        )}

        {isSameCreditManager && !isBackMovement && crJobId && (
          <ButtonContainer>
            <IGButton
              style={{marginRight: "1rem"}}
              color="golden"
              onClick={handleApproveClick}
              disabled={
                (lenderName === lenderNameEnum.PIRAMAL &&
                  kycStatusCheck?.status !== loanKycStatusEnum.COMPLETED) ||
                showKycDetailsAlert
              }
            >
              APPROVE
            </IGButton>
            <IGButton color="inherit" onClick={() => setRejectModal(true)}>
              SEND BACK AND REQUEST UPDATE
            </IGButton>

            <IGButton
              style={{marginLeft: "1rem"}}
              variant="outlined"
              color="error"
              onClick={() => setShowBlacklistModal(true)}
            >
              Blacklist
            </IGButton>
          </ButtonContainer>
        )}

        <ApproveApplication
          {...{
            selectedPayload,
            crJobId,
            approveApplication,
            setApproveApplication,
            getNextJobApi,
            getMileStoneOfCr,
            jobType: "CM_LOAN_KYC_APPROVAL",
          }}
        />

        <ModalComponent
          onClose={() => setRejectModal(false)}
          open={rejectModal}
        >
          <ModalContainer>
            <SendMessageToLM
              crJobId={crJobId}
              selectedPayload={selectedPayload}
              setOpen={setRejectModal}
              getMileStoneOfCr={getMileStoneOfCr}
              getNextJobApi={getNextJobApi}
              reasons={reasons}
            />
          </ModalContainer>
        </ModalComponent>
      </MainContainer>
      <BlacklistModal
        open={showBlacklistModal}
        onClose={() => setShowBlacklistModal(false)}
        crId={crId}
      />
    </>
  );
};

export default memo(NewUploadKYC);
