import {
  Slide,
  Dialog,
  Input,
  InputLabel,
  Grid,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import useStyles from "./styles";

const ModalTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ChangePasswordModal = ({
  modal,
  setModal,
  changePassword,
  changePasswordHandler,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={modal.changePassword.open}
      TransitionComponent={ModalTransition}
      fullWidth
      maxWidth="xs"
      onClose={() =>
        setModal({
          ...modal,
          changePassword: {...modal.changePassword, open: false},
        })
      }
    >
      <Box sx={{flexGrow: 1, p: 5}}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel>Old password</InputLabel>
            <Input
              type="text"
              value={modal.changePassword.oldPassword}
              onChange={(e) =>
                setModal({
                  ...modal,
                  changePassword: {
                    ...modal.changePassword,
                    oldPassword: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Email</InputLabel>
            <Input
              type="text"
              value={modal.changePassword.email}
              onChange={(e) =>
                setModal({
                  ...modal,
                  changePassword: {
                    ...modal.changePassword,
                    email: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>New password</InputLabel>
            <Input
              type="password"
              value={modal.changePassword.newPassword}
              onChange={(e) =>
                setModal({
                  ...modal,
                  changePassword: {
                    ...modal.changePassword,
                    newPassword: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Confirm new password</InputLabel>
            <Input
              type="password"
              value={modal.changePassword.confirmedNewPassword}
              onChange={(e) =>
                setModal({
                  ...modal,
                  changePassword: {
                    ...modal.changePassword,
                    confirmedNewPassword: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={changePassword}
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => changePasswordHandler()}
            >
              {changePassword ? (
                <span className="spinner-grow spinner-grow-lg mx-2"></span>
              ) : (
                <span>Change Password</span>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ChangePasswordModal;
