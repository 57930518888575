import React from "react";
import {Box, Typography} from "@mui/material";
import BTScoreAccordion from "../BTScoreAccordion";
import BTScoreCardTips from "../BTScoreCardTips";

const BtScoreSection = ({crId}: { crId: number }) => {
  return (
    <Box
      sx={{
        overflowY: "scroll",
        scrollbarWidth: "thin",
      }}
      maxHeight="90vh"
      bgcolor="#FFFFFF;"
      px={2}
    >
      <Typography
        fontFamily="roboto"
        variant="subtitle2"
        fontSize="14px"
        lineHeight="22px"
      >
        How the score is calculated?
      </Typography>
      <BTScoreAccordion crId={Number(crId)} />
      <BTScoreCardTips crId={Number(crId)} />
    </Box>
  );
};

export default BtScoreSection;
