import {createAction} from "@reduxjs/toolkit";
import {LoanIdPayload, LoanPaymentResponse, PaymentIdPayload, RefundStatusResponse, ShiftPaymentPayload, ShiftPaymentStatusResponse} from "./loanPayment.types";

const loanPaymentActionTypes = {
  GetLoanPaymentDetails: "[LoanPayment] Get LoanPayment Details",
  GetLoanPaymentDetailsSuccess: "[LoanPayment] Get LoanPayment Details Success",
  GetLoanPaymentDetailsFailure: "[LoanPayment] Get LoanPayment Details Failure",
  GetLenderLoanPaymentDetails: "[LoanPayment] Get Lender LoanPayment Details",
  GetLenderLoanPaymentDetailsSuccess: "[LoanPayment] Get Lender LoanPayment Details Success",
  GetLenderLoanPaymentDetailsFailure: "[LoanPayment] Get Lender LoanPayment Details Failure",
  GetRefundStatus: "[LoanPayment] Get Refund Status",
  GetRefundStatusSuccess: "[LoanPayment] Get Refund Status Success",
  GetRefundStatusFailure: "[LoanPayment] Get Refund Status Failure",
  RefundPayment: "[LoanPayment] Refund Payment",
  RefundPaymentSuccess: "[LoanPayment] Refund Payment Success",
  RefundPaymentFailure: "[LoanPayment] Refund Payment Failure",
  ShiftLoanPayment: "[LoanPayment] Shift Loan Payment",
  ShiftLoanPaymentSuccess: "[LoanPayment] Shift Loan Payment Success",
  ShiftLoanPaymentFailure: "[LoanPayment] Shift Loan Payment Failure",
  GetShiftPaymentStatus: "[LoanPayment] Get Shift Payment Status",
  GetShiftPaymentStatusSuccess: "[LoanPayment] Get Shift Payment Status Success",
  GetShiftPaymentStatusFailure: "[LoanPayment] Get Shift Payment Status Failure",
};

const loanPaymentActions = {
  getLoanPaymentDetails: createAction<LoanIdPayload>(
    loanPaymentActionTypes.GetLoanPaymentDetails,
  ),
  getLoanPaymentDetailsSuccess: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetLoanPaymentDetailsSuccess,
  ),
  getLoanPaymentDetailsFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetLoanPaymentDetailsFailure,
  ),
  getLenderLoanPaymentDetails: createAction<LoanIdPayload>(
    loanPaymentActionTypes.GetLenderLoanPaymentDetails,
  ),
  getLenderLoanPaymentDetailsSuccess: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetLenderLoanPaymentDetailsSuccess,
  ),
  getLenderLoanPaymentDetailsFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetLenderLoanPaymentDetailsFailure,
  ),
  getRefundStatus: createAction<PaymentIdPayload>(
    loanPaymentActionTypes.GetRefundStatus,
  ),
  getRefundStatusSuccess: createAction<RefundStatusResponse>(
    loanPaymentActionTypes.GetRefundStatusSuccess,
  ),
  getRefundStatusFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetRefundStatusFailure,
  ),
  refundPayment: createAction<
    PaymentIdPayload & ShiftPaymentPayload
  >(loanPaymentActionTypes.RefundPayment),
  refundPaymentSuccess: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.RefundPaymentSuccess,
  ),
  refundPaymentFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.RefundPaymentFailure,
  ),
  shiftLoanPayment: createAction<ShiftPaymentPayload>(
    loanPaymentActionTypes.ShiftLoanPayment,
  ),
  shiftLoanPaymentSuccess: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.ShiftLoanPaymentSuccess,
  ),
  shiftLoanPaymentFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.ShiftLoanPaymentFailure,
  ),
  getShiftPaymentStatus: createAction<PaymentIdPayload>(
    loanPaymentActionTypes.GetShiftPaymentStatus,
  ),
  getShiftPaymentStatusSuccess: createAction<ShiftPaymentStatusResponse>(
    loanPaymentActionTypes.GetShiftPaymentStatusSuccess,
  ),
  getShiftPaymentStatusFailure: createAction<LoanPaymentResponse>(
    loanPaymentActionTypes.GetShiftPaymentStatusFailure,
  ),
};

export default loanPaymentActions;

export {
  loanPaymentActionTypes,
};