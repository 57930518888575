import {createAction, createSlice} from "@reduxjs/toolkit";
import {getValueFromQueryParams} from "_metronic";

// ------> Pagination Factory Function <-------
export interface PaginationState {
  pageNo: number;
  pageSize: number;
  paginatedIndexes: { [key: number]: number[] };
}

export interface PaginationPayload {
  pageNo?: number;
  pageSize?: number;
  paginatedIndexes?: { [key: number]: number[] };
}

const getInitialState = (prefix: string): PaginationState => {
  return {
    pageNo: Number(getValueFromQueryParams(`${prefix}PageNo`) || 1),
    pageSize: Number(getValueFromQueryParams(`${prefix}PageSize`) || 10),
    paginatedIndexes: {},
  };
};

const setPaginationFor = (prefix: string) => {
  return createAction<PaginationPayload>(`${prefix}Pagination`);
};

const paginationSliceCreator = (prefix: string) => {
  const paginationAction = setPaginationFor(prefix);
  const paginationSlice = createSlice({
    name: `${prefix}Pagination`,
    initialState: () => getInitialState(prefix),
    reducers: {},
    extraReducers: {
      [paginationAction.type]: (state, action) => {
        state.pageNo = action.payload.pageNo || state.pageNo;
        state.pageSize = action.payload.pageSize || state.pageSize;
        state.paginatedIndexes =
          action.payload.paginatedIndexes || state.paginatedIndexes;
      },
    },
  });
  return paginationSlice.reducer;
};

export {
  paginationSliceCreator,
  setPaginationFor,
  getInitialState,
};
