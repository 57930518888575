import React from "react";
import SubTitle from "../../styles/SubTitle";
import DocumentImageWrapper from "../styles/DocumentImageWrapper";
import DocIconWrapper from "../styles/DocIconWrapper";
import HeaderContainer from "./styles/HeaderContainer";
import BodyContainer from "./styles/BodyContainer";
import Title from "../../styles/Title";
import ColumnWrapper from "../styles/ColumnWrapper";
import DocumentWrapper from "../styles/DocumentWrapper";
import {ZoomOutMap} from "@mui/icons-material";
import {Box} from "@mui/material";
import DocBlacklistedStatusChip from "../styles/DocBlackListedStatusChip";
const PanCard = ({panDetails, setOpenPanModal}) => {
  return (
    <DocumentWrapper>
      <HeaderContainer>
        {panDetails[0].documentType === "FORM60" ? (
          <Title>FORM 60</Title>
        ) : (
          <>
            <Title>PAN</Title>
            {panDetails && (
              <Box mb={1} mr={1}>
                <DocBlacklistedStatusChip
                  className={
                    panDetails[0]?.isBlackListed ? "errorChip" : "successChip"
                  }
                  size="small"
                  label={
                    panDetails[0]?.isBlackListed
                      ? panDetails[0]?.blacklistReason === "IG_EMPLOYEE"
                        ? "Blacklisted: IG employee"
                        : "Blacklisted"
                      : "Approved"
                  }
                />
              </Box>
            )}
          </>
        )}
        <ZoomOutMap onClick={() => setOpenPanModal(true)} />
      </HeaderContainer>
      <BodyContainer>
        <ColumnWrapper>
          <SubTitle>Number</SubTitle>
          <Title>{panDetails[0].documentNumber || "Not Available"}</Title>
          <SubTitle>Remarks</SubTitle>
          <Title>{panDetails[0].remark}</Title>
        </ColumnWrapper>
        <ColumnWrapper>
          <SubTitle>Photo Verification</SubTitle>
          {/* aadhar photo */}
          <DocumentImageWrapper>
            {panDetails[0]?.fileUrl && (
              <DocIconWrapper src={panDetails[0].fileUrl} alt="pan_front" />
            )}
            {panDetails[0]?.backFileUrl && (
              <DocIconWrapper src={panDetails[0].backFileUrl} alt="pan_back" />
            )}
          </DocumentImageWrapper>
        </ColumnWrapper>
      </BodyContainer>
    </DocumentWrapper>
  );
};

export default PanCard;
