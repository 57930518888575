import React, {useState, useRef, useCallback, useEffect} from "react";
import styles from "../../styles";
import getGoldLoanDetail from "../../../../../../../../api/business/getGoldLoanDetail";
import {CircularProgress} from "@mui/material";
import ApplicationGenerated from "./ApplicationGenerated";
import ApplicationNotGenerated from "./ApplicationNotGenerated";
import ReturnGoldValuation from "../ReturnToGoldValuation";

const PersonalLoanNotTaken = ({
  selectedPayload,
  isSameCreditManager,
  ltv,
  getMileStoneOfCr,
  isBackMovement,
  isOfferApplicable,
  isOfferApplied,
  requesting,
  setRequesting,
  onModalHandler,
}) => {
  const classes = styles();

  const [isApplicationGenerated, setIsApplicationGenerated] = useState(false);
  const [goldApplicationNo, setGoldApplciationNo] = useState(null);

  const [state, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      goldLoan: null,
    },
    errorMessage: "",
  });

  const counter = useRef(0);

  const getDetails = useCallback(async () => {
    try {
      const [goldLoanResponse] = await Promise.all([
        getGoldLoanDetail({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "GOLD_LOAN",
        }),
      ]);

      if (goldLoanResponse.statusCode === 200) {
        setIsApplicationGenerated(
          goldLoanResponse.payload.applicationNumber !== null,
        );
        setGoldApplciationNo(goldLoanResponse.payload.applicationNumber);
        setStates({
          loading: false,
          error: false,
          payload: {
            goldLoan: goldLoanResponse.payload,
          },
          errorMessage: "",
        });
      } else {
        setStates({
          loading: false,
          error: true,
          payload: {
            goldLoan: null,
          },
          errorMessage: goldLoanResponse?.error?.toString(),
        });
      }
    } catch (error) {
      setStates({
        loading: false,
        error: true,
        payload: {
          goldLoan: null,
        },
        errorMessage: "Something went wrong",
      });
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    if (counter.current === 0) {
      counter.current = 1;
      getDetails();
    }
  }, [getDetails, selectedPayload.id]);

  if (state.loading) {
    return <CircularProgress />;
  }

  if (state.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{state.errorMessage}</div>
      </div>
    );
  }

  return (
    <div className={classes.textContainer}>
      {isApplicationGenerated ? (
        <ApplicationGenerated
          getMileStoneOfCr={getMileStoneOfCr}
          selectedPayload={selectedPayload}
          isSameCreditManager={isSameCreditManager}
          ltv={ltv}
          goldLoanApplicationNo={goldApplicationNo}
          onModalHandler={onModalHandler}
          requesting={requesting}
        />
      ) : (
        <ApplicationNotGenerated
          requesting={requesting}
          setRequesting={setRequesting}
          payload={state.payload}
          setIsApplicationGenerated={setIsApplicationGenerated}
          getDetails={getDetails}
          selectedPayload={selectedPayload}
          isSameCreditManager={isSameCreditManager}
          ltv={ltv}
          getMileStoneOfCr={getMileStoneOfCr}
          isBackMovement={isBackMovement}
          isOfferApplicable={isOfferApplicable}
          isOfferApplied={isOfferApplied}
        />
      )}
      {state.payload.goldLoan.status !== "COMPLETED" && (
        <ReturnGoldValuation
          {...{
            getMileStoneOfCr,
            selectedPayload,
          }}
        />
      )}
    </div>
  );
};

export default PersonalLoanNotTaken;
