import React, {FC} from "react";
import {
  Box,
  Paper,
} from "@mui/material";
import {
  IGButton,
  IGSelect,
  IGTextField,
} from "app/components";
import {useFormik} from "formik";
import {useSearchParams} from "react-router-dom";
import {useIGTranslation} from "app/infra/plugins/i18n";
import {useIGValidations, useStringValidations} from "app/validations";
import {checkIsAlphanumeric, checkIsNumberic} from "lib/regexChecks";
import {
  customerProfileSelectors,
} from "app/store/customerProfile";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {UploadFileRounded} from "@mui/icons-material";
import {
  openModal,
  resetBatchRefundStatus,
} from "app/store/customerProfile/customerProfile.reducer";
import BatchRefundUpdateModal from "./BatchRefundUpdateModal";
import {authSelectors} from "app/store/auth";
import {RolesEnum} from "app/enums/roles";

interface FiltersProps {}

interface FormValues {
  currentSearchType: string;
  searchText: string;
}

const filterSearchTypes = [
  {
    type: "LOAN_ID",
    typeName: "CR ID",
  },
  {
    type: "MOBILE",
    typeName: "Mobile",
  },
  {
    type: "LANID",
    typeName: "LAN ID",
  },
  {
    type: "SB_LAN_ID",
    typeName: "SB LAN ID",
  },
];
const inputPlaceHolder: Record<string, string> = {
  MOBILE: "Search by Customer Mobile No",
  LOAN_ID: "e.g.- 111111",
  CUSTOMER_NAME: "Search by Customer Name",
  LANID: "e.g.- 54321",
  LENDER_LOAN_ID: "Search by SB LAN ID",
};
const numberSearchTypes = ["LOAN_ID", "MOBILE"];
const Filter: FC<FiltersProps> = () => {
  const dispatch = useAppDispatch();
  const {tErrors} = useIGTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const openModals = useAppSelector(customerProfileSelectors.getOpenModals);
  const user = useAppSelector(authSelectors.getUser);
  const isFinanceTeam = user?.userRoleList?.includes(RolesEnum.FINANCE_TEAM);


  const validationSchema = useIGValidations({
    currentSearchType: useStringValidations({required: true}),
    searchText: useStringValidations({
      custom: {
        name: "isSearchTextValid",
        test: function(value, context) {
          if (
            context.parent.currentSearchType === "LANID" &&
            value &&
            !checkIsAlphanumeric(value, true)
          ) {
            return context.createError({
              message: tErrors("INVALID", {field: "Lan Id"}),
            });
          }
          if (
            context.parent.currentSearchType === "MOBILE" &&
            value &&
            !checkIsNumberic(value, true) &&
            value.trim().length !== 10
          ) {
            return context.createError({
              message: tErrors("INVALID", {field: "Mobile Number"}),
            });
          }
          if (
            context.parent.currentSearchType === "LOAN_ID" &&
            value &&
            !checkIsNumberic(value, true)
          ) {
            return context.createError({
              message: tErrors("TYPE", {type: "integers"}),
            });
          }
          return true;
        },
      },
    }),
  });

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    handleSubmit,
  } = useFormik<FormValues>({
    initialValues: {
      currentSearchType:
        searchParams.get("currentSearchType") || filterSearchTypes[0].type,
      searchText: searchParams.get("searchText") || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      for (const field in values) {
        searchParams.set(field, String(values[field as keyof typeof values]));
      }
      setSearchParams(searchParams);
    },
  });

  return (
    <Box p={3} component={Paper}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={2}>
            <IGSelect
              fullWidth={false}
              sx={{width: 150}}
              name="currentSearchType"
              value={values.currentSearchType}
              onChange={(e) => {
                setFieldValue("searchText", "");
                handleChange(e);
              }}
              options={filterSearchTypes.map((searchType) => ({
                text: searchType.typeName,
                value: searchType.type,
              }))}
              error={Boolean(errors.currentSearchType)}
              touched={Boolean(errors.currentSearchType)}
              helperText={errors.currentSearchType}
            />
            <IGTextField
              fullWidth={false}
              clearable
              sx={{width: 300}}
              name="searchText"
              onChange={handleChange}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await submitForm();
                }
              }}
              onClear={async () => {
                await setFieldValue("searchText", "");
                await submitForm();
              }}
              placeholder={inputPlaceHolder[values.currentSearchType]}
              value={values.searchText}
              type={
                numberSearchTypes.includes(values.currentSearchType)
                  ? "number"
                  : "text"
              }
              error={Boolean(errors.searchText)}
              touched={Boolean(errors.searchText)}
              helperText={errors.searchText}
            />
          </Box>
          {isFinanceTeam && (
            <Box>
              <IGButton
                onClick={() => {
                  dispatch(resetBatchRefundStatus());
                  dispatch(openModal("uploadBatchClosureRefundReport"));
                }}
                startIcon={<UploadFileRounded />}
              >
                Loan Closure Refund
              </IGButton>
            </Box>
          )}
        </Box>
      </form>
      {openModals.uploadBatchClosureRefundReport && (
        <BatchRefundUpdateModal />
      )}
    </Box>
  );
};

export default Filter;
