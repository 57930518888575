import styled from "@emotion/styled";
import {Select} from "@mui/material";

const Header = styled.div`
  width: 100%;
  font-size: 2rem;
  text-align: center;
`;

const Body = styled.div`
  margin: 2rem;
`;

const StyledSelect = styled(Select)<{ marginTop?: string }>`
  margin-top: ${({marginTop}) => marginTop || "1.5rem"};

  min-width: 10rem;
`;

const FilterContainer = styled.div`
  width: 80%;
  gap: 8px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled.button`
  border: none;
  padding: 0.5rem 1.5rem;
  background: #e6cf94;
  color: black;
  border-radius: 4px;
  margin-right: 1rem;

  :disabled {
    background: lightgrey;
  }
`;

export {Header, FilterContainer, StyledButton, Body, StyledSelect};
