import {
  faHandHoldingDollar,
  faCubesStacked,
  faGift,
  faUsers,
  faUsersBetweenLines,
  faUserSlash,
  faPhoneSlash,
  faUserCheck,
  faMoneyCheckDollar,
  faMoneyCheckAlt,
  faChartBar,
  faWandSparkles,
  faLink,
  faFileCsv,
  faUserSecret,
  faUserPlus,
  faUserGear,
  faQuestion,
  faChartPie,
  faUserFriends,
  faUserShield,
  faCalendarAlt,
  faShoppingCart,
  faCoins,
  faCalendarCheck,
  faPiggyBank,
  faReceipt,
  faBell,
  faIdCard,
  faMapLocation,
  faStreetView,
  faEnvelopeOpenText,
  faHeadset,
  faComputer,
  faVault,
  faMoneyBillTransfer,
  faAddressCard,
  faPeopleArrowsLeftRight,
  faUserLock,
  faHammer,
  faUnlockKeyhole,
} from "@fortawesome/free-solid-svg-icons";

export * from "./EMI";
export * from "./DigitalGold";

export interface MenuIconProps {
  isActive?: boolean;
}

const MENU_ICONS = {
  // Digital Gold
  EMI: faHandHoldingDollar,
  DigitalGold: faCubesStacked,
  GiftCardConfig: faGift,
  Users: faUsers,
  AllUsers: faUsersBetweenLines,
  BannedUsers: faUserSlash,
  BannedDevices: faPhoneSlash,
  WhitelistUsers: faUserCheck,
  Payments: faMoneyCheckDollar,
  Payouts: faMoneyCheckAlt,
  Report: faChartBar,
  Promotions: faWandSparkles,
  DynamicLink: faLink,
  Promos: faWandSparkles,
  CSV: faFileCsv,
  InternalUsers: faAddressCard,
  AllInternalUsers: faUserSecret,
  CreateInternalUsers: faUserPlus,
  UserTierConfig: faUserGear,
  Support: faQuestion,
  Analytics: faChartPie,
  AnalyticsReferral: faUserFriends,
  Fraud: faUserShield,
  DailyStatus: faCalendarAlt,
  RedeemOrder: faShoppingCart,
  GoldLoan: faCoins,
  GLCustomers: faUserFriends,
  DetailsOfEmiPayments: faHandHoldingDollar,
  Referral: faPeopleArrowsLeftRight,
  // Gold Loan
  Scheduling: faCalendarCheck,
  ActiveLoans: faPiggyBank,
  Accounts: faReceipt,
  Alerts: faBell,
  CustomerProfiles: faIdCard,
  Tracking: faMapLocation,
  LmDistance: faStreetView,
  Mis: faEnvelopeOpenText,
  Leads: faHeadset,
  ServiceDesk: faComputer,
  // Gold Locker
  LockerJobScheduling: faCalendarAlt,
  ActiveLockerJobs: faVault,
  LockerReleaseRequest: faMoneyBillTransfer,
  BlackListManagement: faUserLock,
  Utilites: faHammer,
  BlackListRemoval :faUnlockKeyhole,
};

export {MENU_ICONS};
