import {createAction} from "@reduxjs/toolkit";
import {UserTierConfigState} from "./reducer";

export const UserTierConfigTypes = {
  GetUserTierConfig: "[User Tier Config] Get User Tier Config",
  GetUserTierConfigSuccess: "[User Tier Config] Get User Tier Config Success",
  GetUserTierConfigError: "[User Tier Config] Get User Tier Config Error",
  UpdateUserTierConfig: "[User Tier Config] Udpate User Tier Config User",
  UpdateUserTierConfigSuccess:
    "[User Tier Config] Udpate User Tier Config Success",
  UpdateUserTierConfigError: "[User Tier Config] Udpate User Tier Config Error",
};

export const userTierConfigAction = {
  getUserTierConfig: createAction(UserTierConfigTypes.GetUserTierConfig),
  getUserTierConfigSuccess: createAction<UserTierConfigState>(
    UserTierConfigTypes.GetUserTierConfigSuccess,
  ),
  getUserTierConfigError: createAction<UserTierConfigState>(
    UserTierConfigTypes.GetUserTierConfigError,
  ),
  updateUserTierConfig: createAction<any>(
    UserTierConfigTypes.UpdateUserTierConfig,
  ),
  updateUserTierConfigSuccess: createAction<UserTierConfigState>(
    UserTierConfigTypes.UpdateUserTierConfigSuccess,
  ),
  updateUserTierConfigError: createAction<UserTierConfigState>(
    UserTierConfigTypes.UpdateUserTierConfigError,
  ),
};
