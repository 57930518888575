const CONSTANTS = {
  ENVIRONMENT: process.env.NODE_ENV,
  DEFAULT_MENU: "AdminMenuConfig",
};

const API_EXCEPTIONS = {
  FWC_TOKEN_EXCEPTION: "FwcTokenException",
};

const USER_ROLES = {
  ADMIN: {
    id: 3,
    name: "ADMIN",
    text: "Admin",
    menu: "AdminMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: true,
  },
  AUGMONT: {
    id: 6,
    name: "AUGMONT",
    text: "Augmont",
    menu: "AugmontMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  A_HIMANSHU: {
    id: 5,
    name: "A_HIMANSHU",
    text: "A_HIMANSHU",
    menu: "HimanshuMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  CST: {
    id: 4,
    name: "CST",
    text: "CST",
    menu: "CstMenuConfig",
    showBulkData: false,
    showDownloadReport: false,
    showAnalytics: false,
  },
  ACCOUNT_TEAM: {
    id: 53,
    name: "ACCOUNT_TEAM",
    text: "Account Team",
    menu: "AccountTeamMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  BANK_MANAGER: {
    id: 9,
    name: "BANK_MANAGER",
    text: "Bank Manager",
    menu: "BankManagerMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  CREDIT_MANAGER: {
    id: 54,
    name: "CREDIT_MANAGER",
    text: "Credit Manager",
    menu: "CreditManagerMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  LOAN_MANAGER: {
    id: 7,
    name: "LOAN_MANAGER",
    text: "Loan Manager",
    menu: "LoanManagerMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  OPS_TEAM: {
    id: 10,
    name: "OPS_TEAM",
    text: "Ops Team",
    menu: "OpsManagerMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  VAULT_MANAGER: {
    id: 8,
    name: "VAULT_MANAGER",
    text: "Vault Manager",
    menu: "VaultManagerMenuConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: false,
  },
  SCHEDULER: {
    id: 56,
    name: "SCHEDULER",
    text: "Schedular",
    menu: "SchedulerMenuConfig",
    showBulkData: false,
    showDownloadReport: false,
    showAnalytics: false,
  },
  FOUNDER: {
    id: 51,
    name: "FOUNDER",
    text: "Founder",
    menu: "FounderConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: true,
  },
  BUSINESS: {
    id: 62,
    name: "BUSINESS",
    text: "Business",
    menu: "BusinessConfig",
    showBulkData: true,
    showDownloadReport: true,
    showAnalytics: true,
  },
  CUSTOMER_SUPPORT: {
    id: 63,
    name: "CUSTOMER_SUPPORT",
    text: "Customer Support",
    menu: "CustomerSupportConfig",
    showBulkData: false,
    showDownloadReport: false,
    showAnalytics: false,
  },
  SENIOR_OPERATIONS: {
    id: 64,
    name: "SENIOR_OPERATIONS",
    text: "Senior Operations",
    menu: "SeniorOperationMenuConfig",
    showBulkData: false,
    showDownloadReport: false,
    showAnalytics: false,
  },
  CUSTOMER_SUPPORT_TEAM_LEAD: {
    id: 65,
    name: "CUSTOMER_SUPPORT_TEAM_LEAD",
    text: "Customer Support Team Lead",
    menu: "CustomerSupportTeamLeadConfig",
    showBulkData: false,
    showDownloadReport: false,
    showAnalytics: false,
  },
  INSIDE_SALES_LOAN: {
    id: 66,
    name: "INSIDE_SALES_LOAN",
    text: "Inside Sales (Loan)",
    menu: "InsideSalesLoanConfig",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  INSIDE_SALES_LOCKER: {
    id: 67,
    name: "INSIDE_SALES_LOCKER",
    text: "Inside Sales (Loker)",
    menu: "InsideSaleLockerConfig",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  INSIDE_SALES_TEAM_LEAD: {
    id: 68,
    name: "INSIDE_SALES_TEAM_LEAD",
    text: "Inside Sales (Team Lead)",
    menu: "insideSaleTeamLeadConfig",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  CITY_HEAD: {
    id: 69,
    name: "CITY_HEAD",
    text: "City Head",
    menu: "CityHeadConfig",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  INSIDE_SALES_AFFILIATE: {
    id: 70,
    name: "INSIDE_SALES_AFFILIATE",
    text: "Inside Sales Affiliate",
    menu: "InsideSalesAffiliate",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  LENDER: {
    id: 76,
    name: "LENDER",
    text: "Lender",
    menu: "LenderConfig",
    showAnalytics: false,
    showDownloadReport: false,
    showBulkData: false,
  },
  AUDIT_MANAGER_HEAD: {
    id: 34,
    name: "AUDIT_MANAGER_HEAD",
    text: "Audit Manager Head",
    menu: "AuditManagerHead",
    showAnalytics: true,
    showDownloadReport: false,
    showBulkData: false,
  },
};

const ALL_USER_ROLES = {
  ADMIN: {
    id: 3,
    new: true,
    roleName: "ADMIN",
  },
  CST: {
    id: 4,
    new: true,
    roleName: "CST",
  },
  A_HIMANSHU: {
    id: 5,
    new: true,
    roleName: "A_HIMANSHU",
  },
  AUGMONT: {
    id: 6,
    new: true,
    roleName: "AUGMONT",
  },
  LOAN_MANAGER: {
    id: 7,
    new: true,
    roleName: "LOAN_MANAGER",
  },
  VAULT_MANAGER: {
    id: 8,
    new: true,
    roleName: "VAULT_MANAGER",
  },
  BANK_MANAGER: {
    id: 9,
    new: true,
    roleName: "BANK_MANAGER",
  },
  OPS_TEAM: {
    id: 10,
    new: true,
    roleName: "OPS_TEAM",
  },
  ACCOUNT_TEAM: {
    id: 53,
    new: true,
    roleName: "ACCOUNT_TEAM",
  },
  CREDIT_MANAGER: {
    id: 54,
    new: true,
    roleName: "CREDIT_MANAGER",
  },
  AUDIT_MANAGER: {
    id: 55,
    new: true,
    roleName: "AUDIT_MANAGER",
  },
  SCHEDULER: {
    id: 56,
    new: true,
    roleName: "SCHEDULER",
  },
  LEVEL1: {
    id: 57,
    new: true,
    roleName: "LEVEL1",
  },
  LEVEL2: {
    id: 58,
    new: true,
    roleName: "LEVEL2",
  },
  LEVEL3: {
    id: 59,
    new: true,
    roleName: "LEVEL3",
  },
  LEVEL4: {
    id: 60,
    new: true,
    roleName: "LEVEL4",
  },
  FOUNDER: {
    id: 61,
    new: true,
    roleName: "FOUNDER",
  },
  BUSINESS: {
    id: 62,
    new: true,
    roleName: "BUSINESS",
  },
  CUSTOMER_SUPPORT: {
    id: 63,
    new: true,
    roleName: "CUSTOMER_SUPPORT",
  },
  SENIOR_OPERATIONS: {
    id: 64,
    new: true,
    roleName: "SENIOR_OPERATIONS",
  },
  CUSTOMER_SUPPORT_TEAM_LEAD: {
    id: 65,
    new: true,
    roleName: "CUSTOMER_SUPPORT_TEAM_LEAD",
  },
  INSIDE_SALES_LOAN: {
    id: 66,
    new: true,
    roleName: "INSIDE_SALES_LOAN",
  },
  INSIDE_SALES_LOCKER: {
    id: 67,
    new: true,
    roleName: "INSIDE_SALES_LOCKER",
  },
  INSIDE_SALES_TEAM_LEAD: {
    id: 68,
    new: true,
    roleName: "INSIDE_SALES_TEAM_LEAD",
  },
  CITY_HEAD: {
    id: 69,
    new: true,
    roleName: "CITY_HEAD",
  },
  SALES_MANAGER: {
    id: 70,
    new: true,
    roleName: "SALES_MANAGER",
  },
  COLLECTION_MANAGER: {
    id: 71,
    new: true,
    roleName: "COLLECTION_MANAGER",
  },
  INSIDE_SALES_AFFILIATE: {
    id: 72,
    new: true,
    roleName: "INSIDE_SALES_AFFILIATE",
  },
};

const API_ENDPOINTS = {
  AUTH: {
    LOGIN: {
      URL: "login",
      METHOD: "POST",
    },
    REGISTER: {
      URL: "signup",
      METHOD: "POST",
    },
    REFRESH_TOKEN: {
      URL: "renew-access-token",
      METHOD: "POST",
    },
    CHANGE_PASSWORD: {
      URL: "change-password",
      METHOD: "POST",
    },
  },
  EMI: {
    DELIVERY_PARTNER_LIST: {
      URL: "/emi/delivery-partner-list",
      METHOD: "GET",
    },
    GET_ALL_EMI_ORDERS: {
      URL: "/emi/order",
      METHOD: "GET",
    },
    GET_ALL_EMI_OF_ORDER: {
      URL: "/emi/order/:emiOrderId/emi",
      METHOD: "GET",
    },
    DOWNLOAD_ORDER_SUMMARY: {
      URL: "/emi/download-order-summary",
      METHOD: "GET",
    },
    DOWNLOAD_CANCEL_SUMMARY: {
      URL: "/emi/download-cancel-summary",
      METHOD: "GET",
    },
    DOWNLOAD_CATALOG: {
      URL: "/emi/download-catalog",
      METHOD: "GET",
    },
    UPLOAD_ORDER_SUMMARY: {
      URL: "/emi/upload-catalog",
      METHOD: "POST",
    },
    GET_ORDER_INVOICE: {
      URL: "/emi/order/:emiOrderId/invoice",
      METHOD: "GET",
    },
    GET_ORDER_PERFORMA_INVOICE: {
      URL: "/emi/order/:emiOrderId/performa-invoice",
      METHOD: "GET",
    },
    GET_EMI_INVOICE: {
      URL: "/emi/order-emi/:emiId/invoice",
      METHOD: "GET",
    },
    CHANGE_ORDER_STATUS: {
      URL: "/emi/order/:emiOrderId/change-order-status",
      METHOD: "PUT",
    },
    GET_SINGLE_ORDER: {
      URL: "/emi/order/:emiOrderId",
      METHOD: "GET",
    },
    CANCEL_ORDER: {
      URL: "/emi/order/:emiOrderId/cancel",
      METHOD: "POST",
    },
    GET_ALL_EMI_PRODUCTS: {
      URL: "/emi/product",
      METHOD: "GET",
    },
    GET_ALL_PRODUCT_CATEGORIES: {
      URL: "/emi/product/category/list",
      METHOD: "GET",
    },
    GET_EMI_ORDER_STATS: {
      URL: "/emi/user/:userId/order-stats",
      METHOD: "POST",
    },
  },
  USER: {
    GET_ALL_DOCUMENTS: {
      URL: "/user/:userId/view-kyc-documents",
      METHOD: "GET",
    },
    DOWNLOAD_DOCUMENT: {
      URL: "/user/download-kyc-document",
      METHOD: "GET",
    },
    GET_ALL_USERS: {
      URL: "/user-list",
      METHOD: "GET",
    },
    CHANGE_USER_STATUS: {
      URL: "/user/:userId/change-status/:status",
      METHOD: "PUT",
    },
    GET_USER_DETAILS: {
      URL: "/user/:userId",
      METHOD: "GET",
    },
    GET_USER_VAULT: {
      URL: "/user/:userId/vault",
      METHOD: "GET",
    },
    GET_BULK_USER_VAULT: {
      URL: "/user/vault",
      METHOD: "POST",
    },
    GET_UPI_DETAILS: {
      URL: "/user/:userId/upi-detail",
      METHOD: "GET",
    },
    GET_BANK_DETAILS: {
      URL: "/user/:userId/bank-detail",
      METHOD: "GET",
    },
    GET_CARD_DETAILS: {
      URL: "/user/:userId/card-detail",
      METHOD: "GET",
    },
    GET_DEVICE_DETAILS: {
      URL: "/user/:userId/device-detail",
      METHOD: "GET",
    },
    GET_ADDRESS_DETAILS: {
      URL: "/user/:userId/address",
      METHOD: "GET",
    },
    GET_USER_PERMISSION: {
      URL: "/user/:userId/user-permission",
      METHOD: "GET",
    },
    GET_DEVICE_LIST: {
      URL: "/user/:userId/device-list",
      METHOD: "GET",
    },
    GET_PRICE: {
      URL: "price",
      METHOD: "GET",
    },
    CHANGE_SELL_STATUS: {
      URL: "/sell-status",
      METHOD: "PUT",
    },
    GET_SELL_BANNED: {
      URL: "/sell-ban",
      METHOD: "GET",
    },
    UPLOAD_GOLD_LOAN_FILE: {
      URL: "/gold-loan",
      METHOD: "POSt",
    },
    GET_USER_GIFT_ORDER_PARENT: {
      URL: "/gift/order/:orderId/sender",
      METHOD: "GET",
    },
  },
  INTERNAL_USER: {
    GET_ALL_INTERNAL_USERS: {
      URL: "/internal-user-list",
      METHOD: "GET",
    },
    GET_USERS_ROLES: {
      URL: "/internal-user/:userId/roles",
      METHOD: "GET",
    },
    GET_ALL_ROLES: {
      URL: "/internal-user/all-roles",
      METHOD: "GET",
    },
    UPDATE_USER_ROLES: {
      URL: "/internal-user/:userId/update-roles",
      METHOD: "PUT",
    },
    CREATE_USER: {
      URL: "/internal-user",
      METHOD: "POST",
    },
    GET_ALL_CITIES: {
      URL: "/internal-user/all-city",
      METHOD: "GET",
    },
    CHANGE_LM_USER_STATUS: {
      URL: "/admin/business/lm-user/:userId/change-status/:status",
      METHOD: "PUT",
    },
  },
  PROMO: {
    GET_ALL_PROMOS: {
      URL: "/promo/v2/campaign",
      METHOD: "GET",
    },
    GET_PROMO_RULES: {
      URL: "/promo/v2/campaign/:promoCampaignId/rules",
      METHOD: "GET",
    },
    GET_CAMPAIGN_RULES: {
      URL: "/promo/v2/campaign/ordered-rules",
      METHOD: "GET",
    },
    GET_ALL_CAMPAIGN_RULES: {
      URL: "/promo/v2/campaign/rules-keys",
      METHOD: "GET",
    },
    CREATE_NEW_PROMO: {
      URL: "/promo/v2/campaign",
      METHOD: "POST",
    },
    UPDATE_PROMO_STATUS: {
      URL: "/promo/v2/campaign/:promoCampaignId",
      METHOD: "PUT",
    },
    CANCEL_GOLDBACK: {
      URL: "/dg/order/mark-reverted-as-failed",
      METHOD: "POST",
    },
    RETRY_GOLDBACK: {
      URL: "/promo/:orderId/retry-goldback",
      METHOD: "POST",
    },
    DELETE_PROMO: {
      URL: "/promo/v2/campaign/:promoCampaignId",
      METHOD: "DELETE",
    },
  },
  DG: {
    GET_ALL_DG_ORDERS: {
      URL: "/dg/order",
      METHOD: "GET",
    },
    GET_ORDER_INVOICE: {
      URL: "/dg/order/:orderId/invoice",
      METHOD: "GET",
    },
    DOWNLOAD_ORDER_SUMMARY: {
      URL: "/dg/download-order-summary",
      METHOD: "GET",
    },
    GET_SINGLE_ORDER: {
      URL: "/dg/order/:orderId",
      METHOD: "GET",
    },
    GET_DG_ORDER_STATS: {
      URL: "/dg/user/:userId/order-stats",
      METHOD: "POST",
    },
  },
  PAYMENT: {
    GET_ALL_PAYMENTS: {
      URL: "/payment",
      METHOD: "GET",
    },
    GET_ALL_PAYOUTS: {
      URL: "/payment/payout",
      METHOD: "GET",
    },
    DOWNLOAD_PAYMENT_PAYOUT_SUMMARY: {
      URL: "payment/summary",
      METHOD: "GET",
    },
    DOWNLOAD_PAYMENT_SUMMARY: {
      URL: "payment/payment-summary",
      METHOD: "GET",
    },
    DOWNLOAD_PAYOUT_SUMMARY: {
      URL: "payment/payout-summary",
      METHOD: "GET",
    },
    GET_PAYMENT_FOR_REFERENCE: {
      URL: "payment/reference/:referenceId",
      METHOD: "GET",
    },
    GET_PAYOUT_FOR_REFERENCE: {
      URL: "payment/payout/reference/:referenceId",
      METHOD: "GET",
    },
    GET_BULK_PAYMENT_FOR_REFERENCE: {
      URL: "payment/bulk/reference-id",
      METHOD: "POST",
    },
    GET_BULK_PAYOUT_FOR_REFERENCE: {
      URL: "payment/payout/bulk/reference-id",
      METHOD: "POST",
    },
    GET_PAYMENT_FOR_ID: {
      URL: "payment/id/:id",
      METHOD: "GET",
    },
    GET_PAYOUT_FOR_ID: {
      URL: "payment/payout/id/:id",
      METHOD: "GET",
    },
  },
  PROMOTION: {
    GET_ALL_CAMPAIGNS: {
      URL: "/promo/gift-campaign",
      METHOD: "GET",
    },
    CREATE_CAMPAIGN: {
      URL: "/promo/gift-campaign",
      METHOD: "POST",
    },
    DISTRIBUTE_GIFT: {
      URL: "/promo/distribute-gift",
      METHOD: "POST",
    },
    GET_AFFILIATE_PARTNERS: {
      URL: "/promo/affiliate-partner",
      METHOD: "GET",
    },
    GET_PROMO_PARENT_ORDER_INFO: {
      URL: "/promo/:orderId/get-promo-parent",
      METHOD: "GET",
    },
  },
  SUPPORT: {
    GET_TICKET_CATEGORY_LIST: {
      URL: "/support/ticket/category/list",
      METHOD: "GET",
    },
  },
  ANALYTICS: {
    GET_METABASE_EMBED_LINK: {
      URL: "/analytics/metabase-embed-url",
      METHOD: "GET",
    },
    GET_ANALYTICS_TABS: {
      URL: "/analytics/tab-list",
      METHOD: "GET",
    },
  },
  REFERRAL: {
    GET_USER_ADVOCATE: {
      URL: "/referral/user/:userId/advocate",
      METHOD: "GET",
    },
    GET_USER_FRIENDS: {
      URL: "/referral/user/:userId/friend",
      METHOD: "GET",
    },
  },
  APP_CONFIG: {
    GET_ALL_DEEP_LINKS: {
      URL: "/app-config/deep-link",
      METHOD: "GET",
    },
    GET_ALL_DYNAMIC_LINKS: {
      URL: "/app-config/dynamic-link",
      METHOD: "GET",
    },
    CREATE_DYNAMIC_LINKS: {
      URL: "/app-config/dynamic-link",
      METHOD: "POST",
    },
    GET_BANNED_DEVICES: {
      URL: "/app-config/device-blacklist",
      METHOD: "GET",
    },
    BAN_DEVICE: {
      URL: "/app-config/device-blacklist",
      METHOD: "POST",
    },
    GET_USERS_FOR_DEVICE: {
      URL: "/app-config/device-user-list",
      METHOD: "GET",
    },
  },
};

export {CONSTANTS, API_ENDPOINTS, API_EXCEPTIONS, USER_ROLES, ALL_USER_ROLES};
