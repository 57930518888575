import styled from "@emotion/styled";
import {Drawer} from "@mui/material";
import {makeStyles} from "@mui/styles";
import "./AnonymousPro-Regular.ttf";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "white",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: "80%",
    minHeight: "92vh",
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root1: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  count: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0.2rem",
    fontWeight: 500,
    background: "#b00020",
    color: "white",
    borderRadius: "50%",
    minWidth: "2rem",
    minHeight: "2rem",
    padding: "0.2rem",
  },
  activeButton: {
    background: "rgba(206, 159, 41, 0.5)",
  },
  notification: {
    display: "flex",
    alignItems: "center",
  },
  notificationIcon: {
    "&:hover": {
      background: "pink",
    },
  },
  selected: {
    backgroundColor: "rgba(206,159,41,0.08)",
    "&>div>span": {
      color: "#ce9f29",
    },
  },
  listItemText: {
    color: "black",
  },
  multiMenuActive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  expandIcon: {
    fill: "black",
  },
  serverContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem",
    background: "#000",
  },
  serverLabel: {
    color: "#bfffa5",
    fontWeight: 500,
  },
}));

export const StyledNavDrawer = styled(Drawer)`
  transition: ${(props) => {
    return `width ${
      props.theme.transitions.duration[
        props.open ? "enteringScreen" : "leavingScreen"
      ]
    }ms ${props.theme.transitions.easing.sharp}, margin ${
      props.theme.transitions.duration[
        [props.open ? "enteringScreen" : "leavingScreen"]
      ]
    }ms ${props.theme.transitions.easing.sharp}`;
  }};
`;

export default useStyles;
