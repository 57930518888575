import {goldApiService} from "app/infra/plugins/axios";
import {LenderClosureJobApiInterface, LenderClosureJobApiResources} from "app/store/lenderClosureJob/lenderClosureJob.types";

const CUSTOMER_PROFILE_ENDPOINTS: LenderClosureJobApiResources = {
  GET_CLOSURE_JOB: {
    URL: "/admin/business/lender/job-list/closure",
    METHOD: "GET",
  },
  GET_GOLD_HANDOVER_JOB: {
    URL: "/admin/business/lender/job-list/gold-handover",
    METHOD: "GET",
  },
  GET_LM_USER_LIST: {
    URL: "/admin/business/lender/agent/list",
    METHOD: "GET",
  },
  ASSIGN_AGENT: {
    URL: "/admin/business/lender/cr-job/:crJobId/assign",
    METHOD: "PUT",
  },
  REASSIGN_AGENT: {
    URL: "/admin/business/lender/cr-job/:crJobId/reassign",
    METHOD: "PUT",
  },
  UPDATE_JOB_STATUS: {
    URL: "/admin/business/lender/closure-cr/:crId/job/:crJobId/status",
    METHOD: "POST",
  },
  GET_LOAN_DETAILS: {
    URL: "/admin/business/lender/closure/:serviceRequestId/detail",
    METHOD: "GET",
  },
  GET_PAYMENT_DETAILS: {
    URL: "/admin/business/lender/loan/:loanId/payment",
    METHOD: "GET",
  },
  UNASSIGN_AGENT: {
    URL: "/admin/business/lender/cr-job/:crJobId/remove",
    METHOD: "PUT",
  },
  GET_CLOSURE_JOB_DETAILS: {
    URL: "/admin/business/lender/cr-job/:crJobId/gold-closure/detail",
    METHOD: "GET",
  },
  GET_AGENT_LIST: {
    URL: "/admin/business/lender/gold-handover/:crJobId/ig-agent/list",
    METHOD: "GET",
  },
  SEND_OTP: {
    URL: "/admin/business/lender/cr-job/:crJobId/send-otp/:igAgentId",
    METHOD: "POST",
  },
  VERIFY_OTP: {
    URL: "/admin/business/lender/cr-job/:crJobId/verify-otp/:igAgentId",
    METHOD: "POST",
  },
  GET_HANDOVER_JOB_DETAILS: {
    URL: "/admin/business/lender/cr-job/:crJobId/gold-handover/detail",
    METHOD: "GET",
  },
  GET_CITY_LIST: {
    URL: "/admin/business/lender/city-list",
    METHOD: "GET",
  },
};

const lenderClosureJobApi: LenderClosureJobApiInterface = {
  getClosureJobs: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CLOSURE_JOB,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  getHandoverJobs: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_GOLD_HANDOVER_JOB,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  getLmUserList: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_LM_USER_LIST,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  reassignAgent: async ({
    crJobId,
    agentId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.REASSIGN_AGENT,
      options: {
        pathVars: {
          crJobId,
        },
        data: {
          agentId,
        },
      },
    });
  },
  updateJobStatus: async ({
    crJobId,
    crId,
    status,
    remark,
    remarkEnum,
    loanClosureAmount,
    foreClosureCharge,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.UPDATE_JOB_STATUS,
      options: {
        pathVars: {
          crJobId,
          crId,
        },
        data: {
          status,
          remark,
          remarkEnum,
          loanClosureAmount,
          foreClosureCharge,
        },
      },
    });
  },
  getLoanDetails: async ({
    serviceRequestId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_LOAN_DETAILS,
      options: {
        pathVars: {
          serviceRequestId,
        },
      },
    });
  },
  getPaymentDetails: async ({
    loanId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_PAYMENT_DETAILS,
      options: {
        pathVars: {
          loanId,
        },
      },
    });
  },
  unassignAgent: async ({
    crJobId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.UNASSIGN_AGENT,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  assignAgent: async ({
    crJobId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.ASSIGN_AGENT,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  getClosureJobDetails: async ({
    crJobId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CLOSURE_JOB_DETAILS,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  getAgentList: async ({
    crJobId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_AGENT_LIST,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  sendOtp: async ({
    crJobId,
    igAgentId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.SEND_OTP,
      options: {
        pathVars: {
          crJobId,
          igAgentId,
        },
      },
    });
  },
  verifyOtp: async ({
    crJobId,
    igAgentId,
    otp,
    otpId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.VERIFY_OTP,
      options: {
        pathVars: {
          crJobId,
          igAgentId,
        },
        data: {
          otp,
          otpId,
        },
      },
    });
  },
  getHandoverJobDetails: async ({
    crJobId,
  }) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_HANDOVER_JOB_DETAILS,
      options: {
        pathVars: {
          crJobId,
        },
      },
    });
  },
  getCityList: async () => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CITY_LIST,
    });
  },
};

export default lenderClosureJobApi;