import React, {useMemo} from "react";
import {Box, capitalize, LinearProgress} from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridRowsProp,
  GridValidRowModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import {IGTableWrapper} from "./style";
import IGTablePagination, {IGTablePaginationProps} from "./IGTablePagination";

export interface IGTableColsInterface<T extends GridValidRowModel>
  extends GridColumns<T> {}

export interface IGTableRowsInterface<T> extends GridRowsProp<T> {}

interface IGTableProps<T extends GridValidRowModel>
  extends Partial<Omit<DataGridProps<T>, "onRowClick">>,
    IGTablePaginationProps {
  tableCols: IGTableColsInterface<T>;
  tableData: IGTableRowsInterface<T>;
  selectedRowId?: number;
  pagination?: true | undefined;
  page?: number;
  pageSizes?: number[];
  onPageChange?: (newPage: number) => void;
  totalRows?: number;
  pageSize?: number;
  loading?: boolean;
  onRowClick?: (row: T) => void;
  onPageSizeChange?: (pageSize: number) => void;
}

/**
 * General Table component which uses mui 5 data grid as Base.
 * @param tableCols
 * @param tableData
 * @param selectedRowId
 * @param loading
 * @param pagination
 * @param page
 * @param totalRows
 * @param pageSize
 * @param pageSizes
 * @param onPageSizeChange
 * @param onRowClick
 * @param onPageChange
 * @param disabledNext
 * @param disabledPrev
 * @param components
 * @param paginationType
 * @param props
 * @constructor
 */
function IGTable<T extends GridValidRowModel>({
  tableCols = [],
  tableData = [],
  selectedRowId = 0,
  loading = false,
  pagination = undefined,
  page = 0,
  totalRows = tableData.length,
  pageSize = 10,
  pageSizes = [10, 25, 50],
  onPageSizeChange,
  onRowClick,
  onPageChange,
  disabledNext,
  disabledPrev,
  components,
  paginationType = "multiple",
  ...props
}: IGTableProps<T>) {
  const [isPageSizeChanged, setIsPageSizeChanged] = React.useState(false);
  const tableColumns: IGTableColsInterface<T> = useMemo(() => {
    return tableCols.map((col) => {
      const updatedCol = {
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "ig-table--header",
        ...col,
      };
      // Render "-" if value not found
      if (!("renderCell" in col)) {
        updatedCol.renderCell = ({value}) => (
          <>{value ? capitalize(String(value).toLowerCase()) : "-"}</>
        );
      }
      return updatedCol;
    });
  }, [tableCols]);

  // calculate totalRowCount based on paginationType as this is required for pagination to work
  const rowCount = useMemo(() => {
    if (tableData.length === 0) return 0;
    if (paginationType === "single") {
      return tableData.length === pageSize
        ? pageSize * page + 1
        : pageSize * (page - 1) + tableData.length;
    }
    return totalRows;
  }, [page, pageSize, totalRows, tableData, paginationType]);

  return (
    <IGTableWrapper>
      <Box flexGrow={1}>
        <DataGrid<T>
          autoHeight
          loading={loading}
          columns={tableColumns}
          rows={tableData}
          paginationMode="server"
          page={page - 1}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setIsPageSizeChanged(true);
            onPageSizeChange && onPageSizeChange(newPageSize);
          }}
          rowCount={rowCount}
          onPageChange={(newPage) => {
            // Refer: https://github.com/mui/mui-x/issues/3516
            // onPageChange is called when page size is changed
            // This is a workaround to avoid calling onPageChange twice
            // because we are using server side pagination
            if (isPageSizeChanged) {
              setIsPageSizeChanged(false);
              return;
            }
            onPageChange && onPageChange(newPage + 1);
          }}
          selectionModel={[selectedRowId]}
          onRowClick={({row}) => onRowClick && onRowClick(row)}
          hideFooterSelectedRowCount={true}
          headerHeight={32}
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: (params) => {
              const apiRef = useGridApiContext();

              if (!(apiRef && apiRef.current && apiRef.current.windowRef)) return <></>;
              return (
                <IGTablePagination
                  disabledNext={disabledNext}
                  disabledPrev={disabledPrev}
                  paginationType={paginationType}
                  pageSizes={pageSizes}
                  {...params}
                />
              );
            },
            ...components,
          }}
          {...props}
        />
      </Box>
    </IGTableWrapper>
  );
}

export default IGTable;
