import {createAction} from "@reduxjs/toolkit";
import {setPaginationFor} from "../factoryReducers";
import {
  ClosureJobPayload,
  CrIdPayload,
  CrJobIdPayload,
  GetLMUserListPayload,
  LmAgent,
  UpdateCRJobStatusPayload,
  ReassignAgentPayload,
  RequestLoanDetails,
  RequestPaymentDetails,
  ClosureJobDetails,
  SendOtpPayload,
  VerifyOtpPayload,
  HandoverJobDetails,
  LenderCity,
} from "./lenderClosureJob.types";
import {LenderClosureJob} from "app/models/lenderClosureJob.model";
import {ServiceRequestIdPayload} from "app/infra/services/api/rateChange/rateChange.types";
import {LoanIdPayload} from "app/infra/services/api/serviceDesk/serviceDesk.types";

const lenderClosureJobActionTypes = {
  GetClosureJobs: "[LenderClosureJob] Get Closure Jobs",
  getClosureJobsSuccess: "[LenderClosureJob] Get Closure Jobs Success",
  getClosureJobsFailure: "[LenderClosureJob] Get Closure Job List Failure",
  GetHandoverImages: "[LenderClosureJob] Get Handover Images",
  GetHandoverImagesSuccess: "[LenderClosureJob] Get Handover Images Success",
  GetHandoverImagesFailure: "[LenderClosureJob] Get Handover Images Failure",
  GetLMUserList: "[LenderClosureJob] Get LM User List",
  GetLMUserListSuccess: "[LenderClosureJob] Get LM User List Success",
  GetLMUserListFailure: "[LenderClosureJob] Get LM User List Failure",
  UpdateJobStatus: "[LenderClosureJob] Update Job Status",
  UpdateJobStatusSuccess: "[LenderClosureJob] Update Job Status Success",
  UpdateJobStatusFailure: "[LenderClosureJob] Update Job Status Failure",
  UnassignAgent: "[LenderClosureJob] Unassign Agent",
  UnassignAgentSuccess: "[LenderClosureJob] Unassign Agent Success",
  UnassignAgentFailure: "[LenderClosureJob] Unassign Agent Failure",
  AssignAgent: "[LenderClosureJob] Assign Agent",
  AssignAgentSuccess: "[LenderClosureJob] Assign Agent Success",
  AssignAgentFailure: "[LenderClosureJob] Assign Agent Failure",
  ReassignAgent: "[LenderClosureJob] Reassign Agent",
  ReassignAgentSuccess: "[LenderClosureJob] Reassign Agent Success",
  ReassignAgentFailure: "[LenderClosureJob] Reassign Agent Failure",
  GetAgentList: "[LenderClosureJob] Get Agent List",
  GetAgentListSuccess: "[LenderClosureJob] Get Agent List Success",
  GetAgentListFailure: "[LenderClosureJob] Get Agent List Failure",
  GetLoanDetails: "[LenderClosureJob] Get Loan Details",
  GetLoanDetailsSuccess: "[LenderClosureJob] Get Loan Details Success",
  GetLoanDetailsFailure: "[LenderClosureJob] Get Loan Details Failure",
  GetPaymentDetails: "[LenderClosureJob] Get Payment Details",
  GetPaymentDetailsSuccess: "[LenderClosureJob] Get Payment Details Success",
  GetPaymentDetailsFailure: "[LenderClosureJob] Get Payment Details Failure",
  GetClosureJobDetails: "[LenderClosureJob] Get Closure Job Details",
  GetClosureJobDetailsSuccess:
    "[LenderClosureJob] Get Closure Job Details Success",
  GetClosureJobDetailsFailure:
    "[LenderClosureJob] Get Closure Job Details Failure",
  SendOtp: "[LenderClosureJob] Send Otp",
  SendOtpSuccess: "[LenderClosureJob] Send Otp Success",
  SendOtpFailure: "[LenderClosureJob] Send Otp Failure",
  VerifyOtp: "[LenderClosureJob] Verify Otp",
  VerifyOtpSuccess: "[LenderClosureJob] Verify Otp Success",
  VerifyOtpFailure: "[LenderClosureJob] Verify Otp Failure",
  GetHandoverJobDetails: "[LenderClosureJob] Get Handover Job Details",
  GetHandoverJobDetailsSuccess:
    "[LenderClosureJob] Get Handover Job Details Success",
  GetHandoverJobDetailsFailure:
    "[LenderClosureJob] Get Handover Job Details Failure",
  GetCityList: "[LenderClosureJob] Get City List",
  GetCityListSuccess: "[LenderClosureJob] Get City List Success",
  GetCityListFailure: "[LenderClosureJob] Get City List Failure",
};

const lenderLoanActions = {
  getClosureJobs: createAction<ClosureJobPayload>(
    lenderClosureJobActionTypes.GetClosureJobs,
  ),
  getClosureJobsSuccess: createAction<LenderClosureJob[]>(
    lenderClosureJobActionTypes.getClosureJobsSuccess,
  ),
  getClosureJobsFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.getClosureJobsFailure,
  ),
  getHandoverImages: createAction<CrIdPayload>(
    lenderClosureJobActionTypes.GetHandoverImages,
  ),
  getHandoverImagesSuccess: createAction<{
    response: {
      goldHandoverImageUrl: string;
      signedPledgeCardImages: string[];
    };
    crId: number;
  }>(lenderClosureJobActionTypes.GetHandoverImagesSuccess),
  getHandoverImagesFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetHandoverImagesFailure,
  ),
  getLmUserList: createAction<GetLMUserListPayload>(
    lenderClosureJobActionTypes.GetLMUserList,
  ),
  getLmUserListSuccess: createAction<LmAgent[]>(
    lenderClosureJobActionTypes.GetLMUserListSuccess,
  ),
  getLmUserListFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetLMUserListFailure,
  ),
  updateJobStatus: createAction<UpdateCRJobStatusPayload>(
    lenderClosureJobActionTypes.UpdateJobStatus,
  ),
  updateJobStatusSuccess: createAction<{
    response: any;
  }>(lenderClosureJobActionTypes.UpdateJobStatusSuccess),
  updateJobStatusFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.UpdateJobStatusFailure,
  ),
  unassignAgent: createAction<CrJobIdPayload>(
    lenderClosureJobActionTypes.UnassignAgent,
  ),
  unassignAgentSuccess: createAction<{
    response: any;
  }>(lenderClosureJobActionTypes.UnassignAgentSuccess),
  unassignAgentFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.UnassignAgentFailure,
  ),
  assignAgent: createAction<CrJobIdPayload>(
    lenderClosureJobActionTypes.AssignAgent,
  ),
  assignAgentSuccess: createAction<{
    response: any;
  }>(lenderClosureJobActionTypes.AssignAgentSuccess),
  assignAgentFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.AssignAgentFailure,
  ),
  reassignAgent: createAction<ReassignAgentPayload>(
    lenderClosureJobActionTypes.ReassignAgent,
  ),
  reassignAgentSuccess: createAction<{
    response: any;
  }>(lenderClosureJobActionTypes.ReassignAgentSuccess),
  reassignAgentFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.ReassignAgentFailure,
  ),
  getLoanDetails: createAction<ServiceRequestIdPayload>(
    lenderClosureJobActionTypes.GetLoanDetails,
  ),
  getLoanDetailsSuccess: createAction<RequestLoanDetails>(
    lenderClosureJobActionTypes.GetLoanDetailsSuccess,
  ),
  getLoanDetailsFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetLoanDetailsFailure,
  ),
  getPaymentDetails: createAction<LoanIdPayload>(
    lenderClosureJobActionTypes.GetPaymentDetails,
  ),
  getPaymentDetailsSuccess: createAction<RequestPaymentDetails[]>(
    lenderClosureJobActionTypes.GetPaymentDetailsSuccess,
  ),
  getPaymentDetailsFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetPaymentDetailsFailure,
  ),
  getClosureJobDetails: createAction<CrJobIdPayload>(
    lenderClosureJobActionTypes.GetClosureJobDetails,
  ),
  getClosureJobDetailsSuccess: createAction<ClosureJobDetails>(
    lenderClosureJobActionTypes.GetClosureJobDetailsSuccess,
  ),
  getClosureJobDetailsFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetClosureJobDetailsFailure,
  ),
  getAgentList: createAction<CrJobIdPayload>(
    lenderClosureJobActionTypes.GetAgentList,
  ),
  getAgentListSuccess: createAction<LmAgent[]>(
    lenderClosureJobActionTypes.GetAgentListSuccess,
  ),
  getAgentListFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetAgentListFailure,
  ),
  sendOtp: createAction<SendOtpPayload>(lenderClosureJobActionTypes.SendOtp),
  sendOtpSuccess: createAction<{ otpId: string }>(
    lenderClosureJobActionTypes.SendOtpSuccess,
  ),
  sendOtpFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.SendOtpFailure,
  ),
  verifyOtp: createAction<VerifyOtpPayload>(
    lenderClosureJobActionTypes.VerifyOtp,
  ),
  verifyOtpSuccess: createAction<{ response: any }>(
    lenderClosureJobActionTypes.VerifyOtpSuccess,
  ),
  verifyOtpFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.VerifyOtpFailure,
  ),
  getHandoverJobDetails: createAction<CrJobIdPayload>(
    lenderClosureJobActionTypes.GetHandoverJobDetails,
  ),
  getHandoverJobDetailsSuccess: createAction<HandoverJobDetails>(
    lenderClosureJobActionTypes.GetHandoverJobDetailsSuccess,
  ),
  getHandoverJobDetailsFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetHandoverJobDetailsFailure,
  ),
  getCityList: createAction(lenderClosureJobActionTypes.GetCityList),
  getCityListSuccess: createAction<LenderCity[]>(
    lenderClosureJobActionTypes.GetCityListSuccess,
  ),
  getCityListFailure: createAction<{ error: string }>(
    lenderClosureJobActionTypes.GetCityListFailure,
  ),
  setLenderClosureJobPagination: setPaginationFor("lenderClosureJob"),
};
export default lenderLoanActions;

export {lenderClosureJobActionTypes};
