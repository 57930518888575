import styled from "@emotion/styled";

const MiniTitle = styled.div`
  font-size: 1rem;

  line-height: 1.33;
  letter-spacing: 0.4px;
  width: 30%;
  font-weight: 500;
`;

export default MiniTitle;
