import {GoldApiError} from "../../typings/api/goldApi.types";
import {
  OverduePaymentDetailInterface,
  ReminderInterface,
} from "./customerProfile.types";
import {createSlice} from "@reduxjs/toolkit";
import customerProfileActions from "./customerProfile.actions";
import {
  CustomerProfileModel,
  InwardReceiptImage,
} from "../../models/customerProfile.model";

type LoadingErrorState =
  | "getOverduePaymentDetails"
  | "getReminders"
  | "sendReminder"
  | "initializeCustomerProfile"
  | "getCustomerProfileList"
  | "getCustomerDetails"
  | "getInwardReceiptImage"
  | "uploadBatchClosureRefundReport";

export interface CustomerProfileState {
  customerProfileList: CustomerProfileModel[];
  loading: Record<LoadingErrorState, boolean>;
  errors: Record<LoadingErrorState, GoldApiError | null>;
  overduePaymentDetail: OverduePaymentDetailInterface | null;
  reminderList: ReminderInterface[];
  inwardReceiptImage: Record<number, InwardReceiptImage>;
  openModals: Record<string, boolean>;
  batchRefundStatus: Array<{
    loanId: number;
    message: string;
    isSuccessful: boolean
  }>;
}

const initialState: CustomerProfileState = {
  customerProfileList: [],
  loading: {
    getOverduePaymentDetails: false,
    getReminders: false,
    sendReminder: false,
    initializeCustomerProfile: false,
    getCustomerProfileList: false,
    getCustomerDetails: false,
    getInwardReceiptImage: false,
    uploadBatchClosureRefundReport: false,
  },
  errors: {
    getOverduePaymentDetails: null,
    getReminders: null,
    sendReminder: null,
    initializeCustomerProfile: null,
    getCustomerProfileList: null,
    getCustomerDetails: null,
    getInwardReceiptImage: null,
    uploadBatchClosureRefundReport: null,
  },
  overduePaymentDetail: null,
  reminderList: [],
  inwardReceiptImage: {},
  openModals: {},
  batchRefundStatus: [],
};

const customerProfileSlice = createSlice({
  name: "customerProfile",
  initialState,
  reducers: {
    openModal(state, action) {
      state.openModals[action.payload] = true;
    },
    closeModal(state, action) {
      state.openModals[action.payload] = false;
    },
    resetBatchRefundStatus(state) {
      state.batchRefundStatus = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerProfileActions.getOverduePaymentDetails, (state) => {
        state.loading.getOverduePaymentDetails = true;
        state.overduePaymentDetail = initialState.overduePaymentDetail;
        state.errors.getOverduePaymentDetails =
          initialState.errors.getOverduePaymentDetails;
      })
      .addCase(
        customerProfileActions.getOverduePaymentDetailsSuccess,
        (state, action) => {
          state.loading.getOverduePaymentDetails = false;
          state.overduePaymentDetail = action.payload.response;
        },
      )
      .addCase(
        customerProfileActions.getOverduePaymentDetailsFailure,
        (state, action) => {
          state.loading.getOverduePaymentDetails = false;
          state.errors.getOverduePaymentDetails = action.payload.error;
        },
      )
      .addCase(customerProfileActions.getReminders, (state) => {
        state.loading.getReminders = true;
        state.reminderList = initialState.reminderList;
        state.errors.getReminders = initialState.errors.getReminders;
      })
      .addCase(customerProfileActions.getRemindersSuccess, (state, action) => {
        state.loading.getReminders = false;
        state.reminderList = action.payload.response;
      })
      .addCase(customerProfileActions.getRemindersFailure, (state, action) => {
        state.loading.getReminders = false;
        state.errors.getReminders = action.payload.error;
      })
      .addCase(customerProfileActions.sendReminder, (state) => {
        state.loading.sendReminder = true;
        state.errors.sendReminder = initialState.errors.sendReminder;
      })
      .addCase(customerProfileActions.sendReminderSuccess, (state) => {
        state.loading.sendReminder = false;
      })
      .addCase(customerProfileActions.sendReminderFailure, (state, action) => {
        state.loading.sendReminder = false;
        state.errors.sendReminder = action.payload.error;
      })
      .addCase(customerProfileActions.initializeCustomerProfile, (state) => {
        state.loading.initializeCustomerProfile = true;
        state.errors.initializeCustomerProfile = null;
      })
      .addCase(
        customerProfileActions.initializeCustomerProfileSuccess,
        (state, action) => {
          state.loading.initializeCustomerProfile = false;
        },
      )
      .addCase(
        customerProfileActions.initializeCustomerProfileFailure,
        (state, action) => {
          state.loading.initializeCustomerProfile = false;
          state.errors.initializeCustomerProfile = action.payload.error;
        },
      )
      .addCase(customerProfileActions.getCustomerProfileList, (state) => {
        state.loading.getCustomerProfileList = true;
        state.customerProfileList = initialState.customerProfileList;
        state.errors.getCustomerProfileList =
          initialState.errors.getCustomerProfileList;
      })
      .addCase(
        customerProfileActions.getCustomerProfileListSuccess,
        (state, action) => {
          state.loading.getCustomerProfileList = false;
          state.customerProfileList = action.payload.response;
        },
      )
      .addCase(
        customerProfileActions.getCustomerProfileListFailure,
        (state, action) => {
          state.loading.getCustomerProfileList = false;
          state.errors.getCustomerProfileList = action.payload.error;
        },
      )
      .addCase(customerProfileActions.getCustomerDetails, (state) => {
        state.loading.getCustomerDetails = true;
        state.errors.getCustomerDetails =
          initialState.errors.getCustomerDetails;
      })
      .addCase(
        customerProfileActions.getCustomerDetailsSuccess,
        (state, action) => {
          state.loading.getCustomerDetails = false;
          const {response} = action.payload;
          const index = state.customerProfileList.findIndex(
            (c) => c.userId === response.id,
          );
          if (index !== -1) {
            state.customerProfileList[index] = {
              ...state.customerProfileList[index],
              ...response,
            };
          } else {
            state.customerProfileList.push({
              ...response,
            });
          }
        },
      )
      .addCase(
        customerProfileActions.getCustomerDetailsFailure,
        (state, action) => {
          state.loading.getCustomerDetails = false;
          state.errors.getCustomerDetails = action.payload.error;
        },
      )
      .addCase(customerProfileActions.getInwardReceiptImage, (state) => {
        state.loading.getInwardReceiptImage = true;
        state.errors.getInwardReceiptImage =
          initialState.errors.getInwardReceiptImage;
      })
      .addCase(
        customerProfileActions.getInwardReceiptImageSuccess,
        (state, action) => {
          state.loading.getInwardReceiptImage = false;
          state.errors.getInwardReceiptImage =
            initialState.errors.getInwardReceiptImage;
          state.inwardReceiptImage[action.payload.loanId] = {
            inwardReceiptImageUrl: action.payload.response.receiptImage,
          };
        },
      )
      .addCase(
        customerProfileActions.getInwardReceiptImageFailure,
        (state, action) => {
          state.loading.getInwardReceiptImage = false;
          state.errors.getInwardReceiptImage = action.payload.error;
        },
      )
      .addCase(
        customerProfileActions.uploadBatchClosureRefundReport,
        (state) => {
          state.loading.uploadBatchClosureRefundReport = true;
          state.errors.uploadBatchClosureRefundReport = null;
          state.batchRefundStatus = [];
        },
      )
      .addCase(
        customerProfileActions.uploadBatchClosureRefundReportSuccess,
        (state, action) => {
          state.loading.uploadBatchClosureRefundReport = false;
          state.errors.uploadBatchClosureRefundReport = null;
          state.batchRefundStatus = action.payload.response || [];
        },
      )
      .addCase(
        customerProfileActions.uploadBatchClosureRefundReportFailure,
        (state, action) => {
          state.loading.uploadBatchClosureRefundReport = false;
          state.errors.uploadBatchClosureRefundReport = action.payload.error;
        },
      );
  },
});

export const {
  openModal,
  closeModal,
  resetBatchRefundStatus,
} = customerProfileSlice.actions;
export default customerProfileSlice.reducer;
