import React, {useEffect} from "react";
import {Box, Typography, Stack, Divider} from "@mui/material";
import {IGDialog, IGLoading} from "app/components";
import IGTable, {IGTableColsInterface} from "app/components/IGTable";
import {activeLoansActions, activeLoansSelectors} from "app/store/activeLoans";
import {toggleModals} from "app/store/activeLoans/activeLoans.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {ActionHistory, Category} from "app/models/activeLoans/activeLoans.model";
import {getValidTime} from "_metronic/utils/moment";
interface ActionHistoryDialogProps {
  crId: number;
}
const ActionHistoryDialog = ({crId}: ActionHistoryDialogProps) => {
  const dispatch = useAppDispatch();
  const actionHistory = useAppSelector(state =>
    activeLoansSelectors.getActionHistoryByCrId(state, crId),
  );
  const {actionHistory: isActionHistoryModalOpen} = useAppSelector(
    activeLoansSelectors.getOpenModals,
  );
  const {getActionHistory: isActionHistoryLoading} = useAppSelector(
    activeLoansSelectors.getLoading,
  );

  const tableCols: IGTableColsInterface<ActionHistory> = [
    {
      headerName: "Time",
      field: "createdAt",
      flex: 0.8,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Box>
          <Typography fontSize={14} fontWeight={500}>
            {getValidTime(value).format("DD MMM, YY") || "-"}
          </Typography>
          <Typography fontSize={12} color="text.secondary">
            {getValidTime(value).format("hh:mm A") || "-"}
          </Typography>
        </Box>
      ),
    },

    {
      headerName: "Action",
      field: "actionType",
      flex: 0.6,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      headerName: "Category And Description",
      field: "categoryList",
      flex: 3,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => (
        <Box>
          {value.map((i: Category, index: number) => (
            <>
              <Stack>
                <Typography fontSize={12} fontWeight={500}>
                  {i?.categoryName}
                </Typography>

                {i?.subCategoryNameList?.map((subCategory, subIndex) => (
                  <Typography key={subIndex} fontSize={12} color="text.secondary">
                    {subCategory}
                  </Typography>
                ))}
              </Stack>
              {value?.length - 1 === index ? null : (
                <Divider
                  sx={{
                    marginY: "4px",
                  }}
                />
              )}
            </>
          ))}
        </Box>
      ),
    },
    {
      headerName: "Remarks",
      field: "remark",
      flex: 0.8,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
    {
      headerName: "Milestone",
      field: "source",
      flex: 0.8,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => <Typography>{value?.replaceAll("_", " ")}</Typography>,
    },
    {
      headerName: "CM Name",
      field: "actorName",
      flex: 0.8,
      sortable: false,
      headerClassName: "disbursed-loans-header",
      renderCell: ({value}) => <Typography>{value}</Typography>,
    },
  ];

  useEffect(() => {
    dispatch(
      activeLoansActions.getActionHistory({
        crId,
      }),
    );
  }, []);
  return (
    <IGDialog
      title="Action History"
      open={isActionHistoryModalOpen}
      hideFooter
      maxWidth="lg"
      onClose={() =>
        dispatch(
          toggleModals({
            type: "actionHistory",
            value: false,
          }),
        )
      }
    >
      <Box mt={2} p={1}>
        {isActionHistoryLoading ? (
          <IGLoading height="10vh" />
        ) : (
          <IGTable<ActionHistory>
            getRowId={row => row?.createdAt}
            tableCols={tableCols}
            tableData={actionHistory}
            hideFooterPagination
            getRowHeight={() => "auto"}
            sx={{
              "& .disbursed-loans-header": {
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: "#000000",
              },
              "& .disbursed-loans-header .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .disbursed-loans-header .MuiSvgIcon-root": {
                fill: "white",
              },
              "& .MuiDataGrid-cell": {
                alignItems: "start",
                padding: "4px",
              },
            }}
          />
        )}
      </Box>
    </IGDialog>
  );
};

export default ActionHistoryDialog;
