import React, {useCallback, useEffect, useState} from "react";

import UploadKYCComponent from "./UploadKYC";

import ApproveButton from "./ApproveButton";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";

import getPersonalDetails from "api/business/getPersonalDetail";
import {customerPersonalDetailsEnum} from "app/enums/accounts";

const UploadKYC = ({
  selectedPayload,
  currentMileStone,
  isSameCreditManager,
  isBackMovement,
  otherRequestTimer,
  getMileStoneOfCr,
}) => {
  const accessToken = useAppSelector(authSelectors.getAuthToken);
  const [isAllChecked, setAllChecked] = useState(false);
  const [takeoverCustomerAge, setTakeoverCustomerAge] = useState("");
  const jobType =
    currentMileStone.data.milestone === "CR_GOLD_LOAN_FRESH_UPDATE_KYC"
      ? "CM_LOAN_KYC_APPROVAL"
      : "CM_TAKEOVER_KYC_APPROVAL";

  const [showTakeoverKycAlert, setShowTakeoverKycAlert] = useState(false);
  const fetchPersonalDetails = useCallback(async () => {
    const {payload, statusCode} = await getPersonalDetails({
      accessToken,
      crId: selectedPayload.id,
      personalDetailType: customerPersonalDetailsEnum.TAKEOVER_PERSONAL_DETAIL,
    });

    if (statusCode === 200) {
      setTakeoverCustomerAge(payload.ageInYearsMonthDaysFromDOB);
    }
  }, [selectedPayload.id]);

  useEffect(() => {
    fetchPersonalDetails();
  }, []);

  return (
    <>
      {/* <GoldLoanConsent
        {...{
          otherRequestTimer,
          selectedPayload,
        }}
      /> */}

      <UploadKYCComponent
        currentMileStone={currentMileStone}
        selectedPayload={selectedPayload}
        otherRequestTimer={otherRequestTimer}
        showTakeoverKycAlert={showTakeoverKycAlert}
        setShowTakeoverKycAlert={setShowTakeoverKycAlert}
        setAllChecked={setAllChecked}
      />

      {isSameCreditManager && !isBackMovement && (
        <>
          <ApproveButton
            otherRequestTimer={otherRequestTimer}
            selectedPayload={selectedPayload}
            getMileStoneOfCr={getMileStoneOfCr}
            jobType={jobType}
            takeoverCustomerAge={takeoverCustomerAge}
            showTakeoverKycAlert={showTakeoverKycAlert}
            isAllChecked={isAllChecked}
          />
        </>
      )}
    </>
  );
};

export default UploadKYC;
