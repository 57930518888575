import {
  LenderLoanResponse,
  MarkDisbursedJobAsReceivedPayload,
  GetDisbursedLoansPayload,
  AssignDisbursalJobPayload,
  UnAssignDisbursalJobPayload,
  GetLoanBookingJobListPayload,
  CrIdPayload,
  RequestIdPayload,
  RejectSanctionedLoanPayload,
  ApproveSanctionedLoanPayload,
} from "./lenderLoan.types";
import {createAction} from "@reduxjs/toolkit";
import {setPaginationFor} from "../factoryReducers";
import {GetDocumentListByCrIdPayload} from "../loanRenewalRebook/cifValidation/cifValidation.types";

const lenderLoanActionTypes = {
  GetDisbursedLoans: "[LenderLoan] Get Disbursed Loans",
  GetDisbursedLoansSuccess: "[LenderLoan] Get Disbursed Loans Success",
  GetDisbursedLoansFailure: "[LenderLoan] Get Disbursed Loans Failure",
  MarkDisbursedJobAsCompleted: "[LenderLoan] Mark Disbursed Job As Completed",
  MarkDisbursedJobAsCompletedSuccess: "[LenderLoan] Mark Disbursed Job As Completed Success",
  MarkDisbursedJobAsCompletedFailure: "[LenderLoan] Mark Disbursed Job As Completed Failure",
  AssignDisbursalJob: "[LenderLoan] Assign Disbursal Job",
  AssignDisbursalJobSuccess: "[LenderLoan] Assign Disbursal Job Success",
  AssignDisbursalJobFailure: "[LenderLoan] Assign Disbursal Job Failure",
  UnAssignDisbursalJob: "[LenderLoan] UnAssign Disbursal Job",
  UnAssignDisbursalJobSuccess: "[LenderLoan] UnAssign Disbursal Job Success",
  UnAssignDisbursalJobFailure: "[LenderLoan] UnAssign Disbursal Job Failure",
  AssignLoanBookingJob: "[LenderLoan] Assign Loan Booking Job",
  UnassignLoanBookingJob: "[LenderLoan] Unassign Loan Booking Job",
  GetLoanBookingJobList: "[LenderLoan] Get Loan Booking Job List",
  GetLoanBookingJobDetails: "[LenderLoan] Get Loan Booking Job Details",
  GetLoanBookingJobDocuments: "[LenderLoan] Get Loan Booking Job Documents",
  ApproveLoanBookingJob: "[LenderLoan] Approve Loan Booking Job",
  RejectLoanBookingJob: "[LenderLoan] Reject Loan Booking Job",
  GetRemarksHistory: "[LenderLoan] Get Remarks History",
};

const lenderLoanActions = {
  getDisbursedLoans: createAction<GetDisbursedLoansPayload>(
    lenderLoanActionTypes.GetDisbursedLoans,
  ),
  getDisbursedLoansSuccess: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.GetDisbursedLoansSuccess,
  ),
  getDisbursedLoansFailure: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.GetDisbursedLoansFailure,
  ),
  markDisbursedJobAsCompleted: createAction<MarkDisbursedJobAsReceivedPayload>(
    lenderLoanActionTypes.MarkDisbursedJobAsCompleted,
  ),
  markDisbursedJobAsCompletedSuccess: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.MarkDisbursedJobAsCompletedSuccess,
  ),
  markDisbursedJobAsCompletedFailure: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.MarkDisbursedJobAsCompletedFailure,
  ),
  assignDisbursalJobToMe: createAction<AssignDisbursalJobPayload>(
    lenderLoanActionTypes.AssignDisbursalJob,
  ),
  assignDisbursalJobToMeSuccess: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.AssignDisbursalJobSuccess,
  ),
  assignDisbursalJobToMeFailure: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.AssignDisbursalJobFailure,
  ),
  unAssignDisbursalJobToMe: createAction<UnAssignDisbursalJobPayload>(
    lenderLoanActionTypes.UnAssignDisbursalJob,
  ),
  unAssignDisbursalJobToMeSuccess: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.UnAssignDisbursalJobSuccess,
  ),
  unAssignDisbursalJobToMeFailure: createAction<LenderLoanResponse>(
    lenderLoanActionTypes.UnAssignDisbursalJobFailure,
  ),
  assignLoanBookingJobToMe: createAction<RequestIdPayload>(
    lenderLoanActionTypes.AssignLoanBookingJob,
  ),
  unassignLoanBookingJobToMe: createAction<RequestIdPayload>(
    lenderLoanActionTypes.UnassignLoanBookingJob,
  ),
  getLoanBookingJobList: createAction<GetLoanBookingJobListPayload>(
    lenderLoanActionTypes.GetLoanBookingJobList,
  ),
  getLoanBookingJobDetails: createAction<RequestIdPayload & CrIdPayload>(
    lenderLoanActionTypes.GetLoanBookingJobDetails,
  ),
  getLoanBookingJobDocuments: createAction<GetDocumentListByCrIdPayload>(
    lenderLoanActionTypes.GetLoanBookingJobDocuments,
  ),
  approveLoanBookingJob: createAction<ApproveSanctionedLoanPayload>(
    lenderLoanActionTypes.ApproveLoanBookingJob,
  ),
  rejectLoanBookingJob: createAction<RejectSanctionedLoanPayload>(
    lenderLoanActionTypes.RejectLoanBookingJob,
  ),
  getRemarksHistory: createAction<RequestIdPayload>(lenderLoanActionTypes.GetRemarksHistory),
  setLenderLoansPagination: setPaginationFor("lenderLoan"),
};
export default lenderLoanActions;

export {lenderLoanActionTypes};
