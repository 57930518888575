import React, {useCallback, useEffect, useState} from "react";
import {Popover, Typography, CircularProgress} from "@mui/material";
import {Notifications} from "@mui/icons-material";

import getNotifications from "../../../../api/notification/getNotification";
import NotificationDrawer from "./NotificationDrawer";
import {useAppSelector} from "app/store/hooks";
import {authSelectors} from "app/store/auth";


const NotificationWindow = () => {
  const authToken = useAppSelector(authSelectors.getAuthToken);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [states, setStates] = useState({
    loading: false,
    error: false,
    payload: null,
    errorMessage: "",
  });

  const [notifications, setNotifications] = useState(null);

  const getInitialNotification = useCallback(async (initialTime) => {
    try {
      const response = await getNotifications({
        accessToken: authToken,
        fromTime: initialTime,
        pageSize: 10,
      });

      if (response.statusCode === 200) {
        setStates({
          error: false,
          errorMessage: "",
          loading: false,
          payload: response.payload,
        });
        setNotifications(response.payload.businessNotificationResponseDtoList);
      } else {
        setStates({
          error: true,
          errorMessage: response.error,
          loading: false,
          payload: null,
        });
      }
    } catch (error) {
      setStates({
        error: true,
        errorMessage: "Something went wrong",
        loading: false,
        payload: null,
      });
    }
  }, [authToken]);


  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (anchorEl) {
      getInitialNotification(new Date().getTime());
    }
  }, [getInitialNotification, anchorEl]);

  return (
    <>
      <Notifications
        color="action"
        aria-describedby={id}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        sx={{
          top: "22px",
        }}
        elevation={2}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{p: 2}}>Notifications</Typography>

        {states.loading ? (
          <div className="kt-notification">
            <CircularProgress />
          </div>
        ) : states.error ? (
          <div className="kt-notification">{states.errorMessage}</div>
        ) : (
          <NotificationDrawer
            setNotifications={setNotifications}
            notifications={notifications}
          />
        )}
      </Popover>
    </>
  );
};

export default NotificationWindow;
