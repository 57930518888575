import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {IGDocumentViewer, IGLoading, IGModal} from "app/components";
import {closeModal} from "app/store/customerProfile/customerProfile.reducer";
import {useAppDispatch, useAppSelector} from "app/store/hooks";
import {
  loanPaymentActions,
  loanPaymentSelectors,
} from "app/store/loanPayment";
import {RootState} from "app/store/store";
import numberWithCurrencyAndComma from "lib/numberWithCurrencyAndComma";
import React, {useEffect} from "react";

const RefundReasonEnum: Record<string, string> = {
  WRONG_VA_SHARED_BY_AGENT: "Wrong VA Shared By Agent",
  CUSTOMER_MADE_PAYMENT_IN_WRONG_VA: "Customer Made Payment in Wrong VA",
  DUPLICATE_PAYMENT: "Duplicate Payment",
  EXCESS_AMOUNT_MANUAL: "Excess Payment Manual",
  EXCESS_AMOUNT_AUTO: "Excess Payment Auto",
  OTHERS: "Others",
};

const RefundStatusModal = ({paymentId}: { paymentId: number }) => {
  const dispatch = useAppDispatch();
  const refundStatus = useAppSelector((state: RootState) =>
    loanPaymentSelectors.getRefundStatus(state, paymentId),
  );
  const {getRefundStatus: getRefundStatusLoading} = useAppSelector(
    loanPaymentSelectors.getLoading,
  );

  useEffect(() => {
    if (paymentId) {
      dispatch(
        loanPaymentActions.getRefundStatus({paymentId: paymentId.toString()}),
      );
    }
  }, [paymentId, dispatch]);

  return (
    <IGModal
      open={true}
      title="Refund Details"
      onClose={() => {
        dispatch(closeModal("refundStatus"));
      }}
      width="70%"
    >
      <>
        {getRefundStatusLoading && <IGLoading height="10vh" />}
        {refundStatus?.length && (
          <TableContainer component={Paper}>
            <Table
              sx={{
                "& th": {
                  backgroundColor: "#000",
                  color: "#fff",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Refund ID</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Refund Reason</TableCell>
                  <TableCell>Refund Amount</TableCell>
                  <TableCell>Refund Status</TableCell>
                  <TableCell>Shivalik Approval</TableCell>
                  <TableCell>IG Approval</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refundStatus?.map((row) => (
                  <TableRow key={row.refundId}>
                    <TableCell>
                      {row.refundId || <Typography variant="h6">-</Typography>}
                    </TableCell>
                    <TableCell>
                      {row.refundedByName || (
                        <Typography variant="h6">-</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {RefundReasonEnum[row.refundReason] ||
                        row.refundReason || (
                          <Typography variant="h6">-</Typography>
                        )}
                    </TableCell>
                    <TableCell>
                      {numberWithCurrencyAndComma(row.refundAmount)}
                    </TableCell>
                    <TableCell>
                      {row.refundStatus || (
                        <Typography variant="h6">-</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.shivalikConfirmationAttachmentUrl ? (
                        <IGDocumentViewer
                          document={{
                            fileUrl: row.shivalikConfirmationAttachmentUrl,
                            documentType: "Shivalik Approval",
                          }}
                          thumbHeight="40px"
                          thumbWidth="40px"
                          download
                        />
                      ) : (
                        <Typography variant="h6">-</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.internalApprovalAttachmentUrl ? (
                        <IGDocumentViewer
                          document={{
                            fileUrl: row.internalApprovalAttachmentUrl,
                            documentType: "IG Approval",
                          }}
                          thumbHeight="40px"
                          thumbWidth="40px"
                          download
                        />
                      ) : (
                        <Typography variant="h6">-</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    </IGModal>
  );
};

export default RefundStatusModal;
