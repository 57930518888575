import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import partReleaseActions from "./partRelease.actions";
import {CMConfig, CMOfferDetail, CSTConfig, ConsumerOfferDetail, NetPayableAmount, NewLoanOfferDetail, PartReleaseData, PartReleaseOfferHistory, PartReleaseOrnament} from "./partRelease.types";
import {createActionsInitialState, setActionError, setActionLoading, setActionSuccess} from "app/store/redux.utils";
import {RootState} from "app/store/store";

const ornamentsAdaptor = createEntityAdapter<PartReleaseOrnament>({
  selectId: (ornament) => ornament.crOrnamentId,
});

export interface PartReleaseState {
  partReleaseOfferHistory: {
    [requestId: string]: Array<PartReleaseOfferHistory>;
  };
  offerHistoryOrnaments: {
    [offerHistoryId: string]: Array<PartReleaseOrnament>;
  };
  ornaments: ReturnType<typeof ornamentsAdaptor.getInitialState>;
  consumerOfferDetail: {
    [requestId: number]: ConsumerOfferDetail
  },
  newLoanOfferDetail: {
    [requestId: number]: NewLoanOfferDetail
  }
  netPayableAmountDetails: {
    [requestId: number]: NetPayableAmount
  }
  cstConfig: {
    [requestId: number]:CSTConfig
  }
  cmConfig: {
    [requestId: number]: CMConfig
  }
  cmOfferDetail: {
    [requestId: number]: CMOfferDetail
  }
  actions: ReturnType<typeof createActionsInitialState>;
  partReleaseData: PartReleaseData;
  loading: {
    partReleaseOfferHistory: boolean;
    partReleaseData: boolean;
  };
  error: {
    partReleaseOfferHistory: string;
    partReleaseData: string;
  };
  modal: {
    shareOfferWithCustomer: boolean;
    editRoi: boolean;
  }
}

type PartReleaseActions =
| "getPartReleaseOfferHistory"
| "getPartReleaseData"
| "getOrnamentList"
| "getOfferHistoryOrnamentDetail"
| "getConsumerOfferDetail"
| "getNewLoanOfferDetail"
| "getNetPayableAmount"
| "updateCstOffer"
| "checkIsOfferExpired"
| "shareOffer"
| "getCstConfig"
| "getCMConfig"
| "getCMOfferDetail"
| "updateCmOffer"
| "updateOfferRoi"
| "allowPayment";

export const initialState: PartReleaseState = {
  partReleaseOfferHistory: {},
  offerHistoryOrnaments: {},
  ornaments: ornamentsAdaptor.getInitialState(),
  consumerOfferDetail: {},
  newLoanOfferDetail: {},
  netPayableAmountDetails: {},
  cstConfig: {},
  cmConfig: {},
  cmOfferDetail: {},
  partReleaseData: {
    serviceRequestId: 0,
    loanId: 0,
    interestTillNextWorkingDay: 0,
    foreclosureAmount: 0,
    balancePrincipal: 0,
    excessAmount: 0,
    subventionRecoveryAmount: 0,
    releaseOrnamentFileUrlList: [],
    isProcessingFeeActive: false,
    goldPriceMultiplierForNewLoanAmount: 0,
    maxAllowedPfPercentage: 0,
  },
  actions: createActionsInitialState([
    "getPartReleaseOfferHistory",
    "getPartReleaseData",
    "getOrnamentList",
    "getOfferHistoryOrnamentDetail",
    "getConsumerOfferDetail",
    "getNewLoanOfferDetail",
    "getNetPayableAmount",
    "updateCstOffer",
    "checkIsOfferExpired",
    "shareOffer",
    "getCstConfig",
    "getCMConfig",
    "getCMOfferDetail",
    "updateCmOffer",
    "updateOfferRoi",
  ]),
  loading: {
    partReleaseOfferHistory: false,
    partReleaseData: false,
  },
  error: {
    partReleaseOfferHistory: "",
    partReleaseData: "",
  },
  modal: {
    shareOfferWithCustomer: false,
    editRoi: false,
  },
};

const partReleaseReducer = createSlice({
  name: "partRelease",
  initialState,
  reducers: {
    resetPartReleaseState: () => initialState,
    offerHistoryReceived: (state, action) => {
      const {response, requestId} = action.payload;
      state.partReleaseOfferHistory[requestId] = response;
    },
    offerHistoryOrnamentsReceived: (state, action) => {
      const {response, offerHistoryId} = action.payload;
      state.offerHistoryOrnaments[offerHistoryId] = response;
    },
    ornamentListReceived: (state, action) => {
      ornamentsAdaptor.setAll(
        state.ornaments,
        action.payload.response.map((ornament: PartReleaseOrnament) => ({
          ...ornament,
          requestId: action.payload.requestId,
        })),
      );
    },
    consumerOfferDetailReceived: (state, action) => {
      state.consumerOfferDetail[action.payload.requestId] =
        action.payload.response;
    },
    newLoanOfferDetailReceived: (state, action) => {
      state.newLoanOfferDetail[action.payload.requestId] =
        action.payload.response;
    },
    netPayableAmountReceived: (state, action) => {
      state.netPayableAmountDetails[action.payload.requestId] =
        action.payload.response;
    },
    cstConfigReceived: (state, action) => {
      state.cstConfig[action.payload.requestId] = action.payload.response;
    },
    cmConfigReceived: (state, action) => {
      state.cmConfig[action.payload.requestId] = action.payload.response;
    },
    cmOfferDetailReceived: (state, action) => {
      state.cmOfferDetail[action.payload.requestId] = action.payload.response;
    },
    toggleModal: (
      state,
      action: PayloadAction<{
        key: keyof PartReleaseState["modal"];
        value: boolean;
      }>,
    ) => {
      state.modal[action.payload.key] = action.payload.value;
    },
    setActionLoading,
    setActionError,
    setActionSuccess,
  },
  extraReducers: (builder) => {
    builder
      .addCase(partReleaseActions.getPartReleaseOfferHistory, (state) => {
        state.loading.partReleaseOfferHistory = true;
      })
      .addCase(partReleaseActions.getPartReleaseData, (state) => {
        state.loading.partReleaseData = true;
      })
      .addCase(
        partReleaseActions.getPartReleaseDataSuccess,
        (state, action) => {
          state.partReleaseData = action.payload.response;
          state.loading.partReleaseData = false;
        },
      )
      .addCase(
        partReleaseActions.getPartReleaseDataFailure,
        (state, action) => {
          state.loading.partReleaseData = false;
          state.error.partReleaseData = action.payload.error;
        },
      );
  },
});

export const selectPartReleaseAction = (state: RootState, action: PartReleaseActions) =>
  state.serviceDesk.services.partReleaseState.actions[action];

const {
  selectAll: selectAllOrnaments,
  selectIds: selectOrnamentIds,
  selectById: selectOrnamentById,
} = ornamentsAdaptor.getSelectors(
  (state: RootState) =>
    state.serviceDesk.services.partReleaseState.ornaments,
);

const {
  resetPartReleaseState,
  offerHistoryReceived,
  ornamentListReceived,
  consumerOfferDetailReceived,
  newLoanOfferDetailReceived,
  netPayableAmountReceived,
  cstConfigReceived,
  cmConfigReceived,
  cmOfferDetailReceived,
  offerHistoryOrnamentsReceived,
  toggleModal: partReleaseToggleModal,
  setActionLoading: partReleaseSetActionLoading,
  setActionError: partReleaseSetActionError,
  setActionSuccess: partReleaseSetActionSuccess,
} = partReleaseReducer.actions;

export {
  initialState as partReleaseState,
  resetPartReleaseState,
  offerHistoryReceived,
  ornamentListReceived,
  consumerOfferDetailReceived,
  newLoanOfferDetailReceived,
  netPayableAmountReceived,
  cstConfigReceived,
  cmConfigReceived,
  cmOfferDetailReceived,
  offerHistoryOrnamentsReceived,
  partReleaseSetActionLoading,
  partReleaseSetActionError,
  partReleaseSetActionSuccess,
  selectAllOrnaments,
  selectOrnamentIds,
  selectOrnamentById,
  partReleaseToggleModal,
};

export default partReleaseReducer.reducer;
