import React from "react";
import {Autocomplete, AutocompleteProps, TextField, TextFieldProps} from "@mui/material";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface IGAutocompleteProps<T>
  extends Optional<AutocompleteProps<T, undefined, boolean, false>, "renderInput"> {
  label: string
  value: AutocompleteProps<T, undefined, boolean, false>["value"]
  onChange: AutocompleteProps<T, undefined, boolean, false>["onChange"]
  options: AutocompleteProps<T, undefined, boolean, false>["options"]
  error?: TextFieldProps["error"]
  helperText?: TextFieldProps["helperText"]
  touched?: boolean,
  textFieldProps?: TextFieldProps,
  required?: boolean
  renderInput?: AutocompleteProps<T, undefined, boolean, false>["renderInput"]
}

/**
 * General Single Autocomplete component which uses mui 5 Autocomplete as Base.
 * @param id
 * @param value
 * @param onChange
 * @param getOptionLabel
 * @param renderInput
 * @param label
 * @param options
 * @param disableClearable
 * @param size
 * @param error
 * @param touched
 * @param helperText
 * @param required
 * @param textFieldProps
 * @param props
 * @constructor
 */
function IGAutocomplete<T>({
  id,
  value,
  onChange,
  getOptionLabel,
  renderInput,
  label,
  options = [],
  disableClearable = true,
  size = "small",
  error = false,
  touched = false,
  helperText = "",
  required = false,
  textFieldProps = {
    variant: "outlined",
  },
  ...props
}: IGAutocompleteProps<T>) {

  return (
    <Autocomplete<T, undefined, boolean, false>
      disableClearable={disableClearable}
      size={size}
      id={id || label}
      options={options}
      getOptionLabel={(option) => {
        if (getOptionLabel) {
          return getOptionLabel(option);
        }
        return typeof option === "string" ? option : String(option);
      }}
      value={value}
      onChange={onChange}
      {...props}
      renderInput={renderInput
        ? renderInput
        : (params) => (
          <TextField
            {...params}
            {...textFieldProps}
            required={required}
            label={label}
            error={error}
            helperText={error ? (touched && helperText) : helperText}
          />
        )}
    />
  );
};

export default IGAutocomplete;