import React, {useEffect, useState} from "react";
import PersonalLoanNotTaken from "./PersonalLoanNotTaken";
import PersonalLoanTaken from "./PersonalLoanTaken";
import isPersonalLoanTakenApi from "../../../../../../../api/business/isPersonalLoanTaken";
import {CircularProgress} from "@mui/material";
import styles from "../styles";

const PersonalLoan = ({
  selectedPayload,
  isSameCreditManager,
  isBackMovement,
  updateIndex,
  currentIndex,
  currentMileStone,
  getMileStoneOfCr,
}) => {
  const [isPersonalLoanTaken, setIsPersonalLoanTaken] = useState(
    selectedPayload.isPersonalLoanTaken,
  );
  const [states, setStates] = useState({
    loading: true,
    error: false,
    errorMessage: "",
  });

  const classes = styles();

  useEffect(() => {
    (async () => {
      try {
        const response = await isPersonalLoanTakenApi({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          setStates({
            error: false,
            errorMessage: "",
            loading: false,
          });
          setIsPersonalLoanTaken(response.payload.isPersonalLoanTaken);
        } else {
          setStates({
            error: true,
            errorMessage: response?.error?.toString(),
            loading: false,
          });
        }
      } catch (error) {
        setStates({
          error: true,
          errorMessage: "Something went wrong",
          loading: false,
        });
      }
    })();
  }, [
    isPersonalLoanTaken,
    selectedPayload.id,
    selectedPayload.isPersonalLoanTaken,
  ]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  if (isBackMovement && !isPersonalLoanTaken) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>Personal Loan not taken</div>
      </div>
    );
  }

  return (
    <>
      {isPersonalLoanTaken ? (
        <PersonalLoanTaken
          isSameCreditManager={isSameCreditManager}
          selectedPayload={selectedPayload}
          currentMileStone={currentMileStone}
        />
      ) : (
        <PersonalLoanNotTaken
          setIsPersonalLoanTaken={setIsPersonalLoanTaken}
          selectedPayload={selectedPayload}
          isSameCreditManager={isSameCreditManager}
          isBackMovement={isBackMovement}
          updateIndex={updateIndex}
          currentIndex={currentIndex}
          currentMileStone={currentMileStone}
          getMileStoneOfCr={getMileStoneOfCr}
        />
      )}
    </>
  );
};

export default PersonalLoan;
