import React from "react";
import {Grid} from "@mui/material";
import SubTitle from "app/pages/home/GoldLoan/DrawerList/MileStoneCard/NewUploadKYC/styles/SubTitle";
import MiniTitle from "app/pages/home/GoldLoan/DrawerList/MileStoneCard/NewUploadKYC/styles/MiniTitle";

const CustomerBankDetails = ({
  customerBankDetails,
  bankDetails,
}) => {
  return (
    <>
      <Grid direction='rows' container spacing={2}>
        {customerBankDetails?.map((detail, index) => (
          <Grid item xs={detail.width ? detail.width : 4} key={index}>
            <SubTitle>{detail?.label}</SubTitle>
            <MiniTitle>{detail?.value}</MiniTitle>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CustomerBankDetails;
