import {call, getContext, put, takeEvery} from "redux-saga/effects";
import {dialerActionTypes} from "./dialer.actions";
import {GoldApi} from "app/infra/services/api";
import {
  setAgentSchedule,
  setDialerErrors,
  setDialerLoading,
  setEventDetails,
} from "./dialer.reducer";
import {PayloadAction} from "@reduxjs/toolkit";

function* getEventDetailsFlow(action: PayloadAction<{ requestId: string }>) {
  const api: GoldApi = yield getContext("api");
  yield put(setDialerLoading({key: "getEventDetails", value: true}));
  const {response, error} = yield call(
    api.autoDialerApi.getEventDetails,
    action.payload,
  );

  if (error) {
    yield put(
      setDialerErrors({
        key: "getEventDetails",
        value: error.message,
      }),
    );
  } else {
    yield put(
      setEventDetails({
        requestId: action.payload.requestId,
        eventDetails: {
          eventDescription: response.eventDescription,
          eventType: response.eventType,
          serviceRequestId: response.serviceRequestId,
          loanId: response.loanId,
          mobile: response.mobile,
        },
      }),
    );
  }
  yield put(setDialerLoading({key: "getEventDetails", value: false}));
}

function* getAgentScheduleFlow(
  action: PayloadAction<{ pageNo: number; pageSize: number }>,
) {
  const api: GoldApi = yield getContext("api");
  yield put(setDialerLoading({key: "getAgentSchedule", value: true}));
  const {response, error} = yield call(
    api.autoDialerApi.getAgentSchedule,
    action.payload,
  );

  if (error) {
    yield put(
      setDialerErrors({
        key: "getAgentSchedule",
        value: error.message,
      }),
    );
  } else {
    yield put(setAgentSchedule(response));
  }
  yield put(setDialerLoading({key: "getAgentSchedule", value: false}));
}

export default function* dialerSaga() {
  yield takeEvery(dialerActionTypes.GetEventDetails, getEventDetailsFlow);
  yield takeEvery(dialerActionTypes.GetAgentSchedule, getAgentScheduleFlow);
}
