import {createAction} from "@reduxjs/toolkit";
import {
  CRAssignJobPayload,
  CRCreateJobPayload,
  CrIdPayload,
  GetCRDetailPayload,
  GetCRListPayload,
  GetNoteListPayload,
  OpenCRResponse,
  UpdateCallingStatusPayload,
  UpdateMeetingTimePayload,
} from "../../../infra/services/api/scheduling/openCR/types";
import {GetUnscheduledAgentReportPayload} from "app/infra/services/api/scheduling/unscheduled/types";

export const OpenCRActionTypes = {
  GetCRList: "[OpenCR] Get CR List",
  GetCRListSuccess: "[OpenCR] Get CR List Success",
  GetCRListFailure: "[OpenCR] Get CR List Failure",
  GetCRDetail: "[OpenCR] Get CR Detail",
  GetCRDetailSuccess: "[OpenCR] Get CR Detail Success",
  GetCRDetailFailure: "[OpenCR] Get CR Detail Failure",
  GetLeadSourceDetail: "[OpenCR] Get Lead Source Detail",
  GetLeadSourceDetailSuccess: "[OpenCR] Get Lead Source Success",
  GetLeadSourceDetailFailure: "[OpenCR] Get Lead Source Failure",
  GetAddressDetail: "[OpenCR] Get Address Detail",
  GetAddressDetailSuccess: "[OpenCR] Get Address Success",
  GetAddressDetailFailure: "[OpenCR] Get Address Failure",
  GetTakeOverDetail: "[OpenCR] Get Take Over Detail",
  GetTakeOverDetailSuccess: "[OpenCR] Get Take Over Success",
  GetTakeOverDetailFailure: "[OpenCR] Get Take Over Failure",
  GetPrimaryLMJobDetail: "[OpenCR] Get Primary LM Job Detail",
  GetPrimaryLMJobDetailSuccess: "[OpenCR] Get Primary LM Job Success",
  GetPrimaryLMJobDetailFailure: "[OpenCR] Get Primary LM Job Failure",
  GetEligibleJobs: "[OpenCR] Get Eligible Jobs List",
  GetEligibleJobsSuccess: "[OpenCR] Get Eligible Jobs List Success",
  GetEligibleJobsFailure: "[OpenCR] Get Eligible Jobs List Failure",
  GetMeetingTime: "[OpenCR] Get Meeting Time",
  GetMeetingTimeSuccess: "[OpenCR] Get Meeting Time Success",
  GetMeetingTimeFailure: "[OpenCR] Get Meeting Time Failure",
  UpdateCallingStatus: "[OpenCR] Update Calling Status",
  UpdateCallingStatusSuccess: "[OpenCR] Update Calling Status Success",
  UpdateCallingStatusFailure: "[OpenCR] Update Calling Status Failure",
  UpdateMeetingTime: "[OpenCR] Update Meeting Time",
  UpdateMeetingTimeSuccess: "[OpenCR] Update Meeting Time Success",
  UpdateMeetingTimeFailure: "[OpenCR] Update Meeting Time Failure",
  CRCreateJob: "[OpenCR] CR Create Job",
  CRCreateJobSuccess: "[OpenCR] CR Create Job Success",
  CRCreateJobFailure: "[OpenCR] CR Create Job Failure",
  CRAssignJob: "[OpenCR] CR Assign Job",
  CRAssignJobSuccess: "[OpenCR] CR Assign Job Success",
  CRAssignJobFailure: "[OpenCR] CR Assign Job Failure",
  ToggleDrawerOpen: "[OpenCR] Toggle drawer open",
  GetNoteList: "[OpenCR] Get Note List",
  GetNoteListSuccess: "[OpenCR] Get Note List Success",
  GetNoteListFailure: "[OpenCR] Get Note List Failure",
  GetOpenCrView: "[OpenCR] Get Open Cr View",
  GetOpenCrClusterDetail: "[OpenCR] Get Open Cr Cluster Detail",
  GetOpenCrAgentReport: "[OpenCR] Get Open Cr Agent Report",
  EnableOpenCrManualAssignment: "[OpenCR] Enable Open Cr Manual Assignment",
};

export const OpenCRActions = {
  getOpenCrAgentReport: createAction<GetUnscheduledAgentReportPayload>(
    OpenCRActionTypes.GetOpenCrAgentReport,
  ),
  getOpenCrClusterDetail: createAction<any>(
    OpenCRActionTypes.GetOpenCrClusterDetail,
  ),
  getOpenCrView: createAction<any>(OpenCRActionTypes.GetOpenCrView),
  getCRList: createAction<GetCRListPayload>(OpenCRActionTypes.GetCRList),
  getCRListSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetCRListSuccess,
  ),
  getCRListFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetCRListFailure,
  ),
  getCRDetail: createAction<GetCRDetailPayload>(OpenCRActionTypes.GetCRDetail),
  getCRDetailSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetCRDetailSuccess,
  ),
  getCRDetailFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetCRDetailFailure,
  ),
  getLeadSourceDetail: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetLeadSourceDetail,
  ),
  getLeadSourceDetailSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetLeadSourceDetailSuccess,
  ),
  getLeadSourceDetailFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetLeadSourceDetailFailure,
  ),
  getAddressDetail: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetAddressDetail,
  ),
  getAddressDetailSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetAddressDetailSuccess,
  ),
  getAddressDetailFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetAddressDetailFailure,
  ),
  getTakeOverDetail: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetTakeOverDetail,
  ),
  getTakeOverDetailSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetTakeOverDetailSuccess,
  ),
  getTakeOverDetailFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetTakeOverDetailFailure,
  ),
  getPrimaryLMJobDetail: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetPrimaryLMJobDetail,
  ),
  getPrimaryLMJobDetailSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetPrimaryLMJobDetailSuccess,
  ),
  getPrimaryLMJobDetailFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetPrimaryLMJobDetailFailure,
  ),
  getEligibleJobs: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetEligibleJobs,
  ),
  getEligibleJobsSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetEligibleJobsSuccess,
  ),
  getEligibleJobsFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetEligibleJobsFailure,
  ),
  getMeetingTime: createAction<GetCRDetailPayload>(
    OpenCRActionTypes.GetMeetingTime,
  ),
  getMeetingTimeSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetMeetingTimeSuccess,
  ),
  getMeetingTimeFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetMeetingTimeFailure,
  ),
  updateCallingStatus: createAction<UpdateCallingStatusPayload>(
    OpenCRActionTypes.UpdateCallingStatus,
  ),
  updateCallingStatusSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.UpdateCallingStatusSuccess,
  ),
  updateCallingStatusFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.UpdateCallingStatusFailure,
  ),
  updateMeetingTime: createAction<UpdateMeetingTimePayload>(
    OpenCRActionTypes.UpdateMeetingTime,
  ),
  updateMeetingTimeSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.UpdateMeetingTimeSuccess,
  ),
  updateMeetingTimeFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.UpdateMeetingTimeFailure,
  ),
  crCreateJob: createAction<CRCreateJobPayload>(OpenCRActionTypes.CRCreateJob),
  crCreateJobSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.CRCreateJobSuccess,
  ),
  crCreateJobFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.CRCreateJobFailure,
  ),
  crAssignJob: createAction<CRAssignJobPayload>(OpenCRActionTypes.CRAssignJob),
  crAssignJobSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.CRAssignJobSuccess,
  ),
  crAssignJobFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.CRAssignJobFailure,
  ),
  getNoteList: createAction<GetNoteListPayload>(OpenCRActionTypes.GetNoteList),
  getNoteListSuccess: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetNoteListSuccess,
  ),
  getNoteListFailure: createAction<OpenCRResponse>(
    OpenCRActionTypes.GetNoteListFailure,
  ),
  enableOpenCrManualAssignment: createAction<CrIdPayload>(
    OpenCRActionTypes.EnableOpenCrManualAssignment,
  ),
};
