import React from "react";

import {useAppSelector, useAppDispatch} from "app/store/hooks";
import {snackBarActions, snackbarSelectors} from "app/store/snackbar";
import {Slide, Snackbar, Alert} from "@mui/material";

function SlideTransition(props: any) {
  return <Slide {...props} direction="down" />;
}

const NewSnackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(snackbarSelectors.getOpen);
  const message = useAppSelector(snackbarSelectors.getMessage);
  const variant = useAppSelector(snackbarSelectors.getVariant);
  const autoHideDuration = useAppSelector(snackbarSelectors.getAutoHideDuration);

  const handleClose = () => {
    dispatch(snackBarActions.close());
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: "top", horizontal: "center"}}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={handleClose} severity={variant}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default NewSnackbar;
