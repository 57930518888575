import React, {FC, ReactNode} from "react";
import {
  Box,
  BoxProps,
  createTheme,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";

interface IGSectionHeaderProps {
  title?: ReactNode;
  actionButtons?: ReactNode[];
  ContainerProps?: BoxProps;
  theme?: "dark" | "light";
}

const IGSectionHeader: FC<IGSectionHeaderProps> = ({
  title,
  actionButtons = [],
  children,
  theme = "dark",
  ContainerProps = {},
}) => {
  const {palette} = createTheme();

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: theme,
          sdGrey: palette.augmentColor({
            color: {
              light: "#e0e0e0",
              main: "#9e9e9e",
              dark: "#616161",
              contrastText: "#ffffff",
            },
          }),
          golden: palette.augmentColor({
            color: {
              dark: "#997a00",
              main: "#cc9f26",
              contrastText: "#ffffff",
              light: "#f5f5f5",
            },
          }),
        },
      })}
    >
      <Box component={Paper} m={1.5} p={3} elevation={0} {...ContainerProps}>
        {title ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight={1000}>
              {title}
            </Typography>
            {actionButtons.map((button) => (
              <>{button}</>
            ))}
          </Box>
        ) : null}
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default IGSectionHeader;
