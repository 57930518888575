import React, {useCallback} from "react";

import styles from "../styles";
import getCustomerLocationApi from "api/business/getLocationCoordinate";
import CustomerLeftHomeComponent from "./CustomerLeftHome";
import {useSnackBar} from "app/store/hooks";

const CustomerLeftHome = ({selectedPayload, isSm = false}) => {
  const classes = styles();
  const {showError} = useSnackBar();

  const onClickHandler = useCallback(
    async (params) => {
      try {
        const response = await getCustomerLocationApi({
          accessToken: window.localStorage.getItem("authToken"),
          locationType: params,
          crId: selectedPayload.id,
        });

        if (response.statusCode === 200) {
          const location = response.payload.locationCoordinates;

          if (location === null) {
            showError("Location is null");
            return;
          }
          const locationArray = location.split(",");

          const lat = locationArray[0];
          const lng = locationArray[1];

          window.open(
            `https://www.google.com/maps/search/?api=1&query=${lng},${lat}`,
          );
        } else {
          showError(response.error);
        }
      } catch (error) {
        showError("Something went wrong");
      }
    },
    [selectedPayload.id],
  );

  return (
    <>
      <div className={classes.textContainer}>
        <div className={classes.text}>
          CUSTOMER LOCATION SHARED BY {isSm ? "SM" : "LM"}
        </div>
        <div
          onClick={() => onClickHandler("CUSTOMER_LOCATION_FROM_LM")}
          className={classes.button}
        >
          View Location
        </div>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.text}>
          CUSTOMER LOCATION SHARED BY SCHEDULER
        </div>
        <div
          onClick={() => onClickHandler("CUSTOMER_LOCATION_FROM_LEAD")}
          className={classes.button}
        >
          View Location
        </div>
      </div>

      <CustomerLeftHomeComponent selectedPayload={selectedPayload} />
    </>
  );
};

export default CustomerLeftHome;
