import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
}

export const getSchemeTypeList = async ({crId}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    GET_SCHEME_TYPE_LIST: {
      URL: "business/cr/:crId/scheme-type-list",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.GET_SCHEME_TYPE_LIST,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default getSchemeTypeList;
