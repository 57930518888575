import {RootState} from "../../store";

const openCRSelectors = {
  getIsFetching: (state: RootState) => state.scheduling.openCR.isFetching,
  getOpenCRList: (state: RootState) => state.scheduling.openCR.crList,
  getOpenCRDetail: (state: RootState) => state.scheduling.openCR.crDetail,
  getErrors: (state: RootState) => state.scheduling.openCR.error,
  getDrawerOpen: (state: RootState) => state.scheduling.openCR.drawerOpen,
  getPaginationDetails: (state: RootState) =>
    state.scheduling.openCR.paginationData,
  getNoteList: (state: RootState) => state.scheduling.openCR.noteList,
  getOpenCrManualAssignment: (state: RootState) =>
    state.scheduling.openCR.openCrFeatureFlag,
  getOpenCrClusterDetail: (state: RootState) =>
    state.scheduling.openCR.openCrClusterDetail,
  getOpenCrAgentReport: (state: RootState) =>
    state.scheduling.openCR.openCrAgentReport,
};
export default openCRSelectors;
