import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  addNewButton: {
    padding: "10px 20px",
    border: "none",
    margin: "6px",
    borderRadius: "4px",
    background: "#ce9f29",
  },
  tableContainer: {
    border: "solid 0.5px #e2e2e2",
    borderRadius: "4px",
    marginTop: "24px",
    marginBottom: "40px",
    background: "#ffffff",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "column",
  },
  toggleText: {
    color: "#ce9f29",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "1.71",
    letterSpacing: "0.1px",
    marginLeft: "24px",
  },
  totalRow: {
    background: "rgba(206, 159, 41, 0.08)",
  },
  totalCell: {
    fontWeight: 600,
  },
  titleContainer: {
    fontSize: "16px",
    lineHeight: "1.5",
    letterSpacing: "2px",
    margin: "16px 24px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paginationButtonContiner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  navButtonStyle: {
    padding: "10px 20px",
    border: "none",
    margin: "6px",
    borderRadius: "4px",
  },
  currentPageNumber: {
    fontSize: "18px",
    margin: "4px",
    fontWeight: 500,
  },

  loaderContainer: {
    marginLeft: "50%",
    marginTop: "5%",
    marginBottom: "5%",
  },
  title: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "2px",
    color: "#121212",
  },
  tableCell: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "0.1px",
  },

  emptyData: {
    padding: "10rem",
    textAlign: "center",
    fontSize: "20px",
  },
}));

export default useStyles;
