import React from "react";
import {authSelectors} from "app/store/auth";
import {useAppSelector} from "app/store/hooks";
import {Navigate} from "react-router-dom";

const ProtectedRoutes = ({children}: { children: React.FC })  => {
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);

  return isAuthenticated ? children : <Navigate to="/auth" />;
};

export default ProtectedRoutes;
