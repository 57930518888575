import {capitalize} from "@mui/material";
import moment from "moment";
import {LM_AVAILABILITY_COLOR_MAPPING} from "./index";

const ConvertSchedulingDetailsToEvents = (schedulingDetails: any) => {
  const events = [];
  for (const agent of schedulingDetails) {
    if (agent.lmScheduleDtoList.length > 0) {
      for (const event of agent.lmScheduleDtoList) {
        const scheduleEvent = {
          agentId: event.agentId,
          title: capitalize(event.type.replaceAll("_", " ")),
          color:
            event.type === "ALGO_RECOMMENDED"
              ? LM_AVAILABILITY_COLOR_MAPPING.ALGO_ASSIGNED
              : event.lmScheduleMeta && event.lmScheduleMeta.crId
              ? event.lmScheduleMeta.jobScheduledBy === "SCHEDULING_ALGO"
                ? LM_AVAILABILITY_COLOR_MAPPING.ALGO_ASSIGNED
                : event.lmScheduleMeta.jobScheduledBy === "REAL_TIME_ALGO"
                ? LM_AVAILABILITY_COLOR_MAPPING.REAL_TIME_ALGO
                : LM_AVAILABILITY_COLOR_MAPPING.MANUAL_JOB
              : LM_AVAILABILITY_COLOR_MAPPING.BLOCKED,
          crId: event.lmUserCalendarId,
          crJobId: event.crJobId,
          end: moment(event.endTime).format(),
          id: event.id,
          leadId: event.leadId,
          remark: event.remark,
          resourceId: event.agentId,
          start: moment(event.startTime).format(),
          date: moment(event.startTime).format(),
          startAndEndTime: {
            startTime: moment(event.startTime).format(),
            endTime: moment(event.endTime).format(),
          },
          type: event.type,
          meta: event.lmScheduleMeta,
        };
        if (event.crJobId && event.lmScheduleMeta) {
          scheduleEvent.title = `(${event.lmScheduleMeta.crId}) ${
            event.lmScheduleMeta.loanClassification
          } - ${event.lmScheduleMeta.type.replaceAll("_", " ")}`;
        }
        events.push(scheduleEvent);
      }
    }
  }
  return events;
};

export default ConvertSchedulingDetailsToEvents;
