import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import moment from "moment-timezone";
import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import CacheBuster from "react-cache-buster";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store, {persistor} from "app/store/store";
import App from "./App";
import Theme from "Theme";
import "./index.scss"; // Standard version
// i18n plugin import
import "app/infra/plugins/i18n";
import ErrorWrapper from "app/pages/errors/ErrorWraper";
import {IGLoading} from "app/components";
import {PersistGate} from "redux-persist/integration/react";
import {BugsnagErrorHandler} from "app/infra/services/bugsnag";

moment.tz.setDefault("Asia/Kolkata");
const appVersion = require("../package.json").version;
const isProduction = process.env.NODE_ENV === "production";

Bugsnag.start({
  apiKey: String(process.env.REACT_APP_BUGSNAG_KEY),
  plugins: [new BugsnagPluginReact(React)],
  appVersion: appVersion,
  appType: "dashboard",
  autoTrackSessions: true,
  autoDetectErrors: true,
  collectUserIp: true,
  enabledBreadcrumbTypes: ["error", "request", "navigation"],
  onError: (event) => {
    // Discard errors with errorClass = InvalidError to save on your quota
    if (
      event.errors[0].errorClass.includes("InvalidError") ||
      event.errors[0].errorClass.includes("FirebaseError")
    ) {
      return false;
    }
  },
});

const Boundary = Bugsnag.getPlugin("react");

let ErrorBoundary: BugsnagErrorBoundary | undefined;

if (Boundary) {
  document.addEventListener("DOMContentLoaded", function() {
    ErrorBoundary = Boundary.createErrorBoundary(React);
    ReactDOM.render(
      <CacheBuster
        currentVersion={appVersion}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<IGLoading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <Provider store={store}>
        <PersistGate persistor={persistor} loading={<></>}>
          <BrowserRouter>
            <ErrorBoundary
              FallbackComponent={({error, clearError}) => (
                <ErrorWrapper error={error} clearError={clearError} />
              )}
              onError={(error) => BugsnagErrorHandler(error)}
            >
              <Theme>
                <App />
              </Theme>
            </ErrorBoundary>
          </BrowserRouter>
          </PersistGate>
        </Provider>
      </CacheBuster>,
      document.getElementById("root"),
    );
  });
}
