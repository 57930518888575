import {LoanModel} from "../../models/loan.model";
import {createSlice, current} from "@reduxjs/toolkit";
import {GoldApiError} from "../../typings/api/goldApi.types";
import loanActions from "./loan.actions";
import {isArray} from "lodash";

type LoadingErrorState =
  "getLoanDetails"
  | "getLoanRepaymentSchedule"
  | "getAllUserLoans"
  | "getAllVirtualAddresses"
  | "generateVirtualAddress";

export interface LoanState {
  loans: Record<number, LoanModel>
  allUserLoanList: LoanModel[],
  loading: Record<LoadingErrorState, boolean>
  errors: Record<LoadingErrorState, GoldApiError | null>
}

const initialState: LoanState = {
  loans: {},
  allUserLoanList: [],
  loading: {
    getLoanDetails: false,
    getLoanRepaymentSchedule: false,
    getAllUserLoans: false,
    getAllVirtualAddresses: false,
    generateVirtualAddress: false,
  },
  errors: {
    getLoanDetails: null,
    getLoanRepaymentSchedule: null,
    getAllUserLoans: null,
    getAllVirtualAddresses: null,
    generateVirtualAddress: null,
  },
};

const LoanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    resetLoanList: (state) => {
      state.loans = {};
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loanActions.getLoanDetails,
        (state) => {
          state.loading.getLoanDetails = true;
          state.errors.getLoanDetails = null;
        },
      )
      .addCase(loanActions.getLoanDetailsSuccess,
        (state, action) => {
          state.loading.getLoanDetails = false;
          const loanId = Number(action.payload.response.id);
          state.loans[loanId] = {
            ...state.loans[loanId],
            ...action.payload.response,
          };
        },
      )
      .addCase(loanActions.getLoanDetailsFailure,
        (state, action) => {
          state.loading.getLoanDetails = false;
          state.errors.getLoanDetails = action.payload.error;
        },
      )
      .addCase(loanActions.getLenderLoanDetails,
        (state) => {
          state.loading.getLoanDetails = true;
          state.errors.getLoanDetails = null;
        },
      )
      .addCase(loanActions.getLenderLoanDetailsSuccess,
        (state, action) => {
          state.loading.getLoanDetails = false;
          const igLoanId = Number(action.payload.response.igLoanId);
          const currentLoans = current(state.loans);
          state.loans[igLoanId] = {
            ...(currentLoans[igLoanId] || {}),
            ...action.payload.response,
          };
        },
      )
      .addCase(loanActions.getLenderLoanDetailsFailure,
        (state, action) => {
          state.loading.getLoanDetails = false;
          state.errors.getLoanDetails = action.payload.error;
        },
      )
      .addCase(loanActions.getLoanRepaymentSchedule,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = true;
          const currentLoans = {...state.loans};
          state.errors.getLoanRepaymentSchedule =
            initialState.errors.getLoanRepaymentSchedule;

          if (action.payload.loanId in currentLoans) {
            state.loans[action.payload.loanId].loanInstallmentList = [];
          }
        },
      )
      .addCase(loanActions.getLoanRepaymentScheduleSuccess,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = false;
          const {
            loanId,
            loanInstallmentResponseDtoList,
            isPersonalLoanTaken,
          } = action.payload.response;

          state.loans[loanId] = {
            ...state.loans[loanId],
            id: Number(loanId),
            loanInstallmentList: loanInstallmentResponseDtoList,
            isPersonalLoanTaken: isPersonalLoanTaken,
          };
        },
      )
      .addCase(loanActions.getLoanRepaymentScheduleFailure,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = false;
          state.errors.getLoanRepaymentSchedule = action.payload.error;
        },
      )
      .addCase(loanActions.getLenderLoanRepaymentSchedule,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = true;
          const currentLoans = {...state.loans};
          state.errors.getLoanRepaymentSchedule =
            initialState.errors.getLoanRepaymentSchedule;

          if (action.payload.loanId in currentLoans) {
            state.loans[action.payload.loanId].loanInstallmentList = [];
          }
        },
      )
      .addCase(loanActions.getLenderLoanRepaymentScheduleSuccess,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = false;
          const {
            igLoanId,
            loanInstallmentResponseDtoList,
            isPersonalLoanTaken,
          } = action.payload.response;

          const currentLoans = current(state.loans);

          state.loans[Number(igLoanId)] = {
            ...(currentLoans[Number(igLoanId)] || {}),
            id: Number(igLoanId),
            igLoanId: Number(igLoanId),
            loanInstallmentList: loanInstallmentResponseDtoList,
            isPersonalLoanTaken: isPersonalLoanTaken,
          };
        },
      )
      .addCase(loanActions.getLenderLoanRepaymentScheduleFailure,
        (state, action) => {
          state.loading.getLoanRepaymentSchedule = false;
          state.errors.getLoanRepaymentSchedule = action.payload.error;
        },
      )
      .addCase(loanActions.getAllUserLoans,
        (state) => {
          state.loading.getAllUserLoans = true;
          state.errors.getAllUserLoans = initialState.errors.getAllUserLoans;
        },
      )
      .addCase(loanActions.getAllUserLoansSuccess,
        (state, action) => {
          state.loading.getAllUserLoans = false;
          state.allUserLoanList = action.payload.response;

          for (const loan of action.payload.response) {
            state.loans[loan.id] = {
              ...state.loans[loan.id],
              ...loan,
            };
          }
        },
      )
      .addCase(loanActions.getAllUserLoansFailure,
        (state, action) => {
          state.loading.getAllUserLoans = false;
          state.errors.getAllUserLoans = action.payload.error;
        },
      )
      .addCase(loanActions.getAllVirtualAddresses,
        (state) => {
          state.loading.getAllVirtualAddresses = true;
          state.errors.getAllVirtualAddresses =
            initialState.errors.getAllVirtualAddresses;
        },
      )
      .addCase(loanActions.getAllVirtualAddressesSuccess,
        (state, action) => {
          const {
            loanId,
            list,
          } = action.payload.response;
          state.loading.getAllVirtualAddresses = false;
          state.errors.getAllVirtualAddresses =
            initialState.errors.getAllVirtualAddresses;
          let virtualAddressList = [];
          if (!isArray(list)) {
            virtualAddressList = [list];
          } else {
            virtualAddressList = list;
          }

          state.loans[loanId] = {
            ...state.loans[loanId],
            id: Number(loanId),
            virtualAddressList,
          };
        },
      )
      .addCase(loanActions.getAllVirtualAddressesFailure,
        (state, action) => {
          state.loading.getAllVirtualAddresses = false;
          state.errors.getAllVirtualAddresses = action.payload.error;
        },
      )
      .addCase(loanActions.generateVirtualAddress,
        (state) => {
          state.loading.generateVirtualAddress = true;
          state.errors.getAllVirtualAddresses =
            initialState.errors.getAllVirtualAddresses;
          state.errors.generateVirtualAddress =
            initialState.errors.generateVirtualAddress;
        },
      )
      .addCase(loanActions.generateVirtualAddressSuccess,
        (state, action) => {
          const {
            loanId,
            list,
          } = action.payload.response;
          const virtualAddressIndex = current(
            state.loans[loanId].virtualAddressList,
          ).findIndex(
            (v) =>
              v.virtualAddressType ===
              list.virtualAddressType,
          );

          if (virtualAddressIndex !== -1) {
            state.loans[loanId].virtualAddressList[
              virtualAddressIndex
            ] = list;
          } else {
            state.loans[loanId].virtualAddressList.push(list);
          }

          state.loading.generateVirtualAddress = false;
          state.errors.generateVirtualAddress =
            initialState.errors.generateVirtualAddress;
        },
      )
      .addCase(loanActions.generateVirtualAddressFailure,
        (state, action) => {
          state.loading.generateVirtualAddress = false;
          state.errors.generateVirtualAddress = action.payload.error;
          state.errors.getAllVirtualAddresses = action.payload.error;
        },
      )
  ,
});

const {
  resetLoanList,
} = LoanSlice.actions;

export {
  resetLoanList,
};

export default LoanSlice.reducer;