import {RootState} from "app/store/store";

export const closureSelectors = {
  closureAmount: (state: RootState) =>
    state.serviceDesk.services.closureState.closureAmountData,
  getClosureDetails: (state: RootState) =>
    state.serviceDesk.services.closureState.closureDetail,
  paymentDetail: (state: RootState) =>
    state.serviceDesk.services.closureState.paymentDetail,
  getLoading: (state: RootState) =>
    state.serviceDesk.services.closureState.loading,
  bankList: (state: RootState) =>
    state.serviceDesk.services.closureState.bankList,
  upiList: (state: RootState) =>
    state.serviceDesk.services.closureState.upiList,
  bankDetailFromIFSC: (state: RootState) =>
    state.serviceDesk.services.closureState.bankDetail,
  getErrors: (state: RootState) =>
    state.serviceDesk.services.closureState.error,
  getAvailableDeliverySlots: (state: RootState) =>
    state.serviceDesk.services.closureState.deliverySlots,
  getDeliverySlotDetail: (state: RootState, id: string) =>
    state.serviceDesk.services.closureState.deliverySlotDetail[id],
};
