import {goldApiService} from "app/infra/plugins/axios";
import {ApiResources} from "app/typings/api/goldApi.types";
import {AgentAvailabilityApiInterface} from "./types";

const AGENT_AVAILABILITY_ENDPOINTS: ApiResources = {
  GET_AGENTS: {
    URL: "/admin/service-desk/cs-agent/schedule", // ? cityId, roleName
    METHOD: "GET",
  },
  UPDATE_SCHEDULE: {
    URL: "/admin/service-desk/cs-agent/schedule", // ? cityId
    METHOD: "PUT",
  },
};

const AgentAvailabilityApi: AgentAvailabilityApiInterface = {
  getAgentScheduleList: async () => {
    const {response, error} = await goldApiService({
      resource: AGENT_AVAILABILITY_ENDPOINTS.GET_AGENTS,
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
  updateAgentSchedule : async (payload) => {
    const {response, error} = await goldApiService({
      resource: AGENT_AVAILABILITY_ENDPOINTS.UPDATE_SCHEDULE,
      options: {
        data: payload,
      },
    });
    if (error) {
      return {error, response: null};
    }
    return {response, error: null};
  },
};

export default AgentAvailabilityApi;

export {AGENT_AVAILABILITY_ENDPOINTS};
