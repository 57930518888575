import React, {useCallback, useEffect, useRef, useState} from "react";
import {Typography} from "@mui/material";
import PersonalLoanTaken from "./PersonalLoanTaken";
import PersonalLoanNotTaken from "./PersonalNotTaken";
import getLTV from "api/business/getLTV";
import {CircularProgress} from "@mui/material";
import styles from "../styles";
import isPersonalLoanTakenAPi from "api/business/isPersonalLoanTaken";
import returnToGoldValuation from "api/activeLoan/returnToGoldValuation";
import ModalComponent from "app/components/Modal";
import getGoldLoanDetails from "api/business/getGoldLoanDetail";
import {useSnackBar} from "app/store/hooks";

const Summary = ({
  selectedPayload,
  isSameCreditManager,
  isBackMovement,
  getMileStoneOfCr,
}) => {
  const classes = styles();
  const [modal, setModal] = useState(false);
  const [requesting, setRequesting] = useState(false);
  // const [loanDetailResponse, setLoanDetailResponse] = useState({
  //   goldLoan: null,
  //   personalLoan: null,
  // });

  const [states, setStates] = useState({
    loading: true,
    error: false,
    payload: {
      ltv: null,

      isPersonalLoanTaken: selectedPayload.isPersonalLoanTaken,
    },
    errorMessage: "",
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  // const [disableReturnButton, setDisableReturnButton] = useState(false);
  const {showError, showSuccess} = useSnackBar();

  const ref = useRef(0);
  const ref2 = useRef(0);

  useEffect(() => {
    if (ref.current === 0) {
      ref.current = 1;
      (async () => {
        try {
          const [response, isPersonalLoanTakenResponse] = await Promise.all([
            getLTV({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
            }),
            isPersonalLoanTakenAPi({
              accessToken: localStorage.getItem("authToken"),
              crId: selectedPayload.id,
            }),
          ]);

          if (
            response.statusCode === 200 &&
            isPersonalLoanTakenResponse.statusCode === 200
          ) {
            setStates({
              error: false,
              errorMessage: "",
              loading: false,
              payload: {
                ltv: response.payload,
                isPersonalLoanTaken:
                  isPersonalLoanTakenResponse.payload.isPersonalLoanTaken,
              },
            });
          } else {
            setStates({
              error: true,
              errorMessage:
                response?.error?.toString() ||
                isPersonalLoanTakenResponse?.error?.toString(),
              loading: false,
              payload: {
                ltv: null,
                isPersonalLoanTaken: null,
              },
            });
          }
        } catch (error) {
          setStates({
            error: true,
            errorMessage: "Something went wrong",
            loading: false,
            payload: {
              ltv: null,
              isPersonalLoanTaken: null,
            },
          });
        }
      })();
    }
  }, [selectedPayload.id, selectedPayload.isPersonalLoanTaken]);

  const fetchData = useCallback(async () => {
    try {
      if (states.payload.isPersonalLoanTaken) {
        ref2.current = 1;
        const [goldLoanResponse, personalLoanResponse] = await Promise.all([
          getGoldLoanDetails({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
            loanType: "GOLD_LOAN",
          }),
          getGoldLoanDetails({
            accessToken: localStorage.getItem("authToken"),
            crId: selectedPayload.id,
            loanType: "PERSONAL_LOAN",
          }),
        ]);

        if (
          goldLoanResponse.statusCode === 200 &&
          personalLoanResponse.statusCode === 200
        ) {
          if (
            goldLoanResponse.payload.applicationNumber ||
            personalLoanResponse.payload.applicationNumber
          ) {
            // setDisableReturnButton(true);
          }
        } else {
          setStates({
            error: true,
            errorMessage:
              goldLoanResponse?.error?.toString() ||
              personalLoanResponse?.error?.toString(),
            loading: false,
            payload: {
              ltv: null,
              isPersonalLoanTaken: null,
            },
          });
        }
      } else {
        ref2.current = 1;

        const goldLoanResponse = await getGoldLoanDetails({
          accessToken: localStorage.getItem("authToken"),
          crId: selectedPayload.id,
          loanType: "GOLD_LOAN",
        });

        if (goldLoanResponse.statusCode === 200) {
          if (goldLoanResponse.payload.applicationNumber) {
            // setDisableReturnButton(true);
          }
        } else {
          setStates({
            error: true,
            errorMessage: goldLoanResponse?.error?.toString(),
            loading: false,
            payload: {
              ltv: null,
              isPersonalLoanTaken: null,
            },
          });
        }
      }
    } catch (err) {
      setStates({
        error: true,
        errorMessage: err.message,
        loading: false,
        payload: {
          ltv: null,
          isPersonalLoanTaken: null,
        },
      });
    }
  }, [selectedPayload.id, states.payload.isPersonalLoanTaken]);

  useEffect(() => {
    if (ref2.current === 0) {
      fetchData();
    }
  }, [fetchData]);

  if (states.loading) {
    return <CircularProgress />;
  }

  if (states.error) {
    return (
      <div className={classes.textContainer}>
        <div className={classes.subText}>{states.errorMessage}</div>
      </div>
    );
  }

  const summary = states.payload.ltv;

  const ltv = summary.finalLtv;

  const onClickHandler = async () => {
    setButtonDisable(true);
    const offerResponse = await returnToGoldValuation({
      accessToken: localStorage.getItem("authToken"),
      crId: selectedPayload.id,
    });

    if (offerResponse.statusCode === 200) {
      getMileStoneOfCr();
      showSuccess("Success");
      onModalHandler();
      fetchData();
    } else {
      showError(`Error: ${offerResponse.error || "Something went wrong"}`);
    }
    setButtonDisable(false);
  };

  const onModalHandler = () => {
    setModal((state) => !state);
  };

  return (
    <>
      {states.payload.isPersonalLoanTaken ? (
        <PersonalLoanTaken
          requesting={requesting}
          setRequesting={setRequesting}
          isSameCreditManager={isSameCreditManager}
          selectedPayload={selectedPayload}
          isBackMovement={isBackMovement}
          ltv={ltv}
          getMileStoneOfCr={getMileStoneOfCr}
          onModalHandler={onModalHandler}
        />
      ) : (
        <PersonalLoanNotTaken
          requesting={requesting}
          setRequesting={setRequesting}
          isSameCreditManager={isSameCreditManager}
          selectedPayload={selectedPayload}
          isBackMovement={isBackMovement}
          ltv={ltv}
          getMileStoneOfCr={getMileStoneOfCr}
          onModalHandler={onModalHandler}
        />
      )}

      <ModalComponent open={modal} setOpen={setModal}>
        <Typography variant="h6">Are you Sure</Typography>

        <Typography style={{margin: "10px 0"}}>
          You will have to redo all the process starting from gold valuation.
          Are you sure you want to go back ?
        </Typography>

        <div style={{display: "flex"}}>
          <button
            className={classes.ApproveButton}
            onClick={onClickHandler}
            disabled={buttonDisable}
          >
            OK
          </button>
          <button
            className={classes.reject}
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default Summary;
