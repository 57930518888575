import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  GET_ALL_ADDRESS: {
    URL: "/business/cr/:crId/address",
    METHOD: "GET",
  },
};

const getAllAddress = async ({
  crId,
  addressType,
}) => {

    const response = await goldApiService({
      resource: API_ENDPOINTS.GET_ALL_ADDRESS,
      options: {
        pathVars: {crId},
        queryParams: {
          addressType,
        },
      },
    });

    return response;
};

export default getAllAddress;
