import React, {memo} from "react";
import styles from "../../styles";
import clsx from "clsx";

const Video = ({video}) => {
  const classes = styles();

  return (
    <div className={classes.textContainer}>
      <div className={clsx(classes.textContainer, classes.center)}>
        <div className={classes.subText}>VIDEO</div>
      </div>
      <div
        className={clsx(
          classes.textContainer,
          classes.center,
          classes.videoContainer,
        )}
      >
        <video
          width="100%"
          controlsList="nodownload"
          controls
          src={video.fileUrl}
        ></video>
      </div>
    </div>
  );
};

export default memo(Video);
