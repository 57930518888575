import axios from "../../_metronic/utils/axios";

import getApiUrl from "lib/ApiUrl";
import {ErrorResponseHandler} from "../index";

const verifyLenderDetails = async ({
  accessToken,
  crId,
  confirmLenderLoanId,
  lenderBranchId,
  lenderLoanId,
  loanType,
}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: {
        "x-access-token": accessToken,
        "Content-type": "application/json",
      },
      url: `${getApiUrl()}/admin/business/cr/${crId}/loan-lender-detail/verify`,
      data: {
        confirmLenderLoanId,
        lenderBranchId,
        lenderLoanId,
        loanType,
      },
    });

    const result = await response.data;

    if (result.status !== 200 || result.error !== null) {
      throw new Error(result.error);
    }

    return {
      statusCode: 200,
      payload: result.result,
      message: "Success",
    };
  } catch (error) {
    return ErrorResponseHandler(error);
  }
};

export default verifyLenderDetails;
