import React, {useState} from "react";
// import {updateName as updateNameApi} from "api/business/updateName";
// import ButtonWithLoader from "app/components/Button/ButtonWithLoader";
import {IGTextField, IGDialog} from "app/components";
import {Box} from "@mui/material";
// import {useSnackBar} from "app/store/hooks";

const UpdateName = ({customerName, open, onClose, crId}) => {
  // const snackbar = useSnackBar();

  const [firstName, setFirstName] = useState(customerName.firstName || "");
  const [lastName, setLastName] = useState(customerName.lastName || "");
  const [middleName, setMiddleName] = useState(customerName.middleName || "");
  // const [loading, setLoading] = useState(false);

  // const updateName = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const {statusCode, error} = await updateNameApi({
  //       crId,
  //       firstName,
  //       lastName,
  //       middleName,
  //     });
  //     if (statusCode === 200 && !error) {
  //       snackbar.showError("Name updated successfully");
  //       onClose();
  //     } else {
  //       snackbar.showError(error?.errors[0] || "Something went wrong");
  //     }
  //   } catch (error) {
  //     snackbar.showError("Something went wrong");
  //   }
  //   setLoading(false);
  // }, [crId, firstName, lastName, middleName, snackbar, onClose]);
  return (
    <IGDialog
      hideFooter
      open={open}
      title="Update Customer Name"
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "66.666%",
          },
        },
      }}
      fullWidth={false}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <IGTextField
          disabled
          onChange={(e) => setFirstName(e.target.value)}
          label="First Name"
          name="firstName"
          value={firstName}
        />
        <IGTextField
          disabled
          onChange={(e) => setMiddleName(e.target.value)}
          label="Middle Name"
          name="middleName"
          value={middleName}
        />
        <IGTextField
          disabled
          onChange={(e) => setLastName(e.target.value)}
          label="Last Name"
          name="lastName"
          value={lastName}
        />
        {/* <div className="w-100 d-flex justify-content-center">
            <ButtonWithLoader
              loading={loading}
              onClick={() => {
                updateName();
              }}
              disabled={loading}
              className="w-75 pt-3 pb-3"
            >
              UPDATE NAME
            </ButtonWithLoader>
          </div> */}
      </Box>
    </IGDialog>
  );
};

export default UpdateName;
