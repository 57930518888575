import {goldApiService} from "app/infra/plugins/axios";

const API_ENDPOINTS = {
  URL: "/admin/business/cr/:crId/employment-detail",
  METHOD: "GET",
};
export const fetchEmploymentDetails = async ({crId, crEmploymentDetailType}) => {
  const response = await goldApiService({
    resource: API_ENDPOINTS,
    options: {
      pathVars: {
        crId,
      },
      queryParams: {
        crEmploymentDetailType,
      },
    },
  });
  return response;
};

export default fetchEmploymentDetails;