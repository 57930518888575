import Fresh from "./Fresh";
import React, {memo, useCallback} from "react";

const VideoKYC = ({
  selectedPayload,
  currentMileStone,
  showTakeoverKycAlert,
  setShowTakeoverKycAlert,
  otherRequestTimer,
  setAllChecked,
}) => {
  const isCorrectMilestone = useCallback(() => {
    return (
      currentMileStone.data.milestone === "CR_GOLD_LOAN_FRESH_UPDATE_KYC" ||
      currentMileStone.data.milestone === "CR_GOLD_LOCKER_PICKUP_KYC_DETAIL" ||
      currentMileStone.data.milestone === "CR_GOLD_LOAN_BT_LOAN_KYC_DETAIL"
    );
  }, [currentMileStone]);

  const isFresh = isCorrectMilestone();

  return (
    <>
      {
        <Fresh
          isFresh={isFresh}
          selectedPayload={selectedPayload}
          currentMileStone={currentMileStone}
          showTakeoverKycAlert={showTakeoverKycAlert}
          setShowTakeoverKycAlert={setShowTakeoverKycAlert}
          setAllChecked={setAllChecked}
        />
      }
    </>
  );
};

export default memo(VideoKYC);
