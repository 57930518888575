import styled from "@emotion/styled";
import {Box} from "@mui/material";

export const IGDrawerContentWrapper = styled(Box)`
  position: relative;

  .cls-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const IGDrawerTitle = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
`;

export const IGDrawerContent = styled(Box)`
  //height: calc(100vh - 134px);
  //overflow: auto;
`;