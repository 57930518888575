import React, {useMemo, useState} from "react";
import {Card, CardMedia} from "@mui/material";
import {IGButton, IGCarousel, IGDialog, IGDocumentViewer} from "app/components";
import {KycDocuments} from "app/models/loanRenewalRebook/personalDetails.model";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface GuarantorDocViewProps {
  guarantorDocument: KycDocuments;
}
const GuarantorDocView = ({guarantorDocument}: GuarantorDocViewProps) => {
  const [isViewAllOpen, setIsViewAllOpen] = useState(false);

  const docImageList = useMemo(() => {
    const images = [];

    if (guarantorDocument) {
      if (guarantorDocument.fileUrl) {
        images.push({title: "", url: guarantorDocument.fileUrl});
      }

      if (guarantorDocument.backFileUrl) {
        images.push({title: "", url: guarantorDocument.backFileUrl});
      }

      guarantorDocument.crDocumentImageResponseDtoList &&
        guarantorDocument.crDocumentImageResponseDtoList.forEach(image => {
          images.push({title: "", url: image.fileUrl});
        });
    }

    return images;
  }, [guarantorDocument]);

  const isPdf = guarantorDocument?.fileType.toLowerCase().includes("pdf");

  return (
    <>
      <Card sx={{width: "100px"}}>
        {isPdf && <IGDocumentViewer document={guarantorDocument} />}
        {!isPdf && (
          <>
            <CardMedia component="img" height="68px" image={guarantorDocument?.fileUrl} alt="Image Thumbnail" />
            <IGButton
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                setIsViewAllOpen(true);
              }}
              variant="text"
            >
              VIEW ALL
            </IGButton>
          </>
        )}
      </Card>
      {isViewAllOpen && (
        <IGDialog
          open={isViewAllOpen}
          onClose={() => setIsViewAllOpen(false)}
          hideFooter
          maxWidth="sm"
        >
          <IGCarousel
            imageList={docImageList}
            showThumbnailPreview={true}
            orientation="vertical"
            maxImageHeight="65vh"
          />
        </IGDialog>
      )}
    </>
  );
};

export default GuarantorDocView;
