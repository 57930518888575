import React from "react";
import {lenderNameEnum} from "app/enums/gold_loan/activeLoans";
import FormWrapper from "./styles/FormWrapper";
import Heading from "./styles/Heading";
import FormContainer from "./styles/FormContainer";
import InputWrapper from "./styles/InputWrapper";
import InputLabel from "./styles/InputLabel";

const ViewLenderAccountDetails = ({
  isSameCreditManager,
  isBackMovement,
  goldLoanDisplayValue,
  personalLoanDisplayValue,
  selectedPayload,
}) => {
  return (
    <>
      {(!isSameCreditManager || isBackMovement) && (
        <>
          {goldLoanDisplayValue && (
            <FormWrapper>
              <Heading info>Gold Loan Information</Heading>
              <FormContainer>
                <InputWrapper>
                  <InputLabel info>Lender Branch</InputLabel>
                  <InputLabel info>
                    {goldLoanDisplayValue.lenderBranchId || "N/A"}
                  </InputLabel>
                </InputWrapper>
                <InputWrapper>
                  <InputLabel info>Account Number</InputLabel>
                  <InputLabel info>
                    {goldLoanDisplayValue.lenderLoanId || "N/A"}
                  </InputLabel>
                </InputWrapper>
              </FormContainer>
            </FormWrapper>
          )}
          {selectedPayload.isPersonalLoanTaken &&
            personalLoanDisplayValue &&
            personalLoanDisplayValue?.lenderName !==
              lenderNameEnum.SUNITA_FINANCE && (
              <FormWrapper>
                <Heading info>Personal Loan Information</Heading>
                <FormContainer>
                  <InputWrapper>
                    <InputLabel info>Lender Branch</InputLabel>
                    <InputLabel info>
                      {personalLoanDisplayValue.lenderBranchId || "N/A"}
                    </InputLabel>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel info>Account Number</InputLabel>
                    <InputLabel info>
                      {personalLoanDisplayValue.lenderLoanId || "N/A"}
                    </InputLabel>
                  </InputWrapper>
                </FormContainer>
              </FormWrapper>
            )}
        </>
      )}
    </>
  );
};

export default ViewLenderAccountDetails;
