import {
  CustomerProfileApiResources,
  CustomerProfileApiInterface,
  GetInwardReceiptImagePayload,
} from "./types";
import {goldApiService} from "../../../plugins/axios";

const CUSTOMER_PROFILE_ENDPOINTS: CustomerProfileApiResources = {
  GET_OVERDUE_PAYMENT_DETAIL: {
    URL: "/admin/payment-overdue/:loanId/user-detail",
    METHOD: "GET",
  },
  GET_REMINDER: {
    URL: "/admin/payment-overdue/:loanId/remark",
    METHOD: "GET",
  },
  SEND_REMINDER: {
    URL: "/admin/payment-overdue/:loanId/send-reminder",
    METHOD: "POST",
  },
  GET_CUSTOMER_PROFILE_LIST: {
    URL: "/admin/business/loan/profile", // startTime, endTime, pageNo, pageSize
    METHOD: "GET",
  },
  GET_CUSTOMER_DETAILS: {
    URL: "/admin/business/user/:userId/profile",
    METHOD: "GET",
  },
  GET_INWARD_RECEIPT_IMAGE: {
    URL:
      "/business-dashboard/locker/loan/:loanId/gold-submission-receipt-detail",
    METHOD: "GET",
  },
  VALIDATE_PART_PAYMENT_VA: {
    URL: "/admin/business/loan/:parentLoanId/part-payment-va/validate",
    METHOD: "GET",
  },
  ENABLE_PART_PAYMENT_FLAG: {
    URL: "/admin/business/loan/:loanId/part-payment-on-app/enable",
    METHOD: "POST",
  },
  GET_CONSUMER_SOA_REPORT: {
    URL: "/admin/lms/loan/:loanId/consumer-soa-report",
    METHOD: "GET",
  },
  GET_CONSUMER_SOA_DATA_FOR_PDF: {
    URL: "/admin/lms/consumer-soa/report-data",
    METHOD: "GET",
  },
  UPLOAD_BATCH_CLOSURE_REFUND_REPORT: {
    URL: "/admin/loan/batch-closure-refund",
    METHOD: "POST",
  },
};

const customerProfileApi: CustomerProfileApiInterface = {
  getOverduePaymentDetails: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_OVERDUE_PAYMENT_DETAIL,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getReminder: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_REMINDER,
      options: {
        pathVars: {loanId},
      },
    });
  },
  sentReminder: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.SEND_REMINDER,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getCustomerProfileList: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CUSTOMER_PROFILE_LIST,
      options: {
        queryParams: {
          ...payload,
        },
      },
    });
  },
  getCustomerDetails: async ({userId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CUSTOMER_DETAILS,
      options: {
        pathVars: {userId},
      },
    });
  },
  getInwardReceiptImage: async ({loanId}: GetInwardReceiptImagePayload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_INWARD_RECEIPT_IMAGE,
      options: {
        pathVars: {
          loanId,
        },
      },
    });
  },
  validatePartPaymentVA: async ({parentLoanId, partPaymentAmount}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.VALIDATE_PART_PAYMENT_VA,
      options: {
        pathVars: {
          parentLoanId,
        },
        queryParams: {
          partPaymentAmount,
        },
      },
    });
  },
  enablePartPaymentFlag: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.ENABLE_PART_PAYMENT_FLAG,
      options: {
        pathVars: {
          loanId,
        },
      },
    });
  },
  getConsumerSoaReport: async ({loanId}) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CONSUMER_SOA_REPORT,
      options: {
        pathVars: {loanId},
      },
    });
  },
  getConsumerSoaDataForPDF: async (payload) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.GET_CONSUMER_SOA_DATA_FOR_PDF,
      options: {
        queryParams: {...payload},
      },
    });
  },
  uploadBatchClosureRefundReport: async (formData) => {
    return await goldApiService({
      resource: CUSTOMER_PROFILE_ENDPOINTS.UPLOAD_BATCH_CLOSURE_REFUND_REPORT,
      options: {
        data: formData,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export {CUSTOMER_PROFILE_ENDPOINTS};

export default customerProfileApi;
