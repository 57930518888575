import {call, getContext, put, takeLatest} from "redux-saga/effects";
import {GoldApi} from "../../infra/services/api";
import {cityActions} from "./index";
import {cityActionTypes} from "./city.actions";

const {
  getCityListSuccess,
  getCityListFailure,
} = cityActions;

function* getCityListFlow() {
  const api: GoldApi = yield getContext("api");
  const {response, error} = yield call(
    api.city.getCityList,
  );
  if (error) {
    yield put(getCityListFailure({response: null, error}));
  } else {
    yield put(getCityListSuccess({response, error: null}));
  }
}
export default function* citySaga() {
  yield takeLatest(
    cityActionTypes.GetCityList,
    getCityListFlow,
  );
}