import {goldApiService} from "app/infra/plugins/axios";

import {
  PersonalDetailsApiInterface,
  PersonalDetailsApiResources,
  GetPersonalDetailsPayload,
  UpdatePersonalDetailsPayload,
  GetKycDocumentPayload,
  GetGuarantorPersonalDetailsPayload,
  GetGuarantorAddressDetailsPayload,
} from "app/store/loanRenewalRebook/personalDetails/personalDetails.types";

const PERSONAL_DETAILS_ENDPOINTS: PersonalDetailsApiResources = {
  GET_PERSONAL_DETAILS: {
    URL: "/admin/renew-rebook/:crId/personal-detail",
    METHOD: "GET",
  },
  GET_ADDRESS_DETAILS: {
    URL: "/admin/renew-rebook/:crId/address-detail",
    METHOD: "GET",
  },
  UPDATE_PERSONAL_DETAILS: {
    URL: "/admin/renew-rebook/:crId/personal-detail",
    METHOD: "POST",
  },
  GET_DOCUMENTS_MASTER_LIST: {
    URL: "/admin/renew-rebook/document-master-list",
    METHOD: "GET",
  },
  GET_KYC_DOCUMENTS: {
    URL: "/admin/renew-rebook/:crId/kyc-doc",
    METHOD: "GET",
  },
  GET_GUARANTOR_PERSONAL_DETAILS: {
    URL: "/business/cr/:crId/personal-detail",
    METHOD: "GET",
  },
  GET_GUARANTOR_ADDRESS_DETAILS: {
    URL: "/business/cr/:crId/address",
    METHOD: "GET",
  },
  GET_GUARANTOR_KYC_DOCUMENTS: {
    URL: "/admin/business/v2/cr/:crId/document-list",
    METHOD: "GET",
  },
};

const personalDetailsApi: PersonalDetailsApiInterface = {
  getPersonalDetails: async ({crId}: GetPersonalDetailsPayload) => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.GET_PERSONAL_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
  },
  getAddressDetails: async ({crId}: GetPersonalDetailsPayload) => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.GET_ADDRESS_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
      },
    });
  },
  updatePersonalDetails: async (crId: number, payload: UpdatePersonalDetailsPayload) => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.UPDATE_PERSONAL_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        data: {
          ...payload,
        },
      },
    });
  },
  getDocumentsMasterList: async () => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.GET_DOCUMENTS_MASTER_LIST,
    });
  },
  getKycDocuments: async ({
    crId,
    documentTypeList,
    isRenewRebook = true,
  }: GetKycDocumentPayload) => {
    return await goldApiService({
      resource: isRenewRebook
        ? PERSONAL_DETAILS_ENDPOINTS.GET_KYC_DOCUMENTS
        : PERSONAL_DETAILS_ENDPOINTS.GET_GUARANTOR_KYC_DOCUMENTS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          documentTypeList,
        },
      },
    });
  },
  getGuarantorPersonalDetails: async ({
    crId,
    personalDetailType,
  }: GetGuarantorPersonalDetailsPayload) => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.GET_GUARANTOR_PERSONAL_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          personalDetailType,
        },
      },
    });
  },

  getGuarantorAddressDetails: async ({crId, addressType}: GetGuarantorAddressDetailsPayload) => {
    return await goldApiService({
      resource: PERSONAL_DETAILS_ENDPOINTS.GET_GUARANTOR_ADDRESS_DETAILS,
      options: {
        pathVars: {
          crId: crId,
        },
        queryParams: {
          addressType,
        },
      },
    });
  },
};

export {PERSONAL_DETAILS_ENDPOINTS};
export default personalDetailsApi;
