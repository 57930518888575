import Bugsnag from "@bugsnag/js";
import {UserInterface} from "app/models/user";

export interface BugsnagNotifyParams {
  error: any;
  user: UserInterface | null | undefined;
  response?: any;
}

export interface BugsnagNotifyHandler {
  (args: BugsnagNotifyParams): void;
}

const BugsnagNotify: BugsnagNotifyHandler = ({
  error,
  user,
  response,
}: BugsnagNotifyParams) => {
  Bugsnag.notify(error, (event) => {
    event.addMetadata("API", {
      Error: error,
    });
    event.addMetadata("API_RESPONSE", {
      Response: response,
    });

    event.severity = "warning";
    event.context = window.location.pathname;

    if (user) {
      const userId = user.id || localStorage.getItem("id");
      const email = user.email || localStorage.getItem("email");
      const name = user.name || localStorage.getItem("name");


      if (userId && email && name) {
        event.setUser(userId, email, name);
      }
    }
  });
  Bugsnag.resetEventCount?.();
};

export function BugsnagErrorHandler(value: unknown) {
  const user={};
  BugsnagNotify({
    error: value,
    user: user as any,
  });
}

export default BugsnagNotify;
