import {RootState} from "../store";

const dialerSelectors = {
  getEventById: (state: RootState, id: string) => {
    return state.dialer.eventDetailsById[id];
  },
  getEventByMobile: (state: RootState, mobile: string) => {
    return Object.values(state.dialer.eventDetailsById).find(
      (event) => event.mobile === mobile,
    );
  },
  getAgentSchedule: (state: RootState) => state.dialer.agentSchedule,
  getLoading: (state: RootState) => state.dialer.loading,
  getErrors: (state: RootState) => state.dialer.errors,
};

export default dialerSelectors;
