import {goldApiService} from "../../../plugins/axios";
import {
  TransactionsApiInterface,
  TransactionsApiResources,
  CrIdPayload,
  GetAdjustmentRenewalRoiPayload,
} from "app/store/transactions/transactions.types";

const TRANSACTIONS_ENDPOINTS: TransactionsApiResources = {
  GET_TRANSACTIONS_LIST: {
    URL: "/admin/disbursal/cr/:crId/transaction-list",
    METHOD: "GET",
  },
  CREATE_TRANSACTION: {
    URL: "/admin/disbursal/cr/:crId/transaction-list",
    METHOD: "POST",
  },
  PROCEED_AFTER_ADJUSTMENT: {
    URL:
      "/admin/business/dashboard/loan-amount/cr/:crId/proceed-after-loan-adjustment-and-loan-amount-change",
    METHOD: "POST",
  },
  SHOW_TRANSACTION_FLOW: {
    URL:
      "/admin/business/dashboard/loan-amount/cr/:crId/show-transaction-maker-flow",
    METHOD: "GET",
  },
  GET_ADJUSTMENT_RENEWAL_ROI: {
    URL:
      "/admin/business/dashboard/loan-amount/cr/:crId/cm-adjustment-renewal-roi-detail",
    METHOD: "GET",
  },
  SHARE_OFFER: {
    URL:
      "/admin/business/dashboard/loan-amount/cr/:crId/proceed-after-loan-adjustment-and-loan-amount-change",
    METHOD: "POST",
  },
};

const transactionsApi: TransactionsApiInterface = {
  getTransactionsList: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.GET_TRANSACTIONS_LIST,
      options: {
        pathVars: {crId},
      },
    });
  },
  proceedAfterAdjustment: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.PROCEED_AFTER_ADJUSTMENT,
      options: {
        pathVars: {crId},
      },
    });
  },
  createTransaction: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.CREATE_TRANSACTION,
      options: {
        pathVars: {crId},
      },
    });
  },
  showTransactionFlow: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.SHOW_TRANSACTION_FLOW,
      options: {
        pathVars: {crId},
      },
    });
  },
  getAdjustmentRenewalRoi: async ({
    crId,
    loanType,
  }: GetAdjustmentRenewalRoiPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.GET_ADJUSTMENT_RENEWAL_ROI,
      options: {
        pathVars: {crId},
        queryParams: {
          loanType,
        },
      },
    });
  },
  shareOfferWithCustomer: async ({crId}: CrIdPayload) => {
    return await goldApiService({
      resource: TRANSACTIONS_ENDPOINTS.SHARE_OFFER,
      options: {
        pathVars: {crId},
      },
    });
  },
};
export {TRANSACTIONS_ENDPOINTS};
export default transactionsApi;
