import {IGDialogProps} from "../../components/IGDialog";
import {ReactNode} from "react";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface IGDialogState extends Optional<IGDialogProps, "onCancel" | "onClose"> {
  type: "confirm" | "success" | "error" | "loading"
  content: ReactNode
  showActions: boolean
  timer?: boolean
  persist?: boolean
}

const initialState: IGDialogState = {
  open: false,
  type: "confirm",
  title: undefined,
  content: undefined,
  timer: false,
  persist: false,
  onConfirm: () => {
  },
  showActions: true,
};

const IGDialogSlice = createSlice({
  name: "igDialog",
  initialState,
  reducers: {
    open: (state, action: PayloadAction<Omit<IGDialogState, "open" | "onConfirm">>) => {
      state.open = true;
      state.title = action.payload.title || initialState.title;
      state.content = action.payload.content || initialState.content;
      state.timer = action.payload.timer || false;
      state.persist = action.payload.persist || false;
      delete action.payload.title;
      delete action.payload.content;
      for (const [key, value] of Object.entries(action.payload)) {
        // @ts-ignore
        state[key] = value;
      }
    },
    close: (state) => {
      state.open = false;
      state.title = initialState.title;
      state.content = initialState.content;
    },
  },
});

const IGDialogActions = IGDialogSlice.actions;

const IGDialogSelectors = {
  getOpen: (state: RootState) => state.igDialog.open,
  getTitle: (state: RootState) => state.igDialog.title,
  getContent: (state: RootState) => state.igDialog.content,
  getType: (state: RootState) => state.igDialog.type,
  getOnConfirm: (state: RootState) => state.igDialog.onConfirm,
  getOtherParams: (state: RootState) => {
    const {
      open,
      title,
      content,
      type,
      onConfirm,
      ...params
    } = state.igDialog;
    // only return other params
    return params;
  },
};

export default IGDialogSlice.reducer;

export {IGDialogSlice, IGDialogSelectors, IGDialogActions};