import {ApiResources} from "app/typings/api/goldApi.types";
import {goldApiService} from "app/infra/plugins/axios";

export interface Props {
  crId: number;
}

export const getPartnerLeadSource = async ({crId}: Props) => {
  const API_ENDPOINTS: ApiResources = {
    GET_LEAD_SOURCE: {
      URL: "/admin/business/v2/cr/:crId/lead-partner",
      METHOD: "GET",
    },
  };

  return await goldApiService({
    resource: API_ENDPOINTS.GET_LEAD_SOURCE,
    options: {
      pathVars: {
        crId,
      },
    },
  });
};

export default getPartnerLeadSource;
